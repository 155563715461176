import React from "react";
import {
  filterSearchColumn,
  useSorterTable,
} from "../../functions/UseFunction";
import { Input, Select, Tag } from "antd";
import { decryptString } from "../../../service/functions/encodeFunction";
import { renderStatusColorInstallment } from "../RecheckWorkingSaleEmployee/ColumnRecheckWorkingSaleEmployee";

export const ColumnInsertNewSaleEmployee = ({
  onChangeRow,
  dataSource,
  optionSaleCode,
  optionSearch,
}) => [
  {
    title: "ลำดับที่",
    dataIndex: "rowNo",
    key: "rowNo",
    align: "center",
    // fixed: "left",
    width: 100,
  },
  {
    title: "รหัสเซลล์/สาขา",
    dataIndex: "SaleCode",
    key: "SaleCode",
    align: "center",
    width: 200,
    ...filterSearchColumn("SaleCode", "รหัสเซลล์/สาขา"),
    render: (text, record) => {
      return (
        <Select
          showSearch
          style={{ width: "100%" }}
          name={`SaleCode${record.rowNo}`}
          defaultValue={
            dataSource[record.rowNo - 1]
              ? dataSource[record.rowNo - 1].SaleCode
              : ""
          }
          onChange={(e) => onChangeRow(e, record.rowNo, "SaleCode")}
        >
          {optionSaleCode.length > 0 &&
            optionSaleCode.map((el) => {
              return (
                <Select.Option value={el.childCode}>
                  {el.childCode}
                </Select.Option>
              );
            })}
        </Select>
      );
      // return dataSource.length > 0 && <Input name={`SaleCode${record.rowNo}`} value={dataSource[record.rowNo - 1] ? dataSource[record.rowNo - 1].saleCodeCommline || dataSource[record.rowNo - 1].saleCodeDplus : ''} onChange={(e) => onChangeRow(e, record.rowNo)} />
    },
  },
  {
    title: "รหัสพนักงาน",
    dataIndex: "ID_User",
    key: "ID_User",
    align: "center",
    // fixed: "left",
    width: 100,
    ...filterSearchColumn("ID_User", "รหัสพนักงาน"),
  },
  {
    title: "หน่วยงาน",
    dataIndex: "departmentName",
    key: "departmentName",
    align: "center",
    // fixed: "left",
    width: 100,
    ...filterSearchColumn("departmentName", "หน่วยงาน"),
  },
  {
    title: "ตำแหน่ง",
    dataIndex: "PositionNameEN",
    key: "PositionNameEN",
    align: "center",
    // fixed: "left",
    width: 200,
    ...filterSearchColumn("PositionNameEN", "ตำแหน่ง"),
  },
  {
    title: "ชื่อพนักงาน",
    dataIndex: "Name",
    key: "Name",
    align: "left",
    // fixed: "left",
    width: 200,
    ...filterSearchColumn("Name", "ชื่อพนักงาน"),
  },
  {
    title: "แผนก",
    dataIndex: "departmentName",
    key: "departmentName",
    align: "center",
    // fixed: "left",
    width: 100,
    ...filterSearchColumn("departmentName", "แผนก"),
  },
  {
    title: "วันที่เริ่มงาน",
    dataIndex: "StartDateJob",
    key: "StartDateJob",
    align: "center",
    // fixed: "left",
    width: 150,
    ...filterSearchColumn("StartDateJob", "แผนก"),
  },
  {
    title: "วันที่พ้นสภาพ",
    dataIndex: "EndDateJob",
    key: "EndDateJob",
    align: "left",
    width: 150,
    ...filterSearchColumn("EndDateJob", "วันที่พ้นสภาพ"),
  },
  {
    title: "สถานะพนักงาน",
    dataIndex: "statusWorkerName",
    key: "statusWorkerName",
    align: "center",
    width: 200,
    ...filterSearchColumn("statusWorkerName", "สถานะพนักงาน"),
  },
  {
    title: "สถานะพนักงานประจำงวด",
    dataIndex: "statusInstallmentName",
    key: "statusInstallmentName",
    align: "center",
    width: 200,
    ...filterSearchColumn("statusInstallmentName", "สถานะพนักงานประจำงวด"),
    render: (text, record) => {
      return (
        <Tag
          color={renderStatusColorInstallment(record.statusWorkerInstallment)}
        >
          {text}
        </Tag>
      );
    },
  },
  {
    title: "กลุ่มรายได้",
    dataIndex: "incomeGroupName",
    key: "incomeGroupName",
    align: "left",
    width: 200,
    render: (text, record) => {
      return (
        <Select
          style={{ width: "100%" }}
          name={`incomeGroupName${record.rowNo}`}
          defaultValue={record.incomeGroupName}
          onChange={(e) => onChangeRow(e, record.rowNo, "incomeGroupName")}
        >
          {record.incomeGroup.map((el) => {
            return (
              <Select.Option value={el.incomeGroupName}>
                {el.incomeGroupName}
              </Select.Option>
            );
          })}
        </Select>
      );
    },
  },
  {
    title: "การันตี commission (บาท)",
    key: "commissionGuarantee",
    align: "left",
    width: 200,
    render: (text, record) => {
      return (
        dataSource.length > 0 && (
          <Input
            type="number"
            name={`commissionGuarantee${record.rowNo}`}
            value={
              dataSource[record.rowNo - 1]
                ? dataSource[record.rowNo - 1].commissionGuarantee
                : ""
            }
            onChange={(e) => onChangeRow(e, record.rowNo)}
          />
        )
      );
    },
  },
  {
    title: "การันตี incentive (บาท)",
    key: "incentiveGuarantee",
    dataIndex: "incentiveGuarantee",
    align: "right",
    width: 200,
    ...useSorterTable("incentiveGuarantee", "number"),
    render: (text, record) => {
      return (
        dataSource.length > 0 && (
          <Input
            type="number"
            name={`incentiveGuarantee${record.rowNo}`}
            value={
              dataSource[record.rowNo - 1]
                ? dataSource[record.rowNo - 1].incentiveGuarantee
                : ""
            }
            onChange={(e) => onChangeRow(e, record.rowNo)}
          />
        )
      );
    },
  },
  {
    title: "เหตุผลการันตี",
    dataIndex: "remarkGuarantee",
    key: "remarkGuarantee",
    align: "center",
    width: 200,
    ...filterSearchColumn("remarkGuarantee", "เหตุผลการันตี"),
    render: (text, record) => {
      return (
        <Select
          showSearch
          style={{ width: "100%" }}
          name={`remarkGuarantee${record.rowNo}`}
          defaultValue={
            dataSource[record.rowNo - 1]
              ? dataSource[record.rowNo - 1].remarkGuarantee
              : ""
          }
          onChange={(e) => onChangeRow(e, record.rowNo, "remarkGuarantee")}
        >
          {optionSearch.optionRemarkGuarnatee.length > 0 &&
            optionSearch.optionRemarkGuarnatee.map((el) => {
              return <Select.Option value={el.value}>{el.txt}</Select.Option>;
            })}
        </Select>
      );
      // return dataSource.length > 0 && <Input name={`SaleCode${record.rowNo}`} value={dataSource[record.rowNo - 1] ? dataSource[record.rowNo - 1].saleCodeCommline || dataSource[record.rowNo - 1].saleCodeDplus : ''} onChange={(e) => onChangeRow(e, record.rowNo)} />
    },
  },
];
