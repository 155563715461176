import React from "react";
import { path } from "ramda"

import "bootstrap/dist/css/bootstrap.css";
import { FormGroup, Label, Input } from "reactstrap";
import TextInput from "./TextInput";
import { Colors, colors } from "../../themes";
import BaseText from "../text/BaseText";
import { Radio } from "antd";

const RadioGroup = ({
  name = "",
  label = "",
  options = [],
  other = false,
  labelOther = "",
  width = 200,
  onChange,
  styleLabel,
  style,
  value,
  valueOther,
  nameOther,
  onChangeOther,
  ...rest
}) => {
  return (
    <FormGroup style={{ display: "flex", alignItems: "center", ...style }}>
      {label ? <Label>{label}</Label> : null}
      <FormGroup check>
        {options.map((option, i) => {
          return (
            <Label
              key={i}
              check
              style={{
                minWidth: other && option.other ? 100 : width,
                ...styleLabel
              }}
            >
              <Radio.Group onChange={e => onChange(name, e.target.value)} value={value}>
                <Radio value={option.value} >
                  {option.label || "-"}
                </Radio>
              </Radio.Group>
            </Label>
          );
        })}
      </FormGroup>
      {other && (
        <React.Fragment>
          <div style={{ display: "flex", alignItems: "center" }}>
            <BaseText
              color="red"
              style={{ margin: 0, minWidth: 100 }}
              padding={"0px 10px 0px 0px"}
              label={labelOther}
            />
            <TextInput
              style={{ margin: 0 }}
              type={"text"}
              name={nameOther}
              value={valueOther}
              onChange={onChangeOther}
            />
          </div>
        </React.Fragment>
      )}
    </FormGroup>
  );
};

export default RadioGroup;
