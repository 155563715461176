import React, { useState, useEffect } from "react";
import { Spin, Form, Space, Card } from 'antd';
import { configForm } from './constants/ConstantComponent';
import { useButton } from './conponents/buttons/CustomButtonAntd';
import { useRenderFormItem } from './conponents/RenderComponents';
import { ComponentSaleCodeAreaAssignment } from "./constants/SaleCodeAreaAssignment/Components";
import { sumColumnWidth } from "./functions/UseFunction";
import { ColumnSaleCodeAreaAssignment, ColumnAreaCode } from "./constants/SaleCodeAreaAssignment/Columns";
import CustomTableAtnd from './conponents/tables/CustomTableAtnd';
import { RenderMainModal } from "./conponents/modal/CustomModalAntd";
import { useOptionSelect } from "./constants/OptionSelect";
import { GET_MAP_SALE_AREA_LIST, POST, INSERT_UPDATE_SALE_AREA } from "../service";
import { useAlert } from "./conponents/modal/CustomAlert";
import { getPeriodDate } from "../functions";
import encodeString from "../extendFunction/encodeString";
import Cookies from 'js-cookie';
import { SetStringToObject } from './../constants/functions/index';

const SaleCodeAreaAssignment = () => {

	let optionSearch = {
		depNo: useOptionSelect("department"),
	}

	const [form] = Form.useForm();
	const [rowSelection, setRowSelection] = useState({ selectedRowKeys: [], selectedRows: [] });
	const [showModal, setShowModal] = useState(false);
	const [dataSource, setDataSource] = useState([])
	const [saleListOrigin, setSaleListOrigin] = useState([])
	const [saleList, setSaleList] = useState([])
	const [loading, setLoading] = useState(false)
	const [isOneRow, setIsOneRow] = useState(false)
	const [roleSelected, setRoleSelected] = useState([])
	const [selectedRowKeys, setSelectedRowKeys] = useState([]);
	const [selectedRowKeysMain, setSelectedRowKeysMain] = useState([]);
	const cookies = SetStringToObject(Cookies.get("cookie_session"));

	useEffect(() => {
		getMapSaleAreaList()
	}, [])

	const getMapSaleAreaList = async () => {
		try {
			setLoading(true)
			const response = await POST(GET_MAP_SALE_AREA_LIST, { periodDate: getPeriodDate('SQL') });
			const { result, success } = response
			if (success) {
				setDataSource(result)
				setSaleList(result)
				setSaleListOrigin(result)
			}
		} catch (err) {
			useAlert({ type: "catch", content: err.message })
		} finally {
			setLoading(false)
		}
	}

	const onOpenModal = (row) => {
		console.log('row', row)
		setShowModal(true)
		let tempSelect = []
		let tempSelectedRows = []
		let tempNotSelectRows = []
		if (row && JSON.stringify(row) != '{}') {
			setIsOneRow(true)
			setRoleSelected(row)
			saleListOrigin.forEach(el => {
				if (row.childCode.includes(el.roleId)) {
					tempSelect.push(el.key)
					tempSelectedRows.push(el)
				} else {
					tempNotSelectRows.push(el)
				}
			})
			setSaleList([...tempSelectedRows, ...tempNotSelectRows])
		} else {
			setIsOneRow(false)
		}
		setSelectedRowKeys(tempSelect)
	}

	const onSelectChangeModal = (selectedRowDataKeys, selectedRows) => {
		setSelectedRowKeys(selectedRowDataKeys)
	};

	const onSelectChangeMain = (selectedRowDataKeys, selectedRows) => {
		setSelectedRowKeysMain(selectedRowDataKeys)
	};

	const rowsSelectionModal = {
		selectedRowKeys,
		onChange: onSelectChangeModal,
	};

	const rowsSelectionMain = {
		selectedRowKeys: selectedRowKeysMain,
		onChange: onSelectChangeMain,
	};

	const insertUpdateSaleArea = async (obj) => {
		try {
			setLoading(true)
			const response = await POST(INSERT_UPDATE_SALE_AREA, obj);
			const { message, success } = response
			if (success) {
				setShowModal(false)
				setSelectedRowKeysMain([])
				useAlert({ type: "success", title: "ดำเนินการสำเร็จ", content: message, Fn: () => getMapSaleAreaList() })
			}
		} catch (err) {
			useAlert({ type: "catch", content: err.message })
		} finally {
			setLoading(false)
		}
	}

	const onSave = async () => {
		let tempSelect = []
		saleList.forEach(el => {
			if (selectedRowKeys.includes(el.key)) {
				tempSelect.push(el.roleId)
			}
		})
		let roleMain = []
		if (isOneRow) {
			roleMain = [roleSelected.roleId]
		} else {
			let tempSelectMain = []
			dataSource.forEach(el => {
				if (selectedRowKeysMain.includes(el.key)) {
					tempSelectMain.push(el.roleId)
				}
			})
			console.log('tempSelectMain', tempSelectMain)
			roleMain = tempSelectMain
		}

		let obj = {
			roleMain,
			roleChildSelected: tempSelect,
			periodDate: getPeriodDate('SQL'),
			userLogin: encodeString.decode(cookies.ID_User),
		}
		console.log('obj', obj)
		insertUpdateSaleArea(obj)
	}

	return (
		<Spin tip="กำลังโหลดข้อมูล ..." spinning={loading}>
			<RenderMainModal
				show={showModal}
				onCancel={() => {
					setShowModal(false);
				}}
				txtTitle={`บันทึกพื้นที่ ${roleSelected.roleId}`}
				ModalContent={<div>
					<CustomTableAtnd
						HeadTable={ColumnAreaCode}
						DataTable={saleList}
						moreProps={{
							rowSelection: rowsSelectionModal,
							scroll: {
								x: sumColumnWidth(ColumnAreaCode) || 2050,
								y: 500,
							},
							size: "small"
						}}
					/>
					<div style={{ textAlignLast: 'right', marginTop: '15px' }}>
						<Space>
							{useButton({ call: "save", moreProps: { onClick: () => onSave(true) } })}
							{useButton({
								call: "normal",
								fn: () => { },
								moreProps: { label: "ยกเลิก", onClick: () => setShowModal(false) },
							})}
						</Space>
					</div>
				</div>}
				width={800}
			/>
			{/* <Form form={form} onFinish={() => { }} {...configForm}>
				<Card
					title={`กำหนดพื้นที่ยอดขาย (รอบวันที่ ${getPeriodDate('display')})`}
					style={{ marginBottom: 30 }}
					extra={
						<Space>
							{useButton({ call: "search" })}
							{useButton({
								call: "normal",
								fn: () => { },
								moreProps: { label: "ล้างข้อมูล" },
							})}
						</Space>
					}
				>
					{useRenderFormItem({
						constantComponent: ComponentSaleCodeAreaAssignment({
							options: optionSearch,
							handleChange: () => { },
						}),
					})}
				</Card>
			</Form> */}
			<Card
				title={`กำหนดพื้นที่ยอดขาย (รอบวันที่ ${getPeriodDate('display')})`}
			// extra={
			// 	<Space>
			// 		{useButton({
			// 			call: "custom", isDisable: rowsSelectionMain.selectedRowKeys.length == 0, moreProps: { label: "บันทึกพื้นที่ยอดขายหลายรายการ", onClick: () => onOpenModal(false) }
			// 		})}
			// 	</Space>
			// }
			>
				<CustomTableAtnd
					HeadTable={ColumnSaleCodeAreaAssignment({ action: onOpenModal })}
					DataTable={dataSource}
					moreProps={{
						// rowSelection: rowsSelectionMain,
						scroll: {
							x: sumColumnWidth(ColumnSaleCodeAreaAssignment({ action: () => setShowModal(true) })) || 2050,
							y: 500,
						},
						size: "small"
					}}
				/>
			</Card>
		</Spin>
	);
};

export default SaleCodeAreaAssignment;
