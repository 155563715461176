import React, { useState, useEffect } from "react";
import { Form, Card, Row, Col, Spin } from "antd";
import { SelectAntd } from "../select/SelectAntd";
import { OJT, USE_CONNECT_MODE_AUTO } from "../../services/ConnectAPI";
import { browserHistory } from "react-router";
import { useButton } from "../buttons/CustomButtonAntd";
import { GET_UserLogin } from "../../functions/UseFunction";
import { useAlert } from "../modal/CustomAlert";
import { useConfirm } from "../modal/CustomConfirm";
import { useOptionSelect } from "../../constants/OptionSelect";

export const FormEditOJTCourse = (props) => {
  const [form] = Form.useForm();
  const { positionCode } = props.params;
  const { userlogin } = GET_UserLogin();
  //[START] State
  const [changeEditValue, setChangeEditValue] = useState(false);
  const [loadingScreen, setLoadingScreen] = useState(false);

  const optionPosition = useOptionSelect("position");
  const optionTrainings = useOptionSelect("training");
  //[END] State

  // [START] useEffect
  useEffect(() => {
    Promise.all([GET_UPDATE_Course()]);
  }, []);
  // [END] useEffect

  // [START] Handle Form Antd
  const onFinish = (values) => {
    const objectValue = {
      ...values,
      active: changeEditValue.statusActive,
      userlogin,
    };
    useConfirm({
      FnOK: () => PUT_UPDATE_Course(objectValue),
    });
  };
  const onReset = () => {
    GET_UPDATE_Course();
  };
  // [END] Handle Form Antd

  // [START] REST API
  const GET_UPDATE_Course = async () => {
    try {
      setLoadingScreen(true);
      const url = `${USE_CONNECT_MODE_AUTO}${OJT.GET_Search_Course}?positionCode=${positionCode}`;
      const response = await fetch(url);
      const data = await response.json();
      if (response.ok) {
        const Course = data.result;
        if (Course.length !== 0) {
          const { positionNo, training } = Course[0];
          let trainingNos = [];
          training.map((element) => trainingNos.push(element.trainingNo));
          const objectValue = {
            positionCode: positionNo,
            trainingNos,
          };
          form.setFieldsValue(objectValue);
          setChangeEditValue(Course[0]);
          setLoadingScreen(false);
        } else {
          setLoadingScreen(true);
          useAlert({
            type: "error",
            title: `ไม่พบข้อมูล ${positionCode} ในระบบ`,
            content: "กรุณาลองใหม่อีกครั้ง",
            Fn: () => browserHistory.push("/ManageOJT"),
          });
        }
      }
    } catch (err) {
      console.log("Error GET Coruse: ", err);
    }
  };

  const PUT_UPDATE_Course = async (objectValue) => {
    try {
      const url = `${USE_CONNECT_MODE_AUTO}${OJT.PUT_Course}`;
      const response = await fetch(url, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(objectValue),
      });
      const data = await response.json();
      if (response.ok) {
        setLoadingScreen(true);
        useAlert({
          type: "success",
          title: "บันทึกข้อมูลสำเร็จ",
          Fn: () => browserHistory.push("/ManageOJT"),
        });
      } else {
        useAlert({
          type: "error",
          title: "เกิดข้อผิดพลาด",
          content: `${data.message}`,
          Fn: () => setLoadingScreen(false),
        });
      }
    } catch (err) {
      console.log("ERR Update Course: ", err);
    }
  };
  // [END] REST API

  // [START] Functions
  const onChangeValue = (val, name) => {
    if (name === "trainingNos") {
      setChangeEditValue((prev) => ({ ...prev, trainingNos: val }));
    } else {
      setChangeEditValue((prev) => ({
        ...prev,
        [name]: val,
      }));
    }
  };
  // [END] Functions

  return (
    <>
      <Card title="แก้ไขหลักสูตรผูกตำแหน่ง" style={{ marginBottom: 30 }}>
        <Spin tip="Loading..." spinning={loadingScreen}>
          <Form
            form={form}
            onFinish={onFinish}
            labelCol={{
              span: 24,
            }}
            wrapperCol={{
              span: 24,
            }}
          >
            <Row gutter={[16, 16]} justify="start" align="top">
              <Col span={10}>
                <SelectAntd
                  name={`positionCode`}
                  label={`ตำแหน่งงาน`}
                  smgReq={`กรุณาเลือก ตำแหน่งงาน`}
                  placeholder={`เลือก ตำแหน่งงาน`}
                  dropdown={optionPosition}
                  FnOnChange={onChangeValue}
                  required={true}
                  isDisable={true}
                />
              </Col>
              <Col span={12}>
                <SelectAntd
                  name={`trainingNos`}
                  label={`หลักสูตรหลัก`}
                  smgReq={`กรุณาเลือก หลักสูตรหลัก`}
                  placeholder={`เลือก หลักสูตรหลัก`}
                  dropdown={optionTrainings}
                  FnOnChange={onChangeValue}
                  required={true}
                  multipleMode={true}
                />
              </Col>
            </Row>
            <Row
              gutter={[16, 16]}
              style={{ marginTop: 20 }}
              justify="center"
              align="bottom"
            >
              <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                <Form.Item>{useButton({ call: "save" })}</Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                <Form.Item>
                  {useButton({
                    call: "normal",
                    fn: onReset,
                    moreProps: { label: "ล้างข้อมูล" },
                  })}
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Spin>
      </Card>
    </>
  );
};

export default FormEditOJTCourse;
