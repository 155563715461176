import React from 'react'
import { Button, Result } from 'antd';

const PageNotFound = () => {
  return (
    <div>
      <Result
        status="404"
        title="404"
        subTitle="ไม่พบหน้าที่คุณค้นหา"
        extra={
          <Button type="primary" onClick={() => window.location.replace('/')}        >
            {'กลับสู่เมนูหลัก'}
          </Button>
        }
      />
    </div>
  )
}

export default PageNotFound