import React from "react";
import "../index.css";
import "bootstrap/dist/css/bootstrap.css";
import {
  Col,
  Row,
  Button,
  FormGroup,
  Label,
  Card,
  CardHeader,
  CardBody,
  Container,
} from "reactstrap";
import { Modal } from "antd";
import "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css";
import CsvParse from "@vtex/react-csv-parse";
import { loadingPage } from "../components/loadingPage";
import { POST, POST_INSERT_SCORE } from "../service";

class ImportScore_history extends React.Component {
  constructor(props) {
    super(props);
    const { userLogin } = props;
    this.state = {
      upload_open: false,
      arrExcel: [],
      done: true,
      userLogin,
    };
  }

  handleData = (data) => {
    this.setState({ arrExcel: data ? data : [] });
  };

  handleOnError = (err, file, inputElem, reason) => {
    console.log("err", err);
    console.log("file", file);
    console.log("inputElem", inputElem);
    console.log("reason", reason);
  };

  addExcel = async () => {
    try {
      const { arrExcel, userLogin } = this.state;
      if (arrExcel.length === 0) {
        Modal.warning({ title: "กรุณาแนบไฟล์ !!" });
      } else {
        this.setState({ done: false });
        const objectData = {
          data: arrExcel,
          User_login: userLogin,
        };
        const response = await POST(POST_INSERT_SCORE, objectData);
        const { status_api } = response;
        if (+status_api === 1) {
          Modal.success({
            title: "INSERT SUCCESS !!",
            onOk: () => window.location.reload(),
          });
        } else {
          Modal.error({ title: "INSERT ERROR !!" });
        }
      }
    } catch (err) {
      console.log("Error Import Score History: ", err);
    } finally {
      this.setState({ done: true });
    }
  };

  render() {
    const { upload_open } = this.state;
    return (
      <Container>
        {this.state.done ? (
          <Card>
            <CardHeader>
              หน้าจัดการบันทึกข้อมูลผลคะแนนย้อนหลัง (Import Score History)
            </CardHeader>
            <CardBody>
              <Row>
                <Col md={6}>
                  <Button
                    color="info"
                    size="sm"
                    onClick={() => this.setState({ upload_open: !upload_open })}
                  >
                    Import Score CSV
                  </Button>
                </Col>
                <Col md={6}>{upload_open && this._renderShowUpload()}</Col>
              </Row>
            </CardBody>
          </Card>
        ) : (
          <div>{loadingPage} </div>
        )}
      </Container>
    );
  } // Render

  _renderShowUpload = () => {
    const keys = [
      "DocNo",
      "Type",
      "staffCode",
      "staffName",
      "DepNo",
      "Level_Worker",
      "Level_WorkerDes",
      "Position_No",
      "PositionNameEN",
      "PositionNameTH",
      "Score1_1_1",
      "Score1_1_2",
      "Score1_2_1",
      "Score1_2_2",
      "Score2_1",
      "Score2_2",
      "Score2_3",
      "Score2_4",
      "Score2_6",
      "Score2_7",
      "Score2_8",
      "Score2_9",
      "Score2_10",
      "scorePart1",
      "scorePart2",
      "totalScore",
    ];
    return (
      <FormGroup row style={{ marginLeft: "25px", marginBottom: "25px" }}>
        <Label style={{ color: "red", marginRight: "20px" }}>
          **รองรับเฉพาะไฟล์ .csv เท่านั้น
        </Label>
        <CsvParse
          keys={keys}
          onDataUploaded={this.handleData}
          onError={this.handleOnError}
          render={(onChange) => (
            <input type="file" onChange={onChange} accept=".csv" />
          )}
        />
        <Button
          color="success"
          size="sm"
          style={{ marginLeft: "20px" }}
          onClick={this.addExcel}
        >
          Upload
        </Button>
      </FormGroup>
    );
  };
}
export default ImportScore_history;
