import React, { useState } from "react";
import { Form, Card, Spin, Space } from "antd";
import { optionSelectLocation, useOptionSelect } from "../../constants/OptionSelect";
import { toUndefined, sumColumnWidth, GET_UserLogin } from "../../functions/UseFunction";
import { useAlert } from "../modal/CustomAlert";
import { configForm, WORDS } from "../../constants/ConstantComponent";
import { useRenderFormItem } from "../RenderComponents";
import CustomTableAtnd from "../tables/CustomTableAtnd";
import { useNotification } from "../notification/NotificationWithIcon";
import { POST, POST_SELECT_DATA_BONUS } from "../../../service";
import { useButton } from "../buttons/CustomButtonAntd";
import { useExportExcel } from "../../services/ExportExcel";
import { Header_ReportBonus } from "../../constants/HeaderExcel";
import { useFixedQueryString } from "../../functions/UseMakeQueryString";
import { ColumnReportBonus } from "../../constants/Reports/Columns/ColumnReportBonus";
import { ComponentReportBonus, DEFAULT_REPORT_BONUS } from "../../constants/Reports/Components/ComponentReportBonus";
import { useText } from '../typography/Typography';

const FormReportBonus = () => {
    const { location: locationLogin } = GET_UserLogin();
    const [formReportBonus] = Form.useForm();
    const [ChangeValue, setChangeValue] = useState({ ...DEFAULT_REPORT_BONUS, location: locationLogin === "FAC" ? "FAC" : undefined });
    const [dataTable, setDataTable] = useState([]);
    const [loadingScreen, setLoadingScreen] = useState(false);

    let optionSearch = {
        company: useOptionSelect("company"),
        location: optionSelectLocation,
        depNo: useOptionSelect("department", useFixedQueryString(ChangeValue, ["companyCode", "location"])),
        subDepNo: useOptionSelect("subDepartment", useFixedQueryString(ChangeValue, ["company", "location", "depNo"])),
        userId: useOptionSelect("empAll", useFixedQueryString(ChangeValue, ["companyCode", "location"])),
        payrollGroup: useOptionSelect("payrollGroup"),
    }

    const onFinish = (values) => {
        //console.log(values)
        const { company, location, depNo, subDepNo, startDate, toDate, userId, payrollGroup } = values
        let objectBody = {
            company: company || "",
            location: location || "",
            depNo: depNo || "",
            subDepNo: subDepNo || "",
            startDate: startDate ? startDate.format("YYYY-MM-DD") : "",
            toDate: toDate ? toDate.format("YYYY-MM-DD") : "",
            userId: userId || "",
            payrollGroup: payrollGroup || "",
        }
        selectDataBonus(objectBody);
    };

    const onReset = () => {
        formReportBonus.resetFields();
        setDataTable([]);
        setChangeValue({ ...DEFAULT_REPORT_BONUS, location: locationLogin === "FAC" ? "FAC" : undefined });
    };

    const selectDataBonus = async (objectValue) => {
        try {
            setLoadingScreen(true);
            setDataTable([]);
            const response = await POST(POST_SELECT_DATA_BONUS, objectValue);
            const { result } = response;
            let mapResult = [];
            if (result.length !== 0) {
                mapResult = result.map((row, index) => ({
                    key: `${row.userId}_${index}`,
                    ...row,
                }));
            } else {
                useNotification({ type: "info", title: "ไม่พบข้อมูล" });
            }
            setDataTable(mapResult);
        } catch (err) {
            useAlert({ type: "catch", content: err.message })
            console.log("Error Search Report Bonus: ", err);
        } finally {
            setLoadingScreen(false);
        }
    };

    const onChangeValue = (name, value) => {
        let setUndefined = {};
        let setName = {};
        switch (name) {
            case "company":
                setName = { companyCode: value };
                if (locationLogin === "FAC") {
                    setUndefined = toUndefined(["depNo", "subDepNo", "userId"]);
                } else {
                    setUndefined = toUndefined(["location", "depNo", "subDepNo", "userId"]);
                }
                break;
            case "location":
                setUndefined = toUndefined(["depNo", "subDepNo", "userId"]);
                break;
            case "depNo":
                setUndefined = toUndefined(["subDepNo"]);
                break;
        }
        formReportBonus.setFieldsValue(setUndefined);
        setDataTable([]);
        setChangeValue((prev) => ({
            ...prev,
            ...setUndefined,
            ...setName,
            [name]: value,
        }));
    };

    return (
        <Spin tip={WORDS.loadingSpin} spinning={loadingScreen}>
            <Form form={formReportBonus} onFinish={onFinish} {...configForm} initialValues={{ location: locationLogin === "FAC" ? "FAC" : undefined }}>
                <Card
                    title="ค้นหารายงานโบนัส"
                    style={{ marginBottom: 30 }}
                    extra={
                        <Space>
                            {useButton({ call: "search" })}
                            {useButton({
                                call: "normal",
                                fn: onReset,
                                moreProps: { label: "ล้างข้อมูล" },
                            })}
                        </Space>
                    }
                >
                    {useRenderFormItem({
                        constantComponent: ComponentReportBonus({
                            options: optionSearch,
                            handleChange: onChangeValue,
                            isDisable: {
                                location: locationLogin === "FAC" || false,
                            }
                        }),
                    })}
                </Card>
                {dataTable.length !== 0 && (
                    <Card
                        title="รายงานโบนัส"
                        style={{ marginBottom: 30 }}
                        extra={
                            useExportExcel({
                                header: Header_ReportBonus,
                                dataset: dataTable,
                                filename: "รายงานโบนัส",
                            })
                        }
                    >
                        {useText({
                            type: "danger",
                            text: "* หมายเหตุ อายุงาน เกิดจากการคำนวณตามที่เลือกช่วงวันที่สิ้นสุด และในกรณีที่พนักงานพ้นสภาพแล้วระบบจะคำนวณจากวันที่เริ่มงานถึงวันที่พ้นสภาพ",
                        })}
                        <CustomTableAtnd
                            HeadTable={ColumnReportBonus}
                            DataTable={dataTable}
                            moreProps={{
                                style: { marginTop: 30 },
                                scroll: {
                                    x: sumColumnWidth(ColumnReportBonus) || 2850,
                                    y: 500,
                                },
                            }}
                        />
                    </Card>
                )}
            </Form>
        </Spin>
    )
}

export default FormReportBonus
