import React from "react";
import { Typography } from "antd";

export const useText = ({
  text = text || "Empty",
  type = type || "",
  icon,
  moreProps = moreProps || {},
}) => {
  return (
    <Typography.Text type={type} {...moreProps}>
      {icon || null}
      {text}
    </Typography.Text>
  );
};

export const useTitle = ({ text = text || "Empty", level = level || 0 }) => {
  return <Typography.Title level={level}>{text}</Typography.Title>;
};
