import { useState, useEffect, useRef } from "react";
import { GET, GET_DETAIL_BENEFITS } from "../../../../service";
import { documentOptionList } from "../constants/documentOptionList";
import { useGetFormList } from "./useGetFormList";
import {
  generalDetailTH,
  generalDetailEN,
  generalDetailAll,
} from "../forms/generalDetail";

export const useGetDocument = ({ setValue }) => {
  const { getFormInputList } = useGetFormList({});
  const [loadingDocument, setLoadingDocument] = useState(false);
  const [documentList, setDocumentList] = useState([]);
  const [fileList, setFileList] = useState([]);
  const [allData, setAddData] = useState(null);
  const [inputState, setInputState] = useState({});
  const docID = useRef(0);

  useEffect(() => {
    onFetchDocumentDetail();
  }, []);

  useEffect(() => {
    setDefaultValue(allData);
  }, [allData]);

  const onFetchDocumentDetail = async () => {
    try {
      setLoadingDocument(true);
      const queryParams = new URLSearchParams(window.location.search);
      docID.current = queryParams.get("docID");
      const respond = await GET(
        `${GET_DETAIL_BENEFITS}?docID=${docID.current}`
      );
      const data = respond.result.data;
      validateDocumentList(data.documentName); //Validate data form Backend
      setAddData(() => data);
      setDocumentList(() => data.documentName);
      //await setDefaultValue(data);
      setFileList(() => data.attachment);
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingDocument(false);
    }
  };

  const setDefaultValue = async (data) => {
    //TODO change the use of setValue to send the default value to Form
    if (!data) return;
    const { defaultValue } = data;
    const generalFormList = [
      ...generalDetailTH(),
      ...generalDetailEN(),
      ...generalDetailAll(),
    ];
    for (const generalForm of generalFormList) {
      //set all default general value
      setValue(generalForm.ref, defaultValue[generalForm.ref]);
    }
    setValue(`signer`, `DP55012`); //TODO refactor to use API data
    let inputState = {};
    for (const document of data.documentName) {
      inputState = { ...inputState, [document.docName]: {} };
      setValue(`isSelected_${document.docName}`, true); //set all to selected
      inputState[document.docName].isSelected = true;
      const formList = getFormInputList(document.docName).filter(
        (e) => e.relation
      );

      for (const form of formList) {
        //set all default Data
        setValue(form.ref, document[form.key]);
      }
      // if (formList.filter((e) => e.relation == "isTransfer").length > 1) { //* uncomment this if you want to automatic check isTransfer
      //   setValue(`isTransfer_${document.docName}`, true);
      //   inputState[document.docName].isTransfer = true;
      // }
      // if (formList.filter((e) => e.relation == "showSalary").length > 0) { //* uncomment this if you want to automatic check isSalary
      //   setValue(`showSalary_${document.docName}`, true);
      //   inputState[document.docName].showSalary = true;
      // }

      if (formList.filter((e) => e.relation == "isApex").length > 0) {
        setValue(`isApex_${document.docName}`, true);
      }
    }
    setInputState(() => inputState);
  };

  const changeInputState = (docName, inputName, state) => {
    setInputState((prev) => ({
      ...prev,
      [docName]: {
        ...prev[docName],
        [inputName]: state,
      },
    }));
  };

  const validateDocumentList = (documentList) => {
    for (const document of documentList) {
      const documentOptions = documentOptionList[document.docName];
      if (!documentOptions) {
        console.error("documentList is not valid! :", document.docName);
      }
    }
  };

  return {
    loadingDocument,
    documentList,
    fileList,
    docID,
    inputState,
    changeInputState,
  };
};
