import React from 'react'
import { PATHNAME } from '../../../constants/enums/pathname'
import DashboardContract from '../../componentsContract/dashboardContract'
import DashboardEmpContract from '../../componentsContract/dashboardEmpContract'
import DashboardTrackContract from '../../componentsContract/dashboardTrackContract'
import DashboardBenefitContract from '../../componentsContract/dashboardBenefitContract'

export function renderTabsWithPathname(_pathname) {
    switch (_pathname) {
        default:
            return [{ tab: 'Tab', content: '...' }]
    }
}
export function renderTabsAdminWithPathname(_pathname, _permission, _idTab) {
    switch (_pathname) {
        // case PATHNAME.DASHBOARD_FOLLOWUP_GURANTEE: return [{ tab: 'รายงานติดตามเอกสารค้ำประกัน', content: <DashboardContract pathname={_pathname} /> }]
        case PATHNAME.DASHBOARD_PROBATION_CONTRACT: return [{ tab: 'แบบประเมินทดลองงาน (สัญญาจ้าง)', content: <DashboardContract pathname={_pathname} /> }]
        case PATHNAME.DASHBOARD_PROBATION_EMP_CONTRACT: return [
            { tab: 'อยู่ระหว่างดำเนินการ - ผ่านทดลองงาน', content: <DashboardEmpContract idTab={_idTab} pathname={_pathname} /> },
            { tab: 'ไม่ผ่านทดลองงาน', content: <DashboardEmpContract idTab={_idTab} pathname={_pathname} /> },
        ]
        case PATHNAME.DASHBOARD_PROBATION_EMP_CONTRACT_NEW: return [
            { tab: 'อยู่ระหว่างดำเนินการ - ผ่านทดลองงาน', content: <DashboardEmpContract idTab={_idTab} pathname={_pathname} /> },
            { tab: 'ไม่ผ่านทดลองงาน', content: <DashboardEmpContract idTab={_idTab} pathname={_pathname} /> },
        ]
        case PATHNAME.DASHBOARD_TRACK_CONTRACT: return [{ tab: 'ค้างรับเอกสารทดลองงาน (สัญญาจ้าง)', content: <DashboardTrackContract pathname={_pathname} /> }]
        case PATHNAME.DASHBOARD_BENEFIT_CONTRACT: return [{ tab: 'ค้างรับสวัสดิการพนักงานใหม่ (สัญญาจ้าง)', content: <DashboardBenefitContract pathname={_pathname} /> }]
        default:
            break;
    }
}

export const TABS_CONTRACT = ({ pathname, permission, idTab }) => {
    // console.log({ permission })
    // switch (permission) {
    // case 'HR':
    // case 'HR-HeadDep':
    // case 'HR-Admin':
    // case 'HR-AdminOff':
    // case 'HR-Ass':
    // case 'HR-Super':
    // case 'MD-Super':

    return renderTabsAdminWithPathname(pathname, permission, idTab)
    // default:
    //     break
    // return renderTabsWithPathname(pathname)
    // }a
}