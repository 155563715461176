import React from 'react';
import '../index.css';
import "bootstrap/dist/css/bootstrap.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { MdAdd } from 'react-icons/md';
import { Col, Row, Button, Form, FormGroup, Label, Input, Card, CardHeader, CardBody, Container } from 'reactstrap';
import { API_URL } from '../components/connectAPI'
import * as NumericInput from "react-numeric-input";
import { loadingPage } from '../components/loadingPage'
import { Checkbox, Modal } from 'antd';
import { GET, GET_SELECT_OPTION_ALL } from '../service';

// const api = connectAPI.API_URL


class InsertEmployee extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			startDate: '',
			endDate: '',
			showDropdown_Position: [],
			showDropdown_Location: [],
			showDropdown_Department: [],
			showDropdown_subDepartment: [],
			showDropdown_StatusWorker: [],
			showDropdown_workShift: [],
			showDropdown_Flow: [],
			showDropdown_View: [],
			// showDropdown_Position: '',
			userCode: '',
			userName: '',
			FirstName_EN: '',
			LastName_EN: '',
			Email: '',
			getCompany: '',
			fields: {},
			followupGuarantee: false,
			errors: {},
			done: undefined,
			timeScan: '',
			userLogin: this.props.userLogin,
		};
		this.strDateChange = this.strDateChange.bind(this);
		this.endDateChange = this.endDateChange.bind(this);
		this.saveWorker = this.saveWorker.bind(this)
	}

	strDateChange(date) {
		let fields = this.state.fields;
		fields['startDate'] = date;
		this.setState({
			startDate: date, fields
		});

	}

	endDateChange(date) {
		this.setState({
			endDate: date
		});
	}


	componentDidMount() {
		this.getSelectOptionAll()
	}

	getSelectOptionAll = async () => {
		try {
			let res = await GET(GET_SELECT_OPTION_ALL())
			const { result, success } = res
			if (success) {
				let options = {
					'department': [],
					'workShift': [],
					'statusWorker': [],
					'permissionview': [],
					'location': []
				}
				let lists = [
					'department',
					'workShift',
					'statusWorker',
					'permissionview',
					'location'
				]
				lists.forEach(field => {
					let arrOption = []
					switch (field) {
						case 'statusWorker':
						case 'location':
							result[field].map(el => {
								arrOption.push({ label: el.description, value: el.code })
							})
							options = { ...options, [field]: arrOption }
							break;
						case 'department':
							result[field].map(el => {
								arrOption.push({ label: el.depNameTH, value: el.depNo })
							})
							options = { ...options, [field]: arrOption }
							break;
						case 'permissionview':
							result[field].map(el => {
								arrOption.push({ label: el.description, value: el.flowID })
							})
							options = { ...options, [field]: arrOption }
							break;
						case 'workShift':
							result[field].map(el => {
								arrOption.push({ label: el.shiftCode + " :: " + el.workShiftNameTH, value: el.shiftCode })
							})
							options = { ...options, [field]: arrOption }
							break;
					}
				})

				this.setState({
					showDropdown_Department: options.department,
					showDropdown_StatusWorker: options.statusWorker,
					showDropdown_Location: options.location,
					showDropdown_View: options.permissionview,
					showDropdown_workShift: options.workShift,
					done: true,
				})
				this.selectDataUser();
			}
		} catch (e) {
			console.warn(e);
		}
	}

	saveWorker(event) {
		event.preventDefault();
		// console.log(event.target)
		if (this.handleValidation()) {

			//this.setState({ done: undefined })
			const data = new FormData(event.target);

			var entries = data.entries();
			var current, item, key, output, value;
			output = {};
			while (item = entries.next().value) {
				// assign to variables to make the code more readable.
				key = item[0];
				value = item[1];
				// Check if key already exist
				if (Object.prototype.hasOwnProperty.call(output, key)) {
					current = output[key];
					if (!Array.isArray(current)) {
						// If it's not an array, convert it to an array.
						current = output[key] = [current];
					}
					current.push(value); // Add the new value to the array.
				} else {
					output[key] = value;
				}
			}
			// console.log('output', output)
			let payload = {
				...output,
				ID_User: this.state.userCode,
				followupGuarantee: this.state.followupGuarantee ? '1' : '0',
				oneTimeScan: this.state.oneTimeScan ? '1' : '0'
			}

			fetch(API_URL + '/HRM_API/insert_worker', {
				method: 'POST',
				headers: {
					//'content-type': 'application/x-www-form-urlencoded',
					'Content-Type': 'application/json',
					Accept: 'application/json'
				},
				body: JSON.stringify({ data: payload }), //  ,
			}).then((Response) => Response.json())
				.then(function (results) {
					if (results.status_api === 1) {
						// alert("Insert Success!! \n" + results.userID + "  Username = " + results.userName + " Password = " + results.password);
						// // this.setState({ done: true })
						// window.location.reload();//browserHistory.push("/report");
						Modal.success({
							title: "Insert Success!! \n" + results.userID + "  Username = " + results.userName + " Password = " + results.password,
							onOk: () => window.location.reload(),
						})

					} else {
						// this.setState({ done: true })
						// alert("Insert Error!!");
						Modal.error({ title: "Insert Error!!" })
					}

				}).catch(ex => {
					console.log("catch : " + ex);
				})
		} else {
			// alert("กรุณาระบุข้อมูลให้ครบถ้วน")
			Modal.warning({ title: "กรุณาระบุข้อมูลให้ครบถ้วน" })
			// console.log(this.state.errors)
		}

	}

	onChangeCompany = (e) => {
		var company = e.target.value
		let fields = this.state.fields;
		fields['company'] = company;

		var API = API_URL + "/HRM_API/select_genUserCode?company=" + company
		// console.log(API);
		fetch(API).then((res) => res.json()).then((results) => {
			//console.log('results');
			// console.log(results['userCode']);
			fields['ID_User'] = results['userCode'];
			this.setState({
				userCode: results['userCode'],
				getCompany: company,
				fields
			}, () => this.setUserName())


		}).catch(ex => {
			console.log("error gate ttt");

		})


	}


	onChangeDep = (e) => {
		var DepartmentNo = e.target.value
		let fields = this.state.fields;
		fields['DepartmentNo'] = DepartmentNo;

		//  this.setState({ done: undefined })
		var API = API_URL + "/HRM_API/select_optionFlowBoss?dep=" + DepartmentNo
		//  console.log(API);
		fetch(API).then((res) => res.json()).then((results) => {
			// console.log(results)
			var arrFlow = [];
			var arrPosition = [];
			var arrSubDep = [];

			if (results['flow'] !== 0) {
				results['flow'].forEach(el => {
					arrFlow.push({
						value: el.ID_Flow + '=)' + el.Approvers_No,
						label: el.Flow_Name
					})
				})

			}

			if (results['position'] !== 0) {
				results['position'].forEach(el => {
					arrPosition.push({
						value: el.PositionCode,
						label: el.PositionCode + "::" + el.PositionNameEN
					})
				})

			}


			if (results['subDep'] !== 0) {
				results['subDep'].forEach(el => {
					arrSubDep.push({
						value: el.subDepNo,
						label: el.subDepNo + "::" + el.subDepName
					})
				})

			}

			this.setState({
				showDropdown_Flow: arrFlow,
				showDropdown_Position: arrPosition,
				showDropdown_subDepartment: arrSubDep,
				fields,
				// done: true
			})

		}).catch(ex => {
			console.log("error gate ttt");

		})


	}

	onChangePosition = (e) => {
		var Position = e.target.value
		let fields = this.state.fields;
		fields['Position_No'] = Position;
		this.setState({
			fields
		})
	}

	onChangeOneTimeScan = (e) => {
		let fields = this.state.fields;

		let a = (e.target.checked) ? '1' : '0'

		// console.log('a', a)
		fields['oneTimeScan'] = a
		this.setState({
			oneTimeScan: e.target.checked,
			fields
		})

		// console.log('fields', fields)
	}


	onChangeSubDep = (e) => {
		let subDep = e.target.value
		let fields = this.state.fields;
		fields['subDep'] = subDep;

		var API = API_URL + "/HRM_API/cent/selectPosition?positionNo=&&subDepNo=" + subDep
		// console.log(API);
		fetch(API).then((res) => res.json()).then((results) => {
			let data = results.result
			var arrPosition = [];

			//console.log('data', data)
			if (data.length !== 0) {
				data.forEach(el => {
					arrPosition.push({
						value: el.PositionCode,
						label: el.PositionCode + "::" + el.PositionNameEN
					})
				})

			}

			this.setState({
				showDropdown_Position: arrPosition,
				fields,
				// done: true
			})
			//  console.log('arrPosition', arrPosition)


		}).catch(ex => {
			console.log("error gate ttt");

		})

	}


	onChangeTitle = (e) => {
		var title = e.target.value
		let fields = this.state.fields;
		fields['title'] = title;
		this.setState({
			fields
		})
	}

	onChangeFlowID = (e) => {
		var FlowID = e.target.value
		let fields = this.state.fields;
		fields['refFlow_HRM'] = FlowID;
		this.setState({
			fields
		})
	}

	onChangeWorkshift = (e) => {
		let fields = this.state.fields;
		fields['shiftCode'] = e.target.value;
		this.setState({
			fields
		})
	}

	onChangeTimeScan = (e) => {
		let fields = this.state.fields;
		fields['timeScan'] = e.target.value;
		this.setState({
			fields,
			timeScan: e.target.value
		})
	}


	onChangeFlowView = (e) => {
		var FlowView = e.target.value
		let fields = this.state.fields;
		fields['refFlow_view'] = FlowView;
		this.setState({
			fields
		})
	}


	onChangeuserCode = (e) => {
		let fields = this.state.fields;
		fields['ID_User'] = e.target.value;
		let userId = e.target.value
		var API = API_URL + "/HRM_API/cent/selectUserId?userId=" + userId
		// console.log(API);
		fetch(API).then((res) => res.json()).then((results) => {
			let data = results.result
			// console.log('results', data.length)
			if (data.length !== 0) {
				//console.log('ggfg')
				// alert('มีผู้ใช้รหัสพนักงานนี้ในระบบแล้ว !!')
				Modal.warning({
					title: "มีผู้ใช้รหัสพนักงานนี้ในระบบแล้ว",
					content: "กรุณาเลือกบริษัทเดิมซ้ำอีกครั้ง เพื่อให้ระบบ Gen รหัสพนักงานใหม่ !!",
					onOk: () => null,
				})
			}
		}).catch(ex => {
			console.log("error gate ttt");

		})
		this.setState({ userCode: e.target.value, fields });
		// console.log(e.target.value)

	}


	onChangefirstNameEN = (e) => {
		let fields = this.state.fields;
		fields['FirstName_EN'] = e.target.value;
		this.setState({ FirstName_EN: e.target.value, fields },
			() => {
				this.setUserName()
			}) //ใช้ call Back เพราะมัน Set State ค่าไม่ทัน
		// this.setUserName()
		// console.log(e.target.value)
	}

	onChangeLastNameEN = (e) => {
		//var _firstNameEN = this.state.firstNameEN
		let fields = this.state.fields;
		fields['LastName_EN'] = e.target.value;
		this.setState({ LastName_EN: e.target.value, fields },
			() => {
				this.setUserName()
			});
		// console.log(e.target.value)
	}


	setUserName = () => {
		// console.log('hh')
		var _firstNameEN = this.state.FirstName_EN
		var _LastNameEN = (this.state.LastName_EN).substring(0, 3)
		var _userName = (_firstNameEN + '.' + _LastNameEN).toLowerCase()
		var getCompany = this.state.getCompany;
		let fields = this.state.fields;
		let local = '@dappmaker.co.th'
		let _mail = ''


		fields['Username'] = _userName;

		if (getCompany !== 'BD') {
			_mail = _userName + local
		}



		this.setState({
			userName: _userName,
			Email: _mail,
			fields
		});
	}

	handleValidation() {
		let fields = this.state.fields;
		//console.log(fields)
		let errors = {};
		let formIsValid = true;

		if (!fields["company"]) {
			formIsValid = false;
			errors["company"] = "Cannot be empty";
		}


		if (!fields["ID_User"]) {
			formIsValid = false;
			errors["ID_User"] = "Cannot be empty";
		}

		if (!fields["startDate"]) {
			formIsValid = false;
			errors["startDate"] = "Cannot be empty";
		}

		if (!fields["title"]) {
			formIsValid = false;
			errors["title"] = "Cannot be empty";
		}

		if (!fields["FirstName"]) {
			formIsValid = false;
			errors["FirstName"] = "Cannot be empty";
		}

		if (!fields["LastName"]) {
			formIsValid = false;
			errors["LastName"] = "Cannot be empty";
		}

		if (!fields["FirstName_EN"]) {
			formIsValid = false;
			errors["FirstName_EN"] = "Cannot be empty";
		}


		if (!fields["LastName_EN"]) {
			formIsValid = false;
			errors["LastName_EN"] = "Cannot be empty";
		}

		if (!fields["Username"]) {
			formIsValid = false;
			errors["Username"] = "Cannot be empty";
		}

		if (!fields["Personal_id"]) {
			formIsValid = false;
			errors["Personal_id"] = "Cannot be empty";
		}

		if (!fields["JG"]) {
			formIsValid = false;
			errors["JG"] = "Cannot be empty";
		}

		// if (!fields["Position"]) {
		//     formIsValid = false;
		//     errors["Position"] = "Cannot be empty";
		// }

		if (!fields["DepartmentNo"]) {
			formIsValid = false;
			errors["DepartmentNo"] = "Cannot be empty";
		}

		if (!fields["shiftCode"]) {
			formIsValid = false;
			errors["shiftCode"] = " shiftCode Cannot be empty";
		}

		if (!fields["timeScan"]) {
			formIsValid = false;
			errors["timeScan"] = " timeScan Cannot be empty";
		}

		// if (!fields["FlowID"]) {
		//     formIsValid = false;
		//     errors["FlowID"] = "Cannot be empty";
		// }

		// if (!fields["refFlow_view"]) {
		//     formIsValid = false;
		//     errors["refFlow_view"] = "Cannot be empty";
		// }

		// console.log('errors', errors)
		this.setState({ errors: errors });
		return formIsValid;
	}

	handleChange(field, e) {
		let fields = this.state.fields;
		// console.log(fields)
		fields[field] = e.target.value;
		this.setState({ fields });
	}

	handleChangeNum(valueAsNumber, field) {
		let fields = this.state.fields;
		// console.log(fields)
		// console.log(valueAsNumber)
		fields[field] = valueAsNumber;
		this.setState({ fields });
	}

	handleChangeEmail = (e) => {
		this.setState({
			Email: e.target.value
		});
	}


	render() {

		return (
			<Container>
				{this.state.done ? (
					<Card>
						<CardHeader><MdAdd className="margin-icon" />เพิ่มพนักงานใหม่</CardHeader>
						<CardBody>
							<Form onSubmit={this.saveWorker} autoComplete="off">
								<Row>
									<Col md={6}>
										<FormGroup>
											<Label for="company"><span className="red"> * </span>บริษัท</Label>
											<Input type="select" name="company" id="company" ref="company" onChange={this.onChangeCompany}>
												<option value="">กรุณาเลือกบริษัท</option>
												<option value="DP">Dplus Intertrade</option>
												<option value="DT">Dplus Together</option>
												<option value="ZT">Zetta Soft</option>
												<option value="FI">FIN</option>
												<option value="DC">Digital Commerce</option>
												<option value="BD">Better Dose</option>
												<option value="SK">SK Production </option>
											</Input>
										</FormGroup>
									</Col>
									<Col md={6}>
										<FormGroup>
											<Label for="location">สถานที่</Label>
											<Input type="select" ref="Location" name="Location" id="Location" >
												{/* <option value="">กรุณาเลือกแผนก</option> */}
												{this.state.showDropdown_Location.map(el => {
													return <option value={el.value}>{el.label}</option>
												})}
											</Input>
											{/* <Input type="text" name="Location_des" id="Location_des" value="Office" disabled />
                                            <Input type="hidden" name="Location" id="Location" value="OFF" /> */}

										</FormGroup>
									</Col>
								</Row>
								<Row>
									<Col md={6}>
										<FormGroup>
											<Label for="EmployeeID"><span className="red"> * </span>รหัสพนักงาน</Label>
											<Input type="text" ref="ID_User" name="ID_User" id="ID_User" disabled={true} value={this.state.userCode} onChange={this.onChangeuserCode} />
											<Input type="hidden" name="userLogin" id="userLogin" value={this.state.userLogin} />

										</FormGroup>
									</Col>
									<Col md={6}>
										<FormGroup>
											<Label for="LNameEN"><span className="red"> * </span>สถานะ</Label>
											<Input type="select" name="Status_Worker" id="Status_Worker" >
												{/* <option value="">กรุณาเลือกสถานะ</option> */}
												{this.state.showDropdown_StatusWorker.map(el => {
													return <option value={el.value}>{el.label}</option>
												})}
											</Input>
										</FormGroup>
									</Col>
								</Row>
								<Row>
									<Col md={6}>
										<FormGroup>
											<Label for="StartDate"><span className="red"> * </span> วันที่เริ่มทำงาน</Label><br></br>
											<DatePicker className="form-control ignore-react-onclickoutside" placeholderText="เลือกวันที่เริ่มงาน" name="StartDateJob"
												dateFormat="yyyy/MM/dd"
												selected={this.state.startDate}
												onChange={this.strDateChange}
												ref="startDate"
											/>
										</FormGroup>
									</Col>


									<Col md={6}>
										<FormGroup>
											<Label for="EndDateJob">เลือกวันที่สิ้นสุดการทำงาน</Label><br></br>
											<DatePicker className="form-control ignore-react-onclickoutside" placeholderText="เลือกวันที่สิ้นสุด" name="EndDateJob"
												dateFormat="yyyy/MM/dd"
												selected={this.state.endDate}
												onChange={this.endDateChange}
											/>
										</FormGroup>
									</Col>


								</Row>
								<Row>
									<Col md={2}>
										<FormGroup>
											<Label for="FNameTH"><span className="red"> * </span> คำนำหน้าชื่อ </Label>
											<Input type="select" ref="title" name="title" id="title" onChange={this.onChangeTitle}>
												<option value="">----โปรดระบุ-----</option>
												<option value="นาย">นาย</option>
												<option value="นางสาว">นางสาว</option>
												<option value="นาง">นาง</option>
												<option value="ดร.">ดร.</option>
												<option value="ว่าที่ร้อยตรี">ว่าที่ร้อยตรี</option>
												<option value="ว่าที่ร้อยโท">ว่าที่ร้อยโท</option>
												<option value="ว่าที่ร้อยเอก">ว่าที่ร้อยเอก</option>
												<option value="พลตำรวจเอก">พลตำรวจเอก</option>
											</Input>
										</FormGroup>
									</Col>
									<Col md={4}>
										<FormGroup>
											<Label for="FNameTH"><span className="red"> * </span> ชื่อ (ภาษาไทย)</Label>
											<Input ref="FirstName" type="text" name="FirstName" id="FirstName" onChange={this.handleChange.bind(this, "FirstName")} value={this.state.fields["FirstName"]} />
										</FormGroup>
									</Col>
									<Col md={6}>
										<FormGroup>
											<Label for="LastName"><span className="red"> * </span>นามสกุล (ภาษาไทย)</Label>
											<Input ref="LastName" type="text" name="LastName" id="LastName" onChange={this.handleChange.bind(this, "LastName")} value={this.state.fields["LastName"]} />
										</FormGroup>
									</Col>
								</Row>

								<Row>
									<Col md={6}>
										<FormGroup>
											<Label for="FirstName_EN"><span className="red"> * </span>ชื่อ (ภาษาอังกฤษ)</Label>
											<Input ref="FirstName_EN" type="text" name="FirstName_EN" id="FirstName_EN" onChange={this.onChangefirstNameEN} />
										</FormGroup>
									</Col>
									<Col md={6}>
										<FormGroup>
											<Label for="LNameEN"><span className="red"> * </span>นามสกุล (ภาษาอังกฤษ)</Label>
											<Input ref="LastName_EN" type="text" name="LastName_EN" id="LastName_EN" onChange={this.onChangeLastNameEN} />
										</FormGroup>
									</Col>
								</Row>

								<Row>
									<Col md={6}>
										<FormGroup>
											<Label for="Username"><span className="red"> * </span>Username</Label>
											<Input ref="Username" type="text" name="Username" id="Username" value={this.state.userName} readOnly />
										</FormGroup>
									</Col>
									<Col md={6}>
										<FormGroup>
											<Label for="Personal_id"><span className="red"> * </span>เลขประจำตัวประชาชน</Label>
											{/* <NumericInput className="form-control" ref="Personal_id" name="Personal_id" id="Personal_id" style={false}
                                                onChange={valueAsNumber => this.handleChangeNum(valueAsNumber, "Personal_id")} value={this.state.fields["Personal_id"]}
                                            /> */}
											<Input className="form-control" ref="Personal_id" name="Personal_id" id="Personal_id" maxLength={13} min={0}

												onChange={(e) => {
													let lengthValue = e.target.value.length
													if (lengthValue <= 13) {
														if (!isNaN(parseFloat(e.target.value)) && isFinite(e.target.value)) {
															this.handleChangeNum(e.target.value, "Personal_id")
														}
														// else {
														//     this.handleChangeNum('', "Personal_id")
														// }
													}
												}
													// valueAsNumber => this.handleChangeNum(valueAsNumber, "Personal_id")
												}
												value={this.state.fields["Personal_id"]}
											/>
										</FormGroup>
									</Col>

								</Row>
								<Row>
									<Col md={6}>
										<FormGroup>
											<Label><span className="red"> * </span> ชื่อเล่น</Label>
											<Input type="text" name="NickName" id="NickName" />
										</FormGroup>
									</Col>

									<Col md={6}>
										<FormGroup>
											<Label for="Tel_No">เบอร์ติดต่อ</Label>
											<Input name="Tel_No" id="Tel_No" value={this.state.Tel_No} onChange={event => this.setState({ Tel_No: event.target.value.replace(/\D/, '') })} />
										</FormGroup>
									</Col>

								</Row>
								<Row>

									<Col md={6}>
										<FormGroup>
											<Label><span className="red"> * </span>ประเภทพนักงาน</Label>
											<Input type="select" ref="manType" name="manType" id="manType" onChange={this.handleChange.bind(this, "manType")}>
												<option value="">กรุณาเลือก</option>
												<option value="new">อัตราใหม่</option>
												<option value="instead">ทดแทน</option>
											</Input>
										</FormGroup>
									</Col>
									<Col md={6}>
										<FormGroup>
											<Label> size เสื้อพนักงาน</Label>
											<Input type="select" ref="shirtSize" name="shirtSize" id="shirtSize" onChange={this.handleChange.bind(this, "shirtSize")}>
												<option value="">กรุณาเลือก</option>
												<option value="SS">SS</option>
												<option value="S">S</option>
												<option value="M">M</option>
												<option value="L">L</option>
												<option value="XL">XL</option>
												<option value="2XL">2XL</option>
											</Input>
										</FormGroup>
									</Col>
								</Row>

								<Row>
									<Col md={6}>
										<FormGroup>
											<Label> E-Mail </Label>
											<Input type="text" ref="Email" name="Email" id="Email" value={this.state.Email} onChange={this.handleChangeEmail} />
										</FormGroup>
									</Col>
									<Col md={6}>
										<FormGroup>
											<Label><span className="red"> * </span>Job Grade (JG)</Label>
											<NumericInput className="form-control" ref="JG" name="JG" id="JG" min={1} max={12}
												onChange={valueAsNumber => this.handleChangeNum(valueAsNumber, "JG")} value={this.state.fields["JG"]} />
										</FormGroup>
									</Col>
								</Row>
								<Row>

									<Col md={6}>
										<FormGroup>
											<Label><span className="red"> * </span>แผนก</Label>
											<Input type="select" ref="DepartmentNo" name="DepartmentNo" id="DepartmentNo" onChange={this.onChangeDep}>
												<option value="">กรุณาเลือกแผนก</option>
												{this.state.showDropdown_Department.map(el => {
													return <option value={el.value}>{el.label}</option>
												})}
											</Input>
										</FormGroup>
									</Col>
									<Col md={6}>
										<FormGroup>
											<Label>แผนกย่อย</Label>
											<Input type="select" ref="subDepNo" name="subDepNo" id="subDepNo" onChange={this.onChangeSubDep}>
												<option value="">กรุณาเลือกแผนกย่อย</option>
												{this.state.showDropdown_subDepartment.map(el => {
													return <option value={el.value}>{el.label}</option>
												})}
											</Input>
										</FormGroup>
									</Col>
								</Row>

								<Row>

									<Col md={6}>
										<FormGroup>
											<Label>ตำแหน่งงาน </Label>
											<Input type="select" ref="Position_No" name="Position_No" id="Position_No" onChange={this.onChangePosition}>
												<option value="">------กรุณาเลือกตำแหน่ง------</option>
												{this.state.showDropdown_Position.map(el => {
													return <option value={el.value}>{el.label}</option>
												})}
											</Input>
										</FormGroup>
									</Col>
									<Col md={6}>
										<FormGroup>
											<Label for="BossID">สายอนุมัติ</Label>
											<Input type="select" ref="refFlow_HRM" name="refFlow_HRM" id="refFlow_HRM" onChange={this.onChangeFlowID} >
												<option value="0">------กรุณาเลือกสายอนุมัติ------</option>
												{this.state.showDropdown_Flow.map(el => {
													return <option value={el.value}>{el.label}</option>
												})}
											</Input>

										</FormGroup>
									</Col>
								</Row>
								<Row>
									<Col md={6} style={{ padding: 15 }}>
										<FormGroup>
											<Label for="shiftCode"><span className="red"> * </span>กะทำงานตั้งต้น </Label>
											{/* <b>(ถ้าบันทึกแล้วจะไม่สามารถแก้ไขได้)</b> */}
											<Input type="select" ref="shiftCode" name="shiftCode" id="shiftCode" onChange={this.onChangeWorkshift} >
												<option value="0">------กรุณาระบุกะทำงาน------</option>
												{this.state.showDropdown_workShift.map(el => {
													return <option value={el.value}>{el.label}</option>
												})}
											</Input>

										</FormGroup>
									</Col>
									<Col md={3} style={{ padding: 15 }}>
										<FormGroup>
											<Label for="timeScan"><span className="red"> * </span>รูปแบบในการสแกน </Label>
											<Input type="select" ref="timeScan" name="timeScan" id="timeScan" value={this.state.timeScan} onChange={this.onChangeTimeScan} >
												<option value="">------กรุณาระบุ------</option>
												<option value="0">ไม่ต้องสแกน</option>
												<option value="4">สแกนเวลารายวัน</option>
												<option value="2">สแกนเวลารายเดือน</option>
											</Input>
										</FormGroup>
									</Col>
									<Col md={3} style={{ padding: 15 }}>
										<FormGroup>
											<Label for="oneTimeScan">สแกนเข้างานครั้งเดียวเท่านั้น? </Label>
											<Checkbox
												ref="oneTimeScan" name="oneTimeScan" id="oneTimeScan"
												checked={this.state.oneTimeScan}
												onChange={this.onChangeOneTimeScan}>
												<Label>ใช่ ขาเข้ารอบเดียว </Label>
											</Checkbox>
										</FormGroup>
									</Col>

								</Row>
								<Row>

								</Row>

								{/* <Checkbox
                                                ref="followupGuarantee" name="followupGuarantee" id="followupGuarantee"
                                                checked={this.state.followupGuarantee}
                                                onChange={this.onChangeFollowupGuarantee}>
                                                <Label>ใช่ ขาเข้ารอบเดียว </Label>
                                            </Checkbox> */}

								{/* <Row>
                                    

                                    <Col md={6}>
                                        <FormGroup>
                                            <Label> สิทธิการดูตรวจสอบข้อมูล</Label>
                                            <Input type="select" ref="refFlow_view" name="refFlow_view" id="refFlow_view" onChange={this.onChangeFlowView} >
                                                <option value="0">------กรุณาเลือกสิทธิการดูตรวจสอบข้อมูล------</option>
                                                {this.state.showDropdown_View}
                                            </Input>

                                        </FormGroup>
                                    </Col> 

                                     <Col md={6}>
                                        <FormGroup>
                                            <Label for="LNameEN">Sale Code</Label>
                                            <Input type="text" name="salesCode" id="salesCode" />
                                        </FormGroup>
                                    </Col>

                                </Row> */}
								{/* <Row>

                                    <Col md={12}>
                                        <FormGroup>
                                            <Label for="ccMail">CC Mail เพิ่มเติม</Label>
                                            <Input type="textarea" name="ccMail" id="ccMail" placeholder='Example : aa@dappmaker.co.th, bb@dappmaker.co.th' />
                                        </FormGroup>
                                    </Col>
                                </Row> */}

								<Row>
									<Col md={4}>
									</Col>
									<Col md={4} className="text-center">
										<Button color="success">บันทึก</Button>
									</Col>
									<Col md={4}>

									</Col>
								</Row>
							</Form>
						</CardBody>
					</Card>
				) : (<div>{loadingPage} </div>)
				}
			</Container>
		);

	}
}
export default InsertEmployee;