import { useEffect, useState } from "react";
import { getCurrent } from "../../extendFunction/getCurrentYears";
import {
  GET_OPTION_UTILITY,
  GET_SELECT_EMPLOYEE_HISTORY,
  GET_SELECT_OPTION_WORKSHIFT_ALL,
  GET_SELECT_OPTION,
  SELECT_APPROVE_FROM,
  SELECT_USER_LIST,
} from "../../service";
import {
  ApprovalFlow,
  CenterAPI,
  Crime,
  History,
  KPIs,
  OJT,
  PermissionView,
  Position,
  SSO,
  Sub_Department,
  USE_CONNECT_MODE_AUTO,
} from "../services/ConnectAPI";
import { useCallAPI, useFetchGET } from "../services/RestAPI";

export const optionSelectLocation = [
  {
    value: "FAC",
    txt: "FAC",
  },
  {
    value: "OFF",
    txt: "OFF",
  },
];

export const optionFilterReportBy = [
  {
    value: "dep",
    txt: "แผนกหลัก",
  },
  {
    value: "subDep",
    txt: "แผนกย่อย",
  },
];

export const optionFilterAgeWorkBy = [
  {
    value: "department",
    txt: "แผนกหลัก",
  },
  {
    value: "subDepartment",
    txt: "แผนกย่อย",
  },
];

export const optionManageHistory = [
  {
    value: "PST", // Position
    txt: "เปลี่ยนตำแหน่ง",
  },
  {
    value: "DPM", // Department
    txt: "เปลี่ยนแผนกย่อย",
  },
  {
    value: "APF", // ApproveFlow
    txt: "เปลี่ยนสายอนุมัติ",
  },
  {
    value: "PMV", // PermissionView
    txt: "เปลี่ยนสายดูข้อมูล",
  },
];

export const optionTopicManageHistory = [
  {
    value: "position", // Position
    txt: "เปลี่ยนตำแหน่ง",
  },
  {
    value: "department", // Department
    txt: "เปลี่ยนแผนก",
  },
  {
    value: "subDepartment", // subDepartment
    txt: "เปลี่ยนแผนกย่อย",
  },
  {
    value: "approval", // ApproveFlow
    txt: "เปลี่ยนสายอนุมัติ",
  },
  {
    value: "permissionView", // PermissionView
    txt: "เปลี่ยนสายดูข้อมูล",
  },
];

export const optionStatusWorker = [
  {
    value: "Daily",
    txt: "พนักงานประจำรายวัน",
  },
  {
    value: "Full",
    txt: "พนักงานประจำรายเดือน",
  },
];

export const optionStatusWorkBenefit = [
  {
    value: "Daily",
    txt: "พนักงานประจำรายวัน",
  },
  {
    value: "Full",
    txt: "พนักงานประจำรายเดือน",
  },
  {
    value: "Temp",
    txt: "พนักงานสัญญาจ้างชั่วคราว",
  },
];

export const optionstatusStamp = [
  {
    value: "none",
    txt: "ยังไม่จบ",
  },
  {
    value: "done",
    txt: "ส่งจบแล้ว",
  },
];

export const optionHasSSO = [
  {
    value: true,
    txt: "เคยมีบัตรรับรองสิทธิ และ ไม่เปลี่ยนโรงโรงพยาบาล",
  },
  {
    value: false,
    txt: "ไม่เคยมีบัตรับรองสิทธิ หรือเคยมีบัตรรับรองสิทธิแต่อยากเปลี่ยนโรงพยาบาล",
  },
];

export const optionFillSSO = [
  {
    value: true,
    txt: "เคยกรอกข้อมูลแล้ว",
  },
  {
    value: false,
    txt: "ไม่เคยกรอกข้อมูล",
  },
];

export const optionFillDataSSO = [
  {
    value: "Y",
    txt: "กรอกข้อมูลแล้ว",
  },
  {
    value: "N",
    txt: "ยังไม่กรอกข้อมูล",
  },
];

export const optionStatusDocSSO = [
  {
    value: "Y",
    txt: "ส่งแล้ว",
  },
  {
    value: "N",
    txt: "ยังไม่ส่ง",
  },
];

export const OPTION_STATUS_TRANSFER = [
  {
    value: "0",
    txt: "รอดำเนินการ",
  },
  {
    value: "1",
    txt: "ดำเนินการแล้ว",
  },
];

export const OPTION_ACTION_TYPE = [
  {
    value: "company",
    txt: "โอนย้ายบริษัท",
  },
  {
    value: "dep",
    txt: "โอนย้ายแผนก",
  },
  {
    value: "subDep",
    txt: "โอนย้ายแผนกย่อย",
  },
  {
    value: "hrmFlow",
    txt: "โอนย้ายสายอนุมัติ",
  },
  {
    value: "viewFlow",
    txt: "โอนย้ายสายการดูข้อมูล",
  },
  {
    value: "position",
    txt: "โอนย้ายตำแหน่งงาน",
  },
];

export const optionStatusPass = [
  {
    value: "pass",
    txt: "ผ่าน",
  },
  {
    value: "notPass",
    txt: "ไม่ผ่าน",
  },
];

export const optionStatusComp = [
  {
    value: "T",
    txt: "สมบูรณ์",
  },
  {
    value: "F",
    txt: "ไม่สมบูรณ์",
  },
];

export const optionCurrentStatusPeriod = [
  {
    value: "new",
    txt: "พนักงานใหม่ (ในงวดนี้)",
  },
  {
    value: "normal",
    txt: "พนักงานปัจจุบัน",
  },
  {
    value: "blocked",
    txt: "พ้นสภาพ",
  },
];
export const optionStatusTask = [
  {
    value: "IT_ASSET",
    txt: "IT Fixed Asset",
  },
  {
    value: "DOMAIN",
    txt: "IT Domain",
  },
  {
    value: "EMAIL",
    txt: "IT Email",
  },
  {
    value: "SOFTWARE",
    txt: "IT Software",
  },
  {
    value: "PURCHASE_ASSET",
    txt: "Purchase Fixed Asset",
  },
  {
    value: "CONFIRM",
    txt: "Confirm",
  },
];
export const optionStatusQuitTask = [
  {
    value: "IT_ASSET",
    txt: "IT Fixed Asset",
  },
  {
    value: "DOMAIN",
    txt: "IT Domain",
  },
  {
    value: "EMAIL",
    txt: "IT Email",
  },
  {
    value: "SOFTWARE",
    txt: "IT Software",
  },
  // {
  //   value: "PURCHASE_ASSET",
  //   txt: "Purchase Fixed Asset",
  // },
  {
    value: "HR",
    txt: "Hr",
  },
  {
    value: "CONFIRM",
    txt: "Confirm",
  },
];

export const optionMonths = [
  {
    value: "01",
    txt: "มกราคม",
  },
  {
    value: "02",
    txt: "กุมภาพันธ์",
  },
  {
    value: "03",
    txt: "มีนาคม",
  },
  {
    value: "04",
    txt: "เมษายน",
  },
  {
    value: "05",
    txt: "พฤษภาคม",
  },
  {
    value: "06",
    txt: "มิถุนายน",
  },
  {
    value: "07",
    txt: "กรกฎาคม",
  },
  {
    value: "08",
    txt: "สิงหาคม",
  },
  {
    value: "09",
    txt: "กันยายน",
  },
  {
    value: "10",
    txt: "ตุลาคม",
  },
  {
    value: "11",
    txt: "พฤศจิกายน",
  },
  {
    value: "12",
    txt: "ธันวาคม",
  },
];

const year = getCurrent().yearsNow;
export const optionYears = [
  {
    value: year.toString(),
    txt: year,
  },
  {
    value: (year - 1).toString(),
    txt: year - 1,
  },
  {
    value: (year - 2).toString(),
    txt: year - 2,
  },
];

//! Dynamic Option Select
export const useOptionSelect = (type, queryString = "") => {
  let option = [];

  console.log({ type, queryString })
  switch (type) {

    
    case "company":
      {
        const url = `${USE_CONNECT_MODE_AUTO}${Position.GET_Company}`;
        const { getData: company } = useFetchGET(url);
        if (company["success"]) {
          const res = company["result"];
          option = res.map((val) => ({
            value: val.code,
            txt: `[${val.code}] : ${val.description}`,
          }));
        }
      }
      break;
      case "location":
        {
          const url = `${USE_CONNECT_MODE_AUTO}${GET_SELECT_OPTION('location')}`;
          const { getData: empName } = useFetchGET(url);
          if (empName["success"]) {
            const res = empName["result"];
            option = res.map((val) => ({
              value: val.code,
              txt: `[${val.code}] : ${val.description}`,
            }));
          }
        }
        break;
    // case "location":
    //   {
    //     const url = `${USE_CONNECT_MODE_AUTO}${Position.GET_Company}`;
    //     const { getData: company } = useFetchGET(url);
    //     if (company["success"]) {
    //       const res = company["result"];
    //       option = res.map((val) => ({
    //         value: val.code,
    //         txt: `[${val.code}] : ${val.description}`,
    //       }));
    //     }
    //   }
    //   break;

    case "position": // All Position
      {
        const url = `${USE_CONNECT_MODE_AUTO}${Position.GET_Option_Position}?${queryString}`;
        const { getData: position } = useFetchGET(url);
        if (position["success"]) {
          const res = position["result"];
          option = res.map((val) => ({
            value: val.PositionCode,
            txt: `[${val.PositionCode}] : ${val.PositionNameTH}`,
          }));
        }
      }
      break;
    case "CoursePosition": // Position that has courses
      {
        const url = `${USE_CONNECT_MODE_AUTO}${OJT.GET_Position_OJT}?${queryString}`;
        const { getData: CoursePosition } = useFetchGET(url);
        if (CoursePosition["success"]) {
          const res = CoursePosition["result"];
          option = res.map((val) => ({
            value: val.positionCode,
            txt: `[${val.positionCode}] : ${val.positionNameTH}`,
          }));
        }
      }
      break;
    case "kpiPosition": // Position that has KPIs
      {
        const url = `${USE_CONNECT_MODE_AUTO}${KPIs.GET_Position_KPIs}?${queryString}`;
        const { getData: kpiPosition } = useFetchGET(url);
        if (kpiPosition["success"]) {
          const res = kpiPosition["result"];
          option = res.map((val) => ({
            value: val.positionCode,
            txt: `[${val.positionCode}] : ${val.positionNameTH}`,
          }));
        }
      }
      break;
    case "department":
      {
        const url = `${USE_CONNECT_MODE_AUTO}${Sub_Department.GET_Department}?${queryString}`;
        const { getData: department } = useFetchGET(url);
        if (department["success"]) {
          const res = department["result"];
          option = res.map((val) => ({
            value: val.depNo,
            txt: `[${val.depNo}] : ${val.depName}`,
          }));
        }
      }
      break;
    // case "subDepartment":
    //   {
    //     const url = `${USE_CONNECT_MODE_AUTO}${Sub_Department.GET_Search_Sub_Department}?${queryString}`;
    //     const { getData: subDepartment } = useFetchGET(url);
    //     if (subDepartment["success"]) {
    //       const res = subDepartment["result"];
    //       option = res.map((val) => ({
    //         value: val.subDepNo,
    //         txt: `[${val.subDepNo}] : ${val.subDepName}`,
    //       }));
    //     }
    //   }
    //   break;
    case "subDepartmentName":
    case "subDepartment":
      {
        const url = `${USE_CONNECT_MODE_AUTO}${Sub_Department.GET_Option_Sub_Department}?${queryString}`;
        const { getData: subDepartment } = useFetchGET(url);
        console.log(subDepartment)
        if (subDepartment["success"]) {
          const res = subDepartment["result"];
          option = res.map((val) => ({
            value: val.subDepNo,
            txt: `[${val.subDepNo}] : ${val.subDepName}`,
          }));
        }
      }
      break;
    case "approval":
      {
        const url = `${USE_CONNECT_MODE_AUTO}${ApprovalFlow.GET_ApproveFlow}?${queryString}`;
        const { getData: approval } = useFetchGET(url);
        if (approval["success"]) {
          const res = approval["result"];
          option = res.map((val) => ({
            value: val.flowId,
            txt: `[${val.flowId}] : ${val.flowName}`,
          }));
        }
      }
      break;
    case "approvalActive":
      {
        const url = `${USE_CONNECT_MODE_AUTO}${ApprovalFlow.GET_ApproveFlow}?statusActive=1&${queryString}`;
        const { getData: approval } = useFetchGET(url);
        if (approval["success"]) {
          const res = approval["result"];
          option = res.map((val) => ({
            value: val.flowId,
            txt: `[${val.flowId}] : ${val.flowName}`,
          }));
        } else {
          option = [];
        }
      }
      break;
    case "viewFlow":
      {
        const url = `${USE_CONNECT_MODE_AUTO}${PermissionView.POST_SEARCH_VIEW}`;
        const { getData: viewFlow } = useCallAPI(url, "POST", { users: [] });
        if (viewFlow["success"]) {
          const res = viewFlow["result"];
          option = res.map((val) => ({
            value: val.flowId,
            txt: `[${val.flowId}] : ${val.description}`,
          }));
        }
      }
      break;
    case "empAll": // All Employee
      {
        const url = `${USE_CONNECT_MODE_AUTO}${History.GET_Employee}?${queryString}`;
        const { getData: empAll } = useFetchGET(url);
        if (empAll["success"]) {
          const res = empAll["result"];
          option = res.map((val) => ({
            value: val.userId,
            txt: `[${val.userId}] : ${val.fullName}`,
          }));
        }
      }
      break;
    case "empPosition": // Employee in Position
      {
        const url = `${USE_CONNECT_MODE_AUTO}${Position.GET_Employee_LIST}${queryString}`;
        const { getData: empPosition } = useFetchGET(url);
        if (empPosition["success"]) {
          const res = empPosition["result"];
          option = res.map((val) => ({
            value: val.userId,
            txt: `[${val.userId}] : ${val.fullName}`,
          }));
        }
      }
      break;
    case "empSubDep": // Employee in Sub Department
      {
        const url = `${USE_CONNECT_MODE_AUTO}${Sub_Department.GET_Employee_List}${queryString}`;
        const { getData: empSubDep } = useFetchGET(url);
        if (empSubDep["success"]) {
          const res = empSubDep["result"];
          option = res.map((val) => ({
            value: val.ID_User,
            txt: `[${val.ID_User}] : ${val.Name}`,
          }));
        }
      }
      break;
    case "empApproval": // Employee in Approval Flow
      {
        const url = `${USE_CONNECT_MODE_AUTO}${ApprovalFlow.GET_EmployeeList_BY_ID}${queryString}`;
        const { getData: empApproval } = useFetchGET(url);
        if (empApproval["success"]) {
          const res = empApproval["result"];
          option = res.map((val) => ({
            value: val.ID_User,
            txt: `[${val.ID_User}] : ${val.Name}`,
          }));
        }
      }
      break;
    case "empPermission": // Employee in PermissionView
      {
        const url = `${USE_CONNECT_MODE_AUTO}${PermissionView.GET_Employee}?${queryString}`;
        const { getData: empPermission } = useFetchGET(url);
        if (empPermission["success"]) {
          const res = empPermission["result"];
          option = res.map((val) => ({
            value: val.userId,
            txt: `[${val.userId}] : ${val.fullName}`,
          }));
        }
      }
      break;
    case "empSSO": // Employee who did not fill the form
      {
        const url = `${USE_CONNECT_MODE_AUTO}${SSO.GET_Employee_NO_SSO}?${queryString}`;
        const { getData: empSSO } = useFetchGET(url);
        if (empSSO["success"]) {
          const res = empSSO["result"];
          option = res.map((val) => ({
            value: val.userId,
            txt: `[${val.userId}] : ${val.fullName}`,
          }));
        }
      }
      break;
    case "training": // Training
      {
        const url = `${USE_CONNECT_MODE_AUTO}${OJT.GET_Training_ALL}`;
        const { getData: training } = useFetchGET(url);
        if (training["success"]) {
          const res = training["result"];
          option = res.map((val) => ({
            value: val.trainingNo,
            txt: `[${val.trainingNo}] : ${val.trainingCourses}`,
          }));
        }
      }
      break;
    case "trainGroup": // Training Group
      {
        const url = `${USE_CONNECT_MODE_AUTO}${OJT.GET_Training_Group}`;
        const { getData: trainGroup } = useFetchGET(url);
        if (trainGroup["success"]) {
          const res = trainGroup["result"];
          option = res.map((val) => ({
            value: val.code,
            txt: `[${val.code}] : ${val.description}`,
          }));
        }
      }
      break;
    case "trainType": // Training Type
      {
        const url = `${USE_CONNECT_MODE_AUTO}${OJT.GET_Training_Type}`;
        const { getData: trainType } = useFetchGET(url);
        if (trainType["success"]) {
          const res = trainType["result"];
          option = res.map((val) => ({
            value: val.code,
            txt: `[${val.code}] : ${val.description}`,
          }));
        }
      }
      break;
    case "trainMethod": // Training Type
      {
        const url = `${USE_CONNECT_MODE_AUTO}${OJT.GET_TrainingMethod}`;
        const { getData: trainMethod } = useFetchGET(url);
        if (trainMethod["success"]) {
          const res = trainMethod["result"];
          option = res.map((val) => ({
            value: val.code,
            txt: `[${val.code}] : ${val.description}`,
          }));
        }
      }
      break;
    case "province": // Province
      {
        const url = `${USE_CONNECT_MODE_AUTO}${SSO.GET_Province}`;
        const { getData: province } = useFetchGET(url);
        if (province["success"]) {
          const res = province["result"];
          option = res.map((val) => ({
            value: val.provinceId,
            txt: `[${val.provinceId}] : ${val.provinceTH}`,
          }));
        }
      }
      break;
    case "hospital": // Hospital
      {
        const url = `${USE_CONNECT_MODE_AUTO}${SSO.GET_hospital}?${queryString}`;
        const { getData: hospital } = useFetchGET(url);
        if (hospital["success"]) {
          const res = hospital["result"];
          option = res.map((val) => ({
            value: val.id,
            txt: val.fullName,
          }));
        }
      }
      break;
    case "reasonLeave": // Reason Leave
      {
        const url = `${USE_CONNECT_MODE_AUTO}${OJT.GET_ReasonLeaveCode}`;
        const { getData: reasonLeave } = useFetchGET(url);
        if (reasonLeave["success"]) {
          const res = reasonLeave["result"];
          option = res.map((val) => ({
            value: val.code,
            txt: `[${val.code}] : ${val.description}`,
          }));
        }
      }
      break;
    case "currentStatus": // Current Status
      {
        const url = `${USE_CONNECT_MODE_AUTO}${OJT.GET_CurrentStatus}`;
        const { getData: currentStatus } = useFetchGET(url);
        if (currentStatus["success"]) {
          const res = currentStatus["result"];
          option = res.map((val) => ({
            value: val.code,
            txt: `[${val.code}] : ${val.description}`,
          }));
        }
      }
      break;
    case "crimeResult": // Crime Result Code
      {
        const url = `${USE_CONNECT_MODE_AUTO}${Crime.GET_Option_Crime}`;
        const { getData: crimeResult } = useFetchGET(url);
        if (crimeResult["success"]) {
          const res = crimeResult["result"];
          option = res.map((val) => ({
            value: val.code,
            txt: val.description,
          }));
        }
      }
      break;
    case "statusMainCode": // Crime Result Code
      {
        const url = `${USE_CONNECT_MODE_AUTO}${CenterAPI.GET_OptionStatusMainCode}`;
        const { getData: statusMainCode } = useFetchGET(url);
        if (statusMainCode["success"]) {
          const res = statusMainCode["result"];
          option = res.map((val) => ({
            value: val.code,
            txt: val.description,
          }));
        }
      }
      break;
    case "contractExtension": // Crime Result Code
      {
        const url = `${USE_CONNECT_MODE_AUTO}${CenterAPI.GET_OptionContractExtention}`;
        const { getData: contractExtension } = useFetchGET(url);
        if (contractExtension["success"]) {
          const res = contractExtension["result"];
          option = res.map((val) => ({
            value: val.code,
            txt: val.description,
          }));
        }
      }
      break;
    case "statusWorker": // Crime Result Code
      {
        const url = `${USE_CONNECT_MODE_AUTO}${CenterAPI.GET_OptionStatusWorker}`;
        const { getData: statusWorker } = useFetchGET(url);
        if (statusWorker["success"]) {
          const res = statusWorker["result"];
          option = res.map((val) => ({
            value: val.code,
            txt: val.description,
          }));
        }
      }
      break;
    case "recruiterGrade": //ประเมินคุณภาพการสรรหา
      {
        const url = `${USE_CONNECT_MODE_AUTO}${CenterAPI.GET_OptionRecruiterGrade}`;
        const { getData: recruiterGrade } = useFetchGET(url);
        if (recruiterGrade["success"]) {
          const res = recruiterGrade["result"];
          option = res.map((val) => ({
            value: val.code,
            txt: `[${val.code}] : ${val.description}`,
          }));
        }
      }
      break;
    case "recruiterScore": //ความพึงพอใจต่อการสรรหา
      {
        const url = `${USE_CONNECT_MODE_AUTO}${CenterAPI.GET_OptionRecruiterScore}`;
        const { getData: recruiterScore } = useFetchGET(url);
        if (recruiterScore["success"]) {
          const res = recruiterScore["result"];
          option = res.map((val) => ({
            value: val.code,
            txt: val.description,
          }));
        }
      }
      break;
    case "statusProbation": // สถานะทดลองงาน
      {
        const url = `${USE_CONNECT_MODE_AUTO}${CenterAPI.GET_OptionStatusProbation}`;
        const { getData: statusProbation } = useFetchGET(url);
        if (statusProbation["success"]) {
          const res = statusProbation["result"];
          option = res.map((val) => ({
            value: val.code,
            txt: val.description,
          }));
        }
      }
      break;
    case "insurancePlan": // แผนประกัน
      {
        const url = `${USE_CONNECT_MODE_AUTO}${CenterAPI.GET_OptionInsurancePlan}`;
        const { getData: insurancePlan } = useFetchGET(url);
        if (insurancePlan["success"]) {
          const res = insurancePlan["result"];
          option = res.map((val) => ({
            value: val.code,
            txt: val.description,
          }));
        }
      }
      break;
    case "grade": // แผนประกัน
      {
        const url = `${USE_CONNECT_MODE_AUTO}${CenterAPI.GET_OptionGrade}`;
        const { getData: grade } = useFetchGET(url);
        if (grade["success"]) {
          const res = grade["result"];
          option = res.map((val) => ({
            value: val.code,
            txt: `[${val.code}] : ${val.description}`,
          }));
        }
      }
      break;
    case "contractExtension": // ลักษณะการต่อสัญญาจ้าง
      {
        const url = `${USE_CONNECT_MODE_AUTO}${CenterAPI.GET_OptionContractExtension}`;
        const { getData: contractExtension } = useFetchGET(url);
        if (contractExtension["success"]) {
          const res = contractExtension["result"];
          option = res.map((val) => ({
            value: val.code,
            txt: `${val.description}`,
          }));
        }
      }
      break;
    case "payrollGroup": // กลุ่มเงินเดือน
      {
        const url = `${USE_CONNECT_MODE_AUTO}${CenterAPI.GET_OptionPayrollGroup}`;
        const { getData: payrollGroup } = useFetchGET(url);
        if (payrollGroup["success"]) {
          const res = payrollGroup["result"];
          option = res.map((val) => ({
            value: val.code,
            txt: `${val.description}`,
          }));
        }
      }
      break;
    case "recruiterHr": // กลุ่มเงินเดือน
      {
        const url = `${USE_CONNECT_MODE_AUTO}${CenterAPI.GET_OptionHrTeam}`;
        const { getData: recruiterHr } = useFetchGET(url);
        if (recruiterHr["success"]) {
          const res = recruiterHr["result"];
          option = res.map((val) => ({
            value: val.recruiterId,
            txt: `[${val.recruiterId}]: ${val.fullname} (${val.nickname})`,
          }));
        }
      }
      break;
    case "movement": // กลุ่ม moment
      {
        const url = `${USE_CONNECT_MODE_AUTO}${CenterAPI.GET_OptionMovement}`;
        const { getData: recruiterHr } = useFetchGET(url);
        if (recruiterHr["success"]) {
          const res = recruiterHr["result"];
          option = res.map((val) => ({
            value: val.code,
            txt: val.description,
          }));
        }
      }
      break;
    case "workShift":
      {
        const url = `${USE_CONNECT_MODE_AUTO}${GET_SELECT_OPTION_WORKSHIFT_ALL()}`;
        const { getData: wprkShift } = useFetchGET(url);
        if (wprkShift["success"]) {
          const res = wprkShift["result"];
          option = res.map((val) => ({
            value: val.shiftCode,
            txt: `[${val.shiftCode}] : ${val.workShiftNameTH}`,
          }));
        }
      }
      break;
    case "approvedForm":
      {
        const url = `${USE_CONNECT_MODE_AUTO}${SELECT_APPROVE_FROM}`;
        const { getData: approvedForm } = useFetchGET(url);
        if (approvedForm["success"]) {
          const res = approvedForm["result"];
          option = res.map((val) => ({
            value: val.value,
            txt: `[${val.value}] : ${val.label}`,
          }));
        }
      }
      break;
    case "empName":
      {
        const url = `${USE_CONNECT_MODE_AUTO}${GET_SELECT_EMPLOYEE_HISTORY()}`;
        const { getData: empName } = useFetchGET(url);
        if (empName["success"]) {
          const res = empName["result"];
          option = res.map((val) => ({
            value: val.value,
            txt: `[${val.value}] : ${val.label}`,
          }));
        }
      }
      break;
    case "managerName":
      {
        const url = `${USE_CONNECT_MODE_AUTO}${SELECT_USER_LIST({
          LevelWorker: "MAN",
        })}`;
        const { getData: managerName } = useFetchGET(url);
        if (managerName["success"]) {
          const res = managerName["result"];
          option = res.map((val) => ({
            value: val.ID_User,
            txt: `[${val.ID_User}] : ${val.Name}`,
          }));
        }
      }
      break;
    case "division":
      {
        const url = `${USE_CONNECT_MODE_AUTO}${GET_OPTION_UTILITY("DIVISION")}`;
        const { getData: division } = useFetchGET(url);
        if (division["success"]) {
          const res = division["result"];
          option = res.map((val) => ({
            value: val.DivisionID,
            txt: `[${val.DivisionID}] : ${val.DivisionName}`,
          }));
        }
      }
      break;
    case "transStatus":
      {
        const url = `${USE_CONNECT_MODE_AUTO}${GET_OPTION_UTILITY(
          "TRANSSTATUS"
        )}`;
        const { getData: transStatus } = useFetchGET(url);
        if (transStatus["success"]) {
          const res = transStatus["result"];
          option = res.map((val) => ({
            value: val.TransStatus,
            txt: `[${val.TransStatus}] : ${val.TransStatusName}`,
          }));
        }
      }
      break;
  }
  return option;
};
//! Dynamic Option Select
