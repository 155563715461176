import { COMPONENT_TYPE, ERROR_MESSAGE } from "..";

const DEFINE = {
    company: {
        label: "บริษัท",
        name: "companyCode",
    },
    depNo: {
        label: "แผนกหลัก",
        name: "depNo",
    },
    subDepNo: {
        label: "แผนกย่อย",
        name: "subDepNo",
    },
    subDepName: {
        label: "ชื่อแผนกย่อย",
        name: "subDepName",
    },
    location: {
        label: "Location",
        name: "location",
    },
}

export const ComponentsSearchSubDepartment = ({
    options = {},
    isDisable = {},
    handleChange = () => null,
    onClick = () => null,
}) => {
    const { company, depNo, subDepNo } = DEFINE
    return [
        { // Row
            rowProps: { justify: "start", align: "bottom" },
            column: [ // column
                {
                    breakpoint: { xs: 24, sm: 24, md: 24, lg: 8, xl: 4 },
                    component: {
                        type: COMPONENT_TYPE.SELECT,
                        itemProps: {
                            name: company.name,
                            label: company.label,
                            rules: [{ required: true, message: ERROR_MESSAGE.SELECT(company.label) }],
                        },
                        componentProps: {
                            style: { width: "100%" },
                            placeholder: ERROR_MESSAGE.SELECT(company.label),
                            disabled: isDisable[company.name] || false,
                        },
                        fnChange: (value) => handleChange(value, company.name),
                        dropdown: options[company.name],
                    },
                },
                {
                    breakpoint: { xs: 24, sm: 24, md: 24, lg: 8, xl: 8 },
                    component: {
                        type: COMPONENT_TYPE.SELECT,
                        itemProps: {
                            name: depNo.name,
                            label: depNo.label,
                            rules: [{ required: false, message: ERROR_MESSAGE.SELECT(depNo.label) }],
                        },
                        componentProps: {
                            style: { width: "100%" },
                            placeholder: ERROR_MESSAGE.SELECT(depNo.label),
                            disabled: isDisable[depNo.name] || false,
                        },
                        fnChange: (value) => handleChange(value, depNo.name),
                        dropdown: options[depNo.name],
                    },
                },
                {
                    breakpoint: { xs: 24, sm: 24, md: 24, lg: 8, xl: 8 },
                    component: {
                        type: COMPONENT_TYPE.SELECT,
                        itemProps: {
                            name: subDepNo.name,
                            label: subDepNo.label,
                            rules: [{ required: false, message: ERROR_MESSAGE.SELECT(subDepNo.label) }],
                        },
                        componentProps: {
                            style: { width: "100%" },
                            placeholder: ERROR_MESSAGE.SELECT(subDepNo.label),
                            disabled: isDisable[subDepNo.name] || false,
                        },
                        fnChange: (value) => handleChange(value, subDepNo.name),
                        dropdown: options[subDepNo.name],
                    },
                },
                {
                    breakpoint: { xs: 24, sm: 12, md: 12, lg: 4, xl: 2 },
                    component: {
                        type: COMPONENT_TYPE.BUTTON,
                        componentProps: {
                            call: "search",
                            disabled: isDisable["submit"] || false,
                        },
                    },
                },
                {
                    breakpoint: { xs: 24, sm: 12, md: 12, lg: 4, xl: 2 },
                    component: {
                        type: COMPONENT_TYPE.BUTTON,
                        componentProps: {
                            call: "normal",
                            label: "ล้างข้อมูล",
                            onClick,
                        },
                    },
                },
            ], // column
        }, // Row
    ];
}

export const ComponentsInsertSubDepartment = ({
    options = {},
    isDisable = {},
    handleChange = () => null,
    onClick = () => null,
}) => {
    const { company, location, depNo, subDepNo, subDepName } = DEFINE
    return [
        { // Row
            rowProps: { justify: "start", align: "bottom" },
            column: [ // column
                {
                    breakpoint: { xs: 24, sm: 24, md: 24, lg: 8, xl: 4 },
                    component: {
                        type: COMPONENT_TYPE.SELECT,
                        itemProps: {
                            name: company.name,
                            label: company.label,
                            rules: [{ required: true, message: ERROR_MESSAGE.SELECT(company.label) }],
                        },
                        componentProps: {
                            style: { width: "100%" },
                            placeholder: ERROR_MESSAGE.SELECT(company.label),
                            disabled: isDisable[company.name] || false,
                        },
                        fnChange: (value) => handleChange(value, company.name),
                        dropdown: options[company.name],
                    },
                },
                {
                    breakpoint: { xs: 24, sm: 24, md: 24, lg: 8, xl: 6 },
                    component: {
                        type: COMPONENT_TYPE.SELECT,
                        itemProps: {
                            name: depNo.name,
                            label: depNo.label,
                            rules: [{ required: true, message: ERROR_MESSAGE.SELECT(depNo.label) }],
                        },
                        componentProps: {
                            style: { width: "100%" },
                            placeholder: ERROR_MESSAGE.SELECT(depNo.label),
                            disabled: isDisable[depNo.name] || false,
                        },
                        fnChange: (value) => handleChange(value, depNo.name),
                        dropdown: options[depNo.name],
                    },
                },
                {
                    breakpoint: { xs: 24, sm: 24, md: 24, lg: 8, xl: 4 },
                    component: {
                        type: COMPONENT_TYPE.SELECT,
                        itemProps: {
                            name: location.name,
                            label: location.label,
                            rules: [{ required: true, message: ERROR_MESSAGE.SELECT(location.label) }],
                        },
                        componentProps: {
                            style: { width: "100%" },
                            placeholder: ERROR_MESSAGE.SELECT(location.label),
                            disabled: isDisable[location.name] || false,
                        },
                        fnChange: (value) => handleChange(value, location.name),
                        dropdown: options[location.name],
                    },
                },
                {
                    breakpoint: { xs: 24, sm: 24, md: 24, lg: 12, xl: 4 },
                    component: {
                        type: COMPONENT_TYPE.INPUT,
                        itemProps: {
                            name: subDepNo.name,
                            label: subDepNo.label,
                            rules: [{ required: true, message: ERROR_MESSAGE.INPUT(subDepNo.label) }],
                        },
                        componentProps: {
                            style: { width: "100%" },
                            placeholder: ERROR_MESSAGE.INPUT(subDepNo.label),
                            disabled: isDisable[subDepNo.name] || false,
                        },
                        fnChange: (event) => handleChange(event.target.value, subDepNo.name),
                    },
                },
                {
                    breakpoint: { xs: 24, sm: 24, md: 24, lg: 12, xl: 6 },
                    component: {
                        type: COMPONENT_TYPE.INPUT,
                        itemProps: {
                            name: subDepName.name,
                            label: subDepName.label,
                            rules: [{ required: true, message: ERROR_MESSAGE.INPUT(subDepName.label) }],
                        },
                        componentProps: {
                            style: { width: "100%" },
                            placeholder: ERROR_MESSAGE.INPUT(subDepName.label),
                            disabled: isDisable[subDepName.name] || false,
                        },
                        fnChange: (event) => handleChange(event.target.value, subDepName.name),
                    },
                },
            ], // column
        }, // Row
        { // Row
            rowProps: { justify: "center", align: "bottom", style: { marginTop: 20 } },
            column: [ // column
                {
                    breakpoint: { xs: 24, sm: 24, md: 12, lg: 6, xl: 4 },
                    component: {
                        type: COMPONENT_TYPE.BUTTON,
                        componentProps: {
                            call: "save",
                            disabled: isDisable["submit"] || false,
                        },
                    },
                },
                {
                    breakpoint: { xs: 24, sm: 24, md: 12, lg: 6, xl: 4 },
                    component: {
                        type: COMPONENT_TYPE.BUTTON,
                        componentProps: {
                            call: "normal",
                            label: "ล้างข้อมูล",
                            onClick,
                        },
                    },
                },
            ], // column
        }, // Row
    ];
}
