import { createRef } from "react";
import { MESSAGE_ERROR } from "../index.jsx";
import {
  columnSearchProps,
  columnSortDate,
} from "../../../components/structuresReport/function";
import { KEY_ACTIVE_TAB, KEY_TYPE } from "../../../constants/enums/enumCenter";
import { BREADCRUMB_PERSONAL } from "../breadcrumbPersonal.jsx";
import { FIELD_EMP } from "../contents/employeeInfo.jsx";
import { FIELD_PERSONAL } from "../contents/personalInfo.jsx";
import { TYPE } from "../../../moduleKPIs/common/inputs/index.jsx";
import { MASTER_OPTION } from "../../../moduleKPIs/services/servicesOptions.jsx";

/* -------------------------------------------------------------------------- */
/*                                  FUNCTION                                  */
/* -------------------------------------------------------------------------- */
export function formatCadidateNo(_value) {
  let Y = `${new Date().getFullYear()}`.substring(0, 2);
  let M = `${new Date().getMonth() + 1}`.substring(0, 2);

  return `NO${`${Y}${`${M.length < 10 ? `0${M}` : M}`}-000`}`;
}
/* -------------------------------------------------------------------------- */

/* -------------------------------------------------------------------------- */
/*                                 FIELD_PAGES                                */
/* -------------------------------------------------------------------------- */

const FIELD_PAGES = {
  //!เปลี่ยนชื่อ Field ที่นี่
  /* ------------------------- TABLE / EXPORT / SEARCH ------------------------ */
  CANDIDATE_NO: FIELD_EMP.CANDIDATE_NO,
  FULLNAME: FIELD_EMP.FULLNAME_TH,
  NICKNAME: FIELD_EMP.NICKNAME_TH,
  TEL: FIELD_PERSONAL.MOBILE_PHONE,
  START_DATE_JOB: FIELD_EMP.START_DATE_JOB,
  JOB_POSITION: FIELD_EMP.JOB_POSITION,
  /* -------------------------------------------------------------------------- */
};

/* -------------------------------------------------------------------------- */
/*                                 LABEL_PAGES                                */
/* -------------------------------------------------------------------------- */

const LABEL_PAGES = {
  //!เปลี่ยนชื่อ Label ที่นี่
  /* ------------------------- TABLE / EXPORT / SEARCH ------------------------ */
  CANDIDATE_NO: "เลขที่เอกสาร",
  FULLNAME: "ชื่อ - นามสกุล",
  NICKNAME: "ชื่อเล่น",
  TEL: "เบอร์โทรศัพท์",
  START_DATE_JOB: "วันที่เริ่มงาน",
  JOB_POSITION: "ตำแหน่ง",
  /* ------------------------------  ----------------------------- */
};

/* -------------------------------------------------------------------------- */
/*                                 TEXT_PAGES                                 */
/* -------------------------------------------------------------------------- */

const TEXT_PAGES = {
  //!เปลี่ยนชื่อ Label Tabs ที่นี่
  HEADER_REPORT: "จัดการพนักงานพ้นสภาพ",
  CONTENT_REPORT: "",
  FILE_EXPORT: {
    [KEY_ACTIVE_TAB.IMPORT_QUIT_PERSONAL]: "Import พนักงานพ้นสภาพ",
  },
  TAB: {
    [KEY_ACTIVE_TAB.IMPORT_QUIT_PERSONAL]: "Import พนักงานพ้นสภาพ",
  },
};

/* -------------------------------------------------------------------------- */
/*                                 ICON_PAGES                                 */
/* -------------------------------------------------------------------------- */

const ICON_PAGES = {
  /**
   * TODO: สามารถใช้ Svg ได้ Exmple : TODO: <Svg.name />
   */
  HEADER_REPORT: null,
  CONTENT_REPORT: null,
  FILE_EXPORT: {
    [KEY_ACTIVE_TAB.IMPORT_QUIT_PERSONAL]: null,
  },
  TAB: {
    [KEY_ACTIVE_TAB.IMPORT_QUIT_PERSONAL]: null,
  },
};

/* -------------------------------------------------------------------------- */
/*                                 FORM_PAGES                                 */
/* -------------------------------------------------------------------------- */

const FORM_PAGES = {
  BREADCRUMB: (props) => BREADCRUMB_PERSONAL({ ...props }),
  TABS: (props) => TABS_PAGES({ ...props }),
  TABLE: (props) => TABLE_PAGES({ ...props }),
  SEARCH: (props) => SEARCH_PAGES({ ...props }),
  EXPORT_EXCEL: (props) => EXPORT_PAGES({ ...props }),
};

/* -------------------------------------------------------------------------- */
/*                                 TABS_PAGES                                 */
/* -------------------------------------------------------------------------- */

const TABS_PAGES = (props) => {
  const { renderTable } = props;
  /**
   * TODO: สามารถแบ่งตาม activeTab , location ได้ โดยใช้ switch case
   */
  return [
    {
      key: 1,
      tab: TEXT_PAGES.TAB[KEY_ACTIVE_TAB.IMPORT_QUIT_PERSONAL],
      icon: "",
      content: renderTable,
      required: false,
    },
  ];
};

/* -------------------------------------------------------------------------- */
/*                                 TABLE_PAGES                                */
/* -------------------------------------------------------------------------- */

const TABLE_PAGES = (props) => {
  const searchInput = createRef();
  const { actionButton } = props;

  return [
    {
      dataIndex: "userId",
      title: "รหัสพนักงาน",
      width: 100,
      fixed: "",
      align: "",
      ...columnSearchProps("userId", searchInput),
    },
    {
      dataIndex: "fullName",
      title: "ชื่อ-นามสกุล",
      width: 200,
      fixed: "",
      align: "",
      ...columnSearchProps("fullName", searchInput),
    },
    {
      dataIndex: "nickName",
      title: "ชื่อเล่น",
      width: 150,
      fixed: "",
      align: "",
      ...columnSearchProps("nickName", searchInput),
    },
    {
      dataIndex: "depNo",
      title: "แผนก",
      width: 100,
      fixed: "",
      align: "",
      ...columnSearchProps("depNo", searchInput),
    },
    {
      dataIndex: "depName",
      title: "แผนก",
      width: 200,
      fixed: "",
      align: "",
      ...columnSearchProps("depName", searchInput),
    },
    {
      dataIndex: "approvedBy",
      title: "ยืนยันโดย",
      width: 200,
      fixed: "",
      align: "",
      ...columnSearchProps("approvedBy", searchInput),
    },
    {
      dataIndex: "approvedDate",
      title: "วันที่ยืนยัน",
      width: 150,
      fixed: "",
      align: "",
      ...columnSearchProps("approvedDate", searchInput),
    },
    {
      dataIndex: "company",
      title: "บริษัท",
      width: 100,
      fixed: "",
      align: "",
      ...columnSearchProps("company", searchInput),
    },
    {
      dataIndex: "startDateJob",
      title: "วันที่เริ่ม",
      width: 150,
      fixed: "",
      align: "",
      ...columnSearchProps("startDateJob", searchInput),
    },
    {
      dataIndex: "endDateJob",
      title: "วันที่สิ้นสุด",
      width: 150,
      fixed: "",
      align: "",
      ...columnSearchProps("endDateJob", searchInput),
    },
    {
      dataIndex: "currentStatus",
      title: "สถานภาพพนักงาน",
      width: 100,
      fixed: "",
      align: "",
      ...columnSearchProps("currentStatus", searchInput),
    },
   
  ];
};

/* -------------------------------------------------------------------------- */
/*                                SEARCH_PAGES                                */
/* -------------------------------------------------------------------------- */

const SEARCH_PAGES = ({options}) => {
  // const { activeTab, location, options, actionButton } = props;
  /**
   * TODO: สามารถแบ่งตาม activeTab , location ได้ โดยใช้ switch case
   */

  return [
    {
      span: 8,
      type: TYPE.SELECT,
      header: 'ประจำปี (ย้อนหลัง 3 ปี)',
      required: true,
      message: '',
      inputOption: {
          fieldname: 'year',
          fieldOption: MASTER_OPTION.OPTION_YEAR,
          plainOptions: options[MASTER_OPTION.OPTION_YEAR],
          defaultValue: options[`SEARCH_${MASTER_OPTION.OPTION_YEAR}`],
          disabled: false,
          placeholder: null
      }
  },
  ];
};

/* -------------------------------------------------------------------------- */
/*                                EXPORT_PAGES                                */
/* -------------------------------------------------------------------------- */

export const EXPORT_PAGES = ({ activeTab }) => {
  return [
    {
      id: 1,
      title: "เลขที่เอกสาร",
      field: "",
    },
    {
      id: 2,
      title: "ชื่อ-นามสกุล",
      field: "",
    },
    {
      id: 3,
      title: "ชื่อเล่น",
      field: "",
    },
    {
      id: 4,
      title: "เบอร์โทรศัพท์",
      field: "",
    },
    {
      id: 5,
      title: "วันที่เริ่มงาน",
      field: "",
    },
    {
      id: 6,
      title: "ตำแหน่งงาน",
      field: "",
    },
  ];
};

/* -------------------------------------------------------------------------- */
/*                                   EXPORT                                   */
/* -------------------------------------------------------------------------- */

export const TEXT_DASHBOARD_IMPORT_QUIT_PERSONAL = TEXT_PAGES;
export const ICON_DASHBOARD_IMPORT_QUIT_PERSONAL = ICON_PAGES;
export const FORM_DASHBOARD_IMPORT_QUIT_PERSONAL = FORM_PAGES;
export const FIELD_DB_IMPORT_QUIT_PERSONAL = FIELD_PAGES;
export const LABEL_DB_IMPORT_QUIT_PERSONAL = LABEL_PAGES;
