import { KEY_OPTION, KEY_TYPE } from "../../../constants/enums/enumCenter";
import { FIELD_STEP, MESSAGE_ERROR } from "..";
import { ContractContext } from "../../../moduleContract/servicesContract/contractProvider";
import { useContext } from "react";

/* -------------------------------------------------------------------------- */
/*                                 LABEL_PAGES                                */
/* -------------------------------------------------------------------------- */

export const ACCEPT_FILE = "application/pdf,image/jpeg,image/png";

const LABEL_PAGES = {
  //!เปลี่ยนชื่อ Label ที่นี่
  /* ------------------------------ COLLAPSE_S41 ------------------------------ */

  // DRIVING_SKILL: 'ความสามารถในการขับขี่ (Driving Skill)',
  // OWNER_VEHICLE: 'มีพาหนะเป็นของตนเอง (Own a vihicle ?)',

  /* ------------------------------ COLLAPSE_S42 ------------------------------ */

  COMPUTER_ABILITY: "ความสามารถทางคอมพิวเตอร์ (Computer programs)",
  OFFICE_ABILITY: "ความสามารถทางการใช้อุปกรณ์สำนักงาน (Office equipment)",

  /* ------------------------------ COLLAPSE_S45 ------------------------------ */

  ACTIVITY_EXTRA: "กิจกรรมที่เคยทำ (Extracurricular activities)",
  ACTIVITY_SPORT: "งานอดิเรก / กีฬา (Hobbies / Sport)",

  /* ------------------------------ COLLAPSE_S46 ------------------------------ */

  ASSETS_TYPE: "ประเภทสินทรัพย์",
  ASSETS_TYPE_NAME: "ชื่อสินทรัพย์",
  ASSETS_REMARK: "หมายเหตุ",
  ASSETS_DELIVERY_DATE: "วันที่ส่งมอบ",
  ASSETS_RETURN_DATE: "วันที่ส่งคืน",

  /* ------------------------------ COLLAPSE_S47 ------------------------------ */

  CRIME_SEND_DATE: "วันที่ส่งตรวจ",
  CRIME_RECIVED_DATE: "วันที่ได้รับผลตรวจ",
  CRIME_RESULT: "ผลตรวจอาชญากรรม",

  /* ------------------------------ COLLAPSE_S48 ------------------------------ */

  HEALTH_RESULT: "ผลการตรวจสุขภาพ",
  HEALTH_SMOKING: "สูบบุหรี่หรือไม่ ?",

  /* ------------------------------ COLLAPSE_S49 ------------------------------ */

  ADDICTIVE_DATE: "วันที่ตรวจ",
  ADDICTIVE_RESULT: "ผลการตรวจ",
  ADDICTIVE_REMARK: "หมายเหตุ",

  /* ------------------------------ COLLAPSE_S410 ------------------------------ */

  PROBATION_END_DATE: "วันผ่านทดลองงาน",
  PF_IN_DATE: "วันที่แจ้งเข้า PF",
  GROUP_INSURANCE_IN_DATE: "วันที่แจ้งเข้า ประกันกลุ่ม",
  GROUP_INSURANCE_TYPE: "แผนประกันกลุ่ม",
  INSURANCE_IN_DATE: "วันที่แจ้งเข้า ประกัน",
  INSURANCE_TYPE: "แผนประกัน",
  SSO_IN_DATE: "วันที่แจ้งเข้า สปส.",
  SSO_IN_NO: "เลขที่ธุรกรรม",
  SSO_OUT_DATE: "วันที่แจ้งออก สปส.",
  SSO_OUT_NO: "เลขที่ธุรกรรม",

  /* ------------------------------ COLLAPSE_S51 ------------------------------ */

  FILE_PERSONAL_ID: "สำเนาบัตรประชาชน",
  FILE_HOUSE_REGIS: "สำเนาทะเบียนบ้าน",
  FILE_TRANSCRIPT: "Transcript",
  FILE_RESUME: "Resume",
  FILE_LANG_TEST: "ผลวัดระดับทางภาษา",
  FILE_GUARANTEE: "เอกสารค้ำประกัน",
  FILE_BOOKBANK: "Bookbank",
  FILE_OTHER_1: "อื่นๆ",
};

/* -------------------------------------------------------------------------- */
/*                                FIELD_PAGES                                */
/* -------------------------------------------------------------------------- */

const FIELD_PAGES = {
  //!เปลี่ยนชื่อ Field ที่นี่

  /* ------------------------------ COLLAPSE_S41 ------------------------------ */

  // DRIVING_SKILL: 'drivingSkill',
  // OWNER_VEHICLE: 'ownerVehicle',

  /* ------------------------------ COLLAPSE_S42 ------------------------------ */

  COMPUTER_ABILITY: "computerAbility",
  OFFICE_ABILITY: "officeEquipmentAbility",

  /* ------------------------------ COLLAPSE_S45 ------------------------------ */

  ACTIVITY_EXTRA: "activityExtra",
  ACTIVITY_SPORT: "activitySport",

  /* ------------------------------ COLLAPSE_S46 ------------------------------ */

  ASSETS_TYPE: "assetsType",
  ASSETS_TYPE_NAME: "assetsTypeName",
  ASSETS_REMARK: "assetsRemark",
  ASSETS_DELIVERY_DATE: "assetsDeliveryDate",
  ASSETS_RETURN_DATE: "assetsReturnDate",

  /* ------------------------------ COLLAPSE_S47 ------------------------------ */

  CRIME_SEND_DATE: "crimeDendDate",
  CRIME_RECIVED_DATE: "crimeREcivedDate",
  CRIME_RESULT: "crimeResult",

  /* ------------------------------ COLLAPSE_S48 ------------------------------ */

  HEALTH_RESULT: "healthResult",
  HEALTH_SMOKING: "healthSmoking",

  /* ------------------------------ COLLAPSE_S49 ------------------------------ */

  ADDICTIVE_DATE: "addictiveDate",
  ADDICTIVE_RESULT: "addictiveResult",
  ADDICTIVE_REMARK: "addictiveRemark",

  /* ------------------------------ COLLAPSE_S410 ------------------------------ */

  PROBATION_END_DATE: "probationEndDate",
  PF_IN_DATE: "pfInDate",
  GROUP_INSURANCE_IN_DATE: "groupInsuranceInDate",
  GROUP_INSURANCE_TYPE: "groupInsuranceType",
  INSURANCE_IN_DATE: "insuranceInDate",
  INSURANCE_TYPE: "insuranceType",
  SSO_IN_DATE: "ssoInDate",
  SSO_IN_NO: "ssoInNo",
  SSO_OUT_DATE: "ssoOutDate",
  SSO_OUT_NO: "ssoOutNo",

  /* ------------------------------ COLLAPSE_S51 ------------------------------ */

  FILE_PERSONAL_ID: "personalCard",
  FILE_HOUSE_REGIS: "houseRegistration",
  FILE_TRANSCRIPT: "transcript",
  FILE_RESUME: "resume",
  FILE_LANG_TEST: "langTest",
  FILE_GUARANTEE: "guarantee",
  FILE_BOOKBANK: "bookbank",
  FILE_OTHER_1: "otherFile1",
  FILES_CURRENTS: "filesCurrents",
};
export const FIELD_OTHER = FIELD_PAGES;

/* -------------------------------------------------------------------------- */
/*                                INITIAL_PAGES                                */
/* -------------------------------------------------------------------------- */

/* ------------------------------ COLLAPSE_S46 ------------------------------ */

export const INITIAL_ASSETS = {
  assetsType: "",
  assetsTypeName: "",
  assetsDeliveryDate: "",
  assetsReturnDate: "",
};
/* ------------------------------ COLLAPSE_S47 ------------------------------ */

export const INITIAL_CRIME = {
  crimeDendDate: "",
  crimeREcivedDate: "",
  crimeResult: "",
};
/* ------------------------------ COLLAPSE_S49 ------------------------------ */

export const INITIAL_ADDICTIVE = {
  addictiveDate: "",
  addictiveResult: "",
  addictiveRemark: "",
};

/* -------------------------------------------------------------------------- */
/*                                   CONTENT                                  */
/* -------------------------------------------------------------------------- */

/* ------------------------------ COLLAPSE_S42 ------------------------------ */

export const CONTENT_ABILITY = (props) => {
  const { disabledAll } = props;
  /**
   * TODO: สามารถแบ่งตาม  location ได้ โดยใช้ switch case
   */
  return [
    [
      {
        span: 12,
        itemprops: {
          name: FIELD_PAGES.COMPUTER_ABILITY,
          label: LABEL_PAGES.COMPUTER_ABILITY, //ความสามารถทางคอมพิวเตอร์ (Computer programs)
          rules: [{ required: false, message: MESSAGE_ERROR.INPUT_AREA }],
        },
        inputprops: {
          type: KEY_TYPE.INPUT_AREA,
          disabled: disabledAll,
          placeholder: "",
        },
      },
      {
        span: 12,
        itemprops: {
          name: FIELD_PAGES.OFFICE_ABILITY,
          label: LABEL_PAGES.OFFICE_ABILITY, //ความสามารถทางการใช้อุปกรณ์สำนักงาน (Office equipment)
          rules: [{ required: false, message: MESSAGE_ERROR.INPUT_AREA }],
        },
        inputprops: {
          type: KEY_TYPE.INPUT_AREA,
          disabled: disabledAll,
          placeholder: "",
        },
      },
    ],
  ];
};

/* ------------------------------ COLLAPSE_S45 ------------------------------ */

export const CONTENT_ACTIVITY = (props) => {
  const { disabledAll } = props;
  /**
   * TODO: สามารถแบ่งตาม  location ได้ โดยใช้ switch case
   */
  return [
    [
      {
        span: 12,
        itemprops: {
          name: FIELD_PAGES.ACTIVITY_EXTRA,
          label: LABEL_PAGES.ACTIVITY_EXTRA, //กิจกรรมที่เคยทำ
          rules: [{ required: false, message: MESSAGE_ERROR.INPUT_AREA }],
        },
        inputprops: {
          type: KEY_TYPE.INPUT_AREA,
          disabled: disabledAll,
          placeholder: "",
        },
      },
      {
        span: 12,
        itemprops: {
          name: FIELD_PAGES.ACTIVITY_SPORT,
          label: LABEL_PAGES.ACTIVITY_SPORT, //งานอดิเรก
          rules: [{ required: false, message: MESSAGE_ERROR.INPUT_AREA }],
        },
        inputprops: {
          type: KEY_TYPE.INPUT_AREA,
          disabled: disabledAll,
          placeholder: "",
        },
      },
    ],
  ];
};

/* ------------------------------ COLLAPSE_S46 ------------------------------ */

export const CONTENT_ASSETS = (props) => {
  const { options, disabledAll } = props;
  /**
   * TODO: สามารถแบ่งตาม  location ได้ โดยใช้ switch case
   */
  return [
    [
      {
        span: 24,
        inputprops: {
          type: KEY_TYPE.CARD_FORM,
          name: FIELD_STEP.COLLAPSE_S46, //assetsInfo
          removelenght: 1,
          header: (idx) => `รายการที่ ${idx + 1}`,
          initialValues: { ...INITIAL_ASSETS },
          disabled: disabledAll,
          content: (field) => [
            {
              span: 4,
              itemprops: {
                name: [field.name, FIELD_PAGES.ASSETS_TYPE],
                label: LABEL_PAGES.ASSETS_TYPE, //ประเภทสินทรัพย์
                fieldKey: [field.fieldKey, FIELD_PAGES.ASSETS_TYPE],
                rules: [{ required: false, message: MESSAGE_ERROR.RADIO }],
              },
              inputprops: {
                type: KEY_TYPE.RADIO,
                disabled: disabledAll,
                placeholder: "",
                options: OPTION_ASSETS_TYPE,
              },
            },
            {
              span: 6,
              itemprops: {
                name: [field.name, FIELD_PAGES.ASSETS_TYPE_NAME],
                label: LABEL_PAGES.ASSETS_TYPE_NAME, // ชื่อสินทรัพย์
                fieldKey: [field.fieldKey, FIELD_PAGES.ASSETS_TYPE_NAME],
                rules: [{ required: false, message: MESSAGE_ERROR.SELECT }],
              },
              inputprops: {
                type: KEY_TYPE.SELECT,
                disabled: disabledAll,
                placeholder: "",
                options: options[KEY_OPTION.CENTER.OPTION_ASSETS_TYPE],
              },
            },
            {
              span: 6,
              itemprops: {
                name: [field.name, FIELD_PAGES.ASSETS_REMARK],
                label: LABEL_PAGES.ASSETS_REMARK, // หมายเหตุ
                fieldKey: [field.fieldKey, FIELD_PAGES.ASSETS_REMARK],
                rules: [{ required: false, message: MESSAGE_ERROR.INPUT }],
              },
              inputprops: {
                type: KEY_TYPE.INPUT,
                disabled: disabledAll,
                placeholder: "",
              },
            },
            {
              span: 4,
              itemprops: {
                name: [field.name, FIELD_PAGES.ASSETS_DELIVERY_DATE],
                label: LABEL_PAGES.ASSETS_DELIVERY_DATE, //วันที่ส่งมอบ
                fieldKey: [field.fieldKey, FIELD_PAGES.ASSETS_DELIVERY_DATE],
                rules: [
                  { required: false, message: MESSAGE_ERROR.DATE_PICKER },
                ],
              },
              inputprops: {
                type: KEY_TYPE.DATE_PICKER,
                disabled: disabledAll,
                placeholder: "",
              },
            },
            {
              span: 4,
              itemprops: {
                name: [field.name, FIELD_PAGES.ASSETS_RETURN_DATE],
                label: LABEL_PAGES.ASSETS_RETURN_DATE, //วันที่ส่งคืน
                fieldKey: [field.fieldKey, FIELD_PAGES.ASSETS_RETURN_DATE],
                rules: [
                  { required: false, message: MESSAGE_ERROR.DATE_PICKER },
                ],
              },
              inputprops: {
                type: KEY_TYPE.DATE_PICKER,
                disabled: disabledAll,
                placeholder: "",
              },
            },
          ],
        },
      },
    ],
  ];
};

/* ------------------------------ COLLAPSE_S47 ------------------------------ */

export const CONTENT_CRIME = (props) => {
  // const { location, options } = props
  const { disabledAll } = props;
  /**
   * TODO: สามารถแบ่งตาม  location ได้ โดยใช้ switch case
   */
  return [
    [
      {
        span: 24,
        inputprops: {
          type: KEY_TYPE.CARD_FORM,
          name: FIELD_STEP.COLLAPSE_S47, //crimeInfo
          removelenght: 1,
          header: (idx) => `รอบตรวจที่ ${idx + 1}`,
          initialValues: { ...INITIAL_CRIME },
          disabled: disabledAll,
          content: (field) => [
            {
              span: 12,
              itemprops: {
                name: [field.name, FIELD_PAGES.CRIME_SEND_DATE],
                label: LABEL_PAGES.CRIME_SEND_DATE, //วันที่ส่งตรวจ',
                fieldKey: [field.fieldKey, FIELD_PAGES.CRIME_SEND_DATE],
                rules: [
                  { required: false, message: MESSAGE_ERROR.DATE_PICKER },
                ],
              },
              inputprops: {
                type: KEY_TYPE.DATE_PICKER,
                disabled: disabledAll,
                placeholder: "",
              },
            },
            {
              span: 12,
              itemprops: {
                name: [field.name, FIELD_PAGES.CRIME_RECIVED_DATE],
                label: LABEL_PAGES.CRIME_RECIVED_DATE, //วันที่ได้รับผลตรวจ',
                fieldKey: [field.fieldKey, FIELD_PAGES.CRIME_RECIVED_DATE],
                rules: [
                  { required: false, message: MESSAGE_ERROR.DATE_PICKER },
                ],
              },
              inputprops: {
                type: KEY_TYPE.DATE_PICKER,
                disabled: disabledAll,
                placeholder: "",
              },
            },
            {
              span: 24,
              itemprops: {
                name: [field.name, FIELD_PAGES.CRIME_RESULT],
                label: LABEL_PAGES.CRIME_RESULT, //ผลตรวจ',
                fieldKey: [field.fieldKey, FIELD_PAGES.CRIME_RESULT],
                rules: [{ required: false, message: MESSAGE_ERROR.INPUT_AREA }],
              },
              inputprops: {
                type: KEY_TYPE.INPUT_AREA,
                disabled: disabledAll,
                placeholder: "",
              },
            },
          ],
        },
      },
    ],
  ];
};

/* ------------------------------ COLLAPSE_S48 ------------------------------ */

export const CONTENT_HEALTH = (props) => {
  // const { location, options } = props
  const { disabledAll } = props;

  /**
   * TODO: สามารถแบ่งตาม  location ได้ โดยใช้ switch case
   */
  return [
    [
      {
        span: 12,
        itemprops: {
          name: FIELD_PAGES.HEALTH_RESULT,
          label: LABEL_PAGES.HEALTH_RESULT, //ผลการตรวจสุขภาพ
          rules: [{ required: false, message: MESSAGE_ERROR.RADIO }],
        },
        inputprops: {
          type: KEY_TYPE.RADIO,
          disabled: disabledAll,
          placeholder: "",
          options: OPTION_HEALTH_RESULT,
        },
      },
      {
        span: 12,
        itemprops: {
          name: FIELD_PAGES.HEALTH_SMOKING,
          label: LABEL_PAGES.HEALTH_SMOKING, //สูบบุหรี่หรือไม่
          rules: [{ required: false, message: MESSAGE_ERROR.RADIO }],
        },
        inputprops: {
          type: KEY_TYPE.RADIO,
          disabled: disabledAll,
          placeholder: "",
          options: OPTION_SMOKING,
        },
      },
    ],
  ];
};

/* ------------------------------ COLLAPSE_S49 ------------------------------ */

export const CONTENT_ADDICTIVE = (props) => {
  // const { location, options } = props
  const { disabledAll } = props;
  /**
   * TODO: สามารถแบ่งตาม  location ได้ โดยใช้ switch case
   */
  return [
    [
      {
        span: 24,
        inputprops: {
          type: KEY_TYPE.CARD_FORM,
          name: FIELD_STEP.COLLAPSE_S49, //addictiveInfo
          removelenght: 1,
          header: (idx) => `รอบตรวจที่ ${idx + 1}`,
          initialValues: { ...INITIAL_ADDICTIVE },
          disabled: disabledAll,
          content: (field) => [
            {
              span: 12,
              itemprops: {
                name: [field.name, FIELD_PAGES.ADDICTIVE_DATE],
                label: LABEL_PAGES.ADDICTIVE_DATE, //วันที่ตรวจ
                fieldKey: [field.fieldKey, FIELD_PAGES.ADDICTIVE_DATE],
                rules: [
                  { required: false, message: MESSAGE_ERROR.DATE_PICKER },
                ],
              },
              inputprops: {
                type: KEY_TYPE.DATE_PICKER,
                disabled: disabledAll,
                placeholder: "",
              },
            },
            {
              span: 12,
              itemprops: {
                name: [field.name, FIELD_PAGES.ADDICTIVE_RESULT],
                label: LABEL_PAGES.ADDICTIVE_RESULT, //ผลการตรวจ
                fieldKey: [field.fieldKey, FIELD_PAGES.ADDICTIVE_RESULT],
                rules: [{ required: false, message: MESSAGE_ERROR.RADIO }],
              },
              inputprops: {
                type: KEY_TYPE.RADIO,
                disabled: disabledAll,
                placeholder: "",
                options: OPTION_HEALTH_RESULT,
              },
            },
            {
              span: 24,
              itemprops: {
                name: [field.name, FIELD_PAGES.ADDICTIVE_REMARK],
                label: LABEL_PAGES.ADDICTIVE_REMARK, //หมายเหตุ
                fieldKey: [field.fieldKey, FIELD_PAGES.ADDICTIVE_REMARK],
                rules: [{ required: false, message: MESSAGE_ERROR.INPUT_AREA }],
              },
              inputprops: {
                type: KEY_TYPE.INPUT_AREA,
                disabled: disabledAll,
                placeholder: "",
              },
            },
          ],
        },
      },
    ],
  ];
};

/* ------------------------------ COLLAPSE_S410 ------------------------------ */

export const CONTENT_OTHER = (props) => {
  // const { location, options } = props
  const { disabledAll } = props;
  /**
   * TODO: สามารถแบ่งตาม  location ได้ โดยใช้ switch case
   */
  return [
    [
      {
        span: 12,
        itemprops: {
          name: FIELD_PAGES.PROBATION_END_DATE,
          label: LABEL_PAGES.PROBATION_END_DATE, //วันผ่านทดลองงาน
          rules: [{ required: false, message: MESSAGE_ERROR.DATE_PICKER }],
        },
        inputprops: {
          type: KEY_TYPE.DATE_PICKER,
          disabled: disabledAll,
          placeholder: "",
        },
      },
      {
        span: 12,
        itemprops: {
          name: FIELD_PAGES.PF_IN_DATE,
          label: LABEL_PAGES.PF_IN_DATE, // วันที่แจ้งเข้า PF
          rules: [{ required: false, message: MESSAGE_ERROR.DATE_PICKER }],
        },
        inputprops: {
          type: KEY_TYPE.DATE_PICKER,
          disabled: disabledAll,
          placeholder: "",
        },
      },
      {
        span: 6,
        itemprops: {
          name: FIELD_PAGES.GROUP_INSURANCE_IN_DATE,
          label: LABEL_PAGES.GROUP_INSURANCE_IN_DATE, //วันที่แจ้งเข้า ประกันกลุ่ม
          rules: [{ required: false, message: MESSAGE_ERROR.DATE_PICKER }],
        },
        inputprops: {
          type: KEY_TYPE.DATE_PICKER,
          disabled: disabledAll,
          placeholder: "",
        },
      },
      {
        span: 6,
        itemprops: {
          name: FIELD_PAGES.GROUP_INSURANCE_TYPE,
          label: LABEL_PAGES.GROUP_INSURANCE_TYPE, //แผน',
          rules: [{ required: false, message: MESSAGE_ERROR.INPUT }],
        },
        inputprops: {
          type: KEY_TYPE.INPUT,
          disabled: disabledAll,
          placeholder: "",
        },
      },
      {
        span: 6,
        itemprops: {
          name: FIELD_PAGES.INSURANCE_IN_DATE,
          label: LABEL_PAGES.INSURANCE_IN_DATE, //วันที่แจ้งเข้า ประกัน',
          rules: [{ required: false, message: MESSAGE_ERROR.DATE_PICKER }],
        },
        inputprops: {
          type: KEY_TYPE.DATE_PICKER,
          disabled: disabledAll,
          placeholder: "",
        },
      },
      {
        span: 6,
        itemprops: {
          name: FIELD_PAGES.INSURANCE_TYPE,
          label: LABEL_PAGES.INSURANCE_TYPE, //แผน',
          rules: [{ required: false, message: MESSAGE_ERROR.INPUT }],
        },
        inputprops: {
          type: KEY_TYPE.INPUT,
          disabled: disabledAll,
          placeholder: "",
        },
      },
      {
        span: 6,
        itemprops: {
          name: FIELD_PAGES.SSO_IN_DATE,
          label: LABEL_PAGES.SSO_IN_DATE, //วันที่แจ้งเข้า สปส.',
          rules: [{ required: false, message: MESSAGE_ERROR.DATE_PICKER }],
        },
        inputprops: {
          type: KEY_TYPE.DATE_PICKER,
          disabled: disabledAll,
          placeholder: "",
        },
      },
      {
        span: 6,
        itemprops: {
          name: FIELD_PAGES.SSO_IN_NO,
          label: LABEL_PAGES.SSO_IN_NO, //เลขที่ธุรกรรม',
          rules: [{ required: false, message: MESSAGE_ERROR.NUMBER }],
        },
        inputprops: {
          type: KEY_TYPE.NUMBER,
          length: 13,
          disabled: disabledAll,
          placeholder: "",
        },
      },
      {
        span: 6,
        itemprops: {
          name: FIELD_PAGES.SSO_OUT_DATE,
          label: LABEL_PAGES.SSO_OUT_DATE, //วันที่แจ้งออก สปส.',
          rules: [{ required: false, message: MESSAGE_ERROR.DATE_PICKER }],
        },
        inputprops: {
          type: KEY_TYPE.DATE_PICKER,
          disabled: disabledAll,
          placeholder: "",
        },
      },
      {
        span: 6,
        itemprops: {
          name: FIELD_PAGES.SSO_OUT_NO,
          label: LABEL_PAGES.SSO_OUT_NO, //เลขที่ธุรกรรม',
          rules: [{ required: false, message: MESSAGE_ERROR.NUMBER }],
        },
        inputprops: {
          type: KEY_TYPE.NUMBER,
          length: 13,
          disabled: disabledAll,
          placeholder: "",
        },
      },
    ],
  ];
};

/* ------------------------------ COLLAPSE_S51 ------------------------------ */

export const CONTENT_FILES = (props) => {
  // const { location, options } = props
  const { disabledAll, userId } = props;
  const { userLogin } = useContext(ContractContext);
  /**
   * TODO: สามารถแบ่งตาม  location ได้ โดยใช้ switch case
   * TODO: เปลียน required
   */

  return [
    [
      {
        span: 12,
        itemprops: {
          name: FIELD_PAGES.FILE_PERSONAL_ID,
          label: LABEL_PAGES.FILE_PERSONAL_ID, //สำเนาบัตรประชาชน',
          rules: [
            {
              required: userId === userLogin,
              message: MESSAGE_ERROR.UPLOAD_FLIES,
            },
          ],
        },
        inputprops: {
          name: FIELD_PAGES.FILE_PERSONAL_ID,
          type: KEY_TYPE.UPLOAD_FLIES,
          listType: "picture",
          multiple: false,
          disabled: disabledAll,
          placeholder: "",
          size: 3,
          accept: ACCEPT_FILE,
          max: 1,
        },
      },
      {
        span: 12,
        itemprops: {
          name: FIELD_PAGES.FILE_HOUSE_REGIS,
          label: LABEL_PAGES.FILE_HOUSE_REGIS, //สำเนาทะเบียนบ้าน',
          rules: [
            {
              required: userId === userLogin,
              message: MESSAGE_ERROR.UPLOAD_FLIES,
            },
          ],
        },
        inputprops: {
          name: FIELD_PAGES.FILE_HOUSE_REGIS,
          type: KEY_TYPE.UPLOAD_FLIES,
          listType: "picture",
          multiple: false,
          disabled: disabledAll,
          placeholder: "",
          size: 3,
          accept: ACCEPT_FILE,
          max: 1,
        },
      },
      {
        span: 12,
        itemprops: {
          name: FIELD_PAGES.FILE_TRANSCRIPT,
          label: LABEL_PAGES.FILE_TRANSCRIPT, //Transcript',
          rules: [
            {
              //required: userId === userLogin,
              required: false,
              message: MESSAGE_ERROR.UPLOAD_FLIES,
            },
          ],
        },
        inputprops: {
          name: FIELD_PAGES.FILE_TRANSCRIPT,
          type: KEY_TYPE.UPLOAD_FLIES,
          listType: "picture",
          multiple: false,
          disabled: disabledAll,
          placeholder: "",
          size: 3,
          accept: ACCEPT_FILE,
          max: 1,
        },
      },
      {
        span: 12,
        itemprops: {
          name: FIELD_PAGES.FILE_RESUME,
          label: LABEL_PAGES.FILE_RESUME, //Resume',
          rules: [{ required: false, message: MESSAGE_ERROR.UPLOAD_FLIES }],
        },
        inputprops: {
          name: FIELD_PAGES.FILE_RESUME,
          type: KEY_TYPE.UPLOAD_FLIES,
          listType: "picture",
          multiple: false,
          disabled: disabledAll,
          placeholder: "",
          size: 3,
          accept: ACCEPT_FILE,
          max: 1,
        },
      },
      {
        span: 12,
        itemprops: {
          name: FIELD_PAGES.FILE_LANG_TEST,
          label: LABEL_PAGES.FILE_LANG_TEST, //ผลวัดระดับทางภาษา',
          rules: [{ required: false, message: MESSAGE_ERROR.UPLOAD_FLIES }],
        },
        inputprops: {
          name: FIELD_PAGES.FILE_LANG_TEST,
          type: KEY_TYPE.UPLOAD_FLIES,
          listType: "picture",
          multiple: false,
          disabled: disabledAll,
          placeholder: "",
          size: 3,
          accept: ACCEPT_FILE,
          max: 1,
        },
      },
      {
        span: 12,
        itemprops: {
          name: FIELD_PAGES.FILE_GUARANTEE,
          label: LABEL_PAGES.FILE_GUARANTEE, //เอกสารค้ำประกัน',
          rules: [{ required: false, message: MESSAGE_ERROR.UPLOAD_FLIES }],
        },
        inputprops: {
          name: FIELD_PAGES.FILE_GUARANTEE,
          type: KEY_TYPE.UPLOAD_FLIES,
          listType: "picture",
          multiple: false,
          disabled: disabledAll,
          placeholder: "",
          size: 3,
          accept: ACCEPT_FILE,
          max: 1,
        },
      },
      {
        span: 12,
        itemprops: {
          name: FIELD_PAGES.FILE_BOOKBANK,
          label: LABEL_PAGES.FILE_BOOKBANK, //Bookbank',
          rules: [
            {
              required: userId === userLogin,
              //required: true,
              message: MESSAGE_ERROR.UPLOAD_FLIES,
            },
          ],
        },
        inputprops: {
          name: FIELD_PAGES.FILE_BOOKBANK,
          type: KEY_TYPE.UPLOAD_FLIES,
          listType: "picture",
          multiple: false,
          disabled: disabledAll,
          placeholder: "",
          size: 3,
          accept: ACCEPT_FILE,
          max: 1,
        },
      },
      {
        span: 24,
        inputprops: {
          type: KEY_TYPE.DIVIDER,
        },
      },
      {
        span: 12,
        itemprops: {
          name: FIELD_PAGES.FILE_OTHER_1,
          label: LABEL_PAGES.FILE_OTHER_1, //อื่นๆ',
          rules: [{ required: false, message: MESSAGE_ERROR.UPLOAD_FLIES }],
        },
        inputprops: {
          name: FIELD_PAGES.FILE_OTHER_1,
          type: KEY_TYPE.UPLOAD_FLIES,
          listType: "picture",
          multiple: true,
          disabled: disabledAll,
          placeholder: "",
          size: 3,
          accept: ACCEPT_FILE,
          max: 10,
        },
      },
    ],
  ];
};

/* -------------------------------------------------------------------------- */
/*                                   OPTIONS                                  */
/* -------------------------------------------------------------------------- */

export const OPTION_SMOKING = [
  { optionId: 1, optionLabel: "ใช่ (Yes)", optionValue: "0" },
  { optionId: 2, optionLabel: "ไม่ใช่ (No)", optionValue: "1" },
];

export const OPTION_HEALTH_RESULT = [
  { optionId: 1, optionLabel: "ผ่าน (Pass)", optionValue: "0" },
  { optionId: 2, optionLabel: "ไม่ผ่าน (No Pass)", optionValue: "1" },
];

export const OPTION_EXPLAIN = [
  { optionId: 1, optionLabel: "ไม่เคย (No)", optionValue: "0" },
  { optionId: 2, optionLabel: "เคย (Yes, Explain)", optionValue: "1" },
];
export const OPTION_BECAUSE = [
  { optionId: 1, optionLabel: "ได้ (Yes)", optionValue: "1" },
  { optionId: 2, optionLabel: "ไม่ได้ (No, Because)", optionValue: " 0" },
];
export const OPTION_HAVE = [
  { optionId: 1, optionLabel: "ไม่มี (No)", optionValue: "0" },
  { optionId: 2, optionLabel: "มี (Yes, Name)", optionValue: "1" },
];
export const OPTION_ASSETS_TYPE = [
  { optionId: 1, optionLabel: "ส่วนตัว", optionValue: "0" },
  { optionId: 2, optionLabel: "บริษัท", optionValue: "1" },
];
