import React, { useState } from "react";
import { Form, Card, Spin } from "antd";
import { Sub_Department, USE_CONNECT_MODE_AUTO } from "../../services/ConnectAPI";
import { optionSelectLocation, useOptionSelect } from "../../constants/OptionSelect";
import { GET_UserLogin } from "../../functions/UseFunction";
import { useFixedQueryString } from "../../functions/UseMakeQueryString";
import { useConfirm } from "../modal/CustomConfirm";
import { useAlert } from "../modal/CustomAlert";
import { configForm, WORDS } from "../../constants/ConstantComponent";
import { useRenderFormItem } from "../RenderComponents";
import { ComponentsInsertSubDepartment } from "../../constants/ManageSubDepartment/Components";

export const FormAddSubDepartment = () => {
  const [form] = Form.useForm();
  const { userlogin } = GET_UserLogin();

  const [changeAddValue, setChangeAddValue] = useState({});
  const [loadingScreen, setLoadingScreen] = useState(false);

  let options = {
    companyCode: useOptionSelect("company"),
    depNo: useOptionSelect("department", useFixedQueryString(changeAddValue, ["companyCode"])),
    location: optionSelectLocation,
  }

  // [START] Handle Form Antd
  const onFinish = (values) => {
    const { companyCode, ...rest } = values;
    useConfirm({
      FnOK: () => insertSubDepartment({ ...rest, userlogin }),
    });
  };
  const onReset = () => {
    form.resetFields();
    setChangeAddValue({});
  };
  // [END] Handle Form Antd

  // [START] REST API
  const insertSubDepartment = async (value) => {
    try {
      setLoadingScreen(true);
      const url = `${USE_CONNECT_MODE_AUTO}${Sub_Department.POST_INSERT_Sub_Department}`;
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(value),
      });
      const data = await response.json();
      if (response.ok) {
        useAlert({
          type: "success",
          title: "บันทึกข้อมูลสำเร็จ",
          Fn: () => window.location.reload(),
        });
      } else {
        useAlert({
          type: "error",
          title: "เกิดข้อผิดพลาด",
          content: `${data.message}`,
          Fn: () => setLoadingScreen(false),
        });
      }
    } catch (err) {
      console.log("Error Insert Sub-Department: ", err);
      useAlert({ type: "catch", content: `${err.message}` });
      setLoadingScreen(false);
    }
  };
  // [END] REST API

  // [START] Functions
  const onChangeValue = (val, name) => {
    let setUndefined = {};
    if (name === "companyCode") {
      setUndefined = { depNo: undefined };
    }
    form.setFieldsValue(setUndefined);
    setChangeAddValue((prev) => ({ ...prev, ...setUndefined, [name]: val }));
  };
  // [END] Functions
  return (
    <>
      <Card title="เพิ่มข้อมูลแผนกย่อย" style={{ marginBottom: 30 }}>
        <Spin tip={WORDS.loadingSpin} spinning={loadingScreen}>
          <Form form={form} onFinish={onFinish} {...configForm}>
            {useRenderFormItem({
              constantComponent: ComponentsInsertSubDepartment({
                options,
                onClick: onReset,
                handleChange: onChangeValue,
              })
            })}
          </Form>
        </Spin>
      </Card>
    </>
  );
};

export default FormAddSubDepartment;
