import { KEY_ACTIVE_TAB } from "../../../constants/enums/enumCenter";
import { TYPE } from "../../../moduleKPIs/common/inputs";
import { MASTER_OPTION } from "../../../moduleKPIs/services/servicesOptions";

export const SEARCH_DASHBOARD_OT = ({ activeTab, permission, options, initialData }) => {
    //console.log({ activeTab })
    switch (permission) {
        default:
            return [{
                span: 8,
                type: TYPE.RANG_PICKER,
                header: 'ช่วงวันที่',
                inputOption: {
                    fieldname: 'activeDate',
                    defaultValue: [],
                    disabled: false,
                    placeholder: ['เริ่มต้นวันที่', 'ถึงวันที่']
                }
            }, {
                span: 8,
                type: TYPE.SELECT_MULTIPLE,
                header: 'แผนกย่อย',
                required: true,
                message: 'กรุณากรอก ระบุแผนกย่อย',
                inputOption: {
                    fieldname: MASTER_OPTION.FILTER_SUB_DEP_OT,
                    fieldOption: `OPTION_${MASTER_OPTION.FILTER_SUB_DEP_OT}`,
                    plainOptions: options[`OPTION_${MASTER_OPTION.FILTER_SUB_DEP_OT}`],
                    defaultValue: [],
                    disabled: false,
                    placeholder: '--- ทั้งหมด ---'
                }
            }, {
                span: 8,
                type: TYPE.SELECT_MULTIPLE,
                header: 'สถานะ',
                required: true,
                message: 'กรุณากรอก ระบุแผนกย่อย',
                inputOption: {
                    fieldname: MASTER_OPTION.FILTER_STATUS_OT,
                    fieldOption: `OPTION_${MASTER_OPTION.FILTER_STATUS_OT}`,
                    plainOptions: activeTab === KEY_ACTIVE_TAB.DASHBOARD_APPROVAL_OT ?
                        options[`OPTION_${MASTER_OPTION.FILTER_STATUS_OT}`].filter((e) => e.optionValue !== 'df' && e.optionValue !== 'apd')
                        :
                        options[`OPTION_${MASTER_OPTION.FILTER_STATUS_OT}`],
                    defaultValue: [],
                    disabled: false,
                    placeholder: '--- ทั้งหมด ---'
                }
            }]
    }
}

export const SEARCH_EXPORT_OT = ({ permission, options, initialData }) => {
    switch (permission) {
        default:
            return [{
                span: 12,
                type: TYPE.RANG_PICKER,
                header: 'ช่วงวันที่',
                inputOption: {
                    fieldname: 'activeDate',
                    defaultValue: [''],
                    disabled: false,
                    placeholder: ['เริ่มต้นวันที่', 'ถึงวันที่']
                }
            }, {
                span: 12,
                type: TYPE.SELECT,
                header: 'แผนกย่อย',
                required: true,
                message: 'กรุณากรอก ระบุแผนกย่อย',
                inputOption: {
                    fieldname: MASTER_OPTION.FILTER_SUB_DEP_OT,
                    fieldOption: `OPTION_${MASTER_OPTION.FILTER_SUB_DEP_OT}`,
                    plainOptions: options[`OPTION_${MASTER_OPTION.FILTER_SUB_DEP_OT}`],
                    defaultValue: '',
                    disabled: false,
                    placeholder: '--- ทั้งหมด ---'
                }
            }]
    }
}