import { Form, Input, Modal, Radio, DatePicker } from "antd";
import React, { useState, useEffect } from "react";
import { useAlert } from "../screens/conponents/modal/CustomAlert";
import { PUT, PUT_CONFIRM_RECEIVED  } from "../service";
import moment from "moment";

const ModalConfirmReceived = ({
  dataRecord,
  modalVisible,
  setModalVisible,
  onCloseModal,
}) => {
  const [form] = Form.useForm();
  const [data, setData] = useState({
    note: "",
    status: "RECEIVED",
    dateReceived: moment().format('YYYY-MM-DD')
  });

  useEffect(() => {
    form.setFieldsValue(dataRecord);
  }, [form, dataRecord]);

  const onChangeValue = (fieldName, value) => {
    setData({ ...data, [fieldName]: value });
  };

  const onSubmit = () => {
    const objBody = {
      userId: dataRecord.userId,
      userReceivedDate: data.dateReceived
    };
    Modal.confirm({
      title: "ยืนยันการบันทึก",
      onOk: () => {onPutConfirmReceived(objBody)
      },
    });
  };

  const onPutConfirmReceived = async (objBody) => {
    try {
      const res = await PUT(PUT_CONFIRM_RECEIVED(), objBody);
      const { success } = res;
      if (success) {
        return Modal.success({
          title: "บันทึกสำเร็จ",
          onOk: () => onCloseModal(),
        });
      }
    } catch (err) {
      useAlert({ type: "catch", content: err.message });
      console.error("Error Put: ", err);
    } finally {
    }
  };

  return (
    <Form
      form={form}
      initialValues={dataRecord}
      labelCol={{
        span: 6,
      }}
      wrapperCol={{
        span: 18,
      }}
      onFinish={onSubmit}
    >
      <Modal
        visible={modalVisible}
        title={"รับสินทรัพย์โดยแอดมิน"}
        onCancel={() => setModalVisible(false)}
        onOk={onSubmit}
      >
        <Form.Item label="รหัสพนักงาน" name="userId">
          <Input disabled />
        </Form.Item>
        <Form.Item label="วันที่รับสินทรัพย์" name="dateReceived">
          <DatePicker 
          onChange={(dateString) => {
            onChangeValue("dateReceived", dateString);
          }}
          />
        </Form.Item>
        <Form.Item
          name="status"
          rules={[{ required: true, message: "Please select an option!" }]}
        >
          <Radio.Group
            defaultValue={"RECEIVED"}
            onChange={(e) => {
              onChangeValue("status", e.target.value);
            }}
            style={{ display: "flex", justifyContent: "center" }}
          >
            <Radio value="RECEIVED">เสร็จสิ้น </Radio>
          </Radio.Group>
        </Form.Item>
      </Modal>
    </Form>
  );
};

export default ModalConfirmReceived;
