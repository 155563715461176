import React from "react";
import { Tabs } from "antd";
import FormAddTraining from "./conponents/forms/FormAddTraining";
import FormSearchTraining from "./conponents/forms/FormSearchTraining";

export const ManageTraining = () => {
  const { TabPane } = Tabs;
  return (
    <>
      <Tabs defaultActiveKey="1">
        <TabPane tab="ดูข้อมูลหลักสูตรหลัก" key="1">
          <FormSearchTraining />
        </TabPane>
        <TabPane tab="เพิ่มข้อมูลหลักสูตรหลัก" key="2">
          <FormAddTraining />
        </TabPane>
      </Tabs>
    </>
  );
};

export default ManageTraining;
