import React, { createContext, useReducer, useContext } from "react";
import { FIELD_BTN } from "../../commonsEvac/structuresEvac/buttonEvac";
import { PATHNAME } from "../../../constants/enums/pathname";


export const ManageEvacContext = createContext();

const initialState = {
    userData: []
}

const MANAGE_EVAC_ACTION = {
    SET_MANAGE_EVAC_SUCCESS: "SET_MANAGE_EVAC_SUCCESS",
    SET_MANAGE_EVAC_FAILURE: "SET_MANAGE_EVAC_FAILURE"
}

const manageEvacReducer = (state, action) => {
    switch (action.type) {
        case MANAGE_EVAC_ACTION.SET_MANAGE_EVAC_SUCCESS:
            return { ...state, ...action.state }
        case MANAGE_EVAC_ACTION.SET_MANAGE_EVAC_FAILURE:
        default:
            return state
    }
}


const ProviderManageEvac = ({ children }) => {
    const { userLogin, userPermission } = children.props;
    const [manageEvacState, manageEvacDispatch] = useReducer(manageEvacReducer, initialState)


    async function onClickButton({ fieldBtn, pathname, payload }) {
        switch (fieldBtn) {
            case FIELD_BTN.DELETE_EVALUTOR:
                console.warn('DELETE_EVALUTOR :', { pathname, payload })
                break;
            default:
                console.error('FIELD NAME NOT FOUND')
                break;
        }
    }


    return (
        <ManageEvacContext.Provider
            value={{
                userLogin: { userId: userLogin, userPermission },
                manageEvacState,
                onClickButton,
                manageEvacDispatch
            }}>
            {children}
        </ManageEvacContext.Provider>
    )
}


export default ProviderManageEvac