import { COMPONENT_TYPE, ERROR_MESSAGE } from "..";

export const INIT_VALUE = {
    //! --- Modal [Pass] ----
    // --- 1 ----
    passDate: "",
    passType: "",
    grade: "",
    contractExtension: "",
    remark: "",
    // --- 2 ----
    recruiterGrade: "",
    recruiterScore: "",
    // --- 3 ----
    checkPass: false,
    outDate: "",
    //! --- Modal [Cancel] ----
    remarkCancel: "",
}

const DEFINE = {
    //! --- Modal [Pass] ---
    // --- 3 ----
    passDate: {
        label: "วันที่ผ่าน",
        name: "passDate",
    },
    passType: {
        label: "สถานะการผ่าน",
        name: "passType",
    },
    grade: {
        label: "เกรด",
        name: "grade",
    },
    contractExtension: {
        label: "ลักษณะการต่อสัญญาจ้าง",
        name: "contractExtension",
    },
    remark: {
        label: "หมายเหตุเพิ่มเติม",
        name: "remark",
    },
    // --- 2 ----
    recruiterGrade: {
        label: "ประเมินคุณภาพการสรรหา (ศักยภาพพนักงานตามคุณภาพการสรรหา)",
        name: "recruiterGrade",
    },
    recruiterScore: {
        label: "ความพึงพอใจต่อการสรรหาตำแหน่งนี้",
        name: "recruiterScore",
    },
    // --- 3 ----
    checkPass: {
        label: "แจ้งพ้นสภาพ",
        text: "ให้ระบบดำเนินการแจ้งพ้นสภาพอัตโนมัติ",
        name: "checkPass",
    },
    outDate: {
        label: "วันที่พ้นสภาพ",
        name: "outDate",
    },
    //! --- Modal [Cancel] ---
    remarkCancel: {
        label: "เหตุผลในการยกเลิกเอกสาร",
        name: "remarkCancel",
    }
}

export const ComponentModalPassProbation = ({
    options = {},
    isDisable = {},
    require = {},
    handleChange = () => null,
}) => {
    const { passDate, passType, grade, remark } = DEFINE
    return [
        { // Row
            rowProps: { justify: "start", align: "bottom" },
            column: [ // column
                {
                    breakpoint: { xs: 24, sm: 8, md: 8, lg: 8, xl: 8 },
                    component: {
                        type: COMPONENT_TYPE.DATE,
                        itemProps: {
                            name: passDate.name,
                            label: passDate.label,
                            rules: [{ required: require[passDate.name], message: ERROR_MESSAGE.DATEPICKER }],
                        },
                        componentProps: {
                            style: { width: "100%" },
                            placeholder: ERROR_MESSAGE.DATEPICKER,
                            disabled: isDisable[passDate.name] || false,
                        },
                        fnChange: (date, string) => handleChange(passDate.name, string),
                    },
                },
                {
                    breakpoint: { xs: 24, sm: 8, md: 8, lg: 8, xl: 8 },
                    component: {
                        type: COMPONENT_TYPE.SELECT,
                        itemProps: {
                            name: passType.name,
                            label: passType.label,
                            rules: [{ required: true, message: ERROR_MESSAGE.SELECT(passType.label) }],
                        },
                        componentProps: {
                            style: { width: "100%" },
                            placeholder: ERROR_MESSAGE.SELECT(passType.label),
                            disabled: isDisable[passType.name] || false,
                        },
                        fnChange: (value) => handleChange(passType.name, value),
                        dropdown: options[passType.name],
                    },
                },
                {
                    breakpoint: { xs: 24, sm: 8, md: 8, lg: 8, xl: 8 },
                    component: {
                        type: COMPONENT_TYPE.SELECT,
                        itemProps: {
                            name: grade.name,
                            label: grade.label,
                            rules: [{ required: require[grade.name], message: ERROR_MESSAGE.SELECT(grade.label) }],
                        },
                        componentProps: {
                            style: { width: "100%" },
                            placeholder: ERROR_MESSAGE.SELECT(grade.label),
                            disabled: isDisable[grade.name] || false,
                        },
                        fnChange: (value) => handleChange(grade.name, value),
                        dropdown: options[grade.name],
                    },
                },
                {
                    breakpoint: { xs: 24, sm: 24, md: 24, lg: 24, xl: 24 },
                    component: {
                        type: COMPONENT_TYPE.INPUT,
                        itemProps: {
                            name: remark.name,
                            label: remark.label,
                            rules: [{ required: false, message: ERROR_MESSAGE.INPUT(remark.label) }],
                        },
                        componentProps: {
                            style: { width: "100%" },
                            placeholder: ERROR_MESSAGE.INPUT(remark.label),
                            disabled: isDisable[remark.name] || false,
                        },
                        fnChange: (event) => handleChange(remark.name, event.target.value),
                    },
                },
            ], // column
        }, // Row
    ]
}

export const ComponentModalPassProbationContract = ({
    options = {},
    isDisable = {},
    require = {},
    handleChange = () => null,
}) => {
    const { passDate, passType, grade, contractExtension, remark } = DEFINE
    return [
        { // Row
            rowProps: { justify: "start", align: "bottom" },
            column: [ // column
                {
                    breakpoint: { xs: 24, sm: 12, md: 8, lg: 8, xl: 8 },
                    component: {
                        type: COMPONENT_TYPE.DATE,
                        itemProps: {
                            name: passDate.name,
                            label: passDate.label,
                            rules: [{ required: require[passDate.name], message: ERROR_MESSAGE.DATEPICKER }],
                        },
                        componentProps: {
                            style: { width: "100%" },
                            placeholder: ERROR_MESSAGE.DATEPICKER,
                            disabled: isDisable[passDate.name] || false,
                        },
                        fnChange: (date, string) => handleChange(passDate.name, string),
                    },
                },
                {
                    breakpoint: { xs: 24, sm: 12, md: 8, lg: 8, xl: 8 },
                    component: {
                        type: COMPONENT_TYPE.SELECT,
                        itemProps: {
                            name: passType.name,
                            label: passType.label,
                            rules: [{ required: true, message: ERROR_MESSAGE.SELECT(passType.label) }],
                        },
                        componentProps: {
                            style: { width: "100%" },
                            placeholder: ERROR_MESSAGE.SELECT(passType.label),
                            disabled: isDisable[passType.name] || false,
                        },
                        fnChange: (value) => handleChange(passType.name, value),
                        dropdown: options[passType.name],
                    },
                },
                {
                    breakpoint: { xs: 24, sm: 12, md: 8, lg: 8, xl: 8 },
                    component: {
                        type: COMPONENT_TYPE.SELECT,
                        itemProps: {
                            name: grade.name,
                            label: grade.label,
                            rules: [{ required: require[grade.name], message: ERROR_MESSAGE.SELECT(grade.label) }],
                        },
                        componentProps: {
                            style: { width: "100%" },
                            placeholder: ERROR_MESSAGE.SELECT(grade.label),
                            disabled: isDisable[grade.name] || false,
                        },
                        fnChange: (value) => handleChange(grade.name, value),
                        dropdown: options[grade.name],
                    },
                },
                {
                    breakpoint: { xs: 24, sm: 12, md: 12, lg: 12, xl: 12 },
                    component: {
                        type: COMPONENT_TYPE.SELECT,
                        itemProps: {
                            name: contractExtension.name,
                            label: contractExtension.label,
                            rules: [{ required: true, message: ERROR_MESSAGE.SELECT(contractExtension.label) }],
                        },
                        componentProps: {
                            style: { width: "100%" },
                            placeholder: ERROR_MESSAGE.SELECT(contractExtension.label),
                            disabled: isDisable[contractExtension.name] || false,
                        },
                        fnChange: (value) => handleChange(contractExtension.name, value),
                        dropdown: options[contractExtension.name],
                    },
                },
                {
                    breakpoint: { xs: 24, sm: 24, md: 12, lg: 12, xl: 12 },
                    component: {
                        type: COMPONENT_TYPE.INPUT,
                        itemProps: {
                            name: remark.name,
                            label: remark.label,
                            rules: [{ required: false, message: ERROR_MESSAGE.INPUT(remark.label) }],
                        },
                        componentProps: {
                            style: { width: "100%" },
                            placeholder: ERROR_MESSAGE.INPUT(remark.label),
                            disabled: isDisable[remark.name] || false,
                        },
                        fnChange: (event) => handleChange(remark.name, event.target.value),
                    },
                },
            ], // column
        }, // Row
    ]
}

export const ComponentModalPassProbation_2 = ({
    options = [],
    isDisable = {},
    require = {},
    handleChange = () => null,
}) => {
    const { recruiterGrade, recruiterScore } = DEFINE
    return [
        { // Row
            rowProps: { justify: "start", align: "bottom" },
            column: [ // column
                {
                    breakpoint: { xs: 24, sm: 24, md: 24, lg: 24, xl: 24 },
                    component: {
                        type: COMPONENT_TYPE.SELECT,
                        itemProps: {
                            name: recruiterGrade.name,
                            label: recruiterGrade.label,
                            rules: [{ required: require[recruiterGrade.name], message: ERROR_MESSAGE.SELECT(recruiterGrade.label) }],
                        },
                        componentProps: {
                            style: { width: "100%" },
                            placeholder: ERROR_MESSAGE.SELECT(recruiterGrade.label),
                            disabled: isDisable[recruiterGrade.name] || false,
                        },
                        fnChange: (value) => handleChange(recruiterGrade.name, value),
                        dropdown: options[recruiterGrade.name],
                    },
                },
                {
                    breakpoint: { xs: 24, sm: 24, md: 24, lg: 24, xl: 24 },
                    component: {
                        type: COMPONENT_TYPE.SELECT,
                        itemProps: {
                            name: recruiterScore.name,
                            label: recruiterScore.label,
                            rules: [{ required: require[recruiterScore.name], message: ERROR_MESSAGE.SELECT(recruiterScore.label) }],
                        },
                        componentProps: {
                            style: { width: "100%" },
                            placeholder: ERROR_MESSAGE.SELECT(recruiterScore.label),
                            disabled: isDisable[recruiterScore.name] || false,
                        },
                        fnChange: (value) => handleChange(recruiterScore.name, value),
                        dropdown: options[recruiterScore.name],
                    },
                },

            ], // column
        }, // Row
    ]
}

export const ComponentModalPassProbation_3 = ({
    isDisable = {},
    require = {},
    handleChange = () => null,
}) => {
    const { checkPass, outDate } = DEFINE
    return [
        { // Row
            rowProps: { justify: "start", align: "top" },
            column: [ // column
                {
                    breakpoint: { xs: 24, sm: 12, md: 12, lg: 12, xl: 12 },
                    component: {
                        type: COMPONENT_TYPE.CHECK_BOX,
                        itemProps: {
                            name: checkPass.name,
                            label: checkPass.label,
                            valuePropName: "checked",
                            rules: [{ required: false }],
                        },
                        componentProps: {
                            disabled: isDisable[checkPass.name] || false,
                        },
                        fnChange: (event) => handleChange(checkPass.name, event.target.checked),
                        text: checkPass.text,
                    },
                },
                {
                    breakpoint: { xs: 24, sm: 12, md: 12, lg: 12, xl: 12 },
                    component: {
                        type: COMPONENT_TYPE.DATE,
                        itemProps: {
                            name: outDate.name,
                            label: outDate.label,
                            rules: [{ required: require[outDate.name], message: ERROR_MESSAGE.DATEPICKER }],
                        },
                        componentProps: {
                            style: { width: "100%" },
                            placeholder: ERROR_MESSAGE.DATEPICKER,
                            disabled: isDisable[outDate.name] || false,
                        },
                        fnChange: (date, string) => handleChange(outDate.name, string),
                    },
                },

            ], // column
        }, // Row
    ]
}

export const ComponentModalCancelDoc = ({
    isDisable = {},
    handleChange = () => null,
}) => {
    const { remarkCancel } = DEFINE
    return [
        { // Row
            rowProps: { justify: "start", align: "bottom" },
            column: [ // column
                {
                    breakpoint: { xs: 24, sm: 24, md: 24, lg: 24, xl: 24 },
                    component: {
                        type: COMPONENT_TYPE.INPUT,
                        itemProps: {
                            name: remarkCancel.name,
                            label: remarkCancel.label,
                            rules: [{ required: true, message: ERROR_MESSAGE.INPUT(remarkCancel.label) }],
                        },
                        componentProps: {
                            style: { width: "100%" },
                            placeholder: ERROR_MESSAGE.INPUT(remarkCancel.label),
                            disabled: isDisable[remarkCancel.name] || false,
                        },
                        fnChange: (event) => handleChange(event.target.value),
                    },
                },
            ], // column
        }, // Row
    ]
}