import React from "react";
import FormSearchRoleUser from "./conponents/forms/FormSearchRoleUser";

const ManageCustomizeRole = () => {
  return (
    <>
      <FormSearchRoleUser />
    </>
  );
};

export default ManageCustomizeRole;
