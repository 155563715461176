import React, { useState, useEffect } from "react";
import { Form, Card, Spin } from "antd";
import { Sub_Department, USE_CONNECT_MODE_AUTO } from "../../services/ConnectAPI";
import { optionSelectLocation, useOptionSelect } from "../../constants/OptionSelect";
import { browserHistory } from "react-router";
import { GET_UserLogin } from "../../functions/UseFunction";
import { useAlert } from "../modal/CustomAlert";
import { useConfirm } from "../modal/CustomConfirm";
import { configForm, WORDS } from "../../constants/ConstantComponent";
import { useRenderFormItem } from "../RenderComponents";
import { ComponentsInsertSubDepartment } from "../../constants/ManageSubDepartment/Components";

export const FormEditSubDepartment = (props) => {
  const [form] = Form.useForm();
  const { SubDepartmentNo } = props.params;
  const { userlogin } = GET_UserLogin();
  const [loadingScreen, setLoadingScreen] = useState(false);

  let options = {
    companyCode: useOptionSelect("company"),
    depNo: useOptionSelect("department"),
    location: optionSelectLocation,
  }

  // [START] useEffect
  useEffect(() => {
    getUpdateSubDepartment();
  }, []);
  // [END] useEffect

  // [START] Handle Form Antd
  const onFinish = (values) => {
    const { subDepName } = values;
    const objectValue = { subDepName, userlogin };
    useConfirm({ FnOK: () => updateSubDepartment(objectValue) });
  };
  const onReset = () => {
    getUpdateSubDepartment();
  };
  // [END] Handle Form Antd

  // [START] REST API
  const getUpdateSubDepartment = async () => {
    try {
      setLoadingScreen(true);
      const url = `${USE_CONNECT_MODE_AUTO}${Sub_Department.GET_UPDATE_Sub_Department}${SubDepartmentNo}`;
      const response = await fetch(url);
      const data = await response.json();
      if (response.ok) {
        const RESULT = data.result;
        const { company, depNo, location, subDepName } = RESULT;
        const dataForm = {
          companyCode: company,
          depNo,
          location,
          subDepNo: SubDepartmentNo,
          subDepName,
        };
        form.setFieldsValue(dataForm);
      }
    } catch (err) {
      console.log("Error Get Update Sub-Department: ", err);
    } finally {
      setLoadingScreen(false);
    }
  };

  const updateSubDepartment = async (objectValue) => {
    try {
      setLoadingScreen(true);
      const url = `${USE_CONNECT_MODE_AUTO}${Sub_Department.PUT_UPDATE_Sub_Department}${SubDepartmentNo}`;
      const response = await fetch(url, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(objectValue),
      });
      const data = await response.json();
      if (response.ok) {
        useAlert({
          type: "success",
          title: "บันทึกข้อมูลสำเร็จ",
          Fn: () => browserHistory.push("/ManageSubDepartment"),
        });
      } else {
        useAlert({
          type: "success",
          title: "เกิดข้อผิดพลาด",
          content: `${data.message}`,
          Fn: () => setLoadingScreen(false),
        });
      }
    } catch (err) {
      setLoadingScreen(false);
      console.log("Error UPDATE Sub-Department: ", err);
      useAlert({ type: "catch", content: `${err.message}` });
    }
  };
  // [END] REST API
  return (
    <>
      <Card title="แก้ไขข้อมูลแผนกย่อย" style={{ marginBottom: 30 }}>
        <Spin tip={WORDS.loadingSpin} spinning={loadingScreen}>
          <Form form={form} onFinish={onFinish} {...configForm}>
            {useRenderFormItem({
              constantComponent: ComponentsInsertSubDepartment({
                options,
                onClick: onReset,
                isDisable: {
                  companyCode: true,
                  depNo: true,
                  location: true,
                  subDepNo: true,
                }
              })
            })}
          </Form>
        </Spin>
      </Card>
    </>
  );
};

export default FormEditSubDepartment;
