import React, { useState } from "react";
import { Form, Card, Spin, Space, Row, Col } from "antd";
import { Transfers, USE_CONNECT_MODE_AUTO } from "../../services/ConnectAPI";
import {
  optionSelectLocation,
  useOptionSelect,
} from "../../constants/OptionSelect";
import {
  GET_UserLogin,
  toUndefined,
  sumColumnWidth,
} from "../../functions/UseFunction";
import { useFixedQueryString } from "../../functions/UseMakeQueryString";
import { useConfirm } from "../modal/CustomConfirm";
import { useAlert } from "../modal/CustomAlert";
import { configForm } from "../../constants/ConstantComponent";
import { useRenderFormItem } from "../RenderComponents";
import {
  actionChangeCompany,
  searchEmployee,
} from "../../constants/TransferEmployee/ComponentTransferCompany";
import CustomTableAtnd from "../tables/CustomTableAtnd";
import { useColumnTable } from "../../constants/UseColumnTable";
import { RenderMainModal } from "../modal/CustomModalAntd";
import { useText } from "../typography/Typography";
import { useNotification } from "../notification/NotificationWithIcon";
import { POST } from "../../../service";
import { useButton } from "../buttons/CustomButtonAntd";
import { Header_ChangeCompany } from "../../constants/HeaderExcel";
import { useExportExcel } from "../../services/ExportExcel";

const FormTransferCompany = () => {
  const [form] = Form.useForm();
  const { userlogin } = GET_UserLogin();
  // [START] States
  const [ChangeValue, setChangeValue] = useState({});
  const [ChangeActionValue, setChangeActionValue] = useState({});
  const [dataTable, setDataTable] = useState([]);
  const [dataTableModal, setDataTableModal] = useState([]);
  const [selectRowKey, setSelectRowKey] = useState([]);
  const [modalshow, setModalshow] = useState(false);
  const [loadingScreen, setLoadingScreen] = useState(false);
  const [loadingModal, setLoadingModal] = useState(false);

  const typeMove = "company"; // company, dep, subDep, hrmFlow, viewFlow, position
  // [END] States

  let optionSearch = {
    company: useOptionSelect("company"),
    location: optionSelectLocation,
    depNo: useOptionSelect(
      "department",
      useFixedQueryString(ChangeValue, ["companyCode", "location"])
    ),
    subDepNo: useOptionSelect(
      "subDepartment",
      useFixedQueryString(ChangeValue, ["company", "location", "depNo"])
    ),
    hrmFlow: useOptionSelect(
      "approvalActive",
      useFixedQueryString(ChangeValue, ["company", "location"])
    ),
    viewFlow: useOptionSelect("viewFlow"),
    positionNo: useOptionSelect(
      "position",
      useFixedQueryString(ChangeValue, ["companyCode"])
    ),
  };

  let optionAction = {
    newCompany: useOptionSelect("company"),
    newLocation: optionSelectLocation,
    newDepNo: useOptionSelect(
      "department",
      useFixedQueryString(ChangeActionValue, ["companyCode", "location"])
    ),
    newSubDepNo: useOptionSelect(
      "subDepartment",
      useFixedQueryString(ChangeActionValue, ["company", "location", "depNo"])
    ),
    newHrmFlow: useOptionSelect(
      "approvalActive",
      useFixedQueryString(ChangeActionValue, ["company", "location"])
    ),
    newPositionNo: useOptionSelect(
      "position",
      useFixedQueryString(ChangeActionValue, ["companyCode"])
    ),
  };

  // [START] Handle Form Antd
  const onFinish = (values) => {
    let bodyRaw = {};
    let dataLine = [];
    if (selectRowKey.length !== 0) {
      dataLine = selectRowKey.map((userId) => ({ userId }));
      bodyRaw = {
        dataHeader: {
          newCompany: values.newCompany || "",
          newLocation: values.newLocation || "",
          newDepNo: values.newDepNo || "",
          newSubDepNo: values.newSubDepNo || "",
          newHrmFlow: values.newHrmFlow || "",
          newViewFlow: values.newViewFlow || "",
          newPositionNo: values.newPositionNo || "",
          actionDate: values.newActionDate.format("YYYY-MM-DD"),
          userLogin: userlogin,
          typeMove,
        },
        dataLine,
      };
      useConfirm({ FnOK: () => POST_insertDataMovement(bodyRaw) });
    } else {
      useAlert({
        type: "warning",
        title: `คุณยังไม่ได้เลือก "พนักงาน" กรุณาลองใหม่อีกครั้ง`,
        Fn: () => null,
      });
    }
  };

  const onReset = () => {
    form.resetFields();
    setSelectRowKey([]);
    setDataTable([]);
    setChangeValue({});
    setChangeActionValue({});
  };

  const POST_searchDataDashboardMovement = async (objectValue) => {
    try {
      let bodyRaw = {
        company: objectValue.company || "",
        location: objectValue.location || "",
        depNo: objectValue.depNo || "",
        subDepNo: objectValue.subDepNo || "",
        hrmFlow: objectValue.hrmFlow || "",
        viewFlow: objectValue.viewFlow || "",
        position: objectValue.positionNo || "",
        typeMove,
      };
      setLoadingScreen(true);
      setSelectRowKey([]);
      const url = `${USE_CONNECT_MODE_AUTO}${Transfers.POST_selectDashboardMovement}`;
      const response = await POST(url, bodyRaw);
      const { result } = response;
      let mapResult = [];
      if (result.length !== 0) {
        mapResult = result.map((row) => ({
          key: row.userId,
          ...row,
        }));
      } else {
        useNotification({ type: "info", desc: "ไม่พบข้อมูล" });
      }
      setDataTable(mapResult);
    } catch (err) {
      console.log("Erro Search Movement: ", err);
    } finally {
      setLoadingScreen(false);
    }
  };
  // [END] Handle Form Antd

  // [START] REST API
  const POST_insertDataMovement = async (objectValue) => {
    try {
      setLoadingScreen(true);
      const url = `${USE_CONNECT_MODE_AUTO}${Transfers.POST_insertDataMovement}`;
      const response = await POST(url, objectValue);
      const { success, result, message } = response;
      if (success) {
        useAlert({
          type: "success",
          title: `${result}`,
          Fn: () => window.location.reload(),
        });
      } else {
        useAlert({
          type: "error",
          title: "เกิดข้อผิดพลาด",
          content: `${message}`,
          Fn: () => setLoadingScreen(false),
        });
      }
    } catch (err) {
      console.log("Error insertDataMovement: ", err);
      setLoadingScreen(false);
    }
  };

  const POST_selectDataLogsNotTransfer = async (objectRow) => {
    try {
      setLoadingModal(true);
      const { userId } = objectRow;
      const url = `${USE_CONNECT_MODE_AUTO}${Transfers.POST_selectDataLogsNotTransfer}`;
      const response = await POST(url, { userId, typeMove });
      const { result, success, message } = response;
      let mapResult = [];
      if (success) {
        if (result.length !== 0) {
          mapResult = result.map((row) => ({
            key: row.userId,
            ...row,
            actionDate: row.actionDate
              ? row.actionDate.substr(0, 10)
              : row.actionDate,
          }));
        }
      } else {
        useAlert({
          type: "error",
          title: "เกิดข้อผิดพลาด",
          content: `${message}`,
        });
      }
      setDataTableModal(mapResult);
    } catch (err) {
      console.log("Error selectDataLogsNotTransfer: ", err);
    } finally {
      setLoadingModal(false);
    }
  };

  const POST_rollbackMovement = async (objectRow) => {
    try {
      const { userId } = objectRow;
      const url = `${USE_CONNECT_MODE_AUTO}${Transfers.POST_rollbackMovement}`;
      const response = await POST(url, { userId, userlogin, typeMove });
      const { result, success, message } = response;
      if (success) {
        useAlert({
          type: "success",
          title: `${result}`,
          Fn: () => POST_searchDataDashboardMovement(ChangeValue),
        });
      } else {
        useAlert({
          type: "error",
          title: "เกิดข้อผิดพลาด",
          content: `${message}`,
        });
      }
    } catch (err) {
      console.log("Error rollbackMovement: ", err);
    }
  };

  const showModal = (objectRow) => {
    setModalshow(true);
    POST_selectDataLogsNotTransfer(objectRow);
  };

  const hideModal = () => {
    setModalshow(false);
  };

  // [END] REST API

  // [START] Functions
  const onChangeValue = (valueChage) => {
    let name = Object.keys(valueChage)[0];
    let value = Object.values(valueChage)[0];

    let SET_undifined = {};
    let duplicate = {};
    let arraySearch = [
      "company",
      "location",
      "depNo",
      "subDepNo",
      "hrmFlow",
      "viewFlow",
    ];
    let arrayInsert = [
      "newCompany",
      "newLocation",
      "newDepNo",
      "newSubDepNo",
      "newHrmFlow",
      "newActionDate",
    ];
    switch (name) {
      case "company":
        duplicate = { companyCode: value };
        SET_undifined = toUndefined([
          ...arraySearch.slice(1, 5),
          ...["positionNo"],
        ]);
        break;
      case "location":
        SET_undifined = toUndefined(arraySearch.slice(2, 5));
        break;
      case "depNo":
        SET_undifined = toUndefined(arraySearch.slice(3, 4));
        break;
      case "newCompany":
        duplicate = { companyCode: value, company: value };
        SET_undifined = toUndefined([
          ...arrayInsert.slice(1, 5),
          ...["newPositionNo"],
        ]);
        break;
      case "newLocation":
        duplicate = { location: value };
        SET_undifined = toUndefined(arrayInsert.slice(2, 5));
        break;
      case "newDepNo":
        duplicate = { depNo: value };
        SET_undifined = toUndefined(arrayInsert.slice(3, 4));
        break;
      default:
        SET_undifined = {};
        break;
    }
    let objectValue = {
      ...SET_undifined,
      ...duplicate,
      [name]: value,
    };
    form.setFieldsValue(SET_undifined);
    if (name.substr(0, 3) !== "new") {
      setDataTable([]);
      setChangeValue((prev) => ({ ...prev, ...objectValue }));
    } else {
      setSelectRowKey([]);
      setChangeActionValue((prev) => ({ ...prev, ...objectValue }));
    }
  };

  const { ColumnTransferEmployee, ColumnTransferLogCompany } = useColumnTable({
    Config: {
      FnShowModal: showModal,
      FnDelete: (data) =>
        useConfirm({
          FnOK: () => POST_rollbackMovement(data),
        }),
    },
  });

  const contentLogTransfer = ({ data = data || [] }) => {
    return dataTableModal.length !== 0 ? (
      <Spin tip="Loading..." spinning={loadingModal}>
        <CustomTableAtnd
          HeadTable={ColumnTransferLogCompany}
          DataTable={dataTableModal}
          SetScroll={{ x: 2000 }}
        />
      </Spin>
    ) : (
      useText({ type: "danger", text: "* ไม่พบข้อมูลการโอนย้าย" })
    );
  };
  // [END] Functions
  return (
    <>
      <Spin tip="Loading..." spinning={loadingScreen}>
        {RenderMainModal({
          show: modalshow,
          onCancel: hideModal,
          txtTitle: "โอนย้าย",
          ModalContent: contentLogTransfer({}),
          width: 1000,
        })}
        <Form
          form={form}
          onFinish={onFinish}
          onValuesChange={onChangeValue}
          {...configForm}
        >
          <Card
            title="ค้นหาพนักงานเพื่อโอนย้ายบริษัท"
            style={{ marginBottom: 30 }}
            extra={
              <Space>
                {useButton({
                  call: "normal",
                  fn: () => POST_searchDataDashboardMovement(ChangeValue),
                  isDisable:
                    ChangeValue["company"] && ChangeValue["location"]
                      ? false
                      : true,
                  moreProps: {
                    label: "ค้นหา",
                    type: "primary",
                  },
                })}
                {useButton({
                  call: "normal",
                  fn: onReset,
                  moreProps: { label: "ล้างข้อมูล" },
                })}
              </Space>
            }
          >
            {useRenderFormItem({
              constantComponent: searchEmployee({
                options: optionSearch,
              }),
            })}
          </Card>
          {dataTable.length !== 0 && (
            <>
              <Card
                title="กรอกข้อมูลที่ต้องการเปลี่ยนแปลงใหม่"
                style={{ marginBottom: 30 }}
              >
                {useRenderFormItem({
                  constantComponent: actionChangeCompany({
                    options: optionAction,
                  }),
                })}
              </Card>
              <Card
                title="เลือกพนักงานที่ต้องการเปลี่ยนแปลงบริษัท"
                style={{ marginBottom: 30 }}
                extra={useExportExcel({
                  header: Header_ChangeCompany,
                  dataset: dataTable,
                  filename: "รายงานพนักงาน(โยกย้ายบริษัท)",
                })}
              >
                <div style={{ marginBottom: 30 }}>
                  {useText({
                    text: "*ถ้ามีรายการโอนย้ายค้างจำเป็นต้องยกเลิกรายการก่อนจึงจะสามารถทำรายการโอนย้ายใหม่ได้",
                    type: "danger",
                  })}
                </div>
                <CustomTableAtnd
                  HeadTable={ColumnTransferEmployee}
                  DataTable={dataTable}
                  moreProps={{
                    rowSelection: {
                      onChange: (selectedRowKeys) =>
                        setSelectRowKey(selectedRowKeys),
                      getCheckboxProps: (record) => ({
                        disabled: record.statusTransfer === 0,
                      }),
                      selectedRowKeys: selectRowKey,
                    },
                    scroll: {
                      x: sumColumnWidth(ColumnTransferEmployee) || 2050,
                      y: 500,
                    },
                  }}
                />
              </Card>
              <Row
                gutter={[16, 16]}
                style={{ marginTop: 20 }}
                justify="center"
                align="bottom"
              >
                <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                  <Form.Item>{useButton({ call: "save" })}</Form.Item>
                </Col>
              </Row>
            </>
          )}
        </Form>
      </Spin>
    </>
  );
};

export default FormTransferCompany;
