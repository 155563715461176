import React, { useState, useContext, useEffect } from 'react'
import { Row, Col, Spin } from 'antd'

import { LayoutWrapper, LayoutBreadcrumb, LayoutSearch, LayoutTabs } from '../../moduleKPIs/common/layouts'
import { REPORT_OPTION } from '../../moduleKPIs/services/servicesOptions'
import { ButtonTheme } from '../../moduleKPIs/common/buttons'

import { ReportEvacContext } from '../servicesEvac/reportEvac/reportEvacProvider'
import { MasterEvacContext } from '../servicesEvac/masterEvac/masterEvacProvider'
import { TEXT_BTN } from '../commonsEvac/structuresEvac/buttonEvac'
import { SEARCH_DASHBOARD_REVIEWER } from '../commonsEvac/structuresEvac/searchEvac'
import { BREADCRUMB_DASHBOARD_REVIEWER } from '../commonsEvac/structuresEvac/breadcrumEvac'
// import DashboardReviewer from '../componentsEvac/dashboardReviewer'
import { TABS_DASHBOARD_REVIEWER } from '../commonsEvac/structuresEvac/tabsEvac'

const PageDashboardReviewer = () => {
    const { masterEvacState, onChangeMaster } = useContext(MasterEvacContext);
    const { getReportEvac } = useContext(ReportEvacContext)

    const [loading, setLoading] = useState(false)

    let SEARCH_LIST = SEARCH_DASHBOARD_REVIEWER({ permission: null, options: masterEvacState })
    let TABS_LIST = TABS_DASHBOARD_REVIEWER({ permission: null, options: masterEvacState })

    async function fetchAPI() {
        await getReportEvac({ fieldname: REPORT_OPTION.DASHBOARD_REVIEWER })
    }

    useEffect(() => {
        fetchAPI()
    }, [])

    return (
        <Spin spinning={loading} tip="กำลังโหลด">
            <LayoutWrapper
                breadcrumb={
                    <div className="layout-breadcrumb-btn-insert">
                        <div className="layout-content-btn-search">
                            <ButtonTheme
                                type="primary"
                                style={{ padding: 10 }}
                                buttonText={TEXT_BTN.SEARCH_DASHBOARD_EVAC}
                                onClick={async () => {
                                    await setLoading(true)
                                    await getReportEvac({ fieldname: REPORT_OPTION.FILTER_DASHBOARD_REVIEWER })
                                    await setLoading(false)
                                }}
                            />
                        </div>
                        <LayoutBreadcrumb breadcrumbLists={BREADCRUMB_DASHBOARD_REVIEWER()} />
                    </div>
                }
                search={<Row gutter={[24, 10]}>
                    {SEARCH_LIST.map((each, eachIndex) => (
                        <Col key={eachIndex} span={each.span} >
                            <LayoutSearch
                                type={each.type}
                                header={each.header}
                                onChange={onChangeMaster}
                                {...each.inputOption}
                            />
                        </Col>
                    ))}
                </Row>}
                content={
                    <div className="layout-content-card" >
                        <LayoutTabs tabsLists={TABS_LIST} />
                        {/* <DashboardReviewer /> */}
                    </div>
                }
            />
        </Spin>
    )
}

export default PageDashboardReviewer