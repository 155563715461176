export const formFilter = ({ optionsEmp, optionsCompany, optionsCerList }) => {
  return [
    {
      ref: "company",
      name: "company",
      label: "บริษัท (Company)",
      type: "DROPDOWN",
      span: 5,
      placeholder: "บริษัท",
      properties: {
        options: optionsCompany || [],
      },
    },
    {
      ref: "empID",
      name: "empID",
      label: "พนักงานผู้ขอเอกสาร",
      type: "DROPDOWN",
      span: 5,
      placeholder: "พนักงาน",
      properties: {
        options: optionsEmp || [],
      },
    },
    {
      ref: "cerName",
      name: "cerName",
      label: "ประเภทเอกสาร",
      type: "DROPDOWN",
      span: 5,
      placeholder: "แสดงทั้งหมด",
      properties: {
        options: optionsCerList || [],
      },
      mode: "multiple",
    },
    {
      ref: "createdDate",
      name: "createdDate",
      label: "ช่วงวันที่ขอเอกสาร",
      type: "RANGE_PICKER",
      span: 6,
      placeholder: ["เริ่ม", "จบ"],
    },
  ];
};
