import React, { useContext, useEffect, useState } from 'react'
import { Divider, Spin } from 'antd';

import { LayoutBreadcrumb, LayoutTabs, LayoutWrapper, WrapperSearch } from '../../moduleKPIs/common/layouts';
import { SEARCH_CONTRACT } from '../commonsContract/structuresContract/searchContract';
import { BREADCRUMB_CONTRACT } from '../commonsContract/structuresContract/breadcrumContract';
import { TABS_CONTRACT } from '../commonsContract/structuresContract/tabsContract';
import { ContractContext } from '../servicesContract/contractProvider';
import { MasterCenterContext } from '../../service/master/masterProvider';
import { KEY_OPTION } from '../../constants/enums/enumCenter';
import WorkingProcess from '../../components/workingProcess';

const PageDashboardEmpContractNew = () => {
    const { userLogin, userPermission, getAPI } = useContext(ContractContext);
    const { masterCenterState, onChangeMaster, getMaster } = useContext(MasterCenterContext);

    const [loading, setLoading] = useState(false);
    const [current, setCurrent] = useState('1');

    const _pathname = window.location.pathname;

    useEffect(() => {
        async function fetchAPI() {
            try {
                await setLoading(true)
                await Promise.all(
                    [getMaster({ fieldname: KEY_OPTION.CENTER.OPTION_DEP, payload: { userId: userLogin, permission: userPermission } })],
                    [getMaster({ fieldname: KEY_OPTION.CENTER.OPTION_USERS })],
                    [getMaster({ fieldname: KEY_OPTION.CENTER.OPTION_LOCATION, payload: { ...masterCenterState } })],
                    [getMaster({ fieldname: KEY_OPTION.CENTER.OPTION_STATUS_WORKERS, payload: { ...masterCenterState } })],
                    [getMaster({ fieldname: KEY_OPTION.PROBATION.OPTION_STATUS_ASSESSMENT, payload: { ...masterCenterState } })],
                    [getMaster({ fieldname: KEY_OPTION.PROBATION.OPTION_STATUS_RESULT, payload: { ...masterCenterState } })]
                )
                await setLoading(false)
            } catch (error) {
                console.log({ error })
                await setLoading(false)
            }
        }
        fetchAPI()
    }, [])

    return (
        <Spin spinning={loading} >
            <LayoutWrapper
                breadcrumb={
                    < LayoutBreadcrumb breadcrumbLists={BREADCRUMB_CONTRACT(_pathname)} />
                }
                search={
                    <WrapperSearch
                        searchLists={SEARCH_CONTRACT({ pathname: _pathname, permission: userPermission, options: masterCenterState })}
                        onChange={onChangeMaster} />
                }
                content={
                    <div className="layout-content-card" style={{ padding: 10 }} >
                        <div style={{ display: 'flex', justifyContent: 'space-between', paddingTop: 10 }}>
                            <WorkingProcess pathname={_pathname} />
                        </div>
                        <Divider />
                        <LayoutTabs
                            onChange={async (e) => {
                                try {
                                    //console.log({ e })
                                    switch (e) {
                                        case '2':
                                            await setLoading(true)
                                            await getAPI({
                                                fieldname: KEY_OPTION.PROBATION.DASHBOARD_EMP_CONTRACT_NOTPASS,
                                                payload: { ...masterCenterState }
                                            })
                                            await setLoading(false)
                                            break;
                                        default:
                                            await setLoading(true)
                                            await getAPI({
                                                fieldname: KEY_OPTION.PROBATION.DASHBOARD_EMP_CONTRACT_PASS,
                                                payload: { ...masterCenterState }
                                            })
                                            await setLoading(false)
                                            break;
                                    }
                                    await setCurrent(e)
                                } catch (error) {
                                    console.log({ error })
                                    setLoading(false)
                                }
                            }}
                            tabsLists={TABS_CONTRACT({ pathname: _pathname, permission: userPermission, idTab: current })} />
                    </div>
                }
            />
        </Spin>
    )
}

export default PageDashboardEmpContractNew