import { responseAPI } from "../../common/functions/funcResponse";
import servicesAPI from "../servicesAPI";

let API = servicesAPI.create()

export async function APIInsertIndicatorHeader(payload, nextAction) {
    try {
        const response = await API.insertIndicatorHeader(payload);
        responseAPI(response, nextAction)
    } catch (error) {
        console.error('error : ', error)
    }
}

export async function APIUpdateIndicatorHeader(payload, nextAction) {
    try {
        const response = await API.updateIndicatorHeader(payload);
        responseAPI(response, nextAction)
    } catch (error) {
        console.error('error : ', error)
    }
}

export async function APIUpdateIndicatorLicense(payload, nextAction) {
    try {
        const response = await API.updateIndicatorLicense(payload);
        responseAPI(response, nextAction)
    } catch (error) {
        console.error('error : ', error)
    }
}

export async function APIInsertIndicatorLicense(payload, nextAction) {
    try {
        const response = await API.insertIndicatorLicense(payload);
        responseAPI(response, nextAction)
    } catch (error) {
        console.error('error : ', error)
    }
}

export async function APIUpdateIndicatorTime(payload, nextAction) {
    try {
        const response = await API.updateIndicatorTime(payload);
        responseAPI(response, nextAction)
    } catch (error) {
        console.error('error : ', error)
    }
}
