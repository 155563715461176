import parse from "html-react-parser";
import React from "react";
import { Card } from "antd";
import { TextLarge } from "../../../../components/global/text";
import { FormPrintContainer } from "../style";

export const FormPrint = ({ formData }) => {
  return (
    <Card>
      <TextLarge text="หนังสือสัญญาจ้าง" bold={true} align="center" />
      { formData && Object.keys(formData).length > 0 &&
       <FormPrintContainer>{parse(formData)}</FormPrintContainer> 
      }
    </Card>
  );
};
