export const INITIAL_FILTER_PROBATION = {
	SegmentTxt: '',
	DepNo: '',
	statusMainCode: '',
	StartDateJob: '',
	ToDateJob: '',
	searchUser: ''
}

export const INITIAL_PROBATION = {
	//**RoundNow***//
	// null: ประเมินครั้งที่ 1  
	// 1 : ประเมินครั้งที่ 2 
	// 2 : ประเมินครั้งที่ 3 
	// 3 : ประเมินครั้งที่ 4
	"RoundNow": 1,
	"probationStatus": "",
	"probationStatus_Des": "",
	"probationGrade": "",
	"mentorCode": "",
	"mentorName": "",
	"remark": "",
	"average_1": 0,
	"average_2": 0,
	"average_3": 0,
	"average_4": 0,
	"contractExtension": '',
	"factor_1": [
		{
			"name": null,
			"score": null
		}
	],
	"factor_2": [
		{
			"name": null,
			"score": null
		}
	],
	"factor_3": [
		{
			"name": null,
			"score": null
		}
	],
	"factor_4": [
		{
			"name": null,
			"score": null
		}
	],
	"factor_5": [
		{
			"name": null,
			"score": null
		}
	],
	"factor_6": [
		{
			"name": null,
			"score": null
		}
	],
	"factor_7": [
		{
			"name": null,
			"score": null
		}
	],
	"factor_8": [
		{
			"name": null,
			"score": null
		}
	],
	"factor_9": [
		{
			"name": null,
			"score": null
		},
	],
	"factor_10": [
		{
			"name": null,
			"score": null
		},
	],
	"factor_11": [
		{
			"name": null,
			"score": null
		},
	],
	"factor_12": [
		{
			"name": null,
			"score": null
		},
	]
}
