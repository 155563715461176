import React, { useState, useContext, useEffect } from 'react'
import { LayoutWrapper, LayoutBreadcrumb, LayoutSearch, LayoutTabs } from '../../moduleKPIs/common/layouts'
import { BREADCRUMB_DASHBOARD_EVAC } from '../commonsEvac/structuresEvac/breadcrumEvac'
import { Row, Col, Spin } from 'antd'
import { MasterEvacContext } from '../servicesEvac/masterEvac/masterEvacProvider'
import { SEARCH_DASHBOARD_EVAC } from '../commonsEvac/structuresEvac/searchEvac'
import { MASTER_OPTION } from '../../moduleKPIs/services/servicesOptions'
import { TABS_DASHBOARD_EVAC } from '../commonsEvac/structuresEvac/tabsEvac'

const PageDashboardEvac = () => {
	const { masterEvacState, onChangeMaster, getMaster } = useContext(MasterEvacContext);

	const [loading, setLoading] = useState(false)

	let SEARCH_LIST = SEARCH_DASHBOARD_EVAC({ permission: null, options: masterEvacState })
	let TABS_LIST = TABS_DASHBOARD_EVAC({ permission: null, options: masterEvacState })

	// // console.log({ masterEvacState })
	async function fetchAPI() {
		await setLoading(true)
		await getMaster({ fieldname: MASTER_OPTION.OPTION_DEP_EVAC })
		//     // await getReportEvac({ fieldname: REPORT_OPTION.DASHBOARD_EVAC })
		await setLoading(false)
	}

	useEffect(() => {
		fetchAPI()
	}, [])

	return (
		<Spin spinning={loading} tip="กำลังโหลด">
			<LayoutWrapper
				breadcrumb={
					< LayoutBreadcrumb breadcrumbLists={BREADCRUMB_DASHBOARD_EVAC()} />
				}
				search={<Row gutter={[24, 10]}>
					{SEARCH_LIST.map((each, eachIndex) => (
						<Col key={eachIndex} span={each.span} >
							<LayoutSearch
								type={each.type}
								header={each.header}
								onChange={onChangeMaster}
								{...each.inputOption}
							/>
						</Col>
					))}
				</Row>}
				content={
					<div className="layout-content-card" >
						<LayoutTabs tabsLists={TABS_LIST} />
					</div>
				}
			/>
		</Spin>
	)
}

export default PageDashboardEvac