import { ConsoleSqlOutlined } from "@ant-design/icons";
import { useState, useEffect } from "react";
import {
  GET_SELECT_OPTION_WORKSHIFT_ALL,
  GET_SELECT_OPTION_WORKSHIFT,
  GET,
} from "../../../service";
import { FIELD_EMP } from "../contents/employeeInfo";

export const useGetOptionWorkShift = () => {
  const [optionWorkShift, setOptionWorkShift] = useState(null);
  const [location, setLocation] = useState(null);
  const [company, setCompany] = useState(null);

  useEffect(() => {
    fetchOptionWorkShiftData();
  }, [location, company]);

  const onChangeParamForWorkShift = ({ field, value, form }) => {
    switch (field) {
      case "company":
        setCompany(() => value);
        break;
      case "location":
        setLocation(() => value);
        break;
      default:
        console.warn(
          "Warning! wrong  field! only company and location is a parameter of workShift"
        );
    }
    form.setFieldsValue({
      [FIELD_EMP.SHIFT_CODE]: undefined,
    });
  };

  const fetchOptionWorkShiftData = async () => {
    let res = {};
    if (location || company) {
      res = await GET(
        GET_SELECT_OPTION_WORKSHIFT({
          company: company || "",
          location: location || "",
        })
      );
    } else {
      res = await GET(GET_SELECT_OPTION_WORKSHIFT_ALL());
    }
    setOptionWorkShift(() =>
      res.result.map((e) => ({
        optionId: e.shiftCode,
        optionValue: e.shiftCode,
        optionLabel: e.workShiftNameTH,
      }))
    );
  };

  return { optionWorkShift, onChangeParamForWorkShift };
};
