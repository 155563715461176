import React from "react";
import "../index.css";
import "bootstrap/dist/css/bootstrap.css";
import { Col, Row, Button, Form, FormGroup, Label, Input, Card, CardHeader, CardBody, Container } from "reactstrap";
import { API_URL, URL_IMAGE } from "../components/connectAPI";
import { loadingPage } from "../components/loadingPage";
import "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from 'react-bootstrap-table2-paginator';
import { Link } from "react-router";
import { GET, GET_SELECT_OPTION_ALL } from "../service";
// const api = connectAPI.API_URL;
class dashboardImportEmployee extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			showDropdown_Department: "",
			showDropdown_subDepartment: "",
			CompanyTxt: "DP",
			DepTxt: "",
			UserIDTxt: "",
			showDropdown_StatusWorker: "",
			statusWorkerID: "Full",
			loadData: [],
			done: undefined,
			subDepTxt: '',
			locationTxt: '',
		};
	}

	componentDidMount() {
		this.getSelectOptionAll()
	}

	getSelectOptionAll = async () => {
		try {
			let res = await GET(GET_SELECT_OPTION_ALL())
			//console.log('reeeee', res)
			const { result, success } = res
			if (success) {
				let departmentLists = [];
				result['department'].forEach(el => {
					departmentLists.push({ label: el.depNameTH, value: el.depNo })
				})

				let optionSubDepartment = [];

				result["subDep"].forEach(el => {
					optionSubDepartment.push({
						label: el.subDepNo + "::" + el.subDepName, value: el.subDepNo
					})
				});
				this.setState({
					showDropdown_Department: departmentLists,
					showDropdown_subDepartment: optionSubDepartment,
					done: true
				});
			}
		} catch (err) {
			console.log('err', err.message)
		}
	}

	search = () => {
		var _DepTxt = this.state.DepTxt;
		let _subDep = this.state.subDepTxt;
		let _locationTxt = this.state.locationTxt;
		this.setState({ done: undefined });
		var API =
			API_URL +
			"/HRM_API/select_dashboardImportEmployee?depNo=" + _DepTxt + "&&subDepNo=" + _subDep + "&&location=" + _locationTxt + "&&candidateNo=";
		fetch(API)
			.then(res => res.json())
			.then(results => {
				if (results["data"] !== 0) {
					results["data"].forEach((element, index, array) =>
						this.setState({ loadData: array })
					);
				} else {
					this.setState({ loadData: [] });
				}
				this.setState({ done: true, DepTxt: "", subDepTxt: "", locationTxt: "" });
			})
			.catch(ex => {
				console.log("error log ::", ex);
			});
	};

	onChangeDep = e => {
		this.setState({ DepTxt: e.target.value });
	};

	onChangeSubDep = e => {
		this.setState({ subDepTxt: e.target.value });
	};
	onChangelLocation = e => {
		this.setState({ locationTxt: e.target.value });
	}

	openImportWorker = e => {
		var _candidateNo = e.target.id;
		window.open("/ImportEmployee/" + _candidateNo);
	};

	render() {
		const products = this.state.loadData;
		const columns = [
			{
				dataField: "fullName",
				text: "ชื่อ-สกุล",
				sort: true,
				style: {
					//  whiteSpace: 'nowrap'
				}
			},
			{
				dataField: "nickNameTH",
				text: "ชื่อเล่น",
				sort: true,
				style: {
					//   whiteSpace: 'nowrap'
				}
			},
			{
				dataField: "startDateJob",
				text: "วันที่เริ่มทำงาน",
				sort: true,
				style: {
					//   whiteSpace: 'nowrap'
				}
			},
			{
				dataField: "departmentName",
				text: "แผนก",
				sort: true,
				style: {
					//  whiteSpace: 'nowrap'
				}
			},
			{
				dataField: "jobPosition",
				text: "ตำแหน่ง",
				sort: true,
				style: {
					// whiteSpace: 'nowrap',
					// backgroundColor: '#F0FFF0'
				}
			},
			{
				// dataField: 'ID_User',
				text: "Action",
				sort: true,
				formatter: (cellContent, row) => {
					return (
						<div>
							{" "}
							<Col>
								<center>
									<Button
										color="success"
										onClick={this.openImportWorker}
										id={row.candidateNo}
									>
										Import
									</Button>
								</center>
							</Col>
						</div>
					);
				},
				style: {

					color: "blue"
				}
			}
		];

		const sizePerPageRenderer = ({
			options,
			currSizePerPage,
			onSizePerPageChange
		}) => (
			<div className="btn-group" role="group">
				{
					options.map((option) => {
						const isSelect = currSizePerPage === `${option.page}`;
						return (
							<button
								key={option.text}
								type="button"
								onClick={() => onSizePerPageChange(option.page)}
								className={`btn ${isSelect ? 'btn-secondary' : 'btn-primary'}`}
							>
								{option.text}
							</button>
						);
					})
				}
			</div>
		);

		const options = {
			sizePerPageRenderer
		};

		return (
			<Container>
				{this.state.done ? (
					<div>
						<Card>
							<CardHeader>Import Employee</CardHeader>
							<CardBody>
								<Form>
									<Row>
										<Col md={3}>
											<FormGroup>
												<Label >สถานที่ทำงาน</Label>
												<Input
													type="select"
													name="location"
													id="location"
													onChange={this.onChangelLocation} >
													<option value="">ทั้งหมด</option>
													<option value="OFF">Office</option>
													<option value="FAC">Factory</option>
													{/* <option value="FI">FIN</option>
                          <option value="DC">Digital Commerce</option> */}

												</Input>
											</FormGroup>
										</Col>
										<Col md={3}>
											<FormGroup>
												<Label for="FNameEN">แผนก</Label>
												<Input
													type="select"
													name="DepNo"
													id="DepNo"
													onChange={this.onChangeDep}
												>
													<option value="">กรุณาเลือกแผนก</option>
													{this.state.showDropdown_Department.map(val => {
														return <option value={val.value}>{val.label}</option>
													})}
												</Input>
											</FormGroup>
										</Col>
										<Col md={3}>
											<FormGroup>
												<Label for="FNameEN">แผนกย่อย</Label>
												<Input
													type="select"
													name="subDepNo"
													id="subDepNo"
													onChange={this.onChangeSubDep}
												>
													<option value="">กรุณาเลือกแผนกย่อย</option>
													{this.state.showDropdown_subDepartment.map(val => {
														return <option value={val.value}>{val.label}</option>
													})}
												</Input>
											</FormGroup>
										</Col>
										<Col md={3}>
											<br />
											<FormGroup>
												<Button color="info" onClick={this.search}>
													Search
												</Button>
											</FormGroup>
										</Col>
									</Row>
								</Form>
							</CardBody>
						</Card>
						<div>

							<hr />
							<BootstrapTable
								keyField="candidateNo" //"custNo"RowID
								data={products}
								columns={columns}
								ref={n => (this.node = n)}
								striped
								hover
								condensed
								pagination={paginationFactory(options)}
								wrapperClasses="table-responsive"

							/>
						</div>
					</div>
				) : (
					<div>{loadingPage} </div>
				)}
			</Container>
		);
	}
}
export default dashboardImportEmployee;
