import React, { useState } from "react";
import { Form, Card, Row, Spin } from "antd";
import { PermissionView, USE_CONNECT_MODE_AUTO } from "../../services/ConnectAPI";
import CustomTableAtnd from "../tables/CustomTableAtnd";
import { useNotification } from "../notification/NotificationWithIcon";
import { useColumnTable } from "../../constants/UseColumnTable";
import { RenderMainModal } from "../modal/CustomModalAntd";
import { useExportExcel } from "../../services/ExportExcel";
import { Header_PagePermissionView } from "../../constants/HeaderExcel";
import { GET_UserLogin, sumColumnWidth } from "../../functions/UseFunction";
import { useOptionSelect } from "../../constants/OptionSelect";
import { useAlert } from "../modal/CustomAlert";
import { useConfirm } from "../modal/CustomConfirm";
import { configForm, WORDS } from "../../constants/ConstantComponent";
import { useText } from "../typography/Typography";
import { useRenderFormItem } from "../RenderComponents";
import { ComponentsSearchBranchView } from "../../constants/ManageBranchView/Components";

const FormSearchBranchView = () => {
  const [form] = Form.useForm();
  const { userlogin } = GET_UserLogin();
  // [START] States
  const [changeSearchValue, setChangeSearchValue] = useState({ users: [] });
  const [dataTable, setDataTable] = useState([]);
  const [dataRow, setDataRow] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [modalUserCount, setModalUserCount] = useState([]);
  const [loadingScreen, setLoadingScreen] = useState(false);
  const [loadingContentModal, setLoadingContentModal] = useState(false);

  const optionEmployeeGroup = useOptionSelect("empPermission");
  // [END] States

  const onFinish = (values) => {
    searchViewFlow();
  };

  const onReset = () => {
    form.resetFields();
    setDataTable([]);
    setChangeSearchValue({ users: [] });
  };

  // [START] REST API
  const searchViewFlow = async () => {
    try {
      setLoadingScreen(true);
      setDataTable([]);
      const url = `${USE_CONNECT_MODE_AUTO}${PermissionView.POST_SEARCH_VIEW}`;
      const response = await fetch(url, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(changeSearchValue),
      });
      const data = await response.json();
      const RESULT = data.result;
      let MAP_RESULT = [];
      if (response.ok) {
        if (RESULT.length === 0) {
          useNotification({ type: "info", title: "ไม่พบข้อมูล" });
        } else {
          MAP_RESULT = RESULT.map((val, index) => ({
            key: index,
            ...val,
          }));
        }
      }
      setDataTable(MAP_RESULT);
    } catch (err) {
      console.log("Error Search View:", err);
      useAlert({ type: "catch", content: `${err.message}` });
    } finally {
      setLoadingScreen(false);
    }
  };

  const putDEACTIVE = async (record) => {
    if (!record) return null;
    try {
      setLoadingScreen(true);
      let statusActive = record.statusActive ? `inactive` : `active`;
      let statusChange = record.statusActive ? false : true;
      const url = `${USE_CONNECT_MODE_AUTO}${PermissionView.PUT_ACTIVE_VIEW}${record.flowId}/${statusActive}`;
      const response = await fetch(url, {
        method: `PUT`,
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ userlogin }),
      });
      const data = await response.json();
      if (response.ok) {
        useAlert({
          type: "success",
          title: `การจัดการสถานะสำเร็จ`,
          Fn: () =>
            setDataTable(
              dataTable.map((val) => {
                return val.flowId === record.flowId
                  ? { ...val, statusActive: statusChange }
                  : val
              })
            ),
        });
      } else {
        useAlert({
          type: "info",
          title: "การจัดการสถานะไม่สำเร็จ",
          content: `${data.message}`,
        });
      }
    } catch (err) {
      console.log("PUT-deActive:", err);
      useAlert({ type: "catch", content: `${err.message}` });
    } finally {
      setLoadingScreen(false);
    }
  };

  const getEmployeeList = async (record) => {
    try {
      setShowModal(true);
      setDataRow(record);
      setLoadingContentModal(true);
      setModalUserCount([]);
      const url = `${USE_CONNECT_MODE_AUTO}${PermissionView.GET_EmployeeList_BY_ID}${record["flowId"]}`;
      const response = await fetch(url);
      const data = await response.json();
      let mapResult = [];
      if (response.ok) {
        const RESULT = data.result;
        mapResult = RESULT.map((val, index) => ({
          key: index,
          ...val,
        }));
      }
      setModalUserCount(mapResult);
    } catch (err) {
      console.log("Error Data Modal: ", err);
      useAlert({ type: "catch", content: `${err.message}` });
    } finally {
      setLoadingContentModal(false);
    }
  };
  // [END] REST API

  // [START] Functions
  const onChangeValue = (val, name) => {
    if (name === "users") {
      setChangeSearchValue(prev => ({ ...prev, [name]: val ? val : [] }))
    }
    setDataTable([]);
  };
  // [END] Functions

  // [START] Control Column
  const { ColumnBranchView, ColumnCountEmployees } = useColumnTable({
    Config: {
      openModal: getEmployeeList,
      fnDelete: (record) => useConfirm({ FnOK: () => putDEACTIVE(record) }),
    },
  });
  // [END] Control Column

  const contentModalUsersFlowView = () => {
    return (
      <div style={{ width: "100%" }}>
        <Spin tip={WORDS.loadingSpin} spinning={loadingContentModal}>
          {modalUserCount.length !== 0 ? (
            <CustomTableAtnd
              HeadTable={ColumnCountEmployees}
              DataTable={modalUserCount}
              SetScroll={{ x: sumColumnWidth(ColumnCountEmployees) || "20%" }}
              TableLayout={`fixed`}
            />
          ) : (
            useText({ type: "danger", text: "* ไม่พบข้อมูลการโอนย้าย" })
          )}
        </Spin>
      </div>
    );
  };

  const closeModal = () => {
    setShowModal(false);
    setDataRow([])
  }

  return (
    <Spin tip={WORDS.loadingSpin} spinning={loadingScreen}>
      <RenderMainModal
        show={showModal}
        onCancel={closeModal}
        txtTitle={`สมาชิก : ${dataRow.description}`}
        ModalContent={contentModalUsersFlowView()}
      />
      <Card title="จัดการสิทธิ์การดูข้อมูล" style={{ marginBottom: 30 }}>
        <Form form={form} onFinish={onFinish} {...configForm}>
          {useRenderFormItem({
            constantComponent: ComponentsSearchBranchView({
              options: { users: optionEmployeeGroup },
              onClick: onReset,
              handleChange: onChangeValue,
            })
          })}
        </Form>
      </Card>
      {dataTable.length !== 0 && (
        <>
          <Row justify="end" align="middle" style={{ marginBottom: 30 }}>
            {useExportExcel({
              header: Header_PagePermissionView,
              dataset: dataTable,
              filename: "รายงานสายการดูข้อมูล",
            })}
          </Row>
          <CustomTableAtnd
            HeadTable={ColumnBranchView}
            SetScroll={{ x: sumColumnWidth(ColumnBranchView) || "80%", y: 500 }}
            DataTable={dataTable}
          />
        </>
      )}
    </Spin>
  );
};

export default FormSearchBranchView;
