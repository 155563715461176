import React from "react";
import FadeIn from "react-fade-in";
import Lottie from "react-lottie";
// import ReactLoading from "react-loading";
import "bootstrap/dist/css/bootstrap.css";
import * as legoData from "../loading.json";

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: legoData.default,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice"
  }
}

export const loadingPage =

  <FadeIn>
    <div className="d-flex justify-content-center align-items-center">
      <Lottie options={defaultOptions} height={200} width={200} />
    </div>
  </FadeIn>

export default {
  loadingPage
};
