import { TYPE } from "../../../moduleKPIs/common/inputs"
import { MASTER_OPTION } from "../../../moduleKPIs/services/servicesOptions"
import { path } from "ramda"

export const PRINT_HEADER_OT = (_element) => {
    //console.log('PRINT_HEADER_OT', _element)
    if (!!_element) {
        return {
            header: path(['statusApproval'], _element) ? `ใบคำขอทำงานล่วงเวลา  ${path(['subDepName'], _element) || '.........'}` : `ใบคำขอทำงานล่วงเวลา  ${path(['subDepName'], _element) || '.........'} (${path(['statusName'], _element) || '.........'})`,
            date: `วันที่  ${path(['startDate'], _element) || '.........'}  - ${path(['endDate'], _element) || '.........'}`,
            time: `ตั้งแต่เวลา  ${path(['startTime'], _element) || '.........'}  - ${path(['endTime'], _element) || '.........'} รวมเวลา ${path(['totalHours'], _element) || '.........'} ชั่วโมง`,
            remark: `เหตุผล  ${path(['remark'], _element) || '....................................'} `,
        }
    } else {
        return {
            header: `ใบคำขอทำงานล่วงเวลา  ....................................`,
            date: `วันที่  ................ - ................`,
            time: `ตั้งแต่เวลา   ................ - ................ รวมเวลา ................ ชั่วโมง`,
            remark: `เหตุผล   ................................`,
        }
    }
}

export const PRINT_OT = (_permission) => {
    switch (_permission) {
        default:
            return [
                [{
                    span: 12,
                    type: TYPE.SELECT,
                    header: 'แผนกย่อย',
                    required: true,
                    message: 'กรุณากรอก ระบุแผนกย่อย',
                    fieldName: MASTER_OPTION.SUBDEP_FACTORY,
                    fieldOption: `OPTION_${MASTER_OPTION.SUBDEP_FACTORY}`,
                }, {
                    span: 12,
                    type: TYPE.SELECT,
                    header: 'ผู้ส่งอนุัมัติ',
                    required: true,
                    message: 'กรุณากรอก ระบุผู้ส่งอนุัมัติ',
                    fieldName: MASTER_OPTION.SUBDEP_FACTORY,
                    fieldOption: `OPTION_${MASTER_OPTION.SUBDEP_FACTORY}`,
                }], [{
                    span: 12,
                    type: TYPE.SELECT,
                    header: 'วันที่เริ่ม',
                    required: true,
                    message: 'กรุณากรอก ระบุวันที่เริ่ม',
                    fieldName: MASTER_OPTION.SUBDEP_FACTORY,
                    fieldOption: `OPTION_${MASTER_OPTION.SUBDEP_FACTORY}`,
                }, {
                    span: 12,
                    type: TYPE.SELECT,
                    header: 'วันที่จบ',
                    required: true,
                    message: 'กรุณากรอก ระบุวันที่จบ',
                    fieldName: MASTER_OPTION.SUBDEP_FACTORY,
                    fieldOption: `OPTION_${MASTER_OPTION.SUBDEP_FACTORY}`,
                }], [{
                    span: 8,
                    type: TYPE.SELECT,
                    header: 'เวลาที่เริ่ม',
                    required: true,
                    message: 'กรุณากรอก ระบุเวลาที่จบ',
                    fieldName: MASTER_OPTION.SUBDEP_FACTORY,
                    fieldOption: `OPTION_${MASTER_OPTION.SUBDEP_FACTORY}`,
                }, {
                    span: 8,
                    type: TYPE.SELECT,
                    header: 'เวลาที่จบ',
                    required: true,
                    message: 'กรุณากรอก ระบุเวลาที่จบ',
                    fieldName: MASTER_OPTION.SUBDEP_FACTORY,
                    fieldOption: `OPTION_${MASTER_OPTION.SUBDEP_FACTORY}`,
                }, {
                    span: 8,
                    type: TYPE.INPUT,
                    header: 'รวมชั่วโมงทั้งหมด',
                    fieldName: MASTER_OPTION.SUBDEP_FACTORY,
                    fieldOption: `OPTION_${MASTER_OPTION.SUBDEP_FACTORY}`,
                }], [{
                    span: 12,
                    type: TYPE.INPUT,
                    header: 'เหตุผล',
                    required: true,
                    message: 'กรุณากรอก ระบุเหตุผล',
                    fieldName: MASTER_OPTION.SUBDEP_FACTORY,
                    fieldOption: `OPTION_${MASTER_OPTION.SUBDEP_FACTORY}`,
                }]
            ]
    }
}