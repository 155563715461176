import { Form, Input } from "antd";
import moment from "moment";
import React, { Fragment } from "react";
import { useDatePicker } from "../../../conponents/datepicker/DatePickerAntd";
import { useInputText } from "../../../conponents/inputs/InputAnt";
import { useSelect } from "../../../conponents/select/SelectAntd";
import {
  filterSearchColumn,
  useSorterTable,
} from "../../../functions/UseFunction";

export const ColumnQuitPersonal = ({ dropdown, actions }) => [
  {
    dataIndex: "userId",
    title: "รหัสพนักงาน",
    width: 100,
    fixed: "left",
    align: "",
    ...filterSearchColumn("userId", "รหัสพนักงาน"),
  },
  {
    dataIndex: "fullname",
    title: "ชื่อ-นามสกุล",
    width: 200,
    fixed: "left",
    align: "",
    ...filterSearchColumn("fullname", "ชื่อ-นามสกุล"),
  },
  {
    dataIndex: "nickname",
    title: "ชื่อเล่น",
    width: 125,
    fixed: "left",
    align: "",
    ...filterSearchColumn("nickname", "ชื่อเล่น"),
  },
  {
    dataIndex: "depName",
    title: "แผนก",
    width: 250,
    fixed: "",
    align: "",
    ...filterSearchColumn("depName", "แผนก"),
  },
  {
    dataIndex: "statusWorker",
    title: "สถานะพนักงาน",
    width: 200,
    fixed: "",
    align: "",
    ...filterSearchColumn("statusWorker", "สถานะพนักงาน"),
  },
  {
    dataIndex: "approvedDate",
    title: "วันที่อนุมัติ",
    width: 100,
    fixed: "",
    align: "center",
    ...filterSearchColumn("approvedDate", "วันที่อนุมัติ"),
  },
  {
    dataIndex: "company",
    title: "บริษัท",
    width: 100,
    fixed: "",
    align: "center",
    ...filterSearchColumn("company", "บริษัท"),
  },
  {
    dataIndex: "startDateJob",
    title: "วันที่เริ่มทำงาน",
    width: 100,
    fixed: "",
    align: "center",
    ...filterSearchColumn("startDateJob", "วันที่เริ่มทำงาน"),
  },
  {
    dataIndex: "endDateJob",
    title: "วันที่พ้นสภาพ",
    width: 150,
    fixed: "",
    align: "center",
    ...filterSearchColumn("endDateJob", "วันที่พ้นสภาพ"),
    render: (text, record) => {
      const { onChangeDate } = actions;
      return (
        <Fragment>
          <br />
          <Form.Item
            name={`endDateJob_${record.key}`}
            wrapperCol={0}
            labelCol={0}
            initialValue={moment(text)}
          >
            {useDatePicker({
              componentProps: {
                placeholder: "เลือกวันสิ้นสุดการทำงาน",
                style: { width: "100%" },
              },
              fnChange: (date, string) => {
                onChangeDate(
                  record.key,
                  moment(date).format("YYYY-MM-DD"),
                  "endDateJob",
                  "other"
                );
              },
            })}
          </Form.Item>
        </Fragment>
      );
    },
  },
  {
    // dataIndex: "reasonLeaveCode",
    title: "เหตุผลการลาออก",
    width: 150,
    fixed: "",
    align: "",
    ...filterSearchColumn("reasonLeaveCode", "เหตุผลการลาออก"),
    render: (record) => {
      const { onChangeReason } = actions;
      return (
        <Fragment>
          <br />
          <Form.Item
            name={`reasonLeaveCode_${record.key}`}
            wrapperCol={0}
            labelCol={0}
            initialValue={record.reasonLeaveCode}
          >
            {useSelect({
              dropdown,
              componentProps: { style: { width: 300 } },
              fnChange: (value, ...other) =>
                onChangeReason(record.key, value, "reasonLeaveCode", other),
            })}
          </Form.Item>
        </Fragment>
      );
    },
  },
  {
    dataIndex: "reasonLeaveDes",
    title: "เหตุผลเพิ่มเติม",
    width: 150,
    fixed: "",
    align: "",
    ...filterSearchColumn("reasonLeaveDes", "เหตุผลเพิ่มเติม"),
    render: (text, record) => {
      const { onChangeDes } = actions;
      return (
        <Fragment>
          <br />
          <Form.Item
            name={`reasonLeaveDes_${record.key}`}
            wrapperCol={0}
            labelCol={0}
            initialValue={record.reasonLeaveCode === "other" ? text : ""}
          >
            {useInputText({
              name: "reasonLeaveDes",
              componentProps: {
                style: { width: "100%" },
                placeholder: "เหตุผลเพิ่มเติม",
                disabled: record.reasonLeaveCode === "other" ? false : true,
              },
              fnChange: (e, ...other) => {
                onChangeDes(
                  record.key,
                  e.target.value,
                  "reasonLeaveDes",
                  other
                );
              },
            })}
          </Form.Item>
        </Fragment>
      );
    },
  },
];
export const ColumnQuitPersonalError = () => [
  {
    dataIndex: "title",
    title: "หัวข้อ",
    width: 100,
    fixed: "",
    align: "",
    ...filterSearchColumn("title", "หัวข้อ"),
  },
  {
    dataIndex: "description",
    title: "รายละเอียด",
    width: 100,
    fixed: "",
    align: "",
    ...filterSearchColumn("description", "รายละเอียด"),
  },
];
