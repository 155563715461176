export const USE_COLORS = {
    success: "#5CB85C",
    warning: "#F0AD4E",
    danger: "#C32604",
    yellow: "#ffec3d",
    white: "#FFFFFF",
    black: "#000000",
    percentPass: "#C6EFCE",
    percentWaring: "#FFEB9C",
    percentFail: "#FFC7CE",
    overDate: "#ff4d4f",
    duringDate: "#faad14",
    successDate: "#52c41a",
}