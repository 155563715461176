import { COMPONENT_TYPE, ERROR_MESSAGE } from ".."

const DEFINE = {
	emp: {
		label: "รายชื่อพนักงาน",
		name: "empNo",
	},
}

export const ComponentFilterOutSalesEmpCommission = ({
	options = {},
	isDisable = {},
	handleChange = () => null,
	isDepNo
}) => {
	const { emp } = DEFINE
	return [
		{ // Row
			rowProps: { justify: "start", align: "bottom" },
			column: [ // column
				{
					breakpoint: { xs: 24, sm: 24, md: 24, lg: 18, xl: 18 },
					component: {
						type: COMPONENT_TYPE.SELECT,
						itemProps: {
							name: emp.name,
							label: emp.label,
							rules: [{ required: true, message: ERROR_MESSAGE.SELECT(emp.label) }],
							mode: 'multi'
						},
						componentProps: {
							style: { width: "100%" },
							placeholder: ERROR_MESSAGE.SELECT(emp.label),
							mode: "multiple"
						},
						fnChange: (value) => handleChange(emp.name, value),
						dropdown: options[emp.name],
					},
				},
			], // column
		}, // Row
	]
}


