import React from 'react'
import { path } from 'ramda';

import TableTheme from "../../constants/tables/TableTheme";
import * as Layouts from "../../constants/layouts";
import { ButtonIcon } from '../../constants/buttons';
import { MdEdit, MdCreate } from 'react-icons/md';
import { colors } from '../../themes';
import { URL_IMAGE } from "../connectAPI";
import { Link } from "react-router";
import { SetDate } from '../../common/setDate';
import { RenderStatusMainCode } from '../../common/setStatus';
import { getColumnSearchProps } from '../../constants/functions/getColumnsSearch';
import Moment from "react-moment";
import { colorClassName } from '../../extendFunction/checkDateColorCode';

// const apiImg = connectAPI.URL_IMAGE
const headersProbations = [
	{ label: "สถานะ", key: "statusMain" },
	{ label: "รหัสพนักงาน", key: "StaffCode" },
	{ label: "ชื่อพนักงาน", key: "StaffName" },
	{ label: "สถานะพนักงาน", key: "statusWorkerDes" },
	{ label: "ชื่อผู้บังคับบัญชา", key: "BossName" },
	{ label: "แผนก / กลุ่ม", key: "DepNo" },
	{ label: "วันที่เริ่มงาน", key: "StartDateJob" },
	{ label: "วันที่ผ่านทดลองงาน", key: "expProDate" },
	{ label: "รอบการประเมิน", key: "RoundNow" },
	{ label: "สถานะการประเมินล่าสุด", key: "probationStatus_Des" },
	{ label: "กองทุนสำรองเลี้ยงชีพ", key: "providentFund" },
	{ label: "ประกันสังคม", key: "SSO" }
];

const columnProbation = (rest) => {
	const searchInput = React.createRef();
	return [
		{
			dataIndex: "statusMainCode",
			title: "สถานะ",
			key: "statusMain",
			width: 150,
            fixed: "left",
			render: (text, record) => {
				return RenderStatusMainCode(text, record.statusMain)
			}
		},
		{
			dataIndex: "StaffCode",
			title: "รหัสพนักงาน",
			key: "StaffCode",
			width: 80,
            fixed: "left",
			render: (text) => <Link target='_blank' to={URL_IMAGE + text + '.jpg'}><b>{text}</b>        </Link>
		},
		{
			dataIndex: "StaffName",
			title: "ชื่อพนักงาน",
			key: "StaffName",
			width: 100,
            fixed: "left",
			...getColumnSearchProps('StaffName', searchInput),

		},
		{
			dataIndex: "NickName",
			title: "ชื่อเล่น",
			key: "NickName",
			width: 100,
            fixed: "left",
			...getColumnSearchProps('NickName', searchInput),
		},
		{
			dataIndex: "Department_Name",
			title: "แผนก",
			key: "Department_Name",
			width: 100,
            fixed: "left",
			...getColumnSearchProps('Department_Name', searchInput),

		},
		{
			dataIndex: "Flow_Name",
			title: "สายอนุมัติ",
			key: "Flow_Name",
			width: 200,
			...getColumnSearchProps('Flow_Name', searchInput),

		},
		{
			dataIndex: "statusWorkerDes",
			title: "สถานะพนักงาน",
			key: "statusWorkerDes",
			width: 200,
			...getColumnSearchProps('statusWorkerDes', searchInput),
		},
		{
			dataIndex: "StartDateJob",
			title: "วันที่เริ่มงาน",
			key: "StartDateJob",
			width: 100,
			render: text => SetDate(text)
		},
		{
			dataIndex: "expProDate",
			title: "วันที่ครบกำหนดทดลองงาน",
			key: "expProDate",
			width: 120,
			render: text => SetDate(text)
		},
		{
			title: "วันที่ผ่านทดลองงาน",
			width: 120,
			dataIndex: 'probationEndDate',
			render: text => SetDate(text)
		},
		{
			title: "รอบปัจจุบัน",
			dataIndex: "RoundNow",
			key: "RoundNow",
			align: "right",
			width: 150,
		},
		{
			title: "รอบที่ยืนยันแล้ว",
			dataIndex: "status_confirm",
			key: "status_confirm",
			align: "right",
			width: 150,
		},
		{
			title: "รอบที่ 1",
			dataIndex: "RoundDate_1",
			key: "RoundDate_1",
			align: "right",
			width: 150,
			render: (text, record) => {
				let RoundDate_1 = "";
				if (record.RoundDate_1) {
					if (record.RoundNow > 1) {
						//จบการประเมินรอบที่ 1
						RoundDate_1 = record.RoundDate_1?(
							<Moment format="YYYY-MM-DD" className="date-green">
								{record.RoundDate_1}
							</Moment>
						):'-';
					} else {
						RoundDate_1 = record.RoundDate_1?(
							<Moment
								format="YYYY-MM-DD"
								className={colorClassName(record.RoundDate_1)}
							>
								{record.RoundDate_1}
							</Moment>
						):'-';
					}
				}
				return <div>{RoundDate_1 || "-"}</div>;
			},
		},
		{
			title: "รอบที่ 2",
			dataIndex: "RoundDate_2",
			key: "RoundDate_2",
			align: "right",
			width: 150,
			render: (text, record) => {
				let RoundDate_2 = "";
				if (record.RoundNow) {
					if (record.RoundNow > 2 || record.statusProbationLast === "1") {
						//จบการประเมินตอนรอบ 2
						RoundDate_2 = record.RoundDate_2?(
							<Moment format="YYYY-MM-DD" className="date-green">
								{record.RoundDate_2}
							</Moment>
						):'-';
					} else {
						RoundDate_2 = record.RoundDate_2?(
							<Moment
								format="YYYY-MM-DD"
								className={colorClassName(record.RoundDate_2)}
							>
								{record.RoundDate_2}
							</Moment>
						):'-';
					}
				}
				return <div>{RoundDate_2 || "-"}</div>;
			},
		},
		{
			title: "รอบที่ 3",
			dataIndex: "RoundDate_3",
			key: "RoundDate_3",
			align: "right",
			width: 150,
			render: (text, record) => {
				let RoundDate_3 = "";
				if (record.RoundNow) {
					if (record.RoundNow < 3 && record.statusProbationLast === "1") {
						//ประเมินจบตั้งแต่รอบที่ 2
						RoundDate_3 = "-";
					} else if (record.RoundNow > 3 || record.statusProbationLast === "1") {
						//จบการประเมินตอนรอบ 3
						RoundDate_3 = record.RoundDate_3?(
							<Moment format="YYYY-MM-DD" className="date-green">
								{record.RoundDate_3}
							</Moment>
						):'-';
					} else {
						RoundDate_3 = record.RoundDate_3?(
							<Moment
								format="YYYY-MM-DD"
								className={colorClassName(record.RoundDate_3)}
							>
								{record.RoundDate_3}
							</Moment>
						):'-';
					}
				}
				return <div>{RoundDate_3 || "-"}</div>;
			},
		},
		{
			title: "รอบที่ 4",
			dataIndex: "RoundDate_4",
			key: "RoundDate_4",
			align: "right",
			width: 150,
			render: (text, record) => {
				let RoundDate_4 = "";
				if (record.RoundNow) {
					if (record.RoundNow < 4 && record.statusProbationLast === "1") {
						//ประเมินจบตั้งแต่รอบที่ 3
						RoundDate_4 = "-";
					} else if (record.RoundNow > 4 || record.statusProbationLast === "1") {
						//จบการประเมินตอนรอบ 4
						RoundDate_4 = record.RoundDate_4?(
							<Moment format="YYYY-MM-DD" className="date-green">
								{record.RoundDate_4}
							</Moment>
						):'-';
					} else {
						RoundDate_4 = record.RoundDate_4?(
							<Moment
								format="YYYY-MM-DD"
								className={colorClassName(record.RoundDate_4)}
							>
								{record.RoundDate_4}
							</Moment>
						):'-';
					}
				}
				return <div>{RoundDate_4 || "-"}</div>;
			},
		},
		{
			title: "รอบที่ 5",
			dataIndex: "RoundDate_5",
			key: "RoundDate_5",
			align: "right",
			width: 150,
			render: (text, record) => {
				let RoundDate_5 = "";
				if (record.RoundNow) {
					if (record.RoundNow < 5 && record.statusProbationLast === "1") {
						//ประเมินจบตั้งแต่รอบที่ 4
						RoundDate_5 = "-";
					} else if (record.RoundNow > 5 || record.statusProbationLast === "1") {
						//จบการประเมินตอนรอบ 5
						RoundDate_5 = record.RoundDate_5?(
							<Moment format="YYYY-MM-DD" className="date-green">
								{record.RoundDate_5}
							</Moment>
						):'-';
					} else {
						RoundDate_5 = record.RoundDate_5?(
							<Moment
								format="YYYY-MM-DD"
								className={colorClassName(record.RoundDate_5)}
							>
								{record.RoundDate_5}
							</Moment>
						):'-';
					}
				}
				return <div>{RoundDate_5 || "-"}</div>;
			},
		},
		{
			title: "รอบที่ 6",
			dataIndex: "RoundDate_6",
			key: "RoundDate_6",
			align: "right",
			width: 150,
			render: (text, record) => {
				let RoundDate_6 = "";
				if (record.RoundNow) {
					if (record.RoundNow < 6 && record.statusProbationLast === "1") {
						//ประเมินจบตั้งแต่รอบที่ 5
						RoundDate_6 = "-";
					} else if (record.RoundNow > 6 || record.statusProbationLast === "1") {
						//จบการประเมินตอนรอบ 6
						RoundDate_6 = record.RoundDate_6?(
							<Moment format="YYYY-MM-DD" className="date-green">
								{record.RoundDate_6}
							</Moment>
						):'-';
					} else {
						RoundDate_6 = record.RoundDate_6?(
							<Moment
								format="YYYY-MM-DD"
								className={colorClassName(record.RoundDate_6)}
							>
								{record.RoundDate_6}
							</Moment>
						):'-';
					}
				}
				return <div>{RoundDate_6 || "-"}</div>;
			},
		},
		{
			dataIndex: "probationStatus_Des",
			title: "สถานะการประเมินล่าสุด",
			key: "probationStatus_Des",
			width: 180,
		},

	]
};

const ProbationViewTable = ({ dataSource, ...rest }) => {
	return (
		<Layouts.MainTheme
			component={<TableTheme
				size={"small"}
				headers={headersProbations}
				filename={"ProbationReport.csv"}
				columns={columnProbation(rest)}
				dataSource={dataSource}
				scroll={{ x: 1024, y: 600 }} />}
		/>
	)
}

const renderButton = (initial, { userRole, userLogin, handleLocation }) => {
	const { statusMainCode } = initial;

	let checkUser = (path(['BossCode'], initial) && path(['BossCode'], initial).trim() === userLogin) || (path(['ApprovalNo'], initial) && path(['ApprovalNo'], initial).trim() === userLogin)
	console.log('checkUser', checkUser)
	switch (statusMainCode) {  // ถ้ามี DocNo : Status MainCode จะต้องไม่มี 0  จะมีแค่ 1 and 2
		case "0": // รอสร้างแบบประเมิน : แสดง ปุ่ม create

			if (checkUser) {
				return <ButtonIcon
					name="create"
					label="CREATE"
					icon={<MdCreate />}
					color={colors.buttonCreate}
					onClick={() => handleLocation({ pathname: `${'/InsertProbation/'}`, state: initial, status: 'create' })} />
			} else {
				return (< div style={{ textAlign: 'center' }} > - </div >)
			}
		case "1": // อยู่ระหว่างประเมิน : แสดงปุ่ม Edit เช็คจาก roundNow ให้แก้ไขข้อมูลได้เฉพาะ roundNow นั้นๆ
			if (checkUser) {
				return <ButtonIcon
					name="edit"
					label="EDIT"
					icon={<MdEdit />}
					color={colors.buttonEdit}
					onClick={() => handleLocation({ pathname: `${'/UpdateProbation/'}`, state: initial, status: 'update' })} />
			} else {
				return (< div style={{ textAlign: 'center' }} > - </div >)
			}
		case "2": // รอส่งเอกสารให้ HR รับ : แสดงปุ่ม View จะสามารถ Print ได้ และ Confirm ได้ โดยเช็คจาก  HR_Received , User Other
			return (
				<ButtonIcon
					name="view"
					label="VIEW"
					// icon={<MdCreate />}
					color={colors.buttonTheme}
					onClick={() => handleLocation({ pathname: `${'/ViewProbation/'}`, state: initial })} />)
		default: return < div style={{ textAlign: 'center' }} > - </div >;
	}
}

export default ProbationViewTable



