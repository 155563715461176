import React, { useState, useEffect } from "react";
import { Form, Card, Spin, Space } from "antd";
import { optionCurrentStatusPeriod, optionSelectLocation, useOptionSelect } from "./constants/OptionSelect";
import { toUndefined, sumColumnWidth, GET_UserLogin } from "./functions/UseFunction";
import { useAlert } from "./conponents/modal/CustomAlert";
import { configForm, WORDS } from "./constants/ConstantComponent";
import { useRenderFormItem } from "./conponents/RenderComponents";
import CustomTableAtnd from "./conponents/tables/CustomTableAtnd";
import { useNotification } from "./conponents/notification/NotificationWithIcon";
import { POST, GET_RECHECK_WORKING_EMPLOYEE, GET_PERMISSION_USER_COMMISSION } from "../service";
import { useButton } from "./conponents/buttons/CustomButtonAntd";
import { useExportExcel } from "./services/ExportExcel";
import { Header_RecheckWorkingSaleEmployee } from "./constants/HeaderExcel";
import { useFixedQueryString } from "./functions/UseMakeQueryString";
import { ColumnRecheckWorkingSaleEmployee } from "./constants/RecheckWorkingSaleEmployee/ColumnRecheckWorkingSaleEmployee";
import { ComponentRecheckWorkingSaleEmployee, DEFAULT_RECHECK_WORKING_SALE_EMPLOYEE } from "./constants/RecheckWorkingSaleEmployee/ComponentRecheckWorkingSaleEmployee";
import { optionInstallmentSaleStatus, optionStatusWorkerCode } from '../common/enum'
import { SetStringToObject } from "../constants/functions";
import Cookies from 'js-cookie';
import encodeString from "../extendFunction/encodeString";
import { browserHistory } from "react-router";

const RecheckWorkingSaleEmployee = () => {
	const { location: locationLogin, userlogin } = GET_UserLogin();
	const [formCheckEmployee] = Form.useForm();
	const [ChangeValue, setChangeValue] = useState({ ...DEFAULT_RECHECK_WORKING_SALE_EMPLOYEE, location: locationLogin === "FAC" ? "FAC" : undefined });
	const [dataTable, setDataTable] = useState([]);
	const [loadingScreen, setLoadingScreen] = useState(false);
	const [permissionUser, setPermissionUser] = useState([]);
	const cookies = SetStringToObject(Cookies.get("cookie_session"));

	let optionSearch = {
		company: useOptionSelect("company"),
		location: optionSelectLocation,
		// depNo: useOptionSelect("department", useFixedQueryString(ChangeValue, ["companyCode", "location"])),
		subDepNo: useOptionSelect("subDepartment", useFixedQueryString(ChangeValue, ["company", "location", "depNo"])),
		currentStatusPeriod: optionCurrentStatusPeriod,
		optionStatusWorkerCode: optionStatusWorkerCode.map(el => { return { txt: el.label, value: el.value } }),
		depNo: permissionUser.map(el => { return { txt: `[${el.departmentNo}]: ${el.departmentName}`, value: el.departmentNo } }),
		optionInstallmentSaleStatus: optionInstallmentSaleStatus.map(el => { return { txt: el.label, value: el.value } })
	}

	useEffect(() => {
		async function fetchApi() {
			await getPermissionUser()
		}
		fetchApi()
	}, [])

	useEffect(() => {
		if (permissionUser.length > 0) {
			onFinish({})
		}
	}, [permissionUser])

	const getPermissionUser = async () => {
		try {
			setLoadingScreen(true);
			const response = await POST(GET_PERMISSION_USER_COMMISSION, { page: "recheckEmp", userLogin: encodeString.decode(cookies.ID_User) });
			const { result } = response;
			if (result.length == 0) {
				browserHistory.push('/')
			} else {
				setPermissionUser(result)
			}
		} catch (err) {
			useAlert({ type: "catch", content: err.message })
			console.log("Error getPermissionUser: ", err);
			browserHistory.push('/')
		} finally {
			setLoadingScreen(false);
		}
	}

	const onFinish = (values) => {
		const { company, location, depNo, startDateJob, endDateJob, statusWorkerInstallment, statusWorker } = values
		let objectBody = {
			company: company || "",
			location: location || "",
			depNo: depNo || permissionUser.map(val => val.departmentNo).join(','),
			statusWorker: statusWorker || "",
			startDateJob: startDateJob ? startDateJob.format("YYYY-MM-DD") : "",
			endDateJob: endDateJob ? endDateJob.format("YYYY-MM-DD") : "",
			statusWorkerInstallment: statusWorkerInstallment && statusWorkerInstallment.length > 0 ? statusWorkerInstallment : optionInstallmentSaleStatus.map(el => el.value),
		}
		selectDataRecheckEmployee(objectBody);
	};

	const onReset = () => {
		formCheckEmployee.resetFields();
		setDataTable([]);
		setChangeValue({ ...DEFAULT_RECHECK_WORKING_SALE_EMPLOYEE, location: locationLogin === "FAC" ? "FAC" : undefined });
	};

	const selectDataRecheckEmployee = async (objectValue) => {
		try {
			setLoadingScreen(true);
			const response = await POST(GET_RECHECK_WORKING_EMPLOYEE, { userLogin: userlogin, ...objectValue });
			const { result } = response;
			let mapResult = [];
			if (result.length !== 0) {
				mapResult = result.map((row, index) => ({
					key: `${row.userId}_${index}`,
					statusInstallmentName: renderLabelStatusInstallment(row.statusWorkerInstallment),
					...row,
				}));
			} else {
				useNotification({ type: "info", title: "ไม่พบข้อมูล" });
			}
			setDataTable(mapResult);
		} catch (err) {
			useAlert({ type: "catch", content: err.message })
			console.log("Error Search Recheck Employee: ", err);
		} finally {
			setLoadingScreen(false);
		}
	};

	const renderLabelStatusInstallment = (statusWorkerInstallment) => {
		return optionInstallmentSaleStatus[optionInstallmentSaleStatus.findIndex(el => (el.value).split(' | ')[0] == statusWorkerInstallment)].label
	}

	const onChangeValue = (name, value) => {
		let setUndefined = {};
		let setName = {};
		switch (name) {
			case "company":
				setName = { companyCode: value };
				if (locationLogin === "FAC") {
					setUndefined = toUndefined(["depNo", "subDepNo", "hrmFlow"]);
				} else {
					setUndefined = toUndefined(["location", "depNo", "subDepNo", "hrmFlow"]);
				}
				break;
			case "location":
				setUndefined = toUndefined(["depNo", "subDepNo", "hrmFlow"]);
				break;
			case "depNo":
				setUndefined = toUndefined(["subDepNo"]);
				break;
		}
		formCheckEmployee.setFieldsValue(setUndefined);
		setDataTable([]);
		setChangeValue((prev) => ({
			...prev,
			...setUndefined,
			...setName,
			[name]: value,
		}));
	};

	return (
		<Spin tip={WORDS.loadingSpin} spinning={loadingScreen}>
			<Form form={formCheckEmployee} onFinish={onFinish} {...configForm} initialValues={{ location: locationLogin === "FAC" ? "FAC" : undefined }} >
				<Card
					title="รายงานตรวจสอบพนักงานเซลล์เข้า-ออก"
					style={{ marginBottom: 30 }}
					extra={
						<Space>
							{useButton({ call: "search" })}
							{useButton({
								call: "normal",
								fn: onReset,
								moreProps: { label: "ล้างข้อมูล" },
							})}
						</Space>
					}
				>
					{useRenderFormItem({
						constantComponent: ComponentRecheckWorkingSaleEmployee({
							options: optionSearch,
							handleChange: onChangeValue,
							isDisable: {
								location: locationLogin === "FAC" || false,
							},
						}),
					})}
				</Card>
				{dataTable.length !== 0 && (
					<Card
						title="รายงานตรวจสอบพนักงานเซลล์เข้า-ออก"
						style={{ marginBottom: 30 }}
						extra={
							useExportExcel({
								header: Header_RecheckWorkingSaleEmployee,
								dataset: dataTable,
								filename: "รายงานตรวจสอบพนักงานเซลล์เข้า-ออก",
							})
						}
					>
						<CustomTableAtnd
							HeadTable={ColumnRecheckWorkingSaleEmployee}
							DataTable={dataTable}
							moreProps={{
								scroll: {
									x: sumColumnWidth(ColumnRecheckWorkingSaleEmployee) || 2850,
									y: 500,
								},
								size: "small"
							}}
						/>
					</Card>
				)}
			</Form>
		</Spin>
	)
}

export default RecheckWorkingSaleEmployee
