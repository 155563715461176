import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "antd/dist/antd.css";
import { Nav, Navbar, Button, NavDropdown } from "react-bootstrap";
import Cookies from "js-cookie";
import { browserHistory } from "react-router";
import { DropdownSubmenu } from "react-bootstrap-submenu";
import { GET_UserLogin } from "../../functions/UseFunction";
import { CenterAPI } from "../../services/ConnectAPI";
import { POST, authenLink } from "../../../service";
import GA4React from "ga-4-react";
import { PATH_ROUTE } from "../../constants/ConstantPath";

export const RenderCustomMenu = () => {
  const [navigateData, setNavigateData] = useState([]);

  const { userlogin, location, userName } = GET_UserLogin();
  useEffect(() => {
    getNavigator();
  }, []);

  useEffect(() => {
    setDocumentTitle();
  }, [window.location.pathname]);

  const logout = () => {
    Cookies.remove("cookie_session");
    browserHistory.push("/");
    window.localStorage.clear();
    window.location.reload();
    window.location = authenLink;
  };

  const setDocumentTitle = () => {
    if (navigateData.length > 0) {
      // console.log('setDocumentTitle')
      const findIndex = navigateData.findIndex(
        (e) => e.pathLink === window.location.pathname
      );
      if (findIndex !== -1) {
        document.title = navigateData[findIndex].pageName;
        setGoogleAnalytics(navigateData[findIndex].pageName);
      }
    }
  };

  const getNavigator = async () => {
    try {
      const url = `${CenterAPI.POST_GetNavigator}`;
      const objectBody = {
        userId: userlogin || "",
        location: location || "",
      };
      const response = await POST(url, objectBody);
      const { success, result } = response;
      //console.log("MENU: ", response);
      if (success) {
        setNavigateData(result);
        /**set document title */
        setDocumentTitle();
      }
    } catch (err) {
      console.log("Error Get Navigator: ", err);
    }
  };

  const changeFormatMenu = (navigate = []) => {
    let result = [];
    const HEADER = navigate.filter((val) => val.pageType === "HEADER");
    const SUB = navigate.filter((val) => val.pageType === "SUBMENU");
    const SUB2 = navigate.filter((val) => val.pageType === "SUBMENU2");
    result = HEADER.map((val) => {
      const SUBMENU = SUB.filter(
        (fil) => val.pageNo.substr(0, 2) === fil.pageNo.substr(0, 2)
      );
      const MAP_SUB = SUBMENU.map((sub) => {
        const SUBMENU2 = SUB2.filter(
          (fil) => sub.pageNo.substr(0, 3) === fil.pageNo.substr(0, 3)
        );
        return {
          ...sub,
          submenu2: SUBMENU2,
        };
      });
      return {
        ...val,
        submenu: MAP_SUB,
      };
    });
    //console.log("Result changeFormatMenu: ", result);
    return result;
  };

  const mapMenu = (menu) => {
    return menu.map((val) => {
      return (
        <NavDropdown
          key={val.key}
          title={val.pageName}
          id="collasible-nav-dropdown"
        >
          {val.submenu.map((sub) => {
            if (sub.submenu2.length !== 0) {
              return (
                <DropdownSubmenu key={sub.key} title={sub.pageName}>
                  {sub.submenu2.map((sub2) => {
                    return (
                      <NavDropdown.Item
                        key={sub2.key}
                        onClick={() => browserHistory.push(sub2.pathLink)}
                      >
                        {sub2.pageName}
                      </NavDropdown.Item>
                    );
                  })}
                </DropdownSubmenu>
              );
            } else {
              // Check has submenu2
              return (
                <NavDropdown.Item
                  key={sub.key}
                  onClick={() => browserHistory.push(sub.pathLink)}
                >
                  {sub.pageName}
                </NavDropdown.Item>
              );
            }
          })}
        </NavDropdown>
      );
    });
  };

  const setGoogleAnalytics = (title) => {
    // console.log('process.env', process.env.NODE_ENV)
    if (process.env.NODE_ENV !== "development") {
      const ga4react = new GA4React("G-XX8W5BXZW8");
      ga4react.initialize().then(
        (ga4) => {
          // ga4.pageview(window.location.pathname + window.location.search)
          ga4.gtag(
            window.location.pathname,
            title,
            window.location.pathname + window.location.search
          ); // or your custom gtag event
        },
        (err) => {
          console.error("ga4react :: ", err);
        }
      );
    }
    // console.log('GoogleAnalyticsWrapper', title)
  };

  return (
    <Navbar
      collapseOnSelect
      expand="lg"
      style={{ "box-shadow": "rgba(149, 157, 165, 0.2) 0px 8px 24px" }}
    >
      <Navbar.Brand onClick={() => browserHistory.push("/")}>
        <span className="logo" />
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      {/* {setDocumentTitle()} */}
      <Navbar.Collapse id="responsive-navbar-nav">
        <Nav className="mr-auto">{mapMenu(changeFormatMenu(navigateData))}</Nav>
        <Nav>
          {/* <Navbar.Text>Welcome, {userlogin}</Navbar.Text> */}
          <NavDropdown
            title={`Welcome, ${userlogin}`}
            id="collasible-nav-dropdown"
          >
            <Nav.Item style={{ marginLeft: 25 }}>{userName}</Nav.Item>
            <NavDropdown.Divider />
            <NavDropdown.Item
              onClick={() => browserHistory.push(PATH_ROUTE.ChangePassword)}
            >
              เปลี่ยนรหัสผ่าน
            </NavDropdown.Item>
            <NavDropdown.Item onClick={logout}>ออกจากระบบ</NavDropdown.Item>
          </NavDropdown>
        </Nav>
      </Navbar.Collapse>
    </Navbar>

  );
};
