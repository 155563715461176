import React, { useContext, useEffect, useState } from 'react'
import { STRUCTURE_KPIS_FACTORY } from '../../constants/structures/structuresKPIsFactory'
import { MasterContext } from '../../services/master/masterProvider';
import { ReportContext, REPORT_TYPE } from '../../services/report/reportProvider';
import { LayoutSearch, LayoutBreadcrumb, LayoutWrapper, LayoutTabs } from '../../common/layouts';
import { Col, Row, Spin } from 'antd';
import { ButtonTheme } from '../../common/buttons';
import { IndicatorContext } from '../../services/manage/indicatorProvider';
import { MASTER_OPTION } from '../../services/servicesOptions';
import { TYPE } from '../../common/inputs';

export const PageManageKPIsFactory = () => {
	const STRUCTURE = STRUCTURE_KPIS_FACTORY;
	const { masterState, onChange, getMaster } = useContext(MasterContext);
	const [loading, setLoading] = useState(false)

	const { userLogin, userPermission } = useContext(IndicatorContext)
	const { searchReport } = useContext(ReportContext)
	let dataSearch = JSON.parse(window.localStorage.getItem("dataSearch"));
	let SEARCH_LIST = STRUCTURE.SEARCH_LISTS(userPermission, { year: !!dataSearch ? dataSearch['year'] : '' })
	let TAB_LIST = STRUCTURE.TABS_LISTS(userPermission, { year: !!dataSearch ? dataSearch['year'] : '' })

	useEffect(() => {
		if (dataSearch) {
			searchReport({
				reportName: REPORT_TYPE.REFRESH_DASHBOARD_KPIS,
				searchOption: masterState,
				dataSearch: dataSearch,
				factory: true
			})
		}
		getMaster({
			fieldname: MASTER_OPTION.FILTER_FACTORY, payload: {
				userId: userLogin,//'ZT58025'
				userPermission: userPermission // userId: 'DP55245'
			}
		})
	}, [])
	return (

		<LayoutWrapper
			breadcrumb={<LayoutBreadcrumb breadcrumbLists={STRUCTURE.BREADCRUMB()} />}
			search={
				<Row gutter={[24, 10]}>
					<ButtonTheme
						style={{ position: 'absolute', top: '0px', padding: '0px', right: '0px' }}
						type='primary'
						buttonText="ค้นหา"
						onClick={async () => {
							// window.localStorage.removeItem('statusWorker')
							setLoading(true)
							let success = await searchReport({
								reportName: REPORT_TYPE.DASHBOARD_KPIS,
								searchOption: masterState,
								searchYear: dataSearch ? dataSearch['year'] : null,
								factory: true
							})
							if (success) {
								setLoading(false)
							}

						}} />
					{
						SEARCH_LIST.map((each, eachIndex) => {
							return (<Col span={each.span} key={eachIndex}>
								<LayoutSearch
									type={TYPE.SELECT}
									header={each.header}
									onChange={onChange}
									fieldOption={each.fieldOption}
									defaultValue={each.defaultValue ? each.defaultValue : masterState[`SEARCH_${each.fieldOption}`][0]}
									plainOptions={masterState[each.fieldOption]}
								/>

							</Col>)
						})
					}
				</Row>
			}
			content={
				<div className="layout-content-card">
					<Spin spinning={loading} tip="กำลังโหลด...">
						<LayoutTabs tabsLists={TAB_LIST} />
					</Spin>
				</div>
			}
		/>
	)
}