import React, { Component } from "react";
import "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css";
import { CSVLink } from "react-csv";
import { MdFileDownload } from "react-icons/md";
import { FiArrowDownCircle, FiCheckCircle } from "react-icons/fi";
import Moment from "react-moment";
import { API_URL } from "../connectAPI";
import checkDateColorCode from "../../extendFunction/checkDateColorCode";
import { Table, Popconfirm, Modal } from "antd";
import { SetDate } from "../../common/setDate";
import { RenderStatusMainCode } from "../../common/setStatus";

class BenefitReportOut extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  insertDate = (_getType, _data) => {
    const userLogin = this.props.userLogin;
    const link = API_URL + "/HRM_API/insert_timeStampBenefits";

    var data = {
      StaffCode: _data.StaffCode,
      typeInsurance: _getType,
      typeBenefit: 'out',
      user_login: userLogin
    };

    fetch(link, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json"
      },
      body: JSON.stringify(data)
    })
      .then(Response => Response.json())
      .then(results => {
        if (results.status_api === 1) {
          Modal.success({ title: "บันทึกเรียบร้อยแล้ว", onOk: () => window.location.reload() })
        } else {
          Modal.error({ title: "Insert Error!!" })
        }
      });
  };

  render() {
    const columnsBenefit = [
      {
        title: "รหัสพนักงาน",
        dataIndex: "StaffCode",
        key: "StaffCode",
        fixed: "left",
      },
      {
        title: "ชื่อพนักงาน",
        dataIndex: "StaffName",
        key: "StaffName",
        fixed: "left",
      },
      {
        dataIndex: "NickName",
        title: "ชื่อเล่น",
        key: "NickName",
        width: 100,
        fixed: "left",
      },
      {
        dataIndex: "probationStatus_Des",
        title: "ผลการทดลองงาน",
        key: "probationStatus_Des",
        width: 100,
        fixed: "left",
        render: (text, record) => {
          return RenderStatusMainCode(text, record.statusMain)
        }
        // render: (text, record) => {
        //   let icon = <FiClock color={"#424949"} size={"18"} />;
        //   if (record.statusProbationLast === '1') { //ผ่านทดลองงาน
        //     icon = <FiCheckCircle color={"#028E22"} size={"18"} />

        //   } else if (record.statusProbationLast === '2') {  //ไม่ผ่าน
        //     icon = <FiXCircle color={"#E74C3C"} size={"18"} />

        //   } else if (record.statusProbationLast === '3') {  //ต่อโปร
        //     icon = <FiAlertCircle color={"#F4D03F"} size={"18"} />

        //   }
        //   return <div>
        //     <Tooltip placement="topLeft" title={record.probationStatus_Des}>
        //       {icon}
        //     </Tooltip>
        //   </div>;
        // }
      },
      {
        label: "กลุ่ม",
        title: "กลุ่ม",
        dataIndex: "statusWorker",
        key: "statusWorker",
      },
      {
        title: "ชื่อผู้บังคับบัญชา",
        dataIndex: "BossName",
        key: "BossName"
      },
      {
        title: "แผนก / กลุ่ม",
        dataIndex: "Department_Name",
        key: "Department_Name",
      },
      {
        title: "วันที่เริ่มงาน",
        dataIndex: "StartDateJob",
        key: "StartDateJob",
        render: text => SetDate(text)
      },
      {
        title: "วันที่พ้นสภาพการเป็นพนักงาน",
        dataIndex: "EndDateJob",
        key: "EndDateJob",
        render: text => SetDate(text)
      },
      {
        title: "ประกันสุขภาพ / ประกันอุบัติเหตุ",
        key: "date_HEA",
        render: (text, record) => {
          let dateEndHEA = "";
          if (record.date_HEA === null && record.statusProbationLast !== '2') {
            dateEndHEA = (
              <React.Fragment>
                <Popconfirm
                  title="ยืนยันการอัพเดท?"
                  onConfirm={() => this.insertDate("hea", record)}
                  onCancel={this.cancel}
                  okText="Yes"
                  cancelText="No"
                >
                  <FiArrowDownCircle color={checkDateColorCode.codeColor(record.expACD_HEA)} size={"18"} />
                  <Moment format="YYYY-MM-DD" className={checkDateColorCode.colorClassName(record.expACD_HEA)}>
                    {record.expACD_HEA}
                  </Moment>
                </Popconfirm>
              </React.Fragment>
            );
          } else if (record.date_HEA !== null && record.statusProbationLast !== '2') {
            dateEndHEA = (
              <React.Fragment>
                <FiCheckCircle color={"#028E22"} size={"18"} />
                <Moment format="YYYY-MM-DD" className="date-green">
                  {record.date_HEA}
                </Moment>
              </React.Fragment>
            );
          }
          return <div>{dateEndHEA}</div>;
        }
      },
      {
        title: "กองทุนสำรองเลี้ยงชีพ",
        key: "date_PVD",
        // filters: false,
        render: (text, record) => {
          let dateEndPVD = "";
          if (record.date_PVD === null && record.statusProbationLast !== '2') {
            dateEndPVD = (
              <React.Fragment>
                <Popconfirm
                  title="ยืนยันการอัพเดท?"
                  onConfirm={() => this.insertDate("pvd", record)}
                  onCancel={this.cancel}
                  okText="Yes"
                  cancelText="No"
                >
                  <FiArrowDownCircle color={checkDateColorCode.codeColor(record.expPVD)} size={"18"} />
                  <Moment format="YYYY-MM-DD" className={checkDateColorCode.colorClassName(record.expPVD)}>
                    {record.expPVD}
                  </Moment>
                </Popconfirm>
              </React.Fragment>
            );
          } else if (record.date_PVD != null && record.statusProbationLast !== '2') {
            dateEndPVD = (
              <React.Fragment>
                <FiCheckCircle color={"#028E22"} size={"18"} />
                <Moment format="YYYY-MM-DD" className="date-green">
                  {record.date_PVD}
                </Moment>
              </React.Fragment>
            );
          }
          return <div>{dateEndPVD}</div>;
        }
      },
      {
        title: "ประกันสังคม",
        key: "date_SOS",
        render: (text, record) => {
          let dateEndSOS = "";
          if (record.date_SOS === null) {
            dateEndSOS = (
              <React.Fragment>
                <Popconfirm
                  title="ยืนยันการอัพเดท?"
                  onConfirm={() => this.insertDate("sos", record)}
                  onCancel={this.cancel}
                  okText="Yes"
                  cancelText="No"
                >
                  <FiArrowDownCircle color={checkDateColorCode.codeColor(record.expSOS)} size={"18"} />
                  <Moment format="YYYY-MM-DD" className={checkDateColorCode.colorClassName(record.expSOS)}>
                    {record.expSOS}
                  </Moment>
                </Popconfirm>
              </React.Fragment>
            );
          } else if (record.date_SOS != null) {
            dateEndSOS = (
              <React.Fragment>
                <FiCheckCircle color={"#028E22"} size={"18"} />
                <Moment format="YYYY-MM-DD" className="date-green">
                  {record.date_SOS}
                </Moment>
              </React.Fragment>
            );
          }
          return <div>{dateEndSOS}</div>;
        }
      },
    ];

    const headersBenefit = [
      { label: "รหัสพนักงาน", key: "StaffCode" },
      { label: "ชื่อพนักงาน", key: "StaffName" },
      { label: "ชื่อผู้บังคับบัญชา", key: "BossName" },
      { label: "แผนก / กลุ่ม", key: "DepNo" },
      { label: "วันที่เริ่มงาน", key: "StartDateJob" },
      { label: "วันที่คาดว่าผ่านทดลองงาน", key: "expProDate" },
      { label: "วันที่ผ่านทดลองงาน", key: "probationEndDate" },
      { label: "วันที่พ้นสภาพการเป็นพนักงาน", key: "EndDateJob" },
      { label: "ประกันสุขภาพ / ประกันชีวิต", key: "date_HEA" },
      { label: "ประกันอุบัติเหตุ", key: "date_ACD" },
      { label: "กองทุนสำรองเลี้ยงชีพ", key: "date_PVD" },
      { label: "ประกันสังคม", key: "date_SOS" }
    ];

    return (
      <div>
        <div className="btn-right">
          <CSVLink
            headers={headersBenefit}
            data={this.props.dataSource || []}
            filename={"BenefitsReport.csv"}
            className="btn btn-export"
            target="_blank"
          >
            <MdFileDownload /> Export CSV
          </CSVLink>
        </div>

        <Table
          columns={columnsBenefit}
          dataSource={this.props.dataSource || []}
          scroll={{ x: 2200 }}
        />

      </div>
    );
  }
}

export default BenefitReportOut;
