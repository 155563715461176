import React from "react";
import "../index.css";
import "bootstrap/dist/css/bootstrap.css";
import { Button, Label, Container } from "reactstrap";
import "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css";
import CsvParse from "@vtex/react-csv-parse";
import { CSVLink } from "react-csv";
import { MdFileDownload } from "react-icons/md";
import { getCurrent } from "../extendFunction/getCurrentYears";
import { Modal, Row, Col, Card, Form, Select, Table, Spin, Space } from "antd";
import {
  GET,
  POST,
  POST_INSERT_DAY_FORM_DAILY_WORKER,
  SELECT_DAY_FORM_DAILY_WORKER,
} from "../service";
import { ColumnImportNumDayDailyWorker } from "../modulePMS/constantsPMS/columns";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { FONT_SUPERMARKET } from "../screens/constants/Fonts";
import { CONFIG } from "../components/connectAPI";

class ImportNumDayDailyWorker extends React.Component {
  constructor(props) {
    super(props);
    const { yearsNow, monthNow } = getCurrent();
    const { userLogin } = this.props;
    this.state = {
      upload_open: false,
      arrExcel: [],
      loadData: [],
      done: true,
      yearsTxt: yearsNow,
      monthTxt: monthNow,
      userLogin,
      showModal: false,
    };
  }

  handleData = (data) => {
    this.setState({ arrExcel: data });
  };

  handleOnError = (err, file, inputElem, reason) => {
    console.log("err: ", err);
    console.log("file: ", file);
    console.log("inputElem: ", inputElem);
    console.log("reason: ", reason);
  };

  onAddExcel = async () => {
    try {
      const { arrExcel, userLogin } = this.state;
      if (arrExcel.length === 0) {
        Modal.warning({ title: "กรุณาแนบไฟล์" });
      } else {
        const res = await POST(POST_INSERT_DAY_FORM_DAILY_WORKER, {
          data: arrExcel,
          userLogin,
        });
        // console.log("res", res);
        const { success } = res;
        // console.log("res", success);
        if (success) {
          Modal.success({
            title: "เพิ่มข้อมูลสำเร็จ",
            onOk: () => window.location.reload(),
          });
        } else {
          Modal.error({ title: "เพิ่มข้อมูลไม่สำเร็จ" });
        }
      }
    } catch (err) {
      console.log("onAddExcel: ", err);
    }
  };

  onFinshin = async (value) => {
    try {
      const { yearsTxt, monthTxt } = value;
      this.setState({ loadData: [], done: false });
      const res = await GET(
        SELECT_DAY_FORM_DAILY_WORKER({ yearsTxt, monthTxt })
      );
      const { success, result } = res;
      let loadData = [];
      if (success) {
        if (result.length !== 0) {
          loadData = result.map((row, index) => ({
            key: index,
            rowNum: index + 1,
            PositionCode: row.employeeId,
            ...row,
          }));
        } else {
          Modal.error({ title: "ไม่พบข้อมูล" });
        }
      }
      this.setState({ loadData });
    } catch (err) {
      console.log("Error Search selectReportCalRevenueGoodGrade", err);
    } finally {
      this.setState({ done: true });
    }
  };

  onChange = (value, name) => {
    this.setState({ [name]: value, loadData: [] });
  };

  render() {
    const _year = getCurrent().yearsNow;
    return (
      <Container>
        {this._renderModal()}
        <div>
          <Spin spinning={this.state.done ? false : true} tip="กำลังค้นหา ...">
            <Card
              title="Dashboard & Import ข้อมูลเงินได้พิเศษจาก KPI"
              style={{ marginBottom: 30 }}
              extra={
                <Space>
                  <Button
                    color="warning"
                    size="md"
                    onClick={() => this.setState({ showModal: true })}
                  >
                    Import Data
                  </Button>
                  <Button
                    color="info"
                    size="md"
                    href={`${CONFIG.API_URL}/template/TemplateImportNumDayDailyWorker.csv`}
                    target="_blank"
                  >
                    Download Template
                  </Button>
                </Space>
              }
            >
              <Form
                onFinish={this.onFinshin}
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
              >
                <Row gutter={[16, 16]} justify="start" align="bottom">
                  <Col xs={24} sm={24} md={24} lg={6} xl={4}>
                    <Form.Item
                      name="yearsTxt"
                      label="ประจำปี"
                      rules={[
                        {
                          required: true,
                          message: "กรุณาเลือก ปี",
                        },
                      ]}
                    >
                      <Select
                        showSearch
                        allowClear
                        style={{ width: "100%" }}
                        placeholder="กรุณาเลือก ประจำปี"
                        onChange={(value) => this.onChange(value, "yearsTxt")}
                        filterOption={(input, option) =>
                          option.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        <Select.Option value={_year}>{_year}</Select.Option>
                        <Select.Option value={_year - 1}>
                          {_year - 1}
                        </Select.Option>
                        <Select.Option value={_year - 2}>
                          {_year - 2}
                        </Select.Option>
                        <Select.Option value={_year - 3}>
                          {_year - 3}
                        </Select.Option>
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={6} xl={4}>
                    <Form.Item
                      name="monthTxt"
                      label="เดือน"
                      rules={[
                        {
                          required: true,
                          message: "กรุณาเลือก เดือน",
                        },
                      ]}
                    >
                      <Select
                        showSearch
                        allowClear
                        style={{ width: "100%" }}
                        placeholder="กรุณาเลือก เดือน"
                        onChange={(value) => this.onChange(value, "monthTxt")}
                        filterOption={(input, option) =>
                          option.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        <Select.Option value="1"> มกราคม </Select.Option>
                        <Select.Option value="2"> กุมภาพันธ์ </Select.Option>
                        <Select.Option value="3"> มีนาคม </Select.Option>
                        <Select.Option value="4"> เมษายน </Select.Option>
                        <Select.Option value="5"> พฤษภาคม </Select.Option>
                        <Select.Option value="6"> มิถุนายน </Select.Option>
                        <Select.Option value="7"> กรกฎาคม </Select.Option>
                        <Select.Option value="8"> สิงหาคม </Select.Option>
                        <Select.Option value="9"> กันยายน </Select.Option>
                        <Select.Option value="10"> ตุลาคม </Select.Option>
                        <Select.Option value="11"> พฤศจิกายน </Select.Option>
                        <Select.Option value="12"> ธันวาคม </Select.Option>
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={6} xl={4}>
                    <Form.Item>
                      <Button color="info" type="submit">
                        SEARCH
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </Card>

            {this.state.loadData.length !== 0 && (
              <>
                <Row gutter={[16, 16]} align="top" justify="end">
                  <Col>{this._renderExportPDF()}</Col>
                  <Col>{this._renderButtonExportCSV()}</Col>
                </Row>
                {this._renderTableAntd()}
              </>
            )}
          </Spin>
        </div>
      </Container>
    );
  } // Render

  _renderTableAntd = () => {
    const { loadData } = this.state;
    return (
      <Table
        columns={ColumnImportNumDayDailyWorker}
        dataSource={loadData}
        pagination={{
          showTotal: (total, range) =>
            `${range[0]}-${range[1]} จาก ${total} รายการ`,
          responsive: true,
          defaultPageSize: 10,
          showSizeChanger: true,
        }}
        scroll={{
          x: 1450,
          y: 500,
        }}
      />
    );
  };

  _renderExportPDF = () => {
    const exportPDF = () => {
      const { loadData } = this.state;
      const totalPagesExp = "{total_pages_count_string}";
      const title = "ข้อมูลเงินได้พิเศษจาก KPI";

      // Setting Paper
      const unit = "pt";
      const size = "A4"; // Use A1, A2, A3 or A4
      const orientation = "portrait"; // portrait or landscape
      const doc = new jsPDF(orientation, unit, size);

      // Add Font
      doc.addFileToVFS("supermarket.ttf", FONT_SUPERMARKET);
      doc.addFont("supermarket.ttf", "supermarket", "normal");
      doc.setFont("supermarket");

      let content = {
        headStyles: { halign: "center" },
        styles: {
          font: "supermarket",
        },
        columns: [
          { header: "ลำดับ", dataKey: "rowNum" },
          { header: "ปี", dataKey: "year" },
          { header: "เดือน", dataKey: "month" },
          { header: "รหัสพนักงาน", dataKey: "employeeId" },
          { header: "ชื่อ - นามสกุล", dataKey: "Name" },
          { header: "แผนก (ย่อย)", dataKey: "subDepName" },
          { header: "เกรด", dataKey: "grade" },
          { header: "จำนวนวันทำงาน", dataKey: "numDay" },
          { header: "จำนวนเงินได้", dataKey: "amount" },
        ],
        body: loadData,
        didDrawPage: function (data) {
          // Header
          doc.setFontSize(16);
          doc.text(title, data.settings.margin.left, 40);

          // Footer
          let pageCount = doc.internal.getNumberOfPages();
          let pageOF = "";
          if (typeof doc.putTotalPages === "function") {
            pageOF = `Page ${pageCount} / ${totalPagesExp}`;
          }
          doc.setFontSize(10);

          const pageSize = doc.internal.pageSize;
          const pageHeight = pageSize.height
            ? pageSize.height
            : pageSize.getHeight();
          doc.text(pageOF, data.settings.margin.left, pageHeight - 20);
        },
        margin: { top: 50 },
      }; // Object Content

      doc.autoTable(content); // SET Content to Table

      if (typeof doc.putTotalPages === "function") {
        doc.putTotalPages(totalPagesExp); // SET Footer
      }

      doc.save("เงินได้พิเศษ.pdf");
    };
    return (
      <button
        type="button"
        className="btn btn-warning"
        onClick={() => exportPDF()}
      >
        <MdFileDownload /> Export PDF
      </button>
    );
  };

  _renderButtonExportCSV = () => {
    const { loadData } = this.state;

    const headerCSV = [
      { label: "year", key: "year" },
      { label: "month", key: "month" },
      { label: "รหัสพนักงาน", key: "employeeId" },
      { label: "ชื่อ - นามสกุล", key: "Name" },
      { label: "แผนก (ย่อย)", key: "subDepName" },
      { label: "เกรด", key: "grade" },
      { label: "จำนวนวันทำงาน", key: "numDay" },
      { label: "จำนวนเงินได้", key: "amount" },
    ];

    return (
      <CSVLink
        headers={headerCSV}
        data={loadData}
        filename={"เงินได้พิเศษ.csv"}
        className="btn btn-export"
      >
        <MdFileDownload /> Export CSV
      </CSVLink>
    );
  };

  _renderModal = () => {
    const { handleData, handleOnError, onAddExcel } = this;
    const keys = ["year", "month", "employeeId", "numDay"];

    return (
      <Modal
        title="Import Data"
        visible={this.state.showModal}
        onCancel={() => this.setState({ showModal: false })}
        onOk={() =>
          Modal.confirm({
            title: "ยืนยันการเพิ่มข้อมูลหรือไม่",
            onOk: () => onAddExcel(),
          })
        }
        okText={"Upload"}
      >
        <Label style={{ color: "red", marginRight: "20px" }}>
          **รองรับเฉพาะไฟล์ .csv เท่านั้น
        </Label>
        <br />
        <CsvParse
          keys={keys}
          onDataUploaded={handleData}
          onError={handleOnError}
          render={(onChange) => (
            <input type="file" onChange={onChange} accept=".csv" />
          )}
        />
      </Modal>
    );
  };
}
export default ImportNumDayDailyWorker;
