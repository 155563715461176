import { COMPONENT_TYPE, ERROR_MESSAGE } from "..";

const DEFINE = {
    company: {
        label: "บริษัท",
        name: "company",
    },
    location: {
        label: "Location",
        name: "location",
    },
    depNo: {
        label: "แผนก",
        name: "depNo",
    },
    subDepNo: {
        label: "แผนกย่อย",
        name: "subDepNo",
    },
    position: {
        label: "ตำแหน่งงาน",
        label_2: "รหัสตำแหน่งงาน",
        name: "positionCode",
    },
    positionNameEN: {
        label: "ชื่อตำแหน่ง (ภาษาอังกฤษ)",
        name: "positionNameEN",
    },
    positionNameTH: {
        label: "ชื่อตำแหน่ง (ภาษาไทย)",
        name: "positionNameTH",
    },
}

export const ComponentsSearchPosition = ({
    options = {},
    isDisable = {},
    handleChange = () => null,
    onClick = () => null,
}) => {
    const { company, location, position, depNo, subDepNo } = DEFINE
    return [
        { // Row
            rowProps: { justify: "start", align: "bottom" },
            column: [ // column
                {
                    breakpoint: { xs: 24, sm: 24, md: 24, lg: 6, xl: 4 },
                    component: {
                        type: COMPONENT_TYPE.SELECT,
                        itemProps: {
                            name: company.name,
                            label: company.label,
                            rules: [{ required: true, message: ERROR_MESSAGE.SELECT(company.label) }],
                        },
                        componentProps: {
                            style: { width: "100%" },
                            placeholder: ERROR_MESSAGE.SELECT(company.label),
                            disabled: isDisable[company.name] || false,
                        },
                        fnChange: (value) => handleChange(value, company.name),
                        dropdown: options[company.name],
                    },
                },
                {
                    breakpoint: { xs: 24, sm: 24, md: 24, lg: 6, xl: 4 },
                    component: {
                        type: COMPONENT_TYPE.SELECT,
                        itemProps: {
                            name: location.name,
                            label: location.label,
                            rules: [{ required: false, message: ERROR_MESSAGE.SELECT(location.label) }],
                        },
                        componentProps: {
                            style: { width: "100%" },
                            placeholder: ERROR_MESSAGE.SELECT(location.label),
                            disabled: isDisable[location.name] || false,
                        },
                        fnChange: (value) => handleChange(value, location.name),
                        dropdown: options[location.name],
                    },
                },
                {
                    breakpoint: { xs: 24, sm: 24, md: 24, lg: 6, xl: 4 },
                    component: {
                        type: COMPONENT_TYPE.SELECT,
                        itemProps: {
                            name: depNo.name,
                            label: depNo.label,
                            rules: [{ required: false, message: ERROR_MESSAGE.SELECT(depNo.label) }],
                        },
                        componentProps: {
                            style: { width: "100%" },
                            placeholder: ERROR_MESSAGE.SELECT(depNo.label),
                            disabled: isDisable[depNo.name] || false,
                        },
                        fnChange: (value) => handleChange(value, depNo.name),
                        dropdown: options[depNo.name],
                    },
                },
                {
                    breakpoint: { xs: 24, sm: 24, md: 24, lg: 6, xl: 4 },
                    component: {
                        type: COMPONENT_TYPE.SELECT,
                        itemProps: {
                            name: subDepNo.name,
                            label: subDepNo.label,
                            rules: [{ required: false, message: ERROR_MESSAGE.SELECT(subDepNo.label) }],
                        },
                        componentProps: {
                            style: { width: "100%" },
                            placeholder: ERROR_MESSAGE.SELECT(subDepNo.label),
                            disabled: isDisable[subDepNo.name] || false,
                        },
                        fnChange: (value) => handleChange(value, subDepNo.name),
                        dropdown: options[subDepNo.name],
                    },
                },
                {
                    breakpoint: { xs: 24, sm: 24, md: 24, lg: 8, xl: 8 },
                    component: {
                        type: COMPONENT_TYPE.SELECT,
                        itemProps: {
                            name: position.name,
                            label: position.label,
                            rules: [{ required: false, message: ERROR_MESSAGE.SELECT(position.label) }],
                        },
                        componentProps: {
                            style: { width: "100%" },
                            placeholder: ERROR_MESSAGE.SELECT(position.label),
                            disabled: isDisable[position.name] || false,
                        },
                        fnChange: (value) => handleChange(value, position.name),
                        dropdown: options[position.name],
                    },
                },
                {
                    breakpoint: { xs: 24, sm: 12, md: 12, lg: 3, xl: 2 },
                    component: {
                        type: COMPONENT_TYPE.BUTTON,
                        componentProps: {
                            call: "search",
                            disabled: isDisable["submit"] || false,
                        },
                    },
                },
                {
                    breakpoint: { xs: 24, sm: 12, md: 12, lg: 3, xl: 2 },
                    component: {
                        type: COMPONENT_TYPE.BUTTON,
                        componentProps: {
                            call: "normal",
                            label: "ล้างข้อมูล",
                            onClick,
                        },
                    },
                },
            ], // column
        }, // Row
    ];
}

export const ComponentsInsertPosition = ({
    options = {},
    isDisable = {},
    onClick = () => null,
    handleChange = () => null,
}) => {
    const { company, location, depNo, subDepNo, position, positionNameEN, positionNameTH } = DEFINE
    return [
        { // Row
            rowProps: { justify: "start", align: "bottom" },
            column: [ // column
                {
                    breakpoint: { xs: 24, sm: 24, md: 12, lg: 8, xl: 4 },
                    component: {
                        type: COMPONENT_TYPE.SELECT,
                        itemProps: {
                            name: company.name,
                            label: company.label,
                            rules: [{ required: true, message: ERROR_MESSAGE.SELECT(company.label) }],
                        },
                        componentProps: {
                            style: { width: "100%" },
                            placeholder: ERROR_MESSAGE.SELECT(company.label),
                            disabled: isDisable[company.name] || false,
                        },
                        dropdown: options[company.name],
                        fnChange: (value) => handleChange(value, company.name),
                    },
                },
                {
                    breakpoint: { xs: 24, sm: 24, md: 12, lg: 8, xl: 4 },
                    component: {
                        type: COMPONENT_TYPE.SELECT,
                        itemProps: {
                            name: location.name,
                            label: location.label,
                            rules: [{ required: false, message: ERROR_MESSAGE.SELECT(location.label) }],
                        },
                        componentProps: {
                            style: { width: "100%" },
                            placeholder: ERROR_MESSAGE.SELECT(location.label),
                            disabled: isDisable[location.name] || false,
                        },
                        dropdown: options[location.name],
                        fnChange: (value) => handleChange(value, location.name),
                    },
                },
                {
                    breakpoint: { xs: 24, sm: 24, md: 12, lg: 8, xl: 4 },
                    component: {
                        type: COMPONENT_TYPE.SELECT,
                        itemProps: {
                            name: depNo.name,
                            label: depNo.label,
                            rules: [{ required: true, message: ERROR_MESSAGE.SELECT(depNo.label) }],
                        },
                        componentProps: {
                            style: { width: "100%" },
                            placeholder: ERROR_MESSAGE.SELECT(depNo.label),
                            disabled: isDisable[depNo.name] || false,
                        },
                        dropdown: options[depNo.name],
                        fnChange: (value) => handleChange(value, depNo.name),
                    },
                },
                {
                    breakpoint: { xs: 24, sm: 24, md: 12, lg: 8, xl: 4 },
                    component: {
                        type: COMPONENT_TYPE.SELECT,
                        itemProps: {
                            name: subDepNo.name,
                            label: subDepNo.label,
                            rules: [{ required: true, message: ERROR_MESSAGE.SELECT(subDepNo.label) }],
                        },
                        componentProps: {
                            style: { width: "100%" },
                            placeholder: ERROR_MESSAGE.SELECT(subDepNo.label),
                            disabled: isDisable[subDepNo.name] || false,
                        },
                        dropdown: options[subDepNo.name],
                        fnChange: (value) => handleChange(value, subDepNo.name),
                    },
                },
                {
                    breakpoint: { xs: 24, sm: 24, md: 12, lg: 8, xl: 4 },
                    component: {
                        type: COMPONENT_TYPE.INPUT,
                        itemProps: {
                            name: position.name,
                            label: position.label_2,
                            rules: [{ required: true, message: ERROR_MESSAGE.INPUT(position.label_2) }],
                        },
                        componentProps: {
                            style: { width: "100%" },
                            placeholder: ERROR_MESSAGE.INPUT(position.label_2),
                            disabled: isDisable[position.name] || false,
                        },
                        fnChange: (value) => handleChange(value, position.name),
                    },
                },
                {
                    breakpoint: { xs: 24, sm: 24, md: 12, lg: 12, xl: 6 },
                    component: {
                        type: COMPONENT_TYPE.INPUT,
                        itemProps: {
                            name: positionNameEN.name,
                            label: positionNameEN.label,
                            rules: [{ required: true, message: ERROR_MESSAGE.INPUT(positionNameEN.label) }],
                        },
                        componentProps: {
                            style: { width: "100%" },
                            placeholder: ERROR_MESSAGE.INPUT(positionNameEN.label),
                            disabled: isDisable[positionNameEN.name] || false,
                        },
                        fnChange: (value) => handleChange(value, positionNameEN.name),
                    },
                },
                {
                    breakpoint: { xs: 24, sm: 24, md: 12, lg: 12, xl: 6 },
                    component: {
                        type: COMPONENT_TYPE.INPUT,
                        itemProps: {
                            name: positionNameTH.name,
                            label: positionNameTH.label,
                            rules: [{ required: true, message: ERROR_MESSAGE.INPUT(positionNameTH.label) }],
                        },
                        componentProps: {
                            style: { width: "100%" },
                            placeholder: ERROR_MESSAGE.INPUT(positionNameTH.label),
                            disabled: isDisable[positionNameTH.name] || false,
                        },
                        fnChange: (value) => handleChange(value, positionNameTH.name),
                    },
                },
            ], // column
        }, // Row
        { // Row
            rowProps: { justify: "center", align: "bottom", style: { marginTop: 20 } },
            column: [ // column
                {
                    breakpoint: { xs: 24, sm: 24, md: 12, lg: 6, xl: 6 },
                    component: {
                        type: COMPONENT_TYPE.BUTTON,
                        componentProps: {
                            call: "save",
                            disabled: isDisable["submit"] || false,
                        },
                    },
                },
                {
                    breakpoint: { xs: 24, sm: 24, md: 12, lg: 6, xl: 6 },
                    component: {
                        type: COMPONENT_TYPE.BUTTON,
                        componentProps: {
                            call: "normal",
                            label: "ล้างข้อมูล",
                            onClick,
                        },
                    },
                },
            ], // column
        }, // Row
    ];
}

export const ComponentsUpdatePosition = ({
    options = {},
    isDisable = {},
    onClick = () => null,
}) => {
    const { company, position, positionNameEN, positionNameTH } = DEFINE
    return [
        { // Row
            rowProps: { justify: "start", align: "bottom" },
            column: [ // column
                {
                    breakpoint: { xs: 24, sm: 24, md: 12, lg: 8, xl: 4 },
                    component: {
                        type: COMPONENT_TYPE.SELECT,
                        itemProps: {
                            name: company.name,
                            label: company.label,
                            rules: [{ required: true, message: ERROR_MESSAGE.SELECT(company.label) }],
                        },
                        componentProps: {
                            style: { width: "100%" },
                            placeholder: ERROR_MESSAGE.SELECT(company.label),
                            disabled: true,
                        },
                        dropdown: options[company.name],
                    },
                },
                {
                    breakpoint: { xs: 24, sm: 24, md: 12, lg: 8, xl: 4 },
                    component: {
                        type: COMPONENT_TYPE.INPUT,
                        itemProps: {
                            name: position.name,
                            label: position.label_2,
                            rules: [{ required: true, message: ERROR_MESSAGE.INPUT(position.label_2) }],
                        },
                        componentProps: {
                            style: { width: "100%" },
                            placeholder: ERROR_MESSAGE.INPUT(position.label_2),
                            disabled: true,
                        },
                    },
                },
                {
                    breakpoint: { xs: 24, sm: 24, md: 12, lg: 12, xl: 6 },
                    component: {
                        type: COMPONENT_TYPE.INPUT,
                        itemProps: {
                            name: positionNameEN.name,
                            label: positionNameEN.label,
                            rules: [{ required: true, message: ERROR_MESSAGE.INPUT(positionNameEN.label) }],
                        },
                        componentProps: {
                            style: { width: "100%" },
                            placeholder: ERROR_MESSAGE.INPUT(positionNameEN.label),
                            disabled: isDisable[positionNameEN.name] || false,
                        },
                    },
                },
                {
                    breakpoint: { xs: 24, sm: 24, md: 12, lg: 12, xl: 6 },
                    component: {
                        type: COMPONENT_TYPE.INPUT,
                        itemProps: {
                            name: positionNameTH.name,
                            label: positionNameTH.label,
                            rules: [{ required: true, message: ERROR_MESSAGE.INPUT(positionNameTH.label) }],
                        },
                        componentProps: {
                            style: { width: "100%" },
                            placeholder: ERROR_MESSAGE.INPUT(positionNameTH.label),
                            disabled: isDisable[positionNameTH.name] || false,
                        },
                    },
                },
            ], // column
        }, // Row
        { // Row
            rowProps: { justify: "center", align: "bottom", style: { marginTop: 20 } },
            column: [ // column
                {
                    breakpoint: { xs: 24, sm: 24, md: 12, lg: 6, xl: 6 },
                    component: {
                        type: COMPONENT_TYPE.BUTTON,
                        componentProps: {
                            call: "save",
                            disabled: isDisable["submit"] || false,
                        },
                    },
                },
                {
                    breakpoint: { xs: 24, sm: 24, md: 12, lg: 6, xl: 6 },
                    component: {
                        type: COMPONENT_TYPE.BUTTON,
                        componentProps: {
                            call: "normal",
                            label: "ล้างข้อมูล",
                            onClick,
                        },
                    },
                },
            ], // column
        }, // Row
    ];
}