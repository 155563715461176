import React from 'react';

export const SR_COLORS = {
    //# status
    ERROR: '#e73040',
    SUCCESS: '#5CB85C',
    WARNING: '#F0AD4E',

    //# texts
    HEADER: '#474747',
    LABEL_INPUT: '#474747',

    //#other
    WHITE: '#FFFFFF',
    BLACK: '#000000',
    THEME: '#17a2b8',
    HOVER: '#cbcdce',
    DISABLED: '#f5f5f5'
}

export const SR_SIZES = {
    TEXT: 12,
    RADIUS: 8,
}

const STYLE_SVG = {
    style: { margin: '0px 0px 2px 0px' },
    width: '16',
    height: '16'
}

export const Svg = {
    download: (props) => (
        <svg xmlns="http://www.w3.org/2000/svg" {...STYLE_SVG} viewBox="0 0 24 24" >
            <path d="M0 0h24v24H0z" fill='none' />
            <path fill={props.color || 'none'}
                d="M19.35 10.04C18.67 6.59 15.64 4 12 4 9.11 4 6.6 5.64 5.35 8.04 2.34 8.36 0 10.91 0 14c0 3.31 2.69 6 6 6h13c2.76 0 5-2.24 5-5 0-2.64-2.05-4.78-4.65-4.96zM17 13l-5 5-5-5h3V9h4v4h3z" />
        </svg>),
    search: (props) => (
        <svg xmlns="http://www.w3.org/2000/svg" {...STYLE_SVG} viewBox="0 0 24 24" >
            <path d="M0 0h24v24H0z" fill="none" />
            <path fill={props.color || 'none'}
                d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z" />
        </svg>),
    cancel: (props) => (
        <svg xmlns="http://www.w3.org/2000/svg" {...STYLE_SVG} viewBox="0 0 24 24" >
            <path d="M0 0h24v24H0z" fill="none" />
            <path
                fill={props.color || 'none'}
                d="M14.59 8L12 10.59 9.41 8 8 9.41 10.59 12 8 14.59 9.41 16 12 13.41 14.59 16 16 14.59 13.41 12 16 9.41 14.59 8zM12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z" />
        </svg>),
    reset: (props) => (
        <svg xmlns="http://www.w3.org/2000/svg" {...STYLE_SVG} viewBox="0 0 24 24" >
            <path d="M0 0h24v24H0z" fill="none" />
            <path
                fill={props.color || 'none'}
                d="M12 5V1L7 6l5 5V7c3.31 0 6 2.69 6 6s-2.69 6-6 6-6-2.69-6-6H4c0 4.42 3.58 8 8 8s8-3.58 8-8-3.58-8-8-8z" />
        </svg>),

}


