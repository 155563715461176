import React, { Component } from "react";
import { Router, browserHistory } from "react-router";
import routes, { SUB_MENU } from "./routes";
import Cookies from "js-cookie";
import Login from "./views/login";
import { checkCookies } from "./functions/cookieValidator";
import { SetStringToObject } from "./constants/functions";
import ProviderState from "./moduleKPIs/services/provider/providerState";
import { POST } from "./service";
import { filterRoute } from "./screens/functions/UseFunction";
import HandleScene from "./screens/Authen/HandleScene";
// import home from "./views/home";
// import { PATH_ROUTE } from "./screens/constants/ConstantPath";
// import InsertNewSaleEmployee from "./screens/InsertNewSaleEmployee";
// import ImportIncentiveStrategy from "./screens/ImportIncentiveStrategy";
// import SaleCodeAreaAssignment from "./screens/SaleCodeAreaAssignment";
// import RecheckCommissionIncentiveDashboard from "./screens/RecheckCommissionIncentiveDashboard";
// import RecheckCommissionIncentiveAction from "./screens/RecheckCommissionIncentiveAction";
// import CutOffCommissionIncentive from "./screens/CutOffCommissionIncentive";
// import RecheckWorkingSaleEmployee from "./screens/RecheckWorkingSaleEmployee";
// import CutOffCommissionIncentiveDashboard from "./screens/CutOffCommissionIncentiveDashboard";
// import ImportMapFocusShopSaleCode from "./screens/ImportMapFocusShopSaleCode";
import DashboardImportQuitPersonal from "./modulePersonal/containersPersonal/dashboardImportQuitPersonal";
import Assets_ConfirmationLogin from "./views/Assets_ConfirmationLogin";

var encodeString = require("./extendFunction/encodeString");
export class MasterRoute extends Component {
  constructor(props) {
    super(props);
    this.state = {
      permission: "HAS",
      userRoute: [],
    };
  }

  componentDidMount() {
    const obj = SetStringToObject(Cookies.get("cookie_session"));
    if (obj) {
      let permission = encodeString.decode(obj.premission);
      let userId = encodeString.decode(obj.ID_User);
      let location = encodeString.decode(obj.location);
      this.getNavigator({ permission, userId, location });
    } else {
      this.setState({ permission: "" });
    }
  }

  getNavigator = async ({ userId = "", location = "", permission = "" }) => {
    const url = `/HRM_API/cent/getNavigator`;
    const res = await POST(url, { userId, location });
    const { result } = res;
    const userRoute = filterRoute({
      dataUser: result,
      objRoute: SUB_MENU,
    });
    userRoute.unshift({
      path: "dashboardImportQuitPersonal",
      component: DashboardImportQuitPersonal,
    });
    // userRoute.unshift({
    //   path: "account/handle",
    //   component: HandleScene,
    // });
    // userRoute.unshift({
    //   path: "/Login",
    //   component: Login,
    // });
    // 	{
    // 	path: PATH_ROUTE.Insert_NewSaleEmployee,
    // 	component: InsertNewSaleEmployee,
    // }, {
    // 	path: PATH_ROUTE.Import_IncentiveStrategy,
    // 	component: ImportIncentiveStrategy,
    // }, {
    // 	path: PATH_ROUTE.SaleCodeAreaAssignment,
    // 	component: SaleCodeAreaAssignment,
    // }, {
    // 	path: PATH_ROUTE.RecheckCommissionIncentiveDashboard,
    // 	component: RecheckCommissionIncentiveDashboard,
    // }, {
    // 	path: PATH_ROUTE.RecheckCommissionIncentiveAction,
    // 	component: RecheckCommissionIncentiveAction,
    // }, {
    // 	path: PATH_ROUTE.CutOffCommissionIncentive,
    // 	component: CutOffCommissionIncentive,
    // }, {
    // 	path: PATH_ROUTE.CutOffCommissionIncentiveDashboard,
    // 	component: CutOffCommissionIncentiveDashboard,
    // }
    // )
    this.setState({ permission, userRoute });
  };

  render() {
    const { userRoute, permission } = this.state;
    if (
      //  -!permission ||
      window.location.pathname.toLowerCase() == "/account/login" ||
      window.location.pathname.toLowerCase() == "/account/handle" ||
      window.location.pathname == "/Assets_ConfirmationLogin" 

    ) {
      return (
        <Router
          history={browserHistory}
          routes={[
            {
              path: "account/Login",
              component: Login,
            },
            {
              path: "account/handle",
              component: HandleScene,
            },
            {
              path: "Assets_ConfirmationLogin",
              component: Assets_ConfirmationLogin,
            },
          ]}
        />
        // <Router history={browserHistory}>
        // <HandleScene path="555" />
        // </Router>
      );
    } else if (permission === "HAS") {
      return null;
    }
    const cookiesSession = SetStringToObject(Cookies.get("cookie_session"));
    if (!cookiesSession) {
      checkCookies();
      return null;
    }
    return (
      <ProviderState>
        <Router history={browserHistory} routes={routes(userRoute)} />
      </ProviderState>
    );
  }
}

export default MasterRoute;
