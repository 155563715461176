import React from 'react'
import { Form, Space } from "antd";
import { useButton } from "../../../conponents/buttons/CustomButtonAntd";
import { filterSearchColumn } from "../../../functions/UseFunction";
import { SearchOutlined } from '@ant-design/icons';
import { useSelect } from '../../../conponents/select/SelectAntd';
import { useInputText } from '../../../conponents/inputs/InputAnt';

export const ColumnSearchReportCrime = [
    {
        title: "บริษัท",
        dataIndex: "company",
        key: "company",
        align: "left",
        width: 100,
        ...filterSearchColumn("company", "บริษัท"),
    },
    {
        title: "Location",
        dataIndex: "location",
        key: "location",
        align: "left",
        width: 100,
        ...filterSearchColumn("location", "Location"),
    },
    {
        title: "ชุดเอกสาร(ระบบ)",
        dataIndex: "docNo",
        key: "docNo",
        align: "left",
        width: 150,
        ...filterSearchColumn("docNo", "ชุดเอกสาร(ระบบ)"),
    },
    {
        title: "ชุดเอกสาร(เพิ่มเติม)",
        dataIndex: "externalDoc",
        key: "externalDoc",
        align: "left",
        width: 150,
        ...filterSearchColumn("externalDoc", "ชุดเอกสาร(เพิ่มเติม)"),
    },
    {
        title: "วันที่ยื่น",
        dataIndex: "prepareDate",
        key: "prepareDate",
        align: "right",
        width: 150,
        ...filterSearchColumn("prepareDate", "วันที่ยื่น"),
    },
    {
        title: "วันที่ส่งตรวจ",
        dataIndex: "sendDate",
        key: "sendDate",
        align: "right",
        width: 150,
        ...filterSearchColumn("sendDate", "วันที่ส่งตรวจ"),
    },
    {
        title: "วันที่รับผลตรวจ",
        dataIndex: "receivedDate",
        key: "receivedDate",
        align: "right",
        width: 150,
        ...filterSearchColumn("receivedDate", "วันที่รับผลตรวจ"),
    },
    {
        title: "จัดการข้อมูล",
        key: "action",
        align: "left",
        width: 300,
        fixed: "right",
        render: (val) => {
            return (
                <Space size="middle" wrap>
                    {!val.filePath ? (
                        useButton({
                            call: "edit_link",
                            linkBtn: `ManageCrime/update-crime/${val.docNo}`,
                        })
                    ) : (
                        <>
                            {useButton({
                                call: "link",
                                linkBtn: `ManageCrime/detail-crime/${val.docNo}`,
                                moreProps: {
                                    label: "รายละเอียด",
                                    type: "primary",
                                    target: "_blank",
                                    icon: <SearchOutlined />,
                                },
                            })}
                            {useButton({
                                call: "download",
                                linkBtn: val.filePath,
                                moreProps: { label: "ดาวน์โหลด" },
                            })}
                        </>
                    )}
                </Space>
            );
        },
    },
];

export const ColumnEditCrime = (option) => [
    {
        title: "รหัสพนักงาน",
        dataIndex: "userId",
        key: "userId",
        align: "left",
        width: 150,
        ...filterSearchColumn("userId", "รหัสพนักงาน"),
    },
    {
        title: "คำนำหน้าชื่อ",
        dataIndex: "title",
        key: "title",
        align: "left",
        width: 100,
        ...filterSearchColumn("title", "คำนำหน้าชื่อ"),
    },
    {
        title: "ชื่อพนักงาน",
        dataIndex: "Name",
        key: "Name",
        align: "left",
        width: 200,
        ...filterSearchColumn("Name", "ชื่อ - นามสกุล"),
    },
    {
        title: "วันเกิด",
        dataIndex: "birthday",
        key: "birthday",
        align: "left",
        width: 150,
    },
    {
        title: "ที่อยู่ปัจจุบัน",
        dataIndex: "presentAddress",
        key: "presentAddress",
        align: "left",
        width: 400,
        ...filterSearchColumn("presentAddress", "ที่อยู่ปัจจุบัน"),
    },
    {
        title: "บริษัท",
        dataIndex: "company",
        key: "company",
        align: "left",
        width: 100,
        ...filterSearchColumn("company", "บริษัท"),
    },
    {
        title: "แผนก",
        dataIndex: "depName",
        key: "depName",
        align: "left",
        width: 200,
        ...filterSearchColumn("depName", "แผนก"),
    },

    {
        title: "แผนกย่อย",
        dataIndex: "subDepName",
        key: "subDepName",
        align: "left",
        width: 300,
        ...filterSearchColumn("subDepName", "แผนกย่อย"),
    },
    {
        title: "วันที่ยื่น",
        dataIndex: "prepareDate",
        key: "prepareDate",
        align: "left",
        width: 150,
        ...filterSearchColumn("prepareDate", "วันที่ยื่น"),
    },
    {
        title: "ผู้สรรหา",
        dataIndex: "recruiterNickname",
        key: "recruiterNickname",
        align: "left",
        width: 150,
        ...filterSearchColumn("recruiterNickname", "ผู้สรรหา"),
    },
    {
        title: "ผลตรวจอาชญากรรม",
        key: "action",
        align: "left",
        fixed: "right",
        width: 250,
        render: (record) => {
            return (
                <Form.Item
                    name={`result_${record.userId}`}
                    initialValue={"pass"}
                    rules={[{ required: false, message: "กรุณาเลือก ผลตรวจ" }]}
                >
                    {useSelect({
                        componentProps: {
                            style: { width: "100%" },
                            placeholder: "เลือก ผลตรวจ",

                        },
                        fnChange: (value) => console.log(`result_${record.userId}`, value),
                        dropdown: option,
                    })}
                </Form.Item>
            );
        },
    },
    {
        title: "เพิ่มหมายเหตุ",
        key: "description",
        align: "left",
        fixed: "right",
        width: 250,
        render: (record) => {
            return (
                <Form.Item
                    name={`remark_${record.userId}`}
                    rules={[{ required: false, message: "กรุณากรอก หมายเหตุ" }]}
                >
                    {useInputText({
                        componentProps: {
                            style: { width: "100%" },
                            placeholder: "เพิ่มหมายเหตุ (ถ้ามี)",
                        },
                        fnChange: (event) => console.log(`remark_${record.userId}`, event.target.value),
                    })}
                </Form.Item>
            );
        },
    },
];

export const ColumnShowDetailCrime = [
    {
        title: "รหัสพนักงาน",
        dataIndex: "userId",
        key: "userId",
        align: "left",
        fixed: "left",
        width: 100,
        ...filterSearchColumn("userId", "รหัสพนักงาน"),
    },
    {
        title: "คำนำหน้าชื่อ",
        dataIndex: "title",
        key: "title",
        align: "left",
        fixed: "left",
        width: 100,
        ...filterSearchColumn("title", "คำนำหน้าชื่อ"),
    },
    {
        title: "ชื่อพนักงาน",
        dataIndex: "Name",
        key: "Name",
        align: "left",
        fixed: "left",
        width: 200,
        ...filterSearchColumn("Name", "ชื่อ - นามสกุล"),
    },
    {
        title: "เลขบัตรประชาชน",
        dataIndex: "Personal_id",
        key: "Personal_id",
        align: "left",
        fixed: "left",
        width: 200,
        ...filterSearchColumn("Personal_id", "เลขบัตรประชาชน"),
    },
    {
        title: "วันเดือนปีเกิด",
        dataIndex: "birthday",
        key: "birthday",
        align: "left",
        width: 150,
    },
    {
        title: "ชื่อบิดา-มารดา",
        dataIndex: "parentNames",
        key: "parentNames",
        align: "left",
        width: 200,
        ...filterSearchColumn("parentNames", "ชื่อบิดา-มารดา"),
    },
    {
        title: "ที่อยู่ปัจจุบัน",
        dataIndex: "presentAddress",
        key: "presentAddress",
        align: "left",
        width: 400,
        ...filterSearchColumn("presentAddress", "ที่อยู่ปัจจุบัน"),
    },
    {
        title: "ผลตรวจอาชญากรรม",
        dataIndex: "crimeResult",
        key: "crimeResult",
        align: "left",
        width: 250,
        ...filterSearchColumn("crimeResult", "ผลตรวจอาชญากรรม"),
    },
    {
        title: "วันที่ยื่นเอกสาร",
        dataIndex: "prepareDate",
        key: "prepareDate",
        align: "left",
        width: 150,
    },
    {
        title: "บริษัท",
        dataIndex: "company",
        key: "company",
        align: "left",
        width: 100,
        ...filterSearchColumn("company", "บริษัท"),
    },
    {
        title: "แผนก",
        dataIndex: "depName",
        key: "depName",
        align: "left",
        width: 200,
        ...filterSearchColumn("depName", "แผนก"),
    },
    {
        title: "แผนกย่อย",
        dataIndex: "subDepName",
        key: "subDepName",
        align: "left",
        width: 300,
        ...filterSearchColumn("subDepName", "แผนกย่อย"),
    },
    {
        title: "ผู้สรรหา",
        dataIndex: "recruiterNickname",
        key: "recruiterNickname",
        align: "left",
        width: 150,
        ...filterSearchColumn("recruiterNickname", "ผู้สรรหา"),
    },
];

