import { API_URL } from "../components/connectAPI";
import { CONFIG } from "../components/connectAPI";
import { responseAPI } from "../moduleKPIs/common/functions/funcResponse";
import servicesAPI from "../moduleKPIs/services/servicesAPI";
import { convertResponse } from "../service/functions/convertResponse";

let API = servicesAPI.create()
// const postAPI = (data) => ({
//     method: 'POST',
//     headers: {
//         //'content-type': 'application/x-www-form-urlencoded',
//         'Content-Type': 'application/json',
//         Accept: 'application/json'
//     },
//     body: JSON.stringify({ data })

// })
export async function APIDashboardProbation(payload, nextAction) {
	try {
		const response = await API.selectDashboardContract(payload);
		convertResponse({
			...response,
			result: response.data.result
		}, nextAction)
	} catch (error) {
		console.error('error : ', error)
	}
}

export async function APIDashboardProbationNew(payload, nextAction) {
	try {
		const response = await API.selectDashboardContractNew(payload);
		convertResponse({
			...response,
			result: response.data.result
		}, nextAction)
	} catch (error) {
		console.error('error : ', error)
	}
}
export async function APIDashboardBenefit(payload, nextAction) {
	try {
		const response = await API.selectDashboardBenefit(payload);
		convertResponse({
			...response,
			result: response.data.data
		}, nextAction)
	} catch (error) {
		console.error('error : ', error)
	}
}

// export const APIDashboardProbation = async payload => {
//   let URL = `${API_URL
//     }${"/HRM_API/select_dataDashboardProbation?"}${payload}`;
//   console.log('[API] APIDashboardProbation :: ', { payload }, URL)
//   try {
//     return await fetch(URL)
//       .then(res => res.json())
//       .then(results => {
//         if (results !== 0) {
//           return Promise.resolve(results);
//         } else {
//           console.warn("Not found");
//           return Promise.reject(results);
//         }
//       });
//   } catch (error) {
//     return Promise.reject(error);
//   }
// };

export const APIBenefit = async payload => {
	let URL = `${API_URL
		}${"/HRM_API/select_dataDashboardBenenfits?"}${payload}`;
	//console.log("[API] APIBenefit :: ", URL);
	try {
		return await fetch(URL)
			.then(res => res.json())
			.then(results => {
				if (results !== 0) {
					return Promise.resolve(results);
				} else {
					console.warn("Not found");
					return Promise.reject(results);
				}
			});
	} catch (error) {
		return Promise.reject(error);
	}
};


export const APIBenefitOut = async payload => {
	let URL = `${API_URL
		}${"/HRM_API/select_dataDashboardBenenfitsOut?"}${payload}`;
	//console.log("[API] APIBenefit :: ", URL);
	try {
		return await fetch(URL)
			.then(res => res.json())
			.then(results => {
				if (results !== 0) {
					return Promise.resolve(results);
				} else {
					console.warn("Not found");
					return Promise.reject(results);
				}
			});
	} catch (error) {
		return Promise.reject(error);
	}
};

export const APIProbation = async payload => {
	//console.log('object APIProbation', payload)
	let URL = `${API_URL
		}${"/HRM_API/prob/selectDataProbation"}`;
	//console.log('[API] APIProbation :: ', URL)
	try {
		return await fetch(URL, {
			method: "POST",
			headers: {
				//'content-type': 'application/x-www-form-urlencoded',
				"Content-Type": "application/json",
				Accept: "application/json"
			},
			body: JSON.stringify(payload)
		})
			.then(res => res.json())
			.then(results => {
				if (results !== 0) {
					return Promise.resolve(results);
				} else {
					console.warn("Not found");
					return Promise.reject(results);
				}
			});
	} catch (error) {
		return Promise.reject(error);
	}
};

export const APIProbationMaster = async payload => {
	let URL = `${API_URL
		}${"/HRM_API/prob/selectDashboardProbationMaster"}`;
	//('[API] APIProbation :: ', URL)
	try {
		return await fetch(URL, {
			method: "POST",
			headers: {
				//'content-type': 'application/x-www-form-urlencoded',
				"Content-Type": "application/json",
				Accept: "application/json"
			},
			body: JSON.stringify(payload)
		})
			.then(res => res.json())
			.then(results => {
				const { result } = results
				if (Array.isArray(result)) {
					return Promise.resolve(result);
				} else {
					console.warn("Not found");
					return Promise.reject(result);
				}
			});
	} catch (error) {
		return Promise.reject(error);
	}
};

export const APIInsertProbation = async (payload, data) => {
	let URL = `${API_URL}${"/HRM_API/prob/insertProbation?"}${payload}`;
	// console.log('[API] APIInsertProbation :: ', { URL, data })
	try {
		return await fetch(URL, {
			method: "POST",
			headers: {
				//'content-type': 'application/x-www-form-urlencoded',
				"Content-Type": "application/json",
				Accept: "application/json"
			},
			body: JSON.stringify(data)
		})
			.then(res => res.json())
			.then(results => {
				const { status_api } = results;
				if (status_api !== 0) {
					return Promise.resolve(results);
				} else {
					return Promise.reject(results);
				}
			});
	} catch (error) {
		return Promise.reject(error);
	}
};

export const APIUpdateProbation = async (payload, data) => {
	let URL = `${API_URL}${"/HRM_API/prob/updateProbation?"}${payload}`;
	// console.log('[API] APIUpdateProbation :: ', { URL, data })
	try {
		return await fetch(URL, {
			method: "POST",
			headers: {
				//'content-type': 'application/x-www-form-urlencoded',
				"Content-Type": "application/json",
				Accept: "application/json"
			},
			body: JSON.stringify(data)
		})
			.then(res => res.json())
			.then(results => {
				// console.log('results update', results)
				const { status_api } = results;
				if (status_api !== 0) {
					return Promise.resolve(results);
				} else {
					return Promise.reject(results);
				}
			});
	} catch (error) {
		return Promise.reject(error);
	}
};

export const APIConfirmProbation = async (payload, data) => {
	let URL = `${API_URL
		}${"/HRM_API/update_ReceivedProbation?"}${payload}`;
	// console.log('[API] APIConfirmProbation :: ', { URL, data })
	try {
		return await fetch(URL, {
			method: "POST",
			headers: {
				//'content-type': 'application/x-www-form-urlencoded',
				"Content-Type": "application/json",
				Accept: "application/json"
			},
			body: JSON.stringify(data)
		})
			.then(res => res.json())
			.then(results => {
				// console.log('results update', results)
				const { status_api } = results;
				if (status_api !== 0) {
					return Promise.resolve(results);
				} else {
					return Promise.reject(results);
				}
			});
	} catch (error) {
		return Promise.reject(error);
	}
};


export const APIInsertProbationHistory = async (data) => {
	//console.log('data', data)
	let URL = `${API_URL
		}${"/HRM_API/empl/insertProbationHistory"}`;
	//console.log('[API] insertProbationHistory :: ', URL)
	try {
		return await fetch(URL, {
			method: "POST",
			headers: {
				//'content-type': 'application/x-www-form-urlencoded',
				"Content-Type": "application/json",
				Accept: "application/json"
			},
			body: JSON.stringify(data)
		})
			.then(res => res.json())
			.then(results => {
				const { status_api } = results;
				if (status_api !== 0) {
					return Promise.resolve(results);
				} else {
					return Promise.reject(results);
				}
			});
	} catch (error) {
		return Promise.reject(error);
	}
};
// APIUpdateProbation = async (payload) => {
//   console.log('APIInsertProbation ::', payload)
//   try {
//     return await fetch(`${URLUpdateProbation}`, {
//       method: 'POST',
//       headers: {
//         //'content-type': 'application/x-www-form-urlencoded',
//         'Content-Type': 'application/json',
//         Accept: 'application/json'
//       },
//       body: JSON.stringify({ data: payload })
//     }).then((res) => res.json()).then((results) => {
//       console.log('results update', results);
//     })
//     // return await fetch(`${URLInsertProbation}`, { method: 'POST' }).then((res) => res.json()).then((results) => {
//     //   if (results !== 0) {
//     //     return (Promise.resolve(results))
//     //   }
//     // })
//   } catch (error) {
//     return (Promise.reject(error))
//   }
// }
