import React, { useState } from "react";
import { Input, Button, Space, Row, Col } from "antd";
import "antd/dist/antd.css";
import Highlighter from "react-highlight-words";
import {
	SearchOutlined,
	CloseCircleOutlined,
	CheckCircleOutlined,
} from "@ant-design/icons";
import { useButton } from "../conponents/buttons/CustomButtonAntd";
import { SelectAntd } from "../conponents/select/SelectAntd";
import { useConfirm } from "../conponents/modal/CustomConfirm";
import { InputAnt } from "../conponents/inputs/InputAnt";
import { useText } from "../conponents/typography/Typography";
import moment from "moment";

export const useColumnTable = ({ Config }) => {
	// ***********  [Start] For Handle Form Search Antd  ***********  //
	const [searchText, setSearchText] = useState(""); // Search in Table
	const [searchedColumn, setSearchedColumn] = useState(""); // Search in Table
	let searchInput = "";

	const getColumnSearchProps = (dataIndex, placeholder) => ({
		filterDropdown: ({
			setSelectedKeys,
			selectedKeys,
			confirm,
			clearFilters,
		}) => (
			<div style={{ padding: 8 }}>
				<Input
					ref={(node) => {
						searchInput = node;
					}}
					placeholder={`ค้นหา ${placeholder}`}
					value={selectedKeys[0]}
					onChange={(e) =>
						setSelectedKeys(e.target.value ? [e.target.value] : [])
					}
					onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
					style={{ marginBottom: 8, display: "block" }}
				/>
				<Space>
					<Button
						type="primary"
						onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
						icon={<SearchOutlined />}
						size="small"
						style={{ width: 90 }}
					>
						ค้นหา
					</Button>
					<Button
						onClick={() => handleReset(clearFilters)}
						size="small"
						style={{ width: 90 }}
					>
						ล้าง
					</Button>
				</Space>
			</div>
		),
		filterIcon: (filtered) => (
			<SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
		),
		onFilter: (value, record) =>
			record[dataIndex]
				? record[dataIndex]
					.toString()
					.toLowerCase()
					.includes(value.toLowerCase())
				: "",
		onFilterDropdownVisibleChange: (visible) => {
			if (visible) {
				setTimeout(() => searchInput, 100);
			}
		},
		render: (text) => {
			const setString = text ? text.toString() : "";
			return searchedColumn === dataIndex ? (
				<Highlighter
					highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
					searchWords={[searchText]}
					autoEscape
					textToHighlight={setString}
				/>
			) : (
				text
			);
		},
	});

	const handleSearch = (selectedKeys, fnSearch, dataIndex) => {
		fnSearch();
		setSearchText(selectedKeys[0]);
		setSearchedColumn(dataIndex);
	};

	const handleReset = (clearFilters) => {
		clearFilters();
		setSearchText("");
	};
	// ***********  [END] For Handle Form Search Antd  ***********  //

	// ! ###################### SET Column ###################### ! //

	const ColumnPosition = [
		{
			title: "บริษัท",
			dataIndex: "company",
			key: "company",
			align: "left",
			width: 100,
			...getColumnSearchProps("company", "บริษัท"),
		},
		{
			title: "Location",
			dataIndex: "location",
			key: "location",
			align: "left",
			width: 100,
			...getColumnSearchProps("location", "Location"),
		},
		{
			title: "รหัสตำแหน่ง",
			dataIndex: "positionCode",
			key: "positionCode",
			align: "left",
			width: 200,
			...getColumnSearchProps("positionCode", "รหัสตำแหน่ง"),
		},
		{
			title: "ชื่อตำแหน่ง (อังกฤษ)",
			dataIndex: "positionNameEN",
			key: "positionNameEN",
			align: "left",
			width: 200,
			...getColumnSearchProps("positionNameEN", "ชื่อตำแหน่ง (อังกฤษ)"),
		},
		{
			title: "ชื่อตำแหน่ง (ไทย)",
			dataIndex: "positionNameTH",
			key: "positionNameTH",
			align: "left",
			width: 200,
			...getColumnSearchProps("positionNameTH", "ชื่อตำแหน่ง (ไทย)"),
		},
		{
			title: "จำนวนคน",
			dataIndex: "userCount",
			key: "userCount",
			align: "right",
			width: 100,
			sorter: (a, b) => a.userCount - b.userCount,
		},
		{
			title: "จัดการข้อมูล",
			key: "action",
			align: "left",
			fixed: "right",
			width: 250,
			render: (val) => {
				let btnChange = val.statusActive ? `inActive` : `active`;
				const { openModal, fnDelete } = Config
				return (
					<Space size="middle" wrap>
						{val.userCount !== 0 ? (
							<>
								{useButton({
									call: "edit_link",
									linkBtn: `ManagePosition/update-position/${val.positionCode}`,
								})}
								{useButton({
									call: "view",
									fn: () => openModal(val),
								})}
							</>
						) : (
							<>
								{useButton({
									call: "edit_link",
									linkBtn: `ManagePosition/update-position/${val.positionCode}`,
								})}
								{useButton({
									call: btnChange,
									fn: () => fnDelete(val),
								})}
							</>
						)}
					</Space>
				);
			},
		},
	];

	const ColumnCountEmployees = [
		{
			title: "รหัสพนักงาน",
			dataIndex: "ID_User",
			key: "ID_User",
			width: 100,
			align: "left",
			...getColumnSearchProps("ID_User", "รหัสพนักงาน"),
		},
		{
			title: "ชื่อ - นามสกุล",
			dataIndex: "Name",
			key: "Name",
			width: 300,
			align: "left",
			...getColumnSearchProps("Name", "ชื่อ - นามสกุล"),
		},
		{
			title: "ชื่อเล่น",
			dataIndex: "NickName",
			key: "NickName",
			width: 100,
			align: "left",
			...getColumnSearchProps("NickName", "ชื่อเล่น"),
		},
	];

	const ColumnSubDepartment = [
		{
			title: "รหัสแผนกย่อย",
			dataIndex: "subDepNo",
			key: "subDepNo",
			align: "left",
			width: 200,
			...getColumnSearchProps("subDepNo", "รหัสแผนกย่อย"),
		},
		{
			title: "ชื่อแผนกย่อย",
			dataIndex: "subDepName",
			key: "subDepName",
			align: "left",
			width: 300,
			...getColumnSearchProps("subDepName", "ชื่อแผนกย่อย"),
		},
		{
			title: "location",
			dataIndex: "location",
			key: "location",
			align: "left",
			width: 100,
			...getColumnSearchProps("location", "location"),
		},
		{
			title: "จำนวนคน",
			dataIndex: "userCount",
			key: "userCount",
			align: "right",
			width: 100,
			sorter: (a, b) => a.userCount - b.userCount,
		},
		{
			title: "จัดการข้อมูล",
			key: "action",
			align: "left",
			width: 250,
			fixed: "right",
			render: (val) => {
				let btnChange = val.statusActive ? `inActive` : `active`;
				const { openModal, fnDelete } = Config
				return (
					<Space size="middle" wrap>
						{val.userCount !== 0 ? (
							<>
								{useButton({
									call: "edit_link",
									linkBtn: `ManageSubDepartment/update-sub-department/${val.subDepNo}`,
								})}
								{useButton({
									call: "view",
									fn: () => openModal(val),
								})}
							</>
						) : (
							<>
								{useButton({
									call: "edit_link",
									linkBtn: `ManageSubDepartment/update-sub-department/${val.subDepNo}`,
								})}
								{useButton({
									call: btnChange,
									fn: () => fnDelete(val),
								})}
							</>
						)}
					</Space>
				);
			},
		},
	];

	const Column_Approval = [
		{
			title: "รหัสสายอนุมัติ",
			dataIndex: "flowId",
			key: "flowId",
			align: "left",
			width: 150,
			...getColumnSearchProps("flowId", "รหัสสายอนุมัติ"),
		},
		{
			title: "ชื่อสายอนุมัติ",
			dataIndex: "flowName",
			key: "flowName",
			align: "left",
			width: 400,
			...getColumnSearchProps("flowName", "ชื่อสายอนุมัติ"),
		},
		{
			title: "จำนวนคน",
			dataIndex: "userCount",
			key: "userCount",
			align: "right",
			width: 100,
			sorter: (a, b) => a.userCount - b.userCount,
		},
		{
			title: "จัดการข้อมูล",
			key: "action",
			align: "left",
			width: 150,
			fixed: "right",
			render: (val) => {
				let btnChange = val.statusActive ? `inActive` : `active`;
				const { openModal, fnDelete } = Config
				return (
					<Space size="middle" wrap>
						{val.userCount !== 0 ? (
							<>
								{useButton({
									call: "view",
									fn: () => openModal(val),
								})}
							</>
						) : (
							<>
								{useButton({
									call: "edit_link",
									linkBtn: `ManageBranchApprove/update-branch-approve/${val.flowId}`,
								})}
								{useButton({
									call: btnChange,
									fn: () => fnDelete(val),
								})}
							</>
						)}
					</Space>
				);
			},
		},
	];

	const ColumnBranchView = [
		{
			title: "รหัสสายการดูข้อมูล",
			dataIndex: "flowId",
			key: "flowId",
			align: "left",
			width: 150,
			...getColumnSearchProps("flowId", "รหัสสายการดูข้อมูล"),
		},
		{
			title: "ชื่อสายการดูข้อมูล",
			dataIndex: "description",
			key: "description",
			align: "left",
			width: 400,
			...getColumnSearchProps("description", "ชื่อสายการดูข้อมูล"),
		},
		{
			title: "จำนวนคน",
			dataIndex: "userCount",
			key: "userCount",
			align: "right",
			width: 100,
			sorter: (a, b) => a.userCount - b.userCount,
		},
		{
			title: "จัดการข้อมูล",
			key: "action",
			align: "left",
			width: 150,
			fixed: "right",
			render: (val) => {
				let btnChange = val.statusActive ? `inActive` : `active`;
				const { openModal, fnDelete } = Config
				return (
					<Space size="middle" wrap>
						{val.userCount !== 0 ? (
							<>
								{useButton({
									call: "view",
									fn: () => openModal(val),
								})}
							</>
						) : (
							<>
								{useButton({
									call: "edit_link",
									linkBtn: `ManageBranchView/update-branch-view/${val.flowId}`,
								})}
								{useButton({
									call: btnChange,
									fn: () => fnDelete(val),
								})}
							</>
						)}
					</Space>
				);
			},
		},
	];

	const Column_CustomizeRole = [
		{
			title: "รหัสเมนู",
			dataIndex: "pageNo",
			key: "pageNo",
			//width: "20%",
			align: "left",
			...getColumnSearchProps("pageNo", "รหัสเมนู"),
		},
		{
			title: "ประเภทเมนู",
			dataIndex: "pageType",
			key: "pageType",
			//width: "25%",
			align: "left",
			...getColumnSearchProps("pageType", "ประเภทเมนู"),
		},
		{
			title: "ชื่อเมนู",
			dataIndex: "pageName",
			key: "pageName",
			//width: "10%",
			align: "left",
			...getColumnSearchProps("pageName", "ชื่อเมนู"),
		},
	];

	const ColumnSSO = [
		{
			title: "รหัสพนักงาน",
			dataIndex: "userId",
			key: "userId",
			align: "left",
			...getColumnSearchProps("positionCode", "รหัสพนักงาน"),
		},
		{
			title: "ชื่อสถานประกอบการ",
			dataIndex: "companyCode",
			key: "companyCode",
			align: "left",
			...getColumnSearchProps("companyCode", "ชื่อสถานประกอบการ"),
		},
		{
			title: "ชื่อ-นามสกุล",
			dataIndex: "fullName",
			key: "fullName",
			align: "left",
			...getColumnSearchProps("fullName", "ชื่อ-นามสกุล"),
		},
		{
			title: "วันที่เริ่มงาน",
			dataIndex: "startDateJob",
			key: "startDateJob",
			align: "right",
			...getColumnSearchProps("startDateJob", "วันที่เริ่มงาน"),
			render: (text) => text && text.substring(0, 10),
		},
		{
			title: "วันเดือนปีเกิด",
			dataIndex: "dateOfBirth",
			key: "dateOfBirth",
			align: "right",
			...getColumnSearchProps("dateOfBirth", "วันเดือนปีเกิด"),
			render: (text) => text && text.substring(0, 10),
		},
		{
			title: "รหัสบัตรประชาชน 13 หลัก",
			dataIndex: "personalId",
			key: "personalId",
			align: "right",
			...getColumnSearchProps("personalId", "รหัสบัตรประชาชน 13 หลัก"),
		},
		{
			title: "เคยมีบัตรประกันสังคม",
			dataIndex: "alreadyHasSSODes",
			key: "alreadyHasSSODes",
			align: "left",
			...getColumnSearchProps("alreadyHasSSODes", "เคยมีบัตรประกันสังคม"),
		},
		{
			title: "โรงพยาบาลลำดับที่ 1",
			dataIndex: "hospital1",
			key: "hospital1",
			align: "left",
			...getColumnSearchProps("hospital1", "โรงพยาบาลลำดับที่ 1"),
		},
		{
			title: "โรงพยาบาลลำดับที่ 2",
			dataIndex: "hospital2",
			key: "hospital2",
			align: "left",
			...getColumnSearchProps("hospital2", "โรงพยาบาลลำดับที่ 2"),
		},
		{
			title: "โรงพยาบาลลำดับที่ 3",
			dataIndex: "hospital3",
			key: "hospital3",
			align: "left",
			...getColumnSearchProps("hospital3", "โรงพยาบาลลำดับที่ 3"),
		},
	];

	const ColumnDashboardSSO_NewEmp = [
		{
			title: "รหัสพนักงาน",
			dataIndex: "userId",
			key: "userId",
			align: "left",
			fixed: "left",
			...getColumnSearchProps("userId", "รหัสพนักงาน"),
		},
		{
			title: "ชื่อพนักงาน",
			dataIndex: "fullname",
			key: "fullname",
			align: "left",
			fixed: "left",
			...getColumnSearchProps("fullname", "ชื่อพนักงาน"),
		},
		{
			title: "ชื่อเล่น",
			dataIndex: "nickname",
			key: "nickname",
			align: "left",
			fixed: "left",
			...getColumnSearchProps("nickname", "ชื่อเล่น"),
		},
		{
			title: "สถานะยื่นประกันสังคม",
			dataIndex: "statusDes",
			key: "statusDes",
			align: "left",
			...getColumnSearchProps("statusDes", "สถานะยื่นประกันสังคม"),
		},
		{
			title: "วันที่ยื่น",
			dataIndex: "dataStamp",
			key: "dataStamp",
			align: "right",
			...getColumnSearchProps("dataStamp", "Data Stamp"),
		},
		{
			title: "ชุดเอกสาร",
			dataIndex: "docRef",
			key: "docRef",
			align: "left",
			...getColumnSearchProps("docRef", "ชุดเอกสาร"),
		},
		{
			title: "เคยมีบัตรประกันสังคม",
			dataIndex: "hasSSO",
			key: "hasSSO",
			align: "left",
			...getColumnSearchProps("hasSSO", "เคยมีบัตรประกันสังคม"),
		},
		{
			title: "กรอกข้อมูลเลือกโรงพยาบาล",
			dataIndex: "fillDataDes",
			key: "fillDataDes",
			align: "left",
			...getColumnSearchProps("fillDataDes", "กรอกข้อมูลเลือกโรงพยาบาล"),
		},
		{
			title: "บริษัท",
			dataIndex: "companyCode",
			key: "companyCode",
			align: "left",
			...getColumnSearchProps("companyCode", "บริษัท"),
		},
		{
			title: "สถานที่",
			dataIndex: "location",
			key: "location",
			align: "left",
			...getColumnSearchProps("location", "สถานที่"),
		},
		{
			title: "แผนก",
			dataIndex: "depNo",
			key: "depNo",
			align: "left",
			...getColumnSearchProps("depNo", "แผนก"),
		},
		{
			title: "ชื่อแผนก",
			dataIndex: "depName",
			key: "depName",
			align: "left",
			...getColumnSearchProps("depName", "ชื่อแผนก"),
		},
		{
			title: "รหัสแผนกย่อย",
			dataIndex: "subDepNo",
			key: "subDepNo",
			align: "left",
			...getColumnSearchProps("subDepNo", "รหัสแผนกย่อย"),
		},
		{
			title: "แผนกย่อย",
			dataIndex: "subDepName",
			key: "subDepName",
			align: "left",
			...getColumnSearchProps("subDepName", "แผนกย่อย"),
		},

		{
			title: "เลขประจำตัวประชาชน",
			dataIndex: "personal_id",
			key: "personal_id",
			align: "right",
			...getColumnSearchProps("personal_id", "เลขประจำตัวประชาชน"),
		},
		{
			title: "วันที่เริ่มงาน",
			dataIndex: "startDateJob",
			key: "startDateJob",
			align: "right",
			...getColumnSearchProps("startDateJob", "วันที่เริ่มงาน"),
		},
		{
			title: "โรงพยาบาลลำดับที่ 1",
			dataIndex: "hospitalNameSq1",
			key: "hospitalNameSq1",
			align: "left",
			...getColumnSearchProps("hospitalNameSq1", "โรงพยาบาลลำดับที่ 1"),
		},
		{
			title: "โรงพยาบาลลำดับที่ 2",
			dataIndex: "hospitalNameSq2",
			key: "hospitalNameSq2",
			align: "left",
			...getColumnSearchProps("hospitalNameSq2", "โรงพยาบาลลำดับที่ 2"),
		},
		{
			title: "โรงพยาบาลลำดับที่ 3",
			dataIndex: "hospitalNameSq3",
			key: "hospitalNameSq3",
			align: "left",
			...getColumnSearchProps("hospitalNameSq3", "โรงพยาบาลลำดับที่ 3"),
		},
	];

	const ColumnDashboardSSO_outEmp = [
		{
			title: "รหัสพนักงาน",
			dataIndex: "userId",
			key: "userId",
			align: "left",
			fixed: "left",
			...getColumnSearchProps("userId", "รหัสพนักงาน"),
		},
		{
			title: "ชื่อพนักงาน",
			dataIndex: "fullname",
			key: "fullname",
			align: "left",
			fixed: "left",
			...getColumnSearchProps("fullname", "ชื่อพนักงาน"),
		},
		{
			title: "ชื่อเล่น",
			dataIndex: "nickname",
			key: "nickname",
			align: "left",
			fixed: "left",
			...getColumnSearchProps("nickname", "ชื่อเล่น"),
		},
		{
			title: "สถานะยื่นประกันสังคม",
			dataIndex: "statusDes",
			key: "statusDes",
			align: "left",
			...getColumnSearchProps("statusDes", "สถานะยื่นประกันสังคม"),
		},
		{
			title: "วันที่ยื่น",
			dataIndex: "dataStamp",
			key: "dataStamp",
			align: "right",
			...getColumnSearchProps("dataStamp", "วันที่ยื่น"),
		},
		{
			title: "ชุดเอกสาร",
			dataIndex: "docRef",
			key: "docRef",
			align: "left",
			...getColumnSearchProps("docRef", "ชุดเอกสาร"),
		},
		{
			title: "บริษัท",
			dataIndex: "companyCode",
			key: "companyCode",
			align: "left",
			...getColumnSearchProps("companyCode", "บริษัท"),
		},
		{
			title: "สถานที่",
			dataIndex: "location",
			key: "location",
			align: "left",
			...getColumnSearchProps("location", "สถานที่"),
		},
		{
			title: "แผนก",
			dataIndex: "depNo",
			key: "depNo",
			align: "left",
			...getColumnSearchProps("depNo", "แผนก"),
		},
		{
			title: "ชื่อแผนก",
			dataIndex: "depName",
			key: "depName",
			align: "left",
			...getColumnSearchProps("depName", "ชื่อแผนก"),
		},
		{
			title: "รหัสแผนกย่อย",
			dataIndex: "subDepNo",
			key: "subDepNo",
			align: "left",
			...getColumnSearchProps("subDepNo", "รหัสแผนกย่อย"),
		},
		{
			title: "แผนกย่อย",
			dataIndex: "subDepName",
			key: "subDepName",
			align: "left",
			...getColumnSearchProps("subDepName", "แผนกย่อย"),
		},
		{
			title: "เลขประจำตัวประชาชน",
			dataIndex: "personal_id",
			key: "personal_id",
			align: "right",
			...getColumnSearchProps("personal_id", "เลขประจำตัวประชาชน"),
		},
		{
			title: "วันสิ้นสุดการทำงาน",
			dataIndex: "endDateJob",
			key: "endDateJob",
			align: "right",
			...getColumnSearchProps("endDateJob", "วันสิ้นสุดการทำงาน"),
		},
		{
			title: "เหตุผลการลาออก",
			dataIndex: "reasonLeaveDes",
			key: "reasonLeaveDes",
			align: "left",
			...getColumnSearchProps("reasonLeaveDes", "เหตุผลการลาออก"),
		},
	];

	const ColumnOJT_Course = [
		{
			title: "รหัสตำแหน่ง",
			key: "positionNo",
			dataIndex: "positionNo",
			align: "left",
			width: "20%",
			...getColumnSearchProps("positionNo", "รหัสตำแหน่ง"),
		},
		{
			title: "หลักสูตรหลัก (Training)",
			key: "training",
			dataIndex: "training",
			width: "65%",
			ellipsis: true,
			render: (tags) => (
				<>
					<Space size="middle" wrap>
						{`หลักสูตรหลัก (Training) ที่พบ : ${tags.length} รายการ`}
					</Space>
				</>
			),
		},
		{
			title: "จัดการข้อมูล",
			key: "action",
			align: "center",
			width: "15%",
			fixed: "right",
			render: (val) => {
				let btnChange = val.statusActive ? `inActive` : `active`;
				return (
					<Space size="middle">
						{useButton({
							call: "edit_link",
							linkBtn: `${Config.LinkEdit}${val.positionNo}`,
						})}
						{useButton({
							call: btnChange,
							fn: () =>
								useConfirm({
									FnOK: () => Config.FnDelete(val),
								}),
						})}
					</Space>
				);
			},
		},
	];

	const ColumnOJT_Training = [
		{
			title: "รหัสหลักสูตรหลัก",
			dataIndex: "trainingNo",
			key: "trainingNo",
			align: "left",
			...getColumnSearchProps("trainingNo", "รหัสหลักสูตรหลัก"),
		},
		{
			title: "ประเภทหลักสูตรหลัก",
			dataIndex: "trainingGroup",
			key: "trainingGroup",
			align: "left",
			...getColumnSearchProps("trainingGroup", "ประเภทหลักสูตรหลัก"),
		},
		{
			title: "ชื่อหัวข้อหลักสูตรหลัก",
			dataIndex: "trainingCourses",
			key: "trainingCourses",
			align: "left",
			...getColumnSearchProps("trainingCourses", "ชื่อหัวข้อหลักสูตรหลัก"),
		},
		{
			title: "Training Method",
			dataIndex: "trainingMethodDes",
			key: "trainingMethodDes",
			align: "left",
			...getColumnSearchProps("trainingMethodDes", "Training Method"),
		},
		{
			title: "ระดับ JG",
			dataIndex: "levelJG",
			key: "levelJG",
			align: "right",
		},
		{
			title: "ระยะเวลา",
			dataIndex: "timePeriod",
			key: "timePeriod",
			align: "right",
			sorter: (a, b) => a.timePeriod - b.timePeriod,
		},
		{
			title: "จัดการข้อมูล",
			key: "action",
			align: "center",
			fixed: "right",
			render: (val) => {
				return (
					<Row>
						<Col span={24}>
							{useButton({
								call: "edit_link",
								linkBtn: `${Config.LinkEdit}${val.trainingNo}`,
							})}
						</Col>
					</Row>
				);
			},
		},
	];

	const ColumnKPIs = [
		{
			title: "รหัสตำแหน่งงาน",
			dataIndex: "positionCode",
			key: "positionCode",
			align: "left",
			...getColumnSearchProps("positionCode", "รหัสตำแหน่งงาน"),
		},
		{
			title: "รหัสตัวชี้วัด",
			dataIndex: "indicatorNo",
			key: "indicatorNo",
			align: "left",
			...getColumnSearchProps("indicatorNo", "รหัสตัวชี้วัด"),
		},
		{
			title: "สถานะพนักงาน",
			dataIndex: "statusWorker",
			key: "statusWorker",
			align: "left",
			...getColumnSearchProps("statusWorker", "สถานะพนักงาน"),
		},
		{
			title: "ตัวชี้วัด",
			key: "indicators",
			dataIndex: "indicators",
			render: (value) => (
				<>
					<Space size="middle" wrap>
						{`จำนวนรายการที่พบ : ${value.length} รายการ`}
					</Space>
				</>
			),
		},
		{
			title: "จัดการข้อมูล",
			key: "action",
			align: "center",
			width: "20%",
			fixed: "right",
			render: (val) => {
				let btnChange = val.statusActiveHeader ? `inActive` : `active`;
				return (
					<Space size="middle">
						{useButton({
							call: "edit_link",
							linkBtn: `${Config.LinkEdit}${val.indicatorNo}`,
						})}
						{useButton({
							call: btnChange,
							fn: () =>
								useConfirm({
									FnOK: () => Config.FnDelete(val),
								}),
						})}
					</Space>
				);
			},
		},
	];

	const ColumnJG_PG = [
		{
			title: "รหัสพนักงาน",
			dataIndex: "userId",
			key: "userId",
			align: "left",
			width: 150,
			...getColumnSearchProps("userId", "รหัสตำแหน่งงาน"),
		},
		{
			title: "ชื่อ - นามสกุล",
			dataIndex: "name",
			key: "name",
			align: "left",
			width: 200,
			...getColumnSearchProps("name", "นามสกุล"),
		},
		{
			title: "ชื่อเล่น",
			dataIndex: "nickname",
			key: "nickname",
			align: "left",
			width: 100,
			...getColumnSearchProps("nickname", "ชื่อเล่น"),
		},
		{
			title: "บริษัท",
			dataIndex: "companyName",
			key: "companyName",
			align: "left",
			width: 300,
			...getColumnSearchProps("companyName", "บริษัท"),
		},
		{
			title: "แผนก",
			dataIndex: "description",
			key: "description",
			align: "left",
			width: 300,
			...getColumnSearchProps("description", "แผนก"),
		},
		{
			title: "JG",
			dataIndex: "JG",
			key: "JG",
			align: "right",
			width: 100,
			sorter: (a, b) => a.JG - b.JG,
			...getColumnSearchProps("JG", "JG"),
		},
		{
			title: "PG",
			dataIndex: "PG",
			key: "PG",
			align: "right",
			width: 100,
			sorter: (a, b) => a.PG - b.PG,
			...getColumnSearchProps("PG", "PG"),
		},
	];

	const Column_Crime = (onChangeValue, option) => [
		{
			title: "รหัสพนักงาน",
			dataIndex: "ID_User",
			key: "ID_User",
			align: "left",
			...getColumnSearchProps("ID_User", "รหัสพนักงาน"),
		},
		{
			title: "บริษัท",
			dataIndex: "company",
			key: "company",
			align: "left",
			...getColumnSearchProps("company", "บริษัท"),
		},
		{
			title: "แผนก",
			dataIndex: "depNo",
			key: "depNo",
			align: "left",
			...getColumnSearchProps("depNo", "แผนก"),
		},
		{
			title: "ชื่อ - นามสกุล",
			dataIndex: "Name",
			key: "Name",
			align: "left",
			...getColumnSearchProps("Name", "ชื่อ - นามสกุล"),
		},
		{
			title: "เลขประจำตัวประชาชน",
			dataIndex: "Personal_id",
			key: "Personal_id",
			align: "left",
			...getColumnSearchProps("Personal_id", "ชื่อเล่น"),
		},
		{
			title: "วันที่เริ่มงาน",
			dataIndex: "startDateJob",
			key: "startDateJob",
			align: "left",
			...getColumnSearchProps("startDateJob", "วันที่เริ่มงาน"),
		},
	];

	const ColumnCrimeUpdate = (option) => [
		{
			title: "รหัสพนักงาน",
			dataIndex: "userId",
			key: "userId",
			align: "left",
			width: 150,
			...getColumnSearchProps("userId", "รหัสพนักงาน"),
		},
		{
			title: "คำนำหน้าชื่อ",
			dataIndex: "title",
			key: "title",
			align: "left",
			width: 100,
			...getColumnSearchProps("title", "คำนำหน้าชื่อ"),
		},
		{
			title: "ชื่อพนักงาน",
			dataIndex: "Name",
			key: "Name",
			align: "left",
			width: 200,
			...getColumnSearchProps("Name", "ชื่อ - นามสกุล"),
		},
		{
			title: "วันเกิด",
			dataIndex: "birthday",
			key: "birthday",
			align: "left",
			width: 150,
		},
		{
			title: "ที่อยู่ปัจจุบัน",
			dataIndex: "presentAddress",
			key: "presentAddress",
			align: "left",
			width: 400,
			...getColumnSearchProps("presentAddress", "ที่อยู่ปัจจุบัน"),
		},
		{
			title: "บริษัท",
			dataIndex: "company",
			key: "company",
			align: "left",
			width: 100,
			...getColumnSearchProps("company", "บริษัท"),
		},
		{
			title: "แผนก",
			dataIndex: "depName",
			key: "depName",
			align: "left",
			width: 200,
			...getColumnSearchProps("depName", "แผนก"),
		},

		{
			title: "แผนกย่อย",
			dataIndex: "subDepName",
			key: "subDepName",
			align: "left",
			width: 300,
			...getColumnSearchProps("subDepName", "แผนกย่อย"),
		},
		{
			title: "วันที่ยื่น",
			dataIndex: "prepareDate",
			key: "prepareDate",
			align: "left",
			width: 150,
			...getColumnSearchProps("prepareDate", "วันที่ยื่น"),
		},
		{
			title: "ผู้สรรหา",
			dataIndex: "recruiterNickname",
			key: "recruiterNickname",
			align: "left",
			width: 150,
			...getColumnSearchProps("recruiterNickname", "ผู้สรรหา"),
		},
		{
			title: "ผลตรวจอาชญากรรม",
			key: "action",
			align: "left",
			fixed: "right",
			width: 250,
			render: (val) => {
				return (
					<SelectAntd
						name={`result_${val.userId}`}
						label={``}
						smgReq={`กรุณาเลือก เลือกคำตอบ`}
						placeholder={`เลือก เลือกคำตอบ`}
						dropdown={option}
						required={true}
						DefaultValue={"pass"}
					/>
				);
			},
		},
		{
			title: "เพิ่มหมายเหตุ",
			key: "description",
			align: "left",
			fixed: "right",
			width: 250,
			render: (val) => {
				return (
					<InputAnt
						name={`remark_${val.userId}`}
						label={``}
						smgReq={`เพิ่มหมายเหตุ`}
						placeholder={`เพิ่มหมายเหตุ (ถ้ามี)`}
						required={false}
					/>
				);
			},
		},
	];

	const ColumnCrimeShowDetail = () => [
		{
			title: "รหัสพนักงาน",
			dataIndex: "userId",
			key: "userId",
			align: "left",
			fixed: "left",
			width: 100,
			...getColumnSearchProps("userId", "รหัสพนักงาน"),
		},
		{
			title: "คำนำหน้าชื่อ",
			dataIndex: "title",
			key: "title",
			align: "left",
			fixed: "left",
			width: 100,
			...getColumnSearchProps("title", "คำนำหน้าชื่อ"),
		},
		{
			title: "ชื่อพนักงาน",
			dataIndex: "Name",
			key: "Name",
			align: "left",
			fixed: "left",
			width: 200,
			...getColumnSearchProps("Name", "ชื่อ - นามสกุล"),
		},
		{
			title: "เลขบัตรประชาชน",
			dataIndex: "Personal_id",
			key: "Personal_id",
			align: "left",
			fixed: "left",
			width: 200,
			...getColumnSearchProps("Personal_id", "เลขบัตรประชาชน"),
		},
		{
			title: "วันเดือนปีเกิด",
			dataIndex: "birthday",
			key: "birthday",
			align: "left",
			width: 150,
		},
		{
			title: "ชื่อบิดา-มารดา",
			dataIndex: "parentNames",
			key: "parentNames",
			align: "left",
			width: 200,
			...getColumnSearchProps("parentNames", "ชื่อบิดา-มารดา"),
		},
		{
			title: "ที่อยู่ปัจจุบัน",
			dataIndex: "presentAddress",
			key: "presentAddress",
			align: "left",
			width: 400,
			...getColumnSearchProps("presentAddress", "ที่อยู่ปัจจุบัน"),
		},
		{
			title: "ผลตรวจอาชญากรรม",
			dataIndex: "crimeResult",
			key: "crimeResult",
			align: "left",
			width: 250,
			...getColumnSearchProps("crimeResult", "ผลตรวจอาชญากรรม"),
		},
		{
			title: "วันที่ยื่นเอกสาร",
			dataIndex: "prepareDate",
			key: "prepareDate",
			align: "left",
			width: 150,
		},
		{
			title: "บริษัท",
			dataIndex: "company",
			key: "company",
			align: "left",
			width: 100,
			...getColumnSearchProps("company", "บริษัท"),
		},
		{
			title: "แผนก",
			dataIndex: "depName",
			key: "depName",
			align: "left",
			width: 200,
			...getColumnSearchProps("depName", "แผนก"),
		},
		{
			title: "แผนกย่อย",
			dataIndex: "subDepName",
			key: "subDepName",
			align: "left",
			width: 300,
			...getColumnSearchProps("subDepName", "แผนกย่อย"),
		},
		{
			title: "ผู้สรรหา",
			dataIndex: "recruiterNickname",
			key: "recruiterNickname",
			align: "left",
			width: 150,
			...getColumnSearchProps("recruiterNickname", "ผู้สรรหา"),
		},
	];

	const ColumnCreateCrime = [
		{
			title: "รหัสพนักงาน",
			dataIndex: "ID_User",
			key: "ID_User",
			align: "left",
			fixed: "left",
			width: 100,
			...getColumnSearchProps("ID_User", "รหัสพนักงาน"),
		},
		{
			title: "คำนำหน้าชื่อ",
			dataIndex: "title",
			key: "title",
			align: "left",
			fixed: "left",
			width: 100,
			...getColumnSearchProps("title", "คำนำหน้าชื่อ"),
		},
		{
			title: "ชื่อพนักงาน",
			dataIndex: "Name",
			key: "Name",
			align: "left",
			fixed: "left",
			width: 200,
			...getColumnSearchProps("Name", "ชื่อพนักงาน"),
		},
		{
			title: "เลขประจำตัวประชาชน",
			dataIndex: "Personal_id",
			key: "Personal_id",
			align: "right",
			fixed: "left",
			width: 200,
			...getColumnSearchProps("Personal_id", "เลขประจำตัวประชาชน"),
		},
		{
			title: "วันเดือนปีเกิด",
			dataIndex: "birthday",
			key: "birthday",
			align: "left",
			width: 150,
			...getColumnSearchProps("birthday", "วันเดือนปีเกิด"),
		},
		{
			title: "ชื่อบิดา-มารดา",
			dataIndex: "parentNames",
			key: "parentNames",
			align: "left",
			width: 200,
			...getColumnSearchProps("parentNames", "มารดา"),
		},
		{
			title: "ที่อยู่ปัจจุบัน",
			dataIndex: "presentAddress",
			key: "presentAddress",
			align: "left",
			width: 400,
			...getColumnSearchProps("presentAddress", "วันเดือนปีเกิด"),
		},
		{
			title: "ผลการตรวจสอบ",
			dataIndex: "crimeResultCode",
			key: "crimeResultCode",
			align: "left",
			width: 150,
			...getColumnSearchProps("crimeResultCode", "ผลการตรวจสอบ"),
		},
		{
			title: "บริษัท",
			dataIndex: "company",
			key: "company",
			align: "left",
			width: 100,
			...getColumnSearchProps("company", "บริษัท"),
		},
		{
			title: "Location",
			dataIndex: "location",
			key: "location",
			align: "left",
			width: 100,
			...getColumnSearchProps("location", "Location"),
		},
		{
			title: "แผนก",
			dataIndex: "depName",
			key: "depName",
			align: "left",
			width: 200,
			...getColumnSearchProps("depName", "แผนก"),
		},
		{
			title: "แผนกย่อย",
			dataIndex: "subDepName",
			key: "subDepName",
			align: "left",
			width: 300,
			...getColumnSearchProps("subDepName", "แผนกย่อย"),
		},
		{
			title: "วันที่เริ่มงาน",
			dataIndex: "startDateJob",
			key: "startDateJob",
			align: "right",
			width: 150,
			...getColumnSearchProps("startDateJob", "วันที่เริ่มงาน"),
		},
		{
			title: "ผู้สรรหา",
			dataIndex: "recruiterNickname",
			key: "recruiterNickname",
			align: "left",
			width: 150,
			...getColumnSearchProps("recruiterNickname", "ผู้สรรหา"),
		},
	];

	const ColumnCrimeReport = [
		{
			title: "บริษัท",
			dataIndex: "company",
			key: "company",
			align: "left",
			width: 100,
			...getColumnSearchProps("company", "บริษัท"),
		},
		{
			title: "Location",
			dataIndex: "location",
			key: "location",
			align: "left",
			width: 100,
			...getColumnSearchProps("location", "Location"),
		},
		{
			title: "ชุดเอกสาร(ระบบ)",
			dataIndex: "docNo",
			key: "docNo",
			align: "left",
			width: 150,
			...getColumnSearchProps("docNo", "ชุดเอกสาร(ระบบ)"),
		},
		{
			title: "ชุดเอกสาร(เพิ่มเติม)",
			dataIndex: "externalDoc",
			key: "externalDoc",
			align: "left",
			width: 150,
			...getColumnSearchProps("externalDoc", "ชุดเอกสาร(เพิ่มเติม)"),
		},
		{
			title: "วันที่ยื่น",
			dataIndex: "prepareDate",
			key: "prepareDate",
			align: "right",
			width: 150,
			...getColumnSearchProps("prepareDate", "วันที่ยื่น"),
		},
		{
			title: "วันที่ส่งตรวจ",
			dataIndex: "sendDate",
			key: "sendDate",
			align: "right",
			width: 150,
			...getColumnSearchProps("sendDate", "วันที่ส่งตรวจ"),
		},
		{
			title: "วันที่รับผลตรวจ",
			dataIndex: "receivedDate",
			key: "receivedDate",
			align: "right",
			width: 150,
			...getColumnSearchProps("receivedDate", "วันที่รับผลตรวจ"),
		},
		{
			title: "จัดการข้อมูล",
			key: "action",
			align: "left",
			width: 300,
			fixed: "right",
			render: (val) => {
				return (
					<Space size="middle" wrap>
						{!val.filePath ? (
							useButton({
								call: "edit_link",
								linkBtn: `ManageCrime/update-crime/${val.docNo}`,
							})
						) : (
							<>
								{useButton({
									call: "link",
									linkBtn: `ManageCrime/detail-crime/${val.docNo}`,
									moreProps: {
										label: "รายละเอียด",
										type: "primary",
										target: "_blank",
										icon: <SearchOutlined />,
									},
								})}
								{useButton({
									call: "download",
									linkBtn: val.filePath,
									moreProps: { label: "ดาวน์โหลด" },
								})}
							</>
						)}
					</Space>
				);
			},
		},
	];

	const ColumnReportCrime = [
		{
			title: "รหัสพนักงาน",
			dataIndex: "userId",
			key: "userId",
			align: "left",
			fixed: "left",
			width: 100,
			...getColumnSearchProps("userId", "รหัสพนักงาน"),
		},
		{
			title: "ชื่อพนักงาน",
			dataIndex: "fullname",
			key: "fullname",
			align: "left",
			fixed: "left",
			width: 200,
			...getColumnSearchProps("fullname", "ชื่อพนักงาน"),
		},
		{
			title: "เลขบัตรประชาชน",
			dataIndex: "personalId",
			key: "personalId",
			align: "left",
			width: 150,
			...getColumnSearchProps("personalId", "เลขบัตรประชาชน"),
		},
		{
			title: "วันเดือนปีเกิด",
			dataIndex: "birthday",
			key: "birthday",
			align: "left",
			width: 150,
		},
		{
			title: "ชื่อบิดา-มารดา",
			dataIndex: "parentName",
			key: "parentName",
			align: "left",
			width: 200,
			...getColumnSearchProps("parentName", "ชื่อบิดา-มารดา"),
		},
		{
			title: "ที่อยู่ปัจจุบันและภูมิลำเนา",
			dataIndex: "address",
			key: "address",
			align: "left",
			...getColumnSearchProps("address", "ที่อยู่ปัจจุบันและภูมิลำเนา"),
			width: 400,
		},
		{
			title: "ผลตรวจอาชญากรรม",
			dataIndex: "crimeResult",
			key: "crimeResult",
			align: "left",
			width: 200,
			...getColumnSearchProps("crimeResult", "ผลตรวจอาชญากรรม"),
		},
		{
			title: "หมายเลขหนังสือเดินทาง",
			dataIndex: "passportId",
			key: "passportId",
			align: "left",
			width: 200,
			...getColumnSearchProps("passportId", "หมายเลขหนังสือเดินทาง"),
		},
		{
			title: "บริษัท",
			dataIndex: "Company",
			key: "Company",
			align: "left",
			width: 100,
			...getColumnSearchProps("company", "บริษัท"),
		},
		{
			title: "Location",
			dataIndex: "Location",
			key: "Location",
			align: "left",
			width: 100,
			...getColumnSearchProps("Location", "Location"),
		},
		{
			title: "รหัสแผนก",
			dataIndex: "depNo",
			key: "depNo",
			align: "left",
			width: 150,
			...getColumnSearchProps("depNo", "รหัสแผนก"),
		},
		{
			title: "ชื่อแผนก",
			dataIndex: "depName",
			key: "depName",
			align: "left",
			width: 200,
			...getColumnSearchProps("depName", "ชื่อแผนก"),
		},
		{
			title: "รหัสแผนกย่อย",
			dataIndex: "subDepNo",
			key: "subDepNo",
			align: "left",
			width: 200,
			...getColumnSearchProps("subDepNo", "รหัสแผนกย่อย"),
		},
		{
			title: "ชื่อแผนกย่อย",
			dataIndex: "subDepName",
			key: "subDepName",
			align: "left",
			width: 300,
			...getColumnSearchProps("subDepName", "ชื่อแผนกย่อย"),
		},
		{
			title: "วันที่เริ่มงาน",
			dataIndex: "startDateJob",
			key: "startDateJob",
			align: "left",
			width: 150,
		},
		{
			title: "ชุดเอกสาร(ระบบ)",
			dataIndex: "refDocNo",
			key: "refDocNo",
			align: "left",
			width: 150,
			...getColumnSearchProps("refDocNo", "ชุดเอกสาร(ระบบ)"),
		},
		{
			title: "ชุดเอกสาร(เพิ่มเติม)",
			dataIndex: "externalDoc",
			key: "externalDoc",
			align: "left",
			width: 150,
			...getColumnSearchProps("externalDoc", "ชุดเอกสาร(เพิ่มเติม)"),
		},
		{
			title: "วันที่ยื่น",
			dataIndex: "prepareDate",
			key: "prepareDate",
			align: "right",
			width: 150,
		},
		{
			title: "วันที่ส่งตรวจ",
			dataIndex: "sendDate",
			key: "sendDate",
			align: "right",
			width: 150,
		},
		{
			title: "วันที่รับผลตรวจ",
			dataIndex: "receivedDate",
			key: "receivedDate",
			align: "right",
			width: 150,
		},
		// {
		//   title: "จัดการข้อมูล",
		//   key: "action",
		//   align: "left",
		//   width: "25%",
		//   fixed: "right",
		//   render: (val) => {
		//     return (
		//       <Space size="middle" wrap>
		//         {!val.filePath ? (
		//           useButton({
		//             call: "edit_link",
		//             linkBtn: `ManageCrime/update-crime/${val.docNo}`,
		//           })
		//         ) : (
		//           <>
		//             {useButton({
		//               call: "link",
		//               linkBtn: `ManageCrime/detail-crime/${val.docNo}`,
		//               moreProps: {
		//                 label: "รายละเอียด",
		//                 type: "primary",
		//                 target: "_blank",
		//                 icon: <SearchOutlined />,
		//               },
		//             })}
		//             {useButton({
		//               call: "download",
		//               linkBtn: val.filePath,
		//               moreProps: { label: "ดาวน์โหลด" },
		//             })}
		//           </>
		//         )}
		//       </Space>
		//     );
		//   },
		// },
	];

	const Column_Probation = [
		{
			title: "บริษัท",
			dataIndex: "company",
			key: "company",
			align: "left",
		},
		{
			title: "แผนก",
			dataIndex: "depName",
			key: "depName",
			align: "left",
			...getColumnSearchProps("depName", "แผนก"),
		},
		{
			title: "แผนกย่อย",
			dataIndex: "subDepName",
			key: "subDepName",
			align: "left",
			...getColumnSearchProps("subDepName", "แผนกย่อย"),
		},
		{
			title: "ตำแหน่งงาน",
			dataIndex: "positionName",
			key: "positionName",
			align: "left",
			...getColumnSearchProps("positionName", "ตำแหน่งงาน"),
		},
		{
			title: "จำนวนเกณฑ์ชี้วัด",
			dataIndex: "descriptions",
			key: "descriptions",
			align: "left",
			render: (value) => (
				<>
					<Space size="middle" wrap>
						{`จำนวนรายการที่พบ : ${value.length} รายการ`}
					</Space>
				</>
			),
		},
		{
			title: "จัดการข้อมูล",
			key: "action",
			align: "center",
			width: "15%",
			fixed: "right",
			render: (val) => {
				return (
					<Space size="middle" wrap>
						{useButton({
							call: "edit_link",
							linkBtn: `ManageMasterProbation/update-probation/${val.positionNo}`,
						})}
						{useButton({
							call: "normal",
							fn: () =>
								useConfirm({
									FnOK: () => Config.FnDelete(val.positionNo),
								}),
							moreProps: {
								label: "ลบ",
								type: "danger",
								icon: <CloseCircleOutlined />,
							},
						})}
					</Space>
				);
			},
		},
	];

	const Column_Report_ALL = [
		{
			title: "รหัสพนักงาน",
			dataIndex: "userId",
			key: "userId",
			align: "left",

			width: 100,
			...getColumnSearchProps("userId", "รหัสพนักงาน"),
		},
		{
			title: "คำนำหน้าชื่อ (ไทย)",
			dataIndex: "title",
			key: "title",
			align: "left",
			width: 150,
			...getColumnSearchProps("title", "คำนำหน้าชื่อ (ไทย)"),
		},
		{
			title: "ชื่อภาษาไทย",
			dataIndex: "firstname",
			key: "firstname",
			align: "left",
			width: 150,
			...getColumnSearchProps("firstname", "ชื่อภาษาไทย"),
		},
		{
			title: "นามสกุลภาษาไทย",
			dataIndex: "lastname",
			key: "lastname",
			align: "left",
			width: 200,
			...getColumnSearchProps("lastname", "นามสกุลภาษาไทย"),
		},
		{
			title: "คำนำหน้าชื่อ (อังกฤษ)",
			dataIndex: "titleEN",
			key: "titleEN",
			align: "left",
			width: 150,
			...getColumnSearchProps("titleEN", "คำนำหน้าชื่อ (อังกฤษ)"),
		},
		{
			title: "ชื่อภาษาอังกฤษ",
			dataIndex: "firstnameEN",
			key: "firstnameEN",
			align: "left",
			width: 150,
			...getColumnSearchProps("firstnameEN", "ชื่อภาษาอังกฤษ"),
		},
		{
			title: "นามสกุลภาษาอังกฤษ",
			dataIndex: "lastnameEN",
			key: "lastnameEN",
			align: "left",
			width: 200,
			...getColumnSearchProps("lastnameEN", "ชื่อภาษาอังกฤษ"),
		},
		{
			title: "เพศ",
			dataIndex: "gender",
			key: "gender",
			align: "left",
			width: 100,
			...getColumnSearchProps("gender", "เพศ"),
		},
		{
			title: "วันเกิด",
			dataIndex: "birthday",
			key: "birthday",
			align: "left",
			width: 150,
		},
		{
			title: "อายุ",
			dataIndex: "age",
			key: "age",
			align: "right",
			width: 100,
		},
		{
			title: "ชื่อเล่น",
			dataIndex: "nickName",
			key: "nickName",
			align: "left",
			width: 100,
			...getColumnSearchProps("nickName", "ชื่อเล่น"),
		},
		{
			title: "สัญชาติ",
			dataIndex: "nationality",
			key: "nationality",
			align: "left",
			width: 100,
			...getColumnSearchProps("nationality", "สัญชาติ"),
		},
		{
			title: "เชื้อชาติ",
			dataIndex: "race",
			key: "race",
			align: "left",
			width: 100,
			...getColumnSearchProps("race", "เชื้อชาติ"),
		},
		{
			title: "ศาสนา",
			dataIndex: "religion",
			key: "religion",
			align: "left",
			width: 100,
			...getColumnSearchProps("religion", "ศาสนา"),
		},
		{
			title: "เลขบัตรประชาชน",
			dataIndex: "personalId",
			key: "personalId",
			align: "left",
			width: 200,
			...getColumnSearchProps("personalId", "เลขบัตรประจำตัวประชาชน"),
		},
		{
			title: "อีเมล",
			dataIndex: "email",
			key: "email",
			align: "left",
			width: 300,
			...getColumnSearchProps("email", "อีเมล"),
		},
		{
			title: "ที่อยู่ปัจจุบัน",
			dataIndex: "presentAddress",
			key: "presentAddress",
			align: "left",
			width: 400,
			...getColumnSearchProps("presentAddress", "ที่อยู่ปัจจุบัน"),
		},
		{
			title: "อำเภอ/เขต",
			dataIndex: "presentDistrict",
			key: "presentDistrict",
			align: "left",
			width: 150,
			...getColumnSearchProps("presentDistrict", "อำเภอ/เขต"),
		},
		{
			title: "จังหวัด",
			dataIndex: "presentProvince",
			key: "presentProvince",
			align: "left",
			width: 150,
			...getColumnSearchProps("presentProvince", "จังหวัด"),
		},
		{
			title: "รหัสไปรษณีย์",
			dataIndex: "presentPostcode",
			key: "presentPostcode",
			align: "right",
			width: 100,
			...getColumnSearchProps("presentPostcode", "รหัสไปรษณีย์"),
		},
		{
			title: "ที่อยู่ตามทะเบียนบ้าน",
			dataIndex: "formerAddress",
			key: "formerAddress",
			align: "left",
			width: 400,
			...getColumnSearchProps("formerAddress", "ที่อยู่ตามทะเบียนบ้าน"),
		},
		{
			title: "อำเภอ/เขต",
			dataIndex: "formerDistrict",
			key: "formerDistrict",
			align: "left",
			width: 150,
			...getColumnSearchProps("formerDistrict", "อำเภอ/เขต"),
		},
		{
			title: "จังหวัด",
			dataIndex: "formerProvince",
			key: "formerProvince",
			align: "left",
			width: 150,
			...getColumnSearchProps("formerProvince", "จังหวัด"),
		},
		{
			title: "รหัสไปรษณีย์",
			dataIndex: "formerPostcode",
			key: "formerPostcode",
			align: "right",
			width: 100,
			...getColumnSearchProps("formerPostcode", "รหัสไปรษณีย์"),
		},
		{
			title: "โทรศัพท์มือถือ(1)",
			dataIndex: "telNo",
			key: "telNo",
			align: "right",
			width: 150,
			...getColumnSearchProps("telNo", "โทรศัพท์มือถือ(1)"),
		},
		{
			title: "วุฒิการศึกษา",
			dataIndex: "educationLevel",
			key: "educationLevel",
			align: "left",
			width: 150,
			...getColumnSearchProps("educationLevel", "วุฒิการศึกษา"),
		},
		{
			title: "สถาบันการศึกษา",
			dataIndex: "educationInstitution",
			key: "educationInstitution",
			align: "left",
			width: 250,
			...getColumnSearchProps("educationInstitution", "สถาบันการศึกษา"),
		},
		{
			title: "คณะการศึกษา",
			dataIndex: "educationFaculty",
			key: "educationFaculty",
			align: "left",
			width: 200,
			...getColumnSearchProps("educationFaculty", "คณะการศึกษา"),
		},
		{
			title: "เกรดเฉลี่ย",
			dataIndex: "gpa",
			key: "gpa",
			align: "right",
			width: 100,
			...getColumnSearchProps("gpa", "เกรดเฉลี่ย"),
		},
		{
			title: "รหัสบริษัท",
			dataIndex: "company",
			key: "company",
			align: "left",
			width: 100,
			...getColumnSearchProps("company", "รหัสบริษัท"),
		},
		{
			title: "ชื่อบริษัท",
			dataIndex: "companyName",
			key: "companyName",
			align: "left",
			width: 250,
			...getColumnSearchProps("companyName", "ชื่อบริษัท"),
		},
		{
			title: "สถานภาพพนักงาน",
			dataIndex: "currentStatusDes",
			key: "currentStatusDes",
			align: "left",
			width: 200,
			...getColumnSearchProps("currentStatusDes", "สถานภาพพนักงาน"),
		},
		{
			title: "สถานะพนักงาน",
			dataIndex: "statusWorker",
			key: "statusWorker",
			align: "left",
			width: 200,
			...getColumnSearchProps("statusWorker", "สถานะพนักงาน"),
		},
		{
			title: "วันที่เริ่มงาน",
			dataIndex: "StartDateJob",
			key: "StartDateJob",
			align: "right",
			width: 150,
			...getColumnSearchProps("StartDateJob", "วันที่เริ่มงาน"),
		},
		{
			title: "อายุงาน (ปี.เดือน)",
			dataIndex: "ageOfWork",
			key: "ageOfWork",
			align: "right",
			width: 100,
			...getColumnSearchProps("ageOfWork", "อายุงาน (ปี.เดือน)"),
		},
		{
			title: "วันผ่านทดลองงาน",
			dataIndex: "probationDateEnd",
			key: "probationDateEnd",
			align: "right",
			width: 150,
			...getColumnSearchProps("probationDateEnd", "วันผ่านทดลองงาน"),
		},
		{
			title: "วันที่มีผลพ้นสภาพพนักงาน",
			dataIndex: "endDateJob",
			key: "endDateJob",
			align: "right",
			width: 200,
			...getColumnSearchProps("endDateJob", "วันที่มีผลพ้นสภาพพนักงาน"),
		},
		{
			title: "ประเภทการลาออก",
			dataIndex: "reasonLeaveDesCode",
			key: "reasonLeaveDesCode",
			align: "left",
			width: 150,
			...getColumnSearchProps("reasonLeaveDesCode", "ประเภทการลาออก"),
		},
		{
			title: "เหตุผลการพ้นสภาพ",
			dataIndex: "reasonLeaveDes",
			key: "reasonLeaveDes",
			align: "left",
			width: 200,
			...getColumnSearchProps("reasonLeaveDes", "เหตุผลการพ้นสภาพ"),
		},
		{
			title: "รหัสตำแหน่งงาน",
			dataIndex: "positionNo",
			key: "positionNo",
			align: "left",
			width: 200,
			...getColumnSearchProps("positionNo", "รหัสตำแหน่งงาน"),
		},
		{
			title: "ชื่อตำแหน่งงาน",
			dataIndex: "positionName",
			key: "positionName",
			align: "left",
			width: 300,
			...getColumnSearchProps("positionName", "ชื่อตำแหน่งงาน"),
		},
		{
			title: "รหัสแผนก",
			dataIndex: "depNo",
			key: "depNo",
			align: "left",
			width: 200,
			...getColumnSearchProps("depNo", "รหัสแผนก"),
		},
		{
			title: "ชื่อแผนก",
			dataIndex: "depName",
			key: "depName",
			align: "left",
			width: 300,
			...getColumnSearchProps("depName", "ชื่อแผนก"),
		},
		{
			title: "รหัสแผนกย่อย",
			dataIndex: "subDepNo",
			key: "subDepNo",
			align: "left",
			width: 200,
			...getColumnSearchProps("subDepNo", "รหัสแผนกย่อย"),
		},
		{
			title: "ชื่อแผนกย่อย",
			dataIndex: "subDepName",
			key: "subDepName",
			align: "left",
			width: 300,
			...getColumnSearchProps("subDepName", "ชื่อแผนกย่อย"),
		},
		{
			title: "ระดับพนักงาน",
			dataIndex: "levelWorker",
			key: "levelWorker",
			align: "left",
			width: 150,
			...getColumnSearchProps("levelWorker", "ระดับพนักงาน"),
		},
		{
			title: "ชื่อระดับพนักงาน",
			dataIndex: "levelWorkerCode",
			key: "levelWorkerCode",
			align: "left",
			width: 250,
			...getColumnSearchProps("levelWorkerCode", "ชื่อระดับพนักงาน"),
		},
		{
			title: "รหัสสถานที่ทำงาน",
			dataIndex: "locationName",
			key: "locationName",
			align: "left",
			width: 150,
			...getColumnSearchProps("locationName", "รหัสสถานที่ทำงาน"),
		},
		{
			title: "รหัสผู้บังคับบัญชา",
			dataIndex: "bossId",
			key: "bossId",
			align: "left",
			width: 150,
			...getColumnSearchProps("bossId", "รหัสผู้บังคับบัญชา"),
		},
		{
			title: "ชื่อผู้บังคับบัญชา",
			dataIndex: "bossName",
			key: "bossName",
			align: "left",
			width: 200,
			...getColumnSearchProps("bossName", "ชื่อผู้บังคับบัญชา"),
		},
		{
			title: "สายอนุมัติ",
			dataIndex: "hrmFlow",
			key: "hrmFlow",
			align: "left",
			width: 200,
			...getColumnSearchProps("hrmFlow", "สายอนุมัติ"),
		},
		{
			title: "ประเภทพนักงานในระบบเงินเดือน",
			dataIndex: "typeEmpSalary",
			key: "typeEmpSalary",
			align: "left",
			width: 200,
			...getColumnSearchProps("typeEmpSalary", "ประเภทพนักงานในระบบเงินเดือน"),
		},
		{
			title: "เลขที่ล๊อคเกอร์",
			dataIndex: "lockerId",
			key: "lockerId",
			align: "left",
			width: 200,
			...getColumnSearchProps("lockerId", "เลขที่ล๊อคเกอร์"),
		},
		{
			title: "ผลการตรวจสารเสพติด",
			dataIndex: "addictiveResult",
			key: "addictiveResult",
			align: "left",
			width: 150,
			...getColumnSearchProps("addictiveResult", "ผลการตรวจสารเสพติด"),
		},
		{
			title: "ผลตรวจอาชญากรรม",
			dataIndex: "crimeResult",
			key: "crimeResult",
			align: "left",
			width: 150,
			...getColumnSearchProps("crimeResult", "ผลตรวจอาชญากรรม"),
		},
		{
			title: "สูบบุหรี่",
			dataIndex: "smoking",
			key: "smoking",
			align: "left",
			width: 100,
			...getColumnSearchProps("smoking", "สูบบุหรี่"),
		},
	];

	const Column_Report_Lv_Worker = [
		{
			title: "รหัสแผนก",
			dataIndex: "depNo",
			key: "depNo",
			align: "left",
			...getColumnSearchProps("depNo", "รหัสแผนก"),
		},
		{
			title: "ชื่อแผนก",
			dataIndex: "depName",
			key: "depName",
			align: "left",
			...getColumnSearchProps("depName", "ชื่อแผนก"),
		},
		{
			title: "เกรด (Level Worker)",
			children: [
				{
					title: "MAN",
					dataIndex: "MAN",
					key: "MAN",
					align: "right",
					sorter: (a, b) => a.MAN - b.MAN,
					render: (num) => num || `-`,
				},
				{
					title: "PRO",
					dataIndex: "PRO",
					key: "PRO",
					align: "right",
					sorter: (a, b) => a.PRO - b.PRO,
					render: (num) => num || `-`,
				},
				{
					title: "STF",
					dataIndex: "STF",
					key: "STF",
					align: "right",
					sorter: (a, b) => a.STF - b.STF,
					render: (num) => num || `-`,
				},
				{
					title: "SUP",
					dataIndex: "SUP",
					key: "SUP",
					align: "right",
					sorter: (a, b) => a.SUP - b.SUP,
					render: (num) => num || `-`,
				},
				{
					title: "รวมทั้งสิ้น",
					dataIndex: "total",
					key: "total",
					align: "right",
					sorter: (a, b) => a.total - b.total,
					render: (num) => num || `-`,
				},
			],
		},
	];

	const Column_ReportEmployeeAge = () => {
		let ColumnAge = [];
		for (let i = 18; i <= 60; i++) {
			ColumnAge.push({
				title: i,
				dataIndex: i,
				key: i,
				align: "right",
				sorter: (a, b) => a[i] - b[i],
				//render: (num) => num || `-`,
			});
		}
		return [
			{
				title: "บริษัท",
				dataIndex: "company",
				key: "company",
				align: "left",
				...getColumnSearchProps("company", "บริษัท"),
			},
			{
				title: "รหัสแผนก",
				dataIndex: "depNo",
				key: "depNo",
				align: "left",
				...getColumnSearchProps("depNo", "รหัสแผนก"),
			},
			{
				title: "ชื่อแผนก",
				dataIndex: "depName",
				key: "depName",
				align: "left",
				...getColumnSearchProps("depName", "ชื่อแผนก"),
			},
			{
				title: "Location",
				dataIndex: "location",
				key: "location",
				align: "left",
				...getColumnSearchProps("location", "Location"),
			},
			{
				title: "อายุพนักงาน",
				children: [
					...ColumnAge,
					{
						title: "รวมทั้งสิ้น",
						dataIndex: "total",
						key: "total",
						align: "right",
						sorter: (a, b) => a.total - b.total,
						//render: (num) => num || `-`,
					},
				],
			},
		];
	};

	const Column_ReportWorkAge = (filterBy) => {
		let ColumnAge = [];
		for (let i = 0; i <= 20; i++) {
			ColumnAge.push({
				title: i,
				dataIndex: i,
				key: i,
				align: "right",
				sorter: (a, b) => a[i] - b[i],
			});
		}

		let ColumnSubDep =
			filterBy === "subDepartment"
				? [
					{
						title: "ชื่อแผนก",
						dataIndex: "depName",
						key: "depName",
						align: "left",
						...getColumnSearchProps("depName", "ชื่อแผนก"),
					},
					{
						title: "รหัสแผนกย่อย",
						dataIndex: "subDepNo",
						key: "subDepNo",
						align: "left",
						...getColumnSearchProps("subDepNo", "รหัสแผนกย่อย"),
					},
				]
				: [];

		return [
			{
				title: "บริษัท",
				dataIndex: "company",
				key: "company",
				align: "left",
				...getColumnSearchProps("company", "บริษัท"),
			},
			{
				title: "รหัสแผนก",
				dataIndex: "depNo",
				key: "depNo",
				align: "left",
				...getColumnSearchProps("depNo", "รหัสแผนก"),
			},
			{
				title: "ชื่อแผนก",
				dataIndex: "depName",
				key: "depName",
				align: "left",
				...getColumnSearchProps("depName", "ชื่อแผนก"),
			},
			...ColumnSubDep,
			{
				title: "Location",
				dataIndex: "location",
				key: "location",
				align: "left",
				...getColumnSearchProps("location", "Location"),
			},
			{
				title: "อายุงานพนักงาน",
				children: [
					...ColumnAge,
					{
						title: "รวมทั้งสิ้น",
						dataIndex: "total",
						key: "total",
						align: "right",
						sorter: (a, b) => a.total - b.total,
						//render: (num) => num || `-`,
					},
				],
			},
		];
	};

	const Column_ReportStatusWorker = [
		{
			title: "รหัสบริษัท",
			dataIndex: "companyCode",
			key: "companyCode",
			align: "left",
			...getColumnSearchProps("companyCode", "รหัสบริษัท"),
		},
		{
			title: "ชื่อบริษัท",
			dataIndex: "companyName",
			key: "companyName",
			align: "left",
			...getColumnSearchProps("companyName", "ชื่อบริษัท"),
		},
		{
			title: "Location",
			dataIndex: "location",
			key: "location",
			align: "left",
			...getColumnSearchProps("location", "Location"),
		},
		{
			title: "สถานะการจ้าง",
			children: [
				{
					title: "พนักงานประจำรายเดือน",
					dataIndex: "Full",
					key: "Full",
					align: "right",
					sorter: (a, b) => a.Full - b.Full,
					render: (num) => num || `-`,
				},
				{
					title: "พนักงานประจำรายวัน",
					dataIndex: "Daily",
					key: "Daily",
					align: "right",
					sorter: (a, b) => a.Daily - b.Daily,
					render: (num) => num || `-`,
				},
				{
					title: "พนักงานชั่วคราว",
					dataIndex: "Temp",
					key: "Temp",
					align: "right",
					sorter: (a, b) => a.Temp - b.Temp,
					render: (num) => num || `-`,
				},
				{
					title: "จำนวนพนักงานในสถานที่ทำงาน",
					dataIndex: "total",
					key: "total",
					align: "right",
					sorter: (a, b) => a.total - b.total,
					render: (num) => num || `-`,
				},
			],
		},
	];

	const Column_ReportEmployeeTransfer = [
		{
			title: "รหัสพนักงาน",
			dataIndex: "userId",
			key: "userId",
			align: "left",
			...getColumnSearchProps("userId", "รหัสพนักงาน"),
		},
		{
			title: "คำนำหน้าชื่อ",
			dataIndex: "title",
			key: "title",
			align: "left",
			...getColumnSearchProps("title", "คำนำหน้าชื่อ"),
		},
		{
			title: "ชื่อพนักงาน",
			dataIndex: "name",
			key: "name",
			align: "left",
			...getColumnSearchProps("name", "ชื่อพนักงาน"),
		},
		{
			title: "วันที่เริ่มงาน",
			dataIndex: "startDateJob",
			key: "startDateJob",
			align: "left",
			...getColumnSearchProps("startDateJob", "วันที่เริ่มงาน"),
		},
		{
			title: "วันที่มีผล",
			dataIndex: "actionDate",
			key: "actionDate",
			align: "left",
			...getColumnSearchProps("actionDate", "วันที่มีผล"),
		},
		{
			title: "วันที่สิ้นสุด",
			dataIndex: "endDate",
			key: "endDate",
			align: "left",
			...getColumnSearchProps("endDate", "วันที่สิ้นสุด"),
		},
		{
			title: "ประเภทการเปลี่ยนแปลงการจ้าง",
			dataIndex: "type",
			key: "type",
			align: "left",
			...getColumnSearchProps("type", "ประเภทการเปลี่ยนแปลงการจ้าง"),
		},
		{
			title: "ประเภท",
			dataIndex: "actionType",
			key: "actionType",
			align: "left",
			...getColumnSearchProps("actionType", "ประเภทการเปลี่ยนแปลงการจ้าง"),
		},
		{
			title: "from",
			children: [
				{
					title: "รหัสบริษัท",
					dataIndex: "company",
					key: "company",
					align: "right",
					...getColumnSearchProps("company", "รหัสบริษัท"),
				},
				{
					title: "ชื่อบริษัท",
					dataIndex: "companyName",
					key: "companyName",
					align: "right",
					...getColumnSearchProps("companyName", "ชื่อบริษัท"),
				},
				{
					title: "รหัสตำแหน่งงาน",
					dataIndex: "positionCode",
					key: "positionCode",
					align: "right",
					...getColumnSearchProps("positionCode", "รหัสตำแหน่งงาน"),
				},
				{
					title: "ชื่อตำแหน่งงาน",
					dataIndex: "positionName",
					key: "positionName",
					align: "right",
					...getColumnSearchProps("positionName", "ชื่อตำแหน่งงาน"),
				},
				{
					title: "รหัสแผนก",
					dataIndex: "depNo",
					key: "depNo",
					align: "right",
					...getColumnSearchProps("depNo", "รหัสแผนก"),
				},
				{
					title: "ชื่อแผนก",
					dataIndex: "depName",
					key: "depName",
					align: "right",
					...getColumnSearchProps("depName", "ชื่อแผนก"),
				},
				{
					title: "รหัสแผนกย่อย",
					dataIndex: "subDepNo",
					key: "subDepNo",
					align: "right",
					...getColumnSearchProps("subDepNo", "รหัสแผนกย่อย"),
				},
				{
					title: "ชื่อแผนกย่อย",
					dataIndex: "subDepName",
					key: "subDepName",
					align: "right",
					...getColumnSearchProps("subDepName", "ชื่อแผนกย่อย"),
				},
				{
					title: "รหัสหัวหน้างาน",
					dataIndex: "boss",
					key: "boss",
					align: "right",
					...getColumnSearchProps("boss", "รหัสหัวหน้างาน"),
				},
				{
					title: "ชื่อหัวหน้างาน",
					dataIndex: "bossName",
					key: "bossName",
					align: "right",
					...getColumnSearchProps("bossName", "ชื่อหัวหน้างาน"),
				},
			],
		},
		{
			title: "to",
			children: [
				{
					title: "รหัสบริษัท",
					dataIndex: "company_2",
					key: "company_2",
					align: "right",
					...getColumnSearchProps("company_2", "รหัสบริษัท"),
				},
				{
					title: "ชื่อบริษัท",
					dataIndex: "companyName_2",
					key: "companyName_2",
					align: "right",
					...getColumnSearchProps("companyName_2", "ชื่อบริษัท"),
				},
				{
					title: "รหัสตำแหน่งงาน",
					dataIndex: "positionCode_2",
					key: "positionCode_2",
					align: "right",
					...getColumnSearchProps("positionCode_2", "รหัสตำแหน่งงาน"),
				},
				{
					title: "ชื่อตำแหน่งงาน",
					dataIndex: "positionName_2",
					key: "positionName_2",
					align: "right",
					...getColumnSearchProps("positionName_2", "ชื่อตำแหน่งงาน"),
				},
				{
					title: "รหัสแผนก",
					dataIndex: "depNo_2",
					key: "depNo_2",
					align: "right",
					...getColumnSearchProps("depNo_2", "รหัสแผนก"),
				},
				{
					title: "ชื่อแผนก",
					dataIndex: "depName_2",
					key: "depName_2",
					align: "right",
					...getColumnSearchProps("depName_2", "ชื่อแผนก"),
				},
				{
					title: "รหัสแผนกย่อย",
					dataIndex: "subDepNo_2",
					key: "subDepNo_2",
					align: "right",
					...getColumnSearchProps("subDepNo_2", "รหัสแผนกย่อย"),
				},
				{
					title: "ชื่อแผนกย่อย",
					dataIndex: "subDepName_2",
					key: "subDepName_2",
					align: "right",
					...getColumnSearchProps("subDepName_2", "ชื่อแผนกย่อย"),
				},
				{
					title: "รหัสหัวหน้างาน",
					dataIndex: "boss_2",
					key: "boss_2",
					align: "right",
					...getColumnSearchProps("boss_2", "รหัสหัวหน้างาน"),
				},
				{
					title: "ชื่อหัวหน้างาน",
					dataIndex: "bossName_2",
					key: "bossName_2",
					align: "right",
					...getColumnSearchProps("bossName_2", "ชื่อหัวหน้างาน"),
				},
			],
		},
	];

	const Column_ReportEmployeeSummary = (monthHeader, filterBy) => {
		let CheckFilter =
			filterBy === "subDep"
				? [
					{
						title: "รหัสแผนกย่อย",
						dataIndex: "subDepNo",
						key: "subDepNo",
						align: "left",
						...getColumnSearchProps("subDepNo", "รหัสแผนกย่อย"),
					},
					{
						title: "ชื่อแผนกย่อย",
						dataIndex: "subDepName",
						key: "subDepName",
						align: "left",
						...getColumnSearchProps("subDepName", "ชื่อแผนกย่อย"),
					},
				]
				: [];
		return [
			{
				title: "รหัสบริษัท",
				dataIndex: "company",
				key: "company",
				align: "left",
				...getColumnSearchProps("company", "รหัสบริษัท"),
			},
			{
				title: "Location",
				dataIndex: "location",
				key: "location",
				align: "left",
				...getColumnSearchProps("location", "Location"),
			},
			{
				title: "รหัสแผนก",
				dataIndex: "depNo",
				key: "depNo",
				align: "left",
				...getColumnSearchProps("depNo", "รหัสแผนก"),
			},
			{
				title: "ชื่อแผนก",
				dataIndex: "depName",
				key: "depName",
				align: "left",
				...getColumnSearchProps("depName", "ชื่อแผนก"),
			},
			...CheckFilter,
			{
				title: monthHeader.month,
				children: [
					{
						title: "เข้าใหม่",
						dataIndex: "newEmployee",
						key: "newEmployee",
						align: "right",
						sorter: (a, b) => a.newEmployee - b.newEmployee,
					},
					{
						title: "พ้นสภาพ",
						dataIndex: "outEmployee",
						key: "outEmployee",
						align: "right",
						sorter: (a, b) => a.outEmployee - b.outEmployee,
					},
					{
						title: "ย้ายเข้าหน่วยงาน",
						dataIndex: "moveInEmployee",
						key: "moveInEmployee",
						align: "right",
						sorter: (a, b) => a.moveInEmployee - b.moveInEmployee,
					},
					{
						title: "ย้ายออกหน่วยงาน",
						dataIndex: "moveOutEmployee",
						key: "moveOutEmployee",
						align: "right",
						sorter: (a, b) => a.moveOutEmployee - b.moveOutEmployee,
					},
					{
						title: "จำนวนพนักงาน",
						dataIndex: "totalEmployee",
						key: "totalEmployee",
						align: "right",
						sorter: (a, b) => a.totalEmployee - b.totalEmployee,
					},
				],
			},
			{
				title: monthHeader.month_2,
				children: [
					{
						title: "เข้าใหม่",
						dataIndex: "newEmployee_2",
						key: "newEmployee_2",
						align: "right",
						sorter: (a, b) => a.newEmployee_2 - b.newEmployee_2,
					},
					{
						title: "พ้นสภาพ",
						dataIndex: "outEmployee_2",
						key: "outEmployee_2",
						align: "right",
						sorter: (a, b) => a.outEmployee_2 - b.outEmployee_2,
					},
					{
						title: "ย้ายเข้าหน่วยงาน",
						dataIndex: "moveInEmployee_2",
						key: "moveInEmployee_2",
						align: "right",
						sorter: (a, b) => a.moveInEmployee_2 - b.moveInEmployee_2,
					},
					{
						title: "ย้ายออกหน่วยงาน",
						dataIndex: "moveOutEmployee_2",
						key: "moveOutEmployee_2",
						align: "right",
						sorter: (a, b) => a.moveOutEmployee_2 - b.moveOutEmployee_2,
					},
					{
						title: "จำนวนพนักงาน",
						dataIndex: "totalEmployee_2",
						key: "totalEmployee_2",
						align: "right",
						sorter: (a, b) => a.totalEmployee_2 - b.totalEmployee_2,
					},
				],
			},
		];
	};

	const ColumnBenefitNewEmp = [
		{
			title: "รหัสพนักงาน",
			dataIndex: "userId",
			key: "userId",
			align: "left",
			fixed: "left",
			width: 100,
			...getColumnSearchProps("userId", "รหัสพนักงาน"),
		},
		{
			title: "ชื่อพนักงาน",
			dataIndex: "fullname",
			key: "fullname",
			align: "left",
			fixed: "left",
			width: 200,
			...getColumnSearchProps("fullname", "ชื่อพนักงาน"),
		},
		{
			title: "ชื่อเล่น",
			dataIndex: "nickname",
			key: "nickname",
			align: "left",
			fixed: "left",
			width: 100,
			...getColumnSearchProps("nickname", "ชื่อเล่น"),
		},
		{
			title: "หมายเลขบัตรประชาชน",
			dataIndex: "personal_ID",
			key: "personal_ID",
			align: "left",
			width: 200,
			...getColumnSearchProps("personal_ID", "หมายเลขบัตรประชาชน"),
		},
		{
			title: "วันเกิด",
			dataIndex: "brithday",
			key: "brithday",
			align: "left",
			width: 150,
			...getColumnSearchProps("brithday", "วันเกิด"),
		},
		{
			title: "สถานะส่งเอกสาร",
			dataIndex: "statusStamp",
			key: "statusStamp",
			align: "left",
			width: 150,
			render: (text) => {
				let showText = text === "done" ? "ส่งจบแล้ว" : "ยังไม่จบ";
				let typeText = text === "done" ? "success" : "danger";
				return useText({
					text: showText,
					type: typeText,
					moreProps: { strong: true },
				});
			},
		},
		{
			title: "ประกันสุขภาพ/อุบัติเหตุ",
			key: "statusHEA",
			align: "left",
			width: 150,
			render: (record) => {
				const { date_HEA, expACD_HEA } = record;
				let textProps = {};
				if (date_HEA) {
					textProps = {
						type: "success",
						icon: <CheckCircleOutlined />,
						text: ` ${date_HEA}`,
					};
				} else {
					textProps = {
						type: "danger",
						icon: <CloseCircleOutlined />,
						text: ` ${expACD_HEA}`,
					};
				}
				return useText(textProps);
			},
		},
		{
			title: "กองทุนสำรองเลี้ยงชีพ",
			key: "statusPVD",
			align: "left",
			width: 150,
			render: (record) => {
				const { date_PVD, expPVD } = record;
				let textProps = {};
				if (date_PVD) {
					textProps = {
						type: "success",
						icon: <CheckCircleOutlined />,
						text: ` ${date_PVD}`,
					};
				} else {
					textProps = {
						type: "danger",
						icon: <CloseCircleOutlined />,
						text: ` ${expPVD}`,
					};
				}
				return useText(textProps);
			},
		},
		{
			title: "แผนประกันสุขภาพ",
			dataIndex: "planHea",
			key: "planHea",
			align: "left",
			width: 200,
		},
		{
			title: "แผนประกันอุบัติเหตุ",
			dataIndex: "planAcd",
			key: "planAcd",
			align: "left",
			width: 200,
		},
		{
			title: "สถานะพนักงาน",
			dataIndex: "statusWorkerDes",
			key: "statusWorkerDes",
			align: "left",
			width: 200,
			...getColumnSearchProps("statusWorkerDes", "สถานะพนักงาน"),
		},
		{
			title: "สถานะ (ประเมินงาน)",
			dataIndex: "statusMainCodeDes",
			key: "statusMainCodeDes",
			align: "left",
			width: 150,
			...getColumnSearchProps("statusMainCodeDes", "สถานะ (ประเมินงาน)"),
		},
		{
			title: "ชื่อผู้บังคับบัญชา",
			dataIndex: "apprivalName1",
			key: "apprivalName1",
			align: "left",
			width: 200,
			...getColumnSearchProps("apprivalName1", "ชื่อผู้บังคับบัญชา"),
		},
		{
			title: "วันที่เริ่มงาน",
			dataIndex: "startDateJob",
			key: "startDateJob",
			align: "right",
			width: 150,
			//...getColumnSearchProps("startDateJob", "วันที่เริ่มงาน"),
			sorter: (a, b) => moment(a.startDateJob).unix() - moment(b.startDateJob).unix(),
		},
		{
			title: "วันผ่านทดลองงาน",
			dataIndex: "probationEndDate",
			key: "probationEndDate",
			align: "right",
			width: 150,
			//...getColumnSearchProps("probationEndDate", "วันผ่านทดลองงาน"),
			sorter: (a, b) => moment(a.probationEndDate).unix() - moment(b.probationEndDate).unix(),
		},
		{
			title: "บริษัท",
			dataIndex: "company",
			key: "company",
			align: "left",
			width: 100,
			...getColumnSearchProps("company", "บริษัท"),
		},
		{
			title: "Location",
			dataIndex: "Location",
			key: "Location",
			align: "left",
			width: 100,
			...getColumnSearchProps("Location", "Location"),
		},
		// {
		//   title: "แผนก",
		//   dataIndex: "depNo",
		//   key: "depNo",
		//   align: "left",
		//   width: 100,
		//   ...getColumnSearchProps("depNo", "แผนก"),
		// },
		{
			title: "ชื่อแผนก",
			dataIndex: "depName",
			key: "depName",
			align: "left",
			width: 300,
			...getColumnSearchProps("depName", "ชื่อแผนก"),
		},
		// {
		//   title: "รหัสแผนกย่อย",
		//   dataIndex: "subDepNo",
		//   key: "subDepNo",
		//   align: "left",
		//   width: 200,
		//   ...getColumnSearchProps("subDepNo", "รหัสแผนกย่อย"),
		// },
		{
			title: "แผนกย่อย",
			dataIndex: "subDepName",
			key: "subDepName",
			align: "left",
			width: 400,
			...getColumnSearchProps("subDepName", "แผนกย่อย"),
		},
	];

	const ColumnBenefitNewEmpTemp = [
		{
			title: "รหัสพนักงาน",
			dataIndex: "userId",
			key: "userId",
			align: "left",
			fixed: "left",
			width: 100,
			...getColumnSearchProps("userId", "รหัสพนักงาน"),
		},
		{
			title: "ชื่อพนักงาน",
			dataIndex: "fullname",
			key: "fullname",
			align: "left",
			fixed: "left",
			width: 200,
			...getColumnSearchProps("fullname", "ชื่อพนักงาน"),
		},
		{
			title: "ชื่อเล่น",
			dataIndex: "nickname",
			key: "nickname",
			align: "left",
			fixed: "left",
			width: 100,
			...getColumnSearchProps("nickname", "ชื่อเล่น"),
		},
		{
			title: "หมายเลขบัตรประชาชน",
			dataIndex: "personal_ID",
			key: "personal_ID",
			align: "right",
			width: 200,
			...getColumnSearchProps("personal_ID", "หมายเลขบัตรประชาชน"),
		},
		{
			title: "วันเกิด",
			dataIndex: "brithday",
			key: "brithday",
			align: "left",
			width: 150,
			...getColumnSearchProps("brithday", "วันเกิด"),
		},
		{
			title: "สถานะส่งเอกสาร",
			dataIndex: "statusStamp",
			key: "statusStamp",
			align: "left",
			width: 150,
			render: (text) => {
				let showText = text === "done" ? "ส่งจบแล้ว" : "ยังไม่จบ";
				let typeText = text === "done" ? "success" : "danger";
				return useText({
					text: showText,
					type: typeText,
					moreProps: { strong: true },
				});
			},
		},
		{
			title: "ประกันสุขภาพ/อุบัติเหตุ",
			key: "statusHEA",
			align: "left",
			width: 150,
			render: (record) => {
				const { date_HEA, expACD_HEA } = record;
				let textProps = {};
				if (date_HEA) {
					textProps = {
						type: "success",
						icon: <CheckCircleOutlined />,
						text: ` ${date_HEA}`,
					};
				} else {
					textProps = {
						type: "danger",
						icon: <CloseCircleOutlined />,
						text: ` ${expACD_HEA}`,
					};
				}
				return useText(textProps);
			},
		},
		// {
		//   title: "กองทุนสำรองเลี้ยงชีพ",
		//   key: "statusPVD",
		//   align: "left",
		//   width: 150,
		//   render: (record) => {
		//     const { date_PVD, expPVD } = record;
		//     let textProps = {};
		//     if (date_PVD) {
		//       textProps = {
		//         type: "success",
		//         icon: <CheckCircleOutlined />,
		//         text: ` ${date_PVD}`,
		//       };
		//     } else {
		//       textProps = {
		//         type: "danger",
		//         icon: <CloseCircleOutlined />,
		//         text: ` ${expPVD}`,
		//       };
		//     }
		//     return useText(textProps);
		//   },
		// },
		{
			title: "แผนประกันสุขภาพ",
			dataIndex: "planHeaDes",
			key: "planHeaDes",
			align: "left",
			width: 200,
		},
		{
			title: "แผนประกันอุบัติเหตุ",
			dataIndex: "planAcdDes",
			key: "planAcdDes",
			align: "left",
			width: 200,
		},
		{
			title: "วันที่เริ่มงาน",
			dataIndex: "startDateJob",
			key: "startDateJob",
			align: "right",
			width: 150,
			//...getColumnSearchProps("startDateJob", "วันที่เริ่มงาน"),
			sorter: (a, b) => moment(a.startDateJob).unix() - moment(b.startDateJob).unix(),
		},
		{
			title: "วันที่สิ้นสุดสัญญาจ้าง",
			dataIndex: "endContactDate",
			key: "endContactDate",
			align: "right",
			width: 150,
			sorter: (a, b) => moment(a.endContactDate).unix() - moment(b.endContactDate).unix(),
		},
		{
			title: "วันที่ผ่านทดลองงาน",
			dataIndex: "passProbationDate",
			key: "passProbationDate",
			align: "right",
			width: 150,
			sorter: (a, b) => moment(a.passProbationDate).unix() - moment(b.passProbationDate).unix(),
		},
		{
			title: "สถานะ (ประเมินงาน)",
			dataIndex: "statusMainCodeDes",
			key: "statusMainCodeDes",
			align: "left",
			width: 150,
			...getColumnSearchProps("statusMainCodeDes", "สถานะ (ประเมินงาน)"),
		},
		{
			title: "รอบสัญญาจ้าง",
			dataIndex: "roundContract",
			key: "roundContract",
			align: "right",
			width: 150,
			...getColumnSearchProps("roundContract", "รอบสัญญาจ้าง"),
		},
		{
			title: "ชื่อผู้บังคับบัญชา",
			dataIndex: "apprivalName1",
			key: "apprivalName1",
			align: "left",
			width: 200,
			...getColumnSearchProps("apprivalName1", "ชื่อผู้บังคับบัญชา"),
		},
		{
			title: "แผนก/กลุ่ม",
			dataIndex: "group",
			key: "group",
			align: "left",
			width: 150,
		},
		{
			title: "บริษัท",
			dataIndex: "company",
			key: "company",
			align: "left",
			width: 100,
			...getColumnSearchProps("company", "บริษัท"),
		},
		{
			title: "Location",
			dataIndex: "Location",
			key: "Location",
			align: "left",
			width: 100,
			...getColumnSearchProps("Location", "Location"),
		},
		// {
		//   title: "แผนก",
		//   dataIndex: "depNo",
		//   key: "depNo",
		//   align: "left",
		//   width: 100,
		//   ...getColumnSearchProps("depNo", "แผนก"),
		// },
		{
			title: "ชื่อแผนก",
			dataIndex: "depName",
			key: "depName",
			align: "left",
			width: 300,
			...getColumnSearchProps("depName", "ชื่อแผนก"),
		},
		// {
		//   title: "รหัสแผนกย่อย",
		//   dataIndex: "subDepNo",
		//   key: "subDepNo",
		//   align: "left",
		//   width: 200,
		//   ...getColumnSearchProps("subDepNo", "รหัสแผนกย่อย"),
		// },
		{
			title: "แผนกย่อย",
			dataIndex: "subDepName",
			key: "subDepName",
			align: "left",
			width: 400,
			...getColumnSearchProps("subDepName", "แผนกย่อย"),
		},
		{
			title: "ระยะเวลาต่อสัญญา",
			dataIndex: "contractPeriodDes",
			key: "contractPeriodDes",
			align: "left",
			width: 200,
			...getColumnSearchProps("contractPeriodDes", "ระยะเวลาต่อสัญญา"),
		},
	];

	const ColumnBenefitOutEmp = [
		{
			title: "รหัสพนักงาน",
			dataIndex: "userId",
			key: "userId",
			align: "left",
			fixed: "left",
			width: 100,
			...getColumnSearchProps("userId", "รหัสพนักงาน"),
		},
		{
			title: "ชื่อพนักงาน",
			dataIndex: "fullname",
			key: "fullname",
			align: "left",
			fixed: "left",
			width: 200,
			...getColumnSearchProps("fullname", "ชื่อพนักงาน"),
		},
		{
			title: "ชื่อเล่น",
			dataIndex: "nickname",
			key: "nickname",
			align: "left",
			fixed: "left",
			width: 100,
			...getColumnSearchProps("nickname", "ชื่อเล่น"),
		},
		{
			title: "หมายเลขบัตรประชาชน",
			dataIndex: "personal_ID",
			key: "personal_ID",
			align: "right",
			width: 200,
			...getColumnSearchProps("personal_ID", "หมายเลขบัตรประชาชน"),
		},
		{
			title: "วันเกิด",
			dataIndex: "brithday",
			key: "brithday",
			align: "left",
			width: 150,
			...getColumnSearchProps("brithday", "วันเกิด"),
		},
		{
			title: "สถานะส่งเอกสาร",
			dataIndex: "statusStamp",
			key: "statusStamp",
			align: "left",
			width: 150,
			render: (text) => {
				let showText = text === "done" ? "ส่งจบแล้ว" : "ยังไม่จบ";
				let typeText = text === "done" ? "success" : "danger";
				return useText({
					text: showText,
					type: typeText,
					moreProps: { strong: true },
				});
			},
		},
		{
			title: "ประกันสุขภาพ/อุบัติเหตุ",
			key: "statusHEA",
			align: "left",
			width: 150,
			render: (record) => {
				const { dateOut_HEA, exp_HEA } = record;
				let textProps = {};
				if (dateOut_HEA) {
					textProps = {
						type: "success",
						icon: <CheckCircleOutlined />,
						text: ` ${dateOut_HEA}`,
					};
				} else {
					textProps = {
						type: "danger",
						icon: <CloseCircleOutlined />,
						text: ` ${exp_HEA}`,
					};
				}
				return useText(textProps);
			},
		},
		{
			title: "กองทุนสำรองเลี้ยงชีพ",
			key: "statusPVD",
			align: "left",
			width: 150,
			render: (record) => {
				const { dateOut_PVD, exp_PVD } = record;
				let textProps = {};
				if (record.statusWorkerCode !== "Temp") {
					if (dateOut_PVD) {
						textProps = {
							type: "success",
							icon: <CheckCircleOutlined />,
							text: ` ${dateOut_PVD}`,
						};
					} else {
						textProps = {
							type: "danger",
							icon: <CloseCircleOutlined />,
							text: ` ${exp_PVD}`,
						};
					}
					return useText(textProps);
				} else {
					return "-"
				}
			},
		},
		{
			title: "ชื่อผู้บังคับบัญชา",
			dataIndex: "approvalName1",
			key: "approvalName1",
			align: "left",
			width: 200,
			...getColumnSearchProps("approvalName1", "ชื่อผู้บังคับบัญชา"),
		},
		{
			title: "วันที่เริ่มงาน",
			dataIndex: "startDateJob",
			key: "startDateJob",
			align: "right",
			width: 150,
			//...getColumnSearchProps("startDateJob", "วันที่เริ่มงาน"),
			sorter: (a, b) => moment(a.startDateJob).unix() - moment(b.startDateJob).unix(),
		},
		{
			title: "วันสิ้นสุดการทำงาน",
			dataIndex: "endDateJob",
			key: "endDateJob",
			align: "right",
			width: 150,
			//...getColumnSearchProps("endDateJob", "วันสิ้นสุดการทำงาน"),
			sorter: (a, b) => moment(a.endDateJob).unix() - moment(b.endDateJob).unix(),
		},
		{
			title: "บริษัท",
			dataIndex: "company",
			key: "company",
			align: "left",
			width: 100,
			...getColumnSearchProps("company", "บริษัท"),
		},
		{
			title: "Location",
			dataIndex: "Location",
			key: "Location",
			align: "left",
			width: 100,
			...getColumnSearchProps("Location", "Location"),
		},
		// {
		//   title: "แผนก",
		//   dataIndex: "depNo",
		//   key: "depNo",
		//   align: "left",
		//   width: 100,
		//   ...getColumnSearchProps("depNo", "แผนก"),
		// },
		{
			title: "ชื่อแผนก",
			dataIndex: "depName",
			key: "depName",
			align: "left",
			width: 300,
			...getColumnSearchProps("depName", "ชื่อแผนก"),
		},
		// {
		//   title: "รหัสแผนกย่อย",
		//   dataIndex: "subDepNo",
		//   key: "subDepNo",
		//   align: "left",
		//   width: 200,
		//   ...getColumnSearchProps("subDepNo", "รหัสแผนกย่อย"),
		// },
		{
			title: "แผนกย่อย",
			dataIndex: "subDepName",
			key: "subDepName",
			align: "left",
			width: 400,
			...getColumnSearchProps("subDepName", "แผนกย่อย"),
		},
		{
			title: "รอบสัญญา",
			dataIndex: "roundContract",
			key: "roundContract",
			align: "right",
			width: 150,
			...getColumnSearchProps("roundContract", "รอบสัญญา"),
		},
	];

	const ColumnTransferEmployee = [
		{
			title: "บริษัท",
			dataIndex: "company",
			key: "company",
			align: "left",
			width: 100,
			...getColumnSearchProps("company", "บริษัท"),
		},
		{
			title: "รหัสพนักงาน",
			dataIndex: "userId",
			key: "userId",
			align: "left",
			width: 150,
			...getColumnSearchProps("userId", "รหัสพนักงาน"),
		},
		{
			title: "ชื่อพนักงาน",
			dataIndex: "fullname",
			key: "fullname",
			align: "left",
			width: 200,
			...getColumnSearchProps("fullname", "ชื่อพนักงาน"),
		},
		{
			title: "ชื่อเล่น",
			dataIndex: "NickName",
			key: "NickName",
			align: "left",
			width: 100,
			...getColumnSearchProps("NickName", "ชื่อเล่น"),
		},
		{
			title: "แผนก",
			dataIndex: "depName",
			key: "depName",
			align: "left",
			width: 200,
			...getColumnSearchProps("depName", "แผนก"),
		},
		{
			title: "แผนกย่อย",
			dataIndex: "subDepName",
			key: "subDepName",
			align: "left",
			width: 300,
			...getColumnSearchProps("subDepName", "แผนกย่อย"),
		},
		{
			title: "สายอนุมัติ",
			dataIndex: "hrmFlowDes",
			key: "hrmFlowDes",
			align: "left",
			width: 300,
			...getColumnSearchProps("hrmFlowDes", "สายอนุมัติ"),
		},
		{
			title: "สายดูข้อมูล",
			dataIndex: "viewFlowDes",
			key: "viewFlowDes",
			align: "left",
			width: 300,
			...getColumnSearchProps("viewFlowDes", "สายดูข้อมูล"),
		},
		{
			title: "ตำแหน่งงาน",
			dataIndex: "positionName",
			key: "positionName",
			align: "left",
			width: 200,
			...getColumnSearchProps("positionName", "ตำแหน่งงาน"),
		},
		{
			title: "ดูรายการโอนย้าย",
			key: "log",
			align: "left",
			fixed: "right",
			width: 100,
			render: (record) => {
				return (
					<Space size="middle" wrap>
						{useButton({
							call: "view",
							moreProps: { label: "ดู" },
							fn: () => Config.FnShowModal(record),
							isDisable: +record.statusTransfer !== 0 ? true : false,
						})}
					</Space>
				);
			},
		},
		{
			title: "จัดการข้อมูล",
			key: "action",
			align: "left",
			fixed: "right",
			width: 100,
			render: (record) => {
				return (
					<Space size="middle" wrap>
						{useButton({
							call: "normal",
							moreProps: {
								label: "ยกเลิก",
								type: "danger",
							},
							fn: () => Config.FnDelete(record),
							isDisable: +record.statusTransfer !== 0 ? true : false,
						})}
					</Space>
				);
			},
		},
	];

	const ColumnTransferLogCompany = [
		{
			title: "วันที่มีผล",
			dataIndex: "actionDate",
			key: "actionDate",
			align: "right",
			width: 150,
			...getColumnSearchProps("actionDate", "วันที่"),
		},
		{
			title: "บริษัท",
			dataIndex: "company",
			key: "company",
			align: "left",
			width: 150,
			...getColumnSearchProps("company", "บริษัท"),
		},
		{
			title: "บริษัท (ใหม่)",
			dataIndex: "newCompany",
			key: "newCompany",
			align: "left",
			width: 150,
			...getColumnSearchProps("newCompany", "บริษัท"),
		},
		{
			title: "Location",
			dataIndex: "location",
			key: "location",
			align: "left",
			width: 150,
			...getColumnSearchProps("location", "Location"),
		},
		{
			title: "Location (ใหม่)",
			dataIndex: "newLocation",
			key: "newLocation",
			align: "left",
			width: 150,
			...getColumnSearchProps("newLocation", "Location"),
		},
		{
			title: "แผนก",
			dataIndex: "depName",
			key: "depName",
			align: "left",
			width: 200,
			...getColumnSearchProps("depName", "แผนก"),
		},
		{
			title: "แผนก (ใหม่)",
			dataIndex: "newDepName",
			key: "newDepName",
			align: "left",
			width: 200,
			...getColumnSearchProps("newDepName", "แผนก"),
		},
		{
			title: "แผนกย่อย",
			dataIndex: "subDepName",
			key: "subDepName",
			align: "left",
			width: 200,
			...getColumnSearchProps("subDepName", "แผนกย่อย"),
		},
		{
			title: "แผนกย่อย (ใหม่)",
			dataIndex: "newSubDepName",
			key: "newSubDepName",
			align: "left",
			width: 200,
			...getColumnSearchProps("newSubDepName", "แผนกย่อย"),
		},
		{
			title: "สายอนุมัติ",
			dataIndex: "hrmFlowDes",
			key: "hrmFlowDes",
			align: "left",
			width: 200,
			...getColumnSearchProps("hrmFlowDes", "สายอนุมัติ"),
		},
		{
			title: "สายอนุมัติ (ใหม่)",
			dataIndex: "newHrmFlowDes",
			key: "newHrmFlowDes",
			align: "left",
			width: 200,
			...getColumnSearchProps("newHrmFlowDes", "สายอนุมัติ"),
		},
		{
			title: "ตำแหน่งงาน",
			dataIndex: "positionName",
			key: "positionName",
			align: "left",
			width: 200,
			...getColumnSearchProps("positionName", "ตำแหน่งงาน"),
		},
		{
			title: "ตำแหน่งงาน (ใหม่)",
			dataIndex: "newPositionName",
			key: "newPositionName",
			align: "left",
			width: 200,
			...getColumnSearchProps("newPositionName", "ตำแหน่งงาน"),
		},
	];

	const ColumnTransferLogDepartment = [
		{
			title: "วันที่มีผล",
			dataIndex: "actionDate",
			key: "actionDate",
			align: "right",
			width: 150,
			...getColumnSearchProps("actionDate", "วันที่มีผล"),
		},
		{
			title: "แผนก",
			dataIndex: "depName",
			key: "depName",
			align: "left",
			width: 200,
			...getColumnSearchProps("depName", "แผนก"),
		},
		{
			title: "แผนก (ใหม่)",
			dataIndex: "newDepName",
			key: "newDepName",
			align: "left",
			width: 200,
			...getColumnSearchProps("newDepName", "แผนก"),
		},
		{
			title: "แผนกย่อย",
			dataIndex: "subDepName",
			key: "subDepName",
			align: "left",
			width: 300,
			...getColumnSearchProps("subDepName", "แผนกย่อย"),
		},
		{
			title: "แผนกย่อย (ใหม่)",
			dataIndex: "newSubDepName",
			key: "newSubDepName",
			align: "left",
			width: 300,
			...getColumnSearchProps("newSubDepName", "แผนกย่อย"),
		},
	];

	const ColumnTransferLogSubDepartment = [
		{
			title: "วันที่",
			dataIndex: "actionDate",
			key: "actionDate",
			align: "right",
			width: 150,
			...getColumnSearchProps("actionDate", "วันที่"),
		},
		{
			title: "แผนกย่อย",
			dataIndex: "subDepName",
			key: "subDepName",
			align: "left",
			width: 300,
			...getColumnSearchProps("subDepName", "แผนกย่อย"),
		},
		{
			title: "แผนกย่อย (ใหม่)",
			dataIndex: "newSubDepName",
			key: "newSubDepName",
			align: "left",
			width: 300,
			...getColumnSearchProps("newSubDepName", "แผนกย่อย"),
		},
	];

	const ColumnTransferLogApproval = [
		{
			title: "วันที่มีผล",
			dataIndex: "actionDate",
			key: "actionDate",
			align: "right",
			width: 150,
			...getColumnSearchProps("actionDate", "วันที่มีผล"),
		},
		{
			title: "สายอนุมัติ",
			dataIndex: "hrmFlowDes",
			key: "hrmFlowDes",
			align: "left",
			width: 300,
			...getColumnSearchProps("hrmFlowDes", "สายอนุมัติ"),
		},
		{
			title: "สายอนุมัติ (ใหม่)",
			dataIndex: "newHrmFlowDes",
			key: "newHrmFlowDes",
			align: "left",
			width: 300,
			...getColumnSearchProps("newHrmFlowDes", "สายอนุมัติ"),
		},
	];
	const ColumnTransferLogViewFlow = [
		{
			title: "วันที่มีผล",
			dataIndex: "actionDate",
			key: "actionDate",
			align: "right",
			width: 150,
			...getColumnSearchProps("actionDate", "วันที่มีผล"),
		},
		{
			title: "สายการดูข้อมูล",
			dataIndex: "viewFlowDes",
			key: "viewFlowDes",
			align: "left",
			width: 300,
			...getColumnSearchProps("viewFlowDes", "สายการดูข้อมูล"),
		},
		{
			title: "สายการดูข้อมูล (ใหม่)",
			dataIndex: "newViewFlowDes",
			key: "newViewFlowDes",
			align: "left",
			width: 300,
			...getColumnSearchProps("newViewFlowDes", "สายการดูข้อมูล"),
		},
	];

	const ColumnTransferLogPosition = [
		{
			title: "วันที่มีผล",
			dataIndex: "actionDate",
			key: "actionDate",
			align: "right",
			...getColumnSearchProps("actionDate", "วันที่มีผล"),
			width: 150,
		},
		{
			title: "ตำแหน่งงาน",
			dataIndex: "positionName",
			key: "positionName",
			align: "left",
			width: 200,
			...getColumnSearchProps("positionName", "ตำแหน่งงาน"),
		},
		{
			title: "ตำแหน่งงาน (ใหม่)",
			dataIndex: "newPositionName",
			key: "newPositionName",
			align: "left",
			width: 200,
			...getColumnSearchProps("newPositionName", "ตำแหน่งงาน"),
		},
	];
	// ! ###################### SET Column ###################### ! //

	// ? ################# SET Expland Column ################### ? //
	const ColumnKPIs_ExpandableTable = [
		{
			title: "ลำดับตัวชี้วัด",
			dataIndex: "indicatorLine",
			key: "indicatorLine",
			align: "right",
			//width: "10%",
		},
		{
			title: "กลุ่มตัวชี้วัด",
			dataIndex: "indicatorGroup",
			key: "indicatorGroup",
			align: "left",
			width: "10%",
			...getColumnSearchProps("indicatorGroup", "กลุ่มตัวชี้วัด"),
		},
		{
			title: "สัดส่วน",
			dataIndex: "weight",
			key: "weight",
			align: "right",
			width: "10%",
			...getColumnSearchProps("weight", "สัดส่วน"),
		},
		{
			title: "ชื่อตัวชี้วัด",
			dataIndex: "indicatorDes",
			key: "indicatorDes",
			align: "left",
			width: "35%",
			...getColumnSearchProps("indicatorDes", "ชื่อตัวชี้วัด"),
		},

		{
			title: "เกณฑ์",
			dataIndex: "scoreCriteria",
			key: "scoreCriteria",
			align: "left",
			width: "35%",
			...getColumnSearchProps("scoreCriteria", "เกณฑ์"),
			render: (val) => {
				return <Input.TextArea rows={3} value={val} disabled={true} />;
			},
		},
	];

	const ColumnOJT_ExpandableTable = [
		{
			title: "รหัส Training",
			dataIndex: "trainingNo",
			key: "trainingNo",
			align: "left",
			//width: "10%",
			...getColumnSearchProps("trainingNo", "รหัส Training"),
		},
		{
			title: "ประเภท Training",
			dataIndex: "trainingGroup",
			key: "trainingGroup",
			align: "left",
			//width: "10%",
			...getColumnSearchProps("trainingGroup", "ประเภท Training"),
		},
		{
			title: "ชื่อหัวข้อ Training",
			dataIndex: "trainingCourses",
			key: "trainingCourses",
			align: "left",
			//width: "50%",
			...getColumnSearchProps("trainingCourses", "ชื่อหัวข้อ Training"),
		},
		{
			title: "Training Method",
			dataIndex: "trainingMethodDes",
			key: "trainingMethodDes",
			align: "left",
			//width: "10%",
			...getColumnSearchProps("trainingMethodDes", "Training Method"),
		},
		{
			title: "ระดับ JG",
			dataIndex: "levelJG",
			key: "levelJG",
			align: "right",
			//width: "10%",
			sorter: (a, b) => a.levelJG - b.levelJG,
			...getColumnSearchProps("levelJG", "levelJG"),
		},

		{
			title: "ระยะเวลา",
			dataIndex: "timePeriod",
			key: "timePeriod",
			align: "right",
			//width: "10%",
			...getColumnSearchProps("timePeriod", "timePeriod"),
		},
	];

	const Probation_ExpandableTable = [
		{
			title: "บริษัท",
			dataIndex: "company",
			key: "company",
			align: "left",
			...getColumnSearchProps("company", "บริษัท"),
		},
		{
			title: "แผนก",
			dataIndex: "depName",
			key: "depName",
			align: "left",
			...getColumnSearchProps("depName", "แผนก"),
		},
		{
			title: "เกณฑ์ชี้วัด",
			dataIndex: "description",
			key: "description",
			align: "left",
			...getColumnSearchProps("description", "เกณฑ์ชี้วัด"),
		},
	];

	const ColumnChangeShiftWork = [
		{
			title: "บริษัท",
			dataIndex: "company",
			key: "company",
			align: "left",
			width: 150,
			...getColumnSearchProps("company", "บริษัท"),
		},
		{
			title: "กะทำงาน",
			dataIndex: "workShiftName",
			key: "workShiftName",
			align: "left",
			width: 200,
			...getColumnSearchProps("workShiftName", "กะทำงาน"),
		},
		{
			title: "รหัสพนักงาน",
			dataIndex: "userId",
			key: "userId",
			align: "left",
			width: 150,
			...getColumnSearchProps("userId", "รหัสพนักงาน"),
		},
		{
			title: "ชื่อพนักงาน",
			dataIndex: "fullname",
			key: "fullname",
			align: "left",
			width: 200,
			...getColumnSearchProps("fullname", "ชื่อพนักงาน"),
		},
		{
			title: "ชื่อเล่น",
			dataIndex: "NickName",
			key: "NickName",
			align: "left",
			width: 100,
			...getColumnSearchProps("NickName", "ชื่อเล่น"),
		},
		{
			title: "แผนก",
			dataIndex: "depName",
			key: "depName",
			align: "left",
			width: 200,
			...getColumnSearchProps("depName", "แผนก"),
		},
		{
			title: "แผนกย่อย",
			dataIndex: "subDepName",
			key: "subDepName",
			align: "left",
			width: 300,
			...getColumnSearchProps("subDepName", "แผนกย่อย"),
		},
		{
			title: "สายอนุมัติ",
			dataIndex: "hrmFlowDes",
			key: "hrmFlowDes",
			align: "left",
			width: 300,
			...getColumnSearchProps("hrmFlowDes", "สายอนุมัติ"),
		},
		{
			title: "สายดูข้อมูล",
			dataIndex: "viewFlowDes",
			key: "viewFlowDes",
			align: "left",
			width: 300,
			...getColumnSearchProps("viewFlowDes", "สายดูข้อมูล"),
		},
		{
			title: "ตำแหน่งงาน",
			dataIndex: "positionName",
			key: "positionName",
			align: "left",
			width: 200,
			...getColumnSearchProps("positionName", "ตำแหน่งงาน"),
		},
	];
	// ? ################# SET Expland Column ################### ? //

	return {
		ColumnPosition,
		ColumnCountEmployees,
		ColumnSubDepartment,
		Column_Approval,
		ColumnBranchView,
		Column_CustomizeRole,
		ColumnOJT_Course,
		ColumnOJT_Training,
		ColumnOJT_ExpandableTable,
		ColumnKPIs,
		ColumnKPIs_ExpandableTable,
		ColumnSSO,
		ColumnDashboardSSO_NewEmp,
		ColumnDashboardSSO_outEmp,
		ColumnJG_PG,
		Column_Crime,
		ColumnCrimeUpdate,
		ColumnCrimeShowDetail,
		ColumnCreateCrime,
		ColumnCrimeReport,
		ColumnReportCrime,
		Column_Probation,
		Probation_ExpandableTable,
		Column_Report_ALL,
		Column_Report_Lv_Worker,
		Column_ReportEmployeeAge,
		Column_ReportWorkAge,
		Column_ReportStatusWorker,
		Column_ReportEmployeeTransfer,
		Column_ReportEmployeeSummary,
		ColumnBenefitNewEmp,
		ColumnBenefitNewEmpTemp,
		ColumnBenefitOutEmp,
		ColumnTransferEmployee,
		ColumnTransferLogCompany,
		ColumnTransferLogDepartment,
		ColumnTransferLogSubDepartment,
		ColumnTransferLogApproval,
		ColumnTransferLogViewFlow,
		ColumnTransferLogPosition,
		ColumnChangeShiftWork
	};
};
