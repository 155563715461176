import React from 'react'
import SignatureCanvas from './signatureCanvas'
import { colors } from '../../../themes'

export const CompSignature = React.forwardRef((props, refs) => {
    return (
        <div style={{ width: '100%', height: 100, margin: '15px 0px' }} >
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <SignatureCanvas
                    canvasProps={{
                        style: { width: '100%', height: 120, backgroundColor: colors.bgSignature }
                    }}
                   ref={_ref => refs(_ref)} />
            </div>
        </div >

    )
})