import React from "react";
import { Required, RowComponent } from "../../../../styles/global-style";
import { useController } from "react-hook-form";
import { DatePicker as DataPickerAntD, Row } from "antd";
import { TextSmall } from "../../text";
import moment from "moment";

const { Fragment } = React;
const { RangePicker: RangePickerAntD } = DataPickerAntD;

export const DatePicker = ({ control, item, handleChange }) => {
  const {
    ref,
    rules,
    name,
    defaultValue,
    placeholder,
    label,
    picker,
    disabled,
    disabledDate,
    dateFormat,
    itemHandleChange,
  } = item;
  const { field, fieldState } = useController({
    control,
    name,
    rules,
    defaultValue,
  });
  const { error } = fieldState;
  const { value } = field;
  return (
    <Fragment>
      <RowComponent>
        {label && <TextSmall text={label} />}
        {rules && rules.required && <Required>*</Required>}
      </RowComponent>
      <DataPickerAntD
        id={ref}
        name={ref}
        disabledDate={disabledDate}
        disabled={disabled}
        placeholder={placeholder}
        value={value ? moment(value) : ""}
        format={dateFormat || "YYYY-MM-DD"}
        style={{ width: "100%" }}
        picker={"" || picker}
        onChange={(_e, f) => {
          itemHandleChange ? itemHandleChange(f, item) : handleChange(f, item);
        }}
      />
      {error && <TextSmall text={error.message} color="red" />}
    </Fragment>
  );
};

export const RangePicker = ({ control, item, handleChange }) => {
  const {
    ref,
    rules,
    name,
    defaultValue,
    placeholder,
    label,
    picker,
    disabled,
    disabledDate,
    dateFormat,
  } = item;
  const { field, fieldState } = useController({
    control,
    name,
    rules,
    defaultValue,
  });
  const { error } = fieldState;
  const { value } = field;
  return (
    <Fragment>
      <RowComponent>
        {label && <TextSmall text={label} />}
        {rules && rules.required && <Required>*</Required>}
      </RowComponent>
      <RangePickerAntD
        id={ref}
        name={ref}
        placeholder={placeholder}
        disabledDate={disabledDate}
        allowClear={true}
        disabled={disabled}
        value={
          value && value[0] && value[1]
            ? [moment(value[0]), moment(value[1])]
            : ["", ""]
        }
        style={{ width: "100%" }}
        onChange={(_e, f) => {
          handleChange(f, item);
        }}
        picker={"" || picker}
        format={dateFormat || "YYYY-MM-DD"}
      />
      {error && <TextSmall text={error.message} color="red" />}
    </Fragment>
  );
};
