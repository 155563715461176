import React from 'react';
import './index.css';

import { Breadcrumb } from 'antd';


/**
 * @param {*} separator - Default >> "/"
 * @param {*} breadcrumbLists - Todo : [{label: "" , pathname: ""}]
 */

export const LayoutBreadcrumb = ({
    separator,
    breadcrumbLists,
}) => {
    return (
        <Breadcrumb separator={separator} >
            {
                Array.isArray(breadcrumbLists) && breadcrumbLists.map((item, itemIndex) => {
                    return (
                        <Breadcrumb.Item key={itemIndex}  >
                            {item.label}
                        </Breadcrumb.Item>
                    )
                })
            }
        </Breadcrumb>
    )
};