import React, { Component } from "react";
import "../login.css";
import { Layout, Form, Row, Col, Input, Button, Modal, Spin, Rate, Descriptions, Collapse, BackTop, Checkbox } from "antd";
import { GET, GET_DETAIL_TASK_USER_IN_MOBILE, POST } from "../service";
import { LockOutlined, LoginOutlined, UserOutlined } from "@ant-design/icons";
import { PATHNAME } from "../constants/enums/pathname";
import { browserHistory } from "react-router";
import { Label } from "reactstrap";
import { AiFillInfoCircle } from "react-icons/ai";
import BootstrapTable from "react-bootstrap-table-next";
import SupportCom from "../assets/images/info/support-com.png";
import SupportMobile from "../assets/images/info/support-mobile.jpg";
import SupportHelpDesk from "../assets/images/info/support-helpdesk.png";
import ConnectWifi from "../assets/images/info/connect-wifi.png";
import ReqAsset from "../assets/images/info/req-asset.png";
import AnydeskIcon from "../assets/images/info/anydesk.png";
import AnydeskCode from "../assets/images/info/anydesk-code.png";
import AnydeskAccept from "../assets/images/info/anydesk-accept.png";
import Portal from "../assets/images/info/portal.png";
import PortalDisconnect from "../assets/images/info/portal-disconnect.png";
import PortalInternalConnect from "../assets/images/info/portal-internal-connected.png";
import PortalOuterConnect from "../assets/images/info/portal-outer-connected.png";
import PortalOuterDisconnect from "../assets/images/info/portal-outer-disconnect.png";
import SnippingTool from "../assets/images/info/snipping-tool.jpg";
import NewSnippingTool from "../assets/images/info/new-snipping-tool.png";
import QRYoutube from "../assets/images/info/qrCode-youtube.png";

const { Panel } = Collapse;
const md5 = require("md5");
const columns = [
  {
    dataField: "id",
    headerAlign: "center",
    align: "center",
    text: "ลำดับ",
  },

  {
    dataField: "type",
    headerAlign: "center",
    text: "ประเภทสินทรัพย์",
  },
  {
    dataField: "description",
    headerAlign: "center",
    text: "รายการสินทรัพย์",
  },
  {
    dataField: "note",
    headerAlign: "center",
    text: "หมายเหตุ",
  },
];

class Assets_ConfirmationLogin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingScreen: false,
      isLogin: false,
      userId: '',
      message: '',
      isDisabled: false,
      checked: false,
      resultView: [],
      empData: {},
    };
  }

  onFinish = async (value) => {
    try {
      this.setState({ loadingScreen: true });
      const { username, password } = value;
      const enCodePass = md5(password);
      const url = `/HRM_API/cent/verifyLogin`;
      const data = {
        username,
        password: enCodePass,
      };
      const response = await POST(url, data);
      const { result } = response;
      if (result.status == 1) {
        const {
          ID_User,
        } = result;
        this.setState({ userId: ID_User})
        this.setState({ isLogin: true });
      } else {
        this.setState({ loadingScreen: false });
        this.setState({ message: 'ชื่อผู้ใช้หรือรหัสผ่านไม่ถูกต้อง'})
      }
      this.getDataUser();
    } catch (err) {
      Modal.error({
        title: `${err.message}`,
        onOk: () => this.setState({ loadingScreen: false }),
      });
    }
  };

  onSaveFeedback = async (values) => {
    try {
      this.setState({ loadingScreen: true });
      const { rate, suggestion } = values;
      const url = `/HRM_API/cent/asset-feedback`;
      const data = {
        rate,
        suggestion,
        userLogin: this.state.userId
      };
      const response = await POST(url, data);
      const { result } = response;
      if (result.status == 1) {
        Modal.success({
          title: `บันทึกสำเร็จ`,
          width: 300,
          onOk: () => browserHistory.push(PATHNAME.ASSETS_CONFIRM_LOGIN)
        })
      } else {
        Modal.error({
          title: `${result.message}`,
          width: 300,
          onOk: () => this.setState({ loadingScreen: false }),
        });
      }
      this.setState({ isLogin: false });
      this.setState({ loadingScreen: false });
    } catch (err) {
      Modal.error({
        title: `${err.message}`,
        width: 300,
        onOk: () => this.setState({ loadingScreen: false }),
      });
    }
  };

  async getDataUser() {
    const userId = this.state.userId;
    try {
      const res = await GET(GET_DETAIL_TASK_USER_IN_MOBILE(userId));
      const { result } = res;
      let mapResult = [];
      this.setState({ 
        empData: {
          FullName: result.data.fullname,
          SupName: result.data.bossName,
          DepartmentName: result.data.depName,
          StartDateJob: result.data.startDateJob,
          Location: result.data.location,
          Level_Worker: result.data.levelWorker,
          LevelID: result.data.levelId,
          DivisionID: result.data.divisionId,
        }
      })
      mapResult = result.data.line.map((item, key) => ({
        id: key + 1,
        type: item.type,
        description: item.description,
        note: item.note,
      }));
      this.setState({
        resultView: mapResult,
        isDisabled: false
      });
    } catch (error) {
      this.setState({isDisabled: true});
      Modal.error({ title: "เกิดข้อผิดพลาด", content: error.message });
    }
  }

  render() {
    const { loadingScreen, isLogin, userId, message, resultView, empData, isDisabled } = this.state;
    let content;
    if (isLogin && userId) {
      content =  <>{this._renderFeedbackForm(userId, resultView, empData, isDisabled)}</>
    } else {
      content = <Spin tip="กำลังเข้าสู่ระบบ ..." spinning={loadingScreen}>
      <Layout style={{ backgroundColor: "#FFF"}}>
        <Layout.Header
          style={{
            color: "#fff",
            fontSize: "x-large",
            textAlign: "center",
            backgroundColor: "#3368df",
          }}
        >
          Assets Confirmation
        </Layout.Header>
        <br />
            <Descriptions bordered  style={{padding: "10px", textAlign: "center",}}>
                <Descriptions.Item> 
                <AiFillInfoCircle />&nbsp; หากเข้าสู่ระบบครั้งแรก                 
                  <br />
                  <b>ชื่อผู้ใช้งาน (Username)</b> รหัสพนักงาน ตัวอย่าง : <label style={{color: "blue"}}>DPXXXXX, DTXXXXX, ZTXXXXX</label>  etc.
                  <br />
                  <b>รหัสผ่าน (Password)</b> ชื่อตัวเอง 4 ตัวแรกตามด้วย 1234 เช่น ชื่อสมชาย  Password คือ <label style={{color: "blue"}}>somc1234</label> 
                </Descriptions.Item>
            </Descriptions>
            <br />
        <Col span={12}  offset={6} >
        
          <Layout.Content style={{padding: "10px"}}>
            
            <label style={{color: 'red'}}>{message}</label>
            <br />
            {this._renderLoginForm()}      
          </Layout.Content>
          </Col>
      </Layout>
    </Spin>
    }
    return (
      <div>
        {content}
      </div>
    );
  } 

  _renderLoginForm = () => {
    return (
      <Form
        onFinish={this.onFinish}
        labelCol={{
          span: 24,
        }}
        wrapperCol={{
          span: 24,
        }}
      >
        <Row gutter={[16, 16]} justify="start" align="bottom">
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Form.Item
              label="ชื่อผู้ใช้งาน (Username)"
              name="username"
              rules={[
                {
                  required: true,
                  message:
                    "กรุณากรอก ชื่อผู้ใช้งาน (Username) | ตัวอย่าง : DPXXXXX, DTXXXXX, ZTXXXXX etc. |",
                },
              ]}
            >
              <Input
                allowClear
                placeholder={`กรุณากรอก ชื่อผู้ใช้งาน (Username)`}
                prefix={<UserOutlined />}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Form.Item
              label="รหัสผ่าน (Password)"
              name="password"
              rules={[
                { required: true, message: "กรุณากรอก รหัสผ่าน (Password)" },
              ]}
            >
              <Input.Password
                allowClear
                prefix={<LockOutlined />}
                placeholder={`กรุณากรอก รหัสผ่าน (Password)`}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 16]} justify="center" align="bottom">
          <Col xs={24} sm={12} md={8} lg={6} xl={4}>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                style={{ width: "100%" }}
                icon={<LoginOutlined />}
              >
                เข้าสู่ระบบ
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    );
  };

  _renderFeedbackForm = (userId, resultView, empData, isDisabled) => {
    const textWelcome = <>
    ข้อมูลพื้นฐานด้านไอทีของบริษัทดีพลัสอินเตอร์เทรด จำกัด ,บริษัท ดีพลัส ทูเก็ตเตอร์ จำกัด <br />
    <b>1. รหัสพื้นฐานของบริษัทในการใช้งานระบบพื้นฐานขององค์กรมีรายละเอียดดังนี้</b><br />
    <b>ชื่อ - รหัสเข้าคอมพิวเตอร์</b> <br />
          User = ชื่อตัวเอง.นามสกุล 3 ตัว เช่น ชื่อสมชาย นามสกุล ใจดี  user คือ somchai.jai<br />
          Password = ชื่อตัวเอง 4 ตัวแรกโดย 2 ตัวแรกเป็นตัวใหญ่ ตามด้วย 12345 เช่น ชื่อสมชาย  Password คือ SOmc12345<br /><br />
    <b>Email</b><br />
          User = ชื่อตัวเอง.นามสกุล 3 ตัวต่อด้วย <u style={{color: "blue"}}>@dappmaker.co.th</u>  เช่น ชื่อสมชาย นามสกุล ใจดี  user คือ  <u style={{color: "blue"}}>somchai.jai@dappmaker.co.th</u><br />
          Password = ชื่อตัวเอง 4 ตัวแรกโดย 2 ตัวแรกเป็นตัวใหญ่ ตามด้วย 1234 เช่น ชื่อสมชาย  Password คือ SOmc1234<br /><br />

    <b>Web ระบบภายในต่างๆ</b><br />
          URL https://central.zetta-system.com/account/login <br />
          User = ชื่อตัวเอง.นามสกุล 3 ตัว เช่น ชื่อสมชาย นามสกุล ใจดี  user คือ somchai.jai<br />
          Password = ชื่อตัวเอง 4 ตัวแรกตามด้วย 1234 เช่น ชื่อสมชาย  Password คือ somc1234<br />

    <label style={{color: "red"}}>***รบกวนเปลี่ยนรหัสหลังจากการเข้าสู่ระบบครั้งแรก***</label><br />

    <b>ข้อมูลเบื้องต้นเกี่ยวกับรหัส</b><br />
        1. รหัสผ่านควรตั้งอย่างน้อย 8 ตัว<br />
        2. รหัสผ่านต้องประกอบด้วย ภาษาอังกฤษพิมพ์ใหญ่ ,พิมพ์เล็ก ,ตัวอักษร อย่างน้อง 1 ตัว เช่น Wecome1234<br />
        3. กรณีที่เป็นรหัสผ่านที่เข้าคอมพิวเตอร์ และ WIFI บริษัท หากใส่รหัสผ่านผิดเกิน 5 ครั้งระบบจะล็อก และสามารถใช้งานได้อีกครั้งภายใน 30 นาที<br />
    </>
    const textContact = <>
    หากติดปัญหาหรือต้องการข้อมูลเพิ่มเติมสามารถติดต่อได้ที่ <br />
    <b>1. (เข้าจากคอมพิวเตอร์)</b><br />
    <img src={SupportCom} className="img-fluid" alt="SupportCom" />
    <b>2. (เข้าจากมือถือ)</b><br />
    <img src={SupportMobile} className="img-fluid" alt="SupportMobile" />
    <br />
    <img src={SupportHelpDesk} className="img-fluid" alt="SupportHelpDesk" />
    </>
    const textWifi = <>
    <img src={ConnectWifi} className="img-fluid" alt="ConnectWifi" />
    </>
    const textQA = <>
    <b>Q : หากติดปัญหาการใช้งานอุปกรณ์ไอทีต้องทำอย่างไร</b> <br />
    A : ติดต่อสอบถามได้ที่ Helpdesk DIT_ITSupport<br />
  โดยแบ่งเป็น	กรณีที่ไม่สามารถทำงานต่อได้จะเข้าตรวจสอบหน้างานหรือ Anydesk ภายในวันนั้น
  กรณีที่ไม่กระทบกับการทำงานโดยตรงได้รับการแก้ไขภายใน 2 – 7วัน <br /><br />

  <b>Q : หากต้องการอุปกรณ์ไอทีเพิ่มเติม เช่น หน้าจอ ,ลงโปรแกรม ,ปริ้นงาน ต้องทำอย่างไร</b><br />
    A : สามารถแจ้งเข้ามาได้ทางระบบLark Approval แบบฟอร์มขออุปกรณ์และซอฟต์แวร์ไอที 
     <a href="https://applink.larksuite.com/T8D2d6H2jpHg">(https://applink.larksuite.com/T8D2d6H2jpHg)</a>
     <br />
    (มีบางรายการที่ต้องได้รับการอนุมัติจากหัวหน้างานก่อน 
    และถ้าเป็นอุปกรณ์ไอทีที่ต้องจัดซื้อเพิ่มเติมจะใช้เวลาประมาณ 7 – 15 วัน)<br />
    <img src={ReqAsset} className="img-fluid" alt="ReqAsset" />
    <br />
    <b>Q : หากติดปัญหาการใช้งาน Lark ต้องทำอย่างไร</b><br />
    A : ติดต่อสอบถามได้ที่ HR <br /><br />

    <b>Q : Remote (รีโมท) หรือ AnyDesk คืออะไร</b><br />
    A : คือโปรแกรมที่ทางไอทีลงให้ทุกเครื่องสำหรับการแก้ไขปัญหาให้พนักงาน
  โดยหากทางไอทีแจ้งว่าขอ Anydesk ให้ดำเนินการดังนี้<br />

    1. เปิดโปรแกรม  <img src={AnydeskIcon} width={30} alt="AnydeskIcon" /> <br />
    2. ส่งรหัส 9 ตัวให้ทางไอที <br />
    <img src={AnydeskCode} className="img-fluid" alt="AnydeskCode" /><br /><br />
    3. กดรับ Accept <br />
    <img src={AnydeskAccept} className="img-fluid" alt="AnydeskAccept" /> <br /><br />
    <b>Q : ใช้อินเตอร์เน็ตไม่ได้</b><br />
    A : หากใช้งานไม่ได้ให้ตรวจสอบเบื้องต้นดังนี้
  ตรวจสอบการต่อ WIFI DPLUS_WORK อยู่หรือไม่
  ยืนยันตัวตนเรียบร้อยแล้วหรือไม่โดยทำตามขั้นตอนดังนี้<br />
  <img src={ConnectWifi} className="img-fluid" alt="ConnectWifi" /><br /><br />

    <b>Q : ไม่สามารถเชื่อมต่อ VPN ได้</b><br />
    a : Portal ถูกต้องหรือไม่<br />
    <img src={Portal} className="img-fluid" alt="Portal" /><br /><br />
    <img src={PortalDisconnect} className="img-fluid" alt="Portal" /><br /><br />
    <label style={{color: "red"}}>ไม่ได้เชื่อมต่อ</label><br />
    <img src={PortalInternalConnect} className="img-fluid" alt="Portal" /><br /><br />
    <label style={{color: "green"}}>เชื่อมต่ออยู่</label><br />

    i.หากใช้งาน<b>ภายนอกบริษัท</b> Portal คือ Portal.dplus.co.th (เฉพาะผู้มีสิทธิ์)<br />
    b.Username ,Password ถูกต้องหรือไม่โดย Username และ Password จะเป็นชุดเดียวกับที่ Login<br />

      <Row>
        <Col span={{sm : 24}}>
          <img src={PortalOuterConnect} width={300} alt="PortalOuterConnect" /><br />
          <label style={{color: "green"}}>เชื่อมต่ออยู่</label>
        </Col>
        <Col span={{sm : 24}}>
          <img src={PortalOuterDisconnect} width={300} alt="PortalOuterDisconnect" /><br />
          <label style={{color: "red"}}>ไม่ได้เชื่อมต่อ</label>
        </Col>
      </Row>

      <b>Q : มือถือสามารถใช้งานอินเตอร์เน็ตได้หรือไม่</b><br />
      A : สามารถใช้งานได้แต่ต้องต่อ DPLUS_Device และใช้ User ,Password เข้าคอมพิวเตอร์<br /><br />
      
      <b>Q : Error (เออเร่อ) คืออะไร</b><br />
      A : คือโปรแกรมไม่สามารถใช้งานได้ปกติ โดยโปรแกรมจะแจ้งความผิดพลาดให้ทางหน้าจอ<br /><br />

      <b>Q : แคป(Cap)หน้าจอ , PrintScreen คืออะไร</b><br />
      A : คือการถ่ายภาพหน้าจอหรือหยุดหน้าจอ ณ ขณะนั้นๆออกมาเป็นภาพ โดยสามารถทำได้ดังนี้<br />
      <b>วิธีที่ 1</b> 	กดปุ่ม prtsc บริเวณมุมขวา บนของคีย์บอร์ด และกด Crtl + V ในแชท<br />
      <b>วิธีที่ 2 </b>	เปิดโปรแกรม Snipping Tools <br /><br />
      <img src={SnippingTool} className="img-fluid" alt="SnippingTool" /><br /><br />
      Click New และลากคุมพื้นที่ๆต้องการ<br />
      <img src={NewSnippingTool} className="img-fluid" alt="NewSnippingTool" /><br /><br /><br />
      <b>วิธีที่ 3</b> 	ถ่ายรูปหน้าจอจากมือถือ<br /><br />
      <b>Q : ถ้าต้องการโอนสินทรัพย์ไอทีให้คนอื่นต้องทำอย่างไร</b><br />
      A : ดูวิธีการได้จากคลิปใน QR Code หรือ
      <a href="https://www.youtube.com/watch?v=Seg9vmcwjpA&list=PLqXTgAQt34faeI4bSN7BLHhMmFVjtYx1O&index=3&ab_channel=DPLSoftware"> (ลิ้งค์) </a>
      นี้ 
      <br />
      นาทีที่ 0.22 เป็นต้นไป
      <br />
      <img src={QRYoutube} width={150}  alt="QRYoutube" /><br /><br />
    </>

    let chkDisabled = false;
    const onChange = (e) => {
        this.setState({ checked: e.target.checked})
    };
    if (isDisabled || !(this.state.checked)) {
      chkDisabled = true;
    }   
    const btnSave = chkDisabled ? {backgroundColor:'#E1DFDF', color: '#CCC6C6'} : {backgroundColor:'#5CB85C', color: 'white'}

    return (
      <Layout style={{ backgroundColor: "#FFF"}}>
        <Layout.Header
          style={{
            color: "#fff",
            fontSize: "x-large",
            textAlign: "center",
            backgroundColor: "#3368df",
          }}
        >
          แบบประเมินความพึงพอใจ
        </Layout.Header>
        <Col span={{sm : 12 , lg : 24}} offset={{sm : 6 , lg : 12}}>
          <Layout.Content>
              {/* Part1: ข้อมูล Asset */}
              <Descriptions bordered layout="vertical">
                <Descriptions.Item label="ข้อมูลพนักงาน"> 
                      <Label sm ={12}>รหัสพนักงาน: <b>{userId}</b></Label>
                      <Label sm ={12} lg ={6}>ชื่อพนักงาน: <b>{empData.FullName}</b></Label>
                      <Label sm ={12} lg ={6}>ชื่อหัวหน้า: <b>{empData.SupName}</b></Label>
                      <Label sm ={12} lg ={6}>วันที่เริ่มทำงาน: <b>{empData.StartDateJob}</b></Label>
                      <Label sm ={12} lg ={6}>ฝ่าย: <b>{empData.Location}</b></Label>
                      <Label sm ={12} lg ={6}>ระดับ: <b>{empData.Level_Worker}</b></Label>
                      <Label sm ={12} lg ={6}>แผนก: <b>{empData.DepartmentName}</b></Label>
                  <br />
                </Descriptions.Item>
              </Descriptions>
              <BootstrapTable keyField="id" data={resultView} columns={columns} />
              <hr />
              {/* Part2: ข้อมูลทั่วไป */}
              <div style={{textAlign: "center"}}>
                <h4>ยินดีต้อนรับสู่ครอบครัวดีพลัส</h4>
              </div>
              <Collapse defaultActiveKey={['1']}>
              <Panel header="ข้อมูลพื้นฐานด้านไอที" key="1">
                <p>{textWelcome}</p>
              </Panel>
              <Panel header="ช่องทางการติดต่อแผนกไอที" key="2">
                <p>{textContact}</p>
              </Panel>
              <Panel header="วิธีเข้าใช้งาน WIFI" key="3">
                <p>{textWifi}</p>
              </Panel>
              <Panel header="Q & A" key="4">
                <p>{textQA}</p>
              </Panel>
            </Collapse>
              <hr />
              {/* Part3: แบบประเมิน */}
             <Form
              onFinish={this.onSaveFeedback}
              style={{marginTop: '25px', padding: '10px'}}
            >
              <b>🙂 ความพึงพอใจในการใช้บริการครั้งนี้</b><br /><br />
              <Form.Item 
                name="rate" 
                label="ให้คะแนน" 
                rules={[
                  { required: true, message: 'โปรดระบุคะแนน'},
                  { 
                    validator: (_, value) => value != 0 ? Promise.resolve() : Promise.reject(new Error('โปรดระบุคะแนนที่มากกว่า 0'))
                  },
                ]}
              >
                <Rate />
              </Form.Item>
              <Form.Item name="suggestion" label="ข้อเสนอแนะ">
                <Input.TextArea allowClear showCount/>
              </Form.Item>
              <Form.Item  style={{textAlign: "center"}}>
                  <Checkbox onChange={onChange}>ข้าพเจ้าได้รับทรัพย์สิน และอุปกรณ์พร้อมใช้งาน</Checkbox><br /><br />
                  <Button style={btnSave} htmlType="submit" disabled={chkDisabled} >
                    บันทึก
                  </Button>
              </Form.Item>
            </Form>
            <BackTop />
          </Layout.Content>
        </Col>         
      </Layout>

     
    )
  }
}

export default Assets_ConfirmationLogin;
