import React, { useState, useContext, useEffect } from 'react';
import { browserHistory } from "react-router";
import { Spin, Checkbox, Modal } from 'antd';
import { isNil, equals } from 'ramda';

import { LayoutWrapper, LayoutBreadcrumb, LayoutTable } from '../../moduleKPIs/common/layouts'
import { ButtonTheme } from '../../moduleKPIs/common/buttons'

import { PATHNAME } from '../../constants/enums/pathname'
import { TABLE_INSERT_EVAC, TABLE_PREVIEW_REVIEWER } from '../commonsEvac/structuresEvac/tableEvac'
import { BREADCRUMB_INSERT_REVIEWER } from '../commonsEvac/structuresEvac/breadcrumEvac'
import { FIELD_BTN, TEXT_BTN_CUSTOM } from '../commonsEvac/structuresEvac/buttonEvac'
import { ManageEvacContext } from '../servicesEvac/manageEvac/manageEvacProvider'
import { ReportEvacContext } from '../servicesEvac/reportEvac/reportEvacProvider'
import { APIUpdateReviewer } from '../servicesEvac/manageEvac/manageEvacConnect'
import { colors } from '../../themes';


const PageUpdateReviewer = () => {
    const { userLogin, } = useContext(ManageEvacContext);
    const { reportEvacState } = useContext(ReportEvacContext);

    const { reviewerData: { docNo, formId, formDescription, confirmStatus, reviewerLists, year, quarter } } = reportEvacState;
    let defaultReviewer = []
    reviewerLists.filter((val) => val.choose === '1').forEach(({ userId }) => defaultReviewer.push(userId))
    const [reviewers, setReviewers] = useState(defaultReviewer)

    const [statusCF, setStatusCF] = useState(false)
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        if (isNil(year)) {
            return browserHistory.replace(PATHNAME.DASHBOARD_REVIEWER)
        }
    }, [])

    // console.log({ reviewers, reviewerLists })

    async function onSubmit() {
        // console.log({ reviewers })
        let newReviewers = reviewers.map((val) => reviewerLists.filter((each) => equals(each.userId, val))[0])
        // console.log({ newReviewers })
        if (Array.isArray(newReviewers) && newReviewers.length > 0) {
            let dataHeader = {
                docNo: docNo,
                formId: formId,
                year: year,
                quarter: quarter,
                confirmStatus: (statusCF) ? "1" : confirmStatus, //reviewerData.confirmStatus,
                createBy: userLogin.userId
            }
            let dataLine = newReviewers.reduce((acc, cur) => {
                return [...acc, {
                    userId: cur.userId,
                    fullname: cur.name,
                    nickname: cur.nickname,
                    depNo: cur.depNo,
                    subDepNo: cur.subDepNo
                }]
            }, [])
            let dataPreview = newReviewers.reduce((acc, cur) => {
                return [...acc, {
                    userId: cur.userId,
                    fullname: cur.name,
                    nickname: cur.nickname,
                    depName: cur.depName,
                }]
            }, [])

            let payload = { dataHeader, dataLine }

            // console.log(userLogin, { payload })
            await Modal.confirm({
                title: 'คุณต้องการบันทึกข้อมูลใช่หรือไม่ ? ',
                content: <div style={{ padding: '20px 0px' }}>
                    <LayoutTable
                        dataSource={dataPreview}
                        columns={TABLE_PREVIEW_REVIEWER({
                            permission: null,
                            userLogin: userLogin,
                        })}
                        pagination={{
                            pageSize: 20,
                            showTotal: (total) => `ทั้งหมด ${total || 0} รายการ`
                        }}
                    />
                </div>,
                onOk: async () => {
                    await APIUpdateReviewer(payload, async (response) => {
                        if (response) {
                            const { docNo } = response
                            await setLoading(true)
                            await Modal.success({
                                title: 'บันทึกสำเร็จ',
                                content: `เลขที่เอกสาร ${docNo || ''}`,
                                onOk: async () => {
                                    // await getReportEvac({
                                    //     fieldname: REPORT_OPTION.SELETE_REVIEWER_CURRENT,
                                    //     payload: { docNo: docNo }
                                    // })
                                    await browserHistory.push(PATHNAME.DASHBOARD_REVIEWER)
                                }
                            })
                            await setLoading(false)
                        }
                    })
                }

            })
        } else {
            Modal.error({
                title: "กรุณาเลือกพนักงาน"
            })
        }
    }


    const rowSelection = {
        selectedRowKeys: reviewers,
        onChange: async (selectedRowKeys, selectedRows) => {
            await setReviewers(selectedRowKeys)
        },
        onSelect: async (record, selected, selectedRows, nativeEvent) => {
            if (selected === false) {
                await setReviewers([])
            } else {
                await setReviewers(selectedRows)
            }
        }
    };
    return (
        <Spin tip={'กำลังโหลด'} spinning={loading}>
            <LayoutWrapper
                breadcrumb={
                    <div className="layout-breadcrumb-btn-insert">
                        <LayoutBreadcrumb breadcrumbLists={BREADCRUMB_INSERT_REVIEWER()} />
                    </div>
                }
                search={null}
                content={
                    <>
                        <div style={{ fontSize: 16, textAlign: 'center', paddingBottom: 10 }}>
                            {`แบบประเมิน ${formDescription}`}
                        </div>
                        <div style={{ fontSize: 14, textAlign: 'center' }}>
                            {`ปี ${year} ประจำไตรมาส ${quarter}`}
                        </div>
                        <div style={{ fontSize: 14, textAlign: 'left', paddingBottom: 10 }}>
                            {'กรุณาเลือกผู้มีส่วนเกี่ยวข้องที่ต้องการให้ทำแบบสอบถามให้ '}
                        </div>
                        <div className="layout-content-card" >
                            <LayoutTable
                                bordered
                                style={{ padding: 10 }}
                                scroll={{ x: 600 }}
                                rowKey="userId"
                                dataSource={reviewerLists}
                                columns={TABLE_INSERT_EVAC({
                                    userLogin: userLogin,

                                })}
                                pagination={{
                                    pageSize: 500,
                                    showTotal: (total) => `ทั้งหมด ${total || 0} รายการ`
                                }}
                                rowSelection={{
                                    type: 'checkbox',
                                    ...rowSelection
                                }}
                            />
                        </div>
                        <Checkbox
                            disabled={false}
                            style={{ paddingTop: 10 }}
                            checked={statusCF}
                            onChange={() => setStatusCF(!statusCF)}                    >
                            {'ยืนยันการบันทึก (กรณียืนยันแล้ว จะไม่สามารถกลับมาแก้ไขได้)'}
                        </Checkbox>
                        <div className="layout-content-btn-confirm">
                            <ButtonTheme
                                type={(statusCF) ? "primary" : ''}
                                buttonStyle={{ backgroundColor: statusCF && colors.success, color: statusCF && colors.white, border: statusCF && 0 }}
                                style={{ paddingRight: 10, justifyContent: 'center' }}
                                buttonText={TEXT_BTN_CUSTOM({ fieldBtn: FIELD_BTN.SUBMIT_REVIEWER, status: statusCF })}
                                onClick={async () => {
                                    await setLoading(true)
                                    await onSubmit()
                                    await setLoading(false)
                                }}
                            />
                        </div>
                    </>
                }
            />
        </Spin>
    )
}

export default PageUpdateReviewer