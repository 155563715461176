import React from 'react';
import '../index.css';
import "bootstrap/dist/css/bootstrap.css";
import {
    Col,
    Row,
    Button,
    FormGroup,
    Label,
    Container
} from "reactstrap";
import { Modal } from "antd";
import { API_URL } from '../components/connectAPI'
import 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css';
import CsvParse from '@vtex/react-csv-parse';
import { loadingPage } from '../components/loadingPage'
import { getCurrent } from '../extendFunction/getCurrentYears';

var arrData = []


class ImportKPIsFacHistory extends React.Component {

    constructor(props) {
        super(props);
        const { yearsSelect } = getCurrent();
        this.state = {
            upload_open: true,
            showUpload: '',
            arrExcel: '',
            loadData: "",
            done: undefined,
            userLogin: this.props.userLogin,
            yearsTxt: yearsSelect,
            monthTxt: '1'
        };
    }


    handleData = data => {
        arrData = data
        this.setState({ arrExcel: arrData })
    }

    handleOnError = (err, file, inputElem, reason) => {
        console.log(err);
        console.log(file);
    }

    addExcel = () => {
        if (this.state.arrExcel === '' || this.state.arrExcel === undefined) {
            // alert("กรุณาแนบไฟล์")
            Modal.warning({ title: "กรุณาแนบไฟล์" })
        } else {
            var indata = this.state.arrExcel
            //console.log(indata)
            // console.log('data_send', indata)

            if (window.confirm('ยืนยันการเพิ่มข้อมูลหรือไม่')) {
                let url = API_URL + '/HRM_API/kpis/insertKPIsFacHistory'
                fetch(url, {
                    method: "POST",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json;charset=utf-8"
                    },
                    body: JSON.stringify({ data: indata, userLogin: this.state.userLogin })
                })
                    .then(response => response.json())
                    .then((results) => {
                        if (results.success === true) {
                            // alert('Insert Success!!')
                            // this.setState({ modal: false })
                            // window.location.reload()

                            Modal.success({
                                title: "Insert Success!!",
                                onOk: () => {
                                    this.setState({ modal: false });
                                    window.location.reload();
                                }
                            });
                        } else {
                            // alert("Insert Error!!")
                            Modal.error({ title: "Insert Error!!" });
                        }
                    })
                    .catch((error) => {
                        console.log("err", error)
                    })
            }
        }
    }

    showUpload = () => {
        const keys = [
            'year', 'userId', 'fullName', 'nickName', '	depNo', 'subDepNo', '	depName', 'subDepName', 'jobPositionNo', 'jobPositionName', 'approvalNo1',
            'approvalNo2', 'location', 'scoreJan', 'scoreFeb', 'scoreMar', 'scoreApl', 'scoreMay', 'scoreJun', 'scoreJul', 'scoreAug', 'scoreSep', 'scoreOct',
            'scoreNov', 'scoreDec', 'totalAgvScore', 'finalGrade', 'finalPercentage'
        ]
        var Upload = ''

        if (this.state.upload_open === true) {
            Upload = <FormGroup row style={{ marginLeft: '25px', marginBottom: '25px' }}>
                <Label style={{ color: 'red', marginRight: "20px" }}> **รองรับเฉพาะไฟล์ .csv เท่านั้น</Label>
                <CsvParse
                    keys={keys}
                    onDataUploaded={this.handleData}
                    onError={this.handleError}
                    render={onChange => <input type="file" onChange={onChange} accept=".csv" />
                    }
                />
                <Button color="success" size="sm" style={{ marginLeft: '20px' }} onClick={this.addExcel}>Upload</Button>
            </FormGroup>
        }

        this.setState({ showUpload: Upload, upload_open: !this.state.upload_open })
    }

    componentDidMount() {
        //this.selectData();
        this.setState({ done: true })
    }


    render() {

        return (

            <Container>
                {this.state.done ? (
                    <div>
                        <Row>
                            <Col md={6}>
                                <Button color='warning' size='sm' onClick={this.showUpload}>Import Data</Button>
                            </Col>
                            <Col md={6}>
                                {this.state.showUpload}
                            </Col>
                        </Row>
                    </div>) : (<div>{loadingPage} </div>)}

            </Container>

        );

    }
}
export default ImportKPIsFacHistory;
