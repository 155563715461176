import { useState, useEffect } from "react";
import { GET, GET_DASHBOARD_BENEFITS } from "../../../../service";

export const useGetDataSource = () => {
  const [dataSource, setDataSource] = useState([]);
  const [loadingDataSource, setLoadingDataSource] = useState(false);

  useEffect(
    () => onFetchDataSource({ company: "all", empID: "all", cerName: [] }),
    []
  );

  const onFetchDataSource = async (value) => {
    try {
      const company = value.company == "all" ? "" : value.company;
      const empID = value.empID == "all" ? "" : value.empID;
      //const cerName = value.cerName.length == 0 ? "" : value.cerName.join(",");
      const curNameParam =
        value.cerName.length == 0 ? "" : `&docName=${value.cerName.join(",")}`;
      const { createdDate = ["", ""] } = value;
      setLoadingDataSource(true);
      const respond = await GET(
        `${GET_DASHBOARD_BENEFITS}?company=${company}&EmpID=${empID}&createdDateStart=${createdDate[0]}&createdDateEnd=${createdDate[1]}${curNameParam}`
      );
      // CALL API
      setDataSource(() => [
        ...respond.result.data.map((element) => ({
          ...element,
          documentName: element.documentName.split(",").join(", "),
        })),
      ]);
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingDataSource(false);
    }
  };

  return {
    dataSource,
    onFetchDataSource,
    loadingDataSource,
  };
};
