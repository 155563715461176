import { useEffect, useState } from "react";
import {
  GET,
  GET_OPTION_BENEFITS,
  GET_USER_LIST,
} from "../../../../service";

export const useGetOptions = () => {
  const [optionsCompany, setOptionsCompany] = useState([]);
  const [optionsEmp, setOptionsEmp] = useState([]);
  const [loadingOption, setLoadingOption] = useState({
    company: false,
    emp: false,
  });

  useEffect(() => {
    onFetchOptionsCompany();
    onFetchOptionsEmp();
  }, []);

  const onFetchOptionsCompany = async () => {
    try {
      setLoadingOption((prev) => ({ ...prev, company: true }));
      const respond = await GET(GET_OPTION_BENEFITS("company"));
      setOptionsCompany(() => [
        { label: "ทุกบริษัท", value: "all", key: "allCompany" },
        ...respond.result.data.map((e) => ({
          label: e.companyNameTH,
          value: e.companyCode,
          key: e.companyCode,
        })),
      ]);
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingOption((prev) => ({ ...prev, company: false }));
    }
  };

  const onFetchOptionsEmp = async () => {
    try {
      setLoadingOption((prev) => ({ ...prev, emp: true }));
      const respond = await GET(GET_USER_LIST);
      setOptionsEmp([
        { label: "พนักงานทุกคน", value: "all", key: "allEmployee" },
        ...respond.result,
      ]);
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingOption((prev) => ({ ...prev, emp: false }));
    }
  };

  return {
    optionsEmp,
    optionsCompany,
    loadingOption,
  };
};
