import React, { useContext } from 'react';
import '../CSSPersonal.css'

import { KEY_PERSONAL, PersonalContext } from '../servicesPersonal/personalProvider'
import ManagePersonal from '../componentsPersonal/managePersonal';
import { PATHNAME } from '../../constants/enums/pathname';
import { STEPS_PROGRESS_UPDATE } from '../constantsPersonal/updatePersonal';
import { FIELD_EMP } from '../constantsPersonal/contents/employeeInfo';
import { STEPS_PROGRESS_RECHECK } from '../constantsPersonal/recheckPersonal';
import { GET_UserLogin } from '../../screens/functions/UseFunction';

const RecheckPersonal = (props) => {
    const { personalState } = useContext(PersonalContext);
    const { userlogin } = GET_UserLogin()
    return (
        <ManagePersonal
            //refData={{ [FIELD_EMP.EMPLOYEE_ID]: props.params[FIELD_EMP.EMPLOYEE_ID] }}
            refData={{ [FIELD_EMP.EMPLOYEE_ID]: userlogin }}
            initial={{ ...personalState[KEY_PERSONAL.UPDATE_PERSONAL] }}
            pathname={PATHNAME.RECHECK_PERSONAL}
            process={STEPS_PROGRESS_RECHECK} />
    )
}

export default RecheckPersonal

