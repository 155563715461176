import { useButton } from "../../../conponents/buttons/CustomButtonAntd";
import React from "react";
import { filterSearchColumn } from "../../../functions/UseFunction";
import { Form } from "antd";
import { useDatePicker } from "../../../conponents/datepicker/DatePickerAntd";
import moment from "moment";


export const ColumnReportReturn = ({ fnViewData = () => null }) => [
    {
        title: "คำนำหน้า",
        dataIndex: "title",
        key: "title",
        align: "left",
        fixed: "left",
        width: 100,
        ...filterSearchColumn("title", "คำนำหน้า"),
    },
    {
        title: "ชื่อพนักงาน",
        dataIndex: "fullname",
        key: "fullname",
        align: "left",
        fixed: "left",
        width: 200,
        ...filterSearchColumn("fullname", "ชื่อพนักงาน"),
    },
    {
        title: "ชื่อเล่น",
        dataIndex: "nickname",
        key: "nickname",
        align: "left",
        fixed: "left",
        width: 100,
        ...filterSearchColumn("nickname", "ชื่อเล่น"),
    },
    {
        title: "เลขบัตรประชาชน",
        dataIndex: "personalId",
        key: "personalId",
        align: "right",
        fixed: "left",
        width: 200,
        ...filterSearchColumn("personalId", "เลขบัตรประชาชน"),
    },
    {
        title: "กลับมาทำงานใหม่ (ครั้ง)",
        dataIndex: "numReturn",
        key: "numReturn",
        align: "right",
        width: 200,
        ...filterSearchColumn("numReturn", "กลับมาทำงานใหม่ (ครั้ง)"),
    },
    {
        title: "วันที่เริ่มงานครั้งแรก",
        dataIndex: "startDateJob",
        key: "startDateJob",
        align: "right",
        width: 150,
        ...filterSearchColumn("startDateJob", "วันที่เริ่มงานครั้งแรก"),
    },
    {
        title: "บริษัท (ล่าสุด)",
        dataIndex: "company",
        key: "company",
        align: "left",
        width: 150,
        ...filterSearchColumn("company", "บริษัท (ล่าสุด)"),
    },
    {
        title: "สถานที่ (ล่าสุด)",
        dataIndex: "location",
        key: "location",
        align: "left",
        width: 150,
        ...filterSearchColumn("location", "สถานที่ (ล่าสุด)"),
    },
    {
        title: "จัดการข้อมูล",
        key: "action",
        align: "left",
        width: 150,
        render: (record) => {
            return (
                useButton({
                    call: "view",
                    moreProps: { label: "ดูรายละเอียด" },
                    fn: () => fnViewData(record.personalId),
                })
            )
        }
    },
];

export const ColumnReportReturnDetail = [
    {
        title: "รหัสพนักงาน",
        dataIndex: "userId",
        key: "userId",
        align: "left",
        fixed: "left",
        width: 100,
        ...filterSearchColumn("userId", "รหัสพนักงาน"),
    },
    {
        title: "แผนก",
        dataIndex: "depName",
        key: "depName",
        align: "left",
        width: 200,
        ...filterSearchColumn("depName", "แผนก"),
    },
    {
        title: "แผนกย่อย",
        dataIndex: "subDepName",
        key: "subDepName",
        align: "left",
        width: 200,
        ...filterSearchColumn("subDepName", "แผนกย่อย"),
    },
    {
        title: "ตำแหน่ง",
        dataIndex: "positionName",
        key: "positionName",
        align: "left",
        width: 200,
        ...filterSearchColumn("positionName", "ตำแหน่ง"),
    },
    {
        title: "กลุ่มพนักงาน",
        dataIndex: "statusWorkerDes",
        key: "statusWorkerDes",
        align: "left",
        width: 150,
        ...filterSearchColumn("statusWorkerDes", "กลุ่มพนักงาน"),
    },
    {
        title: "วันที่เริ่มงาน",
        dataIndex: "startDateJob",
        key: "startDateJob",
        align: "right",
        width: 150,
        ...filterSearchColumn("startDateJob", "วันที่เริ่มงาน"),
    },
    {
        title: "วันที่ออกจากงาน",
        dataIndex: "endDateJob",
        key: "endDateJob",
        align: "right",
        width: 150,
        ...filterSearchColumn("endDateJob", "วันที่ออกจากงาน"),
    },
    {
        title: "ระยะเวลา (เดือน)",
        dataIndex: "diff",
        key: "diff",
        align: "right",
        width: 150,
        ...filterSearchColumn("diff", "ระยะเวลา (เดือน)"),
    },
];


export const ColumnReportOT = [
    {
        title: "เลขที่เอกสาร",
        dataIndex: "docNo",
        key: "docNo",
        align: "left",
        width: 150,
        ...filterSearchColumn("docNo", "เลขที่เอกสาร"),
    },
    {
        title: "วันที่มีผล",
        dataIndex: "activeDate",
        key: "activeDate",
        align: "right",
        width: 150,
        ...filterSearchColumn("activeDate", "วันที่มีผล"),
    },
    {
        title: "วันที่ขอโอที",
        dataIndex: "startDateTime",
        key: "startDateTime",
        align: "right",
        width: 150,
        ...filterSearchColumn("startDateTime", "วันที่ขอโอที"),
    },
    {
        title: "วันสิ้นสุดโอที",
        dataIndex: "endDateTime",
        key: "endDateTime",
        align: "right",
        width: 150,
        ...filterSearchColumn("endDateTime", "วันสิ้นสุดโอที"),
    },
    {
        title: "รหัสพนักงาน",
        dataIndex: "userId",
        key: "userId",
        align: "left",
        width: 150,
        ...filterSearchColumn("userId", "รหัสพนักงาน"),
    },
    {
        title: "ชื่อ-นามสกุล",
        dataIndex: "Name",
        key: "Name",
        align: "left",
        width: 200,
        ...filterSearchColumn("Name", "ชื่อ-นามสกุล"),
    },
    {
        title: "แผนก",
        dataIndex: "depName",
        key: "depName",
        align: "left",
        width: 150,
        ...filterSearchColumn("depName", "แผนก"),
    },
    {
        title: "แผนกย่อย",
        dataIndex: "subDepName",
        key: "subDepName",
        align: "left",
        width: 300,
        ...filterSearchColumn("subDepName", "แผนกย่อย"),
    },
    {
        title: "หมายเหตุ",
        dataIndex: "remark",
        key: "remark",
        align: "left",
        width: 300,
        ...filterSearchColumn("remark", "หมายเหตุ"),
    },
    {
        title: "จำนวนชั่วโมงที่ขอ",
        dataIndex: "totalHours",
        key: "totalHours",
        align: "right",
        width: 150,
        ...filterSearchColumn("totalHours", "จำนวนชั่วโมงที่ขอ"),
    },
    {
        title: "ผู้อนุมัติอันดับ 1",
        dataIndex: "approvalNo1",
        key: "approvalNo1",
        align: "left",
        width: 150,
        ...filterSearchColumn("approvalNo1", "ผู้อนุมัติอันดับ 1"),
    },
    {
        title: "ผู้อนุมัติอันดับ 2",
        dataIndex: "approvalNo2",
        key: "approvalNo2",
        align: "left",
        width: 150,
        ...filterSearchColumn("approvalNo2", "ผู้อนุมัติอันดับ 2"),
    },
    {
        title: "ผู้อนุมัติอันดับ 3",
        dataIndex: "approvalNo3",
        key: "approvalNo3",
        align: "left",
        width: 150,
        ...filterSearchColumn("approvalNo3", "ผู้อนุมัติอันดับ 3"),
    },
    {
        title: "วันที่อนุมัติ (อันดับ 1)",
        dataIndex: "approvalDate1",
        key: "approvalDate1",
        align: "right",
        width: 200,
        ...filterSearchColumn("approvalDate1", "วันที่อนุมัติ (อันดับ 1)"),
    },
    {
        title: "วันที่อนุมัติ (อันดับ 2)",
        dataIndex: "approvalDate2",
        key: "approvalDate2",
        align: "right",
        width: 200,
        ...filterSearchColumn("approvalDate2", "วันที่อนุมัติ (อันดับ 2)"),
    },
    {
        title: "วันที่อนุมัติ (อันดับ 3)",
        dataIndex: "approvalDate3",
        key: "approvalDate3",
        align: "right",
        width: 200,
        ...filterSearchColumn("approvalDate3", "วันที่อนุมัติ (อันดับ 3)"),
    },
    {
        title: "ผู้บันทึกรายการโอที",
        dataIndex: "createBy",
        key: "createBy",
        align: "left",
        width: 150,
        ...filterSearchColumn("createBy", "ผู้บันทึกรายการโอที"),
    },
    {
        title: "สถานะการยกเลิก",
        dataIndex: "cancelDes",
        key: "cancelDes",
        align: "left",
        width: 200,
        ...filterSearchColumn("cancelDes", "สถานะการยกเลิก"),
    },
    {
        title: "หมายเหตุ (จาก hr)",
        dataIndex: "remarkHr",
        key: "remarkHr",
        align: "left",
        width: 200,
        ...filterSearchColumn("remarkHr", "หมายเหตุ (จาก hr)"),
    },
];

export const ColumnManageOT = [
    {
        title: "เลขที่เอกสาร",
        dataIndex: "docNo",
        key: "docNo",
        align: "left",
        fixed: "left",
        width: 150,
        ...filterSearchColumn("docNo", "เลขที่เอกสาร"),
    },
    {
        title: "สถานะการขอโอที",
        dataIndex: "statusCompDes",
        key: "statusCompDes",
        align: "left",
        fixed: "left",
        width: 150,
        ...filterSearchColumn("statusCompDes", "สถานะการขอโอที"),
        render: (text, record) => {
            return <span style={{ color: record.statusComp === "F" ? "red" : "#000000" }}>{text}</span>
        }
    },
    {
        title: "รหัสพนักงาน",
        dataIndex: "userId",
        key: "userId",
        align: "left",
        fixed: "left",
        width: 150,
        ...filterSearchColumn("userId", "รหัสพนักงาน"),
    },
    {
        title: "ชื่อพนักงาน",
        dataIndex: "fullname",
        key: "fullname",
        align: "left",
        fixed: "left",
        width: 200,
        ...filterSearchColumn("fullname", "ชื่อพนักงาน"),
    },
    {
        title: "แผนกย่อย",
        dataIndex: "subDepName",
        key: "subDepName",
        align: "left",
        width: 300,
        ...filterSearchColumn("subDepName", "แผนกย่อย"),
    },
    {
        title: "วันที่ขาเข้า",
        dataIndex: "dateIn",
        key: "dateIn",
        align: "right",
        width: 150,
        ...filterSearchColumn("dateIn", "วันที่ขาเข้า"),
        render(text, record) {
            return {
                props: {
                    style: { background: text || "#ffcccc" }
                },
                children: <div>{text}</div>
            };
        }
    },
    {
        title: "เวลาเข้า",
        dataIndex: "timeIn",
        key: "timeIn",
        align: "right",
        width: 150,
        ...filterSearchColumn("timeIn", "เวลาเข้า"),
        render(text, record) {
            return {
                props: {
                    style: { background: text || "#ffcccc" }
                },
                children: <div>{text}</div>
            };
        }
    },
    {
        title: "วันที่ขาออก",
        dataIndex: "dateOut",
        key: "dateOut",
        align: "right",
        width: 150,
        ...filterSearchColumn("dateOut", "วันที่ขาออก"),
        render(text) {
            return {
                props: {
                    style: { background: text || "#ffcccc" }
                },
                children: <div>{text}</div>
            };
        }
    },
    {
        title: "เวลาออก",
        dataIndex: "timeOut",
        key: "timeOut",
        align: "right",
        width: 150,
        ...filterSearchColumn("timeOut", "เวลาออก"),
        render(text) {
            return {
                props: {
                    style: { background: text || "#ffcccc" }
                },
                children: <div>{text}</div>
            };
        }
    },
    {
        title: "วันที่มีผล",
        dataIndex: "activeDate",
        key: "activeDate",
        align: "right",
        width: 150,
        ...filterSearchColumn("activeDate", "วันที่มีผล"),
    },
    {
        title: "วันที่ขอโอที",
        dataIndex: "startDateOT",
        key: "startDateOT",
        align: "right",
        width: 150,
        ...filterSearchColumn("startDateOT", "วันที่ขอโอที"),
        render(text, record) {
            return {
                props: {
                    style: { background: record.recheckIn === "T" || "#ffcccc" }
                },
                children: <div>{text}</div>
            };
        }
    },
    {
        title: "โอทีตั้งแต่",
        dataIndex: "startTimeOT",
        key: "startTimeOT",
        align: "right",
        width: 150,
        ...filterSearchColumn("startTimeOT", "โอทีตั้งแต่"),
        render(text, record) {
            return {
                props: {
                    style: { background: record.recheckIn === "T" || "#ffcccc" }
                },
                children: <div>{text}</div>
            };
        }
    },
    {
        title: "วันที่สิ้นสุดโอที",
        dataIndex: "endDateOT",
        key: "endDateOT",
        align: "right",
        width: 150,
        ...filterSearchColumn("endDateOT", "วันที่ขาออก"),
        render(text, record) {
            return {
                props: {
                    style: { background: record.recheckOut === "T" || "#ffcccc" }
                },
                children: <div>{text}</div>
            };
        }
    },
    {
        title: "โอทีถึง",
        dataIndex: "endTimeOT",
        key: "endTimeOT",
        align: "right",
        width: 150,
        ...filterSearchColumn("endTimeOT", "โอทีถึง"),
        render(text, record) {
            return {
                props: {
                    style: { background: record.recheckOut === "T" || "#ffcccc" }
                },
                children: <div>{text}</div>
            };
        }
    },
    {
        title: "จำนวนโอทีขอได้",
        dataIndex: "totalHours",
        key: "totalHours",
        align: "right",
        width: 150,
        sorter: (a, b) => +a.totalHours - +b.totalHours
    },
    {
        title: "ประเภทพนักงาน",
        dataIndex: "userType",
        key: "userType",
        align: "left",
        width: 150,
        ...filterSearchColumn("userType", "ประเภทพนักงาน"),
    },
];

export const ColumnManageOTUpdate = () => {
    return [
        {
            title: "รหัสพนักงาน",
            dataIndex: "userId",
            key: "userId",
            align: "left",
            fixed: "left",
            width: 150,
            ...filterSearchColumn("userId", "รหัสพนักงาน"),
        },
        {
            title: "ชื่อพนักงาน",
            dataIndex: "fullname",
            key: "fullname",
            align: "left",
            fixed: "left",
            width: 200,
            ...filterSearchColumn("fullname", "ชื่อพนักงาน"),
        },
        {
            title: "แผนกย่อย",
            dataIndex: "subDepName",
            key: "subDepName",
            align: "left",
            width: 300,
            ...filterSearchColumn("subDepName", "แผนกย่อย"),
        },
        {
            title: "วันที่มีผล",
            dataIndex: "activeDate",
            key: "activeDate",
            align: "right",
            width: 200,
            render: (text, record) => {
                return (
                    <Form.Item
                        name={`activeDate_${record.key}`}
                        initialValue={text ? moment(text) : undefined}
                    >
                        {useDatePicker({
                            componentProps: {
                                style: { width: "100%" },
                                placeholder: "เลือกวันที่มีผล",
                            },
                            fnChange: (date, dateStr) => console.log(`activeDate_${record.key}`, dateStr)
                        })}
                    </Form.Item>
                )
            },
        },
        {
            title: "วันที่ขาเข้า",
            dataIndex: "startDateOT",
            key: "startDateOT",
            align: "right",
            width: 300,
            render: (text, record) => {
                return (
                    <Form.Item
                        name={`startDateOT_${record.key}`}
                        initialValue={text ? moment(`${record.startDateOT} ${record.startTimeOT}`) : undefined}
                    >
                        {useDatePicker({
                            componentProps: {
                                showTime: true,
                                style: { width: "100%" },
                                placeholder: "เลือกวันที่ขาเข้า",
                            },
                            fnChange: (date, dateStr) => console.log(`startDateOT_${record.key}`, dateStr)
                        })}
                    </Form.Item>
                )
            },
        },
        {
            title: "วันที่ขาออก",
            dataIndex: "endDateOT",
            key: "endDateOT",
            align: "right",
            width: 300,
            render: (text, record) => {
                return (
                    <Form.Item
                        name={`endDateOT_${record.key}`}
                        initialValue={text ? moment(`${record.endDateOT} ${record.endTimeOT}`) : undefined}
                    >
                        {useDatePicker({
                            componentProps: {
                                showTime: true,
                                style: { width: "100%" },
                                placeholder: "เลือกวันที่ขาเข้า",
                            },
                            fnChange: (date, dateStr) => console.log(`endDateOT_${record.key}`, dateStr),
                        })}
                    </Form.Item>
                )
            },
        },
    ];
}
