import React from "react";
import { Image } from "antd";
import logo from "./FilePictures/default_no_picture.png";
import hospital from "./FilePictures/hospital_in_bangkok.png";

export const CustomImage = ({
  imgProps = {
    width: 300,
    src: logo,
    preview: false,
  },
}) => {
  return <Image {...imgProps} />;
};

export const ImagesHospitalList = ({
  imgProps = {
    width: "80%",
    src: hospital,
  },
}) => {
  return <Image {...imgProps} />;
};
