export function validateEmptyLists(_fieldLists, _validateList) {

    /**
     * 
     * @param feldLists - [{ label:'' }]
     * @param validateField - ['label']
     * 
     */
    let result = false
    if (Array.isArray(_fieldLists)) {
        for (let i = 0; i < _fieldLists.length; i++) {
            for (const field of _validateList) {
               if (_fieldLists[i][field] === "" || _fieldLists[i][field] === null || _fieldLists[i][field] === " ") {
                   result = true
                }
            }
        }
    }
    return result
}
