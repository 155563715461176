import React, { Component } from "react";
import { path, update } from "ramda";
import { browserHistory } from 'react-router';
import * as R from "ramda";

import "bootstrap/dist/css/bootstrap.css";
import { Col, Row, Form, Input } from "reactstrap";
import BaseText from "../../constants/text/BaseText";
import { ButtonTheme } from "../../constants/buttons/ButtonTheme";
import * as Layouts from "../../constants/layouts";
import SelectSearch from "../../constants/inputs/SelectSearch";
import { colors } from "../../themes";
import { validateEmptyLists } from "../../constants/functions/emptyLists";
import { convertArray } from "../../constants/functions";
import { APISelectOptionMentor } from "../../moduleTraining/servicesTraining/masterTraining/masterTrainingConnect";
import { INITIAL_PROBATION } from "../../components/probation/initialProbation";
import { PATHNAME } from "../../constants/enums/pathname";
import { KEY_OPTION, KEY_TYPE, TEXT_LOADING } from "../../constants/enums/enumCenter";
import { APISelectOptionStatus } from "../../service/master/masterConnect";
import { APIContractByDocNo, APIInsertProbationContract, APIProbationMaster, APIUpdateProbationContract } from "../servicesContract/contractConnect";
import { convertOptions, convertString } from "../../service/functions/convertOptions";
import FactorPanelContract from "../../components/probation/FactorPanelContract";
import { renderFactor, RenderFactorFacL1, RenderFactorFacL2, RenderFactorFacL3, renderData, renderProbationStatusText, validateBtn, validateGrade } from './ProbationContractContants';
import { POST, POST_INSERT_PROBATION, POST_UPDATE_PROBATION } from './../../service/index';
import { Spin, Modal } from "antd";
import { RenderForm } from './../../constants/inputs/RenderForm';
import encodeString from '../../extendFunction/encodeString';
import RecruitmentAssessment from './../../components/probation/RecruitmentAssessment';
import { GET_UserLogin } from "../../screens/functions/UseFunction";
import moment from "moment";

let detailFac = <span className="text-area"> <b>หมายเหตุ : </b> -  พนักงานที่ผ่านทดลองงานต้องได้คะแนนเฉลี่ยทั้งหมดไม่ต่ำกว่า 2.6 คะแนน และคะแนนส่วนที่ 2 มีคะแนนทุกข้อไม่ต่ำกว่า 3 คะแนน ( 3.7-4.0 = เกินความาดหวังมาก, 3.4-3.6 = เกินความคาดหวัง, 2.6-3.3 = ได้ตามความคาดหวัง , 2.0-2.5 = เกือบได้ตามความคาดหวัง, 0.00-1.99 = ต่ำกว่าความคาดหวัง) <br />
	-  สรุปผลการทดลองงานส่งให้ hr ภายใน 3 วัน หลังจากประเมินครั้งสุดท้าย  <br />
</span>

class InsertProbationContract extends Component {
	constructor(props) {
		super(props);
		const { location: locationLogin } = GET_UserLogin()
		this.state = {
			done: undefined,
			userLogin: this.props.userLogin,
			userLocation: locationLogin,
			latitude: '',
			longitude: '',
			average: [],
			initialData: {},
			optionWorker: [],
			optionContractExtension: [],
			optionProbationGrade: [],
			loadingPage: false,
			statusCF: false,
			recruiterValue: {
				recruiterGrade: '',
				recruiterScore: '',
				recruiter: ''
			}
		};
	}

	componentDidMount() {
		this.getInitialData()
	}

	getInitialData = async () => {
		this.setState({ loadingPage: true })
		let state = JSON.parse(localStorage.getItem(KEY_OPTION.DATA.PROBATION_CONTRACT))
		//console.log('state getInitialData', state)
		if (state) {
			let initialData = {}
			switch (window.location.pathname) {
				case PATHNAME.UPDATE_PROBATION_CONTRACT:
					await APIContractByDocNo({ docNo: state.DocNo }, (res) => {
						initialData = {
							...convertString(res.result),
							ApprovalNo: path(['ApprovalNo'], convertString(res.result)) ? path(['ApprovalNo'], convertString(res.result)).trim() : '',
							BossCode: path(['BossCode'], convertString(res.result)) ? path(['BossCode'], convertString(res.result)).trim() : ''
							//  RoundNow: 1, roundContract: 0 
						}
					})
					initialData = {
						...initialData,
						...state,
						recruiterGrade: initialData.recruiterGrade,
						recruiterScore: initialData.recruiterScore,
						probationGrade: initialData.probationGrade,
					}
					break;
				default:
					if (state.DepNo) {
						initialData = {
							...state,
							...INITIAL_PROBATION,
							StaffCode: state.StaffCode,
							user_login: this.state.userLogin,
							// RoundNow: 2, roundContract: 1
						};
					}
					initialData = {
						...state,
						...initialData
					}

					await APIProbationMaster({
						"position": state.Position_No,
						"company": "",
						"depNo": "",
						"subDepNo": ""
					}).then((res) => {
						if (res) {
							initialData = {
								...initialData,
								[`factor_1`]: res.map(el => { return { name: el.description, score: null, lineNo: 0, isAddedOnWeb: true } })
							}
						}
					}).catch(err => {
						console.log('errrrrrrrrrrrrrr', err)
					})
					break;
			}
			//console.log("initialData", initialData)
			this.setState({
				initialData,
				recruiterValue: {
					...this.state.recruiterValue,
					recruiter: `${initialData.recruiterName || ''} (${initialData.recruiterNickname || ''})`,
					recruiterGrade: `${initialData.recruiterGrade || ''}`,
					recruiterScore: `${initialData.recruiterScore || ''}`,
				},
			})
		} else {
			browserHistory.push(PATHNAME.DASHBOARD_PROBATION_CONTRACT)
		}

		this.getAPIWorker();
	}


	//----- Start :: API -----//

	getAPIWorker = async () => {
		await APISelectOptionMentor({ depNo: '' }, (res) => {
			if (res) {
				let optionWorker = res.result.map(el => {
					let nameWoker = (el.NickName) ? `${el.Name}${' ( '}${el.NickName}${' )'}` : `${el.Name}`
					return { label: nameWoker, value: nameWoker, key: el.ID_User }
				})
				this.setState({ optionWorker })
			}
		})
		await APISelectOptionStatus({ type: 'contractExtension' }, (res) => {
			if (res) {
				// console.log('contractExtension', res)
				// optionContractExtension = servicesOptions(KEY_OPTION.OPTION_STATUS, res, true, 'โปรดระบุ')
				let optionContractExtension = convertOptions({
					key: KEY_OPTION.OPTION_STATUS,
					type: 'required',
					options: res
				})
				this.setState({ optionContractExtension })
			}
		})
		await APISelectOptionStatus({ type: 'grade' }, (res) => {
			if (res) {
				// console.log('contractExtension', res)
				let optionProbationGrade = convertOptions({
					key: KEY_OPTION.OPTION_STATUS,
					type: 'required',
					options: res
				})
				this.setState({ optionProbationGrade })
			}
		})
		this.setState({ loadingPage: false })
	}

	//----- End :: API -----//

	renderAssesseeInfo = () => (
		<Form>
			{renderData({
				objValue: this.state.initialData,
				optionWorker: this.state.optionWorker,
				handleChange: this.handleChange
			}).map((row, i) => (
				<Row key={i}>
					{row.map((each, j) => (
						<Col key={j} md={6} style={{ display: "flex", padding: "5px 15px" }} >
							{this.renderContentInput(each)}
						</Col>
					))}
				</Row>
			))}
		</Form>
	);

	calculateAvg = (fieldname, update, indexScore) => {
		let initialData = { ...this.state.initialData }
		const { factor_1, factor_2, factor_3, factor_4, factor_5, factor_6, factor_7, factor_8, factor_9, factor_10, factor_11 } = initialData
		let Fac1 = [...factor_1]
		let Fac2 = [
			...factor_2, ...factor_3, ...factor_4, ...factor_5, ...factor_6,
			...factor_7, ...factor_8, ...factor_9, ...factor_10, ...factor_11
		]
		let avg = 0
		let avg1 = 0
		let avg2 = 0
		let resultScore = { factor_1: [], factor_2: [] }

		if (update) {
			Fac1.forEach(({ score, name }) => ((name && score) && score[indexScore]) && resultScore.factor_1.push(isNaN(score[indexScore]) ? 0 : score[indexScore]))
			Fac2.forEach(({ score }) => (score && score[indexScore]) && resultScore.factor_2.push(isNaN(score[indexScore]) ? 0 : score[indexScore]))
			avg1 = resultScore.factor_1.length > 0 && (R.sum(resultScore.factor_1) / resultScore.factor_1.length * 60 / 100)
			avg2 = resultScore.factor_2.length > 0 && (R.sum(resultScore.factor_2) / resultScore.factor_2.length * 40 / 100)
			avg = avg1 + avg2
		} else {
			Fac1.forEach(({ score, name }) => (name && score) && resultScore.factor_1.push(isNaN(score) ? 0 : score))
			Fac2.forEach(({ score }) => score && resultScore.factor_2.push(isNaN(score) ? 0 : score))
			avg1 = resultScore.factor_1.length > 0 && (R.sum(resultScore.factor_1) / resultScore.factor_1.length * 60 / 100)
			avg2 = resultScore.factor_2.length > 0 && (R.sum(resultScore.factor_2) / resultScore.factor_2.length * 40 / 100)
			avg = avg1 + avg2
		}
		initialData = { ...initialData, [fieldname]: avg }

		this.setState({ initialData })
	}

	renderButtonConfirm = () => {
		const { statusCF, initialData } = this.state;

		return <ButtonTheme
			//ถ้าสถานะ ผ่านทดลองงานแล้วหรือไม่ผ่านทดลองงานต้องกรอกเกรดก่อนถึงจะเปิดให้บันทึก และ ถ้าสถานะอื่นๆต้องกรอกเหตุผลก่อนถึงจะบันทึกได้
			disabled={!initialData.mentorCode || initialData.RoundNow > 6}
			color={(statusCF) ? colors.buttonConfirm : colors.buttonDraft}
			label={(statusCF) ? "ยืนยันการประเมิน (กรณียืนยันแล้ว จะไม่สามารถกลับมาแก้ไขได้)" : "บันทึกแบบร่าง"}
			style={{ textAlign: 'center' }}
			onClick={() => this.onSave()} />
	}

	renderCheckbok = () => {
		const { userLogin, statusCF, initialData } = this.state;
		const { ApprovalNo, BossCode, sameApprover, RoundNow, workplace, statusWorkerCode } = initialData; // BossCode LV 1 ,ApprovalNo LV2 ;
		let LV_1 = (userLogin === BossCode)
		let LV_2 = (userLogin === ApprovalNo)
		let ApproverY = (sameApprover === 'Y')
		let layoutCheckbox = () => (
			<div className="ui checkbox">
				<Input
					// disabled={validateBtn(initialData)}
					type="checkbox"
					name="CF_Send"
					id="CF_Send"
					checked={statusCF}
					onChange={(e) => { this.setState({ statusCF: +!this.state.statusCF }) }} />

				<label> ยืนยันการประเมิน <b>(กรณียืนยันแล้ว จะไม่สามารถกลับมาแก้ไขได้)</b></label>
			</div>
		)

		if (workplace === 'FAC') {
			if (statusWorkerCode === 'Daily') {
				//พนักงานรายวัน
				if (ApproverY || LV_1 || LV_2) {
					return layoutCheckbox()
				}
			} else {
				//พนักงานรายเดือนand สัญญาจ้าง
				if ((RoundNow < 3 && (LV_1 || LV_2)) || LV_2) {
					return layoutCheckbox()
				}
				// else if (LV_2) {
				// 	return layoutCheckbox()
				// }
			}
		} else {
			//Office
			if (ApproverY || LV_2) {
				return layoutCheckbox()
			}
		}
	}

	onRemoveItem = async (mainRound, indexRound, calAvg) => {
		const { initialData } = this.state
		let prepareObj = {
			...initialData,
			[`${"factor_"}${mainRound}`]: R.remove(indexRound, 1, path([`${"factor_"}${mainRound}`], initialData))
		}
		this.setState({ initialData: prepareObj }, () => {
			if (calAvg) {
				const { fieldname, indexScore } = calAvg
				this.calculateAvg(fieldname, initialData.DocNo && true, indexScore)
			}
		})
	}

	onAddItem = async (indexRound) => {
		let initialData = { ...this.state.initialData }
		let factorIndex = path([`${"factor_"}${indexRound}`], initialData);
		let scoreValue = initialData.DocNo ? Object.assign(new Array(6).fill('')) : null
		let prepareObj = {
			...initialData,
			[`${"factor_"}${indexRound}`]: [...factorIndex, { name: null, score: scoreValue, lineNo: 0, isAddedOnWeb: true }]
		}
		this.setState({ initialData: prepareObj })
	}

	onChangeGradeHr = (fieldName, value) => {
		//console.log('onChangeGradeHr fieldName, value', fieldName, '*****', value)
		const { recruiterValue } = this.state
		this.setState({ recruiterValue: { ...recruiterValue, [fieldName]: value } })
	}


	renderProbationFactor = () => {
		const { initialData, optionProbationGrade, optionContractExtension } = this.state
console.log({initialData, optionProbationGrade, optionContractExtension});

console.log('initialData.Location',initialData.Location,initialData.workplace);
		let template
		let paramsFunction = {
			initialData,
			handleChange: this.handleChange,
			optionContractExtension,
			optionProbationGrade,
			onRemoveItem: this.onRemoveItem,
			onAddItem: this.onAddItem
		}

		if (initialData.Location == 'FAC' || initialData.workplace == 'FAC') {
			let jg = encodeString.decode(initialData.JG)
			let mapFunction = []
			if (jg <= 2) {
				mapFunction = RenderFactorFacL1(paramsFunction)
			} else if (jg > 2 && jg < 6) {
				mapFunction = RenderFactorFacL2(paramsFunction)
			} else {
				mapFunction = RenderFactorFacL3(paramsFunction)
			}

			template = mapFunction.map((each, i) => (
				<FactorPanelContract key={i}
					checkGrade={validateGrade(initialData)}
					handleChange={this.handleChange}
					updateFactor={this.updateFactor}
					// calculateAvg={() => console.log('')}
					initialData={initialData}
					calculateAvg={this.calculateAvg}
					{...each} />)
			)
		} else {
			template = renderFactor(paramsFunction).map((each, i) => (
				<FactorPanelContract key={i}
					checkGrade={validateGrade(initialData)}
					handleChange={this.handleChange}
					updateFactor={this.updateFactor}
					calculateAvg={() => console.log('')}
					initialData={initialData}
					{...each} />
			))

		}

		console.log({template});

		return (
			<React.Fragment>
				<div style={{ fontSize: 12, color: 'red' }}>{'การประเมินใส่คะแนนได้ 1 - 4 คะแนนเท่านั้น  ( 4=ดีมาก , 3=ดี , 2=พอใช้ , 1=ปรับปรุง )'}</div>
				{template}
				{
					((+this.state.initialData.probationStatus == 1 || +this.state.initialData.probationStatus == 2) && this.state.userLocation !== "FAC") &&
					<div style={{ padding: '20px' }}>
						<RecruitmentAssessment
							defaultValue={this.state.recruiterValue}
							onChangeMain={this.onChangeGradeHr}
						/>
					</div>
				}
				<Row>
					<Col md={12} className="text-center">
						<div className="ui checkbox">
							{this.renderCheckbok()}
						</div>
					</Col>
				</Row>
				<Row>
					<Col md={12} className="text-center">
						{this.renderButtonConfirm()}
					</Col>

				</Row>
			</React.Fragment>
		)
	};

	renderContentInput = (each) => {
		const { initialData } = this.state
		const { comp, name, type, value, options, title, onChange } = each
		if (comp == "selectSearch") {
			return (
				<div style={{ display: 'flex', alignItems: 'center' }}>
					<BaseText padding={"0px 10px 0px 0px"} label={title} />
					{
						initialData && !initialData.DocNo ?
							RenderForm({
								width: 200,
								style: { margin: 0 },
								name: name,
								type: type,
								value: value,
								showArrow: false,
								options: options,
								onChange: onChange,
								comp: 'SelectSearch'
							}) :
							RenderForm({
								padding: "0px 10px 0px 0px",
								label: value,
								comp: 'TextDisplay'
							})
					}
				</div>
			)
		} else {
			return (
				<React.Fragment>
					<BaseText padding={"0px 10px 0px 0px"} label={title} />
					<BaseText label={value} />
				</React.Fragment>
			)
		}
	}
	//----- Start :: Function -----//

	updateFactor = (fieldName, value, indexRound, mainRound, calAvg) => {
		let initialData = { ...this.state.initialData }
		const { RoundNow, DocNo } = initialData;
		let updatingField = {}
		let fieldGroup = `${"factor_"}${mainRound}`;

		switch (RoundNow) {
			case 1:
				if (fieldName == 'score') {
					let scoreValue = null
					scoreValue = parseFloat(value) > 4 ? 4 : parseFloat(value) < 1 ? 1 : parseFloat(value)
					updatingField = {
						[fieldName]: DocNo ?
							update(RoundNow - 1, scoreValue, initialData[fieldGroup][indexRound][fieldName])
							: scoreValue
					}

				} else {
					let thisFieldName = initialData[fieldGroup][indexRound][fieldName]
					thisFieldName = value
					updatingField = {
						[fieldName]: DocNo ?
							thisFieldName
							: value
					}
				}
				break;
			case 2:
			case 3:
			case 4:
			case 5:
			case 6:
				if (fieldName == 'score') {
					let scoreValue = null
					scoreValue = parseFloat(value) > 4 ? 4 : parseFloat(value) < 1 ? 1 : parseFloat(value)
					updatingField = {
						[fieldName]: DocNo ?
							update(RoundNow - 1, scoreValue, initialData[fieldGroup][indexRound][fieldName])
							: scoreValue
					}
				} else {
					let thisFieldIndex = initialData[fieldGroup][indexRound][fieldName]
					thisFieldIndex = value
					updatingField = {
						[fieldName]: thisFieldIndex,
					}
				}
				break;
			default:
				if (fieldName == 'score') {
					let scoreValue = null
					scoreValue = parseFloat(value) > 4 ? 4 : parseFloat(value) < 1 ? 1 : parseFloat(value)
					updatingField = {
						[fieldName]: DocNo ?
							update(RoundNow - 1, scoreValue, initialData[fieldGroup][indexRound][fieldName])
							: scoreValue
					}
				} else {
					updatingField = {
						[fieldName]: value
					}
				}
				break;
		}
		let initialDataPrepare = {
			...initialData,
			[fieldGroup]: update(indexRound, {
				...initialData[fieldGroup][indexRound],
				...updatingField,
			}, initialData[fieldGroup])
		}

		this.setState({ initialData: initialDataPrepare }, () => {
			if (calAvg) {
				const { fieldname, indexScore } = calAvg
				this.calculateAvg(fieldname, initialData.DocNo && true, indexScore)
			}
			if (fieldName == 'name') {
				if ((initialData.Location === "FAC" || initialData.workplace === "FAC")) {
					this.calculateAvg(`average_${RoundNow}`, initialData.DocNo && true, RoundNow - 1)
				}
			}
		})
		// console.warn('SUCCESS :', initialData)
	}

	handleChange = (fieldname, value) => {
		let initialData = { ...this.state.initialData }
		switch (fieldname) {
			case "mentor":
				initialData = { ...initialData, ...value }
				break;
			case "status_confirm":
				initialData = {
					...initialData,
					status_confirm: (value === true) ? '1' : '0'
				}
				break;
			case "probationStatus":
				initialData = { ...initialData, [fieldname]: value, probationStatus_Des: renderProbationStatusText(value) }
				break;
			default:
				initialData = { ...initialData, [fieldname]: value }
				break;
		}
		this.setState({ initialData })
		// console.log(initialData)
	}

	hasScoreAtLeastOneRow = () => {
		let numFactor = 12
		const { initialData } = this.state
		let isNotAllEmptyScore = false
		for (let i = 1; i <= numFactor; i++) {
			if (initialData.DocNo) {
				initialData[`factor_${i}`].forEach(el => {
					if (i == 1 && (el.name && el.score[initialData.RoundNow - 1])) {

						isNotAllEmptyScore = true
					} else if (i != 1 && el.score[initialData.RoundNow - 1]) {
						isNotAllEmptyScore = true
					}
				})
			} else {
				initialData[`factor_${i}`].forEach(el => {
					if (i == 1 && (el.name && el.score)) {
						isNotAllEmptyScore = true
					} else if (i != 1 && el.score) {
						isNotAllEmptyScore = true
					}
				})
			}

			if (isNotAllEmptyScore) {
				break;
			}
		}
		return isNotAllEmptyScore
	}

	onSave = async () => {
		try {
			this.setState({ loadingPage: true })
			const { statusCF, recruiterValue } = this.state;
			let initialData = { ...this.state.initialData }
			if ((initialData.Location === "FAC" || initialData.workplace === "FAC") && validateEmptyLists(initialData.factor_1, ['name'])) {
				initialData.factor_1 = convertArray('name', 'object', initialData.factor_1)
			}
			initialData.status_confirm = statusCF ? 1 : 0
			const { RoundNow, contractExtension, probationStatus, probationGrade, remarkResult, ApprovalNo } = initialData;

			if (!this.hasScoreAtLeastOneRow()) {
				return Modal.warning({ title: 'กรุณากรอกคะแนนประเมินอย่างน้อย 1 หัวข้อ' })
			}

			if (initialData.DocNo) {
				initialData = {
					...initialData,
					contractExtension: initialData.contractExtension,
					user_login: this.state.userLogin,
					recruiterGrade: recruiterValue.recruiterGrade,
					recruiterScore: recruiterValue.recruiterScore,
					probationEndDate: initialData.probationEndDate ? moment(initialData.probationEndDate).format('YYYY-MM-DD') : moment(new Date()).format('YYYY-MM-DD')
				}

				switch (+RoundNow) {
					case 2:
					case 3:
						if (+probationStatus === 1 && statusCF) {
							if ((!probationGrade || !contractExtension)) {
								return Modal.warning({ title: 'กรุณาสรุปผลการประเมินเกรดและลักษณะการต่อสัญญาจ้าง เพื่อประเมินทดลองงาน' })
							}
							else if ((!recruiterValue.recruiterGrade || !recruiterValue.recruiterScore) && this.state.userLocation !== "FAC") {
								return Modal.warning({ title: 'กรุณาสรุปผลการประเมิน เพื่อพัฒนาการสรรหา' })
							}
						} else if (+probationStatus === 2 && statusCF) {
							if (!probationGrade) {
								return Modal.warning({ title: 'กรุณาสรุปผลการประเมินเกรด เพื่อประเมินทดลองงาน' })
							}
							else if ((!recruiterValue.recruiterGrade || !recruiterValue.recruiterScore) && this.state.userLocation !== "FAC") {
								return Modal.warning({ title: 'กรุณาสรุปผลการประเมิน เพื่อพัฒนาการสรรหา' })
							}
						}
						else if ((!remarkResult && (+probationStatus === 3)) && statusCF) {
							return Modal.warning({ title: 'กรุณาให้เหตุผลในการต่อช่วงทดลองงาน' })
						}
						else if (+probationStatus === 0 && +RoundNow === 3 && statusCF) {
							return Modal.confirm({
								title: `คุณต้องการประเมินทดลองงานรอบที่ 4 ใช่หรือไม่ ? `,
								okText: `ใช่`,
								cancelText: `ไม่ใช่`,
								onOk: () => {
									// TODO : ต้องการประเมินทดลองงานรอบที่ 4 
									// initialDataTemp.status_confirm = 0 //ส่ง 0 เพระาไม่ยืนยัน
									//ส่่ง status_confirm = 0
									Modal.warning({ title: `กรุณาเลือก "สรุปผลการประเมิน" เป็น อื่นๆ(ต่อช่วงทดลอง)"` })

								},
								onCancel: () => {
									// TODO : ต้องการประเมินทดลองงานรอบที่ 3 ให้จบ 
									// initialDataTemp.status_confirm = 1 //ส่ง 1 เพระายืนยัน

									//ส่่ง status_confirm = 1
									Modal.warning({ title: `กรุณาเลือก "สรุปผลการประเมิน" และ "สรุปผลการประเมินเกรด"` })
								}

							})
						}
						break;
					case 4:
					case 5:
						if (+probationStatus === 1 && statusCF) {
							if ((!probationGrade || !contractExtension)) {
								return Modal.warning({ title: 'กรุณาสรุปผลการประเมินเกรดและลักษณะการต่อสัญญาจ้าง เพื่อประเมินทดลองงาน' })
							}
							else if ((!recruiterValue.recruiterGrade || !recruiterValue.recruiterScore) && this.state.userLocation !== "FAC") {
								return Modal.warning({ title: 'กรุณาสรุปผลการประเมิน เพื่อพัฒนาการสรรหา' })
							}
						} else if (+probationStatus === 2 && statusCF) {
							if (!probationGrade) {
								return Modal.warning({ title: 'กรุณาสรุปผลการประเมินเกรด เพื่อประเมินทดลองงาน' })
							}
							else if ((!recruiterValue.recruiterGrade || !recruiterValue.recruiterScore) && this.state.userLocation !== "FAC") {
								return Modal.warning({ title: 'กรุณาสรุปผลการประเมิน เพื่อพัฒนาการสรรหา' })
							}
						}
						else if ((!remarkResult && (+probationStatus === 3)) && statusCF) {
							return Modal.warning({ title: 'กรุณาให้เหตุผลในการต่อช่วงทดลองงาน' })
						}
						break;
					case 6:
						if (+probationStatus === 1 && statusCF) {
							if ((!probationGrade || !contractExtension)) {
								return Modal.warning({ title: 'กรุณาสรุปผลการประเมินเกรดและลักษณะการต่อสัญญาจ้าง เพื่อประเมินทดลองงาน' })
							}
							else if ((!recruiterValue.recruiterGrade || !recruiterValue.recruiterScore) && this.state.userLocation !== "FAC") {
								return Modal.warning({ title: 'กรุณาสรุปผลการประเมิน เพื่อพัฒนาการสรรหา' })
							}
						} else if (+probationStatus === 2 && statusCF) {
							if (!probationGrade) {
								return Modal.warning({ title: 'กรุณาสรุปผลการประเมินเกรด เพื่อประเมินทดลองงาน' })
							}
							else if ((!recruiterValue.recruiterGrade || !recruiterValue.recruiterScore) && this.state.userLocation !== "FAC") {
								return Modal.warning({ title: 'กรุณาสรุปผลการประเมิน เพื่อพัฒนาการสรรหา' })
							}
						}
						else if (statusCF && !(+probationStatus === 1 || +probationStatus === 2)) {
							//TODO : ประเมินให้ผ่านทดลองงานหรือไม่ผ่านทดลองงาน และไม่ย
							return Modal.warning({ title: 'กรุณาสรุปผลการประเมินเกรด เพื่อประเมินทดลองงาน' })
						}
						break;
				}

				//console.log('Update', initialData)
				let res = await POST(POST_UPDATE_PROBATION, initialData)
				const { success, result } = res
				if (success) {
					Modal.success({
						title: `แก้ไขสำเร็จ เลขที่เอกสาร ${result || ''}`,
						//onOk: () => browserHistory.push(PATHNAME.DASHBOARD_PROBATION_CONTRACT)
						onOk:()=>window.close()
					})
					this.setState({ initialData: { ...this.state.initialData, DocNo: result } })
				}
			} else {
				// console.log('Insert', initialData)
				let res = await POST(POST_INSERT_PROBATION, initialData)
				const { success, result } = res
				if (success) {
					Modal.success({
						title: `บันทึกสำเร็จ เลขที่เอกสาร ${result || ''}`,
						onOk:()=>window.close()
						//onOk: () => browserHistory.push(PATHNAME.DASHBOARD_PROBATION_CONTRACT)
					})
					this.setState({ initialData: { ...this.state.initialData, DocNo: result } })
				}
			}
		} catch (error) {
			//console.log('error', { error })
			Modal.error({
				title: `บันทึกไม่สำเร็จ ${error.message || ''}`,
				//onOk:()=>window.close()
				onOk: () => browserHistory.push(PATHNAME.DASHBOARD_PROBATION_CONTRACT)
			})
		} finally {
			this.setState({ loadingPage: false })
		}
	}


	render() {
		// console.log({ initialData, optionContractExtension })
		//ถ้าผลการประเมิน เท่ากับ  ผ่านทดลองงานแล้ว หรือ ไม่ผ่านทดลองงานแล้ว หรือ อื่นๆ
		return (
			<React.Fragment>
				<Spin tip={TEXT_LOADING} spinning={this.state.loadingPage}>
					<Layouts.MainTheme header={"ข้อมูลผู้ถูกประเมิน"}
						component={this.renderAssesseeInfo()}>
					</Layouts.MainTheme>
					<Layouts.MainTheme header={"ปัจจัยการประเมิน"}
						component={this.renderProbationFactor()}>
					</Layouts.MainTheme>
				</Spin>
			</React.Fragment>

		);
	}
	//----- End :: Function -----//

}

export default InsertProbationContract;
