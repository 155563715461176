import React from "react";
import { Form, Col, Row } from "antd";
import { useSelect } from "./select/SelectAntd";
import { useInputArea, useInputNumber, useInputText } from "./inputs/InputAnt";
import { useDatePicker,useDateRange } from "./datepicker/DatePickerAntd";
import { useButton } from "./buttons/CustomButtonAntd";
import { useCheckbox } from "./checkbox/CheckboxAnt";
import { UseUpload } from "./uploads/UploadAntd";

export const useRenderFormItem = ({ constantComponent = [] }) => {
  return constantComponent.map((element, i) => {
    const { rowProps, column } = element;
    return (
      <Row key={i} gutter={[16, 16]} {...rowProps}>
        {column.length !== 0 &&
          column.map((col, id) => {
            const { breakpoint, component } = col;
            return (
              <Col key={id} {...breakpoint}>
                <Form.Item {...component.itemProps}>
                  {useRenderComponents(component)}
                </Form.Item>
              </Col>
            );
          })}
      </Row>
    );
  });
};

export const useRenderComponents = (component) => {
  const { type, componentProps, itemProps, fnChange, dropdown, text } =
    component;
  switch (type) {
    case "select":
      return useSelect({
        name: itemProps.name,
        componentProps,
        fnChange,
        dropdown,
      });
    case "input":
      return useInputText({
        name: itemProps.name,
        componentProps,
        fnChange,
      });
    case "area":
      return useInputArea({
        name: itemProps.name,
        componentProps,
        fnChange,
      });
    case "datePicker":
      return useDatePicker({
        name: itemProps.name,
        componentProps,
        fnChange,
      });
    case "datePickerRang":
      return useDateRange({
        name: itemProps.name,
        componentProps,
        fnChange,
      });

    case "button":
      return useButton({
        call: componentProps.call,
        moreProps: componentProps,
      });
    case "checkBox":
      return useCheckbox({
        call: itemProps.name,
        componentProps,
        fnChange,
        text,
      });
    case "upload":
      return UseUpload({
        name: itemProps.name,
        componentProps,
        fnChange,
      });
    case "inputNumber":
      return useInputNumber({
        name: itemProps.name,
        componentProps,
        fnChange,
      });
    default:
      return;
  }
};
