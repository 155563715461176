import React from 'react'
import { DatePicker, Radio, Select, Input, Checkbox, Row, Col, Empty, Tooltip, Popover } from 'antd'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { renderWithLabel } from '../functions';
import { validateScore } from '../../../constants/functions';
/**
 * @param {*} inputItems - label or required
 * @param {function} onChange - (value) => console.log(value)
 */

export const TYPE = {
    TEXT: "TEXT",
    SCORE: "SCORE",
    TITLE: "TITLE",
    HEADER: "HEADER",
    TEXT_RESULT: "TEXT_RESULT",
    DIVIDER: "DIVIDER",
    INPUT: "INPUT",
    INPUT_AREA: "INPUT_AREA",
    BUTTON: "BUTTON",
    INPUT_RENDER: "INPUT_RENDER",
    HELPER: "HELPER",
    NUMBER: "NUMBER",
    NUMBERFIX: "NUMBERFIX",
    SELECT: "SELECT",
    CHECKBOX: "CHECKBOX",
    DATE_PICKER: "DATE_PICKER",
    TIME_PICKER: "TIME_PICKER",
    RANG_PICKER: "RANG_PICKER",
    RANG_PICKER_SEARCH: "RANG_PICKER_SEARCH",
    INPUT_SEARCH: "INPUT_SEARCH",
    SELECT_MULTIPLE: 'SELECT_MULTIPLE',
    SELECT_REQUIRED: "SELECT_REQUIRED"
}

export const MainInput = ({
    type,
    disabled,
    placeholder,
    inputProps,
    inputItems,
    options,
    onChange,
    onSearch,
    value,
    rangValue,
    validate
}) => {
    // const [searchValue, onChangeSearch] = useState(undefined)
    switch (type) {
        case TYPE.INPUT:
            return (
                <div className="input-item">
                    {requiredInput({ ...inputItems })}
                    <Input
                        disabled={disabled}
                        value={value}
                        onChange={(e) => onChange(e.target.value)}
                        placeholder={placeholder}
                        style={{ fontSize: 14 }}
                        {...inputProps} />
                </div>
            )
        case TYPE.INPUT_RENDER:
            return (
                <div className="input-item">
                    {requiredInput({ ...inputItems })}
                    <Input
                        disabled={disabled}
                        value={renderWithLabel({ label: value })}
                        onChange={(e) => onChange(e.target.value)}
                        placeholder={placeholder}
                        style={{ fontSize: 14 }}
                        {...inputProps} />
                </div>
            )
        case TYPE.NUMBER:
            return (
                <div className="input-item">
                    {requiredInput({ ...inputItems })}
                    <Tooltip placement="topRight" title={inputItems.tooltip}>
                        <Input
                            style={{ textAlign: 'right' }}
                            value={value}
                            onChange={(e) => {
                                let val = parseFloat(e.target.value)
                                if (!isNaN(val) && isFinite(e.target.value)) {
                                    if (typeof validateScore === 'function') {
                                        onChange(validateScore(e.target.value))
                                    } else {
                                        onChange(val)
                                    }
                                } else {
                                    onChange('')
                                }
                            }}

                        />
                    </Tooltip>
                </div>
            )
        case TYPE.SCORE:
            return (
                <div className="input-item">
                    {requiredInput({ ...inputItems })}
                    <Tooltip placement="topRight" title={inputItems.tooltip}>
                        <Input
                            style={{ textAlign: 'right' }}
                            value={value}
                            onChange={(e) => {
                                let regex = "^(-?[0-9,.]*)$"  //"[0-9\.\-]$";
                                let checkPattern = e.target.value.search(regex);
                                //console.log(e.target.value, { checkPattern })
                                if (checkPattern === -1) {
                                    return onChange('');
                                }
                                return onChange(e.target.value)

                            }}
                        // onChange={(e) => {
                        //     let val = parseFloat(e.target.value)
                        //     if (!isNaN(val) && isFinite(e.target.value)) {
                        //         onChange(e.target.value)
                        //     } else {
                        //         onChange('')
                        //     }
                        // }}

                        />
                    </Tooltip>
                </div>
            )

        case TYPE.INPUT_SEARCH:
            return (
                <div className="input-item">
                    {requiredInput({ ...inputItems })}
                    <Input.Search
                        value={value}
                        disabled={disabled}
                        onSearch={(value, event) => onSearch(value, event.target.value)}
                        placeholder={placeholder}
                        {...inputProps} />
                    {(Array.isArray(inputProps.displayList) && inputProps.displayList.length > 0) ?
                        <Checkbox.Group style={{ width: '100%', fontSize: 14 }} disabled={disabled}
                            onChange={(value) => onChange(value)} >
                            {
                                inputProps.displayList.map((option, optionIndex) => (
                                    <Row key={Number(optionIndex) + 1} >
                                        <Col span={24} >
                                            <Checkbox value={option.optionId}>
                                                {option.optionLabel || ''}
                                            </Checkbox>
                                        </Col>
                                    </Row>
                                ))
                            }

                        </Checkbox.Group>
                        :
                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
                </div>
            )
        case TYPE.SELECT:
            return (
                <div className="input-item">
                    {requiredInput({ ...inputItems })}
                    <Select
                        showSearch
                        disabled={inputProps.disabled}
                        optionFilterProp="children"
                        value={value}
                        onChange={(v, option) => {
                            // onChangeSearch(v)
                            onChange(option.props.optionvalue)
                        }}
                        filterOption={(input, option) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                    >
                        {
                            Array.isArray(inputProps.options) && inputProps.options.map((option, optionIndex) => {
                                return (
                                    <Select.Option
                                        key={Number(optionIndex)}
                                        value={`${option.optionId}`}
                                        optionvalue={option.optionValue ? `${option.optionValue}` : null}
                                    >
                                        {`${option.optionLabel}`}
                                    </Select.Option>
                                )
                            })
                        }
                    </Select>
                    {/* <Select
                        disabled={disabled}
                        value={value}
                        onChange={(v, option) => {
                            onChangeSearch(v)
                            onChange(option.props.optionvalue)
                        }}
                        {...inputProps}>
                        {
                            Array.isArray(inputProps.options) && inputProps.options.map((option, optionIndex) => {
                                return (
                                    <Select.Option
                                        key={Number(optionIndex)}
                                        value={`${option.optionId}`}
                                        optionvalue={option.optionValue ? `${option.optionValue}` : null}
                                    >
                                        {`${option.optionLabel}`}
                                    </Select.Option>
                                )
                            })
                        }
                    </Select> */}
                </div>
            )
        case TYPE.CHECKBOX:
            return (
                <Checkbox
                    disabled={disabled}
                    // value={value}
                    onChange={onChange}
                    {...inputProps}>
                    {requiredInput({ ...inputItems })}
                </Checkbox>
            )
        case TYPE.RADIO:
            return (
                <div className="input-item">
                    {requiredInput({ ...inputItems })}
                    <Radio.Group onChange={(e) => onChange(e.target.value)} value={value}>
                        {
                            inputProps.options.map((option, optionIndex) => {
                                return <Radio key={optionIndex}
                                    style={{ fontSize: 14 }}
                                    value={option.optionValue}>{option.optionLabel}</Radio>

                            })
                        }
                    </Radio.Group>
                </div>
            )
        case TYPE.DATE_PICKER:
            return (
                <div style={{ display: 'flex', flexDirection: 'column', padding: '8px 0px' }}>
                    {requiredInput({ ...inputItems })}
                    <DatePicker
                        disabled={disabled}
                        onCalendarChange={onChange}
                        {...inputProps} />
                </div>

            )
        case TYPE.RANG_PICKER:
            return (
                <div style={{ display: 'flex', flexDirection: 'column', padding: '8px 0px' }}>
                    {requiredInput({ ...inputItems })}
                    <DatePicker.RangePicker
                        disabled={disabled}
                        value={rangValue}
                        onChange={(dates, dateStrings) => onChange({ dates, dateStrings })}
                        {...inputProps}
                    />
                </div>
            )
        case TYPE.HELPER:
            // console.log({ value, inputItems })
            return (
                <div style={{ position: 'absolute', right: '50%', top: '45%' }}>
                    <Popover content={
                        <div >
                            <Input.TextArea
                                disabled
                                rows={4}
                                style={{ width: 600 }}
                                value={value} />

                            {/* {value} */}
                        </div>
                    }
                        title={`${inputItems.title}`}
                        trigger="click">
                        <InfoOutlinedIcon />
                    </Popover>
                </div>
            )
        default:
            return null
    }
}

export function requiredInput({ required, label, back = false, size, labelColor }) {
    if (required) {
        return (
            (back) ?
                <span style={{ fontSize: size || 14, padding: '0px 5px 5px' }}>
                    <span style={{ color: labelColor }}>{label || 'label'} </span>
                    <span style={{ color: 'red' }}>{`${' * '}`}</span>
                </span>
                :
                <span style={{ fontSize: size || 14, padding: '0px 5px 5px' }}>
                    <span style={{ color: 'red' }}>{`${' * '}`}</span>
                    <span style={{ color: labelColor }}>{label || 'label'} </span>
                </span>

        )
    } else {
        return (
            <span style={{ fontSize: 14, padding: '0px 5px 5px' }}>
                {label}
            </span>
        )
    }
}



