import React, { useContext, useEffect, useState } from 'react';
import { ID_REPORT } from '../../constants/buttons'
import LayoutFormPrint from '../commonsTraining/formTraining/layoutFormPrint'
import { FORM_HISTORY_INDIVIDUAL, FORM_TRAINING } from '../commonsTraining/structuresTraining/formTraining'
import { TABLE_TRAINING_FORM, TABLE_HISTORY_INDIVIDUAL_FORM } from '../commonsTraining/structuresTraining/tableTraining'
import { ManageTrainingContext } from '../servicesTraining/manageTraining/manageTrainingProvider'

import { REPORT_OPTION } from '../../moduleKPIs/services/servicesOptions';
import { Spin } from 'antd';

export const PagePrintIndividual = () => {
    const { manageTrainingState, userLogin, getAPI } = useContext(ManageTrainingContext)
    const { dataTableAction: { rowKey, dataSource }, individualHeader } = manageTrainingState
    const [loading, setLoading] = useState(false)

    async function fetchAPI() {
        let dataSearch = JSON.parse(window.localStorage.getItem(REPORT_OPTION.VIEW_INDIVIDUAL));
        await setLoading(true)
        await getAPI({
            fieldname: REPORT_OPTION.VIEW_INDIVIDUAL,
            payload: dataSearch,
        })
        await setLoading(false)
    }

    useEffect(() => {
        fetchAPI()

    }, [])
    return (
        <Spin spinning={loading} tip="กำลังโหลด">
            <div style={{ position: 'relative' }}>
                <LayoutFormPrint
                    idForm={ID_REPORT.INDIVIDUAL}
                    dataSource={dataSource}
                    columns={TABLE_HISTORY_INDIVIDUAL_FORM({ permission: userLogin.userPermission })}
                    rowKey={rowKey}
                    structureHeader={FORM_HISTORY_INDIVIDUAL.HEADER(individualHeader)}
                    structureFooter={FORM_HISTORY_INDIVIDUAL.FOOTER(individualHeader)}
                />
            </div>
        </Spin>
    )
}


export const PagePrintTraining = () => {

    const { manageTrainingState, userLogin, getAPI } = useContext(ManageTrainingContext)
    const { trainingCourses, trainingUser } = manageTrainingState
    const [loading, setLoading] = useState(false)

    async function fetchAPI() {
        try {
            let dataSearch = JSON.parse(window.localStorage.getItem(REPORT_OPTION.VIEW_TRAINING));
            if (dataSearch) {
                setLoading(true)
                await getAPI({
                    fieldname: REPORT_OPTION.VIEW_TRAINING,
                    payload: dataSearch,
                })
            }
        } catch (error) {
            console.log({ error })
        } finally {

            setLoading(false)
        }
    }

    useEffect(() => { fetchAPI() }, [])

    return (
        <Spin spinning={loading} tip="กำลังโหลด">
            <div style={{ position: 'relative' }}>
                <LayoutFormPrint idForm={ID_REPORT.TRAINING}
                    dataSource={trainingCourses}
                    columns={TABLE_TRAINING_FORM({ permission: userLogin.userPermission })}
                    rowKey={'linenum'}
                    structureHeader={FORM_TRAINING.HEADER(trainingUser)}
                    structureFooter={FORM_TRAINING.FOOTER(trainingUser)} />
            </div>
        </Spin>
    )
}
