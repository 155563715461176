import React from "react";
import { Form, Input, InputNumber } from "antd";

const defaultFn = (e, name) => {
  console.log(e);
  console.log(name);
};

export const InputAnt = ({
  // [Start] Default Value
  name = "INPUT_Name",
  label = `INPUT_Label`,
  required = true,
  smgReq = "Please fill someting !!",
  styWidth = "100%",
  placeholder = "ตัวอย่าง INPUT",
  FnOnChange = defaultFn,
  isDisable = false,
  // [End] Default Value
}) => {
  return (
    <Form.Item
      name={name}
      label={label}
      rules={[
        {
          required: required,
          message: smgReq,
        },
      ]}
    >
      <Input
        style={{
          width: styWidth,
        }}
        placeholder={placeholder}
        onChange={(e) => FnOnChange(e.target.value, name)}
        disabled={isDisable}
        allowClear
      />
    </Form.Item>
  );
};

export const useInputText = ({
  name = "useInputText",
  componentProps = {},
  fnChange = () => null,
}) => {
  return (
    <Input name={name} allowClear {...componentProps} onChange={fnChange} />
  );
};

export const useInputArea = ({
  name = "useInputArea",
  componentProps = {},
  fnChange = () => null,
}) => {
  return (
    <Input.TextArea
      name={name}
      allowClear
      {...componentProps}
      onChange={fnChange}
    />
  );
};

export const useInputNumber = ({
  name = "useInputNumber",
  componentProps = {},
  fnChange = () => null,
}) => {
  return (
    <InputNumber
      min={0}
      name={name}
      {...componentProps}
      onBlur={fnChange}
      formatter={(value) =>
        value.replace(new RegExp(/\B(?=(\d{3})+(?!\d))/g), ",")
      }
      onKeyPress={(e) => {
        if (!/[0-9]/.test(e.key)) {
          e.preventDefault();
        }
      }}
    />
  );
};
