import React, { useContext, useState } from 'react'
import { Modal } from 'antd'
import { ButtonTheme } from '../buttons';
import { ModalContext } from '../../services/modal/modalProvider';
import { InsertIndicator } from '../../components/modal';

export const ModalWrapper = ({
    buttonText,
    buttonIcon,
    titleText,
    content,
    style,
    onSubmit,
    visible,
    showModal,
    closeModal,
    buttonStyle,
    loading,
    okText,
    width,
    ...rest
}) => {
    return (
        <div style={style} >
            <ButtonTheme
                buttonStyle={buttonStyle}
                buttonText={buttonText}
                onClick={showModal} />
            <Modal
                // width={'800px'}
                // style={{ width: width || 600 }}
                visible={visible}
                title={titleText || 'Title'}
                onOk={onSubmit}
                onCancel={closeModal}
                okText={okText || "บันทึก"}
                cancelText="ยกเลิก"
                confirmLoading={loading}
                {...rest}
            >
                {content}
            </Modal>
        </div>
    )
};