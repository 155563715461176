import React, { useContext, useState, useRef } from 'react'
import { Modal, Spin } from 'antd';

import { LayoutTable } from '../../moduleKPIs/common/layouts';
import ButtonSearch from '../../constants/buttons/buttonSearch';
import { TABLE_CONTRACT } from '../commonsContract/structuresContract/tableContract';
import { KEY_OPTION } from '../../constants/enums/enumCenter';
import { ContractContext } from '../servicesContract/contractProvider';
import { MasterCenterContext } from '../../service/master/masterProvider';
import { PATHNAME } from '../../constants/enums/pathname';
import { ButtonExport, FIELD_BUTTON } from '../../constants/buttons';
import { APIConfirmProbationContract, APIConfirmReceivedContract } from '../servicesContract/contractConnect';
import { COLUMNS_EXPORT } from '../commonsContract/structuresContract/columnsContract';


const DashboardTrackContract = ({ pathname }) => {
    const refExport = useRef();

    const [loading, setLoading] = useState(false);
    const { masterCenterState } = useContext(MasterCenterContext);
    const { contractState, getAPI, userLogin, userPermission } = useContext(ContractContext);
    const { dataTable: { rowKey, dataSource } } = contractState;


    async function onSearch() {
        try {
            setLoading(true)
            await getAPI({ fieldname: KEY_OPTION.PROBATION.DASHBOARD_TRACK, payload: { ...masterCenterState } })
        } catch (error) {
            console.log({ error })
            setLoading(false)
        } finally {
            setLoading(false)

        }
    }

    return (
        <div>
            <div className="layout-content-btn-search">
                <ButtonSearch
                    disabled={false}
                    onClick={() => onSearch()}
                    style={{ padding: 10 }} />
            </div>
            <div className="layout-content-btn-export-track">
                <ButtonExport
                    ref={refExport}
                    labelButton=""
                    fieldname="รายงานค้างรับเอกสารทดลองงาน (สัญญาจ้าง)"
                    columns={COLUMNS_EXPORT({
                        pathname: pathname,
                        permission: userPermission
                    })}
                    dataSource={dataSource}
                    style={{ paddingRight: 10 }}
                    styleButton={{}}
                />
            </div>
            <Spin spinning={loading} tip="กำลังโหลด">
                <LayoutTable
                    scroll={{ x: 4000 }}
                    bordered={false}
                    rowKey={rowKey}
                    dataSource={dataSource}
                    columns={TABLE_CONTRACT({
                        pathname,
                        userLogin: userLogin,
                        actionButton: async ({ fieldBtn, payload }) => {
                            await setLoading(true)
                            await window.localStorage.setItem(KEY_OPTION.DATA.PROBATION_CONTRACT, JSON.stringify(payload))
                            switch (fieldBtn) {
                                case FIELD_BUTTON.INSERT_CONTRACT:
                                    await window.open(PATHNAME.INSERT_PROBATION_CONTRACT, '_blank')
                                    break;
                                case FIELD_BUTTON.UPDATE_CONTRACT:
                                    await window.open(PATHNAME.UPDATE_PROBATION_CONTRACT, '_blank')
                                    break;
                                case FIELD_BUTTON.VIEW_CONTRACT:
                                    await window.open(PATHNAME.VIEW_PROBATION_CONTRACT, '_blank')
                                    break;
                                case FIELD_BUTTON.CONFIRM_CONTRACT:
                                    //console.log({ payload })
                                    await APIConfirmProbationContract({
                                        DocNo: payload.DocNo,
                                        user_login: userLogin
                                    }, (res) => {
                                        //console.log({ res })
                                        if (res) {
                                            Modal.success({
                                                title: `ยืนยันการส่งเอกสารสำเร็จ เลขที่เอกสาร ${res.data.DocNo}`,
                                                onOk: () => getAPI({ fieldname: KEY_OPTION.PROBATION.DASHBOARD_TRACK, payload: { ...masterCenterState } })
                                            })
                                        }
                                    })
                                    break;
                                case FIELD_BUTTON.CONFIRM_RECEIVE_CONTRACT:
                                    //console.log({ payload })
                                    let res = await APIConfirmReceivedContract({
                                        DocNo: payload.DocNo,
                                        user_login: userLogin
                                    })
                                    //console.log({ res })
                                    if (!!+res.status_api) {
                                        Modal.success({
                                            title: `ยืนยันการส่งเอกสารสำเร็จ เลขที่เอกสาร ${payload.DocNo}`,
                                            onOk: () => getAPI({ fieldname: KEY_OPTION.PROBATION.DASHBOARD_TRACK, payload: { ...masterCenterState } })

                                        })
                                    } break;
                                default:
                                    break;
                            }
                            await setLoading(false)
                        }
                    })}
                />
            </Spin>
        </div>
    )
}

export default DashboardTrackContract