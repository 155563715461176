import React from 'react'

// import DatePicker from "react-datepicker";
import "bootstrap/dist/css/bootstrap.css";
import { FormGroup, Label, Input } from "reactstrap";
import { DatePicker } from 'antd';


const DateInput = ({
	label = "",
	name = "",
	placeholderText = "",
	dateFormat = "yyyy/MM/dd",
	selected,
	onChange,
	styleLabel,
	...rest
}) => {
	return (
		<FormGroup>
			{label ? <Label name={name} style={styleLabel}>{label}</Label> : null}
			<DatePicker
				// autoComplete="true"
				className="form-control ignore-react-onclickoutside"
				placeholderText={placeholderText}
				name={name}
				dateFormat={dateFormat}
				selected={selected}
				onChange={(value) => onChange(name, value)}
				{...rest}
			/>
		</FormGroup>
	)
}

export default DateInput