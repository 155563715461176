import React, { createContext, useReducer } from "react";
import { browserHistory } from "react-router";

import { KEY_OPTION } from "../../constants/enums/enumCenter";
import { convertString } from "../../service/functions/convertOptions";
import { APIContractByDocNo, APIDashboardBenefit, APIDashboardBenefitsOut, APIDashboardContract,APIDashboardContractView, APIDashboardEmpContract, APIDashboardTrack } from "./contractConnect";

export const ContractContext = createContext();


const initialState = {
	dataTable: {
		rowKey: null, dataSource: []
	},
	dataTableView: {
		rowKeyView: null, dataSourceView: []
	},
}


const CONTRACT_ACTION = {
	SET_CONTRACT_SUCCESS: "SET_CONTRACT_SUCCESS",
	SET_CONTRACT_FAILURE: "SET_CONTRACT_FAILURE"
}



const contractReducer = (state, action) => {
	switch (action.type) {
		case CONTRACT_ACTION.SET_CONTRACT_SUCCESS:
			return { ...state, ...action.state }
		case CONTRACT_ACTION.SET_CONTRACT_FAILURE:
		default:
			return state
	}
}



const ProviderContract = ({ children }) => {
	const { userLogin, userPermission, userLocation } = children.props;
	//console.log('children.props', children.props)
	const [contractState, contractDispatch] = useReducer(contractReducer, initialState)

	async function getAPI({ fieldname, payload, pathname }) {
		let _payload = null;

		switch (fieldname) {
			case KEY_OPTION.PROBATION.DASHBOARD_CONTRACT:
				// console.log({ fieldname, payload, pathname })
				//console.log('pkkkpkk============', convertString(payload[`SEARCH_${KEY_OPTION.CENTER.OPTION_SELECT_EMPLOY_HISTORY}`]))
				//console.log('payload', payload)
				_payload = {
					// userID: userLogin,
					userlogin: userLogin,
					locationLogin: userLocation,
					depNo: payload.depNo || '',
					// SegmentTxt: payload.SegmentTxt || 'all',
					statusMainCode: payload.statusMainCode == 'all' ? '' : payload.statusMainCode,
					type: 'contract',
					// premission: userPermission,
					startDate: payload.startDate,
					toDate: payload.toDate,
					searchUser: payload.searchUser == 'all' ? '' : payload.searchUser,
				}
				// console.log({ _payload })
				await APIDashboardContract(_payload, async (response) => {
					//('APIDashboardContract', { response })
					await contractDispatch({
						type: CONTRACT_ACTION.SET_CONTRACT_SUCCESS,
						state: {
							dataTable: {
								rowKey: 'StaffCode',
								dataSource: (response === 0) ? [] : response
							}
						}
					})
				})
				break;
				case KEY_OPTION.PROBATION.DASHBOARD_CONTRACT_VIEW:
				// console.log({ fieldname, payload, pathname })
				//console.log('pkkkpkk============', convertString(payload[`SEARCH_${KEY_OPTION.CENTER.OPTION_SELECT_EMPLOY_HISTORY}`]))
				//console.log('payload', payload)
				_payload = {
					// userID: userLogin,
					userlogin: userLogin,
					locationLogin: userLocation,
					depNo: payload.depNo || '',
					// SegmentTxt: payload.SegmentTxt || 'all',
					statusMainCode: payload.statusMainCode == 'all' ? '' : payload.statusMainCode,
					type: 'contract',
					// premission: userPermission,
					startDate: payload.startDate,
					toDate: payload.toDate,
					searchUser: payload.searchUser == 'all' ? '' : payload.searchUser,
				}
				// console.log({ _payload })
				await APIDashboardContractView(_payload, async (response) => {
					//('APIDashboardContract', { response })
					await contractDispatch({
						type: CONTRACT_ACTION.SET_CONTRACT_SUCCESS,
						state: {
							dataTableView: {
								rowKeyView: 'StaffCode',
								dataSourceView: (response === 0) ? [] : response
							}
						}
					})
				})
				break;

			case KEY_OPTION.PROBATION.DASHBOARD_FOLLOWUP_GURANTEE:
				//console.log({ fieldname, payload, pathname })

				break;
			case KEY_OPTION.PROBATION.DASHBOARD_EMP_CONTRACT_PASS:
				//console.log({ fieldname, payload, pathname })
				let search = {
					locationID: convertString(payload[`SEARCH_${KEY_OPTION.CENTER.OPTION_LOCATION}`]),
					SegmentTxt: convertString(payload[`SEARCH_${KEY_OPTION.CENTER.OPTION_DEP}`]),
					statusWorker: convertString(payload[`SEARCH_${KEY_OPTION.CENTER.OPTION_STATUS_WORKERS}`]),
					statusProbation: convertString(payload[`SEARCH_${KEY_OPTION.PROBATION.OPTION_STATUS_RESULT}`]),
					txtUserSeaching: convertString(payload[`SEARCH_${KEY_OPTION.CENTER.OPTION_USERS}`]),
					statusMainCode: convertString(payload[`SEARCH_${KEY_OPTION.PROBATION.OPTION_STATUS_ASSESSMENT}`]),
					StartDateJob: convertString(payload[`SEARCH_${KEY_OPTION.CENTER.OPTION_DATE}`]),
					ToDateJob: convertString(payload[`SEARCH_${KEY_OPTION.CENTER.OPTION_DATE_TO}`]),
					premission: userPermission,
					type: 'contract',
				}
				_payload = {
					userID: userLogin,
					premission: userPermission,
					report: 'report',
					locationID: search.locationID,
					SegmentTxt: search.SegmentTxt,
					statusWorker: search.statusWorker,
					statusProbation: search.statusProbation,
					txtUserSeaching: search.txtUserSeaching,
					statusMainCode: search.statusMainCode,
					StartDateJob: search.StartDateJob,
					type: 'contract',
				}
				await APIDashboardBenefit({ status: payload.status, ..._payload }, async (response) => {
					//console.log('APIDashboardBenefit', { response })
					await contractDispatch({
						type: CONTRACT_ACTION.SET_CONTRACT_SUCCESS,
						state: {
							dataTable: {
								rowKey: 'DocNo',
								dataSource: (response === 0) ? [] : response
							}
						}
					})
					if (pathname) {
						await browserHistory.push({ pathname })
					}
				})
				break;
			case KEY_OPTION.PROBATION.DASHBOARD_EMP_CONTRACT_NOTPASS:
				//console.log({ fieldname, payload, pathname })
				let search_not = {
					locationID: convertString(payload[`SEARCH_${KEY_OPTION.CENTER.OPTION_LOCATION}`]),
					SegmentTxt: convertString(payload[`SEARCH_${KEY_OPTION.CENTER.OPTION_DEP}`]),
					statusWorker: convertString(payload[`SEARCH_${KEY_OPTION.CENTER.OPTION_STATUS_WORKERS}`]),
					statusProbation: convertString(payload[`SEARCH_${KEY_OPTION.PROBATION.OPTION_STATUS_RESULT}`]),
					txtUserSeaching: convertString(payload[`SEARCH_${KEY_OPTION.CENTER.OPTION_USERS}`]),
					statusMainCode: convertString(payload[`SEARCH_${KEY_OPTION.PROBATION.OPTION_STATUS_ASSESSMENT}`]),
					StartDateJob: convertString(payload[`SEARCH_${KEY_OPTION.CENTER.OPTION_DATE}`]),
					ToDateJob: convertString(payload[`SEARCH_${KEY_OPTION.CENTER.OPTION_DATE_TO}`]),
					premission: userPermission,
					type: 'contract',
				}
				_payload = {
					user_login: userLogin,
					locationID: search_not.locationID,
					SegmentTxt: search_not.SegmentTxt,
					statusWorker: search_not.statusWorker,
					statusProbation: 2,
					txtUserSeaching: search_not.txtUserSeaching,
					statusMainCode: search_not.statusMainCode,
					StartDateJob: search_not.StartDateJob,
					toDateJob: '',
				}
				await APIDashboardBenefitsOut({ status: payload.status, ..._payload }, async (response) => {
					//console.log('APIDashboardBenefitsOut', { response })
					await contractDispatch({
						type: CONTRACT_ACTION.SET_CONTRACT_SUCCESS,
						state: {
							dataTable: {
								rowKey: 'DocNo',
								dataSource: (response === 0) ? [] : response
							}
						}
					})
					if (pathname) {
						await browserHistory.push({ pathname })
					}
				})

				break;

			case KEY_OPTION.PROBATION.DASHBOARD_TRACK:
				//console.log({ fieldname, payload, pathname })

				_payload = {
					user_login: userLogin,
					pageType: 'TrackProbation',
					locationID: convertString(payload[`SEARCH_${KEY_OPTION.CENTER.OPTION_LOCATION}`]),
					SegmentTxt: convertString(payload[`SEARCH_${KEY_OPTION.CENTER.OPTION_DEP}`]),
					statusWorker: convertString(payload[`SEARCH_${KEY_OPTION.CENTER.OPTION_STATUS_WORKERS}`]),
					txtUserSeaching: convertString(payload[`SEARCH_${KEY_OPTION.CENTER.OPTION_USERS}`]),
					statusProbation: convertString(payload[`SEARCH_${KEY_OPTION.PROBATION.OPTION_STATUS_RESULT}`]),
					StartDateJob: payload[`SEARCH_${KEY_OPTION.CENTER.OPTION_RANGE_DATE}`][0],
					toDateJob: payload[`SEARCH_${KEY_OPTION.CENTER.OPTION_RANGE_DATE}`][1],
					type: 'contract',
					premission: userPermission,
				}
				await APIDashboardTrack(_payload, async (response) => {
					await contractDispatch({
						type: CONTRACT_ACTION.SET_CONTRACT_SUCCESS,
						state: {
							dataTable: {
								rowKey: 'StaffCode',
								dataSource: (response === 0) ? [] : response
							}
						}
					})
					if (pathname) {
						await browserHistory.push({ pathname })
					}
				})
				break;
			case KEY_OPTION.PROBATION.DASHBOARD_BENEFIT:
				_payload = {
					user_login: userLogin,
					type: 'contract',
					locationID: convertString(payload[`SEARCH_${KEY_OPTION.CENTER.OPTION_LOCATION}`]),
					SegmentTxt: convertString(payload[`SEARCH_${KEY_OPTION.CENTER.OPTION_DEP}`]),
					statusWorker: convertString(payload[`SEARCH_${KEY_OPTION.CENTER.OPTION_STATUS_WORKERS}`]),
					txtUserSeaching: convertString(payload[`SEARCH_${KEY_OPTION.CENTER.OPTION_USERS}`]),
					statusProbation: convertString(payload[`SEARCH_${KEY_OPTION.PROBATION.OPTION_STATUS_RESULT}`]),
					statusMainCode: 3,
					StartDateJob: payload[`SEARCH_${KEY_OPTION.CENTER.OPTION_RANGE_DATE}`][0],
					toDateJob: payload[`SEARCH_${KEY_OPTION.CENTER.OPTION_RANGE_DATE}`][1],
					premission: userPermission,
				}
				await APIDashboardBenefit(_payload, async (response) => {
					await contractDispatch({
						type: CONTRACT_ACTION.SET_CONTRACT_SUCCESS,
						state: {
							dataTable: {
								rowKey: 'StaffCode',
								dataSource: (response === 0) ? [] : response
							}
						}
					})
					if (pathname) {
						await browserHistory.push({ pathname })
					}
				})

				break;

			default:
				break;
		}
	}

	async function clearData() {
		await contractDispatch({
			type: CONTRACT_ACTION.SET_CONTRACT_SUCCESS,
			state: {
				dataTable: {
					rowKey: null,
					dataSource: []
				}
			}
		})
	}

	return (
		<ContractContext.Provider
			value={{
				userLogin,
				userPermission,
				contractState,
				getAPI,
				clearData,
				contractDispatch
			}}>
			{children}
		</ContractContext.Provider>
	)
}


export default ProviderContract