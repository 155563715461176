import React, { Component } from "react";
import { PATHNAME } from "../../constants/enums/pathname";
import { APISelectOptionMentor } from "../../moduleTraining/servicesTraining/masterTraining/masterTrainingConnect";
import { INITIAL_PROBATION } from "./initialProbation";
import { APIProbationMaster, APIProbation } from "../../service";
import { Col, Row, Form, Input } from "reactstrap";
import BaseText from "../../constants/text/BaseText";
import * as Layouts from "../../constants/layouts";
import {
  RenderData,
  RenderFactor,
  RenderFactorFacL2,
  RenderFactorFacL1,
  RenderFactorFacL3,
} from "./ProbationContantsRender";
import * as R from "ramda";
import FactorPanel from "./FactorPanel";
import encodeString from "../../extendFunction/encodeString";
import "bootstrap/dist/css/bootstrap.css";
import { colors } from "../../themes";
import { Spin, Modal } from "antd";
import { KEY_LOCATION, TEXT_LOADING } from "../../constants/enums/enumCenter";
import { validateEmptyLists } from "../../constants/functions/emptyLists";
import { convertArray } from "../../constants/functions";
import { browserHistory } from "react-router";
import { update } from "ramda";
import { RenderForm } from "./../../constants/inputs/RenderForm";
import {
  POST,
  POST_INSERT_PROBATION,
  POST_UPDATE_PROBATION,
} from "./../../service/index";
import RecruitmentAssessment from "./RecruitmentAssessment";
import moment from "moment";

class InsertProbation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      initialData: {},
      done: undefined,
      userLogin: this.props.userLogin,
      userLocation: this.props.locationM,
      latitude: "",
      longitude: "",
      average: [],
      loadingPage: false,
      statusCF: false,
      optionWorker: [],
      recruiterValue: {
        recruiterGrade: "",
        recruiterScore: "",
        recruiter: "",
      },
    };
  }

  componentDidMount() {
    this.getInitialData();
  }

  getInitialData = async () => {
    try {
      const {
        router: {
          location: { state, pathname },
        },
      } = this.props;
      this.setState({ loadingPage: true });

      if (state) {
        let initialData = {};
        let optionWorker = [];
        //console.log(pathname, PATHNAME.UPDATE_PROBATION)
        if (pathname === PATHNAME.UPDATE_PROBATION) {
          /* --------------------------------- UPDATE --------------------------------- */
          let payload = { docNo: state.DocNo };
          await APIProbation(payload).then((res) => {
            const { result } = res;
            if (result) {
              initialData = {
                ...result,
                ApprovalNo:
                  (result.ApprovalNo && result.ApprovalNo.trim()) || "",
                BossCode: (result.BossCode && result.BossCode.trim()) || "",
              };
            }
          });
          initialData = {
            ...initialData,
            ...state,
          };
          /* -------------------------------------------------------------------------- */
        } else {
          // console.log('----- INSERT -----', state)
          /* ----------------------------- GET API WORKER ----------------------------- */
          await APISelectOptionMentor({ depNo: "" }, (res) => {
            if (res) {
              optionWorker = res.result.map((el) => {
                let nameWoker = el.NickName
                  ? `${el.Name}${" ( "}${el.NickName}${" )"}`
                  : `${el.Name}`;
                return { label: nameWoker, value: nameWoker, key: el.ID_User };
              });
            }
          });
          initialData = {
            ...state, // ข้อมูลที่ส่งจาก props
            ...initialData,
          };
          await APIProbationMaster({
            position: state.Position_No,
            company: "",
            depNo: "",
            subDepNo: "",
          })
            .then((res) => {
              if (res.length > 0) {
                initialData = {
                  ...initialData,
                  [`factor_1`]: res.map((el) => {
                    return {
                      name: el.description,
                      score: null,
                      lineNo: 0,
                      isAddedOnWeb: true,
                    };
                  }),
                };
              }
            })
            .catch((err) => {
              console.log("errrrrrrrrrrrrrr", err);
            });
          /* -------------------------------------------------------------------------- */
          /* --------------------------------- INSERT --------------------------------- */
          //!ข้อมูลDefault จาก API จะไม่ต้องกับ update
        }

        initialData = {
          ...INITIAL_PROBATION,
          ...initialData, // ข้อมูลที่ Default ไว้ในกรณีที่ ต้องการ Insert
          StaffCode: state.StaffCode, // รหัสพนักงานที่ถูกประเมิน จะส่งมาจาก props
          user_login: this.state.userLogin, // จะส่งมาจาก route
        };

        /* -------------------------------------------------------------------------- */
        // console.log('initialData data-------------------------', initialData)
        this.setState({
          initialData,
          optionWorker,
          recruiterValue: {
            ...this.state.recruiterValue,
            recruiter: `${state.recruiterName || ""} (${
              state.recruiterNickname || ""
            })`,
            recruiterGrade: `${state.recruiterGrade || ""}`,
            recruiterScore: `${state.recruiterScore || ""}`,
          },
        });
      }
    } catch (error) {
      console.log({ error });
    } finally {
      this.setState({ loadingPage: false });
    }
  };

  renderWithJG = (_info) => {
    const { initialData } = this.state;
    let jg = encodeString.decode(initialData.JG);
    if (_info.workplace === KEY_LOCATION.FACTORY) {
      if (jg <= 2) {
        return 8;
      } else if (jg > 2 && jg < 6) {
        return 11;
      }
      return 8;
    }

    if (_info.workplace === KEY_LOCATION.OFFICE) {
      return 8;
    }
    return 8;
  };

  validateConfirm = (_info) => {
    // console.log('_info validateConfirm', _info)
    // console.log('num', this.renderWithJG(_info))
    let _num = this.renderWithJG(_info); //! ถ้าปุ่มไม่เปิดเมื่อกรอกครบ เช็คจำนวนหัวข้อแบบประเมิน #1
    let numFac = Array(_num)
      .fill()
      .map((_, i) => i + 1);
    //TODO : ชื่อหัวข้อการประเมินและคะแนน ของแค่ละหัวข้อต้องไม่เป็นค่าว่าง => ถ้าเป็นค่าว่าง true
    let numCurrent = numFac.filter((fac) => !!_info[`factor_${fac}`]);
    let headerLists = numCurrent.map((fac) => `factor_${fac}`);
    let validateLists = headerLists.map((e, i) => {
      // let newIndex = i + 1
      // // e = factor_${newIndex}
      if (i === 0) {
        //TODO : หัวข้อย่อย ที่ 1 จะเช็คทั้ง name และ score
        // return _info[e].every((x) => !!x.name && !!x.score)
        return _info[e].every((x) =>
          !!x.lineNo
            ? !!x.name && !!x.score && !!x.score[_info.RoundNow - 1]
            : !!x.name && !!x.score
        );
      } else {
        // console.log('_info[e].every((x) => (!!x.lineNo) ? (!!x.score[_info.RoundNow - 1]) : !!x.score)', _info[e].every((x) => (!!x.lineNo) ? (!!x.score[_info.RoundNow - 1]) : !!x.score))
        return _info[e].every((x) =>
          !!x.lineNo ? !!x.score[_info.RoundNow - 1] : !!x.score
        );
      }
    });
    let success = validateLists.some((e) => !e); //! ถ้าเป็น true หมดจะ success สามารถยืนยันการประเมินได้
    return !success;
    // return (!!_ && !success)
  };

  onRemoveItem = (mainRound, indexRound, calAvg) => {
    const { initialData } = this.state;
    // let x = R.clone(initialData[`${"factor_"}${indexRound}`])
    let prepareObj = {
      ...initialData,
      [`${"factor_"}${mainRound}`]: R.remove(
        indexRound,
        1,
        initialData[`${"factor_"}${mainRound}`]
      ),
    };
    this.setState({ initialData: prepareObj }, () => {
      if (calAvg) {
        const { fieldname, indexScore } = calAvg;
        this.calculateAvg(fieldname, initialData.DocNo && true, indexScore);
      }
    });
  };

  onAddItem = (indexRound) => {
    const { initialData } = this.state;
    let factorIndex = initialData[`${"factor_"}${indexRound}`];
    let scoreValue = initialData.DocNo
      ? Object.assign(new Array(6).fill(""))
      : null;
    let prepareObj = {
      ...initialData,
      [`${"factor_"}${indexRound}`]: [
        ...factorIndex,
        { name: null, score: scoreValue, lineNo: 0, isAddedOnWeb: true },
      ],
    };
    this.setState({ initialData: prepareObj });
  };

  validateGrade = (_initial) => {
    if (_initial) {
      let status = +_initial.probationStatus;
      if (status == 1 || status == 2) return true;
      return false;
    }
  };

  renderContentInput = (each) => {
    const { initialData } = this.state;
    const { comp, name, type, value, options, title, onChange } = each;
    if (comp == "selectSearch") {
      return (
        <div style={{ display: "flex", alignItems: "center" }}>
          <BaseText padding={"0px 10px 0px 0px"} label={title} />
          {initialData && !initialData.DocNo
            ? RenderForm({
                width: 200,
                style: { margin: 0 },
                name: name,
                type: type,
                value: value,
                showArrow: false,
                options: options,
                onChange: onChange,
                comp: "SelectSearch",
              })
            : RenderForm({
                padding: "0px 10px 0px 0px",
                label: value,
                comp: "TextDisplay",
              })}
        </div>
      );
    } else {
      return (
        <React.Fragment>
          <BaseText padding={"0px 10px 0px 0px"} label={title} />
          <BaseText label={value} />
        </React.Fragment>
      );
    }
  };

  renderAssesseeInfo = () => {
    const { initialData, optionWorker } = this.state;
    return (
      <Form>
        {RenderData({
          objectValue: initialData,
          optionWorker,
          handleChange: this.handleChange,
        }).map((row, i) => (
          <Row key={i}>
            {row.map((each, iR) => (
              <Col
                key={`${iR}`}
                md={6}
                style={{ display: "flex", padding: "5px 15px" }}
              >
                {this.renderContentInput(each)}
              </Col>
            ))}
          </Row>
        ))}
      </Form>
    );
  };

  renderCheckbok = () => {
    const { userLogin, statusCF, initialData, userLocation } = this.state;
    const {
      ApprovalNo,
      BossCode,
      RoundNow,
      sameApprover,
      workplace,
      statusWorkerCode,
    } = initialData; // BossCode LV 1 ,ApprovalNo LV2 ;
    let LV_1 = userLogin === BossCode;
    let LV_2 = userLogin === ApprovalNo;
    let ApproverY = sameApprover === "Y"; //ผู้อนุมัติอันดับ1 และ 2 เหมือนกัน
    // console.log({ initialData }, statusCF)
    let layoutCheckbox = () => (
      <div className="ui checkbox">
        <Input
          onChange={() => this.setState({ statusCF: +!this.state.statusCF })}
          // disabled={!this.validateConfirm(initialData)}
          // disabled={validateBtn(initialData)}
          type="checkbox"
          name="CF_Send"
          id="CF_Send"
          checked={statusCF}
        />
        <label style={{ fontSize: 14 }}>
          {" "}
          ยืนยันการประเมิน{" "}
          <b style={{ color: "red" }}>
            {" "}
            (กรณียืนยันแล้ว จะไม่สามารถกลับมาแก้ไขได้)
          </b>
        </label>
      </div>
    );

    if (userLocation == "FAC" || workplace === "FAC") {
      if (statusWorkerCode === "Daily") {
        //พนักงานรายวัน โรงงาน
        // TODO: ทุกรอบการประเมิน หัวหน้าลำดับ 1 , 2 ประเมินได้  (รวมถึงกรณีที่ ลำดับ 1 และ 2 เป็นคนเดียวกัน)
        if (ApproverY || LV_1 || LV_2) {
          return layoutCheckbox();
        }
      }
      //พนักงานรายเดือน และ สัญญาจ้าง โรงงาน
      // TODO: ถ้ารอบการประเมินที่ 1-2 หัวหน้าลำดับ 1 และ 2 สามารถประเมินได้
      if (RoundNow < 3 && (LV_1 || LV_2)) {
        // TODO: (LV_2) ถ้ารอบการประเมินที่ 3 ขึ้นไป หัวหน้าลำดับ 2 ประเมินได้
        return layoutCheckbox();
      }
      if (LV_2) {
        return layoutCheckbox();
      }
    }
    //Office
    // TODO: ทุกรอบการประเมิน หัวหน้าลำดับ 2 ประเมินได้ (รวมถึงกรณีที่ ลำดับ 1 และ 2 เป็นคนเดียวกัน)
    if (ApproverY || LV_2) {
      return layoutCheckbox();
    }
  };

  renderButtonConfirm = () => {
    const { statusCF, initialData } = this.state;
    return RenderForm({
      comp: "ButtonTheme",
      disabled: !initialData.mentorCode || initialData.RoundNow > 6,
      color: statusCF ? colors.buttonConfirm : colors.buttonDraft,
      label: statusCF
        ? "ยืนยันการประเมิน (กรณียืนยันแล้ว จะไม่สามารถกลับมาแก้ไขได้)"
        : "บันทึกแบบร่าง",
      style: { textAlign: "center" },
      onClick: () => this.onSave(),
    });
  };

  updateFactor = (fieldName, value, indexRound, mainRound, calAvg) => {
    const { initialData } = this.state;
    const { RoundNow, DocNo } = initialData;
    let fieldGroup = `${"factor_"}${mainRound}`;
    let updatingField = {};
    switch (RoundNow) {
      case 1:
        if (fieldName == "score") {
          let scoreValue = null;
          scoreValue =
            parseFloat(value) > 4
              ? 4
              : parseFloat(value) < 1
              ? 1
              : parseFloat(value);
          updatingField = {
            [fieldName]: DocNo
              ? update(
                  RoundNow - 1,
                  scoreValue,
                  initialData[fieldGroup][indexRound][fieldName]
                )
              : scoreValue,
          };
        } else {
          let thisFieldName = initialData[fieldGroup][indexRound][fieldName];
          thisFieldName = value;
          updatingField = {
            [fieldName]: DocNo ? thisFieldName : value,
          };
        }
        break;
      case 2:
      case 3:
      case 4:
      case 5:
      case 6:
        if (fieldName == "score") {
          let scoreValue = null;
          scoreValue =
            parseFloat(value) > 4
              ? 4
              : parseFloat(value) < 1
              ? 1
              : parseFloat(value);
          updatingField = {
            [fieldName]: DocNo
              ? update(
                  RoundNow - 1,
                  scoreValue,
                  initialData[fieldGroup][indexRound][fieldName]
                )
              : scoreValue,
          };
        } else {
          let thisFieldIndex = initialData[fieldGroup][indexRound][fieldName];
          thisFieldIndex = value;
          updatingField = {
            [fieldName]: thisFieldIndex,
          };
        }
        break;
      default:
        if (fieldName == "score") {
          let scoreValue = null;
          scoreValue =
            parseFloat(value) > 4
              ? 4
              : parseFloat(value) < 1
              ? 1
              : parseFloat(value);
          updatingField = {
            [fieldName]: DocNo
              ? update(
                  RoundNow - 1,
                  scoreValue,
                  initialData[fieldGroup][indexRound][fieldName]
                )
              : scoreValue,
          };
        } else {
          updatingField = {
            [fieldName]: value,
          };
        }
        break;
    }

    let initialDataPrepare = {
      ...initialData,
      [fieldGroup]: update(
        indexRound,
        {
          ...initialData[fieldGroup][indexRound],
          ...updatingField,
        },
        initialData[fieldGroup]
      ),
    };

    this.setState({ initialData: initialDataPrepare }, () => {
      if (calAvg) {
        const { fieldname, indexScore } = calAvg;
        this.calculateAvg(fieldname, initialData.DocNo && true, indexScore);
      }
      if (fieldName == "name") {
        if (initialData.Location === "FAC" || initialData.workplace === "FAC") {
          this.calculateAvg(
            `average_${RoundNow}`,
            initialData.DocNo && true,
            RoundNow - 1
          );
        }
      }
    });
  };

  calculateAvg = (fieldname, update, indexScore) => {
    const {
      factor_1,
      factor_2,
      factor_3,
      factor_4,
      factor_5,
      factor_6,
      factor_7,
      factor_8,
      factor_9,
      factor_10,
      factor_11,
    } = this.state.initialData;
    let Fac1 = [...factor_1];
    let Fac2 = [
      ...factor_2,
      ...factor_3,
      ...factor_4,
      ...factor_5,
      ...factor_6,
      ...factor_7,
      ...factor_8,
      ...factor_9,
      ...factor_10,
      ...factor_11,
    ];
    let avg = 0;
    let avg1 = 0;
    let avg2 = 0;
    let resultScore = { factor_1: [], factor_2: [] };
    let facLength1 = [];
    let facLength2 = [];
    if (update) {
      // console.log('1')
      Fac1.forEach(
        ({ score, name }) =>
          name &&
          score &&
          score[indexScore] &&
          resultScore.factor_1.push(
            isNaN(score[indexScore]) ? 0 : score[indexScore]
          )
      );
      Fac2.forEach(
        ({ score }) =>
          score &&
          score[indexScore] &&
          resultScore.factor_2.push(
            isNaN(score[indexScore]) ? 0 : score[indexScore]
          )
      );
      avg1 =
        resultScore.factor_1.length > 0 &&
        ((R.sum(resultScore.factor_1) / resultScore.factor_1.length) * 60) /
          100;
      avg2 =
        resultScore.factor_2.length > 0 &&
        ((R.sum(resultScore.factor_2) / resultScore.factor_2.length) * 40) /
          100;
      avg = avg1 + avg2;
    } else {
      /* ---------------------------- Last Edit 12/7/62 --------------------------- */
      Fac1.forEach(({ score, name }) => {
        Array.isArray(score)
          ? name &&
            resultScore.factor_1.push(
              isNaN(Math.floor(score[0])) ? 0 : Math.floor(score[0])
            )
          : name &&
            resultScore.factor_1.push(
              isNaN(Math.floor(score)) ? 0 : Math.floor(score)
            );
      });
      Fac2.forEach(({ score }) =>
        Array.isArray(score)
          ? resultScore.factor_2.push(
              isNaN(Math.floor(score[0])) ? 0 : Math.floor(score[0])
            )
          : resultScore.factor_2.push(
              isNaN(Math.floor(score)) ? 0 : Math.floor(score)
            )
      );
      resultScore.factor_1.forEach(
        (e) => !isNaN(e) && e !== 0 && facLength1.push(e)
      );
      resultScore.factor_2.forEach(
        (e) => !isNaN(e) && e !== 0 && facLength2.push(e)
      );
      avg1 =
        resultScore.factor_1.length > 0 &&
        ((R.sum(resultScore.factor_1) / facLength1.length) * 60) / 100;
      avg2 =
        resultScore.factor_2.length > 0 &&
        ((R.sum(resultScore.factor_2) / facLength2.length) * 40) / 100;
      avg = avg1 + avg2;
      /* -------------------------------------------------------------------------- */
    }
    let initialData = { ...this.state.initialData, [fieldname]: avg };
    this.setState({ initialData });
  };

  onChangeGradeHr = (fieldName, value) => {
    console.log("onChangeGradeHr fieldName, value", fieldName, "*****", value);
    const { recruiterValue } = this.state;
    this.setState({
      recruiterValue: { ...recruiterValue, [fieldName]: value },
    });
  };

  renderProbationFactor = () => {
    const { initialData } = this.state;
    let factorPanelProps = {
      checkGrade: this.validateGrade(initialData),
      handleChange: this.handleChange,
      updateFactor: this.updateFactor,
      initialData: initialData,
    };
    let paramsFunction = {
      initialData,
      handleChange: this.handleChange,
      onRemoveItem: this.onRemoveItem,
      onAddItem: this.onAddItem,
    };
    let template = RenderFactor(paramsFunction).map((each, i) => (
      <FactorPanel
        key={i}
        {...factorPanelProps}
        calculateAvg={() => {}}
        {...each}
      />
    ));

    if (initialData.Location == "FAC" || initialData.workplace == "FAC") {
      let jg = encodeString.decode(initialData.JG);
      let mapFunction = [];
      if (jg <= 2) {
        mapFunction = RenderFactorFacL1(paramsFunction);
      } else if (jg > 2 && jg < 6) {
        mapFunction = RenderFactorFacL2(paramsFunction);
      } else {
        mapFunction = RenderFactorFacL3(paramsFunction);
      }

      template = mapFunction.map((each, i) => (
        <FactorPanel
          key={i}
          {...factorPanelProps}
          calculateAvg={this.calculateAvg}
          {...each}
        />
      ));
    }

    return (
      <Spin tip={TEXT_LOADING} spinning={this.state.loadingPage}>
        <div style={{ fontSize: 12, color: "red" }}>
          {
            "การประเมินใส่คะแนนได้ 1 - 4 คะแนนเท่านั้น  ( 4=ดีมาก , 3=ดี , 2=พอใช้ , 1=ปรับปรุง )"
          }
        </div>
        {template}
        {(+this.state.initialData.probationStatus == 1 ||
          +this.state.initialData.probationStatus == 2) &&
          this.state.userLocation !== "FAC" && (
            <div style={{ padding: "20px" }}>
              <RecruitmentAssessment
                defaultValue={this.state.recruiterValue}
                onChangeMain={this.onChangeGradeHr}
              />
            </div>
          )}
        <Row>
          <Col md={12} className="text-center">
            <div
              className="ui checkbox"
              onClick={() => this.setState({ statusCF: +!this.state.statusCF })}
            >
              {/* <div className="ui checkbox" onClick={() => !!this.validateConfirm(initialData) && this.setState({ statusCF: +!this.state.statusCF })}> */}
              {this.renderCheckbok()}
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={12} className="text-center">
            {this.renderButtonConfirm()}
          </Col>
        </Row>
      </Spin>
    );
  };

  handleChange = (fieldname, value) => {
    let initialData = {};
    switch (fieldname) {
      case "mentor":
        initialData = { ...this.state.initialData, ...value };
        break;
      case "probationStatus":
        switch (+value) {
          case 1:
            initialData = {
              ...this.state.initialData,
              [fieldname]: value,
              remarkResult: "",
              probationGrade: "",
              probationStatus_Des: "ผ่านการทดลองงานแล้ว",
            };
            break;
          case 2:
            initialData = {
              ...this.state.initialData,
              [fieldname]: value,
              probationGrade: "D",
              remarkResult: "",
              probationStatus_Des: "ไม่ผ่านการทดลองงาน",
            };
            break;
          case 3:
            initialData = {
              ...this.state.initialData,
              probationGrade: "",
              [fieldname]: value,
              probationStatus_Des: "อื่นๆ (ต่อช่วงทดลองงาน)",
            };
            break;
          default:
            initialData = {
              ...this.state.initialData,
              probationGrade: "",
              remarkResult: "",
              [fieldname]: value,
              probationStatus_Des: "อยู่ในช่วงทดลองงาน",
            };
            // this.forceUpdate();
            break;
        }
        break;
      default:
        initialData = { ...this.state.initialData, [fieldname]: value };
        break;
    }
    this.setState({ initialData });
  };

  APIInsert = async (_payload) => {
    try {
      // TODO : ดำเนินตาม Flow
      const { userLogin } = this.state;
      const { date_HEA, ...rest } = _payload;
      //console.log('INSERT', { _payload })
      this.setState({ loadingPage: true });
      let res = await POST(POST_INSERT_PROBATION, _payload);
      //console.log('res APIInsert', res)
      const { success, result } = res;
      if (success) {
        Modal.success({
          title: `${"บันทึกสำเร็จ เลขที่เอกสาร "}${result || ""}`,
          onOk: () => browserHistory.push("/DashboardProbation"),
        });
      }
    } catch (error) {
      Modal.error({
        title: `บันทึกไม่สำเร็จ ${error.message || ""}`,
        onOk: () => browserHistory.push("/DashboardProbation"),
      });
    } finally {
      this.setState({ loadingPage: false });
    }
  };

  APIUpdate = async (_payload) => {
    try {
      // TODO : ดำเนินตาม Flow
      this.setState({ loadingPage: true });
      //console.log('UPDATE', { _payload })
      const { userLogin } = this.state;
      let res = await POST(POST_UPDATE_PROBATION, _payload);
      const { success, result } = res;
      if (success) {
        Modal.success({
          title: `${"แก้ไขเอกสารเลขที่เอกสาร "}${result} ${"สำเร็จ"}`,
          onOk: () => browserHistory.push("/DashboardProbation"),
        });
      }
    } catch (error) {
      Modal.error({
        title: `บันทึกไม่สำเร็จ ${error.message || ""}`,
        onOk: () => browserHistory.push("/DashboardProbation"),
      });
    } finally {
      this.setState({ loadingPage: false });
    }
  };

  onSave = async () => {
    try {
      const { statusCF, userLogin, initialData, recruiterValue } = this.state;
      this.setState({ loadingPage: true });
      let initialDataTemp = { ...initialData };
      if (
        (initialData.Location === "FAC" || initialData.workplace === "FAC") &&
        validateEmptyLists(initialData.factor_1, ["name"])
      ) {
        initialData.factor_1 = convertArray(
          "name",
          "object",
          initialData.factor_1
        );
      }
      const {
        RoundNow,
        probationStatus,
        probationGrade,
        remarkResult,
        ApprovalNo,
      } = initialData;

      if (initialData.DocNo) {
        initialDataTemp = {
          ...initialDataTemp,
          status_confirm: !statusCF ? 0 : 1,
          user_login: userLogin,
          recruiterGrade: recruiterValue.recruiterGrade,
          recruiterScore: recruiterValue.recruiterScore,
          probationEndDate: initialDataTemp.probationEndDate
            ? moment(initialDataTemp.probationEndDate).format("YYYY-MM-DD")
            : moment(new Date()).format("YYYY-MM-DD"),
        };

        // console.log({
        // 	"รอบการประเมินที่": initialData.RoundNow,
        // 	"ยืนยันการประเมิน": statusCF,
        // 	// "สถานะการประเมินรอบปัจจุบัน": initialData.status_confirm,
        // 	// "สถานะการประเมินรอบถัดไป": initialData.status_confirm + 1,
        // })
        const {
          RoundNow,
          probationStatus,
          probationGrade,
          remarkResult,
          ApprovalNo,
        } = initialData;
        switch (+RoundNow) {
          case 1:
            //console.log('ROUND 1')
            this.confirmEventSomeScoreEmpty(this.APIUpdate, initialDataTemp);
            break;
          case 2:
          case 3:
            //console.log(`ROUND ${+ RoundNow}`, initialData)
            if (
              (+probationStatus === 1 || +probationStatus === 2) &&
              !!statusCF
            ) {
              if (!probationGrade) {
                return Modal.warning({
                  title: "กรุณาสรุปผลการประเมินเกรด เพื่อประเมินทดลองงาน",
                });
              } else if (
                (!recruiterValue.recruiterGrade ||
                  !recruiterValue.recruiterScore) &&
                this.state.userLocation !== "FAC"
              ) {
                return Modal.warning({
                  title: "กรุณาสรุปผลการประเมิน เพื่อพัฒนาการสรรหา",
                });
              }
            } else if (!remarkResult && +probationStatus === 3 && !!statusCF) {
              // console.log('3')
              //TODO : ประเมินให้ผ่านทดลองงานหรือไม่ผ่านทดลองงาน และไม่ประเมินเกรด
              return Modal.warning({
                title: "กรุณาให้เหตุผลในการต่อช่วงทดลองงาน",
              });
            } else if (
              +probationStatus === 0 &&
              +RoundNow === 3 &&
              !!statusCF
            ) {
              return Modal.confirm({
                title: `คุณต้องการประเมินทดลองงานรอบที่ 4 ใช่หรือไม่ ? `,
                okText: `ใช่`,
                cancelText: `ไม่ใช่`,
                onOk: () => {
                  // TODO : ต้องการประเมินทดลองงานรอบที่ 4
                  // initialDataTemp.status_confirm = 0 //ส่ง 0 เพระาไม่ยืนยัน
                  initialDataTemp = { ...initialDataTemp, status_confirm: 0 };
                  //ส่่ง status_confirm = 0
                  Modal.warning({
                    title: `กรุณาเลือก "สรุปผลการประเมิน" เป็น อื่นๆ(ต่อช่วงทดลอง)"`,
                  });
                },
                onCancel: () => {
                  // TODO : ต้องการประเมินทดลองงานรอบที่ 3 ให้จบ
                  // initialDataTemp.status_confirm = 1 //ส่ง 1 เพระายืนยัน
                  initialDataTemp = { ...initialDataTemp, status_confirm: 1 };

                  //ส่่ง status_confirm = 1
                  Modal({
                    title: `กรุณาเลือก "สรุปผลการประเมิน" และ "สรุปผลการประเมินเกรด"`,
                  });
                },
              });
            }
            this.confirmEventSomeScoreEmpty(this.APIUpdate, initialDataTemp);
            break;
          case 4:
          case 5:
            if (!remarkResult && +probationStatus === 3 && !!statusCF) {
              //TODO : ประเมินให้ผ่านทดลองงานหรือไม่ผ่านทดลองงาน และไม่ประเมินเกรด
              return Modal.warning({
                title: "กรุณาให้เหตุผลในการต่อช่วงทดลองงาน",
              });
            } else if (
              (+probationStatus === 1 || +probationStatus === 2) &&
              !!statusCF
            ) {
              if (!probationGrade) {
                return Modal.warning({
                  title: "กรุณาสรุปผลการประเมินเกรด เพื่อประเมินทดลองงาน",
                });
              } else if (
                (!recruiterValue.recruiterGrade ||
                  !recruiterValue.recruiterScore) &&
                this.state.userLocation !== "FAC"
              ) {
                return Modal.warning({
                  title: "กรุณาสรุปผลการประเมิน เพื่อพัฒนาการสรรหา",
                });
              }
            }
            this.confirmEventSomeScoreEmpty(this.APIUpdate, initialDataTemp);

            break;
          case 6:
            //console.log('ROUND 6', { probationStatus, probationGrade, statusCF })
            if (
              (+probationStatus === 1 || +probationStatus === 2) &&
              !!statusCF
            ) {
              if (!probationGrade) {
                return Modal.warning({
                  title: "กรุณาสรุปผลการประเมินเกรด เพื่อประเมินทดลองงาน",
                });
              } else if (
                (!recruiterValue.recruiterGrade ||
                  !recruiterValue.recruiterScore) &&
                this.state.userLocation !== "FAC"
              ) {
                return Modal.warning({
                  title: "กรุณาสรุปผลการประเมิน เพื่อพัฒนาการสรรหา",
                });
              }
            } else if (
              !!statusCF &&
              !(+probationStatus === 1 || +probationStatus === 2)
            ) {
              //TODO : ประเมินให้ผ่านทดลองงานหรือไม่ผ่านทดลองงาน และไม่ย
              return Modal.warning({
                title: "กรุณาสรุปผลการประเมินเกรด เพื่อประเมินทดลองงาน",
              });
            }
            this.confirmEventSomeScoreEmpty(this.APIUpdate, initialDataTemp);
            break;
        }
      } else {
        initialDataTemp = {
          ...initialDataTemp,
          status_confirm: +statusCF,
          recruiterGrade: recruiterValue.recruiterGrade,
          recruiterScore: recruiterValue.recruiterScore,
        };
        this.confirmEventSomeScoreEmpty(this.APIInsert, initialDataTemp);
      }
      this.setState({ initialData: initialDataTemp });
    } catch (error) {
      console.log("error", { error });
    } finally {
      this.setState({ loadingPage: false });
    }
  };

  confirmEventSomeScoreEmpty = (action, payload) => {
    let isSomeScoreEmpty = this.validateConfirm(this.state.initialData);
    // console.log('payload -------------------------------------------', payload)
    // console.log('isSomeScoreEmpty confirmEventSomeScoreEmpty', isSomeScoreEmpty)
    if (!isSomeScoreEmpty) {
      return Modal.confirm({
        title: `หัวข้อประเมินบางรายการกรอกคะแนนไม่ครบ คุณต้องการบันทึกใช่หรือไม่ ? `,
        okText: `ใช่`,
        cancelText: `ไม่ใช่`,
        onOk: () => {
          action(payload);
        },
      });
    } else {
      action(payload);
    }
  };

  render() {
    //ถ้าผลการประเมิน เท่ากับ  ผ่านทดลองงานแล้ว หรือ ไม่ผ่านทดลองงานแล้ว หรือ อื่นๆ
    return (
      <React.Fragment>
        <Layouts.MainTheme
          header={"ข้อมูลผู้ถูกประเมิน"}
          component={this.renderAssesseeInfo()}
        ></Layouts.MainTheme>
        <Layouts.MainTheme
          header={"ปัจจัยการประเมิน"}
          component={this.renderProbationFactor()}
        ></Layouts.MainTheme>
      </React.Fragment>
    );
  }
}

export default InsertProbation;
