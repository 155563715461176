import { useState } from "react";
import { GET, GET_DOWNLOAD_MONITOR_FILE,  } from "../../../../src/service";
import { useAlert } from "../../../screens/conponents/modal/CustomAlert";

export const useGetFileDownload = () => {
  const [dataDownload, setDataDownload] = useState('');
  const [loadingDataDownload, setLoadingDataDownload] = useState(false);
  
  const onFetchDataDownload = async (value) => {
    try {
      const userId = value.employeeNo;
      setLoadingDataDownload(true);
      const respond = await GET(
        `${GET_DOWNLOAD_MONITOR_FILE}?userId=${userId}`
      );
      setDataDownload(respond.result);
    } catch (error) {
      useAlert({ type: "catch", content: error.message });
      console.error(error);
    } finally {
      setLoadingDataDownload(false);
    }
  };
  return {
    dataDownload,
    onFetchDataDownload,
    loadingDataDownload,
  };
};
