import React from "react";
import { Form, Upload, Button } from "antd";
import { UploadOutlined } from "@ant-design/icons";

export const UploadAntd = ({
  name = "test",
  label = "ทดสอบ UploadAntd",
  required = true,
  smgReq = "Please UploadAntd Someting !!",
  styWidth = "100%",
  FnOnChange,
  SetAccept,
}) => {
  return (
    <Form.Item
      name={name}
      label={label}
      rules={[
        {
          required: required,
          message: smgReq,
        },
      ]}
      valuePropName="filess"
    >
      <Upload
        style={{ width: styWidth }}
        onChange={(value) => FnOnChange(value, name)}
        beforeUpload={() => false}
        accept={SetAccept}
        maxCount={1}
      >
        <Button icon={<UploadOutlined />}>Click to Upload</Button>
      </Upload>
    </Form.Item>
  );
};

export const UseUpload = ({
  name = "UseUpload",
  componentProps = {},
  fnChange = () => null,
}) => {
  return (
    <Upload
      name={name}
      beforeUpload={() => false}
      maxCount={1}
      onChange={fnChange}
      {...componentProps}
    >
      <Button icon={<UploadOutlined />}>อัปโหลด</Button>
    </Upload>
  );
};

