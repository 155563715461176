import React, { useState } from "react";
import { Form, Card, Spin, Space } from "antd";
import CustomTableAtnd from "../tables/CustomTableAtnd";
import { useNotification } from "../notification/NotificationWithIcon";
import { useButton } from "../buttons/CustomButtonAntd";
import { sumColumnWidth, toUndefined } from "../../functions/UseFunction";
import { useAlert } from "../modal/CustomAlert";
import { ColumnReportDataMovement } from "../../constants/Reports/Columns/ColumnReportEmployeeTransfer";
import { configForm, WORDS } from "../../constants/ConstantComponent";
import { OPTION_ACTION_TYPE, OPTION_STATUS_TRANSFER, useOptionSelect } from "../../constants/OptionSelect";
import { POST, POST_SELECT_DATA_MOVEMENT } from "../../../service";
import { useRenderFormItem } from "../RenderComponents";
import { ComponentReportEmployeeTranfer } from "../../constants/Reports/Components/ComponentReportEmployeeTransfer";
import { Header_ReportEmployeeTransfer } from "../../constants/HeaderExcel";
import { useExportExcel } from "../../services/ExportExcel";

export const FormReportEmployeeTransfer = () => {
  const [form] = Form.useForm();
  // [START] States
  const [ChangeValue, setChangeValue] = useState({});
  const [dataTable, setDataTable] = useState([]);
  const [loadingScreen, setLoadingScreen] = useState(false);
  // [END] States

  let optionSearch = {
    actionType: useOptionSelect("movement"),
    statusTransfer: OPTION_STATUS_TRANSFER,
    userId: useOptionSelect("empAll"),
    currentStatus: useOptionSelect("currentStatus"),
  }

  // [START] Handle Form
  const onFinish = (values) => {
    const { statusTransfer, actionType, startDate, toDate, userId, currentStatus } = values
    const objectBody = {
      statusTransfer: statusTransfer || "",
      actionType: actionType || "",
      startDate: startDate ? startDate.format("YYYY-MM-DD") : "",
      toDate: toDate ? toDate.format("YYYY-MM-DD") : "",
      userId: userId || "",
      currentStatus: currentStatus || "",
    }
    getSelectDataMovement(objectBody)
  };

  const onReset = () => {
    form.resetFields();
    setDataTable([]);
    setChangeValue({});
  };
  // [END] Handle Form

  // [START] REST API
  const getSelectDataMovement = async (objectBody) => {
    try {
      setDataTable([]);
      setLoadingScreen(true);
      const response = await POST(POST_SELECT_DATA_MOVEMENT, objectBody)
      const { result } = response
      let mapResult = []
      if (result.length !== 0) {
        mapResult = result.map((row, index) => ({
          key: `${index}`,
          ...row,
        }))
      } else {
        useNotification({ type: "error", title: "ไม่พบข้อมูล" });
      }
      setDataTable(mapResult);
    } catch (err) {
      useAlert({ type: "catch", content: err.message })
      console.log("Error getSelectDataMovement: ", err);
    } finally {
      setLoadingScreen(false);
    }
  };
  // [END] REST API

  // [START] Functions
  const onChangeValue = (name, value) => {
    let setUndefined = {};
    switch (name) {
      case "actionType":
        setUndefined = toUndefined(["statusTransfer", "userId", "currentStatus"]);
        break;
      case "userId":
        setUndefined = toUndefined(["currentStatus"]);
        break;
      case "startDate":
        setUndefined = toUndefined(["toDate"]);
        break;
    }
    setDataTable([]);
    form.setFieldsValue(setUndefined);
    setChangeValue((prev) => ({
      ...prev,
      ...setUndefined,
      [name]: value,
    }));
  };
  // [END] Functions

  return (
    <Spin tip={WORDS.loadingSpin} spinning={loadingScreen}>
      <Form form={form} onFinish={onFinish} {...configForm}>
        <Card
          title="ค้นหารายงานการเปลี่ยนแปลงการจ้าง"
          style={{ marginBottom: 30 }}
          extra={
            <Space>
              {useButton({ call: "search" })}
              {useButton({
                call: "normal",
                fn: onReset,
                moreProps: { label: "ล้างข้อมูล" },
              })}
            </Space>
          }
        >
          {useRenderFormItem({
            constantComponent: ComponentReportEmployeeTranfer({
              options: optionSearch,
              handleChange: onChangeValue,
              isDisable: {
                toDate: ChangeValue["startDate"] ? false : true,
              }
            }),
          })}
        </Card>
      </Form>
      {dataTable.length !== 0 && (
        <Card
          title="รายงานการเปลี่ยนแปลงการจ้าง"
          style={{ marginBottom: 30 }}
          extra={
            useExportExcel({
              header: Header_ReportEmployeeTransfer,
              dataset: dataTable,
              filename: "รายงานการเปลี่ยนแปลงการจ้าง",
            })
          }
        >
          <CustomTableAtnd
            HeadTable={ColumnReportDataMovement}
            DataTable={dataTable}
            SetScroll={{
              x: sumColumnWidth(ColumnReportDataMovement),
              y: 500,
            }}
          />
        </Card>
      )}
    </Spin>
  );
};

export default FormReportEmployeeTransfer;
