import React from "react";
import { ButtonTheme } from "../buttons";
import BaseText from "../text/BaseText";
import DateInput from "./DateInput"
import SelectInput from "./SelectInput"
import SelectSearch from "./SelectSearch";
import TextInput from './TextInput';

/** NOTE: RENDER INPUT TYPE ONLY  */
export const RenderForm = (props) => {
	const { comp } = props
	switch (comp) {
		case "SelectInput":
			return <SelectInput
				name={props.name}
				label={props.label}
				onChange={props.onChange}
				options={props.options}
				value={props.value}
				valueField={props.valueField}
			// {...props}
			/>
		case "DateInput":
			return <DateInput
				name={props.name}
				label={props.label}
				selected={props.selected}
				onChange={props.onChange}
				placeholderText={props.placeholder}
				{...props}
			/>
		case "ButtonTheme":
			return <ButtonTheme
				name={props.name}
				label={props.label}
				onClick={props.onClick}
				{...props}
			/>
		case "TextInput":
			return <TextInput
				name={props.name}
				label={props.label}
				value={props.value}
				onChange={props.onChange}
			/>
		case "SelectSearch":
			return <SelectSearch
				style={{ ...props.style }}
				isRenderForm={true}
				{...props}
			/>
		case "TextDisplay":
			return <BaseText {...props} />
		default: return null
	}
}