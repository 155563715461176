
export const KEY_MODAL = {
	CATCH_INSERT: 'CATCH_INSERT',
	VALIDATE_INSERT: 'VALIDATE_INSERT',
	INSERT_ERROR: 'INSERT_ERROR',
	VALIDATE_CHANGE_TAB: 'VALIDATE_CHANGE_TAB'
}


export const ERROR_MODAL = (_key, _error) => {
	switch (_key) {
		case KEY_MODAL.CATCH_INSERT:
			return {
				title: `ดำเนินการไม่สำเร็จ`,
				content: `กรุณาตรวจสอบข้อมูลก่อน "ยืนยันข้อมูล" จำนวน ${_error.length || '0'} รายการ`
			}
		case KEY_MODAL.VALIDATE_INSERT:
			return {
				title: `ดำเนินการไม่สำเร็จ`,
				content: `กรุณาตรวจสอบข้อมูลก่อน "ยืนยันข้อมูล"`
			}
		case KEY_MODAL.INSERT_ERROR:
			return {
				title: `บันทึกไม่สำเร็จ`,
				content: `กรุณาลองใหม่ภายหลัง`
			}
		case KEY_MODAL.VALIDATE_CHANGE_TAB:
			return {
				title: `ดำเนินการไม่สำเร็จ`,
				content: `กรุณากรอกข้อมูลที่จำเป็นให้ครบถ้วน`
			}
		case KEY_MODAL.DUPLICATE_INSERT:
			return {
				title: `ดำเนินการไม่สำเร็จ`,
				content: `${_error}`
			}

		default:
			break;
	}
}
