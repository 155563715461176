import React, { useState, useEffect } from "react";
import { Form, Card, Spin } from "antd";
import { PermissionView, USE_CONNECT_MODE_AUTO } from "../../services/ConnectAPI";
import { browserHistory } from "react-router";
import { GET_UserLogin } from "../../functions/UseFunction";
import { useOptionSelect } from "../../constants/OptionSelect";
import { useAlert } from "../modal/CustomAlert";
import { useConfirm } from "../modal/CustomConfirm";
import { configForm, WORDS } from "../../constants/ConstantComponent";
import { useRenderFormItem } from "../RenderComponents";
import { ComponentUpdateBranchView } from "../../constants/ManageBranchView/Components";

const FromEditBranchView = (props) => {
  const [form] = Form.useForm();
  const { flowId } = props.params;
  const { userlogin } = GET_UserLogin();

  // [START] States
  const [loadingScreen, setLoadingScreen] = useState(false);
  const optionEmployeeGroup = useOptionSelect("empAll");
  // [END] States

  // [START] useEffect
  useEffect(() => {
    getUpdateBranchView();
  }, []);
  // [END] useEffect

  const onFinish = (values) => {
    const { description, users } = values;
    let objectValue = {
      description,
      userIds: users || [],
      userlogin,
    };
    useConfirm({ FnOK: () => updateBranchView(objectValue) });
  };
  // [START] REST API
  const getUpdateBranchView = async () => {
    try {
      setLoadingScreen(true);
      let url = `${USE_CONNECT_MODE_AUTO}${PermissionView.GET_SEARCH_BY_ID}${flowId}`;
      const response = await fetch(url);
      const data = await response.json();
      let userIds = [];
      if (response.ok) {
        const RESULT = data.result;
        const { description, users } = RESULT;
        userIds = users.map((val) => val.userId);
        const objectForm = {
          flowId: RESULT.flowId,
          description,
          users: userIds,
        };
        form.setFieldsValue(objectForm);
        setLoadingScreen(false);
      } else {
        useAlert({
          type: "error",
          title: `ไม่พบข้อมูล ${flowId} ในระบบ`,
          content: `กรุณาลองใหม่อีกครั้ง`,
          Fn: () => browserHistory.push("/ManageBranchView"),
        });
      }
    } catch (err) {
      setLoadingScreen(false)
      console.log("Error Get Update Permission View: ", err);
      useAlert({ type: "catch", content: `${err.message}` });
    }
  };

  const updateBranchView = async (objectValue) => {
    if (!objectValue) return null;
    try {
      setLoadingScreen(true);
      const url = `${USE_CONNECT_MODE_AUTO}${PermissionView.PUT_UPDATE_VIEW}/${flowId}`;
      const response = await fetch(url, {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(objectValue),
      });
      const data = await response.json();
      if (response.ok) {
        setLoadingScreen(true);
        useAlert({
          type: "success",
          title: `แก้ไขข้อมูลสำเร็จ`,
          Fn: () => browserHistory.push("/ManageBranchView"),
        });
      } else {
        useAlert({
          type: "error",
          title: `เกิดข้อผิดพลาด`,
          content: `${data.message}`,
          Fn: () => setLoadingScreen(false),
        });
      }
    } catch (err) {
      setLoadingScreen(false)
      console.log("Error Update View: ", err);
      useAlert({ type: "catch", content: `${err.message}` });
    }
  };
  // [END] REST API
  return (
    <Card title="แก้ไขสายการดูข้อมูล" style={{ marginBottom: 30 }}>
      <Spin tip={WORDS.loadingSpin} spinning={loadingScreen}>
        <Form form={form} onFinish={onFinish} {...configForm} >
          {useRenderFormItem({
            constantComponent: ComponentUpdateBranchView({
              options: { users: optionEmployeeGroup },
              onClick: () => getUpdateBranchView(),
            })
          })}
        </Form>
      </Spin>
    </Card>
  );
};

export default FromEditBranchView;
