import React, { useEffect, useState, useContext } from 'react'
import { Row, Col, Spin, Table } from 'antd'

import { LayoutWrapper, LayoutBreadcrumb, LayoutTabs, LayoutSearch } from '../../moduleKPIs/common/layouts'
import { BREADCRUMB_DASHBOARD_OT } from '../commonsOT/structuresOT/breadcrumOT'
import { SEARCH_DASHBOARD_OT } from '../commonsOT/structuresOT/searchOT'
import { TABS_DASHBOARD_OT } from '../commonsOT/structuresOT/tabsOT'
import { ButtonTheme } from '../../moduleKPIs/common/buttons'
import { TEXT_BTN, FIELD_BTN } from '../commonsOT/structuresOT/buttonOT'
import { MasterOTContext } from '../servicesOT/masterOT/masterOTProvider';
import { ManageOTContext } from '../servicesOT/manageOT/manageOTProvider';
import { MASTER_OPTION } from '../../moduleKPIs/services/servicesOptions';
import { ReportOTContext } from '../servicesOT/reportOT/reportOTProvider';
import { APILineNotify, KEY_LOGS } from '../../functions/notifications/lineNotify'
import { PATHNAME } from '../../constants/enums/pathname'
import { TABLE_DASHBOARD_OT } from '../commonsOT/structuresOT/tableOT'
import LayoutTable from '../../components/structuresReport/layoutTable'
import { equals } from 'ramda'
import { KEY_ACTIVE_TAB } from '../../constants/enums/enumCenter'
import { colors } from '../../themes'
import { CheckOutlined } from '@ant-design/icons'


const PageDashboardOT = () => {
	/* --------------------------------- CONTEXT -------------------------------- */

	const { masterOTState, getMaster } = useContext(MasterOTContext);
	const { onClickButton, userLogin } = useContext(ManageOTContext);
	const { reportOTState } = useContext(ReportOTContext);


	/* ---------------------------------- STATE --------------------------------- */

	const [loading, setLoading] = useState(false)
	const [initialData, setInitialData] = useState(null)
	const [activeTab, setActiveTab] = useState(KEY_ACTIVE_TAB.DASHBOARD_OT)
	const [selectedRow, setSelectedRow] = useState([])
	const [selectedRowKey, setSelectedRowKey] = useState([])
	/* --------------------------------- CONTENT -------------------------------- */

	let SEARCH_LIST = SEARCH_DASHBOARD_OT({
		activeTab,
		permission: userLogin.userPermission,
		options: masterOTState,
		initialData
	})
	let DASHBOARD = reportOTState[activeTab]
	// let TAB_LIST = TABS_DASHBOARD_OT({ permission: userLogin.userPermission })

	/* -------------------------------------------------------------------------- */

	/* -------------------------------------------------------------------------- */
	/*                                  useEffect                                 */
	/* -------------------------------------------------------------------------- */

	useEffect(() => {
		async function fetchAPI() {
			try {
				// let payloadSearchOT = JSON.parse(window.localStorage.getItem("searchDashboardOT"))
				// console.log({ payloadSearchOT })

				await Promise.all(
					[getMaster({
						fieldname: MASTER_OPTION.FILTER_DASHBOARD_OT,
						selectAll: true,
						payload: {
							userId: userLogin.userId,
							userPermission: userLogin.userPermission,
						}
					}),
					getMaster({ fieldname: MASTER_OPTION.FILTER_STATUS_OT }),
						// handleSearch() ค้นหาตอนแรกที่เข้ามาหน้านี้ 
					]
				)
			} catch (error) {
				console.log({ error })
				//! line Noti
				APILineNotify({
					key: KEY_LOGS.ERROR_CATCH,
					message: `${error.message}`,
					props: {
						userLogin: `${userLogin.userId}`,
						title: `PageDashboardOT : ${activeTab}`,
						payload: {}
					}
				})
			} finally {
				/** search data */
				handleSearch()
			}
		}
		fetchAPI()


	}, [activeTab])

	/* -------------------------------------------------------------------------- */
	/*                                  Function                                  */
	/* -------------------------------------------------------------------------- */
	async function handleSearch() {
		try {

			//console.log('ffffff')
			await onClickButton({
				fieldBtn: FIELD_BTN.SEARCH_DASHBOARD_OT,
				payload: {
					...initialData,
					activeTab,
					userLogin: userLogin.userId,
					"createPermission": userLogin.userPermission,
					options: { ...masterOTState }
				}
			})
		} catch (error) {
			console.log({ error })
			setLoading(false)
		} finally {
			setLoading(false)
		}
	}

	async function handleTabs(_key) {
		console.log('click tab', _key);
		setLoading(true)
		if (_key !== '1') {
			setActiveTab(KEY_ACTIVE_TAB.DASHBOARD_APPROVAL_OT)
		} else {
			setActiveTab(KEY_ACTIVE_TAB.DASHBOARD_OT)
		}

		// console.log(activeTab);

		// await onClickButton({
		//     fieldBtn: FIELD_BTN.SEARCH_DASHBOARD_OT,
		//     payload: {
		//         // ...searchValue,
		//         userLogin: userLogin.userId,
		//         "createPermission": userLogin.userPermission,
		//         activeTab,
		//         options: { ...masterOTState }
		//     }
		// })
	}


	async function handleSubmit() {
		console.log('handleSubmit ', selectedRow)
		let payload = {}
		try {
			payload = {
				dataList: selectedRow
			}
			setLoading(true)
			await onClickButton({
				fieldBtn: FIELD_BTN.APPROVAL_LIST_REPORT_OT,
				payload: {
					...payload
				}
			})

		} catch (error) {
			setLoading(false)
			console.log({ error })
			//! line Noti
			APILineNotify({
				key: KEY_LOGS.ERROR_CATCH,
				message: `${error.message}`,
				props: {
					userLogin: `${userLogin.userId}`,
					title: `⛔️ handleSubmit`,
					payload: { ...payload }
				}
			})
		} finally {
			setLoading(false)
		}

		// actionWithStatus({
		//     fieldBtn: FIELD_BTN.APPROVAL_LIST_REPORT_OT,
		//     payload: record,
		//     pathname: actionStatus(text, record).pathname
		// })
		// getReports({
		//     fieldname: REPORT_OPTION.REPORT_OT_FACTORY,
		//     payload: { docNo: payload.docNo }
		// })
	}
	function actionWithStatus(_statusTxt, _record) {
		// console.log(_record, userLogin, _statusTxt)
		switch (_statusTxt) {
			case 'ap1':
			case 'ap2':
				// case 'ap3':
				// ถ้า userLogin มีค่าเท่ากับ waitApprovalNo ให้สามารถกดอนุมัติได้
				if (equals(_record.waitApprovalNo, userLogin.userId)) {
					// console.info('มีสิทธิการอนุมัติ', _record.docNo)
					return {
						fieldBtn: FIELD_BTN.APPROVAL_DASHBOARD_OT,
						pathname: PATHNAME.REPORT_OT_FACTORY
					}
				} else {
					// console.info('ไม่มี! สิทธิการอนุมัติ', _record.docNo)
					return {
						fieldBtn: FIELD_BTN.VIEW_DASHBOARD_OT,
						pathname: PATHNAME.REPORT_OT_FACTORY
					}
				}
			case 'df':
				return {
					fieldBtn: FIELD_BTN.DASHBOARD_OT,
					pathname: PATHNAME.UPDATE_REQUEST_OT
				}
			case 'apd':
				return {
					fieldBtn: FIELD_BTN.VIEW_DASHBOARD_OT_APD,
					pathname: PATHNAME.REPORT_OT_FACTORY
				}

			default:
				return {
					fieldBtn: null,
					pathname: null
				}
		}

	}


	function onChange({ fieldname, value }) {
		switch (fieldname) {
			case MASTER_OPTION.FILTER_SUB_DEP_OT:
			case MASTER_OPTION.FILTER_STATUS_OT:
				setInitialData({ ...initialData, [fieldname]: value.checkedList })
				break;
			case 'activeDate':
				setInitialData({ ...initialData, [fieldname]: value.dateStrings })
				break;
			default:
				break;
		}
	}

	const RENDER_TABLE_PAGE = () => {
		if (activeTab !== KEY_ACTIVE_TAB.DASHBOARD_OT) {
			return (
				<div style={{ padding: 10 }}>
					<LayoutTable
						scroll={{ x: 600 }}
						bordered
						pagination={{
							defaultPageSize: 500,
							showTotal: (total) => `ทั้งหมด ${total} รายการ`
						}}
						rowSelection={{
							selectedRowKeys: selectedRowKey,
							onChange: (selectedRowKeys, selectedRows) => {
								// console.log({ selectedRows })
								setSelectedRow(selectedRows)
								setSelectedRowKey(selectedRowKeys)
							},
							// onSelectInvert: (selectedRowKeys) => {
							//     console.log('onSelectInvert', selectedRowKeys)
							//     // if(record.statusCode === 'ap1' && record.approvalNo1 !== userLogin.userId) {

							//     // }
							//     setSelectedRowKey(selectedRowKeys)
							// },
							// onSelectAll: (selected, selectedRows, changeRows) => {
							//     console.log('onSelectAll', selected, selectedRows, changeRows)
							//     // if(record.statusCode === 'ap1' && record.approvalNo1 !== userLogin.userId) {

							//     // }
							//     // setSelectedRowKey(selected)
							// },
							// hideSelectAll: true,
							selections: [Table.SELECTION_ALL],
							// getCheckboxProps: (record) => ({
							//     //ติ๊กได้ในกรณีที่ข้อมูลเป็น ap2 หรือ ap3
							//     disabled: (record.waitApprovalNo === userLogin.userId), // Column configuration not to be checked
							//     name: record.statusCode,
							// }),
						}}
						rowKey="docNo"
						dataSource={DASHBOARD}
						columns={TABLE_DASHBOARD_OT({
							userLogin: userLogin,
							actionButton: onClickButton,
							actionStatus: actionWithStatus,
						})} />
				</div>
			)
		}
		return (
			<div style={{ padding: 10 }}>
				<LayoutTable
					scroll={{ x: 600 }}
					bordered
					pagination={{
						defaultPageSize: 500,
						showTotal: (total) => `ทั้งหมด ${total} รายการ`
					}}
					rowKey="docNo"
					dataSource={DASHBOARD}
					columns={TABLE_DASHBOARD_OT({
						userLogin: userLogin,
						actionButton: onClickButton,
						actionStatus: actionWithStatus,
					})} />
			</div>
		)
	}
	console.log('masterOTState 888888888888888', masterOTState)

	return (
		<LayoutWrapper
			breadcrumb={
				<div className="layout-breadcrumb-btn-insert">
					<div className="layout-content-btn-search">
						<ButtonTheme
							type="primary"
							style={{ padding: 10 }}
							buttonText={TEXT_BTN.SEARCH_DASHBOARD_OT}
							onClick={() => handleSearch()}
						/>
					</div>
					<LayoutBreadcrumb breadcrumbLists={BREADCRUMB_DASHBOARD_OT()} />
				</div>
			}
			search={
				<Row gutter={[24, 10]}>
					{SEARCH_LIST.map((each, eachIndex) => (
						<Col key={eachIndex} span={each.span} >
							<LayoutSearch
								type={each.type}
								header={each.header}
								onChange={(el) => onChange({ fieldname: each.inputOption.fieldname, value: el })}
								{...each.inputOption}
							/>
						</Col>
					))}
				</Row>
			}
			content={
				<div className="layout-content-card" >
					<Spin spinning={loading} tip="กำลังโหลดข้อมูล...">
						<LayoutTabs
							extraContent={
								(activeTab === KEY_ACTIVE_TAB.DASHBOARD_APPROVAL_OT) &&
								<ButtonTheme
									icon={<CheckOutlined style={{ marginBottom: 5 }} />}
									buttonStyle={{ backgroundColor: colors.success, border: 0 }}
									disabled={DASHBOARD.length <= 0}
									type="primary"
									style={{ padding: 10 }}
									buttonText={TEXT_BTN.APPROVAL_REPORT_OT}
									onClick={() => handleSubmit()}
								/>
							}
							defaultKey={'1'}
							onChange={handleTabs}
							tabsLists={TABS_DASHBOARD_OT({
								userLogin: userLogin.userId,
								permission: userLogin.userPermission,
								renderTable: RENDER_TABLE_PAGE()
							})} />
					</Spin>
				</div>
			}
		/>
	)
}

export default PageDashboardOT