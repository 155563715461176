import React, { useContext, useEffect, Fragment } from 'react'
import { LayoutTable } from '../../moduleKPIs/common/layouts'
import { TABLE_REPORT_PRINT_OT } from '../commonsOT/structuresOT/tableOT'
import { Row, Col, Button } from 'antd'
import { ButtonTheme } from '../../moduleKPIs/common/buttons'
import { printDiv } from '../../moduleKPIs/common/functions'
import { ReportOTContext } from '../servicesOT/reportOT/reportOTProvider'
import { REPORT_OPTION } from '../../moduleKPIs/services/servicesOptions'
import { path, equals } from 'ramda'
import moment from 'moment'
import { colors } from '../../themes'
import { PRINT_HEADER_OT } from '../commonsOT/structuresOT/reportPrintOT'
import { BaseText } from '../../moduleKPIs/common/text'
import { BTN_NAME, FIELD_BTN, TEXT_BTN } from '../commonsOT/structuresOT/buttonOT'
import { ManageOTContext } from '../servicesOT/manageOT/manageOTProvider'

const PageReportOT = () => {
    // let userPermission = null

    // const { reportOTState, getReports } = useContext(ReportOTContext)
    const { onClickButton, userLogin } = useContext(ManageOTContext)
    // const { reportOT } = reportOTState;
    const userData = JSON.parse(window.localStorage.getItem("userData"))
    const userLists = JSON.parse(window.localStorage.getItem("userLists"))


    // useEffect(() => {
    //     // let refDocNo = JSON.parse(window.localStorage.getItem("refDocNo"))
    //     // getReports({ fieldname: REPORT_OPTION.REPORT_OT_FACTORY, payload: { docNo: refDocNo } })
    // }, [])
    // console.log('userLogin', userLogin)
    const roleHR = () => {
        switch (path(['userPermission'], userLogin)) {
            // case 'HR-Admin':
            // case 'HR-HeadDep':
            // case 'HR-Super':
            // case 'HR-Ass':
            // case 'HR-AdminOff':
            // case 'HR':
            case 'HRS-OFF':
            case 'HRD-OFF':
            case 'HRM-OFF':
            case 'HRS-FAC':
            case 'HRD-FAC':
            case 'HRM-FAC':
                return true

            default:
                return false
        }
    }

    //console.log({ userData })
    let approvalStatus = equals(path(['waitApprovalNo'], userData), path(['userId'], userLogin)) || (roleHR())
    const { startDateTime, endDateTime } = userData
    let headReport = {
        startDate: `${startDateTime.substr(8, 2)}/${startDateTime.substr(5, 2)}/${startDateTime.substr(0, 4)}`,
        endDate: `${endDateTime.substr(8, 2)}/${endDateTime.substr(5, 2)}/${endDateTime.substr(0, 4)}`,
        startTime: path(['startDateTime'], userData).split('T')[1].substr(0, 5),
        endTime: path(['endDateTime'], userData).split('T')[1].substr(0, 5),
        totalHours: path(['totalHours'], userData),
        remark: path(['remark'], userData),
        subDepName: path(['subDepName'], userData),
        statusName: path(['statusDes'], userData),
        statusCode: (path(['statusCode'], userData) === 'df' || path(['statusCode'], userData) === 'apd') ? true : false,
        statusApproval: path(['statusCode'], userData) === 'apd' ? true : false
    }
    let signatureReport = {
        createName: path(['statusCF'], userData) ? path(['createName'], userData) : '......................................................',
        approvalName1: path(['approvalDate1'], userData) ? path(['approvalName1'], userData) : '......................................................',
        approvalName2: path(['approvalDate2'], userData) ? path(['approvalName2'], userData) : '......................................................',
        approvalName3: path(['approvalDate3'], userData) ? path(['approvalName3'], userData) : '......................................................',
        createDate: path(['createDate'], userData) ? moment(path(['createDate'], userData)).format('DD/MM/YYYY') : '(....../....../......)',
        approvalDate1: path(['approvalDate1'], userData) ? moment(path(['approvalDate1'], userData)).format('DD/MM/YYYY') : '(....../....../......)',
        approvalDate2: path(['approvalDate2'], userData) ? moment(path(['approvalDate2'], userData)).format('DD/MM/YYYY') : '(....../....../......)',
        approvalDate3: path(['approvalDate3'], userData) ? moment(path(['approvalDate3'], userData)).format('DD/MM/YYYY') : '(....../....../......)',

    }


    return (
        <div >
            <div style={{ position: 'relative', width: '100%', minHeight: '120vh' }}>
                <div className="layout-report-btn-confirm">
                    <ButtonTheme
                        type={"primary"}
                        style={{ paddingLeft: 20 }}
                        buttonText={TEXT_BTN.PRINT_REPORT_OT}
                        onClick={() => onClickButton({ fieldBtn: FIELD_BTN.PRINT_REPORT_OT })}
                    />
                    {/* {
                        (path(['statusCode'], userData) !== 'apd') &&

                        <Button type="primary" danger onClick={() => onClickButton({ fieldBtn: FIELD_BTN.REMOVE_REQUEST_OT, payload: { docNo: path(['docNo'], userData) } })}>

                            {TEXT_BTN.REMOVE_REQUEST_OT}
                        </Button>} */}
                </div>
                <div id={'reportOT'}>
                    <div className="layout-header-report">
                        <Row gutter={[24, 10]} >
                            <Col span={24} style={{ fontSize: 20, textAlign: 'center' }} >
                                {PRINT_HEADER_OT(headReport).header}
                            </Col>
                            <Col span={24} style={{ fontSize: 16, textAlign: 'center' }}>
                                {PRINT_HEADER_OT(headReport).date}
                            </Col>
                            <Col span={24} style={{ fontSize: 16, textAlign: 'center' }}>
                                {PRINT_HEADER_OT(headReport).time}
                            </Col>
                            <Col span={24} style={{ fontSize: 14, textAlign: 'center' }}>
                                {PRINT_HEADER_OT(headReport).remark}
                            </Col>
                        </Row>
                    </div>
                    <div className="layout-table-report">
                        <LayoutTable
                            bordered
                            rowKey="linenum"
                            dataSource={userLists}
                            columns={TABLE_REPORT_PRINT_OT({ permission: null })}
                            pagination={false}
                        />
                        <div className="layout-footer-report">
                            <Row gutter={[24, 24]}  >
                                <Col span={24} style={{ fontSize: 14, textAlign: 'center' }} >
                                    {'จึงเรียนมาเพื่อขออนุมัติ'}
                                </Col>
                                <Col span={12} style={{ fontSize: 14, textAlign: 'center' }}>
                                    <div style={{ paddingBottom: 5 }}>  {signatureReport.createName}</div>
                                    <div style={{ paddingBottom: 5 }}>   {'ผู้บันทึก'}</div>
                                    <div style={{ paddingBottom: 5 }}>   {`${signatureReport.createDate}`}</div>
                                </Col>
                                <Col span={12} style={{ fontSize: 14, textAlign: 'center' }}>
                                    <div style={{ paddingBottom: 5 }}>  {signatureReport.approvalName1}</div>
                                    <div style={{ paddingBottom: 5 }}>   {'ผู้ขออนุมัติ (อันดับ 1)'}</div>
                                    <div style={{ paddingBottom: 5 }}>   {`${signatureReport.approvalDate1}`}</div>

                                </Col>
                                <Col span={24} style={{ fontSize: 14, textAlign: 'center' }}>
                                    <div style={{ paddingBottom: 5 }}>  {signatureReport.approvalName2}</div>
                                    <div style={{ paddingBottom: 5 }}>   {'ผู้ขออนุมัติ (อันดับ 2)'}</div>
                                    <div style={{ paddingBottom: 5 }}>   {`${signatureReport.approvalDate2}`}</div>
                                </Col>
                                {/*
                                 //! ไม่มีอนุมัติลำดับ 3 แล้ว
                                <Col span={(path(['totalHours'], userData) > 2) ? 12 : 24} style={{ fontSize: 14, textAlign: 'center' }}>
                                    <div style={{ paddingBottom: 5 }}>  {signatureReport.approvalName2}</div>
                                    <div style={{ paddingBottom: 5 }}>   {'ผู้ขออนุมัติ (อันดับ 2)'}</div>
                                    <div style={{ paddingBottom: 5 }}>   {`${signatureReport.approvalDate2}`}</div>
                                </Col>
                                {(path(['totalHours'], userData) > 2) && <Col span={12} style={{ fontSize: 14, textAlign: 'center' }}>
                                    <div style={{ paddingBottom: 5 }}>  {signatureReport.approvalName3}</div>
                                    <div style={{ paddingBottom: 5 }}>   {'ผู้อนุมัติเฉพาะ'}</div>
                                    <div style={{ paddingBottom: 5 }}>   {`${signatureReport.approvalDate3}`}</div>
                                </Col>} */}
                            </Row>
                        </div>
                    </div>

                </div>
                {(approvalStatus) &&
                    < div style={{ position: 'fixed', display: 'flex', bottom: '15px', justifyContent: 'center', width: '100%' }}>
                        <ButtonTheme
                            style={{ width: 100, marginRight: 5 }}
                            buttonStyle={{ color: colors.white, background: colors.wraning, bordered: 0 }}
                            buttonText={TEXT_BTN.RECALL_REPORT_OT}
                            onClick={() => onClickButton({ fieldBtn: FIELD_BTN.RECALL_REPORT_OT, payload: userData })} />
                        {(userData.statusCode !== 'apd') &&
                            <ButtonTheme
                                style={{ width: 100, marginLeft: 5 }}
                                buttonStyle={{ color: colors.white, background: colors.success, bordered: 0 }}
                                buttonText={TEXT_BTN.APPROVAL_REPORT_OT}
                                onClick={() => onClickButton({ fieldBtn: FIELD_BTN.APPROVAL_REPORT_OT, payload: userData })} />
                        }
                    </div>}
            </div>
        </div >
    )
}

export default PageReportOT

// const buttonWithStatus = (_statusCode) => {
//     switch (_statusCode) {
//         case 'ap1':
//         case 'ap2':
//         case 'ap3':
//         case 'dp':
//         case 'apd':
//             return true
//         default:
//             return false
//     }
// }

// const buttonWithPermission = ({ permission, actionButton }) => {
//     switch (permission) {
//         case 'approverNo1':
//         case 'approverNo2':
//         case 'approverNo3':
//             return (
//                 <div className="layout-report-btn-confirm">
//                     <ButtonTheme
//                         style={{ paddingRight: 10 }}
//                         buttonText={TEXT_BTN.CONFIRM_REPORT_OT}
//                         onClick={() => actionButton({ fieldBtn: FIELD_BTN.CONFIRM_REPORT_OT })}
//                     />
//                     <ButtonTheme
//                         type={"primary"}
//                         style={{ paddingRight: 10 }}
//                         buttonText={TEXT_BTN.PRINT_REPORT_OT}
//                         onClick={() => actionButton({ fieldBtn: FIELD_BTN.PRINT_REPORT_OT })}
//                     />
//                 </div>
//             )
//         default:
//             return (
//                 <div className="layout-report-btn-confirm">
//                     <ButtonTheme
//                         type={"primary"}
//                         style={{ paddingLeft: 20 }}
//                         buttonText={TEXT_BTN.PRINT_REPORT_OT}
//                         onClick={() => actionButton({ fieldBtn: FIELD_BTN.PRINT_REPORT_OT })}
//                     />
//                 </div>
//             )
//     }
// }