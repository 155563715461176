import { COMPONENT_TYPE, ERROR_MESSAGE } from "..";
import { NAME, LABEL } from "./StaticVariable";

export const actionChangeShift = ({
	handleChange = () => null,
	options = {},
	isDisable = {},
}) => [
		{ // Row
			rowProps: { justify: "start", align: "bottom" },
			column: [ // column
				{
					breakpoint: { xs: 24, sm: 24, md: 24, lg: 24, xl: 8 },
					component: {
						type: COMPONENT_TYPE.SELECT,
						itemProps: {
							name: NAME.action_changeShift,
							label: LABEL.action_changeShift,
							rules: [{ required: true, message: ERROR_MESSAGE.SELECT(LABEL.action_changeShift) }],

						},
						componentProps: {
							style: { width: "100%" },
							placeholder: ERROR_MESSAGE.SELECT(LABEL.action_changeShift),
							disabled: isDisable[NAME.action_changeShift] || false,
						},
						fnChange: handleChange,
						dropdown: options.workShift,
					},
				},
				{
					breakpoint: { xs: 24, sm: 24, md: 24, lg: 24, xl: 4 },
					component: {
						type: COMPONENT_TYPE.DATE,
						itemProps: {
							name: NAME.action_Date,
							label: LABEL.action_Date,
							rules: [{ required: true, message: ERROR_MESSAGE.DATEPICKER }],
						},
						componentProps: {
							style: { width: "100%" },
							placeholder: ERROR_MESSAGE.DATEPICKER,
							disabled: isDisable[NAME.action_Date] || false,
						},
						fnChange: handleChange,
					},
				},
			], // column
		}, // Row
	];
