import { COMPONENT_TYPE, ERROR_MESSAGE } from "../..";

export const DEFAULT_REPORT_LEVEL_WORKER = {
  depNo: undefined,
  endDate: undefined,
  reasonLeave: undefined,
};

const DEFINE = {
  depNo: {
    label: "แผนก",
    name: "depNo",
  },
  userId: {
    label: "รหัสพนักงาน",
    name: "userId",
  },
  startDate: {
    label: "วันที่พ้นสภาพเริ่ม",
    name: "startDate",
  },
  endDate: {
    label: "วันที่พ้นสภาพสิ้นสุด",
    name: "endDate",
  },
  approvedForm: {
    label: "แบบฟอร์มประเภทการลาออก",
    name: "approvedForm",
  },
  reasonLeave: {
    label: "เหตุผลการลาออก",
    name: "reasonLeave",
  },
};
export const ComponentQuitPersonal = ({
  options = {},
  isDisable = {},
  handleChange = () => null,
}) => {
  const { depNo, endDate, reasonLeave, userId, startDate, approvedForm } =
    DEFINE;
  return [
    {
      // Row
      rowProps: { justify: "start", align: "bottom" },
      column: [
        // column
        {
          breakpoint: { xs: 24, sm: 24, md: 12, lg: 6, xl: 4 },
          component: {
            type: COMPONENT_TYPE.DATE,
            itemProps: {
              name: startDate.name,
              label: startDate.label,
              rules: [{ required: false, message: ERROR_MESSAGE.DATEPICKER }],
            },
            componentProps: {
              style: { width: "100%" },
              placeholder: ERROR_MESSAGE.DATEPICKER,
              disabled: isDisable[startDate.name] || false,
            },
            fnChange: (date, dateString) =>
              handleChange(startDate.name, dateString),
          },
        },
        {
          breakpoint: { xs: 24, sm: 24, md: 12, lg: 6, xl: 4 },
          component: {
            type: COMPONENT_TYPE.DATE,
            itemProps: {
              name: endDate.name,
              label: endDate.label,
              rules: [{ required: false, message: ERROR_MESSAGE.DATEPICKER }],
            },
            componentProps: {
              style: { width: "100%" },
              placeholder: ERROR_MESSAGE.DATEPICKER,
              disabled: isDisable[endDate.name] || false,
            },
            fnChange: (date, dateString) =>
              handleChange(endDate.name, dateString),
          },
        },
        {
          breakpoint: { xs: 24, sm: 24, md: 24, lg: 12, xl: 8 },
          component: {
            type: COMPONENT_TYPE.SELECT,
            itemProps: {
              name: depNo.name,
              label: depNo.label,
              rules: [
                { required: false, message: ERROR_MESSAGE.SELECT(depNo.label) },
              ],
            },
            componentProps: {
              style: { width: "100%" },
              placeholder: ERROR_MESSAGE.SELECT(depNo.label),
              disabled: isDisable[depNo.name] || false,
            },
            fnChange: (value) => handleChange(depNo.name, value),
            dropdown: options[depNo.name],
          },
        },
        {
          breakpoint: { xs: 24, sm: 24, md: 24, lg: 12, xl: 8 },
          component: {
            type: COMPONENT_TYPE.SELECT,
            itemProps: {
              name: userId.name,
              label: userId.label,
              rules: [
                {
                  required: false,
                  message: ERROR_MESSAGE.SELECT(userId.label),
                },
              ],
            },
            componentProps: {
              style: { width: "100%" },
              placeholder: ERROR_MESSAGE.SELECT(userId.label),
              disabled: isDisable[userId.name] || false,
            },
            fnChange: (value) => handleChange(userId.name, value),
            dropdown: options[userId.name],
          },
        },

        {
          breakpoint: { xs: 24, sm: 24, md: 12, lg: 12, xl: 8 },
          component: {
            type: COMPONENT_TYPE.SELECT,
            itemProps: {
              name: approvedForm.name,
              label: approvedForm.label,
              rules: [
                {
                  required: false,
                  message: ERROR_MESSAGE.SELECT(approvedForm.label),
                },
              ],
            },
            componentProps: {
              style: { width: "100%" },
              placeholder: ERROR_MESSAGE.SELECT(approvedForm.label),
              disabled: isDisable[approvedForm.name] || false,
            },
            fnChange: (value) => handleChange(approvedForm.name, value),
            dropdown: options[approvedForm.name],
          },
        },
      ], // column
    }, // Row
  ];
};
