import React, { useState } from "react";
import { Form, Card, Spin } from "antd";
import { PermissionView, USE_CONNECT_MODE_AUTO } from "../../services/ConnectAPI";
import { useOptionSelect } from "../../constants/OptionSelect";
import { GET_UserLogin } from "../../functions/UseFunction";
import { useConfirm } from "../modal/CustomConfirm";
import { useAlert } from "../modal/CustomAlert";
import { configForm, WORDS } from "../../constants/ConstantComponent";
import { useRenderFormItem } from "../RenderComponents";
import { ComponentInsertBranchView } from "../../constants/ManageBranchView/Components";

const FormAddBranchView = () => {
  // [START] Handle Form Antd
  const [form] = Form.useForm();
  const { userlogin } = GET_UserLogin();
  // [END] Handle Form Antd

  // [START] States
  const [loadingScreen, setLoadingScreen] = useState(false);

  const optionEmployeeGroup = useOptionSelect("empAll");
  // [END] States

  const onFinish = (values) => {
    const { description, users } = values;
    const objectValue = {
      description,
      userIds: users || [],
      active: true,
      userlogin,
    };
    useConfirm({ FnOK: () => insertPermissionView(objectValue) });
  };

  // [START] REST API
  const insertPermissionView = async (objectValue) => {
    try {
      setLoadingScreen(true);
      const url = `${USE_CONNECT_MODE_AUTO}${PermissionView.POST_CREATE_VIEW}`;
      const response = await fetch(url, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(objectValue),
      });
      const data = await response.json();
      if (response.ok) {
        const genID = data.result;
        useAlert({
          type: "success",
          title: "เพิ่มข้อมูลสำเร็จ",
          content: `รหัสสายการดูข้อมูลคือ : ${genID.flowId}`,
          Fn: () => window.location.reload(),
        });
      } else {
        useAlert({
          type: "error",
          title: "เกิดข้อผิดพลาดจากการเพิ่มข้อมูล",
          content: `${data.message}`,
          Fn: () => setLoadingScreen(false),
        });
      }
    } catch (err) {
      console.log("Error Insert Permission View: ", err);
      setLoadingScreen(false);
      useAlert({ type: "catch", content: `${err.message}` });
    }
  };

  // [END] REST API
  return (
    <Card title="เพิ่มข้อมูลสายการดูข้อมูล" style={{ marginBottom: 30 }}>
      <Spin tip={WORDS.loadingSpin} spinning={loadingScreen}>
        <Form form={form} onFinish={onFinish} {...configForm} >
          {useRenderFormItem({
            constantComponent: ComponentInsertBranchView({
              options: { users: optionEmployeeGroup },
              onClick: () => form.resetFields(),
            })
          })}
        </Form>
      </Spin>
    </Card>
  );
};

export default FormAddBranchView;
