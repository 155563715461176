import React, { useContext, useState, useEffect } from 'react';
import { Spin } from 'antd';

import { PATHNAME } from '../../constants/enums/pathname';

import { MASTER_OPTION, REPORT_OPTION } from '../../moduleKPIs/services/servicesOptions';
import { LayoutTable } from '../../moduleKPIs/common/layouts'

import { FIELD_BTN, TEXT_BTN } from '../commonsEvac/structuresEvac/buttonEvac';
import { TABLE_DASHBOARD_WAIT_EVAC } from '../commonsEvac/structuresEvac/tableEvac'
import { ManageEvacContext } from '../servicesEvac/manageEvac/manageEvacProvider';
import { ReportEvacContext } from '../servicesEvac/reportEvac/reportEvacProvider';
import { ButtonTheme } from '../../moduleKPIs/common/buttons';
import { MasterEvacContext } from '../servicesEvac/masterEvac/masterEvacProvider';

const DashboardWaitEvac = () => {
    const { userLogin } = useContext(ManageEvacContext);
    const { clearMaster } = useContext(MasterEvacContext);
    const { reportEvacState, getReportEvac } = useContext(ReportEvacContext);
    const { evacData: { action } } = reportEvacState;

    const [loading, setLoading] = useState(false);
    async function fetchAPI() {
        await setLoading(true)
        await clearMaster({ fieldOption: MASTER_OPTION.OPTION_DEP_EVAC })
        await getReportEvac({ fieldname: REPORT_OPTION.DASHBOARD_EVAC })
        await setLoading(false)
    }

    useEffect(() => {
        fetchAPI()
    }, [])
    return (
        <div>
            <div className="layout-content-btn-search" style={{ top: 0 }}>
                <ButtonTheme
                    type="primary"
                    style={{ padding: 10 }}
                    buttonText={TEXT_BTN.SEARCH_DASHBOARD_EVAC}
                    onClick={async () => {
                        await setLoading(true)
                        await getReportEvac({ fieldname: REPORT_OPTION.DASHBOARD_EVAC })
                        await setLoading(false)
                    }}
                />
            </div>
            <Spin tip={'กำลังโหลด'} spinning={loading}>
                <LayoutTable
                    style={{ padding: 10 }}
                    scroll={{ x: 600 }}
                    bordered
                    rowKey="formId"
                    dataSource={action}
                    columns={TABLE_DASHBOARD_WAIT_EVAC({
                        userLogin: userLogin,
                        actionButton: async ({ fieldBtn, payload }) => {
                            await setLoading(true)
                            switch (fieldBtn) {
                                case FIELD_BTN.INSERT_EVAC:
                                    await getReportEvac({ fieldname: REPORT_OPTION.SELECT_QUESTION, payload, pathname: `${PATHNAME.INSERT_EVAC}` })
                                    break;

                                // case FIELD_BTN.VIEW_EVAC:
                                //     await getReportEvac({ fieldname: REPORT_OPTION.SELECT_QUESTION, payload, pathname: `${PATHNAME.VIEW_EVAC}` })
                                //     break;
                                default:
                                    break;
                            }
                            await setLoading(false)
                        }
                    })}
                    pagination={{
                        pageSize: 50,
                        showTotal: (total) => `ทั้งหมด ${total || 0} รายการ`

                    }}
                />
            </Spin>
        </div>
    )
}

export default DashboardWaitEvac