import React, { Component } from "react";
import "../index.css";
import "bootstrap/dist/css/bootstrap.css";
import { Col, Row, Button } from "reactstrap";
import { FiPrinter } from "react-icons/fi";
import encodeString from '../extendFunction/encodeString';
import {
  GET,
  GET_DETAIL_SAF
} from "../service";
// const api = connectAPI.API_URL;

class PrintSelfAssessment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loadData: [],
    };
  }

  componentDidMount() {
    this.selectData();
  }

  selectData = async () => {
    const docNo = encodeString.decode(this.props.params.DocNos);
    const res = await GET(GET_DETAIL_SAF(docNo))
    // console.log('res', res)
    const { success, result } = res;
    let loadData = [];
    if (success) {
      if (result.length !== 0) {
        loadData = result
      }
      this.setState({ loadData });
    } else {
      console.log("error selectData");
      this.setState({ loading: false });
    }
  }

  printSel = () => {
    window.print();
  };

  render() {
    var data = this.state.loadData;

    return (
      <div>
        <Row>
          <Col className="no-printme">
            <Button color="info" onClick={this.printSel}>
              <FiPrinter className="margin-icon" /> Print
            </Button>
          </Col>
        </Row>
        {data.map((val, index) => (
          <div id="printableArea" className="printme">
            <Row className="ref-number">
              <Col>{val.DocNo}</Col>
            </Row>
            <Row>
              <Col className="text-center header-from">
                แบบประเมินตนเอง (SELF ASSESSMENT FORM){" "}
                {val.CF_Send === 1 ? "" : "| (ฉบับร่าง)"}
              </Col>
            </Row>
            <Row>
              <Col md={10}>
                <Row className="padding-10">
                  <Col>
                    <b>ชื่อผู้ทำแบบประเมิน :</b> {val.staffName}
                  </Col>
                  <Col>
                    <b>ตำแหน่ง :</b> {val.PositionNameEN}
                  </Col>
                </Row>
                <Row className="padding-10">
                  <Col>
                    <b>ชื่อผู้บังคับบัญชา :</b> {val.bossName}
                  </Col>
                  <Col>
                    <b>ตำแหน่ง :</b> {val.PositionNameEN_Boss}
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <b>การประเมินตนเองประจำปี : {val.year}</b>
                  </Col>
                  <Col>
                    <b>{val.typeDes}</b>
                  </Col>
                </Row>
              </Col>
              <Col md={2} className="frame-score">
                <b>
                  คะแนนประเมินตนเอง<br></br>
                  {val.selfScore}
                </b>
              </Col>
            </Row>

            <Row className="border-from padding-25">
              <Col>
                <Row className="bg-headfrom padding-10 head-qu">
                  <Col>
                    <b>1. ผลการดำเนินการปีที่ผ่านมา (Performance)</b>
                  </Col>
                </Row>
                <Row className="padding-10">
                  {/* <Col>
                    <b>1.1 ผลสำเร็จตามเป้าหมาย -</b>
                    คิดเห็นอย่างไรกับผลสำเร็จที่ทำได้ในช่วงเวลาที่ผ่านมา
                  </Col> */}
                </Row>
                <Row className="answer padding-10">
                  <Col>
                    <b>1.1) ผลสำเร็จตามเป้าหมาย</b>
                  </Col>
                </Row>
                <Row className="answer padding-10">
                  <Col><span className="text-area">{val.targetSuccess} </span></Col>
                </Row>

                {/* <Row className="padding-10">
                  <Col>
                    <b>1.2 ผลงานที่ยังไม่บรรลุตามความคาดหวัง - </b>
                    ผลงานที่อยากปรับปรุงพัฒนาให้ดีขึ้นมีอะไรบ้าง
                    คิดว่ามีสาเหตุจากอะไร และมีแนวทางแก้ไขอย่างไร
                  </Col>
                </Row> */}
                <Row className="answer padding-10">
                  <Col>
                    <b>1.2) ผลงานที่ยังไม่บรรลุตามเป้าหมาย</b>
                  </Col>
                </Row>
                <Row className="answer padding-10">
                  <Col><span className="text-area">{val.targetNotSuccess}</span></Col>
                </Row>
                {/* <Row className="answer padding-10">
                  <Col>
                    <b>1.2.2) สาเหตุที่ยังไม่บรรลุตามเป้าหมาย</b>
                  </Col>
                </Row>
                <Row className="answer padding-10">
                  <Col><span className="text-area">{val.causesNotSuccess}</span></Col>
                </Row> */}
                <Row className="answer padding-10">
                  <Col>
                    <b>1.3) อุปสรรคต่างๆที่พบ และ แนวทางพัฒนา แก้ไขปัญหา เพื่อบรรลุเป้าหมาย</b>
                  </Col>
                </Row>
                <Row className="answer padding-10">
                  <Col><span className="text-area">{val.barrier} </span></Col>
                </Row>
                {/* <Row className="answer padding-10">
                  <Col>
                    <b>
                      1.2.4)
                      เราจะมีแนวทางพัฒนาหรือแก้ไขปัญหาร่วมกันอย่างไรได้บ้าง
                    </b>
                  </Col>
                </Row>
                <Row className="answer">
                  <Col><span className="text-area">{val.solution} </span></Col>
                </Row> */}

                <Row className="answer padding-10">
                  <Col>
                    <b>1.4) สิ่งที่ได้เรียนรู้และพัฒนาในปีที่ผ่านมา</b>
                  </Col>
                </Row>
                <Row className="answer padding-20">
                  <Col><span className="text-area">{val.learnDeveloped}</span></Col>
                </Row>
              </Col>
            </Row>
            <Row className="padding-25">
              <Col>
                <Row className="head-qu">
                  <Col>
                    <b>2. ความรู้ ความเชี่ยวชาญในงาน</b>
                  </Col>
                </Row>
                <br />
                <Row className="answer">
                  <Col md={1} className="border-col bg-table">
                    <b>ลำดับที่</b>
                  </Col>
                  <Col md={6} className="border-col bg-table">
                    <b> สิ่งที่อยากพัฒนา ปรับปรุง -  มีเรื่องอะไรบ้างที่อยากเรียนรู้พัฒนาเพิ่มเติม เพื่อช่วยให้ทำงานได้ง่ายขึ้น ดีขึ้น (3อันดับแรก) </b>
                  </Col>
                  <Col md={5} className="border-col bg-table">
                    <b>เหตุผลที่อยากเรียนรู้ พัฒนา (สำหรับแต่ละหัวข้อ)</b>
                  </Col>
                </Row>
                <Row className="answer">
                  <Col md={1} className="border-col text-center-body">
                    1
                  </Col>
                  <Col md={6} className="border-col">
                    <span className="text-area">
                      {val.km_1}
                    </span>
                  </Col>
                  <Col md={5} className="border-col">
                    <span className="text-area">
                      {val.reason_1}</span>
                  </Col>
                </Row>
                <Row className="answer">
                  <Col md={1} className="border-col text-center-body">
                    2
                  </Col>
                  <Col md={6} className="border-col">
                    <span className="text-area">
                      {val.km_2 === '' ? '-' : val.km_2}</span>
                  </Col>
                  <Col md={5} className="border-col">
                    <span className="text-area">
                      {val.reason_2 === '' ? '-' : val.reason_2}</span>
                  </Col>
                </Row>
                <Row className="answer padding-10">
                  <Col md={1} className="border-col text-center-body">
                    3
                  </Col>
                  <Col md={6} className="border-col">
                    <span className="text-area">
                      {val.km_3 === '' ? '-' : val.km_3}</span>
                  </Col>
                  <Col md={5} className="border-col">
                    <span className="text-area">
                      {val.reason_3 === '' ? '-' : val.reason_3}</span>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row className="padding-25">
              <Col>
                <Row className="padding-10 head-qu">
                  <Col>
                    <b>3.ข้อเสนอแนะ และขอคำปรึกษา</b>
                  </Col>
                </Row>
                <Row className="padding-10">
                  <Col>
                    <b>3.1 สิ่งที่อยากเสนอแนะผู้บังคับบัญชา -</b>
                    การทำงานร่วมกันเป็นอย่างไรบ้าง มีสิ่งที่อยากให้
                    ผู้บังคับบัญชาปรับหรือสนับสนุน (Feedback)
                  </Col>
                </Row>
                <Row className="answer padding-20">
                  <Col><span className="text-area">
                    {val.feedback === '' ? '-' : val.feedback}</span>
                  </Col>
                </Row>
                <Row className="padding-10">
                  <Col>
                    <b>3.2 สิ่งที่อยากเสนอแนะองค์กร - </b>  สิ่งที่อยากให้องค์กรปรับเพิ่มเติม
                  </Col>
                </Row>
                <Row className="answer">
                  <Col><span className="text-area">
                    {val.organComment === '' ? '-' : val.organComment}</span>
                  </Col>
                </Row>

                <Row className="padding-10">
                  <Col>
                    <b>3.3 ความเห็นอื่นๆ -</b> ประเด็นอื่นๆ
                    ที่อยากหารือกับผู้บังคับบัญชา / อยากขอบคุณใคร หรือ
                    หน่วยงานใด สำหรับการทำงานในปีนี้
                  </Col>
                </Row>
                <Row className="answer">
                  <Col><span className="text-area">
                    {val.otherComment === '' ? '-' : val.otherComment}
                  </span>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row className="padding-25">
              <Col>
                <Row className="padding-10 head-qu">
                  <Col>
                    <b>4.ความร่วมมือ และความคิดริเริ่ม</b>
                  </Col>
                </Row>
                <Row className="padding-10">
                  <Col>
                    4.1 ปีที่ผ่านมา เราช่วยให้ "ใคร" ในองค์กรได้ทำงานให้ประสบความสำเร็จบ้าง / "ใคร" ช่วยผลักดันงานของเราให้สำเร็จบ้าง
                  </Col>
                </Row>
                <Row className="answer padding-20">
                  <Col><span className="text-area">
                    {val.whoHelp === '' ? '-' : val.whoHelp}</span>
                  </Col>
                </Row>
                <Row className="padding-10">
                  <Col>
                    4.2 ปีที่ผ่านมาเอา "ไอเดีย/ความคิดริเริ่ม" ไหนขององค์กร หรือ ฝ่ายอื่นมาต่อยอดได้บ้าง  
                  </Col>
                </Row>
                <Row className="answer">
                  <Col><span className="text-area">
                    {val.adaptIdea === '' ? '-' : val.adaptIdea}</span>
                  </Col>
                </Row>

                <Row className="padding-10">
                  <Col>
                   4.3 ถ้ามีโอกาสสร้างการเปลี่ยนแปลงในองค์กรได้ มีโครงการ มีไอเดียไหนที่น่าทำและอยากทำบ้าง (สามารถเสนอของหน่วยงานตัวเอง หรือหน่วยงานอื่น ๆ ได้)
                  </Col>
                </Row>
                <Row className="answer">
                  <Col><span className="text-area">
                    {val.changeOrg === '' ? '-' : val.changeOrg}
                  </span>
                  </Col>
                </Row>
              </Col>
            </Row>
            <br></br>
            <Row className="text-center">
              <Col>
                <Row>
                  <Col>
                    ผู้ประเมินตนเองลงนาม.................................................................
                  </Col>
                  <Col>
                    ผู้บังคับบัญชาลงนาม..................................................................
                  </Col>
                </Row>
                <br></br>
                <Row>
                  <Col>
                    วันที่
                    ........................../............................./............................
                  </Col>
                  <Col>
                    วันที่
                    ........................../............................./............................
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        ))}
      </div>
    );
  }
}

export default PrintSelfAssessment;
