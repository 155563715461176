import React, { Fragment } from "react";
import {
  filterSearchColumn,
  useSorterTable,
} from "../../functions/UseFunction";
import { Form, Input, Select, Tag } from "antd";
import { useInputNumber } from "../../conponents/inputs/InputAnt";

export const ColumnInsertSaleTargetFSP = ({ actions }) => [
  {
    title: "ลำดับ",
    align: "right",
    width: 25,
    render: (_id, _record, index) => {
      ++index;
      return index;
    },
  },
  {
    title: "สาขา",
    dataIndex: "roleId",
    key: "roleId",
    align: "left",
    // fixed: "left",
    width: 100,
    ...filterSearchColumn("roleId", "สาขา"),
  },
  {
    title: "Temp",
    dataIndex: "tempTarget",
    key: "tempTarget",
    align: "center",
    // fixed: "left",
    width: 100,
    ...filterSearchColumn("tempTarget", "Temp"),
    render: (text, record) => {
      const { onChangeValue } = actions;
      return (
        <Fragment>
          <Form.Item
            style={{ padding: "0px", margin: "0px" }}
            name={`tempTarget_${record.key}`}
            initialValue={text}
          >
            {useInputNumber({
              name: `tempTarget`,
              componentProps: {
                style: { width: "100%" },
              },
              fnChange: (e) => {
                onChangeValue({
                  key: record.key,
                  value: e.target.value,
                  fieldName: "tempTarget",
                });
              },
            })}
          </Form.Item>
        </Fragment>
      );
    },
  },
  {
    title: "Acc",
    dataIndex: "accTarget",
    key: "accTarget",
    align: "center",
    // fixed: "left",
    width: 100,
    ...filterSearchColumn("accTarget", "Acc"),
    render: (text, record) => {
      const { onChangeValue } = actions;
      return (
        <Fragment>
          <Form.Item
            style={{ padding: "0px", margin: "0px" }}
            name={`accTarget_${record.key}`}
            initialValue={text}
          >
            {useInputNumber({
              name: "accTarget",
              componentProps: {
                style: { width: "100%" },
              },
              fnChange: (e) => {
                onChangeValue({
                  key: record.key,
                  value: e.target.value,
                  fieldName: "accTarget",
                });
              },
            })}
          </Form.Item>
        </Fragment>
      );
    },
  },
  {
    title: "จำนวนคนในสาขา",
    dataIndex: "saleQTY",
    key: "saleQTY",
    align: "center",
    // fixed: "left",
    width: 100,
    ...filterSearchColumn("saleQTY", "จำนวนคนในสาขา"),
    render: (text, record) => {
      const { onChangeValue } = actions;
      return (
        <Fragment>
          <Form.Item
            style={{ padding: "0px", margin: "0px" }}
            name={`saleQTY_${record.key}`}
            initialValue={text}
          >
            {useInputNumber({
              name: "saleQTY",
              componentProps: {
                style: { width: "100%" },
              },
              fnChange: (e) => {
                onChangeValue({
                  key: record.key,
                  value: e.target.value,
                  fieldName: "saleQTY",
                });
              },
            })}
          </Form.Item>
        </Fragment>
      );
    },
  },
];

export const ColumnViewInsertSaleTargetFSP = () => [
  {
    title: "ลำดับ",
    align: "right",
    width: 25,
    render: (_id, _record, index) => {
      ++index;
      return index;
    },
  },
  {
    title: "สาขา",
    dataIndex: "roleId",
    key: "roleId",
    align: "left",
    // fixed: "left",
    width: 100,
    ...filterSearchColumn("roleId", "สาขา"),
  },

  {
    title: "Temp",
    dataIndex: "tempTarget",
    key: "tempTarget",
    align: "right",
    // fixed: "left",
    width: 100,
    ...filterSearchColumn("tempTarget", "Temp"),
    ...useSorterTable("tempTarget", "number"),
    render: (text) => text.toLocaleString(),
  },
  {
    title: "Acc",
    dataIndex: "accTarget",
    key: "accTarget",
    align: "right",
    // fixed: "left",
    width: 100,
    ...filterSearchColumn("accTarget", "Acc"),
    ...useSorterTable("accTarget", "number"),
    render: (text) => text.toLocaleString(),
  },
  {
    title: "จำนวนคนในสาขา",
    dataIndex: "saleQTY",
    key: "saleQTY",
    align: "right",
    // fixed: "left",
    width: 100,
    ...filterSearchColumn("saleQTY", "จำนวนคนในสาขา"),
    ...useSorterTable("saleQTY", "number"),
    render: (text) => text.toLocaleString(),
  },
];
