// var _year = new Date().getFullYear()
// var _month = new Date().getUTCMonth()  // getMonth method returns the month of the date (0-January :: 11-December)

// const chkMonth = [11, 0, 1, 2, 3, 4]
// let yearsSelect = _year
// let typeSelect = "Half"
// if (chkMonth.indexOf(_month) > -1) {  //เจอข้อมูลในช่วยเดือน 1 / 2 จะเป็นการประเมินรอบปลายปี 
//     typeSelect = 'Full'
//     if (_month !== 11) {
//         yearsSelect = _year - 1
//     }

// }

// module.exports = {
//     typeCurrent: typeSelect,
//     yearsCurrent: yearsSelect
// };
export const getCurrent = () => {
    const _year = new Date().getFullYear()
    const _month = new Date().getUTCMonth()  // getMonth method returns the month of the date (0-January :: 11-December)
    const _monthNow = new Date().getMonth() + 1  // ใช้ในหน้า Import ข้อมูลเงินได้พิเศษจาก KPI
    const chkMonth = [10, 11, 0, 1, 2, 3, 4]
    let yearsSelect = _year
    let typeSelect = "Half"
    if (chkMonth.indexOf(_month) > -1) {  //เจอข้อมูลในช่วยเดือน 1 / 2 จะเป็นการประเมินรอบปลายปี 
        typeSelect = 'Full'
        if (_month !== 11 && _month !== 10) {
            yearsSelect = _year - 1

        }

    }


    return { yearsSelect, typeSelect, yearsNow: _year, monthNow: _monthNow }
}
