import { responseAPI } from "../../../moduleKPIs/common/functions/funcResponse";
import servicesAPI from "../../../moduleKPIs/services/servicesAPI";

let API = servicesAPI.create()


export async function APIInsertTraining(payload, nextAction) {
    try {
        const response = await API.insertTraining(payload);
        //console.log(response)
        responseAPI(response, nextAction)

    } catch (error) {
        console.error('error : ', error)
    }
}
export async function APIInsertTrainingHistory(payload, nextAction) {
    try {
        const response = await API.insertTrainingHistory(payload);
        responseAPI(response, nextAction)
    } catch (error) {
        console.error('error : ', error)
    }
}

export async function APIUpdateTraining(payload, nextAction) {
    try {
        const response = await API.updateTraining(payload);
        responseAPI(response, nextAction)
    } catch (error) {
        console.error('error : ', error)
    }
}

export async function APIViewTraining(payload, nextAction) {
    try {
        const response = await API.viewTraining(payload);
        //console.log(response)
        responseAPI(response, nextAction)
    } catch (error) {
        console.error('error : ', error)
    }
}


export async function APIImportTraining(payload, nextAction) {
    try {
        const response = await API.importTraining(payload);
        //console.log({ response })
        responseAPI(response, nextAction)
    } catch (error) {
        console.error('error : ', error)
    }
}

export async function APIRemoveTraining(payload, nextAction) {
    try {
        const response = await API.removeTraining(payload);
        responseAPI(response, nextAction)
    } catch (error) {
        console.error('error : ', error)
    }
}

export async function APIInsertTrainingFile(payload, nextAction) {
    try {
        const response = await API.insertTrainingFile(payload);
        responseAPI(response, nextAction)
    } catch (error) {
        console.error('error : ', error)
    }
}

export async function APIInsertOthersFile(payload, nextAction) {
    try {
        const response = await API.insertOthersFile(payload);
        responseAPI(response, nextAction)
    } catch (error) {
        console.error('error : ', error)
    }
}
export async function APIDownloadTrainingFile(payload, nextAction) {
    try {
        const response = await API.downloadTrainingFile(payload);
        responseAPI(response, nextAction)
    } catch (error) {
        console.error('error : ', error)
    }
}