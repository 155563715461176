import React, { Fragment } from 'react'
import { Button, Col, Form, Row } from 'antd'
import { DeleteFilled, PlusOutlined } from '@ant-design/icons'

import { RenderInput } from '.'
import { colors } from '../../themes'


const LayoutLists = React.forwardRef((element, ref) => {
    return (
        <Form.List
            ref={ref}
            name={element.name} initialValue={element.initialvalue}>
            {(fields, { add, remove }) => (
                <>
                    {fields.map((field, i) => (
                        <Fragment key={field.key} >
                            {
                                (element.header && !+field.key) &&
                                <div style={{ padding: '10px 0px', color: 'red' }}>
                                    {element.header(i)}
                                </div>
                            }

                            <Row gutter={[24, 5]} >
                                {Array.isArray(element.content(field)) && element.content(field).map((con, idCon) => (
                                    <Col key={idCon}  {...con}>
                                        <Form.Item
                                            shouldUpdate={(prevValues, curValues) =>
                                                JSON.stringify(prevValues[element.name]) !== JSON.stringify(curValues[element.name])
                                            }
                                            {...con.itemprops}
                                        >
                                            <RenderInput
                                                value={field.name}
                                                {...con.inputprops} />
                                        </Form.Item>
                                    </Col>
                                ))}
                                {
                                    (field.fieldKey === 0) ?
                                        !element.disabled && <Button
                                            type="primary"
                                            style={{ position: 'relative', top: 31, backgroundColor: colors.theme, borderColor: colors.theme, }}
                                            icon={<PlusOutlined style={{ ...styles }} />}
                                            onClick={() => add()} />
                                        :
                                        (field.fieldKey >= element.removelenght) &&
                                        !element.disabled && <Button
                                            type="primary" danger
                                            style={{ position: 'relative', top: 2 }}
                                            icon={<DeleteFilled style={{ ...styles }} />}
                                            onClick={() => remove(field.name)} />
                                }
                            </Row>
                        </Fragment>
                    ))}
                </>
            )}
        </Form.List >
    )
})

export default LayoutLists

/* -------------------------------------------------------------------------- */
/*                                    STYLE                                   */
/* -------------------------------------------------------------------------- */
const styles = {
    display: 'flex', alignItems: 'center', justifyContent: 'center'
}