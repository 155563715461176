import React, { useState } from "react";
import { Button, Card, Tabs } from "antd";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";

import BenefitPdf from "../assets/files/Benefit_DPL_and_DPT_2566.pdf";
import HolidayPdf from "../assets/files/Holiday_2024.pdf";
import SafetyGuidePdf from "../assets/files/Safety_guide_2567.pdf";

export const BenefitAndHoliday = () => {
  const { TabPane } = Tabs;
  const [numPages, setNumPages] = useState(null);
  const [numHolidayPages, setNumHolidayPages] = useState(null);
  const [numSafetyPages, setNumSafetyPages] = useState(null);

  const onBenefitLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const onHolidayLoadSuccess = ({ numPages }) => {
    setNumHolidayPages(numPages);
  };

  const onSafetyLoadSuccess = ({ numPages }) => {
    setNumSafetyPages(numPages);
  };

  const onClickBenefit = () => {
    fetch(BenefitPdf).then((response) => {
      response.blob().then((blob) => {
        const fileURL = window.URL.createObjectURL(blob);
        let alink = document.createElement("a");
        alink.href = fileURL;
        alink.download = BenefitPdf;
        alink.click();
      });
    });
  };

  const onClickHoliday = () => {
    fetch(HolidayPdf).then((response) => {
      response.blob().then((blob) => {
        const fileURL = window.URL.createObjectURL(blob);
        let alink = document.createElement("a");
        alink.href = fileURL;
        alink.download = HolidayPdf;
        alink.click();
      });
    });
  };

  const onClickSafety = () => {
    fetch(SafetyGuidePdf).then((response) => {
      response.blob().then((blob) => {
        const fileURL = window.URL.createObjectURL(blob);
        let alink = document.createElement("a");
        alink.href = fileURL;
        alink.download = SafetyGuidePdf;
        alink.click();
      });
    });
  };

  return (
    <Tabs defaultActiveKey="1">
      <TabPane tab="สวัสดิการพนักงาน" key="1">
        <div style={{ height: "100vh", width: "100%" }}>
          <Card
            style={{ height: "100vh", width: "100%" }}
            extra={<Button onClick={onClickBenefit}>Download PDF</Button>}
          >
            <center>
              <div
                style={{
                  height: "calc(100vh - 100px)",
                  width: "100%",
                  overflowY: "scroll",
                }}
              >
                <Document
                  file={BenefitPdf}
                  onLoadSuccess={onBenefitLoadSuccess}
                >
                  {Array.from(new Array(numPages), (el, index) => (
                    <Page key={`page_${index + 1}`} pageNumber={index + 1} />
                  ))}
                </Document>
              </div>
            </center>
          </Card>
        </div>
      </TabPane>
      <TabPane tab="วันหยุดประจำปี" key="2">
        <Card
          style={{ height: "100vh", width: "100%" }}
          extra={<Button onClick={onClickHoliday}>Download PDF</Button>}
        >
          <center>
            <div
              style={{
                height: "calc(100vh - 100px)",
                width: "100%",
                overflowY: "scroll",
              }}
            >
              <Document file={HolidayPdf} onLoadSuccess={onHolidayLoadSuccess}>
                {Array.from(new Array(numHolidayPages), (el, index) => (
                  <Page key={`page_${index + 1}`} pageNumber={index + 1} />
                ))}
              </Document>
            </div>
          </center>
        </Card>
      </TabPane>
      <TabPane tab="คู่มือความปลอดภัย" key="3">
        <Card
          style={{ height: "100vh", width: "100%" }}
          extra={<Button onClick={onClickSafety}>Download PDF</Button>}
        >
          <center>
            <div
              style={{
                height: "calc(100vh - 100px)",
                width: "100%",
                overflowY: "scroll",
              }}
            >
              <Document
                file={SafetyGuidePdf}
                onLoadSuccess={onSafetyLoadSuccess}
              >
                {Array.from(new Array(numSafetyPages), (el, index) => (
                  <Page key={`page_${index + 1}`} pageNumber={index + 1} />
                ))}
              </Document>
            </div>
          </center>
        </Card>
      </TabPane>
    </Tabs>
  );
};

export default BenefitAndHoliday;
