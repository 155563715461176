import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.css";
import "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css";
import { Col, Row, Form } from "reactstrap";
import TrackProbationReport from "../components/benefits/TrackProbationReport";
import * as Layouts from "../constants/layouts";
import { INITIAL_FILTER_PROBATION } from "../components/probation/initialProbation";
import { APIProbation, APIDashboardBenefit, GET, GET_SELECT_OPTION_ALL, POST, POST_SELECT_DASHBOARD_PROBATION } from "../service";
import { SetDateAPI } from "../common/setDate";
import { Spin, Modal } from "antd";
import { filterDashboardReportTrackProbation } from "../moduleReport/constantsReport/searchform";
import { optionProbationStatus, optionLocation, optionStatusWorkerCode } from '../common/enum'
import { RenderForm } from './../constants/inputs/RenderForm';

class ReportTrackProbation extends Component {
	constructor(props) {
		super(props);
		const { locationM, userLogin } = props
		this.state = {
			loading: false,
			userLogin,
			initialFilter: INITIAL_FILTER_PROBATION,
			dataSource: [],
			StartDateJob: "",
			toDateJob: "",
			optionSegment: [],
			SegmentTxt: "",
			locationID: "",
			statusWorkerCode: "",
			statusProbationCode: '',
			txtUserSeaching: '',
			locationLogin: locationM,
		};
	}

	componentDidMount() {
		this.fetchAPI()
	}

	fetchAPI = async () => {
		try {
			this.setState({ loading: true })
			let res = await GET(GET_SELECT_OPTION_ALL())
			const { result, success } = res
			if (success) {
				let departmentLists = [];
				result['department'].map(el => {
					departmentLists.push({ label: el.depNameTH, value: el.depNo })
				})
				let optionSegment = [{ value: "", label: "----- ทั้งหมด -----" }, ...departmentLists]
				this.setState({ optionSegment })
			}
		} catch (error) {
			console.log({ error })
			this.setState({ loading: false })
		} finally {
			this.setState({ loading: false })
		}

	}

	//----- Start :: Function -----//

	handleFilter = (fieldname, value) => {
		//console.log(fieldname, " : ", value)
		let initialFilter = { ...this.state.initialFilter }
		let fieldNameSet = ''
		switch (fieldname) {
			case "depNo":
				initialFilter = { ...initialFilter, [fieldname]: value };
				fieldNameSet = 'SegmentTxt'
				break;

			case "location":
				initialFilter = { ...initialFilter, [fieldname]: value };
				fieldNameSet = 'locationID'
				break;

			case "userSeaching":
				initialFilter = { ...initialFilter, [fieldname]: value };
				fieldNameSet = "txtUserSeaching"
				break;

			case "statusWorker":
				initialFilter = { ...initialFilter, [fieldname]: value };
				fieldNameSet = "statusWorkerCode"
				break;

			case "statusProbation":
				initialFilter = { ...initialFilter, [fieldname]: value };
				fieldNameSet = "statusProbationCode"
				break;

			default:
				initialFilter = { ...initialFilter, [fieldname]: value };
				break;
		}
		this.setState({ initialFilter, [fieldNameSet]: value })

	};

	onSearch = async () => {
		try {
			this.setState({ loading: true })
			const { initialFilter, statusProbationCode, statusWorkerCode, userLogin, locationID, SegmentTxt, txtUserSeaching } = this.state;
			let payload = {
				user_login: userLogin,
				locationID: locationID,
				SegmentTxt: SegmentTxt,
				txtUserSeaching: txtUserSeaching,
				statusWorker: statusWorkerCode,
				statusProbation: statusProbationCode,
				pageType: 'TrackProbation',
				StartDateJob: SetDateAPI(initialFilter.StartDateJob),
				toDateJob: SetDateAPI(initialFilter.toDateJob),
				premission: this.props.premission
			}

			this.setState({ loading: true })
			await APIDashboardBenefit(payload, (res) => {
				// console.log("APIDashboardBenefit ::", res);
				if (res) {
					this.setState({ dataSource: res })
				}
				this.forceUpdate();
			})

		} catch (error) {
			this.setState({ loading: false })
			console.log({ error })
		} finally {
			this.setState({ loading: false })

		}
	};
	onSearch2 = async ({ userLogin }) => {
		try {
			const { locationID, SegmentTxt, txtUserSeaching, statusWorkerCode, statusProbationCode, initialFilter } = this.state;

			this.setState({ loading: true })
			let payload = {
				user_login: userLogin,
				locationID: locationID,
				SegmentTxt: SegmentTxt,
				txtUserSeaching: txtUserSeaching,
				statusWorker: statusWorkerCode,
				statusProbation: statusProbationCode,
				pageType: 'TrackProbation',
				StartDateJob: SetDateAPI(initialFilter.StartDateJob),
				toDateJob: SetDateAPI(initialFilter.toDateJob),
				// premission: this.props.premission
			}
			await APIDashboardBenefit(payload, (res) => {
				if (res) {
					this.setState({ dataSource: res })
				}
				// this.forceUpdate();
			})

		} catch (error) {
			this.setState({ loading: false })
			console.log({ error })
		} finally {
			this.setState({ loading: false })

		}


	};

	getSelectDashboardProbation = async () => {
		try {
			this.setState({ loading: true });
			const { locationID, SegmentTxt, initialFilter, txtUserSeaching, statusProbationCode, statusWorkerCode, userLogin, locationLogin, } = this.state;
			const objectBody = {
				userlogin: userLogin,
				locationLogin,
				depNo: SegmentTxt || "",
				subDepNo: "",
				statusMainCode: "",
				startDate: SetDateAPI(initialFilter.StartDateJob),
				toDate: SetDateAPI(initialFilter.toDateJob),
				report: "",
				statusWorker: statusWorkerCode || "",
				currentStatus: "",
				statusProbation: statusProbationCode || "",
				type: "",
				location: locationID || "",
				company: "",
				searchUser: txtUserSeaching || "",
				pageType: "trackProbation",
			}
			const res = await POST(POST_SELECT_DASHBOARD_PROBATION, objectBody)
			const { result } = res
			//console.log("res", res)
			let mapDataSource = [];
			if (result.length !== 0) {
				mapDataSource = result.map((row, index) => ({ key: `${row.StaffCode}_${index}`, ...row }))
			}
			this.setState({ dataSource: mapDataSource })
		} catch (err) {
			console.log("Error getSelectDashboardProbation: ", err)
			Modal.error({ title: "เกิดข้อผิดพลาด", content: `${err.message}` })
		} finally {
			this.setState({ loading: false });
		}

	}
	//----- End :: Function -----//


	handleLocation = async ({ pathname, state, status }) => {
		try {
			const { router } = this.props;
			//console.log("router", router)
			let initialData = null;
			let payload = { docNo: state.DocNo };
			this.setState({ loading: true });
			await APIProbation(payload).then(res => {
				initialData = res.result
				router.push({ pathname, state: { ...initialData } });
			});
		} catch (error) {
			console.log({ error })
			this.setState({ loading: false });
		} finally {

			this.setState({ loading: false });
		}
	};

	render() {
		const { loading } = this.state;
		return (
			<Spin spinning={loading} tip="กำลังโหลด...">
				<Layouts.MainTheme
					header={"รายการค้างรับเอกสารทดลองงาน"}
					component={this.mapArray()}
				/>
				<TrackProbationReport
					userLogin={this.state.userLogin}
					dataSource={this.state.dataSource}
					fncSearch={this.onSearch2}
					handleLocation={this.handleLocation}
				/>
			</Spin>
		);
	}

	mapArray = () => {
		const { optionSegment, txtUserSeaching, txtOption, initialFilter } = this.state
		return (
			<Form>
				{filterDashboardReportTrackProbation({
					handlechange: this.handleFilter,
					options: {
						optionLocation,
						optionSegment,
						optionProbationStatus,
						optionStatusWorkerCode
					},
					txtOption: {
						txtUserSeaching,
						txtOption,
						// txtOptionStatusProbation,
					},
					initialFilter,
					//onClick: this.onSearch
					onClick: this.getSelectDashboardProbation
				}).map((row, i) => (
					<Row key={`content_${i}`}>
						{row.map((each, iR) => (
							<Col key={`row_${iR}`} md={each.size} className={each.className}>
								{RenderForm(each)}
							</Col>
						))}
					</Row>
				))}
			</Form>
		);
	};
}

export default ReportTrackProbation;
