import React, { useState }  from 'react';
import { Table } from 'antd';
import styled from 'styled-components'

const TableStyle = styled.div`
display:contents;
	min-width: 100vh;
    margin-top: -15px;
}`

const LayoutTable = ({ rowKey = '', columns = [], dataSource = [], rowSelection, hasCheckbox, onChange, selectedRowKey, getCheckboxProps, ...rest }) => {
    // const [selectedRowKeys, setSelectedRowKeys] = useState([])
    const selectedRowKeys = [];
    const [size, setPageSize] = useState(16)
    // const [current, setCurrent] = useState(1)
    const hasSelected = Array.isArray(selectedRowKeys) && (selectedRowKeys).length > 0;
    return (
        <TableStyle>
            {hasSelected ? `Selected ${Array.isArray(selectedRowKeys) && (selectedRowKeys).length} items` : ''}

            <Table
                bordered
                size="small"
                rowKey={rowKey}
                columns={columns}
                dataSource={dataSource}
                scroll={{ x: 'max-content' }}
                hasData={Array.isArray(dataSource) && dataSource.length > 0}
                rowSelection={
                    (rowSelection) ?
                        rowSelection
                        :
                        hasCheckbox ?
                            {
                                selectedRowKeys: selectedRowKey,
                                onChange: (selectedRowKeys, selectedRows) => onChange({ key: 'selectedRowKeys', value: { selectedRowKeys, selectedRows } }),
                                selections: [Table.SELECTION_ALL],
                                getCheckboxProps,
                            }
                            :
                            null
                }
                pagination={{
                    total: Array.isArray(dataSource) && (dataSource).length,
                    pageSize: size,
                    showTotal: total => `Total ${total} items`,
                    defaultPageSize: 10,
                    defaultCurrent: 1,
                    showSizeChanger: true,
                    pageSizeOptions: [16, 30, 60, 100, 500],
                    onShowSizeChange: (current, size) => {
                        setPageSize(size)
                        // setCurrent(current)
                    }
                }}
                {...rest}
            />
        </TableStyle>
    )
}

export default LayoutTable