import React, { useState } from "react";
import { Form, Card, Spin, Space } from "antd";
import { useOptionSelect } from "../../constants/OptionSelect";
import { toUndefined, sumColumnWidth, subStringDate } from "../../functions/UseFunction";
import { useAlert } from "../modal/CustomAlert";
import { configForm } from "../../constants/ConstantComponent";
import { useRenderFormItem } from "../RenderComponents";
import CustomTableAtnd from "../tables/CustomTableAtnd";
import { useNotification } from "../notification/NotificationWithIcon";
import { POST, POST_SELECT_DATA_REQ_OT_DETAIL } from "../../../service";
import { useButton } from "../buttons/CustomButtonAntd";
import { ColumnReportOT } from "../../constants/Reports/Columns";
import { useExportExcel } from "../../services/ExportExcel";
import { Header_ReportOT } from "../../constants/HeaderExcel";
import { ComponentReportOT } from "../../constants/Reports/Components/ComponentReportOT";
import { useFixedQueryString } from "../../functions/UseMakeQueryString";

const FormReportOT = () => {
    const [form] = Form.useForm();
    // [START] States
    const [ChangeValue, setChangeValue] = useState({});
    const [dataTable, setDataTable] = useState([]);
    const [loadingScreen, setLoadingScreen] = useState(false);

    // [END] States
    let optionSearch = {
        userId: useOptionSelect("empAll"),
        depNo: useOptionSelect("department"),
        subDepNo: useOptionSelect("subDepartment", useFixedQueryString(ChangeValue, ["depNo"])),
    };

    // [START] Handle Form Antd
    const onFinish = (values) => {
        const { userId, depNo, subDepNo, startDate, toDate } = values
        let objectBody = {
            userId: userId || "",
            depNo: depNo || "",
            subDepNo: subDepNo || "",
            startDate: startDate ? startDate.format("YYYY-MM-DD") : "",
            toDate: toDate ? toDate.format("YYYY-MM-DD") : "",
        }
        selectDataReportOT(objectBody)
    };

    const onReset = () => {
        form.resetFields();
        setDataTable([]);
        setChangeValue({});
    };

    const selectDataReportOT = async (objectValue) => {
        try {
            const { userId, depNo, subDepNo, startDate, toDate } = objectValue
            let objectBody = {
                userId: userId || "",
                depNo: depNo || "",
                subDepNo: subDepNo || "",
                startDate: startDate || "",
                toDate: toDate || "",
            };
            setLoadingScreen(true);
            setDataTable([]);
            const response = await POST(POST_SELECT_DATA_REQ_OT_DETAIL, objectBody);
            const { result } = response;
            let mapResult = [];
            if (result.length !== 0) {
                mapResult = result.map((row, index) => ({
                    key: index,
                    ...row,
                    approvalDate1: subStringDate(row.approvalDate1),
                    approvalDate2: subStringDate(row.approvalDate2),
                    approvalDate3: subStringDate(row.approvalDate3),
                }));
            } else {
                useNotification({ type: "info", title: "ไม่พบข้อมูล" });
            }
            setDataTable(mapResult);
        } catch (err) {
            useAlert({ type: "error", title: "[CATCH] เกิดข้อผิดพลาด", content: err.message })
            console.log("Error Search Data Report OT: ", err);
        } finally {
            setLoadingScreen(false);
        }
    };
    // [END] Handle Form Antd

    // [START] Functions
    const onChangeValue = (name, value) => {
        // console.log(name, " : ", value)
        let setUndefined = {}
        if (name === "depNo") {
            setUndefined = toUndefined(["subDepNo"])
        }
        setDataTable([]);
        form.setFieldsValue(setUndefined)
        setChangeValue((prev) => ({
            ...prev,
            setUndefined,
            [name]: value,
        }))
    };
    // [END] Functions
    return (
        <Spin tip="กำลังโหลดข้อมูล ..." spinning={loadingScreen}>
            <Form form={form} onFinish={onFinish} {...configForm}>
                <Card
                    title="ค้นหารายงานคำขอโอที"
                    style={{ marginBottom: 30 }}
                    extra={
                        <Space>
                            {useButton({ call: "search" })}
                            {useButton({
                                call: "normal",
                                fn: onReset,
                                moreProps: { label: "ล้างข้อมูล" },
                            })}
                        </Space>
                    }
                >
                    {useRenderFormItem({
                        constantComponent: ComponentReportOT({
                            options: optionSearch,
                            handleChange: onChangeValue,
                        }),
                    })}
                </Card>
                {dataTable.length !== 0 && (
                    <Card
                        title="รายงานคำขอโอที"
                        style={{ marginBottom: 30 }}
                        extra={
                            useExportExcel({
                                header: Header_ReportOT,
                                dataset: dataTable,
                                filename: "รายงานคำขอโอที",
                            })
                        }
                    >
                        <CustomTableAtnd
                            HeadTable={ColumnReportOT}
                            DataTable={dataTable}
                            moreProps={{
                                scroll: {
                                    x: sumColumnWidth(ColumnReportOT) || 2050,
                                    y: 500,
                                },
                            }}
                        />
                    </Card>
                )}
            </Form>
        </Spin>
    );
};

export default FormReportOT;
