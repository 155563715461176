import React, { createContext, useReducer, useState } from "react";
import {
  GET_SELECT_OPTION_ALL,
  GET,
  GET_SELECT_HRM_FLOW,
  GET_SELECT_MASTER_OPTION,
  GET_SELECT_OPTION,
  GET_SELECT_OPTION_ADDRESS_BY_POSTCODE,
  GET_SELECT_OPTION_DEPARTMENT_ALL,
  GET_SELECT_OPTION_FLOW,
  GET_SELECT_OPTION_MENTOR,
  GET_SELECT_OPTION_POSITION,
  GET_SELECT_OPTION_SUB_DEPARTMENT,
  GET_SELECT_OPTION_WORKSHIFT_ALL,
  GET_SELECT_UTILITY,
  GET_SELECT_EMPLOYEE_HISTORY,
  GET_SELECT_HRTEAM,
  GET_SELECT_OPTION_RECRUITER_SCORE,
  GET_SELECT_OPTION_RECRUITER_GRADE,
  GET_SELECT_OPTION_SALE_CODE,
} from "../index";
import { KEY_OPTION, KEY_TYPE_OPTION } from "../../constants/enums/enumCenter";
import { convertArray } from "../../constants/functions";
import { APIMasterDepartment } from "../../moduleKPIs/services/master/masterConnect";
import { FIELD_EMP } from "../../modulePersonal/constantsPersonal/contents/employeeInfo";
import { APILineNoti } from "../../modulePersonal/servicesPersonal";
import {
  APISelectOptionFlow,
  APISelectOptionMentor,
} from "../../moduleTraining/servicesTraining/masterTraining/masterTrainingConnect";
import { convertOptions } from "../functions/convertOptions";

export const MasterCenterContext = createContext();

const initialState = {
  // Center
  [KEY_OPTION.CENTER.OPTION_ADDRESS]: {
    [KEY_OPTION.CENTER.OPTION_ADDRESS_FORMER]: {
      [KEY_OPTION.CENTER.OPTION_PROVINCE]: [],
      [KEY_OPTION.CENTER.OPTION_DISTRICT]: [],
      [KEY_OPTION.CENTER.OPTION_SUB_DISTRICT]: [],
    },
    [KEY_OPTION.CENTER.OPTION_ADDRESS_PRESENT]: {
      [KEY_OPTION.CENTER.OPTION_PROVINCE]: [],
      [KEY_OPTION.CENTER.OPTION_DISTRICT]: [],
      [KEY_OPTION.CENTER.OPTION_SUB_DISTRICT]: [],
    },
  },
  [KEY_OPTION.CENTER.OPTION_ADDRESS_BY_POSTCODE]: null,
  [KEY_OPTION.CENTER.OPTION_SUB_DISTRICT]: [],
  [KEY_OPTION.CENTER.OPTION_DISTRICT]: [],
  [KEY_OPTION.CENTER.OPTION_PROVINCE]: [],
  [KEY_OPTION.CENTER.OPTION_SEX]: [],
  [KEY_OPTION.CENTER.OPTION_MILITARYSERVICE]: [],
  [KEY_OPTION.CENTER.OPTION_PERSONLA_STATUS]: [],
  [KEY_OPTION.CENTER.OPTION_RELIGION]: [],
  [KEY_OPTION.CENTER.OPTION_PERSONLA_ID_TYPE]: [],
  [KEY_OPTION.CENTER.OPTION_ONETIME_SCAN]: [],
  [KEY_OPTION.CENTER.OPTION_MAN_TYPE]: [],
  [KEY_OPTION.CENTER.OPTION_POSITION]: [],
  [KEY_OPTION.CENTER.OPTION_SUB_DEPARTMENT_ALL]: [],
  [KEY_OPTION.CENTER.OPTION_SUB_DEPARTMENT]: [], //!not use
  [KEY_OPTION.CENTER.OPTION_DEPARTMENT_ALL]: [],
  [KEY_OPTION.CENTER.OPTION_WORKSHIFT_ALL]: [],
  [KEY_OPTION.CENTER.OPTION_TIME_SCAN]: [],
  [KEY_OPTION.CENTER.OPTION_SHIRT_SIZE]: [],
  [KEY_OPTION.CENTER.OPTION_TITLE_EN]: [],
  [KEY_OPTION.CENTER.OPTION_TITLE_TH]: [],
  [KEY_OPTION.CENTER.OPTION_DEP]: [],
  [KEY_OPTION.CENTER.OPTION_USERS]: [],
  [KEY_OPTION.CENTER.OPTION_SUBDEP]: [],
  [KEY_OPTION.CENTER.OPTION_FLOWHRM]: [],
  [KEY_OPTION.CENTER.OPTION_FLOWVIEW]: [],
  [KEY_OPTION.CENTER.OPTION_LOCATION]: [],
  [KEY_OPTION.CENTER.OPTION_MENTOR]: [],
  [KEY_OPTION.CENTER.OPTION_STATUS_WORKERS]: [],
  [KEY_OPTION.CENTER.OPTION_STATUS_EMPLOYEE]: [],
  [KEY_OPTION.CENTER.OPTION_STATUS_DOCUMENTS]: [],
  [KEY_OPTION.CENTER.OPTION_PAYROLL_GROUP]: [],
  [KEY_OPTION.CENTER.OPTION_REASON_LEAVE_GROUP]: [],
  [KEY_OPTION.CENTER.OPTION_CONTACT_PERIOD]: [],
  [KEY_OPTION.CENTER.OPTION_ASSETS_TYPE]: [],
  [KEY_OPTION.CENTER.OPTION_HEALTH_PLAN]: [],
  [KEY_OPTION.CENTER.OPTION_ACCIDENT_PLAN]: [],

  [`SEARCH_${KEY_OPTION.CENTER.OPTION_DATE}`]: null,
  [`SEARCH_${KEY_OPTION.CENTER.OPTION_RANGE_DATE}`]: ["", ""],
  [`SEARCH_${KEY_OPTION.CENTER.OPTION_DEP}`]: "all",
  [`SEARCH_${KEY_OPTION.CENTER.OPTION_USERS}`]: null,
  [`SEARCH_${KEY_OPTION.CENTER.OPTION_SUBDEP}`]: null,
  [`SEARCH_${KEY_OPTION.CENTER.OPTION_LOCATION}`]: "all",
  [`SEARCH_${KEY_OPTION.CENTER.OPTION_MENTOR}`]: null,
  [`SEARCH_${KEY_OPTION.CENTER.OPTION_STATUS_EMPLOYEE}`]: null,
  [`SEARCH_${KEY_OPTION.CENTER.OPTION_STATUS_WORKERS}`]: "all",
  [`SEARCH_${KEY_OPTION.CENTER.OPTION_STATUS_DOCUMENTS}`]: null,

  // Center

  // Probation
  [KEY_OPTION.PROBATION.OPTION_STATUS_RESULT]: [],
  [KEY_OPTION.PROBATION.OPTION_STATUS_ASSESSMENT]: [],
  [`SEARCH_${KEY_OPTION.PROBATION.OPTION_STATUS_RESULT}`]: "all",
  [`SEARCH_${KEY_OPTION.PROBATION.OPTION_STATUS_ASSESSMENT}`]: "all",
  [`SEARCH_${KEY_OPTION.CENTER.OPTION_SELECT_EMPLOY_HISTORY}`]: "all",

  // Probation

  // Personal
  [KEY_OPTION.OPTIONS_PERSONAL]: {
    [KEY_OPTION.PERSONAL.OPTIONS_DEPARTMENT]: [],
    [KEY_OPTION.PERSONAL.OPTION_SUB_DEPARTMENT]: [],
    [KEY_OPTION.PERSONAL.OPTIONS_POSITION]: [],
    [KEY_OPTION.PERSONAL.OPTIONS_FLOWVIEW]: [],
    [KEY_OPTION.PERSONAL.OPTIONS_WORKSHIFT]: [],
  },
  [KEY_OPTION.CENTER.OPTION_SELECT_ALL]: {
    [KEY_OPTION.PERSONAL.OPTIONS_DEPARTMENT]: [],
    [KEY_OPTION.PERSONAL.OPTION_SUB_DEPARTMENT]: [],
    [KEY_OPTION.CENTER.OPTION_COMPANY]: [],
    [KEY_OPTION.PERSONAL.OPTIONS_FLOWVIEW]: [],
    [KEY_OPTION.PERSONAL.OPTIONS_WORKSHIFT]: [],
    [KEY_OPTION.CENTER.OPTION_STATUS_WORKERS]: [],
    [KEY_OPTION.CENTER.OPTION_LOCATION]: [],
  },
  [KEY_OPTION.CENTER.OPTION_SELECT_EMPLOY_HISTORY]: [],
  [KEY_OPTION.CENTER.OPTION_SELECT_HR_RECRUITER_GRADE]: [],
  [KEY_OPTION.CENTER.OPTION_SELECT_HR_RECRUITER_SCORE]: [],
  [KEY_OPTION.CENTER.OPTION_SELECT_SALE_CODE]: [],

  // Personal
};

const CENTER_ACTION = {
  SET_CENTER_SUCCESS: "SET_CENTER_SUCCESS",
  SET_CENTER_FAILURE: "SET_CENTER_FAILURE",
};

const masterCenterReducer = (state, action) => {
  switch (action.type) {
    case CENTER_ACTION.SET_CENTER_SUCCESS:
      return { ...state, ...action.state };
    case CENTER_ACTION.SET_CENTER_FAILURE:
    default:
      return state;
  }
};

const ProviderMasterCenter = ({ children }) => {
  const { userLogin } = children.props;
  const [masterCenterState, masterCenterDispatch] = useReducer(
    masterCenterReducer,
    initialState
  );
  const [loadingMaster, setLoadingMaster] = useState(false);

  function dispatchSuccess(fieldname, key, typeOption, responseMaster) {
    if (!responseMaster) {
      return;
    }

    masterCenterDispatch({
      type: CENTER_ACTION.SET_CENTER_SUCCESS,
      state: {
        [fieldname]: convertOptions({
          key,
          type: typeOption || "all",
          options: responseMaster,
        }),
      },
    });
  }

  async function getMaster({ fieldname, payload, typeOption }) {
    let responseMaster = [];
    let keyOption = KEY_OPTION.OPTION_STATUS;
    let res = null;
    try {
      setLoadingMaster(true);
      switch (fieldname) {
        case KEY_OPTION.CENTER.OPTION_MENTOR:
          const { depNo: d3 } = payload;
          const r = await GET(GET_SELECT_OPTION_MENTOR(d3));
          responseMaster = r.result;
          keyOption = fieldname;
          typeOption = "required";
          break;

        case KEY_OPTION.OPTION_CENTER:
          responseMaster = await GET(GET_SELECT_OPTION_FLOW(userLogin));
          const { resultView, resultFlowHRM } = responseMaster;
          masterCenterDispatch({
            type: CENTER_ACTION.SET_CENTER_SUCCESS,
            state: {
              [KEY_OPTION.CENTER.OPTION_FLOWHRM]: convertArray(
                "idFlow",
                "string",
                resultFlowHRM
              ),
              [KEY_OPTION.CENTER.OPTION_FLOWVIEW]: convertArray(
                "idFlow",
                "string",
                resultView
              ),
            },
          });
          return;

        case KEY_OPTION.CENTER.OPTION_DEP:
          responseMaster = await APIMasterDepartment(payload);
          masterCenterDispatch({
            type: CENTER_ACTION.SET_CENTER_SUCCESS,
            state: {
              [fieldname]: convertOptions({
                key: fieldname,
                type: typeOption,
                options: responseMaster,
              }),
            },
          });
          return;

        case KEY_OPTION.CENTER.OPTION_TITLE_TH:
        case KEY_OPTION.CENTER.OPTION_TITLE_EN:
        case KEY_OPTION.CENTER.OPTION_COMPANY:
        case KEY_OPTION.CENTER.OPTION_LOCATION:
        case KEY_OPTION.CENTER.OPTION_STATUS_WORKERS:
        case KEY_OPTION.CENTER.OPTION_SHIRT_SIZE:
        case KEY_OPTION.CENTER.OPTION_TIME_SCAN:
        case KEY_OPTION.CENTER.OPTION_CONTACT_PERIOD:
        case KEY_OPTION.CENTER.OPTION_MAN_TYPE:
        case KEY_OPTION.CENTER.OPTION_ONETIME_SCAN:
        case KEY_OPTION.CENTER.OPTION_SEX:
        case KEY_OPTION.CENTER.OPTION_RELIGION:
        case KEY_OPTION.CENTER.OPTION_PERSONLA_STATUS:
        case KEY_OPTION.CENTER.OPTION_DRIVING_TYPE:
        case KEY_OPTION.CENTER.OPTION_MILITARYSERVICE:
        case KEY_OPTION.CENTER.OPTION_PAYROLL_GROUP:
        case KEY_OPTION.CENTER.OPTION_STATUS_EMPLOYEE:
        case KEY_OPTION.CENTER.OPTION_REASON_LEAVE_GROUP:
        case KEY_OPTION.CENTER.OPTION_PERSONLA_ID_TYPE:
        case KEY_OPTION.PROBATION.OPTION_STATUS_RESULT:
        case KEY_OPTION.PROBATION.OPTION_STATUS_ASSESSMENT:
        case KEY_OPTION.CENTER.OPTION_HEALTH_PLAN:
        case KEY_OPTION.CENTER.OPTION_ACCIDENT_PLAN:
          const key = KEY_TYPE_OPTION[fieldname];
          res = await GET(GET_SELECT_OPTION(key));
          responseMaster = res.result;
          break;
        case KEY_OPTION.CENTER.OPTION_RECRUITER:
          res = await GET(GET_SELECT_HRTEAM);
          responseMaster = res.result;
          keyOption = KEY_OPTION.CENTER.OPTION_RECRUITER;
          break;
        case KEY_OPTION.CENTER.OPTION_ASSETS_TYPE:
          res = await GET(GET_SELECT_UTILITY("ASSET"));
          responseMaster = res.result;
          keyOption = KEY_OPTION.CENTER.OPTION_ASSETS_TYPE;
          break;

        case KEY_OPTION.CENTER.OPTION_WORKSHIFT_ALL: //TODO shift filter
          res = await GET(GET_SELECT_OPTION_WORKSHIFT_ALL());
          responseMaster = res.result;
          keyOption = KEY_OPTION.CENTER.OPTION_WORKSHIFT_ALL;
          break;

        case KEY_OPTION.CENTER.OPTION_DEPARTMENT_ALL:
          const { company, location } = payload;
          res = await GET(GET_SELECT_OPTION_DEPARTMENT_ALL(company, location));
          responseMaster = res.result;
          keyOption = KEY_OPTION.CENTER.OPTION_DEPARTMENT_ALL;
          break;

        case KEY_OPTION.CENTER.OPTION_SUB_DEPARTMENT_ALL:
        case KEY_OPTION.CENTER.OPTION_SUB_DEPARTMENT:
          const { depNo } = payload;
          const d =
            fieldname === KEY_OPTION.CENTER.OPTION_SUB_DEPARTMENT_ALL
              ? ""
              : depNo;
          res = await GET(GET_SELECT_OPTION_SUB_DEPARTMENT(d));
          responseMaster = res.result;
          keyOption = fieldname;
          break;

        case KEY_OPTION.CENTER.OPTION_POSITION:
          const { company: c, depNo: de  } = payload;
          res = await GET(GET_SELECT_OPTION_POSITION(c,de));
          responseMaster = res.result;
          keyOption = KEY_OPTION.CENTER.OPTION_POSITION;
          break;

        case KEY_OPTION.CENTER.OPTION_FLOWHRM:
          const { company: c2, location: l2 } = payload;
          res = await GET(GET_SELECT_HRM_FLOW(c2, l2));
          responseMaster = res.result;
          responseMaster = responseMaster.filter(
            (v, i, a) => a.findIndex((t) => t.ID_Flow === v.ID_Flow) === i
          );
          keyOption = KEY_OPTION.CENTER.OPTION_FLOWHRM;
          break;

        case KEY_OPTION.CENTER.OPTION_ADDRESS:
          const promise = [];
          payload.present &&
            promise.push(
              GET(GET_SELECT_OPTION_ADDRESS_BY_POSTCODE(payload.present))
            );
          payload.former &&
            promise.push(
              GET(GET_SELECT_OPTION_ADDRESS_BY_POSTCODE(payload.former))
            );
          const responseAddress = await Promise.all(promise);
          const present = payload.present ? responseAddress[0].result : [];
          const former = payload.former ? responseAddress[1].result : [];
          masterCenterDispatch({
            type: CENTER_ACTION.SET_CENTER_SUCCESS,
            state: {
              [KEY_OPTION.CENTER.OPTION_ADDRESS]: {
                [KEY_OPTION.CENTER.OPTION_ADDRESS_PRESENT]: {
                  [KEY_OPTION.CENTER.OPTION_PROVINCE]:
                    present &&
                    convertOptions({
                      key: KEY_OPTION.CENTER.OPTION_PROVINCE,
                      type: "empty",
                      options: present.provinces || [],
                    }),
                  [KEY_OPTION.CENTER.OPTION_DISTRICT]:
                    present &&
                    convertOptions({
                      key: KEY_OPTION.CENTER.OPTION_DISTRICT,
                      type: "empty",
                      options: present.districts || [],
                    }),
                  [KEY_OPTION.CENTER.OPTION_SUB_DISTRICT]:
                    present &&
                    convertOptions({
                      key: KEY_OPTION.CENTER.OPTION_SUB_DISTRICT,
                      type: "empty",
                      options: present.subDistricts || [],
                    }),
                },
                [KEY_OPTION.CENTER.OPTION_ADDRESS_FORMER]: {
                  [KEY_OPTION.CENTER.OPTION_PROVINCE]:
                    former &&
                    convertOptions({
                      key: KEY_OPTION.CENTER.OPTION_PROVINCE,
                      type: "empty",
                      options: former.provinces || [],
                    }),
                  [KEY_OPTION.CENTER.OPTION_DISTRICT]:
                    former &&
                    convertOptions({
                      key: KEY_OPTION.CENTER.OPTION_DISTRICT,
                      type: "empty",
                      options: former.districts || [],
                    }),
                  [KEY_OPTION.CENTER.OPTION_SUB_DISTRICT]:
                    former &&
                    convertOptions({
                      key: KEY_OPTION.CENTER.OPTION_SUB_DISTRICT,
                      type: "empty",
                      options: former.subDistricts || [],
                    }),
                },
              },
            },
          });
          return {
            present: {
              provinceId:
                present && Array.isArray(present.provinces)
                  ? present.provinces[0].provinceId
                  : "",
              districtId: "",
              subDistrictId: "",
            },
            former: {
              provinceId:
                former && Array.isArray(former.provinces)
                  ? former.provinces[0].provinceId
                  : "",
              districtId: "",
              subDistrictId: "",
            },
          };

        case KEY_OPTION.OPTIONS_PERSONAL:
          res = await GET(GET_SELECT_MASTER_OPTION());
          const response = res.result;
          masterCenterDispatch({
            type: CENTER_ACTION.SET_CENTER_SUCCESS,
            state: {
              [KEY_OPTION.PERSONAL]: {
                [KEY_OPTION.PERSONAL.OPTIONS_DEPARTMENT]:
                  response["department"],
                [KEY_OPTION.PERSONAL.OPTION_SUB_DEPARTMENT]: response["subDep"],
                [KEY_OPTION.PERSONAL.OPTIONS_POSITION]: !response["Position"]
                  ? []
                  : response["Position"],
                [KEY_OPTION.PERSONAL.OPTIONS_FLOWVIEW]:
                  response["permissionview"],
                [KEY_OPTION.PERSONAL.OPTIONS_WORKSHIFT]: convertOptions({
                  key: KEY_OPTION.PERSONAL.OPTIONS_WORKSHIFT,
                  type: typeOption,
                  options: response["workShift"],
                }),
              },
            },
          });
          return;
        case KEY_OPTION.CENTER.OPTION_SELECT_ALL:
          res = await GET(GET_SELECT_OPTION_ALL());
          const responseOptionAll = res.result;
          return;
        case KEY_OPTION.CENTER.OPTION_SELECT_EMPLOY_HISTORY:
          // GET_SELECT_EMPLOYEE_HISTORY
          const { block } = payload;
          // res = await GET(GET_SELECT_EMPLOYEE_HISTORY());
          res = await GET(
            GET_SELECT_EMPLOYEE_HISTORY(`?block=${block || "all"}`)
          );
          responseMaster = res.result;
          masterCenterDispatch({
            type: CENTER_ACTION.SET_CENTER_SUCCESS,
            state: {
              [KEY_OPTION.CENTER.OPTION_SELECT_EMPLOY_HISTORY]: convertOptions({
                key: KEY_OPTION.CENTER.OPTION_SELECT_EMPLOY_HISTORY,
                type: typeOption || "all",
                options: responseMaster || [],
              }),
            },
          });
          return;
        case KEY_OPTION.CENTER.OPTION_SELECT_HR_RECRUITER_GRADE:
          // GET_SELECT_EMPLOYEE_HISTORY
          res = await GET(GET_SELECT_OPTION_RECRUITER_GRADE);

          responseMaster = res.result;
          masterCenterDispatch({
            type: CENTER_ACTION.SET_CENTER_SUCCESS,
            state: {
              [KEY_OPTION.CENTER.OPTION_SELECT_HR_RECRUITER_GRADE]:
                responseMaster.map((el) => ({
                  label: el.description,
                  value: el.code,
                })),
            },
          });
          return;
        case KEY_OPTION.CENTER.OPTION_SELECT_HR_RECRUITER_SCORE:
          // GET_SELECT_EMPLOYEE_HISTORY
          res = await GET(GET_SELECT_OPTION_RECRUITER_SCORE);
          responseMaster = res.result;
          masterCenterDispatch({
            type: CENTER_ACTION.SET_CENTER_SUCCESS,
            state: {
              [KEY_OPTION.CENTER.OPTION_SELECT_HR_RECRUITER_SCORE]:
                responseMaster.map((el) => ({
                  label: el.description,
                  value: el.code,
                })),
            },
          });
          return;
        case KEY_OPTION.CENTER.OPTION_SELECT_SALE_CODE:
          res = await GET(GET_SELECT_OPTION_SALE_CODE);
          responseMaster = res.result;
          masterCenterDispatch({
            type: CENTER_ACTION.SET_CENTER_SUCCESS,
            state: {
              //[KEY_OPTION.CENTER.OPTION_SELECT_SALE_CODE]: responseMaster.map(el => ({ label: `[${el.RoleID}] : ${el.RoleName}`, value: el.RoleID }))
              [KEY_OPTION.CENTER.OPTION_SELECT_SALE_CODE]: convertOptions({
                key: KEY_OPTION.CENTER.OPTION_SELECT_SALE_CODE,
                type: typeOption || "empty",
                options: responseMaster || [],
              }),
            },
          });
          return;
        default:
          break;
      }

      dispatchSuccess(fieldname, keyOption, typeOption, responseMaster);
      return (
        responseMaster && Array.isArray(responseMaster) && responseMaster.length
      );
    } catch (error) {
      //! line Noti
      APILineNoti({
        header: "FRONTEND",
        title: `payload : ${JSON.stringify({ ...payload })}`,
        message: `⛔️ ${fieldname} : ${error.message}  `,
      });
      console.log({ error });
      setLoadingMaster(false);
    } finally {
      setLoadingMaster(false);
    }
  }

  async function onChangeMaster({
    fieldOption,
    checkedList,
    checkAll,
    plainOptions,
  }) {
    // switch (fieldOption) {
    //     // case MASTER_OPTION.OPTION_DEP:
    //     //     /****  RETURN Sting or Number **/
    //     //     await masterCenterDispatch({
    //     //         type: CENTER_ACTION.SET_CENTER_SUCCESS,
    //     //         state: { [`SEARCH_${fieldOption}`]: checkedList[0] }
    //     //     })
    //     //     break;
    //     default:
    //         /****  RETURN Array **/
    //         await masterCenterDispatch({
    //             type: CENTER_ACTION.SET_CENTER_SUCCESS,
    //             state: { [`SEARCH_${fieldOption}`]: (checkAll) ? plainOptions : checkedList }
    //         })
    //         break;
    // }
    masterCenterDispatch({
      type: CENTER_ACTION.SET_CENTER_SUCCESS,
      state: {
        [`SEARCH_${fieldOption}`]: checkAll ? plainOptions : checkedList,
      },
    });
  }

  async function clearMaster({ key }) {
    try {
      setLoadingMaster(true);
      switch (key) {
        case KEY_OPTION.CENTER.OPTION_ADDRESS_PRESENT:
        case KEY_OPTION.CENTER.OPTION_ADDRESS_FORMER:
          masterCenterDispatch({
            type: CENTER_ACTION.SET_CENTER_SUCCESS,
            state: {
              [key]: {
                [KEY_OPTION.CENTER.OPTION_PROVINCE]: [],
                [KEY_OPTION.CENTER.OPTION_DISTRICT]: [],
                [KEY_OPTION.CENTER.OPTION_SUB_DISTRICT]: [],
              },
            },
          });
          break;
        case KEY_OPTION.CENTER.OPTION_ADDRESS_BY_POSTCODE:
          masterCenterDispatch({
            type: CENTER_ACTION.SET_CENTER_SUCCESS,
            state: {
              [KEY_OPTION.CENTER.OPTION_PROVINCE]: [],
              [KEY_OPTION.CENTER.OPTION_DISTRICT]: [],
              [KEY_OPTION.CENTER.OPTION_SUB_DISTRICT]: [],
            },
          });
          break;
        case KEY_OPTION.CENTER.OPTION_CONTACT_PERIOD:
          masterCenterDispatch({
            type: CENTER_ACTION.SET_CENTER_SUCCESS,
            state: {
              [KEY_OPTION.CENTER.OPTION_CONTACT_PERIOD]: [],
            },
          });
          break;
        case KEY_OPTION.CENTER.OPTION_SUB_DEPARTMENT:
          masterCenterDispatch({
            type: CENTER_ACTION.SET_CENTER_SUCCESS,
            state: {
              [KEY_OPTION.CENTER.OPTION_SUB_DEPARTMENT]: [],
            },
          });
          break;
        default:
          await {
            type: CENTER_ACTION.SET_CENTER_SUCCESS,
            state: { [`SEARCH_${key}`]: [] },
          };
          break;
      }
    } catch (error) {
      console.log({ error });
      setLoadingMaster(false);
    } finally {
      setLoadingMaster(false);
    }
  }

  return (
    <MasterCenterContext.Provider
      value={{
        getMaster,
        clearMaster,
        onChangeMaster,
        loadingMaster,
        masterCenterState,
        masterCenterDispatch,
      }}
    >
      {children}
    </MasterCenterContext.Provider>
  );
};

export default ProviderMasterCenter;
