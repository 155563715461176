import React from "react";
import { Label } from "reactstrap";

const BaseText = ({
  label = "",
  margin,
  padding,
  size,
  color,
  weight,
  style,
  ...rest
}) => {
  return (
    <Label
      style={{
        margin: margin,
        padding: padding,
        fontSize: size,
        color: color,
        fontWeight: weight,
        ...style
      }}
      {...rest}
    >
      {label || " - "}
    </Label>
  );
};

export default BaseText;
