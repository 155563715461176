import React from 'react'
import Cookies from 'js-cookie';
import { browserHistory } from "react-router";
import { SetStringToObject } from '../../../constants/functions';
import encodeString from '../../../extendFunction/encodeString';
import { PATHNAME } from '../../../constants/enums/pathname';

const ProviderComposer = ({ contexts, children }) => {

    const userData = SetStringToObject(Cookies.get('cookie_session'))
    // console.log({ userData })
    if (userData !== null) {
        userData.userPermission = encodeString.decode(userData.premission);
        userData.userLogin = encodeString.decode(userData.ID_User);
        userData.location = encodeString.decode(userData.location);
    } else {
        browserHistory.push(PATHNAME.INDEX)
    }

    return contexts.reduceRight(
        (kids, parent) => React.cloneElement(parent, {
            children: kids,
            userLogin: userData.userLogin,
            userPermission: userData.userPermission,
            userLocation: userData.location
        }),
        children,

    )
}

export default ProviderComposer