import React from 'react';
import '../index.css';
import "bootstrap/dist/css/bootstrap.css";
import { FiEdit3 } from 'react-icons/fi';
import { Col, Row, Button, Form, FormGroup, Label, Input, Card, CardHeader, CardBody } from 'reactstrap';
import { browserHistory } from 'react-router';
import encodeString from '../extendFunction/encodeString';
import * as NumericInput from "react-numeric-input";
import { Modal } from 'antd';
import { getCurrent } from '../extendFunction/getCurrentYears';
import {
    GET,
    POST,
    GET_DETAIL_SAF,
    POST_INSERT_DISCUSSION
} from "../service";


class DiscussionForm extends React.Component {
    constructor(props) {
        super(props);
        const { yearsSelect } = getCurrent();
        this.state = {
            loadData: [],
            gradeDIS: '',
            _DocSAF: encodeString.decode(this.props.params.DocSAF),
            _year: yearsSelect,
            sameApprover: this.props.params.sameApprover,
            cfDraft: false,
            cfHr: false,
            userLogin: this.props.userLogin,
        };
        this.insertDIS = this.insertDIS.bind(this)
    }

    componentDidMount() {
        this.selectData();
    }

    selectData = async () => {
        const docNo = this.state._DocSAF;
        let _year = this.state._year
        const res = await GET(GET_DETAIL_SAF(docNo))
        const { success, result } = res;
        if (success) {
            const CF_GradeHR = result[0]['CF_GradeHR']
            result[0]['targetDiscus'] = `จุดประสงค์ของการหารือผลงาน\n\
            1.เพื่อหารือผลงานในปีที่ผ่านมา (เดือน มค. ${_year} - ธค. ${_year})\n\
            2.ร่วมหาแนวทางในการพัฒนา และปรับปรุงผลงานและสมรรถนะของพนักงาน \n\
            - ชื่นชมในสิ่งดี และแนะนำสิ่งที่ควรพัฒนา วางแผนเพื่อปรับปรุงแก้ไขร่วมกัน\n\
            - แจ้งเป้าหมายองค์กร ของแผนก และหน้าที่รับผิดชอบที่ชัดเจนในปี ${_year + 1} เพื่อใช้หารือผลงานครั้งต่อไป\n\
            - ชี้แจงและตอบคำถามให้น้องเข้าใจเรื่องผลการประเมิน และการจ่ายโบนัส ปรับเงินเดือน`
            //console.log(CF_GradeHR)
            this.setState({ loadData: result, CF_GradeHR: CF_GradeHR })

        } else {
            console.log("error selectData");
            this.setState({ loading: false });
        }
    }


    changeValue = (e) => {
        var _id = e.target.id
        var Arr = this.state.loadData
        Arr[0][_id] = e.target.value
        this.setState({ loadData: Arr });
        // console.log(this.state.loadData)
    }

    insertDIS(event) {
        event.preventDefault();
        const data = new FormData(event.target);
        let errors = {};
        let formIsValid = true;
        // console.log('gggg')
        //console.log(this.state.draft)

        let entries = data.entries();
        let current, item, key, output, value;
        output = {};
        while (item = entries.next().value) {
            // assign to variables to make the code more readable.
            key = item[0];
            value = item[1];
            // Check if key already exist
            if (Object.prototype.hasOwnProperty.call(output, key)) {
                current = output[key];
                if (!Array.isArray(current)) {
                    // If it's not an array, convert it to an array.
                    current = output[key] = [current];
                }
                current.push(value); // Add the new value to the array.
            } else {
                output[key] = value;
            }
        }
        //console.log(output)
        if (!output["targetDiscus"]) {
            formIsValid = false;
            errors["targetDiscus"] = "Cannot be empty";
        }

        if (!output["targetSuccess"]) {
            formIsValid = false;
            errors["targetSuccess"] = "Cannot be empty";
        }

        // if (!output["learnDeveloped"]) {
        //     formIsValid = false;
        //     errors["learnDeveloped"] = "Cannot be empty";
        // }

        if (!output["targetNotSuccess"]) {
            formIsValid = false;
            errors["targetNotSuccess"] = "Cannot be empty";
        }

        // if (!output["causesNotSuccess"]) {
        //     formIsValid = false;
        //     errors["causesNotSuccess"] = "Cannot be empty";
        // }

        if (!output["barrier"]) {
            formIsValid = false;
            errors["barrier"] = "Cannot be empty";
        }


        // if (!output["solution"]) {
        //     formIsValid = false;
        //     errors["solution"] = "Cannot be empty";
        // }

        if (!output["km_1"]) {
            formIsValid = false;
            errors["km_1"] = "Cannot be empty";
        }

        if (!output["reason_1"]) {
            formIsValid = false;
            errors["reason_1"] = "Cannot be empty";
        }

        if (!output["valueEmp"]) {
            formIsValid = false;
            errors["valueEmp"] = "Cannot be empty";
        }

        // if (!output["actionEmp"]) {
        //     formIsValid = false;
        //     errors["actionEmp"] = "Cannot be empty";
        // }

        // if (!output["resultEmp"]) {
        //     formIsValid = false;
        //     errors["resultEmp"] = "Cannot be empty";
        // }

        if (!output["valueCulture"]) {
            formIsValid = false;
            errors["valueCulture"] = "Cannot be empty";
        }

        // if (!output["actionMust"]) {
        //     formIsValid = false;
        //     errors["actionMust"] = "Cannot be empty";
        // }

        // if (!output["directResult"]) {
        //     formIsValid = false;
        //     errors["directResult"] = "Cannot be empty";
        // }


        // if (!output["planDev"]) {
        //     formIsValid = false;
        //     errors["planDev"] = "Cannot be empty";
        // }

        if (!output["expectWork"]) {
            formIsValid = false;
            errors["expectWork"] = "Cannot be empty";
        }

        // if (!output["actionPlan"]) {
        //     formIsValid = false;
        //     errors["actionPlan"] = "Cannot be empty";
        // }

        // if (!output["outputPass"]) {
        //     formIsValid = false;
        //     errors["outputPass"] = "Cannot be empty";
        // }

        if (!output["indicator"]) {
            formIsValid = false;
            errors["indicator"] = "Cannot be empty";
        }


        if (!output["overallResult"]) {
            formIsValid = false;
            errors["overallResult"] = "Cannot be empty";
        }

        // if (!output["overallDepYearDetail_1"]) {
        //     formIsValid = false;
        //     errors["overallDepYearDetail_1"] = "Cannot be empty";
        // }

        // if (!output["overallDepYearTime_1"]) {
        //     formIsValid = false;
        //     errors["overallDepYearTime_1"] = "Cannot be empty";
        // }

        // if (!output["overallDepYearDetail_2"]) {
        //     formIsValid = false;
        //     errors["overallDepYearDetail_2"] = "Cannot be empty";
        // }

        // if (!output["overallDepYearTime_2"]) {
        //     formIsValid = false;
        //     errors["overallDepYearTime_2"] = "Cannot be empty";
        // }

        if (this.state.cfDraft === false && this.state.cfHr === false) {
            formIsValid = true
        }
        if (formIsValid === true) {
            Modal.confirm({
                title: 'คุณต้องการยืนยันการทำรายการใช่หรือไม่?',
                content: '',
                async onOk() {

                    const response = await POST(POST_INSERT_DISCUSSION, output);
                    const { result } = response;
                    // console.log(result)
                    if (result.status_api === 1) {
                        Modal.success({
                            title: `Insert Success!! \n  DocNo =  ${result.docNo}`,
                            onOk: () => browserHistory.push("/DashboardDiscussionForm"),
                        });
                    } else {
                        Modal.error({ title: "Insert Error!!" });
                    }
                },
                onCancel() {
                    console.log("Cancel");
                },
            });
        } else {
            // alert("กรุณาระบุข้อมูลให้ครบถ้วน");
            Modal.warning({ title: "กรุณาระบุข้อมูลให้ครบถ้วน" });
            console.log(errors);
        }
    }


    onchangeGrade = (e) => {

        this.setState({ gradeDIS: e.target.value });
    }

    onChangeCheckbox = (e) => {
        let isChecked = e.target.checked;
        this.setState({ cfDraft: isChecked })
    }

    onChangeCheckboxHR = (e) => {
        let isChecked = e.target.checked;
        this.setState({ cfHr: isChecked })
    }
    render() {
        var data = this.state.loadData;
        let checkbox;
        let btnSave;

        btnSave = <Button className="margin-right-20" >บันทึกแบบร่าง</Button>
        if (this.state.cfDraft === true || this.state.cfHr === true) {
            btnSave = <Button color="success" className="margin-right-20" >     ยืนยันข้อมูล (แก้ไขไม่ได้)</Button>
        }

        checkbox = <div class="ui checkbox"> <Input type="checkbox" name="cfDraft" id="cfDraft"
            onChange={this.onChangeCheckbox} value={this.state.cfDraft} defaultChecked={this.state.cfDraft} />  <label>
                ยืนยันการบันทึกและส่งให้หัวหน้างาน <b className="red">(กรณียืนยันแล้ว จะไม่สามารถกลับมาแก้ไขได้)</b></label></div>

        if (this.state.sameApprover === 'Y') {
            checkbox = <div><input type="hidden" id="cfDraft" name="cfDraft" value="1" /></div >
            if (this.state.CF_GradeHR === 1) {
                checkbox = <div class="ui checkbox"> <Input type="checkbox" name="cfHr" id="cfHr" onChange={this.onChangeCheckboxHR} value={this.state.cfHr} defaultChecked={this.state.cfHr} />
                    <label>ยืนยันผลการประเมินและส่งให้ทางฝ่ายบุคคล <b className="red">(กรณียืนยันแล้ว จะไม่สามารถกลับมาแก้ไขได้)</b></label>
                    <input type="hidden" id="cfDraft" name="cfDraft" value="1" /></div>
            }
        }
        return (
            <div>
                <Card>
                    <CardHeader><FiEdit3 className="margin-icon" />แบบฟอร์มหารือผลงาน (DISCUSSION FORM)</CardHeader>
                    {data.map((val, index) => {
                        return (
                            <CardBody>
                                <Form onSubmit={this.insertDIS}>
                                    <Card>
                                        <CardHeader>ข้อมูลส่วนตัว</CardHeader>
                                        <CardBody>
                                            <Row>
                                                <Col md={6}>
                                                    <FormGroup>
                                                        <Label for="Type">ประเภทการประเมิน</Label>
                                                        <Input type="text" name="Type_des" id="Type_des" value={val.TypeDes} disabled />
                                                        <Input type="hidden" name="type" id="type" value={val.Type} />
                                                        <Input type="hidden" name="refDocSAF" id="refDocSAF" value={this.state._DocSAF} />
                                                        <Input type="hidden" name="userLogin" id="userLogin" value={this.state.userLogin} />
                                                    </FormGroup>
                                                </Col>
                                                <Col md={6}>
                                                    <FormGroup>
                                                        <Label for="Years">การประเมินตนเองประจำปี</Label>
                                                        <Input type="text" name="Years_Des" id="Years_Des" value={val.Years} disabled />
                                                        <Input type="hidden" name="year" id="year" value={val.Years} />
                                                    </FormGroup>
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col md={6}>
                                                    <FormGroup>
                                                        <Label for="StaffName">ชื่อผู้ทำประเมิน</Label>
                                                        <Input type="text" name="staffName" id="staffName" value={val.StaffName} readOnly />
                                                        <Input type="hidden" name="staffCode" id="staffCode" value={val.StaffCode} />
                                                        <Input type="hidden" name="depNo" id="depNo" value={val.DepNo} />

                                                    </FormGroup>
                                                </Col>
                                                <Col md={6}>
                                                    <FormGroup>
                                                        <Label for="Level_Worker">ตำแหน่ง</Label>
                                                        <Input type="text" name="positionNameEN" id="positionNameEN" value={val.PositionNameEN} readOnly />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md={6}>
                                                    <FormGroup>
                                                        <Label for="BossName">ชื่อผู้บังคับบัญชา</Label>
                                                        <Input type="text" name="bossName" id="bossName" value={val.BossName} readOnly />
                                                        <Input type="hidden" name="bossCode" id="bossCode" value={val.BossCode} />
                                                        <Input type="hidden" name="idFlow" id="idFlow" value={val.ID_Flow} />
                                                    </FormGroup>
                                                </Col>
                                                <Col md={6}>
                                                    <FormGroup>
                                                        <Label>ตำแหน่ง</Label>
                                                        <Input
                                                            type="text" name="PositionNameEN_Boss" id="PositionNameEN_Boss" value={val.PositionNameEN_Boss} readOnly />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                        </CardBody>
                                    </Card>

                                    <div className="margin-card2">
                                        <Card>
                                            <CardHeader>
                                                <Row>
                                                    <Col md={8}>
                                                        การให้คะแนนประเมินตนเอง (คะแนนเต็ม 10)
                                                    </Col>
                                                    <Col md={4}> <NumericInput className="form-control" name="selfScore" id="selfScore" value={val.selfScore} readOnly /></Col>
                                                </Row>
                                            </CardHeader>
                                            <CardBody>
                                                <Row>

                                                    <Col md={12}>
                                                        <b>คำอธิบายเพิ่มเติม :: </b> &nbsp; &nbsp;
                                                        <label> 1-2 = ต่ำกว่าความคาดหวังมาก &nbsp; &nbsp;
                                                            3-4 = ต่ำกว่าความคาดหวัง &nbsp; &nbsp;
                                                            5-6 = ตามความคาดหวัง &nbsp; &nbsp;
                                                            7-8 = สูงกว่าความคาดหวัง &nbsp; &nbsp;
                                                            9-10 = สูงกว่าความคาดหวังมาก </label>
                                                    </Col>
                                                </Row>
                                            </CardBody>
                                        </Card>
                                    </div>

                                    <div className="margin-card2">
                                        <Card>
                                            <CardHeader><span className="red"> * </span> 0. เป้าหมายการหารือผลงาน / สิ่งที่คาดหวัง - ผู้บังคับบัญชาชี้แจงให้พนักงานทราบวัตถุประสงค์การสนทนา ได้แก่ </CardHeader>
                                            <CardBody>
                                                <Row>
                                                    <Col md={12}>
                                                        <FormGroup>
                                                            <Input type="textarea" className="text-area" name="targetDiscus" id="targetDiscus" rows="10"
                                                                onChange={this.changeValue} value={val.targetDiscus} />
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                            </CardBody>
                                        </Card>
                                    </div>

                                    <div className="margin-card2">
                                        <Card>
                                            <CardHeader>1. ผลการดำเนินการ ปีที่ผ่านมา (Performance)</CardHeader>
                                            <CardBody>
                                                {/* <Label for="company">1.1 ผลสำเร็จตามเป้าหมาย</Label> */}
                                                <Row>
                                                    <Col md={12}>
                                                        <FormGroup>
                                                            <Label for="company"><span className="red"> * </span>1.1.) ผลสำเร็จตามเป้าหมาย</Label>
                                                            <Input type="textarea" className="text-area" name="targetSuccess" id="targetSuccess" value={val.targetSuccess} onChange={this.changeValue} />
                                                        </FormGroup>
                                                    </Col>
                                                    {/* <Col md={6}>
                                                        <FormGroup>
                                                            <Label for="FNameEN"><span className="red"> * </span>1.1.2) สิ่งที่ได้เรียนรู้และพัฒนา</Label>
                                                            <Input type="textarea" className="text-area" name="learnDeveloped" id="learnDeveloped" value={val.learnDeveloped} onChange={this.changeValue} />
                                                        </FormGroup>
                                                    </Col> */}
                                                </Row>
                                                {/* <br /> */}
                                                {/* <Label for="company">1.2 ผลงานที่ยังไม่บรรลุตามความคาดหวัง</Label> */}
                                                <Row>
                                                    <Col md={12}>
                                                        <FormGroup>
                                                            <Label for="company"><span className="red"> * </span>1.2) ผลงานที่ยังไม่บรรลุตามเป้าหมาย</Label>
                                                            <Input type="textarea" className="text-area" name="targetNotSuccess" id="targetNotSuccess" value={val.targetNotSuccess} onChange={this.changeValue} />
                                                        </FormGroup>
                                                    </Col>
                                                    {/* <Col md={6}>
                                                        <FormGroup>
                                                            <Label for="FNameEN"><span className="red"> * </span>1.2.2) สาเหตุที่ยังไม่บรรลุตามเป้าหมาย</Label>
                                                            <Input type="textarea" className="text-area" name="causesNotSuccess" id="causesNotSuccess" value={val.causesNotSuccess} onChange={this.changeValue} />
                                                        </FormGroup>
                                                    </Col> */}
                                                </Row>
                                                <Row>
                                                    <Col md={12}>
                                                        <FormGroup>
                                                            <Label for="company"><span className="red"> * </span>1.3) อุปสรรคต่างๆที่พบ และ แนวทางพัฒนา แก้ไขปัญหา เพื่อบรรลุเป้าหมาย</Label>
                                                            <Input type="textarea" className="text-area" name="barrier" id="barrier" value={val.barrier} onChange={this.changeValue} />
                                                        </FormGroup>
                                                    </Col>
                                                    {/* <Col md={6}>
                                                        <FormGroup>
                                                            <Label for="FNameEN"><span className="red"> * </span>1.2.4) เราจะมีแนวทางพัฒนาหรือแก้ไขปัญหาร่วมกันอย่างไรได้บ้าง</Label>
                                                            <Input type="textarea" className="text-area" name="solution" id="solution" value={val.solution} onChange={this.changeValue} />
                                                        </FormGroup>
                                                    </Col> */}
                                                </Row>
                                            </CardBody>
                                        </Card>
                                    </div>
                                    <div className="margin-card2">
                                        <Card>
                                            {/* <CardHeader>3. การพัฒนาสมรรถนะในการทำงาน (Core & Managerial Competency)  ** อ้างอิง Form ประเมินผลงาน/Competency **</CardHeader> */}
                                            <CardHeader>2.การพัฒนาสมรรถนะในการทำงาน (Core & Competency) ** อ้างอิง Form ประเมินผลงาน / Competency </CardHeader>
                                            <CardBody>
                                                <Row >
                                                    <Col md={12}>
                                                        {/* 3.1) พูดคุยถึงคุณค่าที่มีอย่างเด่นชัดในการทำงาน หรือที่ส่งผลต่อการทำงาน (ชื่นชมในสิ่งที่ดี) (VAI)   (ประเด็นหลักๆ 1-3 ข้อ) */}
                                                        <span className="red"> * </span> 2.1 คุณค่าที่ดีของพนักงาน - ชื่นชมในสิ่งที่ดี (ประเด็นหลักๆ 1-3 ข้อ) ให้ยกตัวอย่างพฤติกรรมที่เป็นคุณค่าที่ดี
                                                    </Col>
                                                </Row>

                                                <Row>
                                                    <Col md={12}>
                                                        <FormGroup>
                                                            {/* <Label><span className="red"> * </span>คุณค่าที่ดีของพนักงาน ตามวัฒนธรรมองค์กร (V) </Label> */}
                                                            <Input type="textarea" className="text-area" name="valueEmp" id="valueEmp" />
                                                        </FormGroup>
                                                    </Col>
                                                    {/* <Col md={4}>
                                                        <FormGroup>
                                                            <Label><span className="red"> * </span>พฤติกรรม/การกระทำที่เป็นคุณค่าที่ดีของพนักงาน (A)</Label>
                                                            <Input type="textarea" className="text-area" name="actionEmp" id="actionEmp" />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col md={4}>
                                                        <FormGroup>
                                                            <Label><span className="red"> * </span> ผลของการกระทำของคุณค่าที่ดี (I)</Label>
                                                            <Input type="textarea" className="text-area" name="resultEmp" id="resultEmp" />
                                                        </FormGroup>
                                                    </Col> */}
                                                </Row>
                                                <br />
                                                <Row >
                                                    <Col md={12}>
                                                        {/* 3.2) คุณค่าที่ต้องการให้พนักงานพัฒนา (แนะให้เห็นแนวทางพัฒนา ปรับปรุง) (VAI) (ประเด็นหลักๆ 1-3 ข้อ) */}
                                                        <span className="red"> * </span> 2.2 คุณค่าที่ต้องการให้พนักงานพัฒนา ปรับปรุง (ประเด็นหลักๆ 1-3 ข้อ) ให้ยกตัวอย่างพฤติกรรมที่อยากเห็น และแนะให้เห็นแนวทางพัฒนา ปรับปรุง
                                                    </Col>
                                                </Row>

                                                <Row>
                                                    <Col md={12}>
                                                        <FormGroup>
                                                            {/* <Label> <span className="red"> * </span>คุณค่าตามวัฒนธรรมองค์กรที่อยากให้เสริม (V)</Label> */}
                                                            <Input type="textarea" className="text-area" name="valueCulture" id="valueCulture" />
                                                        </FormGroup>
                                                    </Col>
                                                    {/* <Col md={4}>
                                                        <FormGroup>
                                                            <Label><span className="red"> * </span>พฤติกรรม/การกระทำที่ดีที่ต้องการให้ทำ (A) </Label>
                                                            <Input type="textarea" className="text-area" name="actionMust" id="actionMust" />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col md={4}>
                                                        <FormGroup>
                                                            <Label> <span className="red"> * </span> ผลของการกระทำที่มีผลดีต่อพนักงาน (I)</Label>
                                                            <Input type="textarea" className="text-area" name="directResult" id="directResult" />
                                                        </FormGroup>
                                                    </Col> */}
                                                </Row>
                                                {/* <br />
                                                <Row>
                                                    <Col md={12}>
                                                        <FormGroup>
                                                            <Label> <span className="red"> * </span>3.3) แผนในการพัฒนา ปรับปรุงสมรรถนะ เป็นอย่างไรบ้าง กำหนดระยะเวลาร่วมกัน </Label>
                                                            <Input type="textarea" className="text-area" name="planDev" id="planDev" />
                                                        </FormGroup>
                                                    </Col>
                                                </Row> */}
                                            </CardBody>
                                        </Card>
                                    </div>

                                    <div className="margin-card2">
                                        <Card>
                                            <CardHeader>3. ความรู้ ความเชี่ยวชาญในงาน  (Functional Competency)</CardHeader>
                                            <CardBody>
                                                <Label for="company"></Label>
                                                <Row>
                                                    <Col md={6}>
                                                        <FormGroup>
                                                            <Label>3.1) สิ่งที่อยากพัฒนา ปรับปรุง -  มีเรื่องอะไรบ้างที่อยากเรียนรู้พัฒนาเพิ่มเติม เพื่อช่วยให้ทำงานได้ง่ายขึ้น ดีขึ้น  </Label>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col md={6}>
                                                        <FormGroup>
                                                            <Label>3.2) เหตุผลที่อยากเรียนรู้ พัฒนา (สำหรับแต่ละหัวข้อ)</Label>
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md={6}>
                                                        <FormGroup>
                                                            <Label for="company"><span className="red"> * </span>ลำดับที่ 1 </Label>
                                                            <Input type="textarea" className="text-area" name="km_1" id="km_1" value={val.km_1} onChange={this.changeValue} />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col md={6}>
                                                        <FormGroup>
                                                            <Label for="FNameEN"><span className="red"> * </span>เหตุผล </Label>
                                                            <Input type="textarea" className="text-area" name="reason_1" id="reason_1" value={val.reason_1} onChange={this.changeValue} />
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md={6}>
                                                        <FormGroup>
                                                            <Label for="company">ลำดับที่ 2 </Label>
                                                            <Input type="textarea" className="text-area" name="km_2" id="km_2" value={val.km_2} onChange={this.changeValue} />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col md={6}>
                                                        <FormGroup>
                                                            <Label for="FNameEN">เหตุผล </Label>
                                                            <Input type="textarea" className="text-area" name="reason_2" id="reason_2" value={val.reason_2} onChange={this.changeValue} />
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md={6}>
                                                        <FormGroup>
                                                            <Label for="company">ลำดับที่ 3 </Label>
                                                            <Input type="textarea" className="text-area" name="km_3" id="km_3" value={val.km_3} onChange={this.changeValue} />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col md={6}>
                                                        <FormGroup>
                                                            <Label for="FNameEN">เหตุผล </Label>
                                                            <Input type="textarea" className="text-area" name="reason_3" id="reason_3" value={val.reason_3} onChange={this.changeValue} />
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                            </CardBody>
                                        </Card>
                                    </div>


                                    <div className="margin-card2">
                                        <Card>
                                            <CardHeader>4. ความคาดหวังงาน สำหรับรอบการทำงานถัดไป </CardHeader>
                                            <CardBody>
                                                <Row>
                                                    <Col md={12}>
                                                        <FormGroup>
                                                            {/* <Label><span className="red"> * </span>4.1) ความคาดหวังในงานที่ชัดเจน - (ชี้แจงให้พนักงานเข้าใจบทบาทหน้าที่ ความรับผิดชอบ สอดคล้องเป้าหมายขององค์กร)</Label> */}
                                                            <Label><span className="red"> * </span>4.1) ชี้แจงหน้าที่รับผิดชอบ และความคาดหวัง</Label>
                                                            <Input type="textarea" className="text-area" name="expectWork" id="expectWork" />
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                {/* <Row>
                                                    <Col md={12}>
                                                        <FormGroup>
                                                            <Label ><span className="red"> * </span>4.2) แผนงานที่มอบหมายให้รับผิดชอบ (Action Plan ที่มอบหมายให้พนักงานรับผิดชอบ เพื่อสนับสนุนภาพรวมหน่วยงาน)</Label>
                                                            <Input type="textarea" className="text-area" name="actionPlan" id="actionPlan" />
                                                        </FormGroup>
                                                    </Col>
                                                </Row> */}
                                                {/* <Row>
                                                    <Col md={12}>
                                                        <Label >4.3) ผลของงานที่คาดหวัง และตัวชี้วัดผลงาน สำหรับปีนี้ (ปัจจัยหลัก 3-7 ข้อ)</Label>

                                                    </Col>
                                                </Row> */}
                                                <Row>
                                                    {/* <Col md={6}>
                                                        <FormGroup>
                                                            <Label ><span className="red"> * </span>ผลของงานที่ต้องทำให้บรรลุ (ผลงานที่ผ่านมาและที่ควรจะเป็น)</Label>
                                                            <Input type="textarea" className="text-area" name="outputPass" id="outputPass" />

                                                        </FormGroup>
                                                    </Col> */}
                                                    <Col md={12}>
                                                        <FormGroup>
                                                            {/* <Label ><span className="red"> * </span>ตัวชี้วัดผลงาน (วัดอะไร อย่างไร และเมื่อใด)</Label> */}
                                                            <Label ><span className="red"> * </span>4.2) ตัวชี้วัดผลงานที่ชัดเจน </Label>
                                                            <Input type="textarea" className="text-area" name="indicator" id="indicator" />
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                            </CardBody>
                                        </Card>
                                    </div>
                                    <div className="margin-card2">
                                        <Card>
                                            <CardHeader>5.ข้อเสนอแนะ และการให้คำปรึกษา (รับ feedback และให้ข้อเสนอแนะเพิ่มเติม)</CardHeader>
                                            <CardBody>
                                                <Row>
                                                    <Col md={12}>
                                                        <FormGroup>
                                                            <Label>
                                                                5.1 สิ่งที่อยากเสนอแนะผู้บังคับบัญชา -
                                                                การทำงานร่วมกันเป็นอย่างไรบ้าง มีสิ่งที่อยากให้
                                                                ผู้บังคับบัญชาปรับหรือสนับสนุน (feedback)
                                                            </Label>
                                                            <Input
                                                                type="textarea" className="text-area" name="feedback" id="feedback" ref="feedback" onChange={this.changeValue} value={val.feedback} />
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md={12}>
                                                        <FormGroup>
                                                            <Label>
                                                                5.2 สิ่งที่อยากเสนอแนะองค์กร - สิ่งที่อยากให้องค์กรปรับเพิ่มเติม
                                                            </Label>
                                                            <Input type="textarea" className="text-area" name="organComment" id="organComment" onChange={this.changeValue} value={val.organComment} />
                                                        </FormGroup>
                                                    </Col>
                                                </Row>

                                                <Row>
                                                    <Col md={12}>
                                                        <FormGroup>
                                                            <Label>
                                                                5.3 ความเห็นอื่นๆ - ประเด็นอื่นๆ ที่อยากหารือกับผู้บังคับบัญชา / อยากขอบคุณใคร หรือ หน่วยงานใด สำหรับการทำงานในปีนี้
                                                            </Label>
                                                            <Input type="textarea" className="text-area" name="otherComment" id="otherComment" onChange={this.changeValue} value={val.otherComment} />
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                            </CardBody>
                                        </Card>
                                    </div>
                                    <div className="margin-card2">
                                        <Card>
                                            <CardHeader>6.ความร่วมมือ และความคิดริเริ่ม </CardHeader>
                                            <CardBody>
                                                <Row>
                                                    <Col md={12}>
                                                        <FormGroup>
                                                            <Label>
                                                                6.1  ปีที่ผ่านมา เราช่วยให้  <b>"ใคร"</b> ในองค์กรได้ทำงานให้ประสบความสำเร็จบ้าง /<b> "ใคร"</b> ช่วยผลักดันงานของเราให้สำเร็จบ้าง
                                                            </Label>
                                                            <Input
                                                                type="textarea" className="text-area" name="whoHelp" id="whoHelp" ref="whoHelp" onChange={this.changeValue} value={val.whoHelp} />
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md={12}>
                                                        <FormGroup>
                                                            <Label>
                                                                6.2 ปีที่ผ่านมาเอา <b>"ไอเดีย/ความคิดริเริ่ม"</b> ไหนขององค์กร หรือ ฝ่ายอื่นมาต่อยอดได้บ้าง
                                                            </Label>
                                                            <Input type="textarea" className="text-area" name="adaptIdea" id="adaptIdea" onChange={this.changeValue} value={val.adaptIdea} />
                                                        </FormGroup>
                                                    </Col>
                                                </Row>

                                                <Row>
                                                    <Col md={12}>
                                                        <FormGroup>
                                                            <Label>
                                                               6.3 ถ้ามีโอกาสสร้างการเปลี่ยนแปลงในองค์กรได้ มีโครงการ มีไอเดียไหนที่น่าทำและอยากทำบ้าง (สามารถเสนอของหน่วยงานตัวเอง หรือหน่วยงานอื่น ๆ ได้)
                                                            </Label>
                                                            <Input type="textarea" className="text-area" name="changeOrg" id="changeOrg" onChange={this.changeValue} value={val.changeOrg} />
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                            </CardBody>
                                        </Card>
                                    </div>
                                    <div className="margin-card2">
                                        <Card>
                                            <CardHeader><span className="red"> * </span>7.ผลการประเมินโดยรวม - (สื่อสาร แจ้งภาพรวมผลประเมินของพนักงานพร้อมทั้งแจ้ง โบนัส และการปรับเงินเดือนประจำปี)</CardHeader>
                                            <CardBody>
                                                <Row>
                                                    <Col md={12}>
                                                        <FormGroup>
                                                            <Input type="textarea" className="text-area" name="overallResult" id="overallResult" />
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                            </CardBody>
                                        </Card>
                                    </div>

                                    {/* <div className="margin-card2">
                                        <Card>
                                            <CardHeader>7. สรุปสิ่งที่จะพัฒนาปรับปรุงร่วมกันในปีนี้ ที่ชัดเจน (เรียงตามลำดับความสำคัญ 2-5 เรื่อง)  </CardHeader>
                                            <br />
                                            <Row >
                                                <Col md={2} > <center><b>ลำดับที่</b> </center>   </Col>
                                                <Col md={6} > <center><b>  หัวข้อ/ รายละเอียด</b> </center>     </Col>
                                                <Col md={4} > <center> <b>กำหนดเวลา</b>    </center>     </Col>
                                            </Row><br />
                                            <Row>
                                                <Col md={2} > <center> <span className="red"> * </span> 1 </center>
                                                </Col>
                                                <Col md={6} >
                                                    {/* <p style="white-space: pre-line"> 
                                                    <Input type="textarea" className="text-area" name="overallDepYearDetail_1" id="overallDepYearDetail_1" />
                                                    {/* </p> 
                                                </Col>
                                                <Col md={4}>
                                                    <Input type="textarea" className="text-area" name="overallDepYearTime_1" id="overallDepYearTime_1" />
                                                </Col>
                                            </Row><br />
                                            <Row>
                                                <Col md={2} > <center><span className="red"> * </span>2 </center>
                                                </Col>
                                                <Col md={6} >
                                                    <Input type="textarea" className="text-area" name="overallDepYearDetail_2" id="overallDepYearDetail_2" />
                                                </Col>
                                                <Col md={4}>
                                                    <Input type="textarea" className="text-area" name="overallDepYearTime_2" id="overallDepYearTime_2" />
                                                </Col>
                                            </Row><br />
                                            <Row>
                                                <Col md={2} > <center> 3 </center>
                                                </Col>
                                                <Col md={6} >
                                                    <Input type="textarea" className="text-area" name="overallDepYearDetail_3" id="overallDepYearDetail_3" />
                                                </Col>
                                                <Col md={4}>
                                                    <Input type="textarea" className="text-area" name="overallDepYearTime_3" id="overallDepYearTime_3" />
                                                </Col>
                                            </Row><br />
                                            <Row>
                                                <Col md={2} > <center> 4 </center>
                                                </Col>
                                                <Col md={6} >
                                                    <Input type="textarea" className="text-area" name="overallDepYearDetail_4" id="overallDepYearDetail_4" />
                                                </Col>
                                                <Col md={4}>
                                                    <Input type="textarea" className="text-area" name="overallDepYearTime_4" id="overallDepYearTime_4" />
                                                </Col>
                                            </Row><br />
                                            <Row>
                                                <Col md={2} > <center> 5 </center>
                                                </Col>
                                                <Col md={6} >
                                                    <Input type="textarea" className="text-area" name="overallDepYearDetail_5" id="overallDepYearDetail_5" />
                                                </Col>
                                                <Col md={4}>
                                                    <Input type="textarea" className="text-area" name="overallDepYearTime_5" id="overallDepYearTime_5" />
                                                </Col>
                                            </Row>

                                        </Card>
                                    </div> */}
                                    <div className="margin-card2">
                                        <Card>
                                            <CardHeader><span className="red"> * </span>8. สรุปผลการดำเนินงานประจำปี {val.Years} </CardHeader>
                                            <CardBody>
                                                <Row>
                                                    <Col md={12}> {val.Type === 'Half' ? '-' : val.gradeDes === '' ? '(รอทาง HR ยืนยันผลการประเมิน)' : val.gradeDes}</Col>
                                                </Row>
                                            </CardBody>
                                        </Card>
                                    </div>
                                    <Row>
                                        <Col md={12} className="text-center">
                                            <div class="ui checkbox">
                                                {checkbox}
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>

                                        <Col md={12} className="text-center">
                                            {btnSave}
                                        </Col>


                                    </Row>
                                </Form>
                            </CardBody>
                        );

                    })}
                </Card>
            </div>
        );

    }
}
export default DiscussionForm;