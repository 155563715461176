import React from 'react';
import '../index.css';
import "bootstrap/dist/css/bootstrap.css";
import { FiEdit3 } from 'react-icons/fi';
import { Col, Row, FormGroup, Label, Input, Card, CardHeader, CardBody } from 'reactstrap';
import * as NumericInput from "react-numeric-input";
import { Tabs } from "antd";
import encodeString from '../extendFunction/encodeString';
import {
    GET,
    SELECT_DATA_PE_HISTORY,
    SELECT_DATA_PE
} from "../service";
const { TabPane } = Tabs;
var _year = new Date().getFullYear()
class PrintPE_STF extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            tooltipOpen: false,
            value: "",
            arrScore1: [],
            arrNum1: [],
            scoreP1: '',
            arrScore2: [],
            arrNum2: [],
            scoreP2: '',
            totalScore: '',
            loadData: [],
            dataHistory: [],
            _DocPEF: encodeString.decode(this.props.params.PDocPEF_STF),
            groupEdit: this.props.params.Type,
            status_confirm: false,
        };

        // this.handleInputChange = this.handleInputChange.bind(this)
    }

    componentDidMount() {
        this.selectData();
    }

    async selectData() {
        try {
            const docNo = this.state._DocPEF;
            const response = await GET(SELECT_DATA_PE(docNo));
            const { result } = response;
            let { data } = result;
            const status_con = data[0].status_confirm;
            const staffCode = data[0].staffCode;
            const years = docNo.substring(3, 7);
            let chk;
            if (status_con === 1) {
                chk = true;
            } else {
                chk = false;
            }
            //  console.log(data)
            const arrScore1 = (result.arrScore1).map(Number);
            const arrScore2 = result.arrScore2.map(Number)
            this.setState({
                loadData: data,
                status_confirm: chk,
                arrScore1,
                arrScore2,
            },
                () => {
                    this.selectDataHistory(years, staffCode);
                }
            );
        } catch (err) {
            console.log("Error Search select Data: ", err);
            this.setState({ loading: false });
        }
    }


    async selectDataHistory(years, staffCode) {
        try {
            const queryObject = {
                years,
                staffCode,
            };
            const response = await GET(SELECT_DATA_PE_HISTORY(queryObject));
            const { result } = response;
            this.setState({
                dataHistory: result !== 0 ? result : [],
            });
        } catch (err) {
            console.log("Error Search select DataHistory: ", err);
            this.setState({ loading: false });
        }
    }


    render() {
        let data = this.state.loadData;
        let dataHistory = this.state.dataHistory || [];
        let notFound = ''
        if (dataHistory.length === 0) {
            notFound = <p > <br /> ***ไม่พบข้อมูลผลการประเมินย้อนหลัง*** </p>
        }

        return (

            <div>
                <Card>
                    <CardHeader><FiEdit3 className="margin-icon" /> แบบฟอร์มบริหารผลงาน ระดับปฏิบัติการ (Staff)</CardHeader></Card>
                <Tabs defaultActiveKey="1">
                    <TabPane tab="แบบกรอกฟอร์มประเมิน" key="1">
                        <h3>กรอกแบบฟอร์มการประเมินประจำปี</h3>
                        {data.map((val, index) => {
                            return (

                                <CardBody>
                                    <Row>
                                        <Col md={6}>
                                            <FormGroup>
                                                <Label for="company">ชื่อผู้ถูกประเมิน</Label>
                                                <Input type="text" name="StaffName_dis" id="StaffName_dis" value={val.staffName} disabled />
                                                <Input type="hidden" name="StaffName" id="StaffName" value={val.staffName} />
                                                <Input type="hidden" name="StaffCode" id="StaffCode" value={val.staffCode} />
                                                <Input type="hidden" name="DepNo" id="DepNo" value={val.DepNo} />
                                                <Input type="hidden" name="ID_Flow" id="ID_Flow" value={val.ID_Flow} />
                                                <Input type="hidden" name="DocNoSAF" id="DocNoSAF" value={val.Ref_DocSAF} />
                                                <Input type="hidden" name="DocNoPEF" id="DocNoPEF" value={this.state._DocPEF} />
                                                <Input type="hidden" name="groupEdit" id="groupEdit" value={this.state.groupEdit} />
                                            </FormGroup>
                                        </Col>
                                        <Col md={6}>
                                            <FormGroup>
                                                <Label for="company">ตำแหน่งงาน</Label>
                                                <Input type="text" name="Level_WorkerDes" id="Level_WorkerDes" value={val.Level_WorkerDes} disabled />
                                                <Input type="hidden" name="Level_Worker" id="Level_Worker" value={val.Level_Worker} />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <h4>ส่วนที่ 1 ด้านผลสำเร็จของงาน</h4>
                                    <Row className="table-custom table-head3">
                                        <Col md={3} className="col-style"><b>1-3 (ต่ำกว่ามาตรฐาน)</b></Col>
                                        <Col md={3} className="col-style"><b>4-6 (ได้มาตรฐาน)</b></Col>
                                        <Col md={3} className="col-style"><b>7-9 (สูงกว่ามาตรฐาน)</b></Col>
                                        <Col md={3} className="col-style"><b>ระบุคะแนน</b></Col>
                                    </Row>
                                    <Row className="table-custom3"><b>&nbsp; &nbsp;1.1 ปริมาณงาน ( น้ำหนักคะแนน 30 ) <span className="red"> * </span></b></Row>

                                    <Row className="table-custom2">
                                        <Col md={3} className="col-style2">ปฏิบัติงานได้ดี มีปริมาณงานน้อยกว่ามาตรฐานหรือน้อยกว่าที่คาดหวัง ในเวลาที่ควรจะเป็น</Col>
                                        <Col md={3} className="col-style2">ปฏิบัติงานได้ดี มีปริมาณงานได้ตามมาตรฐานหรือตามที่คาดหวัง ในเวลาที่ควรจะเป็น</Col>
                                        <Col md={3} className="col-style2">ปฏิบัติงานได้ดี มีปริมาณงานมากกว่ามาตรฐานหรือมากกว่าที่คาดหวัง ในเวลาที่ควรจะเป็น</Col>
                                        <Col md={3} className="col-style2">
                                            <NumericInput className="form-control" name="score1_1_1" id="score1_1_1" value={val.score1_1_1} readOnly />
                                        </Col>
                                    </Row>
                                    <Row className="table-custom3"><b>&nbsp;&nbsp;1.2 คุณภาพงาน ( น้ำหนักคะแนน 30 ) <span className="red"> * </span> </b></Row>
                                    <Row className="table-custom2 margin-bottom20">
                                        <Col md={3} className="col-style2">ผลงานมีคุณภาพไม่ดีพอ ต้องแก้ไขทำใหม่อยู่เป็นประจำ มีความบกพร่องสูง เมื่อเทียบกับมาตรฐานคุณภาพงานที่ควรจะเป็น</Col>
                                        <Col md={3} className="col-style2">ผลงานมีคุณภาพที่ยอมรับได้ มีความถูกต้อง เรียบร้อย ต้องแก้ไขบ้าง แต่ไม่ก่อให้เกิดความเสียหายใดๆ อยู่ในเกณฑ์ที่ยอมรับได้เมื่อเทียบกับมาตรฐานคุณภาพงานที่ควรจะเป็น</Col>
                                        <Col md={3} className="col-style2">ผลงานมีคุณภาพดีมาก มีความถูกต้องเรียบร้อย ไม่มีความบกพร่อง เมื่อเทียบกับมาตรฐานคุณภาพงานที่ควรจะเป็น</Col>
                                        <Col md={3} className="col-style2">
                                            <NumericInput className="form-control" name="score1_2_1" id="score1_2_1" value={val.score1_2_1} readOnly />
                                        </Col>
                                    </Row>

                                    <h4>ส่วนที่ 2 ด้านสมรรถนะและพฤติกรรม</h4>
                                    <Row className="table-custom table-head">&nbsp;&nbsp; <b>Core Competency</b></Row>
                                    <Row className="table-custom table-head3">
                                        <Col md={3} className="col-style"><b>1-3 (ต่ำกว่ามาตรฐาน)</b></Col>
                                        <Col md={3} className="col-style"><b>4-6 (ได้มาตรฐาน)</b></Col>
                                        <Col md={3} className="col-style"><b>7-9 (สูงกว่ามาตรฐาน)</b></Col>
                                        <Col md={3} className="col-style"><b>ระบุคะแนน</b></Col>
                                    </Row>

                                    <Row className="table-custom3">
                                        <Col md={12} >
                                            <b>2.1 Teamwork : การทำงานเป็นทีม (น้ำหนักคะแนน 7) <span className="red"> * </span></b> <br />
                                            <b> คำอธิบายเพิ่มเติม : </b> ความร่วมแรงร่วมใจในการผลักดันพันธกิจของหน่วยงาน/องค์กร ให้ไปในทิศทางเดียวกัน มีทัศนคติที่เปิดกว้าง
                                            รับฟังความคิดเห็นและเคารพในความแตกต่างของผู้อื่น ตลอดจนมีน้ำใจช่วยเหลือผู้อื่น โดยเห็นแก่ความสำเร็จขององค์กรในภาพรวม
                                        </Col>
                                    </Row>
                                    <Row className="table-custom2">
                                        <Col md={3} className="col-style2">ไม่แสดงพฤติกรรม หรือ แสดงพฤติกรรมได้ต่ำกว่ามาตรฐาน</Col>
                                        <Col md={3} className="col-style2">
                                            - เข้าใจถึงบทบาทและหน้าที่ของตน สำนึกในความเป็นส่วนหนึ่งของทีม<br></br>
                                            - แสดงออกถึงความผูกพันกับทีม แสดงบทบาทได้ทั้งผู้นำหรือผู้ตามขึ้นอยู่กับสถานการณ์และความจำเป็น<br></br>
                                            - รับฟังและให้กำลังใจผู้อื่นเมื่อมีการแสดงความคิดเห็น<br></br>
                                            - มักเข้าไปมีส่วนร่วมกับภารกิจ โครงการของทีมอย่างกระตือรือร้นและด้วยความเต็มใจ<br></br>
                                            - พยายามรักษาบรรยากาศในการทำงานของทีมให้เป็นไปด้วยดีอยู่เสมอ
                                        </Col>
                                        <Col md={3} className="col-style2">
                                            - มีทัศนคติเชิงบวก ยืดหยุ่นต่อแนวทางและความคิดใหม่ๆ<br></br>
                                            - รับฟังคำแนะนำ การวิพากษ์ วิจารณ์ที่สร้างสรรค์และปรับพฤติกรรมของตนได้โดยคำนึงถึงเป้าหมายของทีม<br></br>
                                            - มีส่วนร่วมในการกำหนดภารกิจและเป้าหมายของทีม<br></br>
                                            - นำเสนอแนวทางเลือกในการแก้ไขปัญหาที่เกิดขึ้นของสมาชิกในทีม<br></br>
                                            - เต็มใจที่จะแบ่งเบาภาระงานกับผู้อื่น
                                        </Col>
                                        <Col md={3} className="col-style2">
                                            <NumericInput className="form-control" name="score2_1" id="score2_1" value={val.score2_1} readOnly />
                                        </Col>
                                    </Row>

                                    <Row className="table-custom3">
                                        <Col md={12} >
                                            <b>2.2 Customer Focus : ลูกค้าสำคัญ (น้ำหนักคะแนน 6)<span className="red"> * </span> </b> <br />
                                            <b> คำอธิบายเพิ่มเติม : </b> ความตระหนักถึงความต้องการคาดหวังตลอดจนปัญหาของลูกค้าภายใน/ภายนอก พยายามแสวงหาแนวทาง
                                            วิธีการในการส่งมอบผลิตภัณฑ์ บริการที่มีคุณภาพเพื่อสนองตอบความต้องการและสร้างความพึงพอใจของลูกค้าอย่างต่อเนื่องกระตือรือร้น
                                        </Col>
                                    </Row>
                                    <Row className="table-custom2">
                                        <Col md={3} className="col-style2">ไม่แสดงพฤติกรรม หรือ แสดงพฤติกรรมได้ต่ำกว่ามาตรฐาน</Col>
                                        <Col md={3} className="col-style2">
                                            - สามารถระบุความต้องการคาดหวังของลูกค้าได้<br></br><br></br>
                                            - รับฟังปัญหาของลูกค้าด้วยความเต็มใจและตั้งใจ แก้ปัญหาเฉพาะหน้าให้ลูกค้าได้ภายในขอบเขตความรับผิดชอบของตน<br></br><br></br>
                                            - สะท้อนปัญหา รายงานต่อหัวหน้าเกี่ยวกับปัญหา อุปสรรคในการบริการลูกค้าที่เกินขอบเขตของตน
                                        </Col>
                                        <Col md={3} className="col-style2">
                                            - สร้าง/พัฒนา การสื่อสาร คู่มือ กระบวนการ วิธีการทำงาน ระบบข้อมูลที่จำเป็นสำหรับลูกค้า<br></br>
                                            - กำหนดมาตรฐาน หลักประกันว่าสินค้า/บริการจะสามารถตอบสนองความต้องการของลูกค้าได้<br></br>
                                            - ปรับปรุงกระบวนการให้มีประสิทธิภาพสามารถตอบสนองความต้องการของลูกค้าได้อย่างรวดเร็ว ทันกาล<br></br>
                                            - เสนอแนะวิธีการที่จะปรับปรุงคุณภาพผลิตภัณฑ์การบริการเพื่อสร้างความพึงพอใจของลูกค้า
                                        </Col>
                                        <Col md={3} className="col-style2">
                                            <NumericInput className="form-control" name="score2_2" id="score2_2" value={val.score2_2} readOnly />
                                        </Col>
                                    </Row>
                                    <Row className="table-custom3">
                                        <Col md={12} >
                                            <b>2.3 Excellence/ Go Beyond : ความเป็นเลิศ/ ความก้าวหน้า (น้ำหนักคะแนน 6) <span className="red"> * </span></b> <br />
                                            <b> คำอธิบายเพิ่มเติม : </b> การปฏิบัติงานอย่างมีวิสัยทัศน์ ประสิทธิภาพใช้ทรัพยากรที่มีอยู่อย่างชาญฉลาด คุ้มค่า ส่งมอบผลงานที่มีคุณภาพอย่างเหนือความคาดหมาย
                                        </Col>
                                    </Row>
                                    <Row className="table-custom2">
                                        <Col md={3} className="col-style2">ไม่แสดงพฤติกรรม หรือ แสดงพฤติกรรมได้ต่ำกว่ามาตรฐาน</Col>
                                        <Col md={3} className="col-style2">
                                            - เข้าใจถึงผลลัพธ์ของงานใหน้าที่ของตนว่าจะส่งผลกระทบต่อส่วนรวมอย่างไร<br></br>
                                            - พยายามค้นหาทางแก้ปัญหาในงานที่เกิดความผิดพลาดในอดีต ไม่เกิดความผิดพลาดซ้ำ<br></br>
                                            - ผลงานที่ส่งมอบ แสดงถึงความถูกต้อง เรียบร้อย สมบูรณ์ ครบถ้วน<br></br>
                                            - มีการตรวจสอบอย่างถี่ถ้วนเพือหาข้อผิดพลาดก่อนส่งมอบงานเสมอ
                                        </Col>
                                        <Col md={3} className="col-style2">
                                            - กำหนดขั้นตอนและวินัยในการทำงานของตนเองอย่างเป็นระบบและถือปฏิบัติอย่างสม่ำเสมอ<br></br>
                                            - สำนึกถึงความจำเป็นเร่งด่วน และจัดลำดับงานที่ต้องปฏิบัติก่อนหลังได้อย่างเหมาะสม<br></br>
                                            - เข้าใจและอธิบายต่อผู้อื่นได้ถึงปัจจัยแห่งความสำเร็จของงานแต่ละอย่างที่ตนรับผิดชอบอยู่
                                        </Col>
                                        <Col md={3} className="col-style2">
                                            <NumericInput className="form-control" name="score2_3" id="score2_3" value={val.score2_3} readOnly />
                                        </Col>
                                    </Row>


                                    <Row className="table-custom3">
                                        <Col md={12} >
                                            <b>2.4 Learning and Development : การเรียนรู้และพัฒนา (น้ำหนักคะแนน 6) <span className="red"> * </span></b> <br />
                                            <b> คำอธิบายเพิ่มเติม : </b> กระตือรือร้นที่จะเรียนรู้สิ่งใหม่ๆเพื่อเพิ่มพูนควาสามารถและทักษะในการปฏิบัติงานของตนเองอยู่เสมอ
                                            มีการถ่ายทอดแบ่งปันความรู้วิธีปฏิบัติงานใหม่ๆเพื่อให้สามารถตอบสนองต่อการเปลี่ยนแปลงของสภาพแวดล้อมแก่สมาชิกในทีม
                                        </Col>
                                    </Row>

                                    <Row className="table-custom2">
                                        <Col md={3} className="col-style2">ไม่แสดงพฤติกรรม หรือ แสดงพฤติกรรมได้ต่ำกว่ามาตรฐาน</Col>
                                        <Col md={3} className="col-style2">
                                            - แสดงออกถึงความตั้งใจที่จะเรียนรู้งานที่ตนรับผิดชอบ<br></br>
                                            - ค้นคว้าหาข้อมูลที่เกี่ยวข้องจากแหล่งข้อมูลต่างๆได้<br></br>
                                            - เข้ารับการฝึกอบรม เรียนรู้ผ่านหลักสูตร โครงการต่างๆเพื่อเพิ่มพูนความรู้ในงาน
                                        </Col>
                                        <Col md={3} className="col-style2">
                                            - แสดงออกถึงการมีทัศนคติที่เปิดกว้างและรับฟังอย่างเต็มใจต่อความคิดเห็นที่แตกต่าง<br></br>
                                            - เรียนรู้จากความผิดพลาดของตนหรือผู้อื่นแล้วนำมาเป็นแนวทางการปรับปรุงแก้ไขให้ดีขึ้น<br></br>
                                            - ประยุกต์ขั้นตอนและวิธีการทำงานใหม่ๆได้ด้วยตนเอง
                                        </Col>
                                        <Col md={3} className="col-style2">
                                            <NumericInput className="form-control" name="score2_4" id="score2_4" value={val.score2_4} readOnly />
                                        </Col>
                                    </Row>

                                    <Row className="table-custom3">
                                        <Col md={12} >
                                            <b>2.5 Integrity : ความมีคุณธรรม</b> <br />
                                            <b> คำอธิบายเพิ่มเติม : </b> มีความเห็นใจและเข้าใจผู้อื่น ประพฤติตนและเป็นแบบอย่างในด้านความซื่อสัตย์ ยุติธรรม และมีจริยธรรม แสดงออกถึงความสม่ำเสมอทั้งคำพูดและการกระทำ
                                        </Col>
                                    </Row>
                                    <Row className="table-custom2">
                                        <Col md={6} className="col-style">
                                            <FormGroup>
                                                <label> ตัวอย่างหรือตัวชี้บ่งพฤติกรรม </label>
                                                <Input type="textarea" name="ans2_5_example" id="ans2_5_example" value={val.ans2_5_example} readOnly />
                                            </FormGroup>
                                        </Col>
                                        <Col md={6} className="col-style">
                                            <FormGroup>
                                                <label>ข้อเสนอแนะเพื่อการพัฒนา </label>
                                                <Input type="textarea" name="ans2_5_suggestion" id="ans2_5_suggestion" value={val.ans2_5_suggestion} readOnly />
                                            </FormGroup>
                                        </Col>
                                    </Row>


                                    <Row className="table-custom table-head">&nbsp;&nbsp; <b>Functional Competency</b></Row>
                                    <Row className="table-custom table-head3">
                                        <Col md={3} className="col-style"><b>1-3 (ต่ำกว่ามาตรฐาน)</b></Col>
                                        <Col md={3} className="col-style"><b>4-6 (ได้มาตรฐาน)</b></Col>
                                        <Col md={3} className="col-style"><b>7-9 (สูงกว่ามาตรฐาน)</b></Col>
                                        <Col md={3} className="col-style"><b>ระบุคะแนน</b></Col>
                                    </Row>

                                    <Row className="table-custom3">
                                        <Col md={12} >
                                            <b>2.6 Job Knowledge : ความรู้ในงาน (น้ำหนักคะแนน 5) <span className="red"> * </span></b> <br />
                                            <b> คำอธิบายเพิ่มเติม : </b> เป็นผู้มีวิสัยทัศน์ มองการณ์ไกล เข้าใจภาพรวม ประพฤติตนเป็นแบบอย่างที่ดีตามค่านิยมขององค์กร มอบหมายงานและสร้างแรงจูงใจเพื่อผลักดันให้ไปสู่เป้าหมาย
                                            ตลอดจนประเมินผลลัพธ์อย่างเที่ยงตรง สือสารอย่างมีประสิทธิภาพ ได้รับการยอมรับจากสมาชิกในหน่วยงาน
                                        </Col>
                                    </Row>
                                    <Row className="table-custom2">
                                        <Col md={3} className="col-style2">
                                            - อธิบายให้ผู้อื่นเข้าใจถึงกระบวนการ เทคนิค ขั้นตอน วิธีปฏิบัติงานประจำของตนได้พอสังเขป<br></br>
                                            - ส่งมอบงานภายในกรอบเวลาที่กำหนดไว้ได้เป็นส่วนใหญ่<br></br>
                                            - ยังพบข้อผิดพลาดในผลงานและข้อผิดพลาดดังกล่าวสัมพันธ์กับระดับความรู้ในงานโดยตรง
                                        </Col>
                                        <Col md={3} className="col-style2">
                                            - อธิบายให้ผู้อื่นเข้าใจถึงกระบวนการ เทคนิค ขั้นตอน วิธีปฏิบัติงานของตนได้อย่างละเอียด<br></br>
                                            - ปฏิบัติงานประจำโดยอิงกับกระบวนการ ขั้นตอน วิธีการ และมาตรฐานที่กำหนดขึ้นในหน่วยงาน<br></br>
                                            - เมื่อพบปัญหาในการปฏิบัติงานประจำยังต้องขอคำแนะนำด้านเทคนิค วิธีการจากเพื่อนร่วมงานหรือหัวหน้า<br></br>
                                            - ผลงานมีข้อผิดพลาดในระดับที่ยอมรับได้ อาจเป็นผลจากปัจจัยอื่นๆที่ไม่สัมพันธ์กับความรู้ในงานโดยตรง
                                        </Col>
                                        <Col md={3} className="col-style2">
                                            - ผลงานทีส่งมอบมีความเที่ยงตรง ถูกต้อง สม่ำเสมอ เสร็จตามกำหนด<br></br>
                                            - ข้อผิดพลาดมีน้อยหรือถูกค้นพบแก้ไขก่อนด้วยตนเองก่อนส่งมอบผลงาน<br></br>
                                            - ต้องการคำแนะนำจากหัวหน้างาน ในกรณีทีต้องปฏิบัติงานใหม่ๆที่ไม่เคยทำมาก่อน<br></br>
                                            - ประยุกต์ใช้ความรู้ในการแก้ปัญหาที่ไม่ยุ่งยากซับซ้อนมากนักอย่างมีประสิทธิผล
                                        </Col>
                                        <Col md={3} className="col-style2">
                                            <NumericInput className="form-control" name="score2_6" id="score2_6" value={val.score2_6} readOnly />
                                        </Col>
                                    </Row>

                                    <Row className="table-custom3">
                                        <Col md={12} >
                                            <b>2.7 Problem Solving : การแก้ไขปัญหา (น้ำหนักคะแนน 5)<span className="red"> * </span> </b> <br />
                                            <b> คำอธิบายเพิ่มเติม : </b> ความเข้าใจในบุคลิก คุณลักษณะของสมาชิกในทีม ส่งเสริมความสัมพันธ์ในทีม/หน่วยงาน สามารถปรับพฤติกรรมของสมาชิกใน
                                            ทีมให้ไปในทิศทางที่พึงประสงค์ได้มีทักษะในการ ถ่ายทอด สอนงาน ชี้แนะ โน้มน้าวจูงใจ พัฒนาผู้ใต้บังคับบัญชา
                                        </Col>
                                    </Row>
                                    <Row className="table-custom2">
                                        <Col md={3} className="col-style2">ไม่แสดงพฤติกรรม หรือ แสดงพฤติกรรมได้ต่ำกว่ามาตรฐาน</Col>
                                        <Col md={3} className="col-style2">
                                            - ระบุได้ว่าอะไรคือประเด็นปัญหา ใครควรเป็นผู้ลงมือแก้ไข หรือต้องให้ใครเป็นผู้ตัดสินใจก่อนดำเนินการใดๆ<br></br>
                                            - เล็งเห็นถึงปัญหาก่อนที่ปัญหาจะเกิดขึ้น คาดการณ์ได้ว่าปัญหาน่าจะเกิดที่ไหน ตลอดจนมีการติดตาม เฝ้าระวัง ณ จุดนั้น<br></br>
                                            - รายงานสภาพปัญหาต่อหัวหน้าในเวลาที่เหมาะสม เมื่อพบปัญหาที่ไม่สามารถแก้ไขได้เองในเบื้องต้นได้
                                        </Col>
                                        <Col md={3} className="col-style2">
                                            - ใช้การวิเคราะห์เชิงเหตุผล  ตรรกะ บนฐานข้อมูล ข้อเท็จจริงไม่ด่วนสรุป คาดเดาบนฐานของอคติ ความเชื่อส่วนตัว<br></br>
                                            - สามารถสืบค้น แสวงหา รวบรวม ข้อมูล ข้อเท็จจริงที่ต้องใช้ประกอบการตัดสินใจได้อย่างครบถ้วน ตรงประเด็น<br></br>
                                            - ไม่เฉพาะแต่สามารถระบุถึงปัญหาเท่านั้น หากยังสามารถวิเคราะห์สาเหตุปัญหาที่ไม่ซับซ้อนมากและเสนอแนวทางแก้ไขได้
                                        </Col>
                                        <Col md={3} className="col-style2">
                                            <NumericInput className="form-control" name="score2_7" id="score2_7" value={val.score2_7} readOnly />
                                        </Col>
                                    </Row>

                                    <Row className="table-custom table-head">&nbsp;&nbsp; <b>Managerial Competency</b></Row>
                                    <Row className="table-custom table-head3">
                                        <Col md={3} className="col-style"><b>1-3 (ต่ำกว่ามาตรฐาน)</b></Col>
                                        <Col md={3} className="col-style"><b>4-6 (ได้มาตรฐาน)</b></Col>
                                        <Col md={3} className="col-style"><b>7-9 (สูงกว่ามาตรฐาน)</b></Col>
                                        <Col md={3} className="col-style"><b>ระบุคะแนน</b></Col>
                                    </Row>

                                    <Row className="table-custom3">
                                        <Col md={12} >
                                            <b>2.8 Planning and Organizing : การวางแผนและการจัดองค์กร (น้ำหนักคะแนน 5) <span className="red"> * </span> </b> <br />
                                            <b> คำอธิบายเพิ่มเติม : </b>  กำหนดแผนงานโดยตระหนักถึงปัจจัยที่เกี่ยวข้องต่างๆเช่น สภาพแวดล้อม ทรัพยากรที่จำเป็น กำลังคน กระบวนการ ขั้นตอน ปัญหา อุปสรรค ความเสี่ยง ลำดับความสำคัญเร่งด่วน
                                            ตั้งเป้าหมายที่ท้าทายและเป็นไปได้  ดำเนินการตามแผนโดยกำกับ ติดตามและตอบสนองตามความจำเป็นเพื่อให้บรรลุตามที่กำหนดไว้
                                        </Col>
                                    </Row>
                                    <Row className="table-custom2">
                                        <Col md={3} className="col-style2">ไม่แสดงพฤติกรรม หรือ แสดงพฤติกรรมได้ต่ำกว่ามาตรฐาน </Col>
                                        <Col md={3} className="col-style2">
                                            - ระบุถึงงาน/เป้าหมายที่ต้องปฏิบัติในขอบเขตหน้าที่ของตนได้ในแต่ละวัน<br></br>
                                            - เข้าใจถึงเป้าหมายในการทำงานของทีมที่ตนสังกัดอยู่<br></br>
                                            - มีการจัดทำระบบฐานข้อมูล บันทึก สถิติ รายงานต่างๆที่จำเป็น<br></br>
                                            - สามารถทำงานเสร็จตามเป้าหมายที่กำหนดไว้ได้

                                        </Col>
                                        <Col md={3} className="col-style2">
                                            - ช่วยกำหนดแผนการ ขั้นตอนการปฏิบัติการได้จากข้อมูลที่มีอยู่<br></br>
                                            - เจรจาต่อรอง ปรึกษากับหัวหน้า เพื่อปรับกำหนดการ ขอบเขตงาน/กิจกรรมให้เหมาะสม รวมทั้งจัดลำดับความสำคัญให้สอดคล้องกับสภาวะการณ์ที่เกิดขึ้น<br></br>
                                            - ประเมิน ทบทวนผลงานของตนเองเป็นระยะและปรับปรุงวิธีการทำงานอยู่เสมอ
                                        </Col>
                                        <Col md={3} className="col-style2">
                                            <NumericInput className="form-control" name="score2_10" id="score2_10" value={val.score2_10} readOnly />
                                        </Col>
                                    </Row>
                                    <br />
                                    <h4>
                                        ส่วนที่ 3 การเปลี่ยนแปลง
                                        Promote/โยกย้าย/ปรับตำแหน่ง/Execute D (ถ้าเสนอ โปรดระบุ
                                        เพื่อ Discuss Management){" "}
                                    </h4>
                                    <Row className="table-custom2">
                                        <Col md={6} className="col-style2">
                                            <FormGroup>
                                                <Label>รายละเอียด</Label>
                                                <Input
                                                    type="textarea"
                                                    name="remarkDetail"
                                                    id="remarkDetail"
                                                    onChange={this.changeValue}
                                                    value={val.remarkDetail}
                                                    readOnly />
                                            </FormGroup>
                                        </Col>
                                        <Col md={6} className="col-style2">
                                            <FormGroup>
                                                <Label>ระยะเวลา</Label>
                                                <Input
                                                    type="textarea"
                                                    name="remarkTime"
                                                    id="remarkTime"
                                                    onChange={this.changeValue}
                                                    value={val.remarkTime}
                                                    readOnly />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row className="table-custom table-footer">
                                        <Col md={4} className="margin-top-20"><b>ส่วนที่ 1 ด้านผลสำเร็จของงาน (60 คะแนน) </b> <Input type="text" name="scorePart1" id="scorePart1" value={val.scorePart1} readOnly /></Col>
                                        <Col md={4} className="margin-top-20"><b>ส่วนที่ 2 ด้านสมรรถนะและพฤติกรรม (40 คะแนน) </b><Input type="text" name="scorePart2" id="scorePart2" value={val.scorePart2} readOnly /></Col>
                                        <Col md={4} className="margin-top-20 padding-right-32"><b>รวมคะแนนทั้งหมด (100 คะแนน) </b><Input type="text" name="totalScore" id="totalScore" value={val.totalScore} readOnly /></Col>
                                    </Row>
                                </CardBody>

                            );
                        })}
                    </TabPane>
                    <TabPane tab="รายงานผลประเมินย้อนหลัง 3 ปี" key="2">
                        <h3>รายงานผลประเมินย้อนหลัง 3 ปี</h3>
                        {notFound}
                        {dataHistory.map((val, i) => {
                            if (i === 0) {
                                return (
                                    <CardBody>
                                        <Row>
                                            <Col md={6}>
                                                <FormGroup>
                                                    <Label for="company">ชื่อผู้ถูกประเมิน</Label>
                                                    <Input type="text" name="StaffName_dis" id="StaffName_dis" value={dataHistory[0]['staffName']} disabled />
                                                </FormGroup>
                                            </Col>
                                            <Col md={6}>
                                                <FormGroup>
                                                    <Label for="company">ตำแหน่งงาน</Label>
                                                    <Input type="text" name="Level_WorkerDes" id="Level_WorkerDes" value={dataHistory[0]['Level_WorkerDes']} disabled />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <h4>ส่วนที่ 1 การประเมินปัจจัยด้านผลงาน</h4>
                                        <Row className="table-custom table-head3">
                                            <Col md={4} className="col-style"><b>หัวข้อการประเมิน</b></Col>
                                            <Col md={2} className="col-style"><b> {dataHistory['0'] === undefined ? '' : "ปี" + dataHistory['0']['Years']}</b> <br />
                                                {dataHistory['0'] === undefined ? '' : "(" + dataHistory['0']['description'] + ")"}
                                            </Col>
                                            <Col md={2} className="col-style"><b>{dataHistory['1'] === undefined ? '' : "ปี" + dataHistory['1']['Years']}</b><br />
                                                {dataHistory['1'] === undefined ? '' : "(" + dataHistory['1']['description'] + ")"}
                                            </Col>
                                            <Col md={2} className="col-style"><b> {dataHistory['2'] === undefined ? '' : "ปี" + dataHistory['2']['Years']}</b><br />
                                                {dataHistory['2'] === undefined ? '' : "(" + dataHistory['2']['description'] + ")"}
                                            </Col>
                                            <Col md={2} className="col-style"> <b> ปีปัจจุบัน </b> <br />({_year})

                                            </Col>
                                        </Row>
                                        <Row className="table-custom table-head2">&nbsp;&nbsp; <b>ผลงานตามเป้าหมาย</b></Row>
                                        <Row className="table-custom2">
                                            <Col md={4} className="col-style2">ปริมาณงาน</Col>
                                            <Col md={2} className="col-style2"><center>{dataHistory['0'] === undefined ? '' : dataHistory['0']['score1_1_1']} </center></Col>
                                            <Col md={2} className="col-style2"><center>{dataHistory['1'] === undefined ? '' : dataHistory['1']['score1_1_1']}</center></Col>
                                            <Col md={2} className="col-style2"><center>{dataHistory['2'] === undefined ? '' : dataHistory['2']['score1_1_1']}</center></Col>
                                            <Col md={2} className="col-style2">
                                                <NumericInput className="form-control" name="score1_1_1" id="score1_1_1" onChange={valueAsNumber => this.handleInputChange(valueAsNumber, 30, 0, 1, 'score1_1_1')} value={data[0]['score1_1_1']} readOnly />
                                            </Col>
                                        </Row>
                                        <Row className="table-custom2">
                                            <Col md={4} className="col-style2">เวลา</Col>
                                            <Col md={2} className="col-style2"><center>{dataHistory['0'] === undefined ? '' : dataHistory['0']['score1_1_2']} </center></Col>
                                            <Col md={2} className="col-style2"><center>{dataHistory['1'] === undefined ? '' : dataHistory['1']['score1_1_2']} </center></Col>
                                            <Col md={2} className="col-style2"><center>{dataHistory['2'] === undefined ? '' : dataHistory['2']['score1_1_2']} </center></Col>
                                            <Col md={2} className="col-style2">
                                            </Col>
                                        </Row>
                                        <Row className="table-custom table-head2">&nbsp;&nbsp; <b>มาตรฐานงาน</b></Row>
                                        <Row className="table-custom2">
                                            <Col md={4} className="col-style2">คุณภาพ</Col>
                                            <Col md={2} className="col-style2"><center>{dataHistory['0'] === undefined ? '' : dataHistory['0']['score1_2_1']} </center></Col>
                                            <Col md={2} className="col-style2"><center>{dataHistory['1'] === undefined ? '' : dataHistory['1']['score1_2_1']} </center></Col>
                                            <Col md={2} className="col-style2"><center>{dataHistory['2'] === undefined ? '' : dataHistory['2']['score1_2_1']} </center></Col>
                                            <Col md={2} className="col-style2">
                                                <NumericInput className="form-control" name="score1_2_1" id="score1_2_1" onChange={valueAsNumber => this.handleInputChange(valueAsNumber, 30, 1, 1, 'score1_2_1')} value={data[0]['score1_2_1']} readOnly />
                                            </Col>
                                        </Row>
                                        <Row className="table-custom2">
                                            <Col md={4} className="col-style2">การแก้ไข</Col>
                                            <Col md={2} className="col-style2"><center>{dataHistory['0'] === undefined ? '' : dataHistory['0']['score1_2_2']} </center></Col>
                                            <Col md={2} className="col-style2"><center>{dataHistory['1'] === undefined ? '' : dataHistory['1']['score1_2_2']} </center></Col>
                                            <Col md={2} className="col-style2"><center>{dataHistory['2'] === undefined ? '' : dataHistory['2']['score1_2_2']} </center></Col>
                                            <Col md={2} className="col-style2">

                                            </Col>
                                        </Row>
                                        <Row className="table-custom table-head3">
                                            <Col md={4} className="col-style"><b>รวมคะแนนส่วนที่ 1</b></Col>
                                            <Col md={2} className="col-style"><b><center>{dataHistory['0'] === undefined ? '' : dataHistory['0']['scorePart1']} </center></b></Col>
                                            <Col md={2} className="col-style"><b><center>{dataHistory['1'] === undefined ? '' : dataHistory['1']['scorePart1']} </center></b></Col>
                                            <Col md={2} className="col-style"><b><center>{dataHistory['2'] === undefined ? '' : dataHistory['2']['scorePart1']} </center></b></Col>
                                            <Col md={2} className="col-style2">
                                                <Input type="text" name="scorePart1" id="scorePart1" value={data[0]['scorePart1']} readOnly />
                                            </Col>
                                        </Row>
                                        <br />
                                        <h4>ส่วนที่ 2 ด้านสมรรถนะและพฤติกรรม</h4>
                                        <Row className="table-custom table-head">&nbsp;&nbsp; <b>Core Competency</b></Row>
                                        <Row className="table-custom2">
                                            <Col md={4} className="col-style2">Teamwork</Col>
                                            <Col md={2} className="col-style2"><center>{dataHistory['0'] === undefined ? '' : dataHistory['0']['score2_1']} </center></Col>
                                            <Col md={2} className="col-style2"><center>{dataHistory['1'] === undefined ? '' : dataHistory['1']['score2_1']} </center></Col>
                                            <Col md={2} className="col-style2"><center>{dataHistory['2'] === undefined ? '' : dataHistory['2']['score2_1']} </center></Col>
                                            <Col md={2} className="col-style2">
                                                <NumericInput className="form-control" name="score2_1" id="score2_1" onChange={valueAsNumber => this.handleInputChange(valueAsNumber, 7, 0, 2, 'score2_1')} value={data[0]['score2_1']} readOnly />
                                            </Col>
                                        </Row>
                                        <Row className="table-custom2">
                                            <Col md={4} className="col-style2">Customer Focus</Col>
                                            <Col md={2} className="col-style2"><center>{dataHistory['0'] === undefined ? '' : dataHistory['0']['score2_2']} </center></Col>
                                            <Col md={2} className="col-style2"><center>{dataHistory['1'] === undefined ? '' : dataHistory['1']['score2_2']} </center></Col>
                                            <Col md={2} className="col-style2"><center>{dataHistory['2'] === undefined ? '' : dataHistory['2']['score2_2']} </center></Col>
                                            <Col md={2} className="col-style2">
                                                <NumericInput className="form-control" name="score2_2" id="score2_2" onChange={valueAsNumber => this.handleInputChange(valueAsNumber, 6, 1, 2, 'score2_2')} value={data[0]['score2_2']} readOnly />
                                            </Col>
                                        </Row>
                                        <Row className="table-custom2">
                                            <Col md={4} className="col-style2">Excellence / Go Beyond</Col>
                                            <Col md={2} className="col-style2"><center>{dataHistory['0'] === undefined ? '' : dataHistory['0']['score2_3']} </center></Col>
                                            <Col md={2} className="col-style2"><center>{dataHistory['1'] === undefined ? '' : dataHistory['1']['score2_3']} </center></Col>
                                            <Col md={2} className="col-style2"><center>{dataHistory['2'] === undefined ? '' : dataHistory['2']['score2_3']} </center></Col>
                                            <Col md={2} className="col-style2">
                                                <NumericInput className="form-control" name="score2_3" id="score2_3" onChange={valueAsNumber => this.handleInputChange(valueAsNumber, 6, 2, 2, 'score2_3')} value={data[0]['score2_3']} readOnly />
                                            </Col>
                                        </Row>
                                        <Row className="table-custom2">
                                            <Col md={4} className="col-style2">Learning and Development</Col>
                                            <Col md={2} className="col-style2"><center>{dataHistory['0'] === undefined ? '' : dataHistory['0']['score2_4']} </center></Col>
                                            <Col md={2} className="col-style2"><center>{dataHistory['1'] === undefined ? '' : dataHistory['1']['score2_4']} </center></Col>
                                            <Col md={2} className="col-style2"><center>{dataHistory['2'] === undefined ? '' : dataHistory['2']['score2_4']} </center></Col>
                                            <Col md={2} className="col-style2">
                                                <NumericInput className="form-control" name="score2_4" id="score2_4" onChange={valueAsNumber => this.handleInputChange(valueAsNumber, 6, 3, 2, 'score2_4')} value={data[0]['score2_4']} readOnly />
                                            </Col>
                                        </Row>
                                        <Row className="table-custom table-head">&nbsp;&nbsp; <b>Functional Competency</b></Row>
                                        <Row className="table-custom2">
                                            <Col md={4} className="col-style2">Job Knowledge</Col>
                                            <Col md={2} className="col-style2"><center>{dataHistory['0'] === undefined ? '' : dataHistory['0']['score2_6']} </center></Col>
                                            <Col md={2} className="col-style2"><center>{dataHistory['1'] === undefined ? '' : dataHistory['1']['score2_6']} </center></Col>
                                            <Col md={2} className="col-style2"><center>{dataHistory['2'] === undefined ? '' : dataHistory['2']['score2_6']} </center></Col>
                                            <Col md={2} className="col-style2">
                                                <NumericInput className="form-control" name="score2_6" id="score2_6" onChange={valueAsNumber => this.handleInputChange(valueAsNumber, 5, 4, 2, 'score2_6')} value={data[0]['score2_6']} readOnly />
                                            </Col>
                                        </Row>
                                        <Row className="table-custom2">
                                            <Col md={4} className="col-style2">Problem Solving</Col>
                                            <Col md={2} className="col-style2"><center>{dataHistory['0'] === undefined ? '' : dataHistory['0']['score2_7']} </center></Col>
                                            <Col md={2} className="col-style2"><center>{dataHistory['1'] === undefined ? '' : dataHistory['1']['score2_7']} </center></Col>
                                            <Col md={2} className="col-style2"><center>{dataHistory['2'] === undefined ? '' : dataHistory['2']['score2_7']} </center></Col>
                                            <Col md={2} className="col-style2">
                                                <NumericInput className="form-control" name="score2_7" id="score2_7" onChange={valueAsNumber => this.handleInputChange(valueAsNumber, 5, 5, 2, 'score2_7')} value={data[0]['score2_7']} readOnly />
                                            </Col>
                                        </Row>
                                        <Row className="table-custom table-head">&nbsp;&nbsp; <b>Managerial Competency</b></Row>
                                        <Row className="table-custom2">
                                            <Col md={4} className="col-style2">Leadership</Col>
                                            <Col md={2} className="col-style2"><center>{dataHistory['0'] === undefined ? '' : dataHistory['0']['score2_8']} </center></Col>
                                            <Col md={2} className="col-style2"><center>{dataHistory['1'] === undefined ? '' : dataHistory['1']['score2_8']} </center></Col>
                                            <Col md={2} className="col-style2"><center>{dataHistory['2'] === undefined ? '' : dataHistory['2']['score2_8']} </center></Col>
                                            <Col md={2} className="col-style2">

                                            </Col>
                                        </Row>
                                        <Row className="table-custom2">
                                            <Col md={4} className="col-style2">People Management</Col>
                                            <Col md={2} className="col-style2"><center>{dataHistory['0'] === undefined ? '' : dataHistory['0']['score2_9']} </center></Col>
                                            <Col md={2} className="col-style2"><center>{dataHistory['1'] === undefined ? '' : dataHistory['1']['score2_9']} </center></Col>
                                            <Col md={2} className="col-style2"><center>{dataHistory['2'] === undefined ? '' : dataHistory['2']['score2_9']} </center></Col>
                                            <Col md={2} className="col-style2">

                                            </Col>
                                        </Row>
                                        <Row className="table-custom2">
                                            <Col md={4} className="col-style2">Planing and Organizing</Col>
                                            <Col md={2} className="col-style2"><center>{dataHistory['0'] === undefined ? '' : dataHistory['0']['score2_10']} </center></Col>
                                            <Col md={2} className="col-style2"><center>{dataHistory['1'] === undefined ? '' : dataHistory['1']['score2_10']} </center></Col>
                                            <Col md={2} className="col-style2"><center>{dataHistory['2'] === undefined ? '' : dataHistory['2']['score2_10']} </center></Col>
                                            <Col md={2} className="col-style2">
                                                <NumericInput className="form-control" name="score2_10" id="score2_10" onChange={valueAsNumber => this.handleInputChange(valueAsNumber, 5, 6, 2, 'score2_10')} value={data[0]['score2_10']} readOnly />
                                            </Col>
                                        </Row>
                                        <Row className="table-custom table-head3">
                                            <Col md={4} className="col-style"><b>รวมคะแนนส่วนที่ 2</b></Col>
                                            <Col md={2} className="col-style"><b><center>{dataHistory['0'] === undefined ? '' : dataHistory['0']['scorePart2']} </center></b></Col>
                                            <Col md={2} className="col-style"><b><center>{dataHistory['1'] === undefined ? '' : dataHistory['1']['scorePart2']} </center></b></Col>
                                            <Col md={2} className="col-style"><b><center>{dataHistory['2'] === undefined ? '' : dataHistory['2']['scorePart2']} </center></b></Col>
                                            <Col md={2} className="col-style2">
                                                <Input type="text" name="scorePart2" id="scorePart2" value={data[0]['scorePart2']} readOnly />
                                            </Col>
                                        </Row>
                                        <Row className="table-custom table-head">
                                            <Col md={4} className="col-style"><b>รวมคะแนนทั้งหมด</b></Col>
                                            <Col md={2} className="col-style"><b><center>{dataHistory['0']['totalScore']} </center></b></Col>
                                            <Col md={2} className="col-style"><b><center>{dataHistory['1'] === undefined ? '' : dataHistory['1']['totalScore']} </center></b></Col>
                                            <Col md={2} className="col-style"><b><center>{dataHistory['2'] === undefined ? '' : dataHistory['2']['totalScore']} </center></b></Col>
                                            <Col md={2} className="col-style2">
                                                <Input type="text" name="totalScore" id="totalScore" value={data[0]['totalScore']} readOnly />
                                            </Col>
                                        </Row>
                                    </CardBody>
                                );
                            }
                        })}
                    </TabPane>
                </Tabs >
            </div>
        );

    }
}
export default PrintPE_STF;