import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../index.css";
import "antd/dist/antd.css";
import "typeface-roboto";
import "moment-timezone";
import Cookies from "js-cookie";
import { SetStringToObject } from "../constants/functions";
import encodeString from "../extendFunction/encodeString";
import { browserHistory } from "react-router";
import { RenderCustomMenu } from "../screens/conponents/menu/CustomMenu";

class App extends Component {
  constructor(props) {
    super(props);
    let premission = "";
    let userLogin = "";
    let locationM = "";
    const obj = SetStringToObject(Cookies.get("cookie_session"));
    if (obj !== null) {
      premission = encodeString.decode(obj.premission);
      userLogin = encodeString.decode(obj.ID_User);
      locationM = encodeString.decode(obj.location);
    } else {
      browserHistory.push("/");
    }

    this.state = {
      premission,
      userLogin,
      locationM,
      obj,
    };
  }

  render() {
    return (
      <div className="App">
        {
          this.state.obj && this.state.premission ? (
            <>
              <RenderCustomMenu />
              <div className="container">
                <div className="content">
                  {React.cloneElement(this.props.children, {
                    userLogin: this.state.userLogin,
                    premission: this.state.premission,
                    locationM: this.state.locationM,
                  })}
                </div>
              </div>
            </>
          ) : null //(
          //<HandleScene />
          //<Login />
          // )
        }
      </div>
    );
  }
}
export default App;
