import React from 'react'
import './tableEvac.css'
import { ButtonTheme } from '../../../moduleKPIs/common/buttons'
import { colors } from '../../../themes'
import { FIELD_BTN, TEXT_BTN } from './buttonEvac';
import { SearchOutlined, ClearOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { Input, Button, Tag } from 'antd';


export const TABLE_DASHBOARD_REVIEWER = ({ permission, userLogin, actionButton, actionStatus }) => {
    const searchInput = React.createRef();

    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm: fnSearch, clearFilters }) => (
            <div style={{ padding: 5, display: 'flex', alignItems: 'center' }}>
                <Input
                    ref={node => {
                        searchInput.current = node;
                    }}
                    placeholder={`ค้นหา...`}
                    value={selectedKeys && selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => {
                        fnSearch();
                        setSelectedKeys(selectedKeys[0])
                    }}
                    style={{ width: 200, margin: 5, display: 'block', padding: '3px 3px 3px 10px', fontSize: 12 }}
                />
                <Button
                    type="primary"
                    onClick={() => {
                        fnSearch();
                        setSelectedKeys(selectedKeys[0])
                    }}
                    icon={<SearchOutlined />}
                    size="small"
                    style={{ fontSize: 8 }} />
                <Button
                    icon={<ClearOutlined />}
                    onClick={() => {
                        clearFilters();
                        setSelectedKeys('');
                    }}
                    size="small"
                    style={{ fontSize: 8, margin: '0px 5px' }} />
            </div>
        ),
        onFilter: (value, record) =>
            record[dataIndex]
                ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
                : '',
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        render: text => text || '-'
    })

    switch (permission) {
        default:
            return [
                {
                    title: "ฟอร์มแบบสอบถาม",
                    children: [{
                        title: "รหัสฟอร์มแบบสอบถาม",
                        dataIndex: "formId",
                        align: 'left',
                        width: 200,
                        ...getColumnSearchProps('formId'),

                    }, {
                        title: "ชื่อฟอร์มแบบสอบถาม",
                        dataIndex: "formDescription",
                        align: 'left',
                        ...getColumnSearchProps('formDescription'),

                    }, {
                        title: "สถานะ",
                        dataIndex: "",
                        align: 'center',
                        width: 100,
                        filters: [
                        	{
                        		text: 'เสร็จสิ้น',
                        		value: 1,
                        	},
                        	{
                        		text: 'กำลังดำเนินการ',
                        		value: 0,
                        	},
                        ],
                        onFilter: (value, record) => record.confirmStatus === value,
                        render: (record) => record.confirmStatus === 1 ? <Tag color="green">เสร็จสิ้น</Tag> :<Tag color="orange">กำลังดำเนินการ</Tag>

                    }, {
                        title: "",
                        dataIndex: "confirmStatus",
                        width: 100,
                        align: 'center',
                        render: (text, record) => {
                            switch (text) {
                                case null:
                                    return (<ButtonTheme
                                        buttonStyle={{ backgroundColor: colors.success, color: colors.white, width: '100%', padding: 0 }}
                                        buttonText={TEXT_BTN.INSERT_EVALUTOR}
                                        onClick={() => actionButton({ fieldBtn: FIELD_BTN.INSERT_EVALUTOR, payload: record })} />
                                    )
                                case 0:
                                    return (
                                        <ButtonTheme
                                            buttonStyle={{ backgroundColor: colors.wraning, color: colors.white, width: '100%', padding: 0 }}
                                            buttonText={TEXT_BTN.UPDATE_EVALUTOR}
                                            onClick={() => actionButton({ fieldBtn: FIELD_BTN.UPDATE_EVALUTOR, payload: record })} />
                                    )
                                case 1:
                                    return (
                                        <ButtonTheme
                                            buttonStyle={{ backgroundColor: colors.info, color: colors.white, width: '100%', padding: 0 }}
                                            buttonText={TEXT_BTN.VIEW_EVALUTOR}
                                            onClick={() => actionButton({ fieldBtn: FIELD_BTN.VIEW_EVALUTOR, payload: record })} />
                                    )
                                default:
                                    break;
                            }
                        }
                    }]
                }
            ]
    }
}

export const TABLE_SUMMARY_REPLY_BY_DEP = ({ permission, searchData, userLogin, actionButton, actionStatus }) => {
    const searchInput = React.createRef();

    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm: fnSearch, clearFilters }) => (
            <div style={{ padding: 5, display: 'flex', alignItems: 'center' }}>
                <Input
                    ref={node => {
                        searchInput.current = node;
                    }}
                    placeholder={`ค้นหา...`}
                    value={selectedKeys && selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => {
                        fnSearch();
                        setSelectedKeys(selectedKeys[0])
                    }}
                    style={{ width: 200, margin: 5, display: 'block', padding: '3px 3px 3px 10px', fontSize: 12 }}
                />
                <Button
                    type="primary"
                    onClick={() => {
                        fnSearch();
                        setSelectedKeys(selectedKeys[0])
                    }}
                    icon={<SearchOutlined />}
                    size="small"
                    style={{ fontSize: 8 }} />
                <Button
                    icon={<ClearOutlined />}
                    onClick={() => {
                        clearFilters();
                        setSelectedKeys('');
                    }}
                    size="small"
                    style={{ fontSize: 8, margin: '0px 5px' }} />
            </div>
        ),
        onFilter: (value, record) =>
            record[dataIndex]
                ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
                : '',
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        render: text => text || '-'
    })

    switch (permission) {
        default:
            return [
                {
                    title: "แผนก",
                    dataIndex: "depName",
                    align: 'left',
                    ...getColumnSearchProps('depName')
                }, {
                    title: "สถานที่",
                    dataIndex: "locationName",
                    align: 'left',
                    ...getColumnSearchProps('locationName')

                }, {
                    title: "จำนวนแบบสอบถามที่ได้รับ ",
                    dataIndex: "countFormId",
                    sorter: (a, b) => b.countFormId - a.countFormId,
                    align: 'right',
                    // ...getColumnSearchProps('depName')
                }, {
                    title: "จำนวนแบบสอบถามที่ตอบกลับ",
                    dataIndex: "countDocEVAC",
                    sorter: (a, b) => b.countDocEVAC - a.countDocEVAC,
                    align: 'right',
                    // ...getColumnSearchProps('depName')
                }, {
                    title: "การตอบกลับ (%)",
                    dataIndex: "percentDone",
                    className: 'columns-color',
                    sorter: (a, b) => b.percentDone - a.percentDone,
                    align: 'right',
                    render: (text) => {
                        return (
                            <div style={{ backgroundColor: (text >= 90) ? '#b7f1b5' : '#FF9AA2', padding: 5, color: colors.black }}>
                                {`${parseFloat(text || 0).toFixed(2)} %` || '-'}
                            </div>)
                    }

                }]
    }
}

export const TABLE_SUMMARY_REPLY_BY_FORM = ({ permission, searchData, userLogin, actionButton, actionStatus }) => {
    const searchInput = React.createRef();

    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm: fnSearch, clearFilters }) => (
            <div style={{ padding: 5, display: 'flex', alignItems: 'center' }}>
                <Input
                    ref={node => {
                        searchInput.current = node;
                    }}
                    placeholder={`ค้นหา...`}
                    value={selectedKeys && selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => {
                        fnSearch();
                        setSelectedKeys(selectedKeys[0])
                    }}
                    style={{ width: 200, margin: 5, display: 'block', padding: '3px 3px 3px 10px', fontSize: 12 }}
                />
                <Button
                    type="primary"
                    onClick={() => {
                        fnSearch();
                        setSelectedKeys(selectedKeys[0])
                    }}
                    icon={<SearchOutlined />}
                    size="small"
                    style={{ fontSize: 8 }} />
                <Button
                    icon={<ClearOutlined />}
                    onClick={() => {
                        clearFilters();
                        setSelectedKeys('');
                    }}
                    size="small"
                    style={{ fontSize: 8, margin: '0px 5px' }} />
            </div>
        ),
        onFilter: (value, record) =>
            record[dataIndex]
                ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
                : '',
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        render: text => text || '-'
    })

    switch (permission) {
        default:
            return [
                {
                    title: "แบบประเมิน",
                    dataIndex: "formDescription",
                    align: 'left',
                    ...getColumnSearchProps('formDescription')
                }, {
                    title: "สถานที่",
                    dataIndex: "locationName",
                    align: 'left',
                    ...getColumnSearchProps('locationName')
                    // render: (text, record) => console.log(record)
                }, {
                    title: "จำนวนส่งออก",
                    dataIndex: "totalSend",
                    align: 'right',
                    sorter: (a, b) => b.totalSend - a.totalSend,
                    width: 80,
                    render: (text) => text || '-'
                }, {
                    title: "จำนวนตอบกลับ",
                    dataIndex: "totalReply",
                    align: 'right',
                    sorter: (a, b) => b.totalReply - a.totalReply,
                    width: 80,
                    render: (text) => text || '-'
                }, {
                    title: "การตอบกลับ (%)",
                    dataIndex: "percentReply",
                    align: 'right',
                    key: 'percentReply',
                    defaultSortOrder: 'ascend',
                    sorter: (a, b) => b.percentReply - a.percentReply,
                    render: (text) => `${parseFloat(text || 0).toFixed(2)} %` || '-'
                }, {
                    title: "ไม่ประสานงาน",
                    dataIndex: "frequencyWork",
                    sorter: (a, b) => b.frequencyWork - a.frequencyWork,
                    align: 'right',
                    render: (text) => `${parseFloat(text || 0).toFixed(2)}` || ''
                }, {
                    title: `Q4/${searchData.year} (%)`,
                    dataIndex: "avgScoreQ4",
                    className: 'columns-color',
                    // defaultSortOrder: 'ascend',
                    sorter: (a, b) => b.avgScoreQ4 - a.avgScoreQ4,
                    align: 'right',
                    render: (text, record) => {
                        let scoreAvg = parseFloat(text || '')
                        if (scoreAvg > 0 && scoreAvg < 70) {
                            return <div style={{ backgroundColor: '#FF9AA2', padding: 5, color: 'black' }}> {`${parseFloat(text || 0).toFixed(2)} %` || '-'} </div>
                        } else if (scoreAvg > 69.99 && scoreAvg < 75.01) {
                            return <div style={{ backgroundColor: '#ffcba2', padding: 5, color: 'black' }}> {`${parseFloat(text || 0).toFixed(2)} %` || '-'} </div>
                        } else if (scoreAvg > 75 && scoreAvg < 82.01) {
                            return <div style={{ backgroundColor: '#ffffce', padding: 5, color: 'black' }}> {`${parseFloat(text || 0).toFixed(2)} %` || '-'} </div>
                        } else if (scoreAvg > 82) {
                            return <div style={{ backgroundColor: '#b7f1b5', padding: 5, color: 'black' }}> {`${parseFloat(text || 0).toFixed(2)} %` || '-'} </div>
                        }
                    }
                }, {
                    title: `Q3/${searchData.year} (%)`,
                    dataIndex: "avgScoreQ3",
                    className: 'columns-color',
                    sorter: (a, b) => b.avgScoreQ3 - a.avgScoreQ3,
                    align: 'right',
                    render: (text, record) => {
                        let scoreAvg = parseFloat(text || '')
                        if (scoreAvg > 0 && scoreAvg < 70) {
                            return <div style={{ backgroundColor: '#FF9AA2', padding: 5, color: 'black' }}> {`${parseFloat(text || 0).toFixed(2)} %` || '-'} </div>
                        } else if (scoreAvg > 69.99 && scoreAvg < 75.01) {
                            return <div style={{ backgroundColor: '#ffcba2', padding: 5, color: 'black' }}> {`${parseFloat(text || 0).toFixed(2)} %` || '-'} </div>
                        } else if (scoreAvg > 75 && scoreAvg < 82.01) {
                            return <div style={{ backgroundColor: '#ffffce', padding: 5, color: 'black' }}> {`${parseFloat(text || 0).toFixed(2)} %` || '-'} </div>
                        } else if (scoreAvg > 82) {
                            return <div style={{ backgroundColor: '#b7f1b5', padding: 5, color: 'black' }}> {`${parseFloat(text || 0).toFixed(2)} %` || '-'} </div>
                        }
                    }
                }, {
                    title: `Q2/${searchData.year} (%)`,
                    dataIndex: "avgScoreQ2",
                    className: 'columns-color',
                    sorter: (a, b) => b.avgScoreQ2 - a.avgScoreQ2,
                    align: 'right',
                    render: (text, record) => {
                        let scoreAvg = parseFloat(text || '')
                        if (scoreAvg > 0 && scoreAvg < 70) {
                            return <div style={{ backgroundColor: '#FF9AA2', padding: 5, color: 'black' }}> {`${parseFloat(text || 0).toFixed(2)} %` || '-'} </div>
                        } else if (scoreAvg > 69.99 && scoreAvg < 75.01) {
                            return <div style={{ backgroundColor: '#ffcba2', padding: 5, color: 'black' }}> {`${parseFloat(text || 0).toFixed(2)} %` || '-'} </div>
                        } else if (scoreAvg > 75 && scoreAvg < 82.01) {
                            return <div style={{ backgroundColor: '#ffffce', padding: 5, color: 'black' }}> {`${parseFloat(text || 0).toFixed(2)} %` || '-'} </div>
                        } else if (scoreAvg > 82) {
                            return <div style={{ backgroundColor: '#b7f1b5', padding: 5, color: 'black' }}> {`${parseFloat(text || 0).toFixed(2)} %` || '-'} </div>
                        }
                    }
                }, {
                    title: `Q1/${searchData.year} (%)`,
                    dataIndex: "avgScoreQ1",
                    className: 'columns-color',
                    sorter: (a, b) => b.avgScoreQ1 - a.avgScoreQ1,
                    align: 'right',
                    render: (text, record) => {
                        let scoreAvg = parseFloat(text || '')
                        if (scoreAvg > 0 && scoreAvg < 70) {
                            return <div style={{ backgroundColor: '#FF9AA2', padding: 5, color: 'black' }}> {`${parseFloat(text || 0).toFixed(2)} %` || '-'} </div>
                        } else if (scoreAvg > 69.99 && scoreAvg < 75.01) {
                            return <div style={{ backgroundColor: '#ffcba2', padding: 5, color: 'black' }}> {`${parseFloat(text || 0).toFixed(2)} %` || '-'} </div>
                        } else if (scoreAvg > 75 && scoreAvg < 82.01) {
                            return <div style={{ backgroundColor: '#ffffce', padding: 5, color: 'black' }}> {`${parseFloat(text || 0).toFixed(2)} %` || '-'} </div>
                        } else if (scoreAvg > 82) {
                            return <div style={{ backgroundColor: '#b7f1b5', padding: 5, color: 'black' }}> {`${parseFloat(text || 0).toFixed(2)} %` || '-'} </div>
                        }

                    }
                }, {
                    title: <span>
                        <span style={{ color: colors.success }}> ^ </span>
                        <span style={{ color: colors.baseText }}> / </span>
                        <span style={{ color: colors.error }}> v </span>
                    </span>,
                    dataIndex: "avgScore",
                    align: 'center',
                    width: 100,
                    render: (text, record) => {
                        if (parseFloat(text || 0) > parseFloat(record.avgScoreBF || 0)) {
                            return (
                                <div style={{ color: colors.success, fontWeight: 600 }}> ^ </div>
                            )
                        } else {
                            return (
                                <div style={{ color: colors.error, fontWeight: 600 }}> v </div>
                            )
                        }
                    }
                }]
    }
}

export const TABLE_DASHBOARD_WAIT_EVAC = ({ permission, userLogin, actionButton, actionStatus }) => {
    const searchInput = React.createRef();

    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm: fnSearch, clearFilters }) => (
            <div style={{ padding: 5, display: 'flex', alignItems: 'center' }}>
                <Input
                    ref={node => {
                        searchInput.current = node;
                    }}
                    placeholder={`ค้นหา...`}
                    value={selectedKeys && selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => {
                        fnSearch();
                        setSelectedKeys(selectedKeys[0])
                    }}
                    style={{ width: 200, margin: 5, display: 'block', padding: '3px 3px 3px 10px', fontSize: 12 }}
                />
                <Button
                    type="primary"
                    onClick={() => {
                        fnSearch();
                        setSelectedKeys(selectedKeys[0])
                    }}
                    icon={<SearchOutlined />}
                    size="small"
                    style={{ fontSize: 8 }} />
                <Button
                    icon={<ClearOutlined />}
                    onClick={() => {
                        clearFilters();
                        setSelectedKeys('');
                    }}
                    size="small"
                    style={{ fontSize: 8, margin: '0px 5px' }} />
            </div>
        ),
        onFilter: (value, record) =>
            record[dataIndex]
                ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
                : '',
        filterIcon: filtered => (<SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />),
        render: text => text || '-'
    })

    switch (permission) {
        default:
            return [{
                title: "รหัสพนักงาน",
                dataIndex: "userId",
                align: 'left',
                render: (text) => text || '-'
                // ...getColumnSearchProps('userId'),
            }, {
                title: "ชื่อ",
                dataIndex: "fullname",
                align: 'left',
                // render: (text) => text || '-',
                ...getColumnSearchProps('fullname'),
            }, {
                title: "ชื่อเล่น",
                dataIndex: "nickname",
                align: 'left',
                ...getColumnSearchProps('nickname'),
            }, {
                title: "แบบประเมิน",
                dataIndex: "formDescription",
                align: 'left',
                width: 200,
                render: (text) => text || '-'
            }, {
                title: "สถานะ",
                dataIndex: "statusDoEVACDes",
                align: 'left',
                render: (text) => text || '-'
            }, {
                title: "",
                dataIndex: "statusDoEVAC",
                width: 100,
                align: 'left',
                render: (text, record) => {
                    switch (text) {
                        case "0":
                            return (
                                <ButtonTheme
                                    buttonStyle={{ backgroundColor: colors.success, color: colors.white }}
                                    buttonText="ทำแบบสอบถาม"
                                    onClick={() => actionButton({ fieldBtn: FIELD_BTN.INSERT_EVAC, payload: record })} />
                            )
                        // case "1":
                        //     return (
                        //         <ButtonTheme
                        //             buttonStyle={{ backgroundColor: colors.success, color: colors.white }}
                        //             buttonText="ดูรายละเอียด"
                        //             onClick={() => actionButton({ fieldBtn: FIELD_BTN.VIEW_EVAC, payload: record })} />
                        //     )

                        default:
                            break;
                    }
                }
            }
            ]
    }
}
export const TABLE_DASHBOARD_FOLLOW_EVAC = ({ permission, department, percentDone }) => {
    const searchInput = React.createRef();

    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm: fnSearch, clearFilters }) => (
            <div style={{ padding: 5, display: 'flex', alignItems: 'center' }}>
                <Input
                    ref={node => {
                        searchInput.current = node;
                    }}
                    placeholder={`ค้นหา...`}
                    value={selectedKeys && selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => {
                        fnSearch();
                        setSelectedKeys(selectedKeys[0])
                    }}
                    style={{ width: 200, margin: 5, display: 'block', padding: '3px 3px 3px 10px', fontSize: 12 }}
                />
                <Button
                    type="primary"
                    onClick={() => {
                        fnSearch();
                        setSelectedKeys(selectedKeys[0])
                    }}
                    icon={<SearchOutlined />}
                    size="small"
                    style={{ fontSize: 8 }} />
                <Button
                    icon={<ClearOutlined />}
                    onClick={() => {
                        clearFilters();
                        setSelectedKeys('');
                    }}
                    size="small"
                    style={{ fontSize: 8, margin: '0px 5px' }} />
            </div>
        ),
        onFilter: (value, record) =>
            record[dataIndex]
                ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
                : '',
        filterIcon: filtered => (<SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />),
        render: text => text || '-'
    })

    return [{
        title: <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div style={{ fontSize: 18, padding: 10 }}>
                {`แผนก ${department}`}
            </div>
            {!permission ? <div style={{ fontSize: 18, padding: 10, color: (percentDone >= 90) ? colors.success : colors.error }}>
                {`ทำการประเมินแล้ว ${percentDone}%`}
            </div> : <div />}
        </div>,
        align: 'start',
        children:
            [{
                title: "รหัสพนักงาน",
                dataIndex: "userId",
                align: 'left',
                render: (text) => text || '-'
                // ...getColumnSearchProps('userId'),
            }, {
                title: "ชื่อ",
                dataIndex: "fullname",
                align: 'left',
                ...getColumnSearchProps('fullname'),
            }, {
                title: "ชื่อเล่น",
                dataIndex: "nickname",
                align: 'left',
                width: 100,
                ...getColumnSearchProps('nickname'),
            }, {
                title: "แบบประเมิน",
                dataIndex: "formDescription",
                align: 'left',
                render: (text) => text || '-'
            }, {
                title: "สถานะ",
                width: 300,
                dataIndex: "statusDoEVACDes",
                align: 'center',
                render: (text) => {
                    return (
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', color: colors.error }}>
                            <div style={{ display: 'flex', paddingRight: 10 }}>    <CloseCircleOutlined />   </div>
                            <div>{text || ''}</div>
                        </div>
                    )
                }
            }]
    }]
}

export const TABLE_INSERT_EVAC = ({ permission, userLogin, actionButton, actionStatus }) => {
    const searchInput = React.createRef();

    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm: fnSearch, clearFilters }) => (
            <div style={{ padding: 5, display: 'flex', alignItems: 'center' }}>
                <Input
                    ref={node => {
                        searchInput.current = node;
                    }}
                    placeholder={`ค้นหา...`}
                    value={selectedKeys && selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => {
                        fnSearch();
                        setSelectedKeys(selectedKeys[0])
                    }}
                    style={{ width: 200, margin: 5, display: 'block', padding: '3px 3px 3px 10px', fontSize: 12 }}
                />
                <Button
                    type="primary"
                    onClick={() => {
                        fnSearch();
                        setSelectedKeys(selectedKeys[0])
                    }}
                    icon={<SearchOutlined />}
                    size="small"
                    style={{ fontSize: 8 }} />
                <Button
                    icon={<ClearOutlined />}
                    onClick={() => {
                        clearFilters();
                        setSelectedKeys('');
                    }}
                    size="small"
                    style={{ fontSize: 8, margin: '0px 5px' }} />
            </div>
        ),
        onFilter: (value, record) =>
            record[dataIndex]
                ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
                : '',
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        render: text => text || '-'
    })
    switch (permission) {
        default:
            return [
                {
                    title: "เลือกผู้ทำแบบสอบถาม",
                    children: [{
                        title: "รหัสพนักงาน",
                        dataIndex: "userId",
                        align: 'left',
                        ...getColumnSearchProps('userId'),
                    }, {
                        title: "ชื่อ",
                        dataIndex: "name",
                        align: 'left',
                        ...getColumnSearchProps('name'),
                    }, {
                        title: "ชื่อเล่น",
                        dataIndex: "nickname",
                        align: 'left',
                        ...getColumnSearchProps('nickname'),
                    }, {
                        title: "แผนก",
                        dataIndex: "depName",
                        align: 'left',
                        // render: (text) => text || '-'
                        ...getColumnSearchProps('depName'),
                    }, {
                        title: "แผนกย่อย",
                        dataIndex: "subDepName",
                        align: 'left',
                        render: (text) => text || '-'
                    }, {
                        title: "บริษัท",
                        dataIndex: "company",
                        align: 'left',
                        render: (text) => text || '-'
                    }, {
                        title: "สถานที่",
                        dataIndex: "locationName",
                        align: 'left',
                        ...getColumnSearchProps('locationName'),
                    }, {
                        title: "การติดต่อประสานงาน (ครั้งก่อนหน้า)",
                        dataIndex: "frequencyWorkDes",
                        align: 'left',
                        render: (text, record) => {
                            return (<span style={{ color: (record.frequencyWork === 0) ? colors.error : colors.black }}> {text || '-'}</span>)
                        }
                    }]
                }
            ]
    }
}

export const TABLE_PREVIEW_REVIEWER = ({ permission, userLogin, actionButton, actionStatus }) => {
    switch (permission) {
        default:
            return [{
                title: "รหัสพนักงาน",
                dataIndex: "userId",
                align: 'left',
            }, {
                title: "ชื่อ",
                dataIndex: "fullname",
                align: 'left',
            }, {
                title: "ชื่อเล่น",
                dataIndex: "nickname",
                align: 'left',
            }, {
                title: "แผนก",
                dataIndex: "depName",
                align: 'left',
            }]
    }

}


export const TABLE_SUMMARY_AVG_SCORE_BY_LINE = ({ permission, lastSource, searchData, userLogin, actionButton, actionStatus }) => {
    const searchInput = React.createRef();

    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm: fnSearch, clearFilters }) => (
            <div style={{ padding: 5, display: 'flex', alignItems: 'center' }}>
                <Input
                    ref={node => {
                        searchInput.current = node;
                    }}
                    placeholder={`ค้นหา...`}
                    value={selectedKeys && selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => {
                        fnSearch();
                        setSelectedKeys(selectedKeys[0])
                    }}
                    style={{ width: 200, margin: 5, display: 'block', padding: '3px 3px 3px 10px', fontSize: 12 }}
                />
                <Button
                    type="primary"
                    onClick={() => {
                        fnSearch();
                        setSelectedKeys(selectedKeys[0])
                    }}
                    icon={<SearchOutlined />}
                    size="small"
                    style={{ fontSize: 8 }} />
                <Button
                    icon={<ClearOutlined />}
                    onClick={() => {
                        clearFilters();
                        setSelectedKeys('');
                    }}
                    size="small"
                    style={{ fontSize: 8, margin: '0px 5px' }} />
            </div>
        ),
        onFilter: (value, record) =>
            record[dataIndex]
                ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
                : '',
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        render: text => text || '-'
    })

    switch (permission) {
        default:
            return [
                {
                    title: "แบบประเมิน",
                    dataIndex: "formDescription",
                    align: 'left',
                    ...getColumnSearchProps('formDescription')
                }, {
                    title: "หัวข้อการประเมิน",
                    dataIndex: "question",
                    align: 'left',
                    className: 'columns-color',
                    // ...getColumnSearchProps('question'),
                    render: (text, record) => {
                        // console.log({ record, lastSource })
                        let lastQuestion = record.questionNo === `q8`
                        if (lastQuestion) {
                            return <div style={{ backgroundColor: '#ffffce', padding: 5, color: 'black' }}> {text || '-'} </div>
                        } else {
                            return <div style={{ padding: 5, color: 'black' }}>  {text || '-'}</div>
                        }
                    }
                },
                //  {
                //     title: "จำนวนส่งออก",
                //     dataIndex: "totalSend",
                //     sorter: (a, b) => b.totalSend - a.totalSend,

                //     align: 'right',
                //     width: 80,
                //     render: (text) => text || '-'
                // }, {
                //     title: "จำนวนตอบกลับ",
                //     dataIndex: "totalReply",
                //     align: 'right',
                //     width: 80,
                //     sorter: (a, b) => b.totalReply - a.totalReply,
                //     render: (text) => text || '-'
                // }, {
                //     title: "การตอบกลับ (%)",
                //     dataIndex: "percentReply",
                //     align: 'right',
                //     sorter: (a, b) => b.percentReply - a.percentReply,
                //     render: (text) => `${parseFloat(text || 0).toFixed(2)} %` || '-'
                // }, {
                //     title: "ไม่ประสานงาน",
                //     dataIndex: "frequencyWork",
                //     sorter: (a, b) => b.frequencyWork - a.frequencyWork,
                //     align: 'right',
                //     render: (text) => `${parseFloat(text || 0).toFixed(2)}` || ''
                // },
                {
                    title: `คะแนนเฉลี่ย Q4/${searchData.year} (%)`,
                    className: 'columns-color',
                    dataIndex: "avgScoreQ4",
                    align: 'right',
                    sorter: (a, b) => b.avgScoreQ4 - a.avgScoreQ4,
                    render: (text, record) => {
                        let scoreAvg = parseFloat(text || '')
                        if (scoreAvg > 0 && scoreAvg < 70) {
                            return <div style={{ backgroundColor: '#FF9AA2', padding: 5, color: 'black' }}> {`${parseFloat(text || 0).toFixed(2)} %` || '-'} </div>
                        } else if (scoreAvg > 69.99 && scoreAvg < 75.01) {
                            return <div style={{ backgroundColor: '#ffcba2', padding: 5, color: 'black' }}> {`${parseFloat(text || 0).toFixed(2)} %` || '-'} </div>
                        } else if (scoreAvg > 75 && scoreAvg < 82.01) {
                            return <div style={{ backgroundColor: '#ffffce', padding: 5, color: 'black' }}> {`${parseFloat(text || 0).toFixed(2)} %` || '-'} </div>
                        } else if (scoreAvg > 82) {
                            return <div style={{ backgroundColor: '#b7f1b5', padding: 5, color: 'black' }}> {`${parseFloat(text || 0).toFixed(2)} %` || '-'} </div>
                        }

                    }
                    // render: (text) => `${parseFloat(text || 0).toFixed(2)} %` || '-'
                },
                {
                    title: `คะแนนเฉลี่ย Q3/${searchData.year} (%)`,
                    className: 'columns-color',
                    dataIndex: "avgScoreQ3",
                    align: 'right',
                    sorter: (a, b) => b.avgScoreQ3 - a.avgScoreQ3,
                    render: (text, record) => {
                        let scoreAvg = parseFloat(text || '')
                        if (scoreAvg > 0 && scoreAvg < 70) {
                            return <div style={{ backgroundColor: '#FF9AA2', padding: 5, color: 'black' }}> {`${parseFloat(text || 0).toFixed(2)} %` || '-'} </div>
                        } else if (scoreAvg > 69.99 && scoreAvg < 75.01) {
                            return <div style={{ backgroundColor: '#ffcba2', padding: 5, color: 'black' }}> {`${parseFloat(text || 0).toFixed(2)} %` || '-'} </div>
                        } else if (scoreAvg > 75 && scoreAvg < 82.01) {
                            return <div style={{ backgroundColor: '#ffffce', padding: 5, color: 'black' }}> {`${parseFloat(text || 0).toFixed(2)} %` || '-'} </div>
                        } else if (scoreAvg > 82) {
                            return <div style={{ backgroundColor: '#b7f1b5', padding: 5, color: 'black' }}> {`${parseFloat(text || 0).toFixed(2)} %` || '-'} </div>
                        }
                    }
                    // render: (text) => `${parseFloat(text || 0).toFixed(2)} %` || '-'
                }, {
                    title: `คะแนนเฉลี่ย Q2/${searchData.year} (%)`,
                    className: 'columns-color',
                    dataIndex: "avgScoreQ2",
                    align: 'right',
                    sorter: (a, b) => b.avgScoreQ2 - a.avgScoreQ2,
                    render: (text, record) => {
                        let scoreAvg = parseFloat(text || '')
                        if (scoreAvg > 0 && scoreAvg < 70) {
                            return <div style={{ backgroundColor: '#FF9AA2', padding: 5, color: 'black' }}> {`${parseFloat(text || 0).toFixed(2)} %` || '-'} </div>
                        } else if (scoreAvg > 69.99 && scoreAvg < 75.01) {
                            return <div style={{ backgroundColor: '#ffcba2', padding: 5, color: 'black' }}> {`${parseFloat(text || 0).toFixed(2)} %` || '-'} </div>
                        } else if (scoreAvg > 75 && scoreAvg < 82.01) {
                            return <div style={{ backgroundColor: '#ffffce', padding: 5, color: 'black' }}> {`${parseFloat(text || 0).toFixed(2)} %` || '-'} </div>
                        } else if (scoreAvg > 82) {
                            return <div style={{ backgroundColor: '#b7f1b5', padding: 5, color: 'black' }}> {`${parseFloat(text || 0).toFixed(2)} %` || '-'} </div>
                        }

                    }
                    // render: (text) => `${parseFloat(text || 0).toFixed(2)} %` || '-'
                }, {
                    title: `คะแนนเฉลี่ย Q1/${searchData.year} (%)`,
                    className: 'columns-color',
                    dataIndex: "avgScoreQ1",
                    align: 'right',
                    sorter: (a, b) => b.avgScoreQ1 - a.avgScoreQ1,
                    render: (text, record) => {
                        let scoreAvg = parseFloat(text || '')
                        if (scoreAvg > 0 && scoreAvg < 70) {
                            return <div style={{ backgroundColor: '#FF9AA2', padding: 5, color: 'black' }}> {`${parseFloat(text || 0).toFixed(2)} %` || '-'} </div>
                        } else if (scoreAvg > 69.99 && scoreAvg < 75.01) {
                            return <div style={{ backgroundColor: '#ffcba2', padding: 5, color: 'black' }}> {`${parseFloat(text || 0).toFixed(2)} %` || '-'} </div>
                        } else if (scoreAvg > 75 && scoreAvg < 82.01) {
                            return <div style={{ backgroundColor: '#ffffce', padding: 5, color: 'black' }}> {`${parseFloat(text || 0).toFixed(2)} %` || '-'} </div>
                        } else if (scoreAvg > 82) {
                            return <div style={{ backgroundColor: '#b7f1b5', padding: 5, color: 'black' }}> {`${parseFloat(text || 0).toFixed(2)} %` || '-'} </div>
                        }
                    }
                    // render: (text) => `${parseFloat(text || 0).toFixed(2)} %` || '-'
                }, {
                    title: <span>
                        <span style={{ color: colors.success, fontWeight: 600 }}> ^ </span>
                        <span style={{ color: colors.baseText }}> / </span>
                        <span style={{ color: colors.error, fontWeight: 600 }}> v </span>
                    </span>,
                    dataIndex: "trend",
                    align: 'center',
                    render: (text, record) => {
                        if (text === "up") {
                            return (
                                <div style={{ color: colors.success, fontWeight: 600 }}> ^ </div>
                            )
                        } else {
                            return (
                                <div style={{ color: colors.error, fontWeight: 600 }}> v </div>
                            )
                        }
                    }
                }]
    }
}



export const TABLE_RAW_EVALUATION = ({ permission, lastSource, searchData, userLogin, actionButton, actionStatus }) => {
    const searchInput = React.createRef();

    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm: fnSearch, clearFilters }) => (
            <div style={{ padding: 5, display: 'flex', alignItems: 'center' }}>
                <Input
                    ref={node => {
                        searchInput.current = node;
                    }}
                    placeholder={`ค้นหา...`}
                    value={selectedKeys && selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => {
                        fnSearch();
                        setSelectedKeys(selectedKeys[0])
                    }}
                    style={{ width: 200, margin: 5, display: 'block', padding: '3px 3px 3px 10px', fontSize: 12 }}
                />
                <Button
                    type="primary"
                    onClick={() => {
                        fnSearch();
                        setSelectedKeys(selectedKeys[0])
                    }}
                    icon={<SearchOutlined />}
                    size="small"
                    style={{ fontSize: 8 }} />
                <Button
                    icon={<ClearOutlined />}
                    onClick={() => {
                        clearFilters();
                        setSelectedKeys('');
                    }}
                    size="small"
                    style={{ fontSize: 8, margin: '0px 5px' }} />
            </div>
        ),
        onFilter: (value, record) =>
            record[dataIndex]
                ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
                : '',
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        render: text => text || '-'
    })

    switch (permission) {
        default:
            return [
                {
                    title: "แบบประเมิน",
                    dataIndex: "formDescription",
                    align: 'left',
                    ...getColumnSearchProps('formDescription')
                }, {
                    title: "หัวข้อการประเมิน",
                    dataIndex: "question",
                    align: 'left',
                    className: 'columns-color',
                    // ...getColumnSearchProps('question'),
                    render: (text, record) => {
                        // console.log({ record, lastSource })
                        let lastQuestion = record.questionNo === `q8`
                        if (lastQuestion) {
                            return <div style={{ backgroundColor: '#ffffce', padding: 5, color: 'black' }}> {text || '-'} </div>
                        } else {
                            return <div style={{ padding: 5, color: 'black' }}>  {text || '-'}</div>
                        }
                    }
                },
                //  {
                //     title: "จำนวนส่งออก",
                //     dataIndex: "totalSend",
                //     sorter: (a, b) => b.totalSend - a.totalSend,

                //     align: 'right',
                //     width: 80,
                //     render: (text) => text || '-'
                // }, {
                //     title: "จำนวนตอบกลับ",
                //     dataIndex: "totalReply",
                //     align: 'right',
                //     width: 80,
                //     sorter: (a, b) => b.totalReply - a.totalReply,
                //     render: (text) => text || '-'
                // }, {
                //     title: "การตอบกลับ (%)",
                //     dataIndex: "percentReply",
                //     align: 'right',
                //     sorter: (a, b) => b.percentReply - a.percentReply,
                //     render: (text) => `${parseFloat(text || 0).toFixed(2)} %` || '-'
                // }, {
                //     title: "ไม่ประสานงาน",
                //     dataIndex: "frequencyWork",
                //     sorter: (a, b) => b.frequencyWork - a.frequencyWork,
                //     align: 'right',
                //     render: (text) => `${parseFloat(text || 0).toFixed(2)}` || ''
                // },
                {
                    title: `คะแนนเฉลี่ย Q4/${searchData.year} (%)`,
                    className: 'columns-color',
                    dataIndex: "avgScoreQ4",
                    align: 'right',
                    sorter: (a, b) => b.avgScoreQ4 - a.avgScoreQ4,
                    render: (text, record) => {
                        let scoreAvg = parseFloat(text || '')
                        if (scoreAvg > 0 && scoreAvg < 70) {
                            return <div style={{ backgroundColor: '#FF9AA2', padding: 5, color: 'black' }}> {`${parseFloat(text || 0).toFixed(2)} %` || '-'} </div>
                        } else if (scoreAvg > 69.99 && scoreAvg < 75.01) {
                            return <div style={{ backgroundColor: '#ffcba2', padding: 5, color: 'black' }}> {`${parseFloat(text || 0).toFixed(2)} %` || '-'} </div>
                        } else if (scoreAvg > 75 && scoreAvg < 82.01) {
                            return <div style={{ backgroundColor: '#ffffce', padding: 5, color: 'black' }}> {`${parseFloat(text || 0).toFixed(2)} %` || '-'} </div>
                        } else if (scoreAvg > 82) {
                            return <div style={{ backgroundColor: '#b7f1b5', padding: 5, color: 'black' }}> {`${parseFloat(text || 0).toFixed(2)} %` || '-'} </div>
                        }

                    }
                    // render: (text) => `${parseFloat(text || 0).toFixed(2)} %` || '-'
                },
                {
                    title: `คะแนนเฉลี่ย Q3/${searchData.year} (%)`,
                    className: 'columns-color',
                    dataIndex: "avgScoreQ3",
                    align: 'right',
                    sorter: (a, b) => b.avgScoreQ3 - a.avgScoreQ3,
                    render: (text, record) => {
                        let scoreAvg = parseFloat(text || '')
                        if (scoreAvg > 0 && scoreAvg < 70) {
                            return <div style={{ backgroundColor: '#FF9AA2', padding: 5, color: 'black' }}> {`${parseFloat(text || 0).toFixed(2)} %` || '-'} </div>
                        } else if (scoreAvg > 69.99 && scoreAvg < 75.01) {
                            return <div style={{ backgroundColor: '#ffcba2', padding: 5, color: 'black' }}> {`${parseFloat(text || 0).toFixed(2)} %` || '-'} </div>
                        } else if (scoreAvg > 75 && scoreAvg < 82.01) {
                            return <div style={{ backgroundColor: '#ffffce', padding: 5, color: 'black' }}> {`${parseFloat(text || 0).toFixed(2)} %` || '-'} </div>
                        } else if (scoreAvg > 82) {
                            return <div style={{ backgroundColor: '#b7f1b5', padding: 5, color: 'black' }}> {`${parseFloat(text || 0).toFixed(2)} %` || '-'} </div>
                        }
                    }
                    // render: (text) => `${parseFloat(text || 0).toFixed(2)} %` || '-'
                }, {
                    title: `คะแนนเฉลี่ย Q2/${searchData.year} (%)`,
                    className: 'columns-color',
                    dataIndex: "avgScoreQ2",
                    align: 'right',
                    sorter: (a, b) => b.avgScoreQ2 - a.avgScoreQ2,
                    render: (text, record) => {
                        let scoreAvg = parseFloat(text || '')
                        if (scoreAvg > 0 && scoreAvg < 70) {
                            return <div style={{ backgroundColor: '#FF9AA2', padding: 5, color: 'black' }}> {`${parseFloat(text || 0).toFixed(2)} %` || '-'} </div>
                        } else if (scoreAvg > 69.99 && scoreAvg < 75.01) {
                            return <div style={{ backgroundColor: '#ffcba2', padding: 5, color: 'black' }}> {`${parseFloat(text || 0).toFixed(2)} %` || '-'} </div>
                        } else if (scoreAvg > 75 && scoreAvg < 82.01) {
                            return <div style={{ backgroundColor: '#ffffce', padding: 5, color: 'black' }}> {`${parseFloat(text || 0).toFixed(2)} %` || '-'} </div>
                        } else if (scoreAvg > 82) {
                            return <div style={{ backgroundColor: '#b7f1b5', padding: 5, color: 'black' }}> {`${parseFloat(text || 0).toFixed(2)} %` || '-'} </div>
                        }

                    }
                    // render: (text) => `${parseFloat(text || 0).toFixed(2)} %` || '-'
                }, {
                    title: `คะแนนเฉลี่ย Q1/${searchData.year} (%)`,
                    className: 'columns-color',
                    dataIndex: "avgScoreQ1",
                    align: 'right',
                    sorter: (a, b) => b.avgScoreQ1 - a.avgScoreQ1,
                    render: (text, record) => {
                        let scoreAvg = parseFloat(text || '')
                        if (scoreAvg > 0 && scoreAvg < 70) {
                            return <div style={{ backgroundColor: '#FF9AA2', padding: 5, color: 'black' }}> {`${parseFloat(text || 0).toFixed(2)} %` || '-'} </div>
                        } else if (scoreAvg > 69.99 && scoreAvg < 75.01) {
                            return <div style={{ backgroundColor: '#ffcba2', padding: 5, color: 'black' }}> {`${parseFloat(text || 0).toFixed(2)} %` || '-'} </div>
                        } else if (scoreAvg > 75 && scoreAvg < 82.01) {
                            return <div style={{ backgroundColor: '#ffffce', padding: 5, color: 'black' }}> {`${parseFloat(text || 0).toFixed(2)} %` || '-'} </div>
                        } else if (scoreAvg > 82) {
                            return <div style={{ backgroundColor: '#b7f1b5', padding: 5, color: 'black' }}> {`${parseFloat(text || 0).toFixed(2)} %` || '-'} </div>
                        }
                    }
                    // render: (text) => `${parseFloat(text || 0).toFixed(2)} %` || '-'
                }, {
                    title: <span>
                        <span style={{ color: colors.success, fontWeight: 600 }}> ^ </span>
                        <span style={{ color: colors.baseText }}> / </span>
                        <span style={{ color: colors.error, fontWeight: 600 }}> v </span>
                    </span>,
                    dataIndex: "trend",
                    align: 'center',
                    render: (text, record) => {
                        if (text === "up") {
                            return (
                                <div style={{ color: colors.success, fontWeight: 600 }}> ^ </div>
                            )
                        } else {
                            return (
                                <div style={{ color: colors.error, fontWeight: 600 }}> v </div>
                            )
                        }
                    }
                }]
    }
}


export const COLUMNS_EXPORT_DASHBOARD_EVAC = ({ permission }) => {
    switch (permission) {
        default:
            return [
                {
                    title: "รหัสพนักงาน",
                    field: "userId"
                }, {
                    title: "แบบประเมิน",
                    field: "formDescription"
                }, {
                    title: "ชื่อ",
                    field: "fullname"
                }, {
                    title: "ชื่อเล่น",
                    field: "nickname"
                }, {
                    title: "แผนก",
                    field: "depName"
                }
            ]
    }
}


export const COLUMNS_EXPORT_DASHBOARD_ADMIN = ({ permission, searchData }) => {
    switch (permission) {
        default:
            return [
                {
                    title: "แบบประเมิน",
                    field: "formDescription"
                }, {
                    title: "สถานที่",
                    field: "locationName"
                }, {
                    title: "ส่งออก",
                    field: "totalSend"
                }, {
                    title: "ตอบกลับ",
                    field: "totalReply"
                }, {
                    title: "การตอบกลับ (%)",
                    field: "percentReply"
                }, {
                    title: "ไม่ประสานงาน",
                    field: "frequencyWork"
                }, {
                    title: `Q4/${searchData.year} (%)`,
                    field: "avgScoreQ4"
                }, {
                    title: `Q3/${searchData.year} (%)`,
                    field: "avgScoreQ3"
                }, {
                    title: `Q2/${searchData.year} (%)`,
                    field: "avgScoreQ2"
                }, {
                    title: `Q1/${searchData.year} (%)`,
                    field: "avgScoreQ1"
                }, {
                    title: '^/v',
                    field: "trend"
                }
            ]
    }
}


export const COLUMNS_EXPORT_DASHBOARD_DEP = ({ permission, searchData }) => {
    switch (permission) {
        default:
            return [

                {
                    title: "แผนก",
                    field: "depName"
                }, {
                    title: "สถานที่",
                    field: "locationName"
                }, {
                    title: "จำนวนแบบสอบถามที่ได้รับ ",
                    field: "countFormId"
                }, {
                    title: "จำนวนแบบสอบถามที่ตอบกลับ",
                    field: "countDocEVAC"
                }, {
                    title: "การตอบกลับ (%)",
                    field: "percentDone"
                }
            ]
    }
}


