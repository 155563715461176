import React, { useState } from "react";
import { Form, Card, Spin, Space, Row, Col, Input } from "antd";
import { optionSelectLocation, useOptionSelect } from "../../constants/OptionSelect";
import { toUndefined, sumColumnWidth, subStringDate } from "../../functions/UseFunction";
import { useAlert } from "../modal/CustomAlert";
import { configForm } from "../../constants/ConstantComponent";
import { useRenderFormItem } from "../RenderComponents";
import CustomTableAtnd from "../tables/CustomTableAtnd";
import { RenderMainModal } from "../modal/CustomModalAntd";
import { useText } from "../typography/Typography";
import { useNotification } from "../notification/NotificationWithIcon";
import { POST, POST_SELECT_DATA_REPORT_RETURN_EMPLOYEE, POST_SELECT_DATA_REPORT_RETURN_EMPLOYEE_DETAIL } from "../../../service";
import { useButton } from "../buttons/CustomButtonAntd";
import { ComponentReportReturn } from "../../constants/Reports/Components/ComponentsReportReturn";
import { ColumnReportReturn, ColumnReportReturnDetail } from "../../constants/Reports/Columns";
import { useExportExcel } from "../../services/ExportExcel";
import { Header_ReportReturn } from "../../constants/HeaderExcel";

const FormReportReturn = () => {
    const [form] = Form.useForm();
    const [formModal] = Form.useForm();
    // [START] States
    const [ChangeValue, setChangeValue] = useState({});
    const [dataTable, setDataTable] = useState([]);
    const [dataTableModal, setDataTableModal] = useState([]);
    const [modalshow, setModalshow] = useState(false);
    const [loadingScreen, setLoadingScreen] = useState(false);
    const [loadingModal, setLoadingModal] = useState(false);

    // [END] States
    let optionSearch = {
        company: useOptionSelect("company"),
        location: optionSelectLocation,
        personalId: [],
    };

    // [START] Handle Form Antd
    const onFinish = (values) => {
        if (!ChangeValue) return null;
        const { company, location, personalId } = values
        const objectBody = {
            company: company || "",
            location: location || "",
            personalId: personalId || "",
        }
        selectDataReportReturnEmployee(objectBody)
    };

    const onReset = () => {
        form.resetFields();
        formModal.resetFields();
        setDataTable([]);
        setDataTableModal([]);
        setChangeValue({});
    };

    const selectDataReportReturnEmployee = async (objectValue) => {
        try {
            const { company, location, personalId } = objectValue
            let objectBody = {
                company: company || "",
                location: location || "",
                personalId: personalId || "",
            };
            setLoadingScreen(true);
            setDataTable([]);
            const response = await POST(POST_SELECT_DATA_REPORT_RETURN_EMPLOYEE, objectBody);
            const { result } = response;
            let mapResult = [];
            if (result.length !== 0) {
                mapResult = result.map((row, index) => ({
                    key: index,
                    ...row,
                    startDateJob: subStringDate(row.startDateJob),
                }));
            } else {
                useNotification({ type: "info", title: "ไม่พบข้อมูล" });
            }
            setDataTable(mapResult);
        } catch (err) {
            useAlert({ type: "error", title: "[CATCH] เกิดข้อผิดพลาด", content: err.message })
            console.log("Error Search Data Report Return Employee: ", err);
        } finally {
            setLoadingScreen(false);
        }
    };

    const selectDataReportReturnEmployeeDetail = async (_personalId) => {
        try {
            formModal.resetFields();
            if (!_personalId) return null
            setModalshow(true);
            setLoadingModal(true);
            setDataTableModal([]);
            const response = await POST(POST_SELECT_DATA_REPORT_RETURN_EMPLOYEE_DETAIL, { personalId: _personalId });
            const { result } = response;
            let mapResult = [];
            if (result.length !== 0) {
                mapResult = result.map((row, index) => ({
                    key: index,
                    ...row,
                    startDateJob: subStringDate(row.startDateJob),
                    endDateJob: subStringDate(row.endDateJob),
                    probationDateEnd: subStringDate(row.probationDateEnd),
                }));
                formModal.setFieldsValue({
                    fullname: result[0].fullname,
                    nickname: result[0].nickname,
                    personalId: result[0].personalId,
                })
            } else {
                useNotification({ type: "info", title: "ไม่พบข้อมูล" });
            }
            setDataTableModal(mapResult);
        } catch (err) {
            useAlert({ type: "error", title: "[CATCH] เกิดข้อผิดพลาด", content: err.message })
            console.log("Error Search Data Report Return Employee Detail: ", err);
        } finally {
            setLoadingModal(false);
        }
    };
    // [END] Handle Form Antd

    // [START] REST API
    const hideModal = () => {
        setModalshow(false);
    };
    // [END] REST API

    // [START] Functions
    const onChangeValue = (name, value) => {
        let setUndefined = {}
        if (name === "company") {
            setUndefined = toUndefined(["location", "personalId"])
        } else if (name === "location") {
            setUndefined = toUndefined(["personalId"])
        }
        setDataTable([]);
        setDataTableModal([])
        form.setFieldsValue(setUndefined)
        setChangeValue((prev) => ({
            ...prev,
            setUndefined,
            [name]: value,
        }))
    };

    const contentModal = ({ data = data || [] }) => {
        return (
            dataTableModal.length !== 0 ? (
                <Spin tip="Loading..." spinning={loadingModal}>
                    <Form form={formModal} {...configForm}>
                        <Row gutter={[16, 16]} justify="start" align="bottom">
                            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                                <Form.Item label="ชื่อพนักงาน" name="fullname">
                                    <Input disabled={true} />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                                <Form.Item label="ชื่อเล่น" name="nickname">
                                    <Input disabled={true} />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                                <Form.Item label="เลขบัตรประชาชน" name="personalId">
                                    <Input disabled={true} />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                    <hr />
                    <CustomTableAtnd
                        HeadTable={ColumnReportReturnDetail}
                        DataTable={dataTableModal}
                        SetScroll={{ x: sumColumnWidth(ColumnReportReturnDetail) || 2000, y: 500 }}
                    />
                </Spin>
            ) : (
                useText({ type: "danger", text: "* ไม่พบข้อมูลการโอนย้าย" })
            )
        );
    };
    // [END] Functions
    return (
        <Spin tip="กำลังโหลดข้อมูล ..." spinning={loadingScreen}>
            {RenderMainModal({
                show: modalshow,
                onCancel: hideModal,
                txtTitle: "รายละเอียด: ประวัติการกลับเข้ามาทำงานใหม่",
                ModalContent: contentModal({}),
            })}
            <Form form={form} onFinish={onFinish} {...configForm}>
                <Card
                    title="ค้นหาพนักงานกลับมาทำงานใหม่"
                    style={{ marginBottom: 30 }}
                    extra={
                        <Space>
                            {useButton({ call: "search" })}
                            {useButton({
                                call: "normal",
                                fn: onReset,
                                moreProps: { label: "ล้างข้อมูล" },
                            })}
                        </Space>
                    }
                >
                    {useRenderFormItem({
                        constantComponent: ComponentReportReturn({
                            options: optionSearch,
                            handleChange: onChangeValue,
                        }),
                    })}
                </Card>
                {dataTable.length !== 0 && (
                    <Card
                        title="รายงานประวัติการกลับเข้ามาทำงานใหม่"
                        style={{ marginBottom: 30 }}
                        extra={
                            useExportExcel({
                                header: Header_ReportReturn,
                                dataset: dataTable,
                                filename: "รายงานประวัติการกลับเข้ามาทำงานใหม่",
                            })
                        }
                    >
                        <CustomTableAtnd
                            HeadTable={ColumnReportReturn({ fnViewData: selectDataReportReturnEmployeeDetail })}
                            DataTable={dataTable}
                            moreProps={{
                                scroll: {
                                    x: sumColumnWidth(ColumnReportReturn({})) || 2050,
                                    y: 500,
                                },
                            }}
                        />
                    </Card>
                )}
            </Form>
        </Spin>
    );
};

export default FormReportReturn;
