import { FIELD_STEP, MESSAGE_ERROR } from ".."
import { KEY_TYPE } from "../../../constants/enums/enumCenter"

/* -------------------------------------------------------------------------- */
/*                                 LABEL_PAGES                                */
/* -------------------------------------------------------------------------- */

const LABEL_PAGES = {
    //!เปลี่ยนชื่อ Label ที่นี่
    NAME_EMPLOYER: 'ชื่อที่ทำงาน',
    TEL_EMPLOYER: 'เบอร์โทรศัพท์',
    LAST_POSITION: 'ตำแหน่งงานล่าสุด',
    ADDRESS_EMPLOYER: 'ที่อยู่ที่ทำงาน',
    START_DATE: 'วันที่เริ่มต้น',
    END_DATE: 'วันที่สิ้นสุด',
    BOSS_NAME: 'ชื่อหัวหน้างาน',
    BOSS_POSITION: 'ตำแหน่งหัวหน้างาน',
    REASON_LEAVE: 'สาเหตุที่ลาออก',
    RESPONSIBILITY: 'หน้าที่ความรับผิดชอบ',
}

/* -------------------------------------------------------------------------- */
/*                                FIELD_PAGES                                */
/* -------------------------------------------------------------------------- */

const FIELD_PAGES = {
    //!เปลี่ยนชื่อ Field ที่นี่
    NAME_EMPLOYER: 'nameEmployer',
    TEL_EMPLOYER: 'telEmployer',
    LAST_POSITION: 'lastPosition',
    ADDRESS_EMPLOYER: 'addressEmployer',
    START_DATE: 'startWorkEmployer',
    END_DATE: 'endWorkEmployer',
    BOSS_NAME: 'bossNameEmployer',
    BOSS_POSITION: 'bossPosition',
    REASON_LEAVE: 'reasonLeave',
    RESPONSIBILITY: 'responsibility',
}



/* -------------------------------------------------------------------------- */
/*                                INITIAL_PAGES                                */
/* -------------------------------------------------------------------------- */

const INITIAL_PAGES = {
    nameEmployer: '',
    telEmployer: '',
    lastPosition: '',
    addressEmployer: '',
    startWorkEmployer: '',
    endWorkEmployer: '',
    bossNameEmployer: '',
    bossPosition: '',
    reasonForLeave: '',
    responsibility: ''
}

export const FIELD_EXPERIENCES = FIELD_PAGES
export const INITIAL_EXPERIENCES = INITIAL_PAGES

/* -------------------------------------------------------------------------- */
/*                                CONTENT_PAGES                               */
/* -------------------------------------------------------------------------- */

export const CONTENT_EXPERIENCES = (props) => {
    const { disabledAll } = props;
    /**
    * TODO: สามารถแบ่งตาม  location ได้ โดยใช้ switch case
    */
    return [
        [{
            span: 24,
            inputprops: {
                type: KEY_TYPE.CARD_FORM,
                name: FIELD_STEP.COLLAPSE_S21,//experiences
                removelenght: 0,
                header: (idx) => `สถานประกอบการที่ ${idx + 1}`,
                initialValues: { ...INITIAL_PAGES },
                disabled: disabledAll,
                content: (field) => [
                    /* -------------------------------------------------------------------------- */

                    {
                        span: 6,
                        itemprops: {
                            name: [field.name, FIELD_PAGES.NAME_EMPLOYER],
                            label: LABEL_PAGES.NAME_EMPLOYER,//ชื่อที่ทำงาน
                            fieldKey: [field.fieldKey, FIELD_PAGES.NAME_EMPLOYER],
                            rules: [{ required: false, message: MESSAGE_ERROR.INPUT }]
                        },
                        inputprops: {
                            type: KEY_TYPE.INPUT,
                            disabled: disabledAll,
                            placeholder: ''
                        }
                    }, {
                        span: 3,
                        itemprops: {
                            name: [field.name, FIELD_PAGES.START_DATE],
                            label: LABEL_PAGES.START_DATE, //วันที่เริ่มต้น
                            fieldKey: [field.fieldKey, FIELD_PAGES.START_DATE],
                            rules: [{ required: false, message: MESSAGE_ERROR.DATE_PICKER }]
                        },
                        inputprops: {
                            type: KEY_TYPE.DATE_PICKER,
                            disabled: disabledAll,
                            placeholder: ''
                        }
                    }, {
                        span: 3,
                        itemprops: {
                            name: [field.name, FIELD_PAGES.END_DATE],
                            label: LABEL_PAGES.END_DATE, //วันที่สิ้นสุด
                            fieldKey: [field.fieldKey, FIELD_PAGES.END_DATE],
                            rules: [{ required: false, message: MESSAGE_ERROR.DATE_PICKER }]
                        },
                        inputprops: {
                            type: KEY_TYPE.DATE_PICKER,
                            disabled: disabledAll,
                            placeholder: ''
                        }
                    }, {
                        span: 6,
                        itemprops: {
                            name: [field.name, FIELD_PAGES.TEL_EMPLOYER],
                            label: LABEL_PAGES.TEL_EMPLOYER,//เบอร์โทรศัพท์
                            fieldKey: [field.fieldKey, FIELD_PAGES.TEL_EMPLOYER],
                            rules: [{ required: false, message: MESSAGE_ERROR.TEL }]
                        },
                        inputprops: {
                            type: KEY_TYPE.TEL,
                            length: 10,
                            disabled: disabledAll,
                            placeholder: ''
                        }
                    }, {
                        span: 6,
                        itemprops: {
                            name: [field.name, FIELD_PAGES.LAST_POSITION],
                            label: LABEL_PAGES.LAST_POSITION,//ตำแหน่งงานล่าสุด
                            fieldKey: [field.fieldKey, FIELD_PAGES.LAST_POSITION],
                            rules: [{ required: false, message: MESSAGE_ERROR.INPUT }]
                        },
                        inputprops: {
                            type: KEY_TYPE.INPUT,
                            disabled: disabledAll,
                            placeholder: ''
                        }
                    },
                    /* -------------------------------------------------------------------------- */
                    {
                        span: 12,
                        itemprops: {
                            name: [field.name, FIELD_PAGES.ADDRESS_EMPLOYER],
                            label: LABEL_PAGES.ADDRESS_EMPLOYER,//ที่อยู่ที่ทำงาน
                            fieldKey: [field.fieldKey, FIELD_PAGES.ADDRESS_EMPLOYER],
                            rules: [{ required: false, message: MESSAGE_ERROR.INPUT }]
                        },
                        inputprops: {
                            type: KEY_TYPE.INPUT,
                            disabled: disabledAll,
                            placeholder: ''
                        }
                    },

                    {
                        span: 6,
                        itemprops: {
                            name: [field.name, FIELD_PAGES.BOSS_NAME],
                            label: LABEL_PAGES.BOSS_NAME, //ชื่อหัวหน้างาน
                            fieldKey: [field.fieldKey, FIELD_PAGES.BOSS_NAME],
                            rules: [{ required: false, message: MESSAGE_ERROR.INPUT }]
                        },
                        inputprops: {
                            type: KEY_TYPE.INPUT,
                            disabled: disabledAll,
                            placeholder: ''
                        }
                    }, {
                        span: 6,
                        itemprops: {
                            name: [field.name, FIELD_PAGES.BOSS_POSITION],
                            label: LABEL_PAGES.BOSS_POSITION, //ตำแหน่งหัวหน้างาน
                            fieldKey: [field.fieldKey, FIELD_PAGES.BOSS_POSITION],
                            rules: [{ required: false, message: MESSAGE_ERROR.INPUT }]
                        },
                        inputprops: {
                            type: KEY_TYPE.INPUT,
                            disabled: disabledAll,
                            placeholder: ''
                        }
                    },
                    /* -------------------------------------------------------------------------- */

                    {
                        span: 12,
                        itemprops: {
                            name: [field.name, FIELD_PAGES.REASON_LEAVE],
                            label: LABEL_PAGES.REASON_LEAVE, //สาเหตุที่ลาออก
                            fieldKey: [field.fieldKey, FIELD_PAGES.REASON_LEAVE],
                            rules: [{ required: false, message: MESSAGE_ERROR.INPUT_AREA }]
                        },
                        inputprops: {
                            type: KEY_TYPE.INPUT_AREA,
                            disabled: disabledAll,
                            placeholder: ''
                        }
                    }, {
                        span: 12,
                        itemprops: {
                            name: [field.name, FIELD_PAGES.RESPONSIBILITY],
                            label: LABEL_PAGES.RESPONSIBILITY, //หน้าที่ความรับผิดชอบ
                            fieldKey: [field.fieldKey, FIELD_PAGES.RESPONSIBILITY],
                            rules: [{ required: false, message: MESSAGE_ERROR.INPUT_AREA }]
                        },
                        inputprops: {
                            type: KEY_TYPE.INPUT_AREA,
                            disabled: disabledAll,
                            placeholder: ''
                        }
                    }]
            }
        }]
    ]
}