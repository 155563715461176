import React from 'react'

import { Table } from 'antd';
import { CSVLink, CSVDownload } from "react-csv";

import { ButtonIcon } from '../buttons';
import { MdCloudDownload, MdFileDownload } from 'react-icons/md';


const TableTheme = ({ columns, dataSource, headers, filename, ...rest }) => {
    return (
        <div style={{ textAlign: 'end' }}>
            <CSVLink
                headers={headers}
                data={dataSource||[]}
                filename={filename}
                className="btn"
                target="_blank"
            >
                <ButtonIcon label={"Export"} icon={<MdFileDownload />} />
            </CSVLink>
            <div style={{ marginTop: 10 }}>
                <Table
                    bordered
                    columns={columns}
                    dataSource={dataSource||[]}  {...rest}
                    wrapperClasses="table-responsive"
                />
            </div>
        </div>
    )
}

export default TableTheme