import React from "react";
import { memo } from "react";
import { useController } from "react-hook-form";
import { compareRender } from "../../../../constants/functions/form";
import { Select as SelectAntd } from "antd";
import { TextSmall } from "../../text";
//import { useMemo } from 'preact/hooks';
import { Required, RowComponent } from "../../../../styles/global-style";
const { Fragment } = React;
const { Option } = SelectAntd;

const filterOptionAll = (input, option) => {
  const arrInput = input.split(" ");
  for (const element of arrInput) {
    const isFound =
      option.children.toLowerCase().indexOf(element.toLowerCase()) >= 0 ||
      element == "";
    if (!isFound) return false;
  }
  return true;
};

export const Dropdown = ({ control, item, handleChange }) => {
  const {
    ref,
    rules,
    name,
    defaultValue,
    placeholder,
    label,
    mode,
    disabled,
    properties,
    filterOption,
    itemHandleChange,
  } = item;
  const { options, valueKey, labelKey } = properties;
  const { field, fieldState } = useController({
    control,
    name,
    rules,
    defaultValue,
  });
  const { error } = fieldState;
  const { value } = field;

  const opt = options.map((e) => {
    const val = valueKey ? e[valueKey || "value"] : e.value;
    const lab = labelKey ? e[labelKey || "label"] : e.label;
    return (
      <Option value={val} key={val}>
        {lab}
      </Option>
    );
  });

  return (
    <Fragment>
      <RowComponent>
        {label && <TextSmall text={label} />}
        {rules && rules.required && <Required>*</Required>}
      </RowComponent>
      <SelectAntd
        showSearch
        allowClear={true}
        showArrow
        id={ref}
        name={ref}
        value={value || []}
        placeholder={placeholder}
        optionFilterProp="children"
        onChange={(e) =>
          itemHandleChange ? itemHandleChange(e, item) : handleChange(e, item)
        }
        onClear={() => handleChange({}, item)}
        style={{ width: "100%" }}
        mode={mode || undefined}
        disabled={disabled}
        filterOption={
          filterOption
            ? (input, option) => filterOption(input, option)
            : (input, option) => filterOptionAll(input, option)
        }
      >
        {opt}
      </SelectAntd>
      {error && <TextSmall text={error.message} color="red" />}
    </Fragment>
  );
};

export const MemoizedDropdown = memo(
  Dropdown,
  (prev, next) =>
    compareRender(prev, next) &&
    JSON.parse(JSON.stringify(prev.item.properties.options)) ===
      JSON.parse(JSON.stringify(next.item.properties.options)) &&
    compareRender(prev, next, "item.disabled")
);
