import React, { useState } from "react";
import { Form, Card, Row, Col, Spin } from "antd";
import { SelectAntd } from "../select/SelectAntd";
import { OJT, USE_CONNECT_MODE_AUTO } from "../../services/ConnectAPI";
import { useButton } from "../buttons/CustomButtonAntd";
import { useFixedQueryString } from "../../functions/UseMakeQueryString";
import { GET_UserLogin, toUndefined } from "../../functions/UseFunction";
import { useOptionSelect } from "../../constants/OptionSelect";
import { useAlert } from "../modal/CustomAlert";
import { useConfirm } from "../modal/CustomConfirm";

export const FormAddOJTCourse = () => {
  const [form] = Form.useForm();
  const { userlogin } = GET_UserLogin();

  const [changeSearchValue, setChangeSearchValue] = useState({});

  const [loadingScreen, setLoadingScreen] = useState(false);

  const queryPosition = useFixedQueryString(changeSearchValue, ["companyCode"]);

  let optionCompany = useOptionSelect("company");
  let optionTrainings = useOptionSelect("training");
  let optionPosition = useOptionSelect("position", queryPosition);

  // [START] Handle Form Antd
  const onFinish = (values) => {
    const { positionCode, trainingNos } = values;
    const objectValue = {
      positionCode,
      trainingNos,
      active: false,
      userlogin,
    };
    useConfirm({
      FnOK: () => POST_INSERT_Course(objectValue),
    });
  };
  const onReset = () => {
    form.resetFields();
  };
  // [END] Handle Form Antd

  const POST_INSERT_Course = async (objectValue) => {
    try {
      setLoadingScreen(true);
      const url = `${USE_CONNECT_MODE_AUTO}${OJT.POST_INSERT_Course}`;
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(objectValue),
      });
      const data = await response.json();
      if (response.ok) {
        setLoadingScreen(true);
        useAlert({
          type: "success",
          title: "เพิ่มข้อมูลสำเร็จ",
          Fn: () => window.location.reload(),
        });
      } else {
        useAlert({
          type: "error",
          title: "เกิดข้อผิดพลาดจากการเพิ่มข้อมูล",
          content: `${data.message}`,
          Fn: () => setLoadingScreen(false),
        });
      }
    } catch (err) {
      console.log("Error Insert Course: ", err);
    }
  };

  // [START] Functions
  const onChangeValue = (val, name) => {
    let SET_undifined = {};
    let ArrayInput = Object.keys(form.getFieldsValue());
    switch (name) {
      case "companyCode":
        SET_undifined = toUndefined(ArrayInput.slice(1));
        break;
      case "positionCode":
        SET_undifined = toUndefined(ArrayInput.slice(2));
        break;
      default:
        SET_undifined = {};
        break;
    }
    form.setFieldsValue(SET_undifined);
    setChangeSearchValue((prev) => ({
      ...prev,
      ...SET_undifined,
      [name]: val,
    }));
  };
  // [END] Functions

  return (
    <>
      <Spin tip="Loading..." spinning={loadingScreen}>
        <Card title="เพิ่มหลักสูตรผูกตำแหน่ง" style={{ marginBottom: 30 }}>
          <Form
            form={form}
            onFinish={onFinish}
            labelCol={{
              span: 24,
            }}
            wrapperCol={{
              span: 24,
            }}
          >
            <Row gutter={[16, 16]} justify="start" align="top">
              <Col xs={24} sm={24} md={24} lg={6} xl={4}>
                <SelectAntd
                  name={`companyCode`}
                  label={`บริษัท`}
                  smgReq={`กรุณาเลือก บริษัท`}
                  placeholder={`เลือก บริษัท`}
                  dropdown={optionCompany}
                  FnOnChange={onChangeValue}
                  required={true}
                />
              </Col>
              <Col xs={24} sm={24} md={24} lg={6} xl={9}>
                <SelectAntd
                  name={`positionCode`}
                  label={`ตำแหน่งงาน`}
                  smgReq={`กรุณาเลือก ตำแหน่งงาน`}
                  placeholder={`เลือก ตำแหน่งงาน`}
                  dropdown={optionPosition}
                  FnOnChange={onChangeValue}
                  required={true}
                />
              </Col>
              <Col xs={24} sm={24} md={24} lg={6} xl={11}>
                <SelectAntd
                  name={`trainingNos`}
                  label={`หลักสูตรหลัก`}
                  smgReq={`กรุณาเลือก หลักสูตรหลัก`}
                  placeholder={`เลือก หลักสูตรหลัก`}
                  dropdown={optionTrainings}
                  FnOnChange={onChangeValue}
                  required={true}
                  multipleMode={true}
                />
              </Col>
            </Row>
            <Row
              gutter={[16, 16]}
              style={{ marginTop: 20 }}
              justify="center"
              align="bottom"
            >
              <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                <Form.Item>{useButton({ call: "save" })}</Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                <Form.Item>
                  {useButton({
                    call: "normal",
                    fn: onReset,
                    moreProps: { label: "ล้างข้อมูล" },
                  })}
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Card>
      </Spin>
    </>
  );
};

export default FormAddOJTCourse;
