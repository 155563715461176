import { FIELD_STEP, MESSAGE_ERROR } from "../..";
import {
  KEY_LOCATION,
  KEY_OPTION,
  KEY_TYPE,
} from "../../../../constants/enums/enumCenter";
import { FIELD_PERSONAL, LABEL_PERSONAL } from "../personalInfo";
import moment from "moment";

/* -------------------------------------------------------------------------- */
/*                                 LABEL_PAGES                                */
/* -------------------------------------------------------------------------- */

const LABEL_PAGES = {
  //!เปลี่ยนชื่อ Label ที่นี่
  COMPANY: "บริษัท (Company)",
  LOCATION: "สถานที่ (Location)",
  EMPLOYEE_ID: "รหัสพนักงาน (Employee No)",
  STATUS_WORKERS: "สถานะพนักงาน (Status Worker)",
  START_DATE_JOB: "วันที่เริ่มทำงาน (Start Date)",
  END_DATE_JOB: "วันที่สิ้นสุดการทำงาน",
  END_CONTRACT_DATE_JOB: "วันที่สิ้นสุดสัญญา",
  WORK_EXPERIENCE: "อายุงาน (Work Experience)",
  TITLE_TH: "คำนำหน้า  (ภาษาไทย)",
  FIRSTNAME_TH: "ชื่อ (ภาษาไทย)",
  LASTNAME_TH: "นามสกุล (ภาษาไทย)",
  NICKNAME_TH: "ชื่อเล่น  (ภาษาไทย)",
  TITLE_EN: "คำนำหน้า (ภาษาอังกฤษ)",
  FIRSTNAME_EN: "ชื่อ (ภาษาอังกฤษ)",
  LASTNAME_EN: "นามสกุล (ภาษาอังกฤษ)",
  NICKNAME_EN: "ชื่อเล่น (ภาษาอังกฤษ)",
  USERNAME: "Username",
  EMAIL: "อีเมลพนักงาน (Email Employee)",
  TEL: "เบอร์ติดต่อ",
  PERSONAL_ID: "บัตรประชาชนเลขที่ (ID Card No.)",
  PERSONAL_ID_EXP: "หมดอายุ (Date of Expiry)",
  PASSPORT_ID: "หนังสือเดินทาง (Passport No.)",
  PASSPORT_ID_EXP: "หมดอายุ (Date of Expiry)",
  WORKPERMIT_ID: "ใบอนุญาติทำงาน (Work Permit)",
  WORKPERMIT_ID_EXP: "หมดอายุ (Date of Expiry)",
  MAN_TYPE: "ประเภทพนักงาน",
  DEP_NO: "แผนก",
  SUB_DEP_NO: "แผนกย่อย",
  POSITION_NO: "ตำแหน่งงาน",
  FLOW_HRM: "สายอนุมัติ",
  SHIFT_CODE: "กะทำงานตั้งต้น",
  CODE_MAP_SCAN: "สแกนลายนิ้วมือ CodeMap",
  TIME_SCAN: "รูปแบบการสแกน",
  ONETIME_SCAN: "สแกนเข้างานรอบเดียว",
  SHIRT_SIZE: "ไซต์เสื้อ (Shirt Size)",
  SCORE_JG: "Job Grade (JG)",
  STATUS_EMPLOYEE: "สถานภาพพนักงาน",
  REASON_OUT: "เหตุผลการลาออก",
  REASON_OUT_TEXT: "เหตุผลเพิ่มเติม",
  PAYROLL_GROUP: "กลุ่มเงินเดือน (Payroll Group)",
  SEND_CRIME: "ส่งข้อมูลอาชญากรรม",
  SEND_GUARANTEE: "ส่งเอกสารค้ำประกัน",
  CRIME: "ข้อมูลอาชญากรรม",
  GUARANTEE: "ข้อมูลเอกสารค้ำประกัน",
  ACCIDENT_PLAN: "แผนประกันอุบัติเหตุ",
  HEALTH_PLAN: "แผนประกันสุขภาพ",
  SALE_CODE: "รหัส Sale/สาขา",

  /* ------------------------------- OTHERS FIELD ------------------------------- */

  FULLNAME_TH: "ชื่อ - นามสกุล (ภาษาไทย) : ",
  FULLNAME_EN: "ชื่อ - นามสกุล (ภาษาอังกฤษ) : ",
  CONTRACT_PERIOD: "ระยะเวลา",
  RECRUITER: "ผู้สรรหา",
};

/* -------------------------------------------------------------------------- */
/*                                FIELD_PAGES                                */
/* -------------------------------------------------------------------------- */

const FIELD_PAGES = {
  //!เปลี่ยนชื่อ Field ที่นี่
  COMPANY: "company",
  LOCATION: "location",
  EMPLOYEE_ID: "userId",
  STATUS_WORKERS: "statusWorker",
  START_DATE_JOB: "startDateJob",
  END_DATE_JOB: "endDateJob",
  END_CONTRACT_DATE_JOB: "endContractDate",
  WORK_EXPERIENCE: "workExperience",
  TITLE_TH: "titleTH",
  FIRSTNAME_TH: "firstNameTH",
  LASTNAME_TH: "lastNameTH",
  NICKNAME_TH: "nickNameTH",
  TITLE_EN: "titleEN",
  FIRSTNAME_EN: "firstNameEN",
  LASTNAME_EN: "lastNameEN",
  NICKNAME_EN: "nickNameEN",
  USERNAME: "username",
  EMAIL: "emailEmp",
  TEL: "telNo",
  PERSONAL_ID: "personalId",
  PERSONAL_ID_EXP: "personalExpiry",
  PASSPORT_ID: "passportId",
  PASSPORT_ID_EXP: "passportExpiry",
  WORKPERMIT_ID: "workPermitId",
  WORKPERMIT_ID_EXP: "workPermitExpiry",
  MAN_TYPE: "manType",
  DEP_NO: "depNo",
  SUB_DEP_NO: "subDepNo",
  POSITION_NO: "positionNo",
  FLOW_HRM: "refFlowHRM",
  SHIFT_CODE: "shiftCode",
  CODE_MAP_SCAN: "codeMapFingerScan",
  TIME_SCAN: "timeScan",
  ONETIME_SCAN: "oneTimeScan",
  SHIRT_SIZE: "shirtSize",
  SCORE_JG: "JG",
  STATUS_EMPLOYEE: "currentStatus",
  REASON_OUT: "reasonLeaveCode",
  REASON_OUT_TEXT: "reasonLeaveDes",
  PAYROLL_GROUP: "payrollGroup",
  SEND_CRIME: "statusCheckCrime",
  ACCIDENT_PLAN: "planAcd",
  HEALTH_PLAN: "planHea",
  LINEINS_PLAN: "linenumPlanIns",
  SALE_CODE: "Salecode",
  PRESENT_PROVINCE: "presentProvince",
  PRESENT_DISTRICT: "presentDistrict",
  PRESENT_SUB_DISTRICT: "presentSubDistrict",
  FORMER_PROVINCE: "formerProvince",
  FORMER_DISTRICT: "formerDistrict",
  FORMER_SUB_DISTRICT: "formerSubDistrict",

  /* ------------------------------- OTHERS FIELD ------------------------------- */
  CANDIDATE_NO: "candidateNo",
  REMARK: "remark",
  FULLNAME_TH: "fullNameTH",
  FULLNAME_EN: "fullNameEN",
  JOB_POSITION: "jobPosition",
  CONTRACT_PERIOD: "contractPeriod",
  CONTRACT_PERIOD_VALUE: "contractPeriodValue",
  CONFIRM_FIRST_DATA: "cfDataFirst",
  RECRUITER: "recruiterId",
};

export const FIELD_EMP = FIELD_PAGES;
export const LABEL_EMP = LABEL_PAGES;

/* -------------------------------------------------------------------------- */
/*                                CONTENT_PAGES                               */
/* -------------------------------------------------------------------------- */

export const CONTENT_EMPLOYEE_VIEW = (props, hooks = {}) => {
  const { location, required, options, disabled, disabledAll } = props;
  const { optionWorkShift, onChangeParamForWorkShift } = hooks;

  /**
   * TODO: สามารถแบ่งตาม  location ได้ โดยใช้ switch case
   */
  return [
    [
      {
        span: 6,
        itemprops: {
          name: FIELD_PAGES.EMPLOYEE_ID,
          label: LABEL_PAGES.EMPLOYEE_ID, //รหัสพนักงาน
          noStyle: true,
          rules: [{ required: true, message: MESSAGE_ERROR.INPUT }],
        },
        inputprops: {
          size: "small",
          layout: "vertical",
          label: LABEL_PAGES.EMPLOYEE_ID, //รหัสพนักงาน
          typeText: "strong",
          type: KEY_TYPE.CONTENT,
          styleText: { fontSize: 16, fontWeight: "bold" },
          disabled: true,
          placeholder: "",
        },
      },
      {
        span: 6,
        itemprops: {
          name: FIELD_PAGES.RECRUITER,
          label: LABEL_PAGES.RECRUITER, // ผู้สรรหา
          rules: [{ required: false, message: MESSAGE_ERROR.SELECT }],
        },
        inputprops: {
          type: KEY_TYPE.CONTENT,
          disabled: disabled[FIELD_PAGES.RECRUITER] || disabledAll,
          placeholder: "",
          options: options[KEY_OPTION.CENTER.OPTION_RECRUITER],
        },
      },
      {
        span: 6,
        itemprops: {
          name: FIELD_PAGES.COMPANY,
          label: LABEL_PAGES.COMPANY, //บริษัท
          rules: [{ required: true, message: MESSAGE_ERROR.SELECT }],
        },
        inputprops: {
          type: KEY_TYPE.CONTENT,
          disabled: disabled[FIELD_PAGES.COMPANY] || disabledAll,
          placeholder: "",
          options: options[KEY_OPTION.CENTER.OPTION_COMPANY],
          onChangeCallBack: (props) => {
            onChangeParamForWorkShift(props);
          }, //TODO
        },
      },
      {
        span: 6,
        itemprops: {
          name: FIELD_PAGES.LOCATION,
          label: LABEL_PAGES.LOCATION, //สถานที่
          rules: [{ required: true, message: MESSAGE_ERROR.SELECT }],
        },
        inputprops: {
          type: KEY_TYPE.CONTENT,
          disabled: disabled[FIELD_PAGES.LOCATION] || disabledAll,
          placeholder: "",
          options: options[KEY_OPTION.CENTER.OPTION_LOCATION],
          onChangeCallBack: (props) => {
            onChangeParamForWorkShift(props);
          }, //TODO
        },
      },
      {
        span: 6,
        itemprops: {
          name: FIELD_PAGES.DEP_NO,
          label: LABEL_PAGES.DEP_NO, //แผนก
          rules: [{ required: true, message: MESSAGE_ERROR.SELECT }],
        },
        inputprops: {
          type: KEY_TYPE.CONTENT,
          disabled: disabled[FIELD_PAGES.DEP_NO] || disabledAll,
          placeholder: "",
          options: options[KEY_OPTION.CENTER.OPTION_DEPARTMENT_ALL],
        },
      },
      {
        span: 6,
        itemprops: {
          name: FIELD_PAGES.SUB_DEP_NO,
          label: LABEL_PAGES.SUB_DEP_NO, //แผนกย่อย
          rules: [
            {
              required: location === KEY_LOCATION.FACTORY,
              message: MESSAGE_ERROR.SELECT,
            },
          ],
        },
        inputprops: {
          type: KEY_TYPE.CONTENT,
          disabled: !disabled[FIELD_PAGES.SUB_DEP_NO] || disabledAll,
          placeholder: "",
          options: options[KEY_OPTION.CENTER.OPTION_SUB_DEPARTMENT],
        },
      },
      {
        span: 6,
        itemprops: {
          name: FIELD_PAGES.POSITION_NO,
          label: LABEL_PAGES.POSITION_NO, //ตำแหน่งงาน
          rules: [{ required: true, message: MESSAGE_ERROR.SELECT }],
        },
        inputprops: {
          type: KEY_TYPE.CONTENT,
          disabled: !disabled[FIELD_PAGES.POSITION_NO] || disabledAll,
          placeholder: "",
          options: options[KEY_OPTION.CENTER.OPTION_POSITION],
        },
      },
      {
        span: 6,
        itemprops: {
          name: FIELD_PAGES.FLOW_HRM,
          label: LABEL_PAGES.FLOW_HRM, //สายอนุมัติ
          rules: [{ required: true, message: MESSAGE_ERROR.SELECT }],
        },
        inputprops: {
          type: KEY_TYPE.CONTENT,
          disabled: !disabled[FIELD_PAGES.FLOW_HRM] || disabledAll,
          placeholder: "",
          options: options[KEY_OPTION.CENTER.OPTION_FLOWHRM],
        },
      },
      {
        span: 6,
        itemprops: {
          name: FIELD_PAGES.SALE_CODE,
          label: LABEL_PAGES.SALE_CODE, // รหัส Sale
          rules: [{ required: false, message: MESSAGE_ERROR.SELECT }],
        },
        inputprops: {
          type: KEY_TYPE.CONTENT,
          disabled: disabled[FIELD_PAGES.SALE_CODE] || disabledAll,
          placeholder: "",
          options: options[KEY_OPTION.CENTER.OPTION_SELECT_SALE_CODE],
          //options: options[KEY_OPTION.CENTER.OPTION_FLOWHRM]
        },
      },
      {
        span: 6,
        itemprops: {
          name: FIELD_PAGES.MAN_TYPE,
          label: LABEL_PAGES.MAN_TYPE, //ประเภทพนักงาน
          rules: [{ required: true, message: MESSAGE_ERROR.SELECT }],
        },
        inputprops: {
          type: KEY_TYPE.CONTENT,
          disabled: disabledAll,
          placeholder: "",
          options: options[KEY_OPTION.CENTER.OPTION_MAN_TYPE],
        },
      },
      {
        span: 6,
        itemprops: {
          name: FIELD_PAGES.STATUS_WORKERS,
          label: LABEL_PAGES.STATUS_WORKERS, //ประเภท พนง
          rules: [{ required: true, message: MESSAGE_ERROR.SELECT }],
        },
        inputprops: {
          type: KEY_TYPE.CONTENT,
          disabled: disabled[FIELD_PAGES.STATUS_WORKERS] || disabledAll,
          placeholder: "",
          options: options[KEY_OPTION.CENTER.OPTION_STATUS_WORKERS].filter(
            (e) => e.optionId !== "Block" && e.optionId !== "notCome"
          ),
        },
      },
      {
        span: 6,
        itemprops: {
          name: FIELD_PAGES.CONTRACT_PERIOD,
          label: LABEL_PAGES.CONTRACT_PERIOD, // ระยะเวลา
          rules: [
            {
              required: required[FIELD_PAGES.CONTRACT_PERIOD],
              message: MESSAGE_ERROR.SELECT,
            },
          ],
        },
        inputprops: {
          type: KEY_TYPE.CONTENT,
          disabled: disabled[FIELD_PAGES.CONTRACT_PERIOD] || disabledAll,
          placeholder: "",
          options: options[KEY_OPTION.CENTER.OPTION_CONTACT_PERIOD].filter(
            (e) => e.optionId !== "fulltime" && e.optionId !== "uncontract"
          ),
        },
      },
      {
        span: 6,
        itemprops: {
          name: FIELD_PAGES.STATUS_EMPLOYEE,
          label: LABEL_PAGES.STATUS_EMPLOYEE, //สถานะพนักงาน
          rules: [{ required: true, message: MESSAGE_ERROR.SELECT }],
        },
        inputprops: {
          type: KEY_TYPE.CONTENT,
          disabled: disabled[FIELD_PAGES.STATUS_EMPLOYEE] || disabledAll,
          placeholder: "",
          options: options[KEY_OPTION.CENTER.OPTION_STATUS_EMPLOYEE], //.filter((e) => e.optionId !== 'Block' && e.optionId !== 'notCome')
        },
      },
      {
        span: 6,
        itemprops: {
          name: FIELD_PAGES.WORK_EXPERIENCE,
          label: LABEL_PAGES.WORK_EXPERIENCE, //อายุงาน (Work Experience)
          rules: [{ required: false, message: MESSAGE_ERROR.NUMBER }],
        },
        inputprops: {
          type: KEY_TYPE.CONTENT,
          length: 2,
          disabled: true,
          placeholder: "",
        },
      },
      {
        span: 6,
        itemprops: {
          name: FIELD_PAGES.START_DATE_JOB,
          label: LABEL_PAGES.START_DATE_JOB, //วันที่เริ่มทำงาน
          rules: [{ required: true, message: MESSAGE_ERROR.DATE_PICKER }],
        },
        inputprops: {
          type: KEY_TYPE.CONTENT,
          placeholder: "",
          disabled: disabled[FIELD_PAGES.START_DATE_JOB] || disabledAll,
        },
      },
      {
        span: 6,
        itemprops: {
          name: FIELD_PAGES.END_CONTRACT_DATE_JOB,
          label: LABEL_PAGES.END_CONTRACT_DATE_JOB, //วันที่สิ้นสุดการทำงาน
          rules: [
            {
              required: required[FIELD_PAGES.END_CONTRACT_DATE_JOB],
              message: MESSAGE_ERROR.DATE_PICKER,
            },
          ],
        },
        inputprops: {
          //disabled: !required[FIELD_PAGES.END_CONTRACT_DATE_JOB],
          disabled: disabled[FIELD_PAGES.END_CONTRACT_DATE_JOB] || disabledAll,
          type: KEY_TYPE.CONTENT,
          placeholder: "",
        },
      },
      {
        span: 6,
        itemprops: {
          name: FIELD_PAGES.PAYROLL_GROUP,
          label: LABEL_PAGES.PAYROLL_GROUP,
          rules: [{ required: true, message: MESSAGE_ERROR.SELECT }],
        },
        inputprops: {
          type: KEY_TYPE.CONTENT,
          disabled: disabledAll,
          placeholder: "",
          options: options[KEY_OPTION.CENTER.OPTION_PAYROLL_GROUP],
        },
      },
      {
        span: 6,
        itemprops: {
          name: FIELD_PAGES.SHIFT_CODE,
          label: LABEL_PAGES.SHIFT_CODE, //กะทำงานตั้งต้น
          rules: [{ required: true, message: MESSAGE_ERROR.SELECT }],
        },
        inputprops: {
          type: KEY_TYPE.CONTENT,
          disabled: disabled[FIELD_PAGES.SHIFT_CODE] || disabledAll,
          placeholder: "",
          options:
            optionWorkShift == null
              ? options[KEY_OPTION.CENTER.OPTION_WORKSHIFT_ALL]
              : optionWorkShift, //TODO shift filter
        },
      },
      {
        span: 6,
        itemprops: {
          name: FIELD_PAGES.TIME_SCAN,
          label: LABEL_PAGES.TIME_SCAN, //รูปแบบการแสกน
          rules: [{ required: true, message: MESSAGE_ERROR.SELECT }],
        },
        inputprops: {
          type: KEY_TYPE.CONTENT,
          disabled: disabledAll,
          placeholder: "",
          options: options[KEY_OPTION.CENTER.OPTION_TIME_SCAN],
        },
      },
      {
        span: 6,
        itemprops: {
          name: FIELD_PAGES.CODE_MAP_SCAN,
          label: LABEL_PAGES.CODE_MAP_SCAN, //สแกนลายนิ้วมือ CodeMap
          rules: [
            {
              required: location === KEY_LOCATION.FACTORY,
              message: MESSAGE_ERROR.INPUT,
            },
          ],
        },
        inputprops: {
          type: KEY_TYPE.CONTENT,
          disabled: disabledAll,
          placeholder: "",
        },
      },
      {
        span: 6,
        itemprops: {
          name: FIELD_PAGES.ONETIME_SCAN,
          label: LABEL_PAGES.ONETIME_SCAN, //สแกนเข้างานรอบเดียว
          rules: [{ required: true, message: MESSAGE_ERROR.RADIO }],
        },
        inputprops: {
          type: KEY_TYPE.CONTENT,
          disabled: disabledAll,
          placeholder: "",
          options: options[KEY_OPTION.CENTER.OPTION_ONETIME_SCAN],
        },
      },
      {
        span: 6,
        itemprops: {
          name: FIELD_PAGES.SCORE_JG,
          label: LABEL_PAGES.SCORE_JG, //Job Grade
          rules: [{ required: true, message: MESSAGE_ERROR.SCORE_JG(12) }],
        },
        inputprops: {
          type: KEY_TYPE.CONTENT,
          length: 2,
          disabled: disabledAll,
          placeholder: "",
        },
      },
      {
        span: 6,
        itemprops: {
          name: FIELD_PAGES.SHIRT_SIZE,
          label: LABEL_PAGES.SHIRT_SIZE, //ไซต์เสื้อ
          rules: [{ required: true, message: MESSAGE_ERROR.SELECT }],
        },
        inputprops: {
          type: KEY_TYPE.CONTENT,
          disabled: disabledAll,
          placeholder: "",
          options: options[KEY_OPTION.CENTER.OPTION_SHIRT_SIZE],
        },
      },
      {
        span: 3,
        itemprops: {
          name: FIELD_PAGES.SEND_CRIME, // ส่งข้อมูลอาชญากรรม
          label: LABEL_PAGES.CRIME,
          rules: [{ required: false, message: MESSAGE_ERROR.CHECKBOX }],
        },
        inputprops: {
          type: KEY_TYPE.CONTENT,
          label: LABEL_PAGES.SEND_CRIME,
          disabled: disabledAll,
          placeholder: "",
        },
      },
      {
        span: 3,
        itemprops: {
          name: FIELD_PAGES.SEND_GUARANTEE, // ส่งเอกสารค้ำประกัน
          label: LABEL_PAGES.GUARANTEE,
          rules: [{ required: false, message: MESSAGE_ERROR.CHECKBOX }],
        },
        inputprops: {
          type: KEY_TYPE.CONTENT,
          label: LABEL_PAGES.SEND_GUARANTEE,
          disabled: disabledAll,
          placeholder: "",
        },
      },
      /* -------------------------------------------------------------------------- */
      {
        span: 24,
        inputprops: {
          type: KEY_TYPE.DIVIDER,
          title: "ข้อมูลแผนประกัน ",
        },
      },
      {
        span: 6,
        itemprops: {
          name: FIELD_PAGES.HEALTH_PLAN,
          label: LABEL_PAGES.HEALTH_PLAN,
          rules: [{ required: true, message: MESSAGE_ERROR.SELECT }],
        },
        inputprops: {
          type: KEY_TYPE.CONTENT,
          disabled: disabledAll,
          placeholder: "",
          options: options[KEY_OPTION.CENTER.OPTION_HEALTH_PLAN],
        },
      },
      {
        span: 6,
        itemprops: {
          name: FIELD_PAGES.ACCIDENT_PLAN,
          label: LABEL_PAGES.ACCIDENT_PLAN,
          rules: [{ required: true, message: MESSAGE_ERROR.SELECT }],
        },
        inputprops: {
          type: KEY_TYPE.CONTENT,
          disabled: disabledAll,
          placeholder: "",
          options: options[KEY_OPTION.CENTER.OPTION_ACCIDENT_PLAN],
        },
      },
      /* -------------------------------------------------------------------------- */
      {
        span: 24,
        inputprops: {
          type: KEY_TYPE.DIVIDER,
          title: "ข้อมูลส่วนตัว ",
        },
      },
      /* -------------------------------------------------------------------------- */
      {
        span: 3,
        itemprops: {
          name: FIELD_PAGES.TITLE_TH,
          label: LABEL_PAGES.TITLE_TH, //คำนำหน้า (ภาษาไทย)
          rules: [{ required: true, message: MESSAGE_ERROR.SELECT }],
        },
        inputprops: {
          type: KEY_TYPE.CONTENT,
          disabled: disabledAll,
          placeholder: "",
          options: options[KEY_OPTION.CENTER.OPTION_TITLE_TH],
        },
      },
      {
        span: 4,
        itemprops: {
          name: FIELD_PAGES.FIRSTNAME_TH,
          label: LABEL_PAGES.FIRSTNAME_TH, //ชื่อ (ภาษาไทย)
          rules: [{ required: true, message: MESSAGE_ERROR.INPUT }],
        },
        inputprops: {
          type: KEY_TYPE.CONTENT,
          disabled: disabledAll,
          placeholder: "",
        },
      },
      {
        span: 4,
        itemprops: {
          name: FIELD_PAGES.LASTNAME_TH,
          label: LABEL_PAGES.LASTNAME_TH, //นามสกุล (ภาษาไทย)
          rules: [{ required: true, message: MESSAGE_ERROR.INPUT }],
        },
        inputprops: {
          type: KEY_TYPE.CONTENT,
          disabled: disabledAll,
          placeholder: "",
        },
      },
      {
        span: 3,
        itemprops: {
          name: FIELD_PAGES.NICKNAME_TH,
          label: LABEL_PAGES.NICKNAME_TH, //ชื่อเล่น (ภาษาไทย)
          rules: [{ required: true, message: MESSAGE_ERROR.INPUT }],
        },
        inputprops: {
          type: KEY_TYPE.CONTENT,
          disabled: disabledAll,
          placeholder: "",
        },
      },
      {
        span: 6,
        itemprops: {
          name: FIELD_PAGES.PERSONAL_ID,
          label: LABEL_PAGES.PERSONAL_ID, //บัตรประชาชน
          rules: [{ required: false, message: MESSAGE_ERROR.ID_CARD, len: 13 }],
        },
        inputprops: {
          type: KEY_TYPE.CONTENT,
          disabled: disabledAll,
          placeholder: "",
        },
      },
      {
        span: 4,
        itemprops: {
          name: FIELD_PAGES.PERSONAL_ID_EXP,
          label: LABEL_PAGES.PERSONAL_ID_EXP,
          rules: [{ required: false, message: MESSAGE_ERROR.DATE_PICKER }],
        },
        inputprops: {
          type: KEY_TYPE.CONTENT,
          defaultPickerValue: moment(), //.add(1, 'year'),
          disabled: disabledAll,
          placeholder: "",
        },
      },
      {
        span: 3,
        itemprops: {
          name: FIELD_PAGES.TITLE_EN,
          label: LABEL_PAGES.TITLE_EN, //คำนำหน้า (ภาษาอังกฤษ)
          rules: [{ required: true, message: MESSAGE_ERROR.SELECT }],
        },
        inputprops: {
          type: KEY_TYPE.CONTENT,
          disabled: disabledAll,
          placeholder: "",
          options: options[KEY_OPTION.CENTER.OPTION_TITLE_EN],
        },
      },
      {
        span: 4,
        itemprops: {
          name: FIELD_PAGES.FIRSTNAME_EN,
          label: LABEL_PAGES.FIRSTNAME_EN, //ชื่อ (ภาษาอังกฤษ)
          rules: [{ required: true, message: MESSAGE_ERROR.INPUT_EN }],
        },
        inputprops: {
          type: KEY_TYPE.CONTENT,
          disabled: disabledAll,
          placeholder: "",
        },
      },
      {
        span: 4,
        itemprops: {
          name: FIELD_PAGES.LASTNAME_EN,
          label: LABEL_PAGES.LASTNAME_EN, //นามสกุล (ภาษาอังกฤษ)
          rules: [{ required: true, message: MESSAGE_ERROR.INPUT_EN }],
        },
        inputprops: {
          type: KEY_TYPE.CONTENT,
          disabled: disabledAll,
          placeholder: "",
        },
      },
      {
        span: 3,
        itemprops: {
          name: FIELD_PAGES.NICKNAME_EN,
          label: LABEL_PAGES.NICKNAME_EN, //ชื่อเล่น (ภาษาอังกฤษ)
          rules: [{ required: false, message: MESSAGE_ERROR.INPUT_EN }],
        },
        inputprops: {
          type: KEY_TYPE.CONTENT,
          disabled: disabledAll,
          placeholder: "",
        },
      },
      {
        span: 6,
        itemprops: {
          name: FIELD_PAGES.PASSPORT_ID,
          label: LABEL_PAGES.PASSPORT_ID, //หนังสือเดินทาง (Passport No.)
          rules: [{ required: false, message: MESSAGE_ERROR.PASSPORT }],
        },
        inputprops: {
          type: KEY_TYPE.CONTENT,
          maxLength: 20,
          disabled: disabledAll,
          placeholder: "",
        },
      },
      {
        span: 4,
        itemprops: {
          name: FIELD_PAGES.PASSPORT_ID_EXP,
          label: LABEL_PAGES.PASSPORT_ID_EXP,
          rules: [{ required: false, message: MESSAGE_ERROR.DATE_PICKER }],
        },
        inputprops: {
          type: KEY_TYPE.CONTENT,
          defaultPickerValue: moment(), //.add(1, 'year'),
          placeholder: "",
          disabled: disabledAll,
        },
      },
      {
        span: 7,
        itemprops: {
          name: FIELD_PAGES.USERNAME,
          label: LABEL_PAGES.USERNAME, //Username
          rules: [{ required: false, message: MESSAGE_ERROR.INPUT }],
        },

        inputprops: {
          type: KEY_TYPE.CONTENT,
          disabled: true || disabledAll,
          placeholder: "",
        },
      },
      {
        span: 7,
        itemprops: {
          name: FIELD_PAGES.EMAIL,
          label: LABEL_PAGES.EMAIL, //อีเมลพนักงาน (Email Employee)
          rules: [
            { required: false, message: MESSAGE_ERROR.EMAIL, type: "email" },
          ],
        },
        inputprops: {
          type: KEY_TYPE.CONTENT,
          disabled: disabledAll,
          placeholder: "",
        },
      },
      {
        span: 6,
        itemprops: {
          name: FIELD_PAGES.WORKPERMIT_ID,
          label: LABEL_PAGES.WORKPERMIT_ID, //ใบอนุญาติทำงาน (Work Permit)
          rules: [{ required: false, message: MESSAGE_ERROR.ID_CARD, len: 13 }],
        },
        inputprops: {
          type: KEY_TYPE.CONTENT,
          disabled: disabledAll,
          placeholder: "",
        },
      },
      {
        span: 4,
        itemprops: {
          name: FIELD_PAGES.WORKPERMIT_ID_EXP,
          label: LABEL_PAGES.WORKPERMIT_ID_EXP,
          rules: [{ required: false, message: MESSAGE_ERROR.DATE_PICKER }],
        },
        inputprops: {
          type: KEY_TYPE.CONTENT,
          defaultPickerValue: moment(), //.add(1, 'year'),
          placeholder: "",
          disabled: disabledAll,
        },
      },
      /* -------------------------------------------------------------------------- */
      {
        span: 24,
        inputprops: {
          type: KEY_TYPE.CONTENT,
          title: "ข้อมูลการติดต่อ",
        },
      },
      /* -------------------------------------------------------------------------- */
      {
        span: 6,
        itemprops: {
          name: FIELD_EMP.TEL,
          label: LABEL_EMP.TEL, //โทรศัพท์มือถือ,
          rules: [{ required: true, message: MESSAGE_ERROR.TEL }],
        },
        inputprops: {
          type: KEY_TYPE.CONTENT,
          length: 10,
          disabled: disabledAll,
          placeholder: "",
        },
      },
      {
        span: 6,
        itemprops: {
          name: FIELD_PERSONAL.HOME_TEL,
          label: LABEL_PERSONAL.HOME_TEL, //โทรศัพท์ที่บ้าน
          rules: [{ required: false, message: MESSAGE_ERROR.TEL }],
        },
        inputprops: {
          type: KEY_TYPE.CONTENT,
          length: 10,
          disabled: disabledAll,
          placeholder: "",
        },
      },
      {
        span: 6,
        itemprops: {
          name: FIELD_PERSONAL.LINE_ID,
          label: LABEL_PERSONAL.LINE_ID, //LINE ID
          rules: [{ required: false, message: MESSAGE_ERROR.INPUT }],
        },
        inputprops: {
          type: KEY_TYPE.CONTENT,
          disabled: disabledAll,
          placeholder: "",
        },
      },
      {
        span: 6,
        itemprops: {
          name: FIELD_PERSONAL.EMAIL,
          label: LABEL_PERSONAL.EMAIL, //Email
          rules: [
            { required: false, message: MESSAGE_ERROR.EMAIL, type: "email" },
          ],
        },
        inputprops: {
          type: KEY_TYPE.CONTENT,
          disabled: disabledAll,
          placeholder: "",
        },
      },
    ],
  ];
};

/* -------------------------------------------------------------------------- */
/*                                INITIAL_PAGES                                */
/* -------------------------------------------------------------------------- */

const INITIAL_PAGES = {
  nameEmployer: "",
  telEmployer: "",
  lastPosition: "",
  addressEmployer: "",
  startWorkEmployer: "",
  endWorkEmployer: "",
  bossNameEmployer: "",
  bossPosition: "",
  reasonForLeave: "",
  responsibility: "",
};

export const CONTENT_EXPERIENCES_VIEW = (props) => {
  const { disabledAll } = props;
  /**
   * TODO: สามารถแบ่งตาม  location ได้ โดยใช้ switch case
   */
  return [
    [
      {
        span: 24,
        inputprops: {
          type: KEY_TYPE.CARD_FORM,
          name: FIELD_STEP.COLLAPSE_S21, //experiences
          removelenght: 0,
          header: (idx) => `สถานประกอบการที่ ${idx + 1}`,
          initialValues: { ...INITIAL_PAGES },
          disabled: disabledAll,
          content: (field) => [
            /* -------------------------------------------------------------------------- */

            {
              span: 6,
              itemprops: {
                name: [field.name, FIELD_PAGES.NAME_EMPLOYER],
                label: LABEL_PAGES.NAME_EMPLOYER, //ชื่อที่ทำงาน
                fieldKey: [field.fieldKey, FIELD_PAGES.NAME_EMPLOYER],
                rules: [{ required: false, message: MESSAGE_ERROR.INPUT }],
              },
              inputprops: {
                type: KEY_TYPE.CONTENT,
                disabled: disabledAll,
                placeholder: "",
              },
            },
            {
              span: 3,
              itemprops: {
                name: [field.name, FIELD_PAGES.START_DATE],
                label: LABEL_PAGES.START_DATE, //วันที่เริ่มต้น
                fieldKey: [field.fieldKey, FIELD_PAGES.START_DATE],
                rules: [
                  { required: false, message: MESSAGE_ERROR.DATE_PICKER },
                ],
              },
              inputprops: {
                type: KEY_TYPE.CONTENT,
                disabled: disabledAll,
                placeholder: "",
              },
            },
            {
              span: 3,
              itemprops: {
                name: [field.name, FIELD_PAGES.END_DATE],
                label: LABEL_PAGES.END_DATE, //วันที่สิ้นสุด
                fieldKey: [field.fieldKey, FIELD_PAGES.END_DATE],
                rules: [
                  { required: false, message: MESSAGE_ERROR.DATE_PICKER },
                ],
              },
              inputprops: {
                type: KEY_TYPE.CONTENT,
                disabled: disabledAll,
                placeholder: "",
              },
            },
            {
              span: 6,
              itemprops: {
                name: [field.name, FIELD_PAGES.TEL_EMPLOYER],
                label: LABEL_PAGES.TEL_EMPLOYER, //เบอร์โทรศัพท์
                fieldKey: [field.fieldKey, FIELD_PAGES.TEL_EMPLOYER],
                rules: [{ required: false, message: MESSAGE_ERROR.TEL }],
              },
              inputprops: {
                type: KEY_TYPE.CONTENT,
                length: 10,
                disabled: disabledAll,
                placeholder: "",
              },
            },
            {
              span: 6,
              itemprops: {
                name: [field.name, FIELD_PAGES.LAST_POSITION],
                label: LABEL_PAGES.LAST_POSITION, //ตำแหน่งงานล่าสุด
                fieldKey: [field.fieldKey, FIELD_PAGES.LAST_POSITION],
                rules: [{ required: false, message: MESSAGE_ERROR.INPUT }],
              },
              inputprops: {
                type: KEY_TYPE.CONTENT,
                disabled: disabledAll,
                placeholder: "",
              },
            },
            /* -------------------------------------------------------------------------- */
            {
              span: 12,
              itemprops: {
                name: [field.name, FIELD_PAGES.ADDRESS_EMPLOYER],
                label: LABEL_PAGES.ADDRESS_EMPLOYER, //ที่อยู่ที่ทำงาน
                fieldKey: [field.fieldKey, FIELD_PAGES.ADDRESS_EMPLOYER],
                rules: [{ required: false, message: MESSAGE_ERROR.INPUT }],
              },
              inputprops: {
                type: KEY_TYPE.CONTENT,
                disabled: disabledAll,
                placeholder: "",
              },
            },

            {
              span: 6,
              itemprops: {
                name: [field.name, FIELD_PAGES.BOSS_NAME],
                label: LABEL_PAGES.BOSS_NAME, //ชื่อหัวหน้างาน
                fieldKey: [field.fieldKey, FIELD_PAGES.BOSS_NAME],
                rules: [{ required: false, message: MESSAGE_ERROR.INPUT }],
              },
              inputprops: {
                type: KEY_TYPE.CONTENT,
                disabled: disabledAll,
                placeholder: "",
              },
            },
            {
              span: 6,
              itemprops: {
                name: [field.name, FIELD_PAGES.BOSS_POSITION],
                label: LABEL_PAGES.BOSS_POSITION, //ตำแหน่งหัวหน้างาน
                fieldKey: [field.fieldKey, FIELD_PAGES.BOSS_POSITION],
                rules: [{ required: false, message: MESSAGE_ERROR.INPUT }],
              },
              inputprops: {
                type: KEY_TYPE.CONTENT,
                disabled: disabledAll,
                placeholder: "",
              },
            },
            /* -------------------------------------------------------------------------- */

            {
              span: 12,
              itemprops: {
                name: [field.name, FIELD_PAGES.REASON_LEAVE],
                label: LABEL_PAGES.REASON_LEAVE, //สาเหตุที่ลาออก
                fieldKey: [field.fieldKey, FIELD_PAGES.REASON_LEAVE],
                rules: [{ required: false, message: MESSAGE_ERROR.INPUT_AREA }],
              },
              inputprops: {
                type: KEY_TYPE.CONTENT,
                disabled: disabledAll,
                placeholder: "",
              },
            },
            {
              span: 12,
              itemprops: {
                name: [field.name, FIELD_PAGES.RESPONSIBILITY],
                label: LABEL_PAGES.RESPONSIBILITY, //หน้าที่ความรับผิดชอบ
                fieldKey: [field.fieldKey, FIELD_PAGES.RESPONSIBILITY],
                rules: [{ required: false, message: MESSAGE_ERROR.INPUT_AREA }],
              },
              inputprops: {
                type: KEY_TYPE.CONTENT,
                disabled: disabledAll,
                placeholder: "",
              },
            },
          ],
        },
      },
    ],
  ];
};
