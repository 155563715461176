import React, { Fragment, useContext, useState } from 'react'
import { LayoutTable } from '../common/layouts'
import { IndicatorContext } from '../services/manage/indicatorProvider';
import { STRUCTURE_KPIS } from '../constants/structures/structuresKPIs';
import { Empty, Spin } from 'antd';

import { ReportContext } from '../services/report/reportProvider';

import ContentInfoScore from './contentInfoScore';
import ContentInsertScore from './contentInsertScore';
import ContentUpdateScore from './contentUpdateScore';
import { renderMonth } from '../../constants/functions';


export const EvaluationForm = ({ factory }) => {
    const STRUCTURE = STRUCTURE_KPIS
    const { reportState } = useContext(ReportContext)
    const { userLogin } = useContext(IndicatorContext)
    const [pageSize, setPageSize] = useState(100)

    const {dashboardKPIsLists,dataSearch} = reportState
    
    return (
        <Fragment>
            {
                (Array.isArray(dashboardKPIsLists) && dashboardKPIsLists.length > 0) ?
                    <div style={{ padding: 10 }}>
                        <Spin spinning={false} tip={"กำลังโหลด..."}>
                            <LayoutTable
                                bordered
                                scroll={{ x: (dataSearch.length > 10) && 2500 }}
                                rowKey={'employeeId'}
                                columns={STRUCTURE.COLUMN_EVALUATION({
                                    dataSource: dashboardKPIsLists,
                                    searchDate: dataSearch,
                                    action: (record, search) => {
                                        let monthYear = (`${renderMonth(parseInt(search.month)).th}  ${search.year}`);
                                        // console.log({ record, search })
                                        switch (record[`status${search.month}`]) {
                                            case 1:
                                            case 2:
                                                return (
                                                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }} >
                                                        <ContentUpdateScore
                                                            payload={{
                                                                type: 'update',
                                                                lineScore: { employeeId: record.employeeId, month: search.month, year: search.year },
                                                                employeeId: record.employeeId,
                                                                activeMonth: `${(parseInt(search.month) > 9 ? `${search.month}/${search.year} ` : `0${search.month}/${search.year}`)}`
                                                            }}
                                                            search={search}
                                                            content={{ ...record, ...search, totalScore: record.totalScore, monthYear, approval: record.approvalNo2 === userLogin }} />
                                                        <ContentInfoScore
                                                            payload={{
                                                                type: 'info',
                                                                lineScore: { employeeId: record.employeeId, month: search.month, year: search.year },
                                                                employeeId: record.employeeId,
                                                                activeMonth: `${(parseInt(search.month) > 9 ? `${search.month}/${search.year} ` : `0${search.month}/${search.year}`)}`
                                                            }}
                                                            content={{ ...record, ...search, monthYear, approval: record.approvalNo2 === userLogin }} />
                                                    </div>
                                                )
                                            case 3:
                                                return (
                                                    <ContentInfoScore
                                                        totalScore={parseFloat(record[`month${search.month}`] || 0).toFixed(2)}
                                                        payload={{
                                                            type: 'info',
                                                            lineScore: { employeeId: record.employeeId, month: search.month, year: search.year },
                                                            employeeId: record.employeeId,
                                                            activeMonth: `${(parseInt(search.month) > 9 ? `${search.month}/${search.year} ` : `0${search.month}/${search.year}`)}`
                                                        }}
                                                        content={{ ...record, ...search, monthYear, approval: record.approvalNo2 === userLogin }} />
                                                )
                                            default:
                                                return <ContentInsertScore
                                                    factory={false}
                                                    payload={{
                                                        type: 'insert',
                                                        lineScore: { employeeId: record.employeeId, month: search.month, year: search.year },
                                                        employeeId: record.employeeId,
                                                        activeMonth: `${(parseInt(search.month) > 9 ? `${search.month}/${search.year} ` : `0${search.month}/${search.year}`)}`
                                                    }}
                                                    search={search}
                                                    content={{ ...record, ...search, monthYear, approval: record.approvalNo2 === userLogin }} />
                                        }
                                    }
                                })}

                                dataSource={dashboardKPIsLists}
                                pagination={{
                                    position: 'bottom',
                                    pageSize: pageSize,
                                    showSizeChanger: true,
                                    pageSizeOptions: ['100', '500', '1000'],
                                    onShowSizeChange: (current, size) => {
                                        setPageSize(size)
                                    },
                                    showTotal: (total, range) => `ทั้งหมด ${total} รายการ`
                                }}
                            />
                        </Spin>
                    </div>
                    :
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />

            }
        </Fragment>
    )
};