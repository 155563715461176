import { KEY_TYPE, KEY_OPTION } from "../../../constants/enums/enumCenter";
import { PATHNAME } from "../../../constants/enums/pathname"
import { renderInputOptions } from "../../../service/functions/convertOptions"

export const SEARCH_CONTRACT = ({ pathname, permission, options }) => {
	switch (pathname) {
		case PATHNAME.DASHBOARD_FOLLOWUP_GURANTEE:
			return [
				{
					span: 8,
					type: KEY_TYPE.SELECT,
					header: 'แผนก/กลุ่ม',
					fieldname: 'segment',
					required: false,
					disabled: false,
					inputOption: renderInputOptions(KEY_OPTION.CENTER.OPTION_DEP, options)
				}, {
					span: 8,
					type: KEY_TYPE.SELECT,
					header: 'สถานะการประเมิน',
					fieldname: 'statusMainCode',
					required: false,
					disabled: false,
					inputOption: renderInputOptions(KEY_OPTION.PROBATION.OPTION_STATUS_ASSESSMENT, options)
				}, {
					span: 8,
					type: KEY_TYPE.RANG_PICKER,
					header: 'วันที่เริ่มทำงาน',
					fieldname: 'StartDateJob',
					required: false,
					disabled: false,
					inputOption: renderInputOptions(KEY_OPTION.CENTER.OPTION_RANGE_DATE, options)
				}
			]
		case PATHNAME.DASHBOARD_PROBATION_CONTRACT:
			return [
				{
					span: 4,
					type: KEY_TYPE.SELECT,
					header: 'แผนก/กลุ่ม',
					fieldname: 'segment',
					required: false,
					disabled: false,
					inputOption: renderInputOptions(KEY_OPTION.CENTER.OPTION_DEP, options)
				}, {
					span: 4,
					type: KEY_TYPE.SELECT,
					header: 'สถานะการประเมิน',
					fieldname: 'statusMainCode',
					required: false,
					disabled: false,
					inputOption: renderInputOptions(KEY_OPTION.PROBATION.OPTION_STATUS_ASSESSMENT, options)
				}, {
					span: 4,
					type: KEY_TYPE.DATE_PICKER,
					header: 'วันที่เริ่มทำงาน',
					fieldname: 'StartDateJob',
					required: false,
					disabled: false,
					inputOption: renderInputOptions(KEY_OPTION.CENTER.OPTION_DATE, options)
				}, {
					span: 4,
					type: KEY_TYPE.DATE_PICKER,
					header: 'วันที่สิ้นสุดทำงาน',
					fieldname: 'ToDateJob',
					required: false,
					disabled: false,
					inputOption: renderInputOptions(KEY_OPTION.CENTER.OPTION_DATE_TO, options)
				}, {
					span: 4,
					type: KEY_TYPE.SELECT,
					header: 'รายชื่อพนักงาน',
					fieldname: 'searchUser',
					required: false,
					disabled: false,
					inputOption: renderInputOptions(KEY_OPTION.CENTER.OPTION_SELECT_EMPLOY_HISTORY, options)
				},
			]
		case PATHNAME.DASHBOARD_PROBATION_EMP_CONTRACT:
			return [
				{
					span: 6,
					type: KEY_TYPE.SELECT,
					header: 'สถานที่',
					fieldname: 'location',
					required: false,
					disabled: false,
					inputOption: renderInputOptions(KEY_OPTION.CENTER.OPTION_LOCATION, options)
				}, {
					span: 6,
					type: KEY_TYPE.SELECT,
					header: 'แผนก/กลุ่ม',
					fieldname: 'depNo',
					required: false,
					disabled: false,
					inputOption: renderInputOptions(KEY_OPTION.CENTER.OPTION_DEP, options)
				}, {
					span: 6,
					type: KEY_TYPE.SELECT,
					header: 'สถานะการประเมิน',
					fieldname: 'statusMainCode',
					required: false,
					disabled: false,
					inputOption: renderInputOptions(KEY_OPTION.PROBATION.OPTION_STATUS_ASSESSMENT, options)
				}, {
					span: 6,
					type: KEY_TYPE.DATE_PICKER,
					header: 'วันที่เริ่มทำงาน',
					fieldname: 'StartDateJob',
					required: false,
					disabled: false,
					inputOption: renderInputOptions(KEY_OPTION.CENTER.OPTION_DATE, options)
				}, {
					span: 6,
					type: KEY_TYPE.INPUT,
					header: 'รหัส,ชื่อ,ชื่อเล่นพนักงาน',
					fieldname: 'userSeaching',
					required: false,
					disabled: false,
					inputOption: renderInputOptions(KEY_OPTION.CENTER.OPTION_USERS, options)
				}, {
					span: 6,
					type: KEY_TYPE.SELECT,
					header: 'ผลการทดลองงาน',
					fieldname: 'statusProbation',
					required: false,
					disabled: false,
					inputOption: renderInputOptions(KEY_OPTION.PROBATION.OPTION_STATUS_RESULT, options)
				}, {
					span: 6,
					type: KEY_TYPE.SELECT,
					header: 'กลุ่ม/สถานะพนง.',
					fieldname: 'statusWorker',
					required: false,
					disabled: false,
					inputOption: renderInputOptions(KEY_OPTION.CENTER.OPTION_STATUS_WORKERS, options)
				}
			]
		case PATHNAME.DASHBOARD_PROBATION_EMP_CONTRACT_NEW:
			return [
				{
					span: 6,
					type: KEY_TYPE.SELECT,
					header: 'สถานที่',
					fieldname: 'location',
					required: false,
					disabled: false,
					inputOption: renderInputOptions(KEY_OPTION.CENTER.OPTION_LOCATION, options)
				}, {
					span: 6,
					type: KEY_TYPE.SELECT,
					header: 'แผนก/กลุ่ม',
					fieldname: 'depNo',
					required: false,
					disabled: false,
					inputOption: renderInputOptions(KEY_OPTION.CENTER.OPTION_DEP, options)
				}, {
					span: 6,
					type: KEY_TYPE.SELECT,
					header: 'สถานะการประเมิน',
					fieldname: 'statusMainCode',
					required: false,
					disabled: false,
					inputOption: renderInputOptions(KEY_OPTION.PROBATION.OPTION_STATUS_ASSESSMENT, options)
				}, {
					span: 6,
					type: KEY_TYPE.DATE_PICKER,
					header: 'วันที่เริ่มทำงาน',
					fieldname: 'StartDateJob',
					required: false,
					disabled: false,
					inputOption: renderInputOptions(KEY_OPTION.CENTER.OPTION_DATE, options)
				}, {
					span: 6,
					type: KEY_TYPE.INPUT,
					header: 'รหัส,ชื่อ,ชื่อเล่นพนักงาน',
					fieldname: 'userSeaching',
					required: false,
					disabled: false,
					inputOption: renderInputOptions(KEY_OPTION.CENTER.OPTION_USERS, options)
				}, {
					span: 6,
					type: KEY_TYPE.SELECT,
					header: 'ผลการทดลองงาน',
					fieldname: 'statusProbation',
					required: false,
					disabled: false,
					inputOption: renderInputOptions(KEY_OPTION.PROBATION.OPTION_STATUS_RESULT, options)
				}, {
					span: 6,
					type: KEY_TYPE.SELECT,
					header: 'กลุ่ม/สถานะพนง.',
					fieldname: 'statusWorker',
					required: false,
					disabled: false,
					inputOption: renderInputOptions(KEY_OPTION.CENTER.OPTION_STATUS_WORKERS, options)
				}
			]
		case PATHNAME.DASHBOARD_TRACK_CONTRACT:
		case PATHNAME.DASHBOARD_BENEFIT_CONTRACT:
			return [
				{
					span: 8,
					type: KEY_TYPE.SELECT,
					header: 'สถานที่',
					fieldname: 'location',
					required: false,
					disabled: false,
					inputOption: renderInputOptions(KEY_OPTION.CENTER.OPTION_LOCATION, options)
				}, {
					span: 8,
					type: KEY_TYPE.SELECT,
					header: 'แผนก/กลุ่ม',
					fieldname: 'depNo',
					required: false,
					disabled: false,
					inputOption: renderInputOptions(KEY_OPTION.CENTER.OPTION_DEP, options)
				}, {
					span: 8,
					type: KEY_TYPE.RANG_PICKER,
					header: 'วันที่เริ่มทำงาน',
					fieldname: 'StartDateJob',
					required: false,
					disabled: false,
					inputOption: renderInputOptions(KEY_OPTION.CENTER.OPTION_RANGE_DATE, options)
				}, {
					span: 8,
					type: KEY_TYPE.INPUT,
					header: 'รหัส,ชื่อ,ชื่อเล่นพนักงาน',
					fieldname: 'userSeaching',
					required: false,
					disabled: false,
					inputOption: renderInputOptions(KEY_OPTION.CENTER.OPTION_USERS, options)
				}, {
					span: 8,
					type: KEY_TYPE.SELECT,
					header: 'ผลการทดลองงาน',
					fieldname: 'statusProbation',
					required: false,
					disabled: false,
					inputOption: renderInputOptions(KEY_OPTION.PROBATION.OPTION_STATUS_RESULT, options)
				}, {
					span: 8,
					type: KEY_TYPE.SELECT,
					header: 'กลุ่ม/สถานะพนง.',
					fieldname: 'statusWorker',
					required: false,
					disabled: false,
					inputOption: renderInputOptions(KEY_OPTION.CENTER.OPTION_STATUS_WORKERS, options)
				}
			]
		default:
			return []
	}
}
