import React, { useState, useContext } from 'react'
import { Spin, Table, Typography } from 'antd';
import { FIELD_BUTTON, TEXT_BUTTON } from '../../constants/buttons';
import { PATHNAME } from '../../constants/enums/pathname';
import { ButtonTheme } from '../../moduleKPIs/common/buttons';
import { LayoutTable } from '../../moduleKPIs/common/layouts';
import { TABLE_HISTORY_INDIVIDUAL, TABLE_HISTORY_TRAINERS } from '../commonsTraining/structuresTraining/tableTraining';
import { ManageTrainingContext } from '../servicesTraining/manageTraining/manageTrainingProvider';
import { MASTER_OPTION, REPORT_OPTION } from '../../moduleKPIs/services/servicesOptions';
import { MasterTrainingContext } from '../servicesTraining/masterTraining/masterTrainingProvider';
import { colors } from '../../themes';


const DashboardHistory = ({ pathname }) => {
    const { manageTrainingState, userLogin, getAPI } = useContext(ManageTrainingContext)
    const { masterTrainingState } = useContext(MasterTrainingContext)
    const { dataTableAction: { rowKey, dataSource } } = manageTrainingState
    // const [loading, setLoading] = useState(false)

    async function onSearch(_pathname) {
        
        // await setLoading(true)
        await window.localStorage.clear()
        switch (_pathname) {
            case PATHNAME.DASHBOARD_HISTORY_INDIVIDUAL:
                await getAPI({ fieldname: REPORT_OPTION.DASHBOARD_HISTORY_INDIVIDUAL, payload: { ...masterTrainingState } })
                break;
            case PATHNAME.DASHBOARD_HISTORY_TRAINERS:
                await getAPI({ fieldname: REPORT_OPTION.DASHBOARD_HISTORY_TRAINERS, payload: { ...masterTrainingState } })
                break;
            default:
                break;
        }
        // await setLoading(false)
    }
    switch (pathname) {
        case PATHNAME.DASHBOARD_HISTORY_INDIVIDUAL:
            return (RenderDashboard({
                pathname: pathname,
                onSearch: (_pathname) => onSearch(_pathname),
                structureTable: {
                    rowKey: rowKey,
                    dataSource: dataSource,
                    columns: TABLE_HISTORY_INDIVIDUAL({
                        permission: userLogin.userPermission,
                        userLogin: userLogin.userId,
                        actionButton: async ({ fieldBtn, payload }) => {
                            await window.localStorage.setItem(REPORT_OPTION.VIEW_INDIVIDUAL, JSON.stringify(payload))
                            switch (fieldBtn) {
                                case FIELD_BUTTON.PRINT_INDIVIDUAL_REPORT:
                                    await getAPI({
                                        fieldname: REPORT_OPTION.DASHBOARD_HISTORY_INDIVIDUAL,
                                        payload: { ...masterTrainingState, ...payload }
                                    })
                                    // await browserHistory.push(PATHNAME.HISTORY_INDIVIDUAL_FORM)
                                    await window.open(PATHNAME.HISTORY_INDIVIDUAL_FORM, '_blank');
                                    break;
                                default:
                                    break;
                            }
                        }
                    }),
                    bordered: true,
                    scroll: { x: 1000 },
                    pageSize: 100
                }
            })
            )
        case PATHNAME.DASHBOARD_HISTORY_TRAINERS:
            return (
                RenderDashboard({
                    pathname: pathname,
                    onSearch: (_pathname) => onSearch(_pathname),
                    structureTable: {
                        rowKey: rowKey,
                        dataSource: dataSource,
                        columns: TABLE_HISTORY_TRAINERS({
                            permission: userLogin.userPermission,
                            userLogin: userLogin.userId,
                            actionButton: undefined
                        }),

                        summary: pageData => {
                            let totalParticipants = 0;
                            let totalPeriod = 0;

                            pageData.forEach(({ numParticipants, timePeriod }) => {
                                totalParticipants += numParticipants;
                                totalPeriod += timePeriod;
                            });
                            return (
                                <>
                                    <Table.Summary.Row>
                                        <Table.Summary.Cell colSpan={4}>รวมทั้งหมด</Table.Summary.Cell>
                                        <Table.Summary.Cell colSpan={1}  >
                                            <Typography.Text type="danger"  >{totalParticipants}</ Typography.Text >
                                        </Table.Summary.Cell>
                                        <Table.Summary.Cell colSpan={1} >
                                            <Typography.Text type="danger" >{totalPeriod}</ Typography.Text >
                                        </Table.Summary.Cell>
                                    </Table.Summary.Row>
                                </>
                            );
                        },
                        bordered: true,
                        pageSize: 100
                    }
                })
            )
        default:
            break;
    }

}

const RenderDashboard = ({ structureTable, pathname, onSearch }) => {
    const { masterTrainingState, } = useContext(MasterTrainingContext)

    const [loading, setLoading] = useState(false)
    let _course = masterTrainingState[`SEARCH_${MASTER_OPTION.OPTION_TRAINING_COURSE}`]
    let _user = masterTrainingState[`SEARCH_${MASTER_OPTION.OPTION_USER_TRAINING}`]
    // console.log({ _course, _user })
    return (
        <div>
            {(pathname === PATHNAME.DASHBOARD_HISTORY_TRAINERS) && <div style={{ color: 'red', fontSize: 12, position: 'absolute', top: '200px', left: '22px' }}> * กรุณาระบุอย่างใดอย่างหนึ่ง </div>}
            <div className="layout-content-btn-search">
                <ButtonTheme type="primary"
                    disabled={(pathname === PATHNAME.DASHBOARD_HISTORY_TRAINERS) ? ((_course !== " " || _user !== " ") ? false : true) : false}
                    buttonStyle={{ background: colors.buttonSearch, color: colors.white }}
                    buttonText={TEXT_BUTTON.SEARCH}
                    onClick={async () => {
                        await setLoading(true)
                        await onSearch(pathname)
                        await setLoading(false)
                    }}
                />
            </div>
            <Spin spinning={loading} tip="กำลังโหลด">
                <LayoutTable
                    {...structureTable}
                    pagination={{
                        pageSize: structureTable.pageSize || 100,
                        showTotal: (total) => `ทั้งหมด ${total || 0} รายการ`
                    }} />
            </Spin>
        </div>
    )
}
export default DashboardHistory