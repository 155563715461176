import { createRef } from "react";

import { MESSAGE_ERROR } from "../index.jsx";
import {
  columnSearchProps,
  columnSortDate,
} from "../../../components/structuresReport/function";
import {
  KEY_ACTIVE_TAB,
  KEY_OPTION,
  KEY_TYPE,
} from "../../../constants/enums/enumCenter";
import { BREADCRUMB_PERSONAL } from "../breadcrumbPersonal.jsx";
import { message } from "antd";

/* -------------------------------------------------------------------------- */
/*                                 FIELD_PAGES                                */
/* -------------------------------------------------------------------------- */

const FIELD_PAGES = {
  //!เปลี่ยนชื่อ Field ที่นี่
  /* ----------------------------- TABLE / EXPORT ----------------------------- */
  EMPLOYEE_ID: "ID_User",
  USERNAME: "Username",
  TITLE: "title",
  FULLNAME: "Name",
  NICKNAME: "NickName",
  LOCATION_NAME: "locationName",
  DEPARTMENT_NAME: "Department_Name",
  SUB_SEPARTMENT_NAME: "subDepName",
  STATUS_WORKER: "statusWorker",
  SHIRT_SIZE: "shirtSize",
  POSITION_NAME: "PositionNameEN",
  LEVEL_WORKER: "Level_WorkerDes",
  FLOW_HRM_NAME: "Flow_Name",
  PERSONAL_ID: "Personal_id",
  PROBATION_DATE_END: "probationDateEnd",
  PROBATION_STATUS_DES: "probationStatusDes",
  CODE_FINGER: "codeMapFingerScan",
  PAYROLL_GROUP_DES: "payrollGroupDes",

  /* --------------------------------- SEARCH --------------------------------- */
  USER_ID: "userID",
  IS_BLOCK: "isBlock",
  COMPANY: "company",
  COMPANY_UPPER: "Company",
  LOCATION: "location",
  DEPARTMENT_NO: "depNo",
  SUB_DEPARTMENT_NO: "subDepNo",
  START_DATE: "startDate",
  END_DATE: "endDate",
  START_DATE_JOB: "StartDateJob",
  END_DATE_JOB: "EndDateJob",
  CURRENT_STATUS: "currentStatusDes",
  END_DATE_JOB_FROM: "endDateJobFrom",
  END_DATE_JOB_TO: "endDateJobTo",
  BIRTH_DAY: "birthday",
};

/* -------------------------------------------------------------------------- */
/*                                 LABEL_PAGES                                */
/* -------------------------------------------------------------------------- */

const LABEL_PAGES = {
  //!เปลี่ยนชื่อ Label ที่นี่
  /* ----------------------------- TABLE / EXPORT ----------------------------- */
  EMPLOYEE_ID: "รหัสพนักงาน",
  USERNAME: "Username",
  TITLE: "คำนำหน้า",
  FULLNAME: "ชื่อ - นามสกุล",
  NICKNAME: "ชื่อเล่น",
  DEPARTMENT_NAME: "แผนก",
  SUB_SEPARTMENT_NAME: "แผนกย่อย",
  STATUS_WORKER: "สถานะพนักงาน",
  SHIRT_SIZE: "ไซส์เสื้อ",
  POSITION_NAME: "ตำแหน่ง",
  LEVEL_WORKER: "กลุ่มตำแหน่ง",
  FLOW_HRM_NAME: "สายการอนุมัติ",
  START_DATE_JOB: "วันที่เริ่มงาน",
  END_DATE_JOB: "วันที่สิ้นสุดการทำงาน",
  PERSONAL_ID: "รหัสบัตรประชาชน",
  PROBATION_DATE_END: "วันที่ผ่านทดลองงาน",
  PROBATION_STATUS_DES: "สถานะผ่านทดลองงาน",
  CODE_FINGER: "สแกนลายนิ้วมือ",
  PAYROLL_GROUP_DES: "กลุ่มเงินเดือน",
  /* --------------------------------- SEARCH --------------------------------- */
  USER_ID: "รหัสพนักงาน",
  IS_BLOCK: "เป็นพนักงานพ้นสภาพ",
  COMPANY: "บริษัท",
  COMPANY_UPPER: "บริษัท",
  LOCATION: "สถานที่",
  DEPARTMENT_NO: "แผนก",
  SUB_DEPARTMENT_NO: "แผนกย่อย",
  START_DATE: "วันที่เริ่มงาน",
  END_DATE: "วันที่สิ้นสุด",
  CURRENT_STATUS: "สถานภาพพนักงาน",
  END_DATE_JOB_FROM: "วันที่พ้นสภาพ (เริ่ม)",
  END_DATE_JOB_TO: "วันที่พ้นสภาพ (สิ้นสุด)",
  BIRTH_DAY: "วันเกิด",

  /* ------------------------------  ----------------------------- */
};

/* -------------------------------------------------------------------------- */
/*                                 TEXT_PAGES                                 */
/* -------------------------------------------------------------------------- */

const TEXT_PAGES = {
  //!เปลี่ยนชื่อ Label Tabs ที่นี่
  HEADER_REPORT: "จัดการพนักงานใหม่",
  CONTENT_REPORT: "",
  FILE_EXPORT: {
    [KEY_ACTIVE_TAB.UPDATE_PERSONAL]: "รายชื่อพนักงานทั้งหมด",
  },
  TAB: {
    [KEY_ACTIVE_TAB.UPDATE_PERSONAL]: "รายชื่อพนักงานทั้งหมด",
  },
};

/* -------------------------------------------------------------------------- */
/*                                 ICON_PAGES                                 */
/* -------------------------------------------------------------------------- */

const ICON_PAGES = {
  /**
   * TODO: สามารถใช้ Svg ได้ Exmple : TODO: <Svg.name />
   */
  HEADER_REPORT: null,
  CONTENT_REPORT: null,
  FILE_EXPORT: {
    [KEY_ACTIVE_TAB.UPDATE_PERSONAL]: null,
  },
  TAB: {
    [KEY_ACTIVE_TAB.UPDATE_PERSONAL]: null,
  },
};

/* -------------------------------------------------------------------------- */
/*                                 FORM_PAGES                                 */
/* -------------------------------------------------------------------------- */

const FORM_PAGES = {
  BREADCRUMB: (props) => BREADCRUMB_PERSONAL({ ...props }),
  TABS: (props) => TABS_PAGES({ ...props }),
  TABLE: (props) => TABLE_PAGES({ ...props }),
  TABLE_VIEW: () => TABLE_PAGES_VIEW(),
  SEARCH: (props) => SEARCH_PAGES({ ...props }),
  EXPORT_EXCEL: (props) => EXPORT_PAGES({ ...props }),
};

/* -------------------------------------------------------------------------- */
/*                                 TABS_PAGES                                 */
/* -------------------------------------------------------------------------- */

const TABS_PAGES = (props) => {
  const { renderTable } = props;
  /**
   * TODO: สามารถแบ่งตาม activeTab , location ได้ โดยใช้ switch case
   */
  return [
    {
      key: 1,
      tab: TEXT_DASHBOARD_PERSONLA.TAB[KEY_ACTIVE_TAB.UPDATE_PERSONAL],
      icon: "",
      content: renderTable,
      required: false,
    },
  ];
};

/* -------------------------------------------------------------------------- */
/*                                 TABLE_PAGES                                */
/* -------------------------------------------------------------------------- */

const TABLE_PAGES = (props) => {
  const searchInput = createRef();
  const { actionButton } = props;

  /**
   * TODO: สามารถแบ่งตาม activeTab , location ได้ โดยใช้ switch case
   * TODO: Filter ตาม Text ใช้ columnSearchProps(filedname,searchInput)
   * TODO: Filter ตาม Number ใช้ columnSort(filedname)
   * TODO: Filter ตาม Date ใช้ columnSortDate(filedname)
   **/

  return [
    {
      dataIndex: FIELD_PAGES.EMPLOYEE_ID,
      title: LABEL_PAGES.EMPLOYEE_ID,
      width: 150,
      fixed: "",
      align: "left",
      ...columnSearchProps(FIELD_PAGES.EMPLOYEE_ID, searchInput),
    },
    {
      dataIndex: FIELD_PAGES.USERNAME,
      title: LABEL_PAGES.USERNAME,
      width: 100,
      fixed: "",
      align: "left",
      ...columnSearchProps(FIELD_PAGES.USERNAME, searchInput),
    },
    {
      dataIndex: FIELD_PAGES.TITLE,
      title: LABEL_PAGES.TITLE,
      width: 100,
      fixed: "",
      align: "left",
      ...columnSearchProps(FIELD_PAGES.TITLE, searchInput),
    },
    {
      dataIndex: FIELD_PAGES.FULLNAME,
      title: LABEL_PAGES.FULLNAME,
      width: 100,
      fixed: "",
      align: "left",
      ...columnSearchProps(FIELD_PAGES.FULLNAME, searchInput),
    },
    {
      dataIndex: FIELD_PAGES.NICKNAME,
      title: LABEL_PAGES.NICKNAME,
      width: 100,
      fixed: "",
      align: "left",
      ...columnSearchProps(FIELD_PAGES.NICKNAME, searchInput),
    },
    {
      dataIndex: FIELD_PAGES.COMPANY_UPPER,
      title: LABEL_PAGES.COMPANY_UPPER,
      width: 100,
      fixed: "",
      align: "left",
      ...columnSearchProps(FIELD_PAGES.COMPANY_UPPER, searchInput),
    },
    {
      dataIndex: FIELD_PAGES.LOCATION_NAME,
      title: LABEL_PAGES.LOCATION,
      width: 100,
      fixed: "",
      align: "left",
      ...columnSearchProps(FIELD_PAGES.LOCATION_NAME, searchInput),
    },
    {
      dataIndex: FIELD_PAGES.DEPARTMENT_NAME,
      title: LABEL_PAGES.DEPARTMENT_NAME,
      width: 100,
      fixed: "",
      align: "left",
      ...columnSearchProps(FIELD_PAGES.DEPARTMENT_NAME, searchInput),
    },
    {
      dataIndex: FIELD_PAGES.SUB_SEPARTMENT_NAME,
      title: LABEL_PAGES.SUB_SEPARTMENT_NAME,
      width: 100,
      fixed: "",
      align: "left",
      ...columnSearchProps(FIELD_PAGES.SUB_SEPARTMENT_NAME, searchInput),
    },
    {
      dataIndex: FIELD_PAGES.POSITION_NAME,
      title: LABEL_PAGES.POSITION_NAME,
      width: 100,
      fixed: "",
      align: "left",
      ...columnSearchProps(FIELD_PAGES.POSITION_NAME, searchInput),
    },
    // {
    // 	dataIndex: FIELD_PAGES.LEVEL_WORKER,
    // 	title: LABEL_PAGES.LEVEL_WORKER,
    // 	width: 100,
    // 	fixed: '',
    // 	align: 'left',
    // 	...columnSearchProps(FIELD_PAGES.LEVEL_WORKER, searchInput)
    // },

    {
      dataIndex: FIELD_PAGES.FLOW_HRM_NAME,
      title: LABEL_PAGES.FLOW_HRM_NAME,
      width: 120,
      fixed: "",
      align: "left",
      ...columnSearchProps(FIELD_PAGES.FLOW_HRM_NAME, searchInput),
    },
    {
      dataIndex: FIELD_PAGES.START_DATE_JOB,
      title: LABEL_PAGES.START_DATE_JOB,
      width: 120,
      fixed: "",
      align: "left",
      ...columnSortDate(FIELD_PAGES.START_DATE_JOB),
    },
    {
      dataIndex: FIELD_PAGES.PROBATION_DATE_END,
      title: LABEL_PAGES.PROBATION_DATE_END,
      width: 120,
      fixed: "",
      align: "left",
      ...columnSortDate(FIELD_PAGES.PROBATION_DATE_END),
    },
    {
      dataIndex: FIELD_PAGES.END_DATE_JOB,
      //title: LABEL_PAGES.END_DATE_JOB,
      title: "วันที่พ้นสภาพการทำงาน",
      width: 120,
      fixed: "",
      align: "left",
      ...columnSortDate(FIELD_PAGES.END_DATE_JOB),
    },
    {
      dataIndex: FIELD_PAGES.CURRENT_STATUS,
      title: LABEL_PAGES.CURRENT_STATUS,
      width: 120,
      fixed: "",
      align: "left",
    },
    {
      dataIndex: FIELD_PAGES.STATUS_WORKER,
      title: LABEL_PAGES.STATUS_WORKER,
      width: 100,
      fixed: "",
      align: "left",
      ...columnSearchProps(FIELD_PAGES.STATUS_WORKER, searchInput),
    },
    {
      dataIndex: FIELD_PAGES.SHIRT_SIZE, // Size
      title: LABEL_PAGES.SHIRT_SIZE,
      width: 100,
      fixed: "",
      align: "left",
      ...columnSearchProps(FIELD_PAGES.SHIRT_SIZE, searchInput),
    },
    {
      dataIndex: FIELD_PAGES.PAYROLL_GROUP_DES, // Size
      title: LABEL_PAGES.PAYROLL_GROUP_DES,
      width: 100,
      fixed: "",
      align: "left",
      ...columnSearchProps(FIELD_PAGES.PAYROLL_GROUP_DES, searchInput),
    },
    {
      dataIndex: FIELD_PAGES.CODE_FINGER, // Size
      title: LABEL_PAGES.CODE_FINGER,
      width: 100,
      fixed: "",
      align: "left",
      ...columnSearchProps(FIELD_PAGES.CODE_FINGER, searchInput),
    },
    {
      dataIndex: FIELD_PAGES.PERSONAL_ID, // Size
      title: LABEL_PAGES.PERSONAL_ID,
      width: 100,
      fixed: "",
      align: "left",
      ...columnSearchProps(FIELD_PAGES.PERSONAL_ID, searchInput),
    },
    {
      dataIndex: "",
      title: "",
      width: 100,
      fixed: "right",
      align: "center",
      render: actionButton,
    },
  ];
};

const TABLE_PAGES_VIEW = () => {
  const searchInput = createRef();

  return [
    {
      dataIndex: FIELD_PAGES.EMPLOYEE_ID,
      title: LABEL_PAGES.EMPLOYEE_ID,
      width: 150,
      fixed: "",
      align: "left",
      ...columnSearchProps(FIELD_PAGES.EMPLOYEE_ID, searchInput),
    },
    {
      dataIndex: FIELD_PAGES.USERNAME,
      title: LABEL_PAGES.USERNAME,
      width: 100,
      fixed: "",
      align: "left",
      ...columnSearchProps(FIELD_PAGES.USERNAME, searchInput),
    },
    {
      dataIndex: FIELD_PAGES.TITLE,
      title: LABEL_PAGES.TITLE,
      width: 100,
      fixed: "",
      align: "left",
      ...columnSearchProps(FIELD_PAGES.TITLE, searchInput),
    },
    {
      dataIndex: FIELD_PAGES.FULLNAME,
      title: LABEL_PAGES.FULLNAME,
      width: 200,
      fixed: "",
      align: "left",
      ...columnSearchProps(FIELD_PAGES.FULLNAME, searchInput),
    },
    {
      dataIndex: FIELD_PAGES.NICKNAME,
      title: LABEL_PAGES.NICKNAME,
      width: 100,
      fixed: "",
      align: "left",
      ...columnSearchProps(FIELD_PAGES.NICKNAME, searchInput),
    },
    {
      dataIndex: FIELD_PAGES.COMPANY_UPPER,
      title: LABEL_PAGES.COMPANY_UPPER,
      width: 100,
      fixed: "",
      align: "left",
      ...columnSearchProps(FIELD_PAGES.COMPANY_UPPER, searchInput),
    },
    {
      dataIndex: FIELD_PAGES.LOCATION_NAME,
      title: LABEL_PAGES.LOCATION,
      width: 100,
      fixed: "",
      align: "left",
      ...columnSearchProps(FIELD_PAGES.LOCATION_NAME, searchInput),
    },
    {
      dataIndex: FIELD_PAGES.DEPARTMENT_NAME,
      title: LABEL_PAGES.DEPARTMENT_NAME,
      width: 250,
      fixed: "",
      align: "left",
      ...columnSearchProps(FIELD_PAGES.DEPARTMENT_NAME, searchInput),
    },
    {
      dataIndex: FIELD_PAGES.SUB_SEPARTMENT_NAME,
      title: LABEL_PAGES.SUB_SEPARTMENT_NAME,
      width: 100,
      fixed: "",
      align: "left",
      ...columnSearchProps(FIELD_PAGES.SUB_SEPARTMENT_NAME, searchInput),
    },
    {
      dataIndex: FIELD_PAGES.POSITION_NAME,
      title: LABEL_PAGES.POSITION_NAME,
      width: 100,
      fixed: "",
      align: "left",
      ...columnSearchProps(FIELD_PAGES.POSITION_NAME, searchInput),
    },
    {
      dataIndex: FIELD_PAGES.FLOW_HRM_NAME,
      title: LABEL_PAGES.FLOW_HRM_NAME,
      width: 250,
      fixed: "",
      align: "left",
      ...columnSearchProps(FIELD_PAGES.FLOW_HRM_NAME, searchInput),
    },
    {
      dataIndex: FIELD_PAGES.START_DATE_JOB,
      title: LABEL_PAGES.START_DATE_JOB,
      width: 120,
      fixed: "",
      align: "left",
      ...columnSortDate(FIELD_PAGES.START_DATE_JOB),
    },
    {
      dataIndex: FIELD_PAGES.PROBATION_DATE_END,
      title: LABEL_PAGES.PROBATION_DATE_END,
      width: 150,
      fixed: "",
      align: "left",
      ...columnSortDate(FIELD_PAGES.PROBATION_DATE_END),
    },
    {
      dataIndex: FIELD_PAGES.END_DATE_JOB,
      //title: LABEL_PAGES.END_DATE_JOB,
      title: "วันที่พ้นสภาพการทำงาน",
      width: 150,
      fixed: "",
      align: "left",
      ...columnSortDate(FIELD_PAGES.END_DATE_JOB),
    },
    {
      dataIndex: FIELD_PAGES.CURRENT_STATUS,
      title: LABEL_PAGES.CURRENT_STATUS,
      width: 120,
      fixed: "",
      align: "left",
    },
    {
      dataIndex: FIELD_PAGES.STATUS_WORKER,
      title: LABEL_PAGES.STATUS_WORKER,
      width: 120,
      fixed: "",
      align: "left",
      ...columnSearchProps(FIELD_PAGES.STATUS_WORKER, searchInput),
    },
    {
      dataIndex: FIELD_PAGES.SHIRT_SIZE, // Size
      title: LABEL_PAGES.SHIRT_SIZE,
      width: 100,
      fixed: "",
      align: "left",
      ...columnSearchProps(FIELD_PAGES.SHIRT_SIZE, searchInput),
    },
    {
      dataIndex: FIELD_PAGES.BIRTH_DAY, // Size
      title: LABEL_PAGES.BIRTH_DAY,
      width: 100,
      fixed: "",
      align: "left",
      ...columnSearchProps(FIELD_PAGES.BIRTH_DAY, searchInput),
    },
  ];
};

/* -------------------------------------------------------------------------- */
/*                                SEARCH_PAGES                                */
/* -------------------------------------------------------------------------- */
const isRequired = (field, errorField) => {
  return errorField.includes(field);
};

const SEARCH_PAGES = (props) => {
  const { options, errorField } = props;
  /**
   * TODO: สามารถแบ่งตาม activeTab , location ได้ โดยใช้ switch case
   */

  return [
    {
      xs: 24,
      sm: 12,
      md: 4,
      lg: 4,
      itemprops: {
        name: FIELD_PAGES.COMPANY,
        label: LABEL_PAGES.COMPANY,
        rules: [{ required: false, message: MESSAGE_ERROR.SELECT }],
      },
      inputprops: {
        type: KEY_TYPE.SELECT,
        disabled: false,
        placeholder: "",
        options: options[KEY_OPTION.CENTER.OPTION_COMPANY],
      },
    },
    {
      xs: 24,
      sm: 12,
      md: 4,
      lg: 4,
      itemprops: {
        name: FIELD_PAGES.LOCATION,
        label: LABEL_PAGES.LOCATION,
        rules: [{ required: false, message: MESSAGE_ERROR.SELECT }],
      },
      inputprops: {
        type: KEY_TYPE.SELECT,
        disabled: false,
        placeholder: "",
        options: options[KEY_OPTION.CENTER.OPTION_LOCATION],
      },
    },
    {
      xs: 24,
      sm: 12,
      md: 4,
      lg: 4,
      itemprops: {
        name: FIELD_PAGES.DEPARTMENT_NO,
        label: LABEL_PAGES.DEPARTMENT_NO,
        rules: [{ required: false, message: MESSAGE_ERROR.SELECT }],
      },
      inputprops: {
        type: KEY_TYPE.SELECT,
        disabled: false,
        placeholder: "",
        options: options[KEY_OPTION.CENTER.OPTION_DEPARTMENT_ALL],
      },
    },
    {
      xs: 24,
      sm: 12,
      md: 4,
      lg: 4,
      itemprops: {
        name: FIELD_PAGES.SUB_DEPARTMENT_NO,
        label: LABEL_PAGES.SUB_DEPARTMENT_NO,
        rules: [{ required: false, message: MESSAGE_ERROR.SELECT }],
      },
      inputprops: {
        type: KEY_TYPE.SELECT,
        disabled: false,
        placeholder: "",
        options: options[KEY_OPTION.CENTER.OPTION_SUB_DEPARTMENT],
      },
    },
    {
      xs: 24,
      sm: 12,
      md: 4,
      lg: 4,
      itemprops: {
        name: FIELD_PAGES.STATUS_WORKER,
        label: LABEL_PAGES.STATUS_WORKER,
        rules: [{ required: false, message: MESSAGE_ERROR.SELECT }],
      },
      inputprops: {
        type: KEY_TYPE.SELECT,
        disabled: false,
        placeholder: "",
        options: options[KEY_OPTION.CENTER.OPTION_STATUS_WORKERS],
      },
    },
    {
      xs: 24,
      sm: 12,
      md: 4,
      lg: 4,
      itemprops: {
        name: FIELD_PAGES.USER_ID,
        label: LABEL_PAGES.USER_ID,
        // label: LABEL_PAGES.USER_ID + " (พนักงานปัจจุบัน)",
        rules: [{ required: false, message: MESSAGE_ERROR.INPUT }],
      },
      inputprops: {
        type: KEY_TYPE.SELECT,
        disabled: false,
        placeholder: "",
        options: options[KEY_OPTION.CENTER.OPTION_SELECT_EMPLOY_HISTORY],
      },
    },
    {
      xs: 24,
      sm: 12,
      md: 4,
      lg: 4,
      itemprops: {
        name: FIELD_PAGES.START_DATE,
        label: LABEL_PAGES.START_DATE,
        rules: [{ required: false, message: MESSAGE_ERROR.DATE_PICKER }],
      },
      inputprops: {
        type: KEY_TYPE.DATE_PICKER,
        disabled: false,
        placeholder: "",
      },
    },
    {
      xs: 24,
      sm: 12,
      md: 4,
      lg: 4,
      itemprops: {
        name: FIELD_PAGES.END_DATE,
        label: LABEL_PAGES.END_DATE,
        rules: [
          {
            required: isRequired(FIELD_PAGES.END_DATE, errorField),
            message: "",
          },
        ],
      },
      inputprops: {
        type: KEY_TYPE.DATE_PICKER,
        disabled: false,
        placeholder: "",
      },
    },
    // {
    //     xs: 24, sm: 12, md: 6, lg: 6,
    //     itemprops: {
    //         name: FIELD_PAGES.USER_ID,
    //         label: LABEL_PAGES.USER_ID,
    //         rules: [{ required: false, message: MESSAGE_ERROR.INPUT }]
    //     },
    //     inputprops: {
    //         type: KEY_TYPE.INPUT,
    //         disabled: false,
    //         maxLength: 20,
    //         placeholder: '',
    //         // options: options[KEY_OPTION.CENTER.OPTION_LOCATION]
    //     }
    // },
    {
      xs: 24,
      sm: 12,
      md: 4,
      lg: 4,
      itemprops: {
        name: FIELD_PAGES.END_DATE_JOB_FROM,
        label: LABEL_PAGES.END_DATE_JOB_FROM,
        rules: [{ required: false, message: MESSAGE_ERROR.DATE_PICKER }],
      },
      inputprops: {
        type: KEY_TYPE.DATE_PICKER,
        disabled: false,
        placeholder: "",
      },
    },
    {
      xs: 24,
      sm: 12,
      md: 4,
      lg: 4,
      itemprops: {
        name: FIELD_PAGES.END_DATE_JOB_TO,
        label: LABEL_PAGES.END_DATE_JOB_TO,
        rules: [
          {
            required: isRequired(FIELD_PAGES.END_DATE_JOB_TO, errorField),
            message: "",
          },
        ],
      },
      inputprops: {
        type: KEY_TYPE.DATE_PICKER,
        disabled: false,
        placeholder: "",
      },
    },
    {
      xs: 24,
      sm: 12,
      md: 4,
      lg: 4,
      style: { display: "flex", alignItems: "center", paddingTop: 30 },
      itemprops: {
        name: FIELD_PAGES.IS_BLOCK,
        rules: [{ required: false, message: MESSAGE_ERROR.CHECKBOX }],
      },
      inputprops: {
        type: KEY_TYPE.CHECKBOX,
        label: LABEL_PAGES.IS_BLOCK,
        disabled: false,
        placeholder: "",
      },
    },
  ];
};

/* -------------------------------------------------------------------------- */
/*                                EXPORT_PAGES                                */
/* -------------------------------------------------------------------------- */

export const EXPORT_PAGES = ({ activeTab }) => {
  return [
    {
      id: 1,
      title: LABEL_PAGES.EMPLOYEE_ID,
      field: FIELD_PAGES.EMPLOYEE_ID,
    },
    {
      id: 2,
      title: LABEL_PAGES.USERNAME,
      field: FIELD_PAGES.USERNAME,
    },
    {
      id: 3,
      title: LABEL_PAGES.TITLE,
      field: FIELD_PAGES.TITLE,
    },
    {
      id: 4,
      title: LABEL_PAGES.FULLNAME,
      field: FIELD_PAGES.FULLNAME,
    },
    {
      id: 5,
      title: LABEL_PAGES.FULLNAME,
      field: FIELD_PAGES.FULLNAME,
    },
    {
      id: 6,
      title: LABEL_PAGES.NICKNAME,
      field: FIELD_PAGES.NICKNAME,
    },
    {
      id: 7,
      title: LABEL_PAGES.PERSONAL_ID,
      field: FIELD_PAGES.PERSONAL_ID,
    },
    {
      id: 8,
      title: LABEL_PAGES.COMPANY_UPPER,
      field: FIELD_PAGES.COMPANY_UPPER,
    },
    {
      id: 9,
      title: LABEL_PAGES.LOCATION,
      field: FIELD_PAGES.LOCATION_NAME,
    },
    {
      id: 10,
      title: LABEL_PAGES.DEPARTMENT_NAME,
      field: FIELD_PAGES.DEPARTMENT_NAME,
    },
    {
      id: 11,
      title: LABEL_PAGES.SUB_SEPARTMENT_NAME,
      field: FIELD_PAGES.SUB_SEPARTMENT_NAME,
    },
    {
      id: 12,
      title: LABEL_PAGES.CURRENT_STATUS,
      field: FIELD_PAGES.CURRENT_STATUS,
    },
    {
      id: 13,
      title: LABEL_PAGES.STATUS_WORKER,
      field: FIELD_PAGES.STATUS_WORKER,
    },
    {
      id: 14,
      title: LABEL_PAGES.POSITION_NAME,
      field: FIELD_PAGES.POSITION_NAME,
    },
    // {
    // id: 13,
    // title: LABEL_PAGES.LEVEL_WORKER,
    // field: FIELD_PAGES.LEVEL_WORKER
    // },
    {
      id: 15,
      title: LABEL_PAGES.FLOW_HRM_NAME,
      field: FIELD_PAGES.FLOW_HRM_NAME,
    },
    {
      id: 16,
      title: LABEL_PAGES.START_DATE_JOB,
      field: FIELD_PAGES.START_DATE_JOB,
    },
    {
      id: 17,
      title: LABEL_PAGES.PROBATION_DATE_END,
      field: FIELD_PAGES.PROBATION_DATE_END,
    },
    {
      id: 18,
      title: LABEL_PAGES.END_DATE_JOB,
      field: FIELD_PAGES.END_DATE_JOB,
    },
    {
      id: 19,
      title: LABEL_PAGES.PROBATION_STATUS_DES,
      field: FIELD_PAGES.PROBATION_STATUS_DES,
    },
    {
      id: 20,
      title: LABEL_PAGES.SHIRT_SIZE,
      field: FIELD_PAGES.SHIRT_SIZE,
    },
    {
      id: 21,
      title: LABEL_PAGES.PAYROLL_GROUP_DES,
      field: FIELD_PAGES.PAYROLL_GROUP_DES,
    },
    {
      id: 22,
      title: LABEL_PAGES.CODE_FINGER,
      field: FIELD_PAGES.CODE_FINGER,
    },
  ];
};

/* -------------------------------------------------------------------------- */
/*                                   EXPORT                                   */
/* -------------------------------------------------------------------------- */

export const TEXT_DASHBOARD_PERSONLA = TEXT_PAGES;
export const ICON_DASHBOARD_PERSONLA = ICON_PAGES;
export const FORM_DASHBOARD_PERSONLA = FORM_PAGES;
export const FIELD_DB_PERSONAL = FIELD_PAGES;
export const LABEL_DB_PERSONAL = LABEL_PAGES;
