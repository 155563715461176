import React from "react";
import FormSearchOJT from "./conponents/forms/FormSearchOJT";
import FormAddOJTCourse from "./conponents/forms/FormAddOJTCourse";
import { Tabs } from "antd";

export const ManageOJT = () => {
  const { TabPane } = Tabs;
  return (
    <>
      <Tabs defaultActiveKey="1">
        <TabPane tab="จัดการหัวข้อหลักสูตร OJT (โรงงาน)" key="1">
          <FormSearchOJT />
        </TabPane>
        <TabPane tab="เพิ่มหลักสูตรผูกตำแหน่ง" key="2">
          <FormAddOJTCourse />
        </TabPane>
      </Tabs>
    </>
  );
};

export default ManageOJT;
