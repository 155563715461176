import React from 'react'
import { colors } from '../../../themes/colors'

export const BaseText = ({ icon, color, size, label, error, success }) => {
    return (
        <div style={{
            fontSize: (size) ? size : 14,
            color: (color) ? color : ((error) ? colors.error : (success) ? colors.success : colors.baseText)
        }}>
            {(icon) && <span style={{ paddingRight: 10 }}>  {icon}  </span>}
            <span> {label} </span>
        </div>
    )
}