import React, { Component } from "react";
import { path } from "ramda";
import "bootstrap/dist/css/bootstrap.css";
import "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css";
import { Col, Row, Form } from "reactstrap";
import { SelectInput, DateInput, TextInput } from "../constants/inputs";
import { ButtonTheme } from "../constants/buttons/ButtonTheme";
import BenefitReportNew from "../components/benefits/BenefitReportNew";
import * as Layouts from "../constants/layouts";
import { INITIAL_FILTER_PROBATION } from "../components/probation/initialProbation";
import { APIProbation, APIBenefit, APIDashboardBenefit, GET_SELECT_OPTION_ALL, GET } from "../service";
import { SetDateAPI } from "../common/setDate";
import { API_URL } from '../components/connectAPI'
import { Spin } from "antd";
import { filterDashboardReportBenefit } from './../moduleReport/constantsReport/searchform';
import { optionProbationStatus, optionLocation, optionStatusWorkerCode } from '../common/enum'
import { RenderForm } from './../constants/inputs/RenderForm';

class ReportBenefit extends Component {

	constructor(props) {
		super(props);
		this.state = {
			loading: false,
			userLogin: this.props.userLogin,
			initialFilter: INITIAL_FILTER_PROBATION,
			dataSource: [],
			SegmentTxt: "",
			txtUserSeaching: '',
			locationID: "",
			statusWorkerCode: "",
			statusProbationCode: '',
		};
	}

	componentDidMount() {
		this.getSelectOptionAll()
	}

	getSelectOptionAll = async () => {
		try {
			let res = await GET(GET_SELECT_OPTION_ALL())
			const { result, success } = res
			if (success) {
				let departmentLists = [];
				result['department'].map(el => {
					departmentLists.push({ label: el.depNameTH, value: el.depNo })
				})
				let optionSegment = [{ value: "", label: "----- ทั้งหมด -----" }, ...departmentLists]
				this.setState({ optionSegment })
			}
		} catch (err) {
			console.log('err', err.message)
		}
	}

	//----- Start :: Function -----//

	handleFilter = (fieldname, value) => {
		let initialFilter = { ...this.state.initialFilter }
		let fieldNameSet = ''
		switch (fieldname) {
			case "depNo":
				initialFilter = { ...initialFilter, [fieldname]: value };
				fieldNameSet = 'SegmentTxt'
				break;
			case "userSeaching":
				// console.log(value)
				initialFilter = { ...initialFilter, [fieldname]: value };
				fieldNameSet = 'txtUserSeaching'
				break;
			case "location":
				initialFilter = { ...initialFilter, [fieldname]: value };
				fieldNameSet = 'locationID'
				break;

			case "statusWorker":
				initialFilter = { ...initialFilter, [fieldname]: value };
				fieldNameSet = 'statusWorkerCode'
				break;

			case "statusProbation":
				initialFilter = { ...initialFilter, [fieldname]: value };
				fieldNameSet = 'statusProbationCode'
				break;

			default:
				initialFilter = { ...initialFilter, [fieldname]: value };
				break;
		}
		this.setState({ initialFilter, [fieldNameSet]: value })
	};

	onSearch = async () => {
		try {
			const { userLogin, locationID, statusWorkerCode, SegmentTxt, txtUserSeaching, initialFilter } = this.state;
			let payload = {
				user_login: userLogin,
				locationID: locationID,
				statusWorker: statusWorkerCode,
				SegmentTxt: SegmentTxt,
				txtUserSeaching: txtUserSeaching,
				StartDateJob: SetDateAPI(initialFilter.StartDateJob),
				toDateJob: SetDateAPI(initialFilter.toDateJob),
				premission: this.props.premission
			}
			this.setState({ loading: true })

			await APIDashboardBenefit(payload, (res) => {
				if (res) {
					this.setState({ dataSource: res })
				}
				this.setState({ loading: false })
				this.forceUpdate();
			})

		} catch (error) {
			console.log({ error })
			this.setState({ loading: false })
		} finally {
			this.setState({ loading: false })

		}


	};
	async onSearch2({ userLogin }) {
		try {
			const { initialFilter, locationID, statusWorkerCode, SegmentTxt, txtUserSeaching } = this.state;
			let payload = {
				user_login: userLogin,
				locationID: locationID,
				statusWorker: statusWorkerCode,
				SegmentTxt: SegmentTxt,
				txtUserSeaching: txtUserSeaching,
				StartDateJob: SetDateAPI(initialFilter.StartDateJob),
				toDateJob: SetDateAPI(initialFilter.toDateJob),
				// premission: this.props.premission
			}
			// await this.setState({ loading: true })
			await APIDashboardBenefit(payload, (res) => {
				if (res) {
					this.setState({ dataSource: res })
				}
				// this.setState({ loading: false })
				// this.forceUpdate();
			})
		} catch (error) {
			console.log({ error })
			// await this.setState({ loading: false })
		}
	};

	//----- End :: Function -----//


	handleLocation = async ({ pathname, state, status }) => {
		this.setState({ done: undefined });
		const { router } = this.props;
		let initialData = null;

		let payload = { docNo: state.DocNo };
		APIProbation(payload).then(res => {
			initialData = res.result

			router.push({ pathname, state: { ...initialData } });
			this.setState({ done: true });
		});

	};

	render() {
		const { loading } = this.state
		return (
			<Spin spinning={loading} tip="กำลัังโหลด">
				<Layouts.MainTheme
					header={"รายการค้างสวัสดิการพนักงานใหม่"}
					component={this.mapArray()}
				></Layouts.MainTheme>
				<BenefitReportNew
					userLogin={this.state.userLogin}
					dataSource={this.state.dataSource}
					fncSearch={this.onSearch2}
					handleLocation={this.handleLocation}
				/>
				{/*  </TabPane>
        </Tabs> */}
			</Spin>
		);
	}

	mapArray = () => {
		const { optionSegment, txtUserSeaching, txtOption, initialFilter } = this.state
		return (
			<Form>
				{filterDashboardReportBenefit({
					handlechange: this.handleFilter,
					options: {
						optionLocation,
						optionSegment,
						optionProbationStatus,
						optionStatusWorkerCode
					},
					txtOption: {
						txtUserSeaching,
						txtOption,
						// txtOptionStatusProbation,
					},
					initialFilter,
					onClick: this.onSearch
				}).map((row, i) => (
					<Row key={i}>
						{row.map((each, i) => (
							<Col key={i} md={each.size} className={each.className}>
								{/* {this.renderContentInput(each)} */}
								{RenderForm(each)}
							</Col>
						))}
					</Row>
				))}
			</Form>
		);
	};
}

export default ReportBenefit;
