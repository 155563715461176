import React, { Component } from "react";
import "antd/dist/antd.css";
import { Table, Popconfirm, Modal } from "antd";
import "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css";
import { CSVLink } from "react-csv";
import { MdFileDownload } from "react-icons/md";
import { FiArrowDownCircle, FiCheckCircle } from "react-icons/fi";
import Moment from "react-moment";
import { API_URL } from "../connectAPI";
import { APIConfirmProbation } from "../../service";
import { Col, Button } from "reactstrap";
import checkDateColorCode from "../../extendFunction/checkDateColorCode";
import { SetDate } from "../../common/setDate";
import { getColumnSearchProps } from "../../constants/functions/getColumnsSearch";
import { RenderStatusMainCode } from "../../common/setStatus";
class TrackProbationReport extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  openViewPro = (_id) => {
    this.props.handleLocation({
      pathname: `${"/ViewProbation/"}`,
      state: { DocNo: _id },
      status: "view",
    });
  };

  insertDate = (_getType, _data) => {
    const userLogin = this.props.userLogin;
    const link = API_URL + "/HRM_API/insert_timeStampBenefits";

    var data = {
      StaffCode: _data.StaffCode,
      typeInsurance: _getType,
      typeBenefit: "in",
      user_login: userLogin,
    };

    fetch(link, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((Response) => Response.json())
      .then((results) => {
        if (results.status_api === 1) {
          Modal.success({ title: "บันทึกเรียบร้อยแล้ว" });
          this.props.fncSearch({ ...this.props });
        } else {
          Modal.error({ title: "Insert Error!!" });
        }
      });
  };

  updateReceivedDoc = async (_doc) => {
    const userLogin = this.props.userLogin;
    let pathURL = `${"DocNo="}${_doc}`;
    let data = {
      DocNo: _doc,
      user_login: userLogin,
    };
    await APIConfirmProbation(pathURL, data).then((res) => {
      Modal.success({
        title: `${"ยืนยันการส่งเอกสารสำเร็จ เลขที่เอกสาร "}${data.DocNo || ""}`,
      });
      this.props.fncSearch({ ...this.props });
    });
  };

  render() {
    const columnsBenefit = () => {
      const searchInput = React.createRef();
      return [
        {
          title: "รหัสพนักงาน",
          dataIndex: "StaffCode",
          key: "StaffCode",
          fixed: "left",
          ...getColumnSearchProps("StaffCode", searchInput),
        },
        {
          title: "ชื่อพนักงาน",
          dataIndex: "StaffName",
          key: "StaffName",
          fixed: "left",
          ...getColumnSearchProps("StaffName", searchInput),
        },
        {
          dataIndex: "NickName",
          title: "ชื่อเล่น",
          key: "NickName",
          width: 100,
          fixed: "left",
          ...getColumnSearchProps("NickName", searchInput),
        },
        {
          title: "ชื่อผู้บังคับบัญชา",
          dataIndex: "BossName",
          key: "BossName",
          fixed: "left",
          ...getColumnSearchProps("BossName", searchInput),
        },
        {
          label: "กลุ่ม",
          title: "กลุ่ม",
          dataIndex: "statusWorker",
          key: "statusWorker",
          ...getColumnSearchProps("statusWorker", searchInput),
        },
        {
          title: "แผนก / กลุ่ม",
          dataIndex: "Department_Name",
          key: "Department_Name",
          ...getColumnSearchProps("Department_Name", searchInput),
        },
        {
          title: "แผนกย่อย",
          dataIndex: "subDepName",
          key: "subDepName",
          ...getColumnSearchProps("subDepName", searchInput),
        },
        {
          dataIndex: "statusMainCode",
          title: "สถานะ",
          key: "statusMainCode",
          width: 200,
          render: (text) => {
            return RenderStatusMainCode(text);
          },

          //   render: (text, row) => {
          //     let iconA = ''
          //     if (row.statusMainCode === "0") {
          //       iconA = <div> รอประเมิน </div>   //"#B03A2E"
          //     } else if (row.statusMainCode === "1") {
          //       iconA = <div>ช่วงทดลองงาน  </div>    //"#E67E22"
          //     } else if (row.statusMainCode === "2") {
          //       iconA = <div>รอ HR รับ </div>  //"#1E8449"
          //     } else if (row.statusMainCode === "3") {
          //       iconA = <div>จบประเมิน</div>  //"#1E8449"
          //     }
          //     return (
          //       <div>
          //         {iconA}
          //       </div>
          //     )
          //   }
        },
        {
          title: "วันที่เริ่มงาน",
          dataIndex: "StartDateJob",
          key: "StartDateJob",
          render: (text) => SetDate(text),
        },
        {
          title: "วันที่เริ่มรอบสัญญาจ้าง",
          dataIndex: "startProbationDate",
          align: "left",
          key: "startProbationDate",
          render: (text, record) => SetDate(text),
          // ...getColumnSearchProps('StartDateJob', searchInput),
        },
        {
          title: "ต่อสัญญาครั้งที่",
          dataIndex: "roundContract",
          key: "roundContract",
          align: "right",
          ...getColumnSearchProps("roundContract", searchInput),
        },
        {
          title: "วันที่คาดว่าผ่านทดลองงาน",
          dataIndex: "expProDate",
          key: "expProDate",
          render: (text, record) => SetDate(text),
        },
        {
          dataIndex: "",
          title: "เอกสารทดลองงาน",
          key: "",
          render: (record) => {
            let showBtn = "-";
            // console.log('record', record)
            if (record.DocNo) {
              showBtn = (
                <Button
                  color="info"
                  onClick={() => this.openViewPro(record.DocNo)}
                  id={record.DocNo}
                >
                  {" "}
                  View{" "}
                </Button>
              );
            }
            return (
              <div>
                <Col>{showBtn}</Col>
              </div>
            );
          },
        },
        {
          title: "รอบปัจจุบัน",
          dataIndex: "RoundNow",
          align: "left",
          render: (text, record) => (text ? text : "-"),
        },
        {
          title: "รอบที่ยืนยันแล้ว",
          dataIndex: "status_confirm",
          align: "left",
          render: (text, record) => (text ? text : "-"),
        },
        {
          title: "รอบที่ 1",
          dataIndex: "RoundDate_1",
          align: "left",
          // render: (text, record) => {
          //   return <div className={(record.RoundNow > 1) ? 'date-green' : checkDateColorCode.colorClassName(SetDate(text))} >
          //     {SetDate(text)}
          //   </div>
          // }
          render: (text, record) => {
            let RoundDate_1 = "";
            if (record.RoundDate_1) {
              if (record.RoundNow > 1) {
                //จบการประเมินรอบที่ 1
                RoundDate_1 =  record.RoundDate_1?(
                  <Moment format="YYYY-MM-DD" className="date-green">
                    {record.RoundDate_1}
                  </Moment>
                ):'-';
              } else {
                RoundDate_1 = record.RoundDate_1?(
                  <Moment
                    format="YYYY-MM-DD"
                    className={checkDateColorCode.colorClassName(
                      record.RoundDate_1
                    )}
                  >
                    {record.RoundDate_1}
                  </Moment>
                ):'-';
              }
            }
            return <div>{RoundDate_1 || "-"}</div>;
          },
        },
        {
          title: "รอบที่ 2",
          dataIndex: "RoundDate_2",
          align: "left",
          // render: (text, record) => {
          //   return <div className={(record.RoundNow > 2 || record.statusProbationLast === '1') ? 'date-green' : checkDateColorCode.colorClassName(SetDate(text))} >
          //     {SetDate(text)}
          //   </div>
          // }
          render: (text, record) => {
            let RoundDate_2 = "";
            if (record.RoundNow) {
              if (record.RoundNow > 2 || record.statusProbationLast === "1") {
                //จบการประเมินตอนรอบ 2
                RoundDate_2 = record.RoundDate_2?(
                  <Moment format="YYYY-MM-DD" className="date-green">
                    {record.RoundDate_2}
                  </Moment>
                ):'-';;
              } else {
                RoundDate_2 = record.RoundDate_2?(
                  <Moment
                    format="YYYY-MM-DD"
                    className={checkDateColorCode.colorClassName(
                      record.RoundDate_2
                    )}
                  >
                    {record.RoundDate_2}
                  </Moment>
                ):'-';
              }
            }
            return <div>{RoundDate_2 || "-"}</div>;
          },
        },
        {
          title: "รอบที่ 3",
          dataIndex: "RoundDate_3",
          align: "left",
          // render: (text, record) => {
          //   if (record.RoundNow < 3 && record.statusProbationLast === '1') {  //ประเมินจบตั้งแต่รอบที่ 2
          //     return "-"
          //   } else if (record.RoundNow > 3 || record.statusProbationLast === '1') { //จบการประเมินตอนรอบ 3
          //     return <div className="date-green" >{SetDate(text)}</div>
          //   } else {
          //     return <div className={checkDateColorCode.colorClassName(SetDate(text))} >
          //       {SetDate(text)}
          //     </div>
          //   }
          // }
          render: (text, record) => {
            let RoundDate_3 = "";
            //console.log('RoundNow', record.RoundNow)
            //console.log('statusProbationLast', record.statusProbationLast)
            if (record.RoundNow) {
              if (record.RoundNow < 3 && record.statusProbationLast === "1") {
                //ประเมินจบตั้งแต่รอบที่ 2
                RoundDate_3 = "-";
              } else if (
                record.RoundNow > 3 ||
                record.statusProbationLast === "1"
              ) {
                //จบการประเมินตอนรอบ 3
                RoundDate_3 = record.RoundDate_3?(
                  <Moment format="YYYY-MM-DD" className="date-green">
                    {record.RoundDate_3}
                  </Moment>
                ):'-';
              } else {
                RoundDate_3 = record.RoundDate_3?(
                  <Moment
                    format="YYYY-MM-DD"
                    className={checkDateColorCode.colorClassName(
                      record.RoundDate_3
                    )}
                  >
                    {record.RoundDate_3}
                  </Moment>
                ):'-';
              }
            }
            return <div>{RoundDate_3 || "-"}</div>;
          },
        },
        {
          title: "รอบที่ 4",
          dataIndex: "RoundDate_4",
          align: "left",
          // render: (text, record) => {
          //   if (record.RoundNow < 4 && record.statusProbationLast === '1') {  //ประเมินจบตั้งแต่รอบที่ 3
          //     return "-"
          //   } else if (record.RoundNow === '4' || record.statusProbationLast === '1') { //จบการประเมินตอนรอบ 4
          //     return <div className="date-green" >{SetDate(text)}</div>
          //   } else {
          //     return <div className={checkDateColorCode.colorClassName(SetDate(text))} >
          //       {SetDate(text)}
          //     </div>
          //   }
          // }
          render: (text, record) => {
            let RoundDate_4 = "";
            //  console.log('RoundNow', record.RoundNow)
            //  console.log('statusProbationLast', record.statusProbationLast)
            if (record.RoundNow) {
              if (record.RoundNow < 4 && record.statusProbationLast === "1") {
                //ประเมินจบตั้งแต่รอบที่ 3
                RoundDate_4 = "-";
              } else if (
                record.RoundNow === "4" ||
                record.statusProbationLast === "1"
              ) {
                //จบการประเมินตอนรอบ 4
                RoundDate_4 =record.RoundDate_4?(
                  <Moment format="YYYY-MM-DD" className="date-green">
                    {record.RoundDate_4}
                  </Moment>
                ):'-';
              } else {
                // console.log('ddd')
                RoundDate_4 = record.RoundDate_4?(
                  <Moment
                    format="YYYY-MM-DD"
                    className={checkDateColorCode.colorClassName(
                      record.RoundDate_4
                    )}
                  >
                    {record.RoundDate_4}
                  </Moment>
                ):'-';
              }
            }
            return <div>{RoundDate_4 || "-"}</div>;
          },
        },
        {
          title: "วันที่ผ่านทดลองงาน",
          dataIndex: "probationEndDate",
          align: "left",
          render: (text, record) => (text ? SetDate(text) : "-"),
          // render: (text, record) => {
          //   let dateEndPro = "";
          //   if (record.probationEndDate != null) {
          //     dateEndPro = (
          //       <Moment format="YYYY-MM-DD">{record.probationEndDate}</Moment>
          //     );
          //   }
          //   return <div>{dateEndPro}</div>;
          // }
        },
        {
          title: "วันที่รับเอกสารทดลองงาน",
          key: "HR_ReceivedDate",
          dataIndex: "HR_ReceivedDate",
          align: "left",
          render: (text, record) => {
            let HR_ReceivedDate = "";
            // if (record.probationEndDate !== null) {
            if (record.statusMainCode === 2 || record.statusMainCode === "2") {
              HR_ReceivedDate = (
                <React.Fragment>
                  <Popconfirm
                    title="ยืนยันการอัพเดท?"
                    onConfirm={() => this.updateReceivedDoc(record.DocNo)}
                    onCancel={this.cancel}
                    okText="Yes"
                    cancelText="No"
                  >
                    <FiArrowDownCircle color={"#C32604"} size={"18"} />{" "}
                    ยืนยันรับเอกสาร
                    {/* <Moment format="YYYY-MM-DD" className="date-red">
                      {record.probationEndDate}
                    </Moment> */}
                  </Popconfirm>
                </React.Fragment>
              );
            } else if (record.HR_ReceivedDate != null) {
              HR_ReceivedDate = (
                <React.Fragment>
                  <FiCheckCircle color={"#028E22"} size={"18"} />
                  <div className="date-green">{SetDate(text)}</div>
                </React.Fragment>
                // <React.Fragment>
                //   <FiCheckCircle color={"#028E22"} size={"18"} />
                //   <Moment format="YYYY-MM-DD" className="date-green">
                //     {record.HR_ReceivedDate}
                //   </Moment>
                // </React.Fragment>
              );
            }
            // }

            return <div>{HR_ReceivedDate}</div>;
          },
        },
      ];
    };

    const headersBenefit = [
      { label: "รหัสพนักงาน", key: "StaffCode" },
      { label: "ชื่อพนักงาน", key: "StaffName" },
      { label: "ชื่อผู้บังคับบัญชา", key: "BossName" },
      { label: "แผนก / กลุ่ม", key: "Department_Name" },
      { label: "แผนกย่อย", key: "subDepName" },
      { label: "สถานะ", key: "statusMainCodeDes" },
      { label: "รอบปัจจุบัน", key: "RoundNow" },
      { label: "รอบที่ยืนยันแล้ว", key: "status_confirm" },
      { label: "วันที่เริ่มงาน", key: "StartDateJob" },
      { label: "วันที่คาดว่าผ่านทดลองงาน", key: "expProDate" },
      { label: "รอบที่ 1", key: "RoundDate_1" },
      { label: "รอบที่ 2", key: "RoundDate_2" },
      { label: "รอบที่ 3", key: "RoundDate_3" },
      { label: "รอบที่ 4", key: "RoundDate_4" },

      // { label: "ประกันสุขภาพ / ประกันชีวิต", key: "date_HEA" },
      // { label: "ประกันอุบัติเหตุ", key: "date_ACD" },
      // { label: "กองทุนสำรองเลี้ยงชีพ", key: "date_PVD" },
      // { label: "ประกันสังคม", key: "date_SOS" }
    ];
    return (
      <div>
        <div className="btn-right">
          <CSVLink
            headers={headersBenefit}
            data={this.props.dataSource || []}
            filename={"BenefitsReport.csv"}
            className="btn btn-export"
            target="_blank"
          >
            <MdFileDownload /> Export CSV
          </CSVLink>
        </div>
        <div>
          <Table
            columns={columnsBenefit()}
            dataSource={this.props.dataSource || []}
            scroll={{ x: 2200 }}
          />
        </div>
      </div>
    );
  }
}

export default TrackProbationReport;
