import React, { useContext, useRef, useState } from 'react'
import { Modal, Spin } from 'antd';
import { LayoutTable } from '../../moduleKPIs/common/layouts';
import ButtonSearch from '../../constants/buttons/buttonSearch';
import { TABLE_CONTRACT } from '../commonsContract/structuresContract/tableContract';
import { KEY_OPTION } from '../../constants/enums/enumCenter';
import { ContractContext } from '../servicesContract/contractProvider';
import { MasterCenterContext } from '../../service/master/masterProvider';
import { PATHNAME } from '../../constants/enums/pathname';
import { ButtonExport, FIELD_BUTTON } from '../../constants/buttons';
import { APIConfirmProbationContract } from '../servicesContract/contractConnect';
import { COLUMNS_EXPORT } from '../commonsContract/structuresContract/columnsContract';


const DashboardContract = ({ pathname, idTab }) => {
	const refExport = useRef();

	const [loading, setLoading] = useState(false);

	const { contractState, getAPI, userPermission, userLogin } = useContext(ContractContext);
	const { masterCenterState } = useContext(MasterCenterContext);

	const { dataTable: { rowKey, dataSource } } = contractState;


	async function onSearch() {
		await setLoading(true)
		await fetchAPI()
		await setLoading(false)
	}


	async function fetchTabs() {
		switch (idTab) {
			case 1:
				await getAPI({
					fieldname: KEY_OPTION.PROBATION.DASHBOARD_EMP_CONTRACT,
					payload: { status: 'pass', ...masterCenterState }
				})
				break;
			case 2:
				await getAPI({
					fieldname: KEY_OPTION.PROBATION.DASHBOARD_EMP_CONTRACT,
					payload: { status: 'notPass', ...masterCenterState }
				})
				break;
			default:
				break;
		}
	}



	async function fetchAPI() {
		switch (pathname) {
			case PATHNAME.DASHBOARD_FOLLOWUP_GURANTEE:
				await getAPI({ fieldname: KEY_OPTION.PROBATION.DASHBOARD_FOLLOWUP_GURANTEE, payload: { ...masterCenterState } })
				break;
			// case PATHNAME.DASHBOARD_PROBATION_CONTRACT:
			// 	await getAPI({ fieldname: KEY_OPTION.PROBATION.DASHBOARD_CONTRACT, payload: { ...masterCenterState } })
			// 	break;
			case PATHNAME.DASHBOARD_PROBATION_EMP_CONTRACT:
				await fetchTabs()
				break;
			case PATHNAME.DASHBOARD_PROBATION_EMP_CONTRACT_NEW:
				await getAPI({ fieldname: KEY_OPTION.PROBATION.DASHBOARD_EMP_CONTRACT_NEW, payload: { status: 'notPass', ...masterCenterState } })
				break;
			case PATHNAME.DASHBOARD_TRACK_CONTRACT:
				await getAPI({ fieldname: KEY_OPTION.PROBATION.DASHBOARD_TRACK, payload: { ...masterCenterState } })
				break;
			case PATHNAME.DASHBOARD_BENEFIT_CONTRACT:
				await getAPI({ fieldname: KEY_OPTION.PROBATION.DASHBOARD_BENEFIT, payload: { ...masterCenterState } })
				break;
			default:
				break;
		}
	}

	return (
		<div>
			<div className="layout-content-btn-search">
				<ButtonSearch
					disabled={false}
					onClick={() => onSearch()}
					style={{ padding: 10 }} />
			</div>
			<div className="layout-content-btn-export">
				<ButtonExport
					ref={refExport}
					labelButton=""
					fieldname="แบบประเมินทดลองงาน (สัญญาจ้าง)"
					columns={COLUMNS_EXPORT({
						pathname: pathname,
						permission: userPermission
					})}
					dataSource={dataSource}
					style={{ paddingRight: 10 }}
					styleButton={{}}
				/>
			</div>
			<Spin spinning={loading} tip="กำลังโหลด">
				<LayoutTable
					scroll={{ x: 3000 }}
					bordered={false}
					rowKey={rowKey}
					dataSource={dataSource}
					columns={TABLE_CONTRACT({
						pathname,
						userLogin: userLogin,
						actionButton: async ({ fieldBtn, payload }) => {
							await setLoading(true)
							await window.localStorage.setItem(KEY_OPTION.DATA.PROBATION_CONTRACT, JSON.stringify(payload))
							switch (fieldBtn) {
								case FIELD_BUTTON.INSERT_CONTRACT:
									await window.open(PATHNAME.INSERT_PROBATION_CONTRACT, '_blank')
									break;
								case FIELD_BUTTON.UPDATE_CONTRACT:
									await window.open(PATHNAME.UPDATE_PROBATION_CONTRACT, '_blank')
									break;
								case FIELD_BUTTON.CONFIRM_CONTRACT:
									//console.log({ payload })
									await APIConfirmProbationContract({
										DocNo: payload.DocNo,
										user_login: userLogin
									}, (res) => {
										//console.log({ res })
										if (res) {
											Modal.success({
												title: `ยืนยันการส่งเอกสารสำเร็จ เลขที่เอกสาร ${res.data.DocNo}`,
												onOk: () => window.location.reload()
											})
										}
									})
									break;
								case FIELD_BUTTON.INSERT_BENEFIT_CONTRACT:
									//console.log({ payload })
									// const userLogin = this.props.userLogin;
									// const link = API_URL + "/HRM_API/insert_timeStampBenefits";
									// let docSOS = ''
									// if (_getType === 'sos') {
									//   docSOS = this.state.fields[_data.StaffCode]
									// }
									// #  Benefits New  
									// var data = {
									//   StaffCode: _data.StaffCode,
									//   typeInsurance: _getType,
									//   typeBenefit: 'in',
									//   user_login: userLogin,
									//   docSOS: docSOS,
									//   refDocProbation: _data.DocNo
									// };
									// #  Benefits Out  
									// var data = {
									//     StaffCode: _data.StaffCode,
									//     typeInsurance: _getType,
									//     typeBenefit: 'out',
									//     user_login: userLogin
									//   };


									// #  Track Probation         
									// var data = {
									//     StaffCode: _data.StaffCode,
									//     typeInsurance: _getType,
									//     typeBenefit: 'in',
									//     user_login: userLogin
									//   };

									// await APIInsertTimeStampBenefits(null, (res) => {
									//     console.log({ res })
									//     if (res.status_api === 1) {
									//         Modal.success({
									//             title: `บันทึกเรียบร้อยแล้ว`,
									//             onOk: () => window.location.reload(true) // search dat in APIBenefitOut or APIBenefit   let pathURL = `${"user_login="}${this.state.userLogin}${"&locationID="}${locationID}${"&statusProbation="}${statusProbationCode}${"&txtUserSeaching="}${txtUserSeaching}${"&SegmentTxt="}${SegmentTxt}${"&statusMainCode=3"}${"&StartDateJob="}
									//             // ${SetDateAPI(initialFilter.StartDateJob)}${"&toDateJob="}${SetDateAPI(initialFilter.toDateJob)}`; 
									//         })
									//     }
									// })
									break;
								case FIELD_BUTTON.VIEW_CONTRACT:
									//console.log({ payload })
									await window.open(PATHNAME.VIEW_PROBATION_CONTRACT, '_blank')
									break;
								default:
									break;
							}
							await setLoading(false)
						}
					})}
				/>
			</Spin>
		</div>
	)
}

export default DashboardContract