import React from "react";
import { Col } from "antd";
import { Dropdown } from "../../../../components/global/forms/dropdown";
export const DocInputDropdown = ({
  form = {},
  control = { control },
  handleChange = () => console.warn("No handleChange"),
}) => {
  return (
    <Col
      key={`renderInput${form.ref}`}
      xs={{ span: 24 }}
      md={{ span: 24 }}
      xl={{ span: form.span }}
      lg={{ span: form.span }}
    >
      <Dropdown
        item={{ ...form }}
        control={control}
        handleChange={handleChange}
      />
    </Col>
  );
};
