import React from 'react';
import '../index.css';
import "bootstrap/dist/css/bootstrap.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { MdAdd } from 'react-icons/md';
import moment from "moment";
import { Col, Row, Button, Form, FormGroup, Label, Input, Card, CardHeader, CardBody, Container } from 'reactstrap';
import { API_URL } from '../components/connectAPI'
import * as NumericInput from "react-numeric-input";
import { browserHistory } from 'react-router';
import { Checkbox, Modal } from 'antd';
import { GET, GET_SELECT_OPTION_ALL } from '../service';
const dateFormat = require('dateformat');
// const api = connectAPI.API_URL


class UpdateEmployee extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			StartDateJob: '',
			endDate: '',
			showDropdown_Position: [],
			showDropdown_Flow: [],
			showDropdown_Location: [],
			showDropdown_Company: [],
			showDropdown_Department: [],
			showDropdown_subDepartment: [],
			showDropdown_StatusWorker: [],
			showDropdown_View: [],
			showDropdown_workShift: [],
			loadData: [],
			Dep: '',
			fields: {},
			errors: {},
			timeScan: '',
			userID: this.props.UserID,
			userLogin: this.props.userLogin,
			premission: this.props.premission,
			DateToday: dateFormat(new Date(), "yyyy-mm-dd HH:MM:ss"),
		};
		this.strDateChange = this.strDateChange.bind(this);
		this.endDateChange = this.endDateChange.bind(this);
		this.updateWorker = this.updateWorker.bind(this)

	}

	strDateChange(date) {
		let fields = this.state.fields;
		fields['StartDateJob'] = date;
		this.setState({
			StartDateJob: date, fields
		});

	}

	endDateChange(date) {
		let fields = this.state.fields;
		fields['endDate'] = date;
		this.setState({
			endDate: date, fields
		});
	}


	componentDidMount() {
		this.selectData();
	}

	onChangeDep = (e) => {
		var Dep = e.target.value
		this.selectFlowApp(Dep);

	}


	selectFlowApp = (_Dep) => {
		let fields = this.state.fields;
		fields['DepNo'] = _Dep;
		var API = API_URL + "/HRM_API/select_optionFlowBoss?dep=" + _Dep

		fetch(API).then((res) => res.json()).then((results) => {
			let arrFlow = [];
			let arrPosition = [];
			let arrSubDep = [];


			if (results['flow'] !== 0) {
				results['flow'].forEach(el => {
					arrFlow.push({
						label: el.Flow_Name || '',
						value: el.ID_Flow + '=)' + el.Approvers_No
					})
				})

			}
			if (results['position'] !== 0) {
				results['position'].forEach(el => {
					arrPosition.push({
						label: `${el.PositionCode || ''} :: ${el.PositionNameEN || ''}`,
						value: el.PositionCode
					})
				})

			}

			if (results['subDep'] !== 0) {
				results['subDep'].forEach(el => {
					arrSubDep.push({
						label: `${el.subDepNo} :: ${el.subDepName || ''}`,
						value: el.subDepNo
					})
				})

			}

			this.setState({
				showDropdown_Flow: arrFlow,
				showDropdown_Position: arrPosition,
				showDropdown_subDepartment: arrSubDep,
				fields
			})

		}).catch(ex => {
			console.log("error gate ttt");

		})

	}

	selectDataUser() {
		var API = API_URL + "/HRM_API/select_worker?userID=" + this.props.params.UserID + "&page=saf"
		fetch(API).then((res) => res.json()).then((results) => {
			results = results['data']
			var Dep = results[0].DepartmentNo
			let timeScan = results[0].timeScan
			let oneTimeScan = results[0].oneTimeScan
			let startDateBF = results[0].StartDateJob
			let company = results[0].Company

			this.setState({
				loadData: results, fields: results[0], timeScan, oneTimeScan, startDateBF, company
			}, () => { this.selectFlowApp(Dep) })
		}).catch(ex => {
			console.log("error gate ttt");
		})
	}

	async selectData() {
		try {
			let res = await GET(GET_SELECT_OPTION_ALL())
			const { result, success } = res
			if (success) {
				let options = {}
				for (const key in result) {
					let arrOption = []
					switch (key) {
						case 'statusWorker':
						case 'company':
						case 'location':
							result[key].map(el => {
								arrOption.push({ label: el.description, value: el.code })
							})
							break;
						case 'permissionview':
							result[key].map(el => {
								arrOption.push({ label: el.description, value: el.flowID })
							})
							break;
						case 'workShift':
							result[key].map(el => {
								arrOption.push({ label: el.shiftCode + " :: " + el.workShiftNameTH, value: el.shiftCode })
							})
							break;
						case 'department':
							result[key].map(el => {
								arrOption.push({ label: el.depNameTH, value: el.depNo })
							})
							break;
					}
					options = { ...options, [key]: arrOption }
				}
				this.setState({
					showDropdown_Department: options['department'],
					showDropdown_StatusWorker: options['statusWorker'],
					showDropdown_Location: options['location'],
					showDropdown_Company: options['company'],
					showDropdown_View: options['permissionview'],
					showDropdown_workShift: options['workShift'],
				})
			}
			// var API_Option = API_URL + "/HRM_API/select_option"
			// var res1 = await fetch(API_Option);
			// const results = await res1.json();
			// // console.log('results', results)
			// var optionPosition = '';
			// var arrPosition = [];
			// var optionDepartment = '';
			// var arrDepartment = [];
			// var optionStatusWorker = '';
			// var arrStatusWorker = [];
			// var optionLocation = '';
			// var arrLocation = [];
			// var optionView = '';
			// var arrView = [];
			// var workShift = [];
			// var optionWorkShift = '';
			// let optionCompany = '';
			// let arrCompany = [];

			// if (results['Position'] !== 0) {
			// 	results['Position'].forEach((element, index, array) => {
			// 		//console.log(array[index].accountBank)
			// 		optionPosition = <option key={index} value={array[index].code}>{array[index].description} </option>
			// 		//console.log(optionBank)
			// 		arrPosition.push(optionPosition)
			// 	})
			// }
			// results['department'].forEach((element, index, array) => {
			// 	//console.log(results['department'])
			// 	optionDepartment = <option key={index} value={array[index].depNo}>{array[index].depNo + " :: " + array[index].Department_Name} </option>
			// 	//console.log(optionBank)
			// 	arrDepartment.push(optionDepartment)
			// })

			// results['StatusWorker'].forEach((element, index, array) => {
			// 	optionStatusWorker = <option key={index} value={array[index].code}>{array[index].description} </option>
			// 	//console.log(optionBank)
			// 	arrStatusWorker.push(optionStatusWorker)
			// })

			// results['permissionview'].forEach((element, index, array) => {
			// 	//console.log(results['department'])
			// 	optionView = <option key={index} value={array[index].flowID}>{array[index].description || ''} </option>
			// 	//console.log(optionBank)
			// 	arrView.push(optionView)
			// })

			// results['company'].forEach((element, index, array) => {
			// 	optionCompany = <option key={index} value={array[index].code}>{array[index].description} </option>
			// 	//console.log(optionBank)
			// 	arrCompany.push(optionCompany)
			// })
			// results['Location'].forEach((element, index, array) => {
			// 	//console.log(array[index].accountBank)
			// 	optionLocation = <option key={index} value={array[index].code}>{array[index].description} </option>
			// 	//console.log(optionBank)
			// 	arrLocation.push(optionLocation)
			// })

			// results['workShift'].forEach((el, index, array) => {
			// 	//console.log(results['department'])
			// 	optionWorkShift = <option key={index} value={el.shiftCode}>{el.shiftCode + " :: " + el.workShiftNameTH} </option>
			// 	//console.log(optionBank)
			// 	workShift = [...workShift, optionWorkShift]
			// })

			// this.setState({
			// 	showDropdown_Position: arrPosition,
			// 	showDropdown_Department: arrDepartment,
			// 	showDropdown_StatusWorker: arrStatusWorker,
			// 	showDropdown_Location: arrLocation,
			// 	showDropdown_Company: arrCompany,
			// 	showDropdown_View: arrView,
			// 	showDropdown_workShift: workShift,
			// })

			await this.selectDataUser(); //You could pass results as params to consecutive functions like this :)
			//  }

		} catch (e) {
			console.warn(e);
		}
	}

	handleValidation() {
		let fields = this.state.fields;
		let errors = {};
		let formIsValid = true;

		if (!fields["Company"]) {
			formIsValid = false;
			errors["Company"] = "Cannot be empty";
		}


		if (!fields["ID_User"]) {
			formIsValid = false;
			errors["ID_User"] = "Cannot be empty";
		}

		if (!fields["StartDateJob"]) {
			formIsValid = false;
			errors["StartDateJob"] = "Cannot be empty";
		}

		if (!fields["title"]) {
			formIsValid = false;
			errors["title"] = "Cannot be empty";
		}

		if (!fields["FirstName"]) {
			formIsValid = false;
			errors["FirstName"] = "Cannot be empty";
		}

		if (!fields["LastName"]) {
			formIsValid = false;
			errors["LastName"] = "Cannot be empty";
		}

		if (!fields["FirstName_EN"]) {
			formIsValid = false;
			errors["FirstName_EN"] = "Cannot be empty";
		}


		if (!fields["LastName_EN"]) {
			formIsValid = false;
			errors["LastName_EN"] = "Cannot be empty";
		}

		if (!fields["Username"]) {
			formIsValid = false;
			errors["Username"] = "Cannot be empty";
		}

		if (!fields["Personal_id"]) {
			formIsValid = false;
			errors["Personal_id"] = "Cannot be empty";
		}

		if (!fields["JG"]) {
			formIsValid = false;
			errors["JG"] = "Cannot be empty";
		}

		// if (!fields["manType"]) {
		//     formIsValid = false;
		//     errors["manType"] = "Cannot be empty";
		// }

		// if (!fields["Position_No"]) {
		//     formIsValid = false;
		//     errors["Position_No"] = "Cannot be empty";
		// }

		if (!fields["DepNo"]) {
			formIsValid = false;
			errors["DepNo"] = "Cannot be empty";
		}

		// if (!fields["shiftCode"]) {
		//     formIsValid = false;
		//     errors["shiftCode"] = " shiftCode Cannot be empty";
		// }

		// if (!fields["refFlow_HRM"]) {
		//     formIsValid = false;
		//     errors["refFlow_HRM"] = "Cannot be empty";
		// }


		this.setState({ errors: errors });
		return formIsValid;
	}

	onChangeWorkshift = (e) => {
		let fields = this.state.fields;
		fields['shiftCode'] = e.target.value;
		this.setState({
			fields
		})
	}


	onChangeTimeScan = (e) => {
		let fields = this.state.fields;
		fields['timeScan'] = e.target.value;
		this.setState({
			fields,
			timeScan: e.target.value
		})
	}


	onChangeOneTimeScan = (e) => {
		this.setState({
			oneTimeScan: e.target.checked
		})
	}


	updateWorker(event) {
		event.preventDefault();
		if (this.handleValidation()) {
			const data = new FormData(event.target);
			//console.log(data)
			var entries = data.entries();
			var current, item, key, output, value;
			output = {};
			while (item = entries.next().value) {
				// assign to variables to make the code more readable.
				key = item[0];
				value = item[1];
				// Check if key already exist
				if (Object.prototype.hasOwnProperty.call(output, key)) {
					current = output[key];
					if (!Array.isArray(current)) {
						// If it's not an array, convert it to an array.
						current = output[key] = [current];
					}
					current.push(value); // Add the new value to the array.
				} else {
					output[key] = value;
				}
			}

			let payload = {
				...output,
				refFlow_HRM: !!output.refFlow_HRM ? `${output.refFlow_HRM}`.substring(0, 6) : '',
				oneTimeScan: this.state.oneTimeScan ? '1' : '0'
			}
			//console.log({ payload })
			fetch(API_URL + '/HRM_API/update_worker', {
				method: 'POST',
				headers: {
					//'content-type': 'application/x-www-form-urlencoded',
					'Content-Type': 'application/json',
					Accept: 'application/json'
				},
				body: JSON.stringify({ data: payload }), //  ,
			}).then((Response) => Response.json())
				.then(function (results) {
					if (results.status_api === 1) {
						// alert("Updata Success!! ");
						// // window.location.reload();
						// browserHistory.push("/ReportEmployee");
						Modal.success({
							title: "Update Success!!",
							onOk: () => browserHistory.push('/ReportEmployee'),
						})
					} else {
						// alert("Insert Error!!");
						Modal.error({ title: "Update Error!!" })
					}

				}).catch(ex => {
					console.log("catch : " + ex);
				})
		} else {
			// alert("กรุณาระบุข้อมูลให้ครบถ้วน")
			Modal.warning({ title: "กรุณาระบุข้อมูลให้ครบถ้วน" })
			console.log(this.state.errors)
		}

	}

	onChangeTitle = (e) => {
		var title = e.target.value
		let fields = this.state.fields;
		fields['title'] = title;
		this.setState({
			fields
		})
	}
	onChangeCompany = (e) => {
		let fields = this.state.fields;
		fields['Company'] = e.target.value;
		this.setState({
			fields
		})
	}

	onChangeFlowID = (e) => {
		var refFlow_HRM = e.target.value
		let fields = this.state.fields;
		fields['refFlow_HRM'] = refFlow_HRM;
		this.setState({
			fields
		})
	}

	onChangePosition = (e) => {
		var Position = e.target.value
		let fields = this.state.fields;
		fields['Position_No'] = Position;
		this.setState({
			fields
		})
	}

	changeValue = (e) => {
		var _id = e.target.id
		var Arr = this.state.loadData
		Arr[0][_id] = e.target.value
		this.setState({ loadData: Arr });
		//console.log(this.state.loadData)
	}

	onChangeFlowView = (e) => {
		var FlowView = e.target.value
		let fields = this.state.fields;
		fields['refFlow_view'] = FlowView;
		this.setState({
			fields
		})
	}

	handleDatePickerChange = (date, _id) => {

		//console.log('date', date)
		let Arr = this.state.loadData
		let _date = ''
		if (date) {
			_date = moment(date).format('YYYY-MM-DD')
		}

		Arr[0][_id] = _date
		this.setState({ loadData: Arr });
		//console.log(_date)
	}

	clearEndDate = () => {

		let Arr = this.state.loadData
		let _date = ''
		Arr[0]['EndDateJob'] = _date
		this.setState({ loadData: Arr });
	}

	handleChange(field, e) {
		let fields = this.state.fields;
		//console.log(fields)
		fields[field] = e.target.value;
		this.setState({ fields });
	}

	handleChangeNum(valueAsNumber, field) {
		let fields = this.state.fields;

		fields[field] = valueAsNumber;
		this.setState({ fields });
	}

	onChangeSubDep = (e) => {
		let subDep = e.target.value
		let fields = this.state.fields;
		fields['subDep'] = subDep;

		var API = API_URL + "/HRM_API/cent/selectPosition?positionNo=&&subDepNo=" + subDep
		// console.log(API);
		fetch(API).then((res) => res.json()).then((results) => {
			let data = results.result
			var arrPosition = [];

			//console.log('data', data)
			if (data.length !== 0) {
				//console.log('in loop')
				data.forEach(el => {
					arrPosition.push({
						value: el.PositionCode,
						label: `${el.PositionCode || ''} :: ${el.PositionNameEN || ''}`
					})
				})

			}

			this.setState({
				showDropdown_Position: arrPosition,
				fields,
				// done: true
			})
			//  console.log('arrPosition', arrPosition)


		}).catch(ex => {
			console.log("error gate ttt");

		})

	}


	render() {
		var data = this.state.loadData;
		return (
			<Container>
				<Card>
					<CardHeader><MdAdd className="margin-icon" />แก้ไขข้อมูลพนักงาน</CardHeader>
					{Array.isArray(data) && data.map((val, index) => {
						return (
							<CardBody key={index}>
								<Form onSubmit={this.updateWorker}>

									<Row>
										<Col md={6}>
											<FormGroup>
												<Label><span className="red"> * </span>บริษัท</Label>
												<Input type="select" ref="company" name="company" id="company" value={val.Company} onChange={this.onChangeCompany} >
													<option value="">กรุณาเลือกบริษัท</option>
													<option value="DP">Dplus Intertrade</option>
													<option value="DT">Dplus Together</option>
													<option value="ZT">Zetta Soft</option>
													<option value="FI">FIN</option>
													<option value="DC">Digital Commerce</option>
													<option value="BD">Better Dose</option>
													<option value="SK">SK Production </option>
												</Input>
											</FormGroup>
										</Col>
										<Col md={6}>
											<FormGroup>
												<Label for="location">สถานที่</Label>
												<Input type="select" ref="Location" name="Location" id="Location" >
													<option value={val.Location}>{val.locationName}</option>
													{this.state.showDropdown_Location.map(el => {
														return <option value={el.value}>{el.label}</option>
													})}
												</Input>
											</FormGroup>
										</Col>
									</Row>
									<Row>
										<Col md={6}>
											<FormGroup>
												<Label><span className="red"> * </span> รหัสพนักงาน</Label>
												<Input type="text" name="User" id="User" value={val.ID_User} disabled />
												<Input type="hidden" name="ID_User" id="ID_User" value={val.ID_User} />
												<Input type="hidden" name="user_login" id="user_login" value={this.state.userLogin} />
											</FormGroup>
										</Col>
										<Col md={6}>
											<FormGroup>
												<Label for="Status_Worker">สถานะ</Label>
												<Input type="select" name="Status_Worker" id="Status_Worker">
													<option value={val.Status_Worker}>{val.statusWorker}</option>
													{this.state.showDropdown_StatusWorker.map(el => {
														return <option value={el.value}>{el.label}</option>
													})}
												</Input>
											</FormGroup>
										</Col>
									</Row>
									<Row>
										<Col md={6}>
											<FormGroup>
												<Label for="StartDate"><span className="red"> * </span> วันที่เริ่มทำงาน</Label><br></br>
												<DatePicker className="form-control ignore-react-onclickoutside" placeholderText="เลือกวันที่เริ่มงาน" name="StartDateJob"
													dateFormat="yyyy/MM/dd"
													value={val.StartDateJob}
													onChange={(date) => this.handleDatePickerChange(date, 'StartDateJob')}
													readOnly={(this.state.DateToday < this.state.startDateBF) ? false : true} />
											</FormGroup>
										</Col>
										<Col md={5}>
											<FormGroup>
												<Label for="StartDate">เลือกวันที่สิ้นสุดการทำงาน</Label><br></br>
												<DatePicker className="form-control ignore-react-onclickoutside" name="EndDateJob" autocomplete="false"
													dateFormat="yyyy/MM/dd"
													value={(val.EndDateJob == '1900-01-01') ? '' : val.EndDateJob}
													onChange={(date) => this.handleDatePickerChange(date, 'EndDateJob')}
												/>
											</FormGroup>
										</Col>
										<FormGroup>
											<br />
											<Col md={1}><Button color="danger" onClick={this.clearEndDate}>Clear</Button> </Col> </FormGroup>
									</Row>
									<Row>
										<Col md={2}>
											<FormGroup>
												<Label><span className="red"> * </span> คำนำหน้าชื่อ </Label>
												<Input type="select" ref="title" name="title" id="title"
													value={val.title}
													onChange={this.onChangeTitle}>
													<option value="">----โปรดระบุ-----</option>
													<option value="นาย">นาย</option>
													<option value="นางสาว">นางสาว</option>
													<option value="นาง">นาง</option>
													<option value="ดร.">ดร.</option>
													<option value="ว่าที่ร้อยตรี">ว่าที่ร้อยตรี</option>
													<option value="ว่าที่ร้อยโท">ว่าที่ร้อยโท</option>
													<option value="ว่าที่ร้อยเอก">ว่าที่ร้อยเอก</option>
													<option value="พลตำรวจเอก">พลตำรวจเอก</option>
												</Input>
											</FormGroup>
										</Col>
										<Col md={4}>
											<FormGroup>
												<Label><span className="red"> * </span>ชื่อ (ภาษาไทย)</Label>
												<Input ref="FirstName" type="text" name="FirstName" id="FirstName" onChange={this.handleChange.bind(this, "FirstName")} value={this.state.fields["FirstName"]} />
											</FormGroup>
										</Col>
										<Col md={6}>
											<FormGroup>
												<Label><span className="red"> * </span>นามสกุล (ภาษาไทย)</Label>
												<Input ref="LastName" type="text" name="LastName" id="LastName" onChange={this.handleChange.bind(this, "LastName")} value={this.state.fields["LastName"]} />
											</FormGroup>
										</Col>
									</Row>
									<Row>
										<Col md={6}>
											<FormGroup>
												<Label><span className="red"> * </span> ชื่อ (ภาษาอังกฤษ)</Label>
												<Input ref="FirstName_EN" type="text" name="FirstName_EN" id="FirstName_EN" onChange={this.handleChange.bind(this, "FirstName_EN")} value={this.state.fields["FirstName_EN"]} />
											</FormGroup>
										</Col>
										<Col md={6}>
											<FormGroup>
												<Label><span className="red"> * </span>นามสกุล (ภาษาอังกฤษ)</Label>
												<Input ref="LastName_EN" type="text" name="LastName_EN" id="LastName_EN" onChange={this.handleChange.bind(this, "LastName_EN")} value={this.state.fields["LastName_EN"]} />
											</FormGroup>
										</Col>
									</Row>
									<Row>
										<Col md={6}>
											<FormGroup>
												<Label><span className="red"> * </span> Username</Label>
												<Input type="text" name="Username" id="Username" value={val.Username} onChange={this.changeValue} readOnly />
											</FormGroup>
										</Col>
										<Col md={6}>
											<FormGroup>
												<Label><span className="red"> * </span> เลขประจำตัวประชาชน</Label>
												{/* <NumericInput className="form-control" ref="Personal_id" name="Personal_id" id="Personal_id" style={false}
                                                    onChange={valueAsNumber => this.handleChangeNum(valueAsNumber, "Personal_id")} value={this.state.fields["Personal_id"]}
                                                /> */}
												<Input className="form-control" ref="Personal_id" name="Personal_id" id="Personal_id"
													maxLength={13} min={0}
													value={val.Personal_id}
													onChange={(e) => {
														let lengthValue = e.target.value.length
														if (lengthValue <= 13) {
															if (!isNaN(parseFloat(e.target.value)) && isFinite(e.target.value)) {
																this.handleChangeNum(e.target.value || '', "Personal_id")
															} else {
																this.handleChangeNum('', "Personal_id")
															}
														}
													}
														// valueAsNumber => this.handleChangeNum(valueAsNumber, "Personal_id")
													}
												// value={this.state.fields["Personal_id"]}
												/>
											</FormGroup>
										</Col>
									</Row>
									<Row>
										<Col md={6}>
											<FormGroup>
												<Label><span className="red"> * </span>  ชื่อเล่น</Label>
												<Input type="text" name="NickName" id="NickName" value={val.NickName} onChange={this.changeValue} />
											</FormGroup>
										</Col>
										<Col md={6}>
											<FormGroup>
												<Label for="FNameEN">เบอร์ติดต่อ</Label>
												<Input name="Tel_No" id="Tel_No" value={val.Tel_No} onChange={this.changeValue} />
											</FormGroup>
										</Col>

									</Row>
									<Row>
										<Col md={6}>
											<FormGroup>
												<Label>ประเภทพนักงาน</Label>
												<Input type="select" ref="manType" name="manType" id="manType"
													value={val.manType}
													onChange={this.handleChange.bind(this, "manType")}>
													<option value="" >----โปรดระบุ----</option>
													<option value="new">อัตราใหม่</option>
													<option value="instead">ทดแทน</option>
												</Input>
											</FormGroup>
										</Col>
										<Col md={6}>
											<FormGroup>
												<Label> size เสื้อพนักงาน</Label>
												<Input type="select" ref="shirtSize" name="shirtSize" id="shirtSize" value={val.shirtSize} onChange={this.handleChange.bind(this, "shirtSize")}>
													<option value="" >----โปรดระบุ----</option>
													<option value="S" > S </option>
													<option value="M" > M</option>
													<option value="L" >L</option>
													<option value="XL" >XL</option>
													<option value="2XL" >2XL</option>
												</Input>
											</FormGroup>
										</Col>
									</Row>
									<Row>
										<Col md={6}>
											<FormGroup>
												<Label for="Email">E-Mail</Label>
												<Input type="text" name="Email" id="Email" value={val.Email} onChange={this.changeValue} />
											</FormGroup>
										</Col>
										<Col md={6}>
											<FormGroup>
												<Label><span className="red"> * </span>Job Grade (JG)</Label>
												<NumericInput className="form-control" ref="JG" name="JG" id="JG" min={1} max={12}
													onChange={valueAsNumber => this.handleChangeNum(valueAsNumber, "JG")} value={this.state.fields["JG"]} />
											</FormGroup>
										</Col>
									</Row>
									<Row>

										<Col md={6}>
											<FormGroup>
												<Label for="FNameEN">แผนก</Label>
												<Input type="select" name="DepartmentNo" id="DepartmentNo" onChange={this.onChangeDep}>
													<option value={val.DepartmentNo}>
														{(val.DepartmentNo) ? `${val.DepartmentNo} :: ${val.Department_Name}` : ''}
														{/* {val.DepartmentNo + " :: " + val.Department_Name} */}
													</option>
													{this.state.showDropdown_Department.map(el => {
														return <option value={el.value}>{el.label}</option>
													})}
												</Input>
											</FormGroup>
										</Col>
										<Col md={6}>
											<FormGroup>
												<Label for="subDepNo">แผนกย่อย</Label>
												<Input type="select" name="subDepNo" id="subDepNo" onChange={this.onChangeSubDep}>
													<option value={val.subDepNo}>
														{/* {val.subDepNo + " :: " + val.subDepName} */}
														{(val.subDepNo) ? `${val.subDepNo} :: ${val.subDepName}` : ''}
													</option>
													{this.state.showDropdown_subDepartment.map(el => {
														return <option value={el.value}>{el.label}</option>
													})}
												</Input>
											</FormGroup>
										</Col>
									</Row>
									<Row>
										<Col md={6}>
											<FormGroup>
												<FormGroup>
													<Label>ตำแหน่งงาน </Label>
													<Input type="select" ref="Position_No" name="Position_No" id="Position_No" onChange={this.onChangePosition}>
														<option value={val.Position_No}>
															{(val.Position_No) ? `${val.Position_No} :: ${val.PositionNameEN}` : ''}
															{/* {val.Position_No + " :: " + val.PositionNameEN} */}
														</option>
														{this.state.showDropdown_Position.map(el => {
															return <option value={el.value}>{el.label}</option>
														})}
													</Input>
												</FormGroup>
											</FormGroup>
										</Col>
										<Col md={6}>
											<FormGroup>
												<Label for="BossID">สายอนุมัติ</Label>
												<Input type="select" ref="refFlow_HRM" name="refFlow_HRM" id="refFlow_HRM" onChange={this.onChangeFlowID} >
													<option value={val.valueFlow}>{val.Flow_Name || ''}</option>
													{this.state.showDropdown_Flow.map(el => {
														return <option value={el.value}>{el.label}</option>
													})}
												</Input>

											</FormGroup>
										</Col>
									</Row>
									{/* <Row>
                                        <Col md={6}>
                                            <FormGroup>
                                                <Label> สิทธิการดูตรวจสอบข้อมูล</Label>
                                                <Input type="select" ref="refFlow_view" name="refFlow_view" id="refFlow_view" onChange={this.onChangeFlowView} >
                                                    <option value={val.refFlow_view}>{val.refFlow_viewName}</option>
                                                    {this.state.showDropdown_View}
                                                </Input>

                                            </FormGroup>
                                        </Col>
                                    </Row> */}
									<Row>
										<Col md={6} style={{ padding: 15 }}>
											<FormGroup>
												<Label for="shiftCode">กะทำงานตั้งต้น </Label>
												{/* <Input type="text" name="shiftCode" id="shiftCode" value={val.shiftCode} readOnly /> */}
												<Input type="select" ref="shiftCode" name="shiftCode" id="shiftCode" onChange={this.onChangeWorkshift} >
													<option value={val.shiftCode}>{val.shiftCode + "::" + val.workShiftNameTH}</option>
													{this.state.showDropdown_workShift.map(el => {
														return <option value={el.value}>{el.label}</option>
													})}
												</Input>


											</FormGroup>
										</Col>
										<Col md={3} style={{ padding: 15 }}>
											<FormGroup>
												<Label for="timeScan"><span className="red"> * </span>รูปแบบในการสแกน </Label>
												<Input type="select" ref="timeScan" name="timeScan" id="timeScan" value={this.state.timeScan} onChange={this.onChangeTimeScan} >
													<option value="0" defaultValue={val.timeScan === '0'}>ไม่ต้องสแกน</option>
													<option value="4" defaultValue={val.timeScan === '4'}>สแกนเวลารายวัน</option>
													<option value="2" defaultValue={val.timeScan === '2'}>สแกนเวลารายเดือน</option>
												</Input>
											</FormGroup>
										</Col>
										<Col md={3} style={{ padding: 15 }}>
											<FormGroup>
												<Label for="oneTimeScan">สแกนเข้างานครั้งเดียวเท่านั้น? </Label>
												<Checkbox
													ref="oneTimeScan" name="oneTimeScan" id="oneTimeScan"
													checked={this.state.oneTimeScan}
													onChange={this.onChangeOneTimeScan}>
													<Label>ใช่ ขาเข้ารอบเดียว </Label>
												</Checkbox>
											</FormGroup>
										</Col>

										{/* <Col md={6} style={{ padding: 15 }}>
                                        <FormGroup>
                                            <Checkbox
                                                ref="followupGuarantee" name="followupGuarantee" id="followupGuarantee"
                                                checked={this.state.followupGuarantee}
                                                onChange={this.onChangeFollowupGuarantee}>
                                                <Label>ติดตามเอกสารค้ำประกัน </Label>
                                            </Checkbox>
                                        </FormGroup>
                                    </Col> */}
									</Row>
									<Row>
										<Col md={4}>
										</Col>
										<Col md={4} className="text-center">
											<Button color="success">ปรับปรุงข้อมูล</Button>
										</Col>
										<Col md={4}>
										</Col>
									</Row>
								</Form>
							</CardBody>
						);
					})}
				</Card>
			</Container>
		);

	}
}
export default UpdateEmployee;