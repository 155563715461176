export const PATH_ROUTE = {
  Position_Manage: "/ManagePosition",
  Position_Edit: "/ManagePosition/update-position/:positionCode",
  SubDepartment_Manage: "/ManageSubDepartment",
  SubDepartment_Edit:
    "/ManageSubDepartment/update-sub-department/:SubDepartmentNo",
  Approval_Manage: "/ManageBranchApprove",
  Approval_Edit: "/ManageBranchApprove/update-branch-approve/:flowId",
  ViewFlow_Manage: "/ManageBranchView",
  ViewFlow_Edit: "/ManageBranchView/update-branch-view/:flowId",
  OJT_Manage: "/ManageOJT",
  OJT_Edit: "/ManageOJT/update-OJT-Course/:positionCode",
  Training_Manage: "/ManageTraining",
  Training_Edit: "/ManageTraining/update-OJT-Training/:trainingNo",
  KPIs_Manage: "/ManageKPIs",
  KPIs_Edit: "/ManageKPIs/update-KPIs/:indicatorNo",
  SSO_Manage: "/ManageSSO",
  SSO_Edit: "/UpdateSSO",
  SSO_View: "/InformationSSO",
  CustomRole_Manage: "/ManageCustomRole",
  JGPG_Import: "/ImportJGPG",
  JGPG_Import_FAC: "/ImportJGPG-FAC",
  Crime_Manage: "/ManageCrime",
  Crime_Edit: "/ManageCrime/update-crime/:docNo",
  Crime_View: "/ManageCrime/detail-crime/:docNo",
  Probation_Manage: "/ManageMasterProbation",
  Probation_Edit: "/ManageMasterProbation/update-probation/:position",
  Report_All: "/ReportAllProfile",
  Report_Summary: "/ReportEmployeeSummary",
  Report_LvWorker: "/ReportLevelWorker",
  Report_Personal: "/ReportPersonal",
  Report_EmpAge: "/ReportEmployeeAge",
  Report_WorkAge: "/ReportEmployeeAgeOfWork",
  Report_StatusWork: "/ReportStatusWorker",
  Report_EmpTransfer: "/ReportEmployeeTransferHistory",
  Report_Crime: "/ReportResultCrime",
  Report_Return: "/ReportReturn",
  Report_OT: "/ReportOT",
  Report_Resign: "/ReportResignRate",
  Report_Warning: "/ReportWarning",
  Report_Bonus: "/ReportBonus",
  Report_CheckEmployees: "/ReportCheckEmployees",
  Report_Charge: "/ReportCharge",
  Report_ExamineFullTemp: "/ReportExamineFullTemp",
  Report_RecruiterGrade: "/ReportRecruiterGrade",
  Report_RecruiterScore: "/ReportRecruiterScore",
  Benefit_New: "/BenefitNewEmp",
  Benefit_Temp: "/BenefitNewEmpTemp",
  Benefit_Out: "/BenefitOutEmp",
  Transfer_Company: "/TransferCompany",
  Transfer_Department: "/TransferDepartment",
  Transfer_SubDepartment: "/TransferSubDepartment",
  Transfer_HrmFlow: "/TransferHrmFlow",
  Transfer_ViewFlow: "/TransferViewFlow",
  Transfer_Position: "/TransferPosition",
  ChangeShiftWork: "/ChangeShiftWork",
  Change_StatusWorker: "/ChangeStatusWorker",
  Manage_DocProbation: "/ManageDocProbation",
  Manage_DocProbationContract: "/ManageDocProbationContract",
  Manage_OT: "/ManageOT",
  Manage_ChangeInsurancePlan: "/ChangeInsurancePlan",
  Manage_BenefitTransfer: "/ManageBenefitTransfer",
  Manage_BenefitContract: "/ManageBenefitContract",
  RecheckWorkingSaleEmployee: "/RecheckWorkingSaleEmployee",
  Insert_NewSaleEmployee: "/InsertNewSaleEmployee",
  Insert_NewSaleEmployeeFSP: "/InsertNewSaleEmployeeFSP",
  Import_IncentiveStrategy: "/ImportIncentiveStrategy",
  ImportMapFocusShopSaleCode: "/ImportMapFocusShopSaleCode",
  SaleCodeAreaAssignment: "/SaleCodeAreaAssignment",
  FilterOutSalesEmpCommission: "/FilterOutSalesEmpCommission",
  SaleCodeAreaAssignmentFSP: "/SaleCodeAreaAssignmentFSP",
  InsertSaleTargetFSP: "/InsertSaleTargetFSP",

  RecheckCommissionIncentiveDashboard: "/RecheckCommissionIncentiveDashboard",
  RecheckCommissionIncentiveAction: "/RecheckCommissionIncentiveAction",
  RecheckCommissionIncentiveDashboardView: "/RecheckCommissionIncentiveDashboardView",
  RecheckCommissionIncentiveView: "/RecheckCommissionIncentiveView",
  RecheckCommissionIncentiveActionFSP: "/RecheckCommissionIncentiveActionFSP",
  RecheckCommissionIncentiveDashboardFSP:
    "/RecheckCommissionIncentiveDashboardFSP",

  CutOffCommissionIncentive: "/CutOffCommissionIncentive",
  CutOffCommissionIncentiveFSP: "/CutOffCommissionIncentiveFSP",
  CutOffCommissionIncentiveDashboard: "/CutOffCommissionIncentiveDashboard",
  CutOffCommissionIncentiveDashboardFSP: "/CutOffCommissionIncentiveDashboardFSP",
  BenefitAndHoliday: "/BenefitAndHoliday",
  DashboardMonitorFile: "/DashboardMonitorFile",
  DashboardMonitorFileView: "/DashboardMonitorFileView",
  ChangePassword: "/ChangePassword",

  HOME: "/",
  NOT_FOUND: "*",
  TEST: "/TestPage",
};
