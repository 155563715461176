var DateToday = new Date()
//console.log(DateToday)
let codeColor = function (_dueDate) {
    let codeColor = ''
    let diff
    if (_dueDate) {
        // console.log('due', _dueDate)
        _dueDate = _dueDate.substring(0, 10)
        _dueDate = new Date(_dueDate.substring(0, 10))
        diff = parseInt((_dueDate - DateToday) / (24 * 3600 * 1000))
        // console.log('diff', diff)

        if (diff < 0) {
            // console.log('red')
            codeColor = '#CC3300'  // red
        } else if (diff < 8 && diff >= 0) {
            codeColor = '#FFCC00'//yellow
        }


    }
    //console.log(codeColor)
    return codeColor;
}

let colorClassName = function (_dueDate) {

    let colorClassName = ''
    let diff = 0
    if (_dueDate) {
        _dueDate = new Date(_dueDate.substring(0, 10))
        // console.log('due', _dueDate)
        // console.log()
        // console.log(_dueDate)
        diff = parseInt((_dueDate - DateToday) / (24 * 3600 * 1000))
        //   console.log(diff)

        if (diff < 0) {
            // console.log('red')
            colorClassName = 'date-red' // red
        } else if (diff < 8 && diff >= 0) {
            colorClassName = 'date-yellow' //yellow
        }

    }
    //console.log(codeColor)
    return colorClassName;
}



module.exports = {
    codeColor: codeColor,
    colorClassName: colorClassName
};