import React, { useState } from 'react';
import './index.css';
import { Select, Checkbox, Input, Typography, Row, Col, Empty, Radio, DatePicker } from 'antd';
import { requiredInput, TYPE } from '../inputs';
import { KEY_TYPE } from '../../../constants/enums/enumCenter';

const { Search } = Input;
const { Text } = Typography;

export const WrapperSearch = ({ searchLists = [], onChange, onSearch }) => {
	return (
		<Row gutter={[24, 10]}>
			{searchLists.map((each, eachIndex) => (
				<Col key={eachIndex} span={each.span} >
					<LayoutSearch
						type={each.type}
						header={each.header}
						onChange={onChange}
						onSearch={onSearch}
						{...each.inputOption}
					/>
				</Col>
			))}
		</Row>
	)
}

export const LayoutSearch = ({ header, required, ...rest }) => {
	return (
		<div
		// className="layout-search-card"
		>
			<div
			// className="layout-search-header"
			>
				<div style={{ fontSize: 14 }}>  {requiredInput({ required: required, label: header || 'Header' })} </div>
			</div>
			<RenderWithType {...rest} />
		</div>
	)
};

export const RenderWithType = ({ type, plainOptions, inputItems, inputProps, placeholder, disabled, defaultValue, onChange, onSearch, fieldOption, value,
	checkedDetail, searchInput, selectAll, selectedItems, filteredOptions, ...rest }) => {
	// const [indeterminate, setIndeterminate] = useState(false)
	const [checkAll, setCheckAll] = useState(false)
	switch (type) {
		case TYPE.INPUT:
			return (
				<Input
					value={value}
					disabled={disabled}
					onChange={(e) => onChange({ fieldOption: fieldOption, checkedList: e.target.value })}
					placeholder={placeholder}
					{...inputProps} />
			)
		case TYPE.INPUT_SEARCH:
			return (
				<Input.Search
					value={value}
					disabled={disabled}
					onSearch={(value, event) => onSearch(value, event.target.value)}
					placeholder={placeholder}
					{...inputProps} />
			)
		case TYPE.DATE_PICKER:
		case KEY_TYPE.DATE_PICKER:
			return (
				<DatePicker
					style={{ width: '100%' }}
					disabled={disabled}
					value={value}
					onChange={(dates, dateStrings) => onChange({ fieldOption: fieldOption, checkedList: dateStrings })}
					{...rest}
				/>
			)
		case KEY_TYPE.RANG_PICKER:
		case TYPE.RANG_PICKER_SEARCH:
			return (
				<DatePicker.RangePicker
					style={{ width: '100%' }}
					disabled={disabled}
					value={value}
					onChange={(dates, dateStrings) => onChange({ fieldOption: fieldOption, checkedList: [dateStrings] })}
					{...rest}
				/>
			)
		case TYPE.RANG_PICKER:
			return (
				<DatePicker.RangePicker
					style={{ width: '100%' }}
					disabled={disabled}
					value={value}
					onChange={(dates, dateStrings) => onChange({ dates, dateStrings })}
					{...rest}
				/>
			)
		case TYPE.SELECT_MULTIPLE:
			return (
				<Select

					mode="multiple"
					disabled={disabled}
					placeholder={placeholder || "ทั้งหมด"}
					value={selectedItems}
					onChange={(event) => onChange({ fieldOption: fieldOption, checkedList: [event] })}
					style={{ width: '100%' }}
				>
					{
						Array.isArray(plainOptions) && plainOptions.map((option, optionIndex) => {
							return (
								<Select.Option key={optionIndex} value={option.optionValue}>
									{option.optionLabel}
								</Select.Option>
							)
						})
					}
				</Select>
			)
		case TYPE.SELECT:
			return (
				<div className="layout-search-content">
					<Select
						showSearch
						defaultValue={defaultValue}
						disabled={disabled}
						style={{ width: '100%' }}
						optionFilterProp="children"
						onChange={(event) => onChange({ fieldOption: fieldOption, checkedList: [event] })}
						filterOption={(input, option) =>
							option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
						}
					>
						{
							Array.isArray(plainOptions) && plainOptions.map((option, optionIndex) => {
								return (
									<Select.Option key={optionIndex} value={option.optionValue}>
										{option.optionLabel}
									</Select.Option>
								)
							})
						}
					</Select>
				</div>
			)
		case TYPE.SELECT_REQUIRED:
			return (
				<div className="layout-search-content">

					<Select
						// {...rest}
						// value={ defaultValue}
						disabled={disabled}
						style={{ width: '100%' }}
						placeholder={placeholder || "ทั้งหมด"}
						optionFilterProp="children"
						onChange={(event) => onChange({ fieldOption: fieldOption, checkedList: [event] })}
						onSearch={onSearch}
						filterOption={(input, option) =>
							option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
						}
					>
						{
							Array.isArray(plainOptions) && plainOptions.map((option, optionIndex) => {
								return (
									<Select.Option key={optionIndex} value={option.optionValue}>
										{option.optionLabel}
									</Select.Option>
								)
							})
						}
					</Select>
				</div>
			)
		default:
			// console.log(plainOptions)
			let disabledAll = false
			Array.isArray(plainOptions) && plainOptions.find((val) => val.optionDisable ? disabledAll = true : disabledAll = false)
			// console.log(disabledAll)
			return (
				<div className="layout-search-content">
					{(!searchInput) ? null :
						<div className="layout-search-input">
							<Search
								disabled={disabled}
								placeholder="Search..."
								onSearch={onSearch} />
						</div>
					}
					{(checkedDetail && plainOptions.length > 0) ?
						<div className="layout-search-listItem">

							{
								(selectAll) ?
									<div style={{ display: 'flex', flexDirection: 'column' }}>
										<Checkbox
											disabled={disabledAll}
											className="checkbox-all"
											onChange={(event) => {
												let valueAll = []
												plainOptions.forEach(({ optionId }) => valueAll.push(`${optionId}`))
												// setIndeterminate(false)
												setCheckAll(event.target.checked)
												onChange({
													fieldOption: fieldOption,
													checkedList: [],
													checkAll: event.target.checked,
													plainOptions: valueAll
												})
											}
											}
											checked={checkAll}>
											<Text className="layout-search-text">
												{'เลือกทั้งหมด'}
											</Text>
										</Checkbox>
										<Checkbox.Group
											disabled={disabled}
											className="layout-search-text"
											value={checkedDetail}
											onChange={(event) => {
												setCheckAll(!!checkedDetail && checkedDetail.length === plainOptions.length)
												onChange({
													fieldOption: fieldOption,
													checkedList: event
												})
											}}>
											{
												plainOptions.length > 0 && plainOptions.map((option, optionIndex) => (
													<Row key={Number(optionIndex) + 1}>
														<Col>

															<Checkbox value={option.optionId} disabled={option.optionDisable}>
																<Text className="layout-search-text">
																	{option.optionLabel}
																</Text>
															</Checkbox>
														</Col>
													</Row>
												))

											}
										</Checkbox.Group>
									</div> :
									<div>
										<Radio.Group
											onChange={(event) => onChange({ fieldOption: fieldOption, checkedList: [event.target.value] })} value={checkedDetail[0]}
										>
											{
												plainOptions.length > 0 && plainOptions.map((option, optionIndex) => (
													<Row key={Number(optionIndex) + 1}>
														<Col>
															<Radio value={option.optionValue} disabled={option.optionDisable}>
																<Text className="layout-search-text">
																	{option.optionLabel}
																</Text>
															</Radio>
														</Col>
													</Row>
												))

											}
										</Radio.Group>

									</div>

							}
						</div>
						:
						<Empty
							className="layout-search-empty"
							image={Empty.PRESENTED_IMAGE_SIMPLE} />}
				</div>
			)
	}
}