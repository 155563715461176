import { COMPONENT_TYPE, ERROR_MESSAGE } from "../..";
import { NAME, LABEL } from "../StaticVariable";

export const DEFAULT_CREATE_DOC_CRIME = {
  company: undefined,
  location: undefined,
  depNo: undefined,
  subDepNo: undefined,
  startDate: undefined,
  endDate: undefined,
  prepareDate: undefined,
}

const DEFINE = {
  company: {
    label: "บริษัท",
    name: "company",
  },
  location: {
    label: "สถานที่",
    name: "location",
  },
  depNo: {
    label: "แผนก",
    name: "depNo",
  },
  subDepNo: {
    label: "แผนกย่อย",
    name: "subDepNo",
  },
  startDate: {
    label: "วันที่เริ่มงาน",
    name: "startDate",
  },
  endDate: {
    label: "ถึงวันที่",
    name: "endDate",
  },
  prepareDate: {
    label: "วันที่ยื่นเอกสาร",
    name: "prepareDate",
  },
}

export const CreateDocCrime = ({
  handleChange,
  options = {},
  isDisable = {},
}) => [
    { // Row
      rowProps: { justify: "start", align: "bottom" },
      column: [ // column
        {
          breakpoint: { xs: 24, sm: 12, md: 12, lg: 6, xl: 4 },
          component: {
            type: "select",
            itemProps: {
              name: NAME.company,
              label: LABEL.company,
              rules: [{ required: true, message: `กรุณาเลือก ${LABEL.company}` }],
            },
            componentProps: {
              style: { width: "100%" },
              placeholder: `เลือก${LABEL.company}`,
              disabled: isDisable[NAME.company] || false,
            },
            fnChange: handleChange,
            dropdown: options[NAME.company],
          },
        },
        {
          breakpoint: { xs: 24, sm: 12, md: 12, lg: 6, xl: 4 },
          component: {
            type: "select",
            itemProps: {
              name: NAME.location,
              label: LABEL.location,
              rules: [{ required: true, message: `กรุณาเลือก ${LABEL.location}` }],
            },
            componentProps: {
              style: { width: "100%" },
              placeholder: `เลือก ${LABEL.location}`,
              disabled: isDisable[NAME.location] || false,
            },
            fnChange: handleChange,
            dropdown: options[NAME.location],
          },
        },
        {
          breakpoint: { xs: 24, sm: 12, md: 12, lg: 6, xl: 8 },
          component: {
            type: "select",
            itemProps: {
              name: NAME.depNo,
              label: LABEL.depNo,
              rules: [{ required: false, message: `กรุณาเลือก ${LABEL.depNo}` }],
            },
            componentProps: {
              style: { width: "100%" },
              placeholder: `เลือก${LABEL.depNo}`,
              disabled: isDisable[NAME.depNo] || false,
            },
            fnChange: handleChange,
            dropdown: options[NAME.depNo],
          },
        },
        {
          breakpoint: { xs: 24, sm: 12, md: 12, lg: 6, xl: 8 },
          component: {
            type: "select",
            itemProps: {
              name: NAME.subDepNo,
              label: LABEL.subDepNo,
              rules: [{ required: false, message: `กรุณาเลือก ${LABEL.subDepNo}` }],
            },
            componentProps: {
              style: { width: "100%" },
              placeholder: `เลือก${LABEL.subDepNo}`,
              disabled: isDisable[NAME.subDepNo] || false,
            },
            fnChange: handleChange,
            dropdown: options[NAME.subDepNo],
          },
        },
        {
          breakpoint: { xs: 24, sm: 12, md: 12, lg: 6, xl: 8 },
          component: {
            type: "datePicker",
            itemProps: {
              name: NAME.startDate,
              label: LABEL.startDate,
              rules: [{ required: false, message: `กรุณาเลือก ${LABEL.startDate}` }],
            },
            componentProps: {
              style: { width: "100%" },
              placeholder: `เลือก${LABEL.startDate}`,
              disabled: isDisable[NAME.startDate] || false,
            },
            fnChange: handleChange,
          },
        },
        {
          breakpoint: { xs: 24, sm: 12, md: 12, lg: 6, xl: 8 },
          component: {
            type: "datePicker",
            itemProps: {
              name: NAME.endDate,
              label: LABEL.endDate,
              rules: [{ required: false, message: `กรุณาเลือก ${LABEL.endDate}` }],
            },
            componentProps: {
              style: { width: "100%" },
              placeholder: `เลือก${LABEL.endDate}`,
              disabled: isDisable[NAME.endDate] || false,
            },
            fnChange: handleChange,
          },
        },
      ], // column
    }, // Row
  ];

export const INSERT_CreateDocCrime = ({
  handleChange,
  isDisable = {},
  initialValue = undefined,
}) => [
    { // Row
      rowProps: { justify: "start", align: "bottom" },
      column: [ // column
        {
          breakpoint: { xs: 24, sm: 12, md: 12, lg: 6, xl: 8 },
          component: {
            type: "datePicker",
            itemProps: {
              name: NAME.prepareDate,
              label: LABEL.prepareDate,
              initialValue,
              rules: [{ required: true, message: `กรุณาเลือก ${LABEL.prepareDate}` }],
            },
            componentProps: {
              style: { width: "100%" },
              placeholder: `เลือก${LABEL.prepareDate}`,
              disabled: isDisable[NAME.prepareDate] || false,
            },
            fnChange: handleChange,
          },
        },
      ], // column
    }, // Row
  ];

export const ComponentSearchCreateDocCrime = ({
  options = {},
  isDisable = {},
  handleChange = () => null,
}) => {
  const { company, location, depNo, subDepNo, startDate, endDate } = DEFINE
  return [
    { // Row
      rowProps: { justify: "start", align: "bottom" },
      column: [ // column
        {
          breakpoint: { xs: 24, sm: 12, md: 12, lg: 6, xl: 4 },
          component: {
            type: COMPONENT_TYPE.SELECT,
            itemProps: {
              name: company.name,
              label: company.label,
              rules: [{ required: true, message: ERROR_MESSAGE.SELECT(company.label) }],
            },
            componentProps: {
              style: { width: "100%" },
              placeholder: ERROR_MESSAGE.SELECT(company.label),
              disabled: isDisable[company.name] || false,
            },
            fnChange: (value) => handleChange(company.name, value),
            dropdown: options[company.name],
          },
        },
        {
          breakpoint: { xs: 24, sm: 12, md: 12, lg: 6, xl: 4 },
          component: {
            type: COMPONENT_TYPE.SELECT,
            itemProps: {
              name: location.name,
              label: location.label,
              rules: [{ required: true, message: ERROR_MESSAGE.SELECT(location.label) }],
            },
            componentProps: {
              style: { width: "100%" },
              placeholder: ERROR_MESSAGE.SELECT(location.label),
              disabled: isDisable[location.name] || false,
            },
            fnChange: (value) => handleChange(location.name, value),
            dropdown: options[location.name],
          },
        },
        {
          breakpoint: { xs: 24, sm: 24, md: 24, lg: 12, xl: 8 },
          component: {
            type: COMPONENT_TYPE.SELECT,
            itemProps: {
              name: depNo.name,
              label: depNo.label,
              rules: [{ required: false, message: ERROR_MESSAGE.SELECT(depNo.label) }],
            },
            componentProps: {
              style: { width: "100%" },
              placeholder: ERROR_MESSAGE.SELECT(depNo.label),
              disabled: isDisable[depNo.name] || false,
            },
            fnChange: (value) => handleChange(depNo.name, value),
            dropdown: options[depNo.name],
          },
        },
        {
          breakpoint: { xs: 24, sm: 24, md: 24, lg: 12, xl: 8 },
          component: {
            type: COMPONENT_TYPE.SELECT,
            itemProps: {
              name: subDepNo.name,
              label: subDepNo.label,
              rules: [{ required: false, message: ERROR_MESSAGE.SELECT(subDepNo.label) }],
            },
            componentProps: {
              style: { width: "100%" },
              placeholder: ERROR_MESSAGE.SELECT(subDepNo.label),
              disabled: isDisable[subDepNo.name] || false,
            },
            fnChange: (value) => handleChange(subDepNo.name, value),
            dropdown: options[subDepNo.name],
          },
        },
        {
          breakpoint: { xs: 24, sm: 12, md: 12, lg: 6, xl: 4 },
          component: {
            type: COMPONENT_TYPE.DATE,
            itemProps: {
              name: startDate.name,
              label: startDate.label,
              rules: [{ required: false, message: ERROR_MESSAGE.DATEPICKER }],
            },
            componentProps: {
              style: { width: "100%" },
              placeholder: ERROR_MESSAGE.DATEPICKER,
              disabled: isDisable[startDate.name] || false,
            },
            fnChange: (date, dateString) => handleChange(startDate.name, dateString),
          },
        },
        {
          breakpoint: { xs: 24, sm: 12, md: 12, lg: 6, xl: 4 },
          component: {
            type: COMPONENT_TYPE.DATE,
            itemProps: {
              name: endDate.name,
              label: endDate.label,
              rules: [{ required: false, message: ERROR_MESSAGE.DATEPICKER }],
            },
            componentProps: {
              style: { width: "100%" },
              placeholder: ERROR_MESSAGE.DATEPICKER,
              disabled: isDisable[endDate.name] || false,
            },
            fnChange: (date, dateString) => handleChange(endDate.name, dateString),
          },
        },
      ], // column
    }, // Row
  ]
}

export const ComponentPrepareDateDocCrime = ({
  isDisable = {},
  handleChange = () => null,
}) => {
  const { prepareDate } = DEFINE
  return [
    { // Row
      rowProps: { justify: "start", align: "bottom" },
      column: [ // column
        {
          breakpoint: { xs: 24, sm: 12, md: 12, lg: 6, xl: 4 },
          component: {
            type: COMPONENT_TYPE.DATE,
            itemProps: {
              name: prepareDate.name,
              label: prepareDate.label,
              rules: [{ required: true, message: ERROR_MESSAGE.DATEPICKER }],
            },
            componentProps: {
              style: { width: "100%" },
              placeholder: ERROR_MESSAGE.DATEPICKER,
              disabled: isDisable[prepareDate.name] || false,
            },
            fnChange: (date, dateString) => handleChange(prepareDate.name, dateString),
          },
        },
      ], // column
    }, // Row
  ]
}
