import React, { createContext, useReducer, useState } from "react";

import {
  APIDashboardPersonal,
  APIVerifyUser,
  APIInsertPersonal,
  APISelectPersonal,
  APIUpdatePersonal,
  APIGetUserCode,
  APIDashboardImportPersonal,
  APIInsertProbationHistory,
  APIExportProsoft,
  APIExportTigerSoft,
  APILineNoti,
  APIGetDISC,
} from "./personalConnect";
import { KEY_ACTIVE_TAB } from "../../constants/enums/enumCenter";
import { FIELD_EMP } from "../constantsPersonal/contents/employeeInfo";
import { SET_GETDATA, SET_POSTDATA } from "../functions";
import { FIELD_STEP } from "../constantsPersonal";
// import { downloadFile } from "../../constants/functions";
import {
  POST,
  POST_SELECT_DATA_DASHBOARD_EMPLOYEE,
  POST_SELECT_DATA_DASHBOARD_VIEW,
} from "../../service";
import FileDownload from "js-file-download";

export const PersonalContext = createContext();

export const KEY_PERSONAL = {
  /* ---------------------------------- Pages --------------------------------- */

  EXPORT_PROSOFT: "EXPORT_PROSOFT",
  EXPORT_TIGER_SOFT: "EXPORT_TIGER_SOFT",
  VERIFY_EMP: "VERIFY_EMP",
  GET_USER_CODE: "GET_USER_CODE",
  INSERT_PERSONAL: "INSERT_PERSONAL",
  IMPORT_PERSONAL: "IMPORT_PERSONAL",
  UPDATE_PERSONAL: "UPDATE_PERSONAL",
  BLOCK_PERSONAL: "BLOCK_PERSONAL",
  DASHBOARD_IMPORT_PERSONAL_ID_CARD: "DASHBOARD_IMPORT_PERSONAL_ID_CARD",
  SELECT_EMP_PERSONAL: "SELECT_EMP_PERSONAL",
  DASHBOARD_PERSONAL: "DASHBOARD_PERSONAL",
  DASHBOARD_PERSONAL_VIEW: "DASHBOARD_PERSONAL_VIEW",
  DASHBOARD_IMPORT_PERSONAL: "DASHBOARD_IMPORT_PERSONAL",
  INSERT_PROBATION_HISTORY: "INSERT_PROBATION_HISTORY",
  SELECT_TYPE_DISC: "SELECT_TYPE_DISC",

  /* -------------------------------------------------------------------------- */
};

//TODO : กำหนดข้อมูลตั้งต้น
const initialState = {
  //TODO: แก้ไขพนักงาน
  [KEY_PERSONAL.DASHBOARD_PERSONAL]: {
    dataSource: {
      [KEY_ACTIVE_TAB.UPDATE_PERSONAL]: [],
    },
    rowKey: {
      [KEY_ACTIVE_TAB.UPDATE_PERSONAL]: null,
    },
  },
  //TODO: Import ดูข้อมูลพนักงาน
  [KEY_PERSONAL.DASHBOARD_PERSONAL_VIEW]: {
    dataSource: {
      [KEY_ACTIVE_TAB.VIEW_PERSONAL]: [],
    },
    rowKey: {
      [KEY_ACTIVE_TAB.VIEW_PERSONAL]: null,
    },
  },
  //TODO: Import พนักงานใหม่
  [KEY_PERSONAL.DASHBOARD_IMPORT_PERSONAL]: {
    dataSource: {
      [KEY_ACTIVE_TAB.IMPORT_PERSONAL]: [],
    },
    rowKey: {
      [KEY_ACTIVE_TAB.IMPORT_PERSONAL]: null,
    },
  },
  [KEY_PERSONAL.INSERT_PERSONAL]: {
    [FIELD_EMP.ACCIDENT_PLAN]: "p1",
    [FIELD_EMP.HEALTH_PLAN]: "p1",
  },
  [KEY_PERSONAL.UPDATE_PERSONAL]: null,
  [KEY_PERSONAL.DASHBOARD_IMPORT_PERSONAL_ID_CARD]: {
    dataSource: {
      [KEY_ACTIVE_TAB.IMPORT_PERSONAL_ID_CARD]: [],
    },
    rowKey: {
      [KEY_ACTIVE_TAB.IMPORT_PERSONAL_ID_CARD]: null,
    },
  },
};

//TODO : กำหนดตัวแปร
const PERSONAL_ACTION = {
  SET_PERSONAL_ACTION_SUCCESS: "SET_PERSONAL_ACTION_SUCCESS",
  SET_PERSONAL_ACTION_FAILURE: "SET_PERSONAL_ACTION_FAILURE",
};

//TODO : กำหนด Reducer
const personalReducer = (state, action) => {
  switch (action.type) {
    case PERSONAL_ACTION.SET_PERSONAL_ACTION_SUCCESS:
      return { ...state, ...action.state };
    case PERSONAL_ACTION.SET_PERSONAL_ACTION_FAILURE:
    default:
      return state;
  }
};

//TODO : กำหนด Provider
//! อย่าลืมเพิ่ม Provider ในไฟล์  (\src\moduleKPIs\services\provider\providerState.jsx)
const ProviderPersonal = ({ children }) => {
  const { userLogin, userPermission, userLocation } = children.props;
  const [personalState, personalDispatch] = useReducer(
    personalReducer,
    initialState
  );
  const [loadingAPI, setloadingAPI] = useState(false);

  async function fetchAPIPersonal({ key, data, pathname }) {
    let payload = null;
    let response = null;
    try {
      setloadingAPI(true);
      switch (key) {
        /* -------------------------------------------------------------------------- */
        /*                                VERIFY_EMP                               */
        /* -------------------------------------------------------------------------- */

        case KEY_PERSONAL.VERIFY_EMP:
          payload = {
            personalId: data[FIELD_EMP.PERSONAL_ID],
          };
          response = await APIVerifyUser(payload);
          if (response) {
            return response;
          }
          break;

        /* -------------------------------------------------------------------------- */
        /*                                GET_USER_CODE                               */
        /* -------------------------------------------------------------------------- */

        case KEY_PERSONAL.GET_USER_CODE:
          payload = { ...data };
          response = await APIGetUserCode(payload);
          if (response) {
            return response;
          }
          break;

        /* -------------------------------------------------------------------------- */
        /*                             SELECT_EMP_PERSONAL                            */
        /* -------------------------------------------------------------------------- */

        case KEY_PERSONAL.SELECT_EMP_PERSONAL:
          /**
           * ถ้าส่ง userId จะใช้ในหน้า Edit
           * ถ้าส่ง personalId จะใช้ในการตรวจสอบข้อมูลพนักงาน
           * ถ้าส่ง candidateNo จะใช้ใน หน้า Import พนักงาน
           */
          payload = {
            userId: data[FIELD_EMP.EMPLOYEE_ID] || "",
            personalId: data[FIELD_EMP.PERSONAL_ID] || "",
            candidateNo: data[FIELD_EMP.CANDIDATE_NO] || "",
          };

          response = await Promise.all([
            APISelectPersonal(payload),
            //! line Noti
            APILineNoti({
              header: `FRONTEND : ${userLogin}`,
              title: `📊 ${key}`,
              message: `payload : ${JSON.stringify({ ...payload })}`,
            }),
          ]);
          response = response[0];
          /**
           * กรณีส่งค่าว่าง => { success : false }
           * กรณีส่งค่าที่ไม่มีจริง => { success : false }
           * กรณีส่งค่าที่มีจริง => { success : true , result : { empInfo: {...}}}
           */

          // console.log(`${key}`, { response })
          if (response.success) {
            //console.log('response.result', response.result)
            let finalResult = {};
            if (response.result.empInfo) {
              const { empInfo } = response.result;
              //TODO : !!data[FIELD_EMP.PERSONAL_ID] ถ้ามี personalId  ส่งมาค้นหา จะต้องสร้าง UserId ใหม่ เพระามองว่า Insert
              if (!!empInfo && !!data[FIELD_EMP.PERSONAL_ID]) {
                /* -------------- กรณีที่มีข้อมูลพนักงาน จะต้อง GET UserID ใหม่ ------------- */
                const payloadUser = { company: empInfo.company };
                const userCode = await APIGetUserCode({ ...payloadUser });
                // console.log({ userCode })
                empInfo[FIELD_EMP.EMPLOYEE_ID] = userCode || ""; //? ถ้า userCode เป็น undefined ให้เป็นค่าว่าง
                /* -------------------------------------------------------------------------- */
              }

              //TODO : SET_GETDATA >> ดู Field Modify ที่ '/src/modulePersonal/unctions/index.jsx'
              finalResult = SET_GETDATA(
                {
                  ...empInfo,
                  ...empInfo[FIELD_STEP.STEP_0], //empInfo
                  ...empInfo[FIELD_STEP.STEP_1], //personal
                  ...empInfo[FIELD_STEP.STEP_4], //learning
                  ...response.result,
                  ...(response.result.evidencesInfo || {}),
                },
                userLogin
              );
            } else {
              finalResult = response.result;
            }

            //TODO : บันทึกค่าเข้า Context
            personalDispatch({
              type: PERSONAL_ACTION.SET_PERSONAL_ACTION_SUCCESS,
              state: {
                [KEY_PERSONAL.INSERT_PERSONAL]: { ...finalResult },
                [KEY_PERSONAL.UPDATE_PERSONAL]: { ...finalResult },
              },
            });
            //TODO : ส่งค่าไปหน้า ManagePersonal
            return {
              success: response.success,
              result: finalResult,
            };
          }

          return {
            success: false,
            result: SET_GETDATA({}),
            message: response.message,
          };
        case KEY_PERSONAL.DASHBOARD_PERSONAL:
          payload = {
            company: data.company || "",
            dep: data.depNo || "",
            subDep: data.subDepNo || "",
            location: data.location || "",
            userID: data.userID || "",
            statusWorker: data.statusWorker || "",
            startDateJobFrom: data.startDate || "",
            startDateJobTo: data.endDate || "",
            isBlock: `${+data.isBlock || 0}`,
            endDateJobFrom: data.endDateJobFrom || "",
            endDateJobTo: data.endDateJobTo || "",
          };

          try {
            //console.log("payload", payload)
            response = await Promise.all([
              // POST(POST_SELECT_EMPLOYEE, payload),
              POST(POST_SELECT_DATA_DASHBOARD_EMPLOYEE, payload),
              APILineNoti({
                header: `FRONTEND : ${userLogin}`,
                title: `📊 ${key}`,
                message: `payload : ${JSON.stringify({ ...payload })}`,
              }),
            ]);

            response = response[0];
          } catch (error) {
            response = { result: [] };
            console.log(error);
          }

          personalDispatch({
            type: PERSONAL_ACTION.SET_PERSONAL_ACTION_SUCCESS,
            state: {
              [key]: {
                dataSource: {
                  [KEY_ACTIVE_TAB.UPDATE_PERSONAL]: response.result || [],
                },
                rowKey: {
                  [KEY_ACTIVE_TAB.UPDATE_PERSONAL]: "ID_User",
                },
              },
            },
          });
          break;

        case KEY_PERSONAL.DASHBOARD_PERSONAL_VIEW:
          payload = {
            loginUserID: userLogin,
            //loginUserID: "ZT58025",
            company: data.company || "",
            dep: data.depNo || "",
            subDep: data.subDepNo || "",
            location: data.location || "",
            userID: data.userID || "",
            statusWorker: data.statusWorker || "",
            startDateJobFrom: data.startDate || "",
            startDateJobTo: data.endDate || "",
            isBlock: `${+data.isBlock || 0}`,
            endDateJobFrom: data.endDateJobFrom || "",
            endDateJobTo: data.endDateJobTo || "",
          };

          try {
            response = await POST(POST_SELECT_DATA_DASHBOARD_VIEW, payload);
          } catch (error) {
            response = { result: [] };
            console.log(error);
          }
          console.log(response);
          personalDispatch({
            type: PERSONAL_ACTION.SET_PERSONAL_ACTION_SUCCESS,
            state: {
              [key]: {
                dataSource: {
                  [KEY_ACTIVE_TAB.VIEW_PERSONAL]: response.result || [],
                },
                rowKey: {
                  [KEY_ACTIVE_TAB.VIEW_PERSONAL]: "ID_User",
                },
              },
            },
          });
          break;

        /* -------------------------------------------------------------------------- */
        /*                          DASHBOARD_IMPORT_PERSONAL                         */
        /* -------------------------------------------------------------------------- */

        case KEY_PERSONAL.DASHBOARD_IMPORT_PERSONAL:
          payload = { candidateNo: data.candidateNo || "" };
          response = await Promise.all([
            APIDashboardImportPersonal(payload),
            //! line Noti
            APILineNoti({
              header: `FRONTEND : ${userLogin}`,
              title: `📊 ${key}`,
              message: `payload : ${JSON.stringify({ ...payload })}`,
            }),
          ]);
          response = response[0];
          if (!!response) {
            // //! line Noti
            //  APILineNoti({
            //     header: `FRONTEND : ${userLogin}`,
            //     title: `📊 ${key}`,
            //     message: `✅ SUCCESS`,
            // })
            personalDispatch({
              type: PERSONAL_ACTION.SET_PERSONAL_ACTION_SUCCESS,
              state: {
                [key]: {
                  dataSource: {
                    [KEY_ACTIVE_TAB.IMPORT_PERSONAL]: response.result || [],
                  },
                  rowKey: {
                    [KEY_ACTIVE_TAB.IMPORT_PERSONAL]: "candidateNo",
                  },
                },
              },
            });
          }
          break;
        /* -------------------------------------------------------------------------- */

        /* -------------------------------------------------------------------------- */
        /*                               INSERT_PERSONAL                              */
        /* -------------------------------------------------------------------------- */

        case KEY_PERSONAL.INSERT_PERSONAL:
          //TODO:แปลงก้อนข้อมูล
          payload = SET_POSTDATA({ ...data }, userLogin);
          response = await Promise.all([
            APIInsertPersonal(payload),
            //! line Noti
            APILineNoti({
              header: `FRONTEND : ${userLogin}`,
              title: `📋 ${key}`,
              message: `empInfo : ${JSON.stringify({ ...payload.empInfo })}`,
            }),
          ]);
          response = response[0];
          if (!!response && response.success) {
            //! line Noti
            APILineNoti({
              header: `FRONTEND : ${userLogin}`,
              title: `✅ ${key}`,
              message: `SUCCESS`,
            });
          }
          return response;
        /* -------------------------------------------------------------------------- */

        case KEY_PERSONAL.UPDATE_PERSONAL:
        case KEY_PERSONAL.BLOCK_PERSONAL:
          payload = SET_POSTDATA({ ...data }, userLogin);
          response = await Promise.all([
            APIUpdatePersonal(payload),
            //! line Noti
            APILineNoti({
              header: `FRONTEND : ${userLogin}`,
              title: `📋 ${key}`,
              message: `empInfo : ${JSON.stringify({ ...payload.empInfo })}`,
            }),
          ]);
          response = response[0];
          if (!!response && response.success) {
            //! line Noti
            APILineNoti({
              header: `FRONTEND : ${userLogin}`,
              title: `✅ ${key}`,
              message: `SUCCESS`,
            });
            return response;
          }

          return false;

        case KEY_PERSONAL.INSERT_PROBATION_HISTORY:
          payload = { ...data };
          response = await Promise.all([
            APIInsertProbationHistory(payload),
            //! line Noti
            APILineNoti({
              header: `FRONTEND : ${userLogin}`,
              title: `📋 ${key}`,
              message: `payload : ${JSON.stringify({ ...payload })}`,
            }),
          ]);
          response = response[0];
          if (!!response) {
            //! line Noti
            APILineNoti({
              header: `FRONTEND : ${userLogin}`,
              title: `✅ ${key}`,
              message: `SUCCESS`,
            });
            return response;
          }
          break;

        case KEY_PERSONAL.EXPORT_PROSOFT:
          payload = { ...data };
          response = await Promise.all([APIExportProsoft(payload)]);

          if (response.length > 0) {
            response = response[0];
            await FileDownload(
              response.data,
              `PMS_PROSOFT_${Date.now("nano")}.xls`
            );
          }

          break;

        case KEY_PERSONAL.EXPORT_TIGER_SOFT:
          payload = { ...data };
          response = await Promise.all([APIExportTigerSoft(payload)]);

          if (response.length > 0) {
            response = response[0];
            await FileDownload(
              response.data,
              `PMS_TIGER_SOFT_${Date.now("nano")}.xls`
            );
          }

          break;

        case KEY_PERSONAL.SELECT_TYPE_DISC:
          payload = { ...data };
          response = await APIGetDISC(payload);
          return response.result;
        default:
          break;
      }
    } catch (error) {
      //! line Noti
      APILineNoti({
        header: "FRONTEND",
        title: `payload : ${JSON.stringify({ ...payload })}`,
        message: `⛔️ ${key} : ${error.message}  `,
      });
      setloadingAPI(false);
      console.log("fetchAPI Personal : ", { error });
    } finally {
      setloadingAPI(false);
    }
  }

  function clearPersonal() {
    return personalDispatch({
      type: PERSONAL_ACTION.SET_PERSONAL_ACTION_SUCCESS,
      state: {
        //TODO: แก้ไขพนักงาน
        [KEY_PERSONAL.DASHBOARD_PERSONAL]: {
          dataSource: {
            [KEY_ACTIVE_TAB.UPDATE_PERSONAL]: [],
          },
          rowKey: {
            [KEY_ACTIVE_TAB.UPDATE_PERSONAL]: null,
          },
        },
        //TODO: Import พนักงานใหม่
        [KEY_PERSONAL.DASHBOARD_IMPORT_PERSONAL]: {
          dataSource: {
            [KEY_ACTIVE_TAB.IMPORT_PERSONAL]: [],
          },
          rowKey: {
            [KEY_ACTIVE_TAB.IMPORT_PERSONAL]: null,
          },
        },
        [KEY_PERSONAL.INSERT_PERSONAL]: null,
        [KEY_PERSONAL.UPDATE_PERSONAL]: null,
      },
    });
  }

  return (
    <PersonalContext.Provider
      value={{
        userLogin,
        userPermission,
        userLocation,
        loadingAPI,
        fetchAPIPersonal,
        clearPersonal,
        personalState,
        personalDispatch,
      }}
    >
      {children}
    </PersonalContext.Provider>
  );
};

export default ProviderPersonal;
