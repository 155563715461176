import React from "react";
import "../index.css";
import "bootstrap/dist/css/bootstrap.css";
import {
  Col,
  Row,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  Card,
  CardHeader,
  CardBody,
} from "reactstrap";
import "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import { CSVLink } from "react-csv";
import { MdFileDownload } from "react-icons/md";
import { SetStringToObject } from "../constants/functions";
import Cookies from "js-cookie";
import { GET, SELECT_DASHBOARD_RECHECK_GRADE } from "../service";
import { getCurrent } from "../extendFunction/getCurrentYears";
import { ColumnDashboardGrade } from "../modulePMS/constantsPMS/columns";
import {
  RenderDepartmentPMS,
  RenderYearPMS_5Years,
} from "../modulePMS/componentsPMS/dropdown";
import { loadingPage } from "../components/loadingPage";
import { Modal } from "antd";

const obj = SetStringToObject(Cookies.get("cookie_session"));
class DashboardGrade extends React.Component {
  constructor(props) {
    super(props);
    const { yearsSelect } = getCurrent();
    const { userLogin } = props;
    this.state = {
      SegmentTxt: "",
      yearsTxt: yearsSelect,
      Years: yearsSelect,
      statusCode: "1",
      loadData: [],
      dataArrCF: [],
      done: false,
      userLogin,
    };
  }

  onLoadFinish = () => {
    this.setState({ done: true });
  };

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  search = async () => {
    try {
      if (!this.state.done) {
        return;
      }
      const { SegmentTxt, yearsTxt, statusCode, userLogin } = this.state;
      const { premission } = obj;
      this.setState({ loadData: [] });
      if (yearsTxt !== "0") {
        this.setState({ done: false });
        const queryObject = {
          SegmentTxt,
          yearsTxt,
          userLogin,
          statusCode,
          premission,
        };
        const response = await GET(SELECT_DASHBOARD_RECHECK_GRADE(queryObject));
        const { dataCF, data } = response.result;
        //console.log(data)
        let loadData = [];
        if (response.length !== 0) {
          loadData = data.map((row, index) => ({ RowID: index, ...row }));
        }
        this.setState({ loadData, dataArrCF: dataCF });
      } else {
        Modal.warning({ title: "กรุณาเลือกปีประเมินให้ครบถ้วน !!" });
      }
    } catch (err) {
      console.log("Error SELECT DASHBOARD GRADE : ", err);
    } finally {
      this.setState({ done: true });
    }
  };

  render() {
    const { userLogin } = this.state;
    return (
      <div>
        <Card>
          <CardHeader>Dashboard Grade</CardHeader>
          <CardBody>
            <Form>
              <Row>
                <Col md={3}>
                  <RenderYearPMS_5Years onChange={this.onChange} />
                </Col>
                <Col md={3}>
                  <RenderDepartmentPMS
                    userLogin={userLogin}
                    onChange={this.onChange}
                    onLoadFinish={this.onLoadFinish}
                  />
                </Col>
                <Col md={3}>
                  <FormGroup>
                    <Label>สถานะการประเมิน</Label>
                    <Input
                      type="select"
                      name="statusCode"
                      id="Status"
                      onChange={this.onChange}
                    >
                      <option value="1"> ยืนยันผลคะแนนเรียบร้อยแล้ว </option>
                    </Input>
                  </FormGroup>
                </Col>
                <Col md={3} className="text-center cs_btn">
                  <FormGroup>
                    <Button color="info" onClick={this.search}>
                      SEARCH
                    </Button>
                  </FormGroup>
                </Col>
              </Row>
            </Form>
          </CardBody>
        </Card>
        <hr />
        {this.state.done ? (
          <>
            {this._renderButtonExportCSV()}
            {this._renderTable()}
          </>
        ) : (
          <div>{loadingPage} </div>
        )}
      </div>
    );
  } // render

  _renderTable = () => {
    const { loadData } = this.state;

    const sizePerPageRenderer = ({
      options,
      currSizePerPage,
      onSizePerPageChange,
    }) => (
      <div className="btn-group" role="group">
        {options.map((option, i) => {
          const isSelect = currSizePerPage === `${option.page}`;
          return (
            <button
              key={`${option.text}${i}`}
              type="button"
              onClick={() => onSizePerPageChange(option.page)}
              className={`btn ${isSelect ? "btn-secondary" : "btn-primary"}`}
            >
              {option.text}
            </button>
          );
        })}
      </div>
    );

    const opt = {
      sizePerPageRenderer,
    };
    return (
      <BootstrapTable
        keyField="RowID"
        data={loadData}
        columns={ColumnDashboardGrade()}
        ref={(n) => (this.node = n)}
        striped
        hover
        condensed
        pagination={paginationFactory(opt)}
        wrapperClasses="table-responsive"
      />
    );
  };

  _renderButtonExportCSV = () => {
    const { loadData } = this.state;
    const headerCSV = [
      { label: "ปี", key: "years" },
      { label: "รหัสพนักงาน", key: "idUser" },
      { label: "ชื่อ - สกุล", key: "staffName" },
      { label: "รหัสแผนก", key: "depNo" },
      { label: "แผนก", key: "depName" },
      { label: "คะแนนรวม", key: "totalScore" },
      { label: "ผลการประเมิน", key: "gradeDes" },
    ];
    return (
      <div className="btn-right">
        <CSVLink
          headers={headerCSV}
          data={loadData}
          filename={"คะแนนผลการประเมินประจำปี.csv"}
          className="btn btn-export"
        >
          <MdFileDownload /> Export CSV
        </CSVLink>
      </div>
    );
  };
}
export default DashboardGrade;
