import { useEffect, useState } from "react";
import {
  GET,
  GET_SELECT_OPTION_DEPARTMENT_ALL,
  GET_SELECT_OPTION_SUB_DEPARTMENT,
  GET_OPTION_BENEFITS,
  GET_SELECT_OPTION_WORKSHIFT_ALL,
} from "../../../../service";

export const useGetOptions = () => {
  const [optionsCompanyTH, setOptionsCompanyTH] = useState([]);
  const [optionsPositionEN, setOptionsPositionEN] = useState([]);
  const [optionsPositionTH, setOptionsPositionTH] = useState([]);
  const [optionsDepartmentTH, setOptionsDepartmentTH] = useState([]);
  const [optionsSubDepartmentTH, setOptionsSubDepartmentTH] = useState([]);
  const [optionsWorkShift, setOptionsWorkShift] = useState([]);
  const [loadingOption, setLoadingOption] = useState({
    company: false,
    position: false,
    department: false,
    subDepartment: false,
    workShift: false,
  });

  useEffect(() => {
    onFetchOptionsCompany();
    onFetchPosition();
    onFetchDepartment();
    onFetchSubDepartment();
    onFetchWorkShift();
  }, []);

  useEffect(() => {}, []);

  const onFetchOptionsCompany = async () => {
    try {
      setLoadingOption((prev) => ({ ...prev, company: true }));
      const respond = await GET(GET_OPTION_BENEFITS("company"));
      setOptionsCompanyTH(() =>
        respond.result.data.map((e) => ({
          label: e.companyNameTH,
          value: e.companyNameTH,
          others: { e },
        }))
      );
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingOption((prev) => ({ ...prev, company: false }));
    }
  };

  const onFetchPosition = async () => {
    try {
      setLoadingOption((prev) => ({ ...prev, position: true }));
      const respond = await GET(GET_OPTION_BENEFITS("position"));
      const positionNameEN = respond.result.data.map((e) => e.positionNameEN);
      const positionNameTH = respond.result.data.map((e) => e.positionNameTH);
      setOptionsPositionEN(() =>
        positionNameEN
          .filter((element, index) => {
            return positionNameEN.indexOf(element) === index;
          })
          .map((e) => ({
            label: e,
            value: e,
          }))
      );
      setOptionsPositionTH(() =>
        positionNameTH
          .filter((element, index) => {
            return positionNameTH.indexOf(element) === index;
          })
          .map((e) => ({
            label: e,
            value: e,
          }))
      );
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingOption((prev) => ({ ...prev, position: false }));
    }
  };

  const onFetchDepartment = async () => {
    try {
      setLoadingOption((prev) => ({ ...prev, department: true }));
      const respond = await GET(GET_SELECT_OPTION_DEPARTMENT_ALL("", ""));
      setOptionsDepartmentTH(() =>
        respond.result.map((e) => ({ label: e.depNameTH, value: e.depNameTH }))
      );
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingOption((prev) => ({ ...prev, department: false }));
    }
  };
  
  const onFetchSubDepartment = async () => {
    try {
      setLoadingOption((prev) => ({ ...prev, subDepartment: true }));
      const respond = await GET(GET_SELECT_OPTION_SUB_DEPARTMENT("", ""));
      setOptionsSubDepartmentTH(() =>
        respond.result.map((e) => ({ label: e.subDepName, value: e.subDepName }))
      );
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingOption((prev) => ({ ...prev, subDepartment: false }));
    }
  };

  const onFetchWorkShift = async () => {
    try {
      setLoadingOption((prev) => ({ ...prev, workShift: true }));
      const respond = await GET(GET_SELECT_OPTION_WORKSHIFT_ALL(""));
      setOptionsWorkShift(() =>
        respond.result.map((e) => ({ label: e.workShiftNameTH, value: e.workShiftNameTH }))
      );
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingOption((prev) => ({ ...prev, workShift: false }));
    }
  }

  return {
    optionsCompanyTH,
    optionsPositionEN,
    optionsPositionTH,
    optionsDepartmentTH,
    optionsWorkShift,
    optionsSubDepartmentTH,
    loadingOption:
      loadingOption.company &&
      loadingOption.position &&
      loadingOption.department &&
      loadingOption.subDepartment &&
      loadingOption.workShift,
  };
};
