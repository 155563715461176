import React, { useEffect, useState } from "react";
import { FormGroup, Label, Input } from "reactstrap";
import { getCurrent } from "../../extendFunction/getCurrentYears";
import {
  GET,
  GET_SELECT_DEPARTMENT_SUPER_ADMIN,
  GET_SELECT_PERMISSION,
} from "../../service";
export const RenderDepartmentPMS = ({
  userLogin,
  onChange = () => null,
  onLoadFinish = () => null,
}) => {
  const [departments, setDepartments] = useState([]);
  useEffect(() => {
    onFetchDepartment();
  }, []);

  const onFetchDepartment = async () => {
    try {
      const res = await GET(GET_SELECT_PERMISSION(userLogin));
      const { result } = res;
      const { depArr } = result;
      setDepartments(depArr);
      onLoadFinish();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <FormGroup>
      <Label for="Type">แผนก / กลุ่ม</Label>
      <Input type="select" name="SegmentTxt" id="segment" onChange={onChange}>
        <option value="all">-----All-----</option>
        {departments.map((val, i) => (
          <option value={val.depNo} key={`${val.depNo}${i}`}>
            {val.depNo + " :: " + val.depName}
          </option>
        ))}
      </Input>
    </FormGroup>
  );
};

export const RenderDepartment_SuperAdmin = ({
  onChange = () => null,
  onLoadFinish = () => null,
}) => {
  const [departments, setDepartments] = useState([]);
  useEffect(() => {
    onFetchDepartment();
  }, []);

  const onFetchDepartment = async () => {
    try {
      const res = await GET(GET_SELECT_DEPARTMENT_SUPER_ADMIN());
      const { department: departmentSuperAdmin } = res;
      setDepartments(departmentSuperAdmin);
      onLoadFinish();
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <FormGroup>
      <Label for="Type">แผนก / กลุ่ม</Label>
      <Input type="select" name="SegmentTxt" id="segment" onChange={onChange}>
        <option value="all">-----All-----</option>
        {departments.map((val, i) => (
          <option value={val.depNo} key={`${val.depNo}${i}`}>
            {val.depNo + " :: " + val.Department_Name}
          </option>
        ))}
      </Input>
    </FormGroup>
  );
};

export const RenderStatusEvaluatePMS = ({ onChange = () => null }) => {
  return (
    <FormGroup>
      <Label>สถานะการประเมิน</Label>
      <Input type="select" name="statusCode" id="Status" onChange={onChange}>
        <option value={"ALL"}>-----All-----</option>
        <option value={"0"}>ยังไม่ทำแบบประเมิน</option>
        <option value={"1"}>รอยืนยันการประเมิน (แบบร่าง)</option>
        <option value={"2"}>จบการประเมิน</option>
      </Input>
    </FormGroup>
  );
};

export const RenderStatusEvaluateDiscussion = ({ onChange = () => null }) => {
  return (
    <FormGroup>
      <Label>สถานะการประเมิน</Label>
      <Input type="select" name="statusCode" id="Status" onChange={onChange}>
        <option value="all">-----All-----</option>
        <option value="0"> รอประเมินตนเอง </option>
        <option value="1"> รอประเมินทีม </option>
        <option value="2"> รอผู้ประเมินอันดับ 1 </option>
        <option value="3">แบบร่าง (รอยืนยันผู้ประเมินอันดับ 1)</option>
        <option value="4">รอยืนยันประเมินจากผู้ประเมินอันดับ 2</option>
        <option value="6"> รอรับทราบผลการประเมิน </option>
        <option value="5"> จบการประเมิน </option>
      </Input>
    </FormGroup>
  );
};

export const RenderStatusEvaluateCF_Discussion = ({
  onChange = () => null,
}) => {
  return (
    <FormGroup>
      <Label>สถานะการประเมิน</Label>
      <Input type="select" name="statusCode" id="Status" onChange={onChange}>
        <option value="all">-----All-----</option>
        <option value="0"> อยู่ระหว่างดำเนินการ </option>
        <option value="1"> รอยืนยันรับทราบผลประเมิน </option>
        <option value="2">ยืนยันรับทราบผลประเมินแล้ว</option>
      </Input>
    </FormGroup>
  );
};

export const RenderStatusPerformance = ({ onChange = () => null }) => {
  return (
    <FormGroup>
      <Label>สถานะการประเมิน</Label>
      <Input type="select" name="statusCode" id="Status" onChange={onChange}>
        <option value="all">-----All-----</option>
        <option value="0"> รอประเมินตนเอง </option>
        <option value="1"> รอผู้ประเมินอันดับ 1 </option>
        <option value="2">แบบร่าง (รอยืนยันผู้ประเมินอันดับ 1)</option>
        <option value="3"> รอยืนยันประเมิน </option>
        <option value="4"> จบการประเมิน </option>
      </Input>
    </FormGroup>
  );
};

export const RenderStatusRecheckGrade = ({ onChange = () => null }) => {
  return (
    <FormGroup>
      <Label>สถานะการประเมิน</Label>
      <Input type="select" name="statusCode" id="Status" onChange={onChange}>
        <option value="all">-----All-----</option>
        <option value="0"> ยังไม่ได้ยืนยันผลคะแนน </option>
        <option value="1">ยืนยันผลคะแนนเรียบร้อยแล้ว</option>
      </Input>
    </FormGroup>
  );
};

export const RenderYearPMS = ({ onChange = () => null }) => {
  const year = getCurrent().yearsNow;
  const yearsSelect = getCurrent().yearsSelect;
  return (
    <FormGroup>
      <Label>
        <span className="red"> * </span>การประเมินประจำปี
      </Label>
      <Input
        type="select"
        name="yearsTxt"
        id="Years"
        onChange={onChange}
        defaultValue={yearsSelect}
      >
        <option value={year}>{year}</option>
        <option value={year - 1}>{year - 1}</option>
        <option value={year - 2}>{year - 2}</option>
      </Input>
    </FormGroup>
  );
};

export const RenderYearPMS_5Years = ({ onChange = () => null }) => {
  const year = getCurrent().yearsNow;
  const yearsSelect = getCurrent().yearsSelect;
  return (
    <FormGroup>
      <Label>
        <span className="red"> * </span>การประเมินประจำปี
      </Label>
      <Input
        type="select"
        name="yearsTxt"
        id="Years"
        onChange={onChange}
        defaultValue={yearsSelect}
      >
        <option value={year}>{year}</option>
        <option value={year - 1}>{year - 1}</option>
        <option value={year - 2}>{year - 2}</option>
        <option value={year - 3}>{year - 3}</option>
        <option value={year - 4}>{year - 4}</option>
      </Input>
    </FormGroup>
  );
};

export const RenderYearPMS_PEF = ({ onChange = () => null }) => {
  const year = getCurrent().yearsNow;
  const yearsSelect = getCurrent().yearsSelect;
  return (
    <FormGroup>
      <Label>
        <span className="red"> * </span>การประเมินประจำปี
      </Label>
      <Input
        type="select"
        name="yearsTxt"
        id="Years"
        defaultValue={yearsSelect}
        onChange={onChange}
      >
        <option value={year}>{year}</option>
        <option value={year - 1}>{year - 1}</option>
        <option value={year - 2}>{year - 2}</option>
        <option value={year - 3}>{year - 3}</option>
      </Input>
    </FormGroup>
  );
};

export const RenderTypeEvaluatePMS = ({ onChange = () => null }) => {
  const typeSelect = getCurrent().typeSelect;
  return (
    <FormGroup>
      <Label>
        <span className="red"> * </span>ประเภทการประเมิน
      </Label>
      <Input
        type="select"
        name="typeTXt"
        id="Type"
        defaultValue={typeSelect}
        onChange={onChange}
      >
        <option value="Half">ครั้งที่ 1 (ม.ค. - มิ.ย.)</option>
        <option value="Full">ครั้งที่ 2 (ม.ค. - ธ.ค.)</option>
      </Input>
    </FormGroup>
  );
};

export const RenderCF_HR = ({ onChange = () => null }) => {
  return (
    <FormGroup>
      <Label>
        <span className="red"> * </span>สถานะเอกสาร
      </Label>
      <Input type="select" name="CF_HR" id="CF_HR" onChange={onChange}>
        <option value="">-----All-----</option>
        <option value="0">แบบร่าง</option>
        <option value="1">ยืนยันผลเรียบร้อยแล้ว</option>
      </Input>
    </FormGroup>
  );
};
