import React from 'react'
import styled from 'styled-components'
import { Tabs } from 'antd';

import { requiredLabel } from './function';
import { SR_COLORS, SR_SIZES } from './index.jsx';

const TabsStyle = styled(Tabs)`
    display:contents;
    border-radius: ${SR_SIZES.RADIUS}px;  
    width: ${props => props.width ? `${props.width}px` : `${100}%`};
    padding: ${15}px ${0}px ;
    margin:${15}px ${0}px ${0}px ${0}px
`


const TextStyle = styled.div`
    display: contens;
    align-items: center;
    color: ${props => props.color ? props.color : SR_COLORS.HEADER};
	font-weight: ${props => props.weight ? props.weight : ''};  
	font-size: ${props => props.size ? `${props.size}px` : `${SR_SIZES.TEXT}px`};  
    padding-right: ${10}px
    `

export const LayoutTabs = ({ type, defaultKey, tabsLists, onChange, extraContent, ...rest }) => {

    function callback(key) {
        if (typeof onChange === 'function') {
            onChange(key)
        }
    }

    return (
        <TabsStyle
            type={type}
            defaultActiveKey={defaultKey}
            onChange={callback}
            tabBarExtraContent={extraContent}
            {...rest} >
            {
                Array.isArray(tabsLists) && tabsLists.map((element, elementIndex) => {
                    return (
                        <Tabs.TabPane
                            key={elementIndex + 1}
                            tab={
                                //renderName Tabs
                                <TextStyle >
                                    {element.icon}
                                    {requiredLabel({
                                        required: element.required,
                                        label: element.tab,
                                        color: element.color
                                    })}
                                </TextStyle>
                            }>
                            {
                                element.content //renderTable
                            }
                        </Tabs.TabPane>
                    )
                })
            }
        </TabsStyle>
    )
};
