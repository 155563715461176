export const ERROR_MESSAGE = {
  INPUT: (name) => `กรุณากรอก ${name}`,
  SELECT: (name) => `กรุณาเลือก ${name}`,
  DATEPICKER: `กรุณาเลือก วันที่`,
  DATEPICKERRANG: [`กรุณาเลือก วันที่เริ่ม`,'ถึงวันที่'],
};

export const COMPONENT_TYPE = {
  INPUT: "input",
  INPUT_NUMBER: "inputNumber",
  AREA: "area",
  SELECT: "select",
  BUTTON: "button",
  DATE: "datePicker",
  RANG_PICKER:"datePickerRang",
  CHECK_BOX: "checkBox",
  UPLOAD: "upload",
};
