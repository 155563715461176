import React from "react";
import { URL_IMAGE } from "../../components/connectAPI";
import {
  FiXCircle,
  FiEdit,
  FiSend,
  FiFileText,
  FiPenTool,
  FiUserCheck,
  FiClipboard,
} from "react-icons/fi";
import { Col, Button } from "reactstrap";
import { Link } from "react-router";
import encodeString from "../../extendFunction/encodeString";
import { filterSearchColumn } from "../../screens/functions/UseFunction";
//import { searchFilter } from "../../components/tables/searchfilter/SearchFilter";

export const ColumnSelfAssessment = ({
  userLogin,
  permission,
  openPrintSAF = () => null,
  delRow = () => null,
  openEditSAF = () => null,
  openCreateSAF = () => null,
}) => {
  return [
    {
      dataField: "index",
      text: "#",
      formatter: (cellContent, row, index) => (
        <div>
          <Col>{index + 1}</Col>
        </div>
      ),
    },
    {
      dataField: "action",
      text: "Action",
      sort: true,
      formatter: (cellContent, row) => {
        let viewSAF = "-";
        if (row.ID_User === userLogin && row.statusCode === "0") {
          // เป็นรหัสตัวเองและยังไม่ได้ทำแบบประเมิน  && (row.statusCode === 0)
          viewSAF = (
            <Button color="success" onClick={openCreateSAF} id={row.DocNo}>
              Create
            </Button>
          );
        } else if (
          row.ID_User !== userLogin &&
          (row.statusCode === "0" || row.statusCode === "1")
        ) {
          // ไม่ใช่รหัสตัวเองและยังไม่ได้ทำแบบประเมิน
        } else if (row.ID_User === userLogin && row.statusCode === "1") {
          //เป็นรหัสตัวเองและ ยังร่างอยู่
          viewSAF = (
            <div>
              <Button color="warning" onClick={openEditSAF} id={row.DocNo}>
                Edit
              </Button>
              &nbsp; &nbsp; &nbsp;
              <Button color="danger" onClick={delRow} id={row.DocNo}>
                Del
              </Button>
            </div>
          );
        } else if (row.statusCode === "2") {
          if (row.DepartmentNo === "D01") {
            if (
              row.ID_User === userLogin ||
              row.Approvers_No1 === userLogin ||
              row.Approvers_No2 === userLogin ||
              // permission === "MD-Super"
              permission === "MD-OFF"
            ) {
              viewSAF = (
                <Button color="info" onClick={openPrintSAF} id={row.DocNo}>
                  View
                </Button>
              );
            }
          } else {
            viewSAF = (
              <Button color="info" onClick={openPrintSAF} id={row.DocNo}>
                View
              </Button>
            );
          }
        }

        return (
          <div>
            <center>{viewSAF}</center>
          </div>
        );
      },
    },
    {
      dataField: "status",
      text: "status",
      sort: true,
      formatter: (cellContent, row) => {
        switch (+row.statusCode) {
          case 0:
            return (
              <div>
                <FiXCircle className="margin-icon" color="#FF5733" size={20} />
                ยังไม่ทำแบบประเมิน
              </div>
            );
          case 1:
            return (
              <div>
                <FiEdit className="margin-icon" color="#95A5A6" size={20} />
                รอยืนยันการประเมิน (แบบร่าง)
              </div>
            );
          case 2:
            return (
              <div>
                <FiSend className="margin-icon" color="#28B463" size={20} />
                เรียบร้อยแล้ว
              </div>
            );
        }
      },
    },
    {
      dataField: "user_id",
      text: "รหัสพนักงาน",
      sort: true,
      formatter: (cellContent, row) => {
        return (
          <div>
            <Link target="_blank" to={URL_IMAGE + row.ID_User.toLowerCase() + ".jpg"}>
              <b>{row.ID_User}</b>
            </Link>
          </div>
        );
      },
    },
    {
      dataField: "Name",
      text: "ชื่อ - สกุล",
      sort: true,
    },
    {
      dataField: "NickName",
      text: "ชื่อเล่น",
      sort: true,
    },
    {
      dataField: "Department_Name",
      text: "แผนก",
      sort: true,
    },
    {
      dataField: "Years",
      text: "ปี",
      sort: true,
    },
    {
      dataField: "TypeDes",
      text: "ประเภทการประเมิน",
      sort: true,
    },
  ];
};


export const ColumnSelfAssessmentNew = ({
  userLogin,
  permission,
  openPrintSAF = () => null,
  delRow = () => null,
  openEditSAF = () => null,
  openCreateSAF = () => null,
}) => {
  return [
    {
      dataIndex: "index",
      key:'index',
      title: "#",
      render: (cellContent, row, index) => {
        return index + 1
      },
    },
    {
      dataIndex: "action",
      title: "Action",
      key:"action",
      render: (cellContent, row) => {
        let viewSAF = "-";
        if (row.ID_User === userLogin && row.statusCode === "0") {
          // เป็นรหัสตัวเองและยังไม่ได้ทำแบบประเมิน  && (row.statusCode === 0)
          viewSAF = (
            <Button color="success" onClick={openCreateSAF} id={row.DocNo}>
              Create
            </Button>
          );
        } else if (
          row.ID_User !== userLogin &&
          (row.statusCode === "0" || row.statusCode === "1")
        ) {
          // ไม่ใช่รหัสตัวเองและยังไม่ได้ทำแบบประเมิน
        } else if (row.ID_User === userLogin && row.statusCode === "1") {
          //เป็นรหัสตัวเองและ ยังร่างอยู่
          viewSAF = (
            <div>
              <Button color="warning" onClick={openEditSAF} id={row.DocNo}>
                Edit
              </Button>
              &nbsp; &nbsp; &nbsp;
              <Button color="danger" onClick={delRow} id={row.DocNo}>
                Del
              </Button>
            </div>
          );
        } else if (row.statusCode === "2") {
          if (row.DepartmentNo === "D01") {
            if (
              row.ID_User === userLogin ||
              row.Approvers_No1 === userLogin ||
              row.Approvers_No2 === userLogin ||
              // permission === "MD-Super"
              permission === "MD-OFF"
            ) {
              viewSAF = (
                <Button color="info" onClick={openPrintSAF} id={row.DocNo}>
                  View
                </Button>
              );
            }
          } else {
            viewSAF = (
              <Button color="info" onClick={openPrintSAF} id={row.DocNo}>
                View
              </Button>
            );
          }
        }

        return (
          <div>
            <center>{viewSAF}</center>
          </div>
        );
      },
    },
    {
      dataIndex: "status",
      title: "status",
      key: 'status',
      filters: [
        {
          text: "ยังไม่ทำแบบประเมิน",
          value: "0",
        },
        {
          text: "รอยืนยันการประเมิน (แบบร่าง)",
          value: "1",
        },
        {
          text: "เรียบร้อยแล้ว",
          value: "2",
        },
      ],
      onFilter: (value, record) => record.statusCode.indexOf(value) == 0,
      render: (cellContent, row) => {
        switch (+row.statusCode) {
          case 0:
            return (
              <div>
                <FiXCircle className="margin-icon" color="#FF5733" size={20} />
                ยังไม่ทำแบบประเมิน
              </div>
            );
          case 1:
            return (
              <div>
                <FiEdit className="margin-icon" color="#95A5A6" size={20} />
                รอยืนยันการประเมิน (แบบร่าง)
              </div>
            );
          case 2:
            return (
              <div>
                <FiSend className="margin-icon" color="#28B463" size={20} />
                เรียบร้อยแล้ว
              </div>
            );
        }
      },
    },
    {
      dataIndex: "user_id",
      title: "รหัสพนักงาน",
      key: 'user_id',
      ...filterSearchColumn("user_id", "รหัสพนักงาน"),
      render: (cellContent, row) => {
        return (
          <div>
            <Link target="_blank" to={URL_IMAGE + row.ID_User.toLowerCase() + ".jpg"}>
              <b>{row.ID_User}</b>
            </Link>
          </div>
        );
      },
    },
    {
      dataIndex: "Name",
      key: "Name",
      title: "ชื่อ - สกุล",
      ...filterSearchColumn("Name", "ชื่อ - สกุล"),
    },
    {
      dataIndex: "NickName",
      key: "NickName",
      title: "ชื่อเล่น",
      ...filterSearchColumn("NickName", "ชื่อเล่น"),
    },
    {
      dataIndex: "Department_Name",
      key: "Department_Name",
      title: "แผนก",
      ...filterSearchColumn("Department_Name", "แผนก"),
    },
    {
      dataIndex: "Years",
      key: "Years",
      title: "ปี",
      ...filterSearchColumn("Years", "ปี"),
    },
    {
      dataIndex: "TypeDes",
      key: "TypeDes",
      title: "ประเภทการประเมิน",
      ...filterSearchColumn("TypeDes", "ประเภทการประเมิน"),
    },
  ];
};









export const ColumnDiscussionForm = ({
  userLogin,
  permission,
  openUpdateDIS = () => null,
  printDIS = () => null,
  openCreateForm = () => null,
  openEditDIS = () => null,
}) => {
  return [
    {
      dataIndex: "index",
      key:'index',
      title: "#",
      render: (cellContent, row, index) => {
        return (
          <div>
            <Col>{index + 1}</Col>
          </div>
        );
      },
    },
    {
      dataIndex: "action",
      key:'action',
      title: "Action",
      render: (cellContent, row) => {
        let viewDIS = "-";
        if (row.Approvers_No === userLogin && row.statusCode === "4") {

          console.log('8888888',row.statusCode)
          //เป็นผู้อนุมัติอันดับ 2 และยังไม่ได้กดส่งข้อมูลให้ HR
          return (
            <div>
              <Col>
                <Button
                  color="warning"
                  onClick={openUpdateDIS}
                  id={row.DocNo_DI}
                >
                  Update
                </Button>
              </Col>
            </div>
          );
        } else if (row.Boss === userLogin && row.statusCode === "2") {
          //ยังไม่เคยสร้าง PE เลย เป็นคนอนุมัติอันดับแรก
          return (
            <div>
              <Col>
                <Button
                  color="success"
                  onClick={openCreateForm}
                  id={row.DocNo_SA + "=)" + row.sameApprover}
                >
                  Create
                </Button>
              </Col>
            </div>
          );
        } else if (row.Boss === userLogin && row.statusCode === "3") {
          //เป็นคนอนุมัติอันดับแรก และเอกสารยังไม่ได้ส่งให้คนที่ 2
          return (
            <div>
              <Col>
                <Button color="danger" onClick={openEditDIS} id={row.DocNo_DI}>
                  Edit
                </Button>
              </Col>
            </div>
          );
        } else if (row.statusCode === "5" || row.statusCode === "6") {
          if (row.DepartmentNo === "D01") {
            // ถ้าแผนกผู้บริหาร
            if (
              row.Approvers_No2 === userLogin ||
              row.Approvers_No1 === userLogin ||
              //permission === "MD-Super"
              permission === "MD-OFF"
            ) {
              viewDIS = (
                <Button color="info" onClick={printDIS} id={row.DocNo_DI}>
                  View
                </Button>
              );
            }
          } else {
            viewDIS = (
              <Button color="info" onClick={printDIS} id={row.DocNo_DI}>
                View
              </Button>
            );
          }
          return (
            <div>
              <Col>{viewDIS}</Col>
            </div>
          );
        }
      },
    },
    {
      dataIndex: "status",
      key:"status",
      title: "สถานะ",
      filters: [
        {
          text: "รอประเมินตนเอง",
          value: "0",
        },
        {
          text: "รอประเมินผลงาน",
          value: "1",
        },
        {
          text: "รอผู้ประเมินอันดับ 1",
          value: "2",
        },
        {
          text: "แบบร่าง",
          value: "3",
        },
        {
          text: "รอยืนยันประเมิน",
          value: "4",
        },
        {
          text: "จบการประเมิน",
          value: "5",
        },
        {
          text: "รอรับทราบผลการประเมิน",
          value: "6",
        }
      ],
      onFilter: (value, record) => record.statusCode.indexOf(value) == 0,
      render: (cellContent, row) => {
        switch (+row.statusCode) {
          case 0:
            return (
              <div>
                <FiXCircle className="margin-icon" color="#FF5733" size={20} />
                รอประเมินตนเอง
              </div>
            );
          case 1:
            return (
              <div>
                <FiXCircle className="margin-icon" color="#F1C40F" size={20} />
                รอประเมินผลงาน
              </div>
            );
          case 2:
            return (
              <div>
                <FiFileText className="margin-icon" color="#C39BD3" size={20} />
                รอผู้ประเมินอันดับ 1  <br />
                <center>( {row.userNameBoss})</center>
              </div>
            );
          case 3:
            return (
              <div>
                <FiEdit className="margin-icon" color="#95A5A6" size={20} />
                แบบร่าง <br /> <center>( {row.Approvers_LV1} )</center>
              </div>
            );
          case 4:
            return (
              <div>
                <FiPenTool className="margin-icon" color="#5DADE2" size={20} />
                รอยืนยันประเมิน <br /> <center>( {row.Approvers_LV2} )</center>
              </div>
            );
          case 5:
            return (
              <div>
                <FiSend className="margin-icon" color="#28B463" size={20} />
                จบการประเมิน
              </div>
            );
          case 6:
            return (
              <div>
                <FiUserCheck
                  className="margin-icon"
                  color="#8E44AD"
                  size={20}
                />
                รอรับทราบผลการประเมิน
              </div>
            );
        } // Switch
      },
    },
    {
      dataIndex: "user_id",
      key:"user_id",
      title: "รหัสพนักงาน",
      ...filterSearchColumn("user_id", "รหัสพนักงาน"),
      render: (cellContent, row) => {
        return (
          <div>
            <Link target="_blank" to={URL_IMAGE + row.ID_User.toLowerCase() + ".jpg"}>
              <b>{row.ID_User}</b>
            </Link>
          </div>
        );
      },
    },
    {
      dataIndex: "Name",
      key:'Name',
      title: "ชื่อ - สกุล",
      ...filterSearchColumn("Name", "ชื่อ - สกุล"),
    },
    {
      dataIndex: "NickName",
      title: "ชื่อเล่น",
      key:'NickName',
      ...filterSearchColumn("NickName", "ชื่อเล่น"),
    },
    {
      dataIndex: "Department_Name",
      title: "แผนก",
      key:'Department_Name',
      ...filterSearchColumn("Department_Name", "แผนก"),
    },
    {
      dataIndex: "Years",
      title: "ปี",
      key:'Years',
      ...filterSearchColumn("Years", "ปี"),
    },
    {
      dataIndex: "TypeDes",
      title: "ประเภทการประเมิน",
      key:'TypeDes',
      ...filterSearchColumn("TypeDes", "ประเภทการประเมิน"),
    },
    {
      dataIndex: "evaluateSelf",
      key:'evaluateSelf',
      title: "ชุดประเมินตนเอง",
      render: (cellContent, row, index) => {
        let linkViewSAF = <b>{row.DocNo_SA}</b>;
        if (row.DepartmentNo === "D01") {
          // ถ้าแผนกผู้บริหาร
          if (
            row.Approvers_No1 === userLogin ||
            row.Approvers_No2 === userLogin ||
            //  permission === "MD-Super"
            permission === "MD-OFF"
          ) {
            linkViewSAF = (
              <Link
                target="_blank"
                to={"/PrintSelfAssessment/" + encodeString.encode(row.DocNo_SA)}
              >
                <b>{row.DocNo_SA}</b>
              </Link>
            );
          }
        } else {
          linkViewSAF = (
            <Link
              target="_blank"
              to={"/PrintSelfAssessment/" + encodeString.encode(row.DocNo_SA)}
            >
              <b>{row.DocNo_SA}</b>
            </Link>
          );
        }
        return (
          <div>
            <Col>{linkViewSAF}</Col>
          </div>
        );
      },
    },
    {
      dataIndex: "evaluateJob",
      title: "ชุดประเมินผลงาน",
      key:"evaluateJob",
      render: (cellContent, row, index) => {
        let linkViewPEF = (
          <Link
            target="_blank"
            to={
              "/PrintPE_" +
              row.Level_Worker +
              "/" +
              encodeString.encode(row.DocNo_PE)
            }
          >
            <b>{row.DocNo_PE}</b>
          </Link>
        );
        //if (row.DepartmentNo === "D01" && permission !== "MD-Super") {
        if (row.DepartmentNo === "D01" && permission !== "MD-OFF") {
          linkViewPEF = <b>{row.DocNo_PE}</b>;
          if (row.Approvers_No2 === userLogin) {
            linkViewPEF = (
              <Link
                target="_blank"
                to={
                  "/PrintPE_" +
                  row.Level_Worker +
                  "/" +
                  encodeString.encode(row.DocNo_PE)
                }
              >
                <b>{row.DocNo_PE}</b>
              </Link>
            );
          }
          //} else if (permission === "Head" || permission === "HR-Admin") {
        } else if (
          permission === "Head-FAC" ||
          permission === "Head-OFF" ||
          permission === "HRD-OFF" ||
          permission === "HRD-FAC" ||
          permission === "HRM-OFF" ||
          permission === "HRM-FAC"
        ) {
          linkViewPEF = <b>{row.DocNo_PE}</b>;
        }
        return (
          <div>
            <Col>{linkViewPEF}</Col>
          </div>
        );
      },
    },
  ];
};

export const ColumnConfirmDiscussion = ({
  userLogin,
  permission,
  printDIS = () => null,
  openCFDIS = () => null,
}) => {
  return [
    {
      dataIndex: "index",
      key:"index",
      title: "#",
      formatter: (cellContent, row, index) => {
        return (
          <div>
            <Col>{index + 1}</Col>
          </div>
        );
      },
    },
    {
      dataIndex: "action",
      title: "Action",
      key: "action",
      render: (cellContent, row) => {
        const isUser_dont = row.ID_User === userLogin && row.statusCode === "0";
        const noUser_wait = row.ID_User === userLogin && row.statusCode === "0";
        const isUser_wait = row.ID_User === userLogin && row.statusCode === "1";
        const complete = row.statusCode === "2"; //จบการประเมินตนเองแล้ว
        let viewSAF = "-";
        if (isUser_dont || noUser_wait) {
          // เป็นรหัสตัวเองและยังไม่ได้ทำแบบประเมิน และ ไม่ใช่รหัสตัวเองและยังไม่ได้รับทราบแบบประเมิน
          return (
            <div>
              <Col>
                <center> - </center>
              </Col>
            </div>
          );
        } else if (isUser_wait) {
          //เป็นรหัสตัวเองและ รอรับทราบแบบประเมิน
          return (
            <div>
              <Col>
                <center>
                  <Button
                    color="success"
                    onClick={openCFDIS}
                    id={row.Ref_DocDiscus}
                  >
                    Confirm
                  </Button>
                </center>
              </Col>
            </div>
          );
        } else if (complete) {
          if (row.DepartmentNo === "D01") {
            // ถ้าแผนกผู้บริหาร
            if (
              row.ID_User === userLogin ||
              row.Approvers_No1 === userLogin ||
              row.Approvers_No2 === userLogin ||
              //permission === "MD-Super"
              permission === "MD-OFF"
            ) {
              viewSAF = (
                <Button color="info" onClick={printDIS} id={row.Ref_DocDiscus}>
                  View
                </Button>
              );
            }
          } else {
            viewSAF = (
              <Button color="info" onClick={printDIS} id={row.Ref_DocDiscus}>
                View
              </Button>
            );
          }
          return (
            <div>
              <Col>
                <center>{viewSAF}</center>
              </Col>
            </div>
          );
        }
      },
      style: {
        color: "blue",
      },
    },
    {
      dataIndex: "status",
      title: "status",
      key: "status",
      filters: [
        {
          text: "อยู่ระหว่างดำเนินการ",
          value: "0",
        },
        {
          text: "รอยืนยันรับทราบผลประเมิน",
          value: "1",
        },
        {
          text: "รับทราบผลประเมินแล้ว",
          value: "2",
        },
      ],
      onFilter: (value, record) => record.statusCode.indexOf(value) == 0,
      render: (cellContent, row) => {
        switch (+row.statusCode) {
          case 0:
            return (
              <div>
                <FiXCircle className="margin-icon" color="#FF5733" size={20} />
                อยู่ระหว่างดำเนินการ
              </div>
            );
          case 1:
            return (
              <div>
                <FiEdit className="margin-icon" color="#95A5A6" size={20} />
                รอยืนยันรับทราบผลประเมิน
              </div>
            );
          case 2:
            return (
              <div>
                <FiSend className="margin-icon" color="#28B463" size={20} />
                รับทราบผลประเมินแล้ว
              </div>
            );
        }
      },
    },
    {
      dataIndex: "user_id",
      title: "รหัสพนักงาน",
      key: "user_id",
      render: (cellContent, row) => {
        return (
          <div>
            <Link target="_blank" to={URL_IMAGE + row.ID_User.toLowerCase() + ".jpg"}>
              <b>{row.ID_User}</b>
            </Link>
          </div>
        );
      },
      ...filterSearchColumn("user_id", "รหัสพนักงาน"),
    },
    {
      dataIndex: "Name",
      title: "ชื่อ - สกุล",
      key: "Name",
      ...filterSearchColumn("Name", "ชื่อ - สกุล"),
    },
    {
      dataIndex: "NickName",
      title: "ชื่อเล่น",
      key: "NickName",
      ...filterSearchColumn("NickName", "ชื่อเล่น"),
    },
    {
      dataIndex: "Department_Name",
      title: "แผนก",
      key: "Department_Name",
      ...filterSearchColumn("Department_Name", "แผนก"),
    },
    {
      dataIndex: "Years",
      title: "ปี",
      key: "Years",
      ...filterSearchColumn("Years", "ปี"),
    },
    {
      dataIndex: "TypeDes",
      title: "ประเภทการประเมิน",
      key: "TypeDes",
    },
  ];
};

export const ColumnPerformance = ({
  userLogin,
  permission,
  openUpdatePEF = () => null,
  openCreateForm = () => null,
  openViewPEF = () => null,
  openEditPEF = () => null,
}) => {
  return [
    {
      dataIndex: "index",
      key: "index",
      title: "#",
      render: (cellContent, row, index) => {
        return (
          <div>
            <Col>{index + 1}</Col>
          </div>
        );
      },
    },
    {
      dataIndex: "action",
      key: "action",
      title: "Action",
      render: (cellContent, row) => {
        let viewPEF = "-";
        if (row.Approvers_No === userLogin && row.statusCode === "3") {
          //เป็นผู้อนุมัติอันดับ 2 และยังไม่ได้กดส่งข้อมูลให้ HR
          return (
            <div>
              <Col>
                <Button
                  color="warning"
                  onClick={openUpdatePEF}
                  id={row.DocNo_PE + "=)" + row.Level_Worker}
                >
                  Update
                </Button>
              </Col>
            </div>
          );
        } else if (row.Boss === userLogin && row.statusCode === "1") {
          //ยังไม่เคยสร้าง PE เลย เป็นคนอนุมัติอันดับแรก
          return (
            <div>
              <Col>
                <Button
                  color="success"
                  onClick={openCreateForm}
                  id={
                    row.DocNo_SA +
                    "=)" +
                    row.Level_Worker +
                    "=)" +
                    row.sameApprover
                  }
                >
                  Create
                </Button>
              </Col>
            </div>
          );
        } else if (
          row.Boss === userLogin &&
          row.statusCode === "3" &&
          row.ApprovalDate === null
        ) {
          //เป็นคนอนุมัติอันดับแรก และส่งรอคนที่ 2 อนุมัติ และคนที่ 2 ยังไม่มีการแก้ไขใดๆ
          return (
            <div>
              <Col>
                <Button
                  color="info"
                  onClick={openViewPEF}
                  id={row.DocNo_PE + "=)" + row.Level_Worker}
                >
                  View
                </Button>
              </Col>
            </div>
          );
        } else if (row.Boss === userLogin && row.statusCode === "2") {
          //เป็นคนอนุมัติอันดับแรก และเอกสารยังไม่ได้ส่งให้คนที่ 2
          return (
            <div>
              <Col>
                <Button
                  color="danger"
                  onClick={openEditPEF}
                  id={row.DocNo_PE + "=)" + row.Level_Worker}
                >
                  Edit
                </Button>
              </Col>
            </div>
          );
        } else if (row.statusCode === "4") {
          if (row.DepartmentNo === "D01") {
            // ถ้าแผนกผู้บริหาร
            //   if (row.Approvers_No2 === userLogin || permission === "MD-Super") {
            if (row.Approvers_No2 === userLogin || permission === "MD-OFF") {
              viewPEF = (
                <Button
                  color="info"
                  onClick={openViewPEF}
                  id={row.DocNo_PE + "=)" + row.Level_Worker}
                >
                  View
                </Button>
              );
            }
          } else if (permission !== "Head-OFF" || permission !== "Head-FAC") {
            viewPEF = (
              <Button
                color="info"
                onClick={openViewPEF}
                id={row.DocNo_PE + "=)" + row.Level_Worker}
              >
                View
              </Button>
            );
          }
          return (
            <div>
              <Col>{viewPEF}</Col>
            </div>
          );
        } else {
          return (
            <div>
              <Col> - </Col>
            </div>
          );
        }
      },
    },
    {
      dataIndex: "status",
      title: "สถานะ",
      key: "status",
      filters: [
        {
          text: "รอประเมินตนเอง",
          value: "0",
        },
        {
          text: "รอผู้ประเมินอันดับ 1",
          value: "1",
        },
        {
          text: "แบบร่าง",
          value: "2",
        },
        {
          text: "รอยืนยันประเมิน",
          value: "3",
        },
        {
          text: "จบการประเมิน",
          value: "4",
        }
      ],
      onFilter: (value, record) => record.statusCode.indexOf(value) == 0,
      render: (cellContent, row) => {
        switch (+row.statusCode) {
          case 0:
            return (
              <div>
                <FiXCircle className="margin-icon" color="#FF5733" size={20} />
                รอประเมินตนเอง
              </div>
            );
          case 1:
            return (
              <div>
                <FiClipboard
                  className="margin-icon"
                  color="#F1C40F"
                  size={20}
                />
                รอผู้ประเมินอันดับ 1<br/>
                <center>( {row.Approvers_LV1})</center>
              </div>
            );
          case 2:
            return (
              <div>
                <FiEdit className="margin-icon" color="#95A5A6" size={20} />
                แบบร่าง <br /> <center>( {row.Approvers_LV1})</center>
              </div>
            );
          case 3:
            return (
              <div>
                <FiPenTool className="margin-icon" color="#5DADE2" size={20} />
                รอยืนยันประเมิน <br /> <center>( {row.Approvers_LV2})</center>
              </div>
            );
          case 4:
            return (
              <div>
                <FiSend className="margin-icon" color="#28B463" size={20} />
                จบการประเมิน
              </div>
            );
        } // Switch
      },
    },
    {
      dataIndex: "user_id",
      title: "รหัสพนักงาน",
      key: "user_id",
      render: (cellContent, row) => {
        return (
          <div>
            <Link target="_blank" to={URL_IMAGE + row.ID_User.toLowerCase() + ".jpg"}>
              <b>{row.ID_User}</b>
            </Link>
          </div>
        );
      },
      ...filterSearchColumn("user_id", "รหัสพนักงาน"),
    },
    {
      dataIndex:"Name",
      title: "ชื่อ - สกุล",
      key:"Name",
      render: (cellContent, row, index) => {
        return (
          <div>
            <Col>
              {row.Name}
              <input
                type="hidden"
                name="Level_Worker"
                id="Level_Worker"
                value={row.Level_Worker}
              />
            </Col>
          </div>
        );
      },
      ...filterSearchColumn("Name", "ชื่อ - สกุล"),
    },
    {
      dataIndex: "NickName",
      title: "ชื่อเล่น",
      key: "NickName",
      ...filterSearchColumn("NickName", "ชื่อเล่น"),
    },
    {
      dataIndex: "Level_WorkerDes",
      title: "ตำแหน่ง",
      key: "Level_WorkerDes",
      ...filterSearchColumn("Level_WorkerDes", "ตำแหน่ง"),
    },
    {
      dataIndex: "Department_Name",
      title: "แผนก",
      key: "Department_Name",
      ...filterSearchColumn("Department_Name", "แผนก"),
    },
    {
      dataIndex: "Years",
      title: "ปี",
      key: "Years",
      ...filterSearchColumn("Years", "ปี"),
    },
    {
      title: "ชุดประเมินตนเอง",
      render: (cellContent, row, index) => {
        let linkViewSAF = <b>{row.DocNo_SA}</b>;
        if (row.DepartmentNo === "D01") {
          // ถ้าแผนกผู้บริหาร
          if (
            row.Approvers_No1 === userLogin ||
            row.Approvers_No2 === userLogin ||
            // permission === "MD-Super"
            permission === "MD-OFF"
          ) {
            linkViewSAF = (
              <Link
                target="_blank"
                to={"/PrintSelfAssessment/" + encodeString.encode(row.DocNo_SA)}
              >
                <b>{row.DocNo_SA}</b>
              </Link>
            );
          }
        } else {
          linkViewSAF = (
            <Link
              target="_blank"
              to={"/PrintSelfAssessment/" + encodeString.encode(row.DocNo_SA)}
            >
              <b>{row.DocNo_SA}</b>
            </Link>
          );
        }
        return (
          <div>
            <Col>{linkViewSAF}</Col>
          </div>
        );
      },
    },
  ];
};

export const ColumnRecheckGrade = () => {
  return [
    {
      dataField: "index",
      text: "#",
      editable: false,
      formatter: (cellContent, row, index) => {
        return (
          <div>
            <Col>{index + 1}</Col>
          </div>
        );
      },
    },
    {
      dataField: "statusCF",
      text: "statusCF",
      sort: true,
      editable: false,
      formatter: (cellContent, row) => {
        let iconA = "";
        if (row.CF === 0) {
          iconA = (
            <div>
              <FiXCircle className="margin-icon" color="#FF5733" size={20} />
            </div>
          ); //"#B03A2E"
        } else if (row.CF === 1) {
          iconA = (
            <div>
              <FiSend className="margin-icon" color="#28B463" size={20} />
            </div>
          ); //"#1E8449"
        }
        return <div>{iconA}</div>;
      },
    },
    {
      dataField: "years",
      text: "Years",
      sort: true,
      editable: false,
    },
    {
      dataField: "docNo",
      text: "PE Doc",
      sort: true,
      editable: false,
    },
    {
      dataField: "idUser",
      text: "รหัสพนักงาน",
      sort: true,
      editable: false,
    },
    {
      dataField: "staffName",
      text: "ชื่อ - สกุล",
      sort: true,
      editable: false,
      formatter: (cellContent, row) => {
        return <div>{row.staffName}</div>;
      },
    },
    {
      dataField: "depNo",
      text: "รหัสแผนก",
      sort: true,
      editable: false,
    },
    {
      dataField: "depName",
      text: "ชื่อแผนก",
      sort: true,
      editable: false,
    },
    {
      dataField: "totalScore",
      text: "คะแนนรวม",
      sort: true,
      editable: false,
      style: {
        textAlign: "center",
      },
    },
    {
      dataField: "grade",
      text: "Grade",
      sort: true,
      editCellStyle: {
        width: "100px",
      },
    },
    {
      dataField: "pg",
      text: "PG",
      sort: true,
      editCellStyle: {
        width: "100px",
      },
    },
    {
      dataField: "jg",
      text: "JG",
      sort: true,
      editCellStyle: {
        width: "100px",
      },
    },
  ];
};

export const ColumnDashboardGrade = () => {
  return [
    {
      dataField: "index",
      text: "#",
      editable: false,
      formatter: (cellContent, row, index) => {
        return (
          <div>
            <Col>{index + 1}</Col>
          </div>
        );
      },
    },
    {
      dataField: "status",
      text: "สถานะ",
      sort: true,
      editable: false,
      formatter: (cellContent, row) => {
        if (row.CF === 0) {
          return (
            <div>
              <FiXCircle className="margin-icon" color="#FF5733" size={20} />
              ยังไม่ยืนยันผล
            </div>
          );
        } else if (row.CF === 1) {
          return (
            <div>
              <FiSend className="margin-icon" color="#28B463" size={20} />
              เรียบร้อยแล้ว
            </div>
          );
        }
      },
    },
    {
      dataField: "years",
      text: "ปี",
      sort: true,
      editable: false,
    },
    {
      dataField: "docNo",
      text: "ชุดประเมินผลงาน",
      sort: true,
      editable: false,
    },
    {
      dataField: "idUser",
      text: "รหัสพนักงาน",
      sort: true,
      editable: false,
    },
    {
      dataField: "staffName",
      text: "ชื่อ - สกุล",
      sort: true,
      editable: false,
      formatter: (cellContent, row) => {
        return <div>{row.staffName}</div>;
      },
    },
    {
      dataField: "depNo",
      text: "รหัสแผนก",
      sort: true,
      editable: false,
    },
    {
      dataField: "depName",
      text: "ชื่อแผนก",
      sort: true,
      editable: false,
    },
    {
      dataField: "totalScore",
      text: "คะแนนรวม",
      sort: true,
      editable: false,
      style: {
        textAlign: "center",
      },
    },
    {
      dataField: "gradeDes",
      text: "ผลการประเมิน",
      sort: true,
      editCellStyle: {
        width: "100px",
      },
    },
  ];
};

export const ColumnImportNumDayDailyWorker = [
  {
    title: "#",
    dataIndex: "rowNum",
    key: "rowNum",
    align: "right",
    width: 100,
  },
  {
    title: "ปี",
    dataIndex: "year",
    key: "year",
    align: "right",
    width: 100,
    ...filterSearchColumn("year", "ปี"),
  },
  {
    title: "เดือน",
    dataIndex: "month",
    key: "month",
    align: "right",
    width: 100,
    ...filterSearchColumn("month", "เดือน"),
  },
  {
    title: "รหัสพนักงาน",
    dataIndex: "employeeId",
    key: "employeeId",
    align: "left",
    width: 150,
    ...filterSearchColumn("employeeId", "รหัสพนักงาน"),
  },
  {
    title: "ชื่อ - นามสกุล",
    dataIndex: "Name",
    key: "Name",
    align: "left",
    width: 200,
    ...filterSearchColumn("Name", "ชื่อ - นามสกุล"),
  },
  {
    title: "แผนก (ย่อย)",
    dataIndex: "subDepName",
    key: "subDepName",
    align: "left",
    width: 400,
    ...filterSearchColumn("subDepName", "แผนก (ย่อย)"),
  },
  {
    title: "เกรด",
    dataIndex: "grade",
    key: "grade",
    align: "left",
    width: 100,
    ...filterSearchColumn("grade", "เกรด"),
  },
  {
    title: "จำนวนวันทำงาน",
    dataIndex: "numDay",
    key: "numDay",
    align: "right",
    width: 150,
    sorter: (a, b) => a.numDay - b.numDay,
  },
  {
    title: "จำนวนเงินได้",
    dataIndex: "amount",
    key: "amount",
    align: "right",
    width: 150,
    sorter: (a, b) => a.amount - b.amount,
  },
];
