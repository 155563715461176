import { responseAPI } from "../../../moduleKPIs/common/functions/funcResponse";
import servicesAPI from "../../../moduleKPIs/services/servicesAPI";

let API = servicesAPI.create()

export async function APIDashboardOTFactory(payload, nextAction) {
    try {
        const response = await API.dashboardOTFactory(payload);
        responseAPI(response, nextAction)
    } catch (error) {
        console.error('error : ', error)
    }
}
export async function APIExportOTFactory(payload, nextAction) {
    try {
        const response = await API.exportOTFac(payload);
        responseAPI(response, nextAction)
    } catch (error) {
        console.error('error : ', error)
    }
}


// export async function APISelectFilterRequestOT(payload, nextAction) {
//     try {
//         const response = await API.selectFilterRequestOT(payload);
//         responseAPI(response, nextAction)
//     } catch (error) {
//         console.error('error : ', error)
//     }
// }


export async function APISelectReportOT(payload, nextAction) {
    try {
        const response = await API.selectReportOT(payload);
        responseAPI(response, nextAction)
    } catch (error) {
        console.error('error : ', error)
    }
}
