import { CONFIG } from "../../components/connectAPI";
import { responseAPI } from "../../moduleKPIs/common/functions/funcResponse";
import servicesAPI from "../../moduleKPIs/services/servicesAPI";
import { convertResponse } from "../../service/functions/convertResponse";
import { API_URL } from './../../components/connectAPI';

let API = servicesAPI.create()

export async function APIContractByDocNo(payload, nextAction) {
	try {
		let response = await API.selectContractByDocNo(payload);
		convertResponse({
			...response,
			result: response.data
		}, nextAction)
	} catch (error) {
		console.error('error : ', error)
	}
}

export const APIProbationMaster = async payload => {
	let URL = `${API_URL
		}${"/HRM_API/prob/selectDashboardProbationMaster"}`;
	//console.log('[API] APIProbation :: ', URL)
	try {
		return await fetch(URL, {
			method: "POST",
			headers: {
				//'content-type': 'application/x-www-form-urlencoded',
				"Content-Type": "application/json",
				Accept: "application/json"
			},
			body: JSON.stringify(payload)
		})
			.then(res => res.json())
			.then(results => {
				const { result } = results
				if (Array.isArray(result)) {
					return Promise.resolve(result);
				} else {
					console.warn("Not found");
					return Promise.reject(result);
				}
			});
	} catch (error) {
		return Promise.reject(error);
	}
};

export async function APIDashboardContract(payload, nextAction) {
	try {
		const response = await API.selectDashboardContract(payload);
		convertResponse({
			...response,
			result: response.data.result
		}, nextAction)
	} catch (error) {
		console.error('error : ', error)
	}
}

export async function APIDashboardContractView(payload, nextAction) {
	try {
		const response = await API.selectDashboardContractView(payload);
		convertResponse({
			...response,
			result: response.data.result
		}, nextAction)
	} catch (error) {
		console.error('error : ', error)
	}
}

export async function APIDashboardEmpContract(payload, nextAction) {
	try {
		let response = null
		switch (payload.status) {
			case 'pass':
				delete payload.status
				response = await API.selectDashboardContract(payload);
				//console.log('pass', { response })
				convertResponse({ ...response, result: response.data.data }, nextAction)
				break;
			case 'notPass':
				delete payload.status
				response = await API.selectDashboardBenenfitsOut(payload);
				//console.log('notPass', { response })
				convertResponse({ ...response, result: response.data.data }, nextAction)
				break;

			default:
				break;
		}
	} catch (error) {
		console.error('error : ', error)
	}
}

export async function APIDashboardTrack(payload, nextAction) {
	try {
		const response = await API.selectDashboardTrack(payload);
		convertResponse({
			...response,
			result: response.data.data
		}, nextAction)
	} catch (error) {
		console.error('error : ', error)
	}
}

export async function APIDashboardBenefit(payload, nextAction) {
	try {
		const response = await API.selectDashboardBenefit(payload);
		convertResponse({
			...response,
			result: response.data.data
		}, nextAction)
	} catch (error) {
		console.error('error : ', error)
	}
}
export async function APIDashboardBenefitsOut(payload, nextAction) {
	try {
		const response = await API.selectDashboardBenenfitsOut(payload);
		convertResponse({
			...response,
			result: response.data.data
		}, nextAction)
	} catch (error) {
		console.error('error : ', error)
	}
}

export async function APIInsertProbationContract(payload, nextAction) {
	try {
		let response = await API.insertProbationContract(payload);
		convertResponse({
			...response,
			result: { docNo: response.data.docNO }
		}, nextAction)
	} catch (error) {
		console.error('error : ', error)
	}
}
export async function APIInsertTimeStampBenefits(payload, nextAction) {
	try {
		let response = await API.insertTimeStampBenefits(payload);
		convertResponse({
			...response,
			result: { docNo: response.data.docNO }
		}, nextAction)
	} catch (error) {
		console.error('error : ', error)
	}
}

export async function APIConfirmProbationContract(payload, nextAction) {
	try {
		const response = await API.confirmProbationContract(payload);
		if (response.status === 200) {
			return response
		}
		responseAPI(response, nextAction)
	} catch (error) {
		console.error('error : ', error)
	}
}

export async function APIUpdateProbationContract(payload, nextAction) {
	try {
		let response = await API.updateProbationContract(payload);
		convertResponse({
			...response,
			result: { docNo: response.data.docNO }
		}, nextAction)
	} catch (error) {
		console.error('error : ', error)
	}
}

export async function APIConfirmReceivedContract(payload, nextAction) {
	try {
		const response = await API.confirmReceivedContract(payload);
		//console.log({ response })
		if (response.status === 200) {
			return response.data
		}
		responseAPI(response, nextAction)
	} catch (error) {
		console.error('error : ', error)
	}
}