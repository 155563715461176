import React, { createContext, useReducer } from "react";
import { browserHistory } from "react-router";
import { MASTER_OPTION, REPORT_OPTION } from "../../../moduleKPIs/services/servicesOptions";
import { APIDashboardTraining, APISelectTrainingCourse, APISelectTrainingUser, APIDashboardIndividual, APIDashboardTrainers, APISelectOptionFlow } from "../masterTraining/masterTrainingConnect";
import { APIInsertTrainingFile, APIViewTraining } from "./manageTrainingConnect";
import { convertArray } from "../../../constants/functions";
import { Modal } from "antd";


export const ManageTrainingContext = createContext();

const initialState = {
    dataTableAction: {
        rowKey: null, dataSource: [], columns: []
    },
    dataTableView: {
        rowKey: null, dataSource: [], columns: []
    },
    trainingUser: null,
    trainingCourses: null,
    trainingLists: [],
    trainersHeader: null,
    individualHeader: null,
    files: {
        trainingFiles: [],
        enrollCourses: []
    }
}

const MANAGE_TRAINING_ACTION = {
    SET_MANAGE_TRAINING_SUCCESS: "SET_MANAGE_TRAINING_SUCCESS",
    SET_MANAGE_TRAINING_FAILURE: "SET_MANAGE_TRAINING_FAILURE"
}

const manageTrainingReducer = (state, action) => {
    switch (action.type) {
        case MANAGE_TRAINING_ACTION.SET_MANAGE_TRAINING_SUCCESS:
            return { ...state, ...action.state }
        case MANAGE_TRAINING_ACTION.SET_MANAGE_TRAINING_FAILURE:
        default:
            return state
    }
}


const ProviderManageTraining = ({ children }) => {
    const { userLogin, userPermission } = children.props;
    const [manageTrainingState, manageTrainingDispatch] = useReducer(manageTrainingReducer, initialState)
    const { files } = manageTrainingState

    async function getAPI({ fieldname, payload, pathname }) {
        // console.warn('[getAPI] :', fieldname)
        let _payload = null
        let _flowHRM = []
        let _subDep = []
        //let _dep = []
        await APISelectOptionFlow({ userId: userLogin },
            ({ resultDep, resultFlowHRM, resultsubDep }) => {
                //_dep = convertArray('depNo', 'string', resultDep)
                _flowHRM = convertArray('idFlow', 'string', resultFlowHRM)
                _subDep = convertArray('subDepNo', 'string', resultsubDep)
            })
        // console.log({ _flowHRM, _subDep, _dep })


        switch (fieldname) {

            case REPORT_OPTION.DASHBOARD_TRAINING_ACTION:
                await APISelectOptionFlow({ userId: userLogin },
                    ({ resultDep, resultFlowHRM, resultsubDep }) => {
                        //_dep = convertArray('depNo', 'string', resultDep)
                        _flowHRM = convertArray('flowId', 'string', resultFlowHRM)
                        _subDep = convertArray('subDepNo', 'string', resultsubDep)
                    })
                // console.log({ _flowHRM, _subDep, _dep })
                _payload = {
                    userId: '',
                    type: payload.type,//'trainingAction',
                    strDate: payload[`SEARCH_${MASTER_OPTION.RANG_DATE}`][0],
                    endDate: payload[`SEARCH_${MASTER_OPTION.RANG_DATE}`][1],
                    flowHRM: _flowHRM,
                    subDepNo: payload[`SEARCH_${MASTER_OPTION.OPTION_SUBDEP}`] ? payload[`SEARCH_${MASTER_OPTION.OPTION_SUBDEP}`] : _subDep,
                    statusNo: payload[`SEARCH_${MASTER_OPTION.OPTION_TRAINING_STATUS}`],
                }
                // console.log("responseTraining", _payload)
                await APIDashboardTraining(_payload, async (response) => {
                    if (response) {
                        await manageTrainingDispatch({
                            type: MANAGE_TRAINING_ACTION.SET_MANAGE_TRAINING_SUCCESS,
                            state: {
                                dataTableAction: {
                                    rowKey: 'userId',
                                    dataSource: response.dataLine
                                }
                            }
                        })
                    }
                })
                break;
            case REPORT_OPTION.DASHBOARD_TRAINING_VIEW:
                _payload = {
                    userId: '',
                    type: 'trainingView',
                    strDate: payload[`SEARCH_${MASTER_OPTION.RANG_DATE}`][0],
                    endDate: payload[`SEARCH_${MASTER_OPTION.RANG_DATE}`][1],
                    flowHRM: '',
                    subDepNo: payload[`SEARCH_${MASTER_OPTION.OPTION_SUBDEP}`] ? payload[`SEARCH_${MASTER_OPTION.OPTION_SUBDEP}`] : _subDep,
                    statusNo: payload[`SEARCH_${MASTER_OPTION.OPTION_TRAINING_STATUS}`],
                }
                await APIDashboardTraining(_payload, async (response) => {
                    if (response) {
                        await manageTrainingDispatch({
                            type: MANAGE_TRAINING_ACTION.SET_MANAGE_TRAINING_SUCCESS,
                            state: {
                                dataTableView: {
                                    rowKey: 'userId',
                                    dataSource: response.dataLine
                                }
                            }
                        })
                    }
                })
                break;



            case REPORT_OPTION.DASHBOARD_HISTORY_INDIVIDUAL:
                _payload = {
                    userId: payload[`SEARCH_${MASTER_OPTION.OPTION_USER_TRAINING}`] || '',
                    trainingNo: payload[`SEARCH_${MASTER_OPTION.OPTION_TRAINING_COURSE}`] || '',
                    subDepNo: payload[`SEARCH_${MASTER_OPTION.OPTION_SUBDEP}`] ? payload[`SEARCH_${MASTER_OPTION.OPTION_SUBDEP}`] : _subDep,
                    trainingGroup: payload[`SEARCH_${MASTER_OPTION.OPTION_TRAINING_GROUP}`] || '',
                }
                // console.log(_payload, payload)
                await APIDashboardIndividual(_payload, async (response) => {
                    if (response) {
                        await window.localStorage.setItem("individualHeader", JSON.stringify({ ...response.dataHeader }))
                        await manageTrainingDispatch({
                            type: MANAGE_TRAINING_ACTION.SET_MANAGE_TRAINING_SUCCESS,
                            state: {
                                individualHeader: response.dataHeader,
                                dataTableAction: {
                                    rowKey: 'linenum',
                                    dataSource: response.dataLine
                                }
                            }
                        })
                    }
                })
                break;
            case REPORT_OPTION.DASHBOARD_HISTORY_TRAINERS:
                _payload = {
                    userId: payload[`SEARCH_${MASTER_OPTION.OPTION_USER_TRAINING}`] || '',
                    trainingNo: payload[`SEARCH_${MASTER_OPTION.OPTION_TRAINING_COURSE}`] || '',
                    subDepNo: payload[`SEARCH_${MASTER_OPTION.OPTION_SUBDEP}`] ? payload[`SEARCH_${MASTER_OPTION.OPTION_SUBDEP}`] : _subDep,
                }
                // console.log(_payload, payload)
                await APIDashboardTrainers(_payload, async (response) => {
                    if (response) {
                        await manageTrainingDispatch({
                            type: MANAGE_TRAINING_ACTION.SET_MANAGE_TRAINING_SUCCESS,
                            state: {
                                trainersHeader: response.dataHeader,
                                dataTableAction: {
                                    rowKey: 'linenum',
                                    dataSource: response.dataLine
                                }
                            }
                        })
                    }
                })
                break;

            case REPORT_OPTION.SELECT_TRAINING_USER:
                await window.localStorage.setItem("trainingUser", JSON.stringify(payload))
                await manageTrainingDispatch({
                    type: MANAGE_TRAINING_ACTION.SET_MANAGE_TRAINING_SUCCESS,
                    state: {
                        trainingUser: payload
                    }
                })
                if (pathname) {
                    await browserHistory.push({ pathname })
                }
                break;
            case REPORT_OPTION.SELECT_TRAINING_COURSE:
                await APISelectTrainingCourse(payload, async (response) => {
                    if (response) {
                        await window.localStorage.setItem("trainingCourses", JSON.stringify(response))
                        await manageTrainingDispatch({
                            type: MANAGE_TRAINING_ACTION.SET_MANAGE_TRAINING_SUCCESS,
                            state: {
                                trainingCourses: response
                            }
                        })
                    }
                })
                break;
            case REPORT_OPTION.SELECT_TRAINING_USER_BY_DOC_NO:
                await APISelectTrainingUser(payload, async (response) => {
                    if (response) {
                        await window.localStorage.setItem("trainingUser", JSON.stringify({ ...response, filePath: payload.filePath, filename: payload.filename }))
                        await manageTrainingDispatch({
                            type: MANAGE_TRAINING_ACTION.SET_MANAGE_TRAINING_SUCCESS,
                            state: {
                                trainingUser: { ...response, filePath: payload.filePath, filename: payload.filename },
                            }
                        })
                        if (pathname) {
                            await browserHistory.push({ pathname })
                        }
                    }
                })
                break;
            case REPORT_OPTION.VIEW_TRAINING:
                await APISelectTrainingUser(payload, async (response) => {
                    if (response) {
                        await window.localStorage.setItem("trainingUser", JSON.stringify({ ...response, filePath: payload.filePath, filename: payload.filename }))
                        await manageTrainingDispatch({
                            type: MANAGE_TRAINING_ACTION.SET_MANAGE_TRAINING_SUCCESS,
                            state: {
                                trainingUser: { ...response, filePath: payload.filePath, filename: payload.filename },
                            }
                        })
                    }
                })
                await APIViewTraining({ userId: payload.userId, docNo: payload.docNo }, async (response) => {
                    if (response) {
                        // console.log({ response })
                        await window.localStorage.setItem("trainingCourses", JSON.stringify(response))
                        await manageTrainingDispatch({
                            type: MANAGE_TRAINING_ACTION.SET_MANAGE_TRAINING_SUCCESS,
                            state: {
                                trainingCourses: response
                            }
                        })
                        if (pathname) {
                            await browserHistory.push({ pathname })
                        }
                    }
                })
                break;
            case REPORT_OPTION.VIEW_INDIVIDUAL:
                _payload = {
                    userId: payload.userId,
                    trainingNo: '',
                    subDepNo: '',
                    trainingGroup: '',
                }
                // console.log('_payload', payload)
                await APIDashboardIndividual(_payload, async (response) => {
                    if (response) {
                        // await window.localStorage.setItem("individualHeader", JSON.stringify({ ...payload, ...response.dataHeader }))
                        await manageTrainingDispatch({
                            type: MANAGE_TRAINING_ACTION.SET_MANAGE_TRAINING_SUCCESS,
                            state: {
                                individualHeader: { ...payload, ...response.dataHeader },
                                dataTableAction: {
                                    rowKey: 'linenum',
                                    dataSource: response.dataLine
                                }
                            }
                        })
                    }
                })
                break;

            default:
                break;
        }
    }

    async function onUploadFile(action, file, fieldname) {

        // console.log({ action, file, fieldname })
        switch (action) {
            case "UPLOAD_ADD":
                let duplicateFile = files[fieldname].filter(
                    image => image.name === file.name
                );

                // console.log({ action, file, fieldname, duplicateFile, files })
                if (duplicateFile.length > 0) {
                    Modal.error({
                        // key: REGISTER_KEY,
                        title: "ดำเนินการไม่สำเร็จ",
                        content: "มีชื่อไฟล์ซ้ำ กรุณาเลือกไฟล์ใหม่"
                    });
                } else {
                    if (files[fieldname].length > 4) {
                        Modal.error({
                            title: "ดำเนินการไม่สำเร็จ",
                            content: "อัพโหลดได้สูงสุด 5 รูปเท่านั้น!!"
                        });
                    } else {
                        await manageTrainingDispatch({
                            type: MANAGE_TRAINING_ACTION.SET_MANAGE_TRAINING_SUCCESS,
                            state: {
                                files: {
                                    ...files, [fieldname]: files[fieldname].concat([file])
                                }
                            }
                        })
                        let payloadFile = {
                            // code: 'TRA',
                            docNo: null,
                            userId: userLogin,
                            trainingFile: files[fieldname].concat([file]),

                        }
                        // console.log(files[fieldname].concat([file]), payloadFile, files)
                        await APIInsertTrainingFile(payloadFile, async (resFile) => {
                            if (resFile) {
                                await Modal.success({
                                    title: "อัพโหลดไฟล์สำเร็จ",
                                    onOk: () => window.location.reload()
                                })
                            }
                        })
                        // setFiles({ ...files, [fieldname]: files[fieldname].concat([file]) });
                    }
                }
                break;
            case "UPLOAD_REMOVE":
                if (files[fieldname].length > 0) {
                    await manageTrainingDispatch({
                        type: MANAGE_TRAINING_ACTION.SET_MANAGE_TRAINING_SUCCESS,
                        state: {
                            files: {
                                ...files, [fieldname]: []
                            }
                        }
                    })
                    // setFiles({ ...files, [fieldname]: [] });
                }
                break;
            default:
                break;
        }
    }
    return (
        <ManageTrainingContext.Provider
            value={{
                userLogin: { userId: userLogin, userPermission },
                manageTrainingState,
                getAPI,
                onUploadFile,
                manageTrainingDispatch
            }}>
            {children}
        </ManageTrainingContext.Provider>
    )
}


export default ProviderManageTraining

