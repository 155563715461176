import React from "react";
import "../index.css";
import "bootstrap/dist/css/bootstrap.css";
import { Button, Label, Container } from "reactstrap";
import { Modal, Card, Space } from "antd";
import "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css";
import CsvParse from "@vtex/react-csv-parse";
import { POST, POST_INSERT_GRADE } from "../service";
import { CSVLink } from "react-csv";
import { MdFileDownload, MdFileUpload } from "react-icons/md";
import { CONFIG } from "../components/connectAPI";
class ImportGrade extends React.Component {
  constructor(props) {
    super(props);
    const { userLogin } = props;
    this.importGradeRef = React.createRef();
    this.state = {
      upload_open: false,
      arrExcel: [],
      userLogin,
      showModal: false,
    };
  }

  handleData = (data) => {
    this.setState({ arrExcel: data ? data : [] });
  };

  handleOnError = (err, file, inputElem, reason) => {
    console.log("err", err);
    console.log("file", file);
    console.log("inputElem", inputElem);
    console.log("reason", reason);
  };

  addExcel = async () => {
    try {
      const { arrExcel, userLogin } = this.state;
      if (!this.importGradeRef.current.value) {
        Modal.warning({ title: "กรุณาแนบไฟล์ !!" });
      } else if (arrExcel.length === 0) {
        Modal.warning({ title: "ไม่พบข้อมูลในไฟล์", content: "กรุณาตรวจสอบข้อมูลในไฟล์ก่อน Import Grade" });
      } else {
        const objectData = {
          data: arrExcel,
          userLogin: userLogin,
        };
        const response = await POST(POST_INSERT_GRADE, objectData);
        const { result } = response;
        if (result.status_api === 1) {
          Modal.success({
            title: "Import Grade สำเร็จ !!",
            onOk: () => window.location.reload(),
          });
        } else {
          Modal.info({ title: "Import Grade ไม่สำเร็จ" });
        }
      }
    } catch (err) {
      Modal.error({ title: "[CATCH] เกิดข้อผิดพลาด", content: err.message });
      console.log("Error Import Grade: ", err);
    }
  };

  render() {
    return (
      <Container>
        {this._renderModal()}
        <Card
          title="หน้าจัดการบันทึกข้อมูลผลการประเมิน (Import Grade)"
        >
          <Space wrap>
            <Button
              color="warning"
              size="md"
              href={`${CONFIG.API_URL}/template/Templete_JG_PGF_FromEvaluation.csv`}
              target="_blank"
            >
              <MdFileDownload /> Export Template (CSV)
            </Button>
            <Button
              color="info"
              size="md"
              onClick={() => this.setState({ showModal: true })}
            >
              <MdFileUpload /> Import Grade (CSV)
            </Button>
          </Space>
        </Card>
      </Container>
    );
  } // Render

  _renderButtonExportTemplate = () => {
    const headerCSV = [
      { label: "Years", key: "years" },
      { label: "Type", key: "type" },
      { label: "ID_User", key: "idUser" },
      { label: "DepNo", key: "depNo" },
      { label: "Grade", key: "grade" },
      { label: "PG", key: "pg" },
      { label: "JG", key: "jg" },
    ];

    return (
      <CSVLink
        headers={headerCSV}
        data={[]}
        filename={"บันทึกข้อมูลผลการประเมิน(Import-Grade).csv"}
        className="btn btn-warning"
      >
        <MdFileDownload /> Export Template (CSV)
      </CSVLink>
    );
  };

  _renderModal = () => {
    const { handleData, handleOnError, addExcel } = this;
    const keys = ["years", "type", "idUser", "depNo", "grade", "pg", "jg"];

    return (
      <Modal
        title="Import Grade"
        visible={this.state.showModal}
        onCancel={() => {
          this.importGradeRef.current.value = "";
          this.setState({ showModal: false, arrExcel: [] });
        }}
        onOk={() => Modal.confirm({ title: "ยืนยันการเพิ่มข้อมูลหรือไม่", onOk: () => addExcel(), })}
        okText={"Upload"}
      >
        <Label style={{ color: "red", marginRight: "20px" }}>
          **รองรับเฉพาะไฟล์ .csv เท่านั้น
        </Label>
        <br />
        <CsvParse
          keys={keys}
          onDataUploaded={handleData}
          onError={(err, file, inputElem, reason) => handleOnError(err, file, inputElem, reason)}
          render={(onChange) => (<input type="file" ref={this.importGradeRef} onChange={onChange} accept=".csv" />)}
        />
      </Modal>
    )
  }

}
export default ImportGrade;
