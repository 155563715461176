import React, { useContext, useState, useEffect, useRef } from 'react'
import { Spin, Empty } from 'antd'
import { LayoutTable } from '../../moduleKPIs/common/layouts'
import { ButtonTheme, ExportExcel } from '../../moduleKPIs/common/buttons'
import { ReportEvacContext } from '../servicesEvac/reportEvac/reportEvacProvider'
import { ProfileOutlined } from '@ant-design/icons'
import { COLUMNS_EXPORT_DASHBOARD_EVAC, TABLE_DASHBOARD_FOLLOW_EVAC } from '../commonsEvac/structuresEvac/tableEvac'
import { MASTER_OPTION, REPORT_OPTION } from '../../moduleKPIs/services/servicesOptions'
import { MasterEvacContext } from '../servicesEvac/masterEvac/masterEvacProvider'
import servicesAPI from '../../moduleKPIs/services/servicesAPI'
import { equals } from "ramda";
import { useAlert } from "../../screens/conponents/modal/CustomAlert";

const DashboardNonEvac = () => {
    const refExport = useRef()
    const { clearMaster, masterEvacState } = useContext(MasterEvacContext)
    const [loading, setLoading] = useState(false)
    const [data, setData] = useState([])
    const [depart, setDepart] = useState([])

    useEffect(() => {
        fetchAPI()
        getDataNonEval()
    }, [])

    async function fetchAPI() {
        await clearMaster({ fieldOption: MASTER_OPTION.OPTION_DEP_EVAC })
    }

    const getDataNonEval = async () => {
        try {
            await setLoading(true)
            const API = servicesAPI.create()
            const res = await API.getNonEvalation({
                year: masterEvacState[`SEARCH_OPTION_YEAR`],
                quarter: masterEvacState[`SEARCH_OPTION_QUARTER`],
                // year: 2021,
                // quarter: 4
            })
            if (res.status === 200) {
                const { view, summary } = res.data.result
                let followEvac = Array.isArray(view) && view.reduce((acc, cur) => {
                    const dataIndex = summary.findIndex(el => equals(el.depNo, cur.depNo))
                    const dataLine = view.filter(el => equals(el.depNo, cur.depNo))
                    if (dataIndex !== -1) {
                        acc[dataIndex] = {
                            ...summary[dataIndex], dataLine: dataLine
                        }
                        return acc
                    } else {
                        acc[dataIndex] = {
                            ...summary[dataIndex], dataLine: []
                        }
                        return acc
                    }
                }, [])
                setData(view)
                setDepart(followEvac)
            }
        } catch (error) {
            console.log(error);
            useAlert({ type: "catch", content: error.message })
        } finally {
            await setLoading(false)
        }
    }

    const saveRecordSummaryScore = async () => {
        try {
            await setLoading(true)
            const API = servicesAPI.create()
            const res = await API.recordEvaluationByQ({
                year: masterEvacState[`SEARCH_OPTION_YEAR`],
                quarter: masterEvacState[`SEARCH_OPTION_QUARTER`],
                // year: 2021,
                // quarter: 1
            })
            if (res.status === 200) {
                useAlert({ type: "success", content: `บันทึกการประเมินสำเร็จ` });
            }
        } catch (error) {
            console.log(error);
            useAlert({ type: "catch", content: error.message })
        } finally {
            await setLoading(false)
        }
    }

    return (
        <div>
            {/* <div className="layout-content-btn-search" style={{ top: 0 }}>
                <ButtonTheme
                    type="primary"
                    style={{ padding: 10 }}
                    buttonText={TEXT_BTN.SEARCH_DASHBOARD_EVAC}
                    onClick={async () => {
                        await setLoading(true)
                        // await getReportEvac({ fieldname: REPORT_OPTION.DASHBOARD_EVAC_VIEW })
                        await setLoading(false)
                    }}
                />
            </div> */}
            <Spin spinning={loading} tip="กำลังโหลด">
                {(Array.isArray(data) && data.length > 0) ?
                    <div style={{ padding: 10 }}>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <div style={{ fontSize: 20, fontWeight: 600, paddingLeft: 10, alignItems: 'center', display: 'flex' }} >
                                <ProfileOutlined />
                                <span style={{ paddingLeft: 20 }}>{'รายชื่อผู้ที่ไม่ได้ตอบแบบสอบถาม(หมดช่วงเวลาทำ)'} </span>
                            </div>
                            {/* <div>
                                <ExportExcel
                                    ref={refExport}
                                    columns={COLUMNS_EXPORT_DASHBOARD_EVAC({ permission: null })}
                                    dataSource={data}
                                /> */}
                            <ButtonTheme
                                style={{ padding: 10, width: '60%' }}
                                buttonStyle={{ backgroundColor: '#28a745', color: 'white' }}
                                buttonText={'บันทึกปิดจบแบบสอบถาม'}
                                onClick={async () => {
                                    await setLoading(true)
                                    // await getReportEvac({ fieldname: REPORT_OPTION.DASHBOARD_EVAC_VIEW })
                                    await useAlert({ type: "info", content: `ต้องการบันทึกการประเมินใช่หรือไม่`, Fn: () => saveRecordSummaryScore() })
                                    await setLoading(false)
                                }}
                            />
                            {/* </div> */}
                        </div>
                        {Array.isArray(depart) && depart.map((each, index) => {
                            return (
                                <LayoutTable
                                    style={{ padding: 10 }}
                                    scroll={{ x: 600 }}
                                    bordered
                                    rowKey={index}
                                    key={index}
                                    columns={TABLE_DASHBOARD_FOLLOW_EVAC({
                                        permission: 'non_evac',
                                        department: each.depName,
                                        percentDone: each.percentDone,
                                    })}
                                    pagination={{
                                        pageSize: 50,
                                        showTotal: total => `ทั้งหมด ${total || 0} รายการ`
                                    }}
                                    dataSource={each.dataLine}
                                />
                            )
                        })}
                    </div>
                    :
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                }
            </Spin>
        </div>
    )
}

export default DashboardNonEvac