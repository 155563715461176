import moment from "moment";
import numeral from "numeral";

export const convertStrToFormat = (str, format) => {
  if (!str) {
    return "";
  }
  switch (format) {
    case "phone_number":
      str = str.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3");
      break;
    case "id_card":
      str = str.replace(
        /(\d{1})(\d{2})(\d{5})(\d{2})(\d{2})(\d{1})/,
        "$1-$2-$3-$4-$5-$6"
      );
      break;
    case "credit":
      str = str.replace(/(\d{4})(\d{4})(\d{4})(\d{4})/, "$1-$2-$3-$4");
      break;
    case "money":
      str = numeral(str).format("0,0[.]00");
      break;
    case "money_digit":
      str = numeral(str).format("0,0.00");
      break;
    case "date":
      const [d, m, y] = moment(str).format("DD-MM-YYYY").split("-");
      str = `${d}-${m}-${+y + 543}`;
      break;
    case "datetime":
      str = moment(str).add(543, "y").format("DD-MM-YYYY HH:mm:ss");
      break;
  }

  return str;
};

export const convertFormatToStr = (str) => {
  if (!str) {
    return "";
  }
  str = `${str}`.replace(/-|,/g, "");
  return str;
};

export function compareRender(prevProps, nextProps, key = "value") {
  return prevProps[key] === nextProps[key];
}

export function convertExcelHeader(tableColumn) {
  return mapSubExcelHeader(
    tableColumn.filter((e) => {
      return !e.isAction;
    })
  );
}

function mapSubExcelHeader(data) {
  return data.map((e) => {
    return {
      field: e.dataIndex,
      title: e.titleFlat || e.title,
      subMenu: e.children ? mapSubExcelHeader(e.children) : null,
    };
  });
}
