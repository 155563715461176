export const NAME = {
	company: "company",
	location: "location",
	depNo: "depNo",
	subDepNo: "subDepNo",
	hrmFlow: "hrmFlow",
	viewFlow: "viewFlow",
	positionNo: "positionNo",
	action_company: "newCompany",
	action_location: "newLocation",
	action_depNo: "newDepNo",
	action_subDepNo: "newSubDepNo",
	action_approveId: "newHrmFlow",
	action_viewFlow: "newViewFlow",
	action_positionNo: "newPositionNo",
	action_Date: "newActionDate",
	action_changeShift: "newShiftCode"
};

export const LABEL = {
	company: "บริษัท",
	location: "Location",
	depNo: "แผนก",
	subDepNo: "แผนกย่อย",
	hrmFlow: "สายอนุมัติ",
	viewFlow: "สายดูข้อมูล",
	positionNo: "ตำแหน่งงาน",
	action_company: "บริษัท",
	action_location: "Location",
	action_depNo: "แผนก",
	action_subDepNo: "แผนกย่อย",
	action_approveId: "สายอนุมัติ",
	action_viewFlow: "สายดูข้อมูล",
	action_positionNo: "ตำแหน่งงาน",
	action_Date: "วันที่มีผล",
	action_changeShift: "กะทำงาน"
};
