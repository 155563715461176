import React from "react";
import { memo } from "react";
import { useController } from "react-hook-form";
import { compareRender } from "../../../../constants/functions/form";
import { Checkbox as CheckboxAntD } from "antd";
import { TextSmall } from "../../text";
import { Required, RowComponent } from "../../../../styles/global-style";
const { Fragment } = React;

export const Checkbox = ({ control, item, handleChange }) => {
  const {
    ref,
    rules = null,
    name,
    defaultValue,
    label,
    disabled = false,
    itemHandleChange,
  } = item;
  const { field, fieldState } = useController({
    control,
    name,
    rules,
    defaultValue,
  });
  const { error } = fieldState;
  const { value } = field;

  return (
    <Fragment>
      <RowComponent style={{ visibility: "hidden" }}>
        {label && <TextSmall text={label} />}
        {label && rules && rules.required && <Required>*</Required>}
      </RowComponent>
      <CheckboxAntD
        style={{ padding: "5px" }}
        id={ref}
        name={ref}
        checked={value}
        onChange={(e) =>
          itemHandleChange ? itemHandleChange(e, item) : handleChange(e, item)
        }
        disabled={disabled}
      >
        <RowComponent>
          {label && <TextSmall text={label} />}
          {rules && rules.required && <Required>*</Required>}
        </RowComponent>
      </CheckboxAntD>
      {error && <TextSmall text={error.message} color="red" />}
    </Fragment>
  );
};

export const MemoizedCheckbox = memo(
  Checkbox,
  (prev, next) =>
    compareRender(prev, next) && next.item.disabled == prev.item.disabled
);
