import React from 'react';
import '../index.css';
import "bootstrap/dist/css/bootstrap.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { MdAdd } from 'react-icons/md';
import moment from "moment";
import { Col, Row, Button, Form, FormGroup, Label, Input, Card, CardHeader, CardBody, Container } from 'reactstrap';
import { API_URL } from '../components/connectAPI'
import * as NumericInput from "react-numeric-input";
import { browserHistory } from 'react-router';
import { Checkbox, Modal } from 'antd';
import { GET_SELECT_OPTION_ALL, GET } from '../service';
// const api = connectAPI.API_URL


class ImportEmployee extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			startDateJob: '',
			endDate: '',
			showDropdown_Position: [],
			showDropdown_Location: [],
			showDropdown_Department: [],
			showDropdown_subDepartment: [],
			showDropdown_StatusWorker: [],
			showDropdown_View: [],
			showDropdown_workShift: [],
			loadData: [],
			Dep: '',
			fields: {},
			errors: {},
			candidateNo: this.props.params.CandidateNo,
			userLogin: this.props.userLogin,
			premission: this.props.premission
		};
		//  console.log('fff', this.props.userLogin)
		//  console.log('fff', this.props.premission)
		this.strDateChange = this.strDateChange.bind(this);
		this.endDateChange = this.endDateChange.bind(this);
		this.importWorker = this.importWorker.bind(this)

	}

	strDateChange(date) {
		let fields = this.state.fields;
		fields['startDateJob'] = date;
		this.setState({
			startDateJob: date, fields
		});

	}

	endDateChange(date) {
		let fields = this.state.fields;
		fields['endDate'] = date;
		this.setState({
			endDate: date, fields
		});
	}


	componentDidMount() {
		this.selectData();
		this.selectOption();
	}

	onChangeDep = (e) => {
		var Dep = e.target.value
		this.selectFlowApp(Dep);

	}


	selectFlowApp = (_Dep) => {
		let fields = this.state.fields;
		fields['departmentNo'] = _Dep;
		var API = API_URL + "/HRM_API/select_optionFlowBoss?dep=" + _Dep
		// console.log(API);
		fetch(API).then((res) => res.json()).then((results) => {
			// console.log(results)
			var arrFlow = [];
			var arrPosition = [];
			var arrSubDep = [];


			if (results['flow'] !== 0) {
				results['flow'].forEach(el => {
					arrFlow.push({
						value: el.ID_Flow + '=)' + el.Approvers_No,
						label: el.Flow_Name
					})
				})

			}
			if (results['position'] !== 0) {
				results['position'].forEach(el => {
					arrPosition.push({
						value: el.PositionCode,
						label: el.PositionCode + "::" + el.PositionNameEN
					})
				})

			}

			if (results['subDep'] !== 0) {
				results['subDep'].forEach(el => {
					arrSubDep.push({
						value: el.subDepNo,
						label: el.subDepNo + "::" + el.subDepName
					})
				})

			}

			this.setState({
				showDropdown_Flow: arrFlow,
				showDropdown_Position: arrPosition,
				showDropdown_subDepartment: arrSubDep,
				fields
			})

		}).catch(ex => {
			console.log("error gate ttt");
		})

	}

	selectOption() {
		this.getSelectOptionAll()
	}

	getSelectOptionAll = async () => {
		try {
			let res = await GET(GET_SELECT_OPTION_ALL())
			const { result, success } = res
			if (success) {
				let optionWorkShift = []
				result['workShift'].forEach(el => {
					optionWorkShift.push({
						label: el.shiftCode + " :: " + el.workShiftNameTH,
						value: el.shiftCode
					})
				})
				this.setState({
					showDropdown_workShift: optionWorkShift,
				})
				return 1;
			}
		} catch (err) {
			console.log('err', err.message)
		}
	}

	selectDataUser() {
		// console.log(this.props.UserID)
		var API =
			API_URL +
			"/HRM_API/select_dashboardImportEmployee?depNo=&&subDepNo=&&location=&&candidateNo=" + this.state.candidateNo;

		fetch(API).then((res) => res.json()).then((results) => {
			results = results['data']
			var Dep = results[0].departmentNo
			this.setState({
				loadData: results,
				fields: results[0],
				firstNameEN: results[0].firstNameEN,
				lastNameEN: results[0].lastNameEN,
				username: `${results[0].firstNameEN}.${`${results[0].lastNameEN}`.substring(0, 3)}`,
				Location: results[0].Location,
				statusWorker: results[0].statusWorker

			},
				() => { this.selectFlowApp(Dep) })
			// )
		}).catch(ex => {
			console.log("error gate ttt");
		})
	}

	selectData = async () => {
		try {
			let res = await GET(GET_SELECT_OPTION_ALL())
			const { result, success } = res
			if (success) {
				let options = {
					'department': [],
					'company': [],
					'statusWorker': [],
					'permissionview': [],
					'location': []
				}
				let lists = [
					'department',
					'company',
					'statusWorker',
					'permissionview',
					'location'
				]
				lists.forEach(field => {
					let arrOption = []
					switch (field) {
						case 'company':
						case 'statusWorker':
						case 'location':
							result[field].map(el => {
								arrOption.push({ label: el.description, value: el.code })
							})
							options = { ...options, [field]: arrOption }
							break;
						case 'department':
							result[field].map(el => {
								arrOption.push({ label: el.depNameTH, value: el.depNo })
							})
							options = { ...options, [field]: arrOption }
							break;
						case 'permissionview':
							result[field].map(el => {
								arrOption.push({ label: el.description, value: el.flowID })
							})
							options = { ...options, [field]: arrOption }
							break;
					}
				})

				this.setState({
					showDropdown_Position: options.company,
					showDropdown_Department: options.department,
					showDropdown_StatusWorker: options.statusWorker,
					showDropdown_Location: options.location,
					showDropdown_View: options.permissionview,
				})
				this.selectDataUser();
			}
		} catch (e) {
			console.warn(e);
		}
	}

	handleValidation() {
		// let fields = this.state.fields;
		// console.log(fields)
		// let errors = {};
		// let formIsValid = true;
		const { fields } = this.state;

		let validateList = ['company', 'startDateJob', 'titleTH',
			'firstNameTH', 'lastNameTH', 'firstNameEN', 'lastNameEN',
			'username', 'personalId', 'JG', 'departmentNo', 'timeScan', "Location", "statusWorker", "manType"
		]
		// let errorList = validateList.filter((e) => !fields[e])
		//console.log({ errorList })
		return (!validateList.some((e) => !fields[e]))
		// if (!fields["company"]) {
		//     formIsValid = false;
		//     errors["company"] = "Cannot be empty";
		// }


		// if (!fields["userId"]) {
		//     formIsValid = false;
		//     errors["userId"] = "Cannot be empty";
		// }

		// if (!fields["startDateJob"]) {
		//     formIsValid = false;
		//     errors["startDateJob"] = "Cannot be empty";
		// }

		// if (!fields["titleTH"]) {
		//     formIsValid = false;
		//     errors["titleTH"] = "Cannot be empty";
		// }

		// if (!fields["firstNameTH"]) {
		//     formIsValid = false;
		//     errors["firstNameTH"] = "Cannot be empty";
		// }

		// if (!fields["lastNameTH"]) {
		//     formIsValid = false;
		//     errors["lastNameTH"] = "Cannot be empty";
		// }

		// if (!fields["firstNameEN"]) {
		//     formIsValid = false;
		//     errors["firstNameEN"] = "Cannot be empty";
		// }


		// if (!fields["lastNameEN"]) {
		//     formIsValid = false;
		//     errors["lastNameEN"] = "Cannot be empty";
		// }

		// if (!fields["username"]) {
		//     formIsValid = false;
		//     errors["username"] = "Cannot be empty";
		// }

		// if (!fields["personalId"]) {
		//     formIsValid = false;
		//     errors["personalId"] = "Cannot be empty";
		// }

		// if (!fields["JG"]) {
		//     formIsValid = false;
		//     errors["JG"] = "Cannot be empty";
		// }


		// if (!fields["departmentNo"]) {
		//     formIsValid = false;
		//     errors["departmentNo"] = "Cannot be empty";
		// }

		// if (!fields["timeScan"]) {
		//     formIsValid = false;
		//     errors["timeScan"] = " timeScan Cannot be empty";
		// }


		// this.setState({ errors: errors });
		// return formIsValid;
	}

	importWorker(event) {
		event.preventDefault();
		//console.log(this.state.errors)
		if (this.handleValidation()) {
			const data = new FormData(event.target);
			//console.log(data)
			var entries = data.entries();
			var current, item, key, output, value;
			output = {};
			while (item = entries.next().value) {
				// assign to variables to make the code more readable.
				key = item[0];
				value = item[1];
				// Check if key already exist
				if (Object.prototype.hasOwnProperty.call(output, key)) {
					current = output[key];
					if (!Array.isArray(current)) {
						// If it's not an array, convert it to an array.
						current = output[key] = [current];
					}
					current.push(value); // Add the new value to the array.
				} else {
					output[key] = value;
				}
			}
			let payload = {
				// ...output,
				company: output.company || '',
				Location: output.Location || '',
				DepartmentNo: output.departmentNo || '',
				Email: output.email || '',
				EndDateJob: output.endDateJob || '',
				FirstName: output.firstNameTH || '',
				LastName: output.lastNameTH || '',
				FirstName_EN: output.firstNameEN || '',
				LastName_EN: output.lastNameEN || '',
				manType: output.manType || '',
				Tel_No: output.mobilePhone || '',
				Personal_id: output.personalId || '',
				NickName: output.nickNameTH || '',
				positionNo: output.positionNo || '',
				refCandidateNo: output.refCandidateNo || '',
				refFlow_HRM: output.refFlowHRM || '',
				Username: this.state.username || '',
				shiftCode: output.shiftCode || '',
				shirtSize: output.shirtSize || '',
				StartDateJob: output.startDateJob || '',
				Status_Worker: output.statusWorker || '',
				subDepNo: output.subDepNo || '',
				timeScan: output.timeScan || '',
				title: output.titleTH || '',
				JG: output.JG || '',
				oneTimeScan: '',
				Position_No: output.positionNo || '',
				ID_User: this.state.userCode,
				userLogin: this.state.userLogin,
			}
			//console.log({ output, payload })

			fetch(API_URL + '/HRM_API/insert_worker', {
				method: 'POST',
				headers: {
					//'content-type': 'application/x-www-form-urlencoded',
					'Content-Type': 'application/json',
					Accept: 'application/json'
				},
				body: JSON.stringify({ data: payload }), //  ,
			}).then((Response) => Response.json())
				.then(function (results) {
					if (results.status_api === 1) {
						// alert("Insert Success!! \n" + results.userID + "  Username = " + results.userName + " Password = " + results.password);
						// // this.setState({ done: true })
						// // window.location.reload();//browserHistory.push("/report");
						// browserHistory.push("/dashboardImportEmployee");

						Modal.success({
							title: "Insert Success!! \n" + results.userID + "  Username = " + results.userName + " Password = " + results.password,
							onOk: () => browserHistory.push("/dashboardImportEmployee"),
						})
					} else {
						// this.setState({ done: true })
						// alert("Insert Error!!");
						Modal.error({ title: "Insert Error !!" })
					}

				}).catch(ex => {
					console.log("catch : " + ex);
				})
		} else {
			Modal.error({
				title: 'กรุณาระบุข้อมูลให้ครบถ้วน',
				// content: `${JSON.stringify(this.state.errors)}`
			})
			// alert("กรุณาระบุข้อมูลให้ครบถ้วน")
			//console.log(this.state.errors)
		}

	}

	onChangeTitle = (e) => {
		var titleTH = e.target.value
		let fields = this.state.fields;
		fields['titleTH'] = titleTH;
		this.setState({
			fields
		})
	}

	onChangeFlowID = (e) => {
		var refFlowHRM = e.target.value
		let fields = this.state.fields;
		fields['refFlowHRM'] = refFlowHRM;
		this.setState({
			fields
		})
	}

	onChangePosition = (e) => {
		var Position = e.target.value
		let fields = this.state.fields;
		fields['positionNo'] = Position;
		this.setState({
			fields
		})
	}

	changeValue = (e) => {
		var _id = e.target.id
		var Arr = this.state.loadData
		Arr[0][_id] = e.target.value
		this.setState({ loadData: Arr });
		//console.log(this.state.loadData)
	}

	onChangeFlowView = (e) => {
		var FlowView = e.target.value
		let fields = this.state.fields;
		fields['refFlow_view'] = FlowView;
		this.setState({
			fields
		})
	}

	handleDatePickerChange = (date, _id) => {

		//console.log('date', date)
		let Arr = this.state.loadData
		let _date = ''
		if (date) {
			_date = moment(date).format('YYYY-MM-DD')
		}

		Arr[0][_id] = _date
		this.setState({ loadData: Arr });
		//console.log(_date)
	}

	clearEndDate = () => {

		let Arr = this.state.loadData
		let _date = ''
		Arr[0]['endDateJob'] = _date
		this.setState({ loadData: Arr });
	}

	handleChange(field, e) {
		let fields = this.state.fields;
		let fieldId = e.target.id
		let _userName = fields['username']


		//  console.log(e.target.id)
		fields[field] = e.target.value;
		//this.setState({ fields });

		if (fieldId === 'firstNameEN') {
			let _LastNameEN = (fields['lastNameEN']).substring(0, 3)
			_userName = (e.target.value + '.' + _LastNameEN).toLowerCase()
			this.setState({ firstNameEN: e.target.value });
		} else if (fieldId === 'lastNameEN') {
			let _firstNameEN = fields['firstNameEN']
			_userName = (_firstNameEN + '.' + (e.target.value).substring(0, 3)).toLowerCase()
			this.setState({ lastNameEN: e.target.value });
		}
		fields['username'] = _userName;
		this.setState({ fields });

	}

	handleChangeNum(valueAsNumber, field) {
		let fields = this.state.fields;

		fields[field] = valueAsNumber;
		this.setState({ fields });
	}

	onChangeSubDep = (e) => {
		let subDep = e.target.value
		let fields = this.state.fields;
		fields['subDep'] = subDep;
		this.setState({
			fields
		})
	}

	onChangeCompany = (e) => {
		var company = e.target.value
		let fields = this.state.fields;
		fields['company'] = company;

		var API = API_URL + "/HRM_API/select_genUserCode?company=" + company

		fetch(API).then((res) => res.json()).then((results) => {
			//console.log(results['userCode']);
			fields['userId'] = results['userCode'];

			// console.log(' fields[userId]', fields['userId']);
			this.setState({
				userCode: results['userCode'],
				getCompany: company,
				fields
				//})
			}, () => this.setUserName())

		}).catch(ex => {
			console.log("error onChangeCompany ::", ex);

		})


	}

	onChangeuserCode = (e) => {
		let fields = this.state.fields;
		fields['userId'] = e.target.value;

		this.setState({ userCode: e.target.value, fields });


		// this.setState({ loadData: Arr });

	}

	setUserName = () => {
		var _firstNameEN = this.state.firstNameEN
		var _LastNameEN = `${this.state.lastNameEN}`.substring(0, 3)
		var _userName = (_firstNameEN + '.' + _LastNameEN).toLowerCase()
		var getCompany = this.state.getCompany;
		let fields = this.state.fields;
		let local = '@dappmaker.co.th'
		let _mail = ''

		if (getCompany !== 'BD') {
			_mail = _userName + local
		}

		fields['email'] = _mail;
		fields['username'] = _userName;

		this.setState({
			userName: _userName,
			email: _mail,
			fields
		});

	}

	onChangeTimeScan = (e) => {
		let fields = this.state.fields;
		fields['timeScan'] = e.target.value;
		this.setState({
			fields,
			timeScan: e.target.value
		})
	}

	onChangeOneTimeScan = (e) => {
		let fields = this.state.fields;

		let a = (e.target.checked) ? '1' : '0'

		fields['oneTimeScan'] = a
		this.setState({
			oneTimeScan: e.target.checked,
			fields
		})
	}

	handleChangeText = (fieldname, value) => {
		return this.setState({ fields: { ...this.state.fields, [fieldname]: value } })
	}

	render() {
		var data = this.state.loadData;
		return (
			<Container>
				<Card>
					<CardHeader><MdAdd className="margin-icon" />Import ข้อมูลพนักงาน</CardHeader>
					{data.map((val, index) => {
						return (
							<CardBody key={index}>
								<Form onSubmit={this.importWorker}>
									import {GET} from '..';

									<Row>
										<Col md={6}>
											<FormGroup>
												<Label for="company"><span className="red"> * </span>บริษัท</Label>
												<Input type="select" name="company" id="company" ref="company"
													value={this.state.fields["company"]}
													onChange={this.onChangeCompany}>
													<option value="">กรุณาเลือกบริษัท</option>
													<option value="DP">Dplus Intertrade</option>
													<option value="DT">Dplus Together</option>
													<option value="ZT">Zetta Soft</option>
													<option value="FI">FIN</option>
													<option value="DC">Digital Commerce</option>
													<option value="BD">Better Dose</option>
													<option value="SK">SK Production </option>
												</Input>
											</FormGroup>
										</Col>
										<Col md={6}>
											<FormGroup>
												<Label for="location"><span className="red"> * </span>สถานที่</Label>
												{/* <Input type="text" name="Location_des" id="Location_des" value="Office" />
                                                <Input type="hidden" name="Location" id="Location" value="OFF" /> */}
												<Input type="select" ref="Location" name="Location" id="Location"
													value={val.Location}
													onChange={(e) => this.handleChangeText('Location', e.target.value)}
												>
													<option value={val.Location}>{val.locationName}</option>
													{this.state.showDropdown_Location.map(el => {
														return <option value={el.value}>{el.label}</option>
													})}
												</Input>
											</FormGroup>
										</Col>
									</Row>
									<Row>
										<Col md={6}>
											<FormGroup>
												<Label><span className="red"> * </span> รหัสพนักงาน</Label>
												<Input type="text" name="userId" id="userId" disabled onChange={this.handleChange.bind(this, "userId")}
													value={this.state.fields["userId"]} />
												{/* value={val.userId} */}
												<Input type="hidden" name="refCandidateNo" id="refCandidateNo" value={this.state.candidateNo} />
												<Input type="hidden" name="userLogin" id="userLogin" value={this.state.userLogin} />
											</FormGroup>
										</Col>
										<Col md={6}>
											<FormGroup>
												<Label for="statusWorker"><span className="red"> * </span>สถานะ</Label>
												<Input type="select" name="statusWorker" id="statusWorker"
													onChange={(e) => this.handleChangeText("statusWorker", e.target.value)}
													value={this.state.fields["statusWorker"]}>
													<option value="">------โปรดระบุ------</option>
													{this.state.showDropdown_StatusWorker.map(el => {
														return <option value={el.value}>{el.label}</option>
													})}
												</Input>
											</FormGroup>
										</Col>
									</Row>
									<Row>
										<Col md={6}>
											<FormGroup>
												<Label for="StartDate"><span className="red"> * </span> วันที่เริ่มทำงาน</Label><br></br>
												<DatePicker className="form-control ignore-react-onclickoutside" placeholderText="เลือกวันที่เริ่มงาน" name="startDateJob"
													dateFormat="yyyy/MM/dd"
													value={(val.startDateJob !== undefined && val.startDateJob !== null && val.startDateJob !== '') ? val.startDateJob.substring(0, 10) : ''}
													onChange={(date) => this.handleDatePickerChange(date, 'startDateJob')}
												/>
											</FormGroup>
										</Col>
										<Col md={5}>
											<FormGroup>
												<Label for="StartDate">เลือกวันที่สิ้นสุดการทำงาน</Label><br></br>
												<DatePicker className="form-control ignore-react-onclickoutside" name="endDateJob" autocomplete="false"
													dateFormat="yyyy/MM/dd"
													value={(val.endDateJob == '1900-01-01') ? '' : val.endDateJob}
													onChange={(date) => this.handleDatePickerChange(date, 'endDateJob')}
												/>
											</FormGroup>
										</Col>
										<FormGroup>
											<br />
											<Col md={1}><Button color="danger" onClick={this.clearEndDate}>Clear</Button> </Col> </FormGroup>
									</Row>
									<Row>
										<Col md={2}>
											<FormGroup>
												<Label><span className="red"> * </span> คำนำหน้าชื่อ </Label>
												<Input type="select" ref="titleTH" name="titleTH" id="titleTH"
													value={this.state.fields["titleTH"]}
													onChange={this.onChangeTitle}>
													<option value="" >----โปรดระบุ----</option>
													<option value="นาย" >นาย</option>
													<option value="นางสาว" >นางสาว</option>
													<option value="นาง" >นาง</option>
													<option value="ดร." >ดร.</option>
													<option value="ว่าที่ร้อยตรี" >ว่าที่ร้อยตรี</option>
													<option value="ว่าที่ร้อยโท">ว่าที่ร้อยโท</option>
													<option value="ว่าที่ร้อยเอก" >ว่าที่ร้อยเอก</option>
													<option value="พลตำรวจเอก" >พลตำรวจเอก</option>
												</Input>
											</FormGroup>
										</Col>
										<Col md={4}>
											<FormGroup>
												<Label><span className="red"> * </span>ชื่อ (ภาษาไทย)</Label>
												<Input ref="firstNameTH" type="text" name="firstNameTH" id="firstNameTH" onChange={this.handleChange.bind(this, "firstNameTH")} value={this.state.fields["firstNameTH"]} />
											</FormGroup>
										</Col>
										<Col md={6}>
											<FormGroup>
												<Label><span className="red"> * </span>นามสกุล (ภาษาไทย)</Label>
												<Input ref="lastNameTH" type="text" name="lastNameTH" id="lastNameTH" onChange={this.handleChange.bind(this, "lastNameTH")} value={this.state.fields["lastNameTH"]} />
											</FormGroup>
										</Col>
									</Row>
									<Row>
										<Col md={6}>
											<FormGroup>
												<Label><span className="red"> * </span> ชื่อ (ภาษาอังกฤษ)</Label>
												<Input ref="firstNameEN" type="text" name="firstNameEN" id="firstNameEN" onChange={this.handleChange.bind(this, "firstNameEN")} value={this.state.fields["firstNameEN"]} />
											</FormGroup>
										</Col>
										<Col md={6}>
											<FormGroup>
												<Label><span className="red"> * </span>นามสกุล (ภาษาอังกฤษ)</Label>
												<Input ref="lastNameEN" type="text" name="lastNameEN" id="lastNameEN" onChange={this.handleChange.bind(this, "lastNameEN")} value={this.state.fields["lastNameEN"]} />
											</FormGroup>
										</Col>
									</Row>
									<Row>
										<Col md={6}>
											<FormGroup>
												<Label><span className="red"> * </span> username</Label>
												<Input type="text" name="username" id="username"
													value={this.state.username}
													onChange={(e) => this.setState({ username: e.target.value })}
												/>
											</FormGroup>
										</Col>
										<Col md={6}>
											<FormGroup>
												<Label>เลขประจำตัวประชาชน</Label>
												{/* <NumericInput className="form-control" ref="personalId" name="personalId" id="personalId" style={false}
                                                    onChange={valueAsNumber => this.handleChangeNum(valueAsNumber, "personalId")} value={this.state.fields["personalId"]}
                                                /> */}
												<Input className="form-control" ref="personalId" name="personalId" id="personalId" maxLength={13} min={0}
													onChange={(e) => {
														let lengthValue = e.target.value.length
														if (lengthValue <= 13) {
															if (!isNaN(parseFloat(e.target.value)) && isFinite(e.target.value)) {
																this.handleChangeNum(e.target.value, "personalId")
															}
															//  else {
															//     this.handleChangeNum('', "personalId")
															// }
														}
													}
														// valueAsNumber => this.handleChangeNum(valueAsNumber, "personalId")
													}
													value={this.state.fields["personalId"]}
												/>
											</FormGroup>
										</Col>
									</Row>
									<Row>
										<Col md={6}>
											<FormGroup>
												<Label><span className="red"> * </span>  ชื่อเล่น</Label>
												<Input type="text" name="nickNameTH" id="nickNameTH" value={val.nickNameTH} onChange={this.changeValue} />
											</FormGroup>
										</Col>
										<Col md={6}>
											<FormGroup>
												<Label for="FNameEN">เบอร์ติดต่อ</Label>
												<Input name="mobilePhone" id="mobilePhone"
													maxLength={10}
													value={val.mobilePhone} onChange={this.changeValue} />
											</FormGroup>
										</Col>

									</Row>
									<Row>
										<Col md={6}>
											<FormGroup>
												<Label><span className="red"> * </span>ประเภทพนักงาน</Label>
												<Input type="select" ref="manType" name="manType" id="manType" onChange={this.handleChange.bind(this, "manType")}>
													<option value="">------โปรดระบุ------</option>
													<option value="new" selected={val.manType === 'new'}>อัตราใหม่</option>
													<option value="instead" selected={val.manType === 'instead'}>ทดแทน</option>
												</Input>
											</FormGroup>
										</Col>
										<Col md={6}>
											<FormGroup>
												<Label> size เสื้อพนักงาน</Label>
												<Input type="select" ref="shirtSize" name="shirtSize" id="shirtSize" onChange={this.handleChange.bind(this, "shirtSize")}>
													<option value="">------โปรดระบุ------</option>
													<option value="S" selected={val.shirtSize === 'S'}>S</option>
													<option value="M" selected={val.shirtSize === 'M'}>M</option>
													<option value="L" selected={val.shirtSize === 'L'}>L</option>
													<option value="XL" selected={val.shirtSize === 'XL'}>XL</option>
													<option value="2XL" selected={val.shirtSize === '2XL'}>2XL</option>
												</Input>
											</FormGroup>
										</Col>
									</Row>
									<Row>
										<Col md={6}>
											<FormGroup>
												<Label for="Email">E-Mail</Label>
												<Input type="text" name="email" id="email" value={val.email} onChange={this.changeValue} />
											</FormGroup>
										</Col>
										<Col md={6}>
											<FormGroup>
												<Label><span className="red"> * </span>Job Grade (JG)</Label>
												<NumericInput className="form-control" ref="JG" name="JG" id="JG" min={1} max={12}
													onChange={valueAsNumber => this.handleChangeNum(valueAsNumber, "JG")} value={this.state.fields["JG"]} />
											</FormGroup>
										</Col>
									</Row>
									<Row>

										<Col md={6}>
											<FormGroup>
												<Label for="FNameEN"> <span className="red"> * </span>แผนก</Label>
												<Input type="select" name="departmentNo" id="departmentNo" onChange={this.onChangeDep}
													value={this.state.fields["departmentNo"]} >
													{(val.departmentNo) ?
														<option value={val.departmentNo}>{val.departmentNo + " :: " + val.departmentName}</option>
														:
														<option value={''}>{''}</option>

													}
													{this.state.showDropdown_Department.map(el => {
														return <option value={el.value}>{el.label}</option>
													})}
												</Input>
											</FormGroup>
										</Col>
										<Col md={6}>
											<FormGroup>
												<Label for="subDepNo">แผนกย่อย</Label>
												<Input type="select" name="subDepNo" id="subDepNo" onChange={this.onChangeSubDep}>
													{
														(val.subDepNo) ?
															<option value={val.subDepNo}>{val.subDepNo + " :: " + val.subDepName}</option>
															:
															<option value={''}>{''}</option>
													}
													{this.state.showDropdown_subDepartment.map(el => {
														return <option value={el.value}>{el.label}</option>
													})}
												</Input>
											</FormGroup>
										</Col>
									</Row>
									<Row>
										<Col md={6}>
											<FormGroup>
												<FormGroup>
													<Label>ตำแหน่งงาน </Label>
													<Input type="select" ref="positionNo" name="positionNo" id="positionNo" onChange={this.onChangePosition}>
														<option value="">------โปรดระบุ------</option>
														{/* <option value={val.positionNo}>{val.positionNo + " :: " + val.PositionNameEN}</option> */}
														{this.state.showDropdown_Position.map(el => {
															return <option value={el.value}>{el.label}</option>
														})}
													</Input>
												</FormGroup>
											</FormGroup>
										</Col>
										<Col md={6}>
											<FormGroup>
												<Label for="BossID">สายอนุมัติ</Label>
												<Input type="select" ref="refFlowHRM" name="refFlowHRM" id="refFlowHRM" value={this.state.fields["refFlowHRM"]} onChange={this.onChangeFlowID} >
													<option value="">------โปรดระบุ------</option>
													{this.state.showDropdown_Flow.map(el => {
														return <option value={el.value}>{el.label}</option>
													})}
												</Input>

											</FormGroup>
										</Col>
									</Row>
									<Row>
										<Col md={6} style={{ padding: 15 }}>
											<FormGroup>
												<Label for="shiftCode"><span className="red"> * </span>กะทำงานตั้งต้น </Label>
												{/* <b>(ถ้าบันทึกแล้วจะไม่สามารถแก้ไขได้)</b> */}
												<Input type="select" ref="shiftCode" name="shiftCode" id="shiftCode" onChange={this.onChangeWorkshift} >
													<option value="0">------กรุณาระบุกะทำงาน------</option>
													{this.state.showDropdown_workShift.map(el => {
														return <option value={el.value}>{el.label}</option>
													})}
												</Input>

											</FormGroup>
										</Col>
										<Col md={3} style={{ padding: 15 }}>
											<FormGroup>
												<Label for="timeScan"><span className="red"> * </span>รูปแบบในการสแกน </Label>
												<Input type="select" ref="timeScan" name="timeScan" id="timeScan" value={this.state.timeScan} onChange={this.onChangeTimeScan} >
													<option value="">------กรุณาระบุ------</option>
													<option value="0">ไม่ต้องสแกน</option>
													<option value="4">สแกนเวลารายวัน</option>
													<option value="2">สแกนเวลารายเดือน</option>
												</Input>
											</FormGroup>
										</Col>
										<Col md={3} style={{ padding: 15 }}>
											<FormGroup>
												<Label for="oneTimeScan">สแกนเข้างานครั้งเดียวเท่านั้น? </Label>
												<Checkbox
													ref="oneTimeScan" name="oneTimeScan" id="oneTimeScan"
													checked={this.state.oneTimeScan}
													onChange={this.onChangeOneTimeScan}>
													<Label>ใช่ ขาเข้ารอบเดียว </Label>
												</Checkbox>
											</FormGroup>
										</Col>

									</Row>
									{/* <Row>
                                        <Col md={6}>
                                            <FormGroup>
                                                <Label> สิทธิการดูตรวจสอบข้อมูล</Label>
                                                <Input type="select" ref="refFlow_view" name="refFlow_view" id="refFlow_view" onChange={this.onChangeFlowView} >
                                                    <option value={val.refFlow_view}>{val.refFlow_viewName}</option>
                                                    {this.state.showDropdown_View}
                                                </Input>

                                            </FormGroup>
                                        </Col>
                                    </Row> */}

									<Row>
										<Col md={4}>
										</Col>
										<Col md={4} className="text-center">
											<Button color="success">Import Data</Button>
										</Col>
										<Col md={4}>
										</Col>
									</Row>
								</Form>
							</CardBody>
						);
					})}
				</Card>
			</Container>
		);

	}
}
export default ImportEmployee;