import React from 'react'
import { FiEdit, FiFileText, FiSend, FiXCircle } from 'react-icons/fi';

export const RenderStatusMainCode = (_status, _text) => {
    switch (_status) {
        case '0':
            return (
                <div>
                    <FiXCircle className='margin-icon' color="#FF5733" size={20} />
                    {_text || 'รอสร้างแบบประเมิน '}
                </div>
            )
        case '1':
            return (
                <div>
                    <FiEdit className='margin-icon' color="#95A5A6" size={20} />
                    {_text || 'อยู่ในช่วงทดลองงาน '}
                </div>
            )
        case '2':
            return (
                <div>
                    <FiFileText className='margin-icon' color="#C39BD3" size={20} />
                    {_text || 'รอส่งเอกสารให้  HR รับ '}
                </div>
            )
        case '3':
            return (
                <div>
                    <FiSend className='margin-icon' color="#28B463" size={20} />
                    {_text || 'จบการประเมิน '}
                </div>
            )
        default:
            break;
    }
}
