import React, { useState } from 'react'
import { Upload, Modal, Button } from 'antd'
// import styled from 'styled-components';
// import { Dialog } from '../modal';
import './index.css'
import { UploadOutlined } from '@ant-design/icons';
import { CONFIG } from '../../components/connectAPI';

let UPLOAD_KEY = "UPLOAD_KEY";

const UploadDocuments = (props) => {
    // console.log('UploadDocuments', { props })
    let maxlength = props.maxlength || 5

    function beforeUpload(file, fileList) {
        switch (file.type) {
            // case "image/jpg":
            // case "image/heic":
            // case "image/webp":
            // case "image/jpeg":
            // case "image/png":
            // case "application/pdf":
            // case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
            // case "application/vnd.ms-excel":
            // case "application/msword":
            // case "application/x-zip-compressed":
            //     // if (fileList.length > maxlength) {
            //     //     Modal.error({
            //     //         key: UPLOAD_KEY,
            //     //         title: "ดำเนินการไม่สำเร็จ",
            //     //         content: `อัพโหลดได้สูงสุด ${maxlength} ไฟล์เท่านั้น!!`
            //     //     })
            //     //     fileList = []
            //     // } else {
            //     props.handleupload("UPLOAD_ADD", file, props.fieldname)
            //     return false
            //     // }
            //     break;
            default:
                props.handleupload("UPLOAD_ADD", file, props.fieldname)
                return false
                // Modal.error({
                //     key: UPLOAD_KEY,
                //     title: "ดำเนินการไม่สำเร็จ",
                //     content: "ประเภทไฟล์ไม่รองรับ รองรับเฉพาะไฟล์ pdf,csv,xlsx และ doc"
                // })
                // fileList = []
        }

    }

    let update = Array.isArray(props.defaultfileList);
    switch (update) {
        //edit
        case true:
            let sumDocuments = props.defaultfileList.concat(props.fileList)
            let newFileList = sumDocuments.filter((val) => (!!val && val.status === "done") ? props.defaultfileList : props.fileList) || []
            return (
                <div >
                    <Upload
                        disabled={props.disabled}
                        // style={{ display: 'flex', flexDirection: 'inherit', alignItems: 'baseline' }}
                        multiple={props.multiple}
                        listType="picture"
                        // style={{ display: 'flex' }}
                        fileList={GET_DOCUMENT_FILE(newFileList)}
                        onRemove={(file) => props.handleupload("UPLOAD_REMOVE", file, props.fieldname)}
                        beforeUpload={(file, fileList) => beforeUpload(file, fileList)}
                    >
                        {(props.disabled) ? null : (sumDocuments.length > (maxlength - 1)) ? null :
                            <CustomButton buttonText={props.buttonText} />
                        }
                    </Upload>
                </div>
            )
            
        //add
        case false:
            return (
                <div>
                    <Upload
                        multiple={props.multiple}
                        listType="picture"
                        // style={{ display: 'flex', flexDirection: 'inherit', alignItems: 'baseline' }}
                        fileList={props.fileList || []}
                        onRemove={(file) => props.handleupload("UPLOAD_REMOVE", file, props.fieldname)}
                        beforeUpload={(file, fileList) => beforeUpload(file, fileList)}
                    >
                        {(props.fileList && props.fileList.length > (maxlength - 1)) ? null : <CustomButton buttonText={props.buttonText} />}

                    </Upload>
                </div>
            )
            

        default:
            break;
    }

}

export default UploadDocuments


// const div = styled.div`
//     padding-top: 15px;
// `
const CustomButton = ({ buttonText }) => {
    return (
        <Button style={{ fontSize: 12 }} >
            <UploadOutlined /> {buttonText || 'อัพโหลดเอกสาร'}
        </Button>

    )
}


function filterURL(url) {
    let result = null;
    if (!!url && url.indexOf(CONFIG.API.HOST) === -1) {
        result = `${CONFIG.API.IMAGE}${url}`;
    } else {
        result = url;
    }
    return result;
}

function GET_DOCUMENT_FILE(_documentList = []) {
    let result = [];
    _documentList.map((each, index) => (
        result = result.concat({
            ...each,
            id: index + 1,
            uid: each.uid || `generate_${index + 1}`,
            name: each.name || "",
            status: each.status || "",
            url: filterURL(each.url) || "",
            thumbUrl: filterURL(each.url) || ""
        })
    ));
    return result;
}



// export function GET_DOCUMENT_PREVIEW(_fieldList = []) {
//     console.log('GET_DOCUMENT_PREVIEW', _fieldList)
//     let result = [];
//     _fieldList.map((each, index) => {
//         if (each !== undefined) {
//             return (
//                 result = result.concat({
//                     ...each,
//                     name: each.filename || "",
//                     status: 'done',
//                     url: each.filePath || ""
//                 })
//             )
//         } else {
//             return (
//                 result = result.concat([])
//             )
//         }
//     });
//     console.log('GET_DOCUMENT_PREVIEW', result)
//     return result;
// }


export function GET_DOCUMENT_PREVIEW(_fieldList = []) {
    let result = [];
    _fieldList.map((each, index) => (
        result = result.concat({
            ...each,
            name: each.filename || "",
            status: 'done',
            url: each.filePath || ""
        })
    ));
    // console.log('GET_DOCUMENT_PREVIEW', result)
    return result;
}
