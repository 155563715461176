import { DatePicker, Form, Input, Modal, Radio } from "antd";
import React, { useState, useEffect } from "react";
import { useAlert } from "../screens/conponents/modal/CustomAlert";
import { PUT, PUT_CONFIRM_TASK_IN, PUT_CONFIRM_TASK_OUT } from "../service";
import { PATHNAME } from "../constants/enums/pathname";
import { browserHistory } from "react-router";

const ModalConfirmTask = ({
  dataRecord,
  modalVisible,
  setModalVisible,
  onCloseModal,
  activeTab,
}) => {
  const [form] = Form.useForm();
  const [data, setData] = useState({
    note: "",
    status: "CONFIRM",
  });

  useEffect(() => {
    form.setFieldsValue(dataRecord);
  }, [form, dataRecord]);

  const onChangeValue = (fieldName, value) => {
    setData({ ...data, [fieldName]: value });
  };

  const onSubmit = () => {
    const { note, status } = data;
    const objBody = {
      userId: dataRecord.userId,
      note: note,
      prepareStatus: status,
    };
    Modal.confirm({
      title: "ยืนยันการบันทึก",
      onOk: () => {
        activeTab === "NEW"
          ? onPutConfirmTask(objBody)
          : onPutConfirmTaskOut(objBody);
      },
    });
  };

  const onPutConfirmTask = async (objBody) => {
    try {
      const res = await PUT(PUT_CONFIRM_TASK_IN(), objBody);
      const { success } = res;
      if (success) {
        return Modal.success({
          title: "บันทึกสำเร็จ",
          onOk: () => browserHistory.push(PATHNAME.ASSETS_NEW_EMP_PRINT+`/?UserID=${dataRecord.userId}`)/*onCloseModal()*/,
        });
      }
    } catch (err) {
      useAlert({ type: "catch", content: err.message });
      console.log("Error Put: ", err);
    } finally {
    }
  };

  const onPutConfirmTaskOut = async (objBody) => {
    try {
      const res = await PUT(PUT_CONFIRM_TASK_OUT(), objBody);
      const { success } = res;
      if (success) {
        return Modal.success({
          title: "บันทึกสำเร็จ",
          onOk: () => onCloseModal(),
        });
      }
    } catch (err) {
      useAlert({ type: "catch", content: err.message });
      console.log("Error Put: ", err);
    } finally {
    }
  };
  return (
    <Form
      form={form}
      initialValues={dataRecord}
      labelCol={{
        span: 6,
      }}
      wrapperCol={{
        span: 18,
      }}
    >
      <Modal
        visible={modalVisible}
        title={"ยืนยันการปิด Task งาน"}
        onCancel={() => setModalVisible(false)}
        onOk={onSubmit}
      >
        <Form.Item label="รหัสพนักงาน" name="userId">
          <Input disabled />
        </Form.Item>
        <Form.Item label="หมายเหตุ" name="note">
          <Input
            onChange={(e) => {
              onChangeValue("note", e.target.value);
            }}
          />
        </Form.Item>
        <Form.Item
          name="status"
          rules={[{ required: true, message: "Please select an option!" }]}
        >
          <Radio.Group
            defaultValue={"CONFIRM"}
            onChange={(e) => {
              onChangeValue("status", e.target.value);
            }}
            style={{ display: "flex", justifyContent: "center" }}
          >
            <Radio value="CONFIRM">เสร็จสิ้น </Radio>
            <Radio value="CANCEL"> ยกเลิก </Radio>
          </Radio.Group>
        </Form.Item>
      </Modal>
    </Form>
  );
};

export default ModalConfirmTask;
