export const formFilter = ({
  optionsCompany = [], 
  optionsDepartment = [], 
  optionsEmp = [], 
  optionStatus = [], 
  optionStatusGuarantee = []
}) => {
  return [
    {
      ref: "company",
      name: "company",
      label: "บริษัท (Company)",
      type: "DROPDOWN",
      span: 5,
      placeholder: "บริษัท",
      properties: {
        options: optionsCompany,
      },
    },
    {
      ref: "departmentNo",
      name: "departmentNo",
      label: "แผนก",
      type: "DROPDOWN",
      span: 5,
      placeholder: "แผนก",
      properties: {
        options: optionsDepartment,
      },
    },
    {
      ref: "employeeNo",
      name: "employeeNo",
      label: "พนักงาน",
      type: "DROPDOWN",
      span: 4,
      placeholder: "พนักงาน",
      properties: {
        options: optionsEmp,
      },
    },
    {
      ref: "fileStatus",
      name: "fileStatus",
      label: "สถานะแนบไฟล์",
      type: "DROPDOWN",
      span: 2,
      placeholder: "สถานะเอกสาร",
      properties: {
        options: optionStatus,
      },
    },
    {
      ref: "fileGuaranteeStatus",
      name: "fileGuaranteeStatus",
      label: "ระบุเอกสารค้ำประกัน",
      type: "DROPDOWN",
      span: 3,
      placeholder: "",
      properties: {
        options: optionStatusGuarantee,
      },
    },
  ];
};

