import React, { Component } from "react";
import "../index.css";
import "bootstrap/dist/css/bootstrap.css";
import { FiPrinter } from "react-icons/fi";
import { Col, Row, Button } from "reactstrap";
import encodeString from '../extendFunction/encodeString';
import { GET, SELECT_DATA_CF_DISCUSSION } from "../service/";
class PrintDiscussionForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loadData: [],

    };
  }

  componentDidMount() {
    this.selectData();
  }

  selectData = async () => {
    const docNo = encodeString.decode(this.props.params.DocDIS);
    const response = await GET(SELECT_DATA_CF_DISCUSSION({ docNo }));
    const { result } = response;
    let chk = false;
    const status_con = result[0]["status_confirm"];
    const grade_con = result[0]["gradeDIS"];

    if (status_con === 1) {
      chk = true;
    }
    this.setState({
      loadData: result,
      status_confirm: chk,
      gradedata: grade_con
    });


  }

  printDis = () => {
    window.print();
  };

  render() {
    var data = this.state.loadData;
    return (
      <div>
        <Row>
          <Col className="no-printme">
            <Button color="info" onClick={this.printDis}>
              <FiPrinter className="margin-icon" /> Print
            </Button>
          </Col>
        </Row>

        {data.map((val, index) => (
          <div id="printableArea" className="printme">
            <Row className="ref-number">
              <Col>{val.DocNo}</Col>
            </Row>
            <Row>
              <Col className="text-center header-from">
                ฟอร์มหารือผลงาน (DISCUSSION FORM)
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <Row className="padding-10">
                  <Col>
                    <b>ชื่อผู้ถูกประเมิน :</b> {val.StaffName}
                  </Col>
                  <Col>
                    <b>ตำแหน่ง :</b> {val.Level_WorkerDes}
                  </Col>
                </Row>
                <Row className="padding-10">
                  <Col>
                    <b>ชื่อผู้ประเมินอันดับ 1 :</b> {val.BossName}
                  </Col>
                  <Col>
                    <b>ตำแหน่ง :</b> {val.Level_WorkerBossDes}
                  </Col>
                </Row>
                <Row className="padding-10">
                  <Col>
                    <b>ชื่อผู้ประเมินอันดับ 2 :</b> {val.ApprovalName}
                  </Col>
                  <Col>
                    <b>ตำแหน่ง :</b> {val.PositionNameTH_LV2}
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <b>การประเมินตนเองประจำปี : {val.Years}</b>
                  </Col>
                  <Col>
                    <b>{val.TypeDes}</b>
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row className="border-from padding-25">
              <Col>
                <Row className="bg-headfrom padding-10 head-qu">
                  <Col>
                    <b>
                      0. เป้าหมายการหารือผลงาน / สิ่งที่คาดหวัง -
                      ผู้บังคับบัญชาชี้แจงให้พนักงานทราบวัตถุประสงค์การสนทนา
                      ได้แก่
                    </b>
                  </Col>
                </Row>
                <Row className="answer">
                  <Col><span className="text-area">{val.Target_Discus}</span></Col>
                </Row>
              </Col>
            </Row>
            <Row className="padding-25">
              <Col>
                <Row className="padding-10 head-qu">
                  <Col>
                    <b>1. ผลการดำเนินการ ปีที่ผ่านมา (Performance)</b>
                  </Col>
                </Row>
                {/* <Row className="padding-10">
                  <Col>
                    <b>1.1 ผลสำเร็จตามเป้าหมาย</b>
                  </Col>
                </Row> */}
                <Row className="answer padding-10">
                  <Col>
                    <b>1.1) ผลสำเร็จตามเป้าหมาย</b>
                  </Col>
                </Row>
                <Row className="answer padding-10">
                  <Col><span className="text-area">{val.Target_Success}</span></Col>
                </Row>
                {/* <Row className="answer padding-10">
                  <Col>
                    <b>1.1.2) สิ่งที่ได้เรียนรู้และพัฒนา</b>
                  </Col>
                </Row>
                <Row className="answer padding-20">
                  <Col><span className="text-area">{val.Learn_Developed}</span></Col>
                </Row> */}
                {/* <Row className="padding-10">
                  <Col>
                    <b>1.2 ผลงานที่ยังไม่บรรลุตามความคาดหวัง</b>
                  </Col>
                </Row> */}
                <Row className="answer padding-10">
                  <Col>
                    <b>1.2) ผลงานที่ยังไม่บรรลุตามเป้าหมาย</b>
                  </Col>
                </Row>
                <Row className="answer padding-10">
                  <Col><span className="text-area">{val.Target_NotSuccess}</span></Col>
                </Row>
                {/* <Row className="answer padding-10">
                  <Col>
                    <b>1.2.2) สาเหตุที่ยังไม่บรรลุตามเป้าหมาย</b>
                  </Col>
                </Row>
                <Row className="answer padding-10">
                  <Col><span className="text-area">{val.Causes_NotSuccess}</span></Col>
                </Row> */}
                <Row className="answer padding-10">
                  <Col>
                    <b>
                      1.3) อุปสรรคต่างๆที่พบ และ แนวทางพัฒนา แก้ไขปัญหา เพื่อบรรลุเป้าหมาย
                    </b>
                  </Col>
                </Row>
                <Row className="answer padding-10">
                  <Col><span className="text-area">{val.Barrier}</span></Col>
                </Row>
                {/* <Row className="answer padding-10">
                  <Col>
                    <b>
                      1.2.4)
                      เราจะมีแนวทางพัฒนาหรือแก้ไขปัญหาร่วมกันอย่างไรได้บ้าง
                    </b>
                  </Col>
                </Row>
                <Row className="answer padding-10">
                  <Col><span className="text-area">{val.Solution}</span></Col>
                </Row> */}
              </Col>
            </Row>

            <Row className="padding-25">
              <Col>
                <Row className="padding-10 head-qu">
                  <Col>
                    {/* <b>
                      3. การพัฒนาสมรรถนะในการทำงาน (Core & Managerial
                      Competency) ** อ้างอิง Form ประเมินผลงาน/Competency
                    </b> */}
                    <b>2.การพัฒนาสมรรถนะในการทำงาน (Core & Competency) ** อ้างอิง Form ประเมินผลงาน / Competency  </b>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <b>
                      2.1)  คุณค่าที่ดีของพนักงาน - ชื่นชมในสิ่งที่ดี (ประเด็นหลักๆ 1-3 ข้อ) ให้ยกตัวอย่างพฤติกรรมที่เป็นคุณค่าที่ดี
                    </b>
                  </Col>
                </Row>

                {/* <Row className="answer text-center">
                  <Col md={4} className="border-col bg-table">
                    <b>คุณค่าที่ดีของพนักงาน ตามวัฒนธรรมองค์กร (V)</b>
                  </Col>
                  <Col md={4} className="border-col bg-table">
                    <b>พฤติกรรม/การกระทำที่เป็นคุณค่าที่ดีของพนักงาน (A)</b>
                  </Col>
                  <Col md={4} className="border-col bg-table">
                    <b>ผลของการกระทำของคุณค่าที่ดี (I)</b>
                  </Col>
                </Row> */}
                <Row className="answer padding-25">
                  <Col>
                    <span className="text-area"> {val.valueEmp} </span>
                  </Col>
                  {/* <Col md={4} className="border-col">
                    <span className="text-area"> {val.actionEmp} </span>
                  </Col>
                  <Col md={4} className="border-col">
                    <span className="text-area"> {val.resultEmp} </span>
                  </Col> */}
                </Row>
                <Row>
                  <Col>
                    <b>
                      {/* 3.2) คุณค่าที่ต้องการให้พนักงานพัฒนา
                      (แนะให้เห็นแนวทางพัฒนา ปรับปรุง) (VAI) (ประเด็นหลักๆ 1-3
                      ข้อ) */}
                      2.2 คุณค่าที่ต้องการให้พนักงานพัฒนา ปรับปรุง (ประเด็นหลักๆ 1-3 ข้อ) ให้ยกตัวอย่างพฤติกรรมที่อยากเห็น และแนะให้เห็นแนวทางพัฒนา ปรับปรุง
                    </b>
                  </Col>
                </Row>
                {/* <Row className="answer text-center">
                  <Col md={4} className="border-col bg-table">
                    <b>คุณค่าตามวัฒนธรรมองค์กรที่อยากให้เสริม (V)</b>
                  </Col>
                  <Col md={4} className="border-col bg-table">
                    <b>พฤติกรรม/การกระทำที่ดีที่ต้องการให้ทำ (A)</b>
                  </Col>
                  <Col md={4} className="border-col bg-table">
                    <b>ผลของการกระทำที่มีผลดีต่อพนักงาน (I)</b>
                  </Col>
                </Row> */}
                <Row className="answer padding-20">
                  <Col>
                    <span className="text-area"> {val.valueCulture} </span>
                  </Col>
                  {/* <Col md={4} className="border-col">
                    <span className="text-area"> {val.actionMust}</span>
                  </Col>
                  <Col md={4} className="border-col">
                    <span className="text-area"> {val.directResult} </span>
                  </Col> */}
                </Row>
                {/* <Row className="padding-10">
                  <Col>
                    <b>
                      3.3) แผนในการพัฒนา ปรับปรุงสมรรถนะ เป็นอย่างไรบ้าง
                      กำหนดระยะเวลาร่วมกัน
                    </b>
                  </Col>
                </Row>
                <Row className="answer">
                  <Col><span className="text-area"> {val.planDev} </span></Col>
                </Row> */}
              </Col>
            </Row>
            <Row className="padding-25">
              <Col>
                <Row className="head-qu">
                  <Col>
                    <b>3. ความรู้ ความเชี่ยวชาญในงาน (Functional Competency)</b>
                  </Col>
                </Row>
                <br />
                <Row className="answer">
                  <Col md={1} className="border-col bg-table">
                    <b>ลำดับที่</b>
                  </Col>
                  <Col md={6} className="border-col bg-table">
                    <b> สิ่งที่อยากพัฒนา ปรับปรุง -  มีเรื่องอะไรบ้างที่อยากเรียนรู้พัฒนาเพิ่มเติม เพื่อช่วยให้ทำงานได้ง่ายขึ้น ดีขึ้น (3อันดับแรก) </b>
                  </Col>
                  <Col md={5} className="border-col bg-table">
                    <b> เหตุผลที่อยากเรียนรู้ พัฒนา (สำหรับแต่ละหัวข้อ)</b>
                  </Col>
                </Row>
                <Row className="answer">
                  <Col md={1} className="border-col text-center-body">
                    1
                  </Col>
                  <Col md={6} className="border-col">
                    <span className="text-area">{val.KM_1}</span>
                  </Col>
                  <Col md={5} className="border-col">
                    <span className="text-area"> {val.Reason_1}</span>
                  </Col>
                </Row>
                <Row className="answer">
                  <Col md={1} className="border-col text-center-body">
                    2
                  </Col>
                  <Col md={6} className="border-col">
                    <span className="text-area">  {val.KM_2 === '' ? '-' : val.KM_2}</span>
                  </Col>
                  <Col md={5} className="border-col">
                    <span className="text-area"> {val.Reason_2 === '' ? '-' : val.Reason_2}</span>
                  </Col>
                </Row>
                <Row className="answer">
                  <Col md={1} className="border-col text-center-body">
                    3
                  </Col>
                  <Col md={6} className="border-col">
                    <span className="text-area"> {val.KM_3 === '' ? '-' : val.KM_3} </span>
                  </Col>
                  <Col md={5} className="border-col">
                    <span className="text-area"> {val.Reason_3 === '' ? '-' : val.Reason_3} </span>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row className="padding-25">
              <Col>
                <Row className="padding-10 head-qu">
                  <Col>
                    <b>4. ความคาดหวังงาน สำหรับรอบการทำงานถัดไป</b>
                  </Col>
                </Row>
                <Row className="padding-10">
                  <Col>
                    <b>
                      {/* 4.1) ความคาดหวังในงานที่ชัดเจน -
                      (ชี้แจงให้พนักงานเข้าใจบทบาทหน้าที่ ความรับผิดชอบ
                      สอดคล้องเป้าหมายขององค์กร) */}
                      4.1) ชี้แจงหน้าที่รับผิดชอบ และความคาดหวัง
                    </b>
                  </Col>
                </Row>
                <Row className="answer padding-20">
                  <Col>
                    <span className="text-area">
                      {val.expectWork}
                    </span>
                  </Col>
                </Row>
                {/* <Row className="padding-10">
                  <Col>
                    <b>
                      4.2) แผนงานที่มอบหมายให้รับผิดชอบ (Action Plan
                      ที่มอบหมายให้พนักงานรับผิดชอบ เพื่อสนับสนุนภาพรวมหน่วยงาน)
                    </b>
                  </Col>
                </Row>
                <Row className="answer padding-20">
                  <Col><span className="text-area">
                    {val.actionPlan}
                  </span>
                  </Col>
                </Row> */}
                {/* <Row>
                  <Col>
                    <b>
                      4.3) ผลของงานที่คาดหวัง และตัวชี้วัดผลงาน สำหรับปีนี้
                      (ปัจจัยหลัก 3-7 ข้อ)
                    </b>
                  </Col>
                </Row> */}
                <Row className="padding-10">
                  {/* <Col md={6} className="border-col bg-table">
                    <b>
                      ผลของงานที่ต้องทำให้บรรลุ (ผลงานที่ผ่านมาและที่ควรจะเป็น)
                    </b>
                  </Col> */}
                  <Col>
                    <b>4.2) ตัวชี้วัดผลงานที่ชัดเจน</b>
                  </Col>
                </Row>
                <Row className="answer">
                  {/* <Col md={6} className="border-col">
                    <span className="text-area">{val.outputPass}</span>
                  </Col> */}
                  <Col>
                    <span className="text-area"> {val.indicator} </span>
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row className="padding-25">
              <Col>
                <Row className="padding-10 head-qu">
                  <Col>
                    <b>
                      5.ข้อเสนอแนะ และการให้คำปรึกษา (รับ Feedback
                      และให้ข้อเสนอแนะเพิ่มเติม)
                    </b>
                  </Col>
                </Row>
                <Row className="padding-10">
                  <Col>
                    <b> 5.1 สิ่งที่อยากเสนอแนะผู้บังคับบัญชา - การทำงานร่วมกันเป็นอย่างไรบ้าง มีสิ่งที่อยากให้ผู้บังคับบัญชาปรับหรือสนับสนุน (Feedback)</b>
                  </Col>
                </Row>
                <Row className="answer padding-20">
                  <Col><span className="text-area">
                    {val.Feedback === '' ? '-' : val.Feedback}
                  </span>
                  </Col>
                </Row>
                <Row className="padding-10">
                  <Col>
                    <b>5.2 สิ่งที่อยากเสนอแนะองค์กร - สิ่งที่อยากให้องค์กรปรับเพิ่มเติม</b>
                  </Col>
                </Row>
                <Row className="answer">
                  <Col><span className="text-area">{val.Organ_comment === '' ? '-' : val.Organ_comment} </span>
                  </Col>
                </Row>
                <Row className="padding-10">
                  <Col>
                    <b>  5.3 ความเห็นอื่นๆ - ประเด็นอื่นๆ ที่อยากหารือกับผู้บังคับบัญชา / อยากขอบคุณใคร หรือ หน่วยงานใด สำหรับการทำงานในปีนี้ </b>
                  </Col>
                </Row>
                <Row className="answer">
                  <Col><span className="text-area">{val.Other_comment === '' ? '-' : val.Other_comment} </span>
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row className="padding-25">
              <Col>
                <Row className="padding-10 head-qu">
                  <Col>
                    <b>
                      6.ข้อเสนอแนะ และการให้คำปรึกษา (รับ Feedback
                      และให้ข้อเสนอแนะเพิ่มเติม)
                    </b>
                  </Col>
                </Row>
                <Row className="padding-10">
                  <Col>
                    <b> 6.1 ปีที่ผ่านมา เราช่วยให้  <b>"ใคร"</b> ในองค์กรได้ทำงานให้ประสบความสำเร็จบ้าง /<b> "ใคร"</b> ช่วยผลักดันงานของเราให้สำเร็จบ้าง</b>
                  </Col>
                </Row>
                <Row className="answer padding-20">
                  <Col><span className="text-area">
                    {val.whoHelp === '' ? '-' : val.whoHelp}
                  </span>
                  </Col>
                </Row>
                <Row className="padding-10">
                  <Col>
                    <b> 6.2 ปีที่ผ่านมาเอา "ไอเดีย/ความคิดริเริ่ม" ไหนขององค์กร หรือ ฝ่ายอื่นมาต่อยอดได้บ้าง</b>
                  </Col>
                </Row>
                <Row className="answer">
                  <Col><span className="text-area">{val.adaptIdea === '' ? '-' : val.adaptIdea} </span>
                  </Col>
                </Row>
                <Row className="padding-10">
                  <Col>
                    <b> 6.3 ถ้ามีโอกาสสร้างการเปลี่ยนแปลงในองค์กรได้ มีโครงการ มีไอเดียไหนที่น่าทำและอยากทำบ้าง (สามารถเสนอของหน่วยงานตัวเอง หรือหน่วยงานอื่น ๆ ได้) </b>
                  </Col>
                </Row>
                <Row className="answer">
                  <Col><span className="text-area">{val.changeOrg === '' ? '-' : val.changeOrg} </span>
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row className="padding-25">
              <Col>
                <Row className="padding-10 head-qu">
                  <Col>
                    <b>
                      7.ผลการประเมินโดยรวม - (สื่อสาร
                      แจ้งภาพรวมผลประเมินของพนักงานพร้อมทั้งแจ้ง โบนัส
                      และการปรับเงินเดือนประจำปี)
                    </b>
                  </Col>
                </Row>
                <Row className="answer">
                  <Col><span className="text-area">{val.overallResult} </span>
                  </Col>
                </Row>
              </Col>
            </Row>

            {/* <Row className="padding-25">
              <Col>
                <Row className="head-qu">
                  <Col>
                    <b>
                      7. สรุปสิ่งที่จะพัฒนาปรับปรุงร่วมกันในปีนี้ ที่ชัดเจน
                      (เรียงตามลำดับความสำคัญ 2-5 เรื่อง)
                    </b>
                  </Col>
                </Row>
                <Row className="answer text-center">
                  <Col md={6} className="border-col bg-table">
                    <b>หัวข้อ/ รายละเอียด</b>
                  </Col>
                  <Col md={6} className="border-col bg-table">
                    <b>กำหนดเวลา</b>
                  </Col>
                </Row>
                <Row className="answer">
                  <Col md={6} className="border-col">
                    <span className="text-area"> {val.overallDepYearDetail_1}  <br />
                      {val.overallDepYearDetail_2}  <br />
                      {val.overallDepYearDetail_3}  <br />
                      {val.overallDepYearDetail_4}  <br />
                      {val.overallDepYearDetail_5}
                    </span>
                  </Col>
                  <Col md={6} className="border-col">
                    <span className="text-area">
                      {val.overallDepYearTime_1} <br />
                      {val.overallDepYearTime_2} <br />
                      {val.overallDepYearTime_3} <br />
                      {val.overallDepYearTime_4} <br />
                      {val.overallDepYearTime_5} <br />
                    </span>
                  </Col>
                </Row>
              </Col>
            </Row> */}
            <Row className="padding-25">
              <Col>
                <Row className="padding-10 head-qu">
                  <Col>
                    <b>8. สรุปผลการดำเนินงานประจำปี {val.Years}</b>
                  </Col>
                </Row>

                <Row className="answer-chkbox">{val.gradeDes === '' ? '-' : val.gradeDes}</Row>
              </Col>
            </Row>

            <Row className="text-center">
              <Col>
                <Row>
                  <Col md={4}>
                    {val.CF_user === 1 ? val.StaffName : '..................................................'}
                  </Col>
                  <Col md={4}>
                    {val.CF_user === 1 ? val.BossName : '..................................................'}
                  </Col>
                  <Col md={4}>
                    {val.CF_user === 1 ? val.ApprovalName : '..................................................'}
                  </Col>
                </Row>
                <Row>
                  <Col md={4}>พนักงานลงนาม</Col>
                  <Col md={4}> ลงนามผู้บังคับบัญชาขั้นต้น </Col>
                  <Col md={4}> ลงนามผู้บังคับบัญชาระดับบริหาร </Col>
                </Row>
                <Row>

                  <Col md={4}> {val.CF_user === 1 ? val.cfDate : 'วันที่ ........./........./.........'} </Col>
                  <Col md={4}> {val.CF_user === 1 ? val.DateCreated : 'วันที่ ........./........./.........'} </Col>
                  <Col md={4}> {val.CF_user === 1 ? val.ApprovalDate : 'วันที่ ........./........./.........'} </Col>
                </Row>
              </Col>
            </Row>
          </div>
        ))}
      </div>
    );
  }
}

export default PrintDiscussionForm;
