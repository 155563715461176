const sizeL = 20;
const sizeM = 15;
const sizeS = 12;
export const certEmployEN = ({ name, reqTransfer, reqQuit, isSelected, options }) => {
  return [
    {
      ref: `isSelected_${name}`,
      name: `isSelected_${name}`,
      key: `isSelected`,
      label: `เลือกพิมพ์`,
      type: "CHECK_BOX_STATE",
      span: 3,
    },
    ...transferEN(name, reqTransfer, isSelected, options),
    ...quit(name, reqQuit, isSelected),
    ...footerForm(name, isSelected, sizeM),
  ];
};

export const certEmployTH = ({ name, reqTransfer, reqQuit, isSelected, options }) => {
  return [
    {
      ref: `isSelected_${name}`,
      name: `isSelected_${name}`,
      key: `isSelected`,
      label: `เลือกพิมพ์`,
      type: "CHECK_BOX_STATE",
      span: 3,
    },
    ...transferTH(name, reqTransfer, isSelected, options),
    ...quit(name, reqQuit, isSelected),
    ...footerForm(name, isSelected, sizeM),
  ];
};

export const certSalaryTH = ({ name, reqTransfer, reqQuit, isSelected, options }) => {
  return [
    {
      ref: `isSelected_${name}`,
      name: `isSelected_${name}`,
      key: `isSelected`,
      label: `เลือกพิมพ์`,
      type: "CHECK_BOX_STATE",
      span: 3,
    },
    ...transferTH(name, reqTransfer, isSelected, options),
    ...quit(name, reqQuit, isSelected),
    {
      ref: `salary_${name}`,
      name: `salary_${name}`,
      key: `salary`,
      label: "เงินเดือน",
      placeholder: "เงินเดือน",
      type: "INPUT_NUMBER",
      span: 3,
      rules: {
        required: isSelected ? "กรุณาระบุ เงินเดือน" : false,
      },
      disabled: !isSelected,
      relation: `showSalary`,
    },
    ...footerForm(name, isSelected, sizeS),
  ];
};

export const certSalaryEN = ({ name, reqTransfer, reqQuit, isSelected, options }) => {
  return [
    {
      ref: `isSelected_${name}`,
      name: `isSelected_${name}`,
      key: `isSelected`,
      label: `เลือกพิมพ์`,
      type: "CHECK_BOX_STATE",
      span: 3,
    },
    ...transferEN(name, reqTransfer, isSelected, options),
    ...quit(name, reqQuit, isSelected),
    {
      ref: `salary_${name}`,
      name: `salary_${name}`,
      key: `salary`,
      label: "เงินเดือน",
      placeholder: "เงินเดือน",
      type: "INPUT_NUMBER",
      span: 3,
      rules: {
        required: isSelected ? "กรุณาระบุ เงินเดือน" : false,
      },
      disabled: !isSelected,
      relation: `showSalary`,
    },
    ...footerForm(name, isSelected, sizeS),
  ];
};

export const certSalaryENVisa = ({ name, reqSalary, isSelected }) => {
  return [
    {
      ref: `isSelected_${name}`,
      name: `isSelected_${name}`,
      key: `isSelected`,
      label: `เลือกพิมพ์`,
      type: "CHECK_BOX_STATE",
      span: 3,
    },
    {
      ref: `startDate_${name}`,
      name: `startDate_${name}`,
      key: `startDate`,
      relation: `isApex`,
      placeholder: "วันที่เดินทาง",
      label: "วันที่เดินทาง",
      type: "DATE_PICKER",
      span: 3,
      rules: {
        required: isSelected ? "กรุณาระบุ วันที่เดินทาง" : false,
      },
      disabled: !isSelected,
    },
    {
      ref: `endDate_${name}`,
      name: `endDate_${name}`,
      key: `endDate`,
      relation: `isApex`,
      placeholder: "วันที่เดินทางกลับ",
      label: "วันที่เดินทางกลับ",
      type: "DATE_PICKER",
      span: 3,
      rules: {
        required: isSelected ? "กรุณาระบุ วันที่เดินทางกลับ" : false,
      },
      disabled: !isSelected,
    },
    {
      ref: `showSalary_${name}`,
      name: `showSalary_${name}`,
      key: `showSalary`,
      label: "ระบุเงินเดือน",
      type: "CHECK_BOX_STATE",
      span: 3,
      disabled: !isSelected,
    },
    {
      ref: `salary_${name}`,
      name: `salary_${name}`,
      key: `salary`,
      label: "เงินเดือน",
      placeholder: "เงินเดือน",
      type: "INPUT_NUMBER",
      relation: `showSalary`,
      span: 3,
      rules: {
        required: reqSalary && isSelected ? "กรุณาระบุ เงินเดือน" : false,
      },
      disabled: !(reqSalary && isSelected),
    },
    {
      ref: `isApex_${name}`,
      name: `isApex_${name}`,
      key: `isApex`,
      label: "Apex",
      type: "CHECK_BOX",
      relation: `isApex`,
      span: 6,
      disabled: !isSelected,
    },
    ...footerForm(name, isSelected, sizeL),
  ];
};

const transferTH = (
  name,
  reqTransfer,
  isSelected,
  options = {
    optionsCompanyTH: [],
    optionsPositionTH: [],
  }
) => {
  return [
    {
      ref: `isTransfer_${name}`,
      name: `isTransfer_${name}`,
      key: `isTransfer`,
      label: "โอนย้าย",
      type: "CHECK_BOX_STATE",
      span: 2,
      disabled: !isSelected,
    },
    {
      ref: `oldPositionTH_${name}`,
      name: `oldPositionTH_${name}`,
      key: `oldPositionTH`,
      relation: `isTransfer`,
      label: "ตำแหน่งเดิม (ภาษาไทย)",
      placeholder: "ตำแหน่งเดิม (ภาษาไทย)",
      type: "AUTO_COMPLETE",
      options: options.optionsPositionTH,
      span: 5,
      rules: {
        required: reqTransfer && isSelected ? "กรุณาระบุ ตำแหน่งเดิม" : false,
      },
      disabled: !(reqTransfer && isSelected),
    },
    {
      ref: `newPositionTH_${name}`,
      name: `newPositionTH_${name}`,
      key: `newPositionTH`,
      relation: `isTransfer`,
      label: "ตำแหน่งใหม่ (ภาษาไทย)",
      placeholder: "ตำแหน่งใหม่ (ภาษาไทย)",
      type: "AUTO_COMPLETE",
      options: options.optionsPositionTH,
      span: 5,
      rules: {
        required: reqTransfer && isSelected ? "กรุณาระบุ ตำแหน่งใหม่" : false,
      },
      disabled: !(reqTransfer && isSelected),
    },
    {
      ref: `newCompanyTH_${name}`,
      name: `newCompanyTH_${name}`,
      key: `newCompanyTH`,
      relation: `isTransfer`,
      label: "สถานที่โอนย้าย (ภาษาไทย)",
      placeholder: "สถานที่โอนย้าย (ภาษาไทย)",
      type: "DROPDOWN",
      properties: {
        options: options.optionsCompanyTH,
      },
      span: 5,
      rules: {
        required:
          reqTransfer && isSelected ? "กรุณาระบุ สถานที่โอนย้าย" : false,
      },
      disabled: !reqTransfer,
    },
    {
      ref: `moveDate_${name}`,
      name: `moveDate_${name}`,
      key: `moveDate`,
      relation: `isTransfer`,
      placeholder: "วันที่โอนย้าย",
      label: "วันที่โอนย้าย",
      type: "DATE_PICKER",
      span: 4,
      rules: {
        required: reqTransfer && isSelected ? "กรุณาระบุ วันที่โอนย้าย" : false,
      },
      disabled: !(reqTransfer && isSelected),
    },
  ];
};

const transferEN = (
  name,
  reqTransfer,
  isSelected,
  options = {
    optionsCompanyEN: [],
    optionsPositionEN: [],
  }
) => {
  return [
    {
      ref: `isTransfer_${name}`,
      name: `isTransfer_${name}`,
      key: `isTransfer`,
      label: "โอนย้าย",
      type: "CHECK_BOX_STATE",
      span: 2,
      disabled: !isSelected,
    },
    {
      ref: `oldPositionEN_${name}`,
      name: `oldPositionEN_${name}`,
      key: `oldPositionEN`,
      relation: `isTransfer`,
      label: "ตำแหน่งเดิม (อังกฤษ)",
      placeholder: "ตำแหน่งเดิม (อังกฤษ)",
      type: "AUTO_COMPLETE",
      options: options.optionsPositionEN,
      span: 5,
      rules: {
        required: reqTransfer && isSelected ? "กรุณาระบุ ตำแหน่งเดิม" : false,
      },
      disabled: !(reqTransfer && isSelected),
    },
    {
      ref: `newPositionEN_${name}`,
      name: `newPositionEN_${name}`,
      key: `newPositionEN`,
      relation: `isTransfer`,
      label: "ตำแหน่งใหม่ (อังกฤษ)",
      placeholder: "ตำแหน่งใหม่ (อังกฤษ)",
      type: "AUTO_COMPLETE",
      options: options.optionsPositionEN,
      span: 5,
      rules: {
        required: reqTransfer && isSelected ? "กรุณาระบุ ตำแหน่งใหม่" : false,
      },
      disabled: !(reqTransfer && isSelected),
    },
    {
      ref: `newCompanyEN_${name}`,
      name: `newCompanyEN_${name}`,
      key: `newCompanyEN`,
      relation: `isTransfer`,
      label: "สถานที่โอนย้าย (อังกฤษ)",
      placeholder: "สถานที่โอนย้าย (อังกฤษ)",
      properties: {
        options: options.optionsCompanyEN,
      },
      type: "DROPDOWN",
      span: 5,
      rules: {
        required:
          reqTransfer && isSelected ? "กรุณาระบุ สถานที่โอนย้าย" : false,
      },
      disabled: !(reqTransfer && isSelected),
    },
    {
      ref: `moveDate_${name}`,
      name: `moveDate_${name}`,
      key: `moveDate`,
      relation: `isTransfer`,
      placeholder: "วันที่โอนย้าย",
      label: "วันที่โอนย้าย",
      type: "DATE_PICKER",
      span: 4,
      rules: {
        required: reqTransfer && isSelected ? "กรุณาระบุ วันที่โอนย้าย" : false,
      },
      disabled: !(reqTransfer && isSelected),
    }
  ];
};

const quit = (
  name,
  reqQuit,
  isSelected
) => {
  return [
    {
      ref: `isQuit_${name}`,
      name: `isQuit_${name}`,
      key: `isQuit`,
      label: "ลาออก",
      type: "CHECK_BOX_STATE",
      span: 2,
      disabled: !isSelected,
    },
    {
      ref: `quitDate_${name}`,
      name: `quitDate_${name}`,
      key: `quitDate`,
      relation: `isQuit`,
      placeholder: "วันที่ลาออก",
      label: "วันที่ลาออก",
      type: "DATE_PICKER",
      span: 3,
      rules: {
        required: reqQuit && isSelected ? "กรุณาระบุ วันที่ลาออก" : false,
      },
      disabled: !(reqQuit && isSelected),
    },
  ];
};

const footerForm = (name, isSelected, remarkSpan) => {
  return [
    {
      ref: `runningCode_${name}`,
      name: `runningCode_${name}`,
      key: `runningCode`,
      label: "รหัสเอกสาร",
      placeholder: "รหัสเอกสาร",
      type: "INPUT",
      span: 4,
      rules: {
        required: isSelected ? "กรุณาระบุรหัสเอกสาร" : false,
      },
      relation: "footer",
      disabled: !isSelected,
    },
    {
      ref: `remark_${name}`,
      name: `remark_${name}`,
      key: `remark`,
      label: "หมายเหตุ",
      placeholder: "หมายเหตุ",
      type: "INPUT",
      span: remarkSpan,
      rules: {
        required: false,
      },
      relation: "footer",
      disabled: !isSelected,
    },
  ];
};
