import React from "react";
import { Modal, Spin } from "antd";
import { POST, GET_BEARER } from "../../service";
import { useEffect } from "react";
import Cookies from "js-cookie";
import encodeString from "../../extendFunction/encodeString";
import { authenLink } from "../../service";

const HandleScene = () => {
  const authEncoded = encodeURIComponent(window.location.search).replace(
    "%3Fauth%3D",
    ""
  );

  const auth = decodeURIComponent(authEncoded);
  console.log("file: HandleScene.js:15 ~ auth", auth);
  if (!auth) {
    window.location = authenLink;
  }

  useEffect(() => {
    onHandle();
  }, []);

  const onHandle = async () => {
    try {
      const loginRes = await POST(`/HRM_API/authen/login-central`, {
        auth,
      });
      const { accessToken } = loginRes.result;
      const profileRes = await GET_BEARER(
        `/HRM_API/authen/get-profile`,
        accessToken
      );
      const result = profileRes.result.profileData;
      //console.log("response", result);
      if (result) {
        const {
          ID_User,
          Location,
          premission,
          Username,
          DepartmentNo,
          status,
        } = result;

        const obj = {
          ID_User: encodeString.encode(ID_User),
          location: encodeString.encode(Location),
          premission: encodeString.encode(premission),
          Username: encodeString.encode(Username),
          DepartmentNo: encodeString.encode(DepartmentNo),
          status: encodeString.encode(status),
          token: encodeString.encode(accessToken),
        };
        Cookies.set("cookie_session", obj, { expires: 1 });
        Cookies.set("premission", premission, { expires: 1 });
        setTimeout(() => (window.location = "/"), 200);
      }
    } catch (error) {
      const { message } = error;
      console.error(error);
      Modal.error({
        title: "Handle Error : " + message,
      });
    }
  };

  return (
    <div style={{ padding: "10%", textAlign: "center" }}>
      <Spin tip="Loading..." spinning={true} />
    </div>
  );
};

export default HandleScene;
