import React, { useContext, useEffect, useState } from "react";
import { Spin, Form, Space, Card, Modal, Table } from 'antd';
import { configForm } from './constants/ConstantComponent';
import { useButton } from './conponents/buttons/CustomButtonAntd';
import { useRenderFormItem } from './conponents/RenderComponents';
import { ComponentRecheckCommissionIncentiveAction } from "./constants/RecheckCommissionIncentive/Components";
import { sumColumnWidth } from "./functions/UseFunction";
import { ColumnRecheckCommissionIncentiveAction, getTotalNet } from "./constants/RecheckCommissionIncentive/Columns";
import CustomTableAtnd from './conponents/tables/CustomTableAtnd';
import { browserHistory } from "react-router";
import { useOptionSelect } from "./constants/OptionSelect";
import moment from "moment";
import { useAlert } from "./conponents/modal/CustomAlert";
import { GET_COMMISSION_INCENTIVE, POST, INSERT_UPDATE_COMMISSION_INCENTIVE, UPDATE_SALE_DATA_BY_VIEW, GET_PERMISSION_USER_COMMISSION, GET_COMMISSION_INCENTIVE_BY_DOCNO } from "../service";
import { getDatePeriodNow } from './../functions/index';
import { optionInstallmentSaleStatus } from "../common/enum";
import { useNotification } from "./conponents/notification/NotificationWithIcon";
import { decryptString, encryptString } from "../service/functions/encodeFunction";
import { SetStringToObject } from "../constants/functions";
import Cookies from 'js-cookie';
import encodeString from "../extendFunction/encodeString";
import { useExportExcel } from './services/ExportExcel';
import { Header_CutOffSaleEmployeeReport } from "./constants/HeaderExcel";
import { ContractContext } from "../moduleContract/servicesContract/contractProvider";

const RecheckCommissionIncentiveAction = (props) => {
	const [form] = Form.useForm();
	const { userLogin } = useContext(ContractContext);

	const [isDepNo, setIsDepNo] = useState(false);
	const [loadingScreen, setLoadingScreen] = useState(false);
	const [dataSource, setDataSource] = useState([]);
	const [dataSourceOrigin, setDataSourceOrigin] = useState([]);
	const [selectedRowKeys, setSelectedRowKeys] = useState([]);
	const [permissionUser, setPermissionUser] = useState([]);
	const [depOption, setDepOption] = useState([]);
	const [dateStart, setDateStart] = useState('');
	const [dateEnd, setDateEnd] = useState('');
	const cookies = SetStringToObject(Cookies.get("cookie_session"));

	let optionSearch = {
		depNo: useOptionSelect("department"),
	}

	useEffect(() => {
		let start = ''
		let end = ''
		if (props.location.state && props.location.state.docNo) {
			getCommissionIncentiveByDocNo(props.location.state.docNo)
			start = moment(new Date(props.location.state.installmentStartDate))
			end = moment(new Date(props.location.state.installmentEndDate))
		} else {
			start = moment(getDatePeriodNow('start'))
			end = moment(getDatePeriodNow('end'))
		}
		getPermissionUser()
		setDateEnd(end)
		setDateStart(start)
		form.setFieldsValue({ startDate: start })
		form.setFieldsValue({ endDate: end })
	}, [])

	useEffect(() => {
		if (permissionUser.length > 0 && depOption.length == 0) {
			let temp = optionSearch.depNo.filter(el => {
				// if (el.value == 'D23' || el.value == 'D24' || el.value == 'D25' || el.value == 'D26' || el.value == 'D37') {
				// 	return el
				// }
				let index = permissionUser.findIndex(val => val.departmentNo == el.value)
				if (index != -1) {
					return el
				}
			})
			setDepOption(temp)
		}
	}, [optionSearch.depNo])

	const getPermissionUser = async () => {
		try {
			setLoadingScreen(true);
			const response = await POST(GET_PERMISSION_USER_COMMISSION, { userLogin: encodeString.decode(cookies.ID_User) });
			const { result } = response;
			if (result.length == 0) {
				browserHistory.push('/')
			} else {
				setPermissionUser(result)
			}
		} catch (err) {
			useAlert({ type: "catch", content: err.message })
			console.log("Error getPermissionUser: ", err);
			browserHistory.push('/')
		} finally {
			setLoadingScreen(false);
		}
	}

	const onFinish = (values) => {
		console.log(values)
		const { depNo } = values
		let objectBody = {
			depNo: depNo.join(','),
			startDate: moment(dateStart).format("YYYY-MM-DD"),
			endDate: moment(dateEnd).format("YYYY-MM-DD"),
			// startDate: '2022-01-21',
			// endDate: '2022-02-20',
		}
		// selectDataRecheckEmployee(objectBody);
		getCommissionIncentive(objectBody)
	};

	const formDataSource = (result) => {
		return result.map((row, index) => {
			let preObj = {
				...row,
				key: index + 1,
				statusInstallmentName: row.statusWorkerInstallment && renderLabelStatusInstallment(row.statusWorkerInstallment),
				commission: parseFloat(decryptString(row.commission)) || '',
				commissionFocusShop: parseFloat(decryptString(row.commissionFocusShop)) || '',
				commissionGuarantee: parseFloat(decryptString(row.commissionGuarantee)) || '',
				incentive: parseFloat(decryptString(row.incentive)) || '',
				incentiveFocusShop: parseFloat(decryptString(row.incentiveFocusShop)) || '',
				incentiveGuarantee: parseFloat(decryptString(row.incentiveGuarantee)) || '',
				incentiveMonth: parseFloat(decryptString(row.incentiveMonth)) || '',
				commissionMonth: parseFloat(decryptString(row.commissionMonth)) || '',
				incentiveQuater: parseFloat(decryptString(row.incentiveQuater)) || '',
				incentiveStrategy: parseFloat(decryptString(row.incentiveStrategy)) || '',
				persentSalePerMonth: parseFloat(decryptString(row.persentSalePerMonth)) || '',
				persentSalePerQuater: parseFloat(decryptString(row.persentSalePerQuater)) || '',
				rvAmount: parseFloat(decryptString(row.rvAmount)) || '',
				saleActual: parseFloat(decryptString(row.saleActual)) || '',
				saleActualQuater: parseFloat(decryptString(row.saleActualQuater)) || '',
				saleTarget: parseFloat(decryptString(row.saleTarget)) || '',
				saleTargetQuater: parseFloat(decryptString(row.saleTargetQuater)) || '',
				totalCommission: parseFloat(decryptString(row.totalCommission)) || '',
				totalCommissionBeforeUpdate: parseFloat(decryptString(row.totalCommissionBeforeUpdate)) || '',
				totalIncentive: parseFloat(decryptString(row.totalIncentive)) || '',
				totalIncentiveBeforeUpdate: parseFloat(decryptString(row.totalIncentiveBeforeUpdate)) || '',

				recoverCommission: row.recoverCommission && parseFloat(decryptString(`${row.recoverCommission}`)),
				recoverIncentive: row.recoverIncentive && parseFloat(decryptString(`${row.recoverIncentive}`)),
				remarkRecover: row.remarkRecover ? `${row.remarkRecover}` : '',
				totalIncentivePay: row.totalIncentivePay && parseFloat(decryptString(`${row.totalIncentivePay}`)),
				totalCommissionPay: row.totalCommissionPay && parseFloat(decryptString(`${row.totalCommissionPay}`)),
			}
			return {
				...preObj,
				total: getTotalNet(preObj)
			}
		});
	}

	const getCommissionIncentiveByDocNo = async (docNo) => {
		try {
			setLoadingScreen(true);
			const response = await POST(GET_COMMISSION_INCENTIVE_BY_DOCNO, { docNo, userLogin });
			const { result } = response;
			let mapResult = [];

			if (result.length !== 0) {
				form.setFieldsValue({ depNo: props.location.state.depNo.split(',') })
				form.setFieldsValue({ startDate: moment(props.location.state.installmentStartDate) })
				form.setFieldsValue({ endDate: moment(props.location.state.installmentEndDate) })
				setIsDepNo(props.location.state && props.location.state.docNo)
				mapResult = formDataSource(result)
			} else {
				useNotification({ type: "info", title: "ไม่พบข้อมูล" });
			}
			setDataSource(mapResult);
			setDataSourceOrigin(JSON.parse(JSON.stringify(mapResult)))
		} catch (err) {
			useAlert({ type: "catch", content: err.message })
			console.log("Error Search Recheck Employee: ", err);
		} finally {
			setLoadingScreen(false);
		}
	}

	const getCommissionIncentive = async (objectBody) => {
		try {
			setLoadingScreen(true);
			const response = await POST(GET_COMMISSION_INCENTIVE, objectBody);
			const { result } = response;
			let mapResult = [];

			if (result.length !== 0) {

				mapResult = formDataSource(result)
			} else {
				useNotification({ type: "info", title: "ไม่พบข้อมูล" });
			}
			setDataSource(mapResult);
			setDataSourceOrigin(JSON.parse(JSON.stringify(mapResult)))
		} catch (err) {
			useAlert({ type: "catch", content: err.message })
			console.log("Error Search Recheck Employee: ", err);
		} finally {
			setLoadingScreen(false);
		}
	}

	const renderLabelStatusInstallment = (statusWorkerInstallment) => {
		return optionInstallmentSaleStatus[optionInstallmentSaleStatus.findIndex(el => (el.value).split(' | ')[0] == statusWorkerInstallment)].label
	}

	const insertUpdateCommissionIncentive = async (statusSaveBtn) => {
		try {
			let statusSave = 0
			let index = permissionUser.findIndex(val => dataSource[0].DepartmentNo == val.departmentNo)
			if (index != -1) {
				statusSave = +(permissionUser[index].sequence)
			}

			let tempArr = []
			let depNo = []
			let checkValidRemark = true
			let checkValidRecoverRemark = true
			dataSource.map(el => {
				if (!depNo.includes(el.DepartmentNo)) {
					depNo.push(el.DepartmentNo)
				}
				if ((el.incentive || el.commission) && !el.remarkApprovalSpecial) {
					checkValidRemark = false
					return
				}
				if ((el.recoverIncentive || el.recoverCommission) && !el.remarkRecover) {
					checkValidRecoverRemark = false
					return
				}
				tempArr.push({
					...el,
					commissionGuarantee: el.commissionGuarantee && encryptString(`${el.commissionGuarantee}`),
					incentiveGuarantee: el.incentiveGuarantee && encryptString(`${el.incentiveGuarantee}`),
					commission: el.commission && (encryptString(`${el.commission}`)),
					commissionFocusShop: el.commissionFocusShop && (encryptString(`${el.commissionFocusShop}`)),
					incentive: el.incentive && (encryptString(`${el.incentive}`)),
					incentiveFocusShop: el.incentiveFocusShop && (encryptString(`${el.incentiveFocusShop}`)),
					incentiveMonth: el.incentiveMonth && (encryptString(`${el.incentiveMonth}`)),
					commissionMonth: el.commissionMonth && (encryptString(`${el.commissionMonth}`)),
					incentiveQuater: el.incentiveQuater && (encryptString(`${el.incentiveQuater}`)),
					incentiveStrategy: el.incentiveStrategy && (encryptString(`${el.incentiveStrategy}`)),
					persentSalePerMonth: el.persentSalePerMonth && (encryptString(`${el.persentSalePerMonth}`)),
					persentSalePerQuater: el.persentSalePerQuater && (encryptString(`${el.persentSalePerQuater}`)),
					rvAmount: el.rvAmount && (encryptString(`${el.rvAmount}`)),
					saleActual: el.saleActual && (encryptString(`${el.saleActual}`)),
					saleActualQuater: el.saleActualQuater && (encryptString(`${el.saleActualQuater}`)),
					saleTarget: el.saleTarget && (encryptString(`${el.saleTarget}`)),
					saleTargetQuater: el.saleTargetQuater && (encryptString(`${el.saleTargetQuater}`)),
					totalCommission: el.totalCommission && (encryptString(`${el.totalCommission}`)),
					totalCommissionBeforeUpdate: el.totalCommissionBeforeUpdate && (encryptString(`${el.totalCommissionBeforeUpdate}`)),
					totalIncentive: el.totalIncentive && (encryptString(`${el.totalIncentive}`)),
					totalIncentiveBeforeUpdate: el.totalIncentiveBeforeUpdate && (encryptString(`${el.totalIncentiveBeforeUpdate}`)),
					recoverCommission: el.recoverCommission && (encryptString(`${el.recoverCommission}`)),
					recoverIncentive: el.recoverIncentive && (encryptString(`${el.recoverIncentive}`)),
					totalIncentivePay: el.totalIncentivePay && (encryptString(`${el.totalIncentivePay}`)),
					totalCommissionPay: el.totalCommissionPay && (encryptString(`${el.totalCommissionPay}`)),
				})
			})

			if (!checkValidRemark) {
				return useAlert({ type: "warning", content: 'กรุณากรอกหมายเหตุอนุมัติพิเศษ' })
			}
			if (!checkValidRecoverRemark) {
				return useAlert({ type: "warning", content: 'กรุณากรอกหมายเหตุค่าชดเชย' })
			}

			let objectBody = {
				docNo: props.location.state && props.location.state.docNo || "",
				statusSave: statusSaveBtn ? statusSave : statusSave - 1,
				arrChild: tempArr,
				depNo: depNo.join(','),
				// startDateInstallment: '2022-01-21',
				// endDateInstallment: '2022-02-20',
				startDateInstallment: moment(dateStart).format("YYYY-MM-DD"),
				endDateInstallment: moment(dateEnd).format("YYYY-MM-DD"),
				userLogin: encodeString.decode(cookies.ID_User),
			}
			const response = await POST(INSERT_UPDATE_COMMISSION_INCENTIVE, objectBody);
			const { success, message } = response;
			if (success) {
				useAlert({ type: "success", title: "ดำเนินการสำเร็จ", content: message, Fn: () => browserHistory.push('/RecheckCommissionIncentiveDashboard') })
			}
		} catch (err) {
			useAlert({ type: "catch", content: err.message })
			console.log("Error Search Recheck Employee: ", err);
		} finally {
			setLoadingScreen(false);
		}
	}

	const onSelectChange = (selectedRowDataKeys, selectedRows) => {
		setSelectedRowKeys(selectedRowDataKeys)
	};

	const rowsSelection = {
		selectedRowKeys: selectedRowKeys,
		onChange: onSelectChange,
		getCheckboxProps: (record) => ({
			disabled: record.incomeGroupCode == 'N' || !record.saleCode || props.location.state && props.location.state.view, // Column configuration not to be checked
		}),
		selections: [
			Table.SELECTION_ALL,
			Table.SELECTION_NONE,]
	};

	const onChangeRow = (e, rowNo) => {
		let temp = [...dataSource]
		setDataSource([])
		let fieldName = e.target.name.split(/(\d+)/)[0]
		if (fieldName == 'incentiveStrategy') {
			temp[rowNo - 1][`totalIncentiveBeforeUpdate`] = +((temp[rowNo - 1][`incentiveMonth`] || 0) + parseFloat(e.target.value))
		} else if (fieldName == 'commissionFocusShop') {
			temp[rowNo - 1][`totalCommissionBeforeUpdate`] = +(temp[rowNo - 1][`commissionMonth`] + parseFloat(e.target.value))
		}
		temp[rowNo - 1][`${fieldName}`] = e.target.value
		setDataSource(temp)
	}

	const updateSalesDataByBI = () => {
		Modal.confirm({
			title: `***หลังจากดึงข้อมูลสำเร็จระบบจะทำการรีเฟรชหน้านี้ใหม่อีกครั้ง หากกำลังอยู่ระหว่างแก้ไขข้อมูล โปรดแน่ใจว่าได้บันทึกข้อมูลที่แก้ไขเรียบร้อยแล้ว`,
			okText: `ยืนยัน`,
			cancelText: `ยกเลิก`,
			onOk: async () => {
				try {
					setLoadingScreen(true);
					const response = await POST(UPDATE_SALE_DATA_BY_VIEW, { startDate: (props && props.location && props.location.state) ? moment(props.location.state.installmentStartDate) : moment(getDatePeriodNow('start')) });
					if (response.success) {
						return Modal.success({
							title: 'ดึงข้อมูลจาก BI สำเร็จ',
							afterClose: () => {
								window.location.reload()
							}
						});
					}
					useAlert({ type: "catch", content: 'เกิดข้อผิดพลาด ทำรายการไม่สำเร็จ' })
				} catch (err) {
					useAlert({ type: "catch", content: err.message || "เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง" })
					console.log("Error updateSalesDataByBI: ", err);
				} finally {
					setLoadingScreen(false);
				}
			},
		})
	}

	return (
		<Spin tip="กำลังโหลดข้อมูล ..." spinning={loadingScreen}>
			<Form form={form} onFinish={onFinish} {...configForm}>
				<Card
					title={`บันทึกการตรวจสอบ Commission/Incentive (รอบวันที่ ${moment(dateStart).format("YYYY-MM-DD")} - ${moment(dateEnd).format("YYYY-MM-DD")})`}
					style={{ marginBottom: 30 }}
					extra={
						<Space>
							{useButton({ call: "search", isDisable: props.location.state && props.location.state.docNo })}
							{useButton({
								call: "normal",
								isDisable: props.location.state && props.location.state.docNo,
								fn: () => { },
								moreProps: { label: "ล้างข้อมูล" },
							})}
						</Space>
					}
				>
					{useRenderFormItem({
						constantComponent: ComponentRecheckCommissionIncentiveAction({
							options: { depNo: depOption },
							handleChange: () => { },
							isDepNo
						}),
					})}
				</Card>
			</Form>
			<Card
				title={props.location.state && props.location.state.docNo && `เลขเอกสาร ${props.location.state.docNo}`}
				extra={
					<div style={{ display: 'flex' }}>
						{useButton({ call: "normal", isDisable: (dataSource.length > 0 && dataSource[0].statusCutOff == '1'), moreProps: { label: 'อัปเดตข้อมูลจาก BI', onClick: updateSalesDataByBI } })}
						&nbsp;
						{useExportExcel({
							header: Header_CutOffSaleEmployeeReport,
							dataset: dataSource,
							filename: "รายงานค่า commission/incentive พนักงาน",
						})}
					</div>
				}
			>
				<CustomTableAtnd
					HeadTable={ColumnRecheckCommissionIncentiveAction({ isView: props.location.state && props.location.state.view, dataSource, onChangeRow, dataSourceOrigin })}
					DataTable={dataSource}
					moreProps={{
						pageSize: 50,
						rowSelection: rowsSelection,
						scroll: {
							x: sumColumnWidth(ColumnRecheckCommissionIncentiveAction({ isView: props.location.state && props.location.state.view, dataSource, onChangeRow, dataSourceOrigin })) || 2050,
							y: 500,
						},
						size: "small"
					}}
				/>
				<div style={{ textAlignLast: 'center', marginTop: '15px' }}>
					<Space>
						{useButton({ call: "search", isDisable: dataSource.length == 0 || (props.location.state && props.location.state.view), moreProps: { label: "บันทึกร่าง", onClick: () => insertUpdateCommissionIncentive(0) } })}
						{useButton({ call: "save", isDisable: !(dataSource.length > 0 && dataSource.length == selectedRowKeys.length), moreProps: { label: "ยืนยันข้อมูล", onClick: () => insertUpdateCommissionIncentive(1) } })}
						{useButton({ call: "normal", moreProps: { label: "กลับหน้าแรก", onClick: () => { browserHistory.push('/RecheckCommissionIncentiveDashboard') } } })}
					</Space>
				</div>
			</Card>
		</Spin>
	);
};

export default RecheckCommissionIncentiveAction;
