import { COMPONENT_TYPE, ERROR_MESSAGE } from ".."

export const INIT_VALUE = {
    // ------ Card 1 ------
    company: "",
    location: "",
    depNo: "",
    subDepNo: "",
    userId: "",
    // ------ Card 2 ------
    actionDate: "",
    statusWorker: "",
    remark: "",
}

const DEFINE = {
    // ------ Card 1 ------
    company: {
        label: "บริษัท",
        name: "company",
    },
    location: {
        label: "Location",
        name: "location",
    },
    depNo: {
        label: "แผนก",
        name: "depNo",
    },
    subDepNo: {
        label: "แผนกย่อย",
        name: "subDepNo",
    },
    userId: {
        label: "รหัสพนักงาน",
        name: "userId",
    },
    // ------ Card 2 ------
    actionDate: {
        label: "วันที่มีผล",
        name: "actionDate",
    },
    statusWorker: {
        label: "สถานะพนักงาน",
        name: "statusWorker",
    },
    remark: {
        label: "คำอธิบายเพิ่มเติม",
        name: "remark",
    },
}

// ------------------------ Card 1 ----------------------------------
export const ComponentSearchChangeStatusWorker = ({
    options = {},
    isDisable = {},
    handleChange = () => null,
}) => {
    const { company, location, depNo, subDepNo, userId } = DEFINE
    return [
        { // Row
            rowProps: { justify: "start", align: "bottom" },
            column: [ // column
                {
                    breakpoint: { xs: 24, sm: 24, md: 12, lg: 6, xl: 4 },
                    component: {
                        type: COMPONENT_TYPE.SELECT,
                        itemProps: {
                            name: company.name,
                            label: company.label,
                            rules: [{ required: true, message: ERROR_MESSAGE.SELECT(company.label) }],
                        },
                        componentProps: {
                            style: { width: "100%" },
                            placeholder: ERROR_MESSAGE.SELECT(company.label),
                            disabled: isDisable[company.name] || false,
                        },
                        fnChange: (value) => handleChange(company.name, value),
                        dropdown: options[company.name],
                    },
                },
                {
                    breakpoint: { xs: 24, sm: 24, md: 12, lg: 6, xl: 4 },
                    component: {
                        type: COMPONENT_TYPE.SELECT,
                        itemProps: {
                            name: location.name,
                            label: location.label,
                            rules: [{ required: true, message: ERROR_MESSAGE.SELECT(location.label) }],
                        },
                        componentProps: {
                            style: { width: "100%" },
                            placeholder: ERROR_MESSAGE.SELECT(location.label),
                            disabled: isDisable[location.name] || false,
                        },
                        fnChange: (value) => handleChange(location.name, value),
                        dropdown: options[location.name],
                    },
                },
                {
                    breakpoint: { xs: 24, sm: 24, md: 12, lg: 12, xl: 8 },
                    component: {
                        type: COMPONENT_TYPE.SELECT,
                        itemProps: {
                            name: depNo.name,
                            label: depNo.label,
                            rules: [{ required: false, message: ERROR_MESSAGE.SELECT(depNo.label) }],
                        },
                        componentProps: {
                            style: { width: "100%" },
                            placeholder: ERROR_MESSAGE.SELECT(depNo.label),
                            disabled: isDisable[depNo.name] || false,
                        },
                        fnChange: (value) => handleChange(depNo.name, value),
                        dropdown: options[depNo.name],
                    },
                },
                {
                    breakpoint: { xs: 24, sm: 24, md: 12, lg: 12, xl: 8 },
                    component: {
                        type: COMPONENT_TYPE.SELECT,
                        itemProps: {
                            name: subDepNo.name,
                            label: subDepNo.label,
                            rules: [{ required: false, message: ERROR_MESSAGE.SELECT(subDepNo.label) }],
                        },
                        componentProps: {
                            style: { width: "100%" },
                            placeholder: ERROR_MESSAGE.SELECT(subDepNo.label),
                            disabled: isDisable[subDepNo.name] || false,
                        },
                        fnChange: (value) => handleChange(subDepNo.name, value),
                        dropdown: options[subDepNo.name],
                    },
                },
                {
                    breakpoint: { xs: 24, sm: 24, md: 12, lg: 12, xl: 8 },
                    component: {
                        type: COMPONENT_TYPE.SELECT,
                        itemProps: {
                            name: userId.name,
                            label: userId.label,
                            rules: [{ required: false, message: ERROR_MESSAGE.SELECT(userId.label) }],
                        },
                        componentProps: {
                            style: { width: "100%" },
                            placeholder: ERROR_MESSAGE.SELECT(userId.label),
                            disabled: isDisable[userId.name] || false,
                        },
                        fnChange: (value) => handleChange(userId.name, value),
                        dropdown: options[userId.name],
                    },
                },
            ], // column
        }, // Row
    ]
}

// ------------------------ Card 2 ----------------------------------
export const ComponentActionChangeStatusWorker = ({
    options = {},
    isDisable = {},
    handleChange = () => null,
}) => {
    const { actionDate, statusWorker, remark } = DEFINE
    return [
        { // Row
            rowProps: { justify: "start", align: "bottom" },
            column: [ // column
                {
                    breakpoint: { xs: 24, sm: 24, md: 12, lg: 6, xl: 4 },
                    component: {
                        type: COMPONENT_TYPE.DATE,
                        itemProps: {
                            name: actionDate.name,
                            label: actionDate.label,
                            rules: [{ required: true, message: ERROR_MESSAGE.DATEPICKER }],
                        },
                        componentProps: {
                            style: { width: "100%" },
                            placeholder: ERROR_MESSAGE.DATEPICKER,
                            disabled: isDisable[actionDate.name] || false,
                        },
                        fnChange: (date, dateString) => handleChange(actionDate.name, dateString),
                        dropdown: options[actionDate.name],
                    },
                },
                {
                    breakpoint: { xs: 24, sm: 24, md: 12, lg: 6, xl: 4 },
                    component: {
                        type: COMPONENT_TYPE.SELECT,
                        itemProps: {
                            name: statusWorker.name,
                            label: statusWorker.label,
                            rules: [{ required: true, message: ERROR_MESSAGE.SELECT(statusWorker.label) }],
                        },
                        componentProps: {
                            style: { width: "100%" },
                            placeholder: ERROR_MESSAGE.SELECT(statusWorker.label),
                            disabled: isDisable[statusWorker.name] || false,
                        },
                        fnChange: (value) => handleChange(statusWorker.name, value),
                        dropdown: options[statusWorker.name],
                    },
                },
                {
                    breakpoint: { xs: 24, sm: 24, md: 12, lg: 12, xl: 8 },
                    component: {
                        type: COMPONENT_TYPE.INPUT,
                        itemProps: {
                            name: remark.name,
                            label: remark.label,
                            rules: [{ required: false, message: ERROR_MESSAGE.SELECT(remark.label) }],
                        },
                        componentProps: {
                            style: { width: "100%" },
                            placeholder: ERROR_MESSAGE.SELECT(remark.label),
                            disabled: isDisable[remark.name] || false,
                        },
                        fnChange: (event) => handleChange(remark.name, event.target.value),
                    },
                },
            ], // column
        }, // Row
    ]
}