import { Modal } from "antd";

export function convertResponse(response, nextAction) {
    const { data, result, status } = response
    switch (status) {
        case 200:
            if (typeof nextAction === 'function') {
                return nextAction(result)
            }
            return result
        case '':
            return [];
        default:
            return { error: data.message }
    }

}


export function responseSuccess(response, action = () => null) {
    if (!!response && response.success) {
        Modal.success({
            title: `${response.message}`,
            onOk: () => action()
        })
    }
}

//*response with content
export function responseSuccessExtra(response, content, action = () => null) {
    if (!!response && response.success) {
        Modal.success({
            title: `${response.message}`,
            content,
            onOk: () => action()
        })
    }
}