import React, { useState } from "react";
import { Form, Card, Spin, Space, Tabs, Row, Col } from "antd";
import { optionSelectLocation, optionstatusStamp, optionStatusWorker, useOptionSelect } from "../../constants/OptionSelect";
import { toUndefined, sumColumnWidth, subStringDate, GET_UserLogin, DisableButtonSSO } from "../../functions/UseFunction";
import { useAlert } from "../modal/CustomAlert";
import { configForm } from "../../constants/ConstantComponent";
import { useRenderFormItem } from "../RenderComponents";
import CustomTableAtnd from "../tables/CustomTableAtnd";
import { useNotification } from "../notification/NotificationWithIcon";
import { POST, POST_INSERT_SEND_SSO, POST_SELECT_DASHBOARD_BENEFITS_2Y, POST_UPDATE_PLAN_INSURANCE } from "../../../service";
import { useButton } from "../buttons/CustomButtonAntd";
import { useExportExcel } from "../../services/ExportExcel";
import { Header_ChangeInsurancePlan } from "../../constants/HeaderExcel";
import { useFixedQueryString } from "../../functions/UseMakeQueryString";
import { RenderMainModal } from "../modal/CustomModalAntd";
import { useConfirm } from "../modal/CustomConfirm";
import moment from "moment";
import { ComponentChangeInsurancePlan, ComponentChangeInsurancePlanDatatimeStamp } from "../../constants/Benefits/Components";
import { ColumnChangeInsurancePlan } from "../../constants/Benefits/Columns";
import { useSelect } from "../select/SelectAntd";

const FormChangeInsurancePlan = () => {
    const [form] = Form.useForm();
    const [formSendSSO] = Form.useForm();
    const { userlogin } = GET_UserLogin();
    // [START] States
    const [ChangeValue, setChangeValue] = useState({});
    const [InsurancePlan, setInsurancePlan] = useState(undefined);
    const [dataTable, setDataTable] = useState([]);
    const [rowSelection, setRowSelection] = useState({ selectedRowKeys: [], selectedRows: [] });
    const [loadingScreen, setLoadingScreen] = useState(false);
    const [showModal, setShowModal] = useState(false)
    const [showModalUpdate, setShowModalUpdate] = useState(false)

    // [END] States
    let optionSearch = {
        company: useOptionSelect("company"),
        location: optionSelectLocation,
        depNo: useOptionSelect("department", useFixedQueryString(ChangeValue, ["companyCode", "location"])),
        subDepNo: useOptionSelect("subDepartment", useFixedQueryString(ChangeValue, ["company", "location", "depNo"])),
        statusWorker: optionStatusWorker,
        statusStamp: optionstatusStamp,
        insurancePlan: useOptionSelect("insurancePlan"),
    };


    // [START] Handle Form Antd
    const onFinish = (values) => {
        const { company, location, depNo, subDepNo, startDate, toDate, statusStamp, statusWorker } = values
        let objectBody = {
            company: company || "",
            location: location || "",
            depNo: depNo || "",
            subDepNo: subDepNo || "",
            startDate: startDate ? startDate.format("YYYY-MM-DD") : "",
            toDate: toDate ? toDate.format("YYYY-MM-DD") : "",
            statusWorker: statusWorker || "",
            statusStamp: statusStamp || "",
            //userId: userId || "",
        }
        selectDashboardBenefits2Y(objectBody)
    };

    const onFinishSendSSO = (values) => {
        const { datatimeStamp } = values
        const { selectedRows } = rowSelection
        let dataArray = [];
        if (selectedRows.length === 0) {
            useAlert({
                type: "warning",
                title: "แจ้งเตือน",
                content: `คุณยังไม่ได้เลือก "พนักงาน" กรุณาลองใหม่อีกครั้ง`,
                Fn: () => null,
            });
        } else if (!datatimeStamp) {
            useAlert({
                type: "warning",
                title: "แจ้งเตือน",
                content: `คุณยังไม่ได้เลือก "วันที่ยื่น" กรุณาลองใหม่อีกครั้ง`,
                Fn: () => null,
            });
        } else {
            dataArray = selectedRows.map((row) => ({
                userId: row.userId,
                typeInsurance: "hea",
                planInsuranceHea: row.planHea || "",
                planInsuranceAcd: row.planAcd || "",
                company: row.company,
                typeBenefit: "in",
                datatimeStamp: datatimeStamp.format("YYYY-MM-DD"),
                docRef: "",
                userlogin,
            }));
            //console.log("dataArray", dataArray)
            useConfirm({ FnOK: () => sendSSO(dataArray) })
        }

    };

    const onReset = () => {
        form.resetFields();
        setDataTable([]);
        setChangeValue({});
        setRowSelection({ selectedRowKeys: [], selectedRows: [] })
    };

    const selectDashboardBenefits2Y = async (objectValue) => {
        try {
            setLoadingScreen(true);
            setRowSelection({ selectedRowKeys: [], selectedRows: [] })
            setDataTable([]);
            const response = await POST(POST_SELECT_DASHBOARD_BENEFITS_2Y, objectValue);
            const { result } = response;
            let mapResult = [];
            if (result.length !== 0) {
                mapResult = result.map((row, index) => ({
                    key: `${row.userId}_${index}`,
                    ...row,
                    startDateJob: subStringDate(row.startDateJob),
                    birthday: subStringDate(row.birthday),
                    datatimePDV: subStringDate(row.datatimePDV),
                    datatimeHEA: subStringDate(row.datatimeHEA),
                }));
            } else {
                useNotification({ type: "info", title: "ไม่พบข้อมูล" });
            }
            setDataTable(mapResult);
        } catch (err) {
            useAlert({ type: "catch", content: err.message })
            console.log("Error Select Dashboar dBenefits 2Y: ", err);
        } finally {
            setLoadingScreen(false);
        }
    };

    const updatePlanInsurance = async (typePlan) => {
        try {
            const { selectedRows } = rowSelection
            if (selectedRows.length === 0) useAlert({ type: "warning", title: `กรุณาเลือกอย่างน้อย 1 รายการ` });
            if (!InsurancePlan) useAlert({ type: "warning", title: `กรุณาเลือก แผนประกัน ก่อนดำเนินการต่อไป` });
            setLoadingScreen(true);
            let objectPlan = {}
            if (typePlan === "hea") {
                objectPlan = { planHea: InsurancePlan, planAcd: "" }
            }
            if (typePlan === "acd") {
                objectPlan = { planHea: "", planAcd: InsurancePlan }
            }
            let dataBody = selectedRows.map((row) => ({
                userId: row.userId,
                ...objectPlan,
                userlogin,
            }));
            // console.log("dataBody", dataBody)
            const response = await POST(POST_UPDATE_PLAN_INSURANCE, dataBody);
            const { success, result } = response;
            if (success) {
                useAlert({ type: "success", title: `${result}`, Fn: () => window.location.reload() })
            } else {
                useAlert({ type: "info", title: "บันทึกข้อมูลไม่สำเร็จ กรุณาลองใหม่อีกครั้ง" })
            }
        } catch (err) {
            useAlert({ type: "catch", content: err.message })
            console.log("Error Update Plan Insurance: ", err);
        } finally {
            setLoadingScreen(false);
        }
    };

    const sendSSO = async (dataBody) => {
        try {
            // console.log("dataBody", dataBody)
            const response = await POST(POST_INSERT_SEND_SSO, dataBody);
            const { success, result } = response;
            if (success) {
                useAlert({
                    type: "success", content: `${result}`, Fn: () => window.location.reload(),
                });
            } else {
                useAlert({ type: "info", content: `บันทึกข้อมูลไม่สำเร็จ กรุณาลองใหม่อีกครั้ง` });
            }
        } catch (err) {
            useAlert({ type: "catch", content: err.message })
            console.log("Error Send SSO (HEA): ", err);
        } finally {
            setLoadingScreen(false);
        }
    };
    // [END] Handle Form Antd

    // [START] Functions
    const onChangeValue = (name, value) => {
        // console.log(name, " : ", value)
        let setUndefined = {};
        let setName = {};
        switch (name) {
            case "company":
                setName = { companyCode: value };
                setUndefined = toUndefined(["location", "depNo", "subDepNo"]);
                break;
            case "location":
                setUndefined = toUndefined(["depNo", "subDepNo"]);
                break;
            case "depNo":
                setUndefined = toUndefined(["subDepNo"]);
                break;
        }
        if (name !== "datatimeStamp") setDataTable([]);
        form.setFieldsValue(setUndefined);
        setChangeValue((prev) => ({
            ...prev,
            ...setUndefined,
            ...setName,
            [name]: value,
        }));
    };

    const contentChangePlanHea = (type) => {
        return (
            <div>
                <Row gutter={[16, 16]} justify="start" align="middle" style={{ marginBottom: 30 }}>
                    <Col span={8}>
                        ปรับแผนประกัน :
                    </Col>
                    <Col span={16}>
                        {useSelect({
                            componentProps: {
                                style: { width: "100%" },
                                placeholder: "เลือก เปลี่ยนแผนประกัน",
                                disabled: false,
                                value: InsurancePlan
                            },
                            fnChange: (value) => setInsurancePlan(value),
                            dropdown: optionSearch.insurancePlan,
                        })}
                    </Col>
                </Row>
                <Row gutter={[16, 16]} justify="end" align="middle">
                    <Col span={8}>
                        {useButton({
                            call: "normal",
                            moreProps: { label: "ยืนยัน", type: "primary" },
                            fn: () => useConfirm({ FnOK: () => updatePlanInsurance(type) }),
                            isDisable: InsurancePlan ? false : true,
                        })}
                    </Col>
                </Row>
            </div>
        )
    }
    // [END] Functions
    return (
        <Spin tip="กำลังโหลดข้อมูล ..." spinning={loadingScreen}>
            <RenderMainModal
                show={showModal}
                onCancel={() => {
                    setInsurancePlan(undefined);
                    setShowModal(false);
                }}
                txtTitle={"ปรับแผนประกันสุขภาพ"}
                ModalContent={contentChangePlanHea("hea")}
                width={550}
            />
            <RenderMainModal
                show={showModalUpdate}
                onCancel={() => {
                    setInsurancePlan(undefined);
                    setShowModalUpdate(false);
                }}
                txtTitle={"ปรับแผนประกันอุบัติเหตุ"}
                ModalContent={contentChangePlanHea("acd")}
                width={550}
            />

            <Form form={form} onFinish={onFinish} {...configForm}>
                <Card
                    title="ค้นหารายการปรับแผนประกัน (เมื่ออายุงานครบ 2 ปี)"
                    style={{ marginBottom: 30 }}
                    extra={
                        <Space>
                            {useButton({ call: "search" })}
                            {useButton({
                                call: "normal",
                                fn: onReset,
                                moreProps: { label: "ล้างข้อมูล" },
                            })}
                        </Space>
                    }
                >
                    {useRenderFormItem({
                        constantComponent: ComponentChangeInsurancePlan({
                            options: optionSearch,
                            handleChange: onChangeValue,
                        }),
                    })}
                </Card>
            </Form>
            {dataTable.length !== 0 && (
                <Card
                    title="รายการปรับแผนประกัน (เมื่ออายุงานครบ 2 ปี)"
                    style={{ marginBottom: 30 }}
                    extra={
                        <Space wrap>
                            {useButton({
                                call: "normal",
                                fn: () => setShowModal(true),
                                moreProps: { label: "ปรับแผนประกันสุขภาพ" },
                                isDisable: rowSelection.selectedRowKeys.length === 0
                            })}
                            {useButton({
                                call: "normal",
                                fn: () => setShowModalUpdate(true),
                                moreProps: { label: "ปรับแผนประกันอุบัติเหตุ" },
                                isDisable: rowSelection.selectedRowKeys.length === 0
                            })}
                            {useButton({
                                call: "normal",
                                fn: () =>
                                    useConfirm({
                                        content: `ยืนยันการส่ง "ประกันสุขภาพ/อุบัติเหตุ" ใช่หรือไม่`,
                                        //FnOK: () => sendSSO("hea"),
                                        FnOK: () => formSendSSO.submit(),
                                    }),
                                moreProps: { label: "ส่งประกันสุขภาพ/อุบัติเหตุ" },
                                isDisable: DisableButtonSSO(rowSelection.selectedRows, "datatimeHEA"),
                            })}
                        </Space>
                    }
                >
                    <Form
                        form={formSendSSO}
                        {...configForm}
                        onFinish={onFinishSendSSO}
                        initialValues={{ datatimeStamp: moment() }}
                    >
                        {useRenderFormItem({
                            constantComponent: ComponentChangeInsurancePlanDatatimeStamp({
                                handleChange: onChangeValue,
                            }),
                        })}
                    </Form>
                    <Tabs
                        tabBarExtraContent={
                            <Space>
                                {useExportExcel({
                                    header: Header_ChangeInsurancePlan,
                                    dataset: dataTable,
                                    filename: "รายการปรับแผนประกัน",
                                })}
                            </Space>
                        }
                    >
                        <Tabs.TabPane tab="ข้อมูลรายการปรับแผนประกัน" key="1">
                            <CustomTableAtnd
                                HeadTable={ColumnChangeInsurancePlan}
                                DataTable={dataTable}
                                moreProps={{
                                    rowSelection: {
                                        onChange: (selectedRowKeys, selectedRows) => {
                                            setRowSelection({ selectedRowKeys, selectedRows });
                                        },
                                    },
                                    scroll: {
                                        x: sumColumnWidth(ColumnChangeInsurancePlan) || 2050,
                                        y: 500,
                                    },
                                }}
                            />
                        </Tabs.TabPane>
                    </Tabs>
                </Card>
            )}

        </Spin>
    );
};

export default FormChangeInsurancePlan;
