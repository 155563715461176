import App from "./views/App";
import InsertEmployee from "./views/InsertEmployee";
import UpdateEmployee from "./views/UpdateEmployee";
import ImportEmployee from "./views/ImportEmployee";
import Report from "./views/ReportEmployee";
import DashboardSelfAssessment from "./views/DashboardSelfAssessment";
import DashboardDiscussionForm from "./views/DashboardDiscussionForm";
import SelfAssessmentForm from "./views/SelfAssessmentForm";
import UpdateSelfAssessmentForm from "./views/UpdateSelfAssessmentForm";
import PerformanceEvaluationFormStaff from "./views/PerformanceEvaluationFormStaff";
import PerformanceEvaluationFormOfficer from "./views/PerformanceEvaluationFormOfficer";
import PerformanceEvaluationFormSupervisor from "./views/PerformanceEvaluationFormSupervisor";
import PerformanceEvaluationFormMD from "./views/PerformanceEvaluationFormMD";
import DiscussionForm from "./views/DiscussionForm";
import UpdateDiscussionForm from "./views/UpdateDiscussionForm";
import DashboardPerformance from "./views/DashboardPerformance";
import DashboardProbation from "./views/DashboardProbation";
import Update_PerformanceEvaluationFormMD from "./views/Update_PerformanceEvaluationFormMD";
import Update_PerformanceEvaluationFormOfficer from "./views/Update_PerformanceEvaluationFormOfficer";
import Update_PerformanceEvaluationFormStaff from "./views/Update_PerformanceEvaluationFormStaff";
import Update_PerformanceEvaluationFormSupervisor from "./views/Update_PerformanceEvaluationFormSupervisor";
import SummaryReportPEF from "./views/SummaryReportPEF";

import InsertProbation from "./components/probation/InsertProbation";
import ImportGrade from "./views/ImportGrade";
import PrintSelfAssessment from "./views/PrintSelfAssessment";
import PrintDiscussionForm from "./views/PrintDiscussionForm";
import ReportNewEmployee from "./views/ReportNewEmployee";
import PrintPE_STF from "./views/PrintPE_STF";
import PrintPE_OFF from "./views/PrintPE_OFF";
import PrintPE_SUP from "./views/PrintPE_SUP";
import PrintPE_MAN from "./views/PrintPE_MAN";
import ImportScore_history from "./views/ImportScore_history";
import DashboardRecheckGrade from "./views/DashboardRecheckGrade";
import DashboardGrade from "./views/DashboardGrade";
import home from "./views/home";
import login from "./views/login";
import notFound from "./views/notFound";
import ProbationReport from "./components/probation/ProbationReport";
import ReportBenefit from "./views/ReportBenefit";
import ViewProbation from "./components/probation/ViewProbation";
import ImportDepartment from "./views/ImportDepartment";
import ImportJobPosition from "./views/ImportJobPosition";
import ImportUpdateEmployee from "./views/ImportUpdateEmployee";

import ImportApprovalFlow from "./views/ImportApprovalFlow";
import ImportPermissionView from "./views/ImportPermissionView";
import ImportKPIsFacHistory from "./views/ImportKPIsFacHistory";

import ReportBenefitOut from "./views/ReportBenefitOut";
import ReportTrackProbation from "./views/ReportTrackProbation";
import DashboardPlanKnowleage from "./views/DashboardPlanKnowleage";

import DashboardCF_DIS from "./views/DashboardCF_DIS";
import CF_DiscussionForm from "./views/CF_DiscussionForm";
import ImportImageStaff from "./views/ImportImageStaff";
import Assets_NewEmpForm from "./views/Assets_NewEmpForm";
import Assets_NewEmpMain from "./views/Assets_NewEmpMain";
import Assets_NewEmpView from "./views/Assets_NewEmpView";
import Assets_NewEmpPrint from "./views/Assets_NewEmpPrint";
import DashboardAssetEmp from "./views/DashboardAssetEmp";
import Assets_QuitEmpForm from "./views/Assets_QuitEmpForm";
import Assets_QuitEmpMain from "./views/Assets_QuitEmpMain";
import Assets_QuitEmpView from "./views/Assets_QuitEmpView";
import Assets_QuitFormConfirm from "./views/Assets_QuitFormConfirm";
import Assets_QuitFormPrint from "./views/Assets_QuitFormPrint";
import dashboardImportEmployee from "./views/dashboardImportEmployee";

import ImportNumDayDailyWorker from "./views/ImportNumDayDailyWorker";

import { PageManageKPIs } from "./moduleKPIs/containers/pages/pageManageKPIs";
import { PageManageIndicator } from "./moduleKPIs/containers/pages/pageManageIndicator";
import { PATHNAME } from "./constants/enums/pathname";
import { PageManageKPIsFactory } from "./moduleKPIs/containers/pages/pageManageKPIsFactory";
import { PageReportKPIsFactory } from "./moduleKPIs/containers/pages/pageReportKPIsFactory";
import { AssessmentFactoryByDay } from "./moduleKPIs/components/formPrint/assessmentFactory";
import PageDashboardOT from "./moduleOT/containersOT/pageDashboardOT";
import PageReportOT from "./moduleOT/containersOT/pageReportOT";
import PageInsertOT from "./moduleOT/containersOT/pageInsertOT";
import PageUpdateOT from "./moduleOT/containersOT/pageUpdateOT";
import PageExportOT from "./moduleOT/containersOT/pageExportOT";

import editCasePro from "./views/editCasePro";
import PageDashboardEvac from "./moduleEvac/containersEvac/pageDashboardEvac";
import PageInsertEvac from "./moduleEvac/containersEvac/pageInsertEvac";
import PageDashboardReviewer from "./moduleEvac/containersEvac/pageDashboardReviewer";
import PageInsertReviewer from "./moduleEvac/containersEvac/pageInsertReviewer";
import PageUpdateReviewer from "./moduleEvac/containersEvac/pageUpdateReviewer";
import PageViewReviewer from "./moduleEvac/containersEvac/pageViewReviewer";
import PageDashboadEvacByAdmin from "./moduleEvac/containersEvac/pageDashboardEvacByAdmin";
import PageDashboadEvacByDep from "./moduleEvac/containersEvac/pageDashboardEvacByDep";
import PageDashboardEndSaveEvac from "./moduleEvac/containersEvac/pageDashboardEndSaveEvac";
import PagePriviewReviewer from "./moduleEvac/containersEvac/pagePriviewReviewer";

import PageDashboardTraining from "./moduleTraining/containersTraining/pageDashboardTraining";
import PageImportTraining from "./moduleTraining/containersTraining/pageImportTraining";

import CovertFileTax from "./views/CovertFileTax";
import {
  PageInsertTraining,
  PageUpdateTraining,
  PageViewTraining,
} from "./moduleTraining/containersTraining/pageManageTraining";
import PageDashboardHistoryIndividual from "./moduleTraining/containersTraining/pageDashboardHistoryIndividual";
import PageDashboardHistoryTrainers from "./moduleTraining/containersTraining/pageDashboardHistoryTrainers";
import {
  PagePrintIndividual,
  PagePrintTraining,
} from "./moduleTraining/containersTraining/pagePrintTraining";
import PageDashboardContract from "./moduleContract/containersContract/pageDashboardContract";
import PageDashboardEmpContract from "./moduleContract/containersContract/pageDashboardEmpContract";
import PageDashboardEmpContractNew from "./moduleContract/containersContract/pageDashboardEmpContractNew";
import PageDashboardTrackContract from "./moduleContract/containersContract/pageDashboardTrackContract";
import PageDashboardBenefitContract from "./moduleContract/containersContract/pageDashboardBenefitContract";
import {
  PageInsertContract,
  PageUpdateContract,
  PageViewContract,
} from "./moduleContract/containersContract/pageManageContract";
import PageDashboardGuarantee from "./moduleContract/containersContract/pageDashboardGuarantee";
import { ModulePersonal } from "./modulePersonal/indexPersonal.jsx";
import ManagePosition from "./screens/ManagePosition";
import ManageSubDepartment from "./screens/ManageSubDepartment";
import ManageBranchApprove from "./screens/ManageBranchApprove";
import FormEditBranchApprove from "./screens/conponents/forms/FormEditBranchApprove";
import ManageBranchView from "./screens/ManageBranchView";
import FromEditBranchView from "./screens/conponents/forms/FormEditBranchView";
import ManageOJT from "./screens/ManageOJT";
import FormEditTraining from "./screens/conponents/forms/FormEditTraining";
import FormEditOJTCourse from "./screens/conponents/forms/FormEditOJTCourse";
import ManageKPIs from "./screens/ManageKPIs";
import FormEditKPIs from "./screens/conponents/forms/FormEditKPIs";
import ManageSSO from "./screens/ManageSSO";
import FormEditPosition from "./screens/conponents/forms/FormEditPosition";
import FormEditSubDepartment from "./screens/conponents/forms/FormEditSubDepartment";
import ManageCustomizeRole from "./screens/ManageCustomizeRole";
import FormShowSSOInfo from "./screens/conponents/forms/FormShowSSOInfo";
import FormEditSSO from "./screens/conponents/forms/FormEditSSO";
import ManageJG_PG from "./screens/ManageJG_PG";
import ManageCrime from "./screens/ManageCrime";
import ManageMasterProbation from "./screens/ManageMasterProbation";
import FormEditProbation from "./screens/conponents/forms/FormEditProbation";
import ManageTraining from "./screens/ManageTraining";
import FormReportAll from "./screens/conponents/forms/FormReportAll";
import FormReportLevelWorker from "./screens/conponents/forms/FormReportLevelWorker";

//TODO///
import FormReportPersonal from "./screens/conponents/forms/FormReportPersonal";
//TODO//
import FormReportEmployeeAge from "./screens/conponents/forms/FormReportEmployeeAge";
import FormReportWorkAge from "./screens/conponents/forms/FormReportWorkAge";
import FormReportStatusWorker from "./screens/conponents/forms/FormReportStatusWorker";
import FormReportEmployeeTransfer from "./screens/conponents/forms/FormReportEmployeeTransfer";
import FormReportEmployeeSummary from "./screens/conponents/forms/FormReportEmployeeSummary";
import FormEditCrime from "./screens/conponents/forms/FormEditCrime";
import FormShowDetailCrime from "./screens/conponents/forms/FormShowDetailCrime";
import FormSearchBenefitNewEmp from "./screens/conponents/forms/FormSearchBenefitNewEmp";
import FormSearchBenefitNewEmpTemp from "./screens/conponents/forms/FormSearchBenefitNewEmpTemp";
import FormSearchBenefitOutEmp from "./screens/conponents/forms/FormSearchBenefitOutEmp";
import TestPage from "./screens/conponents/TestPage";
import FormTransferCompany from "./screens/conponents/forms/FormTransferCompany";
import FormTransferDepartments from "./screens/conponents/forms/FormTransferDepartments";
import { PATH_ROUTE } from "./screens/constants/ConstantPath";
import FormTransferSubDepartment from "./screens/conponents/forms/FormTransferSubDepartment";
import FormTransferHrmFlow from "./screens/conponents/forms/FormTransferHrmFlow";
import FormTransferViewFlow from "./screens/conponents/forms/FormTransferViewFlow";
import FormTransferPosition from "./screens/conponents/forms/FormTransferPosition";
import FormReportCrimes from "./screens/conponents/forms/FormReportCrimes";
import FormImportTempleteFAC from "./screens/conponents/forms/FormImportTempleteFAC";
import FormSearchProbationEmpContract from "./screens/conponents/forms/FormSearchProbationEmpContract";
import FormSearchProbationEmpContractNew from "./screens/conponents/forms/FormSearchProbationEmpContractNew";
import FormTrackProbation from "./screens/conponents/forms/FormTrackProbation";
import FormTrackContract from "./screens/conponents/forms/FormTrackContract";
import FormSearchProbationEmpNew from "./screens/conponents/forms/FormSearchProbationEmpNew";
import FormSearchChangeStatusWorker from "./screens/conponents/forms/FormSearchChangeStatusWorker";
import FormManageDocProbation from "./screens/conponents/forms/FormManageDocProbation";
import FormReportReturn from "./screens/conponents/forms/FormReportReturn";
import FormManageBenefitContract from "./screens/conponents/forms/FormManageBenefitContract";
import FormManageDocProbationContract from "./screens/conponents/forms/FormManageDocProbationContract";
import FormReportOT from "./screens/conponents/forms/FormReportOT";
import FormReportResignRate from "./screens/conponents/forms/FormReportResignRate";
import FormReportWarning from "./screens/conponents/forms/FormReportWarning";
import FormReportBonus from "./screens/conponents/forms/FormReportBonus";
import FormReportCheckEmployees from "./screens/conponents/forms/FormReportCheckEmployees";
import FormReportCharge from "./screens/conponents/forms/FormReportCharge";
import FormReportExamineFullTemp from "./screens/conponents/forms/FormReportExamineFullTemp";
import FormManageOT from "./screens/conponents/forms/FormManageOT";
import FormPrintCertificateSalaryVISA from "./screens/conponents/forms/FormPrintCertificateSalaryVISA";
import FormChangeInsurancePlan from "./screens/conponents/forms/FormChangeInsurancePlan";
import FormManageBenefitTransfer from "./screens/conponents/forms/FormManageBenefitTransfer";
import FormReportRecruiterGrade from "./screens/conponents/forms/FormReportRecruiterGrade";
import FormReportRecruiterScore from "./screens/conponents/forms/FormReportRecruiterScore";
import InsertNewSaleEmployee from "./screens/InsertNewSaleEmployee";
import ImportIncentiveStrategy from "./screens/ImportIncentiveStrategy";
import SaleCodeAreaAssignment from "./screens/SaleCodeAreaAssignment";
import RecheckCommissionIncentiveDashboard from "./screens/RecheckCommissionIncentiveDashboard";
import RecheckCommissionIncentiveAction from "./screens/RecheckCommissionIncentiveAction";
import FilterOutSalesEmpCommission from "./screens/FilterOutSalesEmpCommission";
import CutOffCommissionIncentive from "./screens/CutOffCommissionIncentive";
import BenefitAndHoliday from "./views/BenefitAndHoliday";
import FormChangeShiftWork from "./screens/conponents/forms/FormChangeShiftWork";
import RecheckWorkingSaleEmployee from "./screens/RecheckWorkingSaleEmployee";
import CutOffCommissionIncentiveDashboard from "./screens/CutOffCommissionIncentiveDashboard";
import ImportMapFocusShopSaleCode from "./screens/ImportMapFocusShopSaleCode";
import CertificateRequestDashboardScene from "./moduleBenefit/CertificateRequest/CertificateRequestDashboard";
import CertificateRequestDetailScene from "./moduleBenefit/CertificateRequest/CertificateRequestDetail";
import RecheckCommissionIncentiveDashboardFSP from "./screens/RecheckCommissionIncentiveDashboardFSP";
import InsertNewSaleEmployeeFSP from "./screens/InsertNewSaleEmployeeFSP";
import SaleCodeAreaAssignmentFSP from "./screens/SaleCodeAreaAssignmentFSP";
import InsertSaleTargetFSP from "./screens/InsertSaleTargetFSP";
import RecheckCommissionIncentiveActionFSP from "./screens/RecheckCommissionIncentiveActionFSP";
import DashboardImportPersonalFac from "./modulePersonal/containersPersonal/dashboardImportPersonalFac";
import CutOffCommissionIncentiveDashboardFSP from "./screens/CutOffCommissionIncentiveDashboardFSP";
import CutOffCommissionIncentiveFSP from "./screens/CutOffCommissionIncentiveFSP";
import RecheckCommissionIncentiveDashboardView from "./screens/RecheckCommissionIncentiveDashboardView";
import RecheckCommissionIncentiveView from "./screens/RecheckCommissionIncentiveView";
import FormContractDashboardScene from "./moduleFormContract/PrintForm/FormContractDashboard";
import FormContractDetailScene from "./moduleFormContract/PrintForm/FormContractDetail";
import FormHireDetailScene from "./moduleFormContract/PrintForm/FormHireDetail";
import DashboardMonitorFile from "./modulePersonal/dashboardMonitorFile";
import DashboardMonitorFileView from "./modulePersonal/dashboardMonitorFileView";
import ChangePassword from "./screens/ChangePassword";

export const ALL_ROUTE_PATH = [
  // Personal
  {
    path: PATHNAME.INSERT_PERSONAL,
    component: ModulePersonal.InsertPersonal,
  },
  {
    path: `${PATHNAME.IMPORT_PERSONAL}/:candidateNo`,
    component: ModulePersonal.ImportPersonal,
  },
  {
    path: `${PATHNAME.UPDATE_PERSONAL}/:userId`,
    component: ModulePersonal.UpdatePersonal,
  },
  {
    path: `${PATHNAME.VIEW_PERSONAL}/:userId`,
    component: ModulePersonal.ViewPersonal,
  },
  {
    path: `${PATHNAME.BLOCK_PERSONAL}/:userId`,
    component: ModulePersonal.BlockPersonal,
  },
  {
    path: PATHNAME.DASHBOARD_PERSONAL,
    component: ModulePersonal.DashboardPersonal,
  },
  {
    path: PATHNAME.DASHBOARD_PERSONAL_VIEW,
    component: ModulePersonal.DashboardPersonalView,
  },
  {
    path: PATHNAME.DASHBOARD_IMPORT_PERSONAL,
    component: ModulePersonal.DashboardImportPersonal,
  },
  {
    path: `${PATHNAME.RECHECK_PERSONAL}`, // /:userId
    component: ModulePersonal.RecheckPersonal,
  },
  // End Personal

  // End จัดการพนักงานเข้าใหม่
  { path: "InsertEmployee", component: InsertEmployee },
  { path: "ReportEmployee", component: Report },
  { path: "UpdateEmployee/:UserID", component: UpdateEmployee },
  { path: "ImportJobPosition", component: ImportJobPosition },
  { path: "ImportDepartment", component: ImportDepartment },
  { path: "ImportUpdateEmployee", component: ImportUpdateEmployee },
  { path: "ImportApprovalFlow", component: ImportApprovalFlow },
  { path: "ImportPermissionView", component: ImportPermissionView },
  { path: "ImportImageStaff", component: ImportImageStaff },
  { path: "dashboardImportEmployee", component: dashboardImportEmployee },
  { path: "ImportEmployee/:CandidateNo", component: ImportEmployee },
  // End จัดการพนักงานเข้าใหม่

  // End แบบทดลองงาน
  { path: "DashboardProbation", component: DashboardProbation },
  { path: "UpdateProbation", component: InsertProbation },
  { path: "InsertProbation", component: InsertProbation },
  { path: "ViewProbation", component: ViewProbation },
  { path: "ReportNewEmployee", component: ReportNewEmployee },
  { path: "ProbationReport", component: ProbationReport },
  { path: "ReportBenefit", component: ReportBenefit },
  { path: "ReportBenefitOut", component: ReportBenefitOut },
  { path: "ReportTrackProbation", component: ReportTrackProbation },
  // End แบบทดลองงาน

  // KPIs OT
  { path: PATHNAME.DASHBOARD_OT_FACTORY, component: PageDashboardOT },
  { path: PATHNAME.REPORT_OT_FACTORY, component: PageReportOT },
  { path: PATHNAME.INSERT_REQUEST_OT, component: PageInsertOT },
  { path: PATHNAME.UPDATE_REQUEST_OT, component: PageUpdateOT },
  { path: PATHNAME.EXPORT_OT_FACTORY, component: PageExportOT },
  // End KPIs OT

  // Evac รายบุคคล
  { path: PATHNAME.DASHBOARD_REVIEWER, component: PageDashboardReviewer },
  { path: PATHNAME.INSERT_REVIEWER, component: PageInsertReviewer },
  { path: PATHNAME.UPDATE_REVIEWER, component: PageUpdateReviewer },
  { path: PATHNAME.VIEW_REVIWER, component: PageViewReviewer },
  { path: PATHNAME.DASHBOARD_EVAC, component: PageDashboardEvac },
  { path: PATHNAME.INSERT_EVAC, component: PageInsertEvac },
  {
    path: PATHNAME.DASHBOARD_EVAC_BY_ADMIN,
    component: PageDashboadEvacByAdmin,
  },
  { path: PATHNAME.DASHBOARD_EVAC_BY_DEP, component: PageDashboadEvacByDep },
  { path: PATHNAME.PRIVIEW_REVIEWER, component: PagePriviewReviewer },
  // End Evac รายบุคคล

  // Training
  { path: PATHNAME.DASHBOARD_TRAINING, component: PageDashboardTraining },
  {
    path: PATHNAME.DASHBOARD_HISTORY_INDIVIDUAL,
    component: PageDashboardHistoryIndividual,
  },
  {
    path: PATHNAME.DASHBOARD_HISTORY_TRAINERS,
    component: PageDashboardHistoryTrainers,
  },
  { path: PATHNAME.INSERT_TRAINING, component: PageInsertTraining },
  { path: PATHNAME.UPDATE_TRAINING, component: PageUpdateTraining },
  { path: PATHNAME.VIEW_TRAINING, component: PageViewTraining },
  { path: PATHNAME.IMPORT_TRAINING, component: PageImportTraining },
  { path: PATHNAME.HISTORY_INDIVIDUAL_FORM, component: PagePrintIndividual },
  { path: PATHNAME.TRAINING_FORM, component: PagePrintTraining },
  // End Training

  // Set Contract
  {
    path: PATHNAME.DASHBOARD_FOLLOWUP_GURANTEE,
    component: PageDashboardGuarantee,
  },
  {
    path: PATHNAME.DASHBOARD_PROBATION_CONTRACT,
    component: PageDashboardContract,
  },
  {
    path: PATHNAME.DASHBOARD_PROBATION_EMP_CONTRACT,
    component: PageDashboardEmpContract,
  },
  {
    path: PATHNAME.DASHBOARD_PROBATION_EMP_CONTRACT_NEW,
    component: PageDashboardEmpContractNew,
  },
  {
    path: PATHNAME.DASHBOARD_TRACK_CONTRACT,
    component: PageDashboardTrackContract,
  },
  {
    path: PATHNAME.DASHBOARD_BENEFIT_CONTRACT,
    component: PageDashboardBenefitContract,
  },
  { path: PATHNAME.INSERT_PROBATION_CONTRACT, component: PageInsertContract },
  { path: PATHNAME.UPDATE_PROBATION_CONTRACT, component: PageUpdateContract },
  { path: PATHNAME.VIEW_PROBATION_CONTRACT, component: PageViewContract },
  // End Set Contract

  // แบบประเมินตนเอง
  { path: "DashboardSelfAssessment", component: DashboardSelfAssessment },
  { path: "SelfAssessmentForm", component: SelfAssessmentForm },
  {
    path: "UpdateSelfAssessmentForm/:docNo",
    component: UpdateSelfAssessmentForm,
  },
  { path: "PrintSelfAssessment/:DocNos", component: PrintSelfAssessment },
  { path: "DashboardCF_DIS", component: DashboardCF_DIS },
  { path: "CF_DiscussionForm/:DocCF", component: CF_DiscussionForm },
  // End แบบประเมินตนเอง

  // Reduce Admin
  { path: "Assets_NewEmpForm", component: Assets_NewEmpForm },
  { path: "Assets_NewEmpMain", component: Assets_NewEmpMain },
  { path: "Assets_NewEmpView", component: Assets_NewEmpView },
  { path: "Assets_NewEmpPrint", component: Assets_NewEmpPrint },
  { path: "Assets_QuitEmpForm", component: Assets_QuitEmpForm },
  { path: "Assets_QuitEmpMain", component: Assets_QuitEmpMain },
  { path: "DashboardAssetEmp", component: DashboardAssetEmp },
  { path: "Assets_QuitFormConfirm", component: Assets_QuitFormConfirm },
  { path: "Assets_QuitFormPrint", component: Assets_QuitFormPrint },
  { path: "Assets_QuitEmpView", component: Assets_QuitEmpView },
  // End Reduce Admin

  { path: "CovertFileTax", component: CovertFileTax },
  { path: "editCasePro", component: editCasePro },

  // แบบประเมินทีม
  { path: "DashboardPerformance", component: DashboardPerformance },
  {
    path: "PerformanceEvaluationFormStaff/:DocSAF_STF/:sameApprover",
    component: PerformanceEvaluationFormStaff,
  },
  {
    path: "PerformanceEvaluationFormOfficer/:DocSAF_OFF/:sameApprover",
    component: PerformanceEvaluationFormOfficer,
  },
  {
    path: "PerformanceEvaluationFormSupervisor/:DocSAF_SUP/:sameApprover",
    component: PerformanceEvaluationFormSupervisor,
  },
  {
    path: "PerformanceEvaluationFormMD/:DocSAF_MAN/:sameApprover",
    component: PerformanceEvaluationFormMD,
  },
  {
    path: "Update_PerformanceEvaluationFormMD/:DocPEF_MAN/:Type",
    component: Update_PerformanceEvaluationFormMD,
  },
  {
    path: "Update_PerformanceEvaluationFormOfficer/:DocPEF_OFF/:Type",
    component: Update_PerformanceEvaluationFormOfficer,
  },
  {
    path: "Update_PerformanceEvaluationFormStaff/:DocPEF_STF/:Type",
    component: Update_PerformanceEvaluationFormStaff,
  },
  {
    path: "Update_PerformanceEvaluationFormSupervisor/:DocPEF_SUP/:Type",
    component: Update_PerformanceEvaluationFormSupervisor,
  },
  { path: "PrintPE_STF/:PDocPEF_STF", component: PrintPE_STF },
  { path: "PrintPE_PRO/:PDocPEF_OFF", component: PrintPE_OFF },
  { path: "PrintPE_SUP/:PDocPEF_SUP", component: PrintPE_SUP },
  { path: "PrintPE_MAN/:PDocPEF_MAN", component: PrintPE_MAN },
  // End แบบประเมินทีม

  //  จัดการผลคะแนน
  { path: "ImportGrade", component: ImportGrade },
  { path: "ImportScore_history", component: ImportScore_history },
  { path: "DashboardRecheckGrade", component: DashboardRecheckGrade },
  // End  จัดการผลคะแนน

  // ฟอร์มหารือ
  { path: "DashboardDiscussionForm", component: DashboardDiscussionForm },
  { path: "DiscussionForm/:DocSAF/:sameApprover", component: DiscussionForm },
  {
    path: "UpdateDiscussionForm/:DocDIS/:Type",
    component: UpdateDiscussionForm,
  },
  { path: "PrintDiscussionForm/:DocDIS", component: PrintDiscussionForm },
  // End ฟอร์มหารือ

  // KPIs รายบุคคล
  { path: PATHNAME.MANAGE_KPIS, component: PageManageKPIs },
  { path: PATHNAME.MANAGE_INDICATOR, component: PageManageIndicator },
  { path: PATHNAME.MANAGE_KPIS_FACTORY, component: PageManageKPIsFactory },
  { path: PATHNAME.REPORT_KPIS_FACTORY, component: PageReportKPIsFactory },
  {
    path: PATHNAME.ASSESSMENT_FACTORY_BY_DAY,
    component: AssessmentFactoryByDay,
  },
  { path: "ImportKPIsFacHistory", component: ImportKPIsFacHistory },
  { path: "ImportNumDayDailyWorker", component: ImportNumDayDailyWorker },
  // End KPIs รายบุคคล

  // รายงานการประเมิน
  { path: "SummaryReportPEF", component: SummaryReportPEF },
  { path: "DashboardGrade", component: DashboardGrade },
  { path: "DashboardPlanKnowleage", component: DashboardPlanKnowleage },
  // End รายงานการประเมิน

  // จัดการตำแหน่งงาน
  { path: PATH_ROUTE.Position_Manage, component: ManagePosition },
  {
    path: PATH_ROUTE.Position_Edit,
    component: FormEditPosition,
  },
  // End จัดการตำแหน่งงาน

  // จัดการแผนกย่อย
  { path: PATH_ROUTE.SubDepartment_Manage, component: ManageSubDepartment },
  {
    path: PATH_ROUTE.SubDepartment_Edit,
    component: FormEditSubDepartment,
  },
  // End จัดการแผนกย่อย

  // จัดการสายอนุมัติ lv 2
  { path: PATH_ROUTE.Approval_Manage, component: ManageBranchApprove },
  {
    path: PATH_ROUTE.Approval_Edit,
    component: FormEditBranchApprove,
  },
  // End จัดการสายอนุมัติ lv 2

  // จัดการสายการดูข้อมูล
  { path: PATH_ROUTE.ViewFlow_Manage, component: ManageBranchView },
  {
    path: PATH_ROUTE.ViewFlow_Edit,
    component: FromEditBranchView,
  },
  // End จัดการสายการดูข้อมูล

  // Manage OJT
  { path: PATH_ROUTE.OJT_Manage, component: ManageOJT },
  {
    path: PATH_ROUTE.OJT_Edit,
    component: FormEditOJTCourse,
  },
  // End Manage OJT

  // จัดการหลักสูตรหลัก (Traning)
  {
    path: PATH_ROUTE.Training_Manage,
    component: ManageTraining,
  },
  {
    path: PATH_ROUTE.Training_Edit,
    component: FormEditTraining,
  },
  // End จัดการหลักสูตรหลัก (Traning)

  // Manage KPIs ผูกตำแหน่ง
  { path: PATH_ROUTE.KPIs_Manage, component: ManageKPIs },
  {
    path: PATH_ROUTE.KPIs_Edit,
    component: FormEditKPIs,
  },
  // End Manage KPIs ผูกตำแหน่ง

  // จัดการสิทธิ์
  { path: PATH_ROUTE.CustomRole_Manage, component: ManageCustomizeRole },
  // End จัดการสิทธิ์

  // จัดการประกันสังคม (SSO)
  { path: PATH_ROUTE.SSO_Manage, component: ManageSSO },
  { path: PATH_ROUTE.SSO_Edit, component: FormEditSSO },
  { path: PATH_ROUTE.SSO_View, component: FormShowSSOInfo },
  // End จัดการประกันสังคม (SSO)

  // แก้ไข/อัพเดต JP PG
  { path: PATH_ROUTE.JGPG_Import, component: ManageJG_PG },
  { path: PATH_ROUTE.JGPG_Import_FAC, component: FormImportTempleteFAC },
  // End แก้ไข/อัพเดต JP PG

  // อาชญากรรม
  { path: PATH_ROUTE.Crime_Manage, component: ManageCrime },
  { path: PATH_ROUTE.Crime_Edit, component: FormEditCrime },
  { path: PATH_ROUTE.Crime_View, component: FormShowDetailCrime },
  // End อาชญากรรม

  // หัวข้อ Master Probation
  { path: PATH_ROUTE.Probation_Manage, component: ManageMasterProbation },
  {
    path: PATH_ROUTE.Probation_Edit,
    component: FormEditProbation,
  },
  // End หัวข้อ Master Probation

  // รายงานจำนวนพนักงาน
  { path: PATH_ROUTE.Report_All, component: FormReportAll },
  { path: PATH_ROUTE.Report_Summary, component: FormReportEmployeeSummary },
  { path: PATH_ROUTE.Report_LvWorker, component: FormReportLevelWorker },
  { path: PATH_ROUTE.Report_Personal, component: FormReportPersonal },
  { path: PATH_ROUTE.Report_EmpAge, component: FormReportEmployeeAge },
  { path: PATH_ROUTE.Report_WorkAge, component: FormReportWorkAge },
  { path: PATH_ROUTE.Report_StatusWork, component: FormReportStatusWorker },
  {
    path: PATH_ROUTE.Report_EmpTransfer,
    component: FormReportEmployeeTransfer,
  },
  { path: PATH_ROUTE.Report_Crime, component: FormReportCrimes },
  // End รายงานจำนวนพนักงาน

  // ยื่นสวัสดิการ
  { path: PATH_ROUTE.Benefit_New, component: FormSearchBenefitNewEmp },
  { path: PATH_ROUTE.Benefit_Temp, component: FormSearchBenefitNewEmpTemp },
  { path: PATH_ROUTE.Benefit_Out, component: FormSearchBenefitOutEmp },
  // End ยื่นสวัสดิการ

  // โยกย้าย/เปลี่ยนแปลง
  { path: PATH_ROUTE.Transfer_Company, component: FormTransferCompany },

  {
    path: PATH_ROUTE.Transfer_Department,
    component: FormTransferDepartments,
  },
  {
    path: PATH_ROUTE.Transfer_SubDepartment,
    component: FormTransferSubDepartment,
  },
  {
    path: PATH_ROUTE.Transfer_HrmFlow,
    component: FormTransferHrmFlow,
  },
  {
    path: PATH_ROUTE.Transfer_ViewFlow,
    component: FormTransferViewFlow,
  },
  {
    path: PATH_ROUTE.Transfer_Position,
    component: FormTransferPosition,
  },
  // End โยกย้าย/เปลี่ยนแปลง

  {
    path: PATH_ROUTE.RecheckWorkingSaleEmployee,
    component: RecheckWorkingSaleEmployee,
  },
  {
    path: PATH_ROUTE.Insert_NewSaleEmployee,
    component: InsertNewSaleEmployee,
  },
  {
    path: PATH_ROUTE.Import_IncentiveStrategy,
    component: ImportIncentiveStrategy,
  },
  {
    path: PATH_ROUTE.ImportMapFocusShopSaleCode,
    component: ImportMapFocusShopSaleCode,
  },
  {
    path: PATH_ROUTE.SaleCodeAreaAssignment,
    component: SaleCodeAreaAssignment,
  },
  {
    path: PATH_ROUTE.RecheckCommissionIncentiveDashboard,
    component: RecheckCommissionIncentiveDashboard,
  },
  {
    path: PATH_ROUTE.RecheckCommissionIncentiveAction,
    component: RecheckCommissionIncentiveAction,
  },
  {
    path: PATH_ROUTE.FilterOutSalesEmpCommission,
    component: FilterOutSalesEmpCommission,
  },
  {
    path: PATH_ROUTE.CutOffCommissionIncentive,
    component: CutOffCommissionIncentive,
  },
  {
    path: PATH_ROUTE.SaleCodeAreaAssignmentFSP,
    component: SaleCodeAreaAssignmentFSP,
  },
  {
    path: PATH_ROUTE.InsertSaleTargetFSP,
    component: InsertSaleTargetFSP,
  },
  {
    path: PATH_ROUTE.Insert_NewSaleEmployeeFSP,
    component: InsertNewSaleEmployeeFSP,
  },
  {
    path: PATH_ROUTE.RecheckCommissionIncentiveDashboardFSP,
    component: RecheckCommissionIncentiveDashboardFSP,
  },
  {
    path: PATH_ROUTE.RecheckCommissionIncentiveActionFSP,
    component: RecheckCommissionIncentiveActionFSP,
  },

  // เปลี่ยนรหัสผ่าน
  { path: PATH_ROUTE.ChangePassword, component: ChangePassword },
  // End เปลี่ยนรหัสผ่าน

  { path: "/TestChange", component: FormTrackProbation },

  { path: PATH_ROUTE.TEST, component: TestPage },
  { path: "*", exact: true, component: notFound },
];

export const SUB_MENU = {
  "/DashboardProbation": [
    { path: "DashboardProbation", component: DashboardProbation }, // Root
    { path: "InsertProbation", component: InsertProbation },
    { path: "UpdateProbation", component: InsertProbation },
    { path: "ViewProbation", component: ViewProbation },
  ],
  "/ReportNewEmployee": [
    //{ path: "ReportNewEmployee", component: ReportNewEmployee }, // Root
    { path: "ReportNewEmployee", component: FormSearchProbationEmpNew }, // Root
    { path: "ViewProbation", component: ViewProbation },
  ],
  "/ReportTrackProbation": [
    //{ path: "ReportTrackProbation", component: ReportTrackProbation }, // Root
    { path: "ReportTrackProbation", component: FormTrackProbation }, // Root
    { path: "ViewProbation", component: ViewProbation },
  ],
  "/dashboardProbationContract": [
    {
      path: PATHNAME.DASHBOARD_PROBATION_CONTRACT,
      component: PageDashboardContract,
    },
    // Root
    { path: "InsertProbation", component: InsertProbation },
    { path: "UpdateProbation", component: InsertProbation },
    { path: "ViewProbation", component: ViewProbation },
    { path: PATHNAME.UPDATE_PROBATION_CONTRACT, component: PageUpdateContract },
    { path: PATHNAME.INSERT_PROBATION_CONTRACT, component: PageInsertContract },
  ],
  "/dashboardProbationEmpContract": [
    {
      path: PATHNAME.DASHBOARD_PROBATION_EMP_CONTRACT,
      //component: PageDashboardEmpContract,
      component: FormSearchProbationEmpContract,
    },
    {
      path: PATHNAME.DASHBOARD_PROBATION_EMP_CONTRACT_NEW,
      component: FormSearchProbationEmpContractNew,
    },

    // Root
    { path: "ViewProbation", component: ViewProbation },
    { path: PATHNAME.VIEW_PROBATION_CONTRACT, component: PageViewContract },
  ],

  ///New Version ///
  "/dashboardProbationEmpContractNew": [
    {
      path: PATHNAME.DASHBOARD_PROBATION_EMP_CONTRACT_NEW,
      //component: PageDashboardEmpContract,
      component: FormSearchProbationEmpContractNew,
    }, // Root
    { path: "ViewProbation", component: ViewProbation },
    { path: PATHNAME.VIEW_PROBATION_CONTRACT, component: PageViewContract },
  ],
  ///New Version ///

  "/dashboardTrackContract": [
    //{ path: PATHNAME.DASHBOARD_TRACK_CONTRACT, component: PageDashboardTrackContract }, // Root
    { path: PATHNAME.DASHBOARD_TRACK_CONTRACT, component: FormTrackContract }, // Root
    { path: "ViewProbation", component: ViewProbation },
  ],
  "/ManageMasterProbation": [
    { path: PATH_ROUTE.Probation_Manage, component: ManageMasterProbation }, // Root
    {
      path: PATH_ROUTE.Probation_Edit,
      component: FormEditProbation,
    },
  ],
  "/DashboardAssetEmp": [
    { path: "DashboardAssetEmp", component: DashboardAssetEmp }, // Root
    { path: "Assets_NewEmpForm", component: Assets_NewEmpForm },
    { path: "Assets_QuitEmpForm", component: Assets_QuitEmpForm },
  ],
  "/Assets_NewEmpMain": [
    { path: "Assets_NewEmpMain", component: Assets_NewEmpMain }, // Root
    { path: "Assets_NewEmpView", component: Assets_NewEmpView },
    { path: "Assets_NewEmpPrint", component: Assets_NewEmpPrint },
    { path: "Assets_QuitEmpView", component: Assets_QuitEmpView },
    { path: "Assets_QuitFormConfirm", component: Assets_QuitFormConfirm },
  ],
  "/insertPersonal": [
    {
      path: PATHNAME.INSERT_PERSONAL,
      component: ModulePersonal.InsertPersonal,
    },
  ],
  "/dashboardImportPersonal": [
    {
      path: PATHNAME.DASHBOARD_IMPORT_PERSONAL,
      component: ModulePersonal.DashboardImportPersonal,
    }, // Root
    {
      path: `${PATHNAME.IMPORT_PERSONAL}/:candidateNo`,
      component: ModulePersonal.ImportPersonal,
    },
  ],
  "/dashboardImportPersonalFac": [
    {
      path: PATHNAME.DASHBOARD_IMPORT_PERSONAL_FAC,
      component: DashboardImportPersonalFac,
    }, // Root
  ],
  "/dashboardImportPersonalIdCard": [
    {
      path: PATHNAME.DASHBOARD_IMPORT_PERSONAL_ID_CARD,
      component: ModulePersonal.DashboardImportPersonalIdCard,
    }, // Root
    // {
    // 	path: `${PATHNAME.IMPORT_PERSONAL}/:candidateNo`,
    // 	component: ModulePersonal.ImportPersonal,
    // },
  ],
  "/ImportImageStaff": [
    { path: "ImportImageStaff", component: ImportImageStaff },
  ],
  "/dashboardPersonal": [
    {
      path: PATHNAME.DASHBOARD_PERSONAL,
      component: ModulePersonal.DashboardPersonal,
    }, // Root
    {
      path: PATHNAME.DASHBOARD_PERSONAL_VIEW,
      component: ModulePersonal.DashboardPersonalView,
    },
    {
      path: `${PATHNAME.BLOCK_PERSONAL}/:userId`,
      component: ModulePersonal.BlockPersonal,
    },
    {
      path: `${PATHNAME.UPDATE_PERSONAL}/:userId`,
      component: ModulePersonal.UpdatePersonal,
    },
    {
      path: `${PATHNAME.VIEW_PERSONAL}/:userId`,
      component: ModulePersonal.ViewPersonal,
    },
  ],
  "/recheckPersonal": [
    {
      path: `${PATHNAME.RECHECK_PERSONAL}`, // /:userId
      component: ModulePersonal.RecheckPersonal,
    },
  ],
  "/dashboardPersonalView": [
    {
      path: PATHNAME.DASHBOARD_PERSONAL_VIEW,
      component: ModulePersonal.DashboardPersonalView,
    },
  ],
  "/TransferCompany": [
    { path: PATH_ROUTE.Transfer_Company, component: FormTransferCompany },
  ],
  "/TransferDepartment": [
    {
      path: PATH_ROUTE.Transfer_Department,
      component: FormTransferDepartments,
    },
  ],
  "/TransferSubDepartment": [
    {
      path: PATH_ROUTE.Transfer_SubDepartment,
      component: FormTransferSubDepartment,
    },
  ],
  "/TransferPosition": [
    {
      path: PATH_ROUTE.Transfer_Position,
      component: FormTransferPosition,
    },
  ],
  "/ChangeShiftWork": [
    {
      path: PATH_ROUTE.ChangeShiftWork,
      component: FormChangeShiftWork,
    },
  ],
  "/TransferHrmFlow": [
    {
      path: PATH_ROUTE.Transfer_HrmFlow,
      component: FormTransferHrmFlow,
    },
  ],
  "/TransferViewFlow": [
    { path: PATH_ROUTE.Transfer_ViewFlow, component: FormTransferViewFlow },
  ],
  "/ImportJGPG": [{ path: PATH_ROUTE.JGPG_Import, component: ManageJG_PG }],
  "/ImportJGPG-FAC": [
    { path: PATH_ROUTE.JGPG_Import_FAC, component: FormImportTempleteFAC },
  ],
  "/ReportLevelWorker": [
    { path: PATH_ROUTE.Report_LvWorker, component: FormReportLevelWorker },
  ],
  "/ReportPersonal": [
    { path: PATH_ROUTE.Report_Personal, component: FormReportPersonal },
  ],
  "/ReportStatusWorker": [
    { path: PATH_ROUTE.Report_StatusWork, component: FormReportStatusWorker },
  ],
  "/ReportEmployeeAge": [
    { path: PATH_ROUTE.Report_EmpAge, component: FormReportEmployeeAge },
  ],
  "/ReportEmployeeAgeOfWork": [
    { path: PATH_ROUTE.Report_WorkAge, component: FormReportWorkAge },
  ],
  "/ReportAllProfile": [
    { path: PATH_ROUTE.Report_All, component: FormReportAll },
  ],
  "/ReportEmployeeTransferHistory": [
    {
      path: PATH_ROUTE.Report_EmpTransfer,
      component: FormReportEmployeeTransfer,
    },
  ],
  "/ReportEmployeeSummary": [
    { path: PATH_ROUTE.Report_Summary, component: FormReportEmployeeSummary },
  ],
  "/InsertOTFactory": [
    { path: PATHNAME.INSERT_REQUEST_OT, component: PageInsertOT },
  ],
  "/DashboardOTFactory": [
    { path: PATHNAME.DASHBOARD_OT_FACTORY, component: PageDashboardOT }, // Root
    { path: PATHNAME.UPDATE_REQUEST_OT, component: PageUpdateOT },
    { path: PATHNAME.REPORT_OT_FACTORY, component: PageReportOT },
  ],
  "/BenefitNewEmp": [
    { path: PATH_ROUTE.Benefit_New, component: FormSearchBenefitNewEmp },
  ],
  "/BenefitNewEmpTemp": [
    { path: PATH_ROUTE.Benefit_Temp, component: FormSearchBenefitNewEmpTemp },
  ],
  "/BenefitOutEmp": [
    { path: PATH_ROUTE.Benefit_Out, component: FormSearchBenefitOutEmp },
  ],
  "/UpdateSSO": [
    { path: PATH_ROUTE.SSO_Edit, component: FormEditSSO },
    { path: PATH_ROUTE.SSO_View, component: FormShowSSOInfo },
  ],
  "/ManageSSO": [{ path: PATH_ROUTE.SSO_Manage, component: ManageSSO }],
  "/CovertFileTax": [{ path: "CovertFileTax", component: CovertFileTax }],
  "/ManageCrime": [
    { path: PATH_ROUTE.Crime_Manage, component: ManageCrime }, // Root
    { path: PATH_ROUTE.Crime_Edit, component: FormEditCrime },
    { path: PATH_ROUTE.Crime_View, component: FormShowDetailCrime },
  ],
  "/ReportResultCrime": [
    { path: PATH_ROUTE.Report_Crime, component: FormReportCrimes },
  ],
  "/DashboardSelfAssessment": [
    { path: "DashboardSelfAssessment", component: DashboardSelfAssessment }, // Root
    { path: "SelfAssessmentForm", component: SelfAssessmentForm },
    {
      path: "UpdateSelfAssessmentForm/:docNo",
      component: UpdateSelfAssessmentForm,
    },
    { path: "PrintSelfAssessment/:DocNos", component: PrintSelfAssessment },
  ],
  "/DashboardPerformance": [
    { path: "DashboardPerformance", component: DashboardPerformance }, // Root
    { path: "PrintPE_MAN/:PDocPEF_MAN", component: PrintPE_MAN },
    { path: "PrintPE_PRO/:PDocPEF_OFF", component: PrintPE_OFF },
    { path: "PrintPE_STF/:PDocPEF_STF", component: PrintPE_STF },
    { path: "PrintPE_SUP/:PDocPEF_SUP", component: PrintPE_SUP },
    {
      path: "PerformanceEvaluationFormMD/:DocSAF_MAN/:sameApprover",
      component: PerformanceEvaluationFormMD,
    },
    {
      path: "PerformanceEvaluationFormOfficer/:DocSAF_OFF/:sameApprover",
      component: PerformanceEvaluationFormOfficer,
    },
    {
      path: "PerformanceEvaluationFormStaff/:DocSAF_STF/:sameApprover",
      component: PerformanceEvaluationFormStaff,
    },
    {
      path: "PerformanceEvaluationFormSupervisor/:DocSAF_SUP/:sameApprover",
      component: PerformanceEvaluationFormSupervisor,
    },
    {
      path: "Update_PerformanceEvaluationFormMD/:DocPEF_MAN/:Type",
      component: Update_PerformanceEvaluationFormMD,
    },
    {
      path: "Update_PerformanceEvaluationFormOfficer/:DocPEF_OFF/:Type",
      component: Update_PerformanceEvaluationFormOfficer,
    },
    {
      path: "Update_PerformanceEvaluationFormStaff/:DocPEF_STF/:Type",
      component: Update_PerformanceEvaluationFormStaff,
    },
    {
      path: "Update_PerformanceEvaluationFormSupervisor/:DocPEF_SUP/:Type",
      component: Update_PerformanceEvaluationFormSupervisor,
    },
    { path: "PrintSelfAssessment/:DocNos", component: PrintSelfAssessment },
  ],
  "/DashboardDiscussionForm": [
    { path: "DashboardDiscussionForm", component: DashboardDiscussionForm }, // Root
    {
      path: "UpdateDiscussionForm/:DocDIS/:Type",
      component: UpdateDiscussionForm,
    },
    { path: "PrintDiscussionForm/:DocDIS", component: PrintDiscussionForm },
    { path: "DiscussionForm/:DocSAF/:sameApprover", component: DiscussionForm },
    { path: "PrintSelfAssessment/:DocNos", component: PrintSelfAssessment },
    { path: "PrintPE_MAN/:PDocPEF_MAN", component: PrintPE_MAN },
    { path: "PrintPE_PRO/:PDocPEF_OFF", component: PrintPE_OFF },
    { path: "PrintPE_STF/:PDocPEF_STF", component: PrintPE_STF },
    { path: "PrintPE_SUP/:PDocPEF_SUP", component: PrintPE_SUP },
  ],
  "/DashboardCF_DIS": [
    { path: "DashboardCF_DIS", component: DashboardCF_DIS }, // Root
    { path: "PrintDiscussionForm/:DocDIS", component: PrintDiscussionForm },
    { path: "CF_DiscussionForm/:DocCF", component: CF_DiscussionForm },
  ],
  "/ImportGrade": [{ path: "ImportGrade", component: ImportGrade }],
  "/DashboardRecheckGrade": [
    { path: "DashboardRecheckGrade", component: DashboardRecheckGrade },
  ],
  "/ImportScore_history": [
    { path: "ImportScore_history", component: ImportScore_history },
  ],
  "/SummaryReportPEF": [
    { path: "SummaryReportPEF", component: SummaryReportPEF },
  ],
  "/DashboardGrade": [{ path: "DashboardGrade", component: DashboardGrade }],
  "/DashboardPlanKnowleage": [
    { path: "DashboardPlanKnowleage", component: DashboardPlanKnowleage },
  ],
  "/DashboardKPIsFactory": [
    { path: PATHNAME.MANAGE_KPIS_FACTORY, component: PageManageKPIsFactory },
  ],
  "/ReportKPIsFactory": [
    { path: PATHNAME.REPORT_KPIS_FACTORY, component: PageReportKPIsFactory },
  ],
  "/ImportNumDayDailyWorker": [
    { path: "ImportNumDayDailyWorker", component: ImportNumDayDailyWorker },
  ],
  "/ManageKPIs": [
    { path: PATH_ROUTE.KPIs_Manage, component: ManageKPIs }, // Root
    { path: PATH_ROUTE.KPIs_Edit, component: FormEditKPIs },
  ],
  "/dashboardReviewer": [
    { path: PATHNAME.DASHBOARD_REVIEWER, component: PageDashboardReviewer }, // Root
    { path: PATHNAME.INSERT_REVIEWER, component: PageInsertReviewer },
    { path: PATHNAME.UPDATE_REVIEWER, component: PageUpdateReviewer },
    { path: PATHNAME.VIEW_REVIWER, component: PageViewReviewer },
  ],
  "/dashboardEvac": [
    { path: PATHNAME.DASHBOARD_EVAC, component: PageDashboardEvac }, // Root
    { path: PATHNAME.INSERT_EVAC, component: PageInsertEvac },
  ],
  "/dashboardEvacByDep": [
    { path: PATHNAME.DASHBOARD_EVAC_BY_DEP, component: PageDashboadEvacByDep },
  ],
  "/dashboardEvacByAdmin": [
    {
      path: PATHNAME.DASHBOARD_EVAC_BY_ADMIN,
      component: PageDashboadEvacByAdmin,
    },
  ],
  "/dashboardEndAndSaveEvac": [
    {
      path: PATHNAME.DASHBOARD_EVAC_END_AND_SAVE,
      component: PageDashboardEndSaveEvac,
    },
  ],
  "/dashboardTraining": [
    { path: PATHNAME.DASHBOARD_TRAINING, component: PageDashboardTraining },
    { path: PATHNAME.UPDATE_TRAINING, component: PageUpdateTraining },
    { path: PATHNAME.INSERT_TRAINING, component: PageInsertTraining },
    { path: PATHNAME.VIEW_TRAINING, component: PageViewTraining },
    { path: PATHNAME.TRAINING_FORM, component: PagePrintTraining },
  ],
  "/importTraining": [
    {
      path: PATHNAME.IMPORT_TRAINING,
      component: PageImportTraining,
    },
  ],
  "/ManageOJT": [
    { path: PATH_ROUTE.OJT_Manage, component: ManageOJT }, // Root
    { path: PATH_ROUTE.OJT_Edit, component: FormEditOJTCourse },
  ],
  "/ManageTraining": [
    { path: PATH_ROUTE.Training_Manage, component: ManageTraining }, // Root
    { path: PATH_ROUTE.Training_Edit, component: FormEditTraining },
  ],
  "/dashboardHistoryIndividual": [
    {
      path: PATHNAME.DASHBOARD_HISTORY_INDIVIDUAL,
      component: PageDashboardHistoryIndividual,
    }, // Root
    { path: PATHNAME.HISTORY_INDIVIDUAL_FORM, component: PagePrintIndividual },
  ],
  "/dashboardHistoryTrainers": [
    {
      path: PATHNAME.DASHBOARD_HISTORY_TRAINERS,
      component: PageDashboardHistoryTrainers,
    },
  ],
  "/ManageCustomRole": [
    { path: PATH_ROUTE.CustomRole_Manage, component: ManageCustomizeRole },
  ],
  "/ManageBranchApprove": [
    { path: PATH_ROUTE.Approval_Manage, component: ManageBranchApprove }, // Root
    { path: PATH_ROUTE.Approval_Edit, component: FormEditBranchApprove },
  ],
  "/ManageBranchView": [
    { path: PATH_ROUTE.ViewFlow_Manage, component: ManageBranchView }, // Root
    { path: PATH_ROUTE.ViewFlow_Edit, component: FromEditBranchView },
  ],
  "/ManagePosition": [
    { path: PATH_ROUTE.Position_Manage, component: ManagePosition }, // Root
    { path: PATH_ROUTE.Position_Edit, component: FormEditPosition },
  ],
  "/ManageSubDepartment": [
    { path: PATH_ROUTE.SubDepartment_Manage, component: ManageSubDepartment }, // Root
    { path: PATH_ROUTE.SubDepartment_Edit, component: FormEditSubDepartment },
  ],
  "/ChangeStatusWorker": [
    {
      path: PATH_ROUTE.Change_StatusWorker,
      component: FormSearchChangeStatusWorker,
    },
  ],
  "/ReportReturn": [
    { path: PATH_ROUTE.Report_Return, component: FormReportReturn },
  ],
  "/ManageDocProbation": [
    { path: PATH_ROUTE.Manage_DocProbation, component: FormManageDocProbation },
    { path: PATHNAME.VIEW_PROBATION_CONTRACT, component: PageViewContract },
  ],
  "/ManageDocProbationContract": [
    {
      path: PATH_ROUTE.Manage_DocProbationContract,
      component: FormManageDocProbationContract,
    },
  ],
  "/ManageBenefitContract": [
    {
      path: PATH_ROUTE.Manage_BenefitContract,
      component: FormManageBenefitContract,
    },
  ],
  "/ReportOT": [{ path: PATH_ROUTE.Report_OT, component: FormReportOT }],
  "/ReportResignRate": [
    { path: PATH_ROUTE.Report_Resign, component: FormReportResignRate },
  ],
  "/ReportWarning": [
    { path: PATH_ROUTE.Report_Warning, component: FormReportWarning },
  ],
  "/ReportBonus": [
    { path: PATH_ROUTE.Report_Bonus, component: FormReportBonus },
  ],
  "/DashboardIndicator": [
    { path: PATHNAME.MANAGE_INDICATOR, component: PageManageIndicator },
  ],
  "/DashboardKPIs": [{ path: PATHNAME.MANAGE_KPIS, component: PageManageKPIs }],
  "/ReportCheckEmployees": [
    {
      path: PATH_ROUTE.Report_CheckEmployees,
      component: FormReportCheckEmployees,
    },
  ],
  "/ReportCharge": [
    { path: PATH_ROUTE.Report_Charge, component: FormReportCharge },
  ],
  "/ReportExamineFullTemp": [
    {
      path: PATH_ROUTE.Report_ExamineFullTemp,
      component: FormReportExamineFullTemp,
    },
  ],
  "/ReportRecruiterGrade": [
    {
      path: PATH_ROUTE.Report_RecruiterGrade,
      component: FormReportRecruiterGrade,
    },
  ],
  "/ReportRecruiterScore": [
    {
      path: PATH_ROUTE.Report_RecruiterScore,
      component: FormReportRecruiterScore,
    },
  ],
  "/ManageOT": [{ path: PATH_ROUTE.Manage_OT, component: FormManageOT }],
  "/ChangeInsurancePlan": [
    {
      path: PATH_ROUTE.Manage_ChangeInsurancePlan,
      component: FormChangeInsurancePlan,
    },
  ],
  "/ManageBenefitTransfer": [
    {
      path: PATH_ROUTE.Manage_BenefitTransfer,
      component: FormManageBenefitTransfer,
    },
  ],
  "/BenefitAndHoliday": [
    { path: PATH_ROUTE.BenefitAndHoliday, component: BenefitAndHoliday },
  ],
  [PATH_ROUTE.ChangePassword]: [
    {
      path: PATH_ROUTE.ChangePassword,
      component: ChangePassword,
    },
  ],
  [PATH_ROUTE.RecheckWorkingSaleEmployee]: [
    {
      path: PATH_ROUTE.RecheckWorkingSaleEmployee,
      component: RecheckWorkingSaleEmployee,
    },
  ],
  [PATH_ROUTE.Insert_NewSaleEmployee]: [
    {
      path: PATH_ROUTE.Insert_NewSaleEmployee,
      component: InsertNewSaleEmployee,
    },
  ],
  [PATH_ROUTE.Insert_NewSaleEmployeeFSP]: [
    {
      path: PATH_ROUTE.Insert_NewSaleEmployeeFSP,
      component: InsertNewSaleEmployeeFSP,
    },
  ],
  [PATH_ROUTE.InsertSaleTargetFSP]: [
    {
      path: PATH_ROUTE.InsertSaleTargetFSP,
      component: InsertSaleTargetFSP,
    },
  ],
  [PATH_ROUTE.Import_IncentiveStrategy]: [
    {
      path: PATH_ROUTE.Import_IncentiveStrategy,
      component: ImportIncentiveStrategy,
    },
  ],
  [PATH_ROUTE.ImportMapFocusShopSaleCode]: [
    {
      path: PATH_ROUTE.ImportMapFocusShopSaleCode,
      component: ImportMapFocusShopSaleCode,
    },
  ],
  [PATH_ROUTE.SaleCodeAreaAssignment]: [
    {
      path: PATH_ROUTE.SaleCodeAreaAssignment,
      component: SaleCodeAreaAssignment,
    },
  ],
  [PATH_ROUTE.FilterOutSalesEmpCommission]: [
    {
      path: PATH_ROUTE.FilterOutSalesEmpCommission,
      component: FilterOutSalesEmpCommission,
    },
  ],
  [PATH_ROUTE.SaleCodeAreaAssignmentFSP]: [
    {
      path: PATH_ROUTE.SaleCodeAreaAssignmentFSP,
      component: SaleCodeAreaAssignmentFSP,
    },
  ],
  [PATH_ROUTE.RecheckCommissionIncentiveDashboard]: [
    {
      path: PATH_ROUTE.RecheckCommissionIncentiveDashboard,
      component: RecheckCommissionIncentiveDashboard,
    },
    {
      path: PATH_ROUTE.RecheckCommissionIncentiveAction,
      component: RecheckCommissionIncentiveAction,
    },
  ],
  [PATH_ROUTE.RecheckCommissionIncentiveDashboardFSP]: [
    {
      path: PATH_ROUTE.RecheckCommissionIncentiveDashboardFSP,
      component: RecheckCommissionIncentiveDashboardFSP,
    },
    {
      path: PATH_ROUTE.RecheckCommissionIncentiveActionFSP,
      component: RecheckCommissionIncentiveActionFSP,
    },
  ],
  [PATH_ROUTE.CutOffCommissionIncentiveDashboard]: [
    {
      path: PATH_ROUTE.CutOffCommissionIncentiveDashboard,
      component: CutOffCommissionIncentiveDashboard,
    },
    {
      path: PATH_ROUTE.CutOffCommissionIncentive,
      component: CutOffCommissionIncentive,
    },
  ],
  [PATH_ROUTE.CutOffCommissionIncentiveDashboardFSP]: [
    {
      path: PATH_ROUTE.CutOffCommissionIncentiveDashboardFSP,
      component: CutOffCommissionIncentiveDashboardFSP,
    },
    {
      path: PATH_ROUTE.CutOffCommissionIncentiveFSP,
      component: CutOffCommissionIncentiveFSP,
    },
  ],
  [PATH_ROUTE.RecheckCommissionIncentiveDashboardView]: [
    {
      path: PATH_ROUTE.RecheckCommissionIncentiveDashboardView,
      component: RecheckCommissionIncentiveDashboardView,
    },
    {
      path: PATH_ROUTE.RecheckCommissionIncentiveView,
      component: RecheckCommissionIncentiveView,
    },
  ],
  [PATH_ROUTE.FormContractDashboardScene]: [
    {
      path: PATH_ROUTE.FormContractDashboardScene,
      component: FormContractDashboardScene,
    },
    {
      path: PATH_ROUTE.FormContractDetailScene,
      component: FormContractDetailScene,
    },
    {
      path: PATH_ROUTE.FormHireDetailScene,
      component: FormHireDetailScene,
    },
  ],
  BaseRoute: [
    { path: PATH_ROUTE.TEST, component: TestPage },
    { path: "/TestPrint", component: FormPrintCertificateSalaryVISA },
    //TODO//
    { path: "/ReportPersonal", component: FormReportPersonal },
    //TODO//
    { path: "/ReportRecruiterGrade", component: FormReportRecruiterGrade },
    { path: "/ReportRecruiterScore", component: FormReportRecruiterScore },
    {
      path: "/CertificateRequestDashboard",
      component: CertificateRequestDashboardScene,
    }, //TODO
    {
      path: "/CertificateRequestDetail",
      component: CertificateRequestDetailScene,
    }, //TODO

    //*=================Form Contract===================
    {
      path: "/FormContractDashboard",
      component: FormContractDashboardScene,
    },
    {
      path: "/FormContractDetail",
      component: FormContractDetailScene,
    },
    {
      path: "/FormHireDetail",
      component: FormHireDetailScene,
    },
    //*=================MonitorEmployeeFile===================
    {
      path: "/dashboardMonitorFile",
      component: DashboardMonitorFile,
    },
    {
      path: "/dashboardMonitorFileView",
      component: DashboardMonitorFileView,
    },
    //*==================================================
    { path: "*", exact: true, component: notFound },
  ],
};

const routes = (permission) => [
  {
    path: "/",
    component: App,
    indexRoute: { component: home },
    childRoutes:ALL_ROUTE_PATH
      // permission.length !== 0
      //   ? permission
      //   : // ? ALL_ROUTE_PATH
      //     [
      //       {
      //         path: "/Login",
      //         component: login,
      //       },
      //     ],
  },
];

export default routes;
