import React, { useContext, useEffect, useRef, useState } from 'react'
import { Divider, Spin } from 'antd';
import { LayoutBreadcrumb, LayoutTabs, LayoutWrapper, WrapperSearch } from '../../moduleKPIs/common/layouts';
import { SEARCH_CONTRACT } from '../commonsContract/structuresContract/searchContract';
import { BREADCRUMB_CONTRACT } from '../commonsContract/structuresContract/breadcrumContract';
import { TABS_CONTRACT } from '../commonsContract/structuresContract/tabsContract';
import { ButtonExport } from '../../constants/buttons';
import { ContractContext } from '../servicesContract/contractProvider';
import { MasterCenterContext } from '../../service/master/masterProvider';
import { KEY_OPTION } from '../../constants/enums/enumCenter';

const PageDashboardGuarantee = () => {
    const refExport = useRef();
    const {  userLogin, userPermission } = useContext(ContractContext);
    const { masterCenterState, onChangeMaster, getMaster } = useContext(MasterCenterContext);

    const [loading, setLoading] = useState(false);

    const _pathname = window.location.pathname;

    let SEARCH_LIST = SEARCH_CONTRACT({ pathname: _pathname, permission: userPermission, options: masterCenterState })
    let TABS_LIST = TABS_CONTRACT({ pathname: _pathname, permission: userPermission })


    useEffect(() => {
        async function fetchAPI() {
            await setLoading(true)
            await Promise.all(
                [window.localStorage.clear()],
                [getMaster({ fieldname: KEY_OPTION.CENTER.OPTION_DEP, payload: { userId: userLogin, permission: userPermission } })],
                [getMaster({ fieldname: KEY_OPTION.PROBATION.OPTION_STATUS_ASSESSMENT, payload: { ...masterCenterState } })],

            )
            await setLoading(false)
        }
        fetchAPI()
    }, [])


    return (
        <Spin spinning={loading} >
            <LayoutWrapper
                breadcrumb={< LayoutBreadcrumb breadcrumbLists={BREADCRUMB_CONTRACT(_pathname)} />}
                search={
                    <WrapperSearch
                        searchLists={SEARCH_LIST}
                        onChange={onChangeMaster} />
                }
                content={
                    <div className="layout-content-card" style={{ padding: 10 }} >
                        <div style={{ display: 'flex', justifyContent: 'space-between', paddingTop: 10 }}>
                            <ButtonExport
                                ref={refExport}
                                labelButton=""
                                fieldname=""
                                columns={[{}]}
                                dataSource={[{}]}
                                style={{ paddingRight: 10 }}
                                styleButton={{}}
                            />
                        </div>
                        <Divider />
                        <LayoutTabs tabsLists={TABS_LIST} />
                    </div>
                }
            />
        </Spin>
    )
}

export default PageDashboardGuarantee