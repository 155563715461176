import { CONFIG } from '../../../components/connectAPI.js'

export * from './uploadAvatar.jsx'

export const STATUS_UPLOAD = {
    SUCCESS: 'success',
    UPLOADING: 'uploading',
    ERROR: 'error',
    DONE: 'done',
    REMOVED: 'removed'
}

export function filterURL(url) {
    if (!!url && url.indexOf(CONFIG.API_IMAGE) === -1) {
        return `${CONFIG.API_IMAGE}${url}`;
    }
    return ''
}


export function SET_DEFAULT_FILELIST(_files) {
    // console.log('SET_DEFAULT_FILELIST', { _files })
    //TODO: ถ้า Files เป็น undifined ให้ return []
    if (!_files) {
        return []
    }
    //TODO : ถ้า _files : [{path:''}]
    let _doneList = Array.isArray(_files) && _files.map((e, i) => {
        switch (e.status) {
            case STATUS_UPLOAD.ERROR:
                // console.log('#3 เพิ่มรูปใหม่')
                return ({
                    ...e,
                    uid: `${e.originFileObj.uid}`,
                    name: `${e.originFileObj.name}`,
                    status: STATUS_UPLOAD.DONE, //ถ้า SUCCESS จะไม่แสดงรูป
                })
            case STATUS_UPLOAD.DONE:
                // console.log('#2 : เมื่อคลิกอัพโหลด',)
                return ({ ...e })
            default:
                let name = !!e.url && `${e.url}`.split('&')[1]
                // console.log('#1 : รูป Default',)
                if (!e.originFileObj) {
                    // console.log('#1.1 : รูป Default',)
                    return ({
                        uid: `old-upload-${new Date().getUTCFullYear()}${new Date().getUTCMonth()}${new Date().getUTCDay()}${new Date().getUTCMilliseconds()}${i}`,
                        name: `${name || 'images'}`,
                        status: STATUS_UPLOAD.DONE,
                        url: filterURL(e.url),
                        thumbUrl: filterURL(e.url)
                    })
                } else {
                    // console.log('#1.2 : รูป Default',)
                    return ({ ...e, status: STATUS_UPLOAD.DONE, })
                }
        }
    })
    // console.log({ _doneList })

    return _doneList

}