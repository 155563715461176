import React from "react";
import { FiCheckCircle } from "react-icons/fi";
import Moment from "react-moment";
import { RenderStatusMainCode } from "../../../common/setStatus";
import { ButtonView } from "../../../constants/buttons";
import { KEY_OPTION } from "../../../constants/enums/enumCenter";
import { PATHNAME } from "../../../constants/enums/pathname";
import { filterSearchColumn } from "../../functions/UseFunction";
import { useButton } from "../../conponents/buttons/CustomButtonAntd";
import { Space, Tag } from "antd";
import { useConfirm } from "../../conponents/modal/CustomConfirm";
import {
  EditOutlined
} from '@ant-design/icons';
import moment from "moment";

export const ColumnSearchProbationEmpNew = [
  {
    title: "สถานะหลัก",
    dataIndex: "statusMainCode",
    key: "statusMainCode",
    align: "left",
    fixed: "left",
    width: 200,
    render: (text, record) => {
      return RenderStatusMainCode(text, record.statusMainCodeDes);
    },
  },
  {
    title: "สถานะการผ่านทดลองงาน",
    dataIndex: "probationStatus_Des",
    key: "probationStatus_Des",
    align: "left",
    fixed: "left",
    width: 200,
    ...filterSearchColumn("probationStatus_Des", "สถานะการผ่านทดลองงาน"),
  },
  {
    title: "รหัสพนักงาน",
    dataIndex: "StaffCode",
    key: "StaffCode",
    align: "left",
    fixed: "left",
    width: 150,
    ...filterSearchColumn("StaffCode", "รหัสพนักงาน"),
  },
  {
    title: "ชื่อพนักงาน",
    dataIndex: "StaffName",
    key: "StaffName",
    align: "left",
    fixed: "left",
    width: 200,
    ...filterSearchColumn("StaffName", "ชื่อพนักงาน"),
  },
  {
    title: "ชื่อเล่น",
    dataIndex: "NickName",
    key: "NickName",
    align: "left",
    width: 150,
    ...filterSearchColumn("NickName", "ชื่อเล่น"),
  },
  {
    title: "แผนก",
    dataIndex: "Department_Name",
    key: "Department_Name",
    align: "left",
    width: 200,
    ...filterSearchColumn("Department_Name", "แผนก"),
  },
  {
    title: "ตำแหน่งงาน",
    dataIndex: "PositionNameEN",
    key: "PositionNameEN",
    align: "PositionNameEN",
    width: 200,
    ...filterSearchColumn("PositionNameEN", "ตำแหน่งงาน"),
  },
  {
    title: "สถานะการจ้าง",
    dataIndex: "statusWorkerDes",
    key: "statusWorkerDes",
    align: "left",
    width: 200,
    ...filterSearchColumn("statusWorkerDes", "สถานะการจ้าง"),
  },
  {
    title: "การประเมินครั้งล่าสุด",
    dataIndex: "RoundNow",
    key: "RoundNow",
    align: "right",
    width: 150,
  },
  {
    title: "วันที่เริ่มงาน",
    dataIndex: "StartDateJob",
    key: "StartDateJob",
    align: "right",
    width: 150,
    ...filterSearchColumn("StartDateJob", "วันที่เริ่มงาน"),
  },
  {
    title: "เอกสารทดลองงาน",
    dataIndex: "DocNo",
    key: "DocNo",
    align: "left",
    width: 150,
    render: (text, record) => {
      if (text) {
        return (
          <ButtonView
            onClick={() => {
              window.localStorage.setItem(
                KEY_OPTION.DATA.PROBATION_CONTRACT,
                JSON.stringify({ ...record })
              );
              window.open(PATHNAME.VIEW_PROBATION_CONTRACT, "_blank");
            }}
          />
        );
      } else {
        return "-";
      }
    },
  },
  {
    title: "วันที่เริ่มงาน",
    dataIndex: "StartDateJob",
    key: "StartDateJob",
    align: "right",
    width: 150,
    ...filterSearchColumn("StartDateJob", "วันที่เริ่มงาน"),
  },
  {
    title: "วันที่ครบกำหนดทดลองงาน",
    dataIndex: "expProDate",
    key: "expProDate",
    align: "right",
    width: 200,
    ...filterSearchColumn("expProDate", "วันที่ครบกำหนดทดลองงาน"),
  },
  {
    title: "วันที่ผ่านทดลองงาน",
    dataIndex: "probationEndDate",
    key: "probationEndDate",
    align: "right",
    width: 150,
    ...filterSearchColumn("probationEndDate", "วันที่ผ่านทดลองงาน"),
  },
  {
    title: "วันที่ HR รับเอกสาร",
    dataIndex: "HR_ReceivedDate",
    key: "HR_ReceivedDate",
    align: "right",
    width: 150,
    ...filterSearchColumn("HR_ReceivedDate", "วันที่ HR รับเอกสาร"),
  },
  {
    title: "ประกันสุขภาพ / ประกันอุบัติเหตุ",
    dataIndex: "date_HEA",
    key: "date_HEA",
    align: "right",
    width: 200,
    ...filterSearchColumn("date_HEA", "ประกันสุขภาพ / ประกันอุบัติเหตุ"),
  },
  {
    title: "กองทุนสำรองเลี้ยงชีพ",
    dataIndex: "date_PVD",
    key: "date_PVD",
    align: "right",
    width: 150,
    ...filterSearchColumn("date_PVD", "กองทุนสำรองเลี้ยงชีพ"),
  },
  {
    title: "ประกันสังคม",
    dataIndex: "date_SOS",
    key: "date_SOS",
    align: "right",
    width: 150,
    ...filterSearchColumn("date_SOS", "ประกันสังคม"),
  },
];

export const ColumnSearchProbationEmpContract = [
  {
    title: "สถานะหลัก",
    dataIndex: "statusMainCode",
    key: "statusMainCode",
    align: "left",
    fixed: "left",
    width: 200,
    render: (text, record) => {
      return RenderStatusMainCode(text, record.statusMainCodeDes);
    },
  },
  {
    title: "สถานะการผ่านทดลองงาน",
    dataIndex: "probationStatus_Des",
    key: "probationStatus_Des",
    align: "left",
    fixed: "left",
    width: 200,
    ...filterSearchColumn("probationStatus_Des", "สถานะการผ่านทดลองงาน"),
  },
  {
    title: "รหัสพนักงาน",
    dataIndex: "StaffCode",
    key: "StaffCode",
    align: "left",
    fixed: "left",
    width: 150,
    ...filterSearchColumn("StaffCode", "รหัสพนักงาน"),
  },
  {
    title: "ชื่อพนักงาน",
    dataIndex: "StaffName",
    key: "StaffName",
    align: "left",
    fixed: "left",
    width: 200,
    ...filterSearchColumn("StaffName", "ชื่อพนักงาน"),
  },
  {
    title: "ชื่อเล่น",
    dataIndex: "NickName",
    key: "NickName",
    align: "left",
    width: 150,
    ...filterSearchColumn("NickName", "ชื่อเล่น"),
  },
  {
    title: "แผนก",
    dataIndex: "Department_Name",
    key: "Department_Name",
    align: "left",
    width: 200,
    ...filterSearchColumn("Department_Name", "แผนก"),
  },
  {
    title: "การประเมินครั้งล่าสุด",
    dataIndex: "RoundNow",
    key: "RoundNow",
    align: "right",
    width: 150,
  },
  {
    title: "เอกสารทดลองงาน",
    dataIndex: "DocNo",
    key: "DocNo",
    align: "left",
    width: 150,
    render: (text, record) => {
      if (text) {
        return (
          <ButtonView
            onClick={() => {
              window.localStorage.setItem(
                KEY_OPTION.DATA.PROBATION_CONTRACT,
                JSON.stringify({ ...record })
              );
              window.open(PATHNAME.VIEW_PROBATION_CONTRACT, "_blank");
            }}
          />
        );
      } else {
        return "-";
      }
    },
  },
  {
    title: "วันที่เริ่มงาน",
    dataIndex: "StartDateJob",
    key: "StartDateJob",
    align: "right",
    width: 150,
    ...filterSearchColumn("StartDateJob", "วันที่เริ่มงาน"),
  },
  {
    title: "วันที่เริ่มรอบสัญญาจ้าง",
    dataIndex: "startProbationDate",
    key: "startProbationDate",
    align: "left",
    width: 150,
    ...filterSearchColumn("startProbationDate", "วันที่เริ่มรอบสัญญาจ้าง"),
  },
  {
    title: "ต่อสัญญาครั้งที่",
    dataIndex: "roundContract",
    key: "roundContract",
    align: "right",
    width: 120,
    ...filterSearchColumn("roundContract", "ต่อสัญญาครั้งที่"),
  },
  {
    title: "ระยะเวลาในการต่อสัญญา",
    dataIndex: "contractPeriod",
    key: "contractPeriod",
    align: "right",
    width: 100,
    ...filterSearchColumn("contractPeriod", "ระยะเวลาในการต่อสัญญา"),
  },
  {
    title: "วันที่ประเมินต่อสัญญาจ้าง",
    dataIndex: "beforeEndContractDate",
    key: "beforeEndContractDate",
    align: "right",
    width: 160,
    ...filterSearchColumn("beforeEndContractDate", "วันที่ประเมินต่อสัญญาจ้าง"),
    render: (text, record) => {
      let endContractDate = "";
      if (record.endContractDate && record.statusMainCode !== "3") {
        const monthBefore = moment(record.endContractDate).add(-1, 'M').format('YYYY-MM-DD');
        endContractDate = (
          <Moment
            format="YYYY-MM-DD"
            className={colorClassNameIsEnding(record.endContractDate)}
          >
            {monthBefore}
          </Moment>
        );
      }
      return <div>{endContractDate}</div>;
    },
  },
  {
    title: "วันที่จบรอบสัญญา",
    dataIndex: "endContractDate",
    key: "endContractDate",
    align: "right",
    width: 160,
    ...filterSearchColumn("endContractDate", "วันที่จบรอบสัญญา"),
  },
  {
    title: "วันที่ครบกำหนดทดลองงาน",
    dataIndex: "expProDate",
    key: "expProDate",
    align: "right",
    width: 180,
    ...filterSearchColumn("expProDate", "วันที่ครบกำหนดทดลองงาน"),
  },
  {
    title: "วันที่ผ่านทดลองงาน",
    dataIndex: "probationEndDate",
    key: "probationEndDate",
    align: "right",
    width: 150,
    ...filterSearchColumn("probationEndDate", "วันที่ผ่านทดลองงาน"),
  },
  {
    title: "วันที่ HR รับเอกสาร",
    dataIndex: "HR_ReceivedDate",
    key: "HR_ReceivedDate",
    align: "right",
    width: 150,
    ...filterSearchColumn("HR_ReceivedDate", "วันที่ HR รับเอกสาร"),
  },
  {
    title: "ประกันสุขภาพ / ประกันอุบัติเหตุ",
    dataIndex: "date_HEA",
    key: "date_HEA",
    align: "right",
    width: 200,
    ...filterSearchColumn("date_HEA", "ประกันสุขภาพ / ประกันอุบัติเหตุ"),
  },
  {
    title: "กองทุนสำรองเลี้ยงชีพ",
    dataIndex: "date_PVD",
    key: "date_PVD",
    align: "right",
    width: 150,
    ...filterSearchColumn("date_PVD", "กองทุนสำรองเลี้ยงชีพ"),
  },
  {
    title: "ประกันสังคม",
    dataIndex: "date_SOS",
    key: "date_SOS",
    align: "right",
    width: 150,
    ...filterSearchColumn("date_SOS", "ประกันสังคม"),
  },
];

export const ColumnSearchProbationEmpContractNew = ({ onEditstatusDoc }) => {
  return [
    // {
    //   title: "สถานะหลัก",
    //   dataIndex: "statusMainCode",
    //   key: "statusMainCode",
    //   align: "left",
    //   fixed: "left",
    //   width: 200,
    //   render: (text, record) => {
    //     return RenderStatusMainCode(text, record.statusMainCodeDes);
    //   },
    // },
    // {
    //   title: "สถานะการผ่านทดลองงาน",
    //   dataIndex: "probationStatus_Des",
    //   key: "probationStatus_Des",
    //   align: "left",
    //   fixed: "left",
    //   width: 200,
    //   ...filterSearchColumn("probationStatus_Des", "สถานะการผ่านทดลองงาน"),
    // },
    {
      title: "รหัสพนักงาน",
      dataIndex: "StaffCode",
      key: "StaffCode",
      align: "left",
      fixed: "left",
      width: 150,
      ...filterSearchColumn("StaffCode", "รหัสพนักงาน"),
    },
    {
      title: "ชื่อพนักงาน",
      dataIndex: "StaffName",
      key: "StaffName",
      align: "left",
      fixed: "left",
      width: 200,
      ...filterSearchColumn("StaffName", "ชื่อพนักงาน"),
    },
    {
      title: "ชื่อเล่น",
      dataIndex: "NickName",
      key: "NickName",
      align: "left",
      width: 150,
      ...filterSearchColumn("NickName", "ชื่อเล่น"),
    },
    {
      title: "แผนก",
      dataIndex: "Department_Name",
      key: "Department_Name",
      align: "left",
      width: 200,
      ...filterSearchColumn("Department_Name", "แผนก"),
    },
    {
      title: "วันที่ประเมินต่อสัญญาจ้าง",
      dataIndex: "beforeEndContractDate",
      key: "beforeEndContractDate",
      align: "right",
      width: 180,
      ...filterSearchColumn("beforeEndContractDate", "วันที่ประเมินต่อสัญญาจ้าง"),
      render: (text, record) => {
        let endContractDate = "";
        if (record.endContractDate && record.statusMainCode !== "3") {
          const monthBefore = moment(record.endContractDate).add(-1, 'M').format('YYYY-MM-DD');
          endContractDate = (
            <Moment
              format="YYYY-MM-DD"
              className={colorClassNameIsEnding(record.endContractDate)}
            >
              {monthBefore}
            </Moment>
          );
        }
        return <div>{endContractDate}</div>;
      },
    },
    {
      title: "เกรด",
      dataIndex: "recruiterGrade",
      key: "recruiterGrade",
      align: "right",
      width: 160,
      ...filterSearchColumn("recruiterGrade", "เกรด"),
    },
    {
      title: "วันที่เริ่มงาน",
      dataIndex: "StartDateJob",
      key: "StartDateJob",
      align: "right",
      width: 150,
      ...filterSearchColumn("StartDateJob", "วันที่เริ่มงาน"),
    },
    {
      title: "ต่อสัญญาครั้งที่",
      dataIndex: "roundContract",
      key: "roundContract",
      align: "right",
      width: 120,
      ...filterSearchColumn("roundContract", "ต่อสัญญาครั้งที่"),
    },
    {
      title: "วันที่สิ้นสุดครั้งล่าสุด",
      dataIndex: "endContractDate",
      key: "endContractDate",
      align: "right",
      width: 160,
      ...filterSearchColumn("endContractDate", "วันที่สิ้นสุดครั้งล่าสุด"),
    },
    {
      title: "การประเมินครั้งล่าสุด",
      dataIndex: "RoundNow",
      key: "RoundNow",
      align: "right",
      width: 150,
    },
    {
      title: "รอบเดือน",
      dataIndex: "endContractMonth",
      key: "endContractMonth",
      align: "right",
      width: 160,
      render: (text, record) => {
        const date = moment(record.endContractDate)
        return record.endContractDate ? date.format('M') : '-'

      },
      ...filterSearchColumn("endContractMonth", "รอบเดือน"),
    },
    {
      title: "ระยะเวลาในการต่อสัญญา",
      dataIndex: "contractPeriod",
      key: "contractPeriod",
      align: "right",
      width: 180,
      ...filterSearchColumn("contractPeriod", "ระยะเวลาในการต่อสัญญา"),
    },
    {
      title: "วันที่เริ่มรอบสัญญาจ้าง",
      dataIndex: "startProbationDate",
      key: "startProbationDate",
      align: "left",
      width: 180,
      ...filterSearchColumn("startProbationDate", "วันที่เริ่มรอบสัญญาจ้าง"),
    },
    {
      title: "วันที่สิ้นสุดสัญญาจ้าง",
      dataIndex: "expProDate",
      key: "expProDate",
      align: "right",
      width: 180,
      ...filterSearchColumn("expProDate", "วันที่สิ้นสุดสัญญาจ้าง"),
    },
    
    // {
    //   title: "เอกสารทดลองงาน",
    //   dataIndex: "DocNo",
    //   key: "DocNo",
    //   align: "left",
    //   width: 150,
    //   render: (text, record) => {
    //     if (text) {
    //       return (
    //         <ButtonView
    //           onClick={() => {
    //             window.localStorage.setItem(
    //               KEY_OPTION.DATA.PROBATION_CONTRACT,
    //               JSON.stringify({ ...record })
    //             );
    //             window.open(PATHNAME.VIEW_PROBATION_CONTRACT, "_blank");
    //           }}
    //         />
    //       );
    //     } else {
    //       return "-";
    //     }
    //   },
    // }, 
    // {
    //   title: "วันที่ผ่านทดลองงาน",
    //   dataIndex: "probationEndDate",
    //   key: "probationEndDate",
    //   align: "right",
    //   width: 150,
    //   ...filterSearchColumn("probationEndDate", "วันที่ผ่านทดลองงาน"),
    // },
    // {
    //   title: "วันที่ HR รับเอกสาร",
    //   dataIndex: "HR_ReceivedDate",
    //   key: "HR_ReceivedDate",
    //   align: "right",
    //   width: 150,
    //   ...filterSearchColumn("HR_ReceivedDate", "วันที่ HR รับเอกสาร"),
    // },
    // {
    //   title: "ประกันสุขภาพ / ประกันอุบัติเหตุ",
    //   dataIndex: "date_HEA",
    //   key: "date_HEA",
    //   align: "right",
    //   width: 200,
    //   ...filterSearchColumn("date_HEA", "ประกันสุขภาพ / ประกันอุบัติเหตุ"),
    // },
    // {
    //   title: "กองทุนสำรองเลี้ยงชีพ",
    //   dataIndex: "date_PVD",
    //   key: "date_PVD",
    //   align: "right",
    //   width: 150,
    //   ...filterSearchColumn("date_PVD", "กองทุนสำรองเลี้ยงชีพ"),
    // },
    // {
    //   title: "ประกันสังคม",
    //   dataIndex: "date_SOS",
    //   key: "date_SOS",
    //   align: "right",
    //   width: 150,
    //   ...filterSearchColumn("date_SOS", "ประกันสังคม"),
    // },
    {
      title: "สถานะเอกสาร",
      dataIndex: "docStatus",
      key: "docStatus",
      align: "right",
      width: 150,
      render: (text, record) => {
        let color = 'success'
        switch (text) {
          case 'เอกสารครบถ้วน':
            color = 'success'
            break;
          case 'ส่งเอกสารให้พนักงานเรียบร้อย':
            color = 'processing'
            break;
          case 'รับเอกสารจากพนักงานเรียบร้อย':
            color = 'default'
            break;
          case 'แก้ไขเอกสารเพิ่มเติม':
            color = 'warning'
            break;
        }
        return record.docNo?<>{text?<Tag color={color}>{text}</Tag>:'-'}< EditOutlined onClick={() => onEditstatusDoc(record)} /></> :'-'
       // return <><Tag color={color}>{text}</Tag>< EditOutlined onClick={() => onEditstatusDoc(record)} /></>
      },
      ...filterSearchColumn("docStatus", "สถานะเอกสาร"),
    },
  ]
};

export const ColumnSearchReportTrackProbation = ({
  fnReceive = () => null,
}) => [
    {
      title: "รหัสพนักงาน",
      dataIndex: "StaffCode",
      key: "StaffCode",
      align: "left",
      fixed: "left",
      width: 150,
      ...filterSearchColumn("StaffCode", "รหัสพนักงาน"),
    },
    // {
    //     title: "วันที่เริ่มงาน",
    //     dataIndex: "StartDateJob",
    //     key: "StartDateJob",
    //     align: "right",
    //     fixed: "left",
    //     width: 150,
    //     ...filterSearchColumn("StartDateJob", "วันที่เริ่มงาน"),
    // },
    {
      title: "ชื่อพนักงาน",
      dataIndex: "StaffName",
      key: "StaffName",
      align: "left",
      fixed: "left",
      width: 200,
      ...filterSearchColumn("StaffName", "ชื่อพนักงาน"),
    },
    {
      title: "ชื่อเล่น",
      dataIndex: "NickName",
      key: "NickName",
      align: "left",
      fixed: "left",
      width: 150,
      ...filterSearchColumn("NickName", "ชื่อเล่น"),
    },
    {
      title: "สถานะการจ้าง",
      dataIndex: "statusWorkerDes",
      key: "statusWorkerDes",
      align: "left",
      width: 200,
      ...filterSearchColumn("statusWorkerDes", "สถานะการจ้าง"),
    },
    {
      title: "ชื่อผู้บังคับบัญชา",
      dataIndex: "BossName",
      key: "BossName",
      align: "left",
      width: 200,
      ...filterSearchColumn("BossName", "ชื่อผู้บังคับบัญชา"),
    },
    {
      title: "แผนก",
      dataIndex: "Department_Name",
      key: "Department_Name",
      align: "left",
      width: 200,
      ...filterSearchColumn("Department_Name", "แผนก"),
    },
    {
      title: "แผนกย่อย",
      dataIndex: "subDepName",
      key: "subDepName",
      align: "left",
      width: 200,
      ...filterSearchColumn("subDepName", "แผนกย่อย"),
    },
    {
      title: "ตำแหน่งงาน",
      dataIndex: "PositionNameEN",
      key: "PositionNameEN",
      align: "left",
      width: 200,
      ...filterSearchColumn("PositionNameEN", "ตำแหน่งงาน"),
    },
    {
      title: "สถานะเอกสาร",
      dataIndex: "statusMainCode",
      key: "statusMainCode",
      align: "left",
      width: 200,
      render: (text) => {
        return RenderStatusMainCode(text);
      },
    },
    {
      title: "วันที่เริ่มงาน",
      dataIndex: "StartDateJob",
      key: "StartDateJob",
      align: "right",
      width: 150,
      ...filterSearchColumn("StartDateJob", "วันที่เริ่มงาน"),
    },
    {
      title: "วันที่เริ่มรอบสัญญาจ้าง",
      dataIndex: "startProbationDate",
      key: "startProbationDate",
      align: "left",
      width: 150,
      // ...getColumnSearchProps('StartDateJob', searchInput),
    },
    {
      title: "ต่อสัญญาครั้งที่",
      key: "roundContract",
      dataIndex: "roundContract",
      align: "right",
      width: 120,
    },
    {
      title: "วันที่คาดว่าผ่านทดลองงาน",
      dataIndex: "expProDate",
      key: "expProDate",
      align: "right",
      width: 200,
      ...filterSearchColumn("expProDate", "วันที่คาดว่าผ่านทดลองงาน"),
    },
    {
      title: "เอกสารทดลองงาน",
      key: "DocNo",
      align: "center",
      width: 200,
      render: (text, record) => {
        if (text) {
          return (
            record.statusMainCode !== "0" && (
              <ButtonView
                onClick={() => {
                  window.localStorage.setItem(
                    KEY_OPTION.DATA.PROBATION_CONTRACT,
                    JSON.stringify({ ...record })
                  );
                  window.open(PATHNAME.VIEW_PROBATION_CONTRACT, "_blank");
                }}
              />
            )
          );
        } else {
          return "-";
        }
      },
    },
    {
      title: "รอบปัจจุบัน",
      dataIndex: "RoundNow",
      key: "RoundNow",
      align: "right",
      width: 150,
    },
    {
      title: "รอบที่ยืนยันแล้ว",
      dataIndex: "status_confirm",
      key: "status_confirm",
      align: "right",
      width: 150,
    },
    {
      title: "รอบที่ 1",
      dataIndex: "RoundDate_1",
      key: "RoundDate_1",
      align: "right",
      width: 150,
      render: (text, record) => {
        let RoundDate_1 = "";
        if (record.RoundDate_1) {
          if (record.RoundNow > 1) {
            //จบการประเมินรอบที่ 1
            RoundDate_1 = record.RoundDate_1 ? (
              <Moment format="YYYY-MM-DD" className="date-green">
                {record.RoundDate_1}
              </Moment>
            ) : '-';
          } else {
            RoundDate_1 = record.RoundDate_1 ? (
              <Moment
                format="YYYY-MM-DD"
                className={colorClassName(record.RoundDate_1)}
              >
                {record.RoundDate_1}
              </Moment>
            ) : '-';
          }
        }
        return <div>{RoundDate_1 || "-"}</div>;
      },
    },
    {
      title: "รอบที่ 2",
      dataIndex: "RoundDate_2",
      key: "RoundDate_2",
      align: "right",
      width: 150,
      render: (text, record) => {
        let RoundDate_2 = "";
        if (record.RoundNow) {
          if (record.RoundNow > 2 || record.statusProbationLast === "1") {
            //จบการประเมินตอนรอบ 2
            RoundDate_2 = record.RoundDate_2 ? (
              <Moment format="YYYY-MM-DD" className="date-green">
                {record.RoundDate_2}
              </Moment>
            ) : '-';
          } else {
            RoundDate_2 = record.RoundDate_2 ? (
              <Moment
                format="YYYY-MM-DD"
                className={colorClassName(record.RoundDate_2)}
              >
                {record.RoundDate_2}
              </Moment>
            ) : '-';
          }
        }
        return <div>{RoundDate_2 || "-"}</div>;
      },
    },
    {
      title: "รอบที่ 3",
      dataIndex: "RoundDate_3",
      key: "RoundDate_3",
      align: "right",
      width: 150,
      render: (text, record) => {
        let RoundDate_3 = "";
        if (record.RoundNow) {
          if (record.RoundNow < 3 && record.statusProbationLast === "1") {
            //ประเมินจบตั้งแต่รอบที่ 2
            RoundDate_3 = "-";
          } else if (record.RoundNow > 3 || record.statusProbationLast === "1") {
            //จบการประเมินตอนรอบ 3
            RoundDate_3 = record.RoundDate_3 ? (
              <Moment format="YYYY-MM-DD" className="date-green">
                {record.RoundDate_3}
              </Moment>
            ) : '-';
          } else {
            RoundDate_3 = record.RoundDate_3 ? (
              <Moment
                format="YYYY-MM-DD"
                className={colorClassName(record.RoundDate_3)}
              >
                {record.RoundDate_3}
              </Moment>
            ) : '-';
          }
        }
        return <div>{RoundDate_3 || "-"}</div>;
      },
    },
    {
      title: "รอบที่ 4",
      dataIndex: "RoundDate_4",
      key: "RoundDate_4",
      align: "right",
      width: 150,
      render: (text, record) => {
        let RoundDate_4 = "";
        if (record.RoundNow) {
          if (record.RoundNow < 4 && record.statusProbationLast === "1") {
            //ประเมินจบตั้งแต่รอบที่ 3
            RoundDate_4 = "-";
          } else if (record.RoundNow > 4 || record.statusProbationLast === "1") {
            //จบการประเมินตอนรอบ 4
            RoundDate_4 = record.RoundDate_4 ? (
              <Moment format="YYYY-MM-DD" className="date-green">
                {record.RoundDate_4}
              </Moment>
            ) : '-';
          } else {
            RoundDate_4 = record.RoundDate_4 ? (
              <Moment
                format="YYYY-MM-DD"
                className={colorClassName(record.RoundDate_4)}
              >
                {record.RoundDate_4}
              </Moment>
            ) : '-';
          }
        }
        return <div>{RoundDate_4 || "-"}</div>;
      },
    },
    {
      title: "รอบที่ 5",
      dataIndex: "RoundDate_5",
      key: "RoundDate_5",
      align: "right",
      width: 150,
      render: (text, record) => {
        let RoundDate_5 = "";
        if (record.RoundNow) {
          if (record.RoundNow < 5 && record.statusProbationLast === "1") {
            //ประเมินจบตั้งแต่รอบที่ 4
            RoundDate_5 = "-";
          } else if (record.RoundNow > 5 || record.statusProbationLast === "1") {
            //จบการประเมินตอนรอบ 5
            RoundDate_5 = record.RoundDate_5 ? (
              <Moment format="YYYY-MM-DD" className="date-green">
                {record.RoundDate_5}
              </Moment>
            ) : '-';
          } else {
            RoundDate_5 = record.RoundDate_5 ? (
              <Moment
                format="YYYY-MM-DD"
                className={colorClassName(record.RoundDate_5)}
              >
                {record.RoundDate_5}
              </Moment>
            ) : '-';
          }
        }
        return <div>{RoundDate_5 || "-"}</div>;
      },
    },
    {
      title: "รอบที่ 6",
      dataIndex: "RoundDate_6",
      key: "RoundDate_6",
      align: "right",
      width: 150,
      render: (text, record) => {
        let RoundDate_6 = "";
        if (record.RoundNow) {
          if (record.RoundNow < 6 && record.statusProbationLast === "1") {
            //ประเมินจบตั้งแต่รอบที่ 5
            RoundDate_6 = "-";
          } else if (record.RoundNow > 6 || record.statusProbationLast === "1") {
            //จบการประเมินตอนรอบ 6
            RoundDate_6 = record.RoundDate_6 ? (
              <Moment format="YYYY-MM-DD" className="date-green">
                {record.RoundDate_6}
              </Moment>
            ) : '-';
          } else {
            RoundDate_6 = record.RoundDate_6 ? (
              <Moment
                format="YYYY-MM-DD"
                className={colorClassName(record.RoundDate_6)}
              >
                {record.RoundDate_6}
              </Moment>
            ) : '-';
          }
        }
        return <div>{RoundDate_6 || "-"}</div>;
      },
    },
    {
      title: "วันที่รับเอกสารทดลองงาน",
      dataIndex: "HR_ReceivedDate",
      key: "HR_ReceivedDate",
      align: "right",
      fixed: "right",
      width: 200,
      render: (text, record) => {
        let HR_ReceivedDate = "";
        if (+record.statusMainCode === 2) {
          return useButton({
            call: "normal",
            moreProps: {
              label: "ยืนยันรับเอกสาร",
              type: "primary",
            },
            fn: () => fnReceive(record),
          });
        } else if (record.HR_ReceivedDate != null) {
          return (
            <React.Fragment>
              <FiCheckCircle color={"#028E22"} size={"18"} />
              <div className="date-green">{text}</div>
            </React.Fragment>
          );
        }
        return <div>{HR_ReceivedDate}</div>;
      },
    },
  ];

export const ColumnSearchReportTrackContract = ({ fnReceive = () => null }) => [
  {
    title: "รหัสพนักงาน",
    dataIndex: "StaffCode",
    key: "StaffCode",
    align: "left",
    fixed: "left",
    width: 150,
    ...filterSearchColumn("StaffCode", "รหัสพนักงาน"),
  },
  {
    title: "ชื่อพนักงาน",
    dataIndex: "StaffName",
    key: "StaffName",
    align: "left",
    fixed: "left",
    width: 200,
    ...filterSearchColumn("StaffName", "ชื่อพนักงาน"),
  },
  {
    title: "ชื่อเล่น",
    dataIndex: "NickName",
    key: "NickName",
    align: "left",
    fixed: "left",
    width: 150,
    ...filterSearchColumn("NickName", "ชื่อเล่น"),
  },
  {
    title: "ชื่อผู้บังคับบัญชา",
    dataIndex: "BossName",
    key: "BossName",
    align: "left",
    fixed: "left",
    width: 200,
    ...filterSearchColumn("BossName", "ชื่อผู้บังคับบัญชา"),
  },
  {
    title: "แผนก",
    dataIndex: "Department_Name",
    key: "Department_Name",
    align: "left",
    width: 200,
    ...filterSearchColumn("Department_Name", "แผนก"),
  },
  {
    title: "แผนกย่อย",
    dataIndex: "subDepName",
    key: "subDepName",
    align: "left",
    width: 200,
    ...filterSearchColumn("subDepName", "แผนกย่อย"),
  },
  {
    title: "สถานะเอกสาร",
    dataIndex: "statusMainCode",
    key: "statusMainCode",
    align: "left",
    width: 200,
    render: (text) => {
      return RenderStatusMainCode(text);
    },
  },
  {
    title: "วันที่เริ่มงาน",
    dataIndex: "StartDateJob",
    key: "StartDateJob",
    align: "right",
    width: 150,
    ...filterSearchColumn("StartDateJob", "วันที่เริ่มงาน"),
  },
  {
    title: "วันที่เริ่มรอบสัญญาจ้าง",
    dataIndex: "startProbationDate",
    align: "right",
    width: 150,
    // ...getColumnSearchProps('StartDateJob', searchInput),
  },
  {
    title: "ต่อสัญญาครั้งที่",
    dataIndex: "roundContract",
    align: "right",
    width: 120,
  },
  {
    title: "วันที่คาดว่าผ่านทดลองงาน",
    dataIndex: "expProDate",
    key: "expProDate",
    align: "right",
    width: 200,
    ...filterSearchColumn("expProDate", "วันที่คาดว่าผ่านทดลองงาน"),
  },
  {
    title: "เอกสารทดลองงาน",
    key: "DocNo",
    dataIndex: "DocNo",
    align: "center",
    width: 200,
    render: (text, record) => {
      if (text) {
        return (
          record.statusMainCode !== "0" && (
            <ButtonView
              onClick={() => {
                window.localStorage.setItem(
                  KEY_OPTION.DATA.PROBATION_CONTRACT,
                  JSON.stringify({ ...record })
                );
                window.open(PATHNAME.VIEW_PROBATION_CONTRACT, "_blank");
              }}
            />
          )
        );
      } else {
        return "-";
      }
    },
  },
  {
    title: "รอบปัจจุบัน",
    dataIndex: "RoundNow",
    key: "RoundNow",
    align: "right",
    width: 150,
    sorter: (a, b) => a.RoundNow - b.RoundNow,
  },
  {
    title: "รอบที่ยืนยันแล้ว",
    dataIndex: "status_confirm",
    key: "status_confirm",
    align: "right",
    width: 150,
    sorter: (a, b) => a.status_confirm - b.status_confirm,
  },
  {
    title: "รอบที่ 1",
    dataIndex: "RoundDate_1",
    key: "RoundDate_1",
    align: "right",
    width: 150,
    render: (text, record) => {
      let RoundDate_1 = "";
      if (record.RoundDate_1) {
        if (record.RoundNow > 1) {
          //จบการประเมินรอบที่ 1
          RoundDate_1 = record.RoundDate_1 ? (
            <Moment format="YYYY-MM-DD" className="date-green">
              {record.RoundDate_1}
            </Moment>
          ) : '-';
        } else {
          RoundDate_1 = record.RoundDate_1 ? (
            <Moment
              format="YYYY-MM-DD"
              className={colorClassName(record.RoundDate_1)}
            >
              {record.RoundDate_1}
            </Moment>
          ) : '-';
        }
      }
      return <div>{RoundDate_1 || "-"}</div>;
    },
  },
  {
    title: "รอบที่ 2",
    dataIndex: "RoundDate_2",
    key: "RoundDate_2",
    align: "right",
    width: 150,
    render: (text, record) => {
      let RoundDate_2 = "";
      if (record.RoundNow) {
        if (record.RoundNow > 2 || record.statusProbationLast === "1") {
          //จบการประเมินตอนรอบ 2
          RoundDate_2 = record.RoundDate_2 ? (
            <Moment format="YYYY-MM-DD" className="date-green">
              {record.RoundDate_2}
            </Moment>
          ) : '-';
        } else {
          RoundDate_2 = record.RoundDate_2 ? (
            <Moment
              format="YYYY-MM-DD"
              className={colorClassName(record.RoundDate_2)}
            >
              {record.RoundDate_2}
            </Moment>
          ) : '-';
        }
      }
      return <div>{RoundDate_2 || "-"}</div>;
    },
  },
  {
    title: "รอบที่ 3",
    dataIndex: "RoundDate_3",
    key: "RoundDate_3",
    align: "right",
    width: 150,
    render: (text, record) => {
      let RoundDate_3 = "";
      if (record.RoundNow) {
        if (record.RoundNow < 3 && record.statusProbationLast === "1") {
          //ประเมินจบตั้งแต่รอบที่ 2
          RoundDate_3 = "-";
        } else if (record.RoundNow > 3 || record.statusProbationLast === "1") {
          //จบการประเมินตอนรอบ 3
          RoundDate_3 = record.RoundDate_3 ? (
            <Moment format="YYYY-MM-DD" className="date-green">
              {record.RoundDate_3}
            </Moment>
          ) : '-';
        } else {
          RoundDate_3 = record.RoundDate_3 ? (
            <Moment
              format="YYYY-MM-DD"
              className={colorClassName(record.RoundDate_3)}
            >
              {record.RoundDate_3}
            </Moment>
          ) : '-';
        }
      }
      return <div>{RoundDate_3 || "-"}</div>;
    },
  },
  {
    title: "รอบที่ 4",
    dataIndex: "RoundDate_4",
    key: "RoundDate_4",
    align: "right",
    width: 150,
    render: (text, record) => {
      let RoundDate_4 = "";
      if (record.RoundNow) {
        if (record.RoundNow < 4 && record.statusProbationLast === "1") {
          //ประเมินจบตั้งแต่รอบที่ 3
          RoundDate_4 = "-";
        } else if (record.RoundNow > 4 || record.statusProbationLast === "1") {
          //จบการประเมินตอนรอบ 4
          RoundDate_4 = record.RoundDate_4 ? (
            <Moment format="YYYY-MM-DD" className="date-green">
              {record.RoundDate_4}
            </Moment>
          ) : '-';
        } else {
          RoundDate_4 = record.RoundDate_4 ? (
            <Moment
              format="YYYY-MM-DD"
              className={colorClassName(record.RoundDate_4)}
            >
              {record.RoundDate_4}
            </Moment>
          ) : '-';
        }
      }
      return <div>{RoundDate_4 || "-"}</div>;
    },
  },
  {
    title: "รอบที่ 5",
    dataIndex: "RoundDate_5",
    key: "RoundDate_5",
    align: "right",
    width: 150,
    render: (text, record) => {
      let RoundDate_5 = "";
      if (record.RoundNow) {
        if (record.RoundNow < 5 && record.statusProbationLast === "1") {
          //ประเมินจบตั้งแต่รอบที่ 4
          RoundDate_5 = "-";
        } else if (record.RoundNow > 5 || record.statusProbationLast === "1") {
          //จบการประเมินตอนรอบ 5
          RoundDate_5 = record.RoundDate_5 ? (
            <Moment format="YYYY-MM-DD" className="date-green">
              {record.RoundDate_5}
            </Moment>
          ) : '-';
        } else {
          RoundDate_5 = record.RoundDate_5 ? (
            <Moment
              format="YYYY-MM-DD"
              className={colorClassName(record.RoundDate_5)}
            >
              {record.RoundDate_5}
            </Moment>
          ) : '-';
        }
      }
      return <div>{RoundDate_5 || "-"}</div>;
    },
  },
  {
    title: "รอบที่ 6",
    dataIndex: "RoundDate_6",
    key: "RoundDate_6",
    align: "right",
    width: 150,
    render: (text, record) => {
      let RoundDate_6 = "";
      if (record.RoundNow) {
        if (record.RoundNow < 6 && record.statusProbationLast === "1") {
          //ประเมินจบตั้งแต่รอบที่ 5
          RoundDate_6 = "-";
        } else if (record.RoundNow > 6 || record.statusProbationLast === "1") {
          //จบการประเมินตอนรอบ 6
          RoundDate_6 = record.RoundDate_6 ? (
            <Moment format="YYYY-MM-DD" className="date-green">
              {record.RoundDate_6}
            </Moment>
          ) : '-';
        } else {
          RoundDate_6 = record.RoundDate_6 ? (
            <Moment
              format="YYYY-MM-DD"
              className={colorClassName(record.RoundDate_6)}
            >
              {record.RoundDate_6}
            </Moment>
          ) : '-';
        }
      }
      return <div>{RoundDate_6 || "-"}</div>;
    },
  },
  {
    title: "วันที่รับเอกสารทดลองงาน",
    dataIndex: "HR_ReceivedDate",
    key: "HR_ReceivedDate",
    align: "right",
    fixed: "right",
    width: 200,
    render: (text, record) => {
      let HR_ReceivedDate = "";
      if (+record.statusMainCode === 2) {
        return useButton({
          call: "normal",
          moreProps: {
            label: "ยืนยันรับเอกสาร",
            type: "primary",
          },
          fn: () => fnReceive(record),
        });
      } else if (record.HR_ReceivedDate != null) {
        return (
          <React.Fragment>
            <FiCheckCircle color={"#028E22"} size={"18"} />
            <div className="date-green">{text}</div>
          </React.Fragment>
        );
      }
      return <div>{HR_ReceivedDate}</div>;
    },
  },
];

export const ColumnManageDocProbation = ({
  showModal = () => null,
  fnCancel = () => null,
}) => [
    {
      title: "รหัสพนักงาน",
      dataIndex: "StaffCode",
      key: "StaffCode",
      align: "left",
      fixed: "left",
      width: 150,
      ...filterSearchColumn("StaffCode", "รหัสพนักงาน"),
    },
    {
      title: "ชื่อพนักงาน",
      dataIndex: "StaffName",
      key: "StaffName",
      align: "left",
      fixed: "left",
      width: 200,
      ...filterSearchColumn("StaffName", "ชื่อพนักงาน"),
    },
    {
      title: "ชื่อเล่น",
      dataIndex: "NickName",
      key: "NickName",
      align: "left",
      width: 150,
      ...filterSearchColumn("NickName", "ชื่อเล่น"),
    },
    {
      title: "ชื่อผู้บังคับบัญชา",
      dataIndex: "BossName",
      key: "BossName",
      align: "left",
      width: 200,
      ...filterSearchColumn("BossName", "ชื่อผู้บังคับบัญชา"),
    },
    {
      title: "แผนก",
      dataIndex: "Department_Name",
      key: "Department_Name",
      align: "left",
      width: 200,
      ...filterSearchColumn("Department_Name", "แผนก"),
    },
    {
      title: "แผนกย่อย",
      dataIndex: "subDepName",
      key: "subDepName",
      align: "left",
      width: 200,
      ...filterSearchColumn("subDepName", "แผนกย่อย"),
    },
    {
      title: "สถานะเอกสาร",
      dataIndex: "statusMainCode",
      key: "statusMainCode",
      align: "left",
      width: 200,
      render: (text) => {
        return RenderStatusMainCode(text);
      },
    },
    {
      title: "วันที่เริ่มงาน",
      dataIndex: "StartDateJob",
      key: "StartDateJob",
      align: "right",
      width: 150,
      ...filterSearchColumn("StartDateJob", "วันที่เริ่มงาน"),
    },
    {
      title: "วันที่เริ่มรอบสัญญาจ้าง",
      dataIndex: "startProbationDate",
      key: "startProbationDate",
      align: "left",
      width: 150,
      // ...getColumnSearchProps('StartDateJob', searchInput),
    },
    {
      title: "ต่อสัญญาครั้งที่",
      key: "roundContract",
      dataIndex: "roundContract",
      align: "right",
      width: 120,
    },
    {
      title: "วันที่คาดว่าผ่านทดลองงาน",
      dataIndex: "expProDate",
      key: "expProDate",
      align: "right",
      width: 200,
      ...filterSearchColumn("expProDate", "วันที่คาดว่าผ่านทดลองงาน"),
    },
    {
      title: "เอกสารทดลองงาน",
      key: "DocNo",
      dataIndex: "DocNo",
      align: "center",
      width: 200,
      render: (text, record) => {
        if (text) {
          return (
            <ButtonView
              onClick={() => {
                window.localStorage.setItem(
                  KEY_OPTION.DATA.PROBATION_CONTRACT,
                  JSON.stringify({ ...record })
                );
                window.open(PATHNAME.VIEW_PROBATION_CONTRACT, "_blank");
              }}
            />
          );
        } else {
          return "-";
        }
      },
    },
    {
      title: "รอบปัจจุบัน",
      dataIndex: "RoundNow",
      key: "RoundNow",
      align: "right",
      width: 150,
      sorter: (a, b) => a.RoundNow - b.RoundNow,
    },
    {
      title: "รอบที่ยืนยันแล้ว",
      dataIndex: "status_confirm",
      key: "status_confirm",
      align: "right",
      width: 150,
      sorter: (a, b) => a.status_confirm - b.status_confirm,
    },
    {
      title: "รอบที่ 1",
      dataIndex: "RoundDate_1",
      key: "RoundDate_1",
      align: "right",
      width: 150,
      render: (text, record) => {
        let RoundDate_1 = "";
        if (record.RoundDate_1) {
          if (record.RoundNow > 1) {
            //จบการประเมินรอบที่ 1
            RoundDate_1 = record.RoundDate_1 ? (
              <Moment format="YYYY-MM-DD" className="date-green">
                {record.RoundDate_1}
              </Moment>
            ) : '-';
          } else {
            RoundDate_1 = record.RoundDate_1 ? (
              <Moment
                format="YYYY-MM-DD"
                className={colorClassName(record.RoundDate_1)}
              >
                {record.RoundDate_1}
              </Moment>
            ) : '-';
          }
        }
        return <div>{RoundDate_1 || "-"}</div>;
      },
    },
    {
      title: "รอบที่ 2",
      dataIndex: "RoundDate_2",
      key: "RoundDate_2",
      align: "right",
      width: 150,
      render: (text, record) => {
        let RoundDate_2 = "";
        if (record.RoundNow) {
          if (record.RoundNow > 2 || record.statusProbationLast === "1") {
            //จบการประเมินตอนรอบ 2
            RoundDate_2 = record.RoundDate_2 ? (
              <Moment format="YYYY-MM-DD" className="date-green">
                {record.RoundDate_2}
              </Moment>
            ) : '-';
          } else {
            RoundDate_2 = record.RoundDate_2 ? (
              <Moment
                format="YYYY-MM-DD"
                className={colorClassName(record.RoundDate_2)}
              >
                {record.RoundDate_2}
              </Moment>
            ) : '-';
          }
        }
        return <div>{RoundDate_2 || "-"}</div>;
      },
    },
    {
      title: "รอบที่ 3",
      dataIndex: "RoundDate_3",
      key: "RoundDate_3",
      align: "right",
      width: 150,
      render: (text, record) => {
        let RoundDate_3 = "";
        if (record.RoundNow) {
          if (record.RoundNow < 3 && record.statusProbationLast === "1") {
            //ประเมินจบตั้งแต่รอบที่ 2
            RoundDate_3 = "-";
          } else if (record.RoundNow > 3 || record.statusProbationLast === "1") {
            //จบการประเมินตอนรอบ 3
            RoundDate_3 = record.RoundDate_3 ? (
              <Moment format="YYYY-MM-DD" className="date-green">
                {record.RoundDate_3}
              </Moment>
            ) : '-';
          } else {
            RoundDate_3 = record.RoundDate_3 ? (
              <Moment
                format="YYYY-MM-DD"
                className={colorClassName(record.RoundDate_3)}
              >
                {record.RoundDate_3}
              </Moment>
            ) : '-';
          }
        }
        return <div>{RoundDate_3 || "-"}</div>;
      },
    },
    {
      title: "รอบที่ 4",
      dataIndex: "RoundDate_4",
      key: "RoundDate_4",
      align: "right",
      width: 150,
      render: (text, record) => {
        let RoundDate_4 = "";
        if (record.RoundNow) {
          if (record.RoundNow < 4 && record.statusProbationLast === "1") {
            //ประเมินจบตั้งแต่รอบที่ 3
            RoundDate_4 = "-";
          } else if (record.RoundNow > 4 || record.statusProbationLast === "1") {
            //จบการประเมินตอนรอบ 4
            RoundDate_4 = record.RoundDate_4 ? (
              <Moment format="YYYY-MM-DD" className="date-green">
                {record.RoundDate_4}
              </Moment>
            ) : '-';
          } else {
            RoundDate_4 = record.RoundDate_4 ? (
              <Moment
                format="YYYY-MM-DD"
                className={colorClassName(record.RoundDate_4)}
              >
                {record.RoundDate_4}
              </Moment>
            ) : '-';
          }
        }
        return <div>{RoundDate_4 || "-"}</div>;
      },
    },
    {
      title: "รอบที่ 5",
      dataIndex: "RoundDate_5",
      key: "RoundDate_5",
      align: "right",
      width: 150,
      render: (text, record) => {
        let RoundDate_5 = "";
        if (record.RoundNow) {
          if (record.RoundNow < 5 && record.statusProbationLast === "1") {
            //ประเมินจบตั้งแต่รอบที่ 4
            RoundDate_5 = "-";
          } else if (record.RoundNow > 5 || record.statusProbationLast === "1") {
            //จบการประเมินตอนรอบ 5
            RoundDate_5 = record.RoundDate_5 ? (
              <Moment format="YYYY-MM-DD" className="date-green">
                {record.RoundDate_5}
              </Moment>
            ) : '-';
          } else {
            RoundDate_5 = record.RoundDate_5 ? (
              <Moment
                format="YYYY-MM-DD"
                className={colorClassName(record.RoundDate_5)}
              >
                {record.RoundDate_5}
              </Moment>
            ) : '-';
          }
        }
        return <div>{RoundDate_5 || "-"}</div>;
      },
    },
    {
      title: "รอบที่ 6",
      dataIndex: "RoundDate_6",
      key: "RoundDate_6",
      align: "right",
      width: 150,
      render: (text, record) => {
        let RoundDate_6 = "";
        if (record.RoundNow) {
          if (record.RoundNow < 6 && record.statusProbationLast === "1") {
            //ประเมินจบตั้งแต่รอบที่ 5
            RoundDate_6 = "-";
          } else if (record.RoundNow > 6 || record.statusProbationLast === "1") {
            //จบการประเมินตอนรอบ 6
            RoundDate_6 = record.RoundDate_6 ? (
              <Moment format="YYYY-MM-DD" className="date-green">
                {record.RoundDate_6}
              </Moment>
            ) : '-';
          } else {
            RoundDate_6 = record.RoundDate_6 ? (
              <Moment
                format="YYYY-MM-DD"
                className={colorClassName(record.RoundDate_6)}
              >
                {record.RoundDate_6}
              </Moment>
            ) : '-';
          }
        }
        return <div>{RoundDate_6 || "-"}</div>;
      },
    },
    {
      title: "จัดการข้อมูล",
      key: "action",
      align: "left",
      fixed: "right",
      width: 200,
      render: (text, record) => {
        return (
          <Space>
            {useButton({
              call: "normal",
              moreProps: {
                label: "ผ่านทดลองงาน",
              },
              moreStyle: {
                backgroundColor: +record.statusMainCode === 0 || "#5CB85C",
                borderColor: +record.statusMainCode === 0 || "#5CB85C",
                color: +record.statusMainCode === 0 || "#FFFFFF",
              },
              fn: () => showModal(record, "pass"),
              isDisable: +record.statusMainCode === 0,
            })}
            {useButton({
              call: "normal",
              moreProps: {
                label: "ยกเลิกเอกสาร",
                type: "danger",
              },
              fn: () => useConfirm({ FnOK: () => fnCancel(record.DocNo) }),
              moreStyle: { color: +record.statusMainCode === 0 || "#FFFFFF" },
              isDisable: +record.statusMainCode === 0,
            })}
          </Space>
        );
      },
    },
  ];

export const ColumnManageBenefitContract = ({ showModal = () => null }) => [
  {
    title: "รหัสพนักงาน",
    dataIndex: "userId",
    key: "userId",
    align: "left",
    fixed: "left",
    width: 150,
    ...filterSearchColumn("userId", "รหัสพนักงาน"),
  },
  {
    title: "ชื่อพนักงาน",
    dataIndex: "fullname",
    key: "fullname",
    align: "left",
    fixed: "left",
    width: 200,
    ...filterSearchColumn("fullname", "ชื่อพนักงาน"),
  },
  {
    title: "ชื่อเล่น",
    dataIndex: "nickname",
    key: "nickname",
    align: "left",
    width: 150,
    ...filterSearchColumn("nickname", "ชื่อเล่น"),
  },
  {
    title: "บริษัท",
    dataIndex: "company",
    key: "company",
    align: "left",
    width: 100,
    ...filterSearchColumn("company", "บริษัท"),
  },
  {
    title: "Location",
    dataIndex: "location",
    key: "location",
    align: "left",
    width: 100,
    ...filterSearchColumn("location", "Location"),
  },
  {
    title: "รหัสแผนก",
    dataIndex: "depNo",
    key: "depNo",
    align: "left",
    width: 100,
    ...filterSearchColumn("depNo", "แผนก"),
  },
  {
    title: "ชื่อแผนก",
    dataIndex: "depName",
    key: "depName",
    align: "left",
    width: 200,
    ...filterSearchColumn("depName", "ชื่อแผนก"),
  },
  {
    title: "แผนกย่อย",
    dataIndex: "subDepName",
    key: "subDepName",
    align: "left",
    width: 300,
    ...filterSearchColumn("subDepName", "แผนกย่อย"),
  },
  {
    title: "รอบสัญญา",
    dataIndex: "roundContract",
    key: "roundContract",
    align: "right",
    width: 150,
    sorter: (a, b) => a.roundContract - b.roundContract,
  },
  {
    title: "ผู้บังคับบัญชา",
    dataIndex: "approversName",
    key: "approversName",
    align: "left",
    width: 200,
    ...filterSearchColumn("approversName", "ผู้บังคับบัญชา"),
  },
  {
    title: "วันที่เริ่มงาน",
    dataIndex: "startDateJob",
    key: "startDateJob",
    align: "right",
    width: 150,
    ...filterSearchColumn("startDateJob", "วันที่เริ่มงาน"),
  },
  {
    title: "จำนวนวัน",
    dataIndex: "totalDay",
    key: "totalDay",
    align: "right",
    width: 150,
    sorter: (a, b) => a.totalDay - b.totalDay,
  },
  {
    title: "จัดการข้อมูล",
    key: "action",
    align: "left",
    fixed: "right",
    width: 150,
    render: (text, record) => {
      return (
        <Space>
          {useButton({
            call: "normal",
            moreProps: {
              label: "รับสวัสดิการ",
            },
            moreStyle: {
              backgroundColor: "#5CB85C",
              borderColor: "#5CB85C",
              color: "#FFFFFF",
            },
            fn: () => showModal(record),
          })}
        </Space>
      );
    },
  },
];

const colorClassName = (_dueDate) => {
  const DateToday = new Date();
  let classNameText = "";
  let diff = 0;
  if (_dueDate) {
    _dueDate = new Date(_dueDate.substring(0, 10));
    diff = parseInt((_dueDate - DateToday) / (24 * 3600 * 1000));
    if (diff < 0) {
      classNameText = "date-red"; // red
    } else if (diff < 8 && diff >= 0) {
      classNameText = "date-yellow"; //yellow
    }
  }
  return classNameText;
};

const colorClassNameIsEnding = (_dueDate) => {
  const DateToday = new Date();
  const curMonth = DateToday.getMonth() + 1;
  let classNameText = "";
  if (_dueDate) {
    _dueDate = new Date(_dueDate.substring(0, 10));
    _dueDate.setMonth(_dueDate.getMonth() + 1);
    if (_dueDate.getMonth() === curMonth || _dueDate.getMonth() === curMonth + 1) {
      //*ถ้าวันจบสัญญา ตรงกับเดือนนี้ หรือ อีก 1 เดือน จากนี้
      classNameText = "date-red";
    }
  }
  return classNameText;
};