import React from "react";
import { Modal } from "antd";

export const useAlert = ({
  type,
  title,
  content,
  Fn,
  moreProps = moreProps || {},
}) => {
  if (type === "catch") {
    return Modal.error({
      title: "[CATCH] : เกิดข้อผิดพลาด",
      content: content,
      onOk: Fn,
      ...moreProps,
    });
  } else {
    return Modal[type]({
      title: title,
      content: content,
      onOk: Fn,
      ...moreProps,
    });
  }
};
