
export const STATUS_VALIDATE = {
    SUCCESS: 'success',
    WARNING: 'warning',
    ERROR: 'error',
    VALIDATING: 'validating',
}


export const MESSAGE_ERROR = {
    INPUT_EN: 'กรุณากรอกข้อมูล เป็นภาษาอังกฤษ',
    INPUT: 'กรุณากรอกข้อมูล',
    SELECT: 'กรุณาเลือกข้อมูล',
    NUMBER: 'กรุณากรอกเฉพาะตัวเลข (0-9) เท่านั้น',
    EMAIL: 'กรุณากรอกข้อมูลให้ถูกต้อง',
    ID_CARD: 'กรุณากรอกเฉพาะตัวเลข 13 หลัก',
    PASSPORT: 'กรุณากรอกข้อมูล',
    DECIMAL: 'กรุณากรอกเฉพาะตัวเลข เท่านั้น',
    TEL: 'กรุณากรอกเฉพาะตัวเลข (0-9) เท่านั้น',
    RADIO: 'กรุณาเลือกข้อมูล',
    UPLOAD_FLIES: 'กรุณาเลือกข้อมูล',
    CHECKBOX: 'กรุณาเลือกข้อมูล',
    DATE_PICKER: 'กรุณาระบุวันที่',
    SCORE_JG: (_score) => `กรอกเฉพาะตัวเลขได้ไม่เกิน ${_score} คะแนน`
}
/* -------------------------------------------------------------------------- */
/*                                 LABEL_PAGES                                */
/* -------------------------------------------------------------------------- */
const LABEL_PAGES = {
    //!เปลี่ยนชื่อ Field ที่นี่
    /* ----------------------------- STEP  ----------------------------- */
    STEP_0: 'ข้อมูลพนักงาน',
    STEP_1: 'ข้อมูลส่วนตัว',
    STEP_2: 'ข้อมูลการทำงาน',
    STEP_3: 'ข้อมูลการศึกษา',
    STEP_4: 'ข้อมูลเพิ่มเติม',
    STEP_5: 'หลักฐานการสมัคร',
    STEP_6: 'แจ้งพ้นสภาพ',

    /* -------------------------------- COLLAPSE STEP_0 -------------------------------- */
    COLLAPSE_S01: 'ค้นหาพนักงาน',
    COLLAPSE_S02: 'ข้อมูลพนักงาน',
    COLLAPSE_S03: 'แจ้งพ้นสภาพ',
    /* -------------------------------- COLLAPSE STEP_1 -------------------------------- */
    COLLAPSE_S11: 'ข้อมูลทั่วไป',
    COLLAPSE_S12: 'ประวัติครอบครัว',
    /* -------------------------------- COLLAPSE STEP_2 -------------------------------- */
    COLLAPSE_S21: 'ข้อมูลการทำงาน',
    /* -------------------------------- COLLAPSE STEP_3 -------------------------------- */
    COLLAPSE_S31: 'ข้อมูลการศึกษา',
    COLLAPSE_S32: 'ข้อมูลการฝึกอบรม',
    /* -------------------------------- COLLAPSE STEP_4 -------------------------------- */
    COLLAPSE_S41: 'ทักษะการขับรถ',
    COLLAPSE_S42: 'ความสามารถพิเศษ',
    COLLAPSE_S43: 'ทักษะด้านภาษา',
    COLLAPSE_S44: 'บุคคลที่ไม่ใช่ญาติซึ่งทราบประวัติของท่าน',
    COLLAPSE_S45: 'กิจกรรม/งานอดิเรก',
    COLLAPSE_S46: 'ข้อมูลสินทรัพย์พนักงาน',
    COLLAPSE_S47: 'ข้อมูลอาชญากรรม (มีตรวจปีละ 1 ครั้ง)',
    COLLAPSE_S48: 'ข้อมูลการตรวจสุขภาพ',
    COLLAPSE_S49: 'ข้อมูลการตรวจสารเสพติด (มีตรวจปีละ 1 ครั้ง)',
    COLLAPSE_S410: 'ข้อมูลอื่นๆ',
    /* -------------------------------- COLLAPSE STEP_5 -------------------------------- */
    COLLAPSE_S51: 'หลักฐานการสมัคร'
}

/* -------------------------------------------------------------------------- */
/*                                 FIELD_PAGES                                */
/* -------------------------------------------------------------------------- */

const FIELD_PAGES = {
    //!เปลี่ยนชื่อ Field ที่นี่
    /* ----------------------------- STEP  ----------------------------- */
    STEP_0: 'empInfo',
    STEP_1: 'personal',
    STEP_2: 'experiences',
    STEP_3: 'learning',
    STEP_4: 'additional',
    STEP_5: 'evidences',
    STEP_6: 'blockEmp',

    /* -------------------------------- COLLAPSE STEP_0 -------------------------------- */
    COLLAPSE_S01: 'findEmp',
    COLLAPSE_S02: 'empInfo',
    COLLAPSE_S03: 'blockEmp',
    /* -------------------------------- COLLAPSE STEP_1 -------------------------------- */
    COLLAPSE_S11: 'personalInfo',
    COLLAPSE_S12: 'familyInfo',
    /* -------------------------------- COLLAPSE STEP_2 -------------------------------- */
    COLLAPSE_S21: 'experiencesInfo',
    /* -------------------------------- COLLAPSE STEP_3 -------------------------------- */
    COLLAPSE_S31: 'educationInfo',
    COLLAPSE_S32: 'trainingInfo',
    /* -------------------------------- COLLAPSE STEP_4 -------------------------------- */
    COLLAPSE_S41: 'drivingInfo',
    COLLAPSE_S42: 'abilityInfo',
    COLLAPSE_S43: 'languageSkill',
    COLLAPSE_S44: 'otherPersonInfo',
    COLLAPSE_S45: 'activitiesInfo',
    COLLAPSE_S46: 'assetsInfo',
    COLLAPSE_S47: 'crimeInfo',
    COLLAPSE_S48: 'healthInfo',
    COLLAPSE_S49: 'addictiveInfo',
    COLLAPSE_S410: 'otherInfo',
    /* -------------------------------- COLLAPSE STEP_5 -------------------------------- */
    COLLAPSE_S51: 'evidencesInfo'
}



export const LABEL_STEP = LABEL_PAGES;
export const FIELD_STEP = FIELD_PAGES;

