import React, { useState } from "react";
import { Form, Card, Spin, Space, Tabs } from "antd";
import {
    optionSelectLocation,
    useOptionSelect,
} from "../../constants/OptionSelect";
import {
    GET_UserLogin,
    toUndefined,
    sumColumnWidth,
    subStringDate,
} from "../../functions/UseFunction";
import { useFixedQueryString } from "../../functions/UseMakeQueryString";
import { useAlert } from "../modal/CustomAlert";
import { configForm } from "../../constants/ConstantComponent";
import { useRenderFormItem } from "../RenderComponents";
import CustomTableAtnd from "../tables/CustomTableAtnd";
import { useNotification } from "../notification/NotificationWithIcon";
import { GET, GET_SLECT_DASHBOARD_PROBATION_OUT, POST, POST_SELECT_DASHBOARD_PROBATION } from "../../../service";
import { useButton } from "../buttons/CustomButtonAntd";
import { ColumnSearchProbationEmpNew } from "../../constants/ProbationEmployee/Columns";
import { ComponentSearchProbationEmpNew } from "../../constants/ProbationEmployee/Components";
import WorkingProcess from "../../../components/workingProcess";
import { useExportExcel } from "../../services/ExportExcel";
import { Header_ReportProbationEmpNew } from "../../constants/HeaderExcel";

const FormSearchProbationEmpNew = () => {
    const [form] = Form.useForm();
    const { userlogin, location } = GET_UserLogin();
    // [START] States
    const [ChangeValue, setChangeValue] = useState({ location: location === "FAC" ? "FAC" : undefined });
    const [dataTableProbation, setDataTableProbation] = useState([]);
    const [dataTableProbationOut, setDataTableProbationOut] = useState([]);
    const [activeTab, setActiveTab] = useState(1);
    const [loadingScreen, setLoadingScreen] = useState(false);
    // [END] States

    let optionSearch = {
        company: useOptionSelect("company"),
        location: optionSelectLocation,
        depNo: useOptionSelect("department", useFixedQueryString(ChangeValue, ["companyCode", "location"])),
        statusMainCode: useOptionSelect("statusMainCode"),
        statusWorker: useOptionSelect("statusWorker"),
        searchUser: useOptionSelect("empAll", useFixedQueryString(ChangeValue, ["companyCode", "location"])),
    }
    // [START] Handle Form Antd
    const onFinish = (values) => {
        const { company, depNo, statusMainCode, statusWorker, startDate, toDate, searchUser } = values
        const objectBody = {
            userlogin,
            locationLogin: location,
            depNo: depNo || "",
            subDepNo: "",
            statusMainCode: statusMainCode || "",
            startDate: startDate ? startDate.format("YYYY-MM-DD") : "",
            toDate: toDate ? toDate.format("YYYY-MM-DD") : "",
            report: "report",
            statusWorker: statusWorker || "",
            currentStatus: "",
            statusProbation: "",
            type: "",
            location: values.location || "",
            company: company || "",
            searchUser: searchUser || "",
            pageType: "",
            contractExtension: "",
        }
        if (activeTab === 1) {
            searchProbationEmpNew(objectBody)
        } else {
            searchProbationEmpNewOut(objectBody)
        }
    };

    const onReset = () => {
        form.resetFields();
        setChangeValue({});
        setDataTableProbation([])
        setDataTableProbationOut([])
    };
    // [END] Handle Form Antd

    // [START] REST API
    const searchProbationEmpNew = async (objectRow) => {
        try {
            const { company, depNo, statusMainCode, statusWorker, startDate, toDate, searchUser } = objectRow
            const objectBody = {
                userlogin,
                locationLogin: location,
                depNo: depNo || "",
                subDepNo: "",
                statusMainCode: statusMainCode || "",
                startDate: startDate || "",
                toDate: toDate || "",
                report: "report",
                statusWorker: statusWorker || "",
                currentStatus: "",
                statusProbation: "",
                type: "",
                location: objectRow.location || "",
                company: company || "",
                searchUser: searchUser || "",
                pageType: "",
                contractExtension: "",
            }
            setLoadingScreen(true);
            const response = await POST(POST_SELECT_DASHBOARD_PROBATION, objectBody);
            const { result } = response;
            let mapResult = [];
            if (result.length !== 0) {
                mapResult = result.map((row, index) => ({
                    key: index,
                    ...row,
                    StartDateJob: subStringDate(row.StartDateJob),
                    expProDate: subStringDate(row.expProDate),
                    probationEndDate: subStringDate(row.probationEndDate),
                    HR_ReceivedDate: subStringDate(row.HR_ReceivedDate),
                    date_HEA: subStringDate(row.date_HEA),
                    date_PVD: subStringDate(row.date_PVD),
                    date_SOS: subStringDate(row.date_SOS),
                }))
            } else {
                useNotification({ type: "info", title: "ไม่พบข้อมูล" })
            }
            setDataTableProbation(mapResult)
        } catch (err) {
            console.log("Error searchProbationEmpNew: ", err);
            useAlert({ type: "error", title: "เกิดข้อผิดพลาด", content: `${err.message}` })
        } finally {
            setLoadingScreen(false);
        }
    };

    const searchProbationEmpNewOut = async (objectRow) => {
        try {
            const { depNo, statusWorker, statusMainCode, searchUser, startDate, toDate } = objectRow
            const objectParam = {
                user_login: userlogin,
                locationID: "all",
                SegmentTxt: depNo || "all",
                statusWorker: statusWorker || "all",
                statusMainCode: statusMainCode || "all",
                txtUserSeaching: searchUser || "",
                StartDateJob: startDate || "",
                toDateJob: toDate || "",
            }
            setLoadingScreen(true);
            const response = await GET(GET_SLECT_DASHBOARD_PROBATION_OUT(objectParam));
            const { data } = response;
            let mapResult = [];
            if (data !== 0) {
                mapResult = data.map((row, index) => ({
                    key: index,
                    ...row,
                    date_HEA: subStringDate(row.date_HEA),
                    date_PVD: subStringDate(row.date_PVD),
                    date_SOS: subStringDate(row.date_SOS),
                }))
            } else {
                useNotification({ type: "info", title: "ไม่พบข้อมูล" })
            }
            setDataTableProbationOut(mapResult)
        } catch (err) {
            console.log("Error searchProbationEmpNewOut: ", err);
            useAlert({ type: "error", title: "เกิดข้อผิดพลาด", content: `${err.message}` })
        } finally {
            setLoadingScreen(false);
        }
    };
    // [END] REST API

    // [START] Functions
    const onChangeValue = (name, value) => {
        let setUndifined = {}
        let setName = {}
        switch (name) {
            case "company":
                setName = { companyCode: value }
                if (location === "FAC") {
                    setUndifined = toUndefined(["depNo", "searchUser"]);
                } else {
                    setUndifined = toUndefined(["location", "depNo", "searchUser"]);
                }
                break;
            case "location":
                setUndifined = toUndefined(["depNo", "searchUser"]);
                break;
            case "depNo":
                setUndifined = toUndefined(["searchUser"]);
                break;
        }
        form.setFieldsValue(setUndifined);
        setDataTableProbation([]);
        setDataTableProbationOut([]);
        setChangeValue((prev) => ({
            ...prev,
            ...setUndifined,
            ...setName,
            [name]: value,
        }));
    };

    // [END] Functions
    return (
        <>
            <Spin tip="กำลังโหลดข้อมูล ..." spinning={loadingScreen}>
                <Form form={form} onFinish={onFinish}  {...configForm} initialValues={{ location: location === "FAC" ? "FAC" : undefined }} >
                    <Card
                        title="รายงานพนักงานช่วงทดลองงาน"
                        style={{ marginBottom: 30 }}
                        extra={
                            <Space>
                                {useButton({ call: "search" })}
                                {useButton({
                                    call: "normal",
                                    fn: onReset,
                                    moreProps: { label: "ล้างข้อมูล" },
                                })}
                            </Space>
                        }
                    >
                        {useRenderFormItem({
                            constantComponent: ComponentSearchProbationEmpNew({
                                options: optionSearch,
                                handleChange: onChangeValue,
                                isDisable: { location: location === "FAC" ? true : false }
                            }),
                        })}
                    </Card>
                    <Card style={{ marginBottom: 30 }}>
                        <WorkingProcess pathname={"/dashboardProbationEmpContract"} />
                    </Card>
                    <Card>
                        <Tabs
                            defaultActiveKey="1"
                            onChange={(activeKey) => {
                                setActiveTab(+activeKey)
                                if (+activeKey === 1) {
                                    setDataTableProbationOut([]);
                                    searchProbationEmpNew(ChangeValue);
                                } else {
                                    setDataTableProbation([]);
                                    searchProbationEmpNewOut(ChangeValue);
                                }
                            }}
                            tabBarExtraContent={
                                useExportExcel({
                                    header: Header_ReportProbationEmpNew(activeTab),
                                    dataset: activeTab === 1 ? dataTableProbation : dataTableProbationOut,
                                    filename: "รายงานพนักงานช่วงทดลองงาน",
                                })
                            }
                        >
                            <Tabs.TabPane key={1} tab={"อยู่ระหว่างดำเนินการ - ผ่านทดลองงาน"}>
                                <CustomTableAtnd
                                    HeadTable={ColumnSearchProbationEmpNew}
                                    DataTable={dataTableProbation}
                                    moreProps={{
                                        scroll: {
                                            x: sumColumnWidth(ColumnSearchProbationEmpNew) || 2050,
                                            y: 500,
                                        },
                                    }}
                                />
                            </Tabs.TabPane>
                            <Tabs.TabPane key={2} tab={"ไม่ผ่านทดลองงาน"}>
                                <CustomTableAtnd
                                    HeadTable={ColumnSearchProbationEmpNew}
                                    DataTable={dataTableProbationOut}
                                    moreProps={{
                                        scroll: {
                                            x: sumColumnWidth(ColumnSearchProbationEmpNew) || 2050,
                                            y: 500,
                                        },
                                    }}
                                />
                            </Tabs.TabPane>
                        </Tabs>
                    </Card>
                </Form>
            </Spin>
        </>
    );
};

export default FormSearchProbationEmpNew;
