import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Modal, Space, Spin, Typography } from "antd";
import ReportPage from "../../components/global/pages/ReportPage";
import { formFilter } from "./forms/reportFilter";
import { FormColumn } from "./constants/tableColumn";
import { useFormState } from "./hook/useFormState";
import { useGetOptions } from "./hook/useGetOptions";
import { useGetDataSource } from "./hook/useGetDataSource";
import { useModal } from "./hook/useModal";
import { useGetFileDownload } from "./hook/useGetFileDownload";
import { CONNECT_API, USE_CONNECTION_MODE } from "../../components/connectAPI";
const { Paragraph, Link } = Typography;

const DashboardMonitorFileView = () => {
  const { defaultValues } = useFormState();
  const { control, setValue, getValues, handleSubmit } = useForm({
    defaultValues,
  });
  const [loadingScreen] = useState(false);
  const { dataDownload, onFetchDataDownload } =
  useGetFileDownload();
  useEffect(
    () => {
      callDownload();
     },
    [dataDownload],    
  );

  const callDownload = () => {
    if (dataDownload) {
      window.open(
        `${CONNECT_API[USE_CONNECTION_MODE].URL}${dataDownload}`,
        "_blank"
      );        
    }
  }  
  const getLinkDownload = async ({employeeNo}) => {
    await onFetchDataDownload({employeeNo})
    callDownload();
  }
  const {
    optionsEmp,
    optionsCompany,
    optionsDepartment,
    optionStatus,
    optionStatusGuarantee,
    loadingOption,
  } = useGetOptions();
  const { dataSource, onFetchDataSource, loadingDataSource } =
    useGetDataSource();
  const { isModalOpen, file, showModal, handleOk, handleCancel } = useModal();


  return (
    <Spin tip="กำลังโหลดข้อมูล ..." spinning={loadingScreen}>
      <ReportPage
        title={"ติดตามไฟล์เอกสาร (View Only)"}
        filterForm={formFilter({
          optionsCompany,
          optionsDepartment,
          optionsEmp,
          optionStatus,
          optionStatusGuarantee,
        })}
        filterLoading={
          loadingOption.company ||
          loadingOption.departmentNo ||
          loadingOption.employeeNo ||
          loadingOption.fileStatus
        }
        tableLoading={loadingDataSource}
        tableColumn={FormColumn({ showModal, getLinkDownload })}
        dataSource={dataSource}
        onSearchClick={handleSubmit(onFetchDataSource)}
        control={control}
        setValue={setValue}
        getValues={getValues}
      />

      <Space>
        <Modal
          title="ไฟล์แนบ"
          visible={isModalOpen}
          onOk={handleOk}
          onCancel={handleCancel}
          width={500}
        >
          <Paragraph>
            {file.map((e) => (
              <ul>
                <li>
                  <Link
                    href={`${CONNECT_API[USE_CONNECTION_MODE].URL}${e.filePath}`}
                    target="_blank"
                  >
                    {e.fileName}
                  </Link>
                </li>
              </ul>
            ))}
          </Paragraph>
        </Modal>
      </Space>
    </Spin>
  );
};

export default DashboardMonitorFileView;
