import { DatePicker, Form, Input, Modal, Radio, Typography } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useAlert } from "../screens/conponents/modal/CustomAlert";
import {
  PUT,
  PUT_CONFIRM_TASK_IN,
  PUT_CONFIRM_TASK_OUT,
  PUT_STAMP_TASK_IN,
  PUT_STAMP_TASK_OUT,
} from "../service";

export const ModalSelectTask = ({
  modalVisible,
  onCloseModal,
  selectedRows,
  modalTask,
  modalTitle,
  setModalVisible,
  activeTab,
}) => {
  const [form] = Form.useForm();
  const [data, setData] = useState({
    followUpDate: moment().format("YYYY-MM-DD"),
    note: "",
    status: "FOLLOW",
    prepareStatus: "CONFIRM",
  });

  const onChangeValue = (fieldName, value) => {
    setData({ ...data, [fieldName]: value });
  };

  const onSubmit = () => {
    Modal.confirm({
      title: "ยืนยันการบันทึก",
      onOk: () => {
        modalTask === "CONFIRM" ? onSetDataConfirm() : onSetData();
      },
    });
  };

  const onSetData = () => {
    const { followUpDate, note, status } = data;
    selectedRows.map((item, key) => {
      const objBody = {
        userId: item.userId,
        task: modalTask,
        followUpDate: moment(followUpDate).format("YYYY-MM-DD"),
        note: note,
        status: status,
      };
      return activeTab === "NEW"
        ? onPutStampTask(objBody, key)
        : onPutStampTaskOut(objBody, key);
    });
  };

  const onSetDataConfirm = () => {
    const { note, prepareStatus } = data;
    selectedRows.map((item, key) => {
      const objBody = {
        userId: item.userId,
        note: note,
        prepareStatus: prepareStatus,
      };
      return activeTab === "NEW"
        ? onPutConfirmTask(objBody, key)
        : onPutConfirmTaskOut(objBody, key);
    });
  };

  const onPutStampTask = async (objBody, key) => {
    try {
      const res = await PUT(PUT_STAMP_TASK_IN(), objBody);
      const { success } = res;
      if (success) {
        if (key === selectedRows.length - 1) {
          return Modal.success({
            title: "บันทึกสำเร็จ",
            onOk: () => {
              onCloseModal();
              setData({
                followUpDate: moment().format("YYYY-MM-DD"),
                note: "",
                status: "FOLLOW",
                prepareStatus: "CONFIRM",
              });
            },
          });
        }
      }
    } catch (err) {
      useAlert({ type: "catch", content: err.message });
      console.log("Error Put: ", err);
    } finally {
    }
  };

  const onPutStampTaskOut = async (objBody, key) => {
    try {
      const res = await PUT(PUT_STAMP_TASK_OUT(), objBody);
      const { success } = res;
      if (success) {
        if (key === selectedRows.length - 1) {
          return Modal.success({
            title: "บันทึกสำเร็จ",
            onOk: () => {
              onCloseModal();
              setData({
                followUpDate: moment().format("YYYY-MM-DD"),
                note: "",
                status: "FOLLOW",
                prepareStatus: "CONFIRM",
              });
            },
          });
        }
      }
    } catch (err) {
      useAlert({ type: "catch", content: err.message });
      console.log("Error Put: ", err);
    } finally {
    }
  };

  const onPutConfirmTask = async (objBody, key) => {
    try {
      const res = await PUT(PUT_CONFIRM_TASK_IN(), objBody);
      const { success } = res;
      if (success) {
        if (key === selectedRows.length - 1) {
          return Modal.success({
            title: "บันทึกสำเร็จ",
            onOk: () => {
              onCloseModal();
              setData({
                followUpDate: moment().format("YYYY-MM-DD"),
                note: "",
                status: "FOLLOW",
                prepareStatus: "CONFIRM",
              });
            },
          });
        }
      }
    } catch (err) {
      useAlert({ type: "catch", content: err.message });
      console.log("Error Put: ", err);
    } finally {
    }
  };

  const onPutConfirmTaskOut = async (objBody, key) => {
    try {
      const res = await PUT(PUT_CONFIRM_TASK_OUT(), objBody);
      const { success } = res;
      if (success) {
        if (key === selectedRows.length - 1) {
          return Modal.success({
            title: "บันทึกสำเร็จ",
            onOk: () => {
              onCloseModal();
            },
          });
        }
      }
    } catch (err) {
      useAlert({ type: "catch", content: err.message });
      console.log("Error Put: ", err);
    } finally {
    }
  };
  return (
    <Form
      form={form}
      labelCol={{
        span: 6,
      }}
      wrapperCol={{
        span: 18,
      }}
      layout="vertical"
    >
      <Modal
        visible={modalVisible}
        title={modalTitle}
        onCancel={() => {
          setData({
            followUpDate: moment().format("YYYY-MM-DD"),
            note: "",
            status: "FOLLOW",
          });
          setModalVisible(false);
        }}
        onOk={onSubmit}
      >
        <Form.Item
          label="รหัสพนักงาน"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography>
            <pre>
              {selectedRows.map((item, key) => {
                return (
                  item.userId + (key < selectedRows.length - 1 ? ", " : "")
                );
              })}
            </pre>
          </Typography>
        </Form.Item>
        {modalTask !== "CONFIRM" && (
          <Form.Item label="วันที่ Followup" name="followUpDate">
            <DatePicker
              style={{
                width: "100%",
              }}
              onChange={(date, dateString) => {
                onChangeValue("followUpDate", dateString);
              }}
              defaultValue={moment()}
              disabled={data.status === "COMPLETE" ? true : false}
            />
          </Form.Item>
        )}

        <Form.Item label="Note" name="note">
          <Input
            onChange={(e) => {
              onChangeValue("note", e.target.value);
            }}
          />
        </Form.Item>

        {modalTask !== "CONFIRM" ? (
          <Form.Item
            name="status"
            rules={[{ required: true, message: "Please select an option!" }]}
          >
            <Radio.Group
              defaultValue={"FOLLOW"}
              onChange={(e) => {
                onChangeValue("status", e.target.value);
              }}
              style={{ display: "flex", justifyContent: "center" }}
            >
              <Radio value="FOLLOW"> ติดตามงาน </Radio>
              <Radio value="COMPLETE"> เสร็จสิ้น </Radio>
            </Radio.Group>
          </Form.Item>
        ) : (
          <Form.Item
            name="prepareStatus"
            rules={[{ required: true, message: "Please select an option!" }]}
          >
            <Radio.Group
              defaultValue={"CONFIRM"}
              onChange={(e) => {
                onChangeValue("prepareStatus", e.target.value);
              }}
              style={{ display: "flex", justifyContent: "center" }}
            >
              <Radio value="CONFIRM"> เสร็จสิ้น </Radio>
              <Radio value="CANCEL"> ยกเลิก </Radio>
            </Radio.Group>
          </Form.Item>
        )}
      </Modal>
    </Form>
  );
};
