import { Button, Tooltip, Col } from "antd";
import styled from "styled-components";
import { buttonMap } from "./buttonMap";
import React from "react";

export const ButtonToolTip = (props) => {
  return (
    <Tooltip placement={props.placement || "topLeft"} title={props.title}>
      {renderButtonType({ isToolTip: true, ...props })}
    </Tooltip>
  );
};

export const ButtonTheme = (props) => {
  return renderButtonType(props);
};

const renderButtonType = ({
  title,
  bgColor,
  color,
  style = {},
  colorFill = true,
  onClick = () => console.warn("No Onclick in Btn" + title),
  isToolTip = false,
  disabled = false,
  useFor = "DEFAULT",
  shape = "round",
  size = "middle",
  ...props
}) => {
  const stuff = buttonMap[useFor] || buttonMap["DEFAULT"];
  const btnTitle = isToolTip ? "" : title || stuff.title;
  const btnBgColor = bgColor || stuff.bgColor;
  const btnColor = color || stuff.color;
  const btnStyle = colorFill
    ? style
    : { backgroundColor: "white", color: btnBgColor, ...style };
  const icon = stuff.icon;

  return (
    useFor && (
      <ButtonStyle
        shape={shape}
        size={size}
        bgColor={btnBgColor}
        color={btnColor}
        style={btnStyle}
        onClick={onClick}
        disabled={disabled}
        icon={icon}
        {...props}
      >
        {btnTitle}
      </ButtonStyle>
    )
  );
};

const ButtonStyle = styled(Button)`
  text-align-last: center;
  align-items: center;
  background-color: ${(props) => props.bgColor};
  border-color: ${(props) => props.bgColor};
  color: ${(props) => props.color || "#fff"};

  :hover,
  :active {
    background-color: ${(props) => props.bgColor};
    border-color: ${(props) => props.bgColor};
    opacity: ${(props) => (props.bgColor ? "0.5" : "unset")};
  }

  :focus {
    background-color: ${(props) => props.bgColor};
    border-color: ${(props) => props.bgColor};
    color: ${(props) => props.color || "#fff"};
  }
`;

export const ContainerButton = styled(Col)`
  text-align-last: ${(props) =>
    props.left ? "start" : props.center ? "center" : props.right ? "end" : ""};
`;
