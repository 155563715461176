
export const TEXT_BTN = {
    SEARCH_DASHBOARD_EVAC: "ค้นหา",
    SEARCH_SUMMARY_REPLY_EVAC: "ค้นหา",
    SEARCH_DASHBOARD_REVIEW: "ค้นหา",
    INSERT_EVALUTOR: "รีวิว",
    UPDATE_EVALUTOR: "แก้ไข",
    DELETE_EVALUTOR: "ลบ",
    VIEW_EVALUTOR: "ดูรายละเอียด",
    CONFIRM_EVALUTOR: "ยืนยันข้อมูล (แก้ไขไม่ได้)",
    DRAFT_EVALUTOR: "บันทึกร่าง",
    SUBMIT_EVALUTOR: "บันทึก",
    DELETE_REVIEWER: "ลบ"
}

export const FIELD_BTN = {
    SEARCH_DASHBOARD_EVAC: "SEARCH_DASHBOARD_EVAC",
    SEARCH_DASHBOARD_REVIEW: "SEARCH_DASHBOARD_REVIEW",
    INSERT_EVALUTOR: "INSERT_EVALUTOR",
    DELETE_EVALUTOR: "DELETE_EVALUTOR",
    UPDATE_EVALUTOR: "UPDATE_EVALUTOR",
    VIEW_EVALUTOR: "VIEW_EVALUTOR",
    SUBMIT_EVALUTOR: "SUBMIT_EVALUTOR",

    SUBMIT_REVIEWER: "SUBMIT_REVIEWER",
    DELETE_REVIEWER: "DELETE_REVIEWER",
    INSERT_EVAC: "INSERT_EVAC",
    VIEW_EVAC: "VIEW_EVAC",
}


export const TEXT_BTN_CUSTOM = ({ fieldBtn, status }) => {
    switch (fieldBtn) {
        case FIELD_BTN.SUBMIT_REVIEWER:
        case FIELD_BTN.SUBMIT_EVALUTOR:
            if (status) {
                return TEXT_BTN.CONFIRM_EVALUTOR
            } else {
                return TEXT_BTN.DRAFT_EVALUTOR
            }
        default:
            console.error('FIELD NAME NOT FOUND')
            break;
    }
} 