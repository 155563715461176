import { Select } from "antd";
import { optionProbationStatus, optionStatusRound } from "../../common/enum";
import { SetDate } from "../../common/setDate";
import React from "react";

export const validateBtn = (_initial) => {
	let _errorValidate = true;
	let _status = _initial.probationStatus;
	switch (_status) {
		case '1':
		case '2':
			if (_initial.probationGrade && (_initial.probationGrade !== "0")) {
				_errorValidate = false
			}
			break;
		case '3':
			if (_initial.remarkResult) {
				_errorValidate = false
			}
			break;
		default:
			if (_initial.mentorName && _initial.factor_1.some((el) => el.name !== null)) {
				_errorValidate = false
			}
			break;
	}
	return _errorValidate
}

export const validateGrade = (_initial) => {
	if (_initial) {
		switch (_initial.probationStatus) {
			case '1':
			case '2': return true
			default: return false
		}
	}
}

export const renderProbationStatusText = (status) => {
	switch (status) {
		case 1: return "ผ่านการทดลองงานแล้ว"
		case 2: return "ไม่ผ่านการทดลองงาน"
		case 3: return "อื่นๆ (ต่อช่วงทดลองงาน)"
		default: return "อยู่ในช่วงทดลองงาน"
	}
}

export const renderData = ({ objValue, optionWorker, handleChange }) => [
	[
		//row
		{ title: "ชื่อ-นามสกุล : ", value: objValue.StaffName || '' }, //column
		{ title: "รหัสพนักงาน : ", value: objValue.StaffCode || '' } //column
	],
	[
		//row
		{ title: "ตำแหน่ง : ", value: objValue.PositionNameEN || '' }, //column
		{ title: "แผนก : ", value: objValue.Department_Name || '' } //column
	],
	[
		//row
		{ title: "วันที่เริ่มงาน : ", value: SetDate(objValue.StartDateJob) }, //column
		{ title: "วันที่ครบทดลองงาน : ", value: SetDate(objValue.expProDate) } //column
	],
	[
		//row
		{
			title: "พี่เลี้ยงที่ได้รับมอบหมาย : ",
			name: "mentorCode",
			comp: "selectSearch",
			value: objValue.mentorName || '',
			options: optionWorker,
			onChange: (name, value) => handleChange('mentor', { mentorCode: value.key, mentorName: name })
		}, //column
		{
			title: "หัวหน้างาน/ผู้ประเมิน : ", value: objValue.BossName || ''
		} //column
	]
];

export const renderFactor = ({ initialData, handleChange, onRemoveItem, onAddItem, optionContractExtension, optionProbationGrade }) => [
	{
		label: "1. ความรอบรู้ในงาน การตัดสินใจ การก้ไขปัญหา (ระบุรายละเอียด)",
		labelButton: "เพิ่มหัวข้อย่อย",
		multiple: 'true',
		mainRound: 1,
		onRemoveItem,
		onAddItem
	},
	{
		label: "2. ความรับผิดชอบ ความขยัน",
		labelButton: "เพิ่มหัวข้อย่อย",// [Add Mulitiple ] If want to change form to mulitiple >> You must add  labelButton: "เพิ่มหัวข้อย่อย" to this line.
		multiple: 'false', // [Add Mulitiple ] If want to change form to mulitiple >> You must change "multiple: false" to "multiple: true" to this line.
		mainRound: 2,
	},
	{
		label: "3. การเรียนรู้ ความสนใจใฝ่รู้",
		multiple: 'false',
		mainRound: 3,
	},
	{
		label: "4. ความมีวินัย ตรงต่อเวลา ปฎิบัติตามกฎระเบียบบริษัท",
		multiple: 'false',
		mainRound: 4,
	},
	{
		label: "5. ทัศนคติ ต่องาน ผู้บังคับบัญชา บริษัท วัฒนธรรมองค์กร",
		multiple: 'false',
		mainRound: 5
	},
	{
		label: "6. บุคลิก การพูด การวางตัว",
		multiple: 'false',
		mainRound: 6
	},
	{
		label: "7. มนุษยสัมพันธ์ การประสานงาน การให้ความร่วมมือ",
		multiple: 'false',
		mainRound: 7
	},
	{
		label: "8. ความคิดริเริ่ม เสนอแนะ",
		multiple: 'false',
		mainRound: 8
	},
	// {
	//   label: "9. คามคิดริเริ่ม เสนอแนะ",
	//   multiple: 'false',
	//   mainRound: 9
	// },
	{
		label: "ความคิดเห็นเพิ่มเติม",
		name: 'remark',
		comp: "TextArea",
		value: initialData.remark || '',
		onChange: (fieldname, value) => handleChange(fieldname, value),
		row: 4
	},
	{
		label: "สรุปผลการประเมิน",
		valueOther: initialData.remarkResult || "",
		nameOther: "remarkResult",
		//disabled: initialData && (initialData.roundContract > 0 && initialData.RoundNow < 2) ? false : true,
		name: 'probationStatus',
		comp: 'probationStatus',
		value: initialData.probationStatus || '',
		onChangeOther: (fieldname, value) => handleChange(fieldname, value),
		onChange: (fieldname, value) => handleChange(fieldname, value),
		options: optionStatusRound(initialData.RoundNow)
	},
	{
		label: "ลักษณะการต่อสัญญาจ้าง",
		// valueOther: path(["contractExtension"], initialData) || "",
		// nameOther: "contractExtension",
		// disabled: initialData && initialData.RoundNow < 2 ? true : false,
		// disabled: initialData && (initialData.roundContract > 0 || initialData.RoundNow < 2) ? false : true,
		name: 'contractExtension',
		comp: 'contractExtension',
		value: initialData.contractExtension || '',
		// onChangeOther: (fieldname, value) => { self.handleChange(fieldname, value) },
		onChange: (fieldname, value) => handleChange(fieldname, value),
		options: optionContractExtension
	},
	{
		label: "สรุปผลการประเมินเกรด",
		// disabled: initialData && (initialData.RoundNow < 2 ) ? true : false,
		// disabled: initialData && (initialData.roundContract > 0 || initialData.RoundNow < 2) ? false : true,

		name: 'probationGrade',
		comp: 'probationGrade',
		value: initialData.probationGrade || '',
		// valueField: { value: "value", label: "label" },
		onChange: (fieldname, value) => handleChange(fieldname, value),
		options: optionProbationGrade
	},
	{
		label: "วันที่ผ่านทดลองงาน / วันที่ไม่ผ่านทดลองงาน",
		comp: "probationEndDate",
		span: 12,
		// disabled: initialData && initialData.RoundNow < 2 ? true : false,
		name: 'probationEndDate',
		value: initialData.probationEndDate || new Date(),
		// valueField: { value: "value", label: "label" },
		onChange: (fieldname, value) => handleChange(fieldname, value),
		// options: optionProbationGrade
	}
];

export const RenderFactorFacL1 = ({ initialData, handleChange, onRemoveItem, onAddItem, optionContractExtension, optionProbationGrade }) => [
	{
		label: "1. การประเมินพนักงานด้านหน้างานตาม Job Description",
		labelButton: "เพิ่มหัวข้อย่อย",
		multiple: 'true',
		mainRound: 1,
		onRemoveItem: onRemoveItem,
		onAddItem: async (indexRound) => onAddItem(indexRound),
	},
	{
		label: "2.ประเมินพนักงานด้านพฤติกรรม",
		labelButton: "เพิ่มหัวข้อย่อย",
		multiple: 'none',
	},
	{
		label: "2.1 ความรับผิดชอบต่อหน้าที่ และความตั้งใจในการทำงาน",
		multiple: 'false',
		mainRound: 2,
	},
	{
		label: "2.2 ปฏิบัติตามกฎ ระเบียบของบริษัทฯ และข้อตกลงต่างๆ ภายในหน่วยงาน",
		multiple: 'false', // [Add Mulitiple ] If want to change form to mulitiple >> You must change "multiple: false" to "multiple: true" to this line.
		mainRound: 3,
	},
	{
		label: "2.3 การแต่งกายสุภาพ และถูกต้องตามระเบียบบริษัทฯ",
		multiple: 'false', // [Add Mulitiple ] If want to change form to mulitiple >> You must change "multiple: false" to "multiple: true" to this line.
		mainRound: 4,
	},
	{
		label: "2.4 เข้าปฏิบัติงานตรงเวลา มาทำงานสม่ำเสมอ ทำโอทีตามที่ได้รับมอบหมาย",
		multiple: 'false', // [Add Mulitiple ] If want to change form to mulitiple >> You must change "multiple: false" to "multiple: true" to this line.
		mainRound: 5,
	},
	{
		label: "2.5 การเรียนรู้ มีความสนใจเรียนใฝ่รู้",
		multiple: 'false', // [Add Mulitiple ] If want to change form to mulitiple >> You must change "multiple: false" to "multiple: true" to this line.
		mainRound: 6,
	},
	{
		label: "2.6 มีมนุษยสัมพันธ์ ให้ความร่วมมือกิจกรรมต่างๆ และปรับตัวเข้ากับเพื่อนร่วมงานได้",
		multiple: 'false', // [Add Mulitiple ] If want to change form to mulitiple >> You must change "multiple: false" to "multiple: true" to this line.
		mainRound: 7,
	},
	{
		label: "2.7 ซักถามเมื่อมีข้อสงสัย หรือพบปัญหา",
		multiple: 'false', // [Add Mulitiple ] If want to change form to mulitiple >> You must change "multiple: false" to "multiple: true" to this line.
		mainRound: 8,
	},
	{
		label: "คะแนนเฉลี่ย",
		multiple: 'false', // [Add Mulitiple ] If want to change form to mulitiple >> You must change "multiple: false" to "multiple: true" to this line.
		mainRound: 12,
		average: true,
	},
	{
		label: "ความคิดเห็นเพิ่มเติม",
		name: 'remark',
		comp: "TextArea",
		value: initialData.remark,
		onChange: (fieldname, value) => handleChange(fieldname, value),
		row: 4
	},
	{
		label: "สรุปผลการประเมิน",
		valueOther: initialData.remarkResult || "",
		nameOther: "remarkResult",
		// disabled: initialData && (initialData.roundContract > 0 && initialData.RoundNow < 2) ? false : true,
		name: 'probationStatus',
		comp: 'probationStatus',
		value: initialData.probationStatus || '',
		onChangeOther: (fieldname, value) => handleChange(fieldname, value),
		onChange: (fieldname, value) => handleChange(fieldname, value),
		options: optionStatusRound(initialData.RoundNow)
	},
	{
		label: "ลักษณะการต่อสัญญาจ้าง",
		// valueOther: path(["contractExtension"], initialData) || "",
		// nameOther: "contractExtension",
		// disabled: initialData && initialData.RoundNow < 2 ? true : false,
		// disabled: initialData && (initialData.roundContract > 0 || initialData.RoundNow < 2) ? false : true,
		name: 'contractExtension',
		comp: 'contractExtension',
		value: initialData.contractExtension || '',
		// onChangeOther: (fieldname, value) => { self.handleChange(fieldname, value) },
		onChange: (fieldname, value) => handleChange(fieldname, value),
		options: optionContractExtension
	},
	{
		label: "สรุปผลการประเมินเกรด",
		// disabled: initialData && (initialData.RoundNow < 2 ) ? true : false,
		// disabled: initialData && (initialData.roundContract > 0 || initialData.RoundNow < 2) ? false : true,

		name: 'probationGrade',
		comp: 'probationGrade',
		value: initialData.probationGrade || '',
		// valueField: { value: "value", label: "label" },
		onChange: (fieldname, value) => handleChange(fieldname, value),
		options: optionProbationGrade
	}, {
		label: "วันที่ผ่านทดลองงาน / วันที่ไม่ผ่านทดลองงาน",
		comp: "probationEndDate",
		// disabled: initialData && initialData.RoundNow < 2 ? true : false,
		name: 'probationEndDate',
		value: initialData.probationEndDate || new Date(),
		// valueField: { value: "value", label: "label" },
		onChange: (fieldname, value) => handleChange(fieldname, value),
		// options: optionProbationGrade
	}
];

export const RenderFactorFacL2 = ({ initialData, handleChange, onRemoveItem, onAddItem, optionContractExtension, optionProbationGrade }) => [
	{
		label: "1. การประเมินพนักงานด้านหน้างานตาม Job Description",
		labelButton: "เพิ่มหัวข้อย่อย",
		multiple: 'true',
		mainRound: 1,
		onRemoveItem: onRemoveItem,
		onAddItem: async (indexRound) => onAddItem(indexRound),
	},
	{
		label: "2.ประเมินพนักงานด้านพฤติกรรม",
		labelButton: "เพิ่มหัวข้อย่อย",
		multiple: 'none',
	},
	{
		label: "2.1 ความรับผิดชอบต่อหน้าที่ และความตั้งใจในการทำงาน",
		multiple: 'false',
		mainRound: 2,
	},
	{
		label: "2.2 ปฏิบัติตามกฎ ระเบียบของบริษัทฯ และข้อตกลงต่างๆ ภายในหน่วยงาน",
		multiple: 'false', // [Add Mulitiple ] If want to change form to mulitiple >> You must change "multiple: false" to "multiple: true" to this line.
		mainRound: 3,
	},
	{
		label: "2.3 การแต่งกายสุภาพ และถูกต้องตามระเบียบบริษัทฯ",
		multiple: 'false', // [Add Mulitiple ] If want to change form to mulitiple >> You must change "multiple: false" to "multiple: true" to this line.
		mainRound: 4,
	},
	{
		label: "2.4 เข้าปฏิบัติงานตรงเวลา มาทำงานสม่ำเสมอ ทำโอทีตามที่ได้รับมอบหมาย",
		multiple: 'false', // [Add Mulitiple ] If want to change form to mulitiple >> You must change "multiple: false" to "multiple: true" to this line.
		mainRound: 5,
	},
	{
		label: "2.5 การเรียนรู้ มีความสนใจเรียนใฝ่รู้",
		multiple: 'false', // [Add Mulitiple ] If want to change form to mulitiple >> You must change "multiple: false" to "multiple: true" to this line.
		mainRound: 6,
	},
	{
		label: "2.6 มีมนุษยสัมพันธ์ ให้ความร่วมมือกิจกรรมต่างๆ และปรับตัวเข้ากับเพื่อนร่วมงานได้",
		multiple: 'false', // [Add Mulitiple ] If want to change form to mulitiple >> You must change "multiple: false" to "multiple: true" to this line.
		mainRound: 7,
	},
	{
		label: "2.7 ซักถามเมื่อมีข้อสงสัย หรือพบปัญหา",
		multiple: 'false', // [Add Mulitiple ] If want to change form to mulitiple >> You must change "multiple: false" to "multiple: true" to this line.
		mainRound: 8,
	},
	{
		label: "2.8 กล้าตัดสินใจในการแก้ไขปัญหา และกล้าเสนอแนะอย่างสร้างสรรค์",
		multiple: 'false', // [Add Mulitiple ] If want to change form to mulitiple >> You must change "multiple: false" to "multiple: true" to this line.
		mainRound: 9,
	},
	{
		label: "2.9 มีความเป็นผู้นำเป็น แบบอย่างให้เพื่อนร่วมงาน ",
		multiple: 'false', // [Add Mulitiple ] If want to change form to mulitiple >> You must change "multiple: false" to "multiple: true" to this line.
		mainRound: 10,
	},
	{
		label: "2.10 มีความอดทน และไม่ใช้อารมณ์ในการปฏิบัติงาน",
		multiple: 'false', // [Add Mulitiple ] If want to change form to mulitiple >> You must change "multiple: false" to "multiple: true" to this line.
		mainRound: 11,
	},
	{
		label: "คะแนนเฉลี่ย",
		multiple: 'false', // [Add Mulitiple ] If want to change form to mulitiple >> You must change "multiple: false" to "multiple: true" to this line.
		mainRound: 12,
		average: true,
	},
	{
		label: "ความคิดเห็นเพิ่มเติม",
		name: 'remark',
		comp: "TextArea",
		value: initialData.remark,
		onChange: (fieldname, value) => handleChange(fieldname, value),
		row: 4
	},
	{
		label: "สรุปผลการประเมิน",
		valueOther: initialData.remarkResult || "",
		nameOther: "remarkResult",
		// disabled: initialData && (initialData.roundContract > 0 && initialData.RoundNow < 2) ? false : true,
		name: 'probationStatus',
		comp: 'probationStatus',
		value: initialData.probationStatus || '',
		onChangeOther: (fieldname, value) => handleChange(fieldname, value),
		onChange: (fieldname, value) => handleChange(fieldname, value),
		options: optionStatusRound(initialData.RoundNow)
	},
	{
		label: "ลักษณะการต่อสัญญาจ้าง",
		// valueOther: path(["contractExtension"], initialData) || "",
		// nameOther: "contractExtension",
		// disabled: initialData && initialData.RoundNow < 2 ? true : false,
		// disabled: initialData && (initialData.roundContract > 0 || initialData.RoundNow < 2) ? false : true,
		name: 'contractExtension',
		comp: 'contractExtension',
		value: initialData.contractExtension || '',
		// onChangeOther: (fieldname, value) => { self.handleChange(fieldname, value) },
		onChange: (fieldname, value) => handleChange(fieldname, value),
		options: optionContractExtension
	},
	{
		label: "สรุปผลการประเมินเกรด",
		// disabled: initialData && (initialData.RoundNow < 2 ) ? true : false,
		// disabled: initialData && (initialData.roundContract > 0 || initialData.RoundNow < 2) ? false : true,

		name: 'probationGrade',
		comp: 'probationGrade',
		value: initialData.probationGrade || '',
		// valueField: { value: "value", label: "label" },
		onChange: (fieldname, value) => handleChange(fieldname, value),
		options: optionProbationGrade
	},
	{
		label: "วันที่ผ่านทดลองงาน / วันที่ไม่ผ่านทดลองงาน",
		comp: "probationEndDate",
		span: 12,
		// disabled: initialData && initialData.RoundNow < 2 ? true : false,
		name: 'probationEndDate',
		value: initialData.probationEndDate || new Date(),
		// valueField: { value: "value", label: "label" },
		onChange: (fieldname, value) => handleChange(fieldname, value),
		// options: optionProbationGrade
	}
];

export const RenderFactorFacL3 = ({ initialData, handleChange, onRemoveItem, onAddItem, optionContractExtension, optionProbationGrade }) => [
	{
		label: "1. การประเมินพนักงานด้านหน้างานตาม Job Description",
		labelButton: "เพิ่มหัวข้อย่อย",
		multiple: 'true',
		mainRound: 1,
		onRemoveItem: onRemoveItem,
		onAddItem: async (indexRound) => onAddItem(indexRound),
	},
	{
		label: "2.ประเมินพนักงานด้านพฤติกรรม",
		labelButton: "เพิ่มหัวข้อย่อย",
		multiple: 'none',
	},
	{
		label: "2.1 ความรับผิดชอบต่อหน้าที่ และความตั้งใจในการทำงาน",
		multiple: 'false',
		mainRound: 2,
	},
	{
		label: "2.2 เป็นแบบอย่าง ปฏิบัติตามกฎ ระเบียบของบริษัทฯ และข้อตกลงต่างๆ ภายในหน่วยงาน",
		multiple: 'false', // [Add Mulitiple ] If want to change form to mulitiple >> You must change "multiple: false" to "multiple: true" to this line.
		mainRound: 3,
	},
	{
		label: "2.3 เข้าปฏิบัติงานตรงเวลา รักษาเวลา ตรงต่อเวลานัดหมาย",
		multiple: 'false', // [Add Mulitiple ] If want to change form to mulitiple >> You must change "multiple: false" to "multiple: true" to this line.
		mainRound: 4,
	},
	{
		label: "2.4 เปิดรับการเรียนรู้ ยอมรับการเปลี่ยนแปลง และรับฟังความคิดเห็นของผู้อื่น",
		multiple: 'false', // [Add Mulitiple ] If want to change form to mulitiple >> You must change "multiple: false" to "multiple: true" to this line.
		mainRound: 5,
	},
	{
		label: "2.5 แก้ไขปัญหาในเชิงการป้องกัน เพื่อไม่เกิดซ้ำ",
		multiple: 'false', // [Add Mulitiple ] If want to change form to mulitiple >> You must change "multiple: false" to "multiple: true" to this line.
		mainRound: 6,
	},
	{
		label: "2.6 สื่อสาร ประสานงาน และ เจรจาต่อรองเก่ง",
		multiple: 'false', // [Add Mulitiple ] If want to change form to mulitiple >> You must change "multiple: false" to "multiple: true" to this line.
		mainRound: 7,
	},
	{
		label: "2.7 มีใจบริการ มีมนุษยสัมพันธ์ดี ให้ความร่วมมือกิจกรรมต่างๆ",
		multiple: 'false', // [Add Mulitiple ] If want to change form to mulitiple >> You must change "multiple: false" to "multiple: true" to this line.
		mainRound: 8,
	},
	{
		label: "2.8 กล้าตัดสินใจในการแก้ไขปัญหา และกล้าเสนอแนะอย่างสร้างสรรค์",
		multiple: 'false', // [Add Mulitiple ] If want to change form to mulitiple >> You must change "multiple: false" to "multiple: true" to this line.
		mainRound: 9,
	},
	{
		label: "2.9 มีความเป็นผู้นำเป็น มีความอดทน และไม่ใช้อารมณ์ในการปฏิบัติงาน",
		multiple: 'false', // [Add Mulitiple ] If want to change form to mulitiple >> You must change "multiple: false" to "multiple: true" to this line.
		mainRound: 10,
	},
	{
		label: "2.10 บริการจัดการผู้ใต้บังคับบัญชาอย่างเป็นกลาง และมีเหตุและผล",
		multiple: 'false', // [Add Mulitiple ] If want to change form to mulitiple >> You must change "multiple: false" to "multiple: true" to this line.
		mainRound: 11,
	},
	{
		label: "คะแนนเฉลี่ย",
		multiple: 'false', // [Add Mulitiple ] If want to change form to mulitiple >> You must change "multiple: false" to "multiple: true" to this line.
		mainRound: 12,
		average: true,
	},
	{
		label: "ความคิดเห็นเพิ่มเติม",
		name: 'remark',
		comp: "TextArea",
		value: initialData.remark,
		onChange: (fieldname, value) => handleChange(fieldname, value),
		row: 4
	},
	{
		label: "สรุปผลการประเมิน",
		valueOther: initialData.remarkResult || "",
		nameOther: "remarkResult",
		// disabled: initialData && (initialData.roundContract > 0 && initialData.RoundNow < 2) ? false : true,
		name: 'probationStatus',
		comp: 'probationStatus',
		value: initialData.probationStatus || '',
		onChangeOther: (fieldname, value) => handleChange(fieldname, value),
		onChange: (fieldname, value) => handleChange(fieldname, value),
		options: optionStatusRound(initialData.RoundNow)
	},
	{
		label: "ลักษณะการต่อสัญญาจ้าง",
		// valueOther: path(["contractExtension"], initialData) || "",
		// nameOther: "contractExtension",
		// disabled: initialData && initialData.RoundNow < 2 ? true : false,
		// disabled: initialData && (initialData.roundContract > 0 || initialData.RoundNow < 2) ? false : true,
		name: 'contractExtension',
		comp: 'contractExtension',
		value: initialData.contractExtension || '',
		// onChangeOther: (fieldname, value) => { self.handleChange(fieldname, value) },
		onChange: (fieldname, value) => handleChange(fieldname, value),
		options: optionContractExtension
	},
	{
		label: "สรุปผลการประเมินเกรด",
		// disabled: initialData && (initialData.RoundNow < 2 ) ? true : false,
		// disabled: initialData && (initialData.roundContract > 0 || initialData.RoundNow < 2) ? false : true,

		name: 'probationGrade',
		comp: 'probationGrade',
		value: initialData.probationGrade || '',
		// valueField: { value: "value", label: "label" },
		onChange: (fieldname, value) => handleChange(fieldname, value),
		options: optionProbationGrade
	},
	{
		label: "วันที่ผ่านทดลองงาน / วันที่ไม่ผ่านทดลองงาน",
		comp: "probationEndDate",
		span: 12,
		// disabled: initialData && initialData.RoundNow < 2 ? true : false,
		name: 'probationEndDate',
		value: initialData.probationEndDate || new Date(),
		// valueField: { value: "value", label: "label" },
		onChange: (fieldname, value) => handleChange(fieldname, value),
		// options: optionProbationGrade
	}
];