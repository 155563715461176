import React from "react";
import { tableColumn } from "../../../../components/global/tables/tableColumn";
import { PATHNAME } from "../../../../constants/enums/pathname";
import { ButtonTheme } from "../../../../components/global/buttons";

export const larkOnProcessColumn = () => {
  return [
    tableColumn({
      type: "TEXT",
      title: "บริษัท",
      key: "companyName",
    }),
    tableColumn({
      type: "DATE",
      title: "รหัสพนักงาน",
      key: "senderID",
      width: 150,
    }),
    tableColumn({
      type: "TEXT",
      title: "ชื่อ-สกุล ผู้ขอ",
      key: "senderName",
    }),
    tableColumn({
      type: "DATE",
      title: "วันที่ขอ",
      key: "requestDate",
      width: 100,
    }),
    tableColumn({
      type: "TEXT",
      title: "เอกสาร",
      key: "documentName",
    }),
    tableColumn({
      type: "CUSTOM_FLAT",
      title: "จัดการ",
      key: "docID",
      isAction: true,
      option: {
        fixed: "right",
        render: (text) => {
          return (
            <ButtonTheme
              useFor="VIEW"
              title="รับเอกสาร"
              onClick={() => {
                window.open(
                  `${PATHNAME.CERTIFICATE_REQUEST_DETAIL}?docID=${text}`,
                  "_blank"
                );
              }}
            />
          );
        },
      },
    }),
  ];
};
