import { Select } from "antd";
import { SetDate } from "../../common/setDate";
import React from "react";
import * as R from "ramda";
import { optionProbationStatus, optionProbationGrade, optionStatusRound } from "../../common/enum";

let detailFac = <span className="text-area"> <b>หมายเหตุ : </b> -  พนักงานที่ผ่านทดลองงานต้องได้คะแนนเฉลี่ยทั้งหมดไม่ต่ำกว่า 2.6 คะแนน และคะแนนส่วนที่ 2 มีคะแนนทุกข้อไม่ต่ำกว่า 3 คะแนน ( 3.7-4.0 = เกินความาดหวังมาก, 3.4-3.6 = เกินความคาดหวัง, 2.6-3.3 = ได้ตามความคาดหวัง , 2.0-2.5 = เกือบได้ตามความคาดหวัง, 0.00-1.99 = ต่ำกว่าความคาดหวัง) <br />
	-  สรุปผลการทดลองงานส่งให้ hr ภายใน 3 วัน หลังจากประเมินครั้งสุดท้าย  <br />
</span>

export const RenderData = ({ objectValue, optionWorker, handleChange }) => [
	[
		//row
		{ title: "ชื่อ-นามสกุล : ", value: objectValue.StaffName || '' }, //column
		{ title: "รหัสพนักงาน : ", value: objectValue.StaffCode || '' } //column
	],
	[
		//row
		{ title: "ตำแหน่ง : ", value: objectValue.PositionNameEN || '' }, //column
		{ title: "แผนก : ", value: objectValue.Department_Name || '' } //column
	],
	[
		//row
		{ title: "วันที่เริ่มงาน : ", value: SetDate(objectValue.StartDateJob || '') }, //column
		{ title: "วันที่ครบทดลองงาน : ", value: SetDate(objectValue.expProDate || '') } //column
	],
	[
		//row
		{
			title: "พี่เลี้ยงที่ได้รับมอบหมาย : ",
			name: "mentorCode",
			comp: "selectSearch",
			value: objectValue.mentorName,
			options: optionWorker,
			onChange: (name, value) => handleChange('mentor', { mentorCode: value.key, mentorName: name })
		}, //column
		{
			title: "หัวหน้างาน/ผู้ประเมิน : ", value: objectValue.BossName || ''
		} //column
	]
];

export const RenderFactor = ({ initialData, handleChange, onRemoveItem, onAddItem }) => [
	{
		label: "1. ความรอบรู้ในงาน การตัดสินใจ การก้ไขปัญหา (ระบุรายละเอียด)",
		labelButton: "เพิ่มหัวข้อย่อย",
		multiple: 'true',
		mainRound: 1,
		onRemoveItem: onRemoveItem,
		onAddItem: async (indexRound) => onAddItem(indexRound),
	},
	{
		label: "2. ความรับผิดชอบ ความขยัน",
		labelButton: "เพิ่มหัวข้อย่อย",// [Add Mulitiple ] If want to change form to mulitiple >> You must add  labelButton: "เพิ่มหัวข้อย่อย" to this line.
		multiple: 'false', // [Add Mulitiple ] If want to change form to mulitiple >> You must change "multiple: false" to "multiple: true" to this line.
		mainRound: 2,
	},
	{
		label: "3. การเรียนรู้ ความสนใจใฝ่รู้",
		multiple: 'false',
		mainRound: 3,
	},
	{
		label: "4. ความมีวินัย ตรงต่อเวลา ปฎิบัติตามกฎระเบียบบริษัท",
		multiple: 'false',
		mainRound: 4,
	},
	{
		label: "5. ทัศนคติ ต่องาน ผู้บังคับบัญชา บริษัท วัฒนธรรมองค์กร",
		multiple: 'false',
		mainRound: 5
	},
	{
		label: "6. บุคลิก การพูด การวางตัว",
		multiple: 'false',
		mainRound: 6
	},
	{
		label: "7. มนุษยสัมพันธ์ การประสานงาน การให้ความร่วมมือ",
		multiple: 'false',
		mainRound: 7
	},
	{
		label: "8. ความคิดริเริ่ม เสนอแนะ",
		multiple: 'false',
		mainRound: 8
	},
	{
		label: "ความคิดเห็นเพิ่มเติม",
		name: 'remark',
		comp: "TextArea",
		value: initialData.remark || '',
		onChange: (fieldname, value) => handleChange(fieldname, value),
		row: 4
	},
	{
		label: "สรุปผลการประเมิน",
		valueOther: initialData.remarkResult || "",
		nameOther: "remarkResult",
		disabled: initialData && initialData.RoundNow < 2 ? true : false,
		name: 'probationStatus',
		comp: "RadioGroup",
		value: initialData.probationStatus,
		onChangeOther: (fieldname, value) => handleChange(fieldname, value),
		onChange: (fieldname, value) => handleChange(fieldname, value),
		options: optionStatusRound(initialData.RoundNow)

	},
	{
		label: "สรุปผลการประเมินเกรด",
		disabled: initialData && initialData.RoundNow < 2 ? true : false,
		name: 'probationGrade',
		comp: "SelectInput",
		value: initialData.probationGrade,
		valueField: { value: "value", label: "label" },
		onChange: (fieldname, value) => handleChange(fieldname, value),
		options: optionProbationGrade
	},
	{
		label: "วันที่ผ่านทดลองงาน / วันที่ไม่ผ่านทดลองงาน",
		comp: "DATE_PICKER",
		span: 12,
		// disabled: initialData && initialData.RoundNow < 2 ? true : false,
		name: 'probationEndDate',
		value: initialData.probationEndDate || new Date(),
		// valueField: { value: "value", label: "label" },
		onChange: (fieldname, value) => handleChange(fieldname, value),
		// options: optionProbationGrade
	}
];

export const RenderFactorFacL1 = ({ initialData, handleChange, onRemoveItem, onAddItem }) => [
	{
		label: "1. การประเมินพนักงานด้านหน้างานตาม Job Description",
		labelButton: "เพิ่มหัวข้อย่อย",
		multiple: 'true',
		mainRound: 1,
		onRemoveItem: onRemoveItem,
		onAddItem: async (indexRound) => onAddItem(indexRound),
	},
	{
		label: "2.ประเมินพนักงานด้านพฤติกรรม",
		labelButton: "เพิ่มหัวข้อย่อย",
		multiple: 'none',
	},
	{
		label: "2.1 ความรับผิดชอบต่อหน้าที่ และความตั้งใจในการทำงาน",
		multiple: 'false',
		mainRound: 2,
	},
	{
		label: "2.2 ปฏิบัติตามกฎ ระเบียบของบริษัทฯ และข้อตกลงต่างๆ ภายในหน่วยงาน",
		multiple: 'false', // [Add Mulitiple ] If want to change form to mulitiple >> You must change "multiple: false" to "multiple: true" to this line.
		mainRound: 3,
	},
	{
		label: "2.3 การแต่งกายสุภาพ และถูกต้องตามระเบียบบริษัทฯ",
		multiple: 'false', // [Add Mulitiple ] If want to change form to mulitiple >> You must change "multiple: false" to "multiple: true" to this line.
		mainRound: 4,
	},
	{
		label: "2.4 เข้าปฏิบัติงานตรงเวลา มาทำงานสม่ำเสมอ ทำโอทีตามที่ได้รับมอบหมาย",
		multiple: 'false', // [Add Mulitiple ] If want to change form to mulitiple >> You must change "multiple: false" to "multiple: true" to this line.
		mainRound: 5,
	},
	{
		label: "2.5 การเรียนรู้ มีความสนใจเรียนใฝ่รู้",
		multiple: 'false', // [Add Mulitiple ] If want to change form to mulitiple >> You must change "multiple: false" to "multiple: true" to this line.
		mainRound: 6,
	},
	{
		label: "2.6 มีมนุษยสัมพันธ์ ให้ความร่วมมือกิจกรรมต่างๆ และปรับตัวเข้ากับเพื่อนร่วมงานได้",
		multiple: 'false', // [Add Mulitiple ] If want to change form to mulitiple >> You must change "multiple: false" to "multiple: true" to this line.
		mainRound: 7,
	},
	{
		label: "2.7 ซักถามเมื่อมีข้อสงสัย หรือพบปัญหา",
		multiple: 'false', // [Add Mulitiple ] If want to change form to mulitiple >> You must change "multiple: false" to "multiple: true" to this line.
		mainRound: 8,
	},
	{
		label: "คะแนนเฉลี่ย",
		multiple: 'false', // [Add Mulitiple ] If want to change form to mulitiple >> You must change "multiple: false" to "multiple: true" to this line.
		mainRound: 12,
		average: true,
	},
	{
		label: detailFac,
		multiple: 'none',
	},
	{
		label: "ความคิดเห็นเพิ่มเติม",
		name: 'remark',
		comp: "TextArea",
		value: initialData.remark,
		onChange: (fieldname, value) => handleChange(fieldname, value),
		row: 4
	},
	{
		label: "สรุปผลการประเมิน",
		valueOther: initialData.remarkResult || "",
		nameOther: "remarkResult",
		disabled: initialData && initialData.RoundNow < 2 ? true : false,
		name: 'probationStatus',
		comp: "RadioGroup",
		value: initialData.probationStatus,
		onChangeOther: (fieldname, value) => handleChange(fieldname, value),
		onChange: (fieldname, value) => handleChange(fieldname, value),
		options: optionStatusRound(initialData.RoundNow)

	},
	{
		label: "สรุปผลการประเมินเกรด",
		disabled: initialData && initialData.RoundNow < 2 ? true : false,
		name: 'probationGrade',
		comp: "SelectInput",
		value: initialData.probationGrade,
		valueField: { value: "value", label: "label" },
		onChange: (fieldname, value) => handleChange(fieldname, value),
		options: optionProbationGrade
	},
	{
		label: "วันที่ผ่านทดลองงาน / วันที่ไม่ผ่านทดลองงาน",
		comp: "DATE_PICKER",
		span: 12,
		// disabled: initialData && initialData.RoundNow < 2 ? true : false,
		name: 'probationEndDate',
		value: initialData.probationEndDate || new Date(),
		// valueField: { value: "value", label: "label" },
		onChange: (fieldname, value) => handleChange(fieldname, value),
		// options: optionProbationGrade
	}
];

export const RenderFactorFacL2 = ({ initialData, handleChange, onRemoveItem, onAddItem }) => [
	{
		label: "1. การประเมินพนักงานด้านหน้างานตาม Job Description",
		labelButton: "เพิ่มหัวข้อย่อย",
		multiple: 'true',
		mainRound: 1,
		onRemoveItem: onRemoveItem,
		onAddItem: async (indexRound) => onAddItem(indexRound),
	},
	{
		label: "2.ประเมินพนักงานด้านพฤติกรรม",
		labelButton: "เพิ่มหัวข้อย่อย",
		multiple: 'none',
	},
	{
		label: "2.1 ความรับผิดชอบต่อหน้าที่ และความตั้งใจในการทำงาน",
		multiple: 'false',
		mainRound: 2,
	},
	{
		label: "2.2 ปฏิบัติตามกฎ ระเบียบของบริษัทฯ และข้อตกลงต่างๆ ภายในหน่วยงาน",
		multiple: 'false', // [Add Mulitiple ] If want to change form to mulitiple >> You must change "multiple: false" to "multiple: true" to this line.
		mainRound: 3,
	},
	{
		label: "2.3 การแต่งกายสุภาพ และถูกต้องตามระเบียบบริษัทฯ",
		multiple: 'false', // [Add Mulitiple ] If want to change form to mulitiple >> You must change "multiple: false" to "multiple: true" to this line.
		mainRound: 4,
	},
	{
		label: "2.4 เข้าปฏิบัติงานตรงเวลา มาทำงานสม่ำเสมอ ทำโอทีตามที่ได้รับมอบหมาย",
		multiple: 'false', // [Add Mulitiple ] If want to change form to mulitiple >> You must change "multiple: false" to "multiple: true" to this line.
		mainRound: 5,
	},
	{
		label: "2.5 การเรียนรู้ มีความสนใจเรียนใฝ่รู้",
		multiple: 'false', // [Add Mulitiple ] If want to change form to mulitiple >> You must change "multiple: false" to "multiple: true" to this line.
		mainRound: 6,
	},
	{
		label: "2.6 มีมนุษยสัมพันธ์ ให้ความร่วมมือกิจกรรมต่างๆ และปรับตัวเข้ากับเพื่อนร่วมงานได้",
		multiple: 'false', // [Add Mulitiple ] If want to change form to mulitiple >> You must change "multiple: false" to "multiple: true" to this line.
		mainRound: 7,
	},
	{
		label: "2.7 ซักถามเมื่อมีข้อสงสัย หรือพบปัญหา",
		multiple: 'false', // [Add Mulitiple ] If want to change form to mulitiple >> You must change "multiple: false" to "multiple: true" to this line.
		mainRound: 8,
	},
	{
		label: "2.8 กล้าตัดสินใจในการแก้ไขปัญหา และกล้าเสนอแนะอย่างสร้างสรรค์",
		multiple: 'false', // [Add Mulitiple ] If want to change form to mulitiple >> You must change "multiple: false" to "multiple: true" to this line.
		mainRound: 9,
	},
	{
		label: "2.9 มีความเป็นผู้นำเป็น แบบอย่างให้เพื่อนร่วมงาน ",
		multiple: 'false', // [Add Mulitiple ] If want to change form to mulitiple >> You must change "multiple: false" to "multiple: true" to this line.
		mainRound: 10,
	},
	{
		label: "2.10 มีความอดทน และไม่ใช้อารมณ์ในการปฏิบัติงาน",
		multiple: 'false', // [Add Mulitiple ] If want to change form to mulitiple >> You must change "multiple: false" to "multiple: true" to this line.
		mainRound: 11,
	},
	{
		label: "คะแนนเฉลี่ย",
		multiple: 'false', // [Add Mulitiple ] If want to change form to mulitiple >> You must change "multiple: false" to "multiple: true" to this line.
		mainRound: 12,
		average: true,
	},
	{
		label: detailFac,
		multiple: 'none',
	},
	{
		label: "ความคิดเห็นเพิ่มเติม",
		name: 'remark',
		comp: "TextArea",
		value: initialData.remark,
		onChange: (fieldname, value) => handleChange(fieldname, value),
		row: 4
	},
	{
		label: "สรุปผลการประเมิน",
		valueOther: initialData.remarkResult || "",
		nameOther: "remarkResult",
		disabled: initialData && initialData.RoundNow < 2 ? true : false,
		name: 'probationStatus',
		comp: "RadioGroup",
		value: initialData.probationStatus,
		onChangeOther: (fieldname, value) => handleChange(fieldname, value),
		onChange: (fieldname, value) => handleChange(fieldname, value),
		options: optionStatusRound(initialData.RoundNow)


	},
	{
		label: "สรุปผลการประเมินเกรด",
		disabled: initialData && initialData.RoundNow < 2 ? true : false,
		name: 'probationGrade',
		span: 12,
		comp: "SelectInput",
		value: initialData.probationGrade,
		valueField: { value: "value", label: "label" },
		onChange: (fieldname, value) => handleChange(fieldname, value),
		options: optionProbationGrade
	},
	{
		label: "วันที่ผ่านทดลองงาน / วันที่ไม่ผ่านทดลองงาน",
		comp: "DATE_PICKER",
		span: 12,
		// disabled: initialData && initialData.RoundNow < 2 ? true : false,
		name: 'probationEndDate',
		value: initialData.probationEndDate || new Date(),
		// valueField: { value: "value", label: "label" },
		onChange: (fieldname, value) => handleChange(fieldname, value),
		// options: optionProbationGrade
	}
];

export const RenderFactorFacL3 = ({ initialData, handleChange, onRemoveItem, onAddItem }) => [
	{
		label: "1. การประเมินพนักงานด้านหน้างานตาม Job Description",
		labelButton: "เพิ่มหัวข้อย่อย",
		multiple: 'true',
		mainRound: 1,
		onRemoveItem: onRemoveItem,
		onAddItem: async (indexRound) => onAddItem(indexRound),
	},
	{
		label: "2.ประเมินพนักงานด้านพฤติกรรม",
		labelButton: "เพิ่มหัวข้อย่อย",
		multiple: 'none',
	},
	{
		label: "2.1 ความรับผิดชอบต่อหน้าที่ และความตั้งใจในการทำงาน",
		multiple: 'false',
		mainRound: 2,
	},
	{
		label: "2.2 เป็นแบบอย่าง ปฏิบัติตามกฎ ระเบียบของบริษัทฯ และข้อตกลงต่างๆ ภายในหน่วยงาน",
		multiple: 'false', // [Add Mulitiple ] If want to change form to mulitiple >> You must change "multiple: false" to "multiple: true" to this line.
		mainRound: 3,
	},
	{
		label: "2.3 เข้าปฏิบัติงานตรงเวลา รักษาเวลา ตรงต่อเวลานัดหมาย",
		multiple: 'false', // [Add Mulitiple ] If want to change form to mulitiple >> You must change "multiple: false" to "multiple: true" to this line.
		mainRound: 4,
	},
	{
		label: "2.4 เปิดรับการเรียนรู้ ยอมรับการเปลี่ยนแปลง และรับฟังความคิดเห็นของผู้อื่น",
		multiple: 'false', // [Add Mulitiple ] If want to change form to mulitiple >> You must change "multiple: false" to "multiple: true" to this line.
		mainRound: 5,
	},
	{
		label: "2.5 แก้ไขปัญหาในเชิงการป้องกัน เพื่อไม่เกิดซ้ำ",
		multiple: 'false', // [Add Mulitiple ] If want to change form to mulitiple >> You must change "multiple: false" to "multiple: true" to this line.
		mainRound: 6,
	},
	{
		label: "2.6 สื่อสาร ประสานงาน และ เจรจาต่อรองเก่ง",
		multiple: 'false', // [Add Mulitiple ] If want to change form to mulitiple >> You must change "multiple: false" to "multiple: true" to this line.
		mainRound: 7,
	},
	{
		label: "2.7 มีใจบริการ มีมนุษยสัมพันธ์ดี ให้ความร่วมมือกิจกรรมต่างๆ",
		multiple: 'false', // [Add Mulitiple ] If want to change form to mulitiple >> You must change "multiple: false" to "multiple: true" to this line.
		mainRound: 8,
	},
	{
		label: "2.8 กล้าตัดสินใจในการแก้ไขปัญหา และกล้าเสนอแนะอย่างสร้างสรรค์",
		multiple: 'false', // [Add Mulitiple ] If want to change form to mulitiple >> You must change "multiple: false" to "multiple: true" to this line.
		mainRound: 9,
	},
	{
		label: "2.9 มีความเป็นผู้นำเป็น มีความอดทน และไม่ใช้อารมณ์ในการปฏิบัติงาน",
		multiple: 'false', // [Add Mulitiple ] If want to change form to mulitiple >> You must change "multiple: false" to "multiple: true" to this line.
		mainRound: 10,
	},
	{
		label: "2.10 บริการจัดการผู้ใต้บังคับบัญชาอย่างเป็นกลาง และมีเหตุและผล",
		multiple: 'false', // [Add Mulitiple ] If want to change form to mulitiple >> You must change "multiple: false" to "multiple: true" to this line.
		mainRound: 11,
	},
	{
		label: "คะแนนเฉลี่ย",
		multiple: 'false', // [Add Mulitiple ] If want to change form to mulitiple >> You must change "multiple: false" to "multiple: true" to this line.
		mainRound: 12,
		average: true,
	},
	{
		label: detailFac,
		multiple: 'none',
	},
	{
		label: "ความคิดเห็นเพิ่มเติม",
		name: 'remark',
		comp: "TextArea",
		value: initialData.remark,
		onChange: (fieldname, value) => handleChange(fieldname, value),
		row: 4
	},
	{
		label: "สรุปผลการประเมิน",
		valueOther: initialData.remarkResult || "",
		nameOther: "remarkResult",
		disabled: initialData && initialData.RoundNow < 2 ? true : false,
		name: 'probationStatus',
		comp: "RadioGroup",
		value: initialData.probationStatus,
		onChangeOther: (fieldname, value) => handleChange(fieldname, value),
		onChange: (fieldname, value) => handleChange(fieldname, value),
		options: optionStatusRound(initialData.RoundNow)
	},
	{
		label: "สรุปผลการประเมินเกรด",
		disabled: initialData && initialData.RoundNow < 2 ? true : false,
		name: 'probationGrade',
		comp: "SelectInput",
		value: initialData.probationGrade,
		valueField: { value: "value", label: "label" },
		onChange: (fieldname, value) => handleChange(fieldname, value),
		options: optionProbationGrade
	},
	{
		label: "วันที่ผ่านทดลองงาน / วันที่ไม่ผ่านทดลองงาน",
		comp: "DATE_PICKER",
		span: 12,
		// disabled: initialData && initialData.RoundNow < 2 ? true : false,
		name: 'probationEndDate',
		value: initialData.probationEndDate || new Date(),
		// valueField: { value: "value", label: "label" },
		onChange: (fieldname, value) => handleChange(fieldname, value),
		// options: optionProbationGrade
	}
];


export const filterDashboardProbation = ({ txtOption, initialFilter, handlechange, optionEmployee, optionSegment, optionSumProbation, functionButton }) => [
	[
		{
			size: 4,
			name: "SegmentTxt",
			id: "SegmentTxt",
			label: "แผนก/กลุ่ม",
			comp: "SelectSearch",
			options: optionSegment || [],
			value: initialFilter.SegmentTxt || '',
			// valueField: txtOption,
			onChange: (fieldname, value) => handlechange('SegmentTxt', value)
		}, {
			size: 4,
			name: "statusMainCode",
			label: "สถานะการประเมิน",
			comp: "SelectSearch",
			options: optionSumProbation || [],
			value: initialFilter.statusMainCode || '',
			// valueField: { value: "value", label: "label" },
			onChange: (fieldname, value) => handlechange('statusMainCode', value)
		},
		{
			size: 4,
			name: "StartDateJob",
			label: "วันที่เริ่มทำงาน",
			comp: "DateInput",
			placeholder: "เลือกวันที่เริ่มทำงาน",
			selected: initialFilter.StartDateJob,
			onChange: (fieldname, value) => handlechange('StartDateJob', value)
		}, {
			size: 4,
			name: "ToDateJob",
			label: "วันที่สิ้นสุดทำงาน",
			comp: "DateInput",
			placeholder: "เลือกวันที่สิ้นสุดทำงาน",
			selected: initialFilter.ToDateJob,
			onChange: (fieldname, value) => handlechange('ToDateJob', value)
		},
		{
			size: 4,
			name: "searchUser",
			id: "searchUser",
			label: "รายชื่อพนักงาน",
			comp: "SelectSearch",
			options: optionEmployee || [],
			value: initialFilter.searchUser || '',
			// valueField: txtOption,
			onChange: (fieldname, value) => handlechange('searchUser', value)
		},
		{
			size: 4,
			name: "search",
			label: "SEARCH",
			comp: "ButtonTheme",
			className: "btn-right cs_btn",
			styleCol: {
				placeSelf: 'center',
				marginTop: '23px'
			},
			onClick: () => functionButton("search")
		}]
]