import React from "react";

import "bootstrap/dist/css/bootstrap.css";
import { Button, FormGroup } from "reactstrap";
import { colors } from "../../themes";

export const ButtonTheme = ({ name = "", onClick, label = "", htmltype = '', style, disabled, color }) => {
  return (
    <FormGroup style={style}>
      <Button name={name} htmltype={htmltype} color="info" onClick={onClick} disabled={disabled}
        style={{
          border: '0px',
          backgroundColor: disabled ? colors.buttonDisabled : color
        }}>
        {label}
      </Button>
    </FormGroup>
  );
};
