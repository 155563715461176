import React from "react";
import { useController } from "react-hook-form";
import { Input as InputAntd, AutoComplete as AutoCompleteAntD } from "antd";
import { Required, RowComponent } from "../../../../styles/global-style";
import { TextSmall } from "../../text";
const { Fragment } = React;

export const filterOptionAll = (input, option) => {
  const arrInput = input.split(" ");
  for (const element of arrInput) {
    const isFound =
      option.value.toLowerCase().indexOf(element.toLowerCase()) >= 0 ||
      element == "";
    if (!isFound) return false;
  }
  return true;
};

export const InputLabel = ({ control, item, handleChange }) => {
  const {
    ref,
    rules,
    name,
    defaultValue,
    label,
    placeholder,
    disabled,
    onEnter,
    itemHandleChange,
  } = item;

  const { field, fieldState } = useController({
    control,
    name,
    rules,
    defaultValue,
  });
  const { error } = fieldState;
  const { value } = field;
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      const { value: v } = event.target;
      onEnter && onEnter(v, true);
    }
  };

  return (
    <Fragment>
      <RowComponent>
        {label && <TextSmall text={label} />}
        {label && rules && rules.required && <Required>*</Required>}
      </RowComponent>
      <InputAntd
        id={ref}
        name={ref}
        value={value}
        disabled={disabled}
        onChange={(e) =>
          itemHandleChange ? itemHandleChange(e, item) : handleChange(e, item)
        }
        onKeyDown={(e) => handleKeyDown(e)}
        placeholder={placeholder}
        autoComplete={"off"}
      />
      {error && <TextSmall text={error.message} color="red" />}
    </Fragment>
  );
};

export const AutoComplete = ({ control, item, handleChange }) => {
  const {
    ref,
    rules,
    name,
    defaultValue,
    label,
    placeholder,
    disabled,
    onEnter,
    itemHandleChange,
    options = [],
  } = item;

  const { field, fieldState } = useController({
    control,
    name,
    rules,
    defaultValue,
  });
  const { error } = fieldState;
  const { value } = field;
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      const { value: v } = event.target;
      onEnter && onEnter(v, true);
    }
  };

  return (
    <Fragment>
      <RowComponent>
        {label && <TextSmall text={label} />}
        {label && rules && rules.required && <Required>*</Required>}
      </RowComponent>
      <AutoCompleteAntD
        id={ref}
        name={ref}
        value={value}
        disabled={disabled}
        options={options}
        style={{ width: "100%" }}
        onChange={(e) =>
          itemHandleChange ? itemHandleChange(e, item) : handleChange(e, item)
        }
        filterOption={filterOptionAll}
        onKeyDown={(e) => handleKeyDown(e)}
        placeholder={placeholder}
      />
      {error && <TextSmall text={error.message} color="red" />}
    </Fragment>
  );
};

export const InputNumber = ({ control, item, handleChange }) => {
  const {
    ref,
    rules,
    name,
    defaultValue,
    label,
    placeholder,
    disabled,
    onEnter,
    itemHandleChange,
  } = item;

  const { field, fieldState } = useController({
    control,
    name,
    rules,
    defaultValue,
  });
  const { error } = fieldState;
  const { value } = field;
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      const { value: v } = event.target;
      onEnter && onEnter(v, true);
    }
  };

  return (
    <Fragment>
      <RowComponent>
        {label && <TextSmall text={label} />}
        {label && rules && rules.required && <Required>*</Required>}
      </RowComponent>
      <InputAntd
        id={ref}
        name={ref}
        value={value}
        type="number"
        disabled={disabled}
        onChange={(e) =>
          itemHandleChange ? itemHandleChange(e, item) : handleChange(e, item)
        }
        onKeyDown={(e) => handleKeyDown(e)}
        placeholder={placeholder}
        autoComplete={"off"}
      />
      {error && <TextSmall text={error.message} color="red" />}
    </Fragment>
  );
};

export const InputArea = ({ control, item, handleChange }) => {
  const {
    ref,
    rules,
    name,
    defaultValue,
    label,
    placeholder,
    disabled,
    onEnter,
  } = item;

  const { field, fieldState } = useController({
    control,
    name,
    rules,
    defaultValue,
  });
  const { error } = fieldState;
  const { value } = field;
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      const { value: v } = event.target;
      onEnter && onEnter(v, true);
    }
  };

  return (
    <Fragment>
      <RowComponent>
        {label && <TextSmall text={label} />}
        {rules && rules.required && <Required>*</Required>}
      </RowComponent>
      <InputAntd.TextArea
        id={ref}
        name={ref}
        value={value}
        disabled={disabled}
        onChange={(e) => handleChange(e, item)}
        onKeyDown={(e) => handleKeyDown(e)}
        placeholder={placeholder}
        autoComplete={"off"}
      />
      {error && <TextSmall text={error.message} color="red" />}
    </Fragment>
  );
};
