import React from 'react'
import { BaseText } from '../text/index.jsx'
import { path } from 'ramda';

export * from "./funcValidate.jsx"

export function printDiv(divName) {

    const printableElements = document.getElementById(divName).innerHTML;
    document.body.innerHTML = printableElements;
    const oldPage = document.body.innerHTML;
    window.print();
    setTimeout(() => {
        window.location.reload()
        document.body.innerHTML = oldPage;
    }, 10)

    // const printableElements = document.getElementById(divName).innerHTML;
    // let newWindow = window.open("")
    // newWindow.document.body.innerHTML = printableElements;
    // const oldPage = newWindow.document.body.innerHTML;
    // newWindow.print();
    // newWindow.close()
    // newWindow.document.body.innerHTML = oldPage

    // let printContents = document.getElementById(divName);
    // let newWindow = window.open("")
    // newWindow.document.write(printContents.innerHTML)
    // newWindow.print()
    // newWindow.close()

}

export function isArray(_params) {
    if (Array.isArray(_params) && _params.length > 0) {
        return true
    } else {
        return false
    }
}


export function dynamicLabel({ label, result, backward }) {
    return (
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <BaseText
                size={14}
                label={`${label}`} />
            <BaseText
                size={14}
                success={result > 50}
                error={result < 50}
                label={(backward) ? `${100 - result}%` : `${result}%`}
            />
        </div>
    )
}

export function renderWithLabel({ label }) {
    if (typeof label === 'string') {
        switch (label) {
            case 'volume': return 'ปริมาณงาน'
            case 'quality': return 'คุณภาพ'
            case 'behavior': return 'พฤติกรรม '
            case 'Full': return 'พนักงานประจำ '
            case 'Daily': return 'พนักงานรายวัน '
            default:
                break;
        }
    }
}

export function renderWithOption(_params, _options) {
    // console.log('renderWithOption', _params, _options)
    if (Array.isArray(_options) && _options.length > 0) {
        let result = _options.filter(val => (val.optionValue === _params))
        return (result.length > 0 ? path([0, 'optionLabel'], result) : '-')
        // console.l.og(result)
    }
}


export function timeDiff(_endDate, _startDate) {
    // console.log(_endDate, _startDate)
    let str = new Date(_startDate)
    let end = new Date(_endDate)
    // console.log(end, str)

    let diff = (((end - str) / 1000) / 60);
    diff /= 60
    //console.log(Math.abs(diff))
    if (isNaN(diff) || diff < 0) {
        return 0
    } else {
        return Math.abs(diff);
    }

}


export function baseLabel(_type, _text, _required, _align) {
    switch (_type) {
        case 'TITLE':
            return (
                <div style={{ fontSize: 14, textAlign: _align || 'left' }}>
                    <span style={{ fontWeight: 'bold', paddingRight: 5 }}>
                        {`${_text.title || ''} : `}
                    </span>
                    <span>
                        {_text.label ? `${_text.label || '-'}` : ''}
                    </span>
                </div>
            )
        case 'TEXT':
            return (
                <div style={{ textAlign: _align || 'left', fontSize: 11, padding: 2, minHeight: 20 }}>
                    {`${_text.title || ''} ${_text.label || '-'}`}
                </div>
            )
        case 'HEADER':
            return (
                <div style={{ paddingBottom: 20, textAlign: _align || 'center' }}>
                    <span style={{ fontSize: 18, fontWeight: 'bold' }}>
                        {`${_text.title}`}
                    </span>
                </div>
            )
        default:
            break;
    }
}





export function calDateDiff(_values) {
    let m1 = new Date(`${_values.startDate || ''} ${_values.startTime || ''}`);
    let m2 = new Date(`${_values.endDate || ''} ${_values.endTime || ''}`);
    //console.log({ _values, m1, m2 })
    let result = (((+m1 - +m2) / 1000) / 60) / 60
    //console.log('nut', Math.abs(result))
    return Math.abs(result)
    // return Math.abs(Math.ceil(result))

}