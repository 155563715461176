import React, { Component } from "react";
import "../login.css";
import Cookies from "js-cookie";
import { Layout, Form, Row, Col, Input, Button, Modal, Spin } from "antd";
import { VERSION_TEXT } from "../constants/enums/enumCenter";
import { GET, POST } from "../service";
import { LockOutlined, LoginOutlined, UserOutlined } from "@ant-design/icons";

const encodeString = require("../extendFunction/encodeString");
const md5 = require("md5");
class login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingScreen: false,
    };
  }

  onFinish = async (value) => {
    // ! Old API
    // try {
    //   const { username, password } = value;
    //   const encodePass = encodeString.encode(password);
    //   const url = `/HRM_API/check_login?username=${username}&password=${encodePass}`;
    //   const response = await GET(url);
    //   //console.log("response", response);
    //   if (response) {
    //     const ID_User = encodeString.encode(response[0]["ID_User"]);
    //     const premission = encodeString.encode(response[0]["premission"]);
    //     const location = encodeString.encode(response[0]["Location"]);
    //     const obj = {
    //       ID_User,
    //       premission,
    //       location,
    //     };
    //     Cookies.set("cookie_session", obj, { expires: 1 });
    //     Cookies.set("premission", premission, { expires: 1 });
    //     window.location.reload();
    //   } else {
    //     Modal.error({
    //       title: "เข้าสู่ระบบล้มเหลว",
    //       content: `โปรดตรวจสอบ "ชื่อผู้ใช้งาน" และ "รหัสผ่าน" ใหม่อีกครั้ง`,
    //       onOk: () => null,
    //     });
    //   }
    // } catch (err) {
    //   console.log("Error Login Page: ", err);
    //   Modal.error({ title: `${err.message}` });
    // }
    // ! Old API

    try {
      this.setState({ loadingScreen: true });
      const { username, password } = value;
      const enCodePass = md5(password);
      const url = `/HRM_API/cent/verifyLogin`;
      const Object = {
        username,
        password: enCodePass,
        // username: "hr-admin",
        //password: "81dc9bdb52d04dc20036dbd8313ed055",
      };
      const response = await POST(url, Object);
      const { result } = response;
      if (result) {
        const {
          ID_User,
          Location,
          premission,
          Username,
          DepartmentNo,
          status,
          accessToken,
        } = result;
        const obj = {
          ID_User: encodeString.encode(ID_User),
          location: encodeString.encode(Location),
          premission: encodeString.encode(premission),
          Username: encodeString.encode(Username),
          DepartmentNo: encodeString.encode(DepartmentNo),
          status: encodeString.encode(status),
          token: encodeString.encode(accessToken),
        };
        if (!premission) {
          throw response;
        }
        Cookies.set("cookie_session", obj, { expires: 15 });
        Cookies.set("premission", premission, { expires: 15 });
        setTimeout(() => (window.location = "/"), 200);
      } else {
        this.setState({ loadingScreen: false });
      }
    } catch (err) {
      console.log("Error Login Page: ", err);
      Modal.error({
        title: `${err.message}`,
        onOk: () => this.setState({ loadingScreen: false }),
      });
    }
  };

  render() {
    const { loadingScreen } = this.state;
    return (
      <Spin tip="กำลังเข้าสู่ระบบ ..." spinning={loadingScreen}>
        <Layout style={{ backgroundColor: "#FFF" }}>
          <Layout.Header
            style={{
              color: "#fff",
              fontSize: "x-large",
              textAlign: "center",
              backgroundColor: "#3368df",
            }}
          >
            HR - PMS System
          </Layout.Header>
          <Layout.Content
            style={{ padding: 50, minHeight: `calc(100vh - 135px)` }}
          >
            <br />
            <span>
              <b>กรุณาเข้าสู่ระบบ (Please Login)</b>
            </span>
            <br />
            <br />
            {this._renderLoginForm()}
          </Layout.Content>
          <Layout.Footer
            style={{ textAlign: "center", backgroundColor: "#FFF" }}
          >
            {`Copyright © Zetta Soft Co.Ltd., | ${VERSION_TEXT}`}
          </Layout.Footer>
        </Layout>
      </Spin>
    );
  } // render

  _renderLoginForm = () => {
    return (
      <Form
        onFinish={this.onFinish}
        labelCol={{
          span: 24,
        }}
        wrapperCol={{
          span: 24,
        }}
      >
        <Row gutter={[16, 16]} justify="start" align="bottom">
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Form.Item
              label="ชื่อผู้ใช้งาน (Username)"
              name="username"
              rules={[
                {
                  required: true,
                  message:
                    "กรุณากรอก ชื่อผู้ใช้งาน (Username) | ตัวอย่าง : DPXXXXX, DTXXXXX, ZTXXXXX etc. |",
                },
              ]}
            >
              <Input
                allowClear
                placeholder={`กรุณากรอก ชื่อผู้ใช้งาน (Username)`}
                prefix={<UserOutlined />}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Form.Item
              label="รหัสผ่าน (Password)"
              name="password"
              rules={[
                { required: true, message: "กรุณากรอก รหัสผ่าน (Password)" },
              ]}
            >
              <Input.Password
                allowClear
                prefix={<LockOutlined />}
                placeholder={`กรุณากรอก รหัสผ่าน (Password)`}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 16]} justify="center" align="bottom">
          <Col xs={24} sm={12} md={8} lg={6} xl={4}>
            <Form.Item>
              <Button
                type="link"
                style={{ width: "100%" }}
                href="http://www.dplus-system.com:8080/dplusapp/index.php/site/Forgotpassword"
                target="_blank"
                icon={<LockOutlined />}
              >
                ลืมรหัสผ่าน
              </Button>
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={8} lg={6} xl={4}>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                style={{ width: "100%" }}
                icon={<LoginOutlined />}
              >
                เข้าสู่ระบบ
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    );
  };
}

export default login;
