import React from "react";
import { Col } from "antd";
import { InputNumber } from "../../../../components/global/forms/input";
export const DocInputNumber = ({
  form = {},
  control = { control },
  handleChange = () => console.warn("No handleChange"),
}) => {
  return (
    <Col
      key={`renderInput${form.ref}`}
      xs={{ span: 24 }}
      md={{ span: 24 }}
      xl={{ span: form.span }}
      lg={{ span: form.span }}
    >
      <InputNumber
        item={{ ...form }}
        control={control}
        handleChange={handleChange}
      />
    </Col>
  );
};
