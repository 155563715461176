import { COMPONENT_TYPE, ERROR_MESSAGE } from ".."

const DEFINE = {
    // Card 1
    company: {
        label: "บริษัท",
        name: "company",
    },
    location: {
        label: "สถานที่",
        name: "location",
    },
    depNo: {
        label: "แผนก",
        name: "depNo",
    },
    subDepNo: {
        label: "แผนกย่อย",
        name: "subDepNo",
    },
    startDate: {
        label: "วันที่เริ่มงาน",
        name: "startDate",
    },
    toDate: {
        label: "ถึงวันที่",
        name: "toDate",
    },
    statusStamp: {
        label: "สถานะส่งเอกสาร",
        name: "statusStamp",
    },
    statusWorker: {
        label: "สถานะพนักงาน",
        name: "statusWorker",
    },
    userId: {
        label: "รหัสพนักงาน",
        name: "userId",
    },
    statusMainCode: {
        label: "สถานะ (ประเมินงาน)",
        name: "statusMainCode",
    },
    contractPeriod: {
        label: "ระยะเวลาต่อสัญญา",
        name: "contractPeriod",
    },
    // Card 2
    datatimeStamp: {
        label: "วันที่ยื่น",
        name: "datatimeStamp",
    },
    dateGetChq: {
        label: "วันที่รับเช็ค",
        name: "dateGetChq",
    },
}

export const ComponentChangeInsurancePlan = ({
    options = {},
    isDisable = {},
    handleChange = () => null,
}) => {
    const { company, location, depNo, subDepNo, startDate, toDate, statusStamp, statusWorker } = DEFINE
    return [
        { // Row
            rowProps: { justify: "start", align: "bottom" },
            column: [ // column
                {
                    breakpoint: { xs: 24, sm: 24, md: 24, lg: 12, xl: 4 },
                    component: {
                        type: COMPONENT_TYPE.SELECT,
                        itemProps: {
                            name: company.name,
                            label: company.label,
                            rules: [{ required: false, message: ERROR_MESSAGE.SELECT(company.label) }],
                        },
                        componentProps: {
                            style: { width: "100%" },
                            placeholder: ERROR_MESSAGE.SELECT(company.label),
                            disabled: isDisable[company.name] || false,
                        },
                        fnChange: (value) => handleChange(company.name, value),
                        dropdown: options[company.name],
                    },
                },
                {
                    breakpoint: { xs: 24, sm: 24, md: 24, lg: 12, xl: 4 },
                    component: {
                        type: COMPONENT_TYPE.SELECT,
                        itemProps: {
                            name: location.name,
                            label: location.label,
                            rules: [{ required: false, message: ERROR_MESSAGE.SELECT(location.label) }],
                        },
                        componentProps: {
                            style: { width: "100%" },
                            placeholder: ERROR_MESSAGE.SELECT(location.label),
                            disabled: isDisable[location.name] || false,
                        },
                        fnChange: (value) => handleChange(location.name, value),
                        dropdown: options[location.name],
                    },
                },
                {
                    breakpoint: { xs: 24, sm: 24, md: 24, lg: 12, xl: 8 },
                    component: {
                        type: COMPONENT_TYPE.SELECT,
                        itemProps: {
                            name: depNo.name,
                            label: depNo.label,
                            rules: [{ required: false, message: ERROR_MESSAGE.SELECT(depNo.label) }],
                        },
                        componentProps: {
                            style: { width: "100%" },
                            placeholder: ERROR_MESSAGE.SELECT(depNo.label),
                            disabled: isDisable[depNo.name] || false,
                        },
                        fnChange: (value) => handleChange(depNo.name, value),
                        dropdown: options[depNo.name],
                    },
                },
                {
                    breakpoint: { xs: 24, sm: 24, md: 24, lg: 12, xl: 8 },
                    component: {
                        type: COMPONENT_TYPE.SELECT,
                        itemProps: {
                            name: subDepNo.name,
                            label: subDepNo.label,
                            rules: [{ required: false, message: ERROR_MESSAGE.SELECT(subDepNo.label) }],
                        },
                        componentProps: {
                            style: { width: "100%" },
                            placeholder: ERROR_MESSAGE.SELECT(subDepNo.label),
                            disabled: isDisable[subDepNo.name] || false,
                        },
                        fnChange: (value) => handleChange(subDepNo.name, value),
                        dropdown: options[subDepNo.name],
                    },
                },
                {
                    breakpoint: { xs: 24, sm: 24, md: 24, lg: 12, xl: 4 },
                    component: {
                        type: COMPONENT_TYPE.DATE,
                        itemProps: {
                            name: startDate.name,
                            label: startDate.label,
                            rules: [{ required: false, message: ERROR_MESSAGE.DATEPICKER }],
                        },
                        componentProps: {
                            style: { width: "100%" },
                            placeholder: ERROR_MESSAGE.DATEPICKER,
                            disabled: isDisable[startDate.name] || false,
                        },
                        fnChange: (date, dateString) => handleChange(startDate.name, dateString),
                    },
                },
                {
                    breakpoint: { xs: 24, sm: 24, md: 24, lg: 12, xl: 4 },
                    component: {
                        type: COMPONENT_TYPE.DATE,
                        itemProps: {
                            name: toDate.name,
                            label: toDate.label,
                            rules: [{ required: false, message: ERROR_MESSAGE.DATEPICKER }],
                        },
                        componentProps: {
                            style: { width: "100%" },
                            placeholder: ERROR_MESSAGE.DATEPICKER,
                            disabled: isDisable[toDate.name] || false,
                        },
                        fnChange: (date, dateString) => handleChange(toDate.name, dateString),
                    },
                },
                {
                    breakpoint: { xs: 24, sm: 24, md: 24, lg: 12, xl: 4 },
                    component: {
                        type: COMPONENT_TYPE.SELECT,
                        itemProps: {
                            name: statusStamp.name,
                            label: statusStamp.label,
                            rules: [{ required: false, message: ERROR_MESSAGE.SELECT(statusStamp.label) }],
                        },
                        componentProps: {
                            style: { width: "100%" },
                            placeholder: ERROR_MESSAGE.SELECT(statusStamp.label),
                            disabled: isDisable[statusStamp.name] || false,
                        },
                        fnChange: (value) => handleChange(statusStamp.name, value),
                        dropdown: options[statusStamp.name],
                    },
                },
                {
                    breakpoint: { xs: 24, sm: 24, md: 24, lg: 12, xl: 4 },
                    component: {
                        type: COMPONENT_TYPE.SELECT,
                        itemProps: {
                            name: statusWorker.name,
                            label: statusWorker.label,
                            rules: [{ required: false, message: ERROR_MESSAGE.SELECT(statusWorker.label) }],
                        },
                        componentProps: {
                            style: { width: "100%" },
                            placeholder: ERROR_MESSAGE.SELECT(statusWorker.label),
                            disabled: isDisable[statusWorker.name] || false,
                        },
                        fnChange: (value) => handleChange(statusWorker.name, value),
                        dropdown: options[statusWorker.name],
                    },
                },
            ], // column
        }, // Row
    ]
}

export const ComponentManageBenefitTransfer = ({
    options = {},
    isDisable = {},
    handleChange = () => null,
}) => {
    const { company, location, depNo, subDepNo, startDate, toDate, statusStamp, userId } = DEFINE
    return [
        { // Row
            rowProps: { justify: "start", align: "bottom" },
            column: [ // column
                {
                    breakpoint: { xs: 24, sm: 24, md: 24, lg: 12, xl: 4 },
                    component: {
                        type: COMPONENT_TYPE.SELECT,
                        itemProps: {
                            name: company.name,
                            label: company.label,
                            rules: [{ required: false, message: ERROR_MESSAGE.SELECT(company.label) }],
                        },
                        componentProps: {
                            style: { width: "100%" },
                            placeholder: ERROR_MESSAGE.SELECT(company.label),
                            disabled: isDisable[company.name] || false,
                        },
                        fnChange: (value) => handleChange(company.name, value),
                        dropdown: options[company.name],
                    },
                },
                {
                    breakpoint: { xs: 24, sm: 24, md: 24, lg: 12, xl: 4 },
                    component: {
                        type: COMPONENT_TYPE.SELECT,
                        itemProps: {
                            name: location.name,
                            label: location.label,
                            rules: [{ required: false, message: ERROR_MESSAGE.SELECT(location.label) }],
                        },
                        componentProps: {
                            style: { width: "100%" },
                            placeholder: ERROR_MESSAGE.SELECT(location.label),
                            disabled: isDisable[location.name] || false,
                        },
                        fnChange: (value) => handleChange(location.name, value),
                        dropdown: options[location.name],
                    },
                },
                {
                    breakpoint: { xs: 24, sm: 24, md: 24, lg: 12, xl: 8 },
                    component: {
                        type: COMPONENT_TYPE.SELECT,
                        itemProps: {
                            name: depNo.name,
                            label: depNo.label,
                            rules: [{ required: false, message: ERROR_MESSAGE.SELECT(depNo.label) }],
                        },
                        componentProps: {
                            style: { width: "100%" },
                            placeholder: ERROR_MESSAGE.SELECT(depNo.label),
                            disabled: isDisable[depNo.name] || false,
                        },
                        fnChange: (value) => handleChange(depNo.name, value),
                        dropdown: options[depNo.name],
                    },
                },
                {
                    breakpoint: { xs: 24, sm: 24, md: 24, lg: 12, xl: 8 },
                    component: {
                        type: COMPONENT_TYPE.SELECT,
                        itemProps: {
                            name: subDepNo.name,
                            label: subDepNo.label,
                            rules: [{ required: false, message: ERROR_MESSAGE.SELECT(subDepNo.label) }],
                        },
                        componentProps: {
                            style: { width: "100%" },
                            placeholder: ERROR_MESSAGE.SELECT(subDepNo.label),
                            disabled: isDisable[subDepNo.name] || false,
                        },
                        fnChange: (value) => handleChange(subDepNo.name, value),
                        dropdown: options[subDepNo.name],
                    },
                },
                {
                    breakpoint: { xs: 24, sm: 24, md: 24, lg: 12, xl: 4 },
                    component: {
                        type: COMPONENT_TYPE.DATE,
                        itemProps: {
                            name: startDate.name,
                            label: startDate.label,
                            rules: [{ required: false, message: ERROR_MESSAGE.DATEPICKER }],
                        },
                        componentProps: {
                            style: { width: "100%" },
                            placeholder: ERROR_MESSAGE.DATEPICKER,
                            disabled: isDisable[startDate.name] || false,
                        },
                        fnChange: (date, dateString) => handleChange(startDate.name, dateString),
                    },
                },
                {
                    breakpoint: { xs: 24, sm: 24, md: 24, lg: 12, xl: 4 },
                    component: {
                        type: COMPONENT_TYPE.DATE,
                        itemProps: {
                            name: toDate.name,
                            label: toDate.label,
                            rules: [{ required: false, message: ERROR_MESSAGE.DATEPICKER }],
                        },
                        componentProps: {
                            style: { width: "100%" },
                            placeholder: ERROR_MESSAGE.DATEPICKER,
                            disabled: isDisable[toDate.name] || false,
                        },
                        fnChange: (date, dateString) => handleChange(toDate.name, dateString),
                    },
                },
                {
                    breakpoint: { xs: 24, sm: 24, md: 24, lg: 12, xl: 4 },
                    component: {
                        type: COMPONENT_TYPE.SELECT,
                        itemProps: {
                            name: statusStamp.name,
                            label: statusStamp.label,
                            rules: [{ required: false, message: ERROR_MESSAGE.SELECT(statusStamp.label) }],
                        },
                        componentProps: {
                            style: { width: "100%" },
                            placeholder: ERROR_MESSAGE.SELECT(statusStamp.label),
                            disabled: isDisable[statusStamp.name] || false,
                        },
                        fnChange: (value) => handleChange(statusStamp.name, value),
                        dropdown: options[statusStamp.name],
                    },
                },
                {
                    breakpoint: { xs: 24, sm: 24, md: 24, lg: 12, xl: 8 },
                    component: {
                        type: COMPONENT_TYPE.SELECT,
                        itemProps: {
                            name: userId.name,
                            label: userId.label,
                            rules: [{ required: false, message: ERROR_MESSAGE.SELECT(userId.label) }],
                        },
                        componentProps: {
                            style: { width: "100%" },
                            placeholder: ERROR_MESSAGE.SELECT(userId.label),
                            disabled: isDisable[userId.name] || false,
                        },
                        fnChange: (value) => handleChange(userId.name, value),
                        dropdown: options[userId.name],
                    },
                },
            ], // column
        }, // Row
    ]

}

export const ComponentBenefitNewEmp = ({
    options = {},
    isDisable = {},
    handleChange = () => null,
}) => {
    const { company, location, depNo, subDepNo, startDate, toDate, statusMainCode, statusWorker, statusStamp } = DEFINE
    return [
        { // Row
            rowProps: { justify: "start", align: "bottom" },
            column: [ // column
                {
                    breakpoint: { xs: 24, sm: 24, md: 12, lg: 6, xl: 4 },
                    component: {
                        type: COMPONENT_TYPE.SELECT,
                        itemProps: {
                            name: company.name,
                            label: company.label,
                            rules: [{ required: false, message: ERROR_MESSAGE.SELECT(company.label) }],
                        },
                        componentProps: {
                            style: { width: "100%" },
                            placeholder: ERROR_MESSAGE.SELECT(company.label),
                            disabled: isDisable[company.name] || false,
                        },
                        fnChange: (value) => handleChange(company.name, value),
                        dropdown: options[company.name],
                    },
                },
                {
                    breakpoint: { xs: 24, sm: 24, md: 12, lg: 6, xl: 4 },
                    component: {
                        type: COMPONENT_TYPE.SELECT,
                        itemProps: {
                            name: location.name,
                            label: location.label,
                            rules: [{ required: false, message: ERROR_MESSAGE.SELECT(location.label) }],
                        },
                        componentProps: {
                            style: { width: "100%" },
                            placeholder: ERROR_MESSAGE.SELECT(location.label),
                            disabled: isDisable[location.name] || false,
                        },
                        fnChange: (value) => handleChange(location.name, value),
                        dropdown: options[location.name],
                    },
                },
                {
                    breakpoint: { xs: 24, sm: 24, md: 24, lg: 12, xl: 8 },
                    component: {
                        type: COMPONENT_TYPE.SELECT,
                        itemProps: {
                            name: depNo.name,
                            label: depNo.label,
                            rules: [{ required: false, message: ERROR_MESSAGE.SELECT(depNo.label) }],
                        },
                        componentProps: {
                            style: { width: "100%" },
                            placeholder: ERROR_MESSAGE.SELECT(depNo.label),
                            disabled: isDisable[depNo.name] || false,
                        },
                        fnChange: (value) => handleChange(depNo.name, value),
                        dropdown: options[depNo.name],
                    },
                },
                {
                    breakpoint: { xs: 24, sm: 24, md: 24, lg: 12, xl: 8 },
                    component: {
                        type: COMPONENT_TYPE.SELECT,
                        itemProps: {
                            name: subDepNo.name,
                            label: subDepNo.label,
                            rules: [{ required: false, message: ERROR_MESSAGE.SELECT(subDepNo.label) }],
                        },
                        componentProps: {
                            style: { width: "100%" },
                            placeholder: ERROR_MESSAGE.SELECT(subDepNo.label),
                            disabled: isDisable[subDepNo.name] || false,
                        },
                        fnChange: (value) => handleChange(subDepNo.name, value),
                        dropdown: options[subDepNo.name],
                    },
                },
                {
                    breakpoint: { xs: 24, sm: 24, md: 12, lg: 6, xl: 4 },
                    component: {
                        type: COMPONENT_TYPE.DATE,
                        itemProps: {
                            name: startDate.name,
                            label: startDate.label,
                            rules: [{ required: false, message: ERROR_MESSAGE.DATEPICKER }],
                        },
                        componentProps: {
                            style: { width: "100%" },
                            placeholder: ERROR_MESSAGE.DATEPICKER,
                            disabled: isDisable[startDate.name] || false,
                        },
                        fnChange: (date, dateString) => handleChange(startDate.name, dateString),
                    },
                },
                {
                    breakpoint: { xs: 24, sm: 24, md: 12, lg: 6, xl: 4 },
                    component: {
                        type: COMPONENT_TYPE.DATE,
                        itemProps: {
                            name: toDate.name,
                            label: toDate.label,
                            rules: [{ required: false, message: ERROR_MESSAGE.DATEPICKER }],
                        },
                        componentProps: {
                            style: { width: "100%" },
                            placeholder: ERROR_MESSAGE.DATEPICKER,
                            disabled: isDisable[toDate.name] || false,
                        },
                        fnChange: (date, dateString) => handleChange(toDate.name, dateString),
                    },
                },
                {
                    breakpoint: { xs: 24, sm: 24, md: 12, lg: 6, xl: 4 },
                    component: {
                        type: COMPONENT_TYPE.SELECT,
                        itemProps: {
                            name: statusMainCode.name,
                            label: statusMainCode.label,
                            rules: [{ required: false, message: ERROR_MESSAGE.SELECT(statusMainCode.label) }],
                        },
                        componentProps: {
                            style: { width: "100%" },
                            placeholder: ERROR_MESSAGE.SELECT(statusMainCode.label),
                            disabled: isDisable[statusMainCode.name] || false,
                        },
                        fnChange: (value) => handleChange(statusMainCode.name, value),
                        dropdown: options[statusMainCode.name],
                    },
                },
                {
                    breakpoint: { xs: 24, sm: 24, md: 12, lg: 6, xl: 4 },
                    component: {
                        type: COMPONENT_TYPE.SELECT,
                        itemProps: {
                            name: statusWorker.name,
                            label: statusWorker.label,
                            rules: [{ required: false, message: ERROR_MESSAGE.SELECT(statusWorker.label) }],
                        },
                        componentProps: {
                            style: { width: "100%" },
                            placeholder: ERROR_MESSAGE.SELECT(statusWorker.label),
                            disabled: isDisable[statusWorker.name] || false,
                        },
                        fnChange: (value) => handleChange(statusWorker.name, value),
                        dropdown: options[statusWorker.name],
                    },
                },
                {
                    breakpoint: { xs: 24, sm: 24, md: 12, lg: 6, xl: 4 },
                    component: {
                        type: COMPONENT_TYPE.SELECT,
                        itemProps: {
                            name: statusStamp.name,
                            label: statusStamp.label,
                            rules: [{ required: false, message: ERROR_MESSAGE.SELECT(statusStamp.label) }],
                        },
                        componentProps: {
                            style: { width: "100%" },
                            placeholder: ERROR_MESSAGE.SELECT(statusStamp.label),
                            disabled: isDisable[statusStamp.name] || false,
                        },
                        fnChange: (value) => handleChange(statusStamp.name, value),
                        dropdown: options[statusStamp.name],
                    },
                },
            ], // column
        }, // Row
    ]
}

export const ComponentBenefitNewEmpTemp = ({
    options = {},
    isDisable = {},
    handleChange = () => null,
}) => {
    const { company, location, depNo, subDepNo, startDate, toDate, statusMainCode, statusStamp, contractPeriod } = DEFINE
    return [
        { // Row
            rowProps: { justify: "start", align: "bottom" },
            column: [ // column
                {
                    breakpoint: { xs: 24, sm: 24, md: 12, lg: 6, xl: 4 },
                    component: {
                        type: COMPONENT_TYPE.SELECT,
                        itemProps: {
                            name: company.name,
                            label: company.label,
                            rules: [{ required: false, message: ERROR_MESSAGE.SELECT(company.label) }],
                        },
                        componentProps: {
                            style: { width: "100%" },
                            placeholder: ERROR_MESSAGE.SELECT(company.label),
                            disabled: isDisable[company.name] || false,
                        },
                        fnChange: (value) => handleChange(company.name, value),
                        dropdown: options[company.name],
                    },
                },
                {
                    breakpoint: { xs: 24, sm: 24, md: 12, lg: 6, xl: 4 },
                    component: {
                        type: COMPONENT_TYPE.SELECT,
                        itemProps: {
                            name: location.name,
                            label: location.label,
                            rules: [{ required: false, message: ERROR_MESSAGE.SELECT(location.label) }],
                        },
                        componentProps: {
                            style: { width: "100%" },
                            placeholder: ERROR_MESSAGE.SELECT(location.label),
                            disabled: isDisable[location.name] || false,
                        },
                        fnChange: (value) => handleChange(location.name, value),
                        dropdown: options[location.name],
                    },
                },
                {
                    breakpoint: { xs: 24, sm: 24, md: 24, lg: 12, xl: 8 },
                    component: {
                        type: COMPONENT_TYPE.SELECT,
                        itemProps: {
                            name: depNo.name,
                            label: depNo.label,
                            rules: [{ required: false, message: ERROR_MESSAGE.SELECT(depNo.label) }],
                        },
                        componentProps: {
                            style: { width: "100%" },
                            placeholder: ERROR_MESSAGE.SELECT(depNo.label),
                            disabled: isDisable[depNo.name] || false,
                        },
                        fnChange: (value) => handleChange(depNo.name, value),
                        dropdown: options[depNo.name],
                    },
                },
                {
                    breakpoint: { xs: 24, sm: 24, md: 24, lg: 12, xl: 8 },
                    component: {
                        type: COMPONENT_TYPE.SELECT,
                        itemProps: {
                            name: subDepNo.name,
                            label: subDepNo.label,
                            rules: [{ required: false, message: ERROR_MESSAGE.SELECT(subDepNo.label) }],
                        },
                        componentProps: {
                            style: { width: "100%" },
                            placeholder: ERROR_MESSAGE.SELECT(subDepNo.label),
                            disabled: isDisable[subDepNo.name] || false,
                        },
                        fnChange: (value) => handleChange(subDepNo.name, value),
                        dropdown: options[subDepNo.name],
                    },
                },
                {
                    breakpoint: { xs: 24, sm: 24, md: 12, lg: 6, xl: 4 },
                    component: {
                        type: COMPONENT_TYPE.DATE,
                        itemProps: {
                            name: startDate.name,
                            label: startDate.label,
                            rules: [{ required: false, message: ERROR_MESSAGE.DATEPICKER }],
                        },
                        componentProps: {
                            style: { width: "100%" },
                            placeholder: ERROR_MESSAGE.DATEPICKER,
                            disabled: isDisable[startDate.name] || false,
                        },
                        fnChange: (date, dateString) => handleChange(startDate.name, dateString),
                    },
                },
                {
                    breakpoint: { xs: 24, sm: 24, md: 12, lg: 6, xl: 4 },
                    component: {
                        type: COMPONENT_TYPE.DATE,
                        itemProps: {
                            name: toDate.name,
                            label: toDate.label,
                            rules: [{ required: false, message: ERROR_MESSAGE.DATEPICKER }],
                        },
                        componentProps: {
                            style: { width: "100%" },
                            placeholder: ERROR_MESSAGE.DATEPICKER,
                            disabled: isDisable[toDate.name] || false,
                        },
                        fnChange: (date, dateString) => handleChange(toDate.name, dateString),
                    },
                },
                {
                    breakpoint: { xs: 24, sm: 24, md: 12, lg: 6, xl: 4 },
                    component: {
                        type: COMPONENT_TYPE.SELECT,
                        itemProps: {
                            name: statusMainCode.name,
                            label: statusMainCode.label,
                            rules: [{ required: false, message: ERROR_MESSAGE.SELECT(statusMainCode.label) }],
                        },
                        componentProps: {
                            style: { width: "100%" },
                            placeholder: ERROR_MESSAGE.SELECT(statusMainCode.label),
                            disabled: isDisable[statusMainCode.name] || false,
                        },
                        fnChange: (value) => handleChange(statusMainCode.name, value),
                        dropdown: options[statusMainCode.name],
                    },
                },
                {
                    breakpoint: { xs: 24, sm: 24, md: 12, lg: 6, xl: 4 },
                    component: {
                        type: COMPONENT_TYPE.SELECT,
                        itemProps: {
                            name: statusStamp.name,
                            label: statusStamp.label,
                            rules: [{ required: false, message: ERROR_MESSAGE.SELECT(statusStamp.label) }],
                        },
                        componentProps: {
                            style: { width: "100%" },
                            placeholder: ERROR_MESSAGE.SELECT(statusStamp.label),
                            disabled: isDisable[statusStamp.name] || false,
                        },
                        fnChange: (value) => handleChange(statusStamp.name, value),
                        dropdown: options[statusStamp.name],
                    },
                },
                {
                    breakpoint: { xs: 24, sm: 24, md: 12, lg: 12, xl: 8 },
                    component: {
                        type: COMPONENT_TYPE.SELECT,
                        itemProps: {
                            name: contractPeriod.name,
                            label: contractPeriod.label,
                            rules: [{ required: false, message: ERROR_MESSAGE.SELECT(contractPeriod.label) }],
                        },
                        componentProps: {
                            style: { width: "100%" },
                            placeholder: ERROR_MESSAGE.SELECT(contractPeriod.label),
                            disabled: isDisable[contractPeriod.name] || false,
                        },
                        fnChange: (value) => handleChange(contractPeriod.name, value),
                        dropdown: options[contractPeriod.name],
                    },
                },
            ], // column
        }, // Row
    ]
}

export const ComponentBenefitOutEmp = ({
    options = {},
    isDisable = {},
    handleChange = () => null,
}) => {
    const { company, location, depNo, subDepNo, startDate, toDate, statusWorker, statusStamp, userId } = DEFINE
    return [
        { // Row
            rowProps: { justify: "start", align: "bottom" },
            column: [ // column
                {
                    breakpoint: { xs: 24, sm: 24, md: 12, lg: 6, xl: 4 },
                    component: {
                        type: COMPONENT_TYPE.SELECT,
                        itemProps: {
                            name: company.name,
                            label: company.label,
                            rules: [{ required: false, message: ERROR_MESSAGE.SELECT(company.label) }],
                        },
                        componentProps: {
                            style: { width: "100%" },
                            placeholder: ERROR_MESSAGE.SELECT(company.label),
                            disabled: isDisable[company.name] || false,
                        },
                        fnChange: (value) => handleChange(company.name, value),
                        dropdown: options[company.name],
                    },
                },
                {
                    breakpoint: { xs: 24, sm: 24, md: 12, lg: 6, xl: 4 },
                    component: {
                        type: COMPONENT_TYPE.SELECT,
                        itemProps: {
                            name: location.name,
                            label: location.label,
                            rules: [{ required: false, message: ERROR_MESSAGE.SELECT(location.label) }],
                        },
                        componentProps: {
                            style: { width: "100%" },
                            placeholder: ERROR_MESSAGE.SELECT(location.label),
                            disabled: isDisable[location.name] || false,
                        },
                        fnChange: (value) => handleChange(location.name, value),
                        dropdown: options[location.name],
                    },
                },
                {
                    breakpoint: { xs: 24, sm: 24, md: 24, lg: 12, xl: 8 },
                    component: {
                        type: COMPONENT_TYPE.SELECT,
                        itemProps: {
                            name: depNo.name,
                            label: depNo.label,
                            rules: [{ required: false, message: ERROR_MESSAGE.SELECT(depNo.label) }],
                        },
                        componentProps: {
                            style: { width: "100%" },
                            placeholder: ERROR_MESSAGE.SELECT(depNo.label),
                            disabled: isDisable[depNo.name] || false,
                        },
                        fnChange: (value) => handleChange(depNo.name, value),
                        dropdown: options[depNo.name],
                    },
                },
                {
                    breakpoint: { xs: 24, sm: 24, md: 24, lg: 12, xl: 8 },
                    component: {
                        type: COMPONENT_TYPE.SELECT,
                        itemProps: {
                            name: subDepNo.name,
                            label: subDepNo.label,
                            rules: [{ required: false, message: ERROR_MESSAGE.SELECT(subDepNo.label) }],
                        },
                        componentProps: {
                            style: { width: "100%" },
                            placeholder: ERROR_MESSAGE.SELECT(subDepNo.label),
                            disabled: isDisable[subDepNo.name] || false,
                        },
                        fnChange: (value) => handleChange(subDepNo.name, value),
                        dropdown: options[subDepNo.name],
                    },
                },
                {
                    breakpoint: { xs: 24, sm: 24, md: 12, lg: 6, xl: 4 },
                    component: {
                        type: COMPONENT_TYPE.DATE,
                        itemProps: {
                            name: startDate.name,
                            label: startDate.label,
                            rules: [{ required: false, message: ERROR_MESSAGE.DATEPICKER }],
                        },
                        componentProps: {
                            style: { width: "100%" },
                            placeholder: ERROR_MESSAGE.DATEPICKER,
                            disabled: isDisable[startDate.name] || false,
                        },
                        fnChange: (date, dateString) => handleChange(startDate.name, dateString),
                    },
                },
                {
                    breakpoint: { xs: 24, sm: 24, md: 12, lg: 6, xl: 4 },
                    component: {
                        type: COMPONENT_TYPE.DATE,
                        itemProps: {
                            name: toDate.name,
                            label: toDate.label,
                            rules: [{ required: false, message: ERROR_MESSAGE.DATEPICKER }],
                        },
                        componentProps: {
                            style: { width: "100%" },
                            placeholder: ERROR_MESSAGE.DATEPICKER,
                            disabled: isDisable[toDate.name] || false,
                        },
                        fnChange: (date, dateString) => handleChange(toDate.name, dateString),
                    },
                },
                {
                    breakpoint: { xs: 24, sm: 24, md: 12, lg: 6, xl: 4 },
                    component: {
                        type: COMPONENT_TYPE.SELECT,
                        itemProps: {
                            name: statusWorker.name,
                            label: statusWorker.label,
                            rules: [{ required: false, message: ERROR_MESSAGE.SELECT(statusWorker.label) }],
                        },
                        componentProps: {
                            style: { width: "100%" },
                            placeholder: ERROR_MESSAGE.SELECT(statusWorker.label),
                            disabled: isDisable[statusWorker.name] || false,
                        },
                        fnChange: (value) => handleChange(statusWorker.name, value),
                        dropdown: options[statusWorker.name],
                    },
                },
                {
                    breakpoint: { xs: 24, sm: 24, md: 12, lg: 6, xl: 4 },
                    component: {
                        type: COMPONENT_TYPE.SELECT,
                        itemProps: {
                            name: statusStamp.name,
                            label: statusStamp.label,
                            rules: [{ required: false, message: ERROR_MESSAGE.SELECT(statusStamp.label) }],
                        },
                        componentProps: {
                            style: { width: "100%" },
                            placeholder: ERROR_MESSAGE.SELECT(statusStamp.label),
                            disabled: isDisable[statusStamp.name] || false,
                        },
                        fnChange: (value) => handleChange(statusStamp.name, value),
                        dropdown: options[statusStamp.name],
                    },
                },
                {
                    breakpoint: { xs: 24, sm: 24, md: 12, lg: 12, xl: 8 },
                    component: {
                        type: COMPONENT_TYPE.INPUT,
                        itemProps: {
                            name: userId.name,
                            label: userId.label,
                            rules: [{ required: false, message: ERROR_MESSAGE.SELECT(userId.label) }],
                        },
                        componentProps: {
                            style: { width: "100%" },
                            placeholder: ERROR_MESSAGE.INPUT(userId.label),
                            disabled: isDisable[userId.name] || false,
                        },
                        fnChange: (event) => handleChange(userId.name, event.target.value),
                    },
                },
            ], // column
        }, // Row
    ]
}

export const ComponentChangeInsurancePlanDatatimeStamp = ({
    isDisable = {},
    handleChange = () => null,
}) => {
    const { datatimeStamp } = DEFINE
    return [
        { // Row
            rowProps: { justify: "start", align: "bottom" },
            column: [ // column
                {
                    breakpoint: { xs: 24, sm: 12, md: 12, lg: 6, xl: 4 },
                    component: {
                        type: COMPONENT_TYPE.DATE,
                        itemProps: {
                            name: datatimeStamp.name,
                            label: datatimeStamp.label,
                            rules: [{ required: true, message: ERROR_MESSAGE.DATEPICKER }],
                        },
                        componentProps: {
                            style: { width: "100%" },
                            placeholder: ERROR_MESSAGE.DATEPICKER,
                            disabled: isDisable[datatimeStamp.name] || false,
                        },
                        fnChange: (date, dateString) => handleChange(datatimeStamp.name, dateString),
                    },
                },
            ], // column
        }, // Row
    ]
}

export const ComponentChangeInsurancePlanDatatimeStampDateCheck = ({
    isDisable = {},
    handleChange = () => null,
}) => {
    const { datatimeStamp, dateGetChq } = DEFINE
    return [
        { // Row
            rowProps: { justify: "start", align: "bottom" },
            column: [ // column
                {
                    breakpoint: { xs: 24, sm: 12, md: 12, lg: 6, xl: 4 },
                    component: {
                        type: COMPONENT_TYPE.DATE,
                        itemProps: {
                            name: datatimeStamp.name,
                            label: datatimeStamp.label,
                            rules: [{ required: true, message: ERROR_MESSAGE.DATEPICKER }],
                        },
                        componentProps: {
                            style: { width: "100%" },
                            placeholder: ERROR_MESSAGE.DATEPICKER,
                            disabled: isDisable[datatimeStamp.name] || false,
                        },
                        fnChange: (date, dateString) => handleChange(datatimeStamp.name, dateString),
                    },
                },
                {
                    breakpoint: { xs: 24, sm: 12, md: 12, lg: 6, xl: 4 },
                    component: {
                        type: COMPONENT_TYPE.DATE,
                        itemProps: {
                            name: dateGetChq.name,
                            label: dateGetChq.label,
                            rules: [{ required: false, message: ERROR_MESSAGE.DATEPICKER }],
                        },
                        componentProps: {
                            style: { width: "100%" },
                            placeholder: ERROR_MESSAGE.DATEPICKER,
                            disabled: isDisable[dateGetChq.name] || false,
                        },
                        fnChange: (date, dateString) => handleChange(dateGetChq.name, dateString),
                    },
                },
            ], // column
        }, // Row
    ]
}
