export const useFormState = () => {
  const defaultValues = {
    company: "all",
    departmentNo: "all",
    employeeNo: "all",
    fileStatus: "WAIT"
  };

  return {
    defaultValues,
  };
};
