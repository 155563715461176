import { FIELD_STEP } from '../constantsPersonal';
import { FIELD_EMP } from '../constantsPersonal/contents/employeeInfo';
import { FIELD_PERSONAL } from '../constantsPersonal/contents/personalInfo';
import { FIELD_FAMILY, INITIAL_FAMILY } from '../constantsPersonal/contents/familyInfo';
import { FIELD_EXPERIENCES, INITIAL_EXPERIENCES } from '../constantsPersonal/contents/experiencesInfo';
import { FIELD_EDUCATION, INITIAL_EDUCATION } from '../constantsPersonal/contents/educationInfo';
import { FIELD_TRAINING, INITIAL_TRAINING } from '../constantsPersonal/contents/trainingInfo';
import { FIELD_OTHER, INITIAL_ASSETS, INITIAL_CRIME, INITIAL_ADDICTIVE } from '../constantsPersonal/contents/otherInfo';
import { FIELD_DRIVING, INITIAL_DRIVING } from '../constantsPersonal/contents/drivingInfo';
import { FIELD_LANGUAGE, INITIAL_LANGUAGE } from '../constantsPersonal/contents/languageSkill';
import { FIELD_OTHER_PERSONAL, INITIAL_OTHER_PERSONAL } from '../constantsPersonal/contents/otherPersonInfo';
import { fixedDecimal } from './calculateField';
import { modifyArrayDefault } from './modifyArray';



export const SET_GETDATA = (props) => {
	return {
		// [FIELD_STEP.STEP_0]: {
		statusFindIdCard: props.statusFindIdCard || '',
		messageImportIdCard: props.message || '',
		[FIELD_EMP.FULLNAME_TH]: props[FIELD_EMP.FULLNAME_TH] || '',
		[FIELD_EMP.FULLNAME_EN]: props[FIELD_EMP.FULLNAME_EN] || '',
		[FIELD_EMP.COMPANY]: props[FIELD_EMP.COMPANY] || '',
		[FIELD_EMP.LOCATION]: props[FIELD_EMP.LOCATION] || '',
		[FIELD_EMP.EMPLOYEE_ID]: props[FIELD_EMP.EMPLOYEE_ID] || '',
		[FIELD_EMP.STATUS_WORKERS]: props[FIELD_EMP.STATUS_WORKERS] || '',
		[FIELD_EMP.START_DATE_JOB]: props[FIELD_EMP.START_DATE_JOB] || '',
		[FIELD_EMP.END_DATE_JOB]: props[FIELD_EMP.END_DATE_JOB] || '',
		[FIELD_EMP.WORK_EXPERIENCE]: props[FIELD_EMP.WORK_EXPERIENCE] || '',
		[FIELD_EMP.TITLE_TH]: props[FIELD_EMP.TITLE_TH] || '',
		[FIELD_EMP.FIRSTNAME_TH]: props[FIELD_EMP.FIRSTNAME_TH] || '',
		[FIELD_EMP.LASTNAME_TH]: props[FIELD_EMP.LASTNAME_TH] || '',
		[FIELD_EMP.NICKNAME_TH]: props[FIELD_EMP.NICKNAME_TH] || '',
		[FIELD_EMP.TITLE_EN]: props[FIELD_EMP.TITLE_EN] || '',
		[FIELD_EMP.FIRSTNAME_EN]: props[FIELD_EMP.FIRSTNAME_EN] || '',
		[FIELD_EMP.LASTNAME_EN]: props[FIELD_EMP.LASTNAME_EN] || '',
		[FIELD_EMP.NICKNAME_EN]: props[FIELD_EMP.NICKNAME_EN] || '',
		[FIELD_EMP.USERNAME]: props[FIELD_EMP.USERNAME] || '',
		[FIELD_EMP.EMAIL]: props[FIELD_EMP.EMAIL] || '',
		[FIELD_EMP.TEL]: props[FIELD_EMP.TEL] || '',
		[FIELD_EMP.PERSONAL_ID]: props[FIELD_EMP.PERSONAL_ID] || '',
		[FIELD_EMP.PERSONAL_ID_EXP]: props[FIELD_EMP.PERSONAL_ID_EXP] || '',
		[FIELD_EMP.PASSPORT_ID]: props[FIELD_EMP.PASSPORT_ID] || '',
		[FIELD_EMP.PASSPORT_ID_EXP]: props[FIELD_EMP.PASSPORT_ID_EXP] || '',
		[FIELD_EMP.WORKPERMIT_ID]: props[FIELD_EMP.WORKPERMIT_ID] || '',
		[FIELD_EMP.WORKPERMIT_ID_EXP]: props[FIELD_EMP.WORKPERMIT_ID_EXP] || '',
		[FIELD_EMP.MAN_TYPE]: props[FIELD_EMP.MAN_TYPE] || '',
		[FIELD_EMP.DEP_NO]: props[FIELD_EMP.DEP_NO] || '',
		[FIELD_EMP.SUB_DEP_NO]: props[FIELD_EMP.SUB_DEP_NO] || '',
		[FIELD_EMP.POSITION_NO]: props[FIELD_EMP.POSITION_NO] || '',
		[FIELD_EMP.FLOW_HRM]: props[FIELD_EMP.FLOW_HRM] || '',
		[FIELD_EMP.SHIFT_CODE]: props[FIELD_EMP.SHIFT_CODE] || '',
		[FIELD_EMP.CODE_MAP_SCAN]: props[FIELD_EMP.CODE_MAP_SCAN] || '',
		[FIELD_EMP.TIME_SCAN]: props[FIELD_EMP.TIME_SCAN] || '',
		[FIELD_EMP.ONETIME_SCAN]: `${props[FIELD_EMP.ONETIME_SCAN]}`,
		[FIELD_EMP.SHIRT_SIZE]: props[FIELD_EMP.SHIRT_SIZE],
		[FIELD_EMP.SCORE_JG]: props[FIELD_EMP.SCORE_JG] || '',
		[FIELD_EMP.CONTRACT_PERIOD]: props[FIELD_EMP.CONTRACT_PERIOD] || '',
		[FIELD_EMP.PAYROLL_GROUP]: props[FIELD_EMP.PAYROLL_GROUP] || '',
		[FIELD_EMP.SEND_CRIME]: props[FIELD_EMP.SEND_CRIME] || '',
		[FIELD_EMP.SEND_GUARANTEE]: props[FIELD_EMP.SEND_GUARANTEE] || '',
		[FIELD_EMP.CONFIRM_FIRST_DATA]: props[FIELD_EMP.CONFIRM_FIRST_DATA] || '',
		[FIELD_EMP.HEALTH_PLAN]: props[FIELD_EMP.HEALTH_PLAN],
		[FIELD_EMP.ACCIDENT_PLAN]: props[FIELD_EMP.ACCIDENT_PLAN],
		[FIELD_EMP.LINEINS_PLAN]: props[FIELD_EMP.LINEINS_PLAN] || '',
		[FIELD_EMP.END_CONTRACT_DATE_JOB]: props[FIELD_EMP.END_CONTRACT_DATE_JOB] || '',
		[FIELD_EMP.STATUS_EMPLOYEE]: props[FIELD_EMP.STATUS_EMPLOYEE] || '',
		[FIELD_EMP.RECRUITER]: props[FIELD_EMP.RECRUITER] || '',
		[FIELD_EMP.SALE_CODE]: props[FIELD_EMP.SALE_CODE] || '',
		// },
		/* -------------------------------------------------------------------------- */

		/* --------------------------------- STEP_1 --------------------------------- */
		// [FIELD_STEP.STEP_1]: {
		[FIELD_PERSONAL.PROFILE_IMAGE]: props[FIELD_PERSONAL.PROFILE_IMAGE] || [],
		// [FIELD_PERSONAL.TITLE_TH]: '',
		// [FIELD_PERSONAL.FIRSTNAME_TH]: '',
		// [FIELD_PERSONAL.LASTNAME_TH]: '',
		// [FIELD_PERSONAL.NICKNAME_TH]: '',
		// [FIELD_PERSONAL.TITLE_EN]: '',
		// [FIELD_PERSONAL.FIRSTNAME_EN]: '',
		// [FIELD_PERSONAL.LASTNAME_EN]: '',
		// [FIELD_PERSONAL.NICKNAME_EN]: '',
		[FIELD_PERSONAL.WEIGHT]: props[FIELD_PERSONAL.WEIGHT] || '',
		[FIELD_PERSONAL.HEIGHT]: props[FIELD_PERSONAL.HEIGHT] || '',
		[FIELD_PERSONAL.AGE_REGIS]: props[FIELD_PERSONAL.AGE_REGIS] || '',
		[FIELD_PERSONAL.AGE]: props[FIELD_PERSONAL.AGE] || '',
		[FIELD_PERSONAL.DATE_OF_BIRTHDAY]: props[FIELD_PERSONAL.DATE_OF_BIRTHDAY] || '',
		[FIELD_PERSONAL.SEX]: props[FIELD_PERSONAL.SEX] || '',
		[FIELD_PERSONAL.NATIONALITY]: props[FIELD_PERSONAL.NATIONALITY] || '',
		[FIELD_PERSONAL.RACE]: props[FIELD_PERSONAL.RACE] || '',
		[FIELD_PERSONAL.RELIGION]: props[FIELD_PERSONAL.RELIGION] || '',
		// [FIELD_PERSONAL.PERSONAL_ID]: '',
		[FIELD_PERSONAL.PERSONAL_ID_EXP]: props[FIELD_PERSONAL.PERSONAL_ID_EXP] || '',
		[FIELD_PERSONAL.PASSPORT_ID]: props[FIELD_PERSONAL.PASSPORT_ID] || '',
		[FIELD_PERSONAL.PASSPORT_ID_EXP]: props[FIELD_PERSONAL.PASSPORT_ID_EXP] || '',
		[FIELD_PERSONAL.WORKPERMIT_ID]: props[FIELD_PERSONAL.WORKPERMIT_ID] || '',
		[FIELD_PERSONAL.WORKPERMIT_ID_EXP]: props[FIELD_PERSONAL.WORKPERMIT_ID_EXP] || '',
		[FIELD_PERSONAL.PERSONAL_STATUS]: props[FIELD_PERSONAL.PERSONAL_STATUS] || '',
		[FIELD_PERSONAL.MILITARYSERVICE]: props[FIELD_PERSONAL.MILITARYSERVICE] || '',
		[FIELD_PERSONAL.MILITARYSERVICE_WHEN]: props[FIELD_PERSONAL.MILITARYSERVICE_WHEN] || '',
		[FIELD_PERSONAL.REASON_EXEMPT]: props[FIELD_PERSONAL.REASON_EXEMPT] || '',
		[FIELD_PERSONAL.SPOUSE_NAME]: props[FIELD_PERSONAL.SPOUSE_NAME] || '',
		[FIELD_PERSONAL.SPOUSE_NUM_CHILDREN]: props[FIELD_PERSONAL.SPOUSE_NUM_CHILDREN] || '',
		[FIELD_PERSONAL.SPOUSE_TEL]: props[FIELD_PERSONAL.SPOUSE_TEL] || '',
		[FIELD_PERSONAL.SPOUSE_OCCUPATION]: props[FIELD_PERSONAL.SPOUSE_OCCUPATION] || '',
		[FIELD_PERSONAL.SPOUSE_POSITION]: props[FIELD_PERSONAL.SPOUSE_POSITION] || '',
		[FIELD_PERSONAL.SPOUSE_OFFICE]: props[FIELD_PERSONAL.SPOUSE_OFFICE] || '',
		[FIELD_PERSONAL.SIMILAR_PRESENT]: props[FIELD_PERSONAL.SIMILAR_PRESENT] || '',
		[FIELD_PERSONAL.PRESENT_ADDRESS]: props[FIELD_PERSONAL.PRESENT_ADDRESS] || '',
		[FIELD_PERSONAL.PRESENT_PROVINCE]: props[FIELD_PERSONAL.PRESENT_PROVINCE] || '',
		[FIELD_PERSONAL.PRESENT_DISTRICT]: props[FIELD_PERSONAL.PRESENT_DISTRICT] || '',
		[FIELD_PERSONAL.PRESENT_SUB_DISTRICT]: props[FIELD_PERSONAL.PRESENT_SUB_DISTRICT] || '',
		[FIELD_PERSONAL.PRESENT_POSTCODE]: props[FIELD_PERSONAL.PRESENT_POSTCODE] || '',
		[FIELD_PERSONAL.FORMER_ADDRESS]: props[FIELD_PERSONAL.FORMER_ADDRESS] || '',
		[FIELD_PERSONAL.FORMER_PROVINCE]: props[FIELD_PERSONAL.FORMER_PROVINCE] || '',
		[FIELD_PERSONAL.FORMER_DISTRICT]: props[FIELD_PERSONAL.FORMER_DISTRICT] || '',
		[FIELD_PERSONAL.FORMER_SUB_DISTRICT]: props[FIELD_PERSONAL.FORMER_SUB_DISTRICT] || '',
		[FIELD_PERSONAL.FORMER_POSTCODE]: props[FIELD_PERSONAL.FORMER_POSTCODE] || '',
		[FIELD_PERSONAL.HOME_TEL]: props[FIELD_PERSONAL.HOME_TEL] || '',
		[FIELD_PERSONAL.MOBILE_PHONE]: props[FIELD_PERSONAL.MOBILE_PHONE] || '',
		[FIELD_PERSONAL.LINE_ID]: props[FIELD_PERSONAL.LINE_ID] || '',
		[FIELD_PERSONAL.EMAIL]: props[FIELD_PERSONAL.EMAIL] || '',
		[FIELD_PERSONAL.EMERGENCY_FULLNAME]: props[FIELD_PERSONAL.EMERGENCY_FULLNAME] || '',
		[FIELD_PERSONAL.EMERGENCY_REALATION]: props[FIELD_PERSONAL.EMERGENCY_REALATION] || '',
		[FIELD_PERSONAL.EMERGENCY_PHONE]: props[FIELD_PERSONAL.EMERGENCY_PHONE] || '',
		[FIELD_PERSONAL.EMERGENCY_TEL_OTHER]: props[FIELD_PERSONAL.EMERGENCY_TEL_OTHER] || '',
		[FIELD_PERSONAL.EMERGENCY_ADDRESS]: props[FIELD_PERSONAL.EMERGENCY_ADDRESS] || '',
		[FIELD_STEP.COLLAPSE_S12]: props[FIELD_STEP.STEP_1] && props[FIELD_STEP.STEP_1][FIELD_STEP.COLLAPSE_S12].length > 0
			? props[FIELD_STEP.STEP_1][FIELD_STEP.COLLAPSE_S12]
			: [{
				[FIELD_FAMILY.FULLNAME]: props[FIELD_FAMILY.FULLNAME] || '',
				[FIELD_FAMILY.RELATION]: props[FIELD_FAMILY.RELATION] || '',
				[FIELD_FAMILY.AGE]: props[FIELD_FAMILY.AGE] || '',
				[FIELD_FAMILY.OCCUPATION]: props[FIELD_FAMILY.OCCUPATION] || '',
				[FIELD_FAMILY.ADDRESS_OFFICE]: props[FIELD_FAMILY.ADDRESS_OFFICE] || '',
			}],

		// },
		/* -------------------------------------------------------------------------- */

		/* ------------------------------ STEP_2 ------------------------------ */
		[FIELD_STEP.COLLAPSE_S21]: props[FIELD_STEP.COLLAPSE_S21].length > 0
			? props[FIELD_STEP.COLLAPSE_S21]
			: [{
				[FIELD_EXPERIENCES.NAME_EMPLOYER]: props[FIELD_EXPERIENCES.NAME_EMPLOYER] || '',
				[FIELD_EXPERIENCES.TEL_EMPLOYER]: props[FIELD_EXPERIENCES.TEL_EMPLOYER] || '',
				[FIELD_EXPERIENCES.LAST_POSITION]: props[FIELD_EXPERIENCES.LAST_POSITION] || '',
				[FIELD_EXPERIENCES.ADDRESS_EMPLOYER]: props[FIELD_EXPERIENCES.ADDRESS_EMPLOYER] || '',
				[FIELD_EXPERIENCES.START_DATE]: props[FIELD_EXPERIENCES.START_DATE] || '',
				[FIELD_EXPERIENCES.END_DATE]: props[FIELD_EXPERIENCES.END_DATE] || '',
				[FIELD_EXPERIENCES.BOSS_NAME]: props[FIELD_EXPERIENCES.BOSS_NAME] || '',
				[FIELD_EXPERIENCES.BOSS_POSITION]: props[FIELD_EXPERIENCES.BOSS_POSITION] || '',
				[FIELD_EXPERIENCES.REASON_LEAVE]: props[FIELD_EXPERIENCES.REASON_LEAVE] || '',
				[FIELD_EXPERIENCES.RESPONSIBILITY]: props[FIELD_EXPERIENCES.RESPONSIBILITY] || '',
			}],
		/* -------------------------------------------------------------------------- */

		/* --------------------------------- STEP_3 --------------------------------- */
		// [FIELD_STEP.STEP_3]: {
		[FIELD_STEP.COLLAPSE_S31]: props[FIELD_STEP.STEP_3] && props[FIELD_STEP.STEP_3][FIELD_STEP.COLLAPSE_S31].length > 0
			? props[FIELD_STEP.STEP_3][FIELD_STEP.COLLAPSE_S31]
			: modifyArrayDefault(new Array(4).fill({
				[FIELD_EDUCATION.EDUCATION_LEVEL]: props[FIELD_EDUCATION.EDUCATION_LEVEL] || '',
				[FIELD_EDUCATION.INSTITUTION]: props[FIELD_EDUCATION.INSTITUTION] || '',
				[FIELD_EDUCATION.MAJOR]: props[FIELD_EDUCATION.MAJOR] || '',
				[FIELD_EDUCATION.DEGREE]: props[FIELD_EDUCATION.DEGREE] || '',
				[FIELD_EDUCATION.GRADUATION_YEAR]: props[FIELD_EDUCATION.GRADUATION_YEAR] || '',
				[FIELD_EDUCATION.GRADE]: props[FIELD_EDUCATION.GRADE] || '',
			}), ['ประถมศึกษา', 'มัธยมศึกษา', 'วิทยาลัย/พาณิชย์/อาชีวะ', 'มหาวิทยาลัย'], [FIELD_EDUCATION.EDUCATION_LEVEL]),
		[FIELD_STEP.COLLAPSE_S32]: props[FIELD_STEP.STEP_3] && props[FIELD_STEP.STEP_3][FIELD_STEP.COLLAPSE_S32].length > 0
			? props[FIELD_STEP.STEP_3][FIELD_STEP.COLLAPSE_S32]
			: [{
				[FIELD_TRAINING.COURSE_NAME]: props[FIELD_TRAINING.COURSE_NAME] || '',
				[FIELD_TRAINING.ORGANIZED_BY]: props[FIELD_TRAINING.ORGANIZED_BY] || '',
				[FIELD_TRAINING.DURATION]: props[FIELD_TRAINING.DURATION] || ''
			}],
		// },
		/* -------------------------------------------------------------------------- */

		/* --------------------------------- STEP_4 --------------------------------- */
		// [FIELD_STEP.STEP_4]: {
		// [FIELD_STEP.COLLAPSE_S41]: [{
		[FIELD_DRIVING.DRIVING_SKILL]: `${props[FIELD_DRIVING.DRIVING_SKILL]}` || '',
		[FIELD_DRIVING.OWNER_VEHICLE]: `${props[FIELD_DRIVING.OWNER_VEHICLE]}` || '',
		[FIELD_OTHER.COMPUTER_ABILITY]: props[FIELD_OTHER.COMPUTER_ABILITY] || '',
		[FIELD_OTHER.OFFICE_ABILITY]: props[FIELD_OTHER.OFFICE_ABILITY] || '',
		[FIELD_OTHER.ACTIVITY_EXTRA]: props[FIELD_OTHER.ACTIVITY_EXTRA] || '',
		[FIELD_OTHER.ACTIVITY_SPORT]: props[FIELD_OTHER.ACTIVITY_SPORT] || '',
		[FIELD_OTHER.HEALTH_RESULT]: props[FIELD_OTHER.HEALTH_RESULT] || '',
		[FIELD_OTHER.HEALTH_SMOKING]: props[FIELD_OTHER.HEALTH_SMOKING] || '',
		[FIELD_OTHER.PROBATION_END_DATE]: props[FIELD_OTHER.PROBATION_END_DATE] || '',
		[FIELD_OTHER.PF_IN_DATE]: props[FIELD_OTHER.PF_IN_DATE] || '',
		[FIELD_OTHER.GROUP_INSURANCE_IN_DATE]: props[FIELD_OTHER.GROUP_INSURANCE_IN_DATE] || '',
		[FIELD_OTHER.GROUP_INSURANCE_TYPE]: props[FIELD_OTHER.GROUP_INSURANCE_TYPE] || '',
		[FIELD_OTHER.INSURANCE_IN_DATE]: props[FIELD_OTHER.INSURANCE_IN_DATE] || '',
		[FIELD_OTHER.INSURANCE_TYPE]: props[FIELD_OTHER.INSURANCE_TYPE] || '',
		[FIELD_OTHER.SSO_IN_DATE]: props[FIELD_OTHER.SSO_IN_DATE] || '',
		[FIELD_OTHER.SSO_IN_NO]: props[FIELD_OTHER.SSO_IN_NO] || '',
		[FIELD_OTHER.SSO_OUT_DATE]: props[FIELD_OTHER.SSO_OUT_DATE] || '',
		[FIELD_OTHER.SSO_OUT_NO]: props[FIELD_OTHER.SSO_OUT_NO] || '',
		[FIELD_STEP.COLLAPSE_S41]: props[FIELD_STEP.STEP_4][FIELD_STEP.COLLAPSE_S41] && props[FIELD_STEP.STEP_4][FIELD_STEP.COLLAPSE_S41].length > 0
			? props[FIELD_STEP.STEP_4][FIELD_STEP.COLLAPSE_S41]
			: [{
				[FIELD_DRIVING.PLATE_NO]: props[FIELD_DRIVING.PLATE_NO] || '',
				[FIELD_DRIVING.LICENSE_NO]: props[FIELD_DRIVING.LICENSE_NO] || '',
				[FIELD_DRIVING.DRIVING_TYPE]: props[FIELD_DRIVING.DRIVING_TYPE] || '',
			}],
		[FIELD_STEP.COLLAPSE_S43]: props[FIELD_STEP.STEP_4][FIELD_STEP.COLLAPSE_S43] && props[FIELD_STEP.STEP_4][FIELD_STEP.COLLAPSE_S43].length > 0
			? props[FIELD_STEP.STEP_4][FIELD_STEP.COLLAPSE_S43]
			: modifyArrayDefault(new Array(3).fill({
				[FIELD_LANGUAGE.LANGUAGE]: props[FIELD_LANGUAGE.LANGUAGE] || '',
				[FIELD_LANGUAGE.LISTENING]: props[FIELD_LANGUAGE.LISTENING] || '',
				[FIELD_LANGUAGE.SPEAKING]: props[FIELD_LANGUAGE.SPEAKING] || '',
				[FIELD_LANGUAGE.READING]: props[FIELD_LANGUAGE.READING] || '',
				[FIELD_LANGUAGE.WRITING]: props[FIELD_LANGUAGE.WRITING] || ''
			}), ['ไทย', 'อังกฤษ', 'จีน'], [FIELD_LANGUAGE.LANGUAGE]),
		[FIELD_STEP.COLLAPSE_S44]: props[FIELD_STEP.STEP_4][FIELD_STEP.COLLAPSE_S44] && props[FIELD_STEP.STEP_4][FIELD_STEP.COLLAPSE_S44].length > 0
			? props[FIELD_STEP.STEP_4][FIELD_STEP.COLLAPSE_S44]
			: [{
				[FIELD_OTHER_PERSONAL.FULLNAME]: props[FIELD_OTHER_PERSONAL.FULLNAME] || '',
				[FIELD_OTHER_PERSONAL.RELATION]: props[FIELD_OTHER_PERSONAL.RELATION] || '',
				[FIELD_OTHER_PERSONAL.TEL]: props[FIELD_OTHER_PERSONAL.TEL] || '',
				[FIELD_OTHER_PERSONAL.POSITION]: props[FIELD_OTHER_PERSONAL.POSITION] || '',
				[FIELD_OTHER_PERSONAL.ADDRESS_OFFICE]: props[FIELD_OTHER_PERSONAL.ADDRESS_OFFICE] || '',

			}],
		[FIELD_STEP.COLLAPSE_S46]: props[FIELD_STEP.STEP_4][FIELD_STEP.COLLAPSE_S46] && props[FIELD_STEP.STEP_4][FIELD_STEP.COLLAPSE_S46].length > 0
			? props[FIELD_STEP.STEP_4][FIELD_STEP.COLLAPSE_S46]
			: [{
				[FIELD_OTHER.ASSETS_TYPE]: props[FIELD_OTHER.ASSETS_TYPE] || '',
				[FIELD_OTHER.ASSETS_TYPE_NAME]: props[FIELD_OTHER.ASSETS_TYPE_NAME] || '',
				[FIELD_OTHER.ASSETS_DELIVERY_DATE]: props[FIELD_OTHER.ASSETS_DELIVERY_DATE] || '',
				[FIELD_OTHER.ASSETS_RETURN_DATE]: props[FIELD_OTHER.ASSETS_RETURN_DATE] || '',

			}],
		[FIELD_STEP.COLLAPSE_S47]: props[FIELD_STEP.STEP_4][FIELD_STEP.COLLAPSE_S47] && props[FIELD_STEP.STEP_4][FIELD_STEP.COLLAPSE_S47].length > 0
			? props[FIELD_STEP.STEP_4][FIELD_STEP.COLLAPSE_S47]
			: [{
				[FIELD_OTHER.CRIME_SEND_DATE]: props[FIELD_OTHER.CRIME_SEND_DATE] || '',
				[FIELD_OTHER.CRIME_RECIVED_DATE]: props[FIELD_OTHER.CRIME_RECIVED_DATE] || '',
				[FIELD_OTHER.CRIME_RESULT]: props[FIELD_OTHER.CRIME_RESULT] || '',

			}],
		[FIELD_STEP.COLLAPSE_S49]: props[FIELD_STEP.STEP_4][FIELD_STEP.COLLAPSE_S49] && props[FIELD_STEP.STEP_4][FIELD_STEP.COLLAPSE_S49].length > 0
			? props[FIELD_STEP.STEP_4][FIELD_STEP.COLLAPSE_S49]
			: [{
				[FIELD_OTHER.ADDICTIVE_DATE]: props[FIELD_OTHER.ADDICTIVE_DATE] || '',
				[FIELD_OTHER.ADDICTIVE_RESULT]: props[FIELD_OTHER.ADDICTIVE_RESULT] || '',
				[FIELD_OTHER.ADDICTIVE_REMARK]: props[FIELD_OTHER.ADDICTIVE_REMARK] || '',

			}],
		// }
		/* -------------------------------------------------------------------------- */
		/* --------------------------------- STEP_5 --------------------------------- */
		// [FIELD_STEP.STEP_5]: {
		// [FIELD_STEP.COLLAPSE_S51]: {
		[FIELD_OTHER.FILE_PERSONAL_ID]: props[FIELD_OTHER.FILE_PERSONAL_ID] || [],
		[FIELD_OTHER.FILE_HOUSE_REGIS]: props[FIELD_OTHER.FILE_HOUSE_REGIS] || [],
		[FIELD_OTHER.FILE_TRANSCRIPT]: props[FIELD_OTHER.FILE_TRANSCRIPT] || [],
		[FIELD_OTHER.FILE_RESUME]: props[FIELD_OTHER.FILE_RESUME] || [],
		[FIELD_OTHER.FILE_LANG_TEST]: props[FIELD_OTHER.FILE_LANG_TEST] || [],
		[FIELD_OTHER.FILE_GUARANTEE]: props[FIELD_OTHER.FILE_GUARANTEE] || [],
		[FIELD_OTHER.FILE_BOOKBANK]: props[FIELD_OTHER.FILE_BOOKBANK] || [],
		[FIELD_OTHER.FILE_OTHER_1]: props[FIELD_OTHER.FILE_OTHER_1] || [],
		[FIELD_OTHER.FILES_CURRENTS]: {
			[FIELD_OTHER.FILE_PERSONAL_ID]: props[FIELD_OTHER.FILE_PERSONAL_ID] || [],
			[FIELD_OTHER.FILE_HOUSE_REGIS]: props[FIELD_OTHER.FILE_HOUSE_REGIS] || [],
			[FIELD_OTHER.FILE_TRANSCRIPT]: props[FIELD_OTHER.FILE_TRANSCRIPT] || [],
			[FIELD_OTHER.FILE_RESUME]: props[FIELD_OTHER.FILE_RESUME] || [],
			[FIELD_OTHER.FILE_LANG_TEST]: props[FIELD_OTHER.FILE_LANG_TEST] || [],
			[FIELD_OTHER.FILE_GUARANTEE]: props[FIELD_OTHER.FILE_GUARANTEE] || [],
			[FIELD_OTHER.FILE_BOOKBANK]: props[FIELD_OTHER.FILE_BOOKBANK] || [],
			[FIELD_OTHER.FILE_OTHER_1]: props[FIELD_OTHER.FILE_OTHER_1] || [],
			[FIELD_PERSONAL.PROFILE_IMAGE]: props[FIELD_PERSONAL.PROFILE_IMAGE] || [],
		}
		// [FIELD_OTHER.FILE_OTHER_2]: props[FIELD_OTHER.FILE_OTHER_2] || '',
		// [FIELD_OTHER.FILE_OTHER_3]: props[FIELD_OTHER.FILE_OTHER_3] || '',
		// [FIELD_OTHER.FILE_OTHER_4]: props[FIELD_OTHER.FILE_OTHER_4] || '',
		// [FIELD_OTHER.FILE_OTHER_5]: props[FIELD_OTHER.FILE_OTHER_5] || '',
		// }}
		/* -------------------------------------------------------------------------- */
	}
}
