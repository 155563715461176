export const generalDetailAll = (options = { optionsSigner: [] }) => {
  return [
    {
      ref: "signer",
      name: "signer",
      label: "ผู้เซ็นรับรองเอกสาร",
      type: "DROPDOWN",
      span: 12,
      placeholder: "ผู้เซ็นรับรองเอกสาร (ภาษาอังกฤษ)",
      properties: {
        options: options.optionsSigner,
      },
      rules: {
        required: "กรุณาระบุ ผู้เซ็นรับรองเอกสาร (ภาษาอังกฤษ)",
      },
    },
    {
      ref: `startDateJob`,
      name: `startDateJob`,
      key: `startDateJob`,
      placeholder: "วันที่เริ่มงาน",
      label: "วันที่เริ่มงาน",
      type: "DATE_PICKER",
      span: 4,
      rules: {
        required: true,
      },
    },
  ];
};

export const generalDetailTH = (
  options = {
    optionsDepartmentTH: [],
    optionsSignerTH: [],
  }
) => {
  return [
    {
      ref: "titleTH",
      name: "titleTH",
      label: "คำนำหน้า (ภาษาไทย)",
      type: "DROPDOWN",
      span: 6,
      placeholder: "คำนำหน้า (ภาษาไทย)",
      properties: {
        options: [
          { value: "นาย", label: "นาย" },
          { value: "นาง", label: "นาง" },
          { value: "นางสาว", label: "นางสาว" },
        ],
      },
      rules: {
        required: "กรุณาระบุ คำนำหน้า (ภาษาไทย)",
      },
    },
    {
      ref: "nameTH",
      name: "nameTH",
      label: "ชื่อ (ภาษาไทย)",
      type: "INPUT",
      span: 18,
      placeholder: "ชื่อ (ภาษาไทย)",
      rules: {
        required: "กรุณาระบุ ชื่อ (ภาษาไทย)",
      },
    },
    {
      ref: "departmentNameTH",
      name: "departmentNameTH",
      label: "แผนก (ภาษาไทย)",
      type: "AUTO_COMPLETE",
      span: 12,
      placeholder: "แผนก (ภาษาไทย)",
      rules: {
        required: "กรุณาระบุ แผนก (ภาษาไทย)",
      },
      options: options.optionsDepartmentTH,
    },
  ];
};

export const generalDetailEN = (
  options = {
    optionsDepartmentEN: [],
  }
) => {
  return [
    {
      ref: "titleEN",
      name: "titleEN",
      label: "คำนำหน้า (ภาษาอังกฤษ)",
      type: "DROPDOWN",
      span: 6,
      placeholder: "คำนำหน้า (ภาษาอังกฤษ)",
      properties: {
        options: [
          { value: "Mister", label: "Mister" },
          { value: "Missus", label: "Missus" },
          { value: "Miss", label: "Miss" },
        ],
      },
      rules: {
        required: "กรุณาระบุ คำนำหน้า (ภาษาอังกฤษ)",
      },
    },
    {
      ref: "nameEN",
      name: "nameEN",
      label: "ชื่อ (ภาษาอังกฤษ)",
      type: "INPUT",
      span: 18,
      placeholder: "ชื่อ (ภาษาอังกฤษ)",
      rules: {
        required: "กรุณาระบุ ชื่อ (ภาษาอังกฤษ)",
      },
    },
    {
      ref: "departmentNameEN",
      name: "departmentNameEN",
      label: "แผนก (ภาษาอังกฤษ)",
      type: "AUTO_COMPLETE",
      span: 12,
      placeholder: "แผนก (ภาษาอังกฤษ)",
      options: options.optionsDepartmentEN,
      rules: {
        required: "กรุณาระบุ แผนก (ภาษาอังกฤษ)",
      },
    },
  ];
};
