import React, { useState } from "react";
import { Form, Card, Row, Spin } from "antd";
import { Sub_Department, USE_CONNECT_MODE_AUTO } from "../../services/ConnectAPI";
import CustomTableAtnd from "../tables/CustomTableAtnd";
import { useNotification } from "../notification/NotificationWithIcon";
import { useColumnTable } from "../../constants/UseColumnTable";
import { RenderMainModal } from "../modal/CustomModalAntd";
import { useExportExcel } from "../../services/ExportExcel";
import { Header_PageSubDepartment } from "../../constants/HeaderExcel";
import { GET_UserLogin, sumColumnWidth, toUndefined } from "../../functions/UseFunction";
import { useFixedQueryString } from "../../functions/UseMakeQueryString";
import { useOptionSelect } from "../../constants/OptionSelect";
import { useConfirm } from "../modal/CustomConfirm";
import { useAlert } from "../modal/CustomAlert";
import { configForm, WORDS } from "../../constants/ConstantComponent";
import { useRenderFormItem } from "../RenderComponents";
import { useText } from "../typography/Typography";
import { ComponentsSearchSubDepartment } from "../../constants/ManageSubDepartment/Components";

export const FormSearchSubDepartment = () => {
  const [form] = Form.useForm();
  const { userlogin } = GET_UserLogin();
  // [START] States
  const [changeValue, setChangeValue] = useState({});
  const [dataTable, setDataTable] = useState([]);
  const [dataRow, setDataRow] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [modalUserCount, setModalUserCount] = useState([]);
  const [loadingScreen, setLoadingScreen] = useState(false);
  const [loadingContentModal, setLoadingContentModal] = useState(false);

  let paramDepNo = useFixedQueryString(changeValue, ["companyCode"]);
  let paramSubDep = useFixedQueryString(changeValue, ["company", "depNo"]);
  let paramSearch = useFixedQueryString(changeValue, ["company", "depNo", "subDepName"]);

  let options = {
    companyCode: useOptionSelect("company"),
    depNo: useOptionSelect("department", paramDepNo),
    subDepNo: useOptionSelect("subDepartmentName", paramSubDep),

  }
  // [END] States

  // [START] Handle Form
  const onFinish = (values) => {
    searchSubDepartment();
  };
  const onReset = () => {
    form.resetFields();
    setChangeValue({});
    setDataTable([]);
  };
  // [END] Handle Form

  // [START] REST API
  const searchSubDepartment = async () => {
    try {
      setLoadingScreen(true);
      setDataTable([]);
      const url = `${USE_CONNECT_MODE_AUTO}${Sub_Department.GET_Search_Sub_Department}?${paramSearch}`;
      const response = await fetch(url);
      const data = await response.json();
      let Map_RESULT = [];
      if (response.ok) {
        const RESULT = data.result;
        if (RESULT.length !== 0) {
          Map_RESULT = RESULT.map((val, index) => ({
            key: index,
            ...val,
          }));
        } else {
          useNotification({ type: "info", title: "ไม่พบข้อมูล" });
        }
      }
      setDataTable(Map_RESULT);
    } catch (err) {
      console.log("Error Search Sub-Department: ", err);
      useAlert({ type: "catch", content: `${err.message}` });
    } finally {
      setLoadingScreen(false);
    }
  };

  const putDEACTIVE = async (record) => {
    if (!record) return null;
    try {
      setLoadingScreen(true);
      let statusActive = record.statusActive ? `inactive` : `active`;
      let statusChange = record.statusActive ? false : true;
      const url = `${USE_CONNECT_MODE_AUTO}${Sub_Department.PUT_Deactive_Sub_Department}${record.subDepNo}/${statusActive}`;
      const response = await fetch(url, {
        method: `PUT`,
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ userlogin }),
      });
      const data = await response.json();
      if (response.ok) {
        useAlert({
          type: "success",
          content: `การจัดการสถานะสำเร็จ`,
          Fn: () =>
            setDataTable(
              dataTable.map((val) =>
                val.subDepNo === record.subDepNo
                  ? { ...val, statusActive: statusChange }
                  : val
              )
            ),
        });
      } else {
        useAlert({
          type: "info",
          title: "การจัดการสถานะไม่สำเร็จ",
          content: `${data.message}`,
        });
      }
    } catch (err) {
      console.log("Error deActive Sub-Department: ", err);
      useAlert({ type: "catch", content: `${err.message}` });
    } finally {
      setLoadingScreen(false);
    }
  };

  const getEmployeeList = async (record) => {
    try {
      setShowModal(true);
      setDataRow(record);
      setLoadingContentModal(true);
      setModalUserCount([]);
      const url = `${USE_CONNECT_MODE_AUTO}${Sub_Department.GET_Employee_List}${record["subDepNo"]}`;
      const response = await fetch(url);
      const data = await response.json();
      let Map_RESULT = [];
      if (response.ok) {
        const RESULT = data.result;
        Map_RESULT = RESULT.map((val, index) => ({
          key: index,
          ID_User: val.ID_User,
          Name: val.Name,
          NickName: val.NickName,
        }));
      }
      setModalUserCount(Map_RESULT);
    } catch (err) {
      console.log("Error Data Modal Sub-Department: ", err);
      useAlert({ type: "catch", content: `${err.message}` });
    } finally {
      setLoadingContentModal(false);
    }
  };
  // [END] REST API

  // [START] Functions
  const onChangeValue = (val, name) => {
    let setUndifined = {};
    let setName = {};
    let ArrayInput = Object.keys(form.getFieldsValue());
    switch (name) {
      case "companyCode":
        setName = { company: val }
        setUndifined = toUndefined(ArrayInput.slice(1));
        break;
      case "depNo":
        setUndifined = toUndefined(ArrayInput.slice(2));
        break;
      case "subDepNo":
        setName = { subDepName: val }
        break;
    }
    setDataTable([]);
    form.setFieldsValue(setUndifined);
    setChangeValue((prev) => ({
      ...prev,
      ...setUndifined,
      ...setName,
      [name]: val,
    }));
  };
  // [END] Functions

  // [START] Control Column
  const { ColumnSubDepartment, ColumnCountEmployees } = useColumnTable({
    Config: {
      openModal: getEmployeeList,
      fnDelete: (data) => useConfirm({ FnOK: () => putDEACTIVE(data) }),
    },
  });
  // [END] Control Column
  const contentModalUsersSubDepartment = () => {
    return (
      <div style={{ width: "100%" }}>
        <Spin tip={WORDS.loadingSpin} spinning={loadingContentModal}>
          {modalUserCount.length !== 0 ? (
            <CustomTableAtnd
              HeadTable={ColumnCountEmployees}
              DataTable={modalUserCount}
              SetScroll={{ x: sumColumnWidth(ColumnCountEmployees) || "20%" }}
              TableLayout={`fixed`}
            />
          ) : (
            useText({ type: "danger", text: "* ไม่พบข้อมูลการโอนย้าย" })
          )}
        </Spin>
      </div>
    );
  };

  const closeModal = () => {
    setShowModal(false);
    setDataRow([])
  }
  return (
    <Spin tip={WORDS.loadingSpin} spinning={loadingScreen}>
      <RenderMainModal
        show={showModal}
        onCancel={closeModal}
        txtTitle={`สมาชิก : ${dataRow.subDepName}`}
        ModalContent={contentModalUsersSubDepartment()}
      />
      <Card title="จัดการแผนกย่อย" style={{ marginBottom: 30 }}>
        <Form form={form} onFinish={onFinish} {...configForm}>
          {useRenderFormItem({
            constantComponent: ComponentsSearchSubDepartment({
              options,
              onClick: onReset,
              handleChange: onChangeValue,
            })
          })}
        </Form>
      </Card>
      {dataTable.length !== 0 && (
        <>
          <Row justify="end" align="middle" style={{ marginBottom: 30 }}>
            {useExportExcel({
              header: Header_PageSubDepartment,
              dataset: dataTable,
              filename: "รายงานแผนกย่อย",
            })}
          </Row>
          <CustomTableAtnd
            HeadTable={ColumnSubDepartment}
            SetScroll={{ x: sumColumnWidth(ColumnSubDepartment) || 1080, y: 500 }}
            DataTable={dataTable}
          />
        </>
      )}
    </Spin>
  );
};

export default FormSearchSubDepartment;
