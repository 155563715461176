import { useGetFormList } from "../hook/useGetFormList";
import { generalDetailTH, generalDetailEN } from "../forms/generalDetail";
import { documentOptionList } from "../constants/documentOptionList";

export const mapSelectedOptionData = (value, documentList) => {
  const { getFormInputList } = useGetFormList({});
  let docSelected = [];
  for (const document of documentList) {
    //loop document type, example certEmployTH,certEmployEN
    if (!value[`isSelected_${document.docName}`]) {
      //if user did not select
      continue;
    }
    let docLine = { docName: document.docName };
    const formList = getFormInputList(document.docName).filter(
      (e) => e.relation
    );
    const transferForm = formList.filter((e) => e.relation == "isTransfer");
    const quitForm = formList.filter((e) => e.relation == "isQuit");
    const salaryForm = formList.filter((e) => e.relation == "showSalary");
    const apexForm = formList.filter((e) => e.relation == "isApex");
    const footerForm = formList.filter((e) => e.relation == "footer");
    docLine = {
      ...docLine,
      option: {
        ...mapIsTransfer(value, document.docName, transferForm),
        ...mapIsQuit(value, document.docName, quitForm),
        ...mapShowSalary(value, document.docName, salaryForm),
        ...isApexMap(value, document.docName, apexForm),
        ...mapGeneralValue(value, document.lang),
        ...mapFooter(value, footerForm),
      },
    };

    docSelected.push(docLine);
  }
  return docSelected;
};

const mapGeneralValue = (value, lang) => {
  const generalFormList = lang == "EN" ? generalDetailEN() : generalDetailTH(); //Determine if form is EN or TH
  let option = {};
  for (const generalForm of generalFormList) {
    //loop general value.
    option[generalForm.ref] = value[generalForm.ref];
  }
  option.startDateJob = value.startDateJob;
  if (lang == "EN") {
    option.prefixEN = value.titleEN == "Mister" ? "He" : "She";
  }
  return option;
};

const mapIsTransfer = (value, docName, formList) => {
  if (formList.length == 0) {
    return {};
  }
  if (!value[`isTransfer_${docName}`]) {
    return { isTransfer: false };
  } else {
    let option = { isTransfer: true };
    for (const form of formList) {
      option[form.key] = value[form.ref];
    }
    return option;
  }
};

const mapIsQuit = (value, docName, formList) => {
  if (formList.length == 0) {
    return {};
  }
  if (!value[`isQuit_${docName}`]) {
    return { isQuit: false };
  } else {
    let option = { isQuit: true };
    for (const form of formList) {
      option[form.key] = value[form.ref];
    }
    return option;
  }
};

const mapShowSalary = (value, docName, formList) => {
  if (formList.length == 0) {
    return {};
  }
  if (
    value[`showSalary_${docName}`] ||
    documentOptionList[docName].includes("salary")
  ) {
    let option = { showSalary: true };
    for (const form of formList) {
      option[form.key] = value[form.ref];
    }
    return option;
  } else {
    return { showSalary: false };
  }
};

const isApexMap = (value, _docName, formList) => {
  if (formList.length == 0) {
    return {};
  }
  let option = {};
  for (const form of formList) {
    option[form.key] = value[form.ref];
  }
  return option;
};

const mapFooter = (value, formList) => {
  let option = {};
  for (const form of formList) {
    option[form.key] = value[form.ref] || "";
  }
  return option;
};
