import React, { Fragment } from 'react';
import './index.css';


/**
 * 
 * @param {*} breadcrumb - components
 * @param {*} content - components
 * @param {*} search - components
 */

export const LayoutWrapper = ({
    breadcrumb,
    content,
    search,
    exportFile
}) => {
    return (
        <div style={{ position: 'relative' }}>
            <div className="layout-wrapper-breadcrumb">
                {breadcrumb}
            </div>
            {
                (search) && <div className="layout-wrapper-search">
                    {search}
                </div>
            }
            <div className="layout-wrapper-content">
                {
                    (exportFile) && <div className="layout-wrapper-export">
                        {exportFile}
                    </div>
                }
                {content}
            </div>
        </div>
    )
};