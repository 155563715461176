// * * DataCell
export const mainHead = {
  // fontWeight: 'bold',
  // textAlign: 'center',
};

export const dataCell = {
  textAlign: "left",
  marginLeft: "5%",
  whiteSpace: "nowrap",
};

export const dataCellNumber = {
  textAlign: "right",
  marginLeft: "5%",
  whiteSpace: "nowrap",
};

export const dataCellDate = {
  textAlign: "center",
  whiteSpace: "nowrap",
};

export const dataCellRed = {
  color: "red",
};
