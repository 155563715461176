export const Header_PagePosition = [
  {
    sheet: "ตำแหน่งงาน",
    column: [
      { label: "รหัสตำแหน่ง", value: "positionCode" },
      { label: "ชื่อตำแหน่ง (อังกฤษ)", value: "positionNameEN" },
      { label: "ชื่อตำแหน่ง (ไทย)", value: "positionNameTH" },
      { label: "จำนวนคน", value: "userCount" },
    ],
  },
];

export const Header_PageSubDepartment = [
  {
    sheet: "แผนกย่อย",
    column: [
      { label: "รหัสแผนกย่อย", value: "subDepNo" },
      { label: "ชื่อแผนกย่อย", value: "subDepName" },
      { label: "Location", value: "location" },
      { label: "จำนวนคน", value: "userCount" },
    ],
  },
];

export const Header_PageApproval = [
  {
    sheet: "สายอนุมัติ",
    column: [
      { label: "รหัสสายอนุมัติ", value: "flowId" },
      { label: "ชื่อสายอนุมัติ", value: "flowName" },
      { label: "จำนวนคน", value: "userCount" },
    ],
  },
];

export const Header_PagePermissionView = [
  {
    sheet: "สายการดูข้อมูล",
    column: [
      { label: "รหัสสายการดูข้อมูล", value: "flowId" },
      { label: "ชื่อสายการดูข้อมูล", value: "description" },
      { label: "จำนวนคน", value: "userCount" },
    ],
  },
];

export const Header_SSO = [
  { label: "รหัสพนักงาน", KEY_Value: "userId" },
  { label: "ชื่อสถานประกอบการ", KEY_Value: "companyCode" },
  { label: "ขื่อ-นามสกุล", KEY_Value: "fullName" },
  { label: "วันที่เริ่มงาน", KEY_Value: "startDateJob" },
  { label: "วันเดือนปีเกิด", KEY_Value: "dateOfBirth" },
  { label: "รหัสบัตรประชาชน 13 หลัก", KEY_Value: "personalId" },
  { label: "โรงพยาบาลลำดับที่ 1", KEY_Value: "hospital1" },
  { label: "โรงพยาบาลลำดับที่ 2", KEY_Value: "hospital2" },
  { label: "โรงพยาบาลลำดับที่ 3", KEY_Value: "hospital3" },
];

export const Header_ReportAll = (columnTable = []) => {
  let column = columnTable.map((row) => ({
    label: row.title,
    value: row.dataIndex,
  }));
  return [
    {
      sheet: "รายงานข้อมูลโปรไฟล์",
      column: [...[{ label: "ลำดับที่", value: "numRow" }], ...column],
    },
  ];
};

export const Header_ReportEmployeeSummary = (month) => [
  {
    sheet: month.month,
    column: [
      { label: "รหัสบริษัท", value: "company" },
      { label: "Location", value: "location" },
      { label: "รหัสแผนก", value: "depNo" },
      { label: "ชื่อแผนก", value: "depName" },
      { label: "รหัสแผนกย่อย", value: "subDepNo" },
      { label: "ชื่อแผนกย่อย", value: "subDepName" },
      { label: "เข้าใหม่", value: "newEmployee" },
      { label: "พ้นสภาพ", value: "outEmployee" },
      { label: "ย้ายเข้าหน่วยงาน", value: "moveInEmployee" },
      { label: "จำนวนพนักงาน", value: "totalEmployee" },
    ],
  },
  {
    sheet: month.month_2,
    column: [
      { label: "รหัสบริษัท", value: "company" },
      { label: "Location", value: "location" },
      { label: "รหัสแผนก", value: "depNo" },
      { label: "ชื่อแผนก", value: "depName" },
      { label: "รหัสแผนกย่อย", value: "subDepNo" },
      { label: "ชื่อแผนกย่อย", value: "subDepName" },
      { label: "เข้าใหม่", value: "newEmployee_2" },
      { label: "พ้นสภาพ", value: "outEmployee_2" },
      { label: "ย้ายเข้าหน่วยงาน", value: "moveInEmployee_2" },
      { label: "จำนวนพนักงาน", value: "totalEmployee_2" },
    ],
  },
];

export const Header_ReportLevelWorker = [
  {
    sheet: "เกรด (Level Worker)",
    column: [
      { label: "รหัสแผนก", value: "depNo" },
      { label: "ชื่อแผนก", value: "depName" },
      { label: "MAN", value: "MAN" },
      { label: "PRO", value: "PRO" },
      { label: "STF", value: "STF" },
      { label: "SUP", value: "SUP" },
      { label: "รวมทั้งสิ้น", value: "total" },
    ],
  },
];

export const Header_ReportPersonal = [
  {
    sheet: "รายงานรายชื่อพนักงานประจำ",
    column: [
      { label: "รหัสพนักงาน", value: "ID_User" },
      { label: "Username", value: "Username" },
      { label: "คำนำหน้า", value: "title" },
      { label: "ชื่อ - นามสกุล", value: "Name" },
      { label: "ชื่อเล่น", value: "NickName" },
      { label: "บริษัท", value: "company" },
      { label: "สถานที่", value: "locationName" },

      { label: "แผนก", value: "Department_Name" },
      { label: "แผนกย่อย", value: "subDepName" },
      { label: "ตำแหน่ง", value: "PositionNameEN" },
      { label: "สายการอนุมัติ", value: "Flow_Name" },
      { label: "วันที่เริ่มงาน", value: "StartDateJob" },
      { label: "วันที่ผ่านทดลองงาน", value: "probationDateEnd" },
      { label: "วันที่พ้นสภาพการทำงาน", value: "EndDateJob" },
      { label: "สถานภาพพนักงาน", value: "currentStatusDes" },
      { label: "สถานะพนักงาน", value: "statusWorker" },
      { label: "ไซส์เสื้อ", value: "shirtSize" },
      { label: "กลุ่มเงินเดือน", value: "payrollGroupDes" },
      { label: "สแกนลายนิ้วมือ", value: "codeMapFingerScan" },
      { label: "รหัสบัตรประชาชน", value: "Personal_id" },
    ],
  },
];

export const Header_ReportEmployeeAge = () => {
  let ColumnAge = [];
  for (let i = 18; i <= 60; i++) {
    ColumnAge.push({
      label: i,
      value: i,
    });
  }
  return [
    {
      sheet: "รายงานจำนวนพนักงานตามช่วงอายุ",
      column: [
        { label: "บริษัท", value: "company" },
        { label: "รหัสแผนก", value: "depNo" },
        { label: "ชื่อแผนก", value: "depName" },
        { label: "Location", value: "location" },
        ...ColumnAge,
        { label: "รวมทั้งสิ้น", value: "total" },
      ],
    },
  ];
};

export const Header_ReportWorkAge = () => {
  let ColumnAge = [];
  for (let i = 0; i <= 20; i++) {
    ColumnAge.push({
      label: i,
      value: i,
    });
  }
  return [
    {
      sheet: "รายงานจำนวนพนักงานตามช่วงอายุงาน",
      column: [
        { label: "บริษัท", value: "company" },
        { label: "รหัสแผนก", value: "depNo" },
        { label: "ชื่อแผนก", value: "depName" },
        { label: "รหัสแผนกย่อย", value: "subDepNo" },
        { label: "ชื่อแผนกย่อย", value: "subDepName" },
        { label: "Location", value: "location" },
        ...ColumnAge,
        { label: "รวมทั้งสิ้น", value: "total" },
      ],
    },
  ];
};

export const Header_ReportStatusWorker = [
  {
    sheet: "รายงานจำนวนพนักงานตามสถานะการจ้าง",
    column: [
      { label: "รหัสบริษัท", value: "companyCode" },
      { label: "ชื่อบริษัท", value: "companyName" },
      { label: "Location", value: "location" },
      { label: "พนักงานประจำรายเดือน", value: "Full" },
      { label: "พนักงานประจำรายวัน", value: "Daily" },
      { label: "พนักงานชั่วคราว", value: "Temp" },
      { label: "จำนวนพนักงานในสถานที่ทำงาน", value: "total" },
    ],
  },
];

export const Header_ReportCreateCrime = [
  {
    sheet: "รายงานผลตรวจอาชญากรรม",
    column: [
      { label: "ลำดับ", value: "numRow" },
      { label: "รหัสพนักงาน", value: "ID_User" },
      { label: "คำนำหน้า", value: "title" },
      { label: "ชื่อพนักงาน", value: "Name" },
      { label: "เลขประจำตัวประชาชน", value: "Personal_id" },
      { label: "วันเดือนปีเกิด", value: "birthday" },
      { label: "ชื่อบิดา-มารดา", value: "parentNames" },
      { label: "ที่อยู่ปัจจุบัน", value: "presentAddress" },
      { label: "บริษัท", value: "company" },
      { label: "Location", value: "location" },
      { label: "แผนก", value: "depName" },
      { label: "แผนกย่อย", value: "subDepName" },
      { label: "อีเมล", value: "email" },
      { label: "เบอร์โทร", value: "telNo" },
      { label: "วันที่เริ่มงาน", value: "startDateJob" },
      { label: "ผู้สรรหา", value: "recruiterNickname" },
    ],
  },
];

export const Header_ReportShowDetailCrime = [
  {
    sheet: "รายงานผลตรวจอาชญากรรม",
    column: [
      // { label: "ลำดับ", value: "numRow" },
      // { label: "คำนำหน้า", value: "title" },
      // { label: "ชื่อพนักงาน", value: "Name" },
      // { label: "เลขประจำตัวประชาชน", value: "Personal_id" },
      // { label: "วันเดือนปีเกิด", value: "birthday" },
      // { label: "ชื่อบิดา-มารดา", value: "parentNames" },
      // { label: "ที่อยู่ปัจจุบัน", value: "presentAddress" },
      // { label: "ผลการตรวจสอบ", value: "crimeResult" },
      { label: "ลำดับ", value: "numRow" },
      { label: "รหัสพนักงาน", value: "ID_User" },
      { label: "คำนำหน้า", value: "title" },
      { label: "ชื่อพนักงาน", value: "Name" },
      { label: "เลขประจำตัวประชาชน", value: "Personal_id" },
      { label: "วันเดือนปีเกิด", value: "birthday" },
      { label: "ชื่อบิดา-มารดา", value: "parentNames" },
      { label: "ที่อยู่ปัจจุบัน", value: "presentAddress" },
      { label: "บริษัท", value: "company" },
      { label: "Location", value: "location" },
      { label: "แผนก", value: "depName" },
      { label: "แผนกย่อย", value: "subDepName" },
      { label: "อีเมล", value: "email" },
      { label: "เบอร์โทร", value: "telNo" },
      { label: "วันที่เริ่มงาน", value: "startDateJob" },
      { label: "ผู้สรรหา", value: "recruiterNickname" },
    ],
  },
];

export const Header_ReportSSO_NewEmp = [
  {
    sheet: "รายงานการยื่นประกันสังคมพนักงานเข้าใหม่",
    column: [
      { label: "รหัสพนักงาน", value: "userId" },
      { label: "ชื่อพนักงาน", value: "fullname" },
      { label: "ชื่อเล่น", value: "nickname" },
      { label: "สถานะยื่นประกันสังคม", value: "statusDes" },
      { label: "วันที่ยื่น", value: "dataStamp" },
      { label: "ชุดเอกสาร", value: "docRef" },
      { label: "เคยมีบัตรประกันสังคม", value: "hasSSO" },
      { label: "กรอกข้อมูลเลือกโรงพยาบาล", value: "fillDataDes" },
      { label: "บริษัท", value: "companyCode" },
      { label: "แผนก", value: "depNo" },
      { label: "ชื่อแผนก", value: "depName" },
      { label: "รหัสแผนกย่อย", value: "subDepNo" },
      { label: "แผนกย่อย", value: "subDepName" },
      { label: "เลขประจำตัวประชาชน", value: "personal_id" },
      { label: "วันที่เริ่มงาน", value: "startDateJob" },
      { label: "โรงพยาบาลลำดับที่ 1", value: "hospitalNameSq1" },
      { label: "โรงพยาบาลลำดับที่ 2", value: "hospitalNameSq2" },
      { label: "โรงพยาบาลลำดับที่ 3", value: "hospitalNameSq3" },
    ],
  },
];

export const Header_ReportSSO_OutEmp = [
  {
    sheet: "รายงานการยื่นประกันสังคมพนักงานพ้นสภาพ",
    column: [
      { label: "รหัสพนักงาน", value: "userId" },
      { label: "ชื่อพนักงาน", value: "fullname" },
      { label: "ชื่อเล่น", value: "nickname" },
      { label: "สถานะยื่นประกันสังคม", value: "statusDes" },
      { label: "วันที่ยื่น", value: "dataStamp" },
      { label: "ชุดเอกสาร", value: "docRef" },
      { label: "บริษัท", value: "companyCode" },
      { label: "แผนก", value: "depNo" },
      { label: "ชื่อแผนก", value: "depName" },
      { label: "รหัสแผนกย่อย", value: "subDepNo" },
      { label: "แผนกย่อย", value: "subDepName" },
      { label: "เลขประจำตัวประชาชน", value: "personal_id" },
      { label: "วันสิ้นสุดการทำงาน", value: "endDateJob" },
    ],
  },
];

export const Header_BenefitEmpNew = [
  {
    sheet: "พนักงานเข้าใหม่(พนักงานประจำ)",
    column: [
      { label: "รหัสพนักงาน", value: "userId" },
      { label: "คำนำหน้า", value: "title" },
      { label: "ชื่อพนักงาน", value: "fullname" },
      { label: "ชื่อเล่น", value: "nickname" },
      { label: "หมายเลขบัตรประชาชน", value: "personal_ID" },
      { label: "วันเกิด", value: "brithday" },
      { label: "สถานะ (ประเมินงาน)", value: "statusMainCodeDes" },
      { label: "ชื่อผู้บังคับบัญชา", value: "apprivalName1" },
      { label: "แผนก/กลุ่ม", value: "group" },
      { label: "แผนประกันสุขภาพ", value: "planHEA" },
      { label: "แผนประกันอุบัติเหตุ", value: "planPVD" },
      { label: "วันที่ยื่นประกันสุขภาพ/อุบัติเหตุ", value: "date_HEA" },
      { label: "วันที่ยื่นกองทุนสำรองเลี้ยงชีพ", value: "date_PVD" },
      { label: "วันที่เริ่มงาน", value: "startDateJob" },
      { label: "วันผ่านทดลองงาน", value: "probationEndDate" },
      { label: "วันที่ HR รับเอกสาร", value: "HR_ReceivedDate" },
      { label: "บริษัท", value: "company" },
      { label: "สถานที่", value: "Location" },
      { label: "แผนก", value: "depNo" },
      { label: "ชื่อแผนก", value: "depName" },
      { label: "รหัสแผนกย่อย", value: "subDepNo" },
      { label: "แผนกย่อย", value: "subDepName" },
    ],
  },
];

export const Header_BenefitEmpNewTemp = [
  {
    sheet: "พนักงานเข้าใหม่(สัญญาจ้าง)",
    column: [
      { label: "รหัสพนักงาน", value: "userId" },
      { label: "คำนำหน้า", value: "title" },
      { label: "ชื่อพนักงาน", value: "fullname" },
      { label: "ชื่อเล่น", value: "nickname" },
      { label: "หมายเลขบัตรประชาชน", value: "personal_ID" },
      { label: "วันเกิด", value: "brithday" },
      { label: "วันที่เริ่มงาน", value: "startDateJob" },
      { label: "วันสิ้นสุดสัญญาจ้าง", value: "endContractDate" },
      { label: "วันที่ผ่านทดลองงาน", value: "passProbationDate" },
      { label: "วันที่ HR รับเอกสาร", value: "HR_ReceivedDate" },
      { label: "สถานะ (ประเมินงาน)", value: "statusMainCodeDes" },
      { label: "รอบสัญญาจ้าง", value: "roundContract" },
      { label: "ชื่อผู้บังคับบัญชา", value: "apprivalName1" },
      { label: "แผนก/กลุ่ม", value: "group" },
      { label: "แผนประกันสุขภาพ", value: "planHeaDes" },
      { label: "แผนประกันอุบัติเหตุ", value: "planAcdDes" },
      { label: "วันที่ยื่นประกันสุขภาพ/อุบัติเหตุ", value: "date_HEA" },
      //{ label: "วันที่ยื่นกองทุนสำรองเลี้ยงชีพ", value: "date_PVD" },
      { label: "บริษัท", value: "company" },
      { label: "Location", value: "Location" },
      { label: "แผนก", value: "depNo" },
      { label: "ชื่อแผนก", value: "depName" },
      { label: "รหัสแผนกย่อย", value: "subDepNo" },
      { label: "แผนกย่อย", value: "subDepName" },
    ],
  },
];

export const Header_BenefitEmpOut = [
  {
    sheet: "พนักงานพ้นสภาพ",
    column: [
      { label: "รหัสพนักงาน", value: "userId" },
      { label: "คำนำหน้า", value: "title" },
      { label: "ชื่อพนักงาน", value: "fullname" },
      { label: "ชื่อเล่น", value: "nickname" },
      { label: "หมายเลขบัตรประชาชน", value: "personal_ID" },
      { label: "วันเกิด", value: "brithday" },
      { label: "ชื่อผู้บังคับบัญชา", value: "approvalName1" },
      { label: "วันที่เริ่มงาน", value: "startDateJob" },
      { label: "วันสิ้นสุดการทำงาน", value: "endDateJob" },
      { label: "วันที่ยื่นประกันสุขภาพ/อุบัติเหตุ", value: "dateOut_HEA" },
      { label: "วันที่ยื่นกองทุนสำรองเลี้ยงชีพ", value: "dateOut_PVD" },
      { label: "สถานะการคืนสินทรัพย์", value: "statusReturn" },
      { label: "วันที่รับเช็ค", value: "dateGetChq" },
      { label: "บริษัท", value: "company" },
      { label: "Location", value: "location" },
      { label: "แผนก", value: "depNo" },
      { label: "ชื่อแผนก", value: "depName" },
      { label: "รหัสแผนกย่อย", value: "subDepNo" },
      { label: "แผนกย่อย", value: "subDepName" },
    ],
  },
];

export const Header_ReportCrimes = [
  {
    sheet: "รายงานผลอาชญากรรม",
    column: [
      { label: "ลำดับ", value: "numRow" },
      { label: "ชื่อพนักงาน", value: "fullname" },
      { label: "เลขบัตรประชาชน", value: "personalId" },
      { label: "วันเดือนปีเกิด", value: "birthday" },
      { label: "ชื่อบิดา-มารดา", value: "parentName" },
      { label: "ที่อยู่ปัจจุบันและภูมิลำเนา", value: "address" },
      { label: "ผลการตรวจสอบ", value: "crimeResult" },


      
    ],
  },
];

export const Header_ReportProbationEmpNew = (activeTab = 1) => [
  {
    sheet: "sheet1",
    column: [
      {
        label: "สถานะหลัก",
        value: activeTab === 1 ? "statusMainCodeDes" : "statusMain",
      },
      { label: "สถานะการผ่านทดลองงาน", value: "probationStatus_Des" },
      { label: "รหัสพนักงาน", value: "StaffCode" },
      { label: "ชื่อพนักงาน", value: "StaffName" },
      { label: "ตำแหน่งงาน", value: "PositionNameEN" },
      { label: "สถานะการจ้าง", value: "statusWorkerDes" },
      { label: "ชื่อผู้บังคับบัญชา", value: "BossName" },
      { label: "แผนก", value: "Department_Name" },
      { label: "การประเมินครั้งล่าสุด", value: "RoundNow" },
      { label: "วันที่เริ่มงาน", value: "StartDateJob" },
      { label: "วันที่ครบกำหนดทดลองงาน", value: "expProDate" },
      { label: "วันที่ผ่านการทดลองงาน", value: "probationEndDate" },
      { label: "วันที่ HR รับเอกสาร", value: "HR_ReceivedDate" },
      { label: "วันที่ออกประกันสุขภาพ / ประกันอุบัติเหตุ", value: "date_HEA" },
      { label: "วันที่ออกกองทุนสำรองเลี้ยงชีพ", value: "date_PVD" },
      { label: "วันที่ออกประกันสังคม", value: "date_SOS" },
    ],
  },
];

export const Header_ReportProbationEmpContract = (activeTab = 1) => [
  {
    sheet: "sheet1",
    column: [
      {
        label: "สถานะหลัก",
        value: activeTab === 1 ? "statusMainCodeDes" : "statusMain",
      },
      { label: "สถานะการผ่านทดลองงาน", value: "probationStatus_Des" },
      { label: "รหัสพนักงาน", value: "StaffCode" },
      { label: "ชื่อพนักงาน", value: "StaffName" },
      { label: "ชื่อผู้บังคับบัญชา", value: "BossName" },
      { label: "แผนก ", value: "Department_Name" },
      { label: "การประเมินครั้งล่าสุด", value: "RoundNow" },
      { label: "วันที่ครบกำหนดทดลองงาน", value: "expProDate" },
      { label: "วันที่ผ่านการทดลองงาน", value: "probationEndDate" },
      { label: "วันที่ HR รับเอกสาร", value: "HR_ReceivedDate" },
      { label: "วันที่ออกประกันสุขภาพ / ประกันอุบัติเหตุ", value: "date_HEA" },
      { label: "วันที่ออกกองทุนสำรองเลี้ยงชีพ", value: "date_PVD" },
      { label: "วันที่ออกประกันสังคม", value: "date_SOS" },
    ],
  },
];

export const Header_ReportProbationEmpContractNew = (activeTab = 1) => [
  {
    sheet: "sheet1",
    column: [
      // {
      //   label: "สถานะหลัก",
      //   value: "statusMainCodeDes",
      // },
      // { label: "สถานะการผ่านทดลองงาน", value: "probationStatus_Des" },
      { label: "รหัสพนักงาน", value: "StaffCode" },
      { label: "ชื่อพนักงาน", value: "StaffName" },
      { label: "ชื่อเล่น", value: "NickName" },
      { label: "ชื่อผู้บังคับบัญชา", value: "BossName" },
      { label: "แผนก ", value: "Department_Name" },
      { label: "วันที่ประเมินต่อสัญญาจ้าง", value: "beforeEndContractDate" },
      { label: "เกรด", value: "recruiterGrade" },
      { label: "วันที่เริ่มงาน", value: "StartDateJob" },
      { label: "ต่อสัญญาครั้งที่", value: "roundContract" },
      { label: "วันที่สิ้นสุดครั้งล่าสุด", value: "endContractDate" },
      { label: "การประเมินครั้งล่าสุด", value: "RoundNow" },
      { label: "รอบเดือน", value: "endContractMonth" },
      { label: "ระยะเวลาในการต่อสัญญา", value: "contractPeriod" },
      { label: "วันที่เริ่มรอบสัญญาจ้าง", value: "startProbationDate" },
      { label: "วันที่สิ้นสุดสัญญาจ้าง", value: "expProDate" },
      { label: "สถานะเอกสาร", value: "docStatus" },
    ],
  },
];

export const Header_ReportTrackProbation = [
  {
    sheet: "sheet1",
    column: [
      { label: "รหัสพนักงาน", value: "StaffCode" },
      { label: "วันที่เริ่มงาน", value: "StartDateJob" },
      { label: "ชื่อพนักงาน", value: "StaffName" },
      { label: "ตำแหน่งงาน", value: "PositionNameEN" },
      { label: "สถานะการจ้าง", value: "statusWorkerDes" },
      { label: "ชื่อผู้บังคับบัญชา", value: "BossName" },
      { label: "แผนก", value: "Department_Name" },
      { label: "แผนกย่อย", value: "subDepName" },
      { label: "สถานะเอกสาร", value: "statusMain" },
      { label: "รอบปัจจุบัน", value: "RoundNow" },
      { label: "รอบที่ยืนยันแล้ว", value: "status_confirm" },
      { label: "วันที่เริ่มงาน", value: "StartDateJob" },
      { label: "รอบที่ 1", value: "RoundDate_1" },
      { label: "รอบที่ 2", value: "RoundDate_2" },
      { label: "รอบที่ 3", value: "RoundDate_3" },
      { label: "รอบที่ 4", value: "RoundDate_4" },
      { label: "รอบที่ 5", value: "RoundDate_5" },
      { label: "รอบที่ 6", value: "RoundDate_6" },
    ],
  },
];

export const Header_ReportReturn = [
  {
    sheet: "sheet1",
    column: [
      { label: "คำนำหน้า", value: "title" },
      { label: "ชื่อพนักงาน", value: "fullname" },
      { label: "ชื่อเล่น", value: "nickname" },
      { label: "เลขบัตรประชาชน", value: "personalId" },
      { label: "กลับมาทำงานใหม่ (ครั้ง)", value: "numReturn" },
      { label: "วันที่เริ่มงานครั้งแรก", value: "startDateJob" },
      { label: "บริษัท (ล่าสุด)", value: "company" },
      { label: "สถานที่ (ล่าสุด)", value: "location" },
    ],
  },
];

export const Header_ManageDocProbationContract = [
  {
    sheet: "sheet1",
    column: [
      { label: "บริษัท", value: "company" },
      { label: "Location", value: "location" },
      { label: "รหัสพนักงาน", value: "userId" },
      { label: "ชื่อพนักงาน", value: "fullname" },
      { label: "ชื่อเล่น", value: "nickname" },
      { label: "รหัสแผนก", value: "depNo" },
      { label: "ชื่อแผนก", value: "depName" },
      { label: "แผนกย่อย", value: "subDepName" },
      { label: "รอบสัญญา", value: "roundContract" },
      { label: "ผู้บังคับบัญชา", value: "approversName" },
      { label: "วันที่เริ่มงาน", value: "startDateJob" },
      { label: "จำนวนวัน", value: "totalDay" },
    ],
  },
];

export const Header_ReportOT = [
  {
    sheet: "sheet1",
    column: [
      { label: "เลขที่เอกสาร", value: "docNo" },
      { label: "วันที่มีผล", value: "activeDate" },
      { label: "วันที่ขอโอที", value: "startDateTime" },
      { label: "วันสิ้นสุดโอที", value: "endDateTime" },
      { label: "รหัสพนักงาน", value: "userId" },
      { label: "ชื่อ-นามสกุล", value: "Name" },
      { label: "แผนก", value: "depNo" },
      { label: "แผนกย่อย", value: "subDepName" },
      { label: "หมายเหตุ", value: "remark" },
      { label: "จำนวนชั่วโมงที่ขอ", value: "totalHours" },
      { label: "ผู้อนุมัติอันดับ 1", value: "approvalNo1" },
      { label: "ผู้อนุมัติอันดับ 2", value: "approvalNo2" },
      { label: "ผู้อนุมัติอันดับ 3", value: "approvalNo3" },
      { label: "วันที่อนุมัติ (อันดับ 1)", value: "approvalDate1" },
      { label: "วันที่อนุมัติ (อันดับ 2)", value: "approvalDate2" },
      { label: "วันที่อนุมัติ (อันดับ 3)", value: "approvalDate3" },
      { label: "ผู้บันทึกรายการโอที", value: "createBy" },
      { label: "สถานะการยกเลิก", value: "cancelDes" },
      { label: "หมายเหตุ (จาก hr)", value: "remarkHr" },
    ],
  },
];

export const Header_ReportOTUpdate = [
  {
    sheet: "sheet1",
    column: [
      { label: "เลขที่เอกสาร", value: "docNo" },
      { label: "สถานะการขอโอที", value: "statusCompDes" },
      { label: "รหัสพนักงาน", value: "userId" },
      { label: "ชื่อพนักงาน", value: "fullname" },
      { label: "แผนกย่อย", value: "subDepName" },
      { label: "วันที่ขาเข้า", value: "dateIn" },
      { label: "เวลาเข้า", value: "timeIn" },
      { label: "วันที่ขาออก", value: "dateOut" },
      { label: "เวลาออก", value: "timeOut" },
      { label: "วันที่มีผล", value: "activeDate" },
      { label: "วันที่ขอโอที", value: "startDateOT" },
      { label: "โอทีตั้งแต่", value: "startTimeOT" },
      { label: "วันที่สิ้นสุดโอที", value: "endDateOT" },
      { label: "โอทีถึง", value: "endTimeOT" },
      { label: "จำนวนโอทีขอได้", value: "totalHours" },
      { label: "ประเภทพนักงาน", value: "userType" },
    ],
  },
];

export const Header_ChangeInsurancePlan = [
  {
    sheet: "sheet1",
    column: [
      { label: "รหัสพนักงาน", value: "userId" },
      { label: "คำนำหน้า", value: "title" },
      { label: "ชื่อพนักงาน", value: "fullname" },
      { label: "ชื่อเล่น", value: "nickname" },
      { label: "หมายเลขบัตรประชาชน", value: "personalId" },
      { label: "วันเกิด", value: "birthday" },
      { label: "สถานะส่งเอกสาร", value: "statusStampDes" },
      { label: "ประกันสุขภาพ/อุบัติเหตุ", value: "datatimeHEA" },
      { label: "แผนประกันสุขภาพ", value: "planHeaDes" },
      { label: "แผนประกันอุบัติเหตุ", value: "planAcdDes" },
      { label: "อายุงาน (ปี)", value: "yearsWork" },
      { label: "วันที่เริ่มงาน", value: "startDateJob" },
      { label: "สถานะพนักงาน", value: "statusWorkerDes" },
      { label: "บริษัท", value: "company" },
      { label: "สถานที่", value: "location" },
      { label: "ชื่อแผนก", value: "depName" },
      { label: "แผนกย่อย", value: "subDepName" },
    ],
  },
];

export const Header_ManageBenefitTransfer = [
  {
    sheet: "sheet1",
    column: [
      { label: "รหัสพนักงาน", value: "userId" },
      { label: "คำนำหน้า", value: "title" },
      { label: "ชื่อพนักงาน", value: "fullname" },
      { label: "ชื่อเล่น", value: "nickname" },
      { label: "หมายเลขบัตรประชาชน", value: "personalId" },
      { label: "วันเกิด", value: "birthday" },
      { label: "วันที่มีผล", value: "actionDate" },
      { label: "สถานะส่งเอกสาร", value: "statusStampDes" },
      { label: "วันที่ยื่นประกันสุขภาพ/อุบัติเหตุ", value: "datatimeStampHea" },
      { label: "วันที่ยื่นกองทุนสำรองเลี้ยงชีพ", value: "datatimeStampPvd" },
      { label: "แผนประกันสุขภาพ", value: "planInsuranceHea" },
      { label: "แผนประกันอุบัติเหตุ", value: "planInsuranceAcd" },
      { label: "บริษัท", value: "company" },
      { label: "บริษัท (เก่า)", value: "oldCompany" },
      { label: "สถานที่", value: "location" },
      { label: "สถานที่ (เก่า)", value: "oldLocation" },
      { label: "ชื่อแผนก", value: "depName" },
      { label: "ชื่อแผนก (เก่า)", value: "oldDepNoDes" },
      { label: "แผนกย่อย", value: "subDepName" },
      { label: "แผนกย่อย (เก่า)", value: "oldSubDepNoDes" },
    ],
  },
];

export const Header_ManageBenefitTransferOut = [
  {
    sheet: "sheet1",
    column: [
      { label: "รหัสพนักงาน", value: "userId" },
      { label: "คำนำหน้า", value: "title" },
      { label: "ชื่อพนักงาน", value: "fullname" },
      { label: "ชื่อเล่น", value: "nickname" },
      { label: "หมายเลขบัตรประชาชน", value: "personalId" },
      { label: "วันเกิด", value: "birthday" },
      { label: "วันที่มีผล", value: "actionDate" },
      { label: "สถานะส่งเอกสาร", value: "statusStampDes" },
      {
        label: "วันที่แจ้งออกประกันสุขภาพ/อุบัติเหตุ",
        value: "datatimeStampHea",
      },
      { label: "วันที่แจ้งออกกองทุนสำรองเลี้ยงชีพ", value: "datatimeStampPvd" },
      { label: "แผนประกันสุขภาพ", value: "planInsuranceHea" },
      { label: "แผนประกันอุบัติเหตุ", value: "planInsuranceAcd" },
      { label: "บริษัท", value: "company" },
      { label: "บริษัท (ใหม่)", value: "newCompany" },
      { label: "สถานที่", value: "location" },
      { label: "สถานที่ (ใหม่)", value: "newLocation" },
      { label: "ชื่อแผนก", value: "depName" },
      { label: "ชื่อแผนก (ใหม่)", value: "newDepNoDes" },
      { label: "แผนกย่อย", value: "subDepName" },
      { label: "แผนกย่อย (ใหม่)", value: "newSubDepNoDes" },
    ],
  },
];

export const Header_ReportEmployeeTransfer = [
  {
    sheet: "sheet1",
    column: [
      { label: "รหัสพนักงาน", value: "userId" },
      { label: "คำนำหน้า", value: "title" },
      { label: "ชื่อพนักงาน", value: "Name" },
      { label: "สถานะการโอนย้าย", value: "statusTransferDes" },
      { label: "ประเภทการโอนย้าย", value: "actionDes" },
      { label: "วันที่มีผล", value: "actionDate" },
      { label: "วันที่เริ่มงาน", value: "startDate" },
      { label: "วันที่สิ้นสุดการทำงาน", value: "endDateJob" },
      { label: "สถานภาพพนักงาน", value: "currentStatus" },
      { label: "บริษัท", value: "company" },
      { label: "บริษัท (ใหม่)", value: "newCompany" },
      { label: "Location", value: "location" },
      { label: "Location (ใหม่)", value: "newLocation" },
      { label: "รหัสแผนก", value: "depNo" },
      { label: "ชื่อแผนก", value: "depName" },
      { label: "รหัสแผนก (ใหม่)", value: "newDepNo" },
      { label: "ชื่อแผนก (ใหม่)", value: "newDepName" },
      { label: "รหัสแผนกย่อย", value: "subDepNo" },
      { label: "ชื่อแผนกย่อย", value: "subDepName" },
      { label: "รหัสแผนกย่อย (ใหม่)", value: "newSubDepNo" },
      { label: "ชื่อแผนกย่อย (ใหม่)", value: "newSubDepName" },
      { label: "รหัสสายอนุมัติ", value: "hrmFlow" },
      { label: "ชื่อสายอนุมัติ", value: "hrmFlowName" },
      { label: "รหัสสายอนุมัติ (ใหม่)", value: "newHrmFlow" },
      { label: "ชื่อสายอนุมัติ (ใหม่)", value: "newHrmFlowName" },
      { label: "รหัสสายการดูข้อมูล", value: "viewFlow" },
      { label: "ชื่อสายการดูข้อมูล", value: "viewFlowDes" },
      { label: "รหัสสายการดูข้อมูล (ใหม่)", value: "newViewFlow" },
      { label: "ชื่อสายการดูข้อมูล (ใหม่)", value: "newViewFlowDes" },
      { label: "รหัสตำแหน่งงาน", value: "positionNo" },
      { label: "ชื่อตำแหน่งงาน", value: "positionName" },
      { label: "รหัสตำแหน่งงาน (ใหม่)", value: "newPositionNo" },
      { label: "ชื่อตำแหน่งงาน (ใหม่)", value: "newPositionName" },
      { label: "สถานะพนักงาน", value: "statusWorkerDes" },
      { label: "สถานะพนักงาน (ใหม่)", value: "newStatusWorkerDes" },
    ],
  },
];

export const Header_ReportResignRate = [
  {
    sheet: "sheet1",
    column: [
      { label: "รหัสพนักงาน", value: "userId" },
      { label: "ชื่อพนักงาน", value: "fullname" },
      { label: "ชื่อเล่น", value: "nickname" },
      { label: "ชื่อเล่น", value: "nickname" },
      { label: "แผนก", value: "depName" },
      { label: "แผนกย่อย", value: "subDepName" },
      { label: "ตำแหน่งงาน", value: "positionNo" },
      { label: "บริษัท", value: "Company" },
      { label: "สถานที่", value: "location" },
      { label: "สถานะพนักงาน", value: "statusWorkerDes" },
      { label: "วันที่เริ่มงาน", value: "StartDateJob" },
      { label: "วันที่สิ้นสุดการทำงาน", value: "EndDateJob" },
      { label: "เหตุผลการลาออก", value: "reasonLeaveDes" },
      { label: "อายุงาน (ปี.เดือน)", value: "workYears" },
      { label: "เกรดประเมินผลงานรอบล่าสุด", value: "probationGrade" },
      { label: "เกรดทดลองงาน", value: "grade" },
    ],
  },
];

export const Header_ReportWarning = [
  {
    sheet: "sheet1",
    column: [
      { label: "รหัสพนักงาน", value: "userId" },
      { label: "ชื่อพนักงาน", value: "fullname" },
      { label: "ชื่อเล่น", value: "nickname" },
      { label: "บริษัท", value: "Company" },
      { label: "สถานที่", value: "location" },
      { label: "แผนก", value: "depName" },
      { label: "ตำแหน่งงาน", value: "positionNo" },
      { label: "สถานะพนักงาน", value: "statusWorkerDes" },
      { label: "กลุ่มเงินเดือน", value: "payrollGroupDes" },
      { label: "วันที่เริ่มงาน", value: "startDateJob" },
      { label: "จำนวนวันลา (ป่วย)", value: "totalDaySick" },
      { label: "จำนวนวันที่เกิน (ป่วย)", value: "totalDaySick" },
      { label: "% กระทบโบนัส (ป่วย)", value: "percentBonusSick" },
      { label: "จำนวนวันลา (ขาดงาน)", value: "absentDay" },
      { label: "จำนวนวันที่เกิน (ขาดงาน)", value: "overDate" },
      { label: "% กระทบโบนัส (ขาดงาน)", value: "percentBonusAbs" },
      { label: "จำนวนวันลา (ลาอื่น ๆ)", value: "totalDayOtherLeave" },
      { label: "จำนวนวันที่เกิน (ลาอื่น ๆ)", value: "overDayOtherLeave" },
      { label: "% กระทบโบนัส (ลาอื่น ๆ)", value: "percentBonusOtherLeave" },
      { label: "จำนวนวันลา (ลาไม่รับค่าจ้าง)", value: "totalDayNotPay" },
      { label: "จำนวนวันที่เกิน (ลาไม่รับค่าจ้าง)", value: "overDayNotPay" },
      { label: "% กระทบโบนัส (ลาไม่รับค่าจ้าง)", value: "percentBonusNotPay" },
      { label: "เตือนร้ายแรง (ใบ)", value: "noValue" },
      { label: "จำนวนวันลา (เตือนวาจา)", value: "noValue" },
      { label: "จำนวนวันที่เกิน (เตือนวาจา)", value: "noValue" },
      { label: "% กระทบโบนัส (เตือนวาจา)", value: "noValue" },
      { label: "จำนวนวันลา (ใบเตือนสาย(ออฟฟิศ))", value: "noValue" },
      { label: "จำนวนวันที่เกิน (ใบเตือนสาย(ออฟฟิศ))", value: "noValue" },
      { label: "% กระทบโบนัส (ใบเตือนสาย(ออฟฟิศ))", value: "noValue" },
      { label: "%เพิ่ม/ลด (สายขาดลา ใบเตือน)", value: "noValue" },
    ],
  },
];

export const Header_ReportBonus = [
  {
    sheet: "sheet1",
    column: [
      { label: "รหัสพนักงาน", value: "userId" },
      { label: "ชื่อพนักงาน", value: "fullname" },
      { label: "ชื่อเล่น", value: "nickname" },
      { label: "สถานะพนักงาน", value: "statusWorkerDes" },
      { label: "บริษัท", value: "Company" },
      { label: "สถานที่", value: "location" },
      { label: "แผนก", value: "depName" },
      { label: "แผนกย่อย", value: "subDepName" },
      { label: "ตำแหน่งงาน", value: "positionNo" },
      { label: "กลุ่มเงินเดือน", value: "payrollGroupDes" },
      { label: "ผู้ประเมิน 1", value: "approverName1" },
      { label: "ผู้ประเมิน 2", value: "approverName2" },
      { label: "วันที่เริ่มงาน", value: "startDateJob" },
      { label: "วันผ่านทดลองงาน", value: "probationDateEnd" },
      { label: "อายุงาน", value: "workYears" },
      { label: "PG", value: "PG" },
      { label: "JG", value: "JG" },
      { label: "ลาป่วย", value: "percentBonusSick" },
      { label: "ขาดงาน", value: "percentBonusSick" },
      { label: "ลาอื่น ๆ", value: "percentBonusOtherLeave" },
      { label: "ลาไม่รับค่าจ้าง", value: "percentBonusNotPay" },
      { label: "เตือนร้ายแรง", value: "noValue" },
      { label: "เตือนวาจา", value: "noValue" },
      { label: "ใบเตือนสาย (ออฟฟิศ)", value: "noValue" },
      { label: "%เพิ่ม/ลด (สายขาดลา ใบเตือน)", value: "noValue" },
    ],
  },
];

export const Header_ReportCharge = [
  {
    sheet: "sheet1",
    column: [
      { label: "รหัสพนักงาน", value: "userId" },
      { label: "ชื่อพนักงาน", value: "fullname" },
      { label: "ชื่อเล่น", value: "nickname" },
      { label: "สถานภาพพนักงาน", value: "currentStatus" },
      { label: "สถานะพนักงาน", value: "stautsWorkerDes" },
      { label: "บริษัท", value: "company" },
      { label: "สถานที่", value: "location" },
      { label: "แผนก", value: "depNo" },
      { label: "ตำแหน่งงาน", value: "positionName" },
      { label: "วันที่เริ่มงาน", value: "statrtDateJob" },
      { label: "วันที่ผ่านทดลองงาน", value: "probationDateEnd" },
      { label: "วันที่สิ้นสุดงาน", value: "endDateJob" },
      { label: "รหัสพนักงาน (ผู้สรรหา)", value: "recruiterId" },
      { label: "ผู้สรรหา", value: "recruiterName" },
      { label: "เกรดสรรหา", value: "recruiterGrade" },
      { label: "คะแนนสรรหา", value: "recruiterScore" },
      { label: "หมายเหตุ", value: "remark" },
      { label: "%Charge", value: "charge" },
    ],
  },
];

export const Header_ReportCheckEmployees = [
  {
    sheet: "sheet1",
    column: [
      { label: "รหัสพนักงาน", value: "userId" },
      { label: "คำนำหน้า", value: "title" },
      { label: "ชื่อพนักงาน", value: "fullname" },
      { label: "บริษัท", value: "company" },
      { label: "สถานที่", value: "location" },
      { label: "แผนก", value: "depName" },
      { label: "แผนกย่อย", value: "subDepName" },
      { label: "ตำแหน่งงาน", value: "positionName" },
      { label: "สายอนุมัติ", value: "flowName" },
      { label: "วันที่เริ่มงาน", value: "statrtDateJob" },
      { label: "วันพ้นสภาพ", value: "endDateJob" },
      { label: "สถานะพนักงาน", value: "stautsWorkerDes" },
      { label: "สถานะพนักงาน (อื่นๆ)", value: "currentStatusPeriodDes" },
    ],
  },
];

export const Header_ReportExamineFullTemp = [
  {
    sheet: "sheet1",
    column: [
      { label: "รหัสพนักงาน", value: "userId" },
      { label: "ชื่อพนักงาน", value: "fullname" },
      { label: "ชื่อเล่น", value: "nickname" },
      { label: "บริษัท", value: "company" },
      { label: "สถานที่", value: "location" },
      { label: "ตำแหน่งงาน", value: "positionName" },
      { label: "วันที่เริ่มงาน", value: "statrtDateJob" },
      { label: "วันที่พ้นสภาพ", value: "endDateJob" },
      { label: "สถานะพนักงาน", value: "stautsWorkerDes" },
      { label: "รหัสพนักงาน (ผู้สรรหา)", value: "recruiterId" },
      { label: "ผู้สรรหา", value: "recruiterName" },
      { label: "สัญญาจ้าง (วันเริ่มสัญญาล่าสุด)", value: "startProbationDate" },
      { label: "สัญญาจ้าง (วันสิ้นสุดสัญญาล่าสุด)", value: "endContractDate" },
      { label: "สัญญาจ้าง (อายุสัญญาล่าสุด)", value: "contractPeriodDes" },
      { label: "สถานะทดลองงาน", value: "probationStatusDes" },
    ],
  },
];

export const Header_ReportRecruiterGrade = (activeTab) => {
  let setName = "sheet1";
  let optimizeFields = [];
  switch (activeTab) {
    case 1:
      setName = "สถานะจ้างงาน";
      optimizeFields = [{ label: "สถานะจ้างงาน", value: "currentStatus" }];
      break;
    case 2:
      setName = "แผนก";
      optimizeFields = [{ label: "แผนก", value: "depName" }];
      break;
    case 3:
      setName = "ผู้สรรหา";
      optimizeFields = [
        { label: "รหัสพนักงาน (ผู้สรรหา)", value: "recruiterId" },
        { label: "ผู้สรรหา", value: "recruiterNickname" },
      ];
      break;
  }
  return [
    {
      sheet: setName,
      column: [
        ...optimizeFields,
        { label: "A", value: "gradeA" },
        { label: "B", value: "gradeB" },
        { label: "C", value: "gradeC" },
        { label: "D", value: "gradeD" },
        { label: "รวม", value: "grandTotal" },
        { label: "A-B (%)", value: "percentAB" },
        { label: "C (%)", value: "percentC" },
        { label: "D (%)", value: "percentD" },
      ],
    },
  ];
};

export const Header_ReportRecruiterScore = (activeTab) => {
  let setName = "sheet1";
  let optimizeFields = [];
  switch (activeTab) {
    case 1:
      setName = "สถานะจ้างงาน";
      optimizeFields = [{ label: "สถานะจ้างงาน", value: "currentStatus" }];
      break;
    case 2:
      setName = "แผนก";
      optimizeFields = [{ label: "แผนก", value: "depName" }];
      break;
    case 3:
      setName = "ผู้สรรหา";
      optimizeFields = [
        { label: "รหัสพนักงาน (ผู้สรรหา)", value: "recruiterId" },
        { label: "ผู้สรรหา", value: "recruiterNickname" },
      ];
      break;
  }
  return [
    {
      sheet: setName,
      column: [
        ...optimizeFields,
        { label: "1 คะแนน", value: "score1" },
        { label: "2 คะแนน", value: "score2" },
        { label: "3 คะแนน", value: "score3" },
        { label: "4 คะแนน", value: "score4" },
        { label: "5 คะแนน", value: "score5" },
        { label: "รวม", value: "grandTotal" },
        { label: "1 คะแนน(%)", value: "percent1" },
        { label: "2 คะแนน(%)", value: "percent2" },
        { label: "3 คะแนน(%)", value: "percent3" },
        { label: "4 คะแนน(%)", value: "percent4" },
        { label: "5 คะแนน(%)", value: "percent5" },
      ],
    },
  ];
};

export const Header_RecheckWorkingSaleEmployee = [
  {
    sheet: "sheet1",
    column: [
      { label: "รหัสพนักงาน", value: "userId" },
      { label: "Username", value: "Username" },
      { label: "ชื่อพนักงาน", value: "fullname" },
      { label: "ชื่อเล่น", value: "nickname" },
      { label: "บริษัท", value: "company" },
      { label: "สถานที่", value: "location" },
      { label: "แผนก", value: "Department_Name" },
      { label: "ตำแหน่ง", value: "positionName" },
      { label: "กลุ่มตำแหน่ง", value: "positionGroup" },
      { label: "สายอนุมัติ", value: "flowName" },
      { label: "วันที่เริ่มงาน", value: "statrtDateJob" },
      { label: "วันที่พ้นสภาพ", value: "endDateJob" },
      { label: "สถานภาพพนักงาน", value: "currentStatusName" },
      { label: "สถานะพนักงาน", value: "stautsWorkerDes" },
      { label: "รหัสเซลล์-สาขา", value: "Salecode" },
      { label: "สถานะพนักงานประจำงวด", value: "statusInstallmentName" },
    ],
  },
];

export const Header_FilterOutSalesEmpCommissionReport = [
  {
    sheet: "sheet1",
    column: [
      { label: "ลำดับ", value: "rowNo" },
      { label: "รหัสพนักงาน", value: "empNo" },
      { label: "ชื่อพนักงาน", value: "empName" },
    ],
  },
];

export const Header_CutOffSaleEmployeeReport = [
  {
    sheet: "sheet1",
    column: [
      { label: "รหัสพนักงาน", value: "employeeId" },
      { label: "ตำแหน่ง", value: "positionName" },
      { label: "รหัสเซลล์-สาขา", value: "saleCode" },
      { label: "ชื่อพนักงาน", value: "fullname" },
      { label: "วันที่เริ่มงาน", value: "startDateJob" },
      { label: "แผนก", value: "departmentName" },
      { label: "สถานะพนักงานประจำงวด", value: "statusInstallmentName" },
      {
        label: "Target ยอดขาย (บาท)",
        value: "saleTarget",
      },
      {
        label: "Actual ยอดขาย (บาท)",
        value: "saleActual",
      },
      {
        label: "%ยอดขายเดือน",
        value: "persentSalePerMonth",
      },
      {
        label: "Incentive Month",
        value: "incentiveMonth",
      },
      {
        label: "ยอดรับชำระหนี้ (บาท)",
        value: "rvAmount",
      },
      {
        label: "Commission",
        value: "commissionMonth",
      },
      {
        label: "Target ยอดขายไตรมาส (บาท)",
        value: "saleTargetQuater",
      },
      {
        label: "Actual ยอดขายไตรมาส (บาท)",
        value: "saleActualQuater",
      },
      {
        label: "%ยอดขายไตรมาส",
        value: "persentSalePerQuater",
      },
      {
        label: "Incentive Quarter",
        value: "incentiveQuater",
      },
      {
        label: "Incentive กลยุทธ์",
        value: "incentiveStrategy",
      },
      {
        label: "Incentive Focus Shop",
        value: "incentiveFocusShop",
      },
      {
        label: "Commission Focus Shop",
        value: "commissionFocusShop",
      },
      {
        label: "การันตี Incentive",
        value: "incentiveGuarantee",
      },
      {
        label: "การันตี Commission",
        value: "commissionGuarantee",
      },
      {
        label: "Total Commision ก่อนปรับปรุง",
        value: "totalCommissionBeforeUpdate",
      },
      {
        label: "Total Incentive ก่อนปรับปรุง",
        value: "totalIncentiveBeforeUpdate",
      },
      {
        label: "เพิ่ม/ลด Commission",
        value: "commission",
      },
      {
        label: "เพิ่ม/ลด Incentive",
        value: "incentive",
      },
      {
        label: "หมายเหตุอนุมัติพิเศษ (ต้องระบุ)",
        value: "remarkApprovalSpecial",
      },
      {
        label: "Total Commision",
        value: "totalCommission",
      },
      {
        label: "Total Incentive",
        value: "totalIncentive",
      },
      {
        label: "ชดเชย Commission",
        value: "recoverCommission",
      },
      {
        label: "ชดเชย Incentive",
        value: "recoverIncentive",
      },
      {
        label: "หมายเหตุค่าชดเชย",
        value: "remarkRecover",
      },
      {
        label: "Total Commision จ่าย",
        value: "totalCommissionPay",
      },
      {
        label: "Total Incentive จ่าย",
        value: "totalIncentivePay",
      },

      {
        label: "Total",
        value: "total",
      },
      // { label: "สายอนุมัติ", value: "flowName" },
      // { label: "วันที่เริ่มงาน", value: "statrtDateJob" },
      // { label: "วันที่พ้นสภาพ", value: "endDateJob" },
      // { label: "สถานภาพพนักงาน", value: "currentStatusName" },
      // { label: "สถานะพนักงาน", value: "stautsWorkerDes" },

      // { label: "สถานะพนักงานประจำงวด", value: "statusInstallmentName" },
    ],
  },
];
export const Header_CutOffSaleEmployeeReportFSP = [
  {
    sheet: "sheet1",
    column: [
      { label: "รหัสพนักงาน", value: "employeeId" },
      { label: "ตำแหน่ง", value: "positionName" },
      { label: "รหัสเซลล์-สาขา", value: "saleCode" },
      { label: "ชื่อพนักงาน", value: "fullname" },
      { label: "วันที่เริ่มงาน", value: "StartDateJob" },
      { label: "แผนก", value: "departmentName" },
      { label: "สถานะพนักงานประจำงวด", value: "statusInstallmentName" },
      {
        label: "Target ยอดขาย (บาท)",
        value: "saleTarget",
      },
      {
        label: "Target Acc (บาท)",
        value: "accTarget",
      },
      {
        label: "Target Temp (บาท)",
        value: "tempTarget",
      },
      {
        label: "Actual ยอดขาย (บาท)",
        value: "saleActual",
      },
      {
        label: "%ยอดขายเดือน",
        value: "persentSalePerMonth",
      },
      // {
      //   label: "ยอดรับชำระหนี้ (บาท)",
      //   value: "rvAmount",
      // },
      {
        label: "Incentive Month",
        value: "incentiveMonth",
      },
      {
        label: "Commission Month",
        value: "commissionMonth",
      },
      {
        label: "% ACC",
        value: "percentAccDecrypt",
      },
      {
        label: "% Temp",
        value: "percentTempDecrypt",
      },

      {
        label: "การันตี Incentive",
        value: "incentiveGuarantee",
      },
      {
        label: "เหตุผลการันตี",
        value: "remarkGuarantee",
      },
      {
        label: "Total Commision ก่อนปรับปรุง",
        value: "totalCommissionBeforeUpdateExcel",
      },
      {
        label: "Total Incentive ก่อนปรับปรุง",
        value: "totalIncentiveBeforeUpdateExcel",
      },
      {
        label: "เพิ่ม/ลด Commission",
        value: "commission",
      },
      {
        label: "เพิ่ม/ลด Incentive",
        value: "incentive",
      },
      {
        label: "หมายเหตุอนุมัติพิเศษ (ต้องระบุ)",
        value: "remarkApprovalSpecial",
      },
      {
        label: "Total Commision",
        value: "totalCommissionExcel",
      },
      {
        label: "Total Incentive",
        value: "totalIncentiveExcel",
      },
      {
        label: "Total",
        value: "total",
      },
    ],
  },
];

export const Header_ImportMapFocusShopSaleCode = [
  {
    sheet: "sheet1",
    column: [
      { label: "Focus shop saleCode", value: "saleCodeFS" },
      { label: "Focus shop name", value: "saleCodeFSName" },
      { label: "saleCode", value: "childrenFS" },
    ],
  },
];

export const Header_ChangeCompany = [
  {
    sheet: "sheet1",
    column: [
      { label: "บริษัท", value: "company" },
      { label: "รหัสพนักงาน", value: "userId" },
      { label: "ชื่อพนักงาน", value: "fullname" },
      { label: "ชื่อเล่น", value: "NickName" },
      { label: "แผนก", value: "depName" },
      { label: "แผนกย่อย", value: "subDepName" },
      { label: "สายอนุมัติ", value: "hrmFlowDes" },
      { label: "สายดูข้อมูล", value: "viewFlowDes" },
      { label: "ตำแหน่งงาน", value: "positionName" },
    ],
  },
];

export const Header_ChangeStatusWorker = [
  {
    sheet: "sheet1",
    column: [
      { label: "บริษัท", value: "company" },
      { label: "Location", value: "location" },
      { label: "รหัสพนักงาน", value: "userId" },
      { label: "ชื่อพนักงาน", value: "fullname" },
      { label: "ชื่อเล่น", value: "NickName" },
      { label: "สถานะพนักงาน", value: "statusWorkerDes" },
      { label: "แผนก", value: "depName" },
      { label: "แผนกย่อย", value: "subDepName" },
      { label: "สายอนุมัติ", value: "hrmFlowDes" },
      { label: "สายดูข้อมูล", value: "viewFlowDes" },
      { label: "ตำแหน่งงาน", value: "positionName" },
    ],
  },
];

export const Header_ChangeShiftWork = [
  {
    sheet: "sheet1",
    column: [
      { label: "บริษัท", value: "company" },
      { label: "กะทำงาน", value: "workShiftName" },
      { label: "รหัสพนักงาน", value: "userId" },
      { label: "ชื่อพนักงาน", value: "fullname" },
      { label: "ชื่อเล่น", value: "NickName" },
      { label: "แผนก", value: "depName" },
      { label: "แผนกย่อย", value: "subDepName" },
      { label: "สายอนุมัติ", value: "hrmFlowDes" },
      { label: "สายดูข้อมูล", value: "viewFlowDes" },
      { label: "ตำแหน่งงาน", value: "positionName" },
    ],
  },
];
export const Header_InsertNewSaleEmployeeFSP = [
  {
    sheet: "sheet1",
    column: [
      { label: "ลำดับที่", value: "rowNo" },
      { label: "รหัสเซลล์/สาขา", value: "SaleCode" },
      { label: "รหัสพนักงาน", value: "ID_User" },
      { label: "หน่วยงาน", value: "departmentName" },
      { label: "ตำแหน่ง", value: "PositionNameEN" },
      { label: "ชื่อพนักงาน", value: "Name" },
      { label: "แผนก", value: "departmentName" },
      { label: "วันที่เริ่มงาน", value: "StartDateJob" },
      { label: "วันที่พ้นสภาพ", value: "EndDateJob" },
      { label: "สถานะพนักงาน", value: "statusWorkerName" },
      { label: "สถานะพนักงานประจำงวด", value: "statusInstallmentName" },
      { label: "การันตี incentive (บาท)", value: "incentiveGuarantee" },
      { label: "เหตุผลการันตี", value: "remarkGuarantee" },
    ],
  },
];

export const Header_InsertSaleTargetFSP = [
  {
    sheet: "sheet1",
    column: [
      { label: "สาขา", value: "roleId" },
      { label: "Temp", value: "tempTarget" },
      { label: "Acc", value: "accTarget" },
      { label: "จำนวนคนในสาขา", value: "saleQTY" },
    ],
  },
];