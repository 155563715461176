import React, { useState } from "react";
import { Form, Card, Spin, Space, Tabs, Row, Col } from "antd";
import { optionStatusComp, useOptionSelect } from "../../constants/OptionSelect";
import { toUndefined, sumColumnWidth, subStringDate, GET_UserLogin, calcutateDateDiff } from "../../functions/UseFunction";
import { useAlert } from "../modal/CustomAlert";
import { configForm } from "../../constants/ConstantComponent";
import { useRenderFormItem } from "../RenderComponents";
import CustomTableAtnd from "../tables/CustomTableAtnd";
import { useNotification } from "../notification/NotificationWithIcon";
import { POST, POST_CANCEL_REQ_OT, POST_SELECT_DATA_DASHBOARD_EDIT_OT, POST_UPDATE_TIME_REQ_OT_BY_LINE } from "../../../service";
import { useButton } from "../buttons/CustomButtonAntd";
import { ColumnManageOT, ColumnManageOTUpdate } from "../../constants/Reports/Columns";
import { useExportExcel } from "../../services/ExportExcel";
import { Header_ReportOTUpdate } from "../../constants/HeaderExcel";
import { ComponentManageOT } from "../../constants/Reports/Components/ComponentReportOT";
import { useFixedQueryString } from "../../functions/UseMakeQueryString";
import { RenderMainModal } from "../modal/CustomModalAntd";
import { useInputText } from "../inputs/InputAnt";
import { useConfirm } from "../modal/CustomConfirm";
import moment from "moment";

const FormManageOT = () => {
    const [form] = Form.useForm();
    const [formUpdate] = Form.useForm();
    const { userlogin } = GET_UserLogin();
    // [START] States
    const [ChangeValue, setChangeValue] = useState({});
    const [remarkByHrValue, setRemarkByHrValue] = useState("");
    const [dataTable, setDataTable] = useState([]);
    const [rowSelection, setRowSelection] = useState({ selectedRowKeys: [], selectedRows: [] });
    const [loadingScreen, setLoadingScreen] = useState(false);
    const [showModal, setShowModal] = useState(false)
    const [showModalUpdate, setShowModalUpdate] = useState(false)

    // [END] States
    let optionSearch = {
        userId: useOptionSelect("empAll", useFixedQueryString({ location: "FAC" }, ["location"])),
        depNo: useOptionSelect("department"),
        subDepNo: useOptionSelect("subDepartment", useFixedQueryString(ChangeValue, ["depNo"])),
        statusComp: optionStatusComp,
    };

    // [START] Handle Form Antd
    const onFinish = (values) => {
        const { userId, depNo, subDepNo, startDate, toDate, statusComp } = values
        let objectBody = {
            userId: userId || "",
            depNo: depNo || "",
            subDepNo: subDepNo || "",
            startDate: startDate ? startDate.format("YYYY-MM-DD") : "",
            toDate: toDate ? toDate.format("YYYY-MM-DD") : "",
            statusComp: statusComp || "",
        }
        selectDataReportOT(objectBody)
    };

    const onFinishUpdate = (values) => {
        const { selectedRows } = rowSelection
        let dataBody = []
        if (values) {
            dataBody = selectedRows.map(row => ({
                docNo: row.docNo,
                linenum: row.linenum,
                activeDate: values[`activeDate_${row.key}`] ? values[`activeDate_${row.key}`].format("YYYY-MM-DD") : row.activeDate,
                startDateTime: values[`startDateOT_${row.key}`] ? values[`startDateOT_${row.key}`].format("YYYY-MM-DD HH:mm:ss") : moment(`${row.startDateOT} ${row.startTimeOT}`).format("YYYY-MM-DD HH:mm:ss"),
                endDateTime: values[`endDateOT_${row.key}`] ? values[`endDateOT_${row.key}`].format("YYYY-MM-DD HH:mm:ss") : moment(`${row.endDateOT} ${row.endTimeOT}`).format("YYYY-MM-DD HH:mm:ss"),
                totalHours: calcutateDateDiff(values[`startDateOT_${row.key}`].format("YYYY-MM-DD HH:mm:ss"), values[`endDateOT_${row.key}`].format("YYYY-MM-DD HH:mm:ss")),
                userlogin,
            }))
        }
        //console.log("dataBody", dataBody)
        useConfirm({ FnOK: () => updateTimeReqOTbyLine(dataBody) })

    };

    const onReset = () => {
        form.resetFields();
        setDataTable([]);
        setChangeValue({});
        setRowSelection({ selectedRowKeys: [], selectedRows: [] })
    };

    const selectDataReportOT = async (objectValue) => {
        try {
            setRowSelection({ selectedRowKeys: [], selectedRows: [] })
            const { userId, subDepNo, startDate, toDate, statusComp } = objectValue
            let objectBody = {
                userId: userId || "",
                subDepNo: subDepNo || "",
                startDate: startDate || "",
                toDate: toDate || "",
                statusComp: statusComp || "",
            };
            setLoadingScreen(true);
            setDataTable([]);
            const response = await POST(POST_SELECT_DATA_DASHBOARD_EDIT_OT, objectBody);
            const { result } = response;
            let mapResult = [];
            if (result.length !== 0) {
                mapResult = result.map((row, index) => ({
                    key: `${row.userId}_${index}`,
                    ...row,
                    dateTimeEndOT: subStringDate(row.dateTimeEndOT),
                    dateTimeIn: subStringDate(row.dateTimeIn),
                    dateTimeOut: subStringDate(row.dateTimeOut),
                    dateTimeStrOT: subStringDate(row.dateTimeStrOT),
                }));
            } else {
                useNotification({ type: "info", title: "ไม่พบข้อมูล" });
            }
            setDataTable(mapResult);
        } catch (err) {
            useAlert({ type: "catch", content: err.message })
            console.log("Error Search Data Report OT: ", err);
        } finally {
            setLoadingScreen(false);
        }
    };

    const cancelReqOT = async () => {
        try {
            const { selectedRows } = rowSelection
            if (selectedRows.length === 0) useAlert({ type: "warning", title: `กรุณาเลือกอย่างน้อย 1 รายการ` });
            setLoadingScreen(true);
            let dataBody = selectedRows.map((row) => ({
                docNo: row.docNo,
                linenum: row.linenum,
                remarkHr: remarkByHrValue,
                userlogin,
            }));
            // console.log("dataBody", dataBody)
            const response = await POST(POST_CANCEL_REQ_OT, dataBody);
            const { success } = response;
            if (success) {
                useAlert({ type: "success", title: "ลบข้อมูลสำเร็จ", Fn: () => window.location.reload() })
            } else {
                useAlert({ type: "error", title: "ลบข้อมูลไม่สำเร็จ" })
            }
        } catch (err) {
            useAlert({ type: "catch", content: err.message })
            console.log("Error Search Data Report OT: ", err);
        } finally {
            setLoadingScreen(false);
        }
    };

    const updateTimeReqOTbyLine = async (dataBody) => {
        try {
            const response = await POST(POST_UPDATE_TIME_REQ_OT_BY_LINE, dataBody);
            const { success } = response;
            if (success) {
                useAlert({
                    type: "success",
                    title: "แก้ไขข้อมูลสำเร็จ",
                    Fn: () => {
                        setShowModalUpdate(false);
                        form.submit();
                    }
                });
            } else {
                useAlert({ type: "error", title: "ลบข้อมูลไม่สำเร็จ" })
            }
        } catch (err) {
            useAlert({ type: "catch", content: err.message })
            console.log("Error Search Data Report OT: ", err);
        } finally {
            setLoadingScreen(false);
        }
    };
    // [END] Handle Form Antd

    // [START] Functions
    const onChangeValue = (name, value) => {
        // console.log(name, " : ", value)
        let setUndefined = {}
        if (name === "depNo") {
            setUndefined = toUndefined(["subDepNo"])
        }
        setDataTable([]);
        form.setFieldsValue(setUndefined)
        setChangeValue((prev) => ({
            ...prev,
            setUndefined,
            [name]: value,
        }))
    };

    const contentCancelReqOT = () => {
        return (
            <div>
                <Row gutter={[16, 16]} justify="start" align="middle" style={{ marginBottom: 30 }}>
                    <Col span={8}>
                        ข้อเสนอแนะเพิ่มเติม :
                    </Col>
                    <Col span={16}>
                        {useInputText({
                            name: "remarkByHrValue",
                            componentProps: {
                                style: { width: "100%" },
                                placeholder: "กรอก ข้อเสนอแนะเพิ่มเติม",
                                disabled: false,
                            },
                            fnChange: (event) => setRemarkByHrValue(event.target.value),
                        })}
                    </Col>
                </Row>
                <Row gutter={[16, 16]} justify="end" align="middle">
                    <Col span={8}>
                        {useButton({
                            call: "normal",
                            moreProps: { label: "ยืนยัน", type: "primary" },
                            fn: () => useConfirm({ FnOK: () => cancelReqOT() }),
                            isDisable: false,
                        })}
                    </Col>
                </Row>
            </div>
        )
    }

    const contentUpdateTimeReqOT = () => {
        const { selectedRows } = rowSelection
        return (
            <Form form={formUpdate} onFinish={onFinishUpdate} {...configForm}>
                <CustomTableAtnd
                    HeadTable={ColumnManageOTUpdate()}
                    DataTable={selectedRows}
                    moreProps={{
                        scroll: {
                            x: sumColumnWidth(ColumnManageOTUpdate()) || 2050,
                            y: 500,
                        },
                    }}
                />
                <Row justify="center" gutter={[16, 16]}>
                    <Col xs={12} sm={12} md={6} lg={6} xl={4}>
                        {useButton({
                            call: "save",
                            moreProps: { label: "บันทึก" },
                            isDisable: rowSelection.selectedRowKeys.length === 0
                        })}
                    </Col>
                    <Col xs={12} sm={12} md={6} lg={6} xl={4}>
                        {useButton({
                            call: "normal",
                            fn: () => formUpdate.resetFields(),
                            moreProps: { label: "เรียกข้อมูลเดิม" },
                        })}
                    </Col>
                </Row>
            </Form>
        )
    }
    // [END] Functions
    return (
        <Spin tip="กำลังโหลดข้อมูล ..." spinning={loadingScreen}>
            <RenderMainModal
                show={showModal}
                onCancel={() => setShowModal(false)}
                txtTitle={"ยกเลิกคำขอโอที"}
                ModalContent={contentCancelReqOT()}
                width={550}
            />
            <RenderMainModal
                show={showModalUpdate}
                onCancel={() => setShowModalUpdate(false)}
                txtTitle={"แก้ไขข้อมูลโอทีรายบุคคล"}
                ModalContent={contentUpdateTimeReqOT()}
                width={1000}
            />
            <Form form={form} onFinish={onFinish} {...configForm}>
                <Card
                    title="แก้ไขข้อมูลโอทีรายบุคคล"
                    style={{ marginBottom: 30 }}
                    extra={
                        <Space>
                            {useButton({ call: "search" })}
                            {useButton({
                                call: "normal",
                                fn: onReset,
                                moreProps: { label: "ล้างข้อมูล" },
                            })}
                        </Space>
                    }
                >
                    {useRenderFormItem({
                        constantComponent: ComponentManageOT({
                            options: optionSearch,
                            handleChange: onChangeValue,
                        }),
                    })}
                </Card>
                {dataTable.length !== 0 && (
                    <Card
                        title="รายงานคำขอโอที"
                        style={{ marginBottom: 30 }}
                        extra={
                            useExportExcel({
                                header: Header_ReportOTUpdate,
                                dataset: dataTable,
                                filename: "รายงานคำขอโอที",
                            })
                        }
                    >
                        <Tabs
                            tabBarExtraContent={
                                <Space>
                                    {useButton({
                                        call: "normal",
                                        fn: () => setShowModalUpdate(true),
                                        moreProps: { label: "แก้ไขข้อมูลโอทีรายบุคคล" },
                                        moreStyle: {
                                            backgroundColor: rowSelection.selectedRowKeys.length === 0 || "#F0AD4E",
                                            borderColor: rowSelection.selectedRowKeys.length === 0 || "#F0AD4E",
                                            color: rowSelection.selectedRowKeys.length === 0 || "#FFFFFF"
                                        },
                                        isDisable: rowSelection.selectedRowKeys.length === 0
                                    })}
                                    {useButton({
                                        call: "normal",
                                        fn: () => setShowModal(true),
                                        moreProps: { label: "ยกเลิกคำขอโอที", type: "danger" },
                                        isDisable: rowSelection.selectedRowKeys.length === 0
                                    })}
                                </Space>
                            }
                        >
                            <Tabs.TabPane tab="ข้อมูลโอทีรายบุคคล" key="1">
                                <CustomTableAtnd
                                    HeadTable={ColumnManageOT}
                                    DataTable={dataTable}
                                    moreProps={{
                                        rowSelection: {
                                            onChange: (selectedRowKeys, selectedRows) => {
                                                setRowSelection({ selectedRowKeys, selectedRows })
                                                // console.log("selectedRows", selectedRows)
                                            },
                                        },
                                        scroll: {
                                            x: sumColumnWidth(ColumnManageOT) || 2050,
                                            y: 500,
                                        },
                                    }}
                                />
                            </Tabs.TabPane>
                        </Tabs>
                    </Card>
                )}
            </Form>
        </Spin>
    );
};

export default FormManageOT;
