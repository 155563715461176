import { TYPE } from "../../../moduleKPIs/common/inputs"
import moment from 'moment'

// import { MASTER_OPTION, servicesOptions } from "../../../moduleKPIs/services/servicesOptions"
export const MANAGE_REQUEST_OT = ({ update, permission, options, defaultValue, initialData }) => {
	// console.log({ defaultValue })
	switch (permission) {
		default:
			return [
				[{
					span: 6,
					type: TYPE.SELECT,
					header: 'แผนกย่อย',
					required: true,
					message: 'กรุณากรอก ระบุแผนกย่อย',
					inputOption: {
						fieldname: 'subDepNo',
						plainOptions: options.subDepNo,
						defaultValue: defaultValue.subDepNo,
						disabled: false,
						placeholder: ''
					}
				},
				{
					span: 6,
					type: TYPE.SELECT,
					header: 'ผู้ส่งอนุมัติ',
					required: true,
					message: 'กรุณากรอก ระบุผู้ส่งอนุัมัติ',
					inputOption: {
						fieldname: 'refFlow',
						plainOptions: options.hrmFlow,
						// plainOptions: options[`OPTION_${MASTER_OPTION.HRM_FLOW_FACTORY}`],
						defaultValue: defaultValue.refFlow,
						disabled: false,
						placeholder: ''
					}
				},
					// {
					//     span: 4,
					//     type: TYPE.SELECT,
					//     header: 'กะทำงาน',
					//     required: true,
					//     message: 'กรุณากรอก กะทำงาน',
					//     inputOption: {
					//         fieldname: 'workShift',
					//         plainOptions: options.workShift,
					//         // plainOptions: options[`OPTION_${MASTER_OPTION.HRM_FLOW_FACTORY}`],
					//         defaultValue: (update) ? defaultValue.workShift : '',
					//         disabled: false,
					//         placeholder: ''
					//     }
					// }
				], [{
					span: 4,
					type: TYPE.DATE_PICKER,
					header: 'วันที่เริ่ม',
					required: true,
					message: 'กรุณากรอก ระบุวันที่เริ่ม',
					inputOption: {
						defaultValue: (update) ? moment(defaultValue.startDate) : moment(new Date()),
						fieldname: 'startDate',
						disabled: false,
						placeholder: 'กรุณาเลือก',
						disabledDate: (current) => current && current < moment().subtract(1, 'months').startOf('months')

					}
				}, {
					span: 4,
					type: TYPE.DATE_PICKER,
					header: 'วันที่จบ',
					required: true,
					message: 'กรุณากรอก ระบุวันที่จบ',
					inputOption: {
						defaultValue: (update) ? moment(defaultValue.endDate) : moment(new Date()),
						fieldname: 'endDate',
						disabled: false,
						placeholder: 'กรุณาเลือก',
						disabledDate: (current) => current && current < moment().subtract(1, 'months').startOf('months')
					}
				}, {
					span: 4,
					type: TYPE.DATE_PICKER,
					header: 'วันที่มีผล',
					required: true,
					message: 'กรุณากรอก วันที่มีผล',
					inputOption: {
						defaultValue: ((update) ? moment(defaultValue.activeDate) : moment(new Date())),
						// value: moment(new Date('2021-10-01')),
						// value: moment(initialData.activeDate),
						fieldname: 'activeDate',
						disabled: false,
						placeholder: 'กรุณาเลือก',
						disabledDate: (current) => current && current < moment().subtract(1, 'months').startOf('months')
					}
				}], [{
					span: 6,
					type: TYPE.TIME_PICKER,
					header: 'เวลาที่เริ่ม (รูปแบบเวลา 24 ชั่วโมง เช่น 14:00)',
					required: true,
					message: 'กรุณากรอก ระบุเวลาที่จบ',
					inputOption: {
						// defaultValue: '00:00',

						defaultValue: (update) ? `${defaultValue.startTime.split('T')[1].substr(0, 5)}` : '',
						fieldname: 'startTime',
						disabled: false,
						// placeholder: '08:00'
					}
				}, {
					span: 6,
					type: TYPE.TIME_PICKER,
					header: 'เวลาที่จบ (รูปแบบเวลา 24 ชั่วโมง เช่น 14:00)',
					required: true,
					message: 'กรุณากรอก ระบุเวลาที่จบ',
					inputOption: {
						// defaultValue: '00:00',
						// defaultValue: (update) ? moment(defaultValue.endTime) : moment(new Date()),
						defaultValue: (update) ? `${defaultValue.endTime.split('T')[1].substr(0, 5)}` : '',
						fieldname: 'endTime',
						disabled: false,
						// placeholder: '24:00'
					}
				}, {
					span: 12,
					type: TYPE.NUMBERFIX,
					header: 'รวมชั่วโมงทั้งหมด',
					inputOption: {
						defaultValue: (update) ? defaultValue.totalHours : 0,
						fieldname: 'totalHours',
						disabled: true,
						placeholder: ''
					}
				}], [{
					span: 12,
					type: TYPE.INPUT_AREA,
					header: 'เหตุผล',
					required: true,
					message: 'กรุณากรอก ระบุเหตุผล',
					inputOption: {
						defaultValue: (update) ? defaultValue.remark : '',
						fieldname: 'remark',
						disabled: false,
						placeholder: 'กรุณาระบุเหตุผล',
						autoSize: { minRows: 2, maxRows: 6 }
					}
				}, {
					span: 12,
					type: TYPE.INPUT_AREA,
					header: 'จำนวนปริมาณงาน',
					required: true,
					message: 'กรุณากรอก จำนวนปริมาณงาน',
					inputOption: {
						defaultValue: (update) ? defaultValue.workload : '',
						fieldname: 'workload',
						disabled: false,
						placeholder: 'กรุณาระบุจำนวนปริมาณงาน',
						autoSize: { minRows: 2, maxRows: 6 }
					}
				}]
			]
	}
}
