import React, { useState, useContext, useEffect } from 'react'
import { browserHistory } from "react-router";

import { Card, Input, Select, Col, Row, Empty, Modal, Radio } from 'antd'
import { ButtonTheme } from '../../moduleKPIs/common/buttons'
import { colors } from '../../themes'
import { LayoutWrapper } from '../../moduleKPIs/common/layouts'
import { ReportEvacContext } from '../servicesEvac/reportEvac/reportEvacProvider'
import { CONFIG } from '../../components/connectAPI'
import { TEXT_BTN } from '../commonsEvac/structuresEvac/buttonEvac'
import { ManageEvacContext } from '../servicesEvac/manageEvac/manageEvacProvider'
import { MASTER_OPTION, REPORT_OPTION } from '../../moduleKPIs/services/servicesOptions'
import { MasterEvacContext } from '../servicesEvac/masterEvac/masterEvacProvider'
import { APIInsertEvac } from '../servicesEvac/manageEvac/manageEvacConnect'
import { PATHNAME } from '../../constants/enums/pathname';
import { update, isNil, equals } from 'ramda';
import { requiredInput } from '../../moduleKPIs/common/inputs';

const PageInsertEvac = () => {
    const { userLogin } = useContext(ManageEvacContext)
    const { masterEvacState, onChangeMaster, getMaster } = useContext(MasterEvacContext)
    const { reportEvacState, getReportEvac } = useContext(ReportEvacContext)
    const { questionData: { depNo, subDepNo, nickname, fullname, userId, formId, formDescription, year, quarter, pathImg, question } } = reportEvacState
    const [remark, setRemark] = useState(null)
    const [answerQuestion, setAnswerQuestion] = useState([])

    let plainOptions = masterEvacState[MASTER_OPTION.OPTION_FREQUENCY_WORK_WITH]

    //console.log({ reportEvacState })
    async function fetchAPI() {
        await getMaster({ fieldname: MASTER_OPTION.OPTION_FREQUENCY_WORK_WITH })
    }

    useEffect(() => {
        fetchAPI()
        if (isNil(year)) {
            return browserHistory.replace(PATHNAME.DASHBOARD_EVAC)
        }
    }, [])


    async function onSubmit() {

        let payload = {
            "dataHeader": {
                "formId": formId,
                "year": year,
                "quarter": quarter,
                "userId": userId,
                "fullname": fullname,
                "nickname": nickname,
                "depNo": depNo,
                "subDepNo": subDepNo,
                "frequencyWork": masterEvacState[`SEARCH_${MASTER_OPTION.OPTION_FREQUENCY_WORK_WITH}`],
                "remark": remark || null,
                "createBy": userLogin.userId
            },
            "dataLine": answerQuestion
        }
        const { dataHeader: { frequencyWork }, dataLine } = payload;
        // console.log({ payload })
        if (frequencyWork) {
            if (frequencyWork === "0" || (!isNil(frequencyWork) && dataLine.length === question.length)) {
                await Modal.confirm({
                    title: "คุณต้องการบันทึกข้อมูลใช่หรือไม่",
                    onOk: async () => await APIInsertEvac(payload, async (response) => {
                        if (response) {
                            await Modal.success({
                                title: "บันทึกสำเร็จ",
                                onOk: async () => {
                                    await getReportEvac({ fieldname: REPORT_OPTION.DASHBOARD_EVAC })
                                    await browserHistory.push(PATHNAME.DASHBOARD_EVAC)
                                    // await window.location.reload()
                                }
                            })
                        }
                    })
                })
            } else {
                Modal.error({
                    title: 'กรุณากรอกข้อมูลให้ครบถ้วน'
                })
            }
        } else {
            Modal.error({
                title: 'กรุณากรอกข้อมูลให้ครบถ้วน'
            })
        }
    }


    return (
        <div style={{ paddingTop: 60 }}>
            <Row gutter={[24, 24]} style={{ width: '100%', fontSize: 20, textAlign: 'center' }}>
                <Col span={24}>  {`แบบประเมิน ความพึงพอใจในการปฏิบัติงาน แผนก ${formDescription || '....................'}`}</Col>
                <Col span={24}> {`ประจำปี ${year || '..........'} ไตรมาส ${quarter || '..........'}`} </Col>
                <Col span={24}>
                    {
                        (pathImg) ?
                            <img src={`${CONFIG.API_URL}${pathImg}`} alt="pathImg" width={800} height={600} />
                            :
                            <Empty style={{ width: 800, height: 300, display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }} />
                    }
                </Col>
            </Row>
            <LayoutWrapper
                content={
                    <>
                        <Card title="ส่วนที่ 1" style={{ marginBottom: 20 }} >
                            <div style={{ paddingBottom: 5 }}>
                                {requiredInput({ required: true, label: "ความถี่ในการติดต่อประสานงาน" })}
                            </div>
                            <div className="layout-search-content">
                                <Select
                                    style={{ width: '100%' }}
                                    placeholder={" "}
                                    optionFilterProp="children"
                                    onChange={(event) => onChangeMaster({ fieldOption: MASTER_OPTION.OPTION_FREQUENCY_WORK_WITH, checkedList: [event] })}
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {
                                        Array.isArray(plainOptions) && plainOptions.map((option, optionIndex) => {
                                            return (
                                                <Select.Option key={optionIndex} value={option.optionValue}>
                                                    {option.optionLabel}
                                                </Select.Option>
                                            )
                                        })
                                    }
                                </Select>
                            </div>
                        </Card>
                        {(masterEvacState[`SEARCH_${MASTER_OPTION.OPTION_FREQUENCY_WORK_WITH}`] !== "0") &&
                            <>
                                <Card title={<div>
                                    <div>
                                        {"ส่วนที่ 2"}
                                    </div>
                                    <div style={{ fontSize: 12 }}> {'กำหนดให้ : 5 คือ ความพึงพอใจมากที่สุด และ 1 คือ ความพึงพอใจน้อยที่สุด'} </div>
                                </div>} style={{ marginBottom: 20 }}>

                                    <Row>
                                        <Col span={16}>
                                            <div style={{ fontSize: 16, color: 'black' }}> {'ความคิดเห็น'} </div>
                                        </Col>
                                        <Col span={8} style={{ display: 'flex', justifyContent: 'center', color: 'black' }}>
                                            <Col span={4} style={{ display: 'flex', justifyContent: 'center' }}> 5 </Col>
                                            <Col span={4} style={{ display: 'flex', justifyContent: 'center' }}> 4 </Col>
                                            <Col span={4} style={{ display: 'flex', justifyContent: 'center' }}> 3 </Col>
                                            <Col span={4} style={{ display: 'flex', justifyContent: 'center' }}> 2 </Col>
                                            <Col span={4} style={{ display: 'flex', justifyContent: 'center' }}> 1 </Col>
                                        </Col>
                                    </Row>
                                    {Array.isArray(question) && question.map((each, index) => {
                                        return (
                                            <Row >
                                                {
                                                    // (question.length - 1 === index) ?
                                                    //     <Col span={16} style={{ display: 'flex', alignItems: 'center', fontSize: 16, color: 'black', backgroundColor: 'yellow' }}>
                                                    //         <div > {`${each.lineNum}.`} {requiredInput({ required: true, back: true, label: `${each.question}` })} </div>
                                                    //     </Col>
                                                    //     :
                                                    <Col span={16} style={{ display: 'flex', alignItems: 'center', fontSize: 16, color: 'black' }}>
                                                        <div > {`${each.lineNum}.`} {requiredInput({ required: true, back: true, label: `${each.question}` })} </div>
                                                    </Col>
                                                }
                                                <Col span={8}>
                                                    <Radio.Group
                                                        style={{ display: 'flex', justifyContent: 'center' }}
                                                        onChange={(e) => {
                                                            const dataIndex = Array.isArray(answerQuestion) && answerQuestion.findIndex(el => equals(el.linenum, each.lineNum))
                                                            if (dataIndex !== -1) {
                                                                setAnswerQuestion(update(each.lineNum - 1, {
                                                                    ...answerQuestion[each.lineNum - 1],
                                                                    "score": e.target.value
                                                                }, answerQuestion))
                                                            } else {
                                                                setAnswerQuestion(answerQuestion.concat({
                                                                    "linenum": each.lineNum,
                                                                    "questionNo": each.questionNo,
                                                                    "score": e.target.value,
                                                                    "createBy": userLogin.userId
                                                                }))
                                                            }
                                                        }}
                                                    // value={answerQuestion}
                                                    >
                                                        <Col span={4}>
                                                            <Radio style={{ display: 'flex', justifyContent: 'center', padding: 15, margin: 0 }} value={5} />
                                                        </Col>
                                                        <Col span={4}>
                                                            <Radio style={{ display: 'flex', justifyContent: 'center', padding: 15, margin: 0 }} value={4} />
                                                        </Col>
                                                        <Col span={4}>
                                                            <Radio style={{ display: 'flex', justifyContent: 'center', padding: 15, margin: 0 }} value={3} />
                                                        </Col>
                                                        <Col span={4}>
                                                            <Radio style={{ display: 'flex', justifyContent: 'center', padding: 15, margin: 0 }} value={2} />
                                                        </Col>
                                                        <Col span={4}>
                                                            <Radio style={{ display: 'flex', justifyContent: 'center', padding: 15, margin: 0 }} value={1} />
                                                        </Col>
                                                    </Radio.Group>
                                                </Col>
                                            </Row>
                                        )
                                    })}
                                </Card>
                                <Card title="ส่วนที่ 3" style={{ marginBottom: 20 }}>
                                    <div className="input-item">
                                        <div style={{ paddingBottom: 5 }}>{'ข้อเสนอเพิ่มเติม'}</div>
                                        <Input.TextArea
                                            rows={4}
                                            disabled={false}
                                            value={remark}
                                            onChange={(e) => setRemark(e.target.value)}
                                            placeholder={''} />
                                    </div>
                                </Card>
                            </>

                        }
                        <ButtonTheme
                            style={{ justifyContent: 'center' }}
                            buttonStyle={{ backgroundColor: colors.success, color: colors.white, border: 0 }}
                            buttonText={TEXT_BTN.SUBMIT_EVALUTOR}
                            onClick={() => onSubmit()}
                        />
                    </>
                }

            />

        </div>
    )
}

export default PageInsertEvac