export const documentOptionList = {
  certEmployTH: ["isTransfer","isQuit"],
  certEmployEN: ["isTransfer","isQuit"],
  certSalaryTH: ["isTransfer", "salary","isQuit"], //* salary mean always show salary
  certSalaryEN: ["isApex", "salary","isQuit"], //* salary mean always show salary
  certSalaryENVisa: ["showSalary"],
  none: [],
  test: [
    "isTransfer",
    "transferTo",
    "transferDate",
    "salary",
    "showSalary",
    "isApex",
    "isQuit"
  ],
};
