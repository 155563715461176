import React, { Component } from "react";
import "antd/dist/antd.css";
import { Table } from "antd";
import "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css";
import { CSVLink } from "react-csv";
import { MdFileDownload } from "react-icons/md";

import { Col, Button } from "reactstrap";
import { RenderStatusMainCode } from "../../common/setStatus";
import { SetDate } from "../../common/setDate";
import { getColumnSearchProps } from "../../constants/functions/getColumnsSearch";

class ProbationReport extends Component {
  openViewPro = (_id) => {
    this.props.handleLocation({ pathname: `${'/ViewProbation/'}`, state: { DocNo: _id }, status: 'view' })
  };

  render() {
    //console.log('data=')
    //  console.log(this.props.dataSource)
    const columnsProbation = () => {
      const searchInput = React.createRef();

      return [
        {
          label: "สถานะหลัก",
          title: "สถานะหลัก",
          dataIndex: "statusMainCode",
          key: "statusMain",
          fixed: "left",
          width: 150,
          render: (text) => RenderStatusMainCode(text)
        },
        {
          title: "สถานะการผ่านทดลองงาน",
          dataIndex: "probationStatus_Des",
          key: "probationStatus_Des",
          fixed: "left",
          render: (text) => (text) ? text : '-'

        },
        {
          label: "รหัสพนักงาน",
          title: "รหัสพนักงาน",
          dataIndex: "StaffCode",
          key: "StaffCode",
          fixed: "left",
          ...getColumnSearchProps('StaffCode', searchInput),

        },
        {
          title: "ชื่อพนักงาน",
          dataIndex: "StaffName",
          key: "StaffName",
          fixed: "left",
          ...getColumnSearchProps('StaffName', searchInput),

        },
        {
          dataIndex: "NickName",
          title: "ชื่อเล่น",
          key: "NickName",
          fixed: "left",
          ...getColumnSearchProps('NickName', searchInput),

        },
        {
          label: "กลุ่ม",
          title: "กลุ่ม",
          dataIndex: "statusWorker",
          key: "statusWorker",
          ...getColumnSearchProps('statusWorker', searchInput),

        },
        {
          title: "ชื่อผู้บังคับบัญชา",
          dataIndex: "BossName",
          key: "BossName",
          ...getColumnSearchProps('BossName', searchInput),

        },
        {
          title: "แผนก / กลุ่ม",
          dataIndex: "Department_Name",
          key: "Department_Name",
          ...getColumnSearchProps('Department_Name', searchInput),

        },
        {
          title: "แผนกย่อย",
          dataIndex: "subDepName",
          key: "subDepName",
          ...getColumnSearchProps('subDepName', searchInput),

        },

        {
          title: "การประเมินครั้งล่าสุด",
          dataIndex: "RoundNow",
          key: "RoundNow",
          render: (text) => (text) ? text : '-'

        },
        {
          dataIndex: "",
          title: "เอกสารทดลองงาน",
          key: "",
          render: record => {
            let showBtn = '-'
            // console.log('record', record)
            if (record.DocNo) {
              showBtn = <Button color="info"
                onClick={() => this.openViewPro(record.DocNo)} id={record.DocNo}
              >   View  </Button>
            }
            return <div>
              <Col>
                {showBtn}
              </Col>
            </div>
          }
        },
        {
          dataIndex: "StartDateJob",
          title: "วันที่เริ่มงาน",
          key: "StartDateJob",
          width: 120,
          render: text => SetDate(text)
        },
        {
          title: "วันที่ครบกำหนดทดลองงาน",
          dataIndex: "expProDate",
          key: "expProDate",
          render: text => SetDate(text)
        },
        {
          title: "วันที่ผ่านทดลองงาน",
          width: 150,
          dataIndex: "probationEndDate",
          render: text => SetDate(text)
        },
        {
          title: "วันที่ HR รับเอกสาร",
          width: 150,
          dataIndex: "HR_ReceivedDate",
          render: text => SetDate(text)
        },
        {
          title: "ประกันสุขภาพ / ประกันอุบัติเหตุ",
          dataIndex: "date_HEA",
          width: 150,
          render: text => SetDate(text)
        },
        {
          title: "กองทุนสำรองเลี้ยงชีพ",
          width: 150,
          dataIndex: "date_PVD",
          render: text => SetDate(text)
        },
        {
          title: "ประกันสังคม",
          width: 150,
          dataIndex: "date_SOS",
          render: text => SetDate(text)
        },

      ]
    };

    const headersProbation = [
      { label: "สถานะหลัก", key: "statusMain" },
      { label: "สถานะการผ่านทดลองงาน", key: "probationStatus_Des" },
      { label: "รหัสพนักงาน", key: "StaffCode" },
      { label: "ชื่อพนักงาน", key: "StaffName" },
      { label: "ชื่อผู้บังคับบัญชา", key: "BossName" },
      { label: "แผนก / กลุ่ม", key: "DepNo" },
      { label: "การประเมินครั้งล่าสุด", key: "RoundNow" },
      { label: "วันที่ครบกำหนดทดลองงาน", key: "expProDate" },
      { label: "วันที่ผ่านการทดลองงาน", key: "probationEndDate" },
      { label: "วันที่ HR รับเอกสาร", key: "HR_ReceivedDate" },
      { label: "วันที่ออกประกันสุขภาพ / ประกันอุบัติเหตุ", key: "date_HEA" },
      { label: "วันที่ออกกองทุนสำรองเลี้ยงชีพ", key: "date_PVD" },
      { label: "วันที่ออกประกันสังคม", key: "date_SOS" },

    ];

    const { dataSource } = this.props;

    return (
      <div>
        <div className="btn-right">
          <CSVLink
            headers={headersProbation}
            data={dataSource || []}
            filename={"ProbationReport.csv"}
            className="btn btn-export"
          >
            <MdFileDownload /> Export CSV
          </CSVLink>
        </div>

        <div>
          <Table
            columns={columnsProbation()}
            dataSource={dataSource || []}
            scroll={{ x: 2500, y: 600 }}
          />
        </div>
      </div >
    );
  }
}

export default ProbationReport;
