import { PATHNAME } from "../../../constants/enums/pathname";

export function renderColumnsWithPathname(pathname) {
    switch (pathname) {
        case PATHNAME.DASHBOARD_TRAINING:
            return [
                {
                    title: "รหัสพนักงาน",
                    field: "userId"
                }, {
                    title: "ชื่อ",
                    field: "fullname"
                }, {
                    title: "ชื่อเล่น",
                    field: "nickname"
                }, {
                    title: "ตำแหน่ง",
                    field: "depName"
                }, {
                    title: "แผนกย่อย",
                    field: "subDepName"
                }, {
                    title: "บังคับบัญชาที่ 1",
                    field: "approverFullName1"
                }, {
                    title: "วันที่เริ่ม",
                    field: "startDate"
                }, {
                    title: "Expect Date",
                    field: "expectDate"
                },
                {
                    title: "วันที่อบรมล่าสุด",
                    field: "trainingDateMax",
                }, {
                    title: "สถานะ",
                    field: "statusDes"
                }, {
                    title: "Over Due",
                    field: "overDue",
                    cellOptions: {
                        color: '#ff0000', //color red
                        textAlign: 'center'
                    }
                }
            ]
        case PATHNAME.DASHBOARD_HISTORY_INDIVIDUAL:
            return [
                {
                    title: "ลำดับที่",
                    field: "linenum"
                },
                {
                    title: "รหัสพนักงาน",
                    field: "userId"
                },
                {
                    title: "ชื่อ-นามสกุล",
                    field: "fullname"
                },
                {
                    title: "ตำแหน่งงาน",
                    field: "positionName"
                },
                {
                    title: "แผนกย่อย",
                    field: "subDepName"
                },
                {
                    title: "กลุ่มหลักสูตร",
                    field: "trainingGroup"
                },
                {
                    title: "ชื่อหลักสูตร",
                    field: "trainingCourses"
                },
                {
                    title: "วันที่อบรม",
                    field: "trainingDate"
                },
                {
                    title: "ผู้อบรม",
                    field: "trainerName"
                }
            ]
        case PATHNAME.DASHBOARD_HISTORY_TRAINERS:
            return [
                {
                    title: "ลำดับ",
                    field: "linenum"
                },
                {
                    title: "ชื่อวิทยากร",
                    field: "trainerName"
                },
                {
                    title: "ชื่อหลักสูตร",
                    field: "trainingCourses"
                },
                {
                    title: "วันที่อบรม",
                    field: "trainingDate"
                },
                {
                    title: "จำนวนผู้เข้าอบรม",
                    field: "numParticipants"
                },
                {
                    title: "จำนวนชั่วโมงอบรม",
                    field: "timePeriod"
                }]
        default:
            break;
    }
}

export const COLUMNS_EXPORT = ({ pathname, permission }) => {
    switch (permission) {
        default:
            return renderColumnsWithPathname(pathname)
    }
}