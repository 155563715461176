import React, { useState, useEffect } from "react";

export const useFetchGET = (url) => {
  const [getData, setGetData] = useState({});
  const [errorMessage, setErrorMessage] = useState({});
  useEffect(() => {
    if (url) {
      (async () => {
        try {
          const response = await fetch(url);
          const data = await response.json();
          setGetData(data);
        } catch (err) {
          console.log("Error Fetch GET: ", err);
          setErrorMessage(err);
        } // Exception
      })();
    } // Check NULL url
  }, [url]); // useEffect

  return { getData, errorMessage };
};

export const useCallAPI = (url, action, obj = {}) => {
  const [getData, setGetData] = useState({});
  const [errorMessage, setErrorMessage] = useState({});
  useEffect(() => {
    if (url) {
      (async () => {
        try {
          const response = await fetch(url, {
            method: action,
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(obj),
          });
          const data = await response.json();
          setGetData(data);
        } catch (err) {
          console.log(err);
          setErrorMessage(err);
        } // Exception
      })();
    } // Check NULL url
  }, [url]); // useEffect

  return { getData, errorMessage };
};
