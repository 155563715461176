import { useState } from "react";

export const useCollapseState = () => {
  const [activeKey, setActiveKey] = useState([
    "attachment",
    "docDetailGeneral",
    "docDetail",
    "formPrint",
  ]);

  return {
    activeKey,
    setActiveKey,
  };
};
