import React from "react"
import { useText } from "../../conponents/typography/Typography";
import { filterSearchColumn, useSorterTable } from "../../functions/UseFunction";
import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";
import moment from "moment";
import { USE_COLORS } from "../Colors";
import { Input, Select } from "antd";

export const ColumnImportMapFocusShopSaleCode = ({ dataSource, onChangeRow, optionSaleCode }) => [
	{
		title: "รหัสพื้นที่ Focus Shop",
		dataIndex: "saleCodeFS",
		key: "saleCodeFS",
		align: "center",
		width: 100,
		...filterSearchColumn("saleCodeFS", "รหัสพื้นที่ Focus Shop"),
	},
	{
		title: "ชื่อพื้นที่ Focus Shop",
		dataIndex: "saleCodeFSName",
		key: "saleCodeFSName",
		align: "center",
		width: 100,
		...filterSearchColumn("saleCodeFSName", "ชื่อพื้นที่ Focus Shop"),
	},
	{
		title: "รหัสเซลล์",
		dataIndex: "childrenFS",
		key: "name",
		align: "center",
		width: 100,
		...filterSearchColumn("childrenFS", "รหัสเซลล์"),
		render: (text, record) => {
			return <Select showSearch style={{ width: '100%' }} name={`childrenFS${record.rowNo}`} defaultValue={dataSource[record.rowNo - 1] ? dataSource[record.rowNo - 1].childrenFS : ''}
				onChange={(e) => onChangeRow(e, record.rowNo, 'childrenFS')}
			>
				{
					optionSaleCode.length > 0 && optionSaleCode.map(el => {
						return <Select.Option value={el.childCode}>{el.childCode}</Select.Option>
					})
				}
			</Select>
		}
	},
];

export const ColumnFocusShopStoreMaster = () => [
	{
		title: "รหัสร้าน Focus Shop",
		dataIndex: "saleCodeFS",
		key: "saleCodeFS",
		align: "center",
		width: 100,
		...filterSearchColumn("saleCodeFS", "รหัสร้าน Focus Shop"),
	},
	{
		title: "ชื่อร้าน Focus Shop",
		dataIndex: "saleCodeFSName",
		key: "saleCodeFSName",
		align: "center",
		width: 100,
		...filterSearchColumn("saleCodeFSName", "ชื่อร้าน Focus Shop"),
	},
];

export const EXPORT_TEMPLATE_PAGES = () => {
	return [{
		id: 1,
		// title: 'เลขบัตรประชาชน',
		title: 'userId',
		field: 'userId'
	},
	{
		id: 2,
		// title: 'เลขบัตรประชาชน',
		title: 'saleCode',
		field: 'saleCode'
	},
	{
		id: 3,
		// title: 'คำนำหน้าชื่อ (ไทย)',
		title: 'incentiveStrategy',
		field: 'incentiveStrategy'
	}, {
		id: 4,
		// title: 'คำนำหน้าชื่อ (อังกฤษ)',
		title: 'incentiveFocusShop',
		field: 'incentiveFocusShop'
	}
	]
}