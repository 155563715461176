import React from 'react'
import './index.css'
import { Button } from 'antd'
import { BaseText } from '../text'
import { colors } from '../../../themes'

export const ButtonRemove = ({ buttonText, style, onClick, ...rest }) => {
    return (
        <div className="button-remove" style={style}>
            <Button type="dashed" onClick={onClick}
                style={{
                    width: 20,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    borderColor: colors.error,
                }}
                {...rest} >
                <BaseText label={buttonText || 'ลบ'} color={colors.error} />
            </Button>
        </div >
    )
}
