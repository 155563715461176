import React, { useContext } from "react";
import "../CSSPersonal.css";

import {
  KEY_PERSONAL,
  PersonalContext,
} from "../servicesPersonal/personalProvider";
import ManagePersonal from "../componentsPersonal/managePersonal";
import { PATHNAME } from "../../constants/enums/pathname";
import { STEPS_PROGRESS_BLOCK } from "../constantsPersonal/blockPersonal";
import { FIELD_EMP } from "../constantsPersonal/contents/employeeInfo";
import { decryptString } from "../../service/functions/encodeFunction";

const BlockPersonal = (props) => {
  const { personalState } = useContext(PersonalContext);
  return (
    <ManagePersonal
      refData={{
        [FIELD_EMP.EMPLOYEE_ID]: decryptString(
          decodeURIComponent(props.params[FIELD_EMP.EMPLOYEE_ID]) //decrypt improve security
        ),
      }}
      initial={{ ...personalState[KEY_PERSONAL.BLOCK_PERSONAL] }}
      pathname={PATHNAME.BLOCK_PERSONAL}
      process={STEPS_PROGRESS_BLOCK}
    />
  );
};

export default BlockPersonal;
