import _ from "lodash"

export const setDataFormPrint = (value) => {
    return  _.pick(value,[
        'runningNo',
        'refPrNo',
        'userId',
        'empFullNameTH',
        'empNickName',
        'positionNameTH',
        'empFullNameEN',
        'positionNameEN',
        'departmentName',
        'subDepartmentName',
        'salary',
        'jobGrade',
        'companyNameTH',
        'startDateJob',
        'workShiftName',
        'isSelectedDaily',
        'isSelectedTemp',
        'isSelectedMonthly',
        'contractMonth',
        'startDateContract',
        'endContractDate',
        'head1FullName',
        'head1Position',
        'head1Department',
        'head2FullName',
        'head2Position',
        'head2Department',
        'inPlan1',
        'notInPlan1',
        'approved1',
        'rejected1',
        'rejectedRemark1',
        'inPlan2',
        'notInPlan2',
        'approved2',
        'rejected2',
        'rejectedRemark2',
        'companyCode'
    ]);
}