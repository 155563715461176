import Cookies from "js-cookie";
import React, { useState } from "react";
import { SetStringToObject } from "../../constants/functions";
import encodeString from "../../extendFunction/encodeString";
import { SearchOutlined } from "@ant-design/icons";
import { Button, Input, Space } from "antd";
import moment from "moment";
import FormChangeShiftWork from "./../conponents/forms/FormChangeShiftWork";
import { PATH_ROUTE } from "../constants/ConstantPath";

export const toUndefined = (arr) => {
  let obj = {};
  for (let i in arr) if (arr[i]) obj[arr[i]] = undefined;
  return obj;
};

export const useModal = () => {
  let [isOpen, setIsOpen] = useState(false);
  const open = () => setIsOpen(true);
  const close = () => setIsOpen(false);
  return [isOpen, open, close];
};

export const GET_UserLogin = () => {
  const obj = SetStringToObject(Cookies.get("cookie_session"));
  if (obj) {
    const userlogin = encodeString.decode(obj.ID_User);
    const userName = encodeString.decode(obj.Username);
    const location = encodeString.decode(obj.location);
    const permission = encodeString.decode(obj.premission);
    const token = encodeString.decode(obj.token);
    return { userlogin, location, permission, token, userName };
  }
};

export const getUniqueListBy = (arr, key) => {
  return [...new Map(arr.map((item) => [item[key], item])).values()];
};

export const sumColumnWidth = (column) => {
  return column.reduce((sum, current) => sum + current.width, 0);
};

export const DisableButtonSSO = (arr, property) => {
  let myFilter = arr.filter((el) => el[property] !== null);
  return myFilter.length !== 0 ? true : false;
};

export const sendDataExcel = (arr = []) => {
  return arr.map((row, index) => ({ numRow: index + 1, ...row }));
};

export const filterSearchColumn = (dataIndex, placeholer) => {
  return {
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm: fnSearch,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`ค้นหา ${placeholer}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => fnSearch()}
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => {
              fnSearch({ closeDropdown: false });
            }}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            ค้นหา
          </Button>
          <Button
            onClick={() => {
              clearFilters();
              fnSearch();
            }}
            size="small"
            style={{ width: 90 }}
          >
            ล้าง
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
  };
};

export const filterRoute = ({ dataUser = [], objRoute = {} }) => {
  let result = [];
  dataUser.forEach((row) => {
    const pathLink = row.pathLink;
    if (objRoute.hasOwnProperty(pathLink)) {
      result.push(objRoute[pathLink]);
    }
  }); // forEach
  result.push(objRoute["BaseRoute"]);
  return result.flat();
};

export const subStringDate = (dateString = "") => {
  return dateString ? dateString.substr(0, 10) : dateString;
};

export const checkObjectValue = (obj) => {
  let result = {};
  const formatDate = "YYYY-MM-DD";
  for (const key in obj) {
    if (typeof obj[key] === "object") {
      result[key] = moment(obj[key]).format(formatDate);
    } else {
      result[key] = obj[key] || "";
    }
  }
  return result;
};

export const useReplace = (str, oldStr, newStr) => {
  return str ? str.replace(oldStr, newStr) : "";
};

export const useSorterTable = (dataIndex, type) => {
  switch (type) {
    case "number":
      return { sorter: (a, b) => a[`${dataIndex}`] - b[`${dataIndex}`] };
    case "string":
      return {
        sorter: (a, b) => a[`${dataIndex}`].length - b[`${dataIndex}`].length,
      };
    case "date":
      return {
        sorter: (a, b) =>
          moment(a[`${dataIndex}`]).unix() - moment(b[`${dataIndex}`]).unix(),
      };
    default:
      return {};
  }
};

export function calcutateDateDiff(startDate, endDate) {
  let m1 = new Date(`${startDate || ""}`);
  let m2 = new Date(`${endDate || ""}`);
  let result = (+m1 - +m2) / 1000 / 60 / 60;
  return Math.abs(result);
}
