import moment from "moment";
import { COMPONENT_TYPE, ERROR_MESSAGE } from ".."
import { getDatePeriodNow } from './../../../functions/index';

const DEFINE = {
	depNo: {
		label: "แผนก",
		name: "depNo",
	},
	startDate: {
		label: "วันที่งวดเริ่มต้น",
		name: "startDate",
	},
	endDate: {
		label: "ถึงวันที่",
		name: "endDate",
	}
}

export const ComponentRecheckCommissionIncentiveDashboard = ({
	options = {},
	isDisable = {},
	handleChange = () => null,
}) => {
	const { depNo, startDate, endDate } = DEFINE
	return [
		{ // Row
			rowProps: { justify: "start", align: "bottom" },
			column: [ // column
				// {
				// 	breakpoint: { xs: 24, sm: 24, md: 24, lg: 12, xl: 4 },
				// 	component: {
				// 		type: COMPONENT_TYPE.SELECT,
				// 		itemProps: {
				// 			name: depNo.name,
				// 			label: depNo.label,
				// 			rules: [{ required: false, message: ERROR_MESSAGE.SELECT(depNo.label) }],
				// 		},
				// 		componentProps: {
				// 			style: { width: "100%" },
				// 			placeholder: ERROR_MESSAGE.SELECT(depNo.label),
				// 			disabled: isDisable[depNo.name] || false,
				// 		},
				// 		fnChange: (value) => handleChange(depNo.name, value),
				// 		dropdown: options[depNo.name],
				// 	},
				// },
				{
					breakpoint: { xs: 24, sm: 24, md: 24, lg: 12, xl: 4 },
					component: {
						type: COMPONENT_TYPE.DATE,
						itemProps: {
							name: startDate.name,
							label: startDate.label,
							rules: [{ required: false, message: ERROR_MESSAGE.DATEPICKER }],
						},
						componentProps: {
							style: { width: "100%" },
							placeholder: ERROR_MESSAGE.DATEPICKER,
							disabled: isDisable[startDate.name] || false,
						},
						fnChange: (date, dateString) => handleChange(startDate.name, dateString),
					},
				},
				{
					breakpoint: { xs: 24, sm: 24, md: 24, lg: 12, xl: 4 },
					component: {
						type: COMPONENT_TYPE.DATE,
						itemProps: {
							name: endDate.name,
							label: endDate.label,
							rules: [{ required: false, message: ERROR_MESSAGE.DATEPICKER }],
						},
						componentProps: {
							style: { width: "100%" },
							placeholder: ERROR_MESSAGE.DATEPICKER,
							disabled: isDisable[endDate.name] || false,
						},
						fnChange: (date, dateString) => handleChange(endDate.name, dateString),
					},
				},
			], // column
		}, // Row
	]
}

export const ComponentRecheckCommissionIncentiveAction = ({
	options = {},
	isDisable = {},
	handleChange = () => null,
	isDepNo
}) => {
	const { depNo, startDate, endDate } = DEFINE
	return [
		{ // Row
			rowProps: { justify: "start", align: "bottom" },
			column: [ // column
				{
					breakpoint: { xs: 24, sm: 24, md: 24, lg: 12, xl: 4 },
					component: {
						type: COMPONENT_TYPE.SELECT,
						itemProps: {
							name: depNo.name,
							label: depNo.label,
							rules: [{ required: true, message: ERROR_MESSAGE.SELECT(depNo.label) }],

						},
						componentProps: {
							style: { width: "100%" },
							placeholder: ERROR_MESSAGE.SELECT(depNo.label),
							disabled: isDepNo,
							mode: "multiple"
						},
						fnChange: (value) => handleChange(depNo.name, value),
						dropdown: options[depNo.name],
					},
				},
				{
					breakpoint: { xs: 24, sm: 24, md: 24, lg: 12, xl: 4 },
					component: {
						type: COMPONENT_TYPE.DATE,
						itemProps: {
							name: startDate.name,
							label: startDate.label,
							rules: [{ required: false, message: ERROR_MESSAGE.DATEPICKER }],
						},
						componentProps: {
							style: { width: "100%" },
							placeholder: ERROR_MESSAGE.DATEPICKER,
							disabled: true,
						},
						fnChange: (date, dateString) => handleChange(startDate.name, dateString),
					},
				},
				{
					breakpoint: { xs: 24, sm: 24, md: 24, lg: 12, xl: 4 },
					component: {
						type: COMPONENT_TYPE.DATE,
						itemProps: {
							name: endDate.name,
							label: endDate.label,
							rules: [{ required: false, message: ERROR_MESSAGE.DATEPICKER }],
						},
						componentProps: {
							style: { width: "100%" },
							placeholder: ERROR_MESSAGE.DATEPICKER,
							disabled: true
						},
						fnChange: (date, dateString) => handleChange(endDate.name, dateString),
					},
				},
			], // column
		}, // Row
	]
}


