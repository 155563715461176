export const BREADCRUMB_DASHBOARD_OT = (_permission) => {
    switch (_permission) {
        default:
            return [
                { label: "HRM - PMS" },
                { label: "คำขอโอที" },
                { label: "จัดการคำขอโอที" }
            ]
    }
}
export const BREADCRUMB_EXPORT_OT = (_permission) => {
    switch (_permission) {
        default:
            return [
                { label: "HRM - PMS" },
                { label: "คำขอโอที" },
                { label: "Export CSV" }
            ]
    }
}
export const BREADCRUMB_INSERT_OT = (_permission) => {
    switch (_permission) {
        default:
            return [
                { label: "HRM - PMS" },
                { label: "คำขอโอที" },
                { label: "ลงทะเบียนคำขอโอที" }
            ]
    }
}
export const BREADCRUMB_UPDATE_OT = (_permission) => {
    switch (_permission) {
        default:
            return [
                { label: "HRM - PMS" },
                { label: "คำขอโอที" },
                { label: "แก้ไขคำขอโอที" }
            ]
    }
}
export const BREADCRUMB_REPORT_PRINT_OT = (_permission) => {
    switch (_permission) {
        default:
            return [
                { label: "HRM - PMS" },
                { label: "คำขอโอที" },
                { label: "ลงทะเบียนคำขอโอที" }
            ]
    }
}