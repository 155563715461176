import React, { useContext, useState, useRef } from 'react'
import { Modal, Spin } from 'antd';

import { LayoutTable } from '../../moduleKPIs/common/layouts';
import ButtonSearch from '../../constants/buttons/buttonSearch';
import { TABLE_CONTRACT } from '../commonsContract/structuresContract/tableContract';
import { KEY_OPTION } from '../../constants/enums/enumCenter';
import { ContractContext } from '../servicesContract/contractProvider';
import { MasterCenterContext } from '../../service/master/masterProvider';
import { PATHNAME } from '../../constants/enums/pathname';
import { ButtonExport, FIELD_BUTTON } from '../../constants/buttons';
import { APIConfirmProbationContract } from '../servicesContract/contractConnect';
import { COLUMNS_EXPORT } from '../commonsContract/structuresContract/columnsContract';


const DashboardBenefitContract = ({ pathname }) => {
    const refExport = useRef();

    const [loading, setLoading] = useState(false);
    const { masterCenterState } = useContext(MasterCenterContext);
    const { contractState, getAPI, userLogin, userPermission } = useContext(ContractContext);
    const { dataTable: { rowKey, dataSource } } = contractState;


    async function onSearch() {
        try {
            setLoading(true)
            await getAPI({ fieldname: KEY_OPTION.PROBATION.DASHBOARD_BENEFIT, payload: { ...masterCenterState } })
        } catch (error) {
            console.log({ error })
            setLoading(false)
        } finally {
            setLoading(false)

        }
    }

    return (
        <div>
            <div className="layout-content-btn-search">
                <ButtonSearch
                    disabled={false}
                    onClick={() => onSearch()}
                    style={{ padding: 10 }} />
            </div>
            <div className="layout-content-btn-export-track">
                <ButtonExport
                    ref={refExport}
                    labelButton=""
                    fieldname="รายงานค้างสวัสดิการพนักงานใหม่ (สัญญาจ้าง)"
                    columns={COLUMNS_EXPORT({
                        pathname: pathname,
                        permission: userPermission
                    })}
                    dataSource={dataSource}
                    style={{ paddingRight: 10 }}
                    styleButton={{}}
                />
            </div>
            <Spin spinning={loading} tip="กำลังโหลด">
                <LayoutTable
                    scroll={{ x: 4000 }}
                    bordered={false}
                    rowKey={rowKey}
                    dataSource={dataSource}
                    columns={TABLE_CONTRACT({
                        pathname,
                        userLogin: userLogin,
                        actionButton: async ({ fieldBtn, payload }) => {
                            try {
                                setLoading(true)
                                window.localStorage.setItem(KEY_OPTION.DATA.PROBATION_CONTRACT, JSON.stringify(payload))
                                switch (fieldBtn) {
                                    case FIELD_BUTTON.INSERT_CONTRACT:
                                        await window.open(PATHNAME.INSERT_PROBATION_CONTRACT, '_blank')
                                        break;
                                    case FIELD_BUTTON.UPDATE_CONTRACT:
                                        await window.open(PATHNAME.UPDATE_PROBATION_CONTRACT, '_blank')
                                        break;
                                    case FIELD_BUTTON.VIEW_CONTRACT:
                                        await window.open(PATHNAME.VIEW_PROBATION_CONTRACT, '_blank')
                                        break;
                                    case FIELD_BUTTON.CONFIRM_CONTRACT:
                                        // console.log({ payload })
                                        let docSOS = (payload.type === 'sos') ? payload.StaffCode : ''
                                        let data = {
                                            StaffCode: payload.StaffCode,
                                            typeInsurance: payload.type,
                                            typeBenefit: 'in',
                                            user_login: userLogin,
                                            docSOS: docSOS,
                                            refDocProbation: payload.DocNo
                                        };
                                        let res = await APIConfirmProbationContract({ ...data })
                                        // console.log({ res })
                                        if (!!res) {
                                            Modal.success({
                                                title: `ยืนยันการส่งเอกสารสำเร็จ `,
                                                onOk: () => onSearch()
                                            })
                                        }
                                        break;
                                    default:
                                        break;
                                }
                            } catch (error) {
                                console.log({ error })
                                setLoading(false)

                            } finally {
                                setLoading(false)

                            }
                        }
                    })}
                />
            </Spin>
        </div>
    )
}

export default DashboardBenefitContract