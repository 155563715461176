import React from 'react'
import { TYPE } from '../../../moduleKPIs/common/inputs';
import { Input, Select, DatePicker, } from 'antd';
import TimeInput from 'react-time-input';
const { TextArea } = Input;

const LayoutInput = ({ type, value, onChange, inputOption }) => {
    switch (type) {
        case TYPE.TEXT:
            return (
                <div> {value || '-'}</div>
            )
        case TYPE.TEXT_RESULT:
            return (
                <div> {(value === 'pass' ? 'ผ่านการะประเมิน' : 'ไม่ผ่านการประเมิน') || '-'}</div>
            )
        case TYPE.SELECT:
            return (
                <Select
                    showSearch
                    showArrow={false}
                    style={{ width: '100%' }}
                    disabled={inputOption.disabled}
                    placeholder={inputOption.placeholder}
                    optionFilterProp="children"
                    onChange={(event) => onChange({ fieldname: inputOption.fieldname, value: [event] })}
                    filterOption={(input, option) =>
                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    // defaultValue={inputOption.defaultValue}
                    {...inputOption}
                >
                    {
                        Array.isArray(inputOption.plainOptions) && inputOption.plainOptions.map((option, optionIndex) => {
                            return (
                                <Select.Option key={optionIndex} value={option.optionValue}>
                                    {option.optionLabel}
                                </Select.Option>
                            )
                        })
                    }
                </Select>
            )
        case TYPE.DATE_PICKER:
            return (
                <DatePicker
                    style={{ width: '100%' }}
                    disabled={inputOption.disabled}
                    placeholder={inputOption.placeholder}
                    // value={value}
                    defaultValue=""
                    onChange={(dates, dateStrings) => onChange({ fieldname: inputOption.fieldname, value: { dates, dateStrings } })}
                    {...inputOption}
                />
            )
        case TYPE.TIME_PICKER:
            // console.log('TIME_PICKER', value)
            return (
                <TimeInput
                    // value={value || inputOption.defaultValue}
                    initTime={value || inputOption.defaultValue}
                    // value={value || }
                    className='form-control'
                    onTimeChange={(val) => {
                        onChange({ fieldname: inputOption.fieldname, value: val })
                    }}
                // {...inputOption}
                />
                // <TimePicker
                //     style={{ width: '100%' }}
                //     disabled={inputOption.disabled}
                //     placeholder={inputOption.placeholder}
                //     // value={value}
                //     onChange={(dates, dateStrings) => onChange({ fieldname: inputOption.fieldname, value: { dates, dateStrings } })}
                //     {...inputOption}
                // />
            )

        case TYPE.NUMBER:
            return (
                <Input
                    value={value}
                    disabled={inputOption.disabled}
                    placeholder={inputOption.placeholder}
                    onChange={(e) => {
                        if (isFinite(e.target.value)) {
                            onChange({ fieldname: inputOption.fieldname, value: e.target.value })
                        }
                    }}
                    {...inputOption}
                />
            )
        case TYPE.NUMBERFIX:
            return (
                <Input
                    value={value}
                    disabled={inputOption.disabled}
                    placeholder={inputOption.placeholder}
                    {...inputOption}
                />
            )

        case TYPE.INPUT:
            return (
                <Input
                    // value={value}
                    disabled={inputOption.disabled}
                    placeholder={inputOption.placeholder}
                    onChange={(e) =>
                        onChange({ fieldname: inputOption.fieldname, value: e.target.value })
                    }
                    {...inputOption}
                />
            )
        case TYPE.INPUT_AREA:
            return (
                <TextArea
                    // defaultValue={value}
                    disabled={inputOption.disabled}
                    placeholder={inputOption.placeholder}
                    onChange={(e) =>
                        onChange({ fieldname: inputOption.fieldname, value: e.target.value })
                    }
                    {...inputOption}
                />
            )
        default:
            break;
    }
}

export default LayoutInput