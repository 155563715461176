import React, { useContext, useState, useEffect } from 'react'
import { Col, Row, Spin } from 'antd'
import { ButtonTheme } from '../../common/buttons'
import { MasterContext } from '../../services/master/masterProvider'
import { STRUCTURE_KPIS } from '../../constants/structures/structuresKPIs'
import { ReportContext, REPORT_TYPE } from '../../services/report/reportProvider'
import { LayoutBreadcrumb, LayoutSearch, LayoutWrapper, LayoutTabs } from '../../common/layouts'
import { MASTER_OPTION } from '../../services/servicesOptions'

export const PageManageKPIs = () => {
    const STRUCTURE = STRUCTURE_KPIS;
    const { masterState, defaultMaster, onChange } = useContext(MasterContext)
    const { searchReport } = useContext(ReportContext)
    const [loading, setLoading] = useState(false)
    let SEARCH_LIST = (masterState[`OPTION_${MASTER_OPTION.BOSS_INSPECT}`].length > 0) ? STRUCTURE.SEARCH_LISTS('approvalNo3') : STRUCTURE.SEARCH_LISTS()
    let TAB_LIST = (masterState[`OPTION_${MASTER_OPTION.BOSS_INSPECT}`].length > 0) ? STRUCTURE.TABS_LISTS('approvalNo3') : STRUCTURE.TABS_LISTS()

    //console.log({ masterState })
    useEffect(() => {
        async function fetchReport() {
            await setLoading(true)
            await searchReport({ reportName: REPORT_TYPE.EVALUATION, searchOption: masterState })
            await defaultMaster()
            await setLoading(false)
        }
        fetchReport()
    }, [])
    return (
        <LayoutWrapper
            breadcrumb={<LayoutBreadcrumb breadcrumbLists={STRUCTURE.BREADCRUMB()} />}
            search={
                <Row gutter={[24, 10]}>
                    <div style={{ position: 'absolute', top: 0, zIndex: 1, right: -15 }}>
                        <ButtonTheme
                            type='primary'
                            buttonText="ค้นหา"
                            onClick={async () => {
                                await setLoading(true)
                                await searchReport({
                                    reportName: REPORT_TYPE.EVALUATION,
                                    searchOption: masterState,
                                    factory: false
                                })
                                await setLoading(false)
                            }} />
                    </div>
                    {
                        SEARCH_LIST.map((each, eachIndex) => {
                            return (<Col span={each.span} key={eachIndex}>
                                <LayoutSearch
                                    type={each.type}
                                    searchInput={each.searchInput}
                                    selectAll={each.selectAll}
                                    fieldOption={each.fieldOption}
                                    header={each.header}
                                    checkedDetail={masterState[`SEARCH_${each.fieldOption}`]}
                                    onChange={onChange}
                                    placeholder={each.placeholder}
                                    plainOptions={masterState[each.fieldOption]} />
                            </Col>)
                        })
                    }
                </Row>
            }
            content={
                <div className="layout-content-card">
                    <Spin spinning={loading} tip="กำลังโหลด...">

                        <LayoutTabs tabsLists={TAB_LIST} />
                    </Spin>
                </div>
            }
        />
    )
}
