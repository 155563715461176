import { FIELD_STEP, MESSAGE_ERROR } from ".."
import { KEY_TYPE } from "../../../constants/enums/enumCenter"

/* -------------------------------------------------------------------------- */
/*                                 LABEL_PAGES                                */
/* -------------------------------------------------------------------------- */

const LABEL_PAGES = {
    //!เปลี่ยนชื่อ Label ที่นี่
    FULLNAME: 'ชื่อ-นามสกุล (Full name)',
    RELATION: 'ความสัมพันธ์ (Relationship)',
    AGE: 'อายุ (Age)',
    OCCUPATION: 'อาชีพ (Occupation)',
    ADDRESS_OFFICE: 'ที่อยู่ที่ทำงาน (Address of Office / Institution / Home)',
}

/* -------------------------------------------------------------------------- */
/*                                FIELD_PAGES                                */
/* -------------------------------------------------------------------------- */

const FIELD_PAGES = {
    //!เปลี่ยนชื่อ Field ที่นี่
    FULLNAME: 'fullName',
    RELATION: 'relation',
    AGE: 'age',
    OCCUPATION: 'occupation',
    ADDRESS_OFFICE: 'addressOffice',
}


/* -------------------------------------------------------------------------- */
/*                                INITIAL_PAGES                                */
/* -------------------------------------------------------------------------- */

const INITIAL_PAGES = {
    fullName: '',
    relation: '',
    age: '',
    occupation: '',
    addressOffice: ''
}


export const FIELD_FAMILY = FIELD_PAGES
export const INITIAL_FAMILY = INITIAL_PAGES
/* -------------------------------------------------------------------------- */
/*                                CONTENT_PAGES                               */
/* -------------------------------------------------------------------------- */

export const CONTENT_FAMILY = (props) => {
    const { disabledAll } = props
    /**
    * TODO: สามารถแบ่งตาม  location ได้ โดยใช้ switch case
    */
    return [
        [{
            span: 24,
            inputprops: {
                type: KEY_TYPE.CARD_FORM,
                name: FIELD_STEP.COLLAPSE_S12,//family
                removelenght: 0,
                header: (idx) => `บุคคลที่ ${idx + 1}`,
                initialValues: { ...INITIAL_PAGES },
                disabled: disabledAll,
                content: (field) => [{
                    span: 9,
                    itemprops: {
                        name: [field.name, FIELD_PAGES.FULLNAME],
                        label: LABEL_PAGES.FULLNAME,//ชื่อ-นามสกุล
                        fieldKey: [field.fieldKey, FIELD_PAGES.FULLNAME],
                        rules: [{ required: false, message: MESSAGE_ERROR.INPUT }]
                    },
                    inputprops: {
                        type: KEY_TYPE.INPUT,
                        disabled: disabledAll,
                        placeholder: ''
                    }
                }, {
                    span: 6,
                    itemprops: {
                        name: [field.name, FIELD_PAGES.RELATION],
                        label: LABEL_PAGES.RELATION,//ความสัมพันธ์
                        fieldKey: [field.fieldKey, FIELD_PAGES.RELATION],
                        rules: [{ required: false, message: MESSAGE_ERROR.INPUT }]
                    },
                    inputprops: {
                        type: KEY_TYPE.INPUT,
                        disabled: disabledAll,
                        placeholder: ''
                    }
                }, {
                    span: 3,
                    itemprops: {
                        name: [field.name, FIELD_PAGES.AGE],
                        label: LABEL_PAGES.AGE,//อายุ
                        fieldKey: [field.fieldKey, FIELD_PAGES.AGE],
                        rules: [{ required: false, message: MESSAGE_ERROR.NUMBER }]
                    },
                    inputprops: {
                        type: KEY_TYPE.NUMBER,
                        length: 2,
                        disabled: disabledAll,
                        placeholder: ''
                    }
                }, {
                    span: 6,
                    itemprops: {
                        name: [field.name, FIELD_PAGES.OCCUPATION],
                        label: LABEL_PAGES.OCCUPATION,//อาชีพ
                        fieldKey: [field.fieldKey, FIELD_PAGES.OCCUPATION],
                        rules: [{ required: false, message: MESSAGE_ERROR.INPUT }]
                    },
                    inputprops: {
                        type: KEY_TYPE.INPUT,
                        disabled: disabledAll,
                        placeholder: ''
                    }
                }, {
                    span: 24,
                    itemprops: {
                        name: [field.name, FIELD_PAGES.ADDRESS_OFFICE],
                        label: LABEL_PAGES.ADDRESS_OFFICE, //ที่อยู่ที่ทำงาน
                        fieldKey: [field.fieldKey, FIELD_PAGES.ADDRESS_OFFICE],
                        rules: [{ required: false, message: MESSAGE_ERROR.INPUT }]
                    },
                    inputprops: {
                        type: KEY_TYPE.INPUT_AREA,
                        disabled: disabledAll,
                        placeholder: ''
                    }
                }]
            }
        }]
    ]
}