import React, { createContext, useReducer, } from "react";
import { MASTER_OPTION, servicesOptions } from "../../../moduleKPIs/services/servicesOptions";
import { path } from "ramda";
import { Modal } from "antd";
import { optionYear, optionQuarter, optionStatusEvac, getQuarter } from "../../../moduleKPIs/common/options";
import { APISelectOptionDepEvac, APISelectOptionFrequencyWorkWith, APISelectDepInFilterEvac, APISelectFilterDepEvac, APISelectFormEvacAll, APISelectFormEvacByUserID } from "./masterEvacConnect";


export const MasterEvacContext = createContext();

const initialState = {
    [MASTER_OPTION.OPTION_YEAR]: servicesOptions(MASTER_OPTION.OPTION_YEAR, optionYear(false, 3), false),
    [MASTER_OPTION.OPTION_QUARTER]: optionQuarter(),
    [MASTER_OPTION.OPTION_STATUS_EVAC]: optionStatusEvac(),
    [MASTER_OPTION.OPTION_DEP_EVAC]: [],
    [MASTER_OPTION.OPTION_FILTER_DEP_EVAC]: [],
    [MASTER_OPTION.OPTION_FREQUENCY_WORK_WITH]: [],
    [MASTER_OPTION.OPTION_FORM_EVAC]: [],

    [`SEARCH_${MASTER_OPTION.OPTION_YEAR}`]: new Date().getFullYear(),
    [`SEARCH_${MASTER_OPTION.OPTION_QUARTER}`]: getQuarter(new Date().getMonth() - 1),
    [`SEARCH_${MASTER_OPTION.OPTION_FREQUENCY_WORK_WITH}`]: null,
    [`SEARCH_${MASTER_OPTION.OPTION_FORM_EVAC}`]: null,
    [`SEARCH_${MASTER_OPTION.OPTION_DEP_EVAC}`]: [],
}

const MASTER_EVAC_ACTION = {
    SET_MASTER_EVAC_SUCCESS: "SET_MASTER_EVAC_SUCCESS",
    SET_MASTER_EVAC_FAILURE: "SET_MASTER_EVAC_FAILURE"
}

const masterEvacReducer = (state, action) => {
    switch (action.type) {
        case MASTER_EVAC_ACTION.SET_MASTER_EVAC_SUCCESS:
            return { ...state, ...action.state }
        case MASTER_EVAC_ACTION.SET_MASTER_EVAC_FAILURE:
        default:
            return state
    }
}

const ProviderMasterEvac = ({ children }) => {
    const { userLogin, userPermission } = children.props;
    const [masterEvacState, masterEvacDispatch] = useReducer(masterEvacReducer, initialState)

    async function getMaster({ fieldname, payload }) {
        switch (fieldname) {
            case MASTER_OPTION.OPTION_DEP_EVAC:
                switch (userPermission) {
                    // case 'HR':
                    // case 'HR-HeadDep':
                    // case 'HR-Admin':
                    // case 'HR-AdminOff':
                    // case 'HR-Ass':
                    // case 'Intern':
                    // case 'HR-Super':
                    case 'HRS-OFF':
                    case 'HRD-OFF':
                    case 'HRM-OFF':
                    case 'HRS-FAC':
                    case 'HRD-FAC':
                    case 'HRM-FAC':
                        await APISelectOptionDepEvac({ depNo: '' }, res => {

                            if (res) {
                                masterEvacDispatch({
                                    type: MASTER_EVAC_ACTION.SET_MASTER_EVAC_SUCCESS,
                                    state: {
                                        [fieldname]: servicesOptions(MASTER_OPTION.OPTION_DEP_EVAC, res, true)
                                    }
                                })
                            }
                        })
                        break;

                    default:
                        await APISelectFilterDepEvac({
                            userId: userLogin,
                            year: masterEvacState[`SEARCH_${MASTER_OPTION.OPTION_YEAR}`],
                            quarter: masterEvacState[`SEARCH_${MASTER_OPTION.OPTION_QUARTER}`],
                        }, async res => {
                            // console.log({ res })
                            if (res) {
                                let depNoPermission = []
                                await servicesOptions(MASTER_OPTION.OPTION_DEP_EVAC, res, false).forEach(({ optionId }) => depNoPermission.push(optionId))
                                await masterEvacDispatch({
                                    type: MASTER_EVAC_ACTION.SET_MASTER_EVAC_SUCCESS,
                                    state: {
                                        [fieldname]: servicesOptions(MASTER_OPTION.OPTION_DEP_EVAC, res, false),
                                        [`SEARCH_${fieldname}`]: depNoPermission
                                    }
                                })
                            }
                        })
                        break;
                }
                break;
            case MASTER_OPTION.OPTION_FREQUENCY_WORK_WITH:
                await APISelectOptionFrequencyWorkWith(null, res => {
                    if (res) {
                        masterEvacDispatch({
                            type: MASTER_EVAC_ACTION.SET_MASTER_EVAC_SUCCESS,
                            state: { [fieldname]: servicesOptions(MASTER_OPTION.OPTION_FREQUENCY_WORK_WITH, res, false) }
                        })
                    }
                })
                break;
            case MASTER_OPTION.OPTION_FORM_EVAC:
                switch (userPermission) {
                    // case 'HR':
                    // case 'HR-HeadDep':
                    // case 'HR-Admin':
                    // case 'HR-AdminOff':
                    // case 'HR-Ass':
                    // case 'Intern':
                    // case 'HR-Super':
                    case 'HRS-OFF':
                    case 'HRD-OFF':
                    case 'HRM-OFF':
                    case 'HRS-FAC':
                    case 'HRD-FAC':
                    case 'HRM-FAC':
                        await APISelectFormEvacAll(null, async res => {
                            if (res) {
                                await masterEvacDispatch({
                                    type: MASTER_EVAC_ACTION.SET_MASTER_EVAC_SUCCESS,
                                    state: {
                                        [fieldname]: servicesOptions(MASTER_OPTION.OPTION_FORM_EVAC, res, false),
                                        // [`SEARCH_${fieldname}`]: servicesOptions(MASTER_OPTION.OPTION_FORM_EVAC, res, false)[0].optionId
                                    }
                                })
                            }
                        })

                        break;
                    default:
                        await APISelectFormEvacByUserID({
                            userId: userLogin,
                            year: masterEvacState[`SEARCH_${MASTER_OPTION.OPTION_YEAR}`],
                            quarter: masterEvacState[`SEARCH_${MASTER_OPTION.OPTION_QUARTER}`],
                        }, async res => {
                            if (res) {
                                await masterEvacDispatch({
                                    type: MASTER_EVAC_ACTION.SET_MASTER_EVAC_SUCCESS,
                                    state: {
                                        [fieldname]: servicesOptions(MASTER_OPTION.OPTION_FORM_EVAC, res, false),
                                        // [`SEARCH_${fieldname}`]: servicesOptions(MASTER_OPTION.OPTION_FORM_EVAC, res, false)[0].optionId
                                    }
                                })
                            }
                        })
                        break;
                }
                break;
            default:
                break;
        }
    }


    async function onChangeMaster({ fieldOption, checkedList, checkAll, plainOptions }) {
        //console.log({ fieldOption, checkedList, checkAll, plainOptions })
        switch (fieldOption) {
            case MASTER_OPTION.OPTION_YEAR:
            case MASTER_OPTION.OPTION_QUARTER:
            case MASTER_OPTION.OPTION_FORM_EVAC:
            case MASTER_OPTION.OPTION_FREQUENCY_WORK_WITH:
                /****  RETURN Number **/
                await masterEvacDispatch({
                    type: MASTER_EVAC_ACTION.SET_MASTER_EVAC_SUCCESS,
                    state: { [`SEARCH_${fieldOption}`]: checkedList[0] }
                })
                break;
            default:
                /****  RETURN Array **/
                await masterEvacDispatch({
                    type: MASTER_EVAC_ACTION.SET_MASTER_EVAC_SUCCESS,
                    state: { [`SEARCH_${fieldOption}`]: (checkAll) ? plainOptions : checkedList }
                })
                break;
        }

    }
    async function clearMaster({ fieldOption }) {
        switch (fieldOption) {
            case MASTER_OPTION.OPTION_DEP_EVAC:
                await masterEvacDispatch({
                    type: MASTER_EVAC_ACTION.SET_MASTER_EVAC_SUCCESS,
                    state: { [`SEARCH_${MASTER_OPTION.OPTION_DEP_EVAC}`]: [] }
                })
                break;
            default:
                break;
        }
    }

    return (
        <MasterEvacContext.Provider
            value={{
                onChangeMaster,
                masterEvacState,
                getMaster,
                clearMaster,
                masterEvacDispatch
            }}>
            {children}
        </MasterEvacContext.Provider>
    )
}


export default ProviderMasterEvac