import { CONTENT_BLOCK_EMP } from './contents/blockEmp.jsx';
import { CONTENT_EMPLOYEE } from './contents/employeeInfo.jsx';
import { CONTENT_PERSONAL_DEFAULT } from './contents/personalInfo.jsx';

import { LABEL_STEP, FIELD_STEP } from './index.jsx';

const LABEL_PAGES = LABEL_STEP;
const FIELD_PAGES = FIELD_STEP;


export const STEPS_PROGRESS_BLOCK = (props) => {
    /**
     * TODO: สามารถแบ่งตาม activeTab , location ได้ โดยใช้ switch case
     */
    return [{
        key: 0,
        hidden: true, //ไม่ให้แสดง Step
        description: LABEL_PAGES.STEP_6, //แจ้งพนักงานพ้นสภาพ
        content: [{
            key: 1,
            name: FIELD_PAGES.COLLAPSE_S02,
            header: LABEL_PAGES.COLLAPSE_S02,
            layout: 'horizontal',
            gutter: [0, 0],
            content: () => CONTENT_PERSONAL_DEFAULT({ ...props }),
        }, {
            key: 2,
            name: FIELD_PAGES.COLLAPSE_S03,
            header: LABEL_PAGES.COLLAPSE_S03,
            content: () => CONTENT_BLOCK_EMP({ ...props }),
        }]
    }]
}