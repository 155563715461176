import React, { useState } from "react";
import { Form, Card, Spin, Tabs, Space } from "antd";
import CustomTableAtnd from "../tables/CustomTableAtnd";
import { useNotification } from "../notification/NotificationWithIcon";
import { useButton } from "../buttons/CustomButtonAntd";
import { useExportExcel } from "../../services/ExportExcel";
import { Header_BenefitEmpNew } from "../../constants/HeaderExcel";
import { optionSelectLocation, optionstatusStamp, optionStatusWorker, useOptionSelect } from "../../constants/OptionSelect";
import { useFixedQueryString } from "../../functions/UseMakeQueryString";
import { DisableButtonSSO, GET_UserLogin, toUndefined, sumColumnWidth, subStringDate } from "../../functions/UseFunction";
import moment from "moment";
import { useAlert } from "../modal/CustomAlert";
import { useConfirm } from "../modal/CustomConfirm";
import { configForm, WORDS } from "../../constants/ConstantComponent";
import { useRenderFormItem } from "../RenderComponents";
import { ComponentBenefitNewEmp, ComponentChangeInsurancePlanDatatimeStamp } from "../../constants/Benefits/Components";
import { POST, POST_INSERT_SEND_SSO, POST_SELECT_DASHBOARD_BENEFITS_NEW_EMP } from "../../../service";
import { ColumnBenefitNewEmp } from "../../constants/Benefits/Columns";

export const FormSearchBenefitNewEmp = () => {
  const [formSearch] = Form.useForm();
  const [formSendSSO] = Form.useForm();
  const { userlogin } = GET_UserLogin();
  // [START] States
  const [changeSearchValue, setChangeSearchValue] = useState({ statusStamp: "none" });
  const [dataTable, setDataTable] = useState([]);
  const [rowSelection, setRowSelection] = useState({ selectedRowKeys: [], selectedRows: [] });
  const [configOnFinish, setInsurancePlan] = useState({ typeInsurance: "", typeBenefit: "" });
  const [loadingScreen, setLoadingScreen] = useState(false);
  // [END] States
  let optionSearch = {
    company: useOptionSelect("company"),
    location: optionSelectLocation,
    depNo: useOptionSelect("department", useFixedQueryString(changeSearchValue, ["companyCode", "location"])),
    subDepNo: useOptionSelect("subDepartment", useFixedQueryString(changeSearchValue, ["company", "location", "depNo"])),
    statusMainCode: useOptionSelect("statusMainCode"),
    statusWorker: optionStatusWorker,
    statusStamp: optionstatusStamp,
  };

  // [START] Handle Form
  const onFinish = (values) => {
    const { company, location, depNo, subDepNo, startDate, toDate, statusMainCode, statusWorker, statusStamp } = values
    let objectBody = {
      company: company || "",
      location: location || "",
      depNo: depNo || "",
      subDepNo: subDepNo || "",
      startDate: startDate ? startDate.format("YYYY-MM-DD") : "",
      toDate: toDate ? toDate.format("YYYY-MM-DD") : "",
      statusMainCode: statusMainCode || "",
      statusWorker: statusWorker || "",
      statusStamp: statusStamp || "",
    }
    selectDashboardBenefitsNewEmp(objectBody);
  };

  const onFinishSendSSO = (values) => {
    const { datatimeStamp } = values
    const { selectedRows } = rowSelection
    let dataArray = [];
    if (selectedRows.length === 0) {
      useAlert({
        type: "warning",
        title: "แจ้งเตือน",
        content: `คุณยังไม่ได้เลือก "พนักงาน" กรุณาลองใหม่อีกครั้ง`,
        Fn: () => null,
      });
    } else if (!datatimeStamp) {
      useAlert({
        type: "warning",
        title: "แจ้งเตือน",
        content: `คุณยังไม่ได้เลือก "วันที่ยื่น" กรุณาลองใหม่อีกครั้ง`,
        Fn: () => null,
      });
    } else {
      const { typeInsurance, typeBenefit } = configOnFinish
      dataArray = selectedRows.map((row) => ({
        userId: row.userId,
        typeInsurance: typeInsurance || "",
        planInsuranceHea: row.planHea || "",
        planInsuranceAcd: row.planAcd || "",
        company: row.company,
        typeBenefit: typeBenefit || "",
        datatimeStamp: datatimeStamp.format("YYYY-MM-DD"),
        docRef: "",
        userlogin,
      }));
      //console.log("dataArray", dataArray)
      useConfirm({ FnOK: () => sendSSO(dataArray) });
    }
  };

  const onReset = () => {
    formSearch.resetFields();
    setDataTable([]);
    setChangeSearchValue({ statusStamp: "none" });
    setRowSelection({ selectedRowKeys: [], selectedRows: [] })
    setInsurancePlan({ typeInsurance: "", typeBenefit: "" });
  };
  // [END] Handle Form

  // [START] REST API
  const selectDashboardBenefitsNewEmp = async (objectValue) => {
    try {
      setLoadingScreen(true);
      setDataTable([]);
      setRowSelection({ selectedRowKeys: [], selectedRows: [] })
      const response = await POST(POST_SELECT_DASHBOARD_BENEFITS_NEW_EMP, objectValue);
      const { result } = response;
      let mapResult = [];
      if (result.length !== 0) {
        mapResult = result.map((row, index) => ({
          key: `${row.userId}_${index}`,
          ...row,
          birthday: subStringDate(row.birthday),
          startDateJob: subStringDate(row.startDateJob),
          probationEndDate: subStringDate(row.probationEndDate),
          HR_ReceivedDate: subStringDate(row.HR_ReceivedDate),
          date_HEA: subStringDate(row.date_HEA),
          expACD_HEA: subStringDate(row.expACD_HEA),
          date_PVD: subStringDate(row.date_PVD),
          expPVD: subStringDate(row.expPVD),
        }));
      } else {
        useNotification({ type: "info", title: "ไม่พบข้อมูล" });
      }
      setDataTable(mapResult);
    } catch (err) {
      useAlert({ type: "catch", content: err.message });
      console.log("Error Search Dashboard Benefits New Emp: ", err);
    } finally {
      setLoadingScreen(false);
    }
  };

  const sendSSO = async (dataBody) => {
    try {
      const response = await POST(POST_INSERT_SEND_SSO, dataBody);
      const { success, result } = response;
      if (success) {
        useAlert({
          type: "success", content: `${result}`, Fn: () => window.location.reload(),
        });
      } else {
        useAlert({ type: "info", content: `บันทึกข้อมูลไม่สำเร็จ กรุณาลองใหม่อีกครั้ง` });
      }
    } catch (err) {
      useAlert({ type: "catch", content: err.message })
      console.log("Error Send SSO (HEA): ", err);
    } finally {
      setLoadingScreen(false);
    }
  };
  // [END] REST API

  // [START] Functions
  const onChangeValue = (name, value) => {
    let setUndefined = {};
    let setName = {};
    switch (name) {
      case "company":
        setName = { companyCode: value };
        setUndefined = toUndefined(["location", "depNo", "subDepNo", "userId"]);
        break;
      case "location":
        setUndefined = toUndefined(["depNo", "subDepNo", "userId"]);
        break;
      case "depNo":
        setUndefined = toUndefined(["subDepNo"]);
        break;
    }
    if (name !== "datatimeStamp") setDataTable([]);
    formSearch.setFieldsValue(setUndefined);
    setChangeSearchValue((prev) => ({
      ...prev,
      ...setUndefined,
      ...setName,
      [name]: value,
    }));
  };
  // [END] Functions

  return (
    <Spin tip={WORDS.loadingSpin} spinning={loadingScreen}>
      <Form form={formSearch} onFinish={onFinish} {...configForm} initialValues={{ statusStamp: "none" }}>
        <Card
          title="รายการค้างสวัสดิการพนักงานใหม่ (พนักงานประจำ)"
          style={{ marginBottom: 30 }}
          extra={
            <Space>
              {useButton({ call: "search" })}
              {useButton({
                call: "normal",
                fn: onReset,
                moreProps: { label: "ล้างข้อมูล" },
              })}
            </Space>
          }
        >
          {useRenderFormItem({
            constantComponent: ComponentBenefitNewEmp({
              options: optionSearch,
              handleChange: onChangeValue,
            }),
          })}
        </Card>
      </Form>
      {dataTable.length !== 0 && (
        <Card
          title="ยื่นสวัสดิการพนักงานเข้าใหม่ (พนักงานประจำ)"
          style={{ marginBottom: 30 }}
          extra={
            <Space wrap>
              {useButton({
                call: "normal",
                fn: () =>
                  useConfirm({
                    content: `ยืนยันการส่ง "ประกันสุขภาพ/อุบัติเหตุ" ใช่หรือไม่`,
                    FnOK: () => {
                      setInsurancePlan({
                        typeInsurance: "hea",
                        typeBenefit: "in",
                      });
                      formSendSSO.submit();
                    },
                  }),
                moreProps: { label: "ส่งประกันสุขภาพ/อุบัติเหตุ" },
                isDisable: DisableButtonSSO(rowSelection.selectedRows, "date_HEA"),
              })}
              {useButton({
                call: "normal",
                fn: () =>
                  useConfirm({
                    content: `ยืนยันการส่ง "กองทุนสำรองเลี้ยงชีพ" ใช่หรือไม่`,
                    FnOK: () => {
                      setInsurancePlan({
                        typeInsurance: "pvd",
                        typeBenefit: "in",
                      });
                      formSendSSO.submit();
                    },
                  }),
                moreProps: { label: "ส่งกองทุนสำรองเลี้ยงชีพ" },
                isDisable: DisableButtonSSO(rowSelection.selectedRows, "date_PVD"),
              })}
            </Space>
          }
        >
          <Form
            form={formSendSSO}
            {...configForm}
            onFinish={onFinishSendSSO}
            initialValues={{ datatimeStamp: moment() }}
          >
            {useRenderFormItem({
              constantComponent: ComponentChangeInsurancePlanDatatimeStamp({
                handleChange: onChangeValue,
              }),
            })}
          </Form>
          <Tabs
            defaultActiveKey="1"
            tabBarExtraContent={{
              right: (
                <Space>
                  {useExportExcel({
                    header: Header_BenefitEmpNew,
                    dataset: rowSelection.selectedRows.length !== 0 ? rowSelection.selectedRows : dataTable,
                    filename: "รายงานยื่นสวัสดิการพนักงานเข้าใหม่(พนักงานประจำ)",
                  })}
                </Space>
              ),
            }}
          >
            <Tabs.TabPane tab="ข้อมูลพนักงานเข้าใหม่ (พนักงานประจำ)" key="1" >
              <CustomTableAtnd
                HeadTable={ColumnBenefitNewEmp}
                DataTable={dataTable}
                moreProps={{
                  scroll: {
                    x: sumColumnWidth(ColumnBenefitNewEmp) || 3100,
                    y: 500,
                  },
                  rowSelection: {
                    onChange: (selectedRowKeys, selectedRows) => {
                      setRowSelection({ selectedRowKeys, selectedRows });
                    },
                    getCheckboxProps: (record) => ({
                      disabled: record.statusStamp === "done",
                    }),
                  },
                }}
              />
            </Tabs.TabPane>
          </Tabs>
        </Card>
      )}
    </Spin>
  );
};

export default FormSearchBenefitNewEmp;
