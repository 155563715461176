import moment from "moment";
import encodeString from "../extendFunction/encodeString";
import { KEY_LOCATION, TEXT_LOADING } from "../constants/enums/enumCenter";

function renderWithJG(_info) {
  let jg = encodeString.decode(_info.JG);

  if (_info.workplace === KEY_LOCATION.FACTORY) {
    if (jg <= 2) {
      return 8;
    } else if (jg > 2 && jg < 6) {
      return 11;
    } else {
      return 8;
    }
  }

  if (_info.workplace === KEY_LOCATION.OFFICE) {
    return 8;
  }
}

export function validateConfirm(_info) {
  let _num = renderWithJG(_info); //! ถ้าปุ่มไม่เปิดเมื่อกรอกครบ เช็คจำนวนหัวข้อแบบประเมิน #1
  let numFac = Array(_num)
    .fill()
    .map((_, i) => i + 1);
  // console.log(numFac)
  //TODO : ชื่อหัวข้อการประเมินและคะแนน ของแค่ละหัวข้อต้องไม่เป็นค่าว่าง => ถ้าเป็นค่าว่าง true
  let numCurrent = numFac.filter((fac) => !!_info[`factor_${fac}`]);
  // console.log('numCurrent', numCurrent)
  let headerLists = numCurrent.map((fac) => `factor_${fac}`);
  // console.log('headerLists', headerLists)
  let validateLists = headerLists.map((e, i) => {
    // let newIndex = i + 1
    // // e = factor_${newIndex}
    // console.log(e, i, _info[e])
    if (i === 0) {
      //TODO : หัวข้อย่อย ที่ 1 จะเช็คทั้ง name และ score
      // return _info[e].every((x) => !!x.name && !!x.score)
      return _info[e].every((x) =>
        // console.log(x.score, _info.RoundNow-1, (!!x.score && x.score[_info.RoundNow - 1]))
        !!x.lineNo
          ? !!x.name && !!x.score && !!x.score[_info.RoundNow - 1]
          : !!x.name && !!x.score
      );
    } else {
      //TODO : หัวข้อย่อย ที่มากกว่า 1 จะเช็ค score อย่างเดียว
      return _info[e].every((x) =>
        !!x.lineNo ? !!x.score[_info.RoundNow - 1] : !!x.score
      );
    }
  });
  const success = validateLists.some((e) => !e); //! ถ้าเป็น true หมดจะ success สามารถยืนยันการประเมินได้
  return !!_info.mentorCode && !success;
}

/** FOR COMMISSION WEB */
export function getPeriodDate(type) {
  if (type == "display") {
    // return '21-05-2023 - 20-06-2023'
    if (moment(new Date()).format("DD") > 28) {
      return `21-${moment(new Date()).format("MM-YYYY")} - 20-${moment(
        new Date()
      )
        .add(1, "months")
        .format("MM-YYYY")}`;
    } else {
      return `21-${moment(new Date())
        .subtract(1, "months")
        .format("MM-YYYY")} - 20-${moment(new Date()).format("MM-YYYY")}`;
    }
  } else if (type == "SQL") {
    // return '2023-05-21 - 2023-06-20'
    if (moment(new Date()).format("DD") > 28) {
      return `${moment(new Date()).format("YYYY-MM")}-21 - ${moment(new Date())
        .add(1, "months")
        .format("YYYY-MM")}-20`;
    } else {
      return `${moment(new Date())
        .subtract(1, "months")
        .format("YYYY-MM")}-21 - ${moment(new Date()).format("YYYY-MM")}-20`;
    }
  }
  return "";
}

export function getDatePeriodNow(type) {
  if (type == "start") {
    // return '2023-05-21'
    if (moment(new Date()).format("DD") > 28) {
      return `${moment(new Date()).format("YYYY-MM")}-21`;
    } else {
      return `${moment(new Date()).subtract(1, "months").format("YYYY-MM")}-21`;
    }   
  } else {
    // return '2023-06-20'
    if (moment(new Date()).format("DD") > 28) {
      return `${moment(new Date()).add(1, "months").format("YYYY-MM")}-20`;
    } else {
      return `${moment(new Date()).format("YYYY-MM")}-20`;
    }   
  }
}

export const getMonthNow = (value) => {
  let mountValue = [];
  switch (value) {
    case (value = 1):
      mountValue = ["01"];
      break;
    case (value = 2):
      mountValue = ["02"];
      break;
    case (value = 3):
      mountValue = ["03"];
      break;
    case (value = 4):
      mountValue = ["04"];
      break;
    case (value = 5):
      mountValue = ["05"];
      break;
    case (value = 6):
      mountValue = ["06"];
      break;
    case (value = 7):
      mountValue = ["07"];
      break;
    case (value = 8):
      mountValue = ["08"];
      break;
    case (value = 9):
      mountValue = ["09"];
      break;
    case (value = 10):
      mountValue = ["10"];
      break;
    case (value = 11):
      mountValue = ["11"];
      break;
    case (value = 12):
      mountValue = ["12"];
      break;

    default:
      break;
  }
  return mountValue;
};
