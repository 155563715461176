import React from "react"
import { filterSearchColumn } from "../../functions/UseFunction";
import { Space } from "antd";
import { colors } from './../../../themes/colors';
import { MdDelete } from 'react-icons/md';
import * as Button from "../../../constants/buttons";

export const ColumnFilterOutSalesEmpCommission = ({ onRemove }) => [
	{
		title: "ลำดับ",
		dataIndex: "rowNo",
		key: "rowNo",
		align: "center",
		fixed: "center",
		width: 10,
	},
	{
		title: "ชื่อพนักงาน",
		dataIndex: "empName",
		key: "empName",
		align: "center",
		fixed: "center",
		width: 100,
		...filterSearchColumn("empName", "ชื่อพนักงาน"),
	},
	{
		title: "",
		dataIndex: "action",
		key: "action",
		align: "right",
		width: 50,
		render: (text, record) => {
			return (
				<div style={{ textAlignLast: 'center' }}>
					<Space>
						<Button.ButtonIcon icon={<MdDelete />} label={"ลบ"} color={colors.buttonEdit}
							onClick={() => onRemove(record)}
						/>
					</Space>
				</div>
			)
		}
	},
];
