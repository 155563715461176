import React, {
  useContext,
  useEffect,
  useState,
  useRef,
  Fragment,
} from "react";
import { Spin, Button, Form, Card, Space, Row, Col, Modal } from "antd";

import { PATHNAME } from "../../constants/enums/pathname";
import { LayoutBreadcrumb } from "../../moduleKPIs/common/layouts";

import { FORM_DASHBOARD_IMPORT_QUIT_PERSONAL } from "../constantsPersonal/dashboards/dashboardImportQuitPersonal";
import {
  POST,
  POST_CONFIRM_OUT,
  POST_GET_WAIT_CONFIRM_OUT,
} from "../../service";
import { useRenderFormItem } from "../../screens/conponents/RenderComponents";
import { useButton } from "../../screens/conponents/buttons/CustomButtonAntd";
import { ComponentReportLevelWorker } from "../../screens/constants/Reports/Components/ComponentReports";
import { ComponentQuitPersonal } from "../../screens/constants/Personal/Components/ComponentQuitPersonal";
import { useOptionSelect } from "../../screens/constants/OptionSelect";
import moment from "moment";
import { useAlert } from "../../screens/conponents/modal/CustomAlert";
import { useNotification } from "../../screens/conponents/notification/NotificationWithIcon";
import {
  ColumnQuitPersonal,
  ColumnQuitPersonalError,
} from "../../screens/constants/Personal/Columns/ColumnQuitPersonal";
import CustomTableAtnd from "../../screens/conponents/tables/CustomTableAtnd";
import { configForm, WORDS } from "../../screens/constants/ConstantComponent";
import {
  GET_UserLogin,
  sumColumnWidth,
  toUndefined,
} from "../../screens/functions/UseFunction";
import { useConfirm } from "../../screens/conponents/modal/CustomConfirm";

const DashboardImportQuitPersonal = () => {
  const [form] = Form.useForm();
  const [formCreateConfirmOut] = Form.useForm();
  const { userlogin } = GET_UserLogin();
  const [loadingScreen, setLoadingScreen] = useState(false);
  const [dataTable, setDataTable] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  let FORM_PAGE = FORM_DASHBOARD_IMPORT_QUIT_PERSONAL; //! อย่าลืมเปลี่ยน FORM_PAGE

  let optionSearch = {
    depNo: useOptionSelect("department"),
    reasonLeave: useOptionSelect("reasonLeave"),
    userId: useOptionSelect("empAll"),
    approvedForm: useOptionSelect("approvedForm"),
  };
  const dropdown = useOptionSelect("reasonLeave");
  const onReset = () => {
    form.resetFields();
    setDataTable([]);
  };

  const onFinish = (values) => {
    const { startDate, endDate, depNo, reasonLeave, approvedForm, userId } =
      values;
    if (endDate >= startDate || (!endDate && !startDate)) {
      let objectBody = {
        startDate: startDate ? moment(startDate).format("YYYY-MM-DD") : "",
        endDate: endDate ? moment(endDate).format("YYYY-MM-DD") : "",
        depNo: depNo || "",
        reasonLeaveCode: reasonLeave || "",
        approvedForm: approvedForm || "",
        userId: userId || "",
      };
      getWaitConfirmOut(objectBody);
    } else {
      useAlert({ type: "catch", content: "วันสิ้นสุดต้องมากกว่าวันเริ่มต้น" });
    }
  };

  const getWaitConfirmOut = async (objectBody) => {
    try {
      setSelectedRowKeys([]);
      setSelectedRows([]);
      setLoadingScreen(true);
      const response = await POST(POST_GET_WAIT_CONFIRM_OUT, objectBody);
      const { result } = response;
      let mapResult = [];
      if (result.data.length !== 0) {
        mapResult = result.data.map((row, index) => ({
          key: `${index}`,
          ...row,
        }));
        setDataTable(mapResult);
      } else {
        useNotification({ type: "info", title: "ไม่พบข้อมูล" });
        form.resetFields();
        setDataTable([]);
      }
    } catch (err) {
      useAlert({ type: "catch", content: err.message });
      console.log("Error get wait Confirm out: ", err);
    } finally {
      setLoadingScreen(false);
    }
  };

  const onFinishConfirmOut = () => {
    let data = selectedRows.map((data) => ({
      userId: data.userId,
      reasonLeaveCode: data.reasonLeaveCode,
      reasonLeaveDes:
        (data.reasonLeaveCode === "other" ? data.reasonLeaveDes : "") || "",
      endDateJob: moment(data.endDateJob).format("YYYY-MM-DD"),
    }));
    let objectValue = {
      data,
      confirmBy: userlogin,
    };
    useConfirm({
      content: `ยืนยันการแจ้งพ้นสภาพ`,
      FnOK: () => createConfirmOut(objectValue),
    });
  };

  const createConfirmOut = async (objectValue) => {
    console.log("objectValue", objectValue);
    try {
      setLoadingScreen(true);
      const response = await POST(POST_CONFIRM_OUT, objectValue);
      getWaitConfirmOut();
      setSelectedRowKeys([]);
      setSelectedRows([]);
      console.log("response", response);
    } catch (err) {
      let error = [];
      error = err.message.map((row, index) => ({
        key: index + 1,
        ...row,
      }));
      Modal.error({
        title: `บันทึกไม่สำเร็จ`,
        content: (
          <div>
            <CustomTableAtnd
              HeadTable={ColumnQuitPersonalError()}
              DataTable={error}
            />
          </div>
        ),
        width: 500,
      });
    } finally {
      setLoadingScreen(false);
    }
  };

  const onChangeReason = (key, value, fieldName, allValues) => {
    //console.log(" line 128 ~ allValues", allValues);
    console.log(key, value, fieldName, allValues);
    setDataTable((prev) => {
      return prev.map((row) => {
        if (row.key === key) {
          return {
            ...row,
            ["reasonLeaveCode"]: fieldName === "reasonLeaveCode" && value,
          };
        }
        return { ...row };
      });
    });
    setSelectedRows((prev) => {
      return prev.map((row) => {
        if (row.key === key) {
          return {
            ...row,
            ["reasonLeaveCode"]: fieldName === "reasonLeaveCode" && value,
          };
        }
        return { ...row };
      });
    });
  };
  const onChangeDes = (key, value, fieldName, allValues) => {
    console.log(key, value, fieldName, allValues);
    setDataTable((prev) => {
      return prev.map((row) => {
        if (row.key === key) {
          return {
            ...row,
            ["reasonLeaveDes"]: fieldName === "reasonLeaveDes" && value,
          };
        }
        return { ...row };
      });
    });
    setSelectedRows((prev) => {
      return prev.map((row) => {
        if (row.key === key) {
          return {
            ...row,
            ["reasonLeaveDes"]: fieldName === "reasonLeaveDes" && value,
          };
        }
        return { ...row };
      });
    });
  };
  const onChangeDate = (key, value, fieldName, allValues) => {
    console.log(key, value, fieldName, allValues);
    setDataTable((prev) => {
      return prev.map((row) => {
        if (row.key === key) {
          return {
            ...row,

            ["endDateJob"]: fieldName === "endDateJob" && value,
          };
        }
        return { ...row };
      });
    });
    setSelectedRows((prev) => {
      return prev.map((row) => {
        if (row.key === key) {
          return {
            ...row,
            ["endDateJob"]: fieldName === "endDateJob" && value,
          };
        }
        return { ...row };
      });
    });
  };

  const onChangeValue = (name, value) => {};
  return (
    <>
      <LayoutBreadcrumb
        breadcrumbLists={FORM_PAGE.BREADCRUMB({
          pathname: PATHNAME.DASHBOARD_IMPORT_QUIT_PERSONAL,
        })}
      />
      <br />
      <Spin tip={WORDS.loadingSpin} spinning={loadingScreen}>
        <Form form={form} onFinish={onFinish} {...configForm}>
          <Card
            title="ค้นหาพนักงานพ้นสภาพ"
            style={{ marginBottom: 30 }}
            extra={
              <Space>
                {useButton({ call: "search" })}
                {useButton({
                  call: "normal",
                  moreProps: { label: "ล้างข้อมูล" },
                  fn: onReset,
                })}
              </Space>
            }
          >
            {useRenderFormItem({
              constantComponent: ComponentQuitPersonal({
                options: optionSearch,
                handleChange: onChangeValue,
              }),
            })}
          </Card>
        </Form>

        <Card title="พนักงานพ้นสภาพ">
          <Form
            form={formCreateConfirmOut}
            {...configForm}
            onFinish={onFinishConfirmOut}
            scrollToFirstError
          >
            <CustomTableAtnd
              HeadTable={ColumnQuitPersonal({
                actions: { onChangeReason, onChangeDes, onChangeDate },
                dropdown,
              })}
              DataTable={dataTable}
              moreProps={{
                scroll: {
                  x: 2000,
                  y: 500,
                },
                rowSelection: {
                  onChange: (selectedRowKeys, selectedRows) => {
                    setSelectedRows(selectedRows);
                    setSelectedRowKeys(selectedRowKeys);
                  },
                },
              }}
            />
            <Row
              gutter={[16, 16]}
              style={{ marginTop: 20 }}
              justify="center"
              align="bottom"
            >
              <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                <Form.Item>
                  {useButton({
                    call: "quit",
                    isDisable: selectedRowKeys.length === 0 ? true : false,
                  })}
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Card>
      </Spin>
    </>
  );
};

export default DashboardImportQuitPersonal;
