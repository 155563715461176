import React, { Fragment, useState, useContext } from 'react'
import { LayoutSearch } from '../../common/layouts'
import { MainInput, TYPE } from '../../common/inputs'
import { DynamicField } from '../../common/inputs/dynamicField';
import { Col, Row } from 'antd';
import { BaseText } from '../../common/text';
import { colors } from '../../../themes';
import { EvaluationContext } from '../../services/manage/evaluationProvider';
import { dynamicLabel } from '../../common/functions';
import { renderMonth } from '../../../constants/functions';

export const InsertScore = ({ approval, monthYear, month, disabled }) => {
    const { evaluationState, onChangeValue } = useContext(EvaluationContext)
    return (
        <Fragment>
            <Row gutter={[10, 28]}>
                <Col span={10} >
                    <BaseText size={14} label={`รหัสพนักงาน : ${evaluationState.employeeId}`} />
                </Col>
                <Col span={14} >
                    <BaseText size={14} label={`ชื่อ - นามสกุล : ${evaluationState.employeeName}`} />
                </Col>
                <Col span={10} >
                    <BaseText size={14} label={`รอบการประเมิน :  ${monthYear}`} />
                </Col>
                {(evaluationState.statusNameEvaulation) && <Col span={14} >
                    <BaseText size={14} label={`สถานะ : ${evaluationState.statusNameEvaulation}`} />
                </Col>}
            </Row>

            <DynamicField
                header={<BaseText size={14} label={"หัวข้อตัววัดผลงาน"} />}
                buttonText={<BaseText icon={'+'} label={"เพิ่มหัวข้อตัวชี้วัด"} />}
                validateField={['score']}
                onChange={(value) => onChangeValue({ fieldname: 'indicator', value })}
                contentLayouts={[
                    { label: 'ตัววัดผลงาน', span: 12, type: TYPE.INPUT, fieldname: 'title', disabled: true },
                    { label: 'เกณฑ์', span: 4, type: TYPE.SCORE, fieldname: 'criterion', disabled: true },   //Before Type NUMBER
                    { label: 'สัดส่วน', span: 4, type: TYPE.SCORE, fieldname: 'proportion', disabled: true },//Before Type NUMBER
                    { label: 'คะแนน', span: 4, type: TYPE.SCORE, fieldname: 'rawScore', fieldgroup: 'indicator' }//Before Type NUMBER
                ]}
                structureContent={{ title: "", criterion: "", proportion: "", rawScore: "", scoreWeight: "", score: "" }}
                initialField={evaluationState.indicator}
            />
            {
                (approval) &&
                <MainInput
                    // value={evaluationState[`status${month}`]}
                    type={TYPE.CHECKBOX}
                    inputProps={{
                        checked: evaluationState[`status${month}`] === 3,
                        value: 3
                    }}
                    inputItems={{ label: 'ยืนยันการอนุมัติ (กรณียืนยันแล้ว จะไม่สามารถกลับมาแก้ไขได้)' }}
                    onChange={(e) => {
                        // console.log(evaluationState[`status${month}`], e.target.value, e)
                        onChangeValue({ fieldname: `status${month}`, value: (e.target.checked) ? e.target.value : 2 })
                    }}
                />
            }

        </Fragment>
    )
};
