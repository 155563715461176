import { FIELD_EMP } from "../../modulePersonal/constantsPersonal/contents/employeeInfo";

export const VERSION_TEXT = "Version 3.6.22 23/04/2024";

export const TEXT_LOADING = "กำลังโหลด...";
export const TEXT_PLACEHOLDER = {
  ALL: "---- ทั้งหมด ----",
  REQUIRED: "---- โปรดระบุ ----",
};

export const KEY_LOCATION = {
  FACTORY: "FAC",
  OFFICE: "OFF",
};

export const KEY_PERMISSION = {
  // HRSuper: 'HR-Super',
  // HRAdmin: 'HR-Admin',
  // Head: 'Head',
  // HeadDep: 'HeadDep',
  // HR: 'HR',
  // HRAdminOff: 'HR-AdminOff',
  // HRAss: 'HR-Ass',
  // MD: 'MD',
  // MDSuper: 'MD-Super',
  // STD: 'STD',
  // INTERN: 'Intern',
  // ADMIN_IT: 'AdminIT',
  // HRHead: 'HR-Head',
  // HRHeadDep: 'HR-HeadDep',
  // DEVELOP: 'DEVELOP'
  HRS_OFF: "HRS-OFF",
  HRD_OFF: "HRD-OFF",
  HRM_OFF: "HRM-OFF",

  HRS_FAC: "HRS-FAC",
  HRD_FAC: "HRS-FAC",
  HRM_FAC: "HRM-FAC",

  MD_OFF: "MD-OFF",
  MD_FAC: "MD-FAC",

  HeadDep_FAC: "HeadDep-FAC",
  Head_FAC: "Head-FAC",

  HeadDep_OFF: "HeadDep-OFF",
  Head_OFF: "Head-OFF",

  STD: "STD",
};

export const KEY_TYPE = {
  TEXT: "TEXT",
  TITLE: "TITLE",
  HEADER: "HEADER",
  DIVIDER: "DIVIDER",
  INPUT: "INPUT",
  INPUT_AREA: "INPUT_AREA",
  INPUT_EN: "INPUT_EN",
  INPUT_RENDER: "INPUT_RENDER",
  INPUT_NUMBER: "INPUT_NUMBER",
  SELECT: "SELECT",
  RADIO: "RADIO",
  CHECKBOX: "CHECKBOX",
  DATE_PICKER: "DATE_PICKER",
  TIME_PICKER: "TIME_PICKER",
  RANG_PICKER: "RANG_DATE_PICKER",
  HELPER: "HELPER",
  NUMBER: "NUMBER",
  CONTENT: "CONTENT",
  SCORE: "SCORE",
  DECIMAL: "DECIMAL",
  ADDRESS: "ADDRESS",
  EMAIL: "EMAIL",
  ID_CARD: "ID_CARD",
  PASSPORT: "PASSPORT",
  UPLOAD_IMAGE: "UPLOAD_IMAGE",
  UPLOAD_FLIES: "UPLOAD_FLIES",
  CARD_FORM: "CARD_FORM",
  BUTTON: "BUTTON",
  LIST_FORM: "LIST_FORM",
};

export const KEY_OPTION = {
  MOCKUP: "MOCKUP",
  OPTION_UTILITY: "OPTION_UTILITY",
  OPTION_STATUS: "OPTION_STATUS",
  OPTION_CENTER: "OPTION_CENTER",
  OPTIONS_PERSONAL: "OPTIONS_PERSONAL",

  DATA: {
    PROBATION_CONTRACT: "DATA_PROBATION_CONTRACT",
    PROBATION_EMP_CONTRACT: "PROBATION_EMP_CONTRACT",
    PROBATION_TRACK: "PROBATION_TRACK",
    PROBATION_BENEFIT: "PROBATION_BENEFIT",
  },

  CENTER: {
    OPTION_ADDRESS: "OPTION_ADDRESS",
    OPTION_ADDRESS_PRESENT: "OPTION_ADDRESS_PRESENT",
    OPTION_ADDRESS_FORMER: "OPTION_ADDRESS_FORMER",
    OPTION_DRIVING_TYPE: "OPTION_DRIVING_TYPE",
    OPTION_ASSETS_TYPE: "OPTION_ASSETS_TYPE",
    OPTION_ADDRESS_BY_POSTCODE: "OPTION_ADDRESS_BY_POSTCODE",
    OPTION_PROVINCE: "OPTION_PROVINCE",
    OPTION_DISTRICT: "OPTION_DISTRICT",
    OPTION_SUB_DISTRICT: "OPTION_SUB_DISTRICT",
    OPTION_SEX: "OPTION_SEX",
    OPTION_MILITARYSERVICE: "OPTION_MILITARYSERVICE",
    OPTION_PERSONLA_STATUS: "OPTION_PERSONLA_STATUS",
    OPTION_RELIGION: "OPTION_RELIGION",
    OPTION_PERSONLA_ID_TYPE: "OPTION_PERSONLA_ID_TYPE",
    OPTION_ONETIME_SCAN: "OPTION_ONETIME_SCAN",
    OPTION_MAN_TYPE: "OPTION_MAN_TYPE",
    OPTION_POSITION: "OPTION_POSITION",
    OPTION_SUB_DEPARTMENT: "OPTION_SUB_DEPARTMENT", //!notuse
    OPTION_SUB_DEPARTMENT_ALL: "OPTION_SUB_DEPARTMENT_ALL",
    OPTION_DEPARTMENT_ALL: "OPTION_DEPARTMENT_ALL",
    OPTION_WORKSHIFT_ALL: "OPTION_WORKSHIFT_ALL",
    OPTION_TIME_SCAN: "OPTION_TIME_SCAN",
    OPTION_SHIRT_SIZE: "OPTION_SHIRT_SIZE",
    OPTION_TITLE_EN: "OPTION_TITLE_EN",
    OPTION_TITLE_TH: "OPTION_TITLE_TH",
    OPTION_COMPANY: "OPTION_COMPANY",
    OPTION_DEP: "OPTION_DEP",
    OPTION_DATE: "OPTION_DATE",
    OPTION_RANGE_DATE: "OPTION_RANGE_DATE",
    OPTION_USERS: "OPTION_USERS",
    OPTION_SUBDEP: "OPTION_SUBDEP",
    OPTION_FLOWHRM: "OPTION_FLOWHRM",
    OPTION_FLOWVIEW: "OPTION_FLOWVIEW",
    OPTION_MENTOR: "OPTION_MENTOR",

    OPTION_LOCATION: "OPTION_LOCATION",

    OPTION_STATUS_WORKERS: "OPTION_STATUS_WORKERS",
    OPTION_STATUS_EMPLOYEE: "OPTION_STATUS_EMPLOYEE",
    OPTION_STATUS_DOCUMENTS: "OPTION_STATUS_DOCUMENTS",
    OPTION_PAYROLL_GROUP: "OPTION_PAYROLL_GROUP",
    OPTION_REASON_LEAVE_GROUP: "OPTION_REASON_LEAVE_GROUP",
    OPTION_CONTACT_PERIOD: "OPTION_CONTACT_PERIOD",
    OPTION_ACCIDENT_PLAN: "OPTION_ACCIDENT_PLAN",
    OPTION_HEALTH_PLAN: "OPTION_HEALTH_PLAN",
    OPTION_SELECT_ALL: "OPTION_SELECT_ALL",
    OPTION_SELECT_EMPLOY_HISTORY: "OPTION_SELECT_EMPLOY_HISTORY",
    OPTION_RECRUITER: "OPTION_RECRUITER",
    OPTION_SELECT_HR_RECRUITER_GRADE: "OPTION_SELECT_HR_RECRUITER_GRADE",
    OPTION_SELECT_HR_RECRUITER_SCORE: "OPTION_SELECT_HR_RECRUITER_SCORE",
    OPTION_SELECT_SALE_CODE: "OPTION_SELECT_SALE_CODE",
  },
  PROBATION: {
    OPTION_STATUS_ASSESSMENT: "OPTION_STATUS_ASSESSMENT",
    OPTION_STATUS_RESULT: "OPTION_STATUS_RESULT",

    DASHBOARD_FOLLOWUP_GURANTEE: "DASHBOARD_FOLLOWUP_GURANTEE",
    DASHBOARD_CONTRACT: "DASHBOARD_CONTRACT",
    DASHBOARD_CONTRACT_NEW: "DASHBOARD_CONTRACT_NEW",
    DASHBOARD_CONTRACT_VIEW: "DASHBOARD_CONTRACT_VIEW",
    DASHBOARD_EMP_CONTRACT_PASS: "DASHBOARD_EMP_CONTRACT_PASS",
    DASHBOARD_EMP_CONTRACT_NOTPASS: "DASHBOARD_EMP_CONTRACT_NOTPASS",
    DASHBOARD_TRACK: "DASHBOARD_TRACK",
    DASHBOARD_BENEFIT: "DASHBOARD_BENEFIT",

    SELECT_DATA_BY_ID: "SELECT_DATA_BY_ID",
  },
  PERSONAL: {
    OPTIONS_MOCKUP: "OPTIONS_MOCKUP",
    OPTIONS_DEPARTMENT: "OPTIONS_DEPARTMENT",
    OPTION_SUB_DEPARTMENT: "OPTION_SUB_DEPARTMENT",
    OPTIONS_POSITION: "OPTIONS_POSITION",
    OPTIONS_FLOWVIEW: "OPTION_FLOWVIEW",
    OPTIONS_WORKSHIFT: "OPTION_WORKSHIFT",
  },
};

export const KEY_ACTIVE_TAB = {
  UPDATE_PERSONAL: "UPDATE_PERSONAL",
  VIEW_PERSONAL: "VIEW_PERSONAL",
  IMPORT_PERSONAL: "IMPORT_PERSONAL",
  IMPORT_PERSONAL_ID_CARD: "IMPORT_PERSONAL_ID_CARD",
  IMPORT_QUIT_PERSONAL: "IMPORT_QUIT_PERSONAL",
  //#OT
  DASHBOARD_OT: "OTAll",
  DASHBOARD_APPROVAL_OT: "OTWaitApproval",
};

export const KEY_TYPE_OPTION = {
  OPTION_COMPANY: FIELD_EMP.COMPANY,
  OPTION_LOCATION: FIELD_EMP.LOCATION,
  OPTION_TITLE_TH: FIELD_EMP.TITLE_TH,
  OPTION_TITLE_EN: FIELD_EMP.TITLE_EN,
  OPTION_SHIRT_SIZE: FIELD_EMP.SHIRT_SIZE,
  OPTION_TIME_SCAN: FIELD_EMP.TIME_SCAN,
  OPTION_ONETIME_SCAN: FIELD_EMP.ONETIME_SCAN,
  OPTION_MAN_TYPE: FIELD_EMP.MAN_TYPE,
  OPTION_PAYROLL_GROUP: FIELD_EMP.PAYROLL_GROUP,
  OPTION_STATUS_EMPLOYEE: FIELD_EMP.STATUS_EMPLOYEE,
  OPTION_REASON_LEAVE_GROUP: FIELD_EMP.REASON_OUT,
  OPTION_ACCIDENT_PLAN: "insurancePlan",
  OPTION_HEALTH_PLAN: "insurancePlan",
  OPTION_CONTACT_PERIOD: "contractExtension",
  OPTION_SEX: "gender",
  OPTION_STATUS_WORKERS: "status_worker",
  OPTION_RELIGION: "religion",
  OPTION_PERSONLA_STATUS: "personalStatus",
  OPTION_DRIVING_TYPE: "drivingType",
  OPTION_MILITARYSERVICE: "militaryService",
  OPTION_STATUS_RESULT: "statusProbation",
  OPTION_STATUS_ASSESSMENT: "statusAssessment",
  OPTION_PERSONLA_ID_TYPE: "codeType",
  OPTION_RECRUITER: "selectDataHrTeam",
};
