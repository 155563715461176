import React, { useState, useEffect } from "react";
import { Upload, Modal } from "antd";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { SET_DEFAULT_FILELIST } from ".";

let UPLOAD_KEY = "UPLOAD_KEY";

export const UploadAvatar = (element) => {
  const [files, setFileList] = useState([]);
  let acceptType = `${element.accept}`.split(","); // ["application/pdf","image/jpeg","image/png"]
  let displayType = acceptType.map((e) => `.${e.split("/")[1]}`); // [".pdf",".jpeg",".png"]

  /* ------------------------------- USE EFFECT ------------------------------- */
  useEffect(() => {
    //TODO : ถ้ามี VALUE ให้ SET เข้า Files
    if (!!element.value && Array.isArray(element.value)) {
      setFileList([...SET_DEFAULT_FILELIST(element.value)]);
    }
    // console.log(SET_DEFAULT_FILELIST(element.value), 'FilesLiast')
  }, []);
  /* -------------------------------------------------------------------------- */
  //TODO : ส่งข้อมูลเพื่อทำการอัพโหลด
  const handleChange = ({ file, fileList }) => {
    const isAccept =
      Array.isArray(acceptType) &&
      acceptType.some((_type) => `${_type}` === file.type); //true
    let valueLists = fileList.map((e) => e.originFileObj);
    if (!!isAccept) {
      setFileList(fileList);
      element.onChange({
        file: file,
        base64: file.thumbUrl,
        fileList: valueLists,
      });
    }
  };
  //TODO : แปลงรูปจากไฟล์เป็น Base64 เพื่อใช้ในการแสดง เมื่อคลิกปุ่ม Preview
  async function handlePreview(file) {
    let src = file.url;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow.document.write(image.outerHTML);
  }

  const uploadButton = (
    <div>
      {element.loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}> {"อัปโหลด"} </div>
    </div>
  );

  //TODO : กำหนดการยอมรับไฟล์ก่อน ส่งข้อมูลไปเปลี่ยนแปลง เช่น กำหนด ชื่อไฟล์ซ้ำ กำหนดขนาดรูป ฯลฯ
  function beforeUpload(file) {
    const isAccept =
      Array.isArray(acceptType) &&
      acceptType.some((_type) => `${_type}` === file.type); //true
    const isLt2M = file.size / 1024 / 1024 < (element.size || 2);

    if (!isAccept) {
      Modal.error({
        key: UPLOAD_KEY,
        title: `ดำเนินการไม่สำเร็จ`,
        content: `ไฟล์รูปไม่ถูกต้อง กรุณาเลือกไฟล์ ${displayType}`,
      });
    }
    if (!isLt2M) {
      Modal.error({
        key: UPLOAD_KEY,
        title: `ดำเนินการไม่สำเร็จ`,
        content: `ไฟล์ต้องมีขนาดเล็กกว่า ${element.accept} MB!`,
      });
    }

    return isAccept && isLt2M;
  }

  function handleRemove(file) {
    // console.log('REMOVE', { file, files });
    const newFileList = files.slice();
    let removedIndex = files.findIndex((v) => v.uid === file.uid);
    newFileList.splice(removedIndex, 1);
    // console.log({ newFileList, removedIndex })
    setFileList([...newFileList]);
    element.onChange({
      file: file,
      base64: file.thumbUrl,
      fileList: [...newFileList],
    });
  }

  return (
    // <ImgCrop rotate>
    <Upload
      name={element.name}
      accept={element.accept || ""}
      listType="picture-card"
      className="avatar-uploader"
      showUploadList={element.showUploadList}
      fileList={SET_DEFAULT_FILELIST(files)} //defaultFiles : สำหรับ value ที่ส่งกลับมาหลังจากอัพโหลดไปแล้ว
      onChange={handleChange}
      onPreview={handlePreview}
      disabled={element.disabled}
      onRemove={handleRemove}
      beforeUpload={beforeUpload}
    >
      {element.imageUrl ? (
        <img src={element.imageUrl} alt="avatar" style={{ width: "100%" }} />
      ) : +element.max > +files.length ? (
        uploadButton
      ) : null}
    </Upload>
    // </ImgCrop>
  );
};
