import { CONTENT_RECHECK_EMPLOYEE } from './contents/employeeInfo.jsx';
import { CONTENT_PERSONAL, CONTENT_PERSONAL_INFO } from './contents/personalInfo.jsx';
import { CONTENT_FAMILY } from './contents/familyInfo.jsx';
import { CONTENT_EXPERIENCES } from './contents/experiencesInfo.jsx';
import { CONTENT_EDUCATION } from './contents/educationInfo.jsx';
import { CONTENT_TRAINING } from './contents/trainingInfo.jsx';
import { CONTENT_DRIVING } from './contents/drivingInfo.jsx';
import { CONTENT_LANGUAGE } from './contents/languageSkill.jsx';
import { CONTENT_OTHER_PERSON } from './contents/otherPersonInfo.jsx';

import {
    CONTENT_ABILITY,
    CONTENT_ACTIVITY,
    CONTENT_FILES,
} from './contents/otherInfo.jsx';
import { LABEL_STEP, FIELD_STEP } from './index.jsx';

const LABEL_PAGES = LABEL_STEP;
const FIELD_PAGES = FIELD_STEP;


export const STEPS_PROGRESS_RECHECK = (props) => {
    /**
     * TODO: สามารถแบ่งตาม activeTab , location ได้ โดยใช้ switch case
     */

    console.log({props})
    return [{
        key: 0,
        description: LABEL_PAGES.STEP_0,
        content: [{
            key: 1,
            name: FIELD_PAGES.COLLAPSE_S02,
            header: LABEL_PAGES.COLLAPSE_S02,
            content: () => CONTENT_RECHECK_EMPLOYEE({ ...props }),
        }]
    }, {
        key: 1,
        description: LABEL_PAGES.STEP_1,
        content: [{
            key: 1,
            name: FIELD_PAGES.COLLAPSE_S02,
            header: LABEL_PAGES.COLLAPSE_S02,
            layout: 'horizontal',
            gutter: [0, 0],
            content: () => CONTENT_PERSONAL_INFO({ ...props }),
        }, {
            key: 2,
            name: FIELD_PAGES.COLLAPSE_S11,
            header: LABEL_PAGES.COLLAPSE_S11,
            content: () => CONTENT_PERSONAL({ ...props }),
        }, {
            key: 3,
            name: FIELD_PAGES.COLLAPSE_S12,
            header: LABEL_PAGES.COLLAPSE_S12,
            content: () => CONTENT_FAMILY({ ...props }),
        }]
    }, {
        key: 2,
        description: LABEL_PAGES.STEP_2,
        content: [{
            key: 1,
            name: FIELD_PAGES.COLLAPSE_S21,
            header: LABEL_PAGES.COLLAPSE_S21,
            content: () => CONTENT_EXPERIENCES({ ...props }),
        }]
    }, {
        key: 3,
        description: LABEL_PAGES.STEP_3,
        content: [{
            key: 1,
            name: FIELD_PAGES.COLLAPSE_S31,
            header: LABEL_PAGES.COLLAPSE_S31,
            content: () => CONTENT_EDUCATION({ ...props }),
        }, {
            key: 2,
            name: FIELD_PAGES.COLLAPSE_S32,
            header: LABEL_PAGES.COLLAPSE_S32,
            content: () => CONTENT_TRAINING({ ...props }),
        }]
    }, {
        key: 4,
        description: LABEL_PAGES.STEP_4,
        content: [{
            key: 1,
            name: FIELD_PAGES.COLLAPSE_S41,
            header: LABEL_PAGES.COLLAPSE_S41,
            content: () => CONTENT_DRIVING({ ...props }),
        }, {
            key: 2,
            name: FIELD_PAGES.COLLAPSE_S42,
            header: LABEL_PAGES.COLLAPSE_S42,
            content: () => CONTENT_ABILITY({ ...props }),
        }, {
            key: 3,
            name: FIELD_PAGES.COLLAPSE_S43,
            header: LABEL_PAGES.COLLAPSE_S43,
            content: () => CONTENT_LANGUAGE({ ...props }),
        }, {
            key: 4,
            name: FIELD_PAGES.COLLAPSE_S44,
            header: LABEL_PAGES.COLLAPSE_S44,
            content: () => CONTENT_OTHER_PERSON({ ...props }),
        }, {
            key: 5,
            name: FIELD_PAGES.COLLAPSE_S45,
            header: LABEL_PAGES.COLLAPSE_S45,
            content: () => CONTENT_ACTIVITY({ ...props }),
        }]
    },{
      key: 5,
      description: LABEL_PAGES.STEP_5,
      content: [
        {
          key: 1,
          name: FIELD_PAGES.COLLAPSE_S51,
          header: LABEL_PAGES.COLLAPSE_S51,
          content: () => CONTENT_FILES({ ...props }),
        },
      ],
    },
  
  ]
}