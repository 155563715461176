import React, { useContext } from 'react'
import InsertProbationContract from '../componentsContract/insertProbationContrat'
import ManageContract from '../componentsContract/manageContract'
import ViewProbationContract from '../componentsContract/viewProbationContract'
import { ContractContext } from '../servicesContract/contractProvider'

export const PageInsertContract = () => {
    const { userLogin } = useContext(ContractContext);

    return (
        <InsertProbationContract userLogin={userLogin} />
        // <ManageContract isUpdate={false} isView={false} />
    )
}

export const PageUpdateContract = () => {
    const { userLogin } = useContext(ContractContext);

    return (
        <InsertProbationContract userLogin={userLogin} />
        // <ManageContract isUpdate={true} isView={false} />
    )
}

export const PageViewContract = () => {
    const { userLogin } = useContext(ContractContext);

    return (
        <ViewProbationContract userLogin={userLogin} />
        // <ManageContract isUpdate={false} isView={true} />
    )
}
