import { KEY_OPTION } from "../../constants/enums/enumCenter";


export const convertPattern = (_key, _data) => {
	switch (_key) {
		case KEY_OPTION.CENTER.OPTION_WORKSHIFT_ALL:
			return {
				optionId: _data.shiftCode,
				optionValue: _data.shiftCode,
				optionLabel: `${_data.shiftCode} : ${_data.workShiftNameTH}`
			}
		case KEY_OPTION.CENTER.OPTION_DEPARTMENT_ALL:
			return {
				optionId: _data.depNo,
				optionValue: _data.depNo,
				optionLabel: `${_data.depNo} : ${_data.depNameEN} / ${_data.depNameTH}`
			}
		case KEY_OPTION.CENTER.OPTION_SUB_DEPARTMENT_ALL:
		case KEY_OPTION.CENTER.OPTION_SUB_DEPARTMENT: //!not use
			return {
				optionId: _data.subDepNo,
				optionValue: _data.subDepNo,
				optionLabel: `${_data.subDepNo} : ${_data.subDepName}`
			}
		case KEY_OPTION.CENTER.OPTION_POSITION:
			return {
				optionId: _data.PositionCode,
				optionValue: _data.PositionCode,
				optionLabel: `${_data.PositionCode.toUpperCase()} : ${_data.PositionNameEN}`
			}
		case KEY_OPTION.CENTER.OPTION_FLOWHRM:
			return {
				optionId: _data.ID_Flow,
				optionValue: _data.ID_Flow,
				optionLabel: `${_data.Flow_Name}`
			}
		case KEY_OPTION.CENTER.OPTION_DEP:
			return {
				optionId: `${_data.depNo}`,
				optionValue: _data.DepNo || _data.depNo,
				optionLabel: _data.Department_Name || _data.depName
			}
		case KEY_OPTION.OPTION_STATUS:
		case KEY_OPTION.CENTER.OPTION_TITLE_TH:
		case KEY_OPTION.CENTER.OPTION_TITLE_EN:
		case KEY_OPTION.CENTER.OPTION_STATUS_EMPLOYEE:
		case KEY_OPTION.CENTER.OPTION_PAYROLL_GROUP:
		case KEY_OPTION.CENTER.OPTION_REASON_LEAVE_GROUP:
		case KEY_OPTION.CENTER.OPTION_CONTACT_PERIOD:
			return {
				optionId: `${_data.code}`,
				optionValue: `${_data.code}`,
				optionLabel: _data.description,
				optionData: _data
			}
		case KEY_OPTION.MOCKUP:
			return {
				id: `${_data.ID_Row}`,
				value: _data.code,
				label: _data.description
			}
		case KEY_OPTION.CENTER.OPTION_PROVINCE:
			return {
				optionId: `${_data.provinceId}`,
				optionValue: _data.provinceId,
				optionLabel: _data.provinceThai
			}

		case KEY_OPTION.CENTER.OPTION_DISTRICT:
			return {
				optionId: `${_data.districtId}`,
				optionValue: _data.districtId,
				optionLabel: _data.districtThai
			}
		case KEY_OPTION.CENTER.OPTION_SUB_DISTRICT:
			return {
				optionId: `${_data.subDistrictId}`,
				optionValue: _data.subDistrictId,
				optionLabel: _data.subDistrictThai
			}
		case KEY_OPTION.CENTER.OPTION_ASSETS_TYPE:
			return {
				optionId: `${_data.assetCode}`,
				optionValue: _data.assetCode,
				optionLabel: _data.assetDesc
			}
		case KEY_OPTION.PERSONAL.OPTIONS_MOCKUP:
			return {
				optionId: `${_data.optionId}`,
				optionValue: _data.optionValue,
				optionLabel: _data.optionLabel
			}
		case KEY_OPTION.CENTER.OPTION_SELECT_EMPLOY_HISTORY:
			let checkNickName = _data.nickname && `(${_data.nickname})`
			return {
				optionId: `${_data.userId}`,
				optionValue: _data.userId,
				optionLabel: `${_data.userId}: ${_data.fullName} ${checkNickName}`
			}
		case KEY_OPTION.CENTER.OPTION_RECRUITER:
			return {
				optionId: `${_data.recruiterId}`,
				optionValue: _data.recruiterId,
				optionLabel: `${_data.fullname} (${_data.nickname})`
			}
		case KEY_OPTION.CENTER.OPTION_SELECT_SALE_CODE:
			return {
				optionId: `${_data.RoleID}`,
				optionValue: _data.RoleID,
				optionLabel: `[${_data.RoleID}] ${_data.RoleName}`
			}
		default:
			return {
				optionId: `${_data.optionId}`,
				optionValue: _data.optionId,
				optionLabel: _data.optionLabel
			}
	}
}

export const convertOptions = ({ key, type = 'all', options = [], }) => {
	let result = []
	switch (type) {
		case 'all':
			result = [{ optionId: 'all', optionValue: 'all', optionLabel: `---- ทั้งหมด ----` }]
			break;
		case 'required':
			result = [{ optionId: null, optionValue: null, optionLabel: `---- โปรดระบุ ----` }]
			break;
		case 'none':
			result = [{ optionId: '', optionValue: '', optionLabel: `---- โปรดระบุ ----` }]
			break;
		case 'null':
			result = [{ optionId: '', optionValue: '', optionLabel: `---- ไม่ระบุ ----` }]
			break;
		case 'empty':
			result = []
			break;
		default:
			result = [{ optionId: '', optionValue: '', optionLabel: '' }]
			break;
	}
	return result.concat(options.map((each) => (convertPattern(key, each))))
}



export const renderInputOptions = (_key, _options) => {
	return {
		fieldOption: _key,
		plainOptions: _options[_key],
		defaultValue: _options[`SEARCH_${_key}`],
	}
}


export const convertString = (_data) => {
	if (Array.isArray(_data) && _data.length > 0) {
		return _data[0]
	} else {
		return _data || ''
	}
}

export const convertBaht = (_data) => {
	if (!_data) return ''
	//let a = _data
	//_data = parseFloat(_data).toFixed(2)
	return _data.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");


}