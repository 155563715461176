import React from 'react';
import '../index.css';
import "bootstrap/dist/css/bootstrap.css";
import { Col, Row, Button, FormGroup, Label, Card, CardHeader, CardBody, Container } from 'reactstrap';
import { Modal } from "antd";
import { API_URL } from '../components/connectAPI'
import 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css';
import CsvParse from '@vtex/react-csv-parse';
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from 'react-bootstrap-table2-paginator';
import { CSVLink } from "react-csv";
import { MdFileDownload } from "react-icons/md";
import { loadingPage } from '../components/loadingPage'

// const api = connectAPI.API_URL
var arrData = []



class ImportUpdateEmployee extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            upload_open: true,
            showUpload: '',
            arrExcel: '',
            userLogin: this.props.userLogin,
        };
    }


    handleData = data => {
        arrData = data
        this.setState({ arrExcel: arrData })
    }

    handleOnError = (err, file, inputElem, reason) => {
        console.log(err);
        console.log(file);
    }

    addExcel = () => {
        if (this.state.arrExcel === '' || this.state.arrExcel === undefined) {
            // alert("กรุณาแนบไฟล์")
            Modal.warning({ title: "กรุณาแนบไฟล์" })
        } else {
            var indata = this.state.arrExcel

            if (window.confirm('ยืนยันการเพิ่มข้อมูลหรือไม่')) {
                let url = API_URL + '/HRM_API/update_workerFromImp'
                fetch(url, {
                    method: "POST",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json;charset=utf-8"
                    },
                    body: JSON.stringify({ data: indata, User_login: this.state.userLogin })
                })
                    .then(response => response.json())
                    .then((results) => {
                        if (results.status_api === 1) {
                            // alert('Insert Success!!')
                            // this.setState({ modal: false })
                            // window.location.reload()      
                            Modal.success({
                                title: "Insert Success!!",
                                onOk: () => {
                                    this.setState({ modal: false });
                                    window.location.reload();
                                }
                            });
                        } else {
                            // alert("Insert Error!!")
                            Modal.error({ title: "Insert Error!!" });
                        }
                    })
                    .catch((error) => {
                        console.log("err", error)
                    })
            }
        }
    }

    showUpload = () => {
        const keys = [
            'ID_User', 'DepartmentNo', 'Position_No', 'JG', 'PG', 'refFlow_HRM', 'refFlow_view', 'Status_Worker', 'EndDateJob'
        ]
        var Upload = ''

        if (this.state.upload_open === true) {
            Upload = <FormGroup row style={{ marginLeft: '25px', marginBottom: '25px' }}>
                <Label style={{ color: 'red', marginRight: "20px" }}> **รองรับเฉพาะไฟล์ .csv เท่านั้น</Label>
                <CsvParse
                    keys={keys}
                    onDataUploaded={this.handleData}
                    onError={this.handleError}
                    render={onChange => <input type="file" onChange={onChange} accept=".csv" />
                    }
                />
                <Button color="success" size="sm" style={{ marginLeft: '20px' }} onClick={this.addExcel}>Upload</Button>
            </FormGroup>
        }

        this.setState({ showUpload: Upload, upload_open: !this.state.upload_open })
    }

    componentDidMount() {
        this.selectData();
    }

    async selectData() {
        try {
            var API_Option = API_URL + "/HRM_API/select_worker?company=&&dep=&&userID=&&statusWorker=";
            var res1 = await fetch(API_Option);
            const results = await res1.json();
            results['data'].forEach((element, index, array) => {
                this.setState({ loadData: array, done: true })
            })
            // console.log(results)

        } catch (e) {
            console.warn(e);
        }
    }


    render() {

        const products = this.state.loadData;
        //console.log(products)
        const columns = [
            {
                dataField: "ID_User",
                text: "รหัสพนักงาน",
                style: {
                    // whiteSpace: 'nowrap'
                }
            },
            {
                dataField: "Username",
                text: "Username",
                sort: true,
                style: {
                    //   whiteSpace: 'nowrap'
                }
            },
            {
                dataField: "Name",
                text: "ชื่อ-สกุล",
                sort: true,
                style: {
                    //  whiteSpace: 'nowrap'
                }
            },
            {
                dataField: "NickName",
                text: "ชื่อเล่น",
                sort: true,
                style: {
                    //  whiteSpace: 'nowrap'
                }
            },
            {
                dataField: "DepartmentNo",
                text: "รหัสแผนก",
                sort: true,
                style: {
                    //   whiteSpace: 'nowrap'
                }
            },
            {
                dataField: "Position_No",
                text: "รหัสตำแหน่ง",
                sort: true,
                style: {
                    //  whiteSpace: 'nowrap'
                }
            },
            {
                dataField: "refFlow_HRM",
                text: "รหัสสายการอนุมัติ",
                sort: true,
                style: {
                    //  whiteSpace: 'nowrap'
                }
            },

            {
                dataField: "refFlow_view",
                text: "รหัสสายการดูข้อมูล",
                sort: true,
                style: {
                    // whiteSpace: 'nowrap',
                    // backgroundColor: '#F0FFF0'
                }
            },
        ];

        const sizePerPageRenderer = ({
            options,
            currSizePerPage,
            onSizePerPageChange
        }) => (
            <div className="btn-group" role="group">
                {
                    options.map((option) => {
                        const isSelect = currSizePerPage === `${option.page}`;
                        return (
                            <button
                                key={option.text}
                                type="button"
                                onClick={() => onSizePerPageChange(option.page)}
                                className={`btn ${isSelect ? 'btn-secondary' : 'btn-primary'}`}
                            >
                                {option.text}
                            </button>
                        );
                    })
                }
            </div>
        );

        const options = {
            sizePerPageRenderer
        };

        const headerCSV = [
            // { label: "สถานะ", key: "StaffCode" },
            { label: "ID_User", key: "ID_User" },
            { label: "DepartmentNo", key: "DepartmentNo" },
            { label: "Position_No", key: "Position_No" },
            { label: "JG", key: "JG" },
            { label: "PG", key: "PG" },
            { label: "refFlow_HRM", key: "refFlow_HRM" },
            { label: "refFlow_view", key: "refFlow_view" },
            { label: "Status_Worker", key: "Status_Worker" },
            { label: "EndDateJob", key: "EndDateJob" },
        ];

        return (
            <Container>
                {this.state.done ? (
                    <div>
                        <Card>
                            <CardHeader> หน้าจัดการอัพเดทแก้ไขข้อมูลพนักงาน (Import Update Employee)</CardHeader>
                            <CardBody>
                                <Row>
                                    <Col md={6}>
                                        <Button color='primary' size='sm' onClick={this.showUpload}>Import Update Employee CSV</Button>
                                    </Col>
                                    <Col md={6}>
                                        {this.state.showUpload}
                                    </Col>
                                </Row>
                            </CardBody>

                        </Card>
                        <hr />
                        <div className="btn-right">
                            <CSVLink
                                headers={headerCSV}
                                data={products}
                                filename={"รายชื่อแผนก.csv"}
                                className="btn btn-export"
                            >
                                <MdFileDownload /> Export CSV
                            </CSVLink>
                        </div>


                        <BootstrapTable
                            keyField="PositionCode" //"custNo"RowID
                            data={products}
                            columns={columns}
                            ref={n => (this.node = n)}
                            striped
                            hover
                            condensed
                            pagination={paginationFactory(options)}
                            wrapperClasses="table-responsive"

                        />
                    </div>) : (<div>{loadingPage} </div>)}

            </Container>

        );

    }
}
export default ImportUpdateEmployee;
