import React from "react";
import { Tabs } from "antd";
import FormSearchSubDepartment from "./conponents/forms/FormSearchSubDepartment";
import FormAddSubDepartment from "./conponents/forms/FormAddSubDepartment";

const ManagePosition = () => {
  const { TabPane } = Tabs;
  return (
    <Tabs defaultActiveKey="1">
      <TabPane tab="ดูข้อมูลแผนกย่อย" key="1">
        <FormSearchSubDepartment />
      </TabPane>
      <TabPane tab="เพิ่มข้อมูลแผนกย่อย" key="2">
        <FormAddSubDepartment />
      </TabPane>
    </Tabs>
  );
};

export default ManagePosition;
