import React, { useContext } from "react";
import { TYPE } from "../../../moduleKPIs/common/inputs";
import { MASTER_OPTION } from "../../../moduleKPIs/services/servicesOptions";
import { ReportEvacContext } from "../../servicesEvac/reportEvac/reportEvacProvider";
import { path } from "ramda";

export const SEARCH_DASHBOARD_REVIEWER = ({ permission, options }) => {
    return [{
        span: 12,
        type: TYPE.SELECT,
        header: 'ประจำปี (ย้อนหลัง 3 ปี)',
        required: true,
        message: '',
        inputOption: {
            fieldname: 'year',
            fieldOption: MASTER_OPTION.OPTION_YEAR,
            plainOptions: options[MASTER_OPTION.OPTION_YEAR],
            defaultValue: options[`SEARCH_${MASTER_OPTION.OPTION_YEAR}`],
            disabled: false,
            placeholder: '--- ทั้งหมด ---'
        }
    }, {
        span: 12,
        type: TYPE.SELECT,
        header: 'ไตรมาส',
        required: true,
        message: '',
        inputOption: {
            fieldname: 'quarter',
            fieldOption: MASTER_OPTION.OPTION_QUARTER,
            plainOptions: options[MASTER_OPTION.OPTION_QUARTER],
            defaultValue: options[`SEARCH_${MASTER_OPTION.OPTION_QUARTER}`],
            disabled: false,
            placeholder: '--- ทั้งหมด ---'
        }
    }]

}

export const SEARCH_DASHBOARD_EVAC = ({ permission, options, initialData }) => {
    switch (permission) {
        case 'non_eval':
            return [{
                span: 8,
                type: TYPE.SELECT,
                header: 'ประจำปี (ย้อนหลัง 3 ปี)',
                required: true,
                message: '',
                inputOption: {
                    fieldname: 'year',
                    fieldOption: MASTER_OPTION.OPTION_YEAR,
                    plainOptions: options[MASTER_OPTION.OPTION_YEAR],
                    defaultValue: options[`SEARCH_${MASTER_OPTION.OPTION_YEAR}`],
                    disabled: true,
                    placeholder: ''
                }
            }, {
                span: 8,
                type: TYPE.SELECT,
                header: 'ไตรมาส',
                required: true,
                message: '',
                inputOption: {
                    fieldname: 'quarter',
                    fieldOption: MASTER_OPTION.OPTION_QUARTER,
                    plainOptions: options[MASTER_OPTION.OPTION_QUARTER],
                    defaultValue: options[`SEARCH_${MASTER_OPTION.OPTION_QUARTER}`],
                    disabled: true,
                    showArrow: false,
                    placeholder: ''
                }
            }]
        default:
            return [{
                span: 8,
                type: TYPE.SELECT,
                header: 'ประจำปี (ย้อนหลัง 3 ปี)',
                required: true,
                message: '',
                inputOption: {
                    fieldname: 'year',
                    fieldOption: MASTER_OPTION.OPTION_YEAR,
                    plainOptions: options[MASTER_OPTION.OPTION_YEAR],
                    defaultValue: options[`SEARCH_${MASTER_OPTION.OPTION_YEAR}`],
                    disabled: true,
                    placeholder: ''
                }
            }, {
                span: 8,
                type: TYPE.SELECT,
                header: 'ไตรมาส',
                required: true,
                message: '',
                inputOption: {
                    fieldname: 'quarter',
                    fieldOption: MASTER_OPTION.OPTION_QUARTER,
                    plainOptions: options[MASTER_OPTION.OPTION_QUARTER],
                    defaultValue: options[`SEARCH_${MASTER_OPTION.OPTION_QUARTER}`],
                    disabled: true,
                    showArrow: false,
                    placeholder: ''
                }
            }, {
                span: 8,
                type: TYPE.SELECT,
                header: 'แผนก',
                required: true,
                message: '',
                inputOption: {
                    fieldname: 'depNo',
                    fieldOption: MASTER_OPTION.OPTION_DEP_EVAC,
                    plainOptions: options[MASTER_OPTION.OPTION_DEP_EVAC],
                    defaultValue: '',
                    showArrow: false,
                    disabled: false,
                    placeholder: '--- ทั้งหมด ---'
                }
            }]
    }
}

export const SEARCH_DASHBOARD_EVAC_BY_ADMIN = ({ permission, options, initialData }) => {
    return [{
        span: 6,
        type: TYPE.SELECT,
        header: 'ประจำปี (ย้อนหลัง 3 ปี)',
        required: true,
        message: '',
        inputOption: {
            fieldname: 'year',
            fieldOption: MASTER_OPTION.OPTION_YEAR,
            plainOptions: options[MASTER_OPTION.OPTION_YEAR],
            defaultValue: options[`SEARCH_${MASTER_OPTION.OPTION_YEAR}`],
            disabled: false,
            placeholder: '--- ทั้งหมด ---'
        }
    }, {
        span: 6,
        type: TYPE.SELECT,
        header: 'ไตรมาส',
        required: true,
        message: '',
        inputOption: {
            fieldname: 'quarter',
            fieldOption: MASTER_OPTION.OPTION_QUARTER,
            plainOptions: options[MASTER_OPTION.OPTION_QUARTER],
            defaultValue: options[`SEARCH_${MASTER_OPTION.OPTION_QUARTER}`],
            disabled: false,
            placeholder: '--- ทั้งหมด ---'
        }
    }
    ]
}

export const SEARCH_DASHBOARD_EVAC_BY_DEP = ({ permission, options, initialData }) => {
    switch (permission) {
        default:
            return [{
                span: 8,
                type: TYPE.SELECT,
                header: 'ประจำปี (ย้อนหลัง 3 ปี)',
                required: true,
                message: '',
                inputOption: {
                    fieldname: 'year',
                    fieldOption: MASTER_OPTION.OPTION_YEAR,
                    plainOptions: options[MASTER_OPTION.OPTION_YEAR],
                    defaultValue: options[`SEARCH_${MASTER_OPTION.OPTION_YEAR}`],
                    disabled: false,
                    placeholder: null
                }
            }, {
                span: 8,
                type: TYPE.SELECT,
                header: 'ไตรมาส',
                required: true,
                message: '',
                inputOption: {
                    fieldname: 'quarter',
                    fieldOption: MASTER_OPTION.OPTION_QUARTER,
                    plainOptions: options[MASTER_OPTION.OPTION_QUARTER],
                    defaultValue: options[`SEARCH_${MASTER_OPTION.OPTION_QUARTER}`],
                    disabled: false,
                    placeholder: null
                }
            }, {
                span: 8,
                type: TYPE.SELECT_REQUIRED,
                header: 'แบบประเมิน',
                message: '',
                inputOption: {
                    required: true,
                    disabled: false,
                    placeholder: 'กรุณาเลือกแบบประเมิน',
                    fieldname: 'formId',
                    fieldOption: MASTER_OPTION.OPTION_FORM_EVAC,
                    plainOptions: options[MASTER_OPTION.OPTION_FORM_EVAC],
                    defaultValue: options[`SEARCH_${MASTER_OPTION.OPTION_FORM_EVAC}`],
                }
            }]
    }
}
