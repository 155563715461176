import React from 'react';
import { Row, Col, Divider } from 'antd';
import { path } from 'ramda';
import { baseLabel } from '../../../moduleKPIs/common/functions';
import { TYPE } from '../../../moduleKPIs/common/inputs';
import { SetDate } from '../../../common/setDate';


export const FORM_HISTORY_INDIVIDUAL = {
    HEADER: (props) => [
        [{
            span: 24,
            title: 'No',
            dataIndex: 'no',
            record: props,
            render: (text, record) =>
                baseLabel(TYPE.TITLE, {
                    title: text,
                    label: 'FR-HRD-19'
                }, false, 'right')

        }], [{
            span: 24,
            title: 'Rev',
            dataIndex: 'revNo',
            record: props,
            render: (text, record) =>
                baseLabel(TYPE.TITLE, {
                    title: text,
                    label: '01'
                }, false, 'right')

        }],
        [{
            span: 24,
            dataIndex: '',
            title: 'แบบฟอร์มบันทึกประวัติการฝึกอบรมรายบุคคล',
            record: props,
            render: (text, record, index) => (
                baseLabel(TYPE.HEADER, {
                    title: text
                })
            )
        }], [{
            span: 6,
            title: 'ชื่อ - นามสกุล',
            dataIndex: 'fullname',
            record: props,
            render: (text, record) =>
                baseLabel(TYPE.TITLE, {
                    title: text,
                    label: path(['fullname'], record)
                })
        }, {
            span: 6,
            title: 'รหัสพนักงาน',
            dataIndex: 'userId',
            record: props,
            render: (text, record) =>
                baseLabel(TYPE.TITLE, {
                    title: text,
                    label: path(['userId'], record)
                })
        }, {
            span: 6,
            title: 'ตำแหน่งงาน',
            dataIndex: 'positionName',
            record: props,
            render: (text, record) =>
                baseLabel(TYPE.TITLE, {
                    title: text,
                    label: path(['positionName'], record)
                })
        }, {
            span: 6,
            title: 'แผนกย่อย',
            dataIndex: 'subDepName',
            record: props,
            render: (text, record) =>
                baseLabel(TYPE.TITLE, {
                    title: text,
                    label: path(['subDepName'], record)
                })
        }], [{
            span: 6,
            title: 'วันที่เริ่มงาน',
            dataIndex: 'startDateJob',
            record: props,
            render: (text, record) =>
                baseLabel(TYPE.TITLE, {
                    title: text,
                    label: SetDate(path(['startDateJob'], record))
                })
        }, {
            span: 6,
            title: 'ประเภทการจ้างงาน',
            dataIndex: 'statusWorkerDes',
            record: props,
            render: (text, record) =>
                baseLabel(TYPE.TITLE, {
                    title: text,
                    label: path(['statusWorkerDes'], record)
                })
        }, {
            span: 6,
            title: 'จำนวนหลักสูตรรวม',
            dataIndex: 'totalCourses',
            record: props,
            render: (text, record) =>
                baseLabel(TYPE.TITLE, {
                    title: text,
                    label: path(['totalCourses'], record)
                })
        }, {
            span: 6,
            title: 'จำนวนชั่วโมงอบรมรวม',
            dataIndex: 'totalTraining',
            record: props,
            render: (text, record) =>
                baseLabel(TYPE.TITLE, {
                    title: text,
                    label: path(['totalTraining'], record)
                })
        },]
    ],
    FOOTER: (props) => []
}
export const FORM_TRAINING = {
    HEADER: (props) => [
        [{
            span: 24,
            title: 'No',
            dataIndex: 'no',
            record: props,
            render: (text, record) =>
                baseLabel(TYPE.TITLE, {
                    title: text,
                    label: 'FR-HRD-24'
                }, false, 'right')

        }], [{
            span: 24,
            title: 'Rev',
            dataIndex: 'revNo',
            record: props,
            render: (text, record) =>
                baseLabel(TYPE.TITLE, {
                    title: text,
                    label: '00'
                }, false, 'right')

        }],
        [{
            span: 24,
            title: 'บันทึกผลการอบรมการฝึกปฎิบัติงาน (OJT)',
            dataIndex: '',
            record: props,
            render: (text, record, index) => (
                baseLabel(TYPE.HEADER, {
                    title: text
                })
            )
        }], [{
            span: 6,
            title: 'ชื่อ - นามสกุล',
            dataIndex: 'fullname',
            record: props,
            render: (text, record) =>
                baseLabel(TYPE.TITLE, {
                    title: text,
                    label: path(['fullname'], record)
                })
        }, {
            span: 6,
            title: 'รหัสพนักงาน',
            dataIndex: 'userId',
            record: props,
            render: (text, record) =>
                baseLabel(TYPE.TITLE, {
                    title: text,
                    label: path(['userId'], record)
                })
        }, {
            span: 6,
            title: 'วันที่เริ่มงาน',
            dataIndex: 'startDate',
            record: props,
            render: (text, record) =>
                baseLabel(TYPE.TITLE, {
                    title: text,
                    label: SetDate(path(['startDate'], record))
                })
        }, {
            span: 6,
            title: 'ตำแหน่งงาน',
            dataIndex: 'positionName',
            record: props,
            render: (text, record) =>
                baseLabel(TYPE.TITLE, {
                    title: text,
                    label: path(['positionName'], record)
                })
        }], [{
            render: (text, record) => <div style={{ height: 10 }} />
        }], [{
            span: 8,
            title: 'แผนกย่อย',
            dataIndex: 'subDepName',
            record: props,
            render: (text, record) =>
                baseLabel(TYPE.TITLE, {
                    title: text,
                    label: path(['subDepName'], record)
                })
        }, {
            span: 8,
            title: 'พี่เลี้ยงที่ได้รับมอบหมาย',
            dataIndex: 'mentorName',
            record: props,
            render: (text, record) =>
                baseLabel(TYPE.TITLE, {
                    title: text,
                    label: path(['mentorName'], record)
                })
        }, {
            span: 8,
            title: 'ข้อมูลช่วงวันฝึกอบรม',
            dataIndex: 'durationTraining',
            record: props,
            render: (text, record) =>
                baseLabel(TYPE.TITLE, {
                    title: text,
                    label: path(['durationTraining'], record)
                })

        }], [{
            render: (text, record) => <div style={{ height: 10 }} />
        }], [{
            span: 8,
            title: 'ระยะเวลาในการฝึกปฏิบัติงาน (วัน)',
            dataIndex: 'totalTraining',
            record: props,
            render: (text, record) =>
                baseLabel(TYPE.TITLE, {
                    title: text,
                    label: path(['totalTraining'], record)
                })
        }, {
            span: 8,
            title: 'ผลการประเมิน',
            dataIndex: 'resultTrainingDes',
            record: props,
            render: (text, record) =>
                baseLabel(TYPE.TITLE, {
                    title: text,
                    label: path(['resultTrainingDes'], record)
                })

        }, {
            span: 8,
            title: 'ผลการเรียนเฉลี่ย',
            dataIndex: 'avgTrainingScore',
            record: props,
            render: (text, record) =>
                baseLabel(TYPE.TITLE, {
                    title: text,
                    label: path(['avgTrainingScore'], record)
                })
        }],
    ],
    FOOTER: (props) => [
        [{
            render: (text, record) => <div style={{ height: 50 }} />
        }], [{
            span: 12,
            title: 'ประเมินโดย',
            dataIndex: 'mentorName',
            record: props,
            render: (text, record) =>
                baseLabel(TYPE.TITLE, {
                    title: text,
                    label: path(['mentorName'], record)
                }, false, 'center')
        }, {
            span: 12,
            title: 'อนุมัติโดย',
            dataIndex: 'approverName',
            record: props,
            render: (text, record) =>
                baseLabel(TYPE.TITLE, {
                    title: text,
                    label: path(['approverName'], record)
                }, false, 'center')
        }], [{
            span: 12,
            title: 'วันที่',
            dataIndex: 'approverDate',
            record: props,
            render: (text, record) =>
                baseLabel(TYPE.TITLE, {
                    title: text,
                    label: path(['approverDate'], record)
                }, false, 'center')
        }, {
            span: 12,
            title: 'วันที่',
            dataIndex: 'dateCF',
            record: props,
            render: (text, record) =>
                baseLabel(TYPE.TITLE, {
                    title: text,
                    label: path(['dateCF'], record)
                }, false, 'center')
        }], [{
            render: (text, record) => <div style={{ height: 50 }} />
        }], [{

            span: 24,
            title: 'แผนก HR บันทึกผล',
            dataIndex: 'recorderName',
            record: props,
            render: (text, record) =>
                baseLabel(TYPE.TITLE, {
                    title: text,
                    label: path(['recorderName'], record),

                }, false, 'center')

        }], [{
            span: 24,
            title: 'วันที่',
            dataIndex: 'dateCF',
            record: props,
            render: (text, record) =>
                baseLabel(TYPE.TITLE, {
                    title: text,
                    label: path(['dateCF'], record)
                }, false, 'center')
        }],

    ]
}