import React from 'react'
import * as R from 'ramda'
import './index.css'

import { TYPE } from '../../common/inputs'
import { BaseText } from '../../common/text'
import { ReportKPIs } from "../../components/reportKPIs"
import { ReportBoss } from '../../components/reportBoss'
import { renderMonth } from '../../../constants/functions'
import { MASTER_OPTION } from "../../services/servicesOptions"
import { EvaluationForm } from "../../components/evaluationForm"


const breadcrumbLists = () => [
    { label: "HRM - PMS" },
    { label: "KPIs รายบุคคล" },
    { label: "กรอกคะแนนตัวชี้วัด (KPIs)" }
]

const tabsLists = (role) => {
    switch (role) {
        case 'approvalNo3':
            return [
                { tab: 'Evaluation Form', content: <EvaluationForm /> }, // For Boss Level 1 or 2
                { tab: 'รายงานน้องในทีม', content: <ReportKPIs /> }, // For Boss Level 1 or 2
                { tab: 'รายงานทั้งหมด', content: <ReportBoss /> }// For Boss Level 3 (Such as HR)
            ]
        default:
            return [
                { tab: 'Evaluation Form', content: <EvaluationForm /> }, // For Boss Level 1 or 2
                { tab: 'รายงานน้องในทีม', content: <ReportKPIs /> }, // For Boss Level 1 or 2
            ]
    }
}





const searchLists = (role) => {
    switch (role) {
        case 'approvalNo3':
            return [
                {
                    span: 8,
                    type: TYPE.SELECT,
                    header: "การประเมินประจำปี",
                    fieldname: MASTER_OPTION.YEAR,
                    fieldOption: `OPTION_${MASTER_OPTION.YEAR}`,
                    selectAll: false,
                    searchInput: true,
                    placeholder: `${new Date().getFullYear()}`
                },
                //  {
                //     span: 6,
                //     header: "การประเมินประจำเดือน",
                //     fieldname: MASTER_OPTION.MONTH,
                //     fieldOption: 'optionMonth',
                //     selectAll: true,
                //     searchInput:true
                // }, 
                {
                    span: 8,
                    type: TYPE.SELECT_MULTIPLE,
                    header: "สายการอนุมัติ",
                    fieldname: MASTER_OPTION.BOSS_APPROVAL,
                    fieldOption: `OPTION_${MASTER_OPTION.BOSS_APPROVAL}`,
                    selectAll: true,
                    searchInput: true
                }, {
                    span: 8,
                    type: TYPE.SELECT_MULTIPLE,
                    header: "ติดตามข้อมูลแผนก",
                    fieldname: MASTER_OPTION.BOSS_INSPECT,
                    fieldOption: `OPTION_${MASTER_OPTION.BOSS_INSPECT}`,
                    selectAll: true,
                    searchInput: true
                }
            ]
        default:
            return [
                {
                    span: 12,
                    type: TYPE.SELECT,
                    header: "การประเมินประจำปี",
                    fieldname: MASTER_OPTION.YEAR,
                    fieldOption: `OPTION_${MASTER_OPTION.YEAR}`,
                    selectAll: false,
                    searchInput: true,
                    placeholder: `${new Date().getFullYear()}`
                },
                // {
                //     span: 8,
                //     header: "การประเมินประจำเดือน",
                //     fieldname: MASTER_OPTION.MONTH,
                //     fieldOption: 'optionMonth',
                //     selectAll: true,
                //     searchInput:true
                // }, 
                {
                    span: 12,
                    type: TYPE.SELECT_MULTIPLE,
                    header: "สายการอนุมัติ",
                    fieldname: MASTER_OPTION.BOSS_APPROVAL,
                    fieldOption: `OPTION_${MASTER_OPTION.BOSS_APPROVAL}`,
                    selectAll: true,
                    searchInput: true
                }
            ]
    }
}


const layoutInsertIndicator = ({ indicatorState, option }) => [
    {
        span: 24,
        fieldname: 'indicatorHeaderName',
        type: TYPE.INPUT,
        inputItems: {
            required: false,
            label: <BaseText label={"กำหนดชื่อตัวชี้วัด"} />
        },
        inputProps: {
            options: option.optionIndicatorHeader
        }
    }, {
        span: 24,
        fieldname: 'durationActive',
        type: TYPE.RANG_PICKER,
        inputItems: {
            required: false,
            label: <BaseText label={"กำหนดเดือนที่มีผลบังคับใช้"} />
        },
        inputProps: {
            picker: "month",
            separator: "to",
            format: "MM/YYYY"
        }
    }, {
        span: 10,
        fieldname: 'copyIndicator',
        type: TYPE.RADIO,
        inputItems: {
            required: false,
            label: "คัดลอกกลุ่มตัวชี้วัดก่อนหน้า ?"
        },
        inputProps: {
            options: [
                { optionValue: false, optionLabel: 'ไม่ใช่' },
                { optionValue: true, optionLabel: 'ใช่' },
            ]
        }
    }, {
        span: 14,
        // fieldname: 'indicatorHeaderId',
        fieldname: 'refHeader',
        type: TYPE.SELECT,
        inputItems: {
            required: false,
            label: <BaseText label={"รายการกลุ่มตัวชี้วัด"} />
        },
        inputProps: {
            disabled: !indicatorState.copyIndicator,
            options: option.optionIndicatorHeader
        }
    },
]


const columnEvaluation = ({ searchDate, action, }) => {
    return [
        {
            title: 'ข้อมูลพนักงาน',
            children: [
                //     {
                //     title: 'ลำดับ',
                //     dataIndex: 'rowNum',
                //     width: 100,
                //     render: (text, record) => text || '-'
                // }, 
                {
                    title: 'รหัสพนักงาน',
                    dataIndex: 'employeeId',
                    width: 50,
                    fixed: 'left',
                    render: (text) => text || '-',
                }, {
                    title: 'ชื่อพนักงาน',
                    dataIndex: 'employeeName',
                    width: 80,
                    fixed: 'left',
                    render: (text) => text || '-',
                }, {
                    title: 'ชื่อเล่น',
                    dataIndex: 'employeeNickname',
                    width: 40,
                    fixed: 'left',
                    align: 'center',
                    render: (text) => text || '-'
                }, {
                    title: 'แผนก',
                    dataIndex: 'departmentName',
                    width: 40,
                    fixed: 'left',
                    align: 'center',
                    render: (text) => text || '-'
                }, {
                    title: 'Total Score',
                    dataIndex: 'totalScore',
                    width: 40,
                    fixed: 'left',
                    align: 'right',
                    render: (text) => parseFloat(text || 0).toFixed(2),
                }]
        },
        {
            title: `ข้อมูลการประเมินประจำปี ${searchDate[0].year} (คะแนนเต็ม 100 คะแนน)`,
            children: R.sort(((a, b) => (a - b)), searchDate).map(each => {
                return (
                    {
                        title: `${renderMonth(parseInt(each.month)).sortTH}`,
                        width: 60,
                        align: 'center',
                        render: (text, record) => (
                            action(record, each)
                        )
                    })
            })
        }]
}

const columnReport = ({ searchDate, action }) => {

    return [
        {
            title: 'รหัสพนักงาน',
            dataIndex: 'employeeId',
            render: (text) => text || '-',
        },
        {
            title: 'ชื่อพนักงาน',
            dataIndex: 'employeeName',
            render: (text) => text || '-',
        }, {
            title: 'แผนก',
            dataIndex: 'departmentName',
            render: (text) => text || '-',
        }, {
            title: 'ชื่อเล่น',
            dataIndex: 'employeeNickname',
            render: (text) => text || '-',
        }, {
            title: 'Total Score ',
            dataIndex: 'totalScore',
            render: (text) => parseFloat(text || 0).toFixed(2),
        }
    ]
}

const columnReportExpanded = ({ searchDate, action }) => {

    return [
        {
            title: '',
            children: [
                //     {
                //     title: 'ลำดับ',
                //     dataIndex: 'rowNum',
                //     render: (text) => text || '-',
                // },
                // {
                //     title: 'รหัสพนักงาน',
                //     dataIndex: 'employeeId',
                //     render: (text) => text || '-',
                // },
                {
                    title: 'ตัววัดผลงาน',
                    className: 'expanded-title',
                    dataIndex: 'indicatorLineName',
                    render: (text) => text || '-',
                },
                {
                    title: 'เกณฑ์',
                    dataIndex: 'criterion',
                    render: (text) => `${parseFloat(text || 0).toFixed(2) || 0}%`,
                },
                {
                    title: 'สัดส่วน',
                    dataIndex: 'proportion',
                    render: (text) => `${parseFloat(text || 0).toFixed(2) || 0}%`,
                },
                {
                    title: 'Total Score',
                    dataIndex: 'totalScoreByline',
                    render: (text) => parseFloat(text || 0).toFixed(2),
                }, {
                    title: 'Total Year',
                    dataIndex: 'totalYearByline',
                    render: (text) => parseFloat(text || 0).toFixed(2),
                }]
        },
        {
            title: `ข้อมูลการประเมินประจำปี ${searchDate[0].year} (คะแนนเต็ม 100 คะแนน)`,
            children: R.sort(((a, b) => (a - b)), searchDate).map(each => {
                return (
                    {
                        title: `${renderMonth(parseInt(each.month)).sortTH}`,
                        render: (text, record) => (
                            text[`month${each.month}`] || '-'
                        )
                    }
                )
            })
        }]
}

export const STRUCTURE_KPIS = {
    BREADCRUMB: breadcrumbLists,
    SEARCH_LISTS: searchLists,
    TABS_LISTS: tabsLists,
    COLUMN_EVALUATION: columnEvaluation,
    COLUMN_REPORT: columnReport,
    COLUMN_REPORT_EXPANDED: columnReportExpanded,
    LAYOUT_INSERT_INDICATOR: layoutInsertIndicator
}