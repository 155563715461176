import React from 'react';
import '../index.css';
import "bootstrap/dist/css/bootstrap.css";
import { Col, Row, Button, Card, CardHeader, CardBody, Container } from 'reactstrap';
import { API_URL } from '../components/connectAPI'
import 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css';
import axios from 'axios';
import { loadingPage } from '../components/loadingPage'
import { Modal } from 'antd';

// const api = connectAPI.API_URL
var arrData = []


class ImportImageStaff extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            userLogin: this.props.userLogin,
            selectedFile: null,
            done: true
        };
    }

    fileSelectHandler = e => {
        this.setState({
            selectedFile: e.target.files
        })
    }

    fileUploadHandler = () => {
        var formData = new FormData();

        if (this.state.selectedFile) {

            for (const key of Object.keys(this.state.selectedFile)) {
                formData.append('imgCollection', this.state.selectedFile[key])
            }

            const endpoint = API_URL + '/HRM_API/uploadImages'
            axios.post(endpoint, formData, {
            }).then(res => {
                let result = res.data
                if (result.status_api === 1) {
                    // alert('Insert Success!!')
                    // this.setState({ modal: false })
                    // window.location.reload()

                    Modal.success({
                        title: "Insert Success !!",
                        onOk: () => {
                            this.setState({ modal: false })
                            window.location.reload()
                        }
                    })
                } else {
                    // alert("Insert Error!!")
                    Modal.error({ title: "Insert Error!!" })
                }
            })
        } else {
            // alert('กรุณาเลือกรูปภาพเพื่อ Upload !!')
            Modal.warning({ title: "กรุณาเลือกรูปภาพเพื่อ Upload !!" })
        }

    }
    render() {
        return (
            <Container>
                {this.state.done ? (
                    <div>
                        <Card>
                            <CardHeader> หน้าจัดการเพิ่มรูปภาพพนักงาน(Import Images Staff)</CardHeader>
                            <CardBody>
                                <Row>
                                    <Col md={6}>
                                        <input type="file" onChange={this.fileSelectHandler} multiple />
                                        <Button color="success" size="sm" style={{ marginLeft: '20px' }} onClick={this.fileUploadHandler} >Upload</Button>
                                    </Col>
                                </Row>
                            </CardBody>

                        </Card>
                    </div>) : (<div>{loadingPage} </div>)}

            </Container>

        );

    }
}
export default ImportImageStaff;
