import React, { useContext, useState } from 'react';
import { Spin } from 'antd';

import { PATHNAME } from '../../constants/enums/pathname';

import { REPORT_OPTION } from '../../moduleKPIs/services/servicesOptions';
import { LayoutTable } from '../../moduleKPIs/common/layouts'

import { FIELD_BTN } from '../commonsEvac/structuresEvac/buttonEvac';
import { TABLE_DASHBOARD_REVIEWER } from '../commonsEvac/structuresEvac/tableEvac'
import { ManageEvacContext } from '../servicesEvac/manageEvac/manageEvacProvider';
import { ReportEvacContext } from '../servicesEvac/reportEvac/reportEvacProvider';
import { ButtonTheme } from '../../moduleKPIs/common/buttons';

const DashboardReviewer = () => {
    const { userLogin } = useContext(ManageEvacContext);
    const { reportEvacState, getReportEvac } = useContext(ReportEvacContext);
    const { reviewerData: { formGroup } } = reportEvacState;

    const [loading, setLoading] = useState(false);

    return (
        <Spin tip={'กำลังโหลด'} spinning={loading}>
            <LayoutTable
                scroll={{ x: 600 }}
                bordered
                rowKey="formId"
                dataSource={formGroup}
                columns={TABLE_DASHBOARD_REVIEWER({
                    userLogin: userLogin,
                    actionButton: async ({ fieldBtn, payload }) => {
                        try {
                            setLoading(true)
                            switch (fieldBtn) {
                                case FIELD_BTN.INSERT_EVALUTOR:
                                    await getReportEvac({ fieldname: REPORT_OPTION.SELECT_DATA_EVAC, payload, pathname: `${PATHNAME.INSERT_REVIEWER}` })
                                    break;
                                case FIELD_BTN.UPDATE_EVALUTOR:
                                    await getReportEvac({ fieldname: REPORT_OPTION.SELETE_REVIEWER_UPDATE, payload, pathname: `${PATHNAME.UPDATE_REVIEWER}` })
                                    break;
                                case FIELD_BTN.VIEW_EVALUTOR:
                                    await getReportEvac({ fieldname: REPORT_OPTION.SELETE_REVIEWER_CURRENT, payload, pathname: `${PATHNAME.VIEW_REVIWER}` })
                                    break;
                                default:
                                    break;
                            }

                        } catch (error) {
                            setLoading(false)
                        } finally {
                            setLoading(false)

                        }
                    }
                })}
                pagination={{
                    pageSize: 100,
                    showTotal: (total) => `ทั้งหมด ${total || 0} รายการ`

                }}
            />
        </Spin>
    )
}

export default DashboardReviewer