import React, { useState } from "react";
import { Form, Card, Spin, Table, Space } from "antd";
import CustomTableAtnd from "../tables/CustomTableAtnd";
import { useNotification } from "../notification/NotificationWithIcon";
import { useButton } from "../buttons/CustomButtonAntd";
import { useExportExcel } from "../../services/ExportExcel";
import { Header_ReportWorkAge } from "../../constants/HeaderExcel";
import { optionFilterAgeWorkBy, optionSelectLocation, optionStatusWorker, useOptionSelect } from "../../constants/OptionSelect";
import { useFixedQueryString, useMakeQueryString } from "../../functions/UseMakeQueryString";
import { GET_UserLogin, toUndefined } from "../../functions/UseFunction";
import { configForm, WORDS } from "../../constants/ConstantComponent";
import { useRenderFormItem } from "../RenderComponents";
import { ComponentReportWorkAge } from "../../constants/Reports/Components/ComponentReports";
import { GET, GET_SELECT_REPORT_WORK_AGE } from "../../../service";
import { useAlert } from "../modal/CustomAlert";
import { ColumnReportWorkAge } from "../../constants/Reports/Columns/ColumnReports";

export const FormReportWorkAge = () => {
  const [form] = Form.useForm();
  const { location: locationLogin } = GET_UserLogin();
  // [START] States
  const [ChangeValue, setChangeValue] = useState({ location: locationLogin === "FAC" ? "FAC" : undefined });
  const [dataTable, setDataTable] = useState([]);
  const [overAll, setOverAll] = useState({});
  const [loadingScreen, setLoadingScreen] = useState(false);
  // [END] States
  let optionSearch = {
    companyCode: useOptionSelect("company"),
    location: optionSelectLocation,
    depNo: useOptionSelect("department", useFixedQueryString(ChangeValue, ["companyCode", "location"])),
    subDepNo: useOptionSelect("subDepartment", useFixedQueryString(ChangeValue, ["company", "location", "depNo"])),
    statusWorker: optionStatusWorker,
    filterBy: optionFilterAgeWorkBy,
  }

  // [START] Handle Form
  const onFinish = (values) => {
    const { companyCode, location, depNo, subDepNo, statusWorker, filterBy } = values
    let objectBody = {
      companyCode: companyCode || "",
      location: location || "",
      depNo: depNo || "",
      subDepNo: subDepNo || "",
      statusWorker: statusWorker || "",
      filterBy: filterBy || "",
    }
    getReportAgeOfWork(useMakeQueryString(objectBody));
  };
  const onReset = () => {
    form.resetFields();
    setDataTable([]);
    setOverAll({});
    setChangeValue({ location: locationLogin === "FAC" ? "FAC" : undefined });
  };
  // [END] Handle Form

  // [START] REST API
  const getReportAgeOfWork = async (query) => {
    try {
      setLoadingScreen(true);
      setDataTable([]);
      setOverAll({});
      const response = await GET(GET_SELECT_REPORT_WORK_AGE(query));
      const { result } = response;
      const { summary, overall } = result;
      let mapResult = [];
      let mapOverAll = {};
      if (summary.length !== 0) {
        mapResult = summary.map((row, index) => ({
          key: `${index}`,
          ...row,
        }));
        mapOverAll = overall
      } else {
        useNotification({ type: "info", title: "ไม่พบข้อมูล" });
      }
      setDataTable(mapResult);
      setOverAll(mapOverAll);
    } catch (err) {
      useAlert({ type: "catch", content: err.message })
      console.log("Error Search Report Age of Work: ", err);
    } finally {
      setLoadingScreen(false);
    }
  };
  // [END] REST API

  // [START] Functions
  const onChangeValue = (name, value) => {
    let setUndefined = {};
    switch (name) {
      case "companyCode":
        if (locationLogin === "FAC") {
          setUndefined = toUndefined(["depNo", "subDepNo"]);
        } else {
          setUndefined = toUndefined(["location", "depNo", "subDepNo"]);
        }
        break;
      case "location":
        setUndefined = toUndefined(["depNo", "subDepNo"]);
        break;
      case "depNo":
        setUndefined = toUndefined(["subDepNo"]);
        break;
    }
    setDataTable([]);
    form.setFieldsValue(setUndefined);
    setChangeValue((prev) => ({
      ...prev,
      ...setUndefined,
      [name]: value,
    }));
  };
  // [END] Functions

  const renderSummaryRow = (obj) => {
    if (typeof obj !== "object") return null;
    let renderCells = [];
    for (let i = 0; i <= 20; i++) {
      renderCells.push(
        <Table.Summary.Cell key={i} index={i} align="right">
          {obj[i] || "-"}
        </Table.Summary.Cell>
      );
    }
    return (
      <Table.Summary>
        <Table.Summary.Row>
          <Table.Summary.Cell
            index={0}
            align="center"
            colSpan={ChangeValue["filterBy"] === "subDepartment" ? 6 : 4}
          >
            รวมทั้งสิ้น
          </Table.Summary.Cell>
          {renderCells}
          <Table.Summary.Cell index={5} align="right">
            {obj["total"] || "-"}
          </Table.Summary.Cell>
        </Table.Summary.Row>
      </Table.Summary>
    );
  };

  return (
    <Spin tip={WORDS.loadingSpin} spinning={loadingScreen}>
      <Form form={form} {...configForm} onFinish={onFinish} initialValues={{ location: locationLogin === "FAC" ? "FAC" : undefined }} >
        <Card
          title="รายงานจำนวนพนักงานตามช่วงอายุงาน"
          style={{ marginBottom: 30 }}
          extra={
            <Space>
              {useButton({ call: "search" })}
              {useButton({
                call: "normal",
                fn: onReset,
                moreProps: { label: "ล้างข้อมูล" },
              })}
            </Space>
          }
        >
          {useRenderFormItem({
            constantComponent: ComponentReportWorkAge({
              options: optionSearch,
              handleChange: onChangeValue,
              isDisable: {
                location: locationLogin === "FAC" || false,
              }
            }),
          })}
        </Card>
      </Form>
      {dataTable.length !== 0 && (
        <Card
          title="รายงานจำนวนพนักงานตามช่วงอายุงาน"
          style={{ marginBottom: 30 }}
          extra={
            useExportExcel({
              header: Header_ReportWorkAge(),
              dataset: dataTable,
              filename: "รายงานจำนวนพนักงานตามช่วงอายุงาน",
            })
          }
        >
          <CustomTableAtnd
            HeadTable={ColumnReportWorkAge(ChangeValue["filterBy"])}
            DataTable={dataTable}
            moreProps={{
              scroll: { x: 2400, y: 500 },
              summary: () => renderSummaryRow(overAll),
            }}
          />
        </Card>
      )}
    </Spin>
  );
};

export default FormReportWorkAge;
