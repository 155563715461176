import React, { useState } from "react";
import { Form, Card, Spin, Space, Tabs } from "antd";
import { optionSelectLocation, optionstatusStamp, useOptionSelect } from "../../constants/OptionSelect";
import { toUndefined, sumColumnWidth, subStringDate, GET_UserLogin, DisableButtonSSO } from "../../functions/UseFunction";
import { useAlert } from "../modal/CustomAlert";
import { configForm } from "../../constants/ConstantComponent";
import { useRenderFormItem } from "../RenderComponents";
import CustomTableAtnd from "../tables/CustomTableAtnd";
import { useNotification } from "../notification/NotificationWithIcon";
import { POST, POST_INSERT_SEND_SSO, POST_SELECT_DASHBOARD_TRANSFER_BENEFIT_IN, POST_SELECT_DASHBOARD_TRANSFER_BENEFIT_OUT } from "../../../service";
import { useButton } from "../buttons/CustomButtonAntd";
import { useExportExcel } from "../../services/ExportExcel";
import { Header_ManageBenefitTransfer, Header_ManageBenefitTransferOut } from "../../constants/HeaderExcel";
import { useFixedQueryString } from "../../functions/UseMakeQueryString";
import { useConfirm } from "../modal/CustomConfirm";
import moment from "moment";
import { ComponentChangeInsurancePlanDatatimeStamp, ComponentManageBenefitTransfer } from "../../constants/Benefits/Components";
import { ColumnManageBenefitTransfer, ColumnManageBenefitTransferOut } from "../../constants/Benefits/Columns";

const FormManageBenefitTransfer = () => {
    const [form] = Form.useForm();
    const [formSendSSO] = Form.useForm();
    const { userlogin } = GET_UserLogin();
    // [START] States
    const [ChangeValue, setChangeValue] = useState({});
    const [configOnFinish, setInsurancePlan] = useState({ typeInsurance: "", typeBenefit: "" });
    const [dataTable, setDataTable] = useState([]);
    const [rowSelection, setRowSelection] = useState({ selectedRowKeys: [], selectedRows: [] });
    const [activeTabs, setActiveTabs] = useState(1);
    const [loadingScreen, setLoadingScreen] = useState(false);

    // [END] States
    let optionSearch = {
        company: useOptionSelect("company"),
        location: optionSelectLocation,
        depNo: useOptionSelect("department", useFixedQueryString(ChangeValue, ["companyCode", "location"])),
        subDepNo: useOptionSelect("subDepartment", useFixedQueryString(ChangeValue, ["company", "location", "depNo"])),
        statusStamp: optionstatusStamp,
        userId: useOptionSelect("empAll", useFixedQueryString(ChangeValue, ["companyCode", "location"])),
    };


    // [START] Handle Form Antd
    const onFinish = (values) => {
        const { company, location, depNo, subDepNo, startDate, toDate, statusStamp, userId } = values
        let url = activeTabs === 1 ? POST_SELECT_DASHBOARD_TRANSFER_BENEFIT_IN : POST_SELECT_DASHBOARD_TRANSFER_BENEFIT_OUT;
        let objectBody = {
            company: company || "",
            location: location || "",
            depNo: depNo || "",
            subDepNo: subDepNo || "",
            startDate: startDate ? startDate.format("YYYY-MM-DD") : "",
            toDate: toDate ? toDate.format("YYYY-MM-DD") : "",
            statusStamp: statusStamp || "",
            userId: userId || "",
        }
        selectDashboardTransferBenefits(objectBody, url)
    };

    const onFinishSendSSO = (values) => {
        const { datatimeStamp } = values
        const { selectedRows } = rowSelection
        let dataArray = [];
        if (selectedRows.length === 0) {
            useAlert({
                type: "warning",
                title: "แจ้งเตือน",
                content: `คุณยังไม่ได้เลือก "พนักงาน" กรุณาลองใหม่อีกครั้ง`,
                Fn: () => null,
            });
        } else if (!datatimeStamp) {
            useAlert({
                type: "warning",
                title: "แจ้งเตือน",
                content: `คุณยังไม่ได้เลือก "วันที่ยื่น" กรุณาลองใหม่อีกครั้ง`,
                Fn: () => null,
            });
        } else {
            const { typeInsurance, typeBenefit } = configOnFinish
            dataArray = selectedRows.map((row) => ({
                userId: row.userId,
                typeInsurance: typeInsurance || "",
                planInsuranceHea: row.planInsuranceHea || "",
                planInsuranceAcd: row.planInsuranceAcd || "",
                company: row.company,
                typeBenefit: typeBenefit || "",
                datatimeStamp: datatimeStamp.format("YYYY-MM-DD"),
                docRef: "",
                userlogin,
            }));
            //console.log("dataArray", dataArray)
            useConfirm({ FnOK: () => sendSSO(dataArray) })
        }

    };

    const onReset = () => {
        form.resetFields();
        setDataTable([]);
        setChangeValue({});
        setRowSelection({ selectedRowKeys: [], selectedRows: [] })
        setInsurancePlan({ typeInsurance: "", typeBenefit: "" });
    };

    const selectDashboardTransferBenefits = async (objectValue, url) => {
        try {
            setLoadingScreen(true);
            setRowSelection({ selectedRowKeys: [], selectedRows: [] })
            setDataTable([]);
            const response = await POST(url, objectValue);
            const { result } = response;
            let mapResult = [];
            if (result.length !== 0) {
                mapResult = result.map((row, index) => ({
                    key: `${row.userId}_${index}`,
                    ...row,
                    birthday: subStringDate(row.birthday),
                }));
            } else {
                useNotification({ type: "info", title: "ไม่พบข้อมูล" });
            }
            setDataTable(mapResult);
        } catch (err) {
            useAlert({ type: "catch", content: err.message })
            console.log("Error Search Dashboard Transfer Benefits: ", err);
        } finally {
            setLoadingScreen(false);
        }
    };

    const sendSSO = async (dataBody) => {
        try {
            // console.log("dataBody", dataBody)
            const response = await POST(POST_INSERT_SEND_SSO, dataBody);
            const { success, result } = response;
            if (success) {
                useAlert({
                    type: "success", content: `${result}`, Fn: () => window.location.reload(),
                });
            } else {
                useAlert({ type: "info", content: `บันทึกข้อมูลไม่สำเร็จ กรุณาลองใหม่อีกครั้ง` });
            }
        } catch (err) {
            useAlert({ type: "catch", content: err.message })
            console.log("Error Send SSO (HEA): ", err);
        } finally {
            setLoadingScreen(false);
        }
    };
    // [END] Handle Form Antd

    // [START] Functions
    const onChangeValue = (name, value) => {
        // console.log(name, " : ", value)
        let setUndefined = {};
        let setName = {};
        switch (name) {
            case "company":
                setName = { companyCode: value };
                setUndefined = toUndefined(["location", "depNo", "subDepNo", "userId"]);
                break;
            case "location":
                setUndefined = toUndefined(["depNo", "subDepNo", "userId"]);
                break;
            case "depNo":
                setUndefined = toUndefined(["subDepNo"]);
                break;
        }
        if (name !== "datatimeStamp") setDataTable([]);
        form.setFieldsValue(setUndefined);
        setChangeValue((prev) => ({
            ...prev,
            ...setUndefined,
            ...setName,
            [name]: value,
        }));
    };
    // [END] Functions
    return (
        <Spin tip="กำลังโหลดข้อมูล ..." spinning={loadingScreen}>
            <Form form={form} onFinish={onFinish} {...configForm}>
                <Card
                    title="ค้นหาสวัสดิการพนักงานโอนย้าย"
                    style={{ marginBottom: 30 }}
                    extra={
                        <Space>
                            {useButton({ call: "search" })}
                            {useButton({
                                call: "normal",
                                fn: onReset,
                                moreProps: { label: "ล้างข้อมูล" },
                            })}
                        </Space>
                    }
                >
                    {useRenderFormItem({
                        constantComponent: ComponentManageBenefitTransfer({
                            options: optionSearch,
                            handleChange: onChangeValue,
                        }),
                    })}
                </Card>
            </Form>

            <Card
                title="จัดการสวัสดิการพนักงานโอนย้าย"
                style={{ marginBottom: 30 }}
                extra={
                    <Space wrap>
                        {useButton({
                            call: "normal",
                            fn: () =>
                                useConfirm({
                                    content: `ยืนยันการส่ง "ประกันสุขภาพ/อุบัติเหตุ" ใช่หรือไม่`,
                                    FnOK: () => {
                                        setInsurancePlan({
                                            typeInsurance: "hea",
                                            typeBenefit: activeTabs === 1 ? "tin" : "tout",
                                        });
                                        formSendSSO.submit();
                                    },
                                }),
                            moreProps: { label: "ส่งประกันสุขภาพ/อุบัติเหตุ" },
                            isDisable: DisableButtonSSO(rowSelection.selectedRows, "datatimeStampHea"),
                        })}
                        {useButton({
                            call: "normal",
                            fn: () =>
                                useConfirm({
                                    content: `ยืนยันการส่ง "กองทุนสำรองเลี้ยงชีพ" ใช่หรือไม่`,
                                    FnOK: () => {
                                        setInsurancePlan({
                                            typeInsurance: "pvd",
                                            typeBenefit: activeTabs === 1 ? "tin" : "tout",
                                        });
                                        formSendSSO.submit();
                                    },
                                }),
                            moreProps: { label: "ส่งกองทุนสำรองเลี้ยงชีพ" },
                            isDisable: DisableButtonSSO(rowSelection.selectedRows, "datatimeStampPvd"),
                        })}
                    </Space>
                }
            >
                <Form
                    form={formSendSSO}
                    {...configForm}
                    onFinish={onFinishSendSSO}
                    initialValues={{ datatimeStamp: moment() }}
                >
                    {useRenderFormItem({
                        constantComponent: ComponentChangeInsurancePlanDatatimeStamp({
                            handleChange: onChangeValue,
                        }),
                    })}
                </Form>
                <Tabs
                    tabBarExtraContent={
                        <Space>
                            {useExportExcel({
                                header: activeTabs === 1 ? Header_ManageBenefitTransfer : Header_ManageBenefitTransferOut,
                                dataset: dataTable,
                                filename: "รายงานสวัสดิการพนักงานโอนย้าย",
                            })}
                        </Space>
                    }
                    onChange={key => {
                        setActiveTabs(+key);
                        form.submit();
                    }}
                >
                    <Tabs.TabPane tab="แจ้งเข้าสวัสดิการพนักงานโอนย้าย" key="1">
                        {dataTable.length !== 0 && (
                            <CustomTableAtnd
                                HeadTable={ColumnManageBenefitTransfer}
                                DataTable={dataTable}
                                moreProps={{
                                    rowSelection: {
                                        onChange: (selectedRowKeys, selectedRows) => {
                                            setRowSelection({ selectedRowKeys, selectedRows });
                                        },
                                    },
                                    scroll: {
                                        x: sumColumnWidth(ColumnManageBenefitTransfer) || 2050,
                                        y: 500,
                                    },
                                }}
                            />
                        )}
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="แจ้งออกสวัสดิการพนักงานโอนย้าย" key="2">
                        {dataTable.length !== 0 && (
                            <CustomTableAtnd
                                HeadTable={ColumnManageBenefitTransferOut}
                                DataTable={dataTable}
                                moreProps={{
                                    rowSelection: {
                                        onChange: (selectedRowKeys, selectedRows) => {
                                            setRowSelection({ selectedRowKeys, selectedRows });
                                        },
                                    },
                                    scroll: {
                                        x: sumColumnWidth(ColumnManageBenefitTransferOut) || 2050,
                                        y: 500,
                                    },
                                }}
                            />
                        )}
                    </Tabs.TabPane>
                </Tabs>
            </Card>
        </Spin>
    );
};

export default FormManageBenefitTransfer;
