import React from "react";
import { DatePicker, Form } from "antd";

const DefaultFn = (date, dateString) => {
  console.log(date, dateString);
};

export const DatePickerAntd = ({
  FnOnChange = DefaultFn,
  name = "Default Name",
  label = "Default Label",
  required = true,
  smgReq = "Default Require",
  picker = {},
  DefaultValue,
}) => {
  return (
    <>
      <Form.Item
        name={name}
        label={label}
        rules={[
          {
            required: required,
            message: smgReq,
          },
        ]}
        style={{ width: "100%" }}
        initialValue={DefaultValue}
      >
        <DatePicker
          onChange={(date, dateString) => FnOnChange(dateString, name)}
          style={{ width: "100%" }}
          {...picker}
        />
      </Form.Item>
    </>
  );
};

export const useDatePicker = ({
  name = "useDatePicker",
  componentProps = {},
  fnChange = () => null,
}) => {
  return <DatePicker name={name} {...componentProps} onChange={fnChange} />;
};

export const useDateRange = ({
  name = "useDateRange",
  componentProps = {},
  fnChange = () => null,
}) => {
  return <DatePicker.RangePicker name={name} {...componentProps} onChange={fnChange} />;
};
