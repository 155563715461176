import React from "react"
import { filterSearchColumn, useSorterTable } from "../../../functions/UseFunction";

export const ColumnReportCheckEmployee = [ // Check จำนวนพนักงาน
    {
        title: "รหัสพนักงาน",
        dataIndex: "userId",
        key: "userId",
        align: "left",
        width: 150,
        ...filterSearchColumn("userId", "รหัสพนักงาน"),
    },
    {
        title: "คำนำหน้า",
        dataIndex: "title",
        key: "title",
        align: "left",
        width: 150,
        ...filterSearchColumn("title", "คำนำหน้า"),
    },
    {
        title: "ชื่อพนักงาน",
        dataIndex: "fullname",
        key: "fullname",
        align: "left",
        width: 200,
        ...filterSearchColumn("fullname", "ชื่อพนักงาน"),
    },
    {
        title: "บริษัท",
        dataIndex: "company",
        key: "company",
        align: "left",
        width: 150,
        ...filterSearchColumn("company", "บริษัท"),
    },
    {
        title: "สถานที่",
        dataIndex: "location",
        key: "location",
        align: "left",
        width: 150,
        ...filterSearchColumn("location", "สถานที่"),
    },
    {
        title: "แผนก",
        dataIndex: "depName",
        key: "depName",
        align: "200",
        width: 300,
        ...filterSearchColumn("depName", "แผนก"),
    },
    {
        title: "แผนกย่อย",
        dataIndex: "subDepName",
        key: "subDepName",
        align: "left",
        width: 300,
        ...filterSearchColumn("subDepName", "แผนกย่อย"),
    },
    {
        title: "ตำแหน่งงาน",
        dataIndex: "positionName",
        key: "positionName",
        align: "left",
        width: 300,
        ...filterSearchColumn("positionName", "ตำแหน่งงาน"),
    },
    {
        title: "สายอนุมัติ",
        dataIndex: "flowName",
        key: "flowName",
        align: "left",
        width: 400,
        ...filterSearchColumn("flowName", "สายอนุมัติ"),
    },
    {
        title: "วันที่เริ่มงาน",
        dataIndex: "statrtDateJob",
        key: "statrtDateJob",
        align: "right",
        width: 150,
        ...filterSearchColumn("statrtDateJob", "วันที่เริ่มงาน"),
    },
    {
        title: "วันพ้นสภาพ",
        dataIndex: "endDateJob",
        key: "endDateJob",
        align: "right",
        width: 150,
        ...filterSearchColumn("endDateJob", "วันพ้นสภาพ"),
    },
    {
        title: "สถานะพนักงาน",
        dataIndex: "stautsWorkerDes",
        key: "stautsWorkerDes",
        align: "left",
        width: 200,
        ...filterSearchColumn("stautsWorkerDes", "สถานะพนักงาน"),
    },
    {
        title: "สถานะพนักงาน (อื่นๆ)",
        dataIndex: "currentStatusPeriodDes",
        key: "currentStatusPeriodDes",
        align: "left",
        width: 200,
        ...filterSearchColumn("currentStatusPeriodDes", "สถานะพนักงาน"),
    },
];

export const ColumnReportCharge = [ // จำนวนพนักงานที่รับเข้าทำงานในเดือนนั้น - เพื่อคำนวณค่าบริการสรรหา
    {
        title: "รหัสพนักงาน",
        dataIndex: "userId",
        key: "userId",
        align: "left",
        width: 150,
        ...filterSearchColumn("userId", "รหัสพนักงาน"),
    },
    {
        title: "ชื่อพนักงาน",
        dataIndex: "fullname",
        key: "fullname",
        align: "left",
        width: 200,
        ...filterSearchColumn("fullname", "ชื่อพนักงาน"),
    },
    {
        title: "ชื่อเล่น",
        dataIndex: "nickname",
        key: "nickname",
        align: "left",
        width: 150,
        ...filterSearchColumn("nickname", "ชื่อเล่น"),
    },
    {
        title: "สถานภาพพนักงาน",
        dataIndex: "currentStatus",
        key: "currentStatus",
        align: "left",
        width: 200,
        ...filterSearchColumn("currentStatus", "สถานภาพพนักงาน"),
    },
    {
        title: "สถานะพนักงาน",
        dataIndex: "stautsWorkerDes",
        key: "stautsWorkerDes",
        align: "left",
        width: 200,
        ...filterSearchColumn("stautsWorkerDes", "สถานะพนักงาน"),
    },
    {
        title: "บริษัท",
        dataIndex: "company",
        key: "company",
        align: "left",
        width: 150,
        ...filterSearchColumn("company", "บริษัท"),
    },
    {
        title: "สถานที่",
        dataIndex: "location",
        key: "location",
        align: "left",
        width: 150,
        ...filterSearchColumn("location", "สถานที่"),
    },
    {
        title: "แผนก",
        dataIndex: "depNo",
        key: "depNo",
        align: "left",
        width: 200,
        ...filterSearchColumn("depNo", "แผนก"),
    },
    {
        title: "ตำแหน่งงาน",
        dataIndex: "positionName",
        key: "positionName",
        align: "left",
        width: 300,
        ...filterSearchColumn("positionName", "ตำแหน่งงาน"),
    },
    {
        title: "วันที่เริ่มงาน",
        dataIndex: "statrtDateJob",
        key: "statrtDateJob",
        align: "right",
        width: 150,
        ...filterSearchColumn("statrtDateJob", "วันที่เริ่มงาน"),
    }, {
        title: "วันที่ผ่านทดลองงาน",
        dataIndex: "probationDateEnd",
        key: "probationDateEnd",
        align: "right",
        width: 150,
        ...filterSearchColumn("probationDateEnd", "วันที่ผ่านทดลองงาน"),
    },
    {
        title: "วันที่สิ้นสุดงาน",
        dataIndex: "endDateJob",
        key: "endDateJob",
        align: "right",
        width: 150,
        ...filterSearchColumn("endDateJob", "วันที่สิ้นสุดงาน"),
    },
    {
        title: "รหัสพนักงาน (ผู้สรรหา)",
        dataIndex: "recruiterId",
        key: "recruiterId",
        align: "left",
        width: 200,
        ...filterSearchColumn("recruiterId", "คนสรรหา"),
    },
    {
        title: "ผู้สรรหา",
        dataIndex: "recruiterName",
        key: "recruiterName",
        align: "left",
        width: 150,
        ...filterSearchColumn("recruiterName", "คนสรรหา"),
    },
    {
        title: "เกรดสรรหา",
        dataIndex: "recruiterGrade",
        key: "recruiterGrade",
        align: "left",
        width: 150,
        ...filterSearchColumn("recruiterGrade", "เกรดสรรหา"),
    },
    {
        title: "คะแนนสรรหา",
        dataIndex: "recruiterScore",
        key: "recruiterScore",
        align: "left",
        width: 150,
        ...filterSearchColumn("recruiterScore", "คะแนนสรรหา"),
    },
    {
        title: "หมายเหตุ",
        dataIndex: "remark",
        key: "remark",
        align: "left",
        width: 200,
        ...filterSearchColumn("remark", "หมายเหตุ"),
    },
    {
        title: "%Charge",
        dataIndex: "charge",
        key: "charge",
        align: "left",
        width: 200,
        ...useSorterTable("charge", "number"),
    },

];

export const ColumnReportExamineFullTemp = [ // ตรวจสอบข้อมูลพนักงานประจำและสัญญาจ้าง
    {
        title: "รหัสพนักงาน",
        dataIndex: "userId",
        key: "userId",
        align: "left",
        width: 150,
        ...filterSearchColumn("userId", "รหัสพนักงาน"),
    },
    {
        title: "ชื่อพนักงาน",
        dataIndex: "fullname",
        key: "fullname",
        align: "left",
        width: 200,
        ...filterSearchColumn("fullname", "ชื่อพนักงาน"),
    },
    {
        title: "ชื่อเล่น",
        dataIndex: "nickname",
        key: "nickname",
        align: "left",
        width: 150,
        ...filterSearchColumn("nickname", "ชื่อเล่น"),
    },
    {
        title: "บริษัท",
        dataIndex: "company",
        key: "company",
        align: "left",
        width: 150,
        ...filterSearchColumn("company", "บริษัท"),
    },
    {
        title: "สถานที่",
        dataIndex: "location",
        key: "location",
        align: "left",
        width: 150,
        ...filterSearchColumn("location", "สถานที่"),
    },
    {
        title: "ตำแหน่งงาน",
        dataIndex: "positionName",
        key: "positionName",
        align: "left",
        width: 200,
        ...filterSearchColumn("positionName", "ตำแหน่งงาน"),
    },
    {
        title: "วันที่เริ่มงาน",
        dataIndex: "statrtDateJob",
        key: "statrtDateJob",
        align: "right",
        width: 150,
        ...filterSearchColumn("statrtDateJob", "วันที่เริ่มงาน"),
    },
    {
        title: "วันที่พ้นสภาพ",
        dataIndex: "endDateJob",
        key: "endDateJob",
        align: "right",
        width: 150,
        ...filterSearchColumn("endDateJob", "วันที่พ้นสภาพ"),
    },
    {
        title: "สถานะพนักงาน",
        dataIndex: "stautsWorkerDes",
        key: "stautsWorkerDes",
        align: "left",
        width: 200,
        ...filterSearchColumn("stautsWorkerDes", "สถานะพนักงาน"),
    },
    {
        title: () => {
            return (
                <div>
                    {"รหัสพนักงาน"} <br />
                    {"(ผู้สรรหา)"}
                </div>
            )
        },
        dataIndex: "recruiterId",
        key: "recruiterId",
        align: "left",
        width: 150,
        ...filterSearchColumn("recruiterId", "รหัสพนักงาน (ผู้สรรหา)"),
    },
    {
        title: "ผู้สรรหา",
        dataIndex: "recruiterName",
        key: "recruiterName",
        align: "left",
        width: 150,
        ...filterSearchColumn("recruiterName", "ผู้สรรหา"),
    },
    {
        title: () => {
            return (
                <div>
                    {"สัญญาจ้าง"} <br />
                    {"(วันเริ่มสัญญาล่าสุด)"}
                </div>
            )
        },
        dataIndex: "startProbationDate",
        key: "startProbationDate",
        align: "right",
        width: 150,
        ...filterSearchColumn("startProbationDate", "สัญญาจ้าง (วันเริ่มสัญญาล่าสุด)"),
    },
    {
        title: () => {
            return (
                <div>
                    {"สัญญาจ้าง"} <br />
                    {"(วันสิ้นสุดสัญญาล่าสุด)"}
                </div>
            )
        },
        dataIndex: "endContractDate",
        key: "endContractDate",
        align: "right",
        width: 150,
        ...filterSearchColumn("endContractDate", "สัญญาจ้าง (วันสิ้นสุดสัญญาล่าสุด)"),
    },
    {
        title: () => {
            return (
                <div>
                    {"สัญญาจ้าง"} <br />
                    {"(อายุสัญญาล่าสุด)"}
                </div>
            )
        },
        dataIndex: "contractPeriodDes",
        key: "contractPeriodDes",
        align: "right",
        width: 150,
        ...filterSearchColumn("contractPeriodDes", "สัญญาจ้าง (อายุสัญญาล่าสุด)"),
    },
    {
        title: "สถานะทดลองงาน",
        dataIndex: "probationStatusDes",
        key: "probationStatusDes",
        align: "left",
        width: 200,
        ...filterSearchColumn("probationStatusDes", "ผ่านทดลองงาน"),
    },
];
