import React, { useState, useEffect } from "react";
import { Form, Input, Card, Row, Col, Button, Progress } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { KPIs, USE_CONNECT_MODE_AUTO } from "../../services/ConnectAPI";
import { GET_UserLogin } from "../../functions/UseFunction";
import { useAlert } from "../modal/CustomAlert";
import { useConfirm } from "../modal/CustomConfirm";
import { useButton } from "../buttons/CustomButtonAntd";

export const FormEditIndicatorList = ({
  ListName = "TEST_LIST",
  handleChangeList,
  DataIndicator,
  Fn,
  IndicatorKey,
}) => {
  const { userlogin } = GET_UserLogin();
  const [IndicatorValue, setIndicatorValue] = useState(0);

  let SumWeight = 0;

  useEffect(() => {
    try {
      if (DataIndicator.length > 1) {
        SumWeight = DataIndicator.reduce(
          (a, b) => parseInt(a) + parseInt(b.weight),
          0
        );
      } else if (DataIndicator.length <= 1) {
        SumWeight = parseInt(DataIndicator[0].weight);
      }
      setIndicatorValue(SumWeight);
    } catch {
      setIndicatorValue(0);
    }
  }, [DataIndicator]);

  const POST_ChangeActiveKPIsByLine = async (value) => {
    try {
      const url = value.active
        ? `${USE_CONNECT_MODE_AUTO}${KPIs.POST_DeActiveByLine}`
        : `${USE_CONNECT_MODE_AUTO}${KPIs.POST_ActiveByLine}`;
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          indicatorNo: IndicatorKey,
          indicatorLine: value.line,
          userlogin,
        }),
      });
      const data = await response.json();
      if (response.ok) {
        useAlert({
          type: "success",
          content: "การดำเนินการสำเร็จ",
          Fn: () => Fn(),
        });
      } else {
        useAlert({
          type: "error",
          content: "การดำเนินการล้มเหลว : กรุณาลองใหม่อีกครั้ง",
          Fn: () => Fn(),
        });
      }
    } catch (err) {
      console.log("Error ChangeActiveKPIsByLine: ", err);
    }
  };

  return (
    <Card title={`กำหนดตัวชี้วัด (${ListName})`} style={{ marginBottom: 30 }}>
      <Form.List name={ListName}>
        {(fields, { add, remove }) => (
          <>
            <Row gutter={[16, 16]} justify="start" align="bottom">
              <Col xs={24} sm={16} md={18} lg={20} xl={21}>
                <Form.Item label={`ภาพรวมสัดส่วน:`} style={{ width: "100%" }}>
                  <Progress
                    percent={IndicatorValue}
                    status={IndicatorValue > 100 ? `exception` : ``}
                  />
                </Form.Item>
              </Col>
              <Col xs={8} sm={8} md={6} lg={4} xl={3}>
                <Form.Item>
                  <Button
                    type="button"
                    onClick={() => add()}
                    style={{
                      width: "100%",
                      backgroundColor: "#5CB85C",
                      color: "#FFFFFF",
                    }}
                    icon={<PlusOutlined />}
                  >
                    เพิ่มตัวชี้วัด
                  </Button>
                </Form.Item>
              </Col>
            </Row>
            {fields.map((field) => {
              let checkDisable =
                DataIndicator[field.key] &&
                  DataIndicator[field.key].indicatorLine
                  ? {
                    disabled: DataIndicator[field.key].statusActive
                      ? false
                      : true,
                  }
                  : {};
              return (
                <Row
                  key={field.key}
                  gutter={[16, 16]}
                  justify="start"
                  align="top"
                >
                  <Col xs={24} sm={24} md={24} lg={12} xl={9}>
                    <Form.Item
                      {...field}
                      label="กำหนดชื่อตัวชี้วัด"
                      name={[field.name, "indicatorDes"]}
                      fieldKey={[field.fieldKey, "indicatorDes"]}
                      rules={[
                        {
                          required: true,
                          message: "กรุณากรอก indicatorDes",
                        },
                      ]}
                      style={{ width: "100%" }}
                    >
                      <Input
                        onChange={(e) =>
                          handleChangeList(e.target.value, [
                            field.fieldKey,
                            "indicatorDes",
                          ])
                        }
                        allowClear
                        placeholder={`กรอก ชื่อตัวชี้วัด`}
                        {...checkDisable}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={12} xl={10}>
                    <Form.Item
                      {...field}
                      label="เกณฑ์"
                      name={[field.name, "scoreCriteria"]}
                      fieldKey={[field.fieldKey, "scoreCriteria"]}
                      rules={[
                        {
                          required: true,
                          message: "กรุณากรอก scoreCriteria",
                        },
                      ]}
                      style={{ width: "100%" }}
                    >
                      <Input.TextArea
                        onChange={(e) =>
                          handleChangeList(e.target.value, [
                            field.fieldKey,
                            "scoreCriteria",
                          ])
                        }
                        rows={3}
                        allowClear
                        placeholder={`กำหนด เกณฑ์ต่าง ๆ ลงในช่องนี้ (คลิกมุมด้านล่างเพื่อขยาย)`}
                        {...checkDisable}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={16} sm={16} md={12} lg={12} xl={2}>
                    <Form.Item
                      {...field}
                      label="สัดส่วน"
                      name={[field.name, "weight"]}
                      fieldKey={[field.fieldKey, "weight"]}
                      rules={[{ required: true, message: "กรุณากรอก weight" }]}
                      style={{ width: "100%" }}
                      initialValue={0}
                    >
                      <Input
                        onChange={(e) => handleChangeList(e.target.value)}
                        allowClear
                        {...checkDisable}
                      />
                    </Form.Item>
                  </Col>
                  {DataIndicator[field.key] &&
                    DataIndicator[field.key].indicatorLine ? (
                    <Col xs={8} sm={8} md={6} lg={4} xl={3}>
                      <Form.Item label={` `}>
                        {useButton({
                          call: DataIndicator[field.key].statusActive
                            ? "inActive"
                            : "active",
                          fn: () =>
                            useConfirm({
                              content: `กรุณาตรวจสอบข้อมูลก่อนกด "ยืนยัน"`,
                              FnOK: () =>
                                POST_ChangeActiveKPIsByLine({
                                  active: DataIndicator[field.key].statusActive,
                                  line: DataIndicator[field.key].indicatorLine,
                                }),
                            }),
                        })}
                      </Form.Item>
                    </Col>
                  ) : (
                    <Col xs={8} sm={8} md={6} lg={4} xl={3}>
                      <Form.Item label={` `}>
                        {useButton({
                          call: "normal",
                          fn: () => {
                            remove(field.name);
                            handleChangeList(field.name);
                          },
                          moreProps: { label: "ลบ", type: "danger" },
                        })}
                      </Form.Item>
                    </Col>
                  )}
                </Row>
              );
            })}
          </>
        )}
      </Form.List>
    </Card>
  );
};

export default FormEditIndicatorList;
