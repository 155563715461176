import React, { useState } from "react";
import { Form, Card, Spin, Space, Row, Col, Divider } from "antd";
import { optionSelectLocation, optionStatusWorker, useOptionSelect } from "../../constants/OptionSelect";
import { GET_UserLogin, toUndefined, sumColumnWidth, subStringDate } from "../../functions/UseFunction";
import { useFixedQueryString } from "../../functions/UseMakeQueryString";
import { useAlert } from "../modal/CustomAlert";
import { configForm } from "../../constants/ConstantComponent";
import { useRenderFormItem } from "../RenderComponents";
import CustomTableAtnd from "../tables/CustomTableAtnd";
import { useNotification } from "../notification/NotificationWithIcon";
import { POST, POST_DEL_DOC_PROBATION, POST_SELECT_DASHBOARD_PROBATION, POST_UPDATE_RECEIVED } from "../../../service";
import { useButton } from "../buttons/CustomButtonAntd";
import { useExportExcel } from "../../services/ExportExcel";
import { Header_ReportTrackProbation } from "../../constants/HeaderExcel";
import { ComponentManageDocProbation } from "../../constants/ProbationEmployee/Components";
import { ColumnManageDocProbation } from "../../constants/ProbationEmployee/Columns";
import { RenderMainModal } from "../modal/CustomModalAntd";
import { ComponentModalCancelDoc, ComponentModalPassProbation, ComponentModalPassProbation_2, ComponentModalPassProbation_3 } from "../../constants/ProbationEmployee/ComponentsModal";
import { useText } from "../typography/Typography";
import { useConfirm } from "../modal/CustomConfirm";

const FormManageDocProbation = () => {
    const [form] = Form.useForm();
    const [formModalPass] = Form.useForm();
    const [formModalCancel] = Form.useForm();

    const { userlogin, location } = GET_UserLogin();
    // [START] States
    const [ChangeValue, setChangeValue] = useState({ statusMainCode: "1" });
    const [ChangeValueModalPass, setChangeValueModalPass] = useState({});
    const [dataRow, setDataRow] = useState({});
    const [dataTable, setDataTable] = useState([]);

    const [modalShow, setModalShow] = useState({ pass: false, cancel: false, });

    const [loadingScreen, setLoadingScreen] = useState(false);
    // [END] States

    let optionSearch = {
        company: useOptionSelect("company"),
        location: optionSelectLocation,
        depNo: useOptionSelect("department", useFixedQueryString(ChangeValue, ["companyCode", "location"])),
        statusMainCode: useOptionSelect("statusMainCode").filter(el => {
            return +el.value !== 2 && +el.value !== 3
        }),
        statusWorker: optionStatusWorker,
        searchUser: useOptionSelect("empAll", useFixedQueryString(ChangeValue, ["companyCode", "location"])),
    }

    let optionPassProbation = {
        passType: useOptionSelect("statusProbation"),
        grade: useOptionSelect("grade"),
        recruiterGrade: useOptionSelect("recruiterGrade"),
        recruiterScore: useOptionSelect("recruiterScore"),
    }

    // [START] Handle Form Antd
    const onFinish = (values) => {
        const { company, depNo, statusMainCode, statusWorker, startDate, toDate, searchUser } = values
        const objectBody = {
            userlogin,
            locationLogin: location,
            depNo: depNo || "",
            subDepNo: "",
            statusMainCode: statusMainCode || "",
            startDate: startDate ? startDate.format("YYYY-MM-DD") : "",
            toDate: toDate ? toDate.format("YYYY-MM-DD") : "",
            report: "",
            statusWorker: statusWorker || "",
            currentStatus: "",
            statusProbation: "",
            type: "",
            location: values.location || "",
            company: company || "",
            searchUser: searchUser || "",
            pageType: "trackProbation",
        }
        searchReportTrackProbation(objectBody)
    };

    const onFinishModalPass = (values) => {
        const { passDate, passType, grade, remark, recruiterGrade, recruiterScore, outDate, checkPass } = values
        const { DocNo } = dataRow
        const objectBody = {
            docNo: DocNo || "",
            probationEndDate: passDate ? passDate.format("YYYY-MM-DD") : "",
            userlogin,
            recruiterGrade: recruiterGrade || "",
            recruiterScore: recruiterScore || "",
            endDate: outDate ? outDate.format("YYYY-MM-DD") : "",
            probationStatus: passType || "",
            probationGrade: grade || "",
            remarkByHr: remark || "",
            pageType: "",
            statusAutoOut: checkPass,
        }
        useConfirm({ FnOK: () => updateReceivedDoc(objectBody) })
    }

    const onReset = () => {
        form.resetFields();
        setChangeValue({ statusMainCode: "1" });
        setDataTable([])
    };
    // [END] Handle Form Antd

    // [START] REST API
    const searchReportTrackProbation = async (objectRow) => {
        try {
            const { company, depNo, statusMainCode, statusWorker, startDate, toDate, searchUser } = objectRow
            const objectBody = {
                userlogin,
                locationLogin: location,
                depNo: depNo || "",
                subDepNo: "",
                statusMainCode: statusMainCode || "",
                startDate: startDate || "",
                toDate: toDate || "",
                report: "",
                statusWorker: statusWorker || "",
                currentStatus: "",
                statusProbation: "",
                type: "",
                location: objectRow.location || "",
                company: company || "",
                searchUser: searchUser || "",
                pageType: "trackProbation",
            }
            setLoadingScreen(true);
            const response = await POST(POST_SELECT_DASHBOARD_PROBATION, objectBody);
            const { result } = response;
            let mapResult = [];
            if (result.length !== 0) {
                mapResult = result.map((row, index) => ({
                    key: index,
                    ...row,
                    StartDateJob: subStringDate(row.StartDateJob),
                    expProDate: subStringDate(row.expProDate),
                    probationEndDate: subStringDate(row.probationEndDate),
                    HR_ReceivedDate: subStringDate(row.HR_ReceivedDate),
                    date_HEA: subStringDate(row.date_HEA),
                    date_PVD: subStringDate(row.date_PVD),
                    date_SOS: subStringDate(row.date_SOS),
                }))
            } else {
                useNotification({ type: "info", title: "ไม่พบข้อมูล" })
            }
            setDataTable(mapResult)
        } catch (err) {
            useAlert({ type: "catch", content: `${err.message}` });
            console.log("Error searchReportTrackProbation: ", err);
        } finally {
            setLoadingScreen(false);
        }
    };

    const updateReceivedDoc = async (objectBody) => {
        try {
            setLoadingScreen(true);
            const response = await POST(POST_UPDATE_RECEIVED, objectBody);
            const { success, message } = response
            if (success) {
                useAlert({
                    type: "success",
                    title: message,
                    Fn: () => window.location.reload(),
                })
            }
        } catch (err) {
            console.log("Error updateReceivedDoc: ", err);
            useAlert({ type: "catch", content: `${err.message}` });
        } finally {
            setLoadingScreen(false);
        }
    };

    const delDocProbation = async (docNo) => {
        try {
            setLoadingScreen(true);
            const response = await POST(POST_DEL_DOC_PROBATION, { docNo });
            const { success, message } = response
            if (success) {
                useAlert({
                    type: "success",
                    title: message,
                    Fn: () => window.location.reload(),
                })
            }
        } catch (err) {
            console.log("Error Delete Doc Probation: ", err);
            useAlert({ type: "catch", content: `${err.message}` });
        } finally {
            setLoadingScreen(false);
        }
    };


    const contentModalPass = () => {
        const { passType, checkPass } = ChangeValueModalPass
        return (
            <Form form={formModalPass} onFinish={onFinishModalPass}  {...configForm} >
                {useRenderFormItem({
                    constantComponent: ComponentModalPassProbation({
                        handleChange: onChangeValueModalPass,
                        options: optionPassProbation,
                        require: {
                            // passDate: checkRequire,
                            // grade: checkRequire,
                            passDate: true,
                            grade: true,
                        },
                    }),
                })}
                {location !== "FAC" && (
                    <>
                        <Divider orientation="left">ประเมินเพื่อพัฒนาสรรหา</Divider>
                        {useRenderFormItem({
                            constantComponent: ComponentModalPassProbation_2({
                                handleChange: onChangeValueModalPass,
                                options: optionPassProbation,
                                require: {
                                    recruiterGrade: true,
                                    recruiterScore: true,
                                },
                            }),
                        })}
                        <Divider orientation="right">ผู้สรรหา: {dataRow["recruiterNickname"] ? dataRow["recruiterNickname"] : "-"}</Divider>
                    </>
                )}
                {passType === "2" && (
                    <>
                        <Divider orientation="left">{useText({ type: "danger", text: "ดำเนินการแจ้งพ้นสภาพ" })}</Divider>
                        {useRenderFormItem({
                            constantComponent: ComponentModalPassProbation_3({
                                handleChange: onChangeValueModalPass,
                                isDisable: { outDate: checkPass ? false : true },
                                require: { outDate: checkPass },
                            }),
                        })}
                    </>
                )}
                <Row gutter={[16, 16]} style={{ marginTop: 20 }} justify="center" align="bottom" >
                    <Col xs={8} sm={6} md={6} lg={6} xl={4}>
                        <Form.Item>{useButton({ call: "save" })}</Form.Item>
                    </Col>
                </Row>
            </Form>
        )
    }

    const contentModalCancel = () => {
        return (
            <Form form={formModalCancel} onFinish={onFinishModalPass}  {...configForm} >
                {useRenderFormItem({
                    constantComponent: ComponentModalCancelDoc({}),
                })}
                <Row gutter={[16, 16]} style={{ marginTop: 20 }} justify="center" align="bottom" >
                    <Col xs={24} sm={24} md={12} lg={6} xl={8}>
                        <Form.Item>{useButton({ call: "save" })}</Form.Item>
                    </Col>
                </Row>
            </Form>
        )
    }

    const showModal = (record, type) => {
        let controlModal = {}
        if (type === "pass") {
            controlModal = { pass: true }
        } else if (type === "cancel") {
            controlModal = { cancel: true }
        }
        setDataRow(record);
        setModalShow(prev => ({ ...prev, ...controlModal }));
    };

    const hideModal = (type) => {
        let controlModal = {}
        switch (type) {
            case "pass":
                controlModal = { pass: false }
                formModalPass.resetFields();
                setChangeValueModalPass({});
                break;
            case "cancel":
                controlModal = { cancel: false }
                formModalCancel.resetFields();
                break;
        }
        setDataRow({});
        setModalShow(prev => ({ ...prev, ...controlModal }));
    };
    // [END] REST API

    // [START] Functions
    const onChangeValue = (name, value) => {
        let setUndifined = {}
        let setName = {}
        switch (name) {
            case "company":
                setName = { companyCode: value }
                setUndifined = toUndefined(["location", "depNo", "searchUser"]);
                break;
            case "location":
                setUndifined = toUndefined(["depNo", "searchUser"]);
                break;
        }
        form.setFieldsValue(setUndifined);
        setDataTable([]);
        setChangeValue((prev) => ({
            ...prev,
            ...setUndifined,
            ...setName,
            [name]: value,
        }));
    };

    const onChangeValueModalPass = (name, value) => {
        let setUndefined = {}
        switch (name) {
            case "passType":
                setUndefined = { checkPass: false, outDate: undefined }
                break;
            case "checkPass":
                setUndefined = toUndefined(["outDate"])
                break;
        }
        formModalPass.setFieldsValue(setUndefined)
        setChangeValueModalPass(prev => ({
            ...prev,
            ...setUndefined,
            [name]: value,
        }))
    }

    // [END] Functions
    return (
        <Spin tip="กำลังโหลดข้อมูล ..." spinning={loadingScreen}>
            <RenderMainModal
                show={modalShow.pass}
                onCancel={() => hideModal("pass")}
                txtTitle={"บันทึกผลผ่านทดลองงาน"}
                ModalContent={contentModalPass()}
                width={800}
            />
            <RenderMainModal
                show={modalShow.cancel}
                onCancel={() => hideModal("cancel")}
                txtTitle={"ยกเลิกเอกสาร"}
                ModalContent={contentModalCancel()}
                width={350}
            />
            <Form form={form} onFinish={onFinish}  {...configForm} initialValues={{ statusMainCode: "1" }}>
                <Card
                    title="จัดการเอกสารทดลองงาน (ทั่วไป)"
                    style={{ marginBottom: 30 }}
                    extra={
                        <Space>
                            {useButton({ call: "search" })}
                            {useButton({
                                call: "normal",
                                fn: onReset,
                                moreProps: { label: "ล้างข้อมูล" },
                            })}
                        </Space>
                    }
                >
                    {useRenderFormItem({
                        constantComponent: ComponentManageDocProbation({
                            options: optionSearch,
                            handleChange: onChangeValue
                        }),
                    })}
                    {useText({ type: "danger", text: `* หมายเหตุ: ถ้าสถานะเอกสาร คือ รอสร้างแบบประเมิน รบกวนแจ้งหน้างานประเมินเข้ามาอย่างน้อย 1 รอบก่อนจึงจะสามารถทำการอัพเดทข้อมูลได้` })}
                </Card>
                {dataTable.length !== 0 && (
                    <Card
                        extra={
                            useExportExcel({
                                header: Header_ReportTrackProbation,
                                dataset: dataTable,
                                filename: "รายงานจัดการเอกสารทดลองงาน (ทั่วไป)",
                            })
                        }
                    >
                        <CustomTableAtnd
                            HeadTable={ColumnManageDocProbation({ showModal: showModal, fnCancel: delDocProbation, })}
                            DataTable={dataTable}
                            moreProps={{
                                scroll: {
                                    x: sumColumnWidth(ColumnManageDocProbation({})) || 2050,
                                    y: 500,
                                },
                            }}
                        />
                    </Card>
                )}
            </Form>
        </Spin>
    );
};

export default FormManageDocProbation;
