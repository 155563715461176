import { COMPONENT_TYPE, ERROR_MESSAGE } from "..";

export const INIT_VALUE = {
    company: "", // บริษัท
    location: "", // สถานที่
    depNo: "", // แผนก
    statusMainCode: "", // สถานะการประเมิน
    startDate: "", // วันที่เริ่มทำงาน
    toDate: "", // ถึงวันที่
    searchUser: "", // รหัส,ชื่อ,ชื่อเล่นพนักงาน
    statusProbation: "", // ผลการทดลองงาน
    statusWorker: "", // กลุ่ม/สถานะพนง.
}

const DEFINE = {
    company: {
        label: "บริษัท",
        name: "company",
    },
    location: {
        label: "สถานที่",
        name: "location",
    },
    depNo: {
        label: "แผนก",
        name: "depNo",
    },
    subDepNo: {
        label: "แผนกย่อย",
        name: "subDepNo",
    },
    statusMainCode: {
        label: "สถานะการประเมิน",
        label_2: "สถานะเอกสาร",
        name: "statusMainCode",
    },
    startDate: {
        label: "วันที่เริ่มทำงาน",
        name: "startDate",
    },
    toDate: {
        label: "ถึงวันที่",
        name: "toDate",
    },
    contractDate: {
        label: "รอบต่อสัญญา",
        name: "contractDate",
    },
    searchUser: {
        label: "รหัส, ชื่อ, ชื่อเล่นพนักงาน",
        label_2: "รหัสพนักงาน",
        name: "searchUser",
    },
    statusWorker: {
        label: "สถานะพนักงาน",
        name: "statusWorker",
    },
}

export const ComponentSearchProbationEmpNew = ({
    options = {},
    isDisable = {},
    handleChange = () => null,
}) => {
    const { company, location, depNo, statusMainCode, startDate, toDate, searchUser, statusWorker } = DEFINE
    return [
        { // Row
            rowProps: { justify: "start", align: "bottom" },
            column: [ // column
                {
                    breakpoint: { xs: 24, sm: 24, md: 12, lg: 6, xl: 4 },
                    component: {
                        type: COMPONENT_TYPE.SELECT,
                        itemProps: {
                            name: company.name,
                            label: company.label,
                            rules: [{ required: false, message: ERROR_MESSAGE.SELECT(company.label) }],
                        },
                        componentProps: {
                            style: { width: "100%" },
                            placeholder: ERROR_MESSAGE.SELECT(company.label),
                            disabled: isDisable[company.name] || false,
                        },
                        fnChange: (value) => handleChange(company.name, value),
                        dropdown: options[company.name],
                    },
                },
                {
                    breakpoint: { xs: 24, sm: 24, md: 12, lg: 6, xl: 4 },
                    component: {
                        type: COMPONENT_TYPE.SELECT,
                        itemProps: {
                            name: location.name,
                            label: location.label,
                            rules: [{ required: false, message: ERROR_MESSAGE.SELECT(location.label) }],
                        },
                        componentProps: {
                            style: { width: "100%" },
                            placeholder: ERROR_MESSAGE.SELECT(location.label),
                            disabled: isDisable[location.name] || false,
                        },
                        fnChange: (value) => handleChange(location.name, value),
                        dropdown: options[location.name],
                    },
                },
                {
                    breakpoint: { xs: 24, sm: 24, md: 12, lg: 12, xl: 8 },
                    component: {
                        type: COMPONENT_TYPE.SELECT,
                        itemProps: {
                            name: depNo.name,
                            label: depNo.label,
                            rules: [{ required: false, message: ERROR_MESSAGE.SELECT(depNo.label) }],
                        },
                        componentProps: {
                            style: { width: "100%" },
                            placeholder: ERROR_MESSAGE.SELECT(depNo.label),
                            disabled: isDisable[depNo.name] || false,
                        },
                        fnChange: (value) => handleChange(depNo.name, value),
                        dropdown: options[depNo.name],
                    },
                },
                {
                    breakpoint: { xs: 24, sm: 24, md: 12, lg: 12, xl: 8 },
                    component: {
                        type: COMPONENT_TYPE.SELECT,
                        itemProps: {
                            name: searchUser.name,
                            label: searchUser.label_2,
                            rules: [{ required: false, message: ERROR_MESSAGE.SELECT(searchUser.label_2) }],
                        },
                        componentProps: {
                            style: { width: "100%" },
                            placeholder: ERROR_MESSAGE.SELECT(searchUser.label_2),
                            disabled: isDisable[searchUser.name] || false,
                        },
                        fnChange: (value) => handleChange(searchUser.name, value),
                        dropdown: options[searchUser.name],
                    },
                },
                {
                    breakpoint: { xs: 24, sm: 24, md: 12, lg: 6, xl: 4 },
                    component: {
                        type: COMPONENT_TYPE.SELECT,
                        itemProps: {
                            name: statusMainCode.name,
                            label: statusMainCode.label,
                            rules: [{ required: false, message: ERROR_MESSAGE.SELECT(statusMainCode.label) }],
                        },
                        componentProps: {
                            style: { width: "100%" },
                            placeholder: ERROR_MESSAGE.SELECT(statusMainCode.label),
                            disabled: isDisable[statusMainCode.name] || false,
                        },
                        fnChange: (value) => handleChange(statusMainCode.name, value),
                        dropdown: options[statusMainCode.name],
                    },
                },
                {
                    breakpoint: { xs: 24, sm: 24, md: 12, lg: 6, xl: 4 },
                    component: {
                        type: COMPONENT_TYPE.SELECT,
                        itemProps: {
                            name: statusWorker.name,
                            label: statusWorker.label,
                            rules: [{ required: false, message: ERROR_MESSAGE.SELECT(statusWorker.label) }],
                        },
                        componentProps: {
                            style: { width: "100%" },
                            placeholder: ERROR_MESSAGE.SELECT(statusWorker.label),
                            disabled: isDisable[statusWorker.name] || false,
                        },
                        fnChange: (value) => handleChange(statusWorker.name, value),
                        dropdown: options[statusWorker.name],
                    },
                },
                {
                    breakpoint: { xs: 24, sm: 24, md: 12, lg: 6, xl: 4 },
                    component: {
                        type: COMPONENT_TYPE.DATE,
                        itemProps: {
                            name: startDate.name,
                            label: startDate.label,
                            rules: [{ required: false, message: ERROR_MESSAGE.DATEPICKER }],
                        },
                        componentProps: {
                            style: { width: "100%" },
                            placeholder: ERROR_MESSAGE.DATEPICKER,
                            disabled: isDisable[startDate.name] || false,
                        },
                        fnChange: (date, dateString) => handleChange(startDate.name, dateString),
                        dropdown: options[startDate.name],
                    },
                },
                {
                    breakpoint: { xs: 24, sm: 24, md: 12, lg: 6, xl: 4 },
                    component: {
                        type: COMPONENT_TYPE.DATE,
                        itemProps: {
                            name: toDate.name,
                            label: toDate.label,
                            rules: [{ required: false, message: ERROR_MESSAGE.DATEPICKER }],
                        },
                        componentProps: {
                            style: { width: "100%" },
                            placeholder: ERROR_MESSAGE.DATEPICKER,
                            disabled: isDisable[toDate.name] || false,
                        },
                        fnChange: (date, dateString) => handleChange(toDate.name, dateString),
                        dropdown: options[toDate.name],
                    },
                },
            ], // column
        }, // Row
    ]
}

export const ComponentSearchProbationEmpContract = ({
    options = {},
    isDisable = {},
    handleChange = () => null,
}) => {
    const { company, location, depNo, statusMainCode, startDate, toDate, searchUser, statusWorker } = DEFINE
    return [
        { // Row
            rowProps: { justify: "start", align: "bottom" },
            column: [ // column
                {
                    breakpoint: { xs: 24, sm: 24, md: 12, lg: 6, xl: 4 },
                    component: {
                        type: COMPONENT_TYPE.SELECT,
                        itemProps: {
                            name: company.name,
                            label: company.label,
                            rules: [{ required: false, message: ERROR_MESSAGE.SELECT(company.label) }],
                        },
                        componentProps: {
                            style: { width: "100%" },
                            placeholder: ERROR_MESSAGE.SELECT(company.label),
                            disabled: isDisable[company.name] || false,
                        },
                        fnChange: (value) => handleChange(company.name, value),
                        dropdown: options[company.name],
                    },
                },
                {
                    breakpoint: { xs: 24, sm: 24, md: 12, lg: 6, xl: 4 },
                    component: {
                        type: COMPONENT_TYPE.SELECT,
                        itemProps: {
                            name: location.name,
                            label: location.label,
                            rules: [{ required: false, message: ERROR_MESSAGE.SELECT(location.label) }],
                        },
                        componentProps: {
                            style: { width: "100%" },
                            placeholder: ERROR_MESSAGE.SELECT(location.label),
                            disabled: isDisable[location.name] || false,
                        },
                        fnChange: (value) => handleChange(location.name, value),
                        dropdown: options[location.name],
                    },
                },
                {
                    breakpoint: { xs: 24, sm: 24, md: 12, lg: 12, xl: 8 },
                    component: {
                        type: COMPONENT_TYPE.SELECT,
                        itemProps: {
                            name: depNo.name,
                            label: depNo.label,
                            rules: [{ required: false, message: ERROR_MESSAGE.SELECT(depNo.label) }],
                        },
                        componentProps: {
                            style: { width: "100%" },
                            placeholder: ERROR_MESSAGE.SELECT(depNo.label),
                            disabled: isDisable[depNo.name] || false,
                        },
                        fnChange: (value) => handleChange(depNo.name, value),
                        dropdown: options[depNo.name],
                    },
                },
                {
                    breakpoint: { xs: 24, sm: 24, md: 12, lg: 12, xl: 8 },
                    component: {
                        type: COMPONENT_TYPE.SELECT,
                        itemProps: {
                            name: searchUser.name,
                            label: searchUser.label_2,
                            rules: [{ required: false, message: ERROR_MESSAGE.SELECT(searchUser.label_2) }],
                        },
                        componentProps: {
                            style: { width: "100%" },
                            placeholder: ERROR_MESSAGE.SELECT(searchUser.label_2),
                            disabled: isDisable[searchUser.name] || false,
                        },
                        fnChange: (value) => handleChange(searchUser.name, value),
                        dropdown: options[searchUser.name],
                    },
                },
                {
                    breakpoint: { xs: 24, sm: 24, md: 12, lg: 6, xl: 4 },
                    component: {
                        type: COMPONENT_TYPE.SELECT,
                        itemProps: {
                            name: statusMainCode.name,
                            label: statusMainCode.label,
                            rules: [{ required: false, message: ERROR_MESSAGE.SELECT(statusMainCode.label) }],
                        },
                        componentProps: {
                            style: { width: "100%" },
                            placeholder: ERROR_MESSAGE.SELECT(statusMainCode.label),
                            disabled: isDisable[statusMainCode.name] || false,
                        },
                        fnChange: (value) => handleChange(statusMainCode.name, value),
                        dropdown: options[statusMainCode.name],
                    },
                },
                {
                    breakpoint: { xs: 24, sm: 24, md: 12, lg: 6, xl: 4 },
                    component: {
                        type: COMPONENT_TYPE.SELECT,
                        itemProps: {
                            name: statusWorker.name,
                            label: statusWorker.label,
                            rules: [{ required: false, message: ERROR_MESSAGE.SELECT(statusWorker.label) }],
                        },
                        componentProps: {
                            style: { width: "100%" },
                            placeholder: ERROR_MESSAGE.SELECT(statusWorker.label),
                            disabled: isDisable[statusWorker.name] || false,
                        },
                        fnChange: (value) => handleChange(statusWorker.name, value),
                        dropdown: options[statusWorker.name],
                    },
                },
                {
                    breakpoint: { xs: 24, sm: 24, md: 12, lg: 6, xl: 4 },
                    component: {
                        type: COMPONENT_TYPE.DATE,
                        itemProps: {
                            name: startDate.name,
                            label: startDate.label,
                            rules: [{ required: false, message: ERROR_MESSAGE.DATEPICKER }],
                        },
                        componentProps: {
                            style: { width: "100%" },
                            placeholder: ERROR_MESSAGE.DATEPICKER,
                            disabled: isDisable[startDate.name] || false,
                        },
                        fnChange: (date, dateString) => handleChange(startDate.name, dateString),
                        dropdown: options[startDate.name],
                    },
                },
                {
                    breakpoint: { xs: 24, sm: 24, md: 12, lg: 6, xl: 4 },
                    component: {
                        type: COMPONENT_TYPE.DATE,
                        itemProps: {
                            name: toDate.name,
                            label: toDate.label,
                            rules: [{ required: false, message: ERROR_MESSAGE.DATEPICKER }],
                        },
                        componentProps: {
                            style: { width: "100%" },
                            placeholder: ERROR_MESSAGE.DATEPICKER,
                            disabled: isDisable[toDate.name] || false,
                        },
                        fnChange: (date, dateString) => handleChange(toDate.name, dateString),
                        dropdown: options[toDate.name],
                    },
                },
            ], // column
        }, // Row
    ]
}

export const ComponentSearchProbationEmpContractNew = ({
    options = {},
    isDisable = {},
    handleChange = () => null,
}) => {
    const { company, location, depNo, statusMainCode, startDate, toDate, searchUser, statusWorker,contractDate } = DEFINE
    return [
        { // Row
            rowProps: { justify: "start", align: "bottom" },
            column: [ // column
                {
                    breakpoint: { xs: 24, sm: 24, md: 12, lg: 6, xl: 4 },
                    component: {
                        type: COMPONENT_TYPE.SELECT,
                        itemProps: {
                            name: company.name,
                            label: company.label,
                            rules: [{ required: false, message: ERROR_MESSAGE.SELECT(company.label) }],
                        },
                        componentProps: {
                            style: { width: "100%" },
                            placeholder: ERROR_MESSAGE.SELECT(company.label),
                            disabled: isDisable[company.name] || false,
                        },
                        fnChange: (value) => handleChange(company.name, value),
                        dropdown: options[company.name],
                    },
                },
                {
                    breakpoint: { xs: 24, sm: 24, md: 12, lg: 6, xl: 4 },
                    component: {
                        type: COMPONENT_TYPE.SELECT,
                        itemProps: {
                            name: location.name,
                            label: location.label,
                            rules: [{ required: false, message: ERROR_MESSAGE.SELECT(location.label) }],
                        },
                        componentProps: {
                            style: { width: "100%" },
                            placeholder: ERROR_MESSAGE.SELECT(location.label),
                            disabled: isDisable[location.name] || false,
                        },
                        fnChange: (value) => handleChange(location.name, value),
                        dropdown: options[location.name],
                    },
                },
                {
                    breakpoint: { xs: 24, sm: 24, md: 12, lg: 12, xl: 8 },
                    component: {
                        type: COMPONENT_TYPE.SELECT,
                        itemProps: {
                            name: depNo.name,
                            label: depNo.label,
                            rules: [{ required: false, message: ERROR_MESSAGE.SELECT(depNo.label) }],
                        },
                        componentProps: {
                            style: { width: "100%" },
                            placeholder: ERROR_MESSAGE.SELECT(depNo.label),
                            disabled: isDisable[depNo.name] || false,
                        },
                        fnChange: (value) => handleChange(depNo.name, value),
                        dropdown: options[depNo.name],
                    },
                },
                {
                    breakpoint: { xs: 24, sm: 24, md: 12, lg: 12, xl: 8 },
                    component: {
                        type: COMPONENT_TYPE.SELECT,
                        itemProps: {
                            name: searchUser.name,
                            label: searchUser.label_2,
                            rules: [{ required: false, message: ERROR_MESSAGE.SELECT(searchUser.label_2) }],
                        },
                        componentProps: {
                            style: { width: "100%" },
                            placeholder: ERROR_MESSAGE.SELECT(searchUser.label_2),
                            disabled: isDisable[searchUser.name] || false,
                        },
                        fnChange: (value) => handleChange(searchUser.name, value),
                        dropdown: options[searchUser.name],
                    },
                },
                {
                    breakpoint: { xs: 24, sm: 24, md: 12, lg: 6, xl: 4 },
                    component: {
                        type: COMPONENT_TYPE.SELECT,
                        itemProps: {
                            name: statusMainCode.name,
                            label: statusMainCode.label,
                            rules: [{ required: false, message: ERROR_MESSAGE.SELECT(statusMainCode.label) }],
                        },
                        componentProps: {
                            style: { width: "100%" },
                            placeholder: ERROR_MESSAGE.SELECT(statusMainCode.label),
                            disabled: isDisable[statusMainCode.name] || false,
                        },
                        fnChange: (value) => handleChange(statusMainCode.name, value),
                        dropdown: options[statusMainCode.name],
                    },
                },
                {
                    breakpoint: { xs: 24, sm: 24, md: 12, lg: 6, xl: 4 },
                    component: {
                        type: COMPONENT_TYPE.SELECT,
                        itemProps: {
                            name: statusWorker.name,
                            label: statusWorker.label,
                            rules: [{ required: false, message: ERROR_MESSAGE.SELECT(statusWorker.label) }],
                        },
                        componentProps: {
                            style: { width: "100%" },
                            placeholder: ERROR_MESSAGE.SELECT(statusWorker.label),
                            disabled: isDisable[statusWorker.name] || false,
                        },
                        fnChange: (value) => handleChange(statusWorker.name, value),
                        dropdown: options[statusWorker.name],
                    },
                },
                {
                    breakpoint: { xs: 24, sm: 24, md: 12, lg: 6, xl: 4 },
                    component: {
                        type: COMPONENT_TYPE.DATE,
                        itemProps: {
                            name: startDate.name,
                            label: startDate.label,
                            rules: [{ required: false, message: ERROR_MESSAGE.DATEPICKER }],
                        },
                        componentProps: {
                            style: { width: "100%" },
                            placeholder: ERROR_MESSAGE.DATEPICKER,
                            disabled: isDisable[startDate.name] || false,
                        },
                        fnChange: (date, dateString) => handleChange(startDate.name, dateString),
                        dropdown: options[startDate.name],
                    },
                },
                 {
                    breakpoint: { xs: 24, sm: 24, md: 12, lg: 6, xl: 4 },
                    component: {
                        type: COMPONENT_TYPE.DATE,
                        itemProps: {
                            name: toDate.name,
                            label: toDate.label,
                            rules: [{ required: false, message: ERROR_MESSAGE.DATEPICKER }],
                        },
                        componentProps: {
                            style: { width: "100%" },
                            placeholder: ERROR_MESSAGE.DATEPICKER,
                            disabled: isDisable[toDate.name] || false,
                        },
                        fnChange: (date, dateString) => handleChange(toDate.name, dateString),
                        dropdown: options[toDate.name],
                    },
                },
                {
                    breakpoint: { xs: 24, sm: 24, md: 24, lg: 24, xl: 8 },
                    component: {
                        type: COMPONENT_TYPE.RANG_PICKER,
                        itemProps: {
                            name: contractDate.name,
                            label: contractDate.label,
                            rules: [{ required: false, message: ERROR_MESSAGE.DATEPICKERRANG }],
                        },
                        componentProps: {
                            style: { width: "100%" },
                            placeholder: ERROR_MESSAGE.DATEPICKERRANG,
                            disabled: isDisable[contractDate.name] || false,
                        },
                        fnChange: (date, dateString) => handleChange(contractDate.name, dateString),
                        dropdown: options[contractDate.name],
                    },
                },
               
            ], // column
        }, // Row
    ]
}

export const ComponentSearchReportTrackProbation = ({
    options = {},
    isDisable = {},
    handleChange = () => null,
}) => {
    const { company, location, depNo, statusMainCode, startDate, toDate, searchUser, statusWorker } = DEFINE
    return [
        { // Row
            rowProps: { justify: "start", align: "bottom" },
            column: [ // column
                {
                    breakpoint: { xs: 24, sm: 24, md: 12, lg: 6, xl: 4 },
                    component: {
                        type: COMPONENT_TYPE.SELECT,
                        itemProps: {
                            name: company.name,
                            label: company.label,
                            rules: [{ required: false, message: ERROR_MESSAGE.SELECT(company.label) }],
                        },
                        componentProps: {
                            style: { width: "100%" },
                            placeholder: ERROR_MESSAGE.SELECT(company.label),
                            disabled: isDisable[company.name] || false,
                        },
                        fnChange: (value) => handleChange(company.name, value),
                        dropdown: options[company.name],
                    },
                },
                {
                    breakpoint: { xs: 24, sm: 24, md: 12, lg: 6, xl: 4 },
                    component: {
                        type: COMPONENT_TYPE.SELECT,
                        itemProps: {
                            name: location.name,
                            label: location.label,
                            rules: [{ required: false, message: ERROR_MESSAGE.SELECT(location.label) }],
                        },
                        componentProps: {
                            style: { width: "100%" },
                            placeholder: ERROR_MESSAGE.SELECT(location.label),
                            disabled: isDisable[location.name] || false,
                        },
                        fnChange: (value) => handleChange(location.name, value),
                        dropdown: options[location.name],
                    },
                },
                {
                    breakpoint: { xs: 24, sm: 24, md: 12, lg: 12, xl: 8 },
                    component: {
                        type: COMPONENT_TYPE.SELECT,
                        itemProps: {
                            name: depNo.name,
                            label: depNo.label,
                            rules: [{ required: false, message: ERROR_MESSAGE.SELECT(depNo.label) }],
                        },
                        componentProps: {
                            style: { width: "100%" },
                            placeholder: ERROR_MESSAGE.SELECT(depNo.label),
                            disabled: isDisable[depNo.name] || false,
                        },
                        fnChange: (value) => handleChange(depNo.name, value),
                        dropdown: options[depNo.name],
                    },
                },
                {
                    breakpoint: { xs: 24, sm: 24, md: 12, lg: 12, xl: 8 },
                    component: {
                        type: COMPONENT_TYPE.SELECT,
                        itemProps: {
                            name: searchUser.name,
                            label: searchUser.label_2,
                            rules: [{ required: false, message: ERROR_MESSAGE.SELECT(searchUser.label_2) }],
                        },
                        componentProps: {
                            style: { width: "100%" },
                            placeholder: ERROR_MESSAGE.SELECT(searchUser.label_2),
                            disabled: isDisable[searchUser.name] || false,
                        },
                        fnChange: (value) => handleChange(searchUser.name, value),
                        dropdown: options[searchUser.name],
                    },
                },
                {
                    breakpoint: { xs: 24, sm: 24, md: 12, lg: 6, xl: 4 },
                    component: {
                        type: COMPONENT_TYPE.SELECT,
                        itemProps: {
                            name: statusMainCode.name,
                            label: statusMainCode.label_2,
                            rules: [{ required: false, message: ERROR_MESSAGE.SELECT(statusMainCode.label_2) }],
                            initialValue: "2",
                        },
                        componentProps: {
                            style: { width: "100%" },
                            placeholder: ERROR_MESSAGE.SELECT(statusMainCode.label_2),
                            disabled: isDisable[statusMainCode.name] || false,
                        },
                        fnChange: (value) => handleChange(statusMainCode.name, value),
                        dropdown: options[statusMainCode.name],
                    },
                },
                {
                    breakpoint: { xs: 24, sm: 24, md: 12, lg: 6, xl: 4 },
                    component: {
                        type: COMPONENT_TYPE.SELECT,
                        itemProps: {
                            name: statusWorker.name,
                            label: statusWorker.label,
                            rules: [{ required: false, message: ERROR_MESSAGE.SELECT(statusWorker.label) }],
                        },
                        componentProps: {
                            style: { width: "100%" },
                            placeholder: ERROR_MESSAGE.SELECT(statusWorker.label),
                            disabled: isDisable[statusWorker.name] || false,
                        },
                        fnChange: (value) => handleChange(statusWorker.name, value),
                        dropdown: options[statusWorker.name],
                    },
                },
                {
                    breakpoint: { xs: 24, sm: 24, md: 12, lg: 6, xl: 4 },
                    component: {
                        type: COMPONENT_TYPE.DATE,
                        itemProps: {
                            name: startDate.name,
                            label: startDate.label,
                            rules: [{ required: false, message: ERROR_MESSAGE.DATEPICKER }],
                        },
                        componentProps: {
                            style: { width: "100%" },
                            placeholder: ERROR_MESSAGE.DATEPICKER,
                            disabled: isDisable[startDate.name] || false,
                        },
                        fnChange: (date, dateString) => handleChange(startDate.name, dateString),
                        dropdown: options[startDate.name],
                    },
                },
                {
                    breakpoint: { xs: 24, sm: 24, md: 12, lg: 6, xl: 4 },
                    component: {
                        type: COMPONENT_TYPE.DATE,
                        itemProps: {
                            name: toDate.name,
                            label: toDate.label,
                            rules: [{ required: false, message: ERROR_MESSAGE.DATEPICKER }],
                        },
                        componentProps: {
                            style: { width: "100%" },
                            placeholder: ERROR_MESSAGE.DATEPICKER,
                            disabled: isDisable[toDate.name] || false,
                        },
                        fnChange: (date, dateString) => handleChange(toDate.name, dateString),
                        dropdown: options[toDate.name],
                    },
                },
            ], // column
        }, // Row
    ]
}

export const ComponentSearchDashboardTrackContract = ({
    options = {},
    isDisable = {},
    defaultValue = {},
    handleChange = () => null,
}) => {
    const { company, location, depNo, statusMainCode, startDate, toDate, searchUser, statusWorker } = DEFINE
    return [
        { // Row
            rowProps: { justify: "start", align: "bottom" },
            column: [ // column
                {
                    breakpoint: { xs: 24, sm: 24, md: 12, lg: 6, xl: 4 },
                    component: {
                        type: COMPONENT_TYPE.SELECT,
                        itemProps: {
                            name: company.name,
                            label: company.label,
                            rules: [{ required: false, message: ERROR_MESSAGE.SELECT(company.label) }],
                        },
                        componentProps: {
                            style: { width: "100%" },
                            placeholder: ERROR_MESSAGE.SELECT(company.label),
                            disabled: isDisable[company.name] || false,
                        },
                        fnChange: (value) => handleChange(company.name, value),
                        dropdown: options[company.name],
                    },
                },
                {
                    breakpoint: { xs: 24, sm: 24, md: 12, lg: 6, xl: 4 },
                    component: {
                        type: COMPONENT_TYPE.SELECT,
                        itemProps: {
                            name: location.name,
                            label: location.label,
                            rules: [{ required: false, message: ERROR_MESSAGE.SELECT(location.label) }],
                        },
                        componentProps: {
                            style: { width: "100%" },
                            placeholder: ERROR_MESSAGE.SELECT(location.label),
                            disabled: isDisable[location.name] || false,
                        },
                        fnChange: (value) => handleChange(location.name, value),
                        dropdown: options[location.name],
                    },
                },
                {
                    breakpoint: { xs: 24, sm: 24, md: 12, lg: 12, xl: 8 },
                    component: {
                        type: COMPONENT_TYPE.SELECT,
                        itemProps: {
                            name: depNo.name,
                            label: depNo.label,
                            rules: [{ required: false, message: ERROR_MESSAGE.SELECT(depNo.label) }],
                        },
                        componentProps: {
                            style: { width: "100%" },
                            placeholder: ERROR_MESSAGE.SELECT(depNo.label),
                            disabled: isDisable[depNo.name] || false,
                        },
                        fnChange: (value) => handleChange(depNo.name, value),
                        dropdown: options[depNo.name],
                    },
                },
                {
                    breakpoint: { xs: 24, sm: 24, md: 12, lg: 12, xl: 8 },
                    component: {
                        type: COMPONENT_TYPE.SELECT,
                        itemProps: {
                            name: searchUser.name,
                            label: searchUser.label_2,
                            rules: [{ required: false, message: ERROR_MESSAGE.SELECT(searchUser.label_2) }],
                        },
                        componentProps: {
                            style: { width: "100%" },
                            placeholder: ERROR_MESSAGE.SELECT(searchUser.label_2),
                            disabled: isDisable[searchUser.name] || false,
                        },
                        fnChange: (value) => handleChange(searchUser.name, value),
                        dropdown: options[searchUser.name],
                    },
                },
                {
                    breakpoint: { xs: 24, sm: 24, md: 12, lg: 6, xl: 4 },
                    component: {
                        type: COMPONENT_TYPE.SELECT,
                        itemProps: {
                            name: statusMainCode.name,
                            label: statusMainCode.label_2,
                            rules: [{ required: false, message: ERROR_MESSAGE.SELECT(statusMainCode.label_2) }],
                            initialValue: defaultValue[statusMainCode.name]
                        },
                        componentProps: {
                            style: { width: "100%" },
                            placeholder: ERROR_MESSAGE.SELECT(statusMainCode.label_2),
                            disabled: isDisable[statusMainCode.name] || false,
                        },
                        fnChange: (value) => handleChange(statusMainCode.name, value),
                        dropdown: options[statusMainCode.name],
                    },
                },
                {
                    breakpoint: { xs: 24, sm: 24, md: 12, lg: 6, xl: 4 },
                    component: {
                        type: COMPONENT_TYPE.SELECT,
                        itemProps: {
                            name: statusWorker.name,
                            label: statusWorker.label,
                            rules: [{ required: false, message: ERROR_MESSAGE.SELECT(statusWorker.label) }],
                            initialValue: defaultValue[statusWorker.name]
                        },
                        componentProps: {
                            style: { width: "100%" },
                            placeholder: ERROR_MESSAGE.SELECT(statusWorker.label),
                            disabled: isDisable[statusWorker.name] || false,
                        },
                        fnChange: (value) => handleChange(statusWorker.name, value),
                        dropdown: options[statusWorker.name],
                    },
                },
                {
                    breakpoint: { xs: 24, sm: 24, md: 12, lg: 6, xl: 4 },
                    component: {
                        type: COMPONENT_TYPE.DATE,
                        itemProps: {
                            name: startDate.name,
                            label: startDate.label,
                            rules: [{ required: false, message: ERROR_MESSAGE.DATEPICKER }],
                        },
                        componentProps: {
                            style: { width: "100%" },
                            placeholder: ERROR_MESSAGE.DATEPICKER,
                            disabled: isDisable[startDate.name] || false,
                        },
                        fnChange: (date, dateString) => handleChange(startDate.name, dateString),
                        dropdown: options[startDate.name],
                    },
                },
                {
                    breakpoint: { xs: 24, sm: 24, md: 12, lg: 6, xl: 4 },
                    component: {
                        type: COMPONENT_TYPE.DATE,
                        itemProps: {
                            name: toDate.name,
                            label: toDate.label,
                            rules: [{ required: false, message: ERROR_MESSAGE.DATEPICKER }],
                        },
                        componentProps: {
                            style: { width: "100%" },
                            placeholder: ERROR_MESSAGE.DATEPICKER,
                            disabled: isDisable[toDate.name] || false,
                        },
                        fnChange: (date, dateString) => handleChange(toDate.name, dateString),
                        dropdown: options[toDate.name],
                    },
                },
            ], // column
        }, // Row
    ]
}

export const ComponentManageDocProbation = ({
    options = {},
    isDisable = {},
    handleChange = () => null,
}) => {
    const { company, location, depNo, statusMainCode, startDate, toDate, searchUser, statusWorker } = DEFINE
    return [
        { // Row
            rowProps: { justify: "start", align: "bottom" },
            column: [ // column
                {
                    breakpoint: { xs: 24, sm: 24, md: 12, lg: 6, xl: 4 },
                    component: {
                        type: COMPONENT_TYPE.SELECT,
                        itemProps: {
                            name: company.name,
                            label: company.label,
                            rules: [{ required: false, message: ERROR_MESSAGE.SELECT(company.label) }],
                        },
                        componentProps: {
                            style: { width: "100%" },
                            placeholder: ERROR_MESSAGE.SELECT(company.label),
                            disabled: isDisable[company.name] || false,
                        },
                        fnChange: (value) => handleChange(company.name, value),
                        dropdown: options[company.name],
                    },
                },
                {
                    breakpoint: { xs: 24, sm: 24, md: 12, lg: 6, xl: 4 },
                    component: {
                        type: COMPONENT_TYPE.SELECT,
                        itemProps: {
                            name: location.name,
                            label: location.label,
                            rules: [{ required: false, message: ERROR_MESSAGE.SELECT(location.label) }],
                        },
                        componentProps: {
                            style: { width: "100%" },
                            placeholder: ERROR_MESSAGE.SELECT(location.label),
                            disabled: isDisable[location.name] || false,
                        },
                        fnChange: (value) => handleChange(location.name, value),
                        dropdown: options[location.name],
                    },
                },
                {
                    breakpoint: { xs: 24, sm: 24, md: 12, lg: 12, xl: 8 },
                    component: {
                        type: COMPONENT_TYPE.SELECT,
                        itemProps: {
                            name: depNo.name,
                            label: depNo.label,
                            rules: [{ required: false, message: ERROR_MESSAGE.SELECT(depNo.label) }],
                        },
                        componentProps: {
                            style: { width: "100%" },
                            placeholder: ERROR_MESSAGE.SELECT(depNo.label),
                            disabled: isDisable[depNo.name] || false,
                        },
                        fnChange: (value) => handleChange(depNo.name, value),
                        dropdown: options[depNo.name],
                    },
                },
                {
                    breakpoint: { xs: 24, sm: 24, md: 12, lg: 12, xl: 8 },
                    component: {
                        type: COMPONENT_TYPE.SELECT,
                        itemProps: {
                            name: searchUser.name,
                            label: searchUser.label_2,
                            rules: [{ required: false, message: ERROR_MESSAGE.SELECT(searchUser.label_2) }],
                        },
                        componentProps: {
                            style: { width: "100%" },
                            placeholder: ERROR_MESSAGE.SELECT(searchUser.label_2),
                            disabled: isDisable[searchUser.name] || false,
                        },
                        fnChange: (value) => handleChange(searchUser.name, value),
                        dropdown: options[searchUser.name],
                    },
                },
                {
                    breakpoint: { xs: 24, sm: 24, md: 12, lg: 6, xl: 4 },
                    component: {
                        type: COMPONENT_TYPE.SELECT,
                        itemProps: {
                            name: statusMainCode.name,
                            label: statusMainCode.label_2,
                            rules: [{ required: false, message: ERROR_MESSAGE.SELECT(statusMainCode.label_2) }],
                        },
                        componentProps: {
                            style: { width: "100%" },
                            placeholder: ERROR_MESSAGE.SELECT(statusMainCode.label_2),
                            disabled: isDisable[statusMainCode.name] || false,
                        },
                        fnChange: (value) => handleChange(statusMainCode.name, value),
                        dropdown: options[statusMainCode.name],
                    },
                },
                {
                    breakpoint: { xs: 24, sm: 24, md: 12, lg: 6, xl: 4 },
                    component: {
                        type: COMPONENT_TYPE.SELECT,
                        itemProps: {
                            name: statusWorker.name,
                            label: statusWorker.label,
                            rules: [{ required: false, message: ERROR_MESSAGE.SELECT(statusWorker.label) }],
                        },
                        componentProps: {
                            style: { width: "100%" },
                            placeholder: ERROR_MESSAGE.SELECT(statusWorker.label),
                            disabled: isDisable[statusWorker.name] || false,
                        },
                        fnChange: (value) => handleChange(statusWorker.name, value),
                        dropdown: options[statusWorker.name],
                    },
                },
                {
                    breakpoint: { xs: 24, sm: 24, md: 12, lg: 6, xl: 4 },
                    component: {
                        type: COMPONENT_TYPE.DATE,
                        itemProps: {
                            name: startDate.name,
                            label: startDate.label,
                            rules: [{ required: false, message: ERROR_MESSAGE.DATEPICKER }],
                        },
                        componentProps: {
                            style: { width: "100%" },
                            placeholder: ERROR_MESSAGE.DATEPICKER,
                            disabled: isDisable[startDate.name] || false,
                        },
                        fnChange: (date, dateString) => handleChange(startDate.name, dateString),
                        dropdown: options[startDate.name],
                    },
                },
                {
                    breakpoint: { xs: 24, sm: 24, md: 12, lg: 6, xl: 4 },
                    component: {
                        type: COMPONENT_TYPE.DATE,
                        itemProps: {
                            name: toDate.name,
                            label: toDate.label,
                            rules: [{ required: false, message: ERROR_MESSAGE.DATEPICKER }],
                        },
                        componentProps: {
                            style: { width: "100%" },
                            placeholder: ERROR_MESSAGE.DATEPICKER,
                            disabled: isDisable[toDate.name] || false,
                        },
                        fnChange: (date, dateString) => handleChange(toDate.name, dateString),
                        dropdown: options[toDate.name],
                    },
                },
            ], // column
        }, // Row
    ]
}

export const ComponentManageDocProbationContract = ({
    options = {},
    isDisable = {},
    handleChange = () => null,
}) => {
    const { company, location, depNo, statusMainCode, startDate, toDate, searchUser } = DEFINE
    return [
        { // Row
            rowProps: { justify: "start", align: "bottom" },
            column: [ // column
                {
                    breakpoint: { xs: 24, sm: 24, md: 12, lg: 6, xl: 4 },
                    component: {
                        type: COMPONENT_TYPE.SELECT,
                        itemProps: {
                            name: company.name,
                            label: company.label,
                            rules: [{ required: false, message: ERROR_MESSAGE.SELECT(company.label) }],
                        },
                        componentProps: {
                            style: { width: "100%" },
                            placeholder: ERROR_MESSAGE.SELECT(company.label),
                            disabled: isDisable[company.name] || false,
                        },
                        fnChange: (value) => handleChange(company.name, value),
                        dropdown: options[company.name],
                    },
                },
                {
                    breakpoint: { xs: 24, sm: 24, md: 12, lg: 6, xl: 4 },
                    component: {
                        type: COMPONENT_TYPE.SELECT,
                        itemProps: {
                            name: location.name,
                            label: location.label,
                            rules: [{ required: false, message: ERROR_MESSAGE.SELECT(location.label) }],
                        },
                        componentProps: {
                            style: { width: "100%" },
                            placeholder: ERROR_MESSAGE.SELECT(location.label),
                            disabled: isDisable[location.name] || false,
                        },
                        fnChange: (value) => handleChange(location.name, value),
                        dropdown: options[location.name],
                    },
                },
                {
                    breakpoint: { xs: 24, sm: 24, md: 12, lg: 12, xl: 8 },
                    component: {
                        type: COMPONENT_TYPE.SELECT,
                        itemProps: {
                            name: depNo.name,
                            label: depNo.label,
                            rules: [{ required: false, message: ERROR_MESSAGE.SELECT(depNo.label) }],
                        },
                        componentProps: {
                            style: { width: "100%" },
                            placeholder: ERROR_MESSAGE.SELECT(depNo.label),
                            disabled: isDisable[depNo.name] || false,
                        },
                        fnChange: (value) => handleChange(depNo.name, value),
                        dropdown: options[depNo.name],
                    },
                },
                {
                    breakpoint: { xs: 24, sm: 24, md: 12, lg: 12, xl: 8 },
                    component: {
                        type: COMPONENT_TYPE.SELECT,
                        itemProps: {
                            name: searchUser.name,
                            label: searchUser.label_2,
                            rules: [{ required: false, message: ERROR_MESSAGE.SELECT(searchUser.label_2) }],
                        },
                        componentProps: {
                            style: { width: "100%" },
                            placeholder: ERROR_MESSAGE.SELECT(searchUser.label_2),
                            disabled: isDisable[searchUser.name] || false,
                        },
                        fnChange: (value) => handleChange(searchUser.name, value),
                        dropdown: options[searchUser.name],
                    },
                },
                {
                    breakpoint: { xs: 24, sm: 24, md: 12, lg: 6, xl: 4 },
                    component: {
                        type: COMPONENT_TYPE.SELECT,
                        itemProps: {
                            name: statusMainCode.name,
                            label: statusMainCode.label_2,
                            rules: [{ required: false, message: ERROR_MESSAGE.SELECT(statusMainCode.label_2) }],
                        },
                        componentProps: {
                            style: { width: "100%" },
                            placeholder: ERROR_MESSAGE.SELECT(statusMainCode.label_2),
                            disabled: isDisable[statusMainCode.name] || false,
                        },
                        fnChange: (value) => handleChange(statusMainCode.name, value),
                        dropdown: options[statusMainCode.name],
                    },
                },
                {
                    breakpoint: { xs: 24, sm: 24, md: 12, lg: 6, xl: 4 },
                    component: {
                        type: COMPONENT_TYPE.DATE,
                        itemProps: {
                            name: startDate.name,
                            label: startDate.label,
                            rules: [{ required: false, message: ERROR_MESSAGE.DATEPICKER }],
                        },
                        componentProps: {
                            style: { width: "100%" },
                            placeholder: ERROR_MESSAGE.DATEPICKER,
                            disabled: isDisable[startDate.name] || false,
                        },
                        fnChange: (date, dateString) => handleChange(startDate.name, dateString),
                        dropdown: options[startDate.name],
                    },
                },
                {
                    breakpoint: { xs: 24, sm: 24, md: 12, lg: 6, xl: 4 },
                    component: {
                        type: COMPONENT_TYPE.DATE,
                        itemProps: {
                            name: toDate.name,
                            label: toDate.label,
                            rules: [{ required: false, message: ERROR_MESSAGE.DATEPICKER }],
                        },
                        componentProps: {
                            style: { width: "100%" },
                            placeholder: ERROR_MESSAGE.DATEPICKER,
                            disabled: isDisable[toDate.name] || false,
                        },
                        fnChange: (date, dateString) => handleChange(toDate.name, dateString),
                        dropdown: options[toDate.name],
                    },
                },
            ], // column
        }, // Row
    ]
}

export const ComponentManageBenefitContract = ({
    options = {},
    isDisable = {},
    handleChange = () => null,
}) => {
    const { company, location, depNo, subDepNo, startDate, toDate, searchUser } = DEFINE
    return [
        { // Row
            rowProps: { justify: "start", align: "bottom" },
            column: [ // column
                {
                    breakpoint: { xs: 24, sm: 24, md: 12, lg: 6, xl: 4 },
                    component: {
                        type: COMPONENT_TYPE.SELECT,
                        itemProps: {
                            name: company.name,
                            label: company.label,
                            rules: [{ required: false, message: ERROR_MESSAGE.SELECT(company.label) }],
                        },
                        componentProps: {
                            style: { width: "100%" },
                            placeholder: ERROR_MESSAGE.SELECT(company.label),
                            disabled: isDisable[company.name] || false,
                        },
                        fnChange: (value) => handleChange(company.name, value),
                        dropdown: options[company.name],
                    },
                },
                {
                    breakpoint: { xs: 24, sm: 24, md: 12, lg: 6, xl: 4 },
                    component: {
                        type: COMPONENT_TYPE.SELECT,
                        itemProps: {
                            name: location.name,
                            label: location.label,
                            rules: [{ required: false, message: ERROR_MESSAGE.SELECT(location.label) }],
                        },
                        componentProps: {
                            style: { width: "100%" },
                            placeholder: ERROR_MESSAGE.SELECT(location.label),
                            disabled: isDisable[location.name] || false,
                        },
                        fnChange: (value) => handleChange(location.name, value),
                        dropdown: options[location.name],
                    },
                },
                {
                    breakpoint: { xs: 24, sm: 24, md: 12, lg: 12, xl: 8 },
                    component: {
                        type: COMPONENT_TYPE.SELECT,
                        itemProps: {
                            name: depNo.name,
                            label: depNo.label,
                            rules: [{ required: false, message: ERROR_MESSAGE.SELECT(depNo.label) }],
                        },
                        componentProps: {
                            style: { width: "100%" },
                            placeholder: ERROR_MESSAGE.SELECT(depNo.label),
                            disabled: isDisable[depNo.name] || false,
                        },
                        fnChange: (value) => handleChange(depNo.name, value),
                        dropdown: options[depNo.name],
                    },
                },
                {
                    breakpoint: { xs: 24, sm: 24, md: 12, lg: 12, xl: 8 },
                    component: {
                        type: COMPONENT_TYPE.SELECT,
                        itemProps: {
                            name: subDepNo.name,
                            label: subDepNo.label,
                            rules: [{ required: false, message: ERROR_MESSAGE.SELECT(subDepNo.label) }],
                        },
                        componentProps: {
                            style: { width: "100%" },
                            placeholder: ERROR_MESSAGE.SELECT(subDepNo.label),
                            disabled: isDisable[subDepNo.name] || false,
                        },
                        fnChange: (value) => handleChange(subDepNo.name, value),
                        dropdown: options[subDepNo.name],
                    },
                },
                {
                    breakpoint: { xs: 24, sm: 24, md: 12, lg: 12, xl: 8 },
                    component: {
                        type: COMPONENT_TYPE.SELECT,
                        itemProps: {
                            name: searchUser.name,
                            label: searchUser.label_2,
                            rules: [{ required: false, message: ERROR_MESSAGE.SELECT(searchUser.label_2) }],
                        },
                        componentProps: {
                            style: { width: "100%" },
                            placeholder: ERROR_MESSAGE.SELECT(searchUser.label_2),
                            disabled: isDisable[searchUser.name] || false,
                        },
                        fnChange: (value) => handleChange(searchUser.name, value),
                        dropdown: options[searchUser.name],
                    },
                },
                {
                    breakpoint: { xs: 24, sm: 24, md: 12, lg: 6, xl: 4 },
                    component: {
                        type: COMPONENT_TYPE.DATE,
                        itemProps: {
                            name: startDate.name,
                            label: startDate.label,
                            rules: [{ required: false, message: ERROR_MESSAGE.DATEPICKER }],
                        },
                        componentProps: {
                            style: { width: "100%" },
                            placeholder: ERROR_MESSAGE.DATEPICKER,
                            disabled: isDisable[startDate.name] || false,
                        },
                        fnChange: (date, dateString) => handleChange(startDate.name, dateString),
                        dropdown: options[startDate.name],
                    },
                },
                {
                    breakpoint: { xs: 24, sm: 24, md: 12, lg: 6, xl: 4 },
                    component: {
                        type: COMPONENT_TYPE.DATE,
                        itemProps: {
                            name: toDate.name,
                            label: toDate.label,
                            rules: [{ required: false, message: ERROR_MESSAGE.DATEPICKER }],
                        },
                        componentProps: {
                            style: { width: "100%" },
                            placeholder: ERROR_MESSAGE.DATEPICKER,
                            disabled: isDisable[toDate.name] || false,
                        },
                        fnChange: (date, dateString) => handleChange(toDate.name, dateString),
                        dropdown: options[toDate.name],
                    },
                },
            ], // column
        }, // Row
    ]
}