import React, { Fragment, useState, useContext } from 'react'
import { LayoutSearch } from '../../common/layouts'
import { MainInput, TYPE } from '../../common/inputs'
import { DynamicField } from '../../common/inputs/dynamicField';
import { Col, Row } from 'antd';
import { BaseText } from '../../common/text';
import { colors } from '../../../themes';
import { EvaluationContext } from '../../services/manage/evaluationProvider';
import { dynamicLabel, renderWithLabel } from '../../common/functions';
import { checkArrayEmpty, checkDisabled, checkDisbled, renderMonth } from '../../../constants/functions';
import { IndicatorContext } from '../../services/manage/indicatorProvider';

export const InsertScoreFactory = ({ approval, monthYear, month, disabled }) => {
    const { userLogin, } = useContext(IndicatorContext)
    const { evaluationState, onChangeValue } = useContext(EvaluationContext)
    // console.log(evaluationState.indicatorFac)
    return (
        <Fragment>
            <Row >
                <Col span={10} style={{ padding: '5px 0px' }} >
                    <BaseText size={14} label={`รหัสพนักงาน : ${evaluationState.ID_User}`} />
                </Col>
                <Col span={14} style={{ padding: '5px 0px' }} >
                    <BaseText size={14} label={`ชื่อ - นามสกุล : ${evaluationState.Name}`} />
                </Col>
                <Col span={10} style={{ padding: '5px 0px' }}>
                    <BaseText size={14} label={`รอบการประเมิน :  ${monthYear}`} />
                </Col>
                {(evaluationState.statusNameEvaulation) && <Col span={14} >
                    <BaseText size={14} style={{ padding: '5px 0px' }} label={`สถานะ : ${evaluationState.statusNameEvaulation}`} />
                </Col>}
            </Row>

            <DynamicField
                header={
                    <div>
                        <div style={{ paddingRight: 10, paddingTop: 20, fontSize: 14, fontWeight: 600 }}>{"หัวข้อตัววัดผลงาน "} </div>
                        {/* <BaseText size={9.5} color={colors.error} label={"1 = ต่ำกว่าความคาดหวังมาก, 2 = ค่ำกว่าความคาดหวัง, 3 = ตามความคาดหวัง, 4 = สูงกว่าความคาดหวัง, 5 = สูงกว่าความคาดหวังมาก "} /> */}

                    </div>
                }
                buttonText={<BaseText icon={'+'} label={"งานที่ชี้วัด"} />}
                validateField={['score']}
                onChange={(value, index) => onChangeValue({ fieldname: 'indicatorFac', value }, index)}
                contentLayouts={[
                    { label: 'งานที่ชี้วัด', span: 8, type: TYPE.INPUT, fieldname: 'indicatorDes', disabled: true },
                    { label: 'ตัววัดผลงาน', span: 5, type: TYPE.INPUT_RENDER, fieldname: 'indicatorGroup', disabled: true },
                    { label: 'เกณฑ์', span: 4, type: TYPE.SCORE, fieldname: 'weight', tooltip: 'indicatorGroup' },
                    { label: 'คะแนน', span: 4, type: TYPE.SCORE, fieldname: 'rawScore', fieldgroup: 'indicatorFac' },
                    { label: '', span: 3, type: TYPE.HELPER, fieldname: 'scoreCriteria', title: 'เกณฑ์การประเมิน' }
                ]}
                structureContent={{ indicatorNo: "", indicatorLine: "", weight: "", rawScore: "", score: "", createBy: userLogin }}
                initialField={evaluationState.indicatorFac}
            />
            {
                (approval) &&
                <MainInput
                    disabled={checkDisbled(evaluationState.indicatorFac, 'rawScore') ? true : false}
                    // value={evaluationState[`status${month}`]}
                    type={TYPE.CHECKBOX}
                    inputProps={{
                        checked: evaluationState.statusApproval,
                        // value: 3
                    }}

                    inputItems={{ label: 'ยืนยันการอนุมัติ (กรณียืนยันแล้ว จะไม่สามารถกลับมาแก้ไขได้)' }}
                    onChange={(e) => {
                        // console.log(evaluationState[`status${month}`], e.target.value, e)
                        onChangeValue({ fieldname: 'statusApproval', value: e.target.checked })
                        // onChangeValue({ fieldname: `status${month}`, value: (e.target.checked) ? e.target.value : 2 })
                    }}
                />
            }
            <div style={{ padding: 5, color: colors.error, fontSize: 10 }}>
                <div>{"1 = ต่ำกว่าความคาดหวังมาก, 2 = ค่ำกว่าความคาดหวัง, 3 = ตามความคาดหวัง,"} </div>
                <div>{"4 = สูงกว่าความคาดหวัง, 5 = สูงกว่าความคาดหวังมาก"} </div>
            </div>

        </Fragment >
    )
};
