import React from "react";
import { path } from "ramda";
import "bootstrap/dist/css/bootstrap.css";
import { Col, Row, } from "reactstrap";
import BaseText from "../../constants/text/BaseText";
import { TextInput, SelectInput } from "../../constants/inputs";
import RadioGroup from "../../constants/inputs/RadioGroup";
import { Input, Radio, Select, DatePicker } from 'antd';
import { MdCreate, MdDelete } from 'react-icons/md';
import * as Button from "../../constants/buttons";
import * as Layouts from "../../constants/layouts";
import { SetDate } from "../../common/setDate";
import { colors } from "../../themes";
import { KEY_TYPE } from "../../constants/enums/enumCenter";
import moment from "moment";

const FactorPanelContract = ({ disabled, ...rest }) => {
	return !disabled && <Layouts.MainTheme component={renderContentFactor({ ...rest })} />
};

const renderContentFactor = (props) => {
	let { multiple, onAddItem } = props;
	// console.log(props)
	switch (multiple) {
		case 'true':
			return <React.Fragment>
				<Row>
					<Col md={5} >
						<div style={{ display: 'flex', alignItems: 'center' }}>
							<BaseText label={props.label} style={{ padding: "0px 25px 10px 0px" }} />
							<Button.ButtonIcon icon={<MdCreate />} label={props.labelButton} onClick={() => onAddItem(props.mainRound)} />
						</div>
					</Col>
					<Col md={7} >
						{renderHeader(props)}
					</Col>
				</Row>
				{renderAssessment(props)}
			</React.Fragment>

		case 'false':
			return (
				<Row style={{ display: 'flex', alignItems: 'center' }}>
					<Col md={5} >
						<BaseText label={props.label} style={{ padding: "0px 25px 0px 0px" }} />
					</Col>
					<Col md={7}>
						{renderAssessment(props)}
					</Col>
				</Row>
			)

		case 'none':
			return (
				<Row style={{ display: 'flex', alignItems: 'center' }}>
					<Col md={7} >
						<div style={{ display: 'flex', alignItems: 'center' }}>
							<BaseText label={props.label} style={{ padding: "0px 25px 10px 0px" }} />
						</div>
					</Col>
				</Row>
			)
		default:
			return renderContentInput(props)
	}
}


const renderAssessment = (props) => {
	let { mainRound } = props;
	let ListRound = path(["initialData", `${"factor_"}${mainRound}`], props) || []
	return ListRound.map((roundData, indexRound) => {
		return (renderRound(mainRound, roundData, indexRound, props))
	})

}

const renderRound = (mainRound, roundValue, indexRound, props) => {
	if (+mainRound === 1) {
		return (
			<Row key={indexRound}>
				<Col md={5} >
					<div style={{ display: 'flex', alignItems: 'center' }}>
						<BaseText label={`${mainRound}${'.'}${indexRound + 1}`} margin={'0px 10px 20px 0px'} />
						<Input.TextArea
							style={{ width: '100%' }}
							autoSize={{ minRows: 1, maxRows: 6 }}
							value={path(["name"], roundValue) || undefined}
							onChange={(e) => props.updateFactor('name', e.target.value, indexRound, mainRound)}
						/>

					</div>
				</Col>
				<Col md={7}>
					{renderInput(mainRound, roundValue, indexRound, props)}
				</Col>
			</Row>
		)
	} else {
		return renderInput(mainRound, roundValue, indexRound, props)
	}

	// switch (mainRound) {
	// 	// [Add Mulitiple ] If want to change form to mulitiple >> You must add "case mainRound" to this line. Example : if mainRound is 2 write is case 2:
	// 	case 1: {
	// 		return (
	// 			<Row key={indexRound}>
	// 				<Col md={5} >
	// 					<div style={{ display: 'flex', alignItems: 'center' }}>
	// 						<BaseText label={`${mainRound}${'.'}${indexRound + 1}`} margin={'0px 10px 20px 0px'} />
	// 						<Input.TextArea
	// 							// disabled={!!initialData.DocNo && true}
	// 							style={{ width: '100%' }}
	// 							autoSize={{ minRows: 1, maxRows: 6 }}
	// 							value={path(["name"], roundValue) || undefined}
	// 							onChange={(e) => props.updateFactor('name', e.target.value, indexRound, mainRound)}
	// 						/>

	// 					</div>
	// 				</Col>
	// 				<Col md={7}>
	// 					{renderInput(mainRound, roundValue, indexRound, props)}
	// 				</Col>
	// 			</Row>
	// 		)
	// 	}
	// 	default: {
	// 		return renderInput(mainRound, roundValue, indexRound, props)
	// 	}
	// }
}

const valueFactor = (RoundNow, roundValue, indexRound, { col }) => {
	// console.log('valueFactor', roundValue)
	let result = null;
	if (col === RoundNow) {    //action
		if (RoundNow >= 1 && RoundNow <= 6) {
			result = Array.isArray(roundValue.score) ? path(["score", RoundNow - 1], roundValue) : path(["score"], roundValue)
		}
	} else {      //display
		result = path(["score", col - 1], roundValue)
	}
	return result

}

const renderInput = (mainRound, roundValue, indexRound, props) => {
	// console.log('roundValue', roundValue)
	const { initialData, average } = props
	const { RoundNow } = initialData;
	let isFinal = RoundNow === 4
	let isExtra = RoundNow > 4
	// let hasRemove = ((indexRound > 0) && (DocNo === null));
	let hasRemove = indexRound > 0 && initialData[`factor_${mainRound}`].length > 0 && initialData[`factor_${mainRound}`][indexRound].isAddedOnWeb;
	// console.log('initialData', initialData)
	// console.log('average', average)
	let arrAssetmentCondition = [
		true,
		true,
		true,
		(isFinal || isExtra),
		isExtra && (RoundNow == 5 || RoundNow == 6) || RoundNow > 6,
		isExtra && RoundNow == 6 || RoundNow > 6
	]
	return (
		<div style={{ display: `${RoundNow < 6 ? '' : 'flex'}` }}>
			<Row key={indexRound}>
				{
					arrAssetmentCondition.map((condition, i) =>
						condition &&
						<Col md={2}  >
							{(average && (initialData.Location == 'FAC' || initialData.workplace === 'FAC')) ?
								<div style={{ width: '12vh', padding: '0 4vw' }}><BaseText label={parseFloat(initialData[`average_${i + 1}`] || 0).toFixed(2)} /></div>
								:
								<Input
									type="number"
									disabled={RoundNow !== i + 1 || RoundNow > 6}
									onChange={(e) => {
										let calAvg = {
											fieldname: `average_${RoundNow}`,
											update: false,
											indexScore: RoundNow - 1
										}
										props.updateFactor('score', e.target.value, indexRound, mainRound, calAvg)
										// props.calculateAvg(`average_${RoundNow}`)
									}}
									value={valueFactor(RoundNow, roundValue, indexRound, { col: i + 1 }) || undefined}
									maxLength={1}
								/>}
						</Col>
					)
				}
				{
					RoundNow < 6 &&
					<Col md={2}>
						{
							(hasRemove && indexRound) &&
							<Button.ButtonIcon key={`rev${mainRound}_${indexRound}`} icon={<MdDelete />} label={"ลบ"} color={colors.buttonEdit} onClick={() => props.onRemoveItem(mainRound, indexRound,
								(initialData.Location == 'FAC' || initialData.workplace === 'FAC') ? {
									fieldname: `average_${RoundNow}`,
									update: false,
									indexScore: RoundNow - 1
								} : '')} />
						}
					</Col>
				}
			</Row>
			{
				RoundNow == 6 && (hasRemove && indexRound) &&
				<div style={{ marginLeft: '5px', width: '0' }}>
					< Button.ButtonIcon key={`rev${mainRound}_${indexRound}`} icon={<MdDelete />} label={"ลบ"} color={colors.buttonEdit} onClick={() => props.onRemoveItem(mainRound, indexRound, (initialData.Location == 'FAC' || initialData.workplace === 'FAC') ? {
						fieldname: `average_${RoundNow}`,
						update: false,
						indexScore: RoundNow - 1
					} : '')} />
				</div>
			}
		</div>
	)
}

const renderContentInput = (props) => {
	const { initialData } = props
	switch (props.comp) {
		case "TextArea":
			return (
				<React.Fragment>
					<BaseText label={`${props.label} :`} style={{ padding: "0px 25px 10px 0px" }} />
					<TextInput
						rows={props.row}
						name={props.name}
						type={"textarea"}
						value={props.value}
						onChange={props.onChange}
					/>
				</React.Fragment>
			);
		case "RadioGroup":
			return (
				<React.Fragment>
					<BaseText label={`${props.label} :`} style={{ padding: "0px 25px 10px 0px" }} />
					{

						<RadioGroup
							labelOther={'* โปรดระบุ...'}
							name={props.name}
							valueOther={props.valueOther}
							nameOther={props.nameOther}
							other={props.value === "3" ? true : false}
							options={props.options}
							value={props.value}
							onChangeOther={props.onChangeOther}
							onChange={props.onChange}
						/>
					}
				</React.Fragment>
			)
		case KEY_TYPE.RADIO:
			return (
				<React.Fragment>
					<BaseText label={`${props.label} :`} style={{ padding: "0px 25px 10px 0px" }} />
					<Radio.Group onChange={(e) => props.onChange(props.name, e.target.value)} value={props.value}>
						{
							Array.isArray(props.options) && props.options.map((option, optionIndex) => {
								return <Radio key={optionIndex}
									style={{ fontSize: 14 }}
									value={option.optionValue}>{option.optionLabel}</Radio>

							})
						}
					</Radio.Group>
				</React.Fragment>
			)
		case "SelectInput":
			return (
				<React.Fragment>
					<BaseText label={`${props.label} :`} style={{ padding: "0px 25px 10px 0px" }} />
					{

						<SelectInput
							name={props.name}
							onChange={props.onChange}
							options={props.options}
							value={props.value}
							valueField={props.valueField}
						/>
					}
				</React.Fragment>
			)
		case KEY_TYPE.SELECT:
			return (
				<Row style={{ display: 'flex', alignItems: 'center', padding: "0px 15px" }}>
					<div style={{ width: 200 }}>
						<BaseText label={`${props.label} :`} style={{ padding: "0px 25px 10px 0px" }} />
						{/* {baseLabel(KEY_TYPE.TITLE, { title: props.label }, props.required)} */}
					</div>
					<div style={{ width: '100%' }}>

						{
							<Select
								showSearch
								defaultValue={props.value || null}
								disabled={(initialData.probationStatus === '2' && props.name === 'contractExtension') ? true : false}
								style={{ width: '100%' }}
								optionFilterProp="children"
								onChange={(value) => props.onChange(props.name, value)}
								filterOption={(input, option) =>
									option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
								}
							>
								{
									Array.isArray(props.options) && props.options.map((option, optionIndex) => {
										return (
											<Select.Option key={optionIndex} value={option.optionValue}>
												{option.optionLabel}
											</Select.Option>
										)
									})
								}
							</Select>
							// <SelectInput
							//   name={props.name}
							//   onChange={props.onChange}
							//   options={props.options}
							//   value={props.value}
							//   valueField={props.valueField}
							// />
						}
					</div>
				</Row>
			)
		case 'probationStatus':
			return (
				//TODO ปรับเงื่อนไข RoundNow > 1 เป็น RoundNow >= 1
				(initialData.RoundNow >= 1) &&
				<React.Fragment>
					<BaseText label={`${props.label} :`} style={{ padding: "0px 25px 10px 0px" }} />
					{

						<RadioGroup
							labelOther={'* โปรดระบุ...'}
							name={props.name}
							valueOther={props.valueOther}
							nameOther={props.nameOther}
							other={props.value === "3" ? true : false}
							options={props.options}
							value={props.value}
							onChangeOther={props.onChangeOther}
							onChange={props.onChange}
						/>
					}
				</React.Fragment>
			)
		case 'probationGrade':
			//TODO ปรับเงื่อนไข RoundNow > 1 เป็น RoundNow >= 1
			return (
				(initialData.RoundNow >= 1 && (initialData.probationStatus === '1' || initialData.probationStatus === '2')) &&
				<Row style={{ display: 'flex', alignItems: 'center', padding: "0px 15px" }}>
					<div style={{ width: 200 }}>
						<BaseText label={`${props.label} :`} style={{ padding: "0px 25px 10px 0px" }} />
						{/* {baseLabel(KEY_TYPE.TITLE, { title: props.label }, props.required)} */}
					</div>
					<div style={{ width: '40%' }}>

						{
							<Select
								showSearch
								defaultValue={props.value || null}
								disabled={props.disabled}
								style={{ width: '100%' }}
								optionFilterProp="children"
								onChange={(value) => props.onChange(props.name, value)}
								filterOption={(input, option) =>
									option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
								}
							>
								{
									Array.isArray(props.options) && props.options.map((option, optionIndex) => {
										return (
											<Select.Option key={optionIndex} value={option.optionValue}>
												{option.optionLabel}
											</Select.Option>
										)
									})
								}
							</Select>
							// <SelectInput
							//   name={props.name}
							//   onChange={props.onChange}
							//   options={props.options}
							//   value={props.value}
							//   valueField={props.valueField}
							// />
						}
					</div>
				</Row >
			)
		case 'contractExtension':
			// console.log('contractExtension', initialData)
			//TODO ปรับเงื่อนไข RoundNow > 1 เป็น RoundNow >= 1
			return (
				(initialData.RoundNow >= 1 && initialData.probationStatus === '1') &&
				<Row style={{ display: 'flex', alignItems: 'center', padding: "0px 15px" }}>
					<div style={{ width: 200 }}>
						<BaseText label={`${props.label} :`} style={{ padding: "0px 25px 10px 0px" }} />
						{/* {baseLabel(KEY_TYPE.TITLE, { title: props.label }, props.required)} */}
					</div>
					<div style={{ width: '40%' }}>

						{
							<Select
								showSearch
								defaultValue={props.value || null}
								disabled={props.disabled}
								style={{ width: '100%' }}
								optionFilterProp="children"
								onChange={(value) => props.onChange(props.name, value)}
								filterOption={(input, option) =>
									option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
								}
							>
								{
									Array.isArray(props.options) && props.options.map((option, optionIndex) => {
										return (
											<Select.Option key={optionIndex} value={option.optionValue}>
												{option.optionLabel}
											</Select.Option>
										)
									})
								}
							</Select>
							// <SelectInput
							//   name={props.name}
							//   onChange={props.onChange}
							//   options={props.options}
							//   value={props.value}
							//   valueField={props.valueField}
							// />
						}
					</div>
				</Row >
			)
		case 'probationEndDate': return (
			//TODO ปรับเงื่อนไข RoundNow > 1 เป็น RoundNow >= 1
			(initialData.RoundNow >= 1 && initialData.probationStatus === '1') &&
			<Row style={{ padding: "0px 15px" }}>
				<div style={{ width: 310 }}>
					<BaseText label={`${props.label} :`} style={{ padding: "0px 25px 10px 0px" }} />
				</div>
				<DatePicker
					{...props}
					style={{ width: 200 }}
					name={props.name}
					onChange={(value) => props.onChange(props.name, value)}
					defaultValue={''}
					value={props.value ? moment(props.value) : null}
				/>
			</Row>

		)
		case 'text': return (<div><BaseText label={`${props.label} :`} style={{ padding: "0px 25px 10px 0px" }} /><BaseText label={`${props.value}`} style={{ padding: "0px 25px 10px 0px" }} /></div>)
	}
};


const renderHeader = ({ initialData }) => {
	const { RoundNow } = initialData;
	let isFinal = RoundNow === 4;
	let isExtra = RoundNow > 4
	let arrAssetmentCondition = [
		true,
		true,
		true,
		(isFinal || isExtra),
		isExtra && (RoundNow == 5 || RoundNow == 6) || RoundNow > 6,
		isExtra && RoundNow == 6 || RoundNow > 6,
	]
	return (
		<Row style={{ paddingBottom: 15, fontSize: 12 }}>
			{
				arrAssetmentCondition.map((condition, i) =>
					condition &&
					<Col md={2}  >
						<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
							<div>ประเมินครั้งที่ {i + 1}</div>
							<div>{SetDate(initialData[`RoundDate_${i + 1}`])}</div>
						</div>
					</Col>
				)
			}
		</Row>
	)
}




export default FactorPanelContract;
