import React, { useState, useEffect } from "react";
import { Spin, Form, Space, Card } from 'antd';
import { configForm } from './constants/ConstantComponent';
import { useButton } from './conponents/buttons/CustomButtonAntd';
import { useRenderFormItem } from './conponents/RenderComponents';
import { ComponentInsertNewSaleEmployee } from "./constants/InsertNewSaleEmployee/Components";
import { sumColumnWidth } from "./functions/UseFunction";
import { ColumnInsertNewSaleEmployee } from "./constants/InsertNewSaleEmployee/Columns";
import CustomTableAtnd from './conponents/tables/CustomTableAtnd';
import { GET_NEW_SALE_EMPLOYEE, POST, INSERT_UPDATE_SALE_EMPLOYEE, GET, GET_SALE_CODE_OPTION, GET_PERMISSION_USER_COMMISSION } from "../service";
import { useOptionSelect } from "./constants/OptionSelect";
import { optionInstallmentSaleStatus } from '../common/enum'
import { SetStringToObject } from "../constants/functions";
import Cookies from "js-cookie";
import encodeString from "../extendFunction/encodeString";
import { getPeriodDate } from "../functions";
import { useAlert } from "./conponents/modal/CustomAlert";
import { GET_INCOME_GROUP } from './../service/index';
import { useNotification } from "./conponents/notification/NotificationWithIcon";
import { decryptString, encryptString } from "../service/functions/encodeFunction";
import { browserHistory } from "react-router";

const InsertNewSaleEmployee = () => {
	const [form] = Form.useForm();
	const [dataSource, setDataSource] = useState([])
	const [optionIncomeGroup, setOptionIncomeGroup] = useState([])
	const [optionSaleCode, setOptionSaleCode] = useState([])
	const [rowsEdit, setRowsEdit] = useState([])
	const [loading, setLoading] = useState(false)
	const [isDisable, setIsDisable] = useState(true)
	const [permissionUser, setPermissionUser] = useState([]);
	const cookies = SetStringToObject(Cookies.get("cookie_session"));

	useEffect(() => {
		fetchApi()
	}, [])

	const fetchApi = async () => {
		async function fetchOptions() {
			await Promise.all([
				getIncomeGroup(),
				getSaleCodeOption(),
				getPermissionUser()
			])
		}
		await fetchOptions()
	}

	useEffect(() => {
	}, [dataSource])

	useEffect(() => {
		if (permissionUser.length > 0) {
			onFinish({})
		}
	}, [permissionUser])



	let optionSearch = {
		// depNo: useOptionSelect("department"),
		depNo: permissionUser.map(el => { return { txt: `[${el.departmentNo}]: ${el.departmentName}`, value: el.departmentNo } }),
		optionInstallmentSaleStatus: optionInstallmentSaleStatus.map(el => { return { txt: el.label, value: el.value } }),
		optionRemarkGuarnatee: [
			{
				txt: 'พนักงานใหม่',
				value: 'พนักงานใหม่'
			},
			{
				txt: 'เปลี่ยนแปลงพื้นที่/ลูกค้า',
				value: 'เปลี่ยนแปลงพื้นที่/ลูกค้า'
			},
			{
				txt: 'เปลี่ยนแปลงหน้างาน',
				value: 'เปลี่ยนแปลงหน้างาน'
			},
			{
				txt: 'อื่นๆ',
				value: 'อื่นๆ'
			},
		]
	}

	const getPermissionUser = async () => {
		try {
			setLoading(true);
			const response = await POST(GET_PERMISSION_USER_COMMISSION, { userLogin: encodeString.decode(cookies.ID_User) });
			const { result } = response;
			if (result.length == 0) {
				browserHistory.push('/')
			} else {
				setPermissionUser(result)
			}
		} catch (err) {
			useAlert({ type: "catch", content: err.message })
			console.log("Error getPermissionUser: ", err);
			browserHistory.push('/')
		} finally {
			setLoading(false);
		}
	}

	const getNewSaleEmployee = async (objectBody) => {
		try {
			setLoading(true)
			const response = await POST(GET_NEW_SALE_EMPLOYEE, objectBody);
			const { result, success } = response
			if (success) {
				let mapResult = []
				if (result.length !== 0) {
					mapResult = result.map((row, index) => ({
						...row,
						key: `${row.ID_User}_${index}`,
						SaleCode: row.saleCodeCommline || row.saleCodeDplus,
						statusInstallmentName: renderLabelStatusInstallment(row.statusWorkerInstallment),
						commissionGuarantee: parseFloat(decryptString(row.commissionGuarantee)) || '',
						incentiveGuarantee: parseFloat(decryptString(row.incentiveGuarantee)) || '',
					}));

				} else {
					useNotification({ type: "info", title: "ไม่พบข้อมูล" });
				}
				setDataSource(mapResult)
			}
		} catch (err) {
			useAlert({ type: "catch", content: err.message })
		} finally {
			setLoading(false)
		}
	}

	const renderLabelStatusInstallment = (statusWorkerInstallment) => {
		if (statusWorkerInstallment) {
			return optionInstallmentSaleStatus[optionInstallmentSaleStatus.findIndex(el => (el.value).split(' | ')[0] == statusWorkerInstallment)].label
		}
	}

	const getIncomeGroup = async () => {
		try {
			setLoading(true)
			const response = await GET(GET_INCOME_GROUP);
			const { result, success } = response
			if (success) {
				console.log('result', result)
				let options = result.map(el => {
					return {
						txt: el.incomeGroupName,
						value: el.incomeGroupName
					}
				})
				setOptionIncomeGroup(options)
			}
		} catch (err) {
			useAlert({ type: "catch", content: err.message })
		} finally {
			setLoading(false)
		}
	}

	const getSaleCodeOption = async () => {
		try {
			setLoading(true)
			const response = await GET(GET_SALE_CODE_OPTION);
			const { result, success } = response
			if (success) {
				console.log('result', result)
				setOptionSaleCode(result)
			}
		} catch (err) {
			useAlert({ type: "catch", content: err.message })
		} finally {
			setLoading(false)
		}
	}

	const onFinish = (payload) => {
		console.log('payload', payload)
		let objectBody = {
			depNo: payload.depNo || permissionUser.map(val => val.departmentNo).join(','),
			periodDate: getPeriodDate('SQL'),
			// periodDate: '2022-01-21 - 2022-02-20',
			incomeGroup: payload.incomeGroup || "",
			startDateJob: payload.startDateJob ? payload.startDateJob.format("YYYY-MM-DD") : "",
			endDateJob: payload.endDateJob ? payload.endDateJob.format("YYYY-MM-DD") : "",
			statusWorkerInstallment: payload.statusInstallment && payload.statusInstallment.length > 0 ? payload.statusInstallment : optionInstallmentSaleStatus.map(el => el.value),
		}
		setRowsEdit([])
		getNewSaleEmployee(objectBody)
	}

	const onReset = () => {
		form.resetFields();
	};

	const onChangeRow = (e, rowNo, fieldName) => {
		let temp = [...dataSource]
		switch (fieldName) {
			case 'incomeGroupName':
			case 'SaleCode':
			case 'remarkGuarantee':
				// console.log('temp[rowNo - 1][fieldName]', e)
				temp[rowNo - 1][fieldName] = e
				break;
			default: temp[rowNo - 1][`${e.target.name.split(/(\d+)/)[0]}`] = e.target.value
		}
		setDataSource([])
		setDataSource(temp)
		setIsDisable(false)
		if (!rowsEdit.includes(rowNo - 1)) {
			let tempRowsEdit = [...rowsEdit]
			tempRowsEdit.push(rowNo - 1)
			setRowsEdit(tempRowsEdit)
		}
	}

	const onSave = async () => {
		try {
			setLoading(true)
			const cookies = SetStringToObject(Cookies.get("cookie_session"));
			let isValid = true
			let checkRemarkGuarantee = true
			dataSource.map(el => {
				if ((el.incentiveGuarantee || el.commissionGuarantee) && !el.remarkGuarantee) {
					checkRemarkGuarantee = false
				}
				if (!el.SaleCode || !el.incomeGroupName) {
					isValid = false
				} else {
					el.commissionGuarantee = el.commissionGuarantee && encryptString(`${el.commissionGuarantee}`)
					el.incentiveGuarantee = el.incentiveGuarantee && encryptString(`${el.incentiveGuarantee}`)
				}
			})
			if (!isValid) return useAlert({ type: "warning", content: 'กรุณากรอกรหัสเซลล์และกลุ่มรายได้ให้ครบถ้วน' })
			if (!checkRemarkGuarantee) return useAlert({ type: "warning", content: 'กรุณากรอกเหตุผลการันตี' })
			let obj = {
				arrSale: dataSource,
				userId: encodeString.decode(cookies.ID_User),
				periodDate: getPeriodDate('SQL')
				// periodDate: '2022-01-21 - 2022-02-20'
			}
			const response = await POST(INSERT_UPDATE_SALE_EMPLOYEE, obj);
			const { message, success } = response
			if (success) {
				setRowsEdit([])
				setIsDisable(true)
				useAlert({ type: "success", title: "ดำเนินการสำเร็จ", content: message, Fn: () => onFinish({}) })
			}
		} catch (err) {
			console.log('err', err)
			useAlert({ type: "catch", content: err.message })
		} finally {
			setLoading(false)
		}
	}

	const renderRowClass = (record) => {
		if (rowsEdit.length > 0) {
			if (rowsEdit.findIndex(el => (+el) + 1 == record.rowNo) != -1) {
				return 'row-edited'
			}
		}
		return ''
	}

	return (
		<Spin tip="กำลังโหลดข้อมูล ..." spinning={loading}>
			<Form form={form} onFinish={onFinish} {...configForm}>
				<Card
					title={`แก้ไขข้อมูลพนักงานเซลล์ (รอบวันที่ ${getPeriodDate('display')})`}
					style={{ marginBottom: 30 }}
					extra={
						<Space>
							{useButton({ call: "search" })}
							{useButton({
								call: "normal",
								fn: onReset,
								moreProps: { label: "ล้างข้อมูล" },
							})}
						</Space>
					}
				>
					{useRenderFormItem({
						constantComponent: ComponentInsertNewSaleEmployee({
							options: { ...optionSearch, optionIncomeGroup },
							handleChange: () => { },
						}),
					})}
				</Card>
			</Form>
			<Card>
				<CustomTableAtnd
					HeadTable={ColumnInsertNewSaleEmployee({ onChangeRow, dataSource, optionSaleCode, optionSearch })}
					DataTable={dataSource}
					moreProps={{
						pageSize: 100,
						rowClassName: renderRowClass,
						scroll: {
							x: sumColumnWidth(ColumnInsertNewSaleEmployee({ onChangeRow, dataSource, optionSaleCode, optionSearch })) || 2050,
							y: 500,
						},
						size: "small"
					}}
				/>
				<div style={{ textAlignLast: 'center', marginTop: '15px' }}>
					<Space>
						{useButton({ call: "save", moreProps: { label: "ยืนยันข้อมูลเพื่อใช้ในหน้าตรวจสอบ", onClick: () => onSave() } })}
					</Space>
				</div>
			</Card>
		</Spin>
	);
};

export default InsertNewSaleEmployee;
