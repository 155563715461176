import { COMPONENT_TYPE, ERROR_MESSAGE } from ".."

const DEFINE = {
	depNo: {
		label: "แผนก",
		name: "depNo",
	},
	startDate: {
		label: "วันที่เริ่มงาน",
		name: "startDate",
	},
	endDate: {
		label: "ถึงวันที่",
		name: "endDate",
	}
}

export const ComponentCutOffCommissionIncentive = ({
	options = {},
	isDisable = {},
	handleChange = () => null,
	isDisableInput
}) => {
	const { depNo, startDate, endDate } = DEFINE
	return [
		{ // Row
			rowProps: { justify: "start", align: "bottom" },
			column: [ // column
				{
					breakpoint: { xs: 24, sm: 24, md: 24, lg: 12, xl: 4 },
					component: {
						type: COMPONENT_TYPE.SELECT,
						itemProps: {
							name: depNo.name,
							label: depNo.label,
							rules: [{ required: false, message: ERROR_MESSAGE.SELECT(depNo.label) }],
						},
						componentProps: {
							style: { width: "100%" },
							placeholder: ERROR_MESSAGE.SELECT(depNo.label),
							disabled: isDisableInput,
							mode: "multiple"
						},
						fnChange: (value) => handleChange(depNo.name, value),
						dropdown: options[depNo.name],
					},
				},
				{
					breakpoint: { xs: 24, sm: 24, md: 24, lg: 12, xl: 4 },
					component: {
						type: COMPONENT_TYPE.DATE,
						itemProps: {
							name: startDate.name,
							label: startDate.label,
							rules: [{ required: false, message: ERROR_MESSAGE.DATEPICKER }],
						},
						componentProps: {
							style: { width: "100%" },
							placeholder: ERROR_MESSAGE.DATEPICKER,
							disabled: isDisableInput,
						},
						fnChange: (date, dateString) => handleChange(startDate.name, dateString),
					},
				},
				{
					breakpoint: { xs: 24, sm: 24, md: 24, lg: 12, xl: 4 },
					component: {
						type: COMPONENT_TYPE.DATE,
						itemProps: {
							name: endDate.name,
							label: endDate.label,
							rules: [{ required: false, message: ERROR_MESSAGE.DATEPICKER }],
						},
						componentProps: {
							style: { width: "100%" },
							placeholder: ERROR_MESSAGE.DATEPICKER,
							disabled: isDisableInput,
						},
						fnChange: (date, dateString) => handleChange(endDate.name, dateString),
					},
				},
			], // column
		}, // Row
	]
}

export const ComponentCutOffCommissionIncentiveDashboard = ({
	options = {},
	isDisable = {},
	handleChange = () => null,
	isDisableInput
}) => {
	const { depNo, startDate, endDate } = DEFINE
	return [
		{ // Row
			rowProps: { justify: "start", align: "bottom" },
			column: [ // column
				{
					breakpoint: { xs: 24, sm: 24, md: 24, lg: 12, xl: 4 },
					component: {
						type: COMPONENT_TYPE.DATE,
						itemProps: {
							name: startDate.name,
							label: startDate.label,
							rules: [{ required: false, message: ERROR_MESSAGE.DATEPICKER }],
						},
						componentProps: {
							style: { width: "100%" },
							placeholder: ERROR_MESSAGE.DATEPICKER,
							disabled: isDisableInput,
						},
						fnChange: (date, dateString) => handleChange(startDate.name, dateString),
					},
				},
				{
					breakpoint: { xs: 24, sm: 24, md: 24, lg: 12, xl: 4 },
					component: {
						type: COMPONENT_TYPE.DATE,
						itemProps: {
							name: endDate.name,
							label: endDate.label,
							rules: [{ required: false, message: ERROR_MESSAGE.DATEPICKER }],
						},
						componentProps: {
							style: { width: "100%" },
							placeholder: ERROR_MESSAGE.DATEPICKER,
							disabled: isDisableInput,
						},
						fnChange: (date, dateString) => handleChange(endDate.name, dateString),
					},
				},
			], // column
		}, // Row
	]
}