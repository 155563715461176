import React, { useState, useEffect } from "react";
import { Form, Input, Card, Row, Col, Button, Progress } from "antd";
import { PlusOutlined, CloseCircleOutlined } from "@ant-design/icons";

export const FormAddIndicatorList = ({
  ListName = "TEST_LIST",
  handleChangeList,
  DataIndicator,
}) => {
  const [IndicatorValue, setIndicatorValue] = useState(0);
  let SumWeight = 0;

  useEffect(() => {
    try {
      if (DataIndicator.length > 1) {
        SumWeight = DataIndicator.reduce(
          (a, b) => parseInt(a) + parseInt(b.weight),
          0
        );
      } else if (DataIndicator.length <= 1) {
        SumWeight = parseInt(DataIndicator[0].weight);
      }
      setIndicatorValue(SumWeight);
    } catch {
      setIndicatorValue(0);
    }
  }, [DataIndicator]);

  return (
    <Card title={`กำหนดตัวชี้วัด (${ListName})`} style={{ marginBottom: 30 }}>
      <Form.List name={ListName}>
        {(fields, { add, remove }) => (
          <>
            <Row gutter={[16, 16]} justify="start" align="bottom">
              <Col xs={24} sm={16} md={18} lg={20} xl={21}>
                <Form.Item label={`ภาพรวมสัดส่วน:`} style={{ width: "100%" }}>
                  <Progress
                    percent={IndicatorValue}
                    status={IndicatorValue > 100 ? `exception` : ``}
                  />
                </Form.Item>
              </Col>
              <Col xs={8} sm={8} md={6} lg={4} xl={3}>
                <Form.Item>
                  <Button
                    type="button"
                    onClick={() => add()}
                    style={{
                      width: "100%",
                      backgroundColor: "#5CB85C",
                      color: "#FFFFFF",
                    }}
                    icon={<PlusOutlined />}
                  >
                    เพิ่มตัวชี้วัด
                  </Button>
                </Form.Item>
              </Col>
            </Row>
            {fields.map((field) => (
              <Row
                key={field.key}
                gutter={[16, 16]}
                justify="start"
                align="top"
              >
                <Col xs={24} sm={24} md={24} lg={12} xl={9}>
                  <Form.Item
                    {...field}
                    label="กำหนดชื่อตัวชี้วัด"
                    name={[field.name, "indicatorDes"]}
                    fieldKey={[field.fieldKey, "indicatorDes"]}
                    rules={[
                      {
                        required: true,
                        message: "กรุณากรอก indicatorDes",
                      },
                    ]}
                    style={{ width: "100%" }}
                  >
                    <Input
                      onChange={(e) =>
                        handleChangeList(e.target.value, [
                          field.fieldKey,
                          "indicatorDes",
                        ])
                      }
                      allowClear
                      placeholder={`กรอก ชื่อตัวชี้วัด`}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={24} lg={12} xl={10}>
                  <Form.Item
                    {...field}
                    label="เกณฑ์"
                    name={[field.name, "scoreCriteria"]}
                    fieldKey={[field.fieldKey, "scoreCriteria"]}
                    rules={[
                      {
                        required: true,
                        message: "กรุณากรอก scoreCriteria",
                      },
                    ]}
                    style={{ width: "100%" }}
                  >
                    <Input.TextArea
                      onChange={(e) =>
                        handleChangeList(e.target.value, [
                          field.fieldKey,
                          "scoreCriteria",
                        ])
                      }
                      rows={3}
                      allowClear
                      placeholder={`กำหนด เกณฑ์ต่าง ๆ ลงในช่องนี้ (คลิกมุมด้านล่างเพื่อขยาย)`}
                    />
                  </Form.Item>
                </Col>
                <Col xs={16} sm={16} md={12} lg={12} xl={2}>
                  <Form.Item
                    {...field}
                    label="สัดส่วน"
                    name={[field.name, "weight"]}
                    fieldKey={[field.fieldKey, "weight"]}
                    rules={[{ required: true, message: "กรุณากรอก weight" }]}
                    style={{ width: "100%" }}
                    initialValue={0}
                  >
                    <Input
                      onChange={(e) => handleChangeList(e.target.value)}
                      allowClear
                    />
                  </Form.Item>
                </Col>
                <Col xs={8} sm={8} md={6} lg={4} xl={3}>
                  <Form.Item label={` `}>
                    <Button
                      type="danger"
                      icon={<CloseCircleOutlined />}
                      onClick={() => {
                        remove(field.name);
                        handleChangeList();
                      }}
                      style={{ width: "100%" }}
                    >
                      ลบ
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            ))}
          </>
        )}
      </Form.List>
    </Card>
  );
};

export default FormAddIndicatorList;
