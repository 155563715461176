import { COMPONENT_TYPE, ERROR_MESSAGE } from "..";
import * as XLSX from "xlsx";

const DEFINE = {
  file: {
    name: "file",
  },
  depNo: {
    label: "แผนก",
    name: "depNo",
  },
  startDateJob: {
    label: "วันที่เริ่มงาน",
    name: "startDateJob",
  },
  endDateJob: {
    label: "ถึงวันที่",
    name: "endDateJob",
  },
  statusInstallment: {
    label: "สถานะพนักงานตามงวด",
    name: "statusInstallment",
  },
};

export const ComponentInsertNewSaleEmployeeFSP = ({
  options = {},
  isDisable = {},
  handleChange = () => null,
}) => {
  const { depNo, startDateJob, endDateJob, statusInstallment } = DEFINE;
  return [
    {
      // Row
      rowProps: { justify: "start", align: "bottom" },
      column: [
        // column
        {
          breakpoint: { xs: 24, sm: 24, md: 24, lg: 12, xl: 4 },
          component: {
            type: COMPONENT_TYPE.SELECT,
            itemProps: {
              name: depNo.name,
              label: depNo.label,
              rules: [
                { required: false, message: ERROR_MESSAGE.SELECT(depNo.label) },
              ],
            },
            componentProps: {
              style: { width: "100%" },
              placeholder: ERROR_MESSAGE.SELECT(depNo.label),
              disabled: isDisable[depNo.name] || false,
            },
            fnChange: (value) => handleChange(depNo.name, value),
            dropdown: options.depNo,
          },
        },
        {
          breakpoint: { xs: 24, sm: 24, md: 24, lg: 12, xl: 4 },
          component: {
            type: COMPONENT_TYPE.DATE,
            itemProps: {
              name: startDateJob.name,
              label: startDateJob.label,
              rules: [{ required: false, message: ERROR_MESSAGE.DATEPICKER }],
            },
            componentProps: {
              style: { width: "100%" },
              placeholder: ERROR_MESSAGE.DATEPICKER,
              disabled: isDisable[startDateJob.name] || false,
            },
            fnChange: (date, dateString) =>
              handleChange(startDateJob.name, dateString),
          },
        },
        {
          breakpoint: { xs: 24, sm: 24, md: 24, lg: 12, xl: 4 },
          component: {
            type: COMPONENT_TYPE.DATE,
            itemProps: {
              name: endDateJob.name,
              label: endDateJob.label,
              rules: [{ required: false, message: ERROR_MESSAGE.DATEPICKER }],
            },
            componentProps: {
              style: { width: "100%" },
              placeholder: ERROR_MESSAGE.DATEPICKER,
              disabled: isDisable[endDateJob.name] || false,
            },
            fnChange: (date, dateString) =>
              handleChange(endDateJob.name, dateString),
          },
        },
        {
          breakpoint: { xs: 24, sm: 24, md: 24, lg: 12, xl: 4 },
          component: {
            type: COMPONENT_TYPE.SELECT,
            itemProps: {
              name: statusInstallment.name,
              label: statusInstallment.label,
              rules: [
                {
                  required: false,
                  message: ERROR_MESSAGE.SELECT(statusInstallment.label),
                },
              ],
            },
            componentProps: {
              style: { width: "100%" },
              mode: "multiple",
              placeholder: ERROR_MESSAGE.SELECT(statusInstallment.label),
              disabled: isDisable[statusInstallment.name] || false,
            },
            fnChange: (value) => handleChange(statusInstallment.name, value),
            dropdown: options.optionInstallmentSaleStatus,
          },
        },
      ], // column
    }, // Row
  ];
};

export const ComponentImportNewSaleEmployeeFSP = ({
  options = {},
  isDisable = {},
  handleChange = () => null,
  ...moreProps
}) => {
  const { file } = DEFINE;
  return [
    {
      rowProps: { justify: "start", align: "bottom" },
      column: [
        {
          breakpoint: { xs: 24, sm: 24, md: 24, lg: 24, xl: 12 },
          component: {
            type: "upload",
            itemProps: {
              name: file.name,
              label: file.label,
            },

            componentProps: {
              style: { width: "100%" },
              disabled: isDisable[file.name] || false,
              fileList: moreProps.fileList,
            },
            fnChange: handleChange,
          },
        },
      ],
    },
  ];
};

export const ExcelToJSON = (
  f,
  callback = (e) => console.log("no call back")
) => {
  const reader = new FileReader();
  return new Promise((resolve, reject) => {
    reader.onerror = () => {
      reader.abort();
      reject(new DOMException("Problem parsing input file."));
    };

    reader.onload = (event) => {
      const binaryString = event.target.result;
      const workbook = XLSX.read(binaryString, { type: "binary" });
      const data = workbook.SheetNames.map((e) => {
        const worksheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[worksheetName];
        const workSheetData = XLSX.utils.sheet_to_json(worksheet);
        return { sheetName: e, data: workSheetData };
      });
      resolve(data);
    };
    reader.readAsBinaryString(f);
  });
};

export const MapDataExcel = (data) => {
  const dataArray = data.map((e) => {
    const { 
      'รหัสเซลล์/สาขา': SaleCode, 
      'รหัสพนักงาน': ID_User, 
      'การันตี incentive (บาท)': incentiveGuarantee, 
      'เหตุผลการันตี': remarkGuarantee
    } = e;
    return {
      SaleCode: SaleCode || '',  
      ID_User: ID_User || '',  
      incentiveGuarantee: incentiveGuarantee || '',
      remarkGuarantee: remarkGuarantee || '',
    }
  })
  return dataArray
}
