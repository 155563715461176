import React from 'react'
import './index.css'
import { Button } from 'antd'

export const ButtonTheme = ({ buttonText, style, buttonStyle, disabled, onClick, icon, ...rest }) => {
    return (
        <div className="button-theme" style={style}>
            <Button
                icon={icon}
                onClick={onClick} style={disabled ? null : buttonStyle} disabled={disabled}{...rest}>
                <span className="button-text" > {buttonText || 'Text'} </span>
            </Button>
        </div >
    )
}
