import React, { useState } from "react";
import { Form, Card, Spin, Table, Space } from "antd";
import CustomTableAtnd from "../tables/CustomTableAtnd";
import { useNotification } from "../notification/NotificationWithIcon";
import { useButton } from "../buttons/CustomButtonAntd";
import { useExportExcel } from "../../services/ExportExcel";
import { Header_ReportLevelWorker } from "../../constants/HeaderExcel";
import { optionSelectLocation, optionStatusWorker, useOptionSelect } from "../../constants/OptionSelect";
import { useFixedQueryString, useMakeQueryString } from "../../functions/UseMakeQueryString";
import { GET_UserLogin, toUndefined } from "../../functions/UseFunction";
import { configForm, WORDS } from "../../constants/ConstantComponent";
import { useRenderFormItem } from "../RenderComponents";
import { ComponentReportLevelWorker } from "../../constants/Reports/Components/ComponentReports";
import { GET, GET_SELECT_REPORT_LEVEL_WORKER } from "../../../service";
import { useAlert } from "../modal/CustomAlert";
import { ColumnReportLevelWorker } from "../../constants/Reports/Columns/ColumnReports";

export const FormReportLevelWorker = () => {
  const [form] = Form.useForm();
  const { location: locationLogin } = GET_UserLogin();
  // [START] States
  const [ChangeValue, setChangeValue] = useState({ location: locationLogin === "FAC" ? "FAC" : undefined });
  const [dataTable, setDataTable] = useState([]);
  const [overAll, setOverAll] = useState({});
  const [loadingScreen, setLoadingScreen] = useState(false);

  let optionSearch = {
    companyCode: useOptionSelect("company"),
    location: optionSelectLocation,
    depNo: useOptionSelect("department", useFixedQueryString(ChangeValue, ["companyCode", "location"])),
    statusWorker: optionStatusWorker,
  }
  // [END] States

  // [START] Handle Form
  const onFinish = (values) => {
    const { companyCode, location, depNo, startYear, endYear, statusWorker } = values
    let objectBody = {
      companyCode: companyCode || "",
      location: location || "",
      depNo: depNo || "",
      startYear: startYear ? startYear.format("YYYY") : "",
      endYear: endYear ? endYear.format("YYYY") : "",
      statusWorker: statusWorker || "",
    }
    getReportLevelWorker(useMakeQueryString(objectBody));
  };

  const onReset = () => {
    form.resetFields();
    setDataTable([]);
    setChangeValue({ location: locationLogin === "FAC" ? "FAC" : undefined });
  };
  // [END] Handle Form

  // [START] REST API
  const getReportLevelWorker = async (query) => {
    try {
      setLoadingScreen(true);
      setDataTable([]);
      setOverAll({});
      const response = await GET(GET_SELECT_REPORT_LEVEL_WORKER(query));
      const { result } = response;
      const { report, overall } = result;
      let mapResult = [];
      let mapOverAll = {};
      if (report.length !== 0) {
        mapResult = report.map((row, index) => ({
          key: `${index}`,
          ...row,
        }));
        mapOverAll = overall
      } else {
        useNotification({ type: "info", title: "ไม่พบข้อมูล" });
      }
      setDataTable(mapResult);
      setOverAll(mapOverAll);
    } catch (err) {
      useAlert({ type: "catch", content: err.message })
      console.log("Error Search Report Level Worker: ", err);
    } finally {
      setLoadingScreen(false);
    }
  };
  // [END] REST API

  // [START] Functions
  const onChangeValue = (name, value) => {
    let setUndefined = {};
    switch (name) {
      case "companyCode":
        if (locationLogin === "FAC") {
          setUndefined = toUndefined(["depNo"]);
        } else {
          setUndefined = toUndefined(["location", "depNo"]);
        }
        break;
      case "location":
        setUndefined = toUndefined(["depNo"]);
        break;
      case "startYear":
        setUndefined = toUndefined(["endYear"]);
        break;
    }
    setDataTable([]);
    form.setFieldsValue(setUndefined);
    setChangeValue((prev) => ({
      ...prev,
      ...setUndefined,
      [name]: value,
    }));
  };
  // [END] Functions

  const renderSummaryRow = (obj) => {
    if (typeof obj !== "object") return null;
    let renderCells = [];
    for (const key in obj) {
      renderCells.push(
        <Table.Summary.Cell key={key} align="right">
          {obj[key] || "-"}
        </Table.Summary.Cell>
      );
    }
    return (
      <Table.Summary fixed>
        <Table.Summary.Row>
          <Table.Summary.Cell align="center" colSpan={2}>
            รวมทั้งสิ้น
          </Table.Summary.Cell>
          {renderCells}
        </Table.Summary.Row>
      </Table.Summary>
    );
  };

  return (
    <Spin tip={WORDS.loadingSpin} spinning={loadingScreen}>
      <Form form={form} onFinish={onFinish} {...configForm} initialValues={{ location: locationLogin === "FAC" ? "FAC" : undefined }} >
        <Card
          title="รายงานจำนวนพนักงานตามแผนก"
          style={{ marginBottom: 30 }}
          extra={
            <Space>
              {useButton({ call: "search" })}
              {useButton({
                call: "normal",
                fn: onReset,
                moreProps: { label: "ล้างข้อมูล" },
              })}
            </Space>
          }
        >
          {useRenderFormItem({
            constantComponent: ComponentReportLevelWorker({
              options: optionSearch,
              handleChange: onChangeValue,
              isDisable: {
                location: locationLogin === "FAC" || false,
              }
            }),
          })}
        </Card>
      </Form>
      {dataTable.length !== 0 && (
        <Card
          title="รายงานจำนวนพนักงานตามแผนก"
          style={{ marginBottom: 30 }}
          extra={
            useExportExcel({
              header: Header_ReportLevelWorker,
              dataset: dataTable,
              filename: "รายงานจำนวนพนักงานตามแผนก",
            })
          }
        >
          <CustomTableAtnd
            HeadTable={ColumnReportLevelWorker}
            DataTable={dataTable}
            moreProps={{
              scroll: {
                x: 1080,
                y: 500,
              },
              summary: () => renderSummaryRow(overAll),
            }}
          />
        </Card>
      )}
    </Spin>
  );
};

export default FormReportLevelWorker;
