import React from "react";
import { useController } from "react-hook-form";
import { RowComponent } from "../../../../styles/global-style";
import { TextSmall } from "../../text";
const { Fragment } = React;

export const TextLabel = ({ control, item }) => {
  const { name, defaultValue, label, placeholder } = item;
  const { field } = useController({
    control,
    name,
    defaultValue,
  });
  const { value } = field;

  return (
    <Fragment>
      <RowComponent>
        {label && <TextSmall text={label} key={name} />}
        {!label && <TextSmall text={"XXX"} key={name} style={{ visibility: "hidden" }}/>}
      </RowComponent>
      <TextSmall
        text={value || placeholder}
        style={{ /*color: `#585858`,*/ marginTop: "5px" }}
      />
    </Fragment>
  );
};
