import React, { useState } from "react";
import { Form, Card, Row, Col, Spin, Tabs } from "antd";
import { SelectAntd } from "../select/SelectAntd";
import { KPIs, USE_CONNECT_MODE_AUTO } from "../../services/ConnectAPI";
import {
  optionStatusWorker,
  useOptionSelect,
} from "../../constants/OptionSelect";
import { useButton } from "../buttons/CustomButtonAntd";
import FormAddIndicatorList from "./FormAddIndicatorList";
import { useFixedQueryString } from "../../functions/UseMakeQueryString";
import { GET_UserLogin, toUndefined } from "../../functions/UseFunction";
import { useAlert } from "../modal/CustomAlert";
import { useConfirm } from "../modal/CustomConfirm";

export const FormAddKPIs = () => {
  const [form] = Form.useForm();
  const { userlogin } = GET_UserLogin();

  const [changeSearchValue, setChangeSearchValue] = useState({});

  const [VolumeData, setVolumeData] = useState([]);
  const [QualityData, setQualityData] = useState([]);
  const [BehaviorData, setBehaviorData] = useState([]);

  const [loadingScreen, setLoadingScreen] = useState(false);

  let queryPosition = useFixedQueryString(changeSearchValue, ["companyCode"]);

  let optionCompany = useOptionSelect("company");
  let optionPosition = useOptionSelect("position", queryPosition);

  // [START] Handle Form Antd
  const onFinish = (values) => {
    try {
      const { positionCode, statusWorker } = values;
      let MAP_Volume = VolumeData
        ? VolumeData.map((val) => ({ indicatorGroup: "volume", ...val }))
        : [];
      let MAP_Quality = QualityData
        ? QualityData.map((val) => ({ indicatorGroup: "quality", ...val }))
        : [];
      let MAP_Behavior = BehaviorData
        ? BehaviorData.map((val) => ({ indicatorGroup: "behavior", ...val }))
        : [];
      const indicators = [...MAP_Volume, ...MAP_Quality, ...MAP_Behavior];
      const objectValue = {
        positionCode,
        statusWorker,
        active: true,
        userlogin,
        indicators,
      };
      useConfirm({
        FnOK: () => POST_INSERT_KPIs(objectValue),
      });
    } catch (err) {
      console.log(err);
    }
  };

  const onReset = () => {
    setChangeSearchValue({});
    form.resetFields();
  };
  // [END] Handle Form Antd

  // [START] REST API
  const POST_INSERT_KPIs = async (value) => {
    try {
      setLoadingScreen(true);
      const url = `${USE_CONNECT_MODE_AUTO}${KPIs.POST_INSERT_KPIs}`;
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ ...value, userlogin }),
      });
      const data = await response.json();
      if (response.ok) {
        useAlert({
          type: "success",
          title: "บันทึกข้อมูลสำเร็จ",
          content: `รหัสตัวชี้วัดคือ: ${data.result.indicatorNo}`,
          Fn: () => window.location.reload(),
        });
      } else {
        useAlert({
          type: "error",
          title: "เกิดข้อผิดพลาด",
          content: `${data.message}`,
          Fn: () => setLoadingScreen(false),
        });
      }
    } catch (err) {
      console.log("Error Insert KPIs: ", err);
    }
  };
  // [END] REST API

  // [START] Function
  const onChangeValue = (val, name) => {
    let SET_undifined = {};
    switch (name) {
      case "companyCode":
        SET_undifined = toUndefined(["positionCode"]);
        break;
      default:
        SET_undifined = {};
        break;
    }
    form.setFieldsValue(SET_undifined);
    setChangeSearchValue((prev) => ({
      ...prev,
      ...SET_undifined,
      [name]: val,
    }));
  };

  const GET_VALUE_LIST = (value, name) => {
    const getVolume = form.getFieldValue("Volume");
    setVolumeData(getVolume);
    const getQuality = form.getFieldValue("Quality");
    setQualityData(getQuality);
    const getBehavior = form.getFieldValue("Behavior");
    setBehaviorData(getBehavior);
  };
  // [END] Function

  return (
    <>
      <Form
        form={form}
        onFinish={onFinish}
        labelCol={{
          span: 24,
        }}
        wrapperCol={{
          span: 24,
        }}
      >
        <Spin tip="Loading..." spinning={loadingScreen}>
          <Card title="เพิ่มข้อมูล KPIs" style={{ marginBottom: 30 }}>
            <Row gutter={[16, 16]} justify="start" align="bottom">
              <Col xs={24} sm={24} md={24} lg={6} xl={4}>
                <SelectAntd
                  name={`companyCode`}
                  label={`บริษัท`}
                  smgReq={`กรุณาเลือก บริษัท`}
                  placeholder={`เลือก บริษัท`}
                  dropdown={optionCompany}
                  FnOnChange={onChangeValue}
                  required={true}
                />
              </Col>
              <Col xs={24} sm={12} md={12} lg={8} xl={10}>
                <SelectAntd
                  name={`positionCode`}
                  label={`ตำแหน่งงาน`}
                  smgReq={`กรุณาเลือก ตำแหน่งงาน`}
                  placeholder={`เลือก ตำแหน่งงาน`}
                  dropdown={optionPosition}
                  FnOnChange={onChangeValue}
                  required={true}
                />
              </Col>
              <Col xs={24} sm={12} md={12} lg={8} xl={4}>
                <SelectAntd
                  name={`statusWorker`}
                  label={`สถานะพนักงาน`}
                  smgReq={`กรุณาเลือก สถานะพนักงาน`}
                  placeholder={`เลือก สถานะพนักงาน`}
                  dropdown={optionStatusWorker}
                  FnOnChange={onChangeValue}
                  required={true}
                  DefaultValue={"Full"}
                />
              </Col>
            </Row>
          </Card>
          <Tabs defaultActiveKey="1">
            <Tabs.TabPane tab="Volume" key="1">
              <FormAddIndicatorList
                ListName={`Volume`}
                handleChangeList={GET_VALUE_LIST}
                DataIndicator={VolumeData}
              />
            </Tabs.TabPane>
            <Tabs.TabPane tab="Quality" key="2">
              <FormAddIndicatorList
                ListName={`Quality`}
                handleChangeList={GET_VALUE_LIST}
                DataIndicator={QualityData}
              />
            </Tabs.TabPane>
            <Tabs.TabPane tab="Behavior" key="3">
              <FormAddIndicatorList
                ListName={`Behavior`}
                handleChangeList={GET_VALUE_LIST}
                DataIndicator={BehaviorData}
              />
            </Tabs.TabPane>
          </Tabs>
          <Row
            gutter={[16, 16]}
            style={{ marginTop: 20 }}
            justify="center"
            align="bottom"
          >
            <Col xs={12} sm={12} md={12} lg={6} xl={3}>
              <Form.Item>{useButton({ call: "save" })}</Form.Item>
            </Col>
            <Col xs={12} sm={12} md={12} lg={6} xl={3}>
              <Form.Item>
                {useButton({
                  call: "normal",
                  fn: onReset,
                  moreProps: { label: "ล้างข้อมูล" },
                })}
              </Form.Item>
            </Col>
          </Row>
        </Spin>
      </Form>
    </>
  );
};

export default FormAddKPIs;
