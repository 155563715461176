import React from "react";
import { Tabs } from "antd";
import FormSearchPosition from "./conponents/forms/FormSearchPosition";
import FormAddPosition from "./conponents/forms/FormAddPosition";

const ManagePosition = () => {
  const { TabPane } = Tabs;
  return (
    <Tabs defaultActiveKey="1">
      <TabPane tab="ดูข้อมูลตำแหน่งงาน" key="1">
        <FormSearchPosition />
      </TabPane>
      <TabPane tab="เพิ่มข้อมูลตำแหน่งงาน" key="2">
        <FormAddPosition />
      </TabPane>
    </Tabs>
  );
};

export default ManagePosition;
