import React from 'react'
import * as R from 'ramda'
import './index.css'

import { TYPE } from '../../common/inputs'
import { BaseText } from '../../common/text'
import { ReportKPIs } from "../../components/reportKPIs"
import { ReportBoss } from '../../components/reportBoss'
import { renderMonth } from '../../../constants/functions'
import { MASTER_OPTION } from "../../services/servicesOptions"
import { EvaluationForm } from "../../components/evaluationForm"
import EvaluationFactory from '../../components/evaluationFactory'
// import EvaluationFactoryByDay from '../../components/EvaluationFactoryByDay'
import moment from 'moment'

const breadcrumbLists = () => [
	{ label: "HRM - PMS" },
	{ label: "KPIs รายบุคคล" },
	{ label: "KPIs โรงงาน" }
]
const breadcrumbPrintLists = () => [
	{ label: "HRM - PMS" },
	{ label: "KPIs รายบุคคล" },
	{ label: "ใบปะหน้า KPIs โรงงาน" }
]

const tabsLists = (role) => {
	switch (role) {
		case 'HRD-FAC':
		case 'HRM-FAC':
		case 'HRS-FAC':
			return [
				{ tab: 'Evaluation From', content: <EvaluationFactory /> },
				{ tab: 'รายงานทั้งหมด', content: <EvaluationFactory view={true} /> } ///level 3
			]
		default:
			return [
				{ tab: 'Evaluation From', content: <EvaluationFactory /> },
			]
	}
}


const searchLists = (role, { year }) => {
	switch (role) {
		case 'HRD-FAC':
		case 'HRM-FAC':
		case 'HRS-FAC':
			return [
				{
					span: 4,
					type: TYPE.SELECT,
					header: "การประเมินประจำปี",
					fieldname: MASTER_OPTION.YEAR,
					fieldOption: `OPTION_${MASTER_OPTION.YEAR}`,

					selectAll: false,
					searchInput: false
				}, {
					span: 4,
					header: "ประเภทการจ้าง",
					fieldname: MASTER_OPTION.EMPLOYMENT_TYPE,
					fieldOption: `OPTION_${MASTER_OPTION.EMPLOYMENT_TYPE}`,
					selectAll: false,
					searchInput: false
				}, {
					span: 4,
					header: "แผนกย่อย",
					type: TYPE.SELECT,
					fieldname: MASTER_OPTION.SUBDEP_FACTORY,
					fieldOption: `OPTION_${MASTER_OPTION.SUBDEP_FACTORY}`,
					selectAll: true,
					searchInput: true
				}, {
					span: 6,
					header: "สิทธิการอนุมัติ",
					fieldname: MASTER_OPTION.FLOWHRM_FACTORY,
					fieldOption: `OPTION_${MASTER_OPTION.FLOWHRM_FACTORY}`,
					selectAll: true,
					searchInput: true
				}, {
					span: 6,
					header: "สิทธิการดู",
					fieldname: MASTER_OPTION.VIEW_FACTORY,
					fieldOption: `OPTION_${MASTER_OPTION.VIEW_FACTORY}`,
					selectAll: true,
					searchInput: true
				},
			]
		default:
			return [
				{
					span: 6,
					type: TYPE.SELECT,
					header: "การประเมินประจำปี",
					fieldname: MASTER_OPTION.YEAR,
					fieldOption: `OPTION_${MASTER_OPTION.YEAR}`,
					defaultValue: year,
					selectAll: false,
					searchInput: false
				}, {
					span: 6,

					header: "ประเภทการจ้าง",
					type: TYPE.SELECT_MULTIPLE,
					fieldname: MASTER_OPTION.EMPLOYMENT_TYPE,
					fieldOption: `OPTION_${MASTER_OPTION.EMPLOYMENT_TYPE}`,
					selectAll: false,
					searchInput: false
				}, {
					span: 6,
					header: "แผนกย่อย",
					type: TYPE.SELECT,
					fieldname: MASTER_OPTION.SUBDEP_FACTORY,
					fieldOption: `OPTION_${MASTER_OPTION.SUBDEP_FACTORY}`,
					selectAll: true,
					searchInput: true
				}, {
					span: 6,
					header: "สิทธิการอนุมัติ",
					type: TYPE.SELECT,
					fieldname: MASTER_OPTION.FLOWHRM_FACTORY,
					fieldOption: `OPTION_${MASTER_OPTION.FLOWHRM_FACTORY}`,
					selectAll: true,
					searchInput: true
				},
			]
	}
}
const searchReports = (role) => {
	switch (role) {
		case 'HRD-FAC':
		case 'HRM-FAC':
		case 'HRS-FAC':
			return [
				{
					span: 4,
					type: TYPE.SELECT,
					header: "การประเมินประจำปี",
					fieldname: MASTER_OPTION.YEAR,
					fieldOption: `OPTION_${MASTER_OPTION.YEAR}`,
					selectAll: false,
					searchInput: false
				},
				{
					span: 4,
					type: TYPE.SELECT,
					header: "การประเมินประจำเดือน",
					required: true,
					fieldname: MASTER_OPTION.MONTH,
					fieldOption: `OPTION_${MASTER_OPTION.MONTH}`,
					selectAll: false,
					searchInput: false
				}, {
					span: 4,
					header: "ประเภทการจ้าง",
					fieldname: MASTER_OPTION.EMPLOYMENT_TYPE,
					fieldOption: `OPTION_${MASTER_OPTION.EMPLOYMENT_TYPE}`,
					selectAll: false,
					searchInput: false
				}, {
					span: 4,
					header: "แผนกย่อย",
					type: TYPE.SELECT,
					fieldname: MASTER_OPTION.SUBDEP_FACTORY,
					fieldOption: `OPTION_${MASTER_OPTION.SUBDEP_FACTORY}`,
					selectAll: true,
					searchInput: true
				}, {
					span: 4,
					header: "สิทธิการอนุมัติ",
					fieldname: MASTER_OPTION.FLOWHRM_FACTORY,
					fieldOption: `OPTION_${MASTER_OPTION.FLOWHRM_FACTORY}`,
					selectAll: true,
					searchInput: true
				}, {
					span: 4,
					header: "สิทธิการดู",
					fieldname: MASTER_OPTION.VIEW_FACTORY,
					fieldOption: `OPTION_${MASTER_OPTION.VIEW_FACTORY}`,
					selectAll: true,
					searchInput: true
				},
			]
		default:
			return [
				{
					span: 4,
					type: TYPE.SELECT,
					header: "การประเมินประจำปี",
					fieldname: MASTER_OPTION.YEAR,
					fieldOption: `OPTION_${MASTER_OPTION.YEAR}`,
					selectAll: false,
					searchInput: false
				}, {
					span: 4,
					type: TYPE.SELECT,
					header: "การประเมินประจำเดือน",
					required: true,
					fieldname: MASTER_OPTION.MONTH,
					fieldOption: `OPTION_${MASTER_OPTION.MONTH}`,
					selectAll: false,
					searchInput: false
				}, {
					span: 4,

					header: "ประเภทการจ้าง",
					type: TYPE.SELECT_MULTIPLE,
					fieldname: MASTER_OPTION.EMPLOYMENT_TYPE,
					fieldOption: `OPTION_${MASTER_OPTION.EMPLOYMENT_TYPE}`,
					selectAll: false,
					searchInput: false
				}, {
					span: 6,
					header: "แผนกย่อย",
					type: TYPE.SELECT,
					fieldname: MASTER_OPTION.SUBDEP_FACTORY,
					fieldOption: `OPTION_${MASTER_OPTION.SUBDEP_FACTORY}`,
					selectAll: true,
					searchInput: true
				}, {
					span: 6,
					header: "สิทธิการอนุมัติ",
					type: TYPE.SELECT,
					fieldname: MASTER_OPTION.FLOWHRM_FACTORY,
					fieldOption: `OPTION_${MASTER_OPTION.FLOWHRM_FACTORY}`,
					selectAll: true,
					searchInput: true
				},
			]
	}
	// switch (role) {
	//     default:
	//         return [
	//             {
	//                 span: 4,
	//                 type: TYPE.SELECT,
	//                 header: "การประเมินประจำปี",
	//                 fieldname: MASTER_OPTION.YEAR,
	//                 fieldOption: `OPTION_${MASTER_OPTION.YEAR}`,
	//                 selectAll: false,
	//                 searchInput: false
	//             },
	//             {
	//                 span: 4,
	//                 type: TYPE.SELECT,
	//                 header: "การประเมินประจำเดือน",
	//                 fieldname: MASTER_OPTION.MONTH,
	//                 fieldOption: `OPTION_${MASTER_OPTION.MONTH}`,
	//                 selectAll: false,
	//                 searchInput: false
	//             },
	//             {
	//                 span: 4,
	//                 type: TYPE.SELECT,
	//                 header: "ประเภทการจ้าง",
	//                 fieldname: MASTER_OPTION.EMPLOYMENT_TYPE,
	//                 fieldOption: `OPTION_${MASTER_OPTION.EMPLOYMENT_TYPE}`,
	//                 selectAll: false,
	//                 searchInput: false
	//             },
	//             {
	//                 span: 6,
	//                 type: TYPE.SELECT,
	//                 header: "แผนกย่อย",
	//                 fieldname: MASTER_OPTION.SUBDEP_FACTORY,
	//                 fieldOption: `OPTION_${MASTER_OPTION.SUBDEP_FACTORY}`,
	//                 selectAll: true,
	//                 searchInput: true
	//             },
	//             {
	//                 span: 6,
	//                 type: TYPE.SELECT,
	//                 header: "สิทธิการอนุมัติ",
	//                 fieldname: MASTER_OPTION.FLOWHRM_FACTORY,
	//                 fieldOption: `OPTION_${MASTER_OPTION.FLOWHRM_FACTORY}`,
	//                 selectAll: true,
	//                 searchInput: true
	//             }
	//         ]
	// }
}


const layoutInsertIndicator = ({ indicatorState, option }) => [
	{
		span: 24,
		fieldname: 'indicatorHeaderName',
		type: TYPE.INPUT,
		inputItems: {
			required: false,
			label: <BaseText label={"กำหนดชื่อตัวชี้วัด"} />
		},
		inputProps: {
			options: option.optionIndicatorHeader
		}
	}, {
		span: 24,
		fieldname: 'durationActive',
		type: TYPE.RANG_PICKER,
		inputItems: {
			required: false,
			label: <BaseText label={"กำหนดเดือนที่มีผลบังคับใช้"} />
		},
		inputProps: {
			picker: "month",
			separator: "to",
			format: "MM/YYYY"
		}
	}
]


const columnEvaluationByMonth = ({ searchDate, action, }) => {
	return [
		// {
		//     // title: 'ข้อมูล KPIs พนักงานรายเดือน รายตำแหน่งงาน หน่วยงาน Mantenance',
		//     span: 10,

		//     children: [
		{
			title: 'ชื่อตำแหน่ง',
			dataIndex: 'employeeName',
			width: 100,
			render: (text) => text || '-',
		}, {
			title: 'หัวข้อตัวชี้วัด',
			dataIndex: 'employeeNickname',
			width: 100,
			render: (text) => text || '-'
		}, {
			title: 'งานที่ชี้วัด',
			dataIndex: 'totalScore',
			width: 200,
			render: (text) => parseFloat(text || 0).toFixed(2),
		}, {
			title: 'น้ำหนักงาน (%)',
			dataIndex: 'totalScore',
			width: 200,
			render: (text) => parseFloat(text || 0).toFixed(2),
		}, {
			title: 'คะแนน',
			dataIndex: 'totalScore',
			width: 200,
			render: (text) => parseFloat(text || 0).toFixed(2),
		}, {
			title: 'เกณฑ์การให้คะแนน',
			dataIndex: 'totalScore',
			width: 200,
			render: (text) => parseFloat(text || 0).toFixed(2),
		}
		// ]
		// }
	]
}

const columnEvaluation = ({ searchDate, searchYear, action, view }) => {
	// console.log({ searchYear })
	return [
		{
			title: 'ข้อมูลพนักงาน',
			span: 10,
			// fixed: 'left',
			children: [
				{
					title: 'รหัสพนักงาน',
					dataIndex: 'ID_User',
					width: 50,
					fixed: 'left',

					render: (text) => text || '-',
				}, {
					title: 'ชื่อพนักงาน',
					dataIndex: 'Name',
					width: 60,
					fixed: 'left',

					render: (text) => text || '-',
				}, {
					title: 'ชื่อเล่น',
					dataIndex: 'NickName',
					width: 40,
					fixed: 'left',

					render: (text) => text || '-'
				}, {
					title: 'หัวหน้า',
					dataIndex: view ? 'BossName' : 'approvalName1',
					width: 60,
					fixed: 'left',

					render: (text) => text || '-'
				}, {
					title: 'แผนกย่อย',
					dataIndex: 'subDepName',
					width: 60,
					fixed: 'left',

					render: (text) => text || '-'
				},
				//  {

				//     title: 'Total Score',
				//     dataIndex: 'totalAgvScore',
				//     width: 200,
				//     render: (text) => parseFloat(text || 0).toFixed(2),
				// }
			]
		}, {
			title: 'Total',
			span: 4,
			// fixed: 'left',

			children: [
				{
					title: 'เฉลี่ย',
					dataIndex: 'totalAgvScore',
					width: 30,
					fixed: 'left',
					align: 'right',
					render: (text) => parseFloat(text || 0).toFixed(2),
					// render: (text) => text,
				}, {
					title: 'เกรด',
					dataIndex: 'finalGrade',
					width: 30,
					fixed: 'left',
					align: 'right',
					render: (text) => text || '-',
				}]
		},
		{
			title: `ข้อมูลการประเมินประจำปี ${searchYear || ''} (คะแนนเต็ม 5 คะแนน)`,
			// title: `ข้อมูลการประเมินประจำปี ${searchDate && searchDate[0].year} (คะแนนเต็ม 5 คะแนน)`,
			span: 10,
			children: R.sort(((a, b) => (a - b)), searchDate).map(each => {
				return (
					{
						title: `${each.sortTH}`,
						width: 80,
						render: (text, record) => (
							action(record, each)
						)
					})
			})
		},

	]
}

const printEvaluationByDay = {
	header: (weight) => [
		[{
			span: 8, title: 'ข้อมูลพนักงาน',
			children: [
				{ span: 4, title: 'ลำดับ', border: 'right' },
				{ span: 10, title: 'รหัสพนักงาน', border: 'right' },
				{ span: 10, title: 'ชื่อ-สกุล' }
			]
		},
		{
			span: 10, title: 'น้ำหนักงาน (%)',
			children: [
				{ span: 8, title: `ปริมาณงาน (${weight ? weight.volume : ''}%)`, border: 'right' },
				{ span: 8, title: `คุณภาพงาน (${weight ? weight.quality : ''}%)`, border: 'right' },
				{ span: 8, title: `พฤติกรรม (${weight ? weight.behavior : ''}%)` }]
		}, {
			span: 4, title: 'Total',
			children: [
				{ span: 12, title: 'Score', border: 'right' },
				{ span: 12, title: '%' }
			],
		}, {
			span: 2, title: 'เกรด',

		}]
	],
	content: (val, index) => [
		[{
			span: 8,
			children: [
				{
					span: 4, answer: index + 1,
					styleBox: { border: 'right' }
				},
				{
					span: 10, answer: val.employeeId,
					styleBox: { border: 'right', align: 'left' }
				},
				{
					span: 10, answer: val.employeeName,
					styleBox: { align: 'left' }
				}]

		},
		{
			span: 10,
			children: [
				{
					span: 8,
					children: [
						{ span: 12, answer: parseFloat(val.rawScoreVolume || 0).toFixed(2), styleBox: { border: 'right' } },
						{ span: 12, answer: parseFloat(val.scoreVolume || 0).toFixed(2), styleBox: { border: 'right' } }
					]
				},
				{
					span: 8,
					children: [
						{ span: 12, answer: parseFloat(val.rawScoreQuality || 0).toFixed(2), styleBox: { border: 'right' } },
						{ span: 12, answer: parseFloat(val.scoreQuality || 0).toFixed(2), styleBox: { border: 'right' } }
					]
				},
				{
					span: 8,
					children: [
						{ span: 12, answer: parseFloat(val.rawScoreBehavior || 0).toFixed(2), styleBox: { border: 'right' } },
						{ span: 12, answer: parseFloat(val.scoreBehavior || 0).toFixed(2) }
					]
				}]
		}, {
			span: 4,
			children: [
				{ span: 12, answer: parseFloat(val.totalScore || 0).toFixed(2), styleBox: { border: 'right' } },
				{ span: 12, answer: parseFloat(val.percentage || 0).toFixed(2) }
			],
		}, {
			span: 2,
			children: [
				{ span: 24, answer: val.grade }
			],
		}]
	],
	contentGrade: () => [
		{ span: 4, title: 'เกรด', answer: '% การประเมิน' },
		{ span: 4, title: 'เกรด A', answer: 'Total < 100%' },
		{ span: 4, title: 'เกรด B', answer: 'Total < 89%' },
		{ span: 4, title: 'เกรด C', answer: 'Total < 79%' },
		{ span: 4, title: 'เกรด C-', answer: 'Total < 64%' },
		{ span: 4, title: 'เกรด D', answer: 'Total < 59%' },
	],
	contentSignature: (resultDay) => {
		let aArray = []
		// resultDay && Array.isArray(resultDay.datasource) && resultDay.datasource.filter(val => val.statusNo === 1 ? a = true : a = false)
		resultDay && Array.isArray(resultDay.datasource) && resultDay.datasource.forEach(({ statusNo }) => aArray.push(statusNo))
		let checkStatus = aArray.find((val) => val !== 1)
		//console.log({ resultDay, aArray, checkStatus })
		return [
			[{
				span: 12, title: 'ลงนามผู้ขออนุมัติ', department: 'หัวหน้างาน',
				signature: checkStatus === undefined ? R.path(['datasource', 1, 'approvalName1'], resultDay) || '' : '',
				date: checkStatus === undefined ? (R.path(['datasource', 1, 'confirmDate'], resultDay) ? moment(R.path(['datasource', 1, 'confirmDate'], resultDay)).format('DD/MM/YYYY') : '') : '',
			}, {
				span: 12, title: 'ลงนามผู้อนุมัติ', department: 'ผู้จัดการแผนก',
				signature: checkStatus === undefined ? R.path(['datasource', 1, 'approvalName2'], resultDay) || '' : '',
				date: checkStatus === undefined ? (R.path(['datasource', 1, 'confirmDate'], resultDay) ? moment(R.path(['datasource', 1, 'confirmDate'], resultDay)).format('DD/MM/YYYY') : '') : '',
			}]
			// , [{
			//     span: 24, title: 'ลงนามผู้ตรวจสอบ', department: 'ผู้อนุมัติ ',
			//     signature: R.path(['datasource', 1, 'hrConfirmName'], resultDay) || '',
			//     date: R.path(['datasource', 1, 'hrConfirmDate'], resultDay) ? moment(R.path(['datasource', 1, 'hrConfirmDate'], resultDay)).format('DD/MM/YYYY') : '',
			// }
			// ]
		]
	}
}


const printEvaluationByMonth = {
	header: (weight) => [
		[{
			span: 2, title: 'ชื่อตำแหน่ง',
		},
		{
			span: 8, title: 'หัวข้อตัวชี้วัด',
		}, {
			span: 2, title: 'งานที่ชี้วัด',
		}, {
			span: 2, title: 'น้ำหนักงาน (%)',
		}, {
			span: 10, title: 'เกณฑ์การให้คะแนน',
		}]
	],
	content: (val) => [
		[{
			span: 2, children: [
				{ span: 24, answer: val.departmentName, styleBox: { align: 'left' } }
			],
		},
		{
			span: 8, answer: 'หัวข้อตัวชี้วัด', children: [
				{
					span: 24,
					fieldList: 'indicator',
					answerList: true,
					answer: (data) => ({ no: data.indicatorLine, value: data.indicatorDes }),
					styleBox: { align: 'left' }
				}
			],
		}, {
			span: 2, answer: 'งานที่ชี้วัด', children: [
				{
					span: 24,
					fieldList: 'indicator',
					answerList: true,
					answer: (data) => ({ value: data.indicatorGroup }),
				}
			],
		}, {
			span: 2, answer: 'น้ำหนักงาน (%)', children: [
				{
					span: 24,
					fieldList: 'indicator',
					answerList: true,
					answer: (data) => ({ value: data.weight }),
				}
			],
		}, {
			span: 10, answer: 'เกณฑ์การให้คะแนน', children: [
				{
					span: 24,
					fieldList: 'indicator',
					answerList: true,
					answer: (data) => ({ no: data.indicatorLine, value: data.scoreCriteria }),
				}
			],
		}]
	],
}

const columnEvaluationByDay = ({ searchDate, action, weight }) => {
	return [
		{
			title: 'ข้อมูลพนักงาน',
			children: [
				{
					title: 'รหัสพนักงาน',
					dataIndex: 'employeeId',
					width: 50,
					render: (text) => text || '-',
				}, {
					title: 'ชื่อพนักงาน',
					dataIndex: 'employeeName',
					width: 200,
					render: (text) => text || '-',
				}, {
					title: 'ชื่อเล่น',
					dataIndex: 'NickName',
					width: 80,
					render: (text) => text || '-'
				}, {
					title: 'หัวหน้า',
					dataIndex: 'approvalName1',
					width: 80,
					render: (text) => text || '-'
				},
				{
					title: 'แผนกย่อย',
					dataIndex: 'subDepName',
					width: 80,
					render: (text) => text || '-'
				},
				//  {
				//     title: 'Total Score',
				//     dataIndex: 'totalScore',
				//     width: 200,
				//     render: (text) => parseFloat(text || 0).toFixed(2),
				// }
			]
		},
		{
			title: 'น้ำหนักงาน (%)',
			children: [
				{
					title: `ปริมาณงาน (${weight ? weight.volume : ''}%)`,
					dataIndex: 'rawScoreVolume',
					align: 'right',
					width: 80,
					render: (text, record) => `${record.rawScoreVolume || '0'} (${parseFloat(record.scoreVolume || 0).toFixed(2)})` || '-',
				}, {
					title: `คุณภาพงาน (${weight ? weight.quality : ''}%)`,
					dataIndex: 'rawScoreQuality',
					align: 'right',
					width: 80,
					render: (text, record) => `${record.rawScoreQuality || '0'}  (${parseFloat(record.scoreQuality || 0).toFixed(2)})` || '-',
					// render: (text) => text || '-',
				}, {
					title: `พฤติกรรม (${weight ? weight.behavior : ''}%)`,
					dataIndex: 'rawScoreBehavior',
					align: 'right',
					width: 80,
					render: (text, record) => `${record.rawScoreBehavior || '0'}  (${parseFloat(record.scoreBehavior || 0).toFixed(2)})` || '-',
					// render: (text) => text || '-'
				}]
		}, {
			title: 'Total',
			children: [
				{
					title: 'Score',
					dataIndex: 'totalScore',
					width: 100,
					align: 'right',
					render: (text) => `${parseFloat(text || 0).toFixed(2)}` || '-',
					// render: (text, record) => (
					//     action(record)
					// ) ,
				}, {
					title: '%',
					dataIndex: 'percentage',
					width: 100,
					align: 'right',
					render: (text) => `${parseFloat(text || 0).toFixed(2)}` || '-',
				}, {
					title: 'เกรด',
					dataIndex: 'grade',
					width: 100,
					align: 'right',
					render: (text) => text || '-',
				}]
		}, {
			title: 'สถานะ',
			dataIndex: 'statusDes',
			width: 100,
			render: (text) => text || '-',
		}]
}

const columnReport = ({ searchDate, action }) => {

	return [
		{
			title: 'รหัสพนักงาน',
			dataIndex: 'employeeId',
			render: (text) => text || '-',
		},
		{
			title: 'ชื่อพนักงาน',
			dataIndex: 'employeeName',
			render: (text) => text || '-',
		}, {
			title: 'ชื่อเล่น',
			dataIndex: 'employeeNickname',
			render: (text) => text || '-',
		}, {
			title: 'Total Score ',
			dataIndex: 'totalScore',
			render: (text) => parseFloat(text || 0).toFixed(2),
		}, {
			title: 'หัวหน้า',
			dataIndex: 'approvalName1',
			width: 100,
			render: (text) => text || '-'
		},
	]
}

const columnReportExpanded = ({ searchDate, action, searchYear }) => {

	return [
		{
			title: '',
			children: [
				{
					title: 'ตัววัดผลงาน',
					className: 'expanded-title',
					dataIndex: 'indicatorLineName',
					render: (text) => text || '-',
				},
				{
					title: 'เกณฑ์',
					dataIndex: 'criterion',
					render: (text) => `${parseFloat(text || 0).toFixed(2) || 0}%`,
				},
				{
					title: 'สัดส่วน',
					dataIndex: 'proportion',
					render: (text) => `${parseFloat(text || 0).toFixed(2) || 0}%`,
				},
				{
					title: 'Total Score',
					dataIndex: 'totalScoreByline',
					render: (text) => parseFloat(text || 0).toFixed(2),
				}, {
					title: 'Total Year',
					dataIndex: 'totalYearByline',
					render: (text) => parseFloat(text || 0).toFixed(2),
				}]
		},
		{
			title: `ข้อมูลการประเมินประจำปี ${searchYear || ''} (คะแนนเต็ม 5 คะแนน)`,
			children: R.sort(((a, b) => (a - b)), searchDate).map(each => {
				return (
					{
						title: `${renderMonth(parseInt(each.month)).sortTH}`,
						width: 80,
						render: (text, record) => (
							text[`month${each.month}`] || '-'
						)
					}
				)
			})
		}]
}

let columnsReportByDay = () => [
	{
		title: 'ข้อมูลพนักงาน', children: [
			{ title: 'รหัสพนักงาน', field: 'employeeId' },
			{ title: 'ชื่อพนักงาน', field: 'employeeName' },
			{ title: 'ชื่อเล่น', field: 'employeeNickname' }
		]
	}, {
		title: 'น้ำหนักงาน (%)', children: [
			{ title: 'ปริมาณงาน (40%)', field: 'employeeId' },
			{ title: 'คุณภาพงาน (40%)', field: 'employeeName' },
			{ title: 'พฤติกรรม (40%)', field: 'employeeNickname' }
		]
	}, {
		title: 'Total Score', children: [
			{ title: 'Score', field: 'employeeId' },
			{ title: '%', field: 'employeeName' },
			{ title: 'เกรด', field: 'employeeNickname' }
		]
	},
]

let columnsReportByMonth = ({ searchDate }) => [
	{
		title: 'ข้อมูลพนักงาน', children: [
			{ title: 'รหัสพนักงาน', field: 'employeeId' },
			{ title: 'ชื่อพนักงาน', field: 'employeeName' },
			{ title: 'ชื่อเล่น', field: 'employeeNickname' },
			{ title: 'Total Score', field: 'nomalizeScore' }
		]
	}, {
		title: `ข้อมูลการประเมินประจำปี (คะแนนเต็ม 5 คะแนน)`,
		children: R.sort(((a, b) => (a - b)), Array.isArray(searchDate) && searchDate).map(each => {
			return (
				{
					title: `${renderMonth(parseInt(each.month)).sortTH}`,
					width: 80,
					field: `month${each.month}`
				}
			)
		})
	}, {
		title: 'ข้อมูลพนักงาน', children: [
			{ title: 'รหัสพนักงาน', field: 'employeeId' },
			{ title: 'ชื่อพนักงาน', field: 'employeeName' },
			{ title: 'ชื่อเล่น', field: 'employeeNickname' },
			{ title: 'Total Score', field: 'nomalizeScore' }
		]
	},
]

export const STRUCTURE_KPIS_FACTORY = {
	BREADCRUMB: breadcrumbLists,
	BREADCRUMB_REPORT: breadcrumbPrintLists,
	SEARCH_LISTS: searchLists,
	SEARCH_REPORTS: searchReports,
	TABS_LISTS: tabsLists,
	COLUMN_EVALUATION: columnEvaluation,
	COLUMN_EVALUATION_BY_DAY: columnEvaluationByDay,
	COLUMN_EVALUATION_BY_MONTH: columnEvaluationByMonth,
	COLUMN_REPORT: columnReport,
	COLUMN_REPORT_EXPANDED: columnReportExpanded,
	LAYOUT_INSERT_INDICATOR: layoutInsertIndicator,
	PRINT_EVALUATION_BY_DAY: printEvaluationByDay,
	PRINT_EVALUATION_BY_MONTH: printEvaluationByMonth,
	COLUMN_REPORT_BY_DAY: columnsReportByDay,
	COLUMN_REPORT_BY_MONTH: columnsReportByMonth
}