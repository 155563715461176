import { COMPONENT_TYPE, ERROR_MESSAGE } from "../..";

export const INIT_VALUE = {
    company: "", // บริษัท
    location: "", // สถานที่
    personalId: "", // หมายเลขบัตรประชาชน
}

const DEFINE = {
    company: {
        label: "บริษัท",
        name: "company",
    },
    location: {
        label: "สถานที่",
        name: "location",
    },
    personalId: {
        label: "หมายเลขบัตรประชาชน",
        name: "personalId",
    },
}

export const ComponentReportReturn = ({
    options = {},
    isDisable = {},
    handleChange = () => null,
}) => {
    const { company, location, personalId } = DEFINE
    return [
        { // Row
            rowProps: { justify: "start", align: "bottom" },
            column: [ // column
                {
                    breakpoint: { xs: 24, sm: 24, md: 12, lg: 6, xl: 4 },
                    component: {
                        type: COMPONENT_TYPE.SELECT,
                        itemProps: {
                            name: company.name,
                            label: company.label,
                            rules: [{ required: false, message: ERROR_MESSAGE.SELECT(company.label) }],
                        },
                        componentProps: {
                            style: { width: "100%" },
                            placeholder: ERROR_MESSAGE.SELECT(company.label),
                            disabled: isDisable[company.name] || false,
                        },
                        fnChange: (value) => handleChange(company.name, value),
                        dropdown: options[company.name],
                    },
                },
                {
                    breakpoint: { xs: 24, sm: 24, md: 12, lg: 6, xl: 4 },
                    component: {
                        type: COMPONENT_TYPE.SELECT,
                        itemProps: {
                            name: location.name,
                            label: location.label,
                            rules: [{ required: false, message: ERROR_MESSAGE.SELECT(location.label) }],
                        },
                        componentProps: {
                            style: { width: "100%" },
                            placeholder: ERROR_MESSAGE.SELECT(location.label),
                            disabled: isDisable[location.name] || false,
                        },
                        fnChange: (value) => handleChange(location.name, value),
                        dropdown: options[location.name],
                    },
                },
                {
                    breakpoint: { xs: 24, sm: 24, md: 12, lg: 10, xl: 8 },
                    component: {
                        type: COMPONENT_TYPE.INPUT,
                        itemProps: {
                            name: personalId.name,
                            label: personalId.label,
                            rules: [{ required: false, message: ERROR_MESSAGE.INPUT(personalId.label) }],
                        },
                        componentProps: {
                            style: { width: "100%" },
                            placeholder: ERROR_MESSAGE.INPUT(personalId.label),
                            disabled: isDisable[personalId.name] || false,
                        },
                        fnChange: (event) => handleChange(personalId.name, event.target.value),
                    },
                },
            ], // column
        }, // Row
    ]
}