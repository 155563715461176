export const optionDocStatus = [
    {
        value: 'เอกสารครบถ้วน',
        label: 'เอกสารครบถ้วน'
    },
    {
        value: 'ส่งเอกสารให้พนักงานเรียบร้อย',
        label: 'ส่งเอกสารให้พนักงานเรียบร้อย'
    },
    {
        value: 'รับเอกสารจากพนักงานเรียบร้อย',
        label: 'รับเอกสารจากพนักงานเรียบร้อย'
    },
    {
        value: 'แก้ไขเอกสารเพิ่มเติม',
        label: 'แก้ไขเอกสารเพิ่มเติม'
    }
]