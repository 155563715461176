export const PATHNAME = {
  INDEX: "/",
  LOGIN: "/login",

  MANAGE_PROBATION: "/DashboardProbation",
  REPORT_NEW_EMPLOYEE: "/ReportNewEmployee",

  MANAGE_SELF_ASSESSMENT: "/DashboardSelfAssessment",
  MANAGE_CONFIRM_DISC: "/DashboardCF_DIS",

  MANAGE_PERFORMANCE_ASSESSMENT: "/DashboardPerformance",
  MANAGE_DISCUSSION_FORM: "/DashboardDiscussionForm",

  MANAGE_ASSESSMENT_REPORT: "/SummaryReportPEF",
  MANAGE_ASSESSMENT_GRADE: "/DashboardGrade",

  MANAGE_KPIS: "/DashboardKPIs",
  MANAGE_INDICATOR: "/DashboardIndicator",
  MANAGE_KPIS_FACTORY: "/DashboardKPIsFactory",
  REPORT_KPIS_FACTORY: "/ReportKPIsFactory",
  MANAGE_ASSET_EMP: "/DashboardAssetEmp",
  MANAGE_NEW_EMP: "/Assets_NewEmpMain",
  ASSETS_NEW_EMP_PRINT: "/Assets_NewEmpPrint",
  ASSETS_CONFIRM_LOGIN: "/Assets_ConfirmationLogin",

  ASSESSMENT_FACTORY_BY_DAY: "/AssessmentByDay",
  ASSESSMENT_FACTORY_BY_MONTH: "/AssessmentByMonth",

  /**  Module OT Factory  **/
  EXPORT_OT_FACTORY: "/ExportOTFactory",
  DASHBOARD_OT_FACTORY: "/DashboardOTFactory",
  REPORT_OT_FACTORY: "/ReportOTFactory",
  INSERT_REQUEST_OT: "/InsertOTFactory",
  UPDATE_REQUEST_OT: "/UpdateOTFactory",
  /** End Module **/

  /**  Module EVAC Factory  **/
  DASHBOARD_REVIEWER: "/dashboardReviewer",
  INSERT_REVIEWER: "/insertReviewer",
  UPDATE_REVIEWER: "/updateReviewer",
  DELETE_REVIEWER: "/deleteReviewer",
  VIEW_REVIWER: "/viewReviewer",
  PRIVIEW_REVIEWER: "/priviewerReviewer",
  DASHBOARD_EVAC: "/dashboardEvac",
  INSERT_EVAC: "/insertEvac",
  DASHBOARD_EVAC_BY_ADMIN: "/dashboardEvacByAdmin",
  DASHBOARD_EVAC_BY_DEP: "/dashboardEvacByDep",
  DASHBOARD_EVAC_END_AND_SAVE: "/dashboardEndAndSaveEvac",
  /** End Module **/

  /**  Module TRAINING  **/
  DASHBOARD_TRAINING: "/dashboardTraining",
  DASHBOARD_HISTORY_INDIVIDUAL: "/dashboardHistoryIndividual",
  DASHBOARD_HISTORY_TRAINERS: "/dashboardHistoryTrainers",
  DASHBOARD_FILE_TRAINERS: "/dashboardFileTrainers",
  INSERT_TRAINING: "/insertTraining",
  UPDATE_TRAINING: "/updateTraining",
  VIEW_TRAINING: "/viewTraining",
  IMPORT_TRAINING: "/importTraining",

  HISTORY_INDIVIDUAL_FORM: "/historyIndividualForm",
  HISTORY_TRAINERS_FORM: "/historyTrainersForm",
  TRAINING_FORM: "/trainingForm",
  /** End Module **/

  DASHBOARD_PROBATION: "/dashboardProbation",
  /**  Module Contract  **/
  DASHBOARD_FOLLOWUP_GURANTEE: "/dashboardFollowupGuarantee",
  DASHBOARD_PROBATION_CONTRACT: "/dashboardProbationContract",
  DASHBOARD_PROBATION_EMP_CONTRACT: "/dashboardProbationEmpContract",
  DASHBOARD_PROBATION_EMP_CONTRACT_NEW: "/dashboardProbationEmpContractNew",
  DASHBOARD_TRACK_CONTRACT: "/dashboardTrackContract",
  DASHBOARD_BENEFIT_CONTRACT: "/dashboardBenefitContract",
  INSERT_PROBATION_CONTRACT: "/insertProbationContract",
  UPDATE_PROBATION_CONTRACT: "/updateProbationContract",
  VIEW_PROBATION_CONTRACT: "/viewProbationContract",
  /** End Module **/

  /* ----------------------------- Module Personal ---------------------------- */
  INSERT_PERSONAL: "/insertPersonal",
  IMPORT_PERSONAL: "/importPersonal",
  UPDATE_PERSONAL: "/updatePersonal",
  VIEW_PERSONAL: "/viewPersonal",
  RECHECK_PERSONAL: "/recheckPersonal",
  BLOCK_PERSONAL: "/blockPersonal",
  DASHBOARD_PERSONAL: "/dashboardPersonal",
  DASHBOARD_PERSONAL_VIEW: "/dashboardPersonalView",
  DASHBOARD_IMPORT_PERSONAL: "/dashboardImportPersonal",
  DASHBOARD_IMPORT_PERSONAL_FAC: "/dashboardImportPersonalFac",
  DASHBOARD_IMPORT_PERSONAL_ID_CARD: "/dashboardImportPersonalIdCard",
  DASHBOARD_IMPORT_QUIT_PERSONAL: "/dashboardImportQuitPersonal",
  DASHBOARD_MONITORFILE: "/dashboardMonitorFile",
  DASHBOARD_MONITORFILE_VIEW: "/dashboardMonitorFileView",



  /* ------------------------------- End Module ------------------------------- */
  /* ----------------------------- Module Probation ---------------------------- */
  INSERT_PROBATION: "/InsertProbation",
  UPDATE_PROBATION: "/UpdateProbation/",
  /* ------------------------------- End Module ------------------------------- */

  /* ----------------------------- Module OTHER ---------------------------- */
  CERTIFICATE_REQUEST_DASHBOARD: "/CertificateRequestDashboard", //TODO MENU
  CERTIFICATE_REQUEST_DETAIL: "CertificateRequestDetail", //TODO MENU
  /* ------------------------------- End Module ------------------------------- */

  /* ----------------------------- Module FormContract ---------------------------- */
  FORM_CONTRACT_DASHBOARD: "/FormContractDashboard",
  FORM_CONTRACT_DETAIL: "/FormContractDetail",
  FORM_HIRE_DETAIL: "/FormHireDetail",
  /* ------------------------------- End Module ------------------------------- */
};
