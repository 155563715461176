import React, { useState, useContext, useEffect } from 'react';
import { browserHistory } from "react-router";
import { Spin, Modal } from 'antd';
import { isNil } from 'ramda';
import { LayoutWrapper, LayoutBreadcrumb, LayoutTable } from '../../moduleKPIs/common/layouts'
import { PATHNAME } from '../../constants/enums/pathname'
import { TABLE_INSERT_EVAC } from '../commonsEvac/structuresEvac/tableEvac'
import { BREADCRUMB_INSERT_REVIEWER } from '../commonsEvac/structuresEvac/breadcrumEvac'
import { FIELD_BTN } from '../commonsEvac/structuresEvac/buttonEvac'
import { ManageEvacContext } from '../servicesEvac/manageEvac/manageEvacProvider'
import { ReportEvacContext } from '../servicesEvac/reportEvac/reportEvacProvider'
import { APIInsertReviewer } from '../servicesEvac/manageEvac/manageEvacConnect'

const PagePriviewReviewer = () => {
    const { userLogin, } = useContext(ManageEvacContext);
    const { reportEvacState, } = useContext(ReportEvacContext);

    const { reviewerData: { formId, formDescription, reviewerLists, year, quarter } } = reportEvacState;

    //const [reviewers, setReviewers] = useState(reviewerLists)
    const reviewers = reviewerLists

    // const [statusCF, setStatusCF] = useState(false)
    const statusCF = false
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        if (isNil(year)) {
            return browserHistory.replace(PATHNAME.DASHBOARD_REVIEWER)
        }
    }, [])


    async function onSubmit() {
        if (Array.isArray(reviewers) && reviewers.length > 0) {
            let dataHeader = {
                formId: formId,
                year: year,
                quarter: quarter,
                confirmStatus: (statusCF) ? "1" : "0", //reviewerData.confirmStatus,
                createBy: userLogin.userId
            }
            let dataLine = reviewers.reduce((acc, cur) => {
                return [...acc, {
                    userId: cur.userId,
                    fullname: cur.name,
                    nickname: cur.nickname,
                    depNo: cur.depNo,
                    subDepNo: cur.subDepNo
                }]
            }, [])

            let payload = { dataHeader, dataLine }

           // console.log(userLogin, { payload })

            await APIInsertReviewer(payload, async (response) => {
                if (response) {
                    const { docNo } = response
                    await setLoading(true)
                    await Modal.success({
                        title: 'บันทึกสำเร็จ',
                        content: `เลขที่เอกสาร ${docNo || ''}`,
                        onOk: async () => {
                            // await getReportEvac({
                            //     fieldname: REPORT_OPTION.SELETE_REVIEWER_CURRENT,
                            //     payload: {docNo: docNo }
                            // })
                            await browserHistory.push(PATHNAME.DASHBOARD_REVIEWER)
                        }
                    })
                    await setLoading(false)
                }
            })
        } else {
            Modal.error({
                title: "กรุณาเลือกผู้ทำแบบทดสอบ"
            })
        }
    }


    // const rowSelection = {
    //     onChange: async (selectedRowKeys, selectedRows) => {
    //         await setReviewers(selectedRows)
    //     },
    //     getCheckboxProps: record => ({
    //         disabled: record.choose === '1', // Column configuration not to be checked
    //         name: record.choose,
    //     }),
    // };

    return (
        <Spin tip={'กำลังโหลด'} spinning={loading}>
            <LayoutWrapper
                breadcrumb={
                    <div className="layout-breadcrumb-btn-insert">
                        <LayoutBreadcrumb breadcrumbLists={BREADCRUMB_INSERT_REVIEWER()} />
                    </div>
                }
                search={null}
                content={
                    <>
                        <div style={{ fontSize: 16, textAlign: 'center', paddingBottom: 10 }}>
                            {`แบบประเมิน ${formDescription}`}
                        </div>
                        <div style={{ fontSize: 14, textAlign: 'center', paddingBottom: 10, }}>
                            {`ปี ${year} ประจำไตรมาส ${quarter}`}
                        </div>

                        {/* <div style={{ fontSize: 14, textAlign: 'left', paddingBottom: 10, paddingLeft: 5 }}>  กรุณาเลือกผู้มีส่วนเกี่ยวข้องที่ต้องการให้ทำแบบสอบถามให้ </div> */}
                        <div className="layout-content-card" >
                            <LayoutTable
                                bordered
                                style={{ padding: 10 }}
                                scroll={{ x: 600 }}
                                rowKey="userId"
                                dataSource={reviewerLists}
                                columns={TABLE_INSERT_EVAC({
                                    userLogin: userLogin,
                                    actionButton: async ({ fieldBtn, payload }) => {
                                        await setLoading(true)
                                        switch (fieldBtn) {
                                            case FIELD_BTN.DELETE_REVIEWER:
                                                await Modal.confirm({
                                                    title: 'คุณต้องการลบรายการใช่หรือไม่',
                                                    onOk: async () => {
                                                        console.log('DELETE_REVIEWER', { payload })
                                                    }
                                                })
                                                break;
                                            default:
                                                break;
                                        }
                                        await setLoading(false)
                                    }
                                })}
                                pagination={{
                                    pageSize: 500,
                                    showTotal: (total) => `ทั้งหมด ${total || 0} รายการ`
                                }}
                            // rowSelection={{
                            //     type: 'checkbox',
                            //     ...rowSelection
                            // }}
                            />

                        </div>
                        {/* <Checkbox
                            disabled={false}
                            style={{ paddingTop: 10 }}
                            checked={statusCF}
                            onChange={() => setStatusCF(!statusCF)}                    >
                            {'ยืนยันการบันทึกและส่งให้หัวหน้างาน (กรณียืนยันแล้ว จะไม่สามารถกลับมาแก้ไขได้)'}
                        </Checkbox>
                        <div className="layout-content-btn-confirm">
                            <ButtonTheme
                                type={(statusCF) ? "primary" : ''}
                                style={{ paddingRight: 10 }}
                                buttonText={TEXT_BTN_CUSTOM({ fieldBtn: FIELD_BTN.SUBMIT_REVIEWER, status: statusCF })}
                                onClick={async () => {
                                    await setLoading(true)
                                    await onSubmit()
                                    await setLoading(false)
                                }}
                            />
                        </div> */}
                    </>
                }
            />
        </Spin>
    )
}

export default PagePriviewReviewer