import { Button } from 'antd'
import React from 'react'
import { TEXT_BUTTON } from '.'
import { MdSearch } from "react-icons/md";

import './buttons.css'
import { colors } from '../../themes';

export const ButtonView = ({ style, disabled, onClick }) => {
    return (
        <div style={style}>
            <Button ghost
                type="primary"
                disabled={disabled}
                style={{
                    minWidth: 100,
                }}
                onClick={onClick}
            > {TEXT_BUTTON.VIEW} </Button>
        </div>
    )
}
