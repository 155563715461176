import React, { useState, useRef, useEffect } from "react";
import { Spin, Form, Space, Card, Modal } from 'antd';
import { configForm } from './constants/ConstantComponent';
import { useButton } from './conponents/buttons/CustomButtonAntd';
import { useRenderFormItem } from './conponents/RenderComponents';
import { ComponentImportIncentiveStrategy } from "./constants/ImportIncentiveStrategy/Components";
import { sumColumnWidth } from "./functions/UseFunction";
import { ColumnImportIncentiveStrategy, EXPORT_TEMPLATE_PAGES } from "./constants/ImportIncentiveStrategy/Columns";
import CustomTableAtnd from './conponents/tables/CustomTableAtnd';
import { useAlert } from "./conponents/modal/CustomAlert";
import { INSERT_UPDATE_INCENTIVE_EXCEL, POST, GET_SALE_LIST_BY_APPROVER1 } from './../service/index';
import servicesAPI from "../moduleKPIs/services/servicesAPI";
import { encryptString } from "../service/functions/encodeFunction";
import { getDatePeriodNow, getPeriodDate } from "../functions";
import { ButtonExport } from './../constants/buttons/buttonExport';
import { CONNECT_API, USE_CONNECTION_MODE } from "../components/connectAPI";
import moment from "moment";

const API = servicesAPI.create()

const ImportIncentiveStrategy = (props) => {
	const refExport = useRef();
	const [form] = Form.useForm();
	const [loading, setLoading] = useState(false)
	const [fileList, setFileList] = useState([])
	const [afterCheckImport, setAfterCheckImport] = useState(false)
	const [dataSource, setDataSource] = useState([])

	useEffect(() => {

	}, [])

	const onChangeFile = (name, value) => {
		if (value.fileList[0]) {
			setFileList(value.fileList)
			validateFile(value.fileList[0])
		}
	}

	const getSaleListByApprover1 = async () => {
		try {
			setLoading(true)
			let obj = {
				startDate: moment(getDatePeriodNow('start')).format("YYYY-MM-DD"),
				// startDate: '2022-01-21',
				userLogin: props.userLogin
			}
			let res = await POST(GET_SALE_LIST_BY_APPROVER1, obj)
			const { result, success } = res
			if (success) {
				// setDataSource(result)
				window.open(`${CONNECT_API[USE_CONNECTION_MODE].URL}/${result.path}`).focus();
			}
		} catch (err) {
			//alert
			console.log('err excel', err)
			useAlert({ type: 'catch', title: err.message })
		} finally {
			setLoading(false)
		}
	}

	const validateFile = async (file) => {
		try {
			setLoading(true)
			let formdata = new FormData()
			formdata.append('file', file.originFileObj)
			const res = await API.checkImportIncentiveExcel(formdata);
			// let res = await UPLOAD(CHECK_IMPORT_INCENTIVE_EXCEL, formdata)
			const { result, success } = res.data
			if (success) {
				setAfterCheckImport(true)
				setDataSource(result)
			}
		} catch (err) {
			//alert
			useAlert({ type: 'catch', title: err.message })
		} finally {
			setLoading(false)
		}
	}

	const onFinish = async () => {
		try {
			setLoading(true)
			let temp = []
			// incentiveArray
			dataSource.forEach(el => {
				temp.push({
					userId: encryptString(el.userId),
					commissionFocusShop: encryptString(`${el.commissionFocusShop}`),
					incentiveStrategy: encryptString(`${el.incentiveStrategy}`),
					periodDate: getPeriodDate('SQL')
				})
			})

			let obj = {
				incentiveArray: temp,
				userLogin: props.userLogin
			}
			const res = await POST(INSERT_UPDATE_INCENTIVE_EXCEL, obj);
			const { message, success } = res
			if (success) {
				useAlert({ type: "success", title: "ดำเนินการสำเร็จ", content: message })
				setAfterCheckImport(false)
				setDataSource([])
				setFileList([])
			}
		} catch (err) {
			console.log('err', err)
			useAlert({ type: 'catch', title: err.message })
		} finally {
			setLoading(false)
		}
	}

	return (
		<Spin tip="กำลังโหลดข้อมูล ..." spinning={loading}>
			<Form form={form} onFinish={() => { }} {...configForm}>
				<Card
					title={`นำเข้า Incentive กลยุทธ์/Focus shop (รอบวันที่ ${getPeriodDate('display')})`}
					style={{ marginBottom: 30 }}
				>
					{useRenderFormItem({
						constantComponent: ComponentImportIncentiveStrategy({
							options: [],
							handleChange: onChangeFile,
						}),
					})}
				</Card>
			</Form>
			<Card
				extra={
					<Space>{useButton({ call: "search", moreProps: { label: "ดาวน์โหลดเทมเพลต", onClick: getSaleListByApprover1 } })}</Space>
				}
			>
				<CustomTableAtnd
					HeadTable={ColumnImportIncentiveStrategy}
					DataTable={dataSource}
					moreProps={{
						scroll: {
							x: sumColumnWidth(ColumnImportIncentiveStrategy) || 2050,
							y: 500,
						},
						size: "small"
					}}
				/>
				<div style={{ width: '100%', textAlignLast: 'center', marginTop: '10px' }}>
					<Space>{useButton({ call: "save", moreProps: { onClick: onFinish }, isDisable: !afterCheckImport })}</Space>
				</div>

			</Card>
		</Spin>
	);
};

export default ImportIncentiveStrategy;
