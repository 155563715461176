import React from 'react'
import ManageTraining from '../componentsTraining/manageTraining'

export const PageInsertTraining = () => {
    return (
        <ManageTraining isUpdate={false} isView={false} />
    )
}

export const PageUpdateTraining = () => {
    return (
        <ManageTraining isUpdate={true} isView={false} />
    )
}

export const PageViewTraining = () => {
    return (
        <ManageTraining isUpdate={false} isView={true} />
    )
}
