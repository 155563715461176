import React, { Component } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { AiOutlineSearch } from "react-icons/ai";
import { Form } from "antd";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import Select from "react-select";

import {
  Col,
  FormGroup,
  Label,
  Card,
  CardHeader,
  CardBody,
  Button,
} from "reactstrap";


const columns = [
  {
    dataField: "status",
    text: "สถานะ",
  },

  {
    dataField: "userID",
    text: "รหัสพนักงาน",
  },
  {
    dataField: "fullName",
    text: "ชื่อ-นามสกุล",
  },
  {
    dataField: "TransCreateDate",
    text: "วันที่แจ้ง",
  },
  {
    dataField: "StartDateJob",
    text: "วันที่เริ่มงาน",
  },
  {
    dataField: "CostCenter",
    text: "CostCenter",
  },
  {
    dataField: "ITAsset",
    text: "IT Fixed Asset",
  },
  {
    dataField: "ITDomain",
    text: "IT Domain",
  },
  {
    dataField: "ITEmail",
    text: "IT Email",
  },
  {
    dataField: "ITSoftware",
    text: "IT Software",
  },
  {
    dataField: "PurchaseAsset",
    text: "Purchase Fixed Asset",
  },

  {
    dataField: "Action",
    text: "",
  },
];

class Assets_QuitEmpMain extends Component {
  render() {
    return (
      <div>
        {" "}
        <Card>
          <CardHeader>
            <AiOutlineSearch /> ค้นหาข้อมูลพนักงานออก
          </CardHeader>
          <CardBody>
            <Form>
              <FormGroup row>
                <Label sm={1}>รหัสพนักงาน: </Label>
                <Col sm={5}>
                  <Select
                    // options={this.state.option_UserID}
                    placeholder="กรุณาเลือก...."
                  // onChange={this.onChangeUserID}
                  />
                </Col>
                <Label sm={1}>หัวหน้า: </Label>
                <Col sm={5}>
                  <Select
                    // options={this.state.option_ManID}
                    placeholder="กรุณาเลือก...."
                  // onChange={this.onChangeMan}
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label sm={1}>วันที่เริ่มทำงาน: </Label>
                <Col sm={5}>
                  <DatePicker
                    className="form-control ignore-react-onclickoutside"
                    //minDate={new Date()}
                    name="SearchDate"
                    placeholderText="เลือกวันที่"
                    //selected={this.state.SearchDate}
                    // onChange={this.handleChangeDate}
                    dateFormat="dd/MM/yyyy"
                  />
                </Col>
                <Label sm={1}>ฝ่าย: </Label>
                <Col sm={5}>
                  <Select
                    //options={this.state.option_DivisionID}
                    placeholder="กรุณาเลือก...."
                  //onChange={this.onChangeDivisionID}
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label sm={1}>อัตรากำลัง: </Label>
                <Col sm={5}>
                  <Select
                    // options={this.state.option_ManType}
                    placeholder="กรุณาเลือก...."
                  // onChange={this.onChangeInstead}
                  />
                </Col>
                <Label sm={1}>แผนก: </Label>
                <Col sm={5}>
                  <Select
                    // options={this.state.option_DeptNo}
                    placeholder="กรุณาเลือก...."
                  // onChange={this.onChangeDeptNo}
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label sm={1}>ระดับ: </Label>
                <Col sm={5}>
                  <Select
                    // options={this.state.option_Level}
                    placeholder="กรุณาเลือก...."
                  // onChange={this.onChangeLevel}
                  />
                </Col>
                <Label sm={1}>สถานะ: </Label>
                <Col sm={5}>
                  <Select
                    // options={this.state.option_TransStatus}
                    placeholder="กรุณาเลือก...."
                  // onChange={this.onChangeTransStatus}
                  />
                </Col>
              </FormGroup>
              <hr />
              <FormGroup row>
                <Col sm={12} style={{ textAlign: "center" }}>
                  <Button color="info">
                    <AiOutlineSearch /> ค้นหา
                  </Button>
                </Col>
              </FormGroup>
            </Form>
          </CardBody>
        </Card>
        <BootstrapTable
          keyField="id"
          data={[]}
          columns={columns}
          noDataIndication={"no results found"}
        />
      </div>
    );
  }
}

export default Assets_QuitEmpMain;
