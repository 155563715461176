import React, { Fragment, useContext, useEffect, useState } from "react";
import axios from "axios";
import { AiOutlineUser } from "react-icons/ai";
import { browserHistory } from "react-router";
import { ContractContext } from "../moduleContract/servicesContract/contractProvider";
import { useOptionSelect } from "../screens/constants/OptionSelect";
import {
  filterSearchColumn,
  GET_UserLogin,
} from "../screens/functions/UseFunction";
import {
  GET,
  GET_BEARER,
  GET_DETAIL_OUT,
  POST,
  POST_ADD_USER_OUT,
  POST_BEARER,
  POST_SELECT_DATA_ASSETS_FORM,
  SELECT_ASSET_POSSESSION,
} from "../service";
import encodeString from "../extendFunction/encodeString";
import { API_URL } from "../components/connectAPI";
import {
  Modal,
  Form,
  Space,
  Row,
  Card as AntdCard,
  Col as AntdCol,
  Spin,
} from "antd";
import CustomTableAtnd from "../screens/conponents/tables/CustomTableAtnd";
import {
  Col,
  FormGroup,
  Label,
  Input,
  Card,
  CardHeader,
  CardBody,
  Button,
} from "reactstrap";

import { useSelect } from "../screens/conponents/select/SelectAntd";
import { useButton } from "../screens/conponents/buttons/CustomButtonAntd";
export const columns = ({ dropdown, actions }) => [
  {
    dataIndex: "id",
    title: "#",
    width: 50,
    fixed: "",
    align: "center",
    ...filterSearchColumn("id", "#"),
  },
  {
    dataIndex: "assetTypeDes",
    title: "ประเภทสินทรัพย์",
    width: 150,
    fixed: "",
    align: "",
    ...filterSearchColumn("assetTypeDes", "ประเภทสินทรัพย์"),
  },
  {
    dataIndex: "AssetName",
    title: "รายการสินทรัพย์",
    width: 150,
    fixed: "",
    align: "",
    ...filterSearchColumn("AssetName", "รายการสินทรัพย์"),
  },
  {
    dataIndex: "AssetID",
    title: "รหัส",
    width: 150,
    fixed: "",
    align: "",
    ...filterSearchColumn("AssetID", "รหัส"),
  },
  // {
  //   dataIndex: "AssetTransferTo",
  //   title: "ผู้รับสินทรัพย์ต่อ",
  //   width: 150,
  //   fixed: "",
  //   align: "center",
  //   ...filterSearchColumn("AssetTransferTo", "ผู้รับสินทรัพย์ต่อ"),
  //   render: (text, record) => {
  //     const [form] = Form.useForm();
  //     const { onChange } = actions;
  //     useEffect(() => {
  //       form.setFieldsValue(record);
  //     }, [form, record]);
  //     return (
  //       <Fragment>
  //         <br />
  //         <Form form={form} initialValue={record}>
  //           <Form.Item
  //             name={`AssetTransferTo_${record.key}`}
  //             wrapperCol={0}
  //             labelCol={0}
  //           >
  //             {useSelect({
  //               dropdown,
  //               componentProps: { style: { width: 300 } },
  //               fnChange: (value, ...other) =>
  //                 onChange(
  //                   record.key,
  //                   value,
  //                   `AssetTransferTo_${record.key}`,
  //                   other
  //                 ),
  //             })}
  //           </Form.Item>
  //         </Form>
  //       </Fragment>
  //     );
  //   },
  // },
];

const Assets_QuitEmpFormNew = () => {
  const [loading, setLoading] = useState(false);
  const [resultView, setResultView] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [data, setData] = useState("");
  const [form] = Form.useForm();

  let params = new URLSearchParams(window.location.search);
  let userId = encodeString.decode(params.get("userId"));
  const dropdown = useOptionSelect("empAll");
  useEffect(() => {
    getTaskQuitEmp();
  }, []);

  const onSelectChange = (selectedRowKeys, selectedRows) => {
    setSelectedRows(selectedRows);
    setSelectedRowKeys(selectedRowKeys);
  };

  const onSelectRow = {
    selectedRows,
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const getTaskQuitEmp = async () => {
    setLoading(true);
    try {
      const res = await GET_BEARER(GET_DETAIL_OUT(userId));
      const { result, success } = res;
      let mapResult = [];
      if (success) {
        setData(result.data);
        mapResult = result.data.line.map((item, index) => ({
          key: index,
          id: index + 1,
          AssetID: item.assetId,
          AssetName: item.assetName,
          AssetDesc: item.AssetDesc,
          AssetTransferTo: "",
          [`AssetTransferTo_${index}`]: "",
          assetTypeDes: item.assetTypeDesc,
        }));
        setResultView(mapResult);
        setLoading(false);
      }
    } catch (err) {
      console.log("Err get detail: ", err.message);
      Modal.error({
        title: "เกิดข้อผิดพลาด",
        content: err.message,
      });
    } finally {
      setLoading(false);
    }

    const res = await GET_BEARER(GET_DETAIL_OUT(userId));
    const { result } = res;

    setData({
      FullName: result.data.fullname,
      SupName: result.data.bossName,
      DepartmentName: result.data.depName,
      StartDateJob: result.data.startDateJob,
      StartDateJob: result.data.endDateJob,
      Location: result.data.location,
      ManType: result.data.manType,
      Level_Worker: result.data.levelWorker,
      LevelID: result.data.levelId,
    });
  };

  const handleSaveData = async () => {
    // const checkAssetTransferTo = resultView.filter(
    //   (item) => item.AssetTransferTo === ""
    // );
    // if (checkAssetTransferTo.length > 0) {
    //   return Modal.warning({
    //     title: "กรุณาระบุผู้รับสินทรัพย์ต่อให้ครบ",
    //   });
    // }

    const asset = resultView.map((item) => ({
      assetId: item.AssetID,
      transferTo: item.AssetTransferTo,
    }));
    const objBody = {
      userId: userId,
      assets: asset,
    };
    Modal.confirm({
      title: "ยืนยันการบันทึก",
      onOk: () => onPostData(objBody),
    });
  };
  const onPostData = async (objBody) => {
    setLoading(true);
    try {
      const res = await POST_BEARER(POST_ADD_USER_OUT(), objBody);
      const { success, message } = res;
      if (!success) {
        return Modal.error({
          title: "เกิดข้อผิดพลาด",
        });
      }
      Modal.success({
        title: "บันทึกสำเร็จ",
        onOk: () => browserHistory.push("/DashboardAssetEmp"),
      });
      setLoading(false);
    } catch (error) {
      Modal.error({
        title: "เกิดข้อผิดพลาด",
        content: error.message,
      });
    } finally {
      setLoading(false);
    }
  };

  const onChange = (key, value, fieldName, allValues) => {
    setResultView((prev) => {
      return prev.map((row) => {
        if (row.key === key) {
          return {
            ...row,
            ["AssetTransferTo"]: value,
            [fieldName]: value,
          };
        }
        return { ...row };
      });
    });
  };

  const onAdd = (value) => {
    if (!value.asset)
      return Modal.warning({ title: "กรุณาระบุผู้รับสินทรัพย์ต่อ" });
    console.log("value.asset", value.asset);
    setSelectedRows([]);
    setSelectedRows((prev) => {
      return prev.map((row) => {
        return {
          ...row,
          ["AssetTransferTo"]: value.asset,
          [`AssetTransferTo_${row.key}`]: value.asset,
        };
      });
    });
    selectedRowKeys.map((key) => {
      setResultView((prev) => {
        return prev.map((row) => {
          if (row.key === key) {
            return {
              ...row,
              ["AssetTransferTo"]: value.asset,
              [`AssetTransferTo_${row.key}`]: value.asset,
            };
          }
          return { ...row };
        });
      });
    });
  };

  return (
    <Spin spinning={loading}>
      <Card>
        <CardHeader>
          <AiOutlineUser /> ข้อมูลพนักงานลาออก
        </CardHeader>
        <CardBody>
          <Form>
            <FormGroup row>
              <Label sm={1}>รหัส/ชื่อพนักงาน: </Label>
              <Col sm={5}>
                <Input
                  type="text"
                  name="UserName"
                  id="UserName"
                  value={data.fullname}
                  readOnly
                />
              </Col>
              <Label sm={1}>รหัส/ชื่อหัวหน้า: </Label>
              <Col sm={5}>
                <Input
                  type="text"
                  name="SupName"
                  id="SupName"
                  value={data.bossName}
                  readOnly
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label sm={1}>วันที่เริ่มทำงาน: </Label>
              <Col sm={5}>
                <Input
                  type="text"
                  name="StartDateJob"
                  id="StartDateJob"
                  value={data.startDateJob}
                  readOnly
                />
              </Col>
              <Label sm={1}>วันที่ออก: </Label>
              <Col sm={5}>
                <Input
                  type="text"
                  name="EndDateJob"
                  id="EndDateJob"
                  value={data.endDateJob}
                  readOnly
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label sm={1}>สถานที่: </Label>
              <Col sm={5}>
                <Input
                  type="text"
                  name="Location"
                  id="Location"
                  value={data.location}
                  readOnly
                />
              </Col>
              <Label sm={1}>แผนก: </Label>
              <Col sm={5}>
                <Input
                  type="text"
                  name="DepartmentName"
                  id="DepartmentName"
                  value={data.depName}
                  readOnly
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label sm={1}>ระดับ: </Label>
              <Col sm={5}>
                <Input
                  type="text"
                  name="Level_Worker"
                  id="Level_Worker"
                  value={data.levelWorker}
                  readOnly
                />
              </Col>
            </FormGroup>
          </Form>
        </CardBody>
      </Card>

      {/* <AntdCard>
        <Form form={form} onFinish={onAdd}>
          <Row>
            <AntdCol span={8} offset={16}>
              <Form.Item
                label={"ผู้รับสินทรัพย์ต่อ"}
                name={"asset"}
                wrapperCol={0}
                labelCol={0}
              >
                {useSelect({
                  dropdown,
                  // componentProps: { style: { width: 300 } },
                  // fnChange: (value, ...other) =>
                  //   onChange(record.key, value, "AssetTransferTo", other),
                })}
              </Form.Item>
            </AntdCol>
          </Row>
          <Space
            direction="horizontal"
            style={{ width: "100%", justifyContent: "end" }}
          >
            {useButton({
              call: "save",
              isDisable: selectedRows.length > 0 ? false : true,
            })}
          </Space>
        </Form>
      </AntdCard> */}
      <br />

      <CustomTableAtnd
        loading={loading}
        HeadTable={columns({
          actions: { onChange },
          dropdown,
        })}
        DataTable={resultView}
        moreProps={{
          scroll: {
            x: 1300,
            y: 1000,
          },
          //rowSelection: onSelectRow,
        }}
      />

      {/* <BootstrapTable keyField="id" data={resultView} columns={columns} /> */}
      <center>
        <Button
          type="submit"
          color="success"
          className="text-center"
          onClick={handleSaveData}
        >
          บันทึก
        </Button>
      </center>
    </Spin>
  );
};

export default Assets_QuitEmpFormNew;
