import { PATHNAME } from "../../../constants/enums/pathname"

let DEFAULT_BREADCRUMB = [
    { label: "HRM - PMS" },
    { label: "บันทึกการเข้ารับการอบรม" },
]

let DEFAULT_BREADCRUMB_MANAGE = [
    ...DEFAULT_BREADCRUMB,
    { label: "หน้าบันทึกผลการอบรมการฝึกปฏิบัติงาน (OJT)" },
]

export function renderLabelWithPathname(pathname) {
    switch (pathname) {
        case PATHNAME.VIEW_TRAINING: return "ดูรายละเอียดเอกสารการเข้ารับการอบรม"
        case PATHNAME.INSERT_TRAINING: return "สร้างเอกสารการเข้ารับการอบรม"
        case PATHNAME.UPDATE_TRAINING: return "แก้ไขเอกสารการเข้ารับการอบรม"
        case PATHNAME.IMPORT_TRAINING: return "Import รายการเข้ารับการอบรม"
        case PATHNAME.DASHBOARD_TRAINING: return "หน้าบันทึกผลการอบรมการฝึกปฏิบัติงาน (OJT)"
        case PATHNAME.DASHBOARD_HISTORY_INDIVIDUAL: return "รายงานบันทึกประวัติผลการฝึกอบรมรายบุคคล"
        case PATHNAME.DASHBOARD_HISTORY_TRAINERS: return "รายงานบันทึกการฝึกอบรมของผู้ฝึกสอน"
        default:
            break;
    }
}

export const BREADCRUMB_TRAINING = (_pathname) => {
    return [...DEFAULT_BREADCRUMB, { label: renderLabelWithPathname(_pathname) }]
}

export const BREADCRUMB_MANAGE_TRAINING = ({ pathname }) => {
    return [...DEFAULT_BREADCRUMB_MANAGE, { label: renderLabelWithPathname(pathname) }]
}