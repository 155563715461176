import { responseAPI } from "../../../moduleKPIs/common/functions/funcResponse";
import servicesAPI from "../../../moduleKPIs/services/servicesAPI";

let API = servicesAPI.create()


export async function APIInsertReviewer(payload, nextAction) {
    try {
        const response = await API.insertReviewer(payload);
        responseAPI(response, nextAction)
    } catch (error) {
        console.error('error : ', error)
    }
}

export async function APIUpdateReviewer(payload, nextAction) {
    try {
        const response = await API.updateReviewer(payload);
        responseAPI(response, nextAction)
    } catch (error) {
        console.error('error : ', error)
    }
}

export async function APIInsertEvac(payload, nextAction) {
    try {
        const response = await API.insertEvac(payload);
        responseAPI(response, nextAction)
    } catch (error) {
        console.error('error : ', error)
    }
}

export async function APIUpdateEvac(payload, nextAction) {
    try {
        const response = await API.updateEvac(payload);
        responseAPI(response, nextAction)
    } catch (error) {
        console.error('error : ', error)
    }
}