import React, { useContext } from 'react';
import '../CSSPersonal.css'

import { KEY_PERSONAL, PersonalContext } from '../servicesPersonal/personalProvider'
import ManagePersonal from '../componentsPersonal/managePersonal';
import { PATHNAME } from '../../constants/enums/pathname';
import { STEPS_PROGRESS_INSERT } from '../constantsPersonal/insertPersonal';

const InsertPersonal = () => {
    const { personalState } = useContext(PersonalContext);
    return (
        <ManagePersonal
            referanceNo={null}
            initial={{ ...personalState[KEY_PERSONAL.INSERT_PERSONAL] }}
            pathname={PATHNAME.INSERT_PERSONAL}
            process={STEPS_PROGRESS_INSERT} />
    )
}

export default InsertPersonal

