import { createRef } from "react";
import { MESSAGE_ERROR } from "../index.jsx";
import { columnSearchProps, columnSortDate } from "../../../components/structuresReport/function";
import { KEY_ACTIVE_TAB, KEY_TYPE } from "../../../constants/enums/enumCenter";
import { BREADCRUMB_PERSONAL } from "../breadcrumbPersonal.jsx";
import { FIELD_EMP } from "../contents/employeeInfo.jsx";
import { FIELD_PERSONAL } from "../contents/personalInfo.jsx";

/* -------------------------------------------------------------------------- */
/*                                  FUNCTION                                  */
/* -------------------------------------------------------------------------- */
export function formatCadidateNo(_value) {
    let Y = `${new Date().getFullYear()}`.substring(0, 2);
    let M = `${new Date().getMonth() + 1}`.substring(0, 2);

    return `NO${`${Y}${`${(M.length < 10) ? `0${M}` : M}`}-000`}`
}
/* -------------------------------------------------------------------------- */


/* -------------------------------------------------------------------------- */
/*                                 FIELD_PAGES                                */
/* -------------------------------------------------------------------------- */

const FIELD_PAGES = {
    //!เปลี่ยนชื่อ Field ที่นี่
    /* ------------------------- TABLE / EXPORT / SEARCH ------------------------ */
    CANDIDATE_NO: FIELD_EMP.CANDIDATE_NO,
    FULLNAME: FIELD_EMP.FULLNAME_TH,
    NICKNAME: FIELD_EMP.NICKNAME_TH,
    TEL: FIELD_PERSONAL.MOBILE_PHONE,
    START_DATE_JOB: FIELD_EMP.START_DATE_JOB,
    JOB_POSITION: FIELD_EMP.JOB_POSITION,
    /* -------------------------------------------------------------------------- */

}

/* -------------------------------------------------------------------------- */
/*                                 LABEL_PAGES                                */
/* -------------------------------------------------------------------------- */

const LABEL_PAGES = {
    //!เปลี่ยนชื่อ Label ที่นี่
    /* ------------------------- TABLE / EXPORT / SEARCH ------------------------ */
    CANDIDATE_NO: 'เลขที่เอกสาร',
    FULLNAME: 'ชื่อ - นามสกุล',
    NICKNAME: 'ชื่อเล่น',
    TEL: 'เบอร์โทรศัพท์',
    START_DATE_JOB: 'วันที่เริ่มงาน',
    JOB_POSITION: 'ตำแหน่ง',
    /* ------------------------------  ----------------------------- */
}

/* -------------------------------------------------------------------------- */
/*                                 TEXT_PAGES                                 */
/* -------------------------------------------------------------------------- */

const TEXT_PAGES = {
    //!เปลี่ยนชื่อ Label Tabs ที่นี่
    HEADER_REPORT: 'จัดการพนักงานใหม่',
    CONTENT_REPORT: '',
    FILE_EXPORT: {
        [KEY_ACTIVE_TAB.IMPORT_PERSONAL]: 'Import พนักงานใหม่',
    },
    TAB: {
        [KEY_ACTIVE_TAB.IMPORT_PERSONAL]: 'Import พนักงานใหม่',
    }
}

/* -------------------------------------------------------------------------- */
/*                                 ICON_PAGES                                 */
/* -------------------------------------------------------------------------- */

const ICON_PAGES = {
    /**
     * TODO: สามารถใช้ Svg ได้ Exmple : TODO: <Svg.name />
     */
    HEADER_REPORT: null,
    CONTENT_REPORT: null,
    FILE_EXPORT: {
        [KEY_ACTIVE_TAB.IMPORT_PERSONAL]: null,
    },
    TAB: {
        [KEY_ACTIVE_TAB.IMPORT_PERSONAL]: null,
    }
}

/* -------------------------------------------------------------------------- */
/*                                 FORM_PAGES                                 */
/* -------------------------------------------------------------------------- */

const FORM_PAGES = {
    BREADCRUMB: (props) => BREADCRUMB_PERSONAL({ ...props }),
    TABS: (props) => TABS_PAGES({ ...props }),
    TABLE: (props) => TABLE_PAGES({ ...props }),
    SEARCH: (props) => SEARCH_PAGES({ ...props }),
    EXPORT_EXCEL: (props) => EXPORT_PAGES({ ...props }),
}

/* -------------------------------------------------------------------------- */
/*                                 TABS_PAGES                                 */
/* -------------------------------------------------------------------------- */

const TABS_PAGES = (props) => {
    const { renderTable } = props;
    /**
     * TODO: สามารถแบ่งตาม activeTab , location ได้ โดยใช้ switch case
     */
    return [{
        key: 1,
        tab: TEXT_PAGES.TAB[KEY_ACTIVE_TAB.IMPORT_PERSONAL],
        icon: '',
        content: renderTable,
        required: false
    }]

}

/* -------------------------------------------------------------------------- */
/*                                 TABLE_PAGES                                */
/* -------------------------------------------------------------------------- */

const TABLE_PAGES = (props) => {
    const searchInput = createRef();
    const { actionButton } = props;

    /**
     * TODO: สามารถแบ่งตาม activeTab , location ได้ โดยใช้ switch case
     * TODO: Filter ตาม Text ใช้ columnSearchProps(filedname,searchInput)
     * TODO: Filter ตาม Number ใช้ columnSort(filedname)
     * TODO: Filter ตาม Date ใช้ columnSortDate(filedname)
     **/

    return [{
        dataIndex: FIELD_PAGES.CANDIDATE_NO,
        title: LABEL_PAGES.CANDIDATE_NO,
        width: 100,
        fixed: '',
        align: '',
        ...columnSearchProps(FIELD_PAGES.CANDIDATE_NO, searchInput)
    }, {
        dataIndex: FIELD_PAGES.FULLNAME,
        title: LABEL_PAGES.FULLNAME,
        width: 100,
        fixed: '',
        align: '',
        ...columnSearchProps(FIELD_PAGES.FULLNAME, searchInput)
    }, {
        dataIndex: FIELD_PAGES.NICKNAME,
        title: LABEL_PAGES.NICKNAME,
        width: 100,
        fixed: '',
        align: '',
        ...columnSearchProps(FIELD_PAGES.NICKNAME, searchInput)
    }, {
        dataIndex: FIELD_PAGES.TEL,
        title: LABEL_PAGES.TEL,
        width: 100,
        fixed: '',
        align: '',
        ...columnSearchProps(FIELD_PAGES.TEL, searchInput)
    }, {
        dataIndex: FIELD_PAGES.START_DATE_JOB,
        title: LABEL_PAGES.START_DATE_JOB,
        width: 100,
        fixed: '',
        align: '',
        ...columnSortDate(FIELD_PAGES.START_DATE_JOB)
    }, {
        dataIndex: FIELD_PAGES.JOB_POSITION,
        title: LABEL_PAGES.JOB_POSITION,
        width: 100,
        fixed: '',
        align: '',
        ...columnSearchProps(FIELD_PAGES.JOB_POSITION, searchInput)
    }, {
        dataIndex: '',
        title: '',
        width: 100,
        fixed: 'right',
        align: 'center',
        render: actionButton
    }]
}

/* -------------------------------------------------------------------------- */
/*                                SEARCH_PAGES                                */
/* -------------------------------------------------------------------------- */

const SEARCH_PAGES = (props) => {
    // const { activeTab, location, options, actionButton } = props;
    /**
     * TODO: สามารถแบ่งตาม activeTab , location ได้ โดยใช้ switch case
     */

    return [{
        span: 12,
        itemprops: {
            name: FIELD_PAGES.CANDIDATE_NO,
            label: LABEL_PAGES.CANDIDATE_NO,
            rules: [{ required: false, message: MESSAGE_ERROR.INPUT }]
        },
        inputprops: {
            type: KEY_TYPE.INPUT,
            disabled: false,
            placeholder: formatCadidateNo(),
        }
    }]
}

/* -------------------------------------------------------------------------- */
/*                                EXPORT_PAGES                                */
/* -------------------------------------------------------------------------- */

export const EXPORT_PAGES = ({ activeTab }) => {
    return [{
        id: 1,
        title: LABEL_PAGES.CANDIDATE_NO,
        field: FIELD_PAGES.CANDIDATE_NO
    }, {
        id: 2,
        title: LABEL_PAGES.FULLNAME,
        field: FIELD_PAGES.FULLNAME
    }, {
        id: 3,
        title: LABEL_PAGES.NICKNAME,
        field: FIELD_PAGES.NICKNAME
    }, {
        id: 4,
        title: LABEL_PAGES.TEL,
        field: FIELD_PAGES.TEL
    }, {
        id: 5,
        title: LABEL_PAGES.START_DATE_JOB,
        field: FIELD_PAGES.START_DATE_JOB
    }, {
        id: 6,
        title: LABEL_PAGES.JOB_POSITION,
        field: FIELD_PAGES.JOB_POSITION
    }]
}

/* -------------------------------------------------------------------------- */
/*                                   EXPORT                                   */
/* -------------------------------------------------------------------------- */

export const TEXT_DASHBOARD_IMPORT_PERSONLA = TEXT_PAGES;
export const ICON_DASHBOARD_IMPORT_PERSONLA = ICON_PAGES;
export const FORM_DASHBOARD_IMPORT_PERSONLA = FORM_PAGES;
export const FIELD_DB_IMPORT_PERSONAL = FIELD_PAGES;
export const LABEL_DB_IMPORT_PERSONAL = LABEL_PAGES;

