import React, { useState, useEffect } from "react";
import { Form, Card, Row, Col, Spin, Tabs } from "antd";
import { SelectAntd } from "../select/SelectAntd";
import { KPIs, USE_CONNECT_MODE_AUTO } from "../../services/ConnectAPI";
import {
  optionStatusWorker,
  useOptionSelect,
} from "../../constants/OptionSelect";
import { browserHistory } from "react-router";
import { useButton } from "../buttons/CustomButtonAntd";
import { GET_UserLogin } from "../../functions/UseFunction";
import FormEditIndicatorList from "./FormEditIndicatorList";
import { useAlert } from "../modal/CustomAlert";
import { useConfirm } from "../modal/CustomConfirm";

export const FormEditKPIs = (props) => {
  const [form] = Form.useForm();
  const { indicatorNo } = props.params;
  const { userlogin } = GET_UserLogin();
  //[START] State
  const [changeSearchValue, setChangeSearchValue] = useState({ active: true });

  const [VolumeData, setVolumeData] = useState([]);
  const [QualityData, setQualityData] = useState([]);
  const [BehaviorData, setBehaviorData] = useState([]);

  const [loadingScreen, setloadingScreen] = useState(false);

  let optionPosition = useOptionSelect("position");
  //[END] State

  // [START] useEffect
  useEffect(() => {
    Promise.all([GET_UPDATE_KPIs()]);
  }, []);
  // [END] useEffect

  // [START] Handle Form Antd

  const onFinish = (values) => {
    if (!changeSearchValue) return null
    try {
      let Remove_IndicatorLine = [];
      let MAP_Volume = VolumeData
        ? VolumeData.map((val) => ({ indicatorGroup: "volume", ...val }))
        : [];
      let MAP_Quality = QualityData
        ? QualityData.map((val) => ({ indicatorGroup: "quality", ...val }))
        : [];
      let MAP_Behavior = BehaviorData
        ? BehaviorData.map((val) => ({ indicatorGroup: "behavior", ...val }))
        : [];

      const indicators = [...MAP_Volume, ...MAP_Quality, ...MAP_Behavior];
      for (let Round in indicators) {
        const {
          indicatorLine,
          company,
          location,
          positionName,
          statusActive,
          ...rest
        } = indicators[Round];
        Remove_IndicatorLine.push(rest);
      }
      let PUT_DATA = {
        positionCode: values.positionCode,
        statusWorker: values.statusWorker,
        active: true,
        userlogin,
        indicators: Remove_IndicatorLine,
      };
      useConfirm({
        FnOK: () => PUT_INSERT_KPIs(PUT_DATA),
      });
    } catch (err) {
      console.log(err);
    }
  };
  const onReset = () => {
    GET_UPDATE_KPIs();
  };
  // [END] Handle Form Antd

  // [START] REST API
  const GET_UPDATE_KPIs = async () => {
    try {
      setloadingScreen(true);
      const url = `${USE_CONNECT_MODE_AUTO}${KPIs.GET_SELECT_KPIs}?indicatorNo=${indicatorNo}`;
      const response = await fetch(url);
      const data = await response.json();
      if (response.ok) {
        const RESULT = data.result;
        if (RESULT.length !== 0) {
          const { indicators, ...others } = RESULT[0];
          const filterVolume = indicators.filter(
            (val) => val.indicatorGroup === "volume"
          );
          const filterQuality = indicators.filter(
            (val) => val.indicatorGroup === "quality"
          );
          const filterBehavior = indicators.filter(
            (val) => val.indicatorGroup === "behavior"
          );
          setVolumeData(
            filterVolume.sort((a, b) => a.indicatorLine - b.indicatorLine)
          );
          setQualityData(
            filterQuality.sort((a, b) => a.indicatorLine - b.indicatorLine)
          );
          setBehaviorData(
            filterBehavior.sort((a, b) => a.indicatorLine - b.indicatorLine)
          );
          const Merge_Value = {
            ...others,
            Volume: filterVolume,
            Quality: filterQuality,
            Behavior: filterBehavior,
          };
          form.setFieldsValue(Merge_Value);
          setChangeSearchValue(Merge_Value);
          setloadingScreen(false);
        }
      } else {
        useAlert({
          type: "error",
          title: `ไม่พบข้อมูล ${indicatorNo} ในระบบ`,
          content: `กรุณาลองใหม่อีกครั้ง`,
          Fn: () => browserHistory.push("/ManageKPIs"),
        });
      }
    } catch (err) {
      setloadingScreen(false);
      console.log("Error GET update KPIs :", err);
    }
  };

  const PUT_INSERT_KPIs = async (objectValue) => {
    try {
      setloadingScreen(true);
      const url = `${USE_CONNECT_MODE_AUTO}${KPIs.PUT_UPDATE_KPIs}/${indicatorNo}`;
      const response = await fetch(url, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(objectValue),
      });
      const data = await response.json();
      if (response.ok) {
        useAlert({
          type: "success",
          title: `บันทึกข้อมูลสำเร็จ`,
          Fn: () => browserHistory.push("/ManageKPIs"),
        });
      } else {
        useAlert({
          type: "error",
          title: `เกิดข้อผิดพลาด`,
          content: `${data.message}`,
          Fn: () => setloadingScreen(false),
        });
      }
    } catch (err) {
      setloadingScreen(false);
      console.log("Error Edit KPIs :", err);
    }
  };
  // [END] REST API

  // [START] Function
  const onChangeValue = (val, name) => {
    setChangeSearchValue((prev) => ({
      ...prev,
      [name]: val,
    }));
  };

  const GET_VALUE_LIST = (value, name) => {
    const getVolume = form.getFieldValue("Volume");
    setVolumeData(getVolume);
    const getQuality = form.getFieldValue("Quality");
    setQualityData(getQuality);
    const getBehavior = form.getFieldValue("Behavior");
    setBehaviorData(getBehavior);
  };
  // [END] Function

  return (
    <>
      <Form
        form={form}
        onFinish={onFinish}
        labelCol={{
          span: 24,
        }}
        wrapperCol={{
          span: 24,
        }}
      >
        <Spin tip="Loading..." spinning={loadingScreen}>
          <Card title="แก้ไขข้อมูล KPIs" style={{ marginBottom: 30 }}>
            <Row gutter={[16, 16]} justify="start" align="bottom">
              <Col xs={24} sm={12} md={12} lg={8} xl={10}>
                <SelectAntd
                  name={`positionCode`}
                  label={`ตำแหน่งงาน`}
                  smgReq={`กรุณาเลือก ตำแหน่งงาน`}
                  placeholder={`เลือก ตำแหน่งงาน`}
                  dropdown={optionPosition}
                  FnOnChange={onChangeValue}
                  required={true}
                  isDisable={true}
                />
              </Col>
              <Col xs={24} sm={12} md={12} lg={8} xl={4}>
                <SelectAntd
                  name={`statusWorker`}
                  label={`สถานะพนักงาน`}
                  smgReq={`กรุณาเลือก สถานะพนักงาน`}
                  placeholder={`เลือก สถานะพนักงาน`}
                  dropdown={optionStatusWorker}
                  FnOnChange={onChangeValue}
                  required={true}
                />
              </Col>
            </Row>
          </Card>
          <Tabs defaultActiveKey="1">
            <Tabs.TabPane tab="Volume" key="1">
              <FormEditIndicatorList
                ListName={`Volume`}
                handleChangeList={GET_VALUE_LIST}
                DataIndicator={VolumeData}
                Fn={GET_UPDATE_KPIs}
                IndicatorKey={indicatorNo}
              />
            </Tabs.TabPane>

            <Tabs.TabPane tab="Quality" key="2">
              <FormEditIndicatorList
                ListName={`Quality`}
                handleChangeList={GET_VALUE_LIST}
                DataIndicator={QualityData}
                Fn={GET_UPDATE_KPIs}
                IndicatorKey={indicatorNo}
              />
            </Tabs.TabPane>

            <Tabs.TabPane tab="Behavior" key="3">
              <FormEditIndicatorList
                ListName={`Behavior`}
                handleChangeList={GET_VALUE_LIST}
                DataIndicator={BehaviorData}
                Fn={GET_UPDATE_KPIs}
                IndicatorKey={indicatorNo}
              />
            </Tabs.TabPane>
          </Tabs>
          <Row
            gutter={[16, 16]}
            style={{ marginTop: 20 }}
            justify="center"
            align="bottom"
          >
            <Col xs={12} sm={12} md={12} lg={6} xl={3}>
              <Form.Item>{useButton({ call: "save" })}</Form.Item>
            </Col>
            <Col xs={12} sm={12} md={12} lg={6} xl={3}>
              <Form.Item>
                {useButton({
                  call: "normal",
                  fn: onReset,
                  moreProps: { label: "ล้างข้อมูล" },
                })}
              </Form.Item>
            </Col>
          </Row>
        </Spin>
      </Form>
    </>
  );
};

export default FormEditKPIs;
