import React, { createContext, useReducer, useContext } from "react";
import { APISelectUserRequestOT, APIUpdateCFApprovalOT, APIUpdateRecallROT, APIDelRequestOTLineAll, APIDelRequestOTLine } from "./manageOTConnect";
import { FIELD_BTN } from "../../commonsOT/structuresOT/buttonOT";
import { printDiv } from "../../../moduleKPIs/common/functions";
import { path } from "ramda";
import { MASTER_OPTION, REPORT_OPTION } from "../../../moduleKPIs/services/servicesOptions";
import { ReportOTContext } from "../reportOT/reportOTProvider";
import { PATHNAME } from "../../../constants/enums/pathname";
import { browserHistory } from "react-router";
import { Modal } from "antd";
import { POST } from "../../../service";
import { CONFIG } from "../../../components/connectAPI";
import { APILineNotify, KEY_LOGS } from "../../../functions/notifications/lineNotify";

export const ManageOTContext = createContext();

const initialState = {
    userData: []
}
const MANAGE_OT_ACTION = {
    SET_MANAGE_OT_SUCCESS: "SET_MANAGE_OT_SUCCESS",
    SET_MANAGE_OT_FAILURE: "SET_MANAGE_OT_FAILURE"
}

const manageOTReducer = (state, action) => {
    switch (action.type) {
        case MANAGE_OT_ACTION.SET_MANAGE_OT_SUCCESS:
            return { ...state, ...action.state }
        case MANAGE_OT_ACTION.SET_MANAGE_OT_FAILURE:
        default:
            return state
    }
}


const ProviderManageOT = ({ children }) => {
    const { userLogin, userPermission } = children.props;
    const [manageOTState, manageOTDispatch] = useReducer(manageOTReducer, initialState)
    const { getReports } = useContext(ReportOTContext);

    async function getUser(payload) {

        await APISelectUserRequestOT({ ...payload }, res => {
            if (res) {
                manageOTDispatch({
                    type: MANAGE_OT_ACTION.SET_MANAGE_OT_SUCCESS,
                    state: { userData: res }
                })
            }
        })
    }



    async function onClickButton({ fieldBtn, pathname, payload }) {
        let payloadDefault = {}
        let response = null
        try {
            //console.log('nut', payload)
            //console.warn('[CLICK]', fieldBtn)
            switch (fieldBtn) {
                // case FIELD_BTN.CONFIRM_REPORT_OT:
                //     console.log('CONFIRM_REPORT_OT')
                //     break;
                case FIELD_BTN.RECALL_REPORT_OT: // ปุ่มตีกลับเอกสารหน้า REPORT_OT_FACTORY
                    //console.log('RECALL_REPORT_OT', payload, userLogin)
                    let payloadRecall = {
                        docNo: path(['docNo'], payload) || '',
                        createBy: userLogin
                    }
                    Modal.confirm({
                        title: 'คุณต้องการตีกลับเอกสารใช่หรือไม่ ?',
                        onOk: async () => {
                            let res = await APIUpdateRecallROT({ ...payloadRecall })
                            if (!!res.success) {
                                Modal.success({
                                    title: 'บันทึกสำเร็จ',
                                    content: `เลขที่เอกสาร : ${res.result.docNo || ''}`,
                                    onOk: () => {
                                        window.location.replace(PATHNAME.DASHBOARD_OT_FACTORY)
                                    }
                                })
                            }
                        }
                    })

                    break;
                case FIELD_BTN.APPROVAL_LIST_REPORT_OT: // ปุ่มอนุมัติหลายรายการ
                    //console.log('APPROVAL_LIST_REPORT_OT', payload, userLogin)
                    payloadDefault = {
                        dataList: payload.dataList,
                        userLogin,
                        userPermission
                    }

                    Modal.confirm({
                        title: 'คุณอนุมัติเอกสารใช่หรือไม่ ?',
                        onOk: async () => {
                            try {
                                // console.log({ payloadDefault });
                                response = await POST(`${CONFIG.FACTORY.UPDATE_CF_LIST_APPROVAL_OT}`, { ...payloadDefault })
                                if (response.success) {
                                    Modal.success({
                                        title: 'บันทึกสำเร็จ',
                                        onOk: () => {
                                            window.location.replace(PATHNAME.DASHBOARD_OT_FACTORY)
                                        }
                                    })
                                }
                            } catch (error) {
                                console.log({ error })
                                //! line Noti
                                APILineNotify({
                                    key: KEY_LOGS.ERROR_CATCH,
                                    message: `${error.message}`,
                                    props: {
                                        userLogin: `${userLogin}`,
                                        title: `⛔️ APPROVAL_LIST_REPORT_OT`,
                                        payload: { ...payloadDefault }
                                    }
                                })
                            }
                        }
                    })

                    break;
                case FIELD_BTN.APPROVAL_REPORT_OT: // ปุ่มอนุมัติเอกสารหน้า REPORT_OT_FACTORY
                    //console.log('APPROVAL_REPORT_OT', payload, userLogin)
                    let payloadConfirm = {
                        docNo: path(['docNo'], payload) || '',
                        statusCode: path(['statusCode'], payload) || '',
                        sameApproval: path(['sameApproval'], payload) || '',
                        createBy: userLogin,
                        userPermission

                    }
                    Modal.confirm({
                        title: 'คุณอนุมัติเอกสารใช่หรือไม่ ?',
                        onOk: async () => {
                            try {
                                let res = await APIUpdateCFApprovalOT({ ...payloadConfirm })
                                if (!!res.success) {
                                    Modal.success({
                                        title: 'บันทึกสำเร็จ',
                                        onOk: () => {
                                            window.location.replace(PATHNAME.DASHBOARD_OT_FACTORY)
                                        }
                                    })
                                }
                            } catch (error) {
                                console.log({ error })
                            }
                        }
                    })

                    break;
                case FIELD_BTN.REMOVE_REQUEST_OT:  // ปุ่ม ลบ ใน TABLE หน้า DASHBOARD_OT_FACTORY
                    //console.log('REMOVE_REQUEST_OT', payload)
                    let payloadDelRequest = {
                        docNo: path(['docNo'], payload) || '',
                        userLogin
                    }
                    Modal.confirm({
                        title: 'คุณต้องการลบข้อมูลใช่หรือไม่ ?',
                        onOk: async () => {
                            await APIDelRequestOTLineAll({ ...payloadDelRequest }, async res => {
                                let payloadSearchOT = JSON.parse(window.localStorage.getItem("searchDashboardOT"))
                                if (!!!!res && res.success) {
                                    Modal.success({
                                        title: 'ลบข้อมูลสำเร็จ',
                                        onOk: () => {
                                            getReports({ fieldname: REPORT_OPTION.DASHBOARD_OT_FACTORY, payload: { ...payloadSearchOT, userLogin } })
                                        }
                                    })
                                }
                                // console.log('RECALL_REPORT_OT', res)
                                // if (res) {
                                //     await getReports({ fieldname: REPORT_OPTION.DASHBOARD_OT_FACTORY, payload: { ...payloadSearchOT } })
                                //     // await window.location.reload()
                                //     await window.localStorage.removeItem('searchDashboardOT')
                                // }
                            })
                        }
                    })
                    break;

                case FIELD_BTN.REMOVE_EMP_REQUEST_OT:  // ปุ่ม ลบ ใน TABLE หน้า UPDATE_OT_FACTORY
                    // console.log('REMOVE_EMP_REQUEST_OT', payload)
                    // if (payload) {
                    Modal.confirm({
                        title: 'คุณต้องการลบข้อมูลใช่หรือไม่ ?',
                        onOk: async () => {
                            let payloadDelLine = {
                                docNo: payload.docNo || '',
                                linenum: 2//payload.linenum || ''
                            }
                            //console.log({ payloadDelLine })
                            await APIDelRequestOTLine({ ...payloadDelLine }, async res => {
                                //console.log('REMOVE_EMP_REQUEST_OT', res)
                                if (res) {
                                    await getReports({ fieldname: REPORT_OPTION.REPORT_OT_FACTORY, payload: { docNo: payload.docNo, userLogin } })
                                    await window.location.reload()
                                }
                            })
                        }
                    })
                    break;
                case FIELD_BTN.PRINT_REPORT_OT: // ปุ่มพิมพ์เอกสารหน้า REPORT_OT_FACTORY
                    await printDiv('reportOT')
                    break;
                case FIELD_BTN.SEARCH_EXPORT_OT:
                    let payloadExport = {
                        startDateTime: path(['activeDate', 0], payload) || '',
                        endDateTime: path(['activeDate', 1], payload) || '',
                        subDepNo: path([MASTER_OPTION.FILTER_SUB_DEP_OT, 0], payload) || '',
                    }
                    //console.log('SEARCH_EXPORT_OT', payloadExport)
                    await getReports({ fieldname: REPORT_OPTION.EXPORT_CSV__OT_FACTORY, payload: { ...payloadExport } })
                    break;
                case FIELD_BTN.SEARCH_DASHBOARD_OT: // ปุ่มค้นหาหน้า DASHBOARD_OT_FACTORY
                    //console.log('SEARCH_DASHBOARD_OT', payload)
                    let payloadSearch = null
                    let newSupDepNo = []
                    if (!path([MASTER_OPTION.FILTER_SUB_DEP_OT, 0], payload)) {
                        Array.isArray(path([`OPTION_${MASTER_OPTION.FILTER_SUB_DEP_OT}`], payload.options)) &&
                            path([`OPTION_${MASTER_OPTION.FILTER_SUB_DEP_OT}`], payload.options).filter((e) => !e.optionValue || e.optionValue !== ' ')
                                .forEach(({ optionValue }) => newSupDepNo.push(optionValue));
                        // Array.isArray(newSupDepNo) && newSupDepNo.shift()
                    }
                    // console.log({ newSupDepNo })
                    payloadSearch = {
                        statusCode: path([MASTER_OPTION.FILTER_STATUS_OT, 0], payload) || '',
                        startDateTime: path(['activeDate', 0], payload) || '',
                        endDateTime: path(['activeDate', 1], payload) || '',
                        subDepNo: path([MASTER_OPTION.FILTER_SUB_DEP_OT, 0], payload) || newSupDepNo || '',
                        userLogin: userLogin,
                        createPermission: userPermission,
                        activeTab: payload.activeTab,
                    }

                    //  console.log('===========================================');
                    //   console.log('dddddd', payloadSearch)
                    //   console.log('===========================================');
                    await window.localStorage.setItem('searchDashboardOT', JSON.stringify(payloadSearch))
                    await getReports({ fieldname: REPORT_OPTION.DASHBOARD_OT_FACTORY, payload: { ...payloadSearch, userLogin, userPermission } })
                    break;
                case FIELD_BTN.UPDATE_REQUEST_OT:  // ปุ่ม แก้ไข ใน TABLE หน้า DASHBOARD_OT_FACTORY
                case FIELD_BTN.VIEW_DASHBOARD_OT:// ปุ่ม ดูรายละเอียด ใน TABLE หน้า DASHBOARD_OT_FACTORY
                case FIELD_BTN.VIEW_DASHBOARD_OT_APD:// ปุ่ม ดูรายละเอียด ใน TABLE หน้า DASHBOARD_OT_FACTORY สถานยอนุมัติแล้ว
                case FIELD_BTN.APPROVAL_DASHBOARD_OT:// ปุ่ม ดูรายละเอียด ใน TABLE หน้า DASHBOARD_OT_FACTORY
                    // await getReports({ fieldname: REPORT_OPTION.REPORT_OT_FACTORY, payload: { docNo: payload.docNo, userLogin } })
                    await getReports({ fieldname: REPORT_OPTION.REPORT_OT_FACTORY, payload: { docNo: payload.docNo, userLogin, record: payload } })
                    await window.open(pathname)
                    // await window.close()
                    break;
                case FIELD_BTN.LINK_REPORT:
                    await window.localStorage.setItem('refDocNo', JSON.stringify(payload.docNo))
                    await browserHistory.push({ pathname: pathname })
                    break;
                default:
                    console.error('FIELD NAME NOT FOUND')
                    break;
            }
        } catch (error) {
            console.log({ error })
        }

    }
    return (
        <ManageOTContext.Provider
            value={{
                userLogin: { userId: userLogin, userPermission },
                getUser,
                onClickButton,
                manageOTState,
                manageOTDispatch
            }}>
            {children}
        </ManageOTContext.Provider>
    )
}


export default ProviderManageOT