/***
 * 1. Error Catch => Key / payload / userLogin , location 
 * 2. Get Dashboard => Config / payload / userLogin , location / activeTabs 
 * 3. Post API => Config / payload / userLogin , location  
 * 4. Success Response => Config / res
 * 5. Get Master => Page / resOption []
 * 
 * NotiInfo / NotiError 
 *  
 */

import { CONFIG } from "../../components/connectAPI";
import { POST } from "../../service";

export const KEY_LOGS = {
    ERROR_CATCH: 'ERROR_CATCH',
    GET_DASHBOARD: 'GET_DASHBOARD',
    POST_API: 'POST_API',
    SUCCESS_RESPONSE: 'SUCCESS_RESPONSE',
}



export function APILineNotify({
    key = '',
    message = '',
    props = {
        userLogin: '',
        title: '',
        payload: {},
    }
}) {

    let activeLogs = {
        [KEY_LOGS.ERROR_CATCH]: true,
        [KEY_LOGS.GET_DASHBOARD]: true,
        [KEY_LOGS.POST_API]: true,
        [KEY_LOGS.SUCCESS_RESPONSE]: true,
    }

    switch (key) {
        case KEY_LOGS.ERROR_CATCH:
            if (activeLogs[key]) {
                POST(`${CONFIG.API_URL}${CONFIG.GET_LINE_NOTI}`, {
                    header: `FRONTEND : ${props.userLogin}`,
                    title: `📍 ${props.title} `,
                    message: `${message} : ${JSON.stringify({ ...props.payload })}`
                })
            }
            break;
        case KEY_LOGS.GET_DASHBOARD:
            if (activeLogs[key]) {
                POST(`${CONFIG.API_URL}${CONFIG.GET_LINE_NOTI}`, {
                    header: `FRONTEND : ${props.userLogin}`,
                    title: `${props.title} 📊`,
                    message: `${JSON.stringify({ ...props.payload })}`
                })
            }
            break;
        case KEY_LOGS.POST_API:
            if (activeLogs[key]) {
                POST(`${CONFIG.API_URL}${CONFIG.GET_LINE_NOTI}`, {
                    header: `FRONTEND : ${props.userLogin}`,
                    title: `${props.title}`,
                    message: `${JSON.stringify({ ...props.payload })}`
                })
            }
            break;

        default:
            break;
    }
}

// ถ้าอยู่ใน Catch Context ใส่ ⛔️
// //! line Noti
// APILineNotify({
//     key: KEY_LOGS.ERROR_CATCH,
//     message: `${error.message}`,
//     props: {
//         userLogin: `${userLogin.userId}`,
//         title: `⛔️ fetchAPIManage`,
//         payload: { ...payload }
//     }
// })

// //! line Noti
// APILineNotify({
//     key: KEY_LOGS.GET_DASHBOARD,
//     props: {
//         userLogin: `${userLogin.userId}`,
//         title: `${CONFIG.GET_WAIT_APPROVAL || functionName } : ${activeTabs}`,
//         payload: { ...payload }
//     }
// })
