import React from 'react'
import { Button, Input } from 'antd';
import { ClearOutlined, SearchOutlined } from '@ant-design/icons';

export const getColumnSearchProps = (dataIndex, searchInput) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm: fnSearch, clearFilters }) => (
        <div style={{ padding: 5, display: 'flex', alignItems: 'center' }}>
            <Input
                ref={node => {
                    searchInput.current = node;
                }}
                placeholder={`ค้นหา...`}
                value={selectedKeys && selectedKeys[0]}
                onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                onPressEnter={() => {
                    fnSearch();
                    setSelectedKeys(selectedKeys[0])
                }}
                style={{ width: 200, margin: 5, display: 'block', padding: '3px 3px 3px 10px', fontSize: 12 }}
            />
            <Button
                type="primary"
                onClick={() => {
                    fnSearch();
                    setSelectedKeys(selectedKeys[0])
                }}
                icon={<SearchOutlined />}
                size="small"
                style={{ fontSize: 8 }} />
            <Button
                icon={<ClearOutlined />}
                onClick={() => {
                    clearFilters();
                    setSelectedKeys('');
                }}
                size="small"
                style={{ fontSize: 8, margin: '0px 5px' }} />
        </div>
    ),
    onFilter: (value, record) =>
        record[dataIndex]
            ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
            : '',
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    render: (text, record, index) => text || '-'
})