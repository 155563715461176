import styled from "styled-components";

export const LayoutPosition = styled.div`
    width: 100%;
    display: flex;
    justify-content: ${props => props.x || 'flex-start'};
    align-items: ${props => props.y || 'flex-start'};
`
export const LayoutButton = styled.div`
    display: flex;
    justify-content: ${props => props.x || 'flex-start'};
    align-items: ${props => props.y || 'flex-start'};
    margin: 5px
`

export const TextTiltle = styled.span`
    font-weight: bold;
`
export const TextLabel = styled.span`
    padding-left: 5px;
`