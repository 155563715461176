import React, { createContext, useReducer, useContext } from "react";
import { APIInsertIndicatorHeader, APIUpdateIndicatorTime, APIInsertIndicatorLicense, APIUpdateIndicatorLicense, APIUpdateIndicatorHeader } from "./indicatorConnect";
import { Modal } from "antd";
import { ReportContext, REPORT_TYPE } from "../report/reportProvider";
import { APIReportIndicatorLine, APIReportIndicatorLicense } from "../report/reportConnect";
import { servicesOptions, MASTER_OPTION, REPORT_OPTION } from "../servicesOptions";
import { slice } from "ramda"
import { APIMasterEvaluationEmployee } from "../master/masterConnect";
import { MasterContext } from "../master/masterProvider";


export const IndicatorContext = createContext();

const initialState = {
    durationActive: null,
    indicator: [{
        title: null, criterion: null, proportion: null
    }],
    indicatorFac: [{
        title: 'ปริมาณงาน', criterion: 40, proportion: 40,
    }, {
        title: 'คุณภาพงาน', criterion: 40, proportion: 40
    }, {
        title: 'พฤติกรรม', criterion: 20, proportion: 20
    }],
    employeeList_LV1: [],
    employeeList_LV2: [],
    disabledEmployee_LV1: false,
    disabledEmployee_LV2: false,
    indicatorHeaderId: null,
    indicatorHeaderName: null,
    statusNo: null,
    approval: false,
    copyIndicator: false,
    proportion: 0
}

const INDICATOR_ACTION = {
    SET_INDICATOR_SUCCESS: "SET_INDICATOR_SUCCESS",
    SET_INDICATOR_FAILURE: "SET_INDICATOR_FAILURE",
    CLEAR_INDICATOR_SUCCESS: "CLEAR_INDICATOR_SUCCESS",
    CLEAR_INDICATOR_FAILURE: "CLEAR_INDICATOR_FAILURE",
}

const indicatorReducer = (state, action) => {
    switch (action.type) {
        case INDICATOR_ACTION.SET_INDICATOR_SUCCESS:
            return { ...state, ...action.state }
        case INDICATOR_ACTION.CLEAR_INDICATOR_SUCCESS:
            return initialState
        case INDICATOR_ACTION.CLEAR_INDICATOR_FAILURE:
        case INDICATOR_ACTION.SET_INDICATOR_FAILURE:
        default:
            return state
    }
}

const ProviderIndicator = ({ children }) => {
    const [indicatorState, indicatorDispatch] = useReducer(indicatorReducer, initialState)
    const { searchReport } = useContext(ReportContext)
    const { getMaster, masterState } = useContext(MasterContext)
    const { userLogin, userPermission } = children.props;
    // console.log({ children })
    async function onSubmit(props) {
        let employeeList = [];
        if (indicatorState.disabledEmployee_LV1) {
            employeeList = indicatorState.employeeList_LV2
        } else {
            employeeList = indicatorState.employeeList_LV1
        }

        let payloadHeader = {
            whereId: indicatorState.indicatorHeaderId,
            params: {
                employeeList: employeeList,   /*** เช็ค employeeList ตอนกดดูรายละเอียดไม่มีข้อมูล */
                indicator: (props && props.factory) ? indicatorState.indicatorFac : indicatorState.indicator,
                // refHeader: indicatorState.refHeader || '',
                indicatorHeaderId: indicatorState.indicatorHeaderId,
                indicatorHeaderName: indicatorState.indicatorHeaderName,
                // proportion: indicatorState.proportion,
                statusNo: indicatorState.statusNo ? indicatorState.statusNo : 1,
                startActive: indicatorState.startActive,
                endActive: indicatorState.endActive,
                userLogin: userLogin
            },
            dataUpdate: {
                indicatorHeaderName: indicatorState.indicatorHeaderName,
                startActive: indicatorState.startActive,
                endActive: indicatorState.endActive,
                statusNo: (indicatorState.statusNo === 1) ? 2 : (indicatorState.statusNo ? indicatorState.statusNo : 1),
                updateDate: new Date(),
                updateBy: userLogin
            }
        }
        //console.log({ payloadHeader })
        if (payloadHeader.whereId) {
            await APIUpdateIndicatorHeader(payloadHeader, res => {
                // console.log({ res })
                if (res) {
                    Modal.success({
                        title: res.message,
                        onOk: () => {
                            clearIndicator()
                            window.location.reload()
                        },
                        onCancel: () => clearIndicator()
                    })
                } else {
                    indicatorDispatch({ type: INDICATOR_ACTION.SET_INDICATOR_FAILURE })
                }
            })

        } else {
            /** เพิ่ม Header */
            await APIInsertIndicatorHeader(payloadHeader, res => {
                if (res) {
                    Modal.success({
                        title: "บันทึกสำเร็จ",
                        onOk: () => {
                            indicatorDispatch({ type: INDICATOR_ACTION.SET_INDICATOR_SUCCESS, state: res })
                            clearIndicator()
                            window.location.reload()
                        },
                        onCancel: () => clearIndicator()
                    })
                } else {
                    indicatorDispatch({ type: INDICATOR_ACTION.SET_INDICATOR_FAILURE })
                }
            })
        }

    }

    async function onChangeValue({ fieldname, value }) {
        let total = 0
        let valueAll = []
        switch (fieldname) {
            case 'refHeader':
                let resultRef = await searchReport({ reportName: REPORT_TYPE.INDICATOR_LINE, searchOption: masterState, valueId: value, factory: false })
                //console.log({ resultRef })

                let newResult = resultRef.reduce((acc, cur) => {
                    return [...acc, {
                        ...cur,
                        refHeader: cur.refHeader ? cur.refHeader : cur.indicatorHeaderId
                    }]
                }, [])
                //console.log({ newResult })
                newResult.forEach(({ proportion }) => { total += parseFloat(proportion || 0) })
                await indicatorDispatch({
                    type: INDICATOR_ACTION.SET_INDICATOR_SUCCESS, state: {
                        indicator: newResult,
                        [fieldname]: value,
                        proportion: parseFloat(total || 0),
                    }
                })
                break;
            case 'indicatorHeaderId':
                let result = await searchReport({ reportName: REPORT_TYPE.INDICATOR_LINE, searchOption: masterState, valueId: value, factory: false })
                // console.log({ result })

                result.forEach(({ proportion }) => { total += parseFloat(proportion || 0) })
                await indicatorDispatch({
                    type: INDICATOR_ACTION.SET_INDICATOR_SUCCESS, state: {
                        indicator: result,
                        [fieldname]: value,
                        proportion: parseFloat(total || 0),
                    }
                })
                break;
            case 'indicator':
                //console.log({ fieldname, value })
                value.forEach(({ proportion }) => { total += parseFloat(proportion || 0) })
                if (total > 100) {
                    Modal.error({ title: 'สัดส่วนเกิน 100%' })
                } else {
                    await indicatorDispatch({
                        type: INDICATOR_ACTION.SET_INDICATOR_SUCCESS, state: {
                            [fieldname]: value,
                            proportion: parseFloat(total || 0)
                        }
                    })
                }
                break;
            case 'durationActive':
                // console.log(value)
                let payloadEmployee = {
                    indicatorHeaderId: value.indicatorHeaderId,
                    strMonth: value.dateStrings[0].slice(0, 2),
                    endMonth: value.dateStrings[1].slice(0, 2),
                    year: value.dateStrings[0].slice(3, 8),
                    userLogin: userLogin,
                    searchText: ''
                }
                // console.log(payload)
                await indicatorDispatch({
                    type: INDICATOR_ACTION.SET_INDICATOR_SUCCESS, state: {
                        [fieldname]: value.dateStrings,
                        startActive: value.dateStrings[0],
                        endActive: value.dateStrings[1],
                        employeeList_LV1: [],
                        employeeList_LV2: []
                    }
                })
                await getMaster({ fieldname: MASTER_OPTION.EVALUATION_EMPLOYEE, payload: payloadEmployee })
                break;
            case 'approval':
                await indicatorDispatch({ type: INDICATOR_ACTION.SET_INDICATOR_SUCCESS, state: { [fieldname]: value, statusNo: (value) ? 3 : indicatorState.statusNo } })
                break;
            case 'copyIndicator':
                //console.log('g')
                let payloadHeader = {
                    strMonth: (indicatorState.startActive) && indicatorState.startActive.slice(0, 2),
                    endMonth: (indicatorState.endActive) && indicatorState.endActive.slice(0, 2),
                    year: (indicatorState.startActive) && indicatorState.startActive.slice(3, 8),
                    userLogin: userLogin,
                    searchText: ''
                }
                if (value) {
                    await getMaster({ fieldname: MASTER_OPTION.INDICATOR_HEADER, payload: payloadHeader })
                }
                await indicatorDispatch({ type: INDICATOR_ACTION.SET_INDICATOR_SUCCESS, state: { [fieldname]: value } })
                break;
            // case 'employeeLists':
            //     let newEmployLists = []
            //     value.forEach(({ optionId }) => newEmployLists.push(`${optionId}`))
            //     await indicatorDispatch({
            //         type: INDICATOR_ACTION.SET_INDICATOR_SUCCESS, state: {
            //             [fieldname]: value,
            //             employeeList: newEmployLists
            //         }
            //     })
            //     break;
            case 'employeeList_LV1':
                await indicatorDispatch({
                    type: INDICATOR_ACTION.SET_INDICATOR_SUCCESS, state: {
                        [fieldname]: value, disabledEmployee_LV2: (value.length > 0) ? true : false
                    }
                })
                break;
            case 'employeeList_LV2':
                await indicatorDispatch({
                    type: INDICATOR_ACTION.SET_INDICATOR_SUCCESS, state: {
                        [fieldname]: value, disabledEmployee_LV1: (value.length > 0) ? true : false
                    }
                })
                break;
            default:
                await indicatorDispatch({ type: INDICATOR_ACTION.SET_INDICATOR_SUCCESS, state: { [fieldname]: value } })
                break;
        }
    }


    async function viewDetail(values) {
        let payloadHeader = {
            strMonth: values.strMonth,
            endMonth: values.strMonth,
            year: values.strYear,
            userLogin: userLogin,
            searchText: ''
        }
        await getMaster({ fieldname: MASTER_OPTION.INDICATOR_HEADER, payload: payloadHeader })
        await APIReportIndicatorLicense({ indicatorHeaderId: values.indicatorHeaderId, userLogin: userLogin }, async employeeList => {
            // console.log(employeeList)
            if (employeeList) {
                const { LV1, LV2 } = employeeList;
                let newEmployListsLV1 = []
                let newEmployListsLV2 = []
                LV1.forEach(({ employeeId }) => newEmployListsLV1.push(`${employeeId}`))
                LV2.forEach(({ employeeId }) => newEmployListsLV2.push(`${employeeId}`))
                // console.log({ values })
                await APIReportIndicatorLine({ indicatorHeaderId: values.indicatorHeaderId }, async indicator => {
                    //console.log({ indicator })
                    let totalProportion = 0
                    indicator.forEach(({ proportion }) => totalProportion += proportion)
                    let result = {
                        ...values,
                        proportion: totalProportion,
                        employeeList_LV1: newEmployListsLV1,
                        employeeList_LV2: newEmployListsLV2,
                        copyIndicator: !!values.refHeader,
                        disabledEmployee_LV1: (newEmployListsLV2.length > 0) ? true : false,
                        // disabledEmployee_LV2: false,
                        // employeeList_LV1: servicesOptions(MASTER_OPTION.EVALUATION_EMPLOYEE, employeeList),
                        indicator,
                    }
                    //console.log(result)
                    await indicatorDispatch({ type: INDICATOR_ACTION.SET_INDICATOR_SUCCESS, state: { ...result } })
                })
            }
        })
    }



    async function clearIndicator() {
        await indicatorDispatch({ type: INDICATOR_ACTION.CLEAR_INDICATOR_SUCCESS, state: { ...initialState } })
    }

    return (
        <IndicatorContext.Provider
            value={{
                userLogin, //: 'ZT54020', //ZT58025             
                userPermission,
                onSubmit,
                viewDetail,
                onChangeValue,
                clearIndicator,
                indicatorState,
                indicatorDispatch
            }}>
            {children}
        </IndicatorContext.Provider>
    )
}


export default ProviderIndicator