import { convertResponse } from "../../../service/functions/convertResponse";
import { responseAPI } from "../../common/functions/funcResponse";
import servicesAPI from "../servicesAPI";

let API = servicesAPI.create()

export async function APIMasterIndicatorStatus(payload, nextAction) {
	try {
		const response = await API.masterIndicatorStatus(payload);
		responseAPI(response, nextAction)
	} catch (error) {
		console.error('error : ', error)
	}
}

export async function APIMasterIndicatorHeader(payload, nextAction) {
	try {
		const response = await API.masterIndicatorHeader(payload);
		responseAPI(response, nextAction)
	} catch (error) {
		console.error('error : ', error)
	}
}

export async function APIMasterEvaluationEmployee(payload, nextAction) {
	try {
		const response = await API.masterEvaluationEmployee(payload);
		responseAPI(response, nextAction)
	} catch (error) {
		console.error('error : ', error)
	}
}

export async function APIMasterBossApproval(payload, nextAction) {
	try {
		const response = await API.masterBossApproval(payload);
		responseAPI(response, nextAction)
	} catch (error) {
		console.error('error : ', error)
	}
}

export async function APIMasterBossInspect(payload, nextAction) {
	try {
		const response = await API.masterBossInspect(payload);
		responseAPI(response, nextAction)
	} catch (error) {
		console.error('error : ', error)
	}
}

export async function APIMasterDepartment(payload, nextAction) {
	try {
		switch (payload.permission) {
			// case 'HR-Super':
			// case 'HR-Admin':
			// case 'HR':
			// case 'HR-HeadDep':
			// case 'HR-AdminOff':
			case 'HRS-OFF':
            case 'HRD-OFF':
            case 'HRM-OFF':
            case 'HRS-FAC':
            case 'HRD-FAC':
            case 'HRM-FAC':
				const responseHR = await API.selectOptionDep({ depNo: '' });
				return convertResponse({ ...responseHR, result: responseHR.data.result }, nextAction)
			default:
				const response = await API.masterDepartment({ userId: payload.userId });
				//return convertResponse({ ...response, result: response.data.result.depArr }, nextAction)
				return convertResponse({ ...response, result: response.data.result["depArrByFlowHrm"] }, nextAction)
		}
	} catch (error) {
		console.error('error : ', error)
	}
}

export async function APIMasterIndicatorFactory(payload, nextAction) {
	try {
		const response = await API.masterIndicatorFactory(payload);
		responseAPI(response, nextAction, true)
	} catch (error) {
		console.error('error : ', error)
	}
}

export async function APIMasterFilterFactory(payload, nextAction) {
	try {
		const response = await API.masterFilterFactory(payload);
		responseAPI(response, nextAction, true)
	} catch (error) {
		console.error('error : ', error)
	}
}

export async function APIMasterFilterSubDepFactory(payload, nextAction) {
	try {
		const response = await API.masterFilterSubDepFactory(payload);
		responseAPI(response, nextAction, true)
	} catch (error) {
		console.error('error : ', error)
	}
}