import React from "react";
import { path } from "ramda";

import "bootstrap/dist/css/bootstrap.css";
import { FormGroup, Label, Input } from "reactstrap";

const SelectInput = ({
	label = "",
	name = "",
	options = [],
	onChange,
	styleLabel,
	style,
	value,
	valueField,
	MasterTable,
	...rest
}) => {
	return (
		<FormGroup style={style}>
			{label ? <Label style={styleLabel}>{label}</Label> : null}
			<Input type="select" name={name} value={value} onChange={(e) => onChange(name, e.target.value)} {...rest}>
				{/* {<option value={'all'}> {"----- ทั้งหมด -----"}   </option>} */}
				{Array.isArray(options) &&
					options.map((option, i) => {
						return (
							<option key={i} value={option.value}>
								{option.label || ""}
							</option>
						);
					})}
			</Input>
		</FormGroup>
	);
};

export default SelectInput;
