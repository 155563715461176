import { responseAPI } from "../../../moduleKPIs/common/functions/funcResponse";
import servicesAPI from "../../../moduleKPIs/services/servicesAPI";

let API = servicesAPI.create()

export async function APISelectOptionFrequencyWorkWith(payload, nextAction) {
    try {
        const response = await API.selectOptionFrequencyWorkWith(payload);
        responseAPI(response, nextAction)
    } catch (error) {
        console.error('error : ', error)
    }
}

export async function APISelectQuestion(payload, nextAction) {
    try {
        const response = await API.selectQuestion(payload);
        responseAPI(response, nextAction)
    } catch (error) {
        console.error('error : ', error)
    }
}

export async function APISelectOptionDepEvac(payload, nextAction) {
    try {
        const response = await API.selectOptionDepEvac(payload);
        responseAPI(response, nextAction)
    } catch (error) {
        console.error('error : ', error)
    }
}

export async function APISelectFilterDepEvac(payload, nextAction) {
    try {
        const response = await API.selectFilterDepEvac(payload);
        responseAPI(response, nextAction)
    } catch (error) {
        console.error('error : ', error)
    }
}

export async function APISelectFormEvacAll(payload, nextAction) {
    try {
        const response = await API.selectFormEvacAll(payload);
        responseAPI(response, nextAction)
    } catch (error) {
        console.error('error : ', error)
    }
}

export async function APISelectFormEvacByUserID(payload, nextAction) {
    try {
        const response = await API.selectFormEvacByUserID(payload);
        responseAPI(response, nextAction)
    } catch (error) {
        console.error('error : ', error)
    }
}