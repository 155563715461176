import { filterSearchColumn } from "../../../functions/UseFunction";

export const ColumnCreateCrime = [
  {
    title: "รหัสพนักงาน",
    dataIndex: "ID_User",
    key: "ID_User",
    align: "left",
    fixed: "left",
    width: 100,
    ...filterSearchColumn("ID_User", "รหัสพนักงาน"),
  },
  {
    title: "คำนำหน้าชื่อ",
    dataIndex: "title",
    key: "title",
    align: "left",
    fixed: "left",
    width: 100,
    ...filterSearchColumn("title", "คำนำหน้าชื่อ"),
  },
  {
    title: "ชื่อพนักงาน",
    dataIndex: "Name",
    key: "Name",
    align: "left",
    fixed: "left",
    width: 200,
    ...filterSearchColumn("Name", "ชื่อพนักงาน"),
  },
  {
    title: "เลขประจำตัวประชาชน",
    dataIndex: "Personal_id",
    key: "Personal_id",
    align: "right",
    fixed: "left",
    width: 200,
    ...filterSearchColumn("Personal_id", "เลขประจำตัวประชาชน"),
  },
  {
    title: "วันเดือนปีเกิด",
    dataIndex: "birthday",
    key: "birthday",
    align: "left",
    width: 150,
    ...filterSearchColumn("birthday", "วันเดือนปีเกิด"),
  },
  {
    title: "ชื่อบิดา-มารดา",
    dataIndex: "parentNames",
    key: "parentNames",
    align: "left",
    width: 200,
    ...filterSearchColumn("parentNames", "มารดา"),
  },
  {
    title: "ที่อยู่ปัจจุบัน",
    dataIndex: "presentAddress",
    key: "presentAddress",
    align: "left",
    width: 400,
    ...filterSearchColumn("presentAddress", "วันเดือนปีเกิด"),
  },
  {
    title: "ผลการตรวจสอบ",
    dataIndex: "crimeResultCode",
    key: "crimeResultCode",
    align: "left",
    width: 150,
    ...filterSearchColumn("crimeResultCode", "ผลการตรวจสอบ"),
  },
  {
    title: "บริษัท",
    dataIndex: "company",
    key: "company",
    align: "left",
    width: 100,
    ...filterSearchColumn("company", "บริษัท"),
  },
  {
    title: "Location",
    dataIndex: "location",
    key: "location",
    align: "left",
    width: 100,
    ...filterSearchColumn("location", "Location"),
  },
  {
    title: "แผนก",
    dataIndex: "depName",
    key: "depName",
    align: "left",
    width: 200,
    ...filterSearchColumn("depName", "แผนก"),
  },
  {
    title: "แผนกย่อย",
    dataIndex: "subDepName",
    key: "subDepName",
    align: "left",
    width: 300,
    ...filterSearchColumn("subDepName", "แผนกย่อย"),
  },

  {
    title: "อีเมล",
    dataIndex: "email",
    key: "email",
    align: "left",
    width: 150,
    ...filterSearchColumn("email", "อีเมล"),
  },
  {
    title: "เบอร์โทร",
    dataIndex: "telNo",
    key: "telNo",
    align: "left",
    width: 150,
    ...filterSearchColumn("telNo", "เบอร์โทร"),
  },
  {
    title: "วันที่เริ่มงาน",
    dataIndex: "startDateJob",
    key: "startDateJob",
    align: "right",
    width: 150,
    ...filterSearchColumn("startDateJob", "วันที่เริ่มงาน"),
  },
  {
    title: "ผู้สรรหา",
    dataIndex: "recruiterNickname",
    key: "recruiterNickname",
    align: "left",
    width: 150,
    ...filterSearchColumn("recruiterNickname", "ผู้สรรหา"),
  },
];
