import React, { useContext, useEffect, useState } from "react";
import { Spin, Form, Space, Card, Modal } from "antd";
import { configForm } from "./constants/ConstantComponent";
import { useButton } from "./conponents/buttons/CustomButtonAntd";
import { useRenderFormItem } from "./conponents/RenderComponents";
import { ComponentRecheckCommissionIncentiveAction } from "./constants/RecheckCommissionIncentive/Components";
import { sumColumnWidth } from "./functions/UseFunction";
import {
  ColumnRecheckCommissionIncentiveAction,
  getTotalNet,
} from "./constants/RecheckCommissionIncentive/Columns";
import CustomTableAtnd from "./conponents/tables/CustomTableAtnd";
import { browserHistory } from "react-router";
import { useOptionSelect } from "./constants/OptionSelect";
import moment from "moment";
import { useAlert } from "./conponents/modal/CustomAlert";
import {
  GET_COMMISSION_INCENTIVE,
  POST,
  GET_PERMISSION_USER_COMMISSION,
  GET_COMMISSION_INCENTIVE_BY_DOCNO,
} from "../service";
import { getDatePeriodNow } from "./../functions/index";
import { optionInstallmentSaleStatus } from "../common/enum";
import { useNotification } from "./conponents/notification/NotificationWithIcon";
import { decryptString } from "../service/functions/encodeFunction";
import { SetStringToObject } from "../constants/functions";
import Cookies from "js-cookie";
import encodeString from "../extendFunction/encodeString";
import { useExportExcel } from "./services/ExportExcel";
import { Header_CutOffSaleEmployeeReport } from "./constants/HeaderExcel";
import { ContractContext } from "../moduleContract/servicesContract/contractProvider";
import { convertBaht } from "../service/functions/convertOptions";

const RecheckCommissionIncentiveView = (props) => {
  const [form] = Form.useForm();
  const { userLogin } = useContext(ContractContext);

  const [isDepNo, setIsDepNo] = useState(false);
  const [loadingScreen, setLoadingScreen] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [dataExcel, setDataExcel] = useState([]);
  const [dataSourceOrigin, setDataSourceOrigin] = useState([]);
  const [permissionUser, setPermissionUser] = useState([]);
  const [depOption, setDepOption] = useState([]);
  const [dateStart, setDateStart] = useState("");
  const [dateEnd, setDateEnd] = useState("");
  const cookies = SetStringToObject(Cookies.get("cookie_session"));

  let optionSearch = {
    depNo: useOptionSelect("department"),
  };

  useEffect(() => {
    let start = "";
    let end = "";
    if (props.location.state && props.location.state.docNo) {
      getCommissionIncentiveByDocNo(props.location.state.docNo);
      start = moment(new Date(props.location.state.installmentStartDate));
      end = moment(new Date(props.location.state.installmentEndDate));
    } else {
      start = moment(getDatePeriodNow("start"));
      end = moment(getDatePeriodNow("end"));
    }
    //getPermissionUser();
    setDateEnd(end);
    setDateStart(start);
    form.setFieldsValue({ startDate: start });
    form.setFieldsValue({ endDate: end });
  }, []);

  useEffect(() => {
    if (permissionUser.length > 0 && depOption.length == 0) {
      let temp = optionSearch.depNo.filter((el) => {
        let index = permissionUser.findIndex(
          (val) => val.departmentNo == el.value
        );
        if (index != -1) {
          return el;
        }
      });
      setDepOption(temp);
    }
  }, [optionSearch.depNo]);

  const getPermissionUser = async () => {
    try {
      setLoadingScreen(true);
      const response = await POST(GET_PERMISSION_USER_COMMISSION, {
        userLogin: encodeString.decode(cookies.ID_User),
      });
      const { result } = response;
      if (result.length == 0) {
        browserHistory.push("/");
      } else {
        setPermissionUser(result);
      }
    } catch (err) {
      useAlert({ type: "catch", content: err.message });
      console.log("Error getPermissionUser: ", err);
      browserHistory.push("/");
    } finally {
      setLoadingScreen(false);
    }
  };

  const onFinish = (values) => {
    console.log(values);
    const { depNo } = values;
    let objectBody = {
      depNo: depNo.join(","),
      startDate: moment(dateStart).format("YYYY-MM-DD"),
      endDate: moment(dateEnd).format("YYYY-MM-DD"),
      // startDate: '2022-01-21',
      // endDate: '2022-02-20',
    };
    // selectDataRecheckEmployee(objectBody);
    getCommissionIncentive(objectBody);
  };

  const formDataSource = (result) => {
    return result.map((row, index) => {
      let preObj = {
        ...row,
        key: index + 1,
        statusInstallmentName:
          row.statusWorkerInstallment &&
          renderLabelStatusInstallment(row.statusWorkerInstallment),
        commission: parseFloat(decryptString(row.commission)) || "",
        commissionFocusShop:
          parseFloat(decryptString(row.commissionFocusShop)) || "",
        commissionGuarantee:
          parseFloat(decryptString(row.commissionGuarantee)) || "",
        incentive: parseFloat(decryptString(row.incentive)) || "",
        incentiveFocusShop:
          parseFloat(decryptString(row.incentiveFocusShop)) || "",
        incentiveGuarantee:
          parseFloat(decryptString(row.incentiveGuarantee)) || "",
        incentiveMonth: parseFloat(decryptString(row.incentiveMonth)) || "",
        commissionMonth: parseFloat(decryptString(row.commissionMonth)) || "",
        incentiveQuater: parseFloat(decryptString(row.incentiveQuater)) || "",
        incentiveStrategy:
          parseFloat(decryptString(row.incentiveStrategy)) || "",
        persentSalePerMonth:
          parseFloat(decryptString(row.persentSalePerMonth)) || "",
        persentSalePerQuater:
          parseFloat(decryptString(row.persentSalePerQuater)) || "",
        rvAmount: parseFloat(decryptString(row.rvAmount)) || "",
        saleActual: parseFloat(decryptString(row.saleActual)) || "",
        saleActualQuater: parseFloat(decryptString(row.saleActualQuater)) || "",
        saleTarget: parseFloat(decryptString(row.saleTarget)) || "",
        saleTargetQuater: parseFloat(decryptString(row.saleTargetQuater)) || "",
        totalCommission: parseFloat(decryptString(row.totalCommission)) || "",
        totalCommissionBeforeUpdate:
          parseFloat(decryptString(row.totalCommissionBeforeUpdate)) || "",
        totalIncentive: parseFloat(decryptString(row.totalIncentive)) || "",
        totalIncentiveBeforeUpdate:
          parseFloat(decryptString(row.totalIncentiveBeforeUpdate)) || "",

        recoverCommission:
          row.recoverCommission &&
          parseFloat(decryptString(`${row.recoverCommission}`)),
        recoverIncentive:
          row.recoverIncentive &&
          parseFloat(decryptString(`${row.recoverIncentive}`)),
        remarkRecover: row.remarkRecover ? `${row.remarkRecover}` : "",
        totalIncentivePay:
          row.totalIncentivePay &&
          parseFloat(decryptString(`${row.totalIncentivePay}`)),
        totalCommissionPay:
          row.totalCommissionPay &&
          parseFloat(decryptString(`${row.totalCommissionPay}`)),
      };
      return {
        ...preObj,
        total: getTotalNet(preObj),
      };
    });
  };
  const formDataExcel = (result) => {
    return result.map((row, index) => {
      let preObj = {
        ...row,
        key: index + 1,
        statusInstallmentName:
          row.statusWorkerInstallment &&
          renderLabelStatusInstallment(row.statusWorkerInstallment),
        commission: parseFloat(decryptString(row.commission)) || "",
        commissionFocusShop:
          parseFloat(decryptString(row.commissionFocusShop)).toFixed(2) || "",
        commissionGuarantee:
          parseFloat(decryptString(row.commissionGuarantee)) || "",
        incentive: parseFloat(decryptString(row.incentive)) || "",
        incentiveFocusShop:
          parseFloat(decryptString(row.incentiveFocusShop)) || "",
        incentiveGuarantee:
          parseFloat(decryptString(row.incentiveGuarantee)) || "",
        incentiveMonth: parseFloat(decryptString(row.incentiveMonth)) || "",
        commissionMonth:
          convertBaht(
            parseFloat(decryptString(row.commissionMonth)).toFixed(2)
          ) || "",
        incentiveQuater: parseFloat(decryptString(row.incentiveQuater)) || "",
        incentiveStrategy:
          parseFloat(decryptString(row.incentiveStrategy)) || "",
        persentSalePerMonth:
          parseFloat(decryptString(row.persentSalePerMonth)).toFixed(2) || "",
        persentSalePerQuater:
          parseFloat(decryptString(row.persentSalePerQuater)).toFixed(2) || "",
        rvAmount: parseFloat(decryptString(row.rvAmount)) || "",
        saleActual: parseFloat(decryptString(row.saleActual)) || "",
        saleActualQuater: parseFloat(decryptString(row.saleActualQuater)) || "",
        saleTarget: parseFloat(decryptString(row.saleTarget)) || "",
        saleTargetQuater: parseFloat(decryptString(row.saleTargetQuater)) || "",
        totalCommission: decryptString(row.totalCommission)
          ? convertBaht(
              parseFloat(decryptString(row.totalCommission)).toFixed(2)
            )
          : "",
        totalCommissionBeforeUpdate: decryptString(
          row.totalCommissionBeforeUpdate
        )
          ? convertBaht(
              parseFloat(
                decryptString(row.totalCommissionBeforeUpdate)
              ).toFixed(2)
            )
          : "",
        totalIncentive: decryptString(row.totalIncentive)
          ? convertBaht(
              parseFloat(decryptString(row.totalIncentive)).toFixed(2)
            )
          : "",
        totalIncentiveBeforeUpdate: decryptString(
          row.totalIncentiveBeforeUpdate
        )
          ? parseFloat(decryptString(row.totalIncentiveBeforeUpdate)).toFixed(2)
          : "",

        recoverCommission:
          row.recoverCommission &&
          parseFloat(decryptString(`${row.recoverCommission}`)).toFixed(2),
        recoverIncentive:
          row.recoverIncentive &&
          parseFloat(decryptString(`${row.recoverIncentive}`)).toFixed(2),
        remarkRecover: row.remarkRecover ? `${row.remarkRecover}` : "",
        totalIncentivePay:
          row.totalIncentivePay &&
          parseFloat(decryptString(`${row.totalIncentivePay}`)).toFixed(2),
        totalCommissionPay:
          row.totalCommissionPay &&
          parseFloat(decryptString(`${row.totalCommissionPay}`)).toFixed(2),
      };
      return {
        ...preObj,
        total: getTotalNet(preObj),
      };
    });
  };

  const getCommissionIncentiveByDocNo = async (docNo) => {
    try {
      setLoadingScreen(true);
      const response = await POST(GET_COMMISSION_INCENTIVE_BY_DOCNO, {
        docNo,
        userLogin,
      });
      const { result } = response;
      let mapResult = [];
      let mapExcelResult = [];

      if (result.length !== 0) {
        form.setFieldsValue({ depNo: props.location.state.depNo.split(",") });
        form.setFieldsValue({
          startDate: moment(props.location.state.installmentStartDate),
        });
        form.setFieldsValue({
          endDate: moment(props.location.state.installmentEndDate),
        });
        setIsDepNo(props.location.state && props.location.state.docNo);
        mapResult = formDataSource(result);
        mapExcelResult = formDataExcel(result);
      } else {
        useNotification({ type: "info", title: "ไม่พบข้อมูล" });
      }
      setDataSource(mapResult);
      setDataExcel(mapExcelResult);
      setDataSourceOrigin(JSON.parse(JSON.stringify(mapResult)));
    } catch (err) {
      useAlert({ type: "catch", content: err.message });
      console.log("Error Search Recheck Employee: ", err);
    } finally {
      setLoadingScreen(false);
    }
  };

  const getCommissionIncentive = async (objectBody) => {
    try {
      setLoadingScreen(true);
      const response = await POST(GET_COMMISSION_INCENTIVE, objectBody);
      const { result } = response;
      let mapResult = [];

      if (result.length !== 0) {
        mapResult = formDataSource(result);
      } else {
        useNotification({ type: "info", title: "ไม่พบข้อมูล" });
      }
      setDataSource(mapResult);
      setDataSourceOrigin(JSON.parse(JSON.stringify(mapResult)));
    } catch (err) {
      useAlert({ type: "catch", content: err.message });
      console.log("Error Search Recheck Employee: ", err);
    } finally {
      setLoadingScreen(false);
    }
  };

  const renderLabelStatusInstallment = (statusWorkerInstallment) => {
    return optionInstallmentSaleStatus[
      optionInstallmentSaleStatus.findIndex(
        (el) => el.value.split(" | ")[0] == statusWorkerInstallment
      )
    ].label;
  };

  const onChangeRow = (e, rowNo) => {
    let temp = [...dataSource];
    setDataSource([]);
    let fieldName = e.target.name.split(/(\d+)/)[0];
    if (fieldName == "incentiveStrategy") {
      temp[rowNo - 1][`totalIncentiveBeforeUpdate`] = +(
        (temp[rowNo - 1][`incentiveMonth`] || 0) + parseFloat(e.target.value)
      );
    } else if (fieldName == "commissionFocusShop") {
      temp[rowNo - 1][`totalCommissionBeforeUpdate`] = +(
        temp[rowNo - 1][`commissionMonth`] + parseFloat(e.target.value)
      );
    }
    temp[rowNo - 1][`${fieldName}`] = e.target.value;
    setDataSource(temp);
  };

  return (
    <Spin tip="กำลังโหลดข้อมูล ..." spinning={loadingScreen}>
      <Form form={form} onFinish={onFinish} {...configForm}>
        <Card
          title={`บันทึกการตรวจสอบ Commission/Incentive (รอบวันที่ ${moment(
            dateStart
          ).format("YYYY-MM-DD")} - ${moment(dateEnd).format("YYYY-MM-DD")})`}
          style={{ marginBottom: 30 }}
          extra={
            <Space>
              {useButton({
                call: "search",
                isDisable: true,
              })}
              {useButton({
                call: "normal",
                isDisable: true,
                fn: () => {},
                moreProps: { label: "ล้างข้อมูล" },
              })}
            </Space>
          }
        >
          {useRenderFormItem({
            constantComponent: ComponentRecheckCommissionIncentiveAction({
              options: { depNo: depOption },
              handleChange: () => {},
              isDepNo,
            }),
          })}
        </Card>
      </Form>
      <Card
        title={
          props.location.state &&
          props.location.state.docNo &&
          `เลขเอกสาร ${props.location.state.docNo}`
        }
        extra={
          <div style={{ display: "flex" }}>
            {useExportExcel({
              header: Header_CutOffSaleEmployeeReport,
              dataset: dataExcel,
              filename: "รายงานค่า commission/incentive พนักงาน",
            })}
          </div>
        }
      >
        <CustomTableAtnd
          HeadTable={ColumnRecheckCommissionIncentiveAction({
            isView: props.location.state && props.location.state.view,
            dataSource,
            onChangeRow,
            dataSourceOrigin,
          })}
          DataTable={dataSource}
          moreProps={{
            pageSize: 50,
            //rowSelection: rowsSelection,
            scroll: {
              x:
                sumColumnWidth(
                  ColumnRecheckCommissionIncentiveAction({
                    isView: props.location.state && props.location.state.view,
                    dataSource,
                    onChangeRow,
                    dataSourceOrigin,
                  })
                ) || 2050,
              y: 500,
            },
            size: "small",
          }}
        />
        <div style={{ textAlignLast: "center", marginTop: "15px" }}>
          <Space>
            {useButton({
              call: "normal",
              moreProps: {
                label: "กลับหน้าแรก",
                onClick: () => {
                  browserHistory.push(
                    "/RecheckCommissionIncentiveDashboardView"
                  );
                },
              },
            })}
          </Space>
        </div>
      </Card>
    </Spin>
  );
};

export default RecheckCommissionIncentiveView;
