import { COMPONENT_TYPE, ERROR_MESSAGE } from "../..";

export const DEFAULT_REPORT_WARNING = {
    company: undefined,
    location: undefined,
    depNo: undefined,
    subDepNo: undefined,
    startDate: undefined,
    toDate: undefined,
    userId: undefined,
    payrollGroup: undefined,
}

const DEFINE = {
    company: {
        label: "บริษัท",
        name: "company",
    },
    location: {
        label: "สถานที่",
        name: "location",
    },
    depNo: {
        label: "แผนก",
        name: "depNo",
    },
    subDepNo: {
        label: "แผนกย่อย",
        name: "subDepNo",
    },
    startDate: {
        label: "ช่วงเวลาที่คำนวณเริ่มวันที่",
        name: "startDate",
    },
    toDate: {
        label: "ถึงวันที่",
        name: "toDate",
    },
    userId: {
        label: "รหัสพนักงาน",
        name: "userId",
    },
    payrollGroup: {
        label: "กลุ่มเงินเดือน",
        name: "payrollGroup",
    },
}

export const ComponentReportWarning = ({
    options = {},
    isDisable = {},
    handleChange = () => null,
}) => {
    const { company, location, depNo, subDepNo, startDate, toDate, userId, payrollGroup } = DEFINE
    return [
        { // Row
            rowProps: { justify: "start", align: "bottom" },
            column: [ // column
                {
                    breakpoint: { xs: 24, sm: 24, md: 12, lg: 12, xl: 4 },
                    component: {
                        type: COMPONENT_TYPE.SELECT,
                        itemProps: {
                            name: company.name,
                            label: company.label,
                            rules: [{ required: false, message: ERROR_MESSAGE.SELECT(company.label) }],
                        },
                        componentProps: {
                            style: { width: "100%" },
                            placeholder: ERROR_MESSAGE.SELECT(company.label),
                            disabled: isDisable[company.name] || false,
                        },
                        fnChange: (value) => handleChange(company.name, value),
                        dropdown: options[company.name],
                    },
                },
                {
                    breakpoint: { xs: 24, sm: 24, md: 12, lg: 12, xl: 4 },
                    component: {
                        type: COMPONENT_TYPE.SELECT,
                        itemProps: {
                            name: location.name,
                            label: location.label,
                            rules: [{ required: false, message: ERROR_MESSAGE.SELECT(location.label) }],
                        },
                        componentProps: {
                            style: { width: "100%" },
                            placeholder: ERROR_MESSAGE.SELECT(location.label),
                            disabled: isDisable[location.name] || false,
                        },
                        fnChange: (value) => handleChange(location.name, value),
                        dropdown: options[location.name],
                    },
                },
                {
                    breakpoint: { xs: 24, sm: 24, md: 12, lg: 12, xl: 8 },
                    component: {
                        type: COMPONENT_TYPE.SELECT,
                        itemProps: {
                            name: depNo.name,
                            label: depNo.label,
                            rules: [{ required: false, message: ERROR_MESSAGE.SELECT(depNo.label) }],
                        },
                        componentProps: {
                            style: { width: "100%" },
                            placeholder: ERROR_MESSAGE.SELECT(depNo.label),
                            disabled: isDisable[depNo.name] || false,
                        },
                        fnChange: (value) => handleChange(depNo.name, value),
                        dropdown: options[depNo.name],
                    },
                },
                {
                    breakpoint: { xs: 24, sm: 24, md: 24, lg: 12, xl: 8 },
                    component: {
                        type: COMPONENT_TYPE.SELECT,
                        itemProps: {
                            name: subDepNo.name,
                            label: subDepNo.label,
                            rules: [{ required: false, message: ERROR_MESSAGE.SELECT(subDepNo.label) }],
                        },
                        componentProps: {
                            style: { width: "100%" },
                            placeholder: ERROR_MESSAGE.SELECT(subDepNo.label),
                            disabled: isDisable[subDepNo.name] || false,
                        },
                        fnChange: (value) => handleChange(subDepNo.name, value),
                        dropdown: options[subDepNo.name],
                    },
                },
                {
                    breakpoint: { xs: 24, sm: 24, md: 12, lg: 6, xl: 4 },
                    component: {
                        type: COMPONENT_TYPE.DATE,
                        itemProps: {
                            name: startDate.name,
                            label: startDate.label,
                            rules: [{ required: true, message: ERROR_MESSAGE.DATEPICKER }],
                        },
                        componentProps: {
                            style: { width: "100%" },
                            placeholder: ERROR_MESSAGE.DATEPICKER,
                            disabled: isDisable[startDate.name] || false,
                        },
                        fnChange: (date, dateString) => handleChange(startDate.name, dateString),
                    },
                },
                {
                    breakpoint: { xs: 24, sm: 24, md: 12, lg: 6, xl: 4 },
                    component: {
                        type: COMPONENT_TYPE.DATE,
                        itemProps: {
                            name: toDate.name,
                            label: toDate.label,
                            rules: [{ required: true, message: ERROR_MESSAGE.DATEPICKER }],
                        },
                        componentProps: {
                            style: { width: "100%" },
                            placeholder: ERROR_MESSAGE.DATEPICKER,
                            disabled: isDisable[toDate.name] || false,
                        },
                        fnChange: (date, dateString) => handleChange(toDate.name, dateString),
                    },
                },
                {
                    breakpoint: { xs: 24, sm: 24, md: 12, lg: 12, xl: 8 },
                    component: {
                        type: COMPONENT_TYPE.SELECT,
                        itemProps: {
                            name: userId.name,
                            label: userId.label,
                            rules: [{ required: false, message: ERROR_MESSAGE.SELECT(userId.label) }],
                        },
                        componentProps: {
                            style: { width: "100%" },
                            placeholder: ERROR_MESSAGE.SELECT(userId.label),
                            disabled: isDisable[userId.name] || false,
                        },
                        fnChange: (value) => handleChange(userId.name, value),
                        dropdown: options[userId.name],
                    },
                },
                {
                    breakpoint: { xs: 24, sm: 24, md: 12, lg: 6, xl: 4 },
                    component: {
                        type: COMPONENT_TYPE.SELECT,
                        itemProps: {
                            name: payrollGroup.name,
                            label: payrollGroup.label,
                            rules: [{ required: false, message: ERROR_MESSAGE.SELECT(payrollGroup.label) }],
                        },
                        componentProps: {
                            style: { width: "100%" },
                            placeholder: ERROR_MESSAGE.SELECT(payrollGroup.label),
                            disabled: isDisable[payrollGroup.name] || false,
                        },
                        fnChange: (value) => handleChange(payrollGroup.name, value),
                        dropdown: options[payrollGroup.name],
                    },
                },
            ], // column
        }, // Row
    ]
}