import React from "react";
import { useForm } from "react-hook-form";
import { Spin, Card, Collapse, Row, Col } from "antd";
import { TextSmall } from "../../../components/global/text";
import { useGetDocument } from "./hook/useGetDocument";
import { useCollapseState } from "./hook/useCollapseState";
import { useGetFormPrint } from "./hook/useGetFormPrint";
import { useButtonState } from "./hook/useButtonState";
import { useGetOptions } from "./hook/useGetOptions";
import {
  ButtonTheme,
  ContainerButton,
} from "../../../components/global/buttons";
import DocumentSelector from "./views/DocumentSelector";
import { MemoGeneralDocumentDetail } from "./views/GeneralDocumentDetail";
import { FormPrint } from "./views/FormPrint";
//import { mapSelectedOptionData } from "./function/map"; //TODO Remove

const CertificateRequestDetailScene = () => {
  const { Panel } = Collapse;
  const { control, setValue, handleSubmit, setError } = useForm();
  const {
    loadingDocument,
    documentList,
    fileList,
    docID,
    inputState,
    changeInputState,
  } = useGetDocument({
    setValue,
  });
  const {
    optionsCompanyTH,
    optionsCompanyEN,
    optionsSigner,
    optionsPositionEN,
    optionsPositionTH,
    optionsDepartmentEN,
    optionsDepartmentTH,
    loadingOption,
  } = useGetOptions();
  const { activeKey, setActiveKey } = useCollapseState();
  const { formPrint, loadingFormPrint, onGetFormPrint, onPrintAll } =
    useGetFormPrint({
      setError,
      documentList,
      setActiveKey,
      docID,
    });
  const { selectArr, onSelected } = useButtonState({
    docLength: documentList.length,
  });

  return (
    <Card title={"หน้าขอใบรับรอง"}>
      <Collapse
        activeKey={activeKey}
        onChange={(e) => {
          setActiveKey(() => e);
        }}
      >
        <Panel header="แก้ไขรายละเอียดทั่วไป" key="docDetailGeneral">
          <Spin spinning={loadingDocument} tip="กำลังโหลด">
            <MemoGeneralDocumentDetail
              control={control}
              setValue={setValue}
              documentList={documentList}
              options={{
                optionsDepartmentEN,
                optionsDepartmentTH,
                optionsSigner,
              }}
            />
          </Spin>
        </Panel>
        <Panel header="ระบุรายละเอียดรายเอกสาร" key="docDetail">
          <Spin spinning={loadingDocument && loadingOption} tip="กำลังโหลด">
            <Row gutter={[4, 4]}>
              {documentList.map((e, index) => (
                <Col span={24}>
                  <DocumentSelector
                    formInputName={e.docName}
                    formDescription={e.docDescription}
                    control={control}
                    setValue={setValue}
                    index={index}
                    onSelected={onSelected}
                    inputState={inputState[e.docName]}
                    changeInputState={changeInputState}
                    options={{
                      optionsCompanyTH,
                      optionsCompanyEN,
                      optionsPositionEN,
                      optionsPositionTH,
                    }}
                  />
                </Col>
              ))}
            </Row>
            <ContainerButton center style={{ marginTop: "20px" }}>
              <ButtonTheme
                bgColor="grey"
                colorFill={false}
                disabled={
                  !selectArr.reduce((prev, cur) => {
                    return prev || cur;
                  })
                }
                title={"เลือก และพรีวิวเอกสาร"}
                onClick={handleSubmit(onGetFormPrint)}
              />
              <ButtonTheme
                style={{ marginLeft: "20px" }}
                disabled={
                  !selectArr.reduce((prev, cur) => {
                    return prev || cur;
                  })
                }
                title={"เลือก และพิมพ์เอกสารทั้งหมด"}
                bgColor="#007bff"
                onClick={handleSubmit(onPrintAll)}
              />
            </ContainerButton>
          </Spin>
        </Panel>
        <Panel header="ไฟล์แนบ" key="attachment">
          {fileList.map((e) => {
            if (e.url != "") {
              return (
                <div>
                  <a href={e.url} target="_blank">
                    {e.fileName}
                  </a>
                </div>
              );
            }
          })}
        </Panel>
        <Panel header="เอกสาร" key="formPrint">
          {formPrint.length == 0 && (
            <TextSmall text="กรุณาเลือกเอกสาร" align="center" />
          )}
          {formPrint.length > 0 && (
            <Spin spinning={loadingFormPrint} tip="กำลังโหลด">
              {formPrint.map((formData) => (
                <FormPrint formData={formData} />
              ))}
            </Spin>
          )}
        </Panel>
      </Collapse>
    </Card>
  );
};

export default CertificateRequestDetailScene;
