import { filterSearchColumn } from "../../functions/UseFunction";
import moment from "moment";

export const ColumnSearchSSONewEmp = [
  {
    title: "รหัสพนักงาน",
    dataIndex: "userId",
    key: "userId",
    align: "left",
    fixed: "left",
    width: 150,
    ...filterSearchColumn("userId", "รหัสพนักงาน"),
  },
  {
    title: "ชื่อพนักงาน",
    dataIndex: "fullname",
    key: "fullname",
    align: "left",
    fixed: "left",
    width: 200,
    ...filterSearchColumn("fullname", "ชื่อพนักงาน"),
  },
  {
    title: "ชื่อเล่น",
    dataIndex: "nickname",
    key: "nickname",
    align: "left",
    fixed: "left",
    width: 150,
    ...filterSearchColumn("nickname", "ชื่อเล่น"),
  },
  {
    title: "สถานะยื่นประกันสังคม",
    dataIndex: "statusDes",
    key: "statusDes",
    align: "left",
    width: 200,
    ...filterSearchColumn("statusDes", "สถานะยื่นประกันสังคม"),
  },
  {
    title: "วันที่ยื่น",
    dataIndex: "dataStamp",
    key: "dataStamp",
    align: "right",
    width: 150,
    ...filterSearchColumn("dataStamp", "Data Stamp"),
  },
  {
    title: "ชุดเอกสาร",
    dataIndex: "docRef",
    key: "docRef",
    align: "left",
    width: 150,
    ...filterSearchColumn("docRef", "ชุดเอกสาร"),
  },
  {
    title: "เคยมีบัตรประกันสังคม",
    dataIndex: "hasSSO",
    key: "hasSSO",
    align: "left",
    width: 200,
    ...filterSearchColumn("hasSSO", "เคยมีบัตรประกันสังคม"),
  },
  {
    title: "กรอกข้อมูลเลือกโรงพยาบาล",
    dataIndex: "fillDataDes",
    key: "fillDataDes",
    align: "left",
    width: 250,
    ...filterSearchColumn("fillDataDes", "กรอกข้อมูลเลือกโรงพยาบาล"),
  },
  {
    title: "บริษัท",
    dataIndex: "companyCode",
    key: "companyCode",
    align: "left",
    width: 150,
    ...filterSearchColumn("companyCode", "บริษัท"),
  },
  {
    title: "สถานที่",
    dataIndex: "location",
    key: "location",
    align: "left",
    width: 150,
    ...filterSearchColumn("location", "สถานที่"),
  },
  // {
  //   title: "แผนก",
  //   dataIndex: "depNo",
  //   key: "depNo",
  //   align: "left",
  //   width: 200,
  //   ...filterSearchColumn("depNo", "แผนก"),
  // },
  {
    title: "ชื่อแผนก",
    dataIndex: "depName",
    key: "depName",
    align: "left",
    width: 200,
    ...filterSearchColumn("depName", "ชื่อแผนก"),
  },
  // {
  //   title: "รหัสแผนกย่อย",
  //   dataIndex: "subDepNo",
  //   key: "subDepNo",
  //   align: "left",
  //   ...filterSearchColumn("subDepNo", "รหัสแผนกย่อย"),
  // },
  {
    title: "แผนกย่อย",
    dataIndex: "subDepName",
    key: "subDepName",
    align: "left",
    width: 300,
    ...filterSearchColumn("subDepName", "แผนกย่อย"),
  },

  {
    title: "เลขประจำตัวประชาชน",
    dataIndex: "personal_id",
    key: "personal_id",
    align: "right",
    width: 200,
    ...filterSearchColumn("personal_id", "เลขประจำตัวประชาชน"),
  },
  {
    title: "วันที่เริ่มงาน",
    dataIndex: "startDateJob",
    key: "startDateJob",
    align: "right",
    width: 150,
    ...filterSearchColumn("startDateJob", "วันที่เริ่มงาน"),
    sorter: (a, b) =>
      moment(b.startDateJob).unix() - moment(a.startDateJob).unix(),
  },
  {
    title: "โรงพยาบาลลำดับที่ 1",
    dataIndex: "hospitalNameSq1",
    key: "hospitalNameSq1",
    align: "left",
    width: 300,
    ...filterSearchColumn("hospitalNameSq1", "โรงพยาบาลลำดับที่ 1"),
  },
  {
    title: "โรงพยาบาลลำดับที่ 2",
    dataIndex: "hospitalNameSq2",
    key: "hospitalNameSq2",
    align: "left",
    width: 300,
    ...filterSearchColumn("hospitalNameSq2", "โรงพยาบาลลำดับที่ 2"),
  },
  {
    title: "โรงพยาบาลลำดับที่ 3",
    dataIndex: "hospitalNameSq3",
    key: "hospitalNameSq3",
    align: "left",
    width: 300,
    ...filterSearchColumn("hospitalNameSq3", "โรงพยาบาลลำดับที่ 3"),
  },
];

export const ColumnSearchSSOOutEmp = [
  {
    title: "รหัสพนักงาน",
    dataIndex: "userId",
    key: "userId",
    align: "left",
    fixed: "left",
    width: 150,
    ...filterSearchColumn("userId", "รหัสพนักงาน"),
  },
  {
    title: "ชื่อพนักงาน",
    dataIndex: "fullname",
    key: "fullname",
    align: "left",
    fixed: "left",
    width: 200,
    ...filterSearchColumn("fullname", "ชื่อพนักงาน"),
  },
  {
    title: "ชื่อเล่น",
    dataIndex: "nickname",
    key: "nickname",
    align: "left",
    fixed: "left",
    width: 150,
    ...filterSearchColumn("nickname", "ชื่อเล่น"),
  },
  {
    title: "สถานะยื่นประกันสังคม",
    dataIndex: "statusDes",
    key: "statusDes",
    align: "left",
    width: 200,
    ...filterSearchColumn("statusDes", "สถานะยื่นประกันสังคม"),
  },
  {
    title: "วันที่ยื่น",
    dataIndex: "dataStamp",
    key: "dataStamp",
    align: "right",
    width: 150,
    ...filterSearchColumn("dataStamp", "วันที่ยื่น"),
  },
  {
    title: "ชุดเอกสาร",
    dataIndex: "docRef",
    key: "docRef",
    align: "left",
    width: 150,
    ...filterSearchColumn("docRef", "ชุดเอกสาร"),
  },
  {
    title: "บริษัท",
    dataIndex: "companyCode",
    key: "companyCode",
    align: "left",
    width: 150,
    ...filterSearchColumn("companyCode", "บริษัท"),
  },
  {
    title: "สถานที่",
    dataIndex: "location",
    key: "location",
    align: "left",
    width: 150,
    ...filterSearchColumn("location", "สถานที่"),
  },
  // {
  //   title: "แผนก",
  //   dataIndex: "depNo",
  //   key: "depNo",
  //   align: "left",
  //   ...filterSearchColumn("depNo", "แผนก"),
  // },
  {
    title: "ชื่อแผนก",
    dataIndex: "depName",
    key: "depName",
    align: "left",
    width: 200,
    ...filterSearchColumn("depName", "ชื่อแผนก"),
  },
  // {
  //   title: "รหัสแผนกย่อย",
  //   dataIndex: "subDepNo",
  //   key: "subDepNo",
  //   align: "left",
  //   ...filterSearchColumn("subDepNo", "รหัสแผนกย่อย"),
  // },
  {
    title: "แผนกย่อย",
    dataIndex: "subDepName",
    key: "subDepName",
    align: "left",
    width: 300,
    ...filterSearchColumn("subDepName", "แผนกย่อย"),
  },
  {
    title: "เลขประจำตัวประชาชน",
    dataIndex: "personal_id",
    key: "personal_id",
    align: "right",
    width: 200,
    ...filterSearchColumn("personal_id", "เลขประจำตัวประชาชน"),
  },
  {
    title: "วันสิ้นสุดการทำงาน",
    dataIndex: "endDateJob",
    key: "endDateJob",
    align: "right",
    width: 150,
    ...filterSearchColumn("endDateJob", "วันสิ้นสุดการทำงาน"),
  },
  {
    title: "เหตุผลการลาออก",
    dataIndex: "reasonLeaveDes",
    key: "reasonLeaveDes",
    align: "left",
    width: 200,
    ...filterSearchColumn("reasonLeaveDes", "เหตุผลการลาออก"),
  },
  {
    title: "ไฟล์ส่งประกันสังคม",
    dataIndex: "fileText",
    key: "fileText",
    align: "center",
    width: 100,
    render: () => "test",
  },
];
