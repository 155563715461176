import { filterSearchColumn } from "../../functions/UseFunction";
import React from "react";
import { Link } from "react-router-dom";
import { Fragment } from "react";
import { Button, Space, Tag, Tooltip } from "antd";
import { useButton } from "../../conponents/buttons/CustomButtonAntd";
import { FileSearchOutlined, PoweroffOutlined } from "@ant-design/icons";
export const ColumnNewEmp = ({ actions }) => [
  {
    dataIndex: "prepareStatusDesc",
    title: "สถานะ",
    width: 100,
    fixed: "",
    align: "center",
    ...filterSearchColumn("prepareStatusDesc", "สถานะ"),
    render: (text, record) => {
      if (record.prepareStatus === "CONFIRM")
        return <Tag color="green">{text}</Tag>;
      if (record.prepareStatus === "CANCEL")
        return <Tag color="red">{text}</Tag>;
      if (record.prepareStatus === "WAIT_PREPARE")
        return <Tag color="orange">{text}</Tag>;
      if (record.prepareStatus === "IN_PROCESS")
        return <Tag color="blue">{text}</Tag>;
    },
  },
  {
    dataIndex: "userId",
    title: "รหัสพนักงาน",
    width: 50,
    fixed: "",
    align: "center",
    ...filterSearchColumn("userId", "รหัสพนักงาน"),
    render: (text) => {
      return (
        <a target="_blank" href={`Assets_NewEmpPrint/?UserID=${text}`}>
          <b>{text}</b>
        </a>
      );
    },
  },
  {
    dataIndex: "userId",
    title: "",
    width: 50,
    fixed: "",
    align: "center",
    render: (text, record) => {
      const isDanger = record.editStatus && record.prepareStatus !== 'CONFIRM' ? "ghost" : "";
      return (
        <a target="_blank" href={`Assets_NewEmpPrint/?UserID=${text}`}>
          <Button
            type= "primary"
            danger={isDanger}
            icon={
              <FileSearchOutlined
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              />
            }
          />
        </a>
      );
    },
  },
  {
    dataIndex: "fullname",
    title: "ชื่อพนักงาน",
    width: 250,
    fixed: "",
    align: "",
    ...filterSearchColumn("fullname", "ชื่อพนักงาน"),
    render: (text, record) => {
      return (
        <Fragment>
          <div>{text}</div>
          <div>{record.fullNameEN}</div>
          <div>{record.tel}</div>
        </Fragment>
      );
    },
  },
  {
    dataIndex: "email",
    title: "อีเมล",
    width: 150,
    fixed: "",
    align: "",
    ...filterSearchColumn("email", "อีเมล"),
  },
  // {
  //   dataIndex: "Email",
  //   title: "Email",
  //   width: 100,
  //   fixed: "",
  //   align: "",
  //   ...filterSearchColumn("Email", "Email"),
  // },
  {
    dataIndex: "bossFullname",
    title: "หัวหน้างาน",
    width: 150,
    fixed: "",
    align: "",
    ...filterSearchColumn("bossFullname", "หัวหน้างาน"),
    render: (text, record) => {
      return (
        <Fragment>
          <div>{text}</div>
          <div>{record.bossTel}</div>
        </Fragment>
      );
    },
  },
  {
    dataIndex: "createDate",
    title: "วันที่แจ้งเข้า",
    width: 150,
    fixed: "",
    align: "center",
    ...filterSearchColumn("createDate", "วันที่แจ้งเข้า"),
  },
  {
    dataIndex: "startDateJob",
    title: "วันที่เริ่มงาน",
    width: 150,
    fixed: "",
    align: "center",
    ...filterSearchColumn("startDateJob", "วันที่เริ่มงาน"),
  },
  {
    dataIndex: "depName",
    title: "แผนก",
    width: 150,
    fixed: "",
    align: "",
    ...filterSearchColumn("depName", "แผนก"),
    render: (text) => text.split("แผนก "),
  },
  {
    dataIndex: "itFixedAssetStatus",
    title: "IT Fixed Asset",
    width: 150,
    fixed: "",
    align: "center",
    // ...filterSearchColumn("itFixedAssetStatus", "IT Fixed Asset"),
    render: (text, record) => {
      const { onOpenModal } = actions;
      if (text === "COMPLETE") {
        return (
          <span className="status-complate">
            {record.itFixedAssetCompletedDate}
            <br />
            {record.itFixedAssetBy}
          </span>
        );
      }
      if (record.prepareStatus === "CONFIRM") return "-";
      if (text === "FOLLOW") {
        return (
          <Fragment>
            {record.itFixedAssetFollowUpDate}
            <Space size="middle" wrap style={{ paddingTop: "5px" }}>
              {useButton({
                call: "onFollow",
                moreProps: {
                  label: "ติดตามงาน",
                }, //
                fn: () => onOpenModal(record, "IT Fixed Asset", "IT_ASSET"),
              })}
            </Space>
          </Fragment>
        );
      }
      return (
        <Space size="middle" wrap>
          {useButton({
            call: "onWait",
            moreProps: {
              label: "รอดำเนินการ",
            },
            fn: () => onOpenModal(record, "IT Fixed Asset", "IT_ASSET"),
            //   isDisable: +record.statusTransfer !== 0 ? true : false,
          })}
        </Space>
      );
    },
  },
  {
    dataIndex: "domainStatus",
    title: "IT Domain",
    width: 150,
    fixed: "",
    align: "center",
    ...filterSearchColumn("domainStatus", "IT Domain"),
    render: (text, record) => {
      const { onOpenModal } = actions;
      if (text === "COMPLETE") {
        return (
          <span className="status-complate">
            {record.domainCompletedDate}
            <br />
            {record.domainBy}
          </span>
        );
      }
      if (record.prepareStatus === "CONFIRM") return "-";
      if (text === "FOLLOW") {
        return (
          <Fragment>
            {record.domainFollowUpDate}
            <Space size="middle" wrap style={{ paddingTop: "5px" }}>
              {useButton({
                call: "onFollow",
                moreProps: {
                  label: "ติดตามงาน",
                }, //
                fn: () => onOpenModal(record, "IT Domain", "DOMAIN"),
                //   isDisable: +record.statusTransfer !== 0 ? true : false,
              })}
            </Space>
          </Fragment>
        );
      }

      return (
        <Space size="middle" wrap>
          {useButton({
            call: "onWait",
            moreProps: {
              label: "รอดำเนินการ",
            },
            fn: () => onOpenModal(record, "IT Domain", "DOMAIN"),
            //   isDisable: +record.statusTransfer !== 0 ? true : false,
          })}
        </Space>
      );
    },
  },
  {
    dataIndex: "emailStatus",
    title: "IT Email",
    width: 150,
    fixed: "",
    align: "center",
    ...filterSearchColumn("emailStatus", "IT Email"),
    render: (text, record) => {
      const { onOpenModal } = actions;
      if (text === "COMPLETE") {
        return (
          <span className="status-complate">
            {record.emailCompletedDate}
            <br />
            {record.emailBy}
          </span>
        );
      }
      if (record.prepareStatus === "CONFIRM") return "-";
      if (text === "FOLLOW") {
        return (
          <Fragment>
            {record.emailFollowUpDate}
            <Space size="middle" wrap style={{ paddingTop: "5px" }}>
              {useButton({
                call: "onFollow",
                moreProps: {
                  label: "ติดตามงาน",
                }, //
                fn: () => onOpenModal(record, "IT Email", "EMAIL"),
                //   isDisable: +record.statusTransfer !== 0 ? true : false,
              })}
            </Space>
          </Fragment>
        );
      }

      return (
        <Space size="middle" wrap>
          {useButton({
            call: "onWait",
            moreProps: {
              label: "รอดำเนินการ",
            },
            fn: () => onOpenModal(record, "IT Email", "EMAIL"),
            //   isDisable: +record.statusTransfer !== 0 ? true : false,
          })}
        </Space>
      );
    },
  },
  {
    dataIndex: "softwareStatus",
    title: "IT Software",
    width: 150,
    fixed: "",
    align: "center",
    ...filterSearchColumn("softwareStatus", "IT Software"),
    render: (text, record) => {
      const { onOpenModal } = actions;
      if (text === "COMPLETE") {
        return (
          <span className="status-complate">
            {record.softwareCompletedDate}
            <br />
            {record.softwareBy}
          </span>
        );
      }
      if (record.prepareStatus === "CONFIRM") return "-";
      if (text === "FOLLOW") {
        return (
          <Fragment>
            {record.softwareFollowUpDate}
            <Space size="middle" wrap style={{ paddingTop: "5px" }}>
              {useButton({
                call: "onFollow",
                moreProps: {
                  label: "ติดตามงาน",
                }, //
                fn: () => onOpenModal(record, "IT Software", "SOFTWARE"),
                //   isDisable: +record.statusTransfer !== 0 ? true : false,
              })}
            </Space>
          </Fragment>
        );
      }

      return (
        <Space size="middle" wrap>
          {useButton({
            call: "onWait",
            moreProps: {
              label: "รอดำเนินการ",
            },
            fn: () => onOpenModal(record, "IT Software", "SOFTWARE"),
            //   isDisable: +record.statusTransfer !== 0 ? true : false,
          })}
        </Space>
      );
    },
  },
  {
    dataIndex: "purchaseFixedAssetStatus",
    title: "Purchase Fixed Asset",
    width: 150,
    fixed: "",
    align: "center",
    ...filterSearchColumn("purchaseFixedAssetStatus", "Purchase Fixed Asset"),
    render: (text, record) => {
      const { onOpenModal } = actions;
      if (text === "COMPLETE") {
        return (
          <span className="status-complate">
            {record.purchaseFixedAssetCompletedDate}
            <br />
            {record.purchaseFixedAssetBy}
          </span>
        );
      }
      if (record.prepareStatus === "CONFIRM") return "-";
      if (text === "FOLLOW") {
        return (
          <Fragment>
            {record.purchaseFixedAssetFollowUpDate}
            <Space size="middle" wrap style={{ paddingTop: "5px" }}>
              {useButton({
                call: "onFollow",
                moreProps: {
                  label: "ติดตามงาน",
                }, //
                fn: () =>
                  onOpenModal(record, "PurchaseFixed", "PURCHASE_ASSET"),
                //   isDisable: +record.statusTransfer !== 0 ? true : false,
              })}
            </Space>
          </Fragment>
        );
      }
      return (
        <Space size="middle" wrap>
          {useButton({
            call: "onWait",
            moreProps: {
              label: "รอดำเนินการ",
            }, //
            fn: () => onOpenModal(record, "PurchaseFixed", "PURCHASE_ASSET"),
            //   isDisable: +record.statusTransfer !== 0 ? true : false,
          })}
        </Space>
      );
    },
  },
  {
    dataIndex: "userReceivedDate",
    title: "วันที่รับสินทรัพย์",
    width: 150,
    fixed: "",
    align: "center",
    ...filterSearchColumn("userReceivedDate", "วันที่รับสินทรัพย์"),
    render: (text, record) => {
      const { onOpenModalConfirmReceived } = actions;
      let receivedBy = '';
      if(text && record.userId !== record.userConfirmQR){
        receivedBy = `(${record.userConfirmQRName})`
      }
      return (
        !text && record.prepareStatus == 'CONFIRM' ? (
          <Space size="middle" wrap>
            {useButton({
              call: "confirm",
              moreProps: {
                label: "ยืนยันรับสินทรัพย์",
              }, //
              fn: () => onOpenModalConfirmReceived(record),
            })}
          </Space>
        ) : (
          <>
            {text} <br /> {receivedBy}
          </>              
        )
      );
    },
  },
  {
    title: "Action",
    width: 100,
    fixed: "",
    align: "center",
    render: (record) => {
      const { onOpenModalConfirmTask } = actions;
      return (
        <Space size="middle" wrap>
          {useButton({
            call: "confirm",
            fn: () => onOpenModalConfirmTask(record),
            isDisable: record.prepareStatus === "CONFIRM" ? true : false,
          })}
        </Space>
      );
    },
  },
];

export const ColumnQuitEmp = ({ actions }) => [
  {
    dataIndex: "prepareStatusDesc",
    title: "สถานะ",
    width: 100,
    fixed: "",
    align: "center",
    ...filterSearchColumn("prepareStatusDesc", "สถานะ"),
    render: (text, record) => {
      if (record.prepareStatus === "CONFIRM")
        return <Tag color="green">{text}</Tag>;
      if (record.prepareStatus === "CANCEL")
        return <Tag color="red">{text}</Tag>;
      if (record.prepareStatus === "WAIT_PREPARE")
        return <Tag color="orange">{text}</Tag>;
      if (record.prepareStatus === "IN_PROCESS")
        return <Tag color="blue">{text}</Tag>;
    },
  },
  {
    dataIndex: "userId",
    title: "รหัสพนักงาน",
    width: 50,
    fixed: "",
    align: "center",
    ...filterSearchColumn("userId", "รหัสพนักงาน"),
    render: (text) => {
      return (
        <a target="_blank" href={`Assets_QuitEmpView/?UserID=${text}`}>
          <b>{text}</b>
        </a>
      );
    },
  },
  {
    dataIndex: "userId",
    title: "",
    width: 50,
    fixed: "",
    align: "center",
    render: (text) => {
      return (
        <a target="_blank" href={`Assets_QuitEmpView/?UserID=${text}`}>
          <Button
            type="primary"
            icon={
              <FileSearchOutlined
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              />
            }
          />
        </a>
      );
    },
  },
  {
    dataIndex: "fullname",
    title: "ชื่อพนักงาน",
    width: 250,
    fixed: "",
    align: "",
    ...filterSearchColumn("fullname", "ชื่อพนักงาน"),
    render: (text, record) => {
      return (
        <Fragment>
          <div>{text}</div>
          <div>{record.fullNameEN}</div>
          <div>{record.tel}</div>
        </Fragment>
      );
    },
  },
  {
    dataIndex: "bossFullname",
    title: "หัวหน้างาน",
    width: 150,
    fixed: "",
    align: "",
    ...filterSearchColumn("bossFullname", "หัวหน้างาน"),
    render: (text, record) => {
      return (
        <Fragment>
          <div>{text}</div>
          <div>{record.bossTel}</div>
        </Fragment>
      );
    },
  },
  {
    dataIndex: "createDate",
    title: "วันที่แจ้งออก",
    width: 150,
    fixed: "",
    align: "center",
    ...filterSearchColumn("createDate", "วันที่แจ้งออก"),
  },
  {
    dataIndex: "endDateJob",
    title: "วันที่ออกงาน",
    width: 150,
    fixed: "",
    align: "center",
    ...filterSearchColumn("endDateJob", "วันที่ออกงาน"),
  },
  {
    dataIndex: "depName",
    title: "แผนก",
    width: 150,
    fixed: "",
    align: "",
    ...filterSearchColumn("depName", "แผนก"),
    render: (text) => text.split("แผนก "),
  },
  {
    dataIndex: "itFixedAssetStatus",
    title: "IT Fixed Asset",
    width: 150,
    fixed: "",
    align: "center",
    // ...filterSearchColumn("itFixedAssetStatus", "IT Fixed Asset"),
    render: (text, record) => {
      const { onOpenModal } = actions;
      if (text === "COMPLETE") {
        return (
          <span className="status-complate">
            {record.itFixedAssetCompletedDate}
            <br />
            {record.itFixedAssetBy}
          </span>
        );
      }
      if (record.prepareStatus === "CONFIRM") return "-";
      if (text === "FOLLOW") {
        return (
          <Fragment>
            {record.itFixedAssetFollowUpDate}
            <Space size="middle" wrap style={{ paddingTop: "5px" }}>
              {useButton({
                call: "onFollow",
                moreProps: {
                  label: "ติดตามงาน",
                }, //
                fn: () => onOpenModal(record, "IT Fixed Asset", "IT_ASSET"),
              })}
            </Space>
          </Fragment>
        );
      }
      return (
        <Space size="middle" wrap>
          {useButton({
            call: "onWait",
            moreProps: {
              label: "รอดำเนินการ",
            },
            fn: () => onOpenModal(record, "IT Fixed Asset", "IT_ASSET"),
            //   isDisable: +record.statusTransfer !== 0 ? true : false,
          })}
        </Space>
      );
    },
  },
  {
    dataIndex: "domainStatus",
    title: "IT Domain",
    width: 150,
    fixed: "",
    align: "center",
    ...filterSearchColumn("domainStatus", "IT Domain"),
    render: (text, record) => {
      const { onOpenModal } = actions;
      if (text === "COMPLETE") {
        return (
          <span className="status-complate">
            {record.domainCompletedDate}
            <br />
            {record.domainBy}
          </span>
        );
      }
      if (record.prepareStatus === "CONFIRM") return "-";
      if (text === "FOLLOW") {
        return (
          <Fragment>
            {record.domainFollowUpDate}
            <Space size="middle" wrap style={{ paddingTop: "5px" }}>
              {useButton({
                call: "onFollow",
                moreProps: {
                  label: "ติดตามงาน",
                }, //
                fn: () => onOpenModal(record, "IT Domain", "DOMAIN"),
                //   isDisable: +record.statusTransfer !== 0 ? true : false,
              })}
            </Space>
          </Fragment>
        );
      }

      return (
        <Space size="middle" wrap>
          {useButton({
            call: "onWait",
            moreProps: {
              label: "รอดำเนินการ",
            },
            fn: () => onOpenModal(record, "IT Domain", "DOMAIN"),
            //   isDisable: +record.statusTransfer !== 0 ? true : false,
          })}
        </Space>
      );
    },
  },
  {
    dataIndex: "emailStatus",
    title: "IT Email",
    width: 150,
    fixed: "",
    align: "center",
    ...filterSearchColumn("emailStatus", "IT Email"),
    render: (text, record) => {
      const { onOpenModal } = actions;
      if (text === "COMPLETE") {
        return (
          <span className="status-complate">
            {record.emailCompletedDate}
            <br />
            {record.emailBy}
          </span>
        );
      }
      if (record.prepareStatus === "CONFIRM") return "-";
      if (text === "FOLLOW") {
        return (
          <Fragment>
            {record.emailFollowUpDate}
            <Space size="middle" wrap style={{ paddingTop: "5px" }}>
              {useButton({
                call: "onFollow",
                moreProps: {
                  label: "ติดตามงาน",
                }, //
                fn: () => onOpenModal(record, "IT Email", "EMAIL"),
                //   isDisable: +record.statusTransfer !== 0 ? true : false,
              })}
            </Space>
          </Fragment>
        );
      }

      return (
        <Space size="middle" wrap>
          {useButton({
            call: "onWait",
            moreProps: {
              label: "รอดำเนินการ",
            },
            fn: () => onOpenModal(record, "IT Email", "EMAIL"),
            //   isDisable: +record.statusTransfer !== 0 ? true : false,
          })}
        </Space>
      );
    },
  },
  {
    dataIndex: "softwareStatus",
    title: "IT Software",
    width: 150,
    fixed: "",
    align: "center",
    ...filterSearchColumn("softwareStatus", "IT Software"),
    render: (text, record) => {
      const { onOpenModal } = actions;
      if (text === "COMPLETE") {
        return (
          <span className="status-complate">
            {record.softwareCompletedDate}
            <br />
            {record.softwareBy}
          </span>
        );
      }
      if (record.prepareStatus === "CONFIRM") return "-";
      if (text === "FOLLOW") {
        return (
          <Fragment>
            {record.softwareFollowUpDate}
            <Space size="middle" wrap style={{ paddingTop: "5px" }}>
              {useButton({
                call: "onFollow",
                moreProps: {
                  label: "ติดตามงาน",
                }, //
                fn: () => onOpenModal(record, "IT Software", "SOFTWARE"),
                //   isDisable: +record.statusTransfer !== 0 ? true : false,
              })}
            </Space>
          </Fragment>
        );
      }

      return (
        <Space size="middle" wrap>
          {useButton({
            call: "onWait",
            moreProps: {
              label: "รอดำเนินการ",
            },
            fn: () => onOpenModal(record, "IT Software", "SOFTWARE"),
            //   isDisable: +record.statusTransfer !== 0 ? true : false,
          })}
        </Space>
      );
    },
  },
  // {
  //   dataIndex: "purchaseFixedAssetStatus",
  //   title: "Purchase Fixed Asset",
  //   width: 150,
  //   fixed: "",
  //   align: "center",
  //   ...filterSearchColumn("purchaseFixedAssetStatus", "Purchase Fixed Asset"),
  //   render: (text, record) => {
  //     const { onOpenModal } = actions;
  //     if (text === "COMPLETE") {
  //       return (
  //         <span className="status-complate">
  //           {record.purchaseFixedAssetCompletedDate}
  //           <br />
  //           {record.purchaseFixedAssetBy}
  //         </span>
  //       );
  //     }
  //     if (record.prepareStatus === "CONFIRM") return "-";
  //     if (text === "FOLLOW") {
  //       return (
  //         <Fragment>
  //           {record.purchaseFixedAssetFollowUpDate}
  //           <Space size="middle" wrap style={{ paddingTop: "5px" }}>
  //             {useButton({
  //               call: "onFollow",
  //               moreProps: {
  //                 label: "ติดตามงาน",
  //               }, //
  //               fn: () =>
  //                 onOpenModal(record, "PurchaseFixed", "PURCHASE_ASSET"),
  //               //   isDisable: +record.statusTransfer !== 0 ? true : false,
  //             })}
  //           </Space>
  //         </Fragment>
  //       );
  //     }
  //     return (
  //       <Space size="middle" wrap>
  //         {useButton({
  //           call: "onWait",
  //           moreProps: {
  //             label: "รอดำเนินการ",
  //           }, //
  //           fn: () => onOpenModal(record, "PurchaseFixed", "PURCHASE_ASSET"),
  //           //   isDisable: +record.statusTransfer !== 0 ? true : false,
  //         })}
  //       </Space>
  //     );
  //   },
  // },

  {
    dataIndex: "hrStatus",
    title: "Hr",
    width: 150,
    fixed: "",
    align: "center",
    ...filterSearchColumn("hrStatus", "Hr"),
    render: (text, record) => {
      const { onOpenModal } = actions;
      if (text === "COMPLETE") {
        return (
          <span className="status-complate">
            {record.hrCompletedDate}
            <br />
            {record.hrBy}
          </span>
        );
      }
      if (record.prepareStatus === "CONFIRM") return "-";
      if (text === "FOLLOW") {
        return (
          <Fragment>
            {record.hrFollowUpDate}
            <Space size="middle" wrap style={{ paddingTop: "5px" }}>
              {useButton({
                call: "onFollow",
                moreProps: {
                  label: "ติดตามงาน",
                }, //
                fn: () => onOpenModal(record, "Hr", "HR"),
                //   isDisable: +record.statusTransfer !== 0 ? true : false,
              })}
            </Space>
          </Fragment>
        );
      }
      return (
        <Space size="middle" wrap>
          {useButton({
            call: "onWait",
            moreProps: {
              label: "รอดำเนินการ",
            }, //
            fn: () => onOpenModal(record, "Hr", "HR"),
            //   isDisable: +record.statusTransfer !== 0 ? true : false,
          })}
        </Space>
      );
    },
  },
  {
    title: "Action",
    width: 100,
    fixed: "",
    align: "center",
    render: (record) => {
      const { onOpenModalConfirmTask } = actions;
      return (
        <Space size="middle" wrap>
          {useButton({
            call: "confirm",
            fn: () => onOpenModalConfirmTask(record),
            isDisable: record.prepareStatus === "CONFIRM" ? true : false,
          })}
        </Space>
      );
    },
  },
];
