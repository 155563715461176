import React from 'react'
import TableTheme from "../../constants/tables/TableTheme";
import * as Layouts from "../../constants/layouts";
const columnSummaryPEF = [
    {
        title: "ข้อมูลทั่วไป",
        fixed: 'left',
        children: [
            {
                title: "สถานะ",
                key: "statusCF_HR",
                dataIndex: "statusCF_HR",
                width: 70,

                // color: 'blue',
            },
            {
                title: "ปี",
                key: "Years",
                dataIndex: "Years",
                width: 70,
                // color: 'blue',
            },
            {
                title: "รหัสพนักงาน",
                key: "staffCode",
                dataIndex: "staffCode",
                width: 80,
                // fixed: 'right',
            },
            {
                title: "ชื่อ-สกุล",
                key: "staffName",
                dataIndex: "staffName",
                width: 150,
                // fixed: 'left',
            },
            {
                title: "ชื่อเล่น",
                key: "NickName",
                dataIndex: "NickName",
                width: 70,
                // fixed: 'left',
            },
            {
                title: "แผนก",
                key: "Department_Name_TH",
                dataIndex: "Department_Name_TH",
                width: 80,
            }, {
                title: "ตำแหน่ง",
                key: "PositionNameTH",
                dataIndex: "PositionNameTH",
                width: 80,
            }, {
                title: "ผู้ประเมิน",
                key: "Boss",
                dataIndex: "Boss",
                width: 80,
            }, {
                title: "ฟอร์ม PE",
                key: "description",
                dataIndex: "description",
                width: 80,
            }, {
                title: "คะแนนรวม",
                key: "totalScore",
                dataIndex: "totalScore",
                width: 100,
                className: 'bg-green'
            }
        ]
    },
    {
        title: "ส่วนที่1 การประเมินปัจจัยด้านผลงาน (ผลงานตามเป้าหมาย / มาตราฐานงาน)",
        children: [
            {
                title: "ปริมาณ",
                key: "Score1_1_1",
                dataIndex: "Score1_1_1",
                width: 100,
            }, {
                title: "เวลา",
                key: "Score1_1_2",
                dataIndex: "Score1_1_2",
                width: 100,
            }, {
                title: "คุณภาพ",
                key: "Score1_2_1",
                dataIndex: "Score1_2_1",
                width: 100,
            }, {
                title: "การแก้ไข",
                key: "Score1_2_2",
                dataIndex: "Score1_2_2",
                width: 100,
            }, {
                title: "คะแนนรวมส่วนที่ 1",
                key: "scorePart1",
                dataIndex: "scorePart1",
                width: 100,
                className: 'bg-yellow'
            }
        ]
    }, {
        title: "ส่วนที่2 ด้านสมรรถนะ และพฤติกรรม (Core competency  / Functional Competency / Managerial Competency)",
        children: [
            {
                title: "Teamwork (C)",
                key: "Score2_1",
                dataIndex: "Score2_1",
                width: 100,
            }, {
                title: "Customer Focus (C)",
                key: "Score2_2",
                dataIndex: "Score2_2",
                width: 100,
            }, {
                title: "Excellence / Go Beyond (C)",
                key: "Score2_3",
                dataIndex: "Score2_3",
                width: 100,
            }, {
                title: "Learning and Development (C)",
                key: "Score2_4",
                dataIndex: "Score2_4",
                width: 100,
            }, {
                title: "Job Knowledge (F)",
                key: "Score2_6",
                dataIndex: "Score2_6",
                width: 100,
            }, {
                title: "Problem Solving (F)",
                key: "Score2_7",
                dataIndex: "Score2_7",
                width: 100,
            }, {
                title: "Leadership (M)",
                key: "Score2_8",
                dataIndex: "Score2_8",
                width: 100,
            }, {
                title: "People Management (M)",
                key: "Score2_9",
                dataIndex: "Score2_9",
                width: 100,
            }, {
                title: "Planing and Organizing (M)",
                key: "Score2_10",
                dataIndex: "Score2_10",
                width: 100,
            }, {
                title: "คะแนนรวมส่วนที่ 2",
                key: "scorePart2",
                dataIndex: "scorePart2",
                width: 100,
                className: 'bg-yellow'

            }
        ]
    },
    {
        title: "การเปลี่ยนแปลง Promote/โยกย้าย/ปรับตำแหน่ง (ถ้าเสนอ โปรดระบุ เพื่อ Discuss Management)",
        children: [
            {
                title: "รายละเอียด",
                key: "remarkDetail",
                dataIndex: "remarkDetail",
                width: 200,
            }, {
                title: "ระยะเวลา",
                key: "remarkTime",
                dataIndex: "remarkTime",
                width: 200,
            }
        ]
    }
];

const SummaryPEFTable = ({ dataSource }) => {
    return (

        <Layouts.MainTheme
            component={
                <TableTheme
                    size="small"
                    columns={columnSummaryPEF}
                    dataSource={dataSource}
                    scroll={{ x: 100 }} />
            }
        />

    )
}

export default SummaryPEFTable

