

export function generateUsername(_firstname = '', _lastname = '') {
    return `${_firstname.replaceAll(' ', '')}.${_lastname.replaceAll(' ', '').substring(0, 3)}`.toLowerCase()
}

export function generateEmail(_firstname = '', _lastname = '', _local = '') {
    let text = generateUsername(_firstname, _lastname)
    let local = '@dappmaker.co.th'
    return `${text}${local}`
}

export function shouldUpdateFields(prevValues, curValues) {
    // console.log('#1')
    return JSON.stringify(prevValues) !== JSON.stringify(curValues)
}



export function renderFieldError(_arr) {
    let field = []
    Array.isArray(_arr) && _arr.forEach((e) => Array.isArray(e.name) && field.push(e.name[0]))
    return JSON.stringify(field.join(','))
}

  //renderFieldError(errorFields)