import React, { useState, useEffect } from "react";
import { Modal } from "antd";

export const ModalUploadTemplete = ({
  show,
  close,
  txtTitle = "Modal",
  ModalContent,
  fnOK,
  width,
}) => {
  const [textTitle, setTextTitle] = useState("Title Modal");
  useEffect(() => {
    setTextTitle(txtTitle);
  }, [show]);

  return (
      <Modal
        title={textTitle}
        visible={show}
        onOk={fnOK}
        onCancel={close}
        okText="Upload"
        width={width || 650}
      >
        <div style={{ width: "100%" }}>{ModalContent}</div>
    </Modal>
  );
};

export const RenderMainModal = ({
  show,
  onCancel,
  txtTitle = "Modal",
  ModalContent,
  footer = null,
  moreProps = {},
  width,
}) => {
  const [textTitle, setTextTitle] = useState("Title Modal");
  useEffect(() => {
    setTextTitle(txtTitle);
  }, [show]);

  return (
      <Modal
        title={textTitle}
        visible={show}
        onCancel={onCancel}
        footer={footer}
        centered
        width={width || 650}
        {...moreProps}
      >
        {ModalContent}
    </Modal>
  );
};
