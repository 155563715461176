import React, { useState } from "react";
import { Form, Card, Row, Col, Spin, Divider } from "antd";
import { Probation, USE_CONNECT_MODE_AUTO } from "../../services/ConnectAPI";
import CustomTableAtnd from "../tables/CustomTableAtnd";
import { SelectAntd } from "../select/SelectAntd";
import { useColumnTable } from "../../constants/UseColumnTable";
import { useButton } from "../buttons/CustomButtonAntd";
import { useOptionSelect } from "../../constants/OptionSelect";
import { useFixedQueryString } from "../../functions/UseMakeQueryString";
import { getUniqueListBy } from "../../functions/UseFunction";
import { useAlert } from "../modal/CustomAlert";

export const FormSearchProbation = () => {
  const [form] = Form.useForm();
  // [START] States
  const [changeSearchValue, setChangeSearchValue] = useState({});

  const [dataTable, setDataTable] = useState([]);

  const [loadingScreen, setLoadingScreen] = useState(false);
  // [END] States

  // [START] Handle Form
  const onFinish = (values) => {
    GET_Probation(values);
  };

  const onReset = () => {
    form.resetFields();
    setDataTable([]);
    setChangeSearchValue({});
  };
  // [END] Handle Form

  // [START] REST API
  const GET_Probation = async (value) => {
    try {
      setDataTable([]);
      setLoadingScreen(true);
      const url = `${USE_CONNECT_MODE_AUTO}${Probation.POST_Select_Probation}`;
      const response = await fetch(url, {
        method: `POST`,
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(value),
      });
      const data = await response.json();
      let ArrCompany = [];
      if (response.ok) {
        const RESULT = data.result;
        RESULT.map((val) =>
          ArrCompany.push({
            positionNo: val.positionNo,
            positionName: val.positionName,
            depNo: val.depNo,
            depName: val.depName,
            subDepNo: val.subDepNo,
            subDepName: val.subDepName,
            company: val.company,
          })
        );
        const ClearDuplicate = getUniqueListBy(ArrCompany, "positionNo");
        //console.log("ClearDuplicate", ClearDuplicate);
        const ReFormat = ClearDuplicate.map((val, index) => ({
          key: index,
          ...val,
          descriptions: RESULT.filter((el) => el.positionNo === val.positionNo),
        }));
        //console.log("ReFormat", ReFormat);
        setDataTable(ReFormat);
      } else {
        useAlert({
          type: "error",
          title: "เกิดข้อผิดพลาด",
          content: `${data.message}`,
          Fn: () => setDataTable([]),
        });
      }
    } catch (err) {
      console.log("Error GET Probation: ", err);
      setDataTable([]);
    } finally {
      setLoadingScreen(false);
    }
  };

  const DELETE_Probation = async (value) => {
    try {
      const BodyData = value
        ? { positionNo: value }
        : { positionNo: undefined };
      const url = `${USE_CONNECT_MODE_AUTO}${Probation.POST_Delete_Probation}`;
      const response = await fetch(url, {
        method: `POST`,
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(BodyData),
      });
      if (response.ok) {
        useAlert({
          type: "success",
          title: "การดำเนินการสำเร็จ",
          Fn: () =>
            setDataTable(dataTable.filter((val) => val.positionNo !== value)),
        });
      } else {
        useAlert({
          type: "error",
          title: "การไม่ดำเนินการสำเร็จ",
        });
      }
    } catch (err) {
      console.log("Error Delete Probation: ", err);
    }
  };

  // [END] REST API

  // [START] Functions
  const onChangeValue = (val, name) => {
    let setUndefined = {};
    if (name === "company") {
      setUndefined = {
        depNo: undefined,
        subDepNo: undefined,
        position: undefined,
      };
    } else if (name === "depNo") {
      setUndefined = {
        subDepNo: undefined,
      };
    }
    form.setFieldsValue(setUndefined);
    setChangeSearchValue((prev) => ({
      ...prev,
      ...setUndefined,
      [name]: val,
    }));
  };
  // [END] Functions

  // [START] Control Column
  const { Column_Probation, Probation_ExpandableTable } = useColumnTable({
    Config: {
      LinkEdit: `ManageMasterProbation/update-probation/`,
      FnDelete: (positionNo) => DELETE_Probation(positionNo),
    },
  });

  const ShowExpandableTable = (record) => {
    const MAP_descriptions = record.descriptions.map((val) => ({
      ...val,
      key: val.lineNum,
    }));
    return (
      <>
        <div style={{ marginTop: 10 }}>
          <Divider orientation="left">เกณฑ์ชี้วัด</Divider>
          {record.descriptions.length !== 0 && (
            <CustomTableAtnd
              HeadTable={Probation_ExpandableTable}
              DataTable={MAP_descriptions}
              SetScroll={{ x: "80%" }}
            />
          )}
        </div>
      </>
    );
  };

  const expandable = {
    expandedRowRender: (record) => ShowExpandableTable(record),
  };
  // [END] Control Column

  const CopyChangeValue = {
    companyCode: changeSearchValue.company,
    ...changeSearchValue,
  };

  return (
    <>
      <Spin tip="Loading..." spinning={loadingScreen}>
        <Card title="จัดการข้อมูล Probation" style={{ marginBottom: 30 }}>
          <Form
            form={form}
            onFinish={onFinish}
            labelCol={{
              span: 24,
            }}
            wrapperCol={{
              span: 24,
            }}
          >
            <Row gutter={[16, 16]} justify="start" align="bottom">
              <Col xs={24} sm={24} md={24} lg={6} xl={4}>
                <SelectAntd
                  name={`company`}
                  label={`บริษัท`}
                  smgReq={`กรุณาเลือก บริษัท`}
                  placeholder={`เลือก บริษัท`}
                  dropdown={useOptionSelect("company")}
                  FnOnChange={onChangeValue}
                  required={false}
                />
              </Col>
              <Col xs={24} sm={24} md={24} lg={8} xl={6}>
                <SelectAntd
                  name={`depNo`}
                  label={`แผนก`}
                  smgReq={`กรุณาเลือก แผนก`}
                  placeholder={`เลือก แผนก`}
                  dropdown={useOptionSelect(
                    "department",
                    useFixedQueryString(CopyChangeValue, ["companyCode"])
                  )}
                  FnOnChange={onChangeValue}
                  required={false}
                />
              </Col>
              <Col xs={24} sm={24} md={24} lg={10} xl={10}>
                <SelectAntd
                  name={`subDepNo`}
                  label={`แผนกย่อย`}
                  smgReq={`กรุณาเลือก บริแผนกย่อยษัท`}
                  placeholder={`เลือก แผนกย่อย`}
                  dropdown={useOptionSelect(
                    "subDepartment",
                    useFixedQueryString(changeSearchValue, ["company", "depNo"])
                  )}
                  FnOnChange={onChangeValue}
                  required={false}
                />
              </Col>
              <Col xs={24} sm={24} md={24} lg={14} xl={10}>
                <SelectAntd
                  name={`position`}
                  label={`ตำแหน่งงาน`}
                  smgReq={`กรุณาเลือก ตำแหน่งงาน`}
                  placeholder={`เลือก ตำแหน่งงาน`}
                  dropdown={useOptionSelect(
                    "position",
                    useFixedQueryString(CopyChangeValue, ["companyCode"])
                  )}
                  FnOnChange={onChangeValue}
                  required={false}
                />
              </Col>
              <Col xs={24} sm={12} md={12} lg={4} xl={2}>
                <Form.Item>{useButton({ call: "search" })}</Form.Item>
              </Col>
              <Col xs={24} sm={12} md={12} lg={4} xl={2}>
                <Form.Item>
                  {useButton({
                    call: "normal",
                    fn: onReset,
                    moreProps: { label: "ล้างข้อมูล" },
                  })}
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Card>
        {dataTable.length !== 0 && (
          <>
            <CustomTableAtnd
              HeadTable={Column_Probation}
              SetScroll={{ x: "80%" }}
              DataTable={dataTable}
              ExpandTable={expandable}
            />
          </>
        )}
      </Spin>
    </>
  );
};

export default FormSearchProbation;
