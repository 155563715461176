import React, { useState } from "react";
import { Form, Card, Spin, Space } from "antd";
import { optionSelectLocation, useOptionSelect } from "../../constants/OptionSelect";
import { toUndefined, sumColumnWidth, subStringDate } from "../../functions/UseFunction";
import { useFixedQueryString } from "../../functions/UseMakeQueryString";
import { configForm, WORDS } from "../../constants/ConstantComponent";
import { useRenderFormItem } from "../RenderComponents";
import CustomTableAtnd from "../tables/CustomTableAtnd";
import { useNotification } from "../notification/NotificationWithIcon";
import { ComponentReportResultCrime } from "../../constants/Reports/Components/ComponentReportCrime";
import { useExportExcel } from "../../services/ExportExcel";
import { Header_ReportCrimes } from "../../constants/HeaderExcel";
import { useAlert } from "../modal/CustomAlert";
import { ColumnReportResultCrime } from "../../constants/Reports/Columns/ColumnReports";
import { useButton } from "../buttons/CustomButtonAntd";
import { POST, POST_SELECT_DATA_CRIME } from "../../../service";

const FormReportCrimes = () => {
  const [form] = Form.useForm();
  // [START] States
  const [ChangeValue, setChangeValue] = useState({});
  const [dataTable, setDataTable] = useState([]);
  const [loadingScreen, setLoadingScreen] = useState(false);
  // [END] States
  let optionSearch = {
    company: useOptionSelect("company"),
    location: optionSelectLocation,
    depNo: useOptionSelect("department", useFixedQueryString(ChangeValue, ["companyCode"])),
    subDepNo: useOptionSelect("subDepartment", useFixedQueryString(ChangeValue, ["company", "location", "depNo"])),
  };

  // [START] Handle Form Antd
  const onFinish = (values) => {
    const { company, location, depNo, subDepNo, startDate, endDate } = values
    let objectBody = {
      company: company || "",
      location: location || "",
      depNo: depNo || "",
      subDepNo: subDepNo || "",
      startDate: startDate ? startDate.format("YYYY") : "",
      endDate: endDate ? endDate.format("YYYY") : "",
    }
    selectDataCrime(objectBody);
  };

  const onReset = () => {
    form.resetFields();
    setDataTable([]);
    setChangeValue({});
  };
  // [END] Handle Form Antd
  const selectDataCrime = async (bodyRaw) => {
    try {
      setLoadingScreen(true);
      setDataTable([]);
      const response = await POST(POST_SELECT_DATA_CRIME, bodyRaw);
      const { result } = response;
      let mapResult = [];
      if (result.length !== 0) {
        mapResult = result.map((row, index) => ({
          key: `${index}`,
          ...row,
          startDateJob: subStringDate(row.startDateJob),
          birthday: subStringDate(row.birthday),
          prepareDate: subStringDate(row.prepareDate),
          sendDate: subStringDate(row.sendDate),
        }));
      } else {
        useNotification({ type: "info", title: "ไม่พบข้อมูล" });
      }
      setDataTable(mapResult);
    } catch (err) {
      useAlert({ type: "catch", content: err.message })
      console.log("Error Search Report Data Crime: ", err);
    } finally {
      setLoadingScreen(false);
    }
  };

  // [START] Functions
  const onChangeValue = (name, value) => {
    let setName = {};
    let setUndefined = {};
    switch (name) {
      case "company":
        setName = { companyCode: value }
        setUndefined = toUndefined(["location", "depNo", "subDepNo"]);
        break;
      case "location":
        setUndefined = toUndefined(["depNo", "subDepNo"]);
        break;
      case "depNo":
        setUndefined = toUndefined(["subDepNo"]);
        break;
      case "startDate":
        setUndefined = toUndefined(["endDate"]);
        break;
    }
    setDataTable([]);
    form.setFieldsValue(setUndefined);
    setChangeValue((prev) => ({
      ...prev,
      ...setName,
      ...setUndefined,
      [name]: value,
    }));
  };

  const sendDataExcel = (arr = []) => {
    return arr.map((row, index) => ({ numRow: index + 1, ...row }));
  };
  // [END] Functions
  return (
    <Spin tip={WORDS.loadingSpin} spinning={loadingScreen}>
      <Form form={form} onFinish={onFinish} {...configForm} >
        <Card
          title="ค้นหารายงานดูข้อมูลการตรวจอาชญากรรม"
          style={{ marginBottom: 30 }}
          extra={
            <Space>
              {useButton({ call: "search" })}
              {useButton({
                call: "normal",
                fn: onReset,
                moreProps: { label: "ล้างข้อมูล" },
              })}
            </Space>
          }
        >
          {useRenderFormItem({
            constantComponent: ComponentReportResultCrime({
              options: optionSearch,
              handleChange: onChangeValue,
            }),
          })}
        </Card>
        {dataTable.length !== 0 && (
          <Card
            title="รายงานดูข้อมูลการตรวจอาชญากรรม"
            style={{ marginBottom: 30 }}
            extra={
              useExportExcel({
                header: Header_ReportCrimes,
                dataset: sendDataExcel(dataTable),
                filename: "รายงานผลตรวจอาชญากรรม",
              })
            }
          >
            <CustomTableAtnd
              HeadTable={ColumnReportResultCrime}
              DataTable={dataTable}
              moreProps={{
                scroll: {
                  x: sumColumnWidth(ColumnReportResultCrime) || 2000,
                  y: 500,
                },
              }}
            />
          </Card>
        )}
      </Form>
    </Spin>
  );
};

export default FormReportCrimes;
