import moment from 'moment';

const contractDays = 30; //สัญญาจ้ารายเดือน นับเป็น 30 วัน สูตร วันเริ่มงาน + 89 = 90 วัน
export const generalDetailAll = (
  setValue,
  getValues,
  options = {
    optionsCompanyTH: [],
    optionsPositionEN: [],
    optionsPositionTH: [],
    optionsDepartmentTH: [],
    optionsSubDepartmentTH: [],
    optionsWorkShift: [],
  }
) => {
  return [
    //No.1
    {
      ref: 'runningNo',
      name: 'runningNo',
      key: 'runningNo',
      label: 'No. ',
      type: 'INPUT',
      span: 4,
      placeholder: '',
      rules: {
        required: 'กรุณาระบุ เลขเอกสาร',
      },
    },
    {
      ref: 'refPRNo',
      name: 'refPRNo',
      key: 'refPRNo',
      label: 'Ref. PR NO. ',
      type: 'INPUT',
      span: 4,
      placeholder: '',
    },
    {
      ref: 'userId',
      name: 'userId',
      key: 'userId',
      label: 'รหัสพนักงาน',
      type: 'INPUT',
      span: 4,
      placeholder: '',
      rules: {
        required: 'กรุณาระบุ รหัสพนักงาน',
      },
    },
    {
      ref: 'empFullNameTH',
      name: 'empFullNameTH',
      key: 'empFullNameTH',
      label: 'ชื่อ-สกุล',
      type: 'INPUT',
      span: 7,
      placeholder: '',
      rules: {
        required: 'กรุณาระบุ ชื่อ-สกุลพนักงาน',
      },
    },
    {
      ref: 'empNickName',
      name: 'empNickName',
      key: 'empNickName',
      label: 'ชื่อเล่น',
      type: 'INPUT',
      span: 5,
      placeholder: '',
      rules: {
        required: 'กรุณาระบุ ชื่อเล่นพนักงาน',
      },
    },
    //--------------------------------------------------------
    {
      ref: 'positionNameTH',
      name: 'positionNameTH',
      key: 'positionNameTH',
      label: 'ตำแหน่ง (TH)',
      type: 'DROPDOWN',
      span: 8,
      properties: {
        options: options.optionsPositionTH,
      },
      rules: {
        required: 'กรุณาระบุ ตำแหน่งพนักงาน (ภาษาไทย)',
      },
    },
    {
      ref: 'empFullNameEN',
      name: 'empFullNameEN',
      key: 'empFullNameEN',
      label: 'Full Name',
      type: 'INPUT',
      span: 8,
      placeholder: '',
      rules: {
        required: 'กรุณาระบุ ชื่อ-สกุลพนักงาน (ภาษาอังกฤษ)',
      },
    },
    {
      ref: 'positionNameEN',
      name: 'positionNameEN',
      key: 'positionNameEN',
      label: 'Position (EN)',
      type: 'DROPDOWN',
      span: 8,
      properties: {
        options: options.optionsPositionEN,
      },
      rules: {
        required: 'กรุณาระบุ ตำแหน่งพนักงาน (ภาษาอังกฤษ)',
      },
    },
    {
      ref: 'departmentName',
      name: 'departmentName',
      key: 'departmentName',
      label: 'แผนก',
      type: 'DROPDOWN',
      span: 7,
      properties: {
        options: options.optionsDepartmentTH,
      },
    },
    {
      ref: 'subDepartmentName',
      name: 'subDepartmentName',
      key: 'subDepartmentName',
      label: 'แผนกย่อย Cost Center',
      type: 'DROPDOWN',
      span: 7,
      properties: {
        options: options.optionsSubDepartmentTH,
      },
    },
    {
      ref: 'salary',
      name: 'salary',
      label: 'เงินเดือน',
      type: 'INPUT_NUMBER',
      span: 5,
      placeholder: '',
      rules: {
        required: 'กรุณาระบุ เงินเดือนพนักงาน',
      },
    },
    {
      ref: 'jobGrade',
      name: 'jobGrade',
      key: 'jobGrade',
      label: 'Job Grade',
      type: 'INPUT_NUMBER',
      span: 5,
      placeholder: '',
    },
    {
      ref: 'companyNameTH',
      name: 'companyNameTH',
      key: 'companyNameTH',
      label: 'สถานที่ปฏิบัติงาน',
      type: 'DROPDOWN',
      span: 7,
      properties: {
        options: options.optionsCompanyTH,
      },
      rules: {
        required: 'กรุณาระบุ สถานที่ปฏิบัติงาน',
      },
    },
    {
      ref: `startDateJob`,
      name: `startDateJob`,
      key: `startDateJob`,
      placeholder: 'วันที่เริ่มงาน',
      label: 'วันที่เริ่มงาน',
      type: 'DATE_PICKER',
      span: 5,
      rules: {
        required: 'กรุณาระบุ วันที่เริ่มงาน',
      },
    },
    {
      ref: 'workShiftName',
      name: 'workShiftName',
      key: 'workShiftName',
      label: 'วัน-เวลาทำงาน',
      type: 'DROPDOWN',
      span: 12,
      properties: {
        options: options.optionsWorkShift,
      },
      rules: {
        required: 'กรุณาระบุ วัน-เวลาทำงาน',
      },
    },
    {
      ref: `empType`,
      name: `empType`,
      // key: ``,
      label: '',
      placeholder: 'ประเภทการจ้าง :',
      type: 'TEXT',
      disabled: true,
      span: 3,
    },
    {
      ref: `isSelectedDaily`,
      name: `isSelectedDaily`,
      key: `isSelectedDaily`,
      label: 'รายวัน',
      type: 'CHECK_BOX',
      span: 3,
      itemHandleChange: (e) => {
        const { isSelectedDaily } = getValues();
        const changeVal = !isSelectedDaily
        if (changeVal) {
          setValue('isSelectedMonthly', false);
          setValue('isSelectedTemp', false);
          setValue('isSelectedDaily', true);
        }        
      },
    },
    {
      ref: `isSelectedMonthly`,
      name: `isSelectedMonthly`,
      key: `isSelectedMonthly`,
      label: 'รายเดือน',
      type: 'CHECK_BOX',
      span: 3,
      itemHandleChange: (e) => {
        const { isSelectedMonthly } = getValues();
        const changeVal = !isSelectedMonthly
        if (changeVal) {
          setValue('isSelectedDaily', false);
          setValue('isSelectedTemp', false);
          setValue('isSelectedMonthly', true);
        }        
      },
    },
    {
      ref: `isSelectedTemp`,
      name: `isSelectedTemp`,
      key: `isSelectedTemp`,
      label: 'สัญญาจ้าง',
      type: 'CHECK_BOX',
      span: 3,
      itemHandleChange: (e) => {
        const { isSelectedTemp } = getValues();
        const changeVal = !isSelectedTemp
        if (changeVal) {
          setValue('isSelectedMonthly', false);
          setValue('isSelectedDaily', false);
          setValue('isSelectedTemp', true);
        }   
      },
    },
    {
      ref: 'contractMonth',
      name: 'contractMonth',
      key: 'contractMonth',
      label: 'จำนวนเดือน',
      type: 'INPUT_NUMBER',
      span: 2,
      itemHandleChange: (e, item) => {
        const contractMonth = e.target.value;
        const { startDateContract } = getValues();
        setValue(item.ref, contractMonth);
        const calDays = (contractDays * contractMonth) - 1
        const value = moment(startDateContract).add(calDays, 'days').format('YYYY-MM-DD');
        setValue('endContractDate', value);
      },
    },
    {
      ref: `startDateContract`,
      name: `startDateContract`,
      key: `startDateContract`,
      placeholder: '',
      label: 'ตั้งแต่วันที่',
      type: 'DATE_PICKER',
      span: 5,
      itemHandleChange: (e, item) => {
        const startDateContract = e;
        const { contractMonth } = getValues();
        setValue(item.ref, startDateContract);
        const calDays = (contractDays * contractMonth) - 1
        const value = moment(startDateContract).add(calDays, 'days').format('YYYY-MM-DD');
        setValue('endContractDate', value);
      },
    },
    {
      ref: `endContractDate`,
      name: `endContractDate`,
      key: `endContractDate`,
      placeholder: '',
      label: 'ถึง',
      type: 'DATE_PICKER',
      span: 5,
    },
    //HeadLevel1
    {
      ref: 'head1FullName',
      name: 'head1FullName',
      key: 'head1FullName',
      label: 'ผู้บังคับบัญชาลำดับ 1',
      type: 'INPUT',
      span: 8,
      placeholder: '',
    },
    {
      ref: 'head1Position',
      name: 'head1Position',
      key: 'head1Position',
      label: 'ตำแหน่ง',
      type: 'DROPDOWN',
      span: 8,
      properties: {
        options: options.optionsPositionTH,
      },
    },
    {
      ref: 'head1Department',
      name: 'head1Department',
      key: 'head1Department',
      label: 'แผนก',
      type: 'DROPDOWN',
      span: 8,
      properties: {
        options: options.optionsDepartmentTH,
      },
    },
    //HeadLevel2
    {
      ref: 'head2FullName',
      name: 'head2FullName',
      key: 'head2FullName',
      label: 'ผู้บังคับบัญชาลำดับ 2',
      type: 'INPUT',
      span: 8,
      placeholder: '',
    },
    {
      ref: 'head2Position',
      name: 'head2Position',
      key: 'head2Position',
      label: 'ตำแหน่ง',
      type: 'DROPDOWN',
      span: 8,
      properties: {
        options: options.optionsPositionTH,
      },
    },
    {
      ref: 'head2Department',
      name: 'head2Department',
      key: 'head2Department',
      label: 'แผนก',
      type: 'DROPDOWN',
      span: 8,
      properties: {
        options: options.optionsDepartmentTH,
      },
    },
    //Approval1
    {
      ref: `approver1`,
      name: `approver1`,
      key: `approver1`,
      label: '',
      placeholder: 'ผู้จัดการแผนก :',
      type: 'TEXT',
      disabled: true,
      span: 6,
    },
    {
      ref: `inPlan1`,
      name: `inPlan1`,
      key: `inPlan1`,
      label: 'อยู่ในแผนอัตรากำลัง',
      type: 'CHECK_BOX',
      span: 5,
    },
    {
      ref: `notInPlan1`,
      name: `notInPlan1`,
      key: `notInPlan1`,
      label: 'ไม่อยู่ในแผนอัตรากำลัง',
      type: 'CHECK_BOX',
      span: 5,
    },
    {
      ref: `approved1`,
      name: `approved1`,
      key: `approved1`,
      label: 'อนุมัติ',
      type: 'CHECK_BOX',
      span: 3,
    },
    {
      ref: `rejected1`,
      name: `rejected1`,
      key: `rejected1`,
      label: 'ไม่อนุมัติ',
      type: 'CHECK_BOX',
      span: 3,
    },
    {
      ref: 'rejectedRemark1',
      name: 'rejectedRemark1',
      key: 'rejectedRemark1',
      label: 'เหตุผล (ถ้าไม่อนุมัติ)',
      type: 'INPUT',
      span: 24,
      placeholder: '',
    },
    //Approval2
    {
      ref: `approver2`,
      name: `approver2`,
      key: `approver2`,
      label: '',
      placeholder: 'ผู้จัดการทรัพยากรมนุษย์ :',
      type: 'TEXT',
      disabled: true,
      span: 6,
    },
    {
      ref: `inPlan2`,
      name: `inPlan2`,
      key: `inPlan2`,
      label: 'อยู่ในแผนอัตรากำลัง',
      type: 'CHECK_BOX',
      span: 5,
    },
    {
      ref: `notInPlan2`,
      name: `notInPlan2`,
      key: `notInPlan2`,
      label: 'ไม่อยู่ในแผนอัตรากำลัง',
      type: 'CHECK_BOX',
      span: 5,
    },
    {
      ref: `approved2`,
      name: `approved2`,
      key: `approved2`,
      label: 'อนุมัติ',
      type: 'CHECK_BOX',
      span: 3,
    },
    {
      ref: `rejected2`,
      name: `rejected2`,
      key: `rejected2`,
      label: 'ไม่อนุมัติ',
      type: 'CHECK_BOX',
      span: 3,
    },
    {
      ref: 'rejectedRemark2',
      name: 'rejectedRemark2',
      key: 'rejectedRemark2',
      label: 'เหตุผล (ถ้าไม่อนุมัติ)',
      type: 'INPUT',
      span: 24,
      placeholder: '',
    },
    {
      ref: 'companyCode',
      name: 'companyCode',
      key: 'companyCode',
      type: 'HIDDEN',
      span: 24,
      display: 'none',
    },
  ];
};
