import { PATHNAME } from "../../constants/enums/pathname";

let DEFAULT_BREADCRUMB = [
  { label: "HRM - PMS" },
  { label: "จัดการข้อมูลพนักงาน" },
];

export function renderLabelWithPathname(_pathname) {
  switch (_pathname) {
    case PATHNAME.DASHBOARD_PERSONAL:
      return "รายชื่อพนักงานทั้งหมด";
    case PATHNAME.DASHBOARD_IMPORT_PERSONAL:
      return "Import พนักงานใหม่";
    case PATHNAME.DASHBOARD_IMPORT_PERSONAL_ID_CARD:
      return "Import พนักงานใหม่ (ผ่านบัตรประชาชน)";
    case PATHNAME.DASHBOARD_IMPORT_QUIT_PERSONAL:
      return "Import พนักงานพ้นสภาพ";
    case PATHNAME.DASHBOARD_IMPORT_QUIT_PERSONAL:
      return "Import พนักงานพ้นสภาพ";
    case PATHNAME.DASHBOARD_PERSONAL_VIEW:
      return "รายชื่อพนักงานทั้งหมด (ดูข้อมูล)";
    default:
      break;
  }
}

export const BREADCRUMB_PERSONAL = ({ pathname }) => {
  return [...DEFAULT_BREADCRUMB, { label: renderLabelWithPathname(pathname) }];
};
