import { responseAPI } from "../../moduleKPIs/common/functions/funcResponse";
import servicesAPI from "../../moduleKPIs/services/servicesAPI";

let API = servicesAPI.create()

export async function APISelectOptionUtility(payload, nextAction) {
    try {
        const response = await API.selectOptionUtility(payload);
        return responseAPI(response, nextAction)
    } catch (error) {
        console.error('error : ', error)
    }
}
export async function APISelectOptionStatus(payload, nextAction) {
    try {
        const response = await API.selectOptionStatus(payload);
        return responseAPI(response, nextAction);
    } catch (error) {
        console.error('error : ', error)
    }
}

export async function APISelectOptionWorkShiftAll(payload, nextAction) {
    try {
        const response = await API.selectOptionWorkShiftAll(payload);
        return responseAPI(response, nextAction)
    } catch (error) {
        console.error('error : ', error)
    }
}

export async function APISelectOptionDepartmenttAll(payload, nextAction) {
    try {
        const response = await API.selectOptionDepartmentAll(payload);
        return responseAPI(response, nextAction)
    } catch (error) {
        console.error('error : ', error)
    }
}

export async function APISelectOptionSubDepartment(payload, nextAction) {
    try {
        const response = await API.selectOptionSubDepartment(payload);
        return responseAPI(response, nextAction)
    } catch (error) {
        console.error('error : ', error)
    }
}

export async function APISelectOptionPosition(payload, nextAction) {
    try {
        const response = await API.selectOptionPosition(payload);
        return responseAPI(response, nextAction)
    } catch (error) {
        console.error('error : ', error)
    }
}

export async function APISelectOptionHRMFlow(payload, nextAction) {
    try {
        const response = await API.selectHRMFlow(payload);
        return responseAPI(response, nextAction)
    } catch (error) {
        console.error('error : ', error)
    }
}

export async function APISelectOptionAddressByPostcode(payload, nextAction) {
    try {
        const response = await API.selectOptionAddressByPostCode(payload);
        if (response.status === 200) {
            return response.data
        }
        return response
    } catch (error) {
        console.error('error : ', error)
    }
}

//!Not use

export async function APISelectOptionPersonal(payload, nextAction) {
    try {
        const response = await API.masterOptions(payload);
        return responseAPI(response, nextAction)
    } catch (error) {
        console.error('error : ', error)
    }
}