import React, { useState, useEffect, useContext } from 'react'
import './index.css'
import { Typography, Col, Row, Modal } from 'antd';
import { remove, update, path } from 'ramda'
import { MainInput } from '.';
import { ButtonTheme } from '../buttons';
import { validateFieldDynamic, renderWithLabel } from '../functions';
import { ButtonRemove } from '../buttons/buttonRemove';
import { ButtonAdd } from '../buttons/buttonAdd';
import { IndicatorContext } from '../../services/manage/indicatorProvider';
import { validateScore } from '../../../constants/functions';

/***
 * @param {*} buttonText is button add item
 */
export const DynamicField = ({ added, disabled, header, contentLayouts, structureContent, initialField, validateField, userLogin, onChange }) => {
    // const [initialValues, setInitialValues] = useState((Array.isArray(initialField) && initialField.length > 0) ? [...initialField] : [{ ...initialField }])
    // console.log({ initialField })
    // const [state, setstate] = useState(initialField)

    async function onChangeText({ fieldname, value, index, fieldgroup }) {
        let newValue = null
        switch (fieldname) {
            case 'title':
                // console.log({ fieldname, value, index, fieldgroup }, initialField[index])
                newValue = value
                if (!!initialField[index].indicatorHeaderId && initialField[index].title !== '') {
                    // console.log('clear', initialField)
                    // let newIndicator = []
                    // initialField.forEach(({ indicatorHeaderId, indicatorLine, criterion, proportion, refHeader, title }) => {
                    //     newIndicator.push({
                    //         indicatorHeaderId: '',
                    //         indicatorLine: '',
                    //         criterion,
                    //         proportion,
                    //         refHeader,
                    //         title
                    //     })
                    // })
                    // console.log({ newIndicator })
                    // console.log({ newValue })
                    onChange(update(index, { ...initialField[index], [fieldname]: newValue, refHeader: '' }, initialField), fieldname)
                } else {
                    //console.log('normal', newValue)
                    onChange(update(index, { ...initialField[index], [fieldname]: newValue }, initialField), fieldname)
                }
                break;
            case 'criterion':
            case 'proportion':
            case 'score':
                newValue = parseFloat(value) > 100 ? 100 : parseFloat(value) || ''
                onChange(update(index, { ...initialField[index], [fieldname]: newValue }, initialField), fieldname)
                break;
            case 'weight':
                newValue = parseFloat(value) > 100 ? 100 : parseFloat(value) || ''
                onChange({ ...initialField[index], [fieldname]: newValue }, index)
                break;
            case 'rawScore':
                // console.log({ structureContent })
                switch (fieldgroup) {
                    case 'indicator':
                        newValue = parseFloat(value) > 100 ? 100 : parseFloat(value) || ''
                        onChange(update(index, { ...initialField[index], [fieldname]: newValue }, initialField), fieldname)
                        break;
                    case 'indicatorFac':
                        newValue = parseFloat(value) > 5 ? 5 : value
                        // console.log('newValue', newValue)
                        const { weight } = initialField[index]
                        let payload = {
                            ...initialField[index],
                            // indicatorNo: indicatorNo,
                            // indicatorLine: indicatorLine,
                            rawScore: newValue,
                            // weight: weight,
                            score: (weight * newValue) / 100,
                            // createBy: userLogin,
                        }
                        //console.log({ payload })
                        onChange(payload, index)
                        break;

                    default:
                        break;
                    // createBy: userLogin,
                }
                // console.log({ payload })
                //onChange(payload, index)

                break;
            default:
                // console.log({ initialField })/*** สำหรับเช็ค Title */ 
                newValue = value
                onChange(update(index, { ...initialField[index], [fieldname]: newValue }, initialField), fieldname)
                break;
        }
        // setInitialValues(update(index, { ...initialValues[index], [fieldname]: value }, initialValues))
    }

    async function handleAdd() {
        let totalProportion = 0
        initialField.forEach(({ proportion }) => {
            totalProportion += proportion
        });
        // console.log('validateResult', totalProportion)
        // if (validateResult.error) {
        //     Modal.error({
        //         title: 'ไม่สามารถเพิ่มรายการได้',
        //         content: `พบข้อมูลมีค่าว่าง`
        //     })
        //     console.error('Validate Error ', validateResult.result)
        // } else {
        //     console.log('clear', initialField)
        // let newIndicator = []
        // initialField.forEach(({ indicatorHeaderId, indicatorLine, criterion, proportion, refHeader, title }) => {
        //     newIndicator.push({
        //         indicatorHeaderId: '',
        //         indicatorLine: '',
        //         criterion,
        //         proportion,
        //         refHeader,
        //         title
        //     })
        // })
        // console.log({ newIndicator })
        if (totalProportion < 100) {
            onChange(initialField.concat({ ...structureContent }))
        } else if (totalProportion === 100) {
            Modal.error({
                title: 'ไม่สามารถเพิ่มรายการได้',
                content: `สัดส่วนเต็ม 100%`
            })
        }

        // }
    }

    async function handleRemove(index) {
        // console.log('clear', initialField)
        // let newIndicator = []
        // initialField.forEach(({ indicatorHeaderId, indicatorLine, criterion, proportion, refHeader, title }) => {
        //     newIndicator.push({
        //         indicatorHeaderId: '',
        //         indicatorLine: '',
        //         criterion,
        //         proportion,
        //         refHeader,
        //         title
        //     })
        // })
        // console.log({ newIndicator })
        Modal.confirm({
            title: 'คุณต้องการลบข้อมูลใช่หรือไม่',
            onOk: () => {
                // setInitialValues(remove(index, 1, initialValues))
                onChange(remove(index, 1, initialField))
            }
        })
    }

    return (
        <div className="dynamic-field-card">
            <div className="layout-search-header">
                <div style={{ fontSize: 14 }}> {header || 'Header'} </div>
            </div>
            {
                Array.isArray(initialField) && initialField.map((initital, inititalIndex) => (
                    <Row key={inititalIndex} gutter={[24, 0]}>
                        {
                            contentLayouts.map((layout, layoutIndex) => (
                                <Col key={layoutIndex} span={layout.span}>
                                    <MainInput
                                        type={layout.type}
                                        disabled={disabled || layout.disabled}
                                        value={initital[layout.fieldname]}
                                        placeholder={layout.placeholder}
                                        onChange={(value) => onChangeText({ fieldname: layout.fieldname, value, index: inititalIndex, fieldgroup: layout.fieldgroup })}
                                        // inputProps={{
                                        //     disabled: disabled || layout.disabled,
                                        // }}
                                        inputItems={{
                                            tooltip: renderWithLabel({ label: initital[layout.tooltip] || '' }),
                                            title: layout.title || '',
                                            label: (inititalIndex > 0) ? null : layout.label
                                        }}
                                    />
                                </Col>

                            ))
                        }
                        {(added) &&
                            <Col span={2}>
                                {
                                    (inititalIndex === 0) ?
                                        <ButtonAdd
                                            disabled={disabled}
                                            onClick={() => handleAdd()} />
                                        :
                                        <ButtonRemove
                                            disabled={disabled}
                                            onClick={() => handleRemove(inititalIndex)} />
                                }
                            </Col>}
                        {/* {
                            (inititalIndex > 0) ?
                                <ButtonRemove onClick={() => handleRemove(inititalIndex)} />
                                :
                                <ButtonTheme style={{ padding: '15px 0px 0px' }}
                                    block
                                    // type="primary"
                                    buttonText={buttonText || 'Text'}
                                    onClick={() => handleAdd()}
                                />
                        } */}
                    </Row>
                ))
            }

            {/* {(added) && <ButtonTheme style={{ padding: '15px 0px 0px' }}
                block
                // type="primary"
                buttonText={buttonText || 'Text'}
                onClick={() => handleAdd()}
            />} */}
        </div>
    );
};

