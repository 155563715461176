import React, { useContext, useState, useEffect } from 'react'
import { STRUCTURE_KPIS_FACTORY } from '../../constants/structures/structuresKPIsFactory'
import { MasterContext } from '../../services/master/masterProvider';
import { ReportContext, REPORT_TYPE } from '../../services/report/reportProvider';
import { LayoutSearch, LayoutBreadcrumb, LayoutWrapper } from '../../common/layouts';
import { Col, Row, Spin } from 'antd';
import { ButtonTheme } from '../../common/buttons';
import ReportKPIsFactory from '../../components/reportKPIsFactory';
import { MASTER_OPTION } from '../../services/servicesOptions';
import { IndicatorContext } from '../../services/manage/indicatorProvider';
import { TYPE } from '../../common/inputs';

export const PageReportKPIsFactory = () => {
    const STRUCTURE = STRUCTURE_KPIS_FACTORY;
    const { masterState, onChange, getMaster } = useContext(MasterContext);
    const { searchReport } = useContext(ReportContext)
    const { userLogin, userPermission } = useContext(IndicatorContext)
    // let SEARCH_REPORTS = STRUCTURE.SEARCH_REPORTS()
    let SEARCH_REPORTS = (masterState.OPTION_VIEW_FACTORY.length > 1) ? STRUCTURE.SEARCH_REPORTS('approvalNo3') : STRUCTURE.SEARCH_REPORTS()
    const [loading, setLoading] = useState(false)

    const { SEARCH_OPTION_EMPLOYMENT_TYPE, SEARCH_OPTION_MONTH } = masterState

    const reportData = {
        result: [],
        byAssessment: SEARCH_OPTION_EMPLOYMENT_TYPE[0]
    }

    useEffect(() => {
        // window.localStorage.removeItem('dataSearch')
        getMaster({
            fieldname: MASTER_OPTION.FILTER_REPORT_FACTORY, payload: {
                userId: userLogin, //'ZT58025'// 'DP55245'
                userPermission: userPermission
            }
        })
    }, [])

    return (
        <LayoutWrapper
            breadcrumb={<LayoutBreadcrumb breadcrumbLists={STRUCTURE.BREADCRUMB_REPORT()} />}
            search={
                <Row gutter={[24, 10]}>
                    <ButtonTheme
                        type='primary'
                        buttonText="ค้นหา"
                        disabled={SEARCH_OPTION_MONTH[0] ? false : true}
                        onClick={async () => {
                            setLoading(true)
                            let success = await searchReport({
                                reportName: REPORT_TYPE.REPORT_FACTORY,
                                searchOption: masterState,
                                factory: true

                            })
                            if (success) {
                                setLoading(false)
                            }
                        }} />
                    {
                        SEARCH_REPORTS.map((each, eachIndex) => {
                            return (<Col span={each.span} key={eachIndex}>
                                <LayoutSearch
                                    type={TYPE.SELECT}
                                    required={each.required}
                                    header={each.header}
                                    onChange={onChange}
                                    fieldOption={each.fieldOption}
                                    defaultValue={masterState[`SEARCH_${each.fieldOption}`][0]}
                                    plainOptions={masterState[each.fieldOption]}
                                />
                                                          </Col>)
                        })
                    }
                </Row>
            }
            //byAssessment : มาจากการ Filter
            content={
                <div>
                    <Spin spinning={loading} tip="กำลังโหลด...">

                        <ReportKPIsFactory {...reportData} />
                        {/* {ConfirmWithPermission(userPermission)} */}
                    </Spin>

                </div>
            }
        />
    )
}

