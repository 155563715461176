import React from "react";
import { Tabs } from "antd";
import FormSearchKPIs from "./conponents/forms/FormSearchKPIs";
import FormAddKPIs from "./conponents/forms/FormAddKPIs";

export const ManageKPIs = () => {
  const { TabPane } = Tabs;
  return (
    <>
      <Tabs defaultActiveKey="1">
        <TabPane tab="จัดการหัวข้อ KPI ตามตำแหน่งงาน (โรงงาน)" key="1">
          <FormSearchKPIs />
        </TabPane>
        <TabPane tab="เพิ่มข้อมูล KPIs" key="2">
          <FormAddKPIs />
        </TabPane>
      </Tabs>
    </>
  );
};

export default ManageKPIs;
