import { Card, Col, Form, Input, Row, Spin } from "antd";
import React, { useState } from "react";
import { useButton } from "./conponents/buttons/CustomButtonAntd";
import { useConfirm } from "./conponents/modal/CustomConfirm";
import { CHANGE_PASS, GET_TARGET, POST } from "../service";
import { useAlert } from "./conponents/modal/CustomAlert";
import { SetStringToObject } from "../constants/functions";
import Cookies from "js-cookie";
import encodeString from "../extendFunction/encodeString";

const ChangePassword = () => {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  const onFinish = (value) => {
    const obj = SetStringToObject(Cookies.get("cookie_session"));
    if (obj) {
      let userId = encodeString.decode(obj.ID_User);
      const payload = { ...value, userID: userId };
      useConfirm({
        content: `ยืนยันการเปลี่ยนรหัสผ่าน`,
        FnOK: () => onChangePassword(payload),
      });
    }
  };

  const onChangePassword = async (value) => {
    setLoading(true);
    try {
      const res = await POST(CHANGE_PASS, value);
      console.log(res);
      const { success } = res;
      if (success) {
        useAlert({
          type: "success",
          title: "เปลี่ยนรหัสผ่านสำเร็จ",
        });
      }
    } catch (error) {
      useAlert({
        type: "error",
        content: error.message,
      });
    } finally {
      setLoading(false);
    }
  };
  return (
    <Spin spinning={loading}>
      <Card
        title="เปลี่ยนรหัสผ่าน"
        style={{ marginBottom: 30, maxWidth: 700, margin: "0 auto" }}
      >
        <Form
          form={form}
          onFinish={onFinish}
          labelCol={{
            span: 24,
          }}
          wrapperCol={{
            span: 24,
          }}
        >
          <Row gutter={[16, 16]} justify="start" align="bottom">
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <Form.Item
                label="รหัสผ่านเดิม (Old Password)"
                name="oldPassword"
                rules={[
                  {
                    required: true,
                    message: "กรุณากรอก รหัสผ่านเดิม (Old Password)",
                  },
                ]}
              >
                <Input.Password
                  allowClear
                  placeholder={`กรุณากรอก รหัสผ่านเดิม (Old Password)`}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <Form.Item
                label="รหัสผ่านใหม่ (New Password)"
                name="newPassword"
                rules={[
                  {
                    required: true,
                    message: "กรุณากรอก รหัสผ่านใหม่ (New Password)",
                  },
                ]}
              >
                <Input.Password
                  allowClear
                  placeholder={`กรุณากรอก รหัสผ่านใหม่ (New Password)`}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <Form.Item
                label="ยืนยันรหัสผ่านใหม่ (Confirm New Password)"
                name="newPasswordRecheck"
                rules={[
                  {
                    required: true,
                    message: "กรุณายืนยันรหัสผ่านใหม่ (Confirm New Password)",
                  },
                ]}
              >
                <Input.Password
                  allowClear
                  placeholder={`กรุณายืนยันรหัสผ่านใหม่ (Confirm New Password)`}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={8} lg={6} xl={4}>
              <Form.Item>
                {useButton({
                  call: "confirm",
                  fn: form.submit,
                })}
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Card>
    </Spin>
  );
};

export default ChangePassword;
