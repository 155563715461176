import React, { useEffect, useState, useContext, useRef } from 'react'
import { Row, Col, Spin } from 'antd'
import { LayoutWrapper, LayoutBreadcrumb, LayoutTabs, LayoutSearch } from '../../moduleKPIs/common/layouts'
import { BREADCRUMB_EXPORT_OT } from '../commonsOT/structuresOT/breadcrumOT'
import { SEARCH_EXPORT_OT } from '../commonsOT/structuresOT/searchOT'
import { TABS_EXPORT_OT } from '../commonsOT/structuresOT/tabsOT'
import { COLUMNS_EXPORT_OT } from '../commonsOT/structuresOT/tableOT'
import { ButtonTheme, ExportExcel } from '../../moduleKPIs/common/buttons'
import { TEXT_BTN, FIELD_BTN } from '../commonsOT/structuresOT/buttonOT'
import { MasterOTContext } from '../servicesOT/masterOT/masterOTProvider';
import { ManageOTContext } from '../servicesOT/manageOT/manageOTProvider';
import { MASTER_OPTION } from '../../moduleKPIs/services/servicesOptions';
import { ReportOTContext } from '../servicesOT/reportOT/reportOTProvider';


const PageExportOT = () => {
    const refExport = useRef()
    const { masterOTState, getMaster } = useContext(MasterOTContext);
    const { onClickButton, userLogin } = useContext(ManageOTContext);
    const { reportOTState } = useContext(ReportOTContext);
    const [initialData, setInitialData] = useState(null)
    const [loading, setLoading] = useState(false)

    let SEARCH_LIST = SEARCH_EXPORT_OT({ permission: null, options: masterOTState, initialData })
    let TAB_LIST = TABS_EXPORT_OT({ permission: null })

    useEffect(() => {
        async function fetchData() {
            await getMaster({ fieldname: MASTER_OPTION.FILTER_STATUS_OT })
            await getMaster({
                fieldname: MASTER_OPTION.FILTER_DASHBOARD_OT,
                selectAll: true,
                payload: {
                    userId: userLogin.userId,
                    userPermission: userLogin.userPermission,
                    subDepNo: ''
                }
            })

        }
        fetchData()
    }, [])

    const onChange = ({ fieldname, value, index = null }) => {
        switch (fieldname) {
            case MASTER_OPTION.FILTER_SUB_DEP_OT:
            case MASTER_OPTION.FILTER_STATUS_OT:
                setInitialData({ ...initialData, [fieldname]: value.checkedList })
                break;
            case 'activeDate':
                setInitialData({ ...initialData, [fieldname]: value.dateStrings })
                break;
            default:
                break;
        }
    }
    return (
        <LayoutWrapper
            breadcrumb={
                <div className="layout-breadcrumb-btn-insert">
                    <div className="layout-content-btn-search">
                        <ButtonTheme
                            type="primary"
                            style={{ padding: 10 }}
                            buttonText={TEXT_BTN.SEARCH_EXPORT_OT}
                            onClick={async () => {
                                await setLoading(true)
                                await onClickButton({ fieldBtn: FIELD_BTN.SEARCH_EXPORT_OT, payload: initialData })
                                await setLoading(false)
                            }}
                        />
                    </div>
                    <LayoutBreadcrumb breadcrumbLists={BREADCRUMB_EXPORT_OT()} />
                </div>
            }
            search={
                <Row gutter={[24, 10]}>
                    {SEARCH_LIST.map((each, eachIndex) => (
                        <Col key={eachIndex} span={each.span} >
                            <LayoutSearch
                                type={each.type}
                                header={each.header}
                                onChange={(el) => onChange({ fieldname: each.inputOption.fieldname, value: el })}
                                {...each.inputOption}
                            />
                        </Col>
                    ))}
                </Row>
            }
            exportFile={
                <ExportExcel 
                ref={refExport}
                style={{ padding: '0px 0px 20px' }}
                    columns={COLUMNS_EXPORT_OT({ permission: null })}
                    dataSource={reportOTState.exportOTFac}
                />
            }
            content={
                <div className="layout-content-card" >
                    <Spin spinning={loading} tip="กำลังโหลดข้อมูล...">
                        <LayoutTabs tabsLists={TAB_LIST} />
                    </Spin>
                </div>
            }
        />
    )
}

export default PageExportOT