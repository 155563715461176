export const colors = {

    theme: '#17a2b8',
    white: "#FFFFFF",
    buttonTheme: "#007BFF",
    buttonSearch: "#17A2B8",
    buttonEdit: "#dc3545",
    buttonConfirm: "#00B553",
    buttonCreate: "#1890FF",
    buttonDisabled: "#BFBFBF",
    buttonDraft: "#273746",

    baseText: '#878787',
    black: '#333333',
    success: '#5CB85C',
    wraning: '#FAAD14',
    info: '#18A0FB',
    error: '#FF4D4F',
    helper: 'bfbfbf',

    bgSignature: "#f4f4f4",

};
